import { Box, Divider, Drawer, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { GroupOptionType, IInfoOption, isInfoOption, useGetContentForInfoOption } from '../hooks/use-group-selector'
import { Callout } from './callout'
import { GroupsTree } from './groups-tree'

interface IGroupSelectorSidebarProps {
    open: boolean
    close: () => void

    divisions: SchoolDivisionsFragment[]

    availableOptions: GroupOptionType[]
    selectedGroupIds: string[]

    onGroupSelected: (groupId: string) => void
}

export const GroupSelectorSidebar = React.memo(({ open, close, availableOptions, selectedGroupIds, onGroupSelected }: IGroupSelectorSidebarProps) => {

    const infoOptions = useMemo(() => {
        return (availableOptions.filter(o => isInfoOption(o) && (o.status === 'divisions-selected' || o.status === 'schoolwide-selected')) as IInfoOption[])
    }, [ availableOptions ])
    const getContentForInfoOption = useGetContentForInfoOption()

    return (
        <Drawer anchor='left' open={open} onClose={close} >
            <Box width={250}>
                <Box p={1}>
                    <Typography variant='h6'>Select Group</Typography>
                </Box>

                <Divider />

                <Box m={1}
                    children={infoOptions.map(o => <Callout key={o.status} children={getContentForInfoOption(o)} type='warning' />)}
                />

                <GroupsTree onGroupSelected={(group) => onGroupSelected(group.id)} selectedGroupIds={selectedGroupIds} />
            </Box>
        </Drawer>
    )
})
