import { IModalControlProps, Modal, useModal } from './modal'
import { Box, Button, Grid, useTheme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { UserType } from '../graphql/autogenerate/schemas'
import * as Yup from 'yup'
import {
	GroupListFieldsFragment,
	SchoolDivisionsFragment,
} from '../graphql/autogenerate/operations'
import {
	FormikDynamicFieldsTouched,
	FormikIconSelector,
	FormikSelectField,
	FormikTextInput,
	ISelectOption,
	Checkbox,
	FormikValuesOnChange,
	FormikSwitchField,
	FormikUserTypeSelector,
} from './forms'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useHandleReactQueryMutation } from '../hooks'
import { ConfirmDialog } from './confirm-dialog'
import { useButtonStyles } from '../styles'
import { OnKeyPressEvent } from './on-key-press-event'
import { searchIconTypes } from '../helpers'
import {
	useCreateGroupMutation,
	useDeleteGroupMutation,
	useUpdateGroupMutation,
} from '../graphql/autogenerate/react-query'
import { useSnackbar } from 'notistack'
import { useSchoolContext } from '../stores/school'

interface IGroupCategoryOptionValue {
	id: string
	color?: string | null
	divisionName: string
	categoryName: string
}

interface IGroupFormModalProps {
	modalControl: IModalControlProps
	divisions: SchoolDivisionsFragment[]
	afterSave: () => void
	groupToEdit?: GroupListFieldsFragment
}

export const GroupFormModal = ({
	modalControl,
	divisions,
	afterSave,
	groupToEdit,
}: IGroupFormModalProps) => {
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()

	const {
		state: { enabledUserTypes },
	} = useSchoolContext()

	const groupCategoryOptions: ISelectOption<IGroupCategoryOptionValue>[] =
		useMemo(() => {
			const options: ISelectOption<IGroupCategoryOptionValue>[] = []
			return divisions.reduce((options, division) => {
				return [
					...options,
					...division.groupCategories.nodes.map(groupCategory => ({
						label: groupCategory.name,
						key: groupCategory.id,
						group: division.name,
						value: {
							id: groupCategory.id,
							color: groupCategory.iconBackgroundColor,
							divisionName: division.name,
							categoryName: groupCategory.name,
						},
					})),
				]
			}, options)
		}, [divisions])

	const initialValues = {
		name: groupToEdit?.groupName || '',
		iconType: groupToEdit?.iconType,
		description: groupToEdit?.information,
		groupCategory: groupCategoryOptions.find(
			g => g.key === groupToEdit?.groupCategoryId
		)?.value,
		passcode: groupToEdit?.passcode,
		passcodeHint: groupToEdit?.passcodeHint,
		requirePasscode: Boolean(groupToEdit?.passcode),
		enableAutoEnroll: Boolean(groupToEdit?.autoEnrollUserTypes),
		autoEnrollUserTypes: groupToEdit?.autoEnrollUserTypes || ([] as UserType[]),
	}

	const { mutateAsync: createGroup } = useHandleReactQueryMutation(
		useCreateGroupMutation({
			onSuccess: ({ createGroup }) => {
				enqueueSnackbar(
					<span>
						Successfully created <b>{createGroup?.group?.groupName}</b>
					</span>,
					{ variant: 'success' }
				)
			},
		})
	)

	const { mutateAsync: updateGroup } = useHandleReactQueryMutation(
		useUpdateGroupMutation()
	)
	const { mutate: deleteGroup } = useHandleReactQueryMutation(
		useDeleteGroupMutation()
	)

	const { warningOutlined } = useButtonStyles()
	const confirmGroupDelete = useModal()

	const [createAnother, setCreateAnother] = useState(false)
	const handleCreateAnotherChange = useCallback((checked: boolean) => {
		setCreateAnother(checked)
	}, [])

	const iconAutoSetRef = useRef(false)

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					groupCategory: Yup.object({}).required('Required'),
					name: Yup.string().required('Required'),
					requirePasscode: Yup.bool(),
					passcode: Yup.string()
						.nullable()
						.test('passcode', 'Required', function (value) {
							if (this.parent.requirePasscode) return Boolean(value)
							return true
						}),
					passcodeHint: Yup.string().nullable(),
					autoEnrollUserTypes: Yup.array().nullable().required('Required'),
				})}
				onSubmit={async (values, actions) => {
					if (values.groupCategory) {
						if (groupToEdit) {
							await updateGroup({
								groupName: values.name,
								groupCategoryId: values.groupCategory.id,
								iconType: values.iconType || null,
								information: values.description,
								groupId: groupToEdit.id,
								passcode: values.requirePasscode ? values.passcode : null,
								passcodeHint: values.requirePasscode ? values.passcodeHint : null,
								autoEnrollUserTypes: values.enableAutoEnroll
									? values.autoEnrollUserTypes
									: null,
							})
						} else {
							await createGroup({
								groupName: values.name,
								groupCategoryId: values.groupCategory.id,
								iconType: values.iconType || null,
								information: values.description,
								passcode: values.requirePasscode ? values.passcode : null,
								passcodeHint: values.requirePasscode ? values.passcodeHint : null,
								autoEnrollUserTypes: values.enableAutoEnroll
									? values.autoEnrollUserTypes
									: null,
							})
						}

						afterSave()

						if (!createAnother) {
							modalControl.close()
						} else {
							actions.setValues(values => ({
								...values,
								iconType: undefined,
								name: '',
								description: '',
							}))
							actions.setFieldTouched('name', false)
						}
					}
				}}
				children={({ submitForm, resetForm, values, setFieldValue }) => (
					<Modal
						{...modalControl.props}
						title={
							groupToEdit
								? `Edit ${groupToEdit.groupName}`
								: `Add Group${
										values.groupCategory
											? ` to ${values.groupCategory.divisionName} ${values.groupCategory.categoryName}`
											: ''
								  }`
						}
						size='sm'
						dividers
						dismissible
						actions={
							<Box flex={1} display='flex' justifyContent='space-between'>
								{!!groupToEdit ? (
									<Button
										type='button'
										variant='outlined'
										className={warningOutlined}
										onClick={confirmGroupDelete.open}
									>
										Delete
									</Button>
								) : (
									<Button
										style={{ minWidth: 100 }}
										type='button'
										onClick={modalControl.close}
										color='inherit'
									>
										Cancel
									</Button>
								)}

								<Box
									display='flex'
									justifyContent='flex-end'
									alignItems='center'
									flex={1}
								>
									{!!groupToEdit ? (
										<Button
											style={{ minWidth: 100 }}
											type='button'
											onClick={modalControl.close}
											color='inherit'
										>
											Cancel
										</Button>
									) : (
										<Checkbox
											checked={createAnother}
											label='Save and create another'
											onChange={handleCreateAnotherChange}
											name='create-another-group'
										/>
									)}
									<Box ml={1}>
										<Button
											style={{ minWidth: 100 }}
											type='submit'
											variant='outlined'
											color='primary'
											onClick={submitForm}
										>
											Save
										</Button>
									</Box>
								</Box>
							</Box>
						}
						afterClose={() => {
							resetForm()
							setCreateAnother(false)
						}}
					>
						<OnKeyPressEvent keyPress='Enter' action={submitForm} />
						<FormikDynamicFieldsTouched />
						<FormikValuesOnChange
							values={values}
							onChange={(prevValues, nextValues) => {
								if (
									(!nextValues.iconType || iconAutoSetRef.current) &&
									nextValues.name.length &&
									prevValues.name !== nextValues.name
								) {
									iconAutoSetRef.current = true
									const result = searchIconTypes(nextValues.name)[0]
									if (result !== nextValues.iconType) setFieldValue('iconType', result)
								}
							}}
							debounceMs={200}
						/>
						<Grid component={Form} container>
							<Grid item container xs={12}>
								<FormikSelectField
									style={{ margin: 0 }}
									fieldProps={{ name: 'groupCategory', label: 'Group Category' }}
									options={groupCategoryOptions}
									getKeyFromOptionValue={value => value?.id}
								/>
							</Grid>
							<Grid item xs={12} container>
								<FormikIconSelector
									fieldProps={{ name: 'iconType', label: 'Group Icon' }}
									color={values.groupCategory?.color}
									onOpened={() => (iconAutoSetRef.current = false)}
								/>
								<FormikTextInput
									fieldProps={{ name: 'name', label: 'Group Name' }}
									style={{ marginRight: 0, marginBottom: 0 }}
								/>
							</Grid>
							<Grid item container xs={12}>
								<FormikTextInput
									fieldProps={{ name: 'description', label: 'Description' }}
									multiline
									rows={5}
									style={{ margin: 0 }}
								/>
							</Grid>
							<Grid item container xs={12}>
								<FormikSwitchField
									style={{
										margin: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
									}}
									field={{ label: 'Require a Passcode', name: 'requirePasscode' }}
									tooltip='Enable to require a passcode when someone joins this group. Note: the passcode is case sensitive.'
								/>
								{values.requirePasscode && (
									<div style={{ display: 'flex', width: '100%' }}>
										<FormikTextInput
											style={{ flex: 1 }}
											fieldProps={{ name: 'passcode', label: 'Passcode' }}
										/>
										<FormikTextInput
											style={{ flex: 1 }}
											fieldProps={{
												name: 'passcodeHint',
												label: 'Passcode Hint (optional)',
											}}
										/>
									</div>
								)}
							</Grid>
							<Grid item container xs={12}>
								<FormikSwitchField
									style={{
										margin: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
									}}
									field={{ label: 'Enable auto-enroll', name: 'enableAutoEnroll' }}
									tooltip='Enable auto-enroll on a Group to automatically include users based on their UserType(s). Groups with auto-enroll enabled will not show up in the normal Groups selection lists. Users will be included as members of an auto-enroll Group based on their UserType(s).'
								/>
								{values.enableAutoEnroll && (
									<div style={{ display: 'flex', width: '100%' }}>
										<FormikUserTypeSelector
											disableAll
											field={{ name: 'autoEnrollUserTypes' }}
											userTypes={enabledUserTypes}
										/>
									</div>
								)}
							</Grid>
						</Grid>
					</Modal>
				)}
			/>

			{groupToEdit && (
				<ConfirmDialog
					{...confirmGroupDelete.props}
					title={`Confirm Delete: ${groupToEdit?.groupName}`}
					confirmButton={{
						label: 'Delete',
						type: 'error',
					}}
					body={
						<div>
							<p>Are you sure you want to delete this Group?</p>
							<p>All content within this Group will be permanently deleted:</p>
							<ul>
								<li>Events</li>
								<li>Resources</li>
								<li>Newsletters</li>
								<li>Notification history</li>
							</ul>
							<p>
								Once the Group is deleted, it will be removed from all users'
								subscription lists and will no longer show up as a subscription option.
							</p>
						</div>
					}
					confirm={() => {
						deleteGroup(
							{ groupId: groupToEdit.id },
							{
								onSettled: () => {
									afterSave()
									confirmGroupDelete.close()
									modalControl.close()
								},
							}
						)
					}}
				/>
			)}
		</>
	)
}
