import { useRef } from 'react'
import IMask from 'imask'
import { useField } from 'formik'
import { MaskedInput } from './masked-input'
import { IField, IFormikTextInputProps } from '.'
import { useFormikScrollToError } from '../../hooks'

export interface IFormikMaskedInputProps extends Omit<IFormikTextInputProps, 'fieldProps'> {
    field: IField
    mask: IMask.AnyMaskedOptions
}

export const FormikMaskedInput = (props: IFormikMaskedInputProps) => {
    const [ formikField, meta, helpers ] = useField<string>(props.field)

    const fieldRef = useRef<HTMLInputElement>(null)
    useFormikScrollToError({ field: fieldRef, name: formikField.name })

    return (
        <MaskedInput
            mask={props.mask}
            setValue={helpers.setValue}
            value={formikField.value}
            label={props.field.label}
            onBlur={formikField.onBlur}
            name={formikField.name}
            touched={meta.touched}
            error={meta.error}
            variant={props.variant}
            style={props.style}
            className={props.className}
            disabled={props.field.disabled}
        />
    )
}