import {
	createStyles,
	makeStyles,
	Typography,
	useTheme,
} from '@material-ui/core'
import { Add, Close, Edit, FolderOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import {
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { animated } from 'react-spring'
import Sortable from 'sortablejs'
import { v4 } from 'uuid'
import { ResourceFragmentFragment } from '../graphql/autogenerate/operations'
import {
	useAdminResourceQuery,
	useUpdateResourceSortOrderMutation,
} from '../graphql/autogenerate/react-query'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { getContrastTextColor } from '../helpers'
import {
	useBoop,
	useHandleReactQuery,
	useHandleReactQueryMutation,
} from '../hooks'
import { useModal } from './modal'
import { ResourceForm } from './resource-form'
import { ResourceListItem } from './resource-list-item'
import { ResourceTile } from './resource-tile'

const useStyles = (color: string) =>
	makeStyles(theme =>
		createStyles({
			header: {
				backgroundColor: color,
				color: getContrastTextColor(color),
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: theme.spacing(1),
				borderRadius: theme.shape.borderRadius,
			},
			description: {
				padding: theme.spacing(1),
				backgroundColor: theme.palette.grey[300],
				margin: `${theme.spacing(2)}px 0`,
				borderRadius: theme.shape.borderRadius,
			},
			addResourcesButton: {
				height: 100,
				border: `1px dashed`,
				borderColor: theme.palette.grey[600],
				color: theme.palette.grey[600],
				borderRadius: theme.shape.borderRadius,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				'&:hover': {
					color,
					borderColor: color,
				},
			},
		})
	)

interface IState {
	resourceToEdit?: ResourceFragmentFragment
	resourceId: string
}

interface IResourceFolderDetailProps {
	resourceId: string
	color: string
	closePressed: () => void
	folderDeleted: (resourceId: string) => void
	folderSaved: (resourceId: string) => void
	onParentFolderClickedRef: MutableRefObject<(() => void) | undefined>
}

export const ResourceFolderDetail = ({
	resourceId: initialResourceId,
	closePressed,
	folderDeleted,
	folderSaved,
	color,
	onParentFolderClickedRef,
}: IResourceFolderDetailProps) => {
	const theme = useTheme()
	const styles = useStyles(color)()
	const hoverBoop = useBoop({ rotation: 15, timing: 100 })
	const closeButtonHoverBoop = useBoop({ rotation: 15, timing: 100 })
	const editButtonHoverBoop = useBoop({ rotation: 15, timing: 100 })

	const [state, setState] = useState<IState>({ resourceId: initialResourceId })
	const { resourceId } = state
	useEffect(() => {
		setState(_state => ({ ..._state, resourceId: initialResourceId }))
	}, [initialResourceId])

	onParentFolderClickedRef.current = () =>
		setState(_state => ({ ..._state, resourceId: initialResourceId }))

	const modal = useModal()

	const { data, refetch } = useHandleReactQuery(
		useAdminResourceQuery({ id: resourceId })
	)
	const { folders, listItems } = useMemo(() => {
		return {
			folders:
				data?.resources?.nodes.filter(o => o.type === ResourceType.Folder) || [],
			listItems:
				data?.resources?.nodes.filter(o => o.type !== ResourceType.Folder) || [],
		}
	}, [data])

	const { mutate: updateSortOrder } = useHandleReactQueryMutation(
		useUpdateResourceSortOrderMutation({
			onError: () => {
				// If there's an error from sorting, have sortable return to the sort order of the current data.
				if (folderSortable.current)
					folderSortable.current.sort(folders.map(o => o.id))
				if (listItemsSortable.current)
					listItemsSortable.current.sort([
						...listItems.map(o => o.id),
						addResourceButtonId.current,
					])
			},
		})
	)
	const { enqueueSnackbar } = useSnackbar()
	const folderSortable = useRef<Sortable>()
	const attemptedHeaderDrag = useRef<{ from?: boolean; to?: boolean }>()
	const foldersSortRef = useCallback(
		(ref: HTMLDivElement | null) => {
			if (ref) {
				if (folderSortable.current) folderSortable.current.destroy()
				folderSortable.current = Sortable.create(ref, {
					onEnd: evt => {
						if (attemptedHeaderDrag.current?.to)
							enqueueSnackbar('Cannot put a resource before the header resource.', {
								variant: 'info',
							})
						if (attemptedHeaderDrag.current?.from)
							enqueueSnackbar('Cannot move the header resource from first position.', {
								variant: 'info',
							})
						attemptedHeaderDrag.current = undefined
						if (evt.oldIndex !== evt.newIndex && evt.newIndex !== undefined)
							updateSortOrder({ newSortOrder: evt.newIndex, resourceId: evt.item.id })
					},
					onMove: evt => {
						attemptedHeaderDrag.current = undefined
						if (
							data?.resources?.nodes.some(o => o.id === evt.related.id && o.header)
						) {
							attemptedHeaderDrag.current = { to: true }
							return false
						}
						if (
							evt.dragged.id !== evt.related.id &&
							data?.resources?.nodes.some(o => o.header && o.id === evt.dragged.id)
						) {
							attemptedHeaderDrag.current = { from: true }
							return false
						}
						return true
					},
					animation: 300,
				})
			}
		},
		[folders]
	)
	useEffect(() => {
		if (folderSortable.current)
			folderSortable.current.sort(folders.map(o => o.id))
	}, [folders])
	const listItemsSortable = useRef<Sortable>()
	const listItemsSortRef = useCallback(
		(ref: HTMLDivElement | null) => {
			if (ref) {
				if (listItemsSortable.current) listItemsSortable.current.destroy()
				listItemsSortable.current = Sortable.create(ref, {
					onEnd: evt => {
						if (evt.newIndex !== undefined)
							updateSortOrder({
								/* 
                            Since we present the resource items in a separate list after the folders, 
                            Sortable thinks their index starts at 0, but it really starts after the 
                            last index in the list of folders. So we need to add that number to 
                            the final sort order (index) we're updating.
                        */
								newSortOrder: evt.newIndex + folders.length,
								resourceId: evt.item.id,
							})
					},
					filter: '.disable-sortable',
					onMove: evt => {
						// Don't allow dragging onto the "add resource" button (which doesn't have an ID)
						if (evt.related.id === addResourceButtonId.current) return false
						return true
					},
					animation: 300,
				})
			}
		},
		[folders]
	)
	const addResourceButtonId = useRef(v4())
	useEffect(() => {
		if (listItemsSortable.current)
			listItemsSortable.current.sort([
				...listItems.map(o => o.id),
				addResourceButtonId.current,
			])
	}, [listItems])

	if (!data?.resources || !data.resource) return null

	const { resource } = data

	return (
		<>
			<div
				style={{
					position: 'relative',
					height: '100%',
					overflow: 'hidden',
					display: 'flex',
					minHeight: 0,
					flexDirection: 'column',
				}}
			>
				<div className={clsx(styles.header)}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FolderOutlined style={{ marginRight: theme.spacing(1) }} />
						{resource.resourceByParentResource && (
							<Typography
								variant='h6'
								style={{ cursor: 'pointer' }}
								onClick={() =>
									setState(_state => ({
										..._state,
										resourceId:
											resource.resourceByParentResource?.id || _state.resourceId,
									}))
								}
							>
								{resource.resourceByParentResource.title} /&nbsp;
							</Typography>
						)}
						<Typography variant='h6'>{resource.title}</Typography>
						<animated.div
							onMouseEnter={editButtonHoverBoop.trigger}
							style={{
								...editButtonHoverBoop.style,
								cursor: 'pointer',
								display: 'flex',
								alignItems: 'center',
								marginLeft: theme.spacing(1),
							}}
						>
							<Edit
								onClick={() => {
									setState(_state => ({ ..._state, resourceToEdit: resource }))
									modal.open()
								}}
							/>
						</animated.div>
					</div>
					<animated.div
						onMouseEnter={closeButtonHoverBoop.trigger}
						style={{
							...closeButtonHoverBoop.style,
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Close onClick={closePressed} />
					</animated.div>
				</div>

				{/* TODO: add header image */}

				<div style={{ overflow: 'auto', flex: 1 }}>
					{resource.coverImage?.temporaryDownloadUrl && (
						<div
							style={{
								marginTop: theme.spacing(2),
								display: 'flex',
								justifyContent: 'center',
								maxHeight: 250,
								overflow: 'hidden',
							}}
						>
							<img
								style={{ objectFit: 'contain', maxHeight: '100%' }}
								src={resource.coverImage.temporaryDownloadUrl}
							/>
						</div>
					)}

					{resource.body ? (
						<div
							className={styles.description}
							dangerouslySetInnerHTML={{ __html: resource.body }}
						/>
					) : (
						<div style={{ margin: theme.spacing(2) }} />
					)}

					{folders.length > 0 && (
						<div
							ref={foldersSortRef}
							style={{
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
								gridAutoRows: '150px',
								columnGap: theme.spacing(1),
								rowGap: theme.spacing(1),
								marginBottom: theme.spacing(2),
							}}
						>
							{folders?.map(o => (
								<ResourceTile
									key={o.id}
									resourceItem={o}
									color={color}
									onClick={() => setState(_state => ({ ..._state, resourceId: o.id }))}
								/>
							))}
						</div>
					)}

					<div ref={listItemsSortRef}>
						{listItems.map(o => (
							<ResourceListItem
								key={o.id}
								resourceItem={o}
								onClick={() => {
									setState(_state => ({ ..._state, resourceToEdit: o }))
									modal.open()
								}}
							/>
						))}
					</div>

					<div
						/* 
                            We need an ID on the Add Resource button so we can exclude it from SortableJS's .sort() method. 
                            Apparently the 'disable-sortable' filter only applies to sorting actions not inclusion in the sort array. 🙄 
                        */
						id={addResourceButtonId.current}
						onMouseEnter={hoverBoop.trigger}
						onClick={modal.open}
						className={clsx(styles.addResourcesButton, 'disable-sortable')}
						style={data.resources.totalCount ? {} : { marginTop: theme.spacing(2) }}
					>
						<animated.div style={hoverBoop.style}>
							<Add />
						</animated.div>
						Add Resource
					</div>
				</div>

				{/* <Fab color='primary' style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <Add />
                </Fab> */}
			</div>

			<ResourceForm
				resourceToEdit={{
					resource: state?.resourceToEdit,
					setResourceToEdit: resourceToEdit =>
						setState(_state => ({ ..._state, resourceToEdit })),
				}}
				modalControls={modal}
				parentResourceId={resource.id}
				groupId={resource.groupId}
				onSave={() => {
					refetch()
					if (state.resourceToEdit?.id === resourceId)
						folderSaved(state.resourceToEdit.id)
				}}
				onDelete={() => {
					if (state.resourceToEdit?.id === resourceId) {
						if (resource.resourceByParentResource) {
							setState(_state => ({
								..._state,
								resourceId: resource.resourceByParentResource?.id || _state.resourceId,
							}))
						} else {
							closePressed()
						}
						folderDeleted(state.resourceToEdit.id)
					} else {
						refetch()
					}
				}}
				enableHeaderResourceToggle={type => type === ResourceType.Folder}
				enableTileImageForResourceTypes={[ResourceType.Folder]}
			/>
		</>
	)
}
