import { Button, IconButton, Paper, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import dayjs from 'dayjs'
import { MutableRefObject, useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useLocation } from 'react-use'
import { NewsletterFragment } from '../graphql/autogenerate/operations'
import { useUrlQuery } from '../hooks'
import { useNativeBridge } from '../hooks/use-native-bridge'
import { Callout } from './callout'
import { NewsletterHeader } from './newsletter-header'
import { PageDisplay } from './page-display'

interface INewsletterDisplayProps {
    newsletter: NewsletterFragment
    handlePrintRef?: MutableRefObject<(() => void) | undefined>
}

export const NewsletterDisplay = ({ newsletter, handlePrintRef }: INewsletterDisplayProps) => {
    const theme = useTheme()

    const phoneAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const ref = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: `${dayjs(newsletter.publishDate || undefined).format('MM/DD/YY')}${newsletter.title ? ` - ${newsletter.title}` : ''}`,
        bodyClass: 'hide-print-button'
    })
    if (handlePrintRef) handlePrintRef.current = handlePrint

    const nativeBridge = useNativeBridge()
    const location = useLocation()

    const query = useUrlQuery()
    useEffect(() => {
        if (query.get('print')) {
            setTimeout(() => {
                handlePrint()
            }, 500)
        }
    }, [])


    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', overflow: 'hidden', padding: theme.spacing(phoneAndDown ? 0 : 2) }} >
            <div style={{ overflow: 'auto', }}>
                <Paper ref={ref} style={{ maxWidth: 850, flex: 1, display: 'flex', flexDirection: 'column', paddingBottom: 10, position: 'relative' }}>
                    <NewsletterHeader newsletter={newsletter} />

                    {newsletter.url ?
                        <div style={{ margin: '10%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Callout style={{ marginBottom: theme.spacing(2) }}>
                                This newsletter was created as a link to an existing external newsletter.
                            </Callout>

                            <Button variant='contained' target='_blank' color='primary' href={newsletter.url}>View External Newsletter</Button>
                        </div>
                        :
                        <>
                            <a
                                href={nativeBridge && location.origin?.replace('native.', '') + '/newsletter/' + newsletter.slug + '?print=true'}
                                onClick={nativeBridge ? undefined : handlePrint}
                                target='_blank'
                                className='print-button'
                                style={{
                                    position: 'absolute',
                                    top: theme.spacing(.5),
                                    right: theme.spacing(.5),
                                    color: 'white',
                                    zIndex: 1,
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.primary.main,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: theme.spacing(.5),
                                    borderRadius: '50%'
                                }}
                            >
                                <Print />
                            </a>
                            <PageDisplay pageId={newsletter.pageId} />
                        </>
                    }
                </Paper>
            </div>
        </div>
    )
}