import { createStyles, makeStyles } from '@material-ui/core'
import React, { } from 'react'
import { useCalendarContext } from '../stores/calendar'
import { MemoizedCalendarHeaderSidenavToggle } from './calendar-header-sidenav-toggle'
import clsx from 'clsx'
import { MemoizedCalendarHeaderTodayButton } from './calendar-header-today-button'
import { MemoizedCalendarHeaderForwardBackArrows } from './calendar-header-forward-back-arrows'
import { MemoizedCalendarHeaderSelectedDateTitle } from './calendar-header-selected-date-title'
import { MemoizedCalendarHeaderSearch } from './calendar-header-search'
import { MemoizedCalendarHeaderInfo } from './calendar-header-info'
import { MemoizedCalendarHeaderSettings } from './calendar-header-settings'
import { MemoizedCalendarKeyboardShortcuts } from './calendar-keyboard-shortcuts'
import { CalendarHeaderGcs } from './calendar-header-gcs'

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            padding: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey[ 300 ]}`
        }
    })
)

const CalendarHeader = () => {
    const { state, dispatch } = useCalendarContext()

    const styles = useStyles()

    return (
        <div className={clsx(styles.container)}>
            {!state.searchActive &&
                <>
                    <MemoizedCalendarHeaderSidenavToggle dispatch={dispatch} />
                    <MemoizedCalendarHeaderTodayButton dispatch={dispatch} />
                    <MemoizedCalendarHeaderForwardBackArrows dispatch={dispatch} viewMode={state.viewMode} />
                    <MemoizedCalendarHeaderSelectedDateTitle selectedDate={state.selectedDate} viewMode={state.viewMode} />
                </>
            }

            {/* <MemoizedCalendarHeaderSearch dispatch={dispatch} searchActive={state.searchActive} /> */}

            {!state.searchActive &&
                <>
                    <CalendarHeaderGcs />
                    <MemoizedCalendarHeaderInfo />
                    {/* <MemoizedCalendarHeaderSettings /> */}
                    {/* <MemoizedCalendarHeaderViewModeSelect dispatch={dispatch} viewMode={state.viewMode} /> */}
                </>
            }

            <MemoizedCalendarKeyboardShortcuts dispatch={dispatch} disableShortcuts={state.disableShortcuts} disableDateScrolling={state.disableDateScrolling} viewMode={state.viewMode} />
        </div>
    )
}


export const MemoizedCalendarHeader = React.memo(CalendarHeader)

