import { useModal, Modal } from './modal'
import {  useHandleReactQueryMutation } from '../hooks'
import { Button, Grid, Chip, useTheme, makeStyles, createStyles, Box } from '@material-ui/core'
import { Delete, DragHandle } from '@material-ui/icons'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { useCallback } from 'react'
import { useCommonStyles } from '../styles'
import { EditableTitle } from './editable-title'
import { CityHall } from '../images/index'
import Sortable from 'sortablejs'
import clsx from 'clsx'
import { useState } from 'react'
import { NewDivisionForm } from './new-division-form'
import { ConfirmDialog } from './confirm-dialog'
import { SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { useDeleteDivisionMutation, useMakeDivisionSchoolwideMutation, useUpdateDivisionNameMutation, useUpdateDivisionSortOrderMutation } from '../graphql/autogenerate/react-query'

const useStyles = makeStyles((theme) =>
    createStyles({
        grid: {
            display: 'grid',
            gridTemplateColumns: '30px 165px auto 25px',
            columnGap: theme.spacing(1),
            rowGap: theme.spacing(1),
            justifyItems: 'stretch',
            alignItems: 'center',
            flex: 1,
            marginBottom: theme.spacing(1),
        },
        sortableHandle: {
            cursor: 'pointer',
            justifySelf: 'center'
        }
    })
)

interface IEditDivisionsButtonProps {
    schoolId: string
    refetchDivisions?: () => any
    divisions?: SchoolDivisionsFragment[]
}
export const EditDivisionsButtons = ({ refetchDivisions, schoolId, divisions }: IEditDivisionsButtonProps) => {
    const commonStyles = useCommonStyles()
    const styles = useStyles()
    const theme = useTheme()

    const editDivisionsModal = useModal()

    const [ showNewDivisionForm, setShowNewDivisionsForm ] = useState(false)

    const { mutate: updateDivisionName } = useHandleReactQueryMutation(useUpdateDivisionNameMutation())
    const { mutateAsync: updateDivisionSortOrder } = useHandleReactQueryMutation(useUpdateDivisionSortOrderMutation())
    const { mutate: makeDivisionSchoolwide } = useHandleReactQueryMutation(useMakeDivisionSchoolwideMutation())

    const [ divisionToDeleteId, setDivisionToDeleteId ] = useState<string>()
    const { mutate: deleteDivision } = useHandleReactQueryMutation(useDeleteDivisionMutation())

    const sortableListRef = useCallback((element: HTMLDivElement) => {
        if (element) {
            Sortable.create(element, {
                animation: 150,
                easing: 'cubic-bezier(1, 0, 0, 1)',
                handle: '.sortableHandle',
                draggable: '.sortableDraggable',
                store: {
                    set: async (sortable) => {
                        const promises: Promise<any>[] = []
                        sortable.toArray().forEach((divisionId, sortOrder) =>
                            promises.push(updateDivisionSortOrder({ divisionId, sortOrder }))
                        )
                        await Promise.all(promises)
                        refetchDivisions && refetchDivisions()
                    },
                    get: () => {
                        return []
                    }
                }
            })
        }
    }, [])

    return (
        <>
            <Button style={{ minWidth: 100 }} variant='contained' onClick={editDivisionsModal.open}>
                Edit Divisions
            </Button>

            <Modal
                {...editDivisionsModal.props}
                title='Edit Divisions'
                size='sm'
                dividers
                dismissible
                disableEscapeKeyDown
                actions={
                    <Button type='button' onClick={editDivisionsModal.close} color='primary'>
                        Done
                    </Button>
                }
            >
                <Grid container>
                    {/* If we don't have any Divisions show the form */}
                    {!divisions &&
                        <NewDivisionForm schoolId={schoolId} afterDivisionSave={refetchDivisions} />
                    }

                    {/* If we have exactly one Division just show the edit title button */}
                    {divisions && divisions.length === 1 &&
                        <Grid item container xs={12} style={{ minHeight: 50 }}>
                            <EditableTitle
                                title={divisions[ 0 ].name}
                                onSave={({ title: name }) => {
                                    updateDivisionName(
                                        { name, divisionId: divisions[ 0 ].id },
                                        {
                                            onSettled: () => {
                                                if (refetchDivisions) refetchDivisions()
                                            }
                                        }
                                    )
                                }}
                            />
                        </Grid>
                    }

                    {/* If we have more than one Division, show the list with controls for deleting, reordering, and changing which Division is the schoolwide Division. */}
                    {divisions && divisions.length > 1 &&
                        <Grid ref={sortableListRef} item container xs={12} style={{ minHeight: 50 }}>
                            <div className={clsx(styles.grid)} style={{ borderBottom: `1px solid ${theme.palette.grey[ 400 ]}`, paddingBottom: theme.spacing(.5), marginBottom: theme.spacing(2) }}>
                                <b></b>
                                <b>Schoolwide</b>
                                <b>Division Name</b>
                                <b></b>
                            </div>
                            {divisions.map(division =>
                                <div data-id={division.id} key={division.id} className={clsx(styles.grid, 'sortableDraggable')}>
                                    <DragHandle className={clsx(styles.sortableHandle, 'sortableHandle')} />
                                    <Box display='flex' mr={.5}>
                                        {division.schoolwide ?
                                            <Chip
                                                icon={<CityHall height='60%' style={{ paddingLeft: 5 }} />}
                                                label='Schoolwide'
                                                color='primary'
                                                style={{ justifyContent: 'flex-start', flex: 1 }}
                                            />
                                            :
                                            <Chip
                                                icon={<CityHall height='60%' style={{ paddingLeft: 5, cursor: 'pointer' }} />}
                                                label='Make Schoolwide'
                                                onClick={() => {
                                                    makeDivisionSchoolwide(
                                                        { divisionId: division.id },
                                                        {
                                                            onSettled: () => {
                                                                if (refetchDivisions) refetchDivisions()
                                                            }
                                                        }
                                                    )
                                                }}
                                                style={{ justifyContent: 'flex-start', flex: 1 }}
                                            />
                                        }
                                    </Box>
                                    <EditableTitle
                                        title={division.name}
                                        onSave={({ title: name }) => {
                                            updateDivisionName(
                                                { name, divisionId: division.id },
                                                {
                                                    onSettled: () => {
                                                        if (refetchDivisions) refetchDivisions()
                                                    }
                                                }
                                            )
                                        }}
                                    />
                                    <AnimationIconWraper
                                        children={<Delete />}
                                        className={commonStyles.errorColorHover}
                                        onClick={() => setDivisionToDeleteId(division.id)}
                                    />
                                </div>
                            )}
                        </Grid>
                    }

                    {/* If we have at least one Division, show the button to add more. */}
                    {divisions && divisions.length > 0 &&
                        <Grid item xs container style={{ marginTop: theme.spacing(2), alignContent: 'flex-end' }}>
                            {showNewDivisionForm ?
                                <NewDivisionForm
                                    schoolId={schoolId}
                                    afterDivisionSave={() => {
                                        refetchDivisions && refetchDivisions()
                                        setShowNewDivisionsForm(false)
                                    }}
                                    onFormCancel={() => setShowNewDivisionsForm(false)}
                                />
                                :
                                <Button variant='outlined' onClick={() => setShowNewDivisionsForm(true)}>Add Division</Button>
                            }
                        </Grid>
                    }
                </Grid>
            </Modal>

            <ConfirmDialog
                isOpen={!!divisionToDeleteId}
                close={() => setDivisionToDeleteId(undefined)}
                title='Delete Division?'
                body='Are you sure you want to delete this Division? This action cannot be undone.'
                confirmButton={{
                    label: 'Delete',
                    type: 'error'
                }}
                confirm={() => {
                    if (divisionToDeleteId) {
                        deleteDivision(
                            { divisionId: divisionToDeleteId },
                            {
                                onSettled: () => {
                                    if (refetchDivisions) refetchDivisions()
                                }
                            }
                        )
                    }
                }}
            />
        </>
    )
}

