import { Paper, useTheme } from '@material-ui/core'
import { GroupCategoryListFieldsFragment, GroupListFieldsFragment } from '../graphql/autogenerate/operations'
import { GroupsTree } from './groups-tree'
import { CommonProps } from './interfaces'

interface IAdminResourcesSidebarProps extends CommonProps {
    onGroupSelected: (group: GroupListFieldsFragment, groupCategory?: GroupCategoryListFieldsFragment) => void
}

export const AdminResourcesSidebar = ({ className, style, onGroupSelected }: IAdminResourcesSidebarProps) => {
    const theme = useTheme()

    return (
        <Paper className={className} style={{ padding: theme.spacing(2), overflowY: 'auto', ...style }}>
            <GroupsTree onGroupSelected={onGroupSelected} />
        </Paper>
    )
}