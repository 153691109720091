import { Divider, Typography, useTheme } from '@material-ui/core'
import { ArrowBackIos, LocationOn, Schedule, Today } from '@material-ui/icons'
import dayjs, { Dayjs } from 'dayjs'
import {
	Fragment,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { IconType } from '../graphql/autogenerate/schemas'
import { IconTypeDisplay } from './icon-type-display'
import { EventFeedFragment } from '../graphql/autogenerate/operations'
import { SchoolNavBar } from './school-nav-bar'
import { ImageDisplay } from './image-display'
import { getContrastTextColor } from '../helpers'
import { LinkButton } from './link-button'
import { useLocation } from 'react-router-dom'

interface IEventsByDay {
	day: Dayjs
	events: IEventsListProps['events']
}

interface IEventsListProps {
	events: EventFeedFragment[]
	day: Dayjs
	onEventPressed?: (event: EventFeedFragment) => void
}

export const EventsList = ({
	events,
	day,
	onEventPressed,
}: IEventsListProps) => {
	const theme = useTheme()

	const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
		undefined
	)

	// Lets go stupid simple. This thing gathers events for days of the week. Create the days and just add events to them...
	const eventsByDay = useMemo<IEventsByDay[]>(() => {
		return gatherEventsByDayInWeek({ events, day })
	}, [events])

	const eventPressed = useCallback((event: EventFeedFragment) => {
		setSelectedEventId(event.id)
		if (onEventPressed) onEventPressed(event)
	}, [])

	const selectedEvent = useMemo(() => {
		return events.find(o => o.id === selectedEventId)
	}, [selectedEventId, events])

	return (
		<>
			{events.length ? (
				eventsByDay.map((day, index) => (
					<Fragment key={day.day.toISOString()}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: index && theme.spacing(2),
								marginBottom: theme.spacing(1),
							}}
						>
							<Divider style={{ flex: 1 }} />
							<Typography
								color='textSecondary'
								variant='caption'
								style={{ margin: `0px ${theme.spacing(1)}px` }}
							>
								{day.day.format('dddd, D')}
							</Typography>
							<Divider style={{ flex: 1 }} />
						</div>

						{day.events.length === 0 && (
							<Typography
								align='center'
								style={{ color: theme.palette.grey[400] }}
							>
								No events.
							</Typography>
						)}

						{day.events.map(event => {
							return (
								<GroupCell
									key={`${index}-${event.id}`}
									id={event.id}
									color={event.calendarDisplay?.iconBackgroundColor}
									icon={event.calendarDisplay?.icon}
									title={event.title}
									allDay={event.allDay}
									startDate={event.startDate}
									endDate={event.endDate}
									groupName={event.calendarDisplay?.calendars
										?.map(o => o?.title)
										.join(', ')}
									onEventPressed={() => {
										eventPressed(event)
									}}
								/>
							)
						})}
					</Fragment>
				))
			) : (
				<div
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography color='textSecondary'>No events this week.</Typography>
				</div>
			)}

			{selectedEvent && (
				<EventDetail
					onClosePressed={() => setSelectedEventId(undefined)}
					selectedEvent={selectedEvent}
				/>
			)}
		</>
	)
}

interface IGroupCellProps {
	id: string
	color?: string | null
	icon?: string | null
	title: string
	allDay?: boolean
	startDate: string
	endDate: string
	groupName?: string | null
	onEventPressed: () => void
}
const GroupCell = memo(
	({
		color,
		icon,
		title,
		allDay,
		startDate,
		endDate,
		groupName,
		onEventPressed,
		id,
	}: IGroupCellProps) => {
		const theme = useTheme()

		const start = dayjs(startDate)
		const end = dayjs(endDate)
		const startDayOfYear = allDay
			? dayjs(startDate).utc().dayOfYear()
			: dayjs(startDate).dayOfYear()
		const endDayOfYear = allDay
			? dayjs(endDate).utc().dayOfYear() - 1
			: dayjs(endDate).dayOfYear()
		const isMultiDay = startDayOfYear !== endDayOfYear
		const timeTitle = (() => {
			if (allDay) return 'All Day'
			if (isMultiDay)
				return `${start.format('h:mm A, M/D')} - ${end.format('h:mm A, M/D')}`
			return `${start.format('h:mm A')} - ${end.format('h:mm A')}`
		})()

		return (
			<div
				style={{
					display: 'flex',
					marginBottom: theme.spacing(1),
					alignItems: 'center',
				}}
				onClick={() => onEventPressed()}
			>
				<div
					style={{
						backgroundColor: color || theme.palette.primary.main,
						width: 65,
						height: 65,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: theme.shape.borderRadius,
					}}
				>
					{icon && (
						<IconTypeDisplay
							style={{ color: 'white' }}
							size={30}
							type={icon as IconType}
						/>
					)}
				</div>

				<div
					style={{
						flex: 1,
						padding: `0px ${theme.spacing(1)}px`,
					}}
				>
					<Typography>
						<b>{title}</b>
						{isMultiDay && (
							<span style={{ color: theme.palette.grey[500] }}>
								{' '}
								(multi-day)
							</span>
						)}
					</Typography>
					<Typography color='textSecondary'>{groupName}</Typography>
					<Typography color='textSecondary'>{timeTitle}</Typography>
				</div>
			</div>
		)
	}
)

interface IEventDetailProps {
	selectedEvent: IEventsListProps['events'][number]
	onClosePressed: () => void
}
const EventDetail = ({ selectedEvent, onClosePressed }: IEventDetailProps) => {
	const theme = useTheme()

	const {
		startDate,
		endDate,
		allDay,
		calendarDisplay,
		title,
		coverImage,
		information,
		buttons,
	} = selectedEvent

	const start = dayjs(startDate)
	const end = dayjs(endDate)
	const startDayOfYear = selectedEvent.allDay
		? dayjs(selectedEvent.startDate).utc().dayOfYear()
		: dayjs(selectedEvent.startDate).dayOfYear()
	const endDayOfYear = selectedEvent.allDay
		? dayjs(selectedEvent.endDate).utc().dayOfYear() - 1
		: dayjs(selectedEvent.endDate).dayOfYear()
	const isMultiDay = startDayOfYear !== endDayOfYear
	const timeTitle = (() => {
		if (isMultiDay) {
			if (selectedEvent.allDay) {
				return `${dayjs()
					.dayOfYear(startDayOfYear)
					.format(`dddd, MMMM D`)} - ${end
					.dayOfYear(endDayOfYear)
					.format(`dddd, MMMM D`)}`
			} else {
				return `${start.format(`dddd, MMMM D @ h:mm A`)} - ${end.format(
					`dddd, MMMM D @h:mm A`
				)}`
			}
		}
		if (allDay)
			return `All Day ${dayjs()
				.dayOfYear(startDayOfYear)
				.format('dddd, MMMM D')}`
		return `${start.format('dddd, MMMM D @ h:mm A')} - ${end.format('h:mm A')}`
	})()

	const notInitialRender = useRef(false)
	const location = useLocation()
	useEffect(() => {
		const homePressed = location.pathname.split('/').pop() === 'calendar'
		if (notInitialRender.current && homePressed) onClosePressed()
		notInitialRender.current = true
	}, [location])

	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				paddingBottom: 55,
				backgroundColor: 'white',
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
				overflow: 'hidden',
			}}
		>
			<SchoolNavBar
				style={{
					padding: `0px ${theme.spacing(1)}px`,
					justifyContent: 'flex-start',
				}}
			>
				<div
					style={{ minWidth: 100, display: 'flex', alignItems: 'center' }}
					onClick={onClosePressed}
				>
					<ArrowBackIos />
					<span>Back</span>
				</div>
			</SchoolNavBar>

			<div
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					minHeight: 0,
					overflowY: 'auto',
				}}
			>
				{coverImage ? (
					<ImageDisplay src={coverImage.temporaryDownloadUrl} />
				) : (
					<div
						style={{
							display: 'flex',
							width: '100%',
							height: 150,
							minHeight: 150,
							backgroundColor:
								calendarDisplay?.iconBackgroundColor ||
								theme.palette.primary.main,
							color: 'white',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{calendarDisplay?.icon && (
							<IconTypeDisplay
								size={75}
								type={calendarDisplay.icon as IconType}
							/>
						)}
					</div>
				)}

				<div style={{ padding: theme.spacing(1) }}>
					<Typography variant='h5' gutterBottom>
						<b>{title}</b>
					</Typography>

					<Typography
						gutterBottom
						color='textSecondary'
						style={{ display: 'flex', alignItems: 'flex-start' }}
					>
						<Schedule style={{ marginRight: theme.spacing(1) }} /> {timeTitle}
					</Typography>
					{selectedEvent.locationString && (
						<Typography
							gutterBottom
							color='textSecondary'
							style={{ display: 'flex', alignItems: 'flex-start' }}
						>
							<LocationOn style={{ marginRight: theme.spacing(1) }} />{' '}
							{selectedEvent.locationString}
						</Typography>
					)}
					<div style={{ display: 'flex', alignItems: 'flex-start' }}>
						<Today
							style={{
								color: theme.palette.grey[600],
								marginRight: theme.spacing(1),
							}}
						/>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							{selectedEvent.calendarDisplay?.calendars?.map(calendar => (
								<div
									key={calendar?.title}
									style={{
										backgroundColor:
											calendar?.iconBackgroundColor ||
											theme.palette.primary.main,
										color: calendar?.iconBackgroundColor
											? getContrastTextColor(calendar.iconBackgroundColor)
											: theme.palette.primary.contrastText,
										borderRadius: theme.shape.borderRadius,
										padding: `0px ${theme.spacing(1)}px`,
										marginRight: theme.spacing(0.5),
										marginBottom: theme.spacing(0.5),
									}}
								>
									<Typography variant='caption'>
										<b>{calendar?.title}</b>
									</Typography>
								</div>
							))}
						</div>
					</div>

					{information && (
						<div
							style={{ margin: `${theme.spacing(2)}px 0px` }}
							dangerouslySetInnerHTML={{ __html: information }}
						/>
					)}

					{buttons?.buttons?.map(o => (
						<LinkButton button={o} />
					))}
				</div>
			</div>
		</div>
	)
}

const gatherEventsByDayInWeek = ({
	events,
	day,
}: {
	events: EventFeedFragment[]
	day: Dayjs
}) => {
	const DATE_FORMAT = 'MMDDYYYY'

	const monday: IEventsByDay = {
		day: day.startOf('isoWeek'),
		events: [],
	}
	const tuesday: IEventsByDay = {
		day: day.startOf('isoWeek').add(1, 'day'),
		events: [],
	}
	const wednesday: IEventsByDay = {
		day: day.startOf('isoWeek').add(2, 'day'),
		events: [],
	}
	const thursday: IEventsByDay = {
		day: day.startOf('isoWeek').add(3, 'day'),
		events: [],
	}
	const friday: IEventsByDay = {
		day: day.startOf('isoWeek').add(4, 'day'),
		events: [],
	}
	const saturday: IEventsByDay = {
		day: day.startOf('isoWeek').add(5, 'day'),
		events: [],
	}
	const sunday: IEventsByDay = {
		day: day.startOf('isoWeek').add(6, 'day'),
		events: [],
	}

	const addEventToDay = (
		event: IEventsListProps['events'][number],
		date: string
	) => {
		switch (date) {
			case sunday.day.format(DATE_FORMAT):
				sunday.events.push(event)
				break
			case monday.day.format(DATE_FORMAT):
				monday.events.push(event)
				break
			case tuesday.day.format(DATE_FORMAT):
				tuesday.events.push(event)
				break
			case wednesday.day.format(DATE_FORMAT):
				wednesday.events.push(event)
				break
			case thursday.day.format(DATE_FORMAT):
				thursday.events.push(event)
				break
			case friday.day.format(DATE_FORMAT):
				friday.events.push(event)
				break
			case saturday.day.format(DATE_FORMAT):
				saturday.events.push(event)
				break
		}
	}

	events?.forEach(event => {
		const firstDayOfWeek = event.allDay
			? day.startOf('isoWeek').utc()
			: day.startOf('isoWeek')
		const lastDayOfWeek = event.allDay
			? day.startOf('isoWeek').utc().add(6, 'day')
			: day.startOf('isoWeek').add(6, 'day')

		const eventStartDay = event.allDay
			? dayjs(event.startDate).utc().startOf('d')
			: dayjs(event.startDate).startOf('d')
		const eventEndDay = event.allDay
			? dayjs(event.endDate).utc().subtract(1, 'd').startOf('d')
			: dayjs(event.endDate).startOf('d')

		// Hack: filter out events that don't fall within this week (necessary since we're using the same Postgres function for the admin week that starts on Sunday and the mobile week that starts on Monday)
		/* 
            - Events that start before the beginning of the week and end any time after the start of the week
            - Events that start after the beginning but before the end of the week and end any time
        */
		let displayEventInWeek = false
		if (
			eventStartDay.isSameOrBefore(firstDayOfWeek, 'd') &&
			eventEndDay.isSameOrAfter(firstDayOfWeek, 'd')
		)
			displayEventInWeek = true
		if (
			eventStartDay.isSameOrAfter(firstDayOfWeek, 'd') &&
			eventStartDay.isSameOrBefore(lastDayOfWeek, 'd')
		)
			displayEventInWeek = true

		if (displayEventInWeek) {
			/* 
                 If the event spans multiple days, need to display it on each day.
                 However...need to handle all-day events carefully...
                 Google sends the end date as midnight on the day after, so technically it would be counted as spanning multiple days with this simple method.
                 To complicate things further...Google sends normal start/end times adjusted to UTC (we ask it to in the events list query).
                 However...it doesn't apply this adjustment to all day event start/end times. They're returned as pure dates with no time, so when we save them as datetimes they get implicitly offset by however much the calendar's UTC offset is.
                 To account for this, we need to treat all day events as if they're stored in UTC, not the current time zone.
                 
                 To accomplish this, we need to parse all-day events' start/end times in UTC instead of local time for the school.
 
                 GOTCHA: we have to deduct one day from all-day events since Google returns the endDate as midnight on the following day.
                 GOTCHA 2: the spannedDayCount needs to be the days the event spans in THIS week (matters when an all-day event spans from last week into this week)
            */

			// What is the first dayOfTheYear of this event that falls in this week?
			// If the event starts before this week starts then the "first day" of the event that falls in this week is the first day of the week.
			const firstDayEventSpansInWeek = eventStartDay.isBefore(
				firstDayOfWeek,
				'd'
			)
				? firstDayOfWeek
				: eventStartDay

			// The last day of the event in this week
			const lastDayEventSpansInWeek = eventEndDay.isAfter(lastDayOfWeek, 'd') // Does this event last longer than the week?
				? lastDayOfWeek // If so, the last day of the week is the last day this event will show up in
				: eventEndDay // If not, the last day of the event is the last day it shows in the week

			// Add the event at least to the first day in the week it spans:
			addEventToDay(event, firstDayEventSpansInWeek.format(DATE_FORMAT))

			// How many more days in this week does the event span?
			// Subtract the first dayOfYear the event shows up in for this week from the last dayOfYear to see how many days in this week the event spans
			const spannedDayCount = lastDayEventSpansInWeek.diff(
				firstDayEventSpansInWeek,
				'd'
			)

			// If this event spans more days, add it to each day.
			// We loop through 7 days in the week, and for each dayOfTheYear in the week that this event spans, add it to the week.
			if (spannedDayCount !== 0) {
				for (let i = 0; i <= 6; i++) {
					const loopingThroughTheWeekDay = firstDayOfWeek.add(i, 'd')

					// Skip adding to the first day in the week the event spans since we do that above.
					// We add the first day outside this loop so we can do the spannedDayCount !== 0 check and avoid looping 7 times per event if it doesn't span more than one day. Probably an over optimization...
					if (firstDayEventSpansInWeek.isSame(loopingThroughTheWeekDay, 'd'))
						continue

					if (
						// Does the event start on or before this day?
						firstDayEventSpansInWeek.isSameOrBefore(
							loopingThroughTheWeekDay,
							'd'
						) &&
						// Does the event end on or after this day?
						lastDayEventSpansInWeek.isSameOrAfter(loopingThroughTheWeekDay, 'd')
					) {
						addEventToDay(event, loopingThroughTheWeekDay.format(DATE_FORMAT))
					}
				}
			}
		}
	})

	return [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
}
