import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	createStyles,
	makeStyles,
	useTheme,
} from '@material-ui/core'
import {
	Add,
	AttachmentOutlined,
	RemoveCircleOutline,
} from '@material-ui/icons'
import { FieldArray, FieldMetaProps, useField } from 'formik'
import { differenceBy } from 'lodash'
import { CSSProperties, useRef } from 'react'
import { v4 } from 'uuid'
import { array, mixed, object } from 'yup'
import { FileFragment } from '../../graphql/autogenerate/operations'
import {
	ACCEPTED_FILE_TYPES,
	IFileFieldValueHandlerOptions,
	ITempUploadedFile,
	getFileSizeInMb,
	getFileTypeForFile,
	isFileFragment,
	useFileFieldValueHandler,
} from './formik-file-helpers'
import { FormikTextInput } from './formik-text-input'
import { IField } from './interfaces'

const MAXIMUM_FILE_SIZE_MB = 25

const attachmentSchema = object({
	file: mixed<File>()
		.nullable()
		.test('fileType', 'File type is not supported', file => {
			if (!file) return true
			const filetype = getFileTypeForFile(file)
			if (!filetype) return false
			return true
		})
		.test(
			'fileSize',
			`File exceeds maximum allowed size (${MAXIMUM_FILE_SIZE_MB}Mb)`,
			file => {
				if (!file) return true
				return getFileSizeInMb(file) <= MAXIMUM_FILE_SIZE_MB
			}
		),
})

export const attachmentsSchema = array().of(attachmentSchema)

const useStyles = makeStyles(theme =>
	createStyles({
		deleteButton: {
			color: theme.palette.grey[500],
			cursor: 'pointer',
			marginLeft: theme.spacing(1),
			'&:hover': {
				color: theme.palette.error.main,
			},
		},
	})
)

interface IFormikAttachmentsField {
	field: IField
	style?: CSSProperties
}

export type AttachmentFieldValue =
	| FileFragment
	| {
			id: string
			file: File
			label: string
	  }

export const FormikAttachmentsField = ({
	field,
	style,
}: IFormikAttachmentsField) => {
	const theme = useTheme()
	const styles = useStyles()

	const [formikField, meta, helpers] = useField<
		AttachmentFieldValue[] | undefined
	>(field)

	const fileFieldRef = useRef<HTMLInputElement>(null)

	return (
		<FormControl error={meta.touched && Boolean(meta.error)} style={style}>
			<FormLabel style={{ marginBottom: theme.spacing(1) }}>
				{field.label}
			</FormLabel>
			<FieldArray
				{...formikField}
				children={arrayHelpers => {
					return (
						<>
							<div>
								{formikField.value?.map((file, index) => (
									<div
										key={file.id}
										style={{
											marginBottom: theme.spacing(1),
											padding: theme.spacing(1),
											border: `1px solid ${theme.palette.grey[300]}`,
											borderRadius: theme.shape.borderRadius,
										}}
									>
										<div id={file.id}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												{isFileFragment(file) ? (
													<div
														style={{
															flex: 1,
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<AttachmentOutlined
															style={{
																color: theme.palette.grey[600],
																marginRight: theme.spacing(1),
															}}
														/>
														{file.label || file.filename} (
														<a
															href={file.temporaryDownloadUrl}
															target='_blank'
															rel='noreferrer'
														>
															{file.type}
														</a>
														)
													</div>
												) : (
													<FormikTextInput
														variant='filled'
														style={{
															marginLeft: theme.spacing(0.5),
															flex: 1,
														}}
														noMargin
														hideHelperTextWhenEmpty
														fieldProps={{
															name: `${field.name}.${index}.label`,
															label: 'Attachment name',
														}}
													/>
												)}

												<RemoveCircleOutline
													className={styles.deleteButton}
													onClick={() => arrayHelpers.remove(index)}
												/>
											</div>
										</div>
										<FormHelperText>
											{getErrorForAttachment({ meta, index, field: 'file' })}
										</FormHelperText>
									</div>
								))}
							</div>

							<Button
								variant='text'
								startIcon={<Add />}
								onClick={() => {
									helpers.setTouched(true)
									if (fileFieldRef.current) fileFieldRef.current.click()
								}}
								style={{ marginBottom: theme.spacing(1) }}
							>
								Add an Attachment
							</Button>

							<input
								ref={fileFieldRef}
								type='file'
								style={{ display: 'none' }}
								multiple
								accept={ACCEPTED_FILE_TYPES.join(',')}
								onChange={e => {
									Array.from(e.currentTarget.files || []).forEach(file =>
										arrayHelpers.push({ id: v4(), file, label: file.name })
									)
								}}
							/>
						</>
					)
				}}
			/>
			<FormHelperText>
				{meta.touched && typeof meta.error === 'string' && meta.error}
			</FormHelperText>
		</FormControl>
	)
}

const getErrorForAttachment = ({
	meta,
	index,
	field,
}: {
	meta: FieldMetaProps<AttachmentFieldValue[] | undefined>
	index: number
	field: string
}) => {
	if (!meta.touched) return
	if (!meta.error) return
	if (!Array.isArray(meta.error)) return
	const errors = meta.error[index]
	if (!errors) return
	return errors[field]
}

export const useAttachmentFieldValueHandler = () => {
	const fileFieldValueHandler = useFileFieldValueHandler()

	return async ({
		initialAttachmentFiles,
		submittedAttachments,
	}: {
		initialAttachmentFiles?: FileFragment[]
		submittedAttachments: AttachmentFieldValue[]
	}) => {
		const removedAttachments = differenceBy(
			initialAttachmentFiles || [],
			submittedAttachments,
			'id'
		).map<IFileFieldValueHandlerOptions>(oldValue => ({
			oldValue,
			newValue: null,
		}))

		const processedAttachments = await Promise.all(
			submittedAttachments
				.map<IFileFieldValueHandlerOptions>(attachment => ({
					oldValue: initialAttachmentFiles?.find(
						original => original?.id === attachment.id
					),
					newValue: attachment,
				}))
				.concat(removedAttachments)
				.map(fileFieldValueHandler)
		)

		const addedAttachments = processedAttachments.reduce<ITempUploadedFile[]>(
			(all, attachment) => {
				if (attachment.results === 'added' || attachment.results === 'replaced')
					all.push(attachment.uploadedFile)
				return all
			},
			[]
		)

		return { addedAttachments, removedAttachments }
	}
}
