import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { LibraryBooksOutlined, LinkOutlined } from '@material-ui/icons'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { ResourceFragmentFragment } from '../graphql/autogenerate/operations'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { useCommonStyles } from '../styles'
import clsx from 'clsx'
import { ImageDisplay } from './image-display'
import { convertRtfToPlainText } from '../helpers'
import { useSchoolContext } from '../stores/school'

const useStyles = makeStyles(theme =>
    createStyles({
        cell: {
            display: 'grid',
            gridTemplateRows: 100 + theme.spacing(1),
            gridTemplateColumns: `${100 + theme.spacing(1)}px 1fr`,
            cursor: 'pointer',
            color: 'black',
            position: 'relative'
        },
        iconContainer: {
            gridArea: '1 / 1 / 2 / 2',
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        iconContainerEmpty: {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[ 200 ]
        },
        svgIcon: {
            height: '80%',
            width: '80%',
            color: theme.palette.grey[ 700 ],
        },
        resourceTypeIndicatorContainer: {

        },
        resourceTypeIndicator: {
            position: 'absolute',
            top: 0,
            right: 0,
            color: theme.palette.grey[ 700 ],
            borderRadius: theme.shape.borderRadius,
            height: 20,
            width: 20,
            margin: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            gridArea: '1 / 2 / 2 / 3',
            overflow: 'hidden',
            padding: `${theme.spacing(.5)}px 0px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        body: {
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            flex: 1,
            color: theme.palette.grey[ 700 ],
        },
    })
)

interface IResourceListItemProps {
    resourceItem: ResourceFragmentFragment
    onClick?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>[ 'onClick' ]
}

export const ResourceListItem = ({ resourceItem, onClick }: IResourceListItemProps) => {
    const styles = useStyles()
    const commonStyles = useCommonStyles()
    const { state: { school: { slug } } } = useSchoolContext()

    const thumbnailUrl = resourceItem.thumbnailImage?.temporaryDownloadUrl || resourceItem.resourceByParentResource?.defaultThumbnailImage?.temporaryDownloadUrl

    const cell = (
        <div
            id={resourceItem.id}
            className={clsx(styles.cell, commonStyles.greyBackgroundHover, 'resource-list-item')}
            onClick={(ev) => {
                gtag('event', 'resource_viewed', {
                    event_category: slug,
                    event_label: resourceItem.title,
                })
                onClick && onClick(ev)
            }}
        >
            {(resourceItem.type === ResourceType.InternalLink || resourceItem.type === ResourceType.Link)
                && <LinkOutlined className={styles.resourceTypeIndicator} />
            }
            {(resourceItem.type === ResourceType.Page)
                && <LibraryBooksOutlined className={styles.resourceTypeIndicator} />
            }

            <div className={clsx(styles.iconContainer)}>
                {thumbnailUrl ?
                    <ImageDisplay src={thumbnailUrl} alt='thumbnail' />
                    :
                    <>
                        {(resourceItem.type === ResourceType.InternalLink || resourceItem.type === ResourceType.Link) &&
                            <LinkOutlined className={styles.svgIcon} />
                        }
                        {(resourceItem.type === ResourceType.Page) &&
                            <LibraryBooksOutlined className={styles.svgIcon} />
                        }
                    </>
                }
            </div>
            <div className={styles.content} >
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}>
                    <b>{resourceItem.title}</b>
                </div>
                {resourceItem.body && <div className={styles.body} >{convertRtfToPlainText(resourceItem.body)}</div>}
            </div>
        </div>
    )

    if (resourceItem.url && !onClick) return <a href={resourceItem.url} target='_blank' >{cell}</a>

    return cell
}