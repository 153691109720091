import { Button, useTheme } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { Modal, useModal } from './modal'
import * as yup from 'yup'
import { FormikTextInput } from './forms'
import { useCallback, useState } from 'react'

interface IPasscodeModalProps {
    passcode: string
    passcodeHint?: string | null
    onCorrectPasscode: () => void
}

export const usePasscodeCheckModal = () => {
    const theme = useTheme()

    const [ state, setState ] = useState<IPasscodeModalProps>({
        passcode: '',
        onCorrectPasscode: () => null,
    })
    const { onCorrectPasscode, passcode, passcodeHint } = state

    const modalControls = useModal()

    const checkPasscode = useCallback((args: IPasscodeModalProps) => {
        setState(args)
        modalControls.open()
    }, [])


    return {
        checkPasscode,
        modal:
            <Formik
                initialValues={{ passcode: '' }}
                validationSchema={yup.object({
                    passcode: yup.string()
                        .test(
                            'passcode',
                            'Incorrect passcode.',
                            function (value) {
                                return value === passcode
                            }
                        )
                })}
                onSubmit={(_, actions) => {
                    onCorrectPasscode()
                    modalControls.close()
                    actions.resetForm()
                }}
            >
                {formikProps =>
                    <Form>
                        <Modal
                            {...modalControls.props}
                            title='Passcode Required'
                            dismissible
                            closeButton
                            size='sm'
                            actions={
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                                    <Button variant='text' style={{ minWidth: 100 }} onClick={modalControls.close} >Cancel</Button>
                                    <Button type='submit' style={{ minWidth: 100 }} variant='contained' color='primary' onClick={formikProps.submitForm}>Submit</Button>
                                </div>
                            }
                        >
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <FormikTextInput style={{ flex: 1 }} fieldProps={{ name: 'passcode', label: 'Passcode' }} />
                                </div>
                                {passcodeHint && <div style={{ padding: `0px ${theme.spacing(.5)}px` }}>Hint: <i>{passcodeHint}</i></div>}
                            </div>
                        </Modal>
                    </Form>
                }
            </Formik>
    }
}

// interface IPasscodeModalProps {
//     passcode: string
//     passcodeHint?: string
//     onCorrectPasscode: () => void
// }

// export const PasscodeModal = ({ passcode, passcodeHint, onCorrectPasscode }: IPasscodeModalProps) => {

//     const modal = useModal()

//     return (

//     )
// }