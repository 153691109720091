import { Box, Divider, Typography, Button } from '@material-ui/core'
import { GroupListFieldsFragment, SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { EditGroupCategories } from './edit-division-group-categories-button'
import { useModal } from './modal'
import { AdminGroupCategoryDetail } from './admin-group-category-detail'

interface IAdminDivisionDetailProps {
    schoolId: string
    division: SchoolDivisionsFragment
    refetch: () => any
    setGroupToEdit: (group: GroupListFieldsFragment) => void
}

export const AdminDivisionDetail = ({ division, refetch, setGroupToEdit }: IAdminDivisionDetailProps) => {
    const editGroupCategoriesModal = useModal()

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5'>Groups</Typography>
                <EditGroupCategories
                    modalControls={editGroupCategoriesModal}
                    refetch={refetch}
                    division={division}
                    groupCategories={division.groupCategories.nodes}
                />
            </Box>

            <Box paddingBottom={2} paddingTop={1}>
                <Divider />
            </Box>

            {division.groupCategories.nodes.length === 0 &&
                <Box mb={10} flex={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                    <Typography variant='h5'>No Group Categories created yet.</Typography>
                    <Box mt={3}>
                        <Button type='button' variant='outlined' color='primary' onClick={editGroupCategoriesModal.open}>Add Group Category</Button>
                    </Box>
                </Box>
            }

            <div style={{ overflow: 'auto' }}>
                {division.groupCategories.nodes.map(category => (
                    <AdminGroupCategoryDetail
                        key={category.id}
                        category={category}
                        setGroupToEdit={setGroupToEdit}
                    />
                ))}
            </div>
        </>
    )
}