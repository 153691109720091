import { useTheme } from '@material-ui/core'
import { useState } from 'react'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { useSchoolSponsorsQuery } from '../graphql/autogenerate/react-query'
import { SponsorType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useModal } from './modal'
import { SponsorFormModal } from './sponsor-form-modal'
import { SponsorsList } from './sponsors-list'

interface IState {
    initialSponsorType?: SponsorType
    sponsorToEdit?: SponsorFragment
}

export const AdminSponsors = () => {
    const theme = useTheme()

    const { state: { school: { id: schoolId, schoolSetting } } } = useSchoolContext()

    const sponsorFormModal = useModal()

    const { data, isFetched, refetch } = useHandleReactQuery(useSchoolSponsorsQuery({ schoolId }))

    const [ state, setState ] = useState<IState>({})

    if (!isFetched) return null

    const goldSponsorLabel = schoolSetting?.goldSponsorLabel || 'Gold'
    const silverSponsorLabel = schoolSetting?.silverSponsorLabel || 'Silver'
    const bronzeSponsorLabel = schoolSetting?.bronzeSponsorLabel || 'Bronze'

    return (
        <>
            <div style={{ margin: theme.spacing(2), flex: 1, minHeight: 0, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                <SponsorsList
                    title={`🥇 ${goldSponsorLabel}`}
                    emptyStateTitle={`No ${goldSponsorLabel} sponsors yet.`}
                    style={{ marginBottom: theme.spacing(2) }}
                    sponsors={data?.gold?.nodes}
                    onSponsorPressed={sponsor => {
                        setState(_state => ({ ..._state, sponsorToEdit: sponsor }))
                        sponsorFormModal.open()
                    }}
                    addSponsorPressed={() => {
                        setState(_state => ({ ..._state, initialSponsorType: SponsorType.Gold }))
                        sponsorFormModal.open()
                    }}
                />

                <SponsorsList
                    title={`🥈 ${silverSponsorLabel}`}
                    emptyStateTitle={`No ${silverSponsorLabel} sponsors yet.`}
                    style={{ marginBottom: theme.spacing(2) }}
                    sponsors={data?.silver?.nodes}
                    onSponsorPressed={sponsor => {
                        setState(_state => ({ ..._state, sponsorToEdit: sponsor }))
                        sponsorFormModal.open()
                    }}
                    addSponsorPressed={() => {
                        setState(_state => ({ ..._state, initialSponsorType: SponsorType.Silver }))
                        sponsorFormModal.open()
                    }}
                />

                <SponsorsList
                    title={`🥉 ${bronzeSponsorLabel}`}
                    emptyStateTitle={`No ${bronzeSponsorLabel} sponsors yet.`}
                    style={{ marginBottom: theme.spacing(2) }}
                    sponsors={data?.bronze?.nodes}
                    onSponsorPressed={sponsor => {
                        setState(_state => ({ ..._state, sponsorToEdit: sponsor }))
                        sponsorFormModal.open()
                    }}
                    addSponsorPressed={() => {
                        setState(_state => ({ ..._state, initialSponsorType: SponsorType.Bronze }))
                        sponsorFormModal.open()
                    }}
                />
            </div>

            <SponsorFormModal
                modalControls={sponsorFormModal}
                afterClose={() => {
                    setState(_state => ({ ..._state, sponsorToEdit: undefined, initialSponsorType: undefined }))
                    refetch()
                }}
                initialSponsorType={state.initialSponsorType}
                sponsorToEdit={state.sponsorToEdit}
            />
        </>
    )
}