import { Route, Routes } from 'react-router'
import { SchoolGroupCalendar } from './school-group-calendar'
import { SchoolGroupHome } from './school-group-home'
import { SchoolGroupNewsletters } from './school-group-newsletters'
import { SchoolGroupNotifications } from './school-group-notifications'
import { SchoolGroupResources } from './school-group-resources'
import { SchoolGroupsList } from './school-groups-list'

export const SchoolGroups = () => {
    return (
        <Routes>
            <Route path='*' element={<SchoolGroupsList />} />
            <Route path=':groupId/*' element={<SchoolGroupHome />} >
                <Route path='resources' element={<SchoolGroupResources />} />
                <Route path='calendar' element={<SchoolGroupCalendar />} />
                <Route path='newsletters' element={<SchoolGroupNewsletters />} />
                <Route path='notifications' element={<SchoolGroupNotifications />} />
            </Route>
        </Routes>
    )
}