import { Add } from '@material-ui/icons'
import { useGroupResourcesQuery, useUpdateResourceSortOrderMutation } from '../graphql/autogenerate/react-query'
import { useBoop, useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import { animated } from 'react-spring'
import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { EmptyState } from './empty-state'
import { useModal } from './modal'
import { GroupListFieldsFragment, ResourceFragmentFragment } from '../graphql/autogenerate/operations'
import { ResourceForm } from './resource-form'
import { ResourceTile } from './resource-tile'
import { useState } from 'react'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { Loading } from './loading'
import Sortable from 'sortablejs'
import { useRef } from 'react'
import { useCallback } from 'react'
import { EventEmitter } from 'stream'
import { TypedEvent } from '../helpers'
import { useEffect } from 'react'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { v4 } from 'uuid'
import { REFETCH_INTERVAL } from '../constants'

const useStyles = makeStyles(theme =>
    createStyles({
        addResourcesButton: {
            width: 300,
            height: 150,
            border: `1px dashed`,
            borderColor: theme.palette.grey[ 600 ],
            color: theme.palette.grey[ 600 ],
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            }
        }
    })
)

interface IGroupResourcesProps {
    group: GroupListFieldsFragment
    folderPressed: (openResourceFolder?: ResourceFragmentFragment) => void
    folderDeletedEmitter: TypedEvent<string>
    folderSavedEmitter: TypedEvent<string>
    color: string
}

interface IState {
    resourceToEdit?: ResourceFragmentFragment
}

export const GroupResources = ({ group, folderPressed, folderDeletedEmitter, folderSavedEmitter, color }: IGroupResourcesProps) => {
    const theme = useTheme()
    const styles = useStyles()

    const hoverBoop = useBoop({ rotation: 15, timing: 100, })
    const { data, refetch } = useHandleReactQuery(useGroupResourcesQuery({ groupId: group.id }, { refetchInterval: REFETCH_INTERVAL }))
    const { mutate: updateSortOrder } = useHandleReactQueryMutation(useUpdateResourceSortOrderMutation({
        onError: () => {
            if (sortable.current && data?.resources?.nodes) {
                sortable.current.sort([ ...data.resources.nodes.map(o => o.id), addResourceId.current ])
            }
        }
    }))

    const modal = useModal()

    const [ state, setState ] = useState<IState>()

    const { enqueueSnackbar } = useSnackbar()

    const sortable = useRef<Sortable>()
    const attemptedHeaderDrag = useRef<{ from?: boolean, to?: boolean }>()
    const listRef = useCallback((ref: HTMLDivElement | null) => {
        if (ref) {
            if (sortable.current) sortable.current.destroy()
            sortable.current = Sortable.create(ref, {
                onEnd: evt => {
                    if (attemptedHeaderDrag.current?.to) enqueueSnackbar('Cannot put a resource before the header resource.', { variant: 'info' })
                    if (attemptedHeaderDrag.current?.from) enqueueSnackbar('Cannot move the header resource from first position.', { variant: 'info' })
                    attemptedHeaderDrag.current = undefined
                    if (evt.oldIndex !== evt.newIndex && evt.newIndex !== undefined) updateSortOrder({ newSortOrder: evt.newIndex, resourceId: evt.item.id })
                },
                filter: '.disable-sortable',
                dataIdAttr: 'id',
                animation: 300,
                onMove: evt => {
                    attemptedHeaderDrag.current = undefined
                    // Don't allow dragging onto the "add resource" button (which doesn't have an ID)
                    if (evt.related.id === addResourceId.current) return false
                    if (data?.resources?.nodes.some(o => o.id === evt.related.id && o.header)) {
                        attemptedHeaderDrag.current = { to: true }
                        return false
                    }
                    if (evt.dragged.id !== evt.related.id && data?.resources?.nodes.some(o => o.header && o.id === evt.dragged.id)) {
                        attemptedHeaderDrag.current = { from: true }
                        return false
                    }
                    return true
                }
            })
        }
    }, [ data ])
    const addResourceId = useRef(v4())
    useEffect(() => {
        if (sortable.current && data?.resources?.nodes) {
            sortable.current.sort([ ...data.resources.nodes.map(o => o.id), addResourceId.current ])
        }
    }, [ data?.resources?.nodes ])

    useEffect(() => {
        // Listen for folder deleted notifications in case we need to refetch because it was one of the ones in this list.
        const onFolderDeleted = (resourceId: string) => {
            if (data?.resources?.nodes.some(o => o.id === resourceId)) refetch()
        }
        const { dispose } = folderDeletedEmitter.on(onFolderDeleted)

        return () => {
            dispose()
        }
    }, [ folderDeletedEmitter, data ])
    useEffect(() => {
        // Listen for folder saved notifications in case we need to refetch because it was one of the ones in this list.
        const onFolderSaved = (resourceId: string) => {
            if (data?.resources?.nodes.some(o => o.id === resourceId)) refetch()
        }
        const { dispose } = folderSavedEmitter.on(onFolderSaved)

        return () => {
            dispose()
        }
    }, [ folderSavedEmitter, data ])

    if (!data?.resources) return <Loading size='m' />

    return (
        <>
            {data.resources.totalCount ?
                <div ref={listRef} style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 300px)', gridAutoRows: 150, columnGap: theme.spacing(1), rowGap: theme.spacing(1), flex: 1 }}>
                    {data.resources.nodes.map(o =>
                        <ResourceTile
                            key={o.id}
                            resourceItem={o}
                            onClick={ev => {
                                ev.preventDefault()
                                if (o.type !== ResourceType.Folder) {
                                    setState(_state => ({ ..._state, resourceToEdit: o }))
                                    modal.open()
                                } else if (o.type === ResourceType.Folder) {
                                    folderPressed(o)
                                }
                            }}
                            color={color}
                        />)
                    }

                    <div
                        /* 
                            We need an ID on the Add Resource button so we can exclude it from SortableJS's .sort() method. 
                            Apparently the 'disable-sortable' filter only applies to sorting actions not inclusion in the sort array. 🙄 
                        */
                        id={addResourceId.current}
                        onMouseEnter={hoverBoop.trigger}
                        onClick={modal.open}
                        className={clsx(styles.addResourcesButton, 'disable-sortable')}
                    >
                        <animated.div style={hoverBoop.style}>
                            <Add />
                        </animated.div>
                    </div>
                </div>
                :
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', width: '100%' }}>
                    <EmptyState style={{ paddingBottom: theme.spacing(8) }} title={`No ${group.groupName} resources have been added yet.`} action={{ buttonTitle: 'Start Adding Resources', onClick: modal.open }} />
                </div>
            }

            <ResourceForm
                resourceToEdit={{
                    resource: state?.resourceToEdit,
                    setResourceToEdit: resourceToEdit => setState(_state => ({ ..._state, resourceToEdit }))
                }}
                modalControls={modal}
                groupId={group.id}
                onSave={refetch}
                onDelete={refetch}
                enableHeaderResourceToggle
                enableTileImageForResourceTypes={Object.values(ResourceType)}
            />
        </>
    )
}