import { Typography, useTheme, Box, Button } from '@material-ui/core'
import { useAppState, useAuth, useSetPageTitle } from '../hooks'

export const NoAccess = () => {
    const theme = useTheme()
    useSetPageTitle('No Access')
    const { state: { authed } } = useAppState()
    const { logout } = useAuth()

    return (
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' height='100%'>
            <Typography variant='h5'>
                It appears that you do not have access to this resource.
            </Typography>
            <Box marginY={2}>
                <Typography>
                    If you believe you've encountered this message in error, please contact customer support at <a href='mailto:support@legitapps.com' style={{ color: theme.palette.primary.main }}>support@legitapps.com</a>.
                </Typography>
            </Box>

            {authed && <Button variant='text' onClick={logout}>Logout</Button>}
        </Box>
    )
}