import { Typography, useTheme } from '@material-ui/core'
import { useRef, useEffect } from 'react'
import { useParams } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import { useSponsorBySlugQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { ImageDisplay } from './image-display'
import { LinkButton } from './link-button'
import { useMobileContext } from './school'

export const SchoolSponsorDetail = () => {
    const theme = useTheme()
    const { sponsorId } = useParams()

    const { data } = useHandleReactQuery(useSponsorBySlugQuery({ slug: sponsorId }, { refetchInterval: REFETCH_INTERVAL }))
    const sponsor = data?.sponsorBySlug

    const { setState: setMobileState, state } = useMobileContext()

    const previousTitle = useRef(state.navbar.title)
    useEffect(() => {
        setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, title: 'Sponsors', backUrl: 'sponsors' } }))

        return () => {
            setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, title: previousTitle.current, backUrl: undefined } }))
        }
    }, [])

    if (!sponsor) return null

    return (
        <div style={{ margin: theme.spacing(1), paddingBottom: 63 }}>
            <ImageDisplay src={sponsor.coverImage?.temporaryDownloadUrl} />

            <Typography gutterBottom style={{ fontWeight: 700 }} variant='h5'>{sponsor.title}</Typography>

            <div dangerouslySetInnerHTML={{ __html: sponsor.body || '' }} />

            <div style={{ marginTop: theme.spacing(2) }}>
                {sponsor.buttons?.buttons?.map(button => <LinkButton key={button.id} style={{ width: '100%' }} button={button} />)}
            </div>
        </div>
    )
}