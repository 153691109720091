import React, { useEffect } from 'react'
import { useKeyPressEvent, useScrolling } from 'react-use'
import { CalendarActionType, CalendarViewMode } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

interface ICalendarKeyboardShortcutsProps extends ICalendarDispatchProp {
    disableDateScrolling: boolean
    disableShortcuts: boolean
    viewMode: CalendarViewMode
}
const CalendarKeyboardShortcuts = ({ dispatch, disableShortcuts, disableDateScrolling, viewMode }: ICalendarKeyboardShortcutsProps) => {
    // useKeyPressEvent(
    //     (ev) => ev.code === 'KeyD' || ev.code === 'Digit1',
    //     null,
    //     () => !disableShortcuts && dispatch({ type: CalendarActionType.setViewMode, payload: 'day' })
    // )

    // useKeyPressEvent(
    //     (ev) => ev.code === 'KeyW' || ev.code === 'Digit2',
    //     null,
    //     () => !disableShortcuts && dispatch({ type: CalendarActionType.setViewMode, payload: 'week' })
    // )

    // useKeyPressEvent(
    //     (ev) => ev.code === 'KeyM' || ev.code === 'Digit3',
    //     null,
    //     () => !disableShortcuts && dispatch({ type: CalendarActionType.setViewMode, payload: 'month' })
    // )

    useKeyPressEvent(
        (ev) => ev.code === 'BracketLeft',
        null,
        () => !disableShortcuts && dispatch({ type: CalendarActionType.toggleSidebar })
    )

    // useKeyPressEvent(
    //     (ev) => ev.code === 'Escape',
    //     null,
    //     () => !disableShortcuts && alert('Back to event view - NOT IMPLEMENTED')
    // )

    useKeyPressEvent(
        (ev) => ev.code === 'ArrowRight' || ev.code === 'KeyN',
        null,
        () => !disableShortcuts && dispatch({ type: CalendarActionType.goToNext })
    )

    useKeyPressEvent(
        (ev) => ev.code === 'ArrowLeft' || ev.code === 'KeyP',
        null,
        () => !disableShortcuts && dispatch({ type: CalendarActionType.goToPrevious })
    )

    useKeyPressEvent(
        (ev) => ev.code === 'KeyT',
        null,
        () => !disableShortcuts && dispatch({ type: CalendarActionType.goToToday })
    )



    useEffect(() => {
        const scrollHandler = (ev: WheelEvent) => {
            if (disableShortcuts || disableDateScrolling) return
            const scrollUp = ev.deltaY < 0
            if (viewMode === 'month') {
                if (scrollUp) {
                    dispatch({ type: CalendarActionType.goToPrevious })
                } else {
                    dispatch({ type: CalendarActionType.goToNext })
                }
            } else if (ev.shiftKey) {
                if (scrollUp) {
                    dispatch({ type: CalendarActionType.goToPrevious })
                } else {
                    dispatch({ type: CalendarActionType.goToNext })
                }
            }
        }

        window.addEventListener('wheel', scrollHandler)

        return () => window.removeEventListener('wheel', scrollHandler)
    }, [ disableShortcuts, disableDateScrolling, viewMode ])

    return null
}

export const MemoizedCalendarKeyboardShortcuts = React.memo(CalendarKeyboardShortcuts)