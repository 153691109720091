import { ActualFileObject, FilePondServerConfigProps } from 'filepond'
import { FileFragment } from '../../graphql/autogenerate/operations'
import {
	useDeleteFileMutation,
	useDeleteTemporaryAwsS3UploadMutation,
	usePresignedUploadUrlMutation,
} from '../../graphql/autogenerate/react-query'
import { FileType, FileInput } from '../../graphql/autogenerate/schemas'
import axios from 'axios'
import { useHandleReactQueryMutation } from '../../hooks'
import Compressor from 'compressorjs'

export interface ITempUploadedFile {
	type: FileType
	filename: string
	label?: string
	sortOrder?: number
}

export type FileFieldValue = ITempUploadedFile | FileFragment | undefined
export const isFileFragment = (
	file: FileFieldValue | File | null | NewFileUpload
): file is FileFragment =>
	Boolean(file) && (file as FileFragment).temporaryDownloadUrl !== undefined
export const isTempUploadedFile = (
	file: FileFieldValue
): file is ITempUploadedFile =>
	Boolean(file) && (file as FileFragment).id === undefined

export const isSupportedFileType = (type: string): type is FileType => {
	if (
		(Object.values(FileType) as string[])
			.map(o => o.toLowerCase())
			.indexOf(type.toLowerCase()) === -1
	)
		return false
	return true
}

export const isValidImageFileType = (type: FileType) =>
	[
		FileType.Apng,
		FileType.Avif,
		FileType.Gif,
		FileType.Jpeg,
		FileType.Png,
		FileType.SvgXml,
		FileType.Webp,
	].includes(type)

export const getFileTypeForFile = (file: File | ActualFileObject) => {
	const type = file.type
	let fileType = type.split('/').pop()
	if (!fileType) return
	fileType = fileType.toUpperCase()
	if (fileType === 'SVG_XML') fileType = 'SVG+XML'
	if (!isSupportedFileType(fileType)) return
	return fileType
}

export const ACCEPTED_FILE_TYPES = [
	'image/apng',
	'image/avif',
	'image/gif',
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	'image/webp',
	'txt/*',
	'application/pdf',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.apple.pages',
	'application/vnd.apple.numbers',
	'application/vnd.apple.keynote',
]

export const getFileSizeInMb = (file: File | ActualFileObject) =>
	file.size / 1000 / 1000

/** 
	Convenience handler for forms that use a FilePond field.
	Simplifies checking whether a file existed and under what conditions it might need to be deleted by the parent form when it submits.
*/
export const useImageFieldChangeHandler = () => {
	const { mutateAsync: deleteFile } = useDeleteFileMutation()

	interface IHanderOptions {
		oldValue?: FileFragment | null
		newValue: FileFieldValue
		options?: Parameters<typeof deleteFile>[ 1 ]
		filePropertyName: string
	}
	interface IHandlerResults {
		[ key: string ]: { create?: FileInput } | string | null
	}

	/** 
		Handles any side effects arising from a change in a File field value.

		@param oldValue The original `FileFragment` value for the form.
		@param newValue The final value from the FormikImageField (could be unchanged from the original).
		@param options Default ReactQuery config options (if desired).
		@param filePropertyName The property name on the entity for the file being handled. NOTE: this is the proper file name, not the FK field name (YES: coverImage / NO: coverImageFileId)

		@description
		Checks a variety of cases to handle side effects:
		1) If there is no old value AND no new value, returns undefined
		2) If there is a new temp upload, returns a GraphQL `create` node value for the new `File`
		3) If there was an old `File`, checks a few different cases (see function implementation comments) to see if the old `File` needs to be deleted

		@returns A payload intended to be spread into the mutation query. It will include the proper values to either: 
		1) Preserve the current file ID if nothing changed
		2) Set the file ID to null if the file was removed
		3) Create a file record for the newly added file
	*/
	const handler = async ({
		oldValue,
		newValue,
		options,
		filePropertyName,
	}: IHanderOptions): Promise<IHandlerResults | undefined> => {
		// If there WAS a file then we need to check a few things to see whether the original needs to be deleted
		let fileNeedsDeleted = false
		if (oldValue) {
			// The old file is the same as the new file (no changes were made)
			if (isFileFragment(newValue) && oldValue.id === newValue.id)
				return { [ `${filePropertyName}FileId` ]: oldValue.id }

			// There is no file now - delete the original
			if (!newValue) fileNeedsDeleted = true

			// There still is a file, but it's a different existing File (currently not possible)
			if (isFileFragment(newValue) && newValue.id !== oldValue.id)
				fileNeedsDeleted = true

			// There still is a file, but it's a new upload
			if (isTempUploadedFile(newValue)) fileNeedsDeleted = true

			if (fileNeedsDeleted) await deleteFile({ id: oldValue.id }, options)
		}

		// If this is a fresh upload, include a create for it.
		let createQuery: { create?: FileInput } | undefined = undefined
		if (isTempUploadedFile(newValue))
			createQuery = {
				create: { filename: newValue.filename, type: newValue.type },
			}

		// If the file was deleted and no new file added, set the file ID to null
		if (fileNeedsDeleted && !createQuery)
			return { [ `${filePropertyName}FileId` ]: null }

		// If a new file was created, return the command to create a new file record and add it to this entity
		if (createQuery) return { [ filePropertyName ]: createQuery }
	}

	return handler
}

interface IUseFilePondServerConfigurationOptions {
	setLoading: (loading: boolean) => void
	setValue: (value?: FileFieldValue) => void
}

export const useFilePondServerConfiguration = ({
	setLoading,
	setValue,
}: IUseFilePondServerConfigurationOptions) => {
	const { mutateAsync: getPresignedUploadUrl } = useHandleReactQueryMutation(
		usePresignedUploadUrlMutation()
	)
	const { mutateAsync: deleteTemporaryUpload } =
		useDeleteTemporaryAwsS3UploadMutation()

	const server: FilePondServerConfigProps[ 'server' ] = {
		process: (fieldName, file, metadata, load, error, progress, abort) => {
			const filetype = getFileTypeForFile(file)
			if (!filetype) {
				error('Unable to determine file type.')
				setLoading(false)
				return
			}

			const source = axios.CancelToken.source()
			getPresignedUploadUrl(
				{ filetype },
				{
					onSuccess: async ({ presignedUploadUrl }) => {
						if (!presignedUploadUrl) {
							error('Error fetching an upload URL.')
							return
						}

						const uploadFile = (fileToUpload: File | ActualFileObject | Blob) => {
							try {
								axios
									.put(presignedUploadUrl.url, fileToUpload, {
										onUploadProgress: ev =>
											progress(ev.lengthComputable, ev.loaded, ev.total),
										cancelToken: source.token,
									})
									.then(response => {
										if (response.status >= 200 && response.status < 300) {
											setValue({
												filename: presignedUploadUrl.filename,
												type: filetype,
											})

											// the load method accepts either a string (id) or an object
											load(presignedUploadUrl.filename)
										} else {
											// Can call the error method if something is wrong, should exit after
											error(`Error uploading file.`)
										}
									})
							} catch (e: any) {
								error(`Error uploading file: ${e.message}`)
							}

							setLoading(false)
						}

						if (isValidImageFileType(filetype)) {
							compressFile(file).then(uploadFile)
						} else {
							uploadFile(file)
						}
					},
					onError: () => {
						error(`Error fetching upload URL.`)
						setLoading(false)
					},
				}
			)

			return {
				abort: () => {
					// This function is entered if the user has tapped the cancel button
					source.cancel('Upload was canceled by FilePond.')

					setLoading(false)

					// Let FilePond know the request has been cancelled
					abort()
				},
			}
		},
		revert: (filename, load, error) => {
			deleteTemporaryUpload(
				{ filename },
				{
					onSuccess: () => {
						console.log('removin dis one', filename)
						setValue(undefined)
						load()
					},
					onError: err => {
						error('Error deleting file.')
					},
				}
			)
		},
	}

	return server
}

const compressFile = async (
	file: File | ActualFileObject,
	options?: Omit<Compressor.Options, 'success' | 'error'>
) => {
	return await new Promise<File | Blob>((resolve, reject) => {
		new Compressor(file, {
			quality: 0.6,
			// Compress anything 1Mb or larger (in Kb)
			convertSize: 1000000,
			success: resolve,
			error: e => reject(`Error uploading file: ${e.message}`),
			...options,
		})
	})
}


const useUploadFile = () => {
	const { mutateAsync: getPresignedUploadUrl } = useHandleReactQueryMutation(
		usePresignedUploadUrlMutation()
	)

	return async ({
		file,
		onUploadProgress,
		enableImageCompression = true,
	}: {
		file: File | ActualFileObject
		onUploadProgress?: (
			isLengthComputable: boolean,
			loadedDataAmount: number,
			totalDataAmount: number
		) => void
		/** Defaults to `true` */
		enableImageCompression?: boolean
	}): Promise<ITempUploadedFile> => {
		// Validate file type
		const filetype = getFileTypeForFile(file)
		if (!filetype) throw new Error('Unable to determine file type.')

		// Compress the file if it's an image
		let fileToUpload: File | Blob = file
		if (isValidImageFileType(filetype) && enableImageCompression) {
			fileToUpload = await compressFile(file)
		}

		const { presignedUploadUrl } = await getPresignedUploadUrl({ filetype })
		if (!presignedUploadUrl) throw new Error('Error fetching an upload URL.')

		// Upload the file
		const source = axios.CancelToken.source()
		const uploadResponse = await axios.put(presignedUploadUrl.url, fileToUpload, {
			onUploadProgress: ev =>
				onUploadProgress &&
				onUploadProgress(ev.lengthComputable, ev.loaded, ev.total),
			cancelToken: source.token,
		})

		// Return an error if the upload failed (axios doesn't throw by default)
		if (uploadResponse.status < 200 || uploadResponse.status >= 300)
			throw new Error('Error uploading file.')

		return {
			filename: presignedUploadUrl.filename,
			type: filetype,
		}
	}
}

const isNewFile = (
	val: FileFragment | NewFileUpload | null
): val is NewFileUpload =>
	Boolean(val) && (val as NewFileUpload).file !== undefined

type NewFileUpload = {
	file: File
	label?: string
}

export interface IFileFieldValueHandlerOptions {
	/** The original `FileFragment` value for the form. */
	oldValue?: FileFragment | null
	/** The final value from the FormikImageField (could be unchanged from the original). */
	newValue: FileFragment | NewFileUpload | null
}
type FileFieldValueHandlerResults =
	| {
		results: 'removed' | 'unchanged'
	}
	| {
		results: 'added' | 'replaced'
		uploadedFile: ITempUploadedFile
	}

/** 
	Convenience handler for file fields in forms.
	Simplifies checking whether a file existed and under what conditions it might 
	need to be uploaded, deleted, or left alone when the form is submitted.
*/
export const useFileFieldValueHandler = () => {
	const { mutateAsync: deleteFile } = useDeleteFileMutation()
	const uploadFile = useUploadFile()

	/** 
		Handles upload/delete side effects arising from a change in a File field value.
	*/
	const handler = async ({
		oldValue,
		newValue,
	}: IFileFieldValueHandlerOptions): Promise<FileFieldValueHandlerResults> => {
		/*
			Scenarios:
			no file -> no file      >> unchanged
			no file -> new file     >> uploaded

			old file -> old file    >> unchanged
			old file -> no file     >> removed
			old file -> new file    >> replaced

			new file -> old file    >> UNSUPPORTED
			new file -> no file     >> UNSUPPORTED
			new file -> new file    >> UNSUPPORTED
		*/

		// no file -> no file
		if (!oldValue && !newValue) return { results: 'unchanged' }

		// no file -> new file
		if (!oldValue && isNewFile(newValue)) {
			const uploadedFile = await uploadFile({ file: newValue.file })
			return {
				results: 'added',
				uploadedFile: { ...uploadedFile, label: newValue.label },
			}
		}

		// old file -> old file
		if (
			isFileFragment(oldValue) &&
			isFileFragment(newValue) &&
			oldValue.id === newValue.id
		)
			return { results: 'unchanged' }

		// old file -> no file
		if (oldValue && !newValue) {
			await deleteFile({ id: oldValue.id })
			return { results: 'removed' }
		}

		// old file -> new file
		if (oldValue && isNewFile(newValue)) {
			await deleteFile({ id: oldValue.id })
			const uploadedFile = await uploadFile({ file: newValue.file })
			return {
				results: 'replaced',
				uploadedFile: { ...uploadedFile, label: newValue.label }
			}
		}

		console.log({ oldValue, newValue })

		throw new Error('Unsupported file change condition encountered')
	}

	return handler
}
