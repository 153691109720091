import dayjs, { Dayjs } from 'dayjs'
import { difference } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ICalendarState, CalendarAction } from '.'
import { REFETCH_INTERVAL } from '../../constants'
import { useEventsForWeekQuery } from '../../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../../hooks'
import { useSchoolContext } from '../school'
import { CalendarActionType, CalendarViewMode, ICalendarEventCell } from './definitions'

interface IUseCalendarQuery {
    state: ICalendarState
    dispatch: React.Dispatch<CalendarAction>
}

/**
    __This hook is only meant to be used within the CalendarContext Provider.__

    It handles...
    - ✅ Initial, on mount, query to fetch events
    - ✅ Querying when the selected date changes
    - ✅ Querying when the list of visible calendars changes
    - Canceling in progress queries whenever the selected date or view mode changes
    
    It also prepares events for display:
    - Splitting up non-allDay events that span multiple days into individual events for each day
    - Dispatches new events into the calendar reducer
*/
export const useCalendarQuery = ({
    state: { visibleCalendars, selectedDate, viewMode },
    dispatch,
}: IUseCalendarQuery) => {
    if (viewMode !== 'week') throw new Error(`'${viewMode}' view mode not yet supported. Only 'week' view mode is currently supported.`)

    const { state: { groups } } = useSchoolContext()
    const schoolCalendarIds = useMemo(() => {
        return groups.reduce<string[]>((calendarIds, group) => ([ ...calendarIds, ...group.calendarIds ]), [])
    }, [ groups ])

    const previousVisibleCalendarIds = useRef<string[]>([])
    const previousSelectedDate = useRef(selectedDate)


    const [eventsQueryParameters, setEventsQueryParameters] = useState<Parameters<typeof useEventsForWeekQuery>[0]>()
    const fetchEvents = async ({ calendarIds, date }: { calendarIds: string[], date: Dayjs }) => {
        // Only query for this school's calendars - we match the Postgres server's definition of a "week"
        setEventsQueryParameters({ calendarIds: calendarIds.filter(o => schoolCalendarIds.includes(o)), date: selectedDate.startOf('week').add(1, 'day').toISOString() })

        previousVisibleCalendarIds.current = calendarIds
        previousSelectedDate.current = date
    }
    const eventsQuery = useHandleReactQuery(useEventsForWeekQuery(eventsQueryParameters!, { refetchInterval: REFETCH_INTERVAL, enabled: Boolean(eventsQueryParameters) }))

    // Initial fetch
    useEffect(() => {
        const calendarIds = getVisibleCalendarIds({ visibleCalendars })
        fetchEvents({ calendarIds, date: selectedDate })
    }, [])

    useEffect(() => {
        const calendarIds = getVisibleCalendarIds({ visibleCalendars })

        // Only fetch if there's a change in visible calendars or the selected date.
        if (
            !previousSelectedDate.current.isSame(selectedDate) ||
            difference(previousVisibleCalendarIds.current, calendarIds).length > 0 ||
            difference(calendarIds, previousVisibleCalendarIds.current).length > 0
        ) {
            fetchEvents({ calendarIds, date: selectedDate })
        }

        previousSelectedDate.current = selectedDate
    }, [ visibleCalendars, selectedDate, visibleCalendars ])

    // Handle new event data
    useEffect(() => {
        const splitEvents = eventsQuery.data?.getCalendarEventsForWeek?.nodes
            // First we map the events to event cells, filling in their group info
            .map<ICalendarEventCell>(event => {
                const calendarIds = event.calendarEvents.nodes.map(c => c.calendarId)
                const eventGroups = groups.filter(o => o.calendarIds.some(id => calendarIds.includes(id)))

                if (!eventGroups) throw new Error('useCalendarQuery - error mapping events to groups. Found an event without a matching group.')

                return {
                    ...event,
                    cellId: event.id,
                    startDate: event.allDay ? dayjs(event.startDate).utc() : dayjs(event.startDate),
                    endDate: event.allDay ? dayjs(event.endDate).utc() : dayjs(event.endDate),
                    groups: eventGroups,
                }
            })
            // Then we need to split up non-allDay events that span multiple days..
            .reduce<ICalendarEventCell[]>((events, event) => {
                const _split: ICalendarEventCell[] = []

                // We don't need any special handling for allDay events.
                if (event.allDay) {
                    _split.push(event)
                }
                // Check if the event spans multiple days
                else if (!event.startDate.startOf('d').isSame(event.endDate.startOf('d'))) {
                    // The event spans multiple days, split up the event into multiple "event parts" that are contained within a day.

                    /* 
                        For each event we start by taking the start date and getting its end of day.
                        We create a clone of the event with its original start time, but a new end time of that end of first day.
                        Then, we loop while, for each loop, adding one more day to the "end".
                        For each of those loops we add another cloned event with the startdate being the start of the day and the enddate being the end of the day.
                        UNTIL
                        We get to the point that the next "end of day" is AFTER the event's original end date.
                        At that point we add one last event with a start at the beginning of the day and the end being the original end date.
                    */
                    let endOfDay = event.startDate.endOf('d')
                    let startOfDay = event.startDate.startOf('d')

                    let sectionCount = 0

                    // Add the first "event section"
                    _split.push({
                        ...event,
                        endDate: endOfDay,
                        cellId: `${event.id}-${sectionCount}`,
                        spansDays: {
                            labelStartDate: event.startDate,
                            labelEndDate: event.endDate,
                        }
                    })

                    endOfDay = endOfDay.add(1, 'd')
                    startOfDay = startOfDay.add(1, 'd')

                    while (event.endDate.isAfter(endOfDay)) {
                        sectionCount++

                        _split.push({
                            ...event,
                            startDate: startOfDay,
                            endDate: endOfDay,
                            cellId: `${event.id}-${sectionCount}`,
                            spansDays: {
                                labelStartDate: event.startDate,
                                labelEndDate: event.endDate,
                            }
                        })

                        endOfDay = endOfDay.add(1, 'd')
                        startOfDay = startOfDay.add(1, 'd')
                    }

                    sectionCount++

                    // Add the last "event section"
                    _split.push({
                        ...event,
                        startDate: startOfDay,
                        endDate: event.endDate,
                        cellId: `${event.id}-${sectionCount}`,
                        spansDays: {
                            labelStartDate: event.startDate,
                            labelEndDate: event.endDate,
                        }
                    })
                }
                else {
                    _split.push(event)
                }
                return events.concat(_split)
            }, [])

        // Only update the events if we get data. It seems that the data gets set to 'undefined' when the query is re-run and we don't want the events display to "flash" between queries.
        if (splitEvents) dispatch({ type: CalendarActionType.setEvents, payload: filterEventsToViewMode({ events: splitEvents, selectedDate, viewMode }) })
    }, [ eventsQuery.data ])

    return {
        eventsQuery
    }
}

const getVisibleCalendarIds = ({ visibleCalendars }: Pick<ICalendarState, 'visibleCalendars'>) =>
    Object.entries(visibleCalendars)
        .filter(([ _, visible ]) => visible)
        .map(([ calendarId ]) => calendarId)

/** 
    Filters the list of events to make sure all the events are within the current view mode range.

    This is necessary since the list could include an event that spans multiple days and then a portion includes the current view mode range.
    We only want to display the portions that are within the current view mode range.

    Currently only supports week view mode.
*/
const filterEventsToViewMode = ({ viewMode, selectedDate, events }: { events: ICalendarEventCell[], selectedDate: Dayjs, viewMode: CalendarViewMode }) => {

    switch (viewMode) {
        case 'day':
        case 'month':
            throw new Error(`filterEventsToViewMode: unsupported view mode. Currently only supports 'week' view mode, but ${viewMode} was provided.`)
        case 'week':
            /* 
                Include events that start before the start of the week and end during the week
                Include events that start before the start of the week and end after the week
                Include events that start after the start of the week and end after the week
            */
            return events
                .filter(event => {
                    const startDate = event.allDay ? event.startDate.utc() : event.startDate
                    const endDate = event.allDay ? event.endDate.utc() : event.endDate

                    let startOfPeriod = selectedDate.startOf(viewMode)
                    let endOfPeriod = selectedDate.endOf(viewMode)
                    // Use UTC comparison for start/end of period if an all day event
                    if (event.allDay) {
                        startOfPeriod = selectedDate.utc().startOf(viewMode)
                        endOfPeriod = selectedDate.utc().endOf(viewMode)
                    }

                    // Most common scenario, during the week
                    if (startDate.isSameOrAfter(startOfPeriod) && endDate.isSameOrBefore(endOfPeriod)) return true

                    // Starts during the week but ends any time
                    if (startDate.isSameOrAfter(startOfPeriod) && startDate.isSameOrBefore(endOfPeriod)) return true

                    // Ends during the week but starts any time
                    if (endDate.isSameOrAfter(startOfPeriod) && endDate.isSameOrBefore(endOfPeriod)) return true

                    return false
                })
    }
}