import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Paper, Box, useTheme } from '@material-ui/core'
import { ITrackSpringHeightStatus, MemoizedSpringHeight } from './spring-height'
import { Close, HelpOutline } from '@material-ui/icons'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { Link } from 'react-router-dom'
import { CommonProps } from './interfaces'

export type CalloutType = 'success' | 'info' | 'warning' | 'error' | 'secondary'

export interface ICalloutClosable {
    isOpen: boolean
    close: () => void
    trackStatus?: ITrackSpringHeightStatus
}

export interface ICalloutWhy {
    tooltip?: string
    href?: string
}

export interface ICalloutProps extends CommonProps {
    children: ReactNode
    type?: CalloutType
    closable?: ICalloutClosable | boolean
    why?: ICalloutWhy
}

/** 
    Closable `<Callout />`s will animate in/out their height.
*/
export const Callout = ({ children, type = 'info', closable, why, style, className }: ICalloutProps) => {
    const theme = useTheme()

    const [ isOpen, setIsOpen ] = useState(typeof closable === 'boolean' ? closable : Boolean(closable?.isOpen))
    useEffect(() => {
        setIsOpen(typeof closable === 'boolean' ? closable : Boolean(closable?.isOpen))
    }, [ closable ])
    const close = useCallback(() => {
        if (typeof closable === 'boolean') {
            setIsOpen(false)
        } else {
            closable?.close()
        }
    }, [ closable ])

    if (closable) {
        return (
            <MemoizedSpringHeight isOpen={isOpen} trackStatus={typeof closable === 'boolean' ? undefined : closable?.trackStatus} >
                <div style={style} className={className}>
                    <Paper
                        variant='outlined'
                        style={{
                            borderColor: theme.palette[ type ].main,
                            backgroundColor: theme.palette[ type ].main,
                            color: type === 'secondary' ? theme.palette[ type ].contrastText : 'white',
                        }}
                    >
                        <Box display='flex' p={1}>
                            <div>
                                <CalloutWhy why={why} />
                            </div>
                            <Box px={1} flex={1} display='flex' alignItems='center'>
                                {children}
                            </Box>
                            <div>
                                <AnimationIconWraper children={<Close />} onClick={close} />
                            </div>
                        </Box>
                    </Paper>
                </div>
            </MemoizedSpringHeight>
        )
    } else {
        return (
            <div style={style} className={className}>
                <Paper
                    variant='outlined'
                    style={{
                        borderColor: theme.palette[ type ].main,
                        backgroundColor: theme.palette[ type ].main,
                        color: type === 'secondary' ? theme.palette[ type ].contrastText : 'white',
                    }}
                >
                    <Box display='flex' p={1}>
                        <Box px={1} flex={1} display='flex' alignItems='center'>
                            {children}
                        </Box>
                        <div>
                            <CalloutWhy why={why} />
                        </div>
                    </Box>
                </Paper>
            </div>
        )
    }
}

const CalloutWhy = ({ why }: Pick<ICalloutProps, 'why'>) => {

    if (!why) return null

    if (why.tooltip && why.href) {
        return (
            <Link to={why.href} target='_blank'>
                <AnimationIconWraper children={<HelpOutline />} tooltip={{ title: why.tooltip }} />
            </Link>
        )
    }

    if (why.tooltip) return <AnimationIconWraper children={<HelpOutline />} tooltip={{ title: why.tooltip }} />

    if (why.href) {
        return (
            <Link to={why.href} target='_blank'>
                <AnimationIconWraper children={<HelpOutline />} />
            </Link>
        )
    }

    return null
}