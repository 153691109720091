import { useState } from 'react'
import { useTheme, Paper, Tab, Tabs } from '@material-ui/core'
import { AdminManagePeopleFacultyStaff } from './admin-manage-people-faculty-staff'
import { AdminPeopleSettings } from './admin-people-settings'

export const AdminManagePeople = () => {
    const theme = useTheme()

    const [ selectedTabIndex, setSelectedTabIndex ] = useState(0)

    return (
        <div style={{ margin: theme.spacing(2), flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
            <Paper>
                <Tabs
                    value={selectedTabIndex}
                    onChange={(_, newValue) => setSelectedTabIndex(newValue)}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    <Tab label='Faculty & Staff' value={0} />
                    {/* <Tab label='Parents' value={1} />
                    <Tab label='Students' value={2} /> */}
                    {/* <Tab label='Guests' value={3} /> */}
                    {/* <Tab label='Settings' value={4} /> */}
                </Tabs>
            </Paper>

            <div style={{ margin: theme.spacing(.5) }} />

            {selectedTabIndex === 0 && <AdminManagePeopleFacultyStaff />}
            {/* {selectedTabIndex === 4 && <AdminPeopleSettings />} */}
        </div>
    )
}
