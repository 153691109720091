import clsx from 'clsx'
import { createStyles, IconButton, makeStyles, Typography } from '@material-ui/core'
import { ICalendarState, useCalendarContext } from '../stores/calendar'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import React from 'react'
import { withContextSelector } from '../helpers'

export const calendarHeadHeight = 85

export const useCalendarEventsHeaderBaseStyles = makeStyles(theme =>
    createStyles({
        header: {
            paddingTop: theme.spacing(1),
            height: calendarHeadHeight,
        }
    })
)

const useWeekStyles = makeStyles(theme =>
    createStyles({
        header: {
            display: 'flex',
        },
        day: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        dayTitleToday: {
            color: theme.palette.primary.main
        },
        dayCircle: {
            color: theme.palette.grey[ 800 ],
            width: 51,
        },
        dayCircleToday: {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        }
    })
)

const getDayLabelForWeekIndex = (index: number) => {
    switch (index) {
        case 0:
            return 'SUN'
        case 1:
            return 'MON'
        case 2:
            return 'TUE'
        case 3:
            return 'WED'
        case 4:
            return 'THU'
        case 5:
            return 'FRI'
        case 6:
            return 'SAT'
    }
}

interface ICalendarEventsHeaderWeekProps extends Partial<Pick<ICalendarState, 'selectedDate'>> { }
const CalendarEventsHeaderWeek = ({ selectedDate }: ICalendarEventsHeaderWeekProps) => {

    const baseStyles = useCalendarEventsHeaderBaseStyles()
    const weekHeaderStyles = useWeekStyles()

    const days = useMemo(() => {
        if (!selectedDate) return []
        const firstDay = selectedDate.startOf('w')
        const _days: number[] = [ firstDay.date() ]
        for (let i = 1; i < 7; i++) {
            _days.push(firstDay.add(i, 'days').date())
        }
        return _days
    }, [ selectedDate ])

    return (
        <div className={clsx(baseStyles.header, weekHeaderStyles.header)}>
            {days.map((day, index) => {
                const isToday = dayjs().date() === day
                return (
                    <div key={day} className={clsx(weekHeaderStyles.day)}>
                        <Typography variant='caption' className={clsx(isToday && weekHeaderStyles.dayTitleToday)}>{getDayLabelForWeekIndex(index)}</Typography>
                        <IconButton color={isToday ? 'primary' : 'default'} className={clsx(weekHeaderStyles.dayCircle, isToday && weekHeaderStyles.dayCircleToday)}>
                            {day}
                        </IconButton>
                    </div>
                )
            })}
        </div>
    )
}

const Memoized = React.memo(CalendarEventsHeaderWeek)
export const MemoizedCalendarEventsHeaderWeek = withContextSelector({
    MemoizedComponent: Memoized,
    getContext: useCalendarContext,
    selector: ({ state: { selectedDate } }) => ({ selectedDate })
})