import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from 'react'
import {
	GroupListFieldsFragment,
	SchoolDivisionsFragment,
} from '../graphql/autogenerate/operations'
import { UserType } from '../graphql/autogenerate/schemas'
import { useSchoolContext } from '../stores/school'
import { SchoolOnboardingAuth } from './school-onboarding-auth'
import { SchoolOnboardingDivisions } from './school-onboarding-divisions'
import { SchoolOnboardingEnableNotifications } from './school-onboarding-enable-notifications'
import { SchoolOnboardingSummary } from './school-onboarding-summary'
import { SchoolOnboardingUserType } from './school-onboarding-user-type'
import { SchoolOnboardingWelcome } from './school-onboarding-welcome'
import { gatherAllGroupsForSchoolFragment } from '../helpers'

export const useSchoolOnboardingStyles = makeStyles(theme =>
	createStyles({
		stepContainer: {
			backgroundColor: 'white',
			borderTop: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			minHeight: 0,
			overflowY: 'hidden',
			padding: `${theme.spacing(5)}px ${theme.spacing(1)}px ${theme.spacing(
				1
			)}px ${theme.spacing(1)}px`,
			width: '100%',
		},
	})
)

type OnboardingStep =
	| 'welcome'
	| 'user-type'
	| 'auth'
	| 'divisions'
	| 'enable-notifications'
	| 'summary'

interface ISchoolOnboardingState {
	step: OnboardingStep
	userTypes?: UserType[]
	selectedDivisions?: SchoolDivisionsFragment[]
	selectedGroups?: GroupListFieldsFragment[]
	notificationsEnabled?: boolean
	onClose?: () => void
}

interface ISchoolOnboardingContext extends ISchoolOnboardingState {
	setSchoolOnboardingState: Dispatch<SetStateAction<ISchoolOnboardingState>>
}

const SchoolOnboardingContext = createContext<
	ISchoolOnboardingContext | undefined
>(undefined)

export const useSchoolOnboardingContext = () => {
	const context = useContext(SchoolOnboardingContext)
	if (!context)
		throw new Error(
			'Attempted to use SchoolOnboardingContext before it was provided.'
		)
	return context
}

interface ISchoolOnboardingProps {
	initialStep?: OnboardingStep
	onClose?: () => void
}
export const SchoolOnboarding = ({
	initialStep,
	onClose,
}: ISchoolOnboardingProps) => {
	const theme = useTheme()

	const {
		state: { currentUserPermissions, school },
	} = useSchoolContext()

	const { divisions } = school

	const schoolwideDivision = divisions.nodes.find(o => o.schoolwide)
	if (!schoolwideDivision) throw new Error('Missing schoolwide division.') // Check to make Typescript happy...there's no way this can happen (prevented at the DB level)

	const [schoolOnboardingState, setSchoolOnboardingState] =
		useState<ISchoolOnboardingState>(
			(() => {
				const initialState: ISchoolOnboardingState = {
					step: initialStep || 'welcome',
					onClose,
					// Always include the schoolwide division in the user's subscription selections.
					selectedDivisions: [schoolwideDivision],
				}

				// If the user's onboarding is being reset, return the default initial state without preserving previous selections
				if (!currentUserPermissions.school?.completedOnboarding)
					return initialState

				const allGroups = gatherAllGroupsForSchoolFragment(school)
				const previouslySelectedGroups =
					currentUserPermissions.groups.nodes.filter(o => o.member)

				initialState.selectedDivisions = [
					schoolwideDivision,
					...divisions.nodes.filter(
						o =>
							!o.schoolwide &&
							previouslySelectedGroups.find(pg => pg.groupId === o.group?.id)
					),
				]

				initialState.selectedGroups = allGroups.filter(
					o =>
						!o.isDivisionGroup &&
						!o.isSchoolwideGroup &&
						previouslySelectedGroups.find(pg => pg.groupId === o.id)
				)

				if (currentUserPermissions.school?.userTypes) {
					initialState.userTypes = currentUserPermissions.school
						.userTypes as UserType[]
				}

				return initialState
			})()
		)
	const { step } = schoolOnboardingState

	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: theme.palette.primary.main,
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center',
				zIndex: 10,
			}}
		>
			<SchoolOnboardingContext.Provider
				value={{ ...schoolOnboardingState, setSchoolOnboardingState }}
			>
				{step === 'welcome' && <SchoolOnboardingWelcome />}
				{step === 'user-type' && <SchoolOnboardingUserType />}
				{step === 'auth' && <SchoolOnboardingAuth />}
				{step === 'divisions' && <SchoolOnboardingDivisions />}
				{step === 'enable-notifications' && (
					<SchoolOnboardingEnableNotifications />
				)}
				{step === 'summary' && <SchoolOnboardingSummary />}
			</SchoolOnboardingContext.Provider>
		</div>
	)
}
