import { Button, Paper, Typography, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { v4 } from 'uuid'
import {
	useSchoolMarqueeQuery,
	useUpdateMarqueeMutation,
} from '../graphql/autogenerate/react-query'
import { ButtonType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { Callout } from './callout'
import {
	FileFieldValue,
	FormikButtonsField,
	FormikDatePicker,
	FormikEmbedField,
	FormikImageField,
	FormikSwitchField,
	FormikTextInput,
	FormikTimePicker,
	embedUrlValidation,
	useImageFieldChangeHandler,
} from './forms'
import { FormikRichText } from './forms/formik-rich-text'
import { MarqueeDisplay } from './marquee-display'
import { PhoneFrame } from './phone-frame'
import * as yup from 'yup'

export const AdminMarquee = () => {
	const theme = useTheme()

	const {
		state: {
			school: { id: schoolId },
		},
	} = useSchoolContext()

	const { data } = useHandleReactQuery(useSchoolMarqueeQuery({ schoolId }))
	const marquee = data?.marquees?.nodes[0]
	const initialValues = useMemo(() => {
		if (!marquee) return null
		return {
			enabled: marquee.enabled,
			marqueeImage: marquee.marqueeImage as FileFieldValue,
			body: marquee.body || '',
			includeCountdown: Boolean(marquee.countdownDate),
			countdownDate: marquee.countdownDate
				? dayjs(marquee.countdownDate)
				: dayjs().add(7, 'days'),
			countdownTime: marquee.countdownDate
				? dayjs(marquee.countdownDate)
				: dayjs().startOf('hour'),
			countdownLabel: marquee.countdownLabel || '',
			countdownHideWhenCompleted: marquee.countdownHideWhenCompleted,
			includeButton: Boolean(marquee.buttons?.buttons?.length),
			buttons: marquee.buttons?.buttons?.length
				? marquee.buttons?.buttons
				: [{ type: '' as ButtonType, url: '', label: '', id: v4() }],
			includeEmbed: Boolean(marquee.embedUrl),
			embedUrl: marquee.embedUrl,
		}
	}, [data])

	const { enqueueSnackbar } = useSnackbar()
	const { mutate: updateMarquee } = useHandleReactQueryMutation(
		useUpdateMarqueeMutation({
			onSuccess: () => {
				enqueueSnackbar('Marquee updated.', { variant: 'success' })
			},
		})
	)
	const fileChangeHandler = useImageFieldChangeHandler()

	const [newImage, setNewImage] = useState<string>()

	if (!marquee || !initialValues) return null

	return (
		<Formik
			enableReinitialize
			validationSchema={yup.object({ embedUrl: embedUrlValidation })}
			initialValues={initialValues}
			onSubmit={async ({ marqueeImage, ...values }) => {
				updateMarquee({
					id: marquee.id,
					...values,
					...(await fileChangeHandler({
						oldValue: marquee.marqueeImage,
						newValue: marqueeImage,
						filePropertyName: 'marqueeImage',
					})),
					countdownDate: values.includeCountdown
						? values.countdownDate
								.hour(values.countdownTime.hour())
								.minute(values.countdownTime.minute())
								.startOf('minute')
								.toISOString()
						: null,
					countdownLabel: values.includeCountdown
						? values.countdownLabel
						: null,
					buttons: values.includeButton ? values.buttons : [],
					embedUrl: values.includeEmbed ? values.embedUrl : null,
				})
			}}
		>
			{formikProps => (
				<div
					style={{
						height: '100%',
						display: 'flex',
						padding: theme.spacing(2),
						overflow: 'hidden',
					}}
				>
					<Paper
						style={{
							flex: 1,
							padding: theme.spacing(2),
							display: 'flex',
							overflow: 'hidden',
							flexDirection: 'column',
							minHeight: 0,
						}}
					>
						<Callout style={{ marginBottom: theme.spacing(2) }}>
							<div>
								<Typography style={{ marginBottom: theme.spacing(1) }}>
									Use the Marquee for announcements, alerts, and other high
									profile information that you want to keep front and center.
								</Typography>
								<Typography>
									If there isn't any high profile info to share at the moment,
									simply disable the marquee until you need it.
								</Typography>
							</div>
						</Callout>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: theme.spacing(1),
							}}
						>
							<Typography variant='h5' color='textSecondary'>
								Marquee Settings
							</Typography>
							<Button
								variant='contained'
								color='primary'
								type='submit'
								onClick={formikProps.submitForm}
							>
								Save Changes
							</Button>
						</div>
						<Form
							style={{
								display: 'flex',
								flexDirection: 'column',
								minHeight: 0,
								overflowY: 'auto',
							}}
						>
							<FormikSwitchField
								style={{ marginLeft: theme.spacing(2) }}
								field={{ name: 'enabled', label: 'Enabled' }}
							/>
							<FormikRichText fieldProps={{ name: 'body' }} />

							<FormikSwitchField
								style={{ marginLeft: theme.spacing(2) }}
								field={{
									name: 'includeCountdown',
									label: 'Include a countdown',
								}}
							/>
							{formikProps.values.includeCountdown && (
								<>
									<div
										style={{
											display: 'flex',
											marginBottom: theme.spacing(1),
											marginLeft: theme.spacing(0.5),
										}}
									>
										<FormikDatePicker
											disablePast
											disabledKeyboard
											fieldProps={{ name: 'countdownDate' }}
											style={{ marginRight: theme.spacing(1) }}
										/>
										<FormikTimePicker
											fieldProps={{ name: 'countdownTime' }}
											startTime={dayjs().startOf('d')}
										/>
										<FormikSwitchField
											style={{ marginLeft: theme.spacing(2) }}
											field={{
												name: 'countdownHideWhenCompleted',
												label: 'Hide countdown when completed',
											}}
										/>
									</div>
									<FormikTextInput
										variant='filled'
										fieldProps={{
											name: 'countdownLabel',
											label: 'Countdown label',
										}}
									/>
								</>
							)}

							<FormikSwitchField
								style={{ marginLeft: theme.spacing(2) }}
								field={{ name: 'includeButton', label: 'Include a button' }}
							/>
							{formikProps.values.includeButton && (
								<FormikButtonsField
									single
									field={{ name: 'buttons', label: 'Button' }}
									style={{ marginBottom: theme.spacing(2) }}
								/>
							)}

							<FormikSwitchField
								style={{ marginLeft: theme.spacing(2) }}
								field={{
									name: 'includeEmbed',
									label: 'Embed a video/podcast',
								}}
							/>
							{formikProps.values.includeEmbed && (
								<FormikEmbedField
									field={{ label: 'Embed URL', name: 'embedUrl' }}
								/>
							)}

							<FormikImageField
								field={{ name: 'marqueeImage', label: 'Marquee Image' }}
								circleCrop
								aspectRatioHelper='Recommended dimensions: 500px by 500px'
								style={{
									marginBottom: theme.spacing(2),
									marginTop: theme.spacing(2),
									maxWidth: 300,
								}}
								onImageProcessed={setNewImage}
							/>
						</Form>
					</Paper>

					<PhoneFrame
						view='detail'
						detailView={{
							title: 'Home',
							body: (
								<MarqueeDisplay
									enabled={formikProps.values.enabled}
									image={
										formikProps.values.marqueeImage &&
										((newImage &&
											URL.createObjectURL(newImage as unknown as Blob)) ||
											marquee.marqueeImage?.temporaryDownloadUrl)
									}
									body={formikProps.values.body}
									countdown={
										formikProps.values.includeCountdown
											? {
													time: formikProps.values.countdownDate
														.hour(formikProps.values.countdownTime.hour())
														.minute(formikProps.values.countdownTime.minute())
														.startOf('minute'),
													label: formikProps.values.countdownLabel,
													hideWhenCompleted:
														formikProps.values.countdownHideWhenCompleted,
											  }
											: undefined
									}
									button={
										formikProps.values.includeButton
											? formikProps.values.buttons[0]
											: undefined
									}
									embedUrl={
										formikProps.values.includeEmbed &&
										formikProps.values.embedUrl
											? formikProps.values.embedUrl
											: undefined
									}
									mobilePreview
								/>
							),
						}}
					/>
				</div>
			)}
		</Formik>
	)
}
