import { Formik } from 'formik'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { Modal, useModal } from './modal'
import * as yup from 'yup'
import {
	FileFieldValue,
	FormikButtonsField,
	FormikHexColorInput,
	FormikImageField,
	FormikSwitchField,
	FormikTextInput,
	useImageFieldChangeHandler,
} from './forms'
import { SponsorType } from '../graphql/autogenerate/schemas'
import { Button, Divider, Typography, useTheme } from '@material-ui/core'
import { FormikRichText } from './forms/formik-rich-text'
import { FormikSimpleSelectField } from './forms/formik-simple-select-field'
import { VALIDATION_REGEX } from './forms/constants'
import { useHandleReactQueryMutation } from '../hooks'
import {
	useCreateSponsorMutation,
	useDeleteSponsorMutation,
	useUpdateSponsorMutation,
} from '../graphql/autogenerate/react-query'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { ConfirmDialog } from './confirm-dialog'

interface ISponsorFormModalProps {
	modalControls: ReturnType<typeof useModal>
	sponsorToEdit?: SponsorFragment
	initialSponsorType?: SponsorType
	afterClose: () => void
}

export const SponsorFormModal = ({
	modalControls,
	sponsorToEdit,
	afterClose,
	initialSponsorType,
}: ISponsorFormModalProps) => {
	const theme = useTheme()
	const buttonStyles = useButtonStyles()

	const {
		state: {
			school: { id: schoolId },
		},
	} = useSchoolContext()

	const initialValues = {
		body: sponsorToEdit?.body || '',
		buttons: sponsorToEdit?.buttons?.buttons || [],
		published: sponsorToEdit ? sponsorToEdit.published : true,
		title: sponsorToEdit?.title || '',
		type: sponsorToEdit?.type || initialSponsorType || ('' as SponsorType),
		coverImage: sponsorToEdit?.coverImage as FileFieldValue,
		thumbnailImage: sponsorToEdit?.thumbnailImage as FileFieldValue,
		logoImage: sponsorToEdit?.logoImage as FileFieldValue,
		promoImage: sponsorToEdit?.promoImage as FileFieldValue,
		primaryColor: sponsorToEdit?.primaryColor || '',
	}

	const { mutate: createSponsor } = useHandleReactQueryMutation(
		useCreateSponsorMutation({ onSuccess: modalControls.close })
	)
	const { mutate: updateSponsor } = useHandleReactQueryMutation(
		useUpdateSponsorMutation({ onSuccess: modalControls.close })
	)
	const fileChangeHandler = useImageFieldChangeHandler()

	const deleteModal = useModal()
	const { mutate: deleteSponsor } = useHandleReactQueryMutation(
		useDeleteSponsorMutation({
			onSettled: () => {
				deleteModal.close()
				modalControls.close()
			},
		})
	)

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={yup.object({
					title: yup.string().required('Required'),
					type: yup.string().required('Required'),
					primaryColor: yup
						.string()
						.nullable()
						.matches(
							VALIDATION_REGEX.hexCode,
							'Must be a valid hex color (e.g. #F5B517).'
						),
				})}
				onSubmit={async ({
					coverImage,
					thumbnailImage,
					promoImage,
					logoImage,
					...values
				}) => {
					if (sponsorToEdit) {
						updateSponsor({
							id: sponsorToEdit.id,
							...values,
							...(await fileChangeHandler({
								oldValue: sponsorToEdit.coverImage,
								newValue: coverImage,
								filePropertyName: 'coverImage',
							})),
							...(await fileChangeHandler({
								oldValue: sponsorToEdit.thumbnailImage,
								newValue: thumbnailImage,
								filePropertyName: 'thumbnailImage',
							})),
							...(await fileChangeHandler({
								oldValue: sponsorToEdit.promoImage,
								newValue: promoImage,
								filePropertyName: 'promoImage',
							})),
							...(await fileChangeHandler({
								oldValue: sponsorToEdit.logoImage,
								newValue: logoImage,
								filePropertyName: 'logoImage',
							})),
						})
					} else {
						createSponsor({
							...values,
							schoolId,
							...(await fileChangeHandler({
								oldValue: undefined,
								newValue: coverImage,
								filePropertyName: 'coverImage',
							})),
							...(await fileChangeHandler({
								oldValue: undefined,
								newValue: thumbnailImage,
								filePropertyName: 'thumbnailImage',
							})),
							...(await fileChangeHandler({
								oldValue: undefined,
								newValue: promoImage,
								filePropertyName: 'promoImage',
							})),
							...(await fileChangeHandler({
								oldValue: undefined,
								newValue: logoImage,
								filePropertyName: 'logoImage',
							})),
						})
					}
				}}
			>
				{formikProps => (
					<Modal
						{...modalControls.props}
						title={
							sponsorToEdit ? `Edit ${sponsorToEdit.title}` : 'Add Sponsor'
						}
						afterClose={() => {
							afterClose()
							formikProps.resetForm()
						}}
						dismissible
						dividers
						closeButton
						size='md'
						actions={
							<div
								style={{
									flex: 1,
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								{sponsorToEdit && (
									<div>
										<Button
											onClick={deleteModal.open}
											className={buttonStyles.warningOutlined}
											style={{ minWidth: 100, marginRight: theme.spacing(3) }}
											variant='outlined'
										>
											Delete
										</Button>
										<FormikSwitchField
											style={{ marginRight: theme.spacing(1) }}
											field={{ name: 'published', label: 'Published' }}
										/>
									</div>
								)}
								<div>
									<Button
										variant='text'
										style={{ minWidth: 100 }}
										onClick={modalControls.close}
									>
										Cancel
									</Button>

									<Button
										style={{ minWidth: 100 }}
										variant='contained'
										color='primary'
										onClick={formikProps.submitForm}
									>
										Save
									</Button>
								</div>
							</div>
						}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Typography variant='h5'>Details</Typography>
						</div>
						<Divider style={{ marginBottom: theme.spacing(2) }} />

						<div>
							<FormikSimpleSelectField
								field={{ name: 'type', label: 'Sponsor Type' }}
								options={[
									{ label: 'Gold', value: SponsorType.Gold },
									{ label: 'Silver', value: SponsorType.Silver },
									{ label: 'Bronze', value: SponsorType.Bronze },
								]}
								style={{
									minWidth: 300,
									marginLeft: theme.spacing(0.5),
									marginBottom: theme.spacing(2),
								}}
							/>
						</div>

						<FormikTextInput fieldProps={{ name: 'title', label: 'Title' }} />
						<FormikRichText
							fieldProps={{ name: 'body', label: 'Description' }}
						/>

						<FormikButtonsField
							field={{ name: 'buttons', label: 'Buttons' }}
							style={{ marginBottom: theme.spacing(2) }}
						/>

						<Typography variant='h5'>Creative</Typography>
						<Divider style={{ marginBottom: theme.spacing(2) }} />
						<FormikHexColorInput
							fieldProps={{ name: 'primaryColor', label: 'Brand Color' }}
						/>
						<FormikImageField
							field={{ name: 'coverImage', label: 'Cover Image' }}
							aspectRatio={16 / 9}
							aspectRatioHelper='16:9 aspect ratio (e.g. 1600px by 900px)'
						/>
						<FormikImageField
							field={{ name: 'thumbnailImage', label: 'Thumbnail Image' }}
							aspectRatio={1}
							aspectRatioHelper='1:1 aspect ratio (e.g. 500px by 500px)'
						/>
						<FormikImageField
							field={{ name: 'logoImage', label: 'Logo' }}
							aspectRatioHelper='Recommended dimensions: 900px by 300px'
						/>
						<FormikImageField
							field={{ name: 'promoImage', label: 'Promo' }}
							aspectRatio={16 / 9}
							aspectRatioHelper='16:9 aspect ratio (e.g. 1600px by 900px)'
						/>
					</Modal>
				)}
			</Formik>

			<ConfirmDialog
				{...deleteModal.props}
				confirmButton={{
					label: 'Delete',
					type: 'error',
				}}
				goBackButtonLabel='Cancel'
				body={
					<>
						<Typography gutterBottom>
							Are you sure you want to delete this Sponsor?
						</Typography>
						<Typography>This action cannot be undone.</Typography>
					</>
				}
				confirm={() => sponsorToEdit && deleteSponsor({ id: sponsorToEdit.id })}
			/>
		</>
	)
}
