import { createContext, DependencyList, FunctionComponent, useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider as HelmetProviderOC } from 'react-helmet-async'

/* 
    NOTE

    We put all the "use" Helmet hooks in one file because we do not want to export either HelmetContext or useHelmet.

    This helps ensure no unwitting developer accidentally calls a useHelmet function directly without wrapping it in a useEffect (which produce infinite rerenders).
*/


interface IHelmetContext {
    setPageTitle: (title?: string) => void
}
const HelmetContext = createContext<IHelmetContext | null>(null)
const useHelmet = () => {
    const helmetContext = useContext(HelmetContext)
    if (!helmetContext) throw new Error('Attemted to use HelmetContext before it was provided.')
    return helmetContext
}

/**
    __useSetPageTitle__

    Sets a page title once or based on dependency changes (a la `useEffect`)

    @example
    // Simple
    useSetPageTitle('This Page')

    // Complex
    const [title, setTitle] = useState('')
    useSetPageTitle(
        () => {
            if (title === 'something weird') {
                return 'something not weird'
            } else {
                return title
            }
        },
        [title]
    )
**/
export const useSetPageTitle = (
    pageTitle?: string | (() => string | Promise<string>),
    deps?: DependencyList
) => {
    const { setPageTitle } = useHelmet()

    useEffect(() => {
        (async () => {
            if (pageTitle) {
                if (typeof pageTitle === 'string') {
                    setPageTitle(pageTitle)
                } else {
                    const title = await pageTitle()
                    setPageTitle(title)
                }
            } else {
                setPageTitle()
            }
        })()

        return () => setPageTitle()
    }, deps || [])
}

interface IHelmetState {
    title: string
}

export const HelmetProvider: FunctionComponent = ({ children }) => {
    const [ helmentState, setHelemetState ] = useState<IHelmetState>({
        title: 'Legit Apps'
    })

    const setTitle = (title?: string) => setHelemetState(_state => ({ ..._state, title: title ? `Legit Apps | ${title}` : 'Legit Apps' }))

    const helmetContext: IHelmetContext = {
        setPageTitle: setTitle
    }

    return (
        <HelmetProviderOC>
            <HelmetContext.Provider value={helmetContext}>
                <Helmet>
                    <title>{helmentState.title}</title>
                </Helmet>

                {children}
            </HelmetContext.Provider>
        </HelmetProviderOC>

    )
}