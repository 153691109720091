import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { EmbedDisplay } from './embed-display'

interface IPageSectionVideoProps {
	pageSection: PageSectionFragment
}

export const PageSectionVideo = ({ pageSection }: IPageSectionVideoProps) => {
	if (!pageSection.url) return null
	return <EmbedDisplay url={pageSection.url} />
}
