import {
	Button,
	Divider,
	IconButton,
	Typography,
	useTheme,
} from '@material-ui/core'
import { ArrowBack, ArrowForward, CheckCircleOutline } from '@material-ui/icons'
import { Fragment, useCallback, useMemo, useState } from 'react'
import {
	GroupListFieldsFragment,
	SchoolDivisionsFragment,
} from '../graphql/autogenerate/operations'
import {
	useCreateSchoolPersonInstallationMutation,
	useSubmitOnboardingAnswersMutation,
} from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { setOneSignalUserId, useNativeBridge } from '../hooks/use-native-bridge'
import { useSchoolContext } from '../stores/school'
import { GroupCell } from './group-cell'
import { IconTypeDisplay } from './icon-type-display'
import { usePasscodeCheckModal } from './passcode-modal'
import {
	useSchoolOnboardingContext,
	useSchoolOnboardingStyles,
} from './school-onboarding'
import { SchoolOnboardingCheckbox } from './school-onboarding-checkbox'

interface IState {
	currentDivision?: SchoolDivisionsFragment
}

export const SchoolOnboardingDivisions = () => {
	const theme = useTheme()
	const onboardingStyles = useSchoolOnboardingStyles()
	const {
		state: {
			school: {
				divisions: { nodes: divisions },
				id: schoolId,
			},
		},
		actions: { refetch },
	} = useSchoolContext()
	const {
		setSchoolOnboardingState,
		selectedDivisions,
		selectedGroups,
		userTypes,
	} = useSchoolOnboardingContext()

	const [state, setState] = useState<IState>({
		currentDivision: divisions.length === 1 ? divisions[0] : undefined,
	})
	const { currentDivision } = state

	const selectedDivisionsWithGroups = useMemo(() => {
		return selectedDivisions?.filter(division =>
			division.groupCategories.nodes.some(category => category.groups.totalCount)
		)
	}, [selectedDivisions])

	const nextDivisionToSelectGroupsFor = useMemo(() => {
		// No divisions selected...there is no next division
		if (!selectedDivisionsWithGroups || !selectedDivisionsWithGroups.length)
			return null

		// At least one division was selected from the list of divisions. Have we moved on to a division detail page?
		if (currentDivision) {
			// We have moved on to a division detail page! Let's get the next one.
			const currentDivisionIdx = selectedDivisionsWithGroups.findIndex(
				o => o.id === currentDivision.id
			)

			// This was the last one, no more divisions!
			if (
				currentDivisionIdx === -1 ||
				selectedDivisionsWithGroups.length === currentDivisionIdx + 1
			)
				return null

			// We have more divisions...return the next one.
			return selectedDivisionsWithGroups[currentDivisionIdx + 1]
		} else {
			// We have selected divisions but we've not moved on to a detail page.
			// The next division is the first division of the selected divisions.
			return selectedDivisionsWithGroups[0]
		}
	}, [currentDivision, selectedDivisionsWithGroups])

	const previousDivisionToSelectGroupsFor = useMemo(() => {
		if (
			divisions.length === 1 ||
			!currentDivision ||
			!selectedDivisionsWithGroups
		)
			return null
		const currentDivisionIdx = selectedDivisionsWithGroups.findIndex(
			o => o.id === currentDivision.id
		)
		if (currentDivisionIdx === -1 || currentDivisionIdx === 0) return null
		return selectedDivisionsWithGroups[currentDivisionIdx - 1]
	}, [selectedDivisionsWithGroups, currentDivision, divisions])

	const { modal, checkPasscode } = usePasscodeCheckModal()
	const onGroupPressed = useCallback(
		(group: GroupListFieldsFragment) => {
			const selected = selectedGroups?.some(o => o.id === group.id)

			const selectGroup = () => {
				if (selected) {
					setSchoolOnboardingState(_state => ({
						..._state,
						selectedGroups: (_state.selectedGroups || []).filter(
							o => o.id !== group.id
						),
					}))
				} else {
					setSchoolOnboardingState(_state => ({
						..._state,
						selectedGroups: (_state.selectedGroups || []).concat(group),
					}))
				}
			}

			if (group.passcode && !selected) {
				checkPasscode({
					onCorrectPasscode: () => selectGroup(),
					passcode: group.passcode,
					passcodeHint: group.passcodeHint,
				})
			} else {
				selectGroup()
			}
		},
		[selectedGroups]
	)

	const bridgeContext = useNativeBridge()
	const { mutateAsync: createSchoolPersonInstallation } =
		useHandleReactQueryMutation(useCreateSchoolPersonInstallationMutation())
	const { mutate: submitOnboardingSelections } = useHandleReactQueryMutation(
		useSubmitOnboardingAnswersMutation({
			onSuccess: async ({ submitCurrentPersonSchoolOnboarding }) => {
				if (submitCurrentPersonSchoolOnboarding?.schoolPerson) {
					if (
						!submitCurrentPersonSchoolOnboarding.schoolPerson
							.schoolPersonInstallations.nodes.length &&
						bridgeContext
					) {
						// If we're on native and there isn't already an installation for this person, create a School_PersonInstallation for this School_Person
						await createSchoolPersonInstallation({
							...bridgeContext.device,
							schoolPersonId: submitCurrentPersonSchoolOnboarding.schoolPerson.id,
						})

						// Register this School_Person's ID with OneSignal as the "user" for the device
						setOneSignalUserId({
							schoolPersonId: submitCurrentPersonSchoolOnboarding.schoolPerson.id,
						})
					}
				}

				const thing = await refetch.schoolDetail()
				console.log(thing)

				setSchoolOnboardingState(_state => ({
					..._state,
					step: bridgeContext ? 'enable-notifications' : 'summary',
				}))
			},
		})
	)

	return (
		<>
			<div className={onboardingStyles.stepContainer}>
				<div style={{ position: 'absolute', top: theme.spacing(1), left: 0 }}>
					<IconButton
						onClick={() => {
							if (previousDivisionToSelectGroupsFor) {
								setState(_state => ({
									..._state,
									currentDivision: previousDivisionToSelectGroupsFor,
								}))
							} else if (currentDivision) {
								setState(_state => ({ ..._state, currentDivision: undefined }))
							} else {
								setSchoolOnboardingState(_state => ({ ..._state, step: 'user-type' }))
							}
						}}
					>
						<ArrowBack />
					</IconButton>
				</div>

				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minHeight: 0,
						overflowY: 'auto',
					}}
				>
					<Typography gutterBottom variant='h4'>
						{currentDivision ? currentDivision.name : 'Divisions'}
					</Typography>
					<Typography style={{ marginBottom: theme.spacing(4) }}>
						Select the{' '}
						{currentDivision ? `${currentDivision.name} groups` : 'divisions'} for
						which you'd like to receive updates.
					</Typography>

					{currentDivision ? (
						currentDivision.groupCategories.nodes.some(o => o.groups.totalCount) ? (
							currentDivision.groupCategories.nodes
								.filter(o => o.groups.totalCount)
								.map(groupCategory => (
									<Fragment key={groupCategory.id}>
										<Divider />
										<Typography
											variant='h6'
											style={{
												display: 'flex',
												margin: `${theme.spacing(1)}px 0px`,
												alignItems: 'center',
												color: groupCategory.iconBackgroundColor || theme.palette.grey[700],
											}}
										>
											<IconTypeDisplay
												type={groupCategory.iconType}
												size={24}
												style={{ marginRight: theme.spacing(1) }}
											/>{' '}
											{groupCategory.name}
										</Typography>
										<div
											style={{
												display: 'flex',
												flexWrap: 'wrap',
												marginBottom: theme.spacing(2),
											}}
										>
											{groupCategory.groups.nodes
												.filter(group => !group.autoEnrollUserTypes)
												.map(group => (
													<GroupCell
														key={group.id}
														group={group}
														category={groupCategory}
														sortingActive
														onClick={onGroupPressed}
														selected={Boolean(selectedGroups?.some(o => o.id === group.id))}
														showPasscodeLock={Boolean(group.passcode)}
													/>
												))}
										</div>
									</Fragment>
								))
						) : (
							<Typography color='textSecondary'>
								This division does not have any group options.
							</Typography>
						)
					) : (
						divisions
							.filter(o => !o.schoolwide)
							.map(division => (
								<SchoolOnboardingCheckbox
									key={division.id}
									checked={Boolean(selectedDivisions?.some(o => o.id === division.id))}
									label={division.name}
									onChange={checked => {
										if (checked) {
											if (!selectedDivisions?.some(o => o.id === division.id))
												setSchoolOnboardingState(_state => ({
													..._state,
													selectedDivisions: divisions.filter(
														o =>
															o.id === division.id ||
															_state.selectedDivisions?.some(selected => selected.id === o.id)
													),
												}))
										} else {
											setSchoolOnboardingState(_state => ({
												..._state,
												selectedDivisions: _state.selectedDivisions?.filter(
													o => o.id !== division.id
												),
											}))
										}
									}}
								/>
							))
					)}
				</div>

				<div>
					<Button
						disabled={!Boolean(selectedDivisions?.length)}
						style={{ width: '100%' }}
						size='large'
						color='primary'
						variant='contained'
						endIcon={
							nextDivisionToSelectGroupsFor ? <ArrowForward /> : <CheckCircleOutline />
						}
						onClick={() => {
							if (nextDivisionToSelectGroupsFor) {
								setState(_state => ({
									..._state,
									currentDivision: nextDivisionToSelectGroupsFor,
								}))
							} else {
								// We have all the user's onboarding selections now. Go ahead and complete the onboarding then take them to enable notifications request.
								submitOnboardingSelections({
									selectedGroupIds: (selectedGroups || [])
										.map(o => o.id)
										.concat((selectedDivisions || []).map(o => o.group!.id)),
									userTypes: userTypes || [],
									schoolId,
									installationFilter: bridgeContext
										? {
												deviceName: { equalTo: bridgeContext.device.deviceName },
												platform: { equalTo: bridgeContext.device.platform },
										  }
										: undefined,
								})
							}
						}}
					>
						{currentDivision
							? nextDivisionToSelectGroupsFor
								? `Continue to ${nextDivisionToSelectGroupsFor.name}`
								: 'Save'
							: 'Continue'}
					</Button>
				</div>

				{/* Progress bar */}
				{currentDivision &&
					selectedDivisionsWithGroups &&
					selectedDivisionsWithGroups.length > 1 && (
						<div
							style={{
								display: 'flex',
								height: theme.spacing(1),
								marginTop: theme.spacing(1),
							}}
						>
							{selectedDivisionsWithGroups.map((division, index, array) => {
								const filled =
									index <=
									selectedDivisionsWithGroups.findIndex(o => o.id === currentDivision.id)
								return (
									<div
										key={division.id}
										style={{
											backgroundColor: filled
												? theme.palette.primary.main
												: theme.palette.grey[500],
											flex: 1,
											height: '100%',
											...(index === 0
												? {
														borderTopLeftRadius: 5,
														borderBottomLeftRadius: 5,
														borderRight: '1px solid white',
												  }
												: {}),
											...(index === array.length - 1
												? {
														borderTopRightRadius: 5,
														borderBottomRightRadius: 5,
														borderLeft: '1px solid white',
												  }
												: {}),
											...(index > 1 && index < array.length - 2
												? {
														borderLeft: '1px solid white',
														borderRight: '1px solid white',
												  }
												: {}),
										}}
									/>
								)
							})}
						</div>
					)}
			</div>
			{modal}
		</>
	)
}
