import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { useMeasure, } from 'react-use'

export type SpringHeightStatus = 'opening' | 'open' | 'closing' | 'closed'

export interface ITrackSpringHeightStatus {
    status: SpringHeightStatus
    setStatus: (status: SpringHeightStatus) => void
}

interface ISpringHeightProps {
    children: ReactNode
    isOpen: boolean
    trackStatus?: ITrackSpringHeightStatus
}

const SpringHeight = ({ children, isOpen, trackStatus }: ISpringHeightProps) => {
    // The height of the content inside of the accordion
    const [ contentHeight, setContentHeight ] = useState(0)

    // Gets the height of the element (ref)
    const [ ref, { height } ] = useMeasure<HTMLDivElement>()

    // Animations
    const expand = useSpring({
        height: isOpen ? `${contentHeight}px` : `${0}px`,
        opacity: isOpen ? 1 : 0,
        config: { tension: isOpen ? 200 : 500, friction: isOpen ? 15 : 50, clamp: true },
        onRest: () => {
            if (trackStatus) {
                if (isOpen && trackStatus.status !== 'open') trackStatus.setStatus('open')
                if (!isOpen && trackStatus.status !== 'closed') trackStatus.setStatus('closed')
            }
        }
    })

    const prevIsOpen = useRef(isOpen)
    useEffect(() => {
        if (trackStatus) {
            if (!prevIsOpen.current && isOpen) trackStatus.setStatus('opening')
            if (prevIsOpen.current && !isOpen) trackStatus.setStatus('closing')
        }
        prevIsOpen.current = isOpen
    }, [ isOpen ])

    useEffect(() => {
        //Sets initial height
        setContentHeight(height)

        // const resizeListener = (ev: UIEvent) => {
        //     setContentHeight(height)
        // }

        // // Adds resize event listener
        // window.addEventListener('resize', resizeListener)

        // // Clean-up
        // return window.removeEventListener('resize', resizeListener)
    }, [ height ])

    return (
        <animated.div style={{ ...expand, overflow: 'hidden' }}>
            <div ref={ref}>
                {children}
            </div>
        </animated.div>
    )
}

export const MemoizedSpringHeight = React.memo(SpringHeight)