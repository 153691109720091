import { useMobileDetect } from '.'
// import { getNativeStorageValue, setNativeStorageValue } from './use-native-bridge'

interface ILocalStorageItem {
    key: string
    value: string
}

/** 
    Wraps all interactions with local storage so that it can be synced with native storage on mobile when posisble.
*/
export const useLocalStorage = () => {

    const { isMobile } = useMobileDetect()

    const setItem = async ({ key, value }: ILocalStorageItem) => {
        localStorage.setItem(key, value)
        // if (isMobile()) await setNativeStorageValue({ key, value })
    }

    /** 
        If local storage has a value for this key, returns that value.
        Otherwise, if on mobile, falls back to the native store value.
    */
    const getItem = async ({ key }: Pick<ILocalStorageItem, 'key'>) => {
        const localStoreValue = localStorage.getItem(key)
        if (localStoreValue !== null) return localStoreValue
        // if (isMobile()) return (await getNativeStorageValue(key))?.value
    }

    const removeItem = async ({ key }: Pick<ILocalStorageItem, 'key'>) => {
        localStorage.removeItem(key)
        // if (isMobile()) await setNativeStorageValue({ key, value: null })
    }

    return { setItem, getItem, removeItem }
}