import { Form, Formik } from 'formik'
import { MutableRefObject } from 'react'
import { usePageContext } from './page-edit'
import * as yup from 'yup'
import { buttonsSchema, FormikButtonsField, FormikPatchTouched } from './forms'
import { useCreateButtonPageSectionMutation, useUpdateButtonPageSectionMutation, useUpdatePageSectionSortOrderMutation } from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { v4 } from 'uuid'
import { ButtonType } from '../graphql/autogenerate/schemas'

interface IPageSectionButtonFormProps {
    submitFormRef: MutableRefObject<(() => Promise<any>) | undefined>
}

export const PageSectionButtonForm = ({ submitFormRef }: IPageSectionButtonFormProps) => {
    const { pageId, state: { newPageSection, pageSectionToEdit }, refetchPageSections, pageSectionFormModal } = usePageContext()

    const { mutateAsync: updateSortOrder } = useHandleReactQueryMutation(useUpdatePageSectionSortOrderMutation())
    const { mutate: createPageSection } = useHandleReactQueryMutation(useCreateButtonPageSectionMutation({
        onSuccess: async data => {
            if (newPageSection?.sortOrder !== undefined && data.createPageSection?.pageSection?.id) await updateSortOrder({ pageSectionId: data.createPageSection?.pageSection?.id, newSortOrder: newPageSection.sortOrder })
            await refetchPageSections()
            pageSectionFormModal.close()
        }
    }))

    const { mutate: updatePageSection } = useHandleReactQueryMutation(useUpdateButtonPageSectionMutation({
        onSuccess: async () => {
            await refetchPageSections()
            pageSectionFormModal.close()
        }
    }))

    return (
        <Formik
            initialValues={{
                buttons: pageSectionToEdit?.buttons?.buttons || [ { type: '' as ButtonType, url: '', label: '', id: v4(), } ],
            }}
            validationSchema={yup.object({
                buttons: buttonsSchema,
            })}
            onSubmit={async (values) => {
                if (pageSectionToEdit) {
                    updatePageSection({
                        id: pageSectionToEdit.id,
                        ...values,
                    })
                } else {
                    createPageSection({
                        pageId,
                        ...values,
                    })
                }
            }}
        >
            {formikProps => {
                submitFormRef.current = formikProps.submitForm
                return (
                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormikPatchTouched />
                        <FormikButtonsField single enableDescription field={{ name: 'buttons' }} />
                    </Form>
                )
            }}
        </Formik>
    )
}