import {
	Button,
	createStyles,
	IconButton,
	makeStyles,
	Tooltip,
	useTheme,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import clsx from 'clsx'
import { useRef } from 'react'
import { useNewsletterEditContext } from './newsletter-edit'
import { useHoverDirty } from 'react-use'
import { Modal, useModal } from './modal'
import { Formik, Form } from 'formik'
import {
	FileFieldValue,
	FormikImageField,
	FormikTextInput,
	useImageFieldChangeHandler,
} from './forms'
import * as yup from 'yup'
import { useUpdateNewsletterHeaderMutation } from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { NewsletterHeader } from './newsletter-header'

const useStyles = makeStyles(theme =>
	createStyles({
		iconContainer: {
			position: 'absolute',
			color: 'white',
			display: 'flex',
			transition: 'opacity .2s ease-in-out',
			zIndex: 10,
		},
		topRight: {
			top: theme.spacing(1),
			right: theme.spacing(1),
		},
		icon: {
			backgroundColor: `${theme.palette.grey[300]}`,
			transition: 'opacity .2s ease-in-out',
			opacity: 0.5,
			'&:hover': {
				backgroundColor: `${theme.palette.grey[300]}`,
				opacity: 1,
			},
		},
		coverImage: {
			height: 250,
			borderTopLeftRadius: theme.shape.borderRadius,
			borderTopRightRadius: theme.shape.borderRadius,
		},
		title: {
			padding: theme.spacing(1),
			paddingTop: 0,
		},
		subtitle: {
			color: theme.palette.grey[700],
			marginBottom: theme.spacing(1),
		},
	})
)

export const NewsletterHeaderEdit = () => {
	const styles = useStyles()
	const theme = useTheme()

	const { newsletter, refetchNewsletter } = useNewsletterEditContext()

	const ref = useRef<HTMLDivElement>(null)
	const isHovering = useHoverDirty(ref)

	const modal = useModal()
	const fileChangeHandler = useImageFieldChangeHandler()
	const { mutate } = useHandleReactQueryMutation(
		useUpdateNewsletterHeaderMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				modal.close()
			},
		})
	)

	return (
		<>
			<div ref={ref} style={{ position: 'relative' }}>
				<div
					className={clsx(styles.iconContainer, styles.topRight)}
					style={{ opacity: isHovering ? 1 : 0 }}
				>
					<Tooltip title='Edit section'>
						<IconButton
							size='small'
							className={styles.icon}
							onClick={modal.open}
						>
							<Edit />
						</IconButton>
					</Tooltip>
				</div>

				<NewsletterHeader newsletter={newsletter} onClick={modal.open} />
			</div>

			<Formik
				initialValues={{
					title: newsletter.title || '',
					coverImage: newsletter.coverImage as FileFieldValue,
				}}
				validationSchema={yup.object({
					title: yup.string().required('Required'),
				})}
				onSubmit={async values => {
					mutate({
						id: newsletter.id,
						title: values.title,
						...(await fileChangeHandler({
							oldValue: newsletter.coverImage,
							newValue: values.coverImage,
							filePropertyName: 'coverImage',
						})),
					})
				}}
			>
				{formikProps => (
					<Form>
						<Modal
							{...modal.props}
							title='Edit Newsletter Header'
							dismissible
							dividers
							closeButton
							size='sm'
							actions={
								<>
									<Button
										disabled={Boolean(formikProps.errors.coverImage)}
										type='submit'
										onClick={formikProps.submitForm}
										style={{ minWidth: 100 }}
										variant='contained'
										color='primary'
									>
										Save
									</Button>
								</>
							}
							children={
								<>
									<FormikImageField
										field={{ name: 'coverImage', label: 'Cover Image' }}
										aspectRatio={3 / 1}
                                        aspectRatioHelper='3:1 aspect ratio (e.g. 1500px by 500px)'
										style={{ marginBottom: theme.spacing(2) }}
									/>
									<FormikTextInput
										noMargin
										style={{
											marginLeft: theme.spacing(0.5),
											marginRight: theme.spacing(0.5),
										}}
										fieldProps={{ name: 'title', label: 'Title' }}
									/>
								</>
							}
						/>
					</Form>
				)}
			</Formik>
		</>
	)
}
