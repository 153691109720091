import clsx from 'clsx'
import { CSSProperties, ReactNode } from 'react'

interface IHeaderProps {
    left?: ReactNode
    center?: ReactNode
    right?: ReactNode
    style?: CSSProperties
    className?: string
}
export const Header = ({ left, center, right, style, className }: IHeaderProps) => {
    return (
        <div className={clsx('header', className)} style={style}>
            <div><div>{left}</div></div>
            <div><div style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>{center}</div></div>
            <div><div>{right}</div></div>
        </div>
    )
}