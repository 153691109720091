import { IconButton, Menu, MenuItem, Typography, useTheme } from '@material-ui/core'
import { NavigateBefore, NavigateNext, ExpandMore, ExpandLess } from '@material-ui/icons'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useCalendarEventsForWeekQuery, useCalendarGroupsQuery, useMemberGroupsEventsForWeekQuery, useSchoolCalendarGroupListQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useDrag } from '@use-gesture/react'
import { REFETCH_INTERVAL } from '../constants'
import { EventsList } from './events-list'
import { useMobileContext } from './school'
import { sortBySortOrder } from '../helpers'
import { CalendarGroupFragment, EventFeedFragment } from '../graphql/autogenerate/operations'
import { SchoolNavBar } from './school-nav-bar'

interface ICalendarMenuProps {
    selectedCalendarGroup?: CalendarGroupFragment
    setSelectedCalendarGroup: Dispatch<SetStateAction<CalendarGroupFragment | undefined>>
}
const CalendarMenu = ({ setSelectedCalendarGroup, selectedCalendarGroup }: ICalendarMenuProps) => {
    const { state: { school: { id: schoolId } } } = useSchoolContext()
    const { data } = useHandleReactQuery(useCalendarGroupsQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL }))
    const calendarGroups = data?.calendarGroups?.nodes
    const [ anchorEl, setAnchorEl ] = useState<HTMLLIElement>()
    const handleClose = useCallback(() => setAnchorEl(undefined), [])

    return (
        <div>
            {calendarGroups?.length ?
                <>
                    <MenuItem onClick={evt => setAnchorEl(evt.currentTarget)}>
                        <Typography style={{ fontWeight: 700, display: 'flex', alignContent: 'center' }}>
                            <div>{selectedCalendarGroup ? selectedCalendarGroup.title : 'Calendar'}</div>
                            {anchorEl ? <ExpandLess /> : <ExpandMore />}
                        </Typography>
                    </MenuItem>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                setSelectedCalendarGroup(undefined)
                                handleClose()
                            }}
                        >
                            Calendar
                        </MenuItem>
                        {sortBySortOrder(calendarGroups).map(o =>
                            <MenuItem
                                key={o.id}
                                onClick={() => {
                                    setSelectedCalendarGroup(o)
                                    handleClose()
                                }}
                            >
                                {o.title}
                            </MenuItem>
                        )}
                    </Menu>
                </>
                :
                'Calendar'
            }
        </div>
    )
}

export const SchoolCalendar = () => {
    const theme = useTheme()
    const { state: { school: { id: schoolId, slug } } } = useSchoolContext()

    const [ day, setDay ] = useState(dayjs().startOf('isoWeek'))
    const [ selectedCalendarGroup, setSelectedCalendarGroup ] = useState<CalendarGroupFragment>()
    const [ events, setEvents ] = useState<EventFeedFragment[]>([])

    /* 
        Toggle between a query for all the current user's events (events for all groups he's subscribed to) 
        OR
        All events for the selected calendar group's calendars
    */
    useHandleReactQuery(useMemberGroupsEventsForWeekQuery(
        { schoolId, date: day.toISOString() },
        {
            refetchInterval: REFETCH_INTERVAL,
            onSuccess: data => setEvents(data.currentPersonSchoolCalendarEventsInWeek?.nodes || []),
            enabled: !Boolean(selectedCalendarGroup)
        }
    ))
    useHandleReactQuery(useCalendarEventsForWeekQuery(
        { calendarIds: selectedCalendarGroup?.calendarGroupCalendars.nodes.map(o => o.calendar?.id || null) || [], date: day.toISOString() },
        {
            refetchInterval: REFETCH_INTERVAL,
            onSuccess: data => setEvents(data.getCalendarEventsForWeek?.nodes || []),
            enabled: Boolean(selectedCalendarGroup)
        }
    ))

    // Handle swiping left and right to advance/reverse selected week
    const bind = useDrag(({ active, movement: [ mx ], direction: [ xDir ] }) => {
        const trigger = Math.abs(mx) > 60 // Make sure the swipe was a full swipe, not some half hearted flick or brush of the screen
        if (!active && trigger) {
            if (xDir === -1) {
                setDay(day.add(1, 'week'))
            } else {
                setDay(day.subtract(1, 'week'))
            }
        }
    })

    return (
        <>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, paddingBottom: 55, backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
                <SchoolNavBar style={{ padding: `0px ${theme.spacing(1)}px`, justifyContent: 'center' }}>
                    <CalendarMenu selectedCalendarGroup={selectedCalendarGroup} setSelectedCalendarGroup={setSelectedCalendarGroup} />
                </SchoolNavBar>

                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden', }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <IconButton onClick={() => setDay(day.subtract(1, 'week'))} children={<NavigateBefore />} />
                        <Typography
                            variant='subtitle1'
                            color='textSecondary'
                            style={{ flex: 1 }}
                            align='center'
                        >
                            Week {day.subtract(2, 'day').week()}, {day.format('MMMM D')} &ndash; {day.endOf('isoWeek').format(`${day.startOf('isoWeek').month() === day.endOf('isoWeek').month() ? '' : 'MMMM'} D`)}
                        </Typography>
                        <IconButton onClick={() => setDay(day.add(1, 'week'))} children={<NavigateNext />} />
                    </div>

                    <div {...bind()} style={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: 0, overflowY: 'auto', padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1) + 55}px ${theme.spacing(1)}px` }}>
                        <EventsList
                            events={events}
                            day={day}
                            onEventPressed={({ title }) => {
                                gtag('event', 'event_viewed', {
                                    event_category: slug,
                                    event_label: title,
                                })
                            }}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}