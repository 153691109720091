import { makeStyles, createStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { MemoizedCalendarSidebarCreateEvent } from './calendar-sidebar-create-event'
import clsx from 'clsx'
import { CalendarActionType, useCalendarContext } from '../stores/calendar'
import { MemoizedCalendarSidebarDatePicker } from './calendar-sidebar-date-picker'
import { MemoizedCalendarSidebarCalendarsList } from './calendar-sidebar-calendars-list'
import { useScrollbarWidth } from 'react-use'
import { useSpring, animated } from 'react-spring'
import { useSchoolContext } from '../stores/school'

const useStyles = makeStyles(theme =>
    createStyles({
        overflowContainer: {
            flex: 1
        },
        overflowContainerOpen: {
            overflow: 'overlay',
        },
        overflowContainerClosed: {
            overflow: 'hidden',
        },
        sidebarContent: {
            minWidth: 270 - theme.spacing(2)
        }
    })
)

const CalendarSidebar = () => {
    const {
        state: { calendarGroups, sidebarIsOpen, selectedDate },
        dispatch,
        actions: { refetch: { calendarGroups: refetchCalendarGroups } }
    } = useCalendarContext()
    const { state: { school: { divisions }, groups } } = useSchoolContext()

    const styles = useStyles()
    const theme = useTheme()

    const scrollBarWidth = useScrollbarWidth()
    const { scrollBarPadding } = makeStyles(() =>
        createStyles({
            scrollBarPadding: {
                paddingRight: `${scrollBarWidth}px`,
            },
        })
    )()

    const drawerSlide = useSpring({
        transform: sidebarIsOpen ? 'translate(0)' : 'translate(-270px)',
    })

    const drawerShrink = useSpring({
        width: sidebarIsOpen ? 270 : 0,
        paddingRight: sidebarIsOpen ? theme.spacing(2) : 0,
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MemoizedCalendarSidebarCreateEvent dispatch={dispatch} sidebarIsOpen={sidebarIsOpen} />
            <div style={{ marginTop: 75 }} />
            <animated.div
                className={clsx(sidebarIsOpen ? [ styles.overflowContainerOpen, scrollBarPadding ] : styles.overflowContainerClosed, styles.overflowContainer)}
                onMouseEnter={() => dispatch({ type: CalendarActionType.disableDateScrolling })}
                style={drawerShrink}
            >
                <animated.div
                    style={drawerSlide}
                    className={styles.sidebarContent}
                >
                    <MemoizedCalendarSidebarDatePicker dispatch={dispatch} selectedDate={selectedDate} />
                    <MemoizedCalendarSidebarCalendarsList dispatch={dispatch} divisions={divisions.nodes} calendarGroups={calendarGroups} refetchCalendarGroups={refetchCalendarGroups} groups={groups} />
                </animated.div>
            </animated.div>
        </div>
    )
}

export const MemoizedCalendarSidebar = React.memo(CalendarSidebar)