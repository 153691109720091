import { useEffect, useRef } from 'react'
import { useDebounce } from 'react-use'

interface IFormikValuesOnChangeProps<ValuesType> {
    values: ValuesType
    onChange: (prevValues: ValuesType, nextValues: ValuesType) => void | Promise<void>
    debounceMs?: number
}

export const FormikValuesOnChange = <ValuesType,> ({ values, onChange, debounceMs }: IFormikValuesOnChangeProps<ValuesType>) => {

    const valuesRef = useRef(values)

    const [ , cancel ] = useDebounce(
        () => {
            onChange(valuesRef.current, values)
            valuesRef.current = values
        },
        debounceMs,
        [ values ]
    )

    useEffect(() => {
        return cancel
    }, [])

    return null
}

