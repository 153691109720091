import { GroupResourcesDisplay } from './group-resources-display'
import { useSchoolGroupContext } from './school-group-home'

export const SchoolGroupResources = () => {

    const { state: { group } } = useSchoolGroupContext()

    return (
        <GroupResourcesDisplay groupId={group.id} />
    )
}