import { makeStyles, createStyles } from "@material-ui/core"

export const useCommonStyles = makeStyles((theme) =>
    createStyles({
        baseIconColor: {
            color: theme.palette.grey[ 600 ]
        },
        borders: {
            [ theme.breakpoints.up('sm') ]: {
                width: '100%',
                padding: theme.spacing(4),
                border: '1px solid',
                borderColor: theme.palette.grey[ 300 ],
                borderRadius: theme.shape.borderRadius,
            }
        },
        backgroundInvalid: {
            backgroundColor: theme.palette.error.light,
            color: 'white',
            padding: theme.spacing(2),
            border: '1px solid',
            borderColor: theme.palette.error.light,
            borderRadius: theme.shape.borderRadius,
        },
        rootTypography: {
            ...theme.typography.body1
        },
        primaryColorHover: {
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
        errorColorHover: {
            '&:hover': {
                color: theme.palette.error.main
            }
        },
        successColorHover: {
            '&:hover': {
                color: theme.palette.success.main
            }
        },
        greyBackgroundHover: {
            '&:hover': {
                backgroundColor: `${theme.palette.grey[ 300 ]}70`
            }
        },
        elevationOnHover: {
            '&:hover': {
                boxShadow: theme.shadows[ 15 ],
                transition: theme.transitions.create('box-shadow'),
                cursor: 'pointer',
            }
        },
        greyColor: {
            color: theme.palette.grey[ 500 ]
        },
        ellipsisText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    })
)