import dayjs from 'dayjs'
import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentPersonSchoolPermisionsQuery } from '../graphql/autogenerate/react-query'
import { useSchoolDetailQueryOverride } from '../graphql/use-school-detail-query-override'
import { useHandleReactQuery, useMobileDetect } from '../hooks'
import {
	NativeBridgeProvider,
	useNativeBridge,
} from '../hooks/use-native-bridge'
import { ShoolContextProvider } from '../stores/school'
import { LoadingOverlay } from './loading-overlay'
import { SchoolNotFound } from './school-not-found'
import { SchoolThemeProvider } from './school-theme-provider'
import { REFETCH_INTERVAL } from '../constants'

interface ISchoolProviderProps {
	refetchEnabled?: boolean
}

export const SchoolProvider: FC<ISchoolProviderProps> = ({
	children,
	refetchEnabled,
}) => {
	const { schoolSlug } = useParams()

	const schoolDetailQuery = useHandleReactQuery(
		useSchoolDetailQueryOverride(
			{ slug: schoolSlug },
			{
				refetchInterval: refetchEnabled ? REFETCH_INTERVAL : undefined,
				enabled: Boolean(schoolSlug),
			}
		)
	)
	const schoolThemeData = schoolDetailQuery.data?.schoolBySlug?.theme

	const bridgeContext = useNativeBridge()

	const { data, refetch: refetchCurrentPersonPermissions } = useHandleReactQuery(
		useCurrentPersonSchoolPermisionsQuery(
			{
				schoolId: schoolDetailQuery.data?.schoolBySlug?.id!,
				installationFilter: bridgeContext && {
					deviceName: { equalTo: bridgeContext.device.deviceName },
					platform: { equalTo: bridgeContext.device.platform },
				},
			},
			{
				enabled: Boolean(schoolDetailQuery.data?.schoolBySlug?.id),
				refetchInterval: refetchEnabled ? REFETCH_INTERVAL / 3 : undefined,
			}
		)
	)
	const currentUserPermissions = data?.currentUserPermissions

	useEffect(() => {
		if (schoolDetailQuery.data?.schoolBySlug?.schoolSetting?.timeZone) {
			dayjs.tz.setDefault(
				schoolDetailQuery.data.schoolBySlug.schoolSetting.timeZone
			)
		}
	}, [schoolDetailQuery.data?.schoolBySlug?.schoolSetting?.timeZone])

	const schoolwideAdmin = useMemo(() => {
		if (!schoolDetailQuery.data?.schoolBySlug || !currentUserPermissions)
			return false
		const schoolwideGroupId =
			schoolDetailQuery.data.schoolBySlug.divisions.nodes.find(o => o.schoolwide)
				?.group?.id
		return Boolean(
			schoolwideGroupId &&
				currentUserPermissions.groups.nodes.some(
					o => o.admin && o.groupId === schoolwideGroupId
				)
		)
	}, [schoolDetailQuery.data, currentUserPermissions])

	/** 
        If a school has provided a Google Analytics tag, activate their tracking.
    */
	const { isMobile } = useMobileDetect()
	useEffect(() => {
		const googleAnalyticsId =
			schoolDetailQuery.data?.schoolBySlug?.schoolSetting?.googleAnalyticsId

		/* 
            This is our "white label" analytics solution for now - allow schools to provide a Google Analytics ID and we'll feed analytics to them directly.
            Since the audience is primarily school admins, only track page views on the app (mobile).
        */
		if (googleAnalyticsId && isMobile()) {
			gtag('config', googleAnalyticsId)
			// @ts-ignore
			window[`ga-disable-${googleAnalyticsId}`] = false
		}

		return () => {
			const googleAnalyticsId =
				schoolDetailQuery.data?.schoolBySlug?.schoolSetting?.googleAnalyticsId
			// @ts-ignore
			if (googleAnalyticsId) window[`ga-disable-${googleAnalyticsId}`] = true
		}
	}, [schoolDetailQuery.data?.schoolBySlug?.schoolSetting?.googleAnalyticsId])

	// Show school cannot be found message if fetched but no school
	if (
		schoolDetailQuery.isFetched &&
		!schoolDetailQuery.isLoading &&
		!schoolDetailQuery.data?.schoolBySlug
	)
		return <SchoolNotFound />

	if (!schoolDetailQuery.data?.schoolBySlug || !currentUserPermissions)
		return <LoadingOverlay />

	return (
		<SchoolThemeProvider schoolTheme={schoolThemeData}>
			<ShoolContextProvider
				school={schoolDetailQuery.data.schoolBySlug}
				currentUserPermissions={{ ...currentUserPermissions, schoolwideAdmin }}
				refetchSchool={async () => {
					await schoolDetailQuery.refetch()
					await refetchCurrentPersonPermissions()
				}}
			>
				<NativeBridgeProvider>{children}</NativeBridgeProvider>
			</ShoolContextProvider>
		</SchoolThemeProvider>
	)
}

// Better version we can't do until this is fixed: https://github.com/dotansimha/graphql-code-generator/issues/6239
// export const AdminSchool = () => {
//     const { schoolSlug } = useParams()

//     const { data, refetch } = useHandleReactQuery(useSchoolDetailQuery(
//         { schoolId: schoolSlug },
//         {
//             enabled: Boolean(schoolSlug),
//             onSuccess: ({ school }) => {
//                 if (school?.schoolSetting?.timeZone) dayjs.tz.setDefault(school.schoolSetting.timeZone)
//             }
//         }
//     ))

//     useEffect(() => {
//         return () => {
//             dayjs.tz.guess()
//         }
//     }, [])

//     if (!data?.school) return <LoadingOverlay />

//     return (
//         <SchoolThemeProvider schoolTheme={data?.school?.theme} >
//             <ShoolContextProvider school={data.school} refetchSchool={refetch}>
//                 <AdminSchoolHome schoolDetail={data.school} />
//             </ShoolContextProvider>
//         </SchoolThemeProvider>
//     )
// }
