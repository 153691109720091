import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import React from 'react'
import { CalendarActionType, ICalendarEventCell } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            '&:hover': {
                cursor: 'pointer',
                opacity: .8,
            }
        },
    })
)

interface ICalendarEventTableRowProps extends ICalendarDispatchProp {
    event: ICalendarEventCell
}

export const CalendarEventTableRow = ({ event, dispatch }: ICalendarEventTableRowProps) => {
    const { title, startDate, endDate } = event
    const styles = useStyles()
    const theme = useTheme()
    const sameAmPm = startDate.format('a') !== endDate.format('a')
    const endDateLabel = endDate.minute() === 0 ? endDate.format('ha') : endDate.format('h:mma')
    const startEndLabel = <>{`${startDate.minute() === 0 ? startDate.format('h') : startDate.format('h:mm')}${sameAmPm ? startDate.format('a') : ''}`} &ndash; {endDateLabel}</>
    return (
        <tr style={{ marginBottom: theme.spacing(.5) }} className={styles.row} onClick={() => dispatch({ type: CalendarActionType.editEvent, payload: event })}>
            <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{startEndLabel}</span>
                </div>
            </td>

            <td>
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                    <span style={{ marginLeft: theme.spacing(.5) }}><b>{title}</b></span>
                </div>
            </td>
        </tr>
    )
}