import { TextField, InputAdornment, useTheme, Link, Box } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { CalendarActionType } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

interface ICalendarSidebarCalendarsListFilterProps extends ICalendarDispatchProp {
    calendarSearchTerm: string
    handleSearchTermChange: (searchTerm: string) => void
}

const CalendarSidebarCalendarsListFilter = ({ calendarSearchTerm, handleSearchTermChange, dispatch }: ICalendarSidebarCalendarsListFilterProps) => {
    const theme = useTheme()

    const [ value, setValue ] = useState(calendarSearchTerm)

    const [ , cancel ] = useDebounce(
        () => {
            handleSearchTermChange(value)
        },
        200,
        [ value ]
    )

    useEffect(() => {
        return cancel
    }, [])

    return (
        <Box mx={1}>
            <TextField
                value={value}
                onChange={(ev) => setValue(ev.currentTarget.value)}
                variant='filled'
                placeholder='Search for calendars'
                InputProps={{
                    startAdornment:
                        <InputAdornment position='start' style={{ marginTop: 0, color: theme.palette.grey[ 600 ] }}>
                            <CalendarToday />
                        </InputAdornment>,
                }}
                inputProps={{
                    style: {
                        padding: '12px 12px 10px'
                    }
                }}
                onFocus={() => dispatch({ type: CalendarActionType.disableShortcuts })}
                onBlur={() => dispatch({ type: CalendarActionType.enableShortcuts })}
            />
            <Link
                style={{
                    transition: 'opacity .2s',
                    opacity: calendarSearchTerm.length ? '1' : '0',
                    cursor: 'pointer',
                    paddingLeft: theme.spacing(.5)
                }}
                onClick={() => setValue('')}
            >
                <small>Clear filter</small>
            </Link>
        </Box>
    )
}

export const MemoizedCalendarSidebarCalendarsListFilter = React.memo(CalendarSidebarCalendarsListFilter)