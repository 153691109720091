import { Grid, Box, Typography, Button, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import { useAuth, useUrlQuery } from '../hooks'
import { usePaddedBorderStyles } from '../styles'
import { FormikTextInput } from './forms'
import * as Yup from 'yup'
import logo from '../images/logo.svg'

export const ForgotPassword = () => {
    const query = useUrlQuery()
    const classes = usePaddedBorderStyles()
    const theme = useTheme()
    const { forgotPassword } = useAuth()

    /* 
        You can provide a redirect URL in the query if you want the user to be redirected to a particular page after successfully resetting their password.
        Useful for things like invitation accept pages that have password reset links.
    */
    const redirect = query.get('redirect')

    return (
        <Formik
            initialValues={{
                email: query.get('email') || '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().required('Required').email('Must be a valid email address.'),
            })}
            onSubmit={(values) => forgotPassword({ email: values.email, redirect: redirect && encodeURIComponent(redirect) })}
        >
            <Grid justify='center' alignItems='center' container direction='column' style={{ height: '100%' }}>
                <Grid className={clsx(classes.borders)} container item xs={12} sm={10} md={8} lg={5} direction='column' justify='center' component={Form} style={{ padding: theme.spacing(2) }}>
                    <Grid item container justify='center' alignContent='center' direction='column'>
                        <Box marginBottom={2} display='flex' justifyContent='center' >
                            <Link to='/'><img src={logo} alt='Legit Apps logo' style={{ maxWidth: 75 }} /></Link>
                        </Box>
                        <Box marginBottom={4}>
                            <Typography variant='h5' align='center'>Forgot Password</Typography>
                        </Box>
                    </Grid>
                    <Grid item container>
                        <FormikTextInput fieldProps={{ name: 'email', label: 'Email' }} />
                    </Grid>
                    <Grid item>
                        <Box marginX={1} marginY={2} display='flex' justifyContent='space-between'>
                            <Link to='/login'>
                                <Button variant='text' color='primary' disableElevation>
                                    Sign in
                                </Button>
                            </Link>
                            <Button type='submit' style={{ minWidth: 100 }} variant='contained' color='primary' disableElevation>
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Formik>
    )
}