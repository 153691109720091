import { Box, Paper, useTheme } from '@material-ui/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import {
	GroupCategoryListFieldsFragment,
	GroupListFieldsFragment,
} from '../graphql/autogenerate/operations'
import { TypedEvent, gatherAllGroupsForSchoolFragment } from '../helpers'
import { useAppState } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { AdminResourcesSidebar } from './admin-resources-sidebar'
import { GroupResources } from './group-resources'
import { ResourceFolderDetail } from './resource-folder-detail'

interface IState {
	searchTerm: string
	selectedTabIndex: number
	selectedGroup?: {
		group: GroupListFieldsFragment
		groupCategory?: GroupCategoryListFieldsFragment
	}
	openResourceFolder?: {
		resourceId: string
		color: string
	}
}

export const AdminResources = () => {
	const theme = useTheme()

	const [state, setState] = useState<IState>({
		searchTerm: '',
		selectedTabIndex: 0,
	})

	const {
		state: { currentUser },
	} = useAppState()

	const {
		state: { currentUserPermissions, school },
	} = useSchoolContext()
	const { divisions } = school

	useEffect(() => {
		const schoolwideGroup = divisions.nodes.find(o => o.schoolwide)?.group

		if (!state.selectedGroup) {
			if (
				currentUserPermissions.schoolwideAdmin ||
				currentUser?.appAdministrator
			) {
				if (schoolwideGroup)
					setState(_state => ({
						..._state,
						selectedGroup: { group: schoolwideGroup },
					}))
			} else {
				const firstAdminOfGroup = currentUserPermissions.groups.nodes.find(
					o => o.admin
				)
				const group = gatherAllGroupsForSchoolFragment(school).find(
					o => o.id === firstAdminOfGroup?.groupId
				)
				if (group)
					setState(_state => ({
						..._state,
						selectedGroup: { group },
					}))
			}
		}
	}, [divisions, state.selectedGroup])

	const [styles, api] = useSpring(() => ({ opacity: 0, flex: 0 }))
	const folderDeletedEmitter = useRef(new TypedEvent<string>())
	const folderSavedEmitter = useRef(new TypedEvent<string>())

	const onParentFolderClickedRef = useRef<() => void>()
	const folderPressed = useCallback(
		(color: string, resourceId?: string) => {
			if (resourceId) {
				setState(_state => ({
					..._state,
					openResourceFolder: { resourceId, color },
				}))
				api.start({ opacity: 1, flex: 1 })

				if (
					resourceId === state.openResourceFolder?.resourceId &&
					onParentFolderClickedRef.current
				)
					onParentFolderClickedRef.current()
			} else {
				setState(_state => ({ ..._state, openResourceFolder: undefined }))
			}
		},
		[state]
	)

	return (
		<>
			<div
				style={{
					display: 'flex',
					flex: 1,
					minHeight: 0,
					margin: theme.spacing(2),
				}}
			>
				<AdminResourcesSidebar
					style={{ marginRight: theme.spacing(2) }}
					onGroupSelected={(group, groupCategory) => {
						setState(_state => ({
							..._state,
							selectedGroup: { group, groupCategory },
							openResourceFolder: undefined,
						}))
						api.set({ opacity: 0, flex: 0 })
					}}
				/>

				<Paper style={{ flex: 1, overflow: 'hidden' }}>
					<Box
						p={2}
						style={{ height: '100%' }}
						display='flex'
						minHeight={0}
						flexDirection='column'
						overflow='auto'
					>
						{state.selectedGroup && (
							<GroupResources
								group={state.selectedGroup.group}
								folderPressed={resource =>
									folderPressed(
										state.selectedGroup?.groupCategory?.iconBackgroundColor ||
											theme.palette.primary.main,
										resource?.id
									)
								}
								folderDeletedEmitter={folderDeletedEmitter.current}
								folderSavedEmitter={folderSavedEmitter.current}
								color={theme.palette.primary.main}
							/>
						)}
					</Box>
				</Paper>

				<animated.div style={{ ...styles, maxWidth: 500 }}>
					{state?.openResourceFolder && (
						<Paper
							style={{
								padding: theme.spacing(2),
								marginLeft: theme.spacing(2),
								height: '100%',
							}}
						>
							<ResourceFolderDetail
								{...state.openResourceFolder}
								closePressed={() => {
									api.start({
										opacity: 0,
										flex: 0,
										onChange: stuff => {
											if (stuff.value.flex < 0.2) api.set({ opacity: 0, flex: 0 })
										},
										onRest: () =>
											setState(_state => ({
												..._state,
												openResourceFolder: undefined,
											})),
									})
								}}
								folderDeleted={resourceId =>
									folderDeletedEmitter.current.emit(resourceId)
								}
								folderSaved={resourceId => folderSavedEmitter.current.emit(resourceId)}
								onParentFolderClickedRef={onParentFolderClickedRef}
							/>
						</Paper>
					)}
				</animated.div>
			</div>
		</>
	)
}
