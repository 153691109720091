import { Route, Routes } from 'react-router'
import { SchoolSponsorDetail } from './school-sponsor-detail'
import { SchoolSponsorsList } from './school-sponsors-list'


export const SchoolSponsors = () => {
    return (
        <Routes>
            <Route path='*' element={<SchoolSponsorsList />} />
            <Route path=':sponsorId' element={<SchoolSponsorDetail />} />
        </Routes>
    )
}

