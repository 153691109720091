
import { Modal, IModalControlProps } from './modal'
import { useHandleReactQueryMutation } from '../hooks'
import { Button, useTheme, makeStyles, createStyles, Box, Typography } from '@material-ui/core'
import { GroupCategoryListFieldsFragment, SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { Delete, DragHandle, Remove } from '@material-ui/icons'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { useCallback } from 'react'
import { useCommonStyles } from '../styles'
import Sortable from 'sortablejs'
import clsx from 'clsx'
import { useState } from 'react'
import { ConfirmDialog } from './confirm-dialog'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { FormikTextInput, FormikDynamicFieldsTouched, FormikIconSelector, FormikColorPicker } from './forms'
import { v4 } from 'uuid'
import { VALIDATION_REGEX } from './forms/constants'
import { Callout } from './callout'
import { IconType } from '../graphql/autogenerate/schemas'
import { useCreateGroupCategoryMutation, useDeleteGroupCategoryMutation, useUpdateGroupCategoryMutation } from '../graphql/autogenerate/react-query'

const useStyles = makeStyles((theme) =>
    createStyles({
        grid: {
            display: 'grid',
            gridTemplateColumns: '30px 55px 55px auto 25px',
            columnGap: theme.spacing(1),
            rowGap: theme.spacing(1),
            justifyItems: 'stretch',
            alignItems: 'start',
            flex: 1,
            marginBottom: theme.spacing(1),
        },
        inputHeight: {
            height: 56
        },
        sortableHandle: {
            cursor: 'pointer',
            justifySelf: 'center'
        }
    })
)

interface IEditDivisionsButtonProps {
    modalControls: IModalControlProps
    division: SchoolDivisionsFragment
    refetch?: () => any
    groupCategories?: GroupCategoryListFieldsFragment[]
}
export const EditGroupCategories = ({ modalControls, refetch, division, groupCategories }: IEditDivisionsButtonProps) => {

    const { mutateAsync: createGroupCategory } = useHandleReactQueryMutation(useCreateGroupCategoryMutation())
    const { mutateAsync: updateGroupCategory } = useHandleReactQueryMutation(useUpdateGroupCategoryMutation())

    const [ groupCategoryToDeleteId, setGroupCategoryToDeleteId ] = useState<string>()
    const { mutate: deleteGroupCategory } = useHandleReactQueryMutation(useDeleteGroupCategoryMutation())

    const groupCategorySchema = Yup.object({
        id: Yup.string().required('Required'),
        isNew: Yup.boolean().required('Required').default(true),
        sortOrder: Yup.number().required('Required'),
        iconType: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        color: Yup.string().nullable().matches(VALIDATION_REGEX.hexCode, 'Must be a valid hex color (e.g. #F5B517).'),
    })

    const validationSchema = Yup.object({
        groupCategories: Yup.array().of(groupCategorySchema).required('Required')
    })

    const initialValues: {
        groupCategories: IGroupCategoryFormRow[]
    } = {
        groupCategories: groupCategories?.map((g, index) => ({
            id: g.id,
            isNew: false,
            sortOrder: index,
            iconType: g.iconType,
            name: g.name,
            color: g.iconBackgroundColor
        })) || []
    }

    return (
        <>
            <Button style={{ minWidth: 100 }} variant='contained' onClick={modalControls.open}>
                Edit Group Categories
            </Button>

            {modalControls.props.isOpen &&
                <Formik
                    validateOnMount
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        const promises: Promise<any>[] = []

                        values.groupCategories.forEach(groupCategory => {
                            if (groupCategory.isNew) {
                                promises.push(createGroupCategory({ ...groupCategory, divisionId: division.id }))
                            } else {
                                promises.push(updateGroupCategory({ ...groupCategory }))
                            }
                        })

                        await Promise.all(promises)
                        refetch && await refetch()
                        actions.setValues(values => ({ ...values, groupCategories: values.groupCategories.map(g => ({ ...g, isNew: false })) }))
                        modalControls.close()
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    children={({ values, submitForm, setValues }) => {
                        // Store the addGroupToCategory function out here so we can set it inside the <FieldArray /> component but use it in the <Modal actions={...} /> actions footer.
                        let addGroupCategory: () => void

                        return (
                            <>
                                <Modal
                                    {...modalControls.props}
                                    title={`Edit Group Categories: ${division.name}`}
                                    size='md'
                                    dividers
                                    disableEscapeKeyDown
                                    closeButton
                                    actions={
                                        <Box flex={1} display='flex' justifyContent='space-between'>
                                            {values.groupCategories.length > 0 &&
                                                <Button type='button' variant='outlined' color='primary' onClick={() => addGroupCategory && addGroupCategory()}>Add Group Category</Button>
                                            }
                                            <Box display='flex' justifyContent='flex-end' flex={1}>
                                                <Box mr={1}><Button disabled={values.groupCategories.length === 0} style={{ minWidth: 100 }} type='submit' variant='outlined' color='primary' onClick={submitForm}>Save</Button></Box>
                                            </Box>
                                        </Box>
                                    }
                                >
                                    <FormikDynamicFieldsTouched />
                                    <Form>
                                        <Callout
                                            children={
                                                <div>
                                                    <p><b>Group Categories</b> are used to organize the <b>Groups</b> within your school.</p>
                                                    <p>Common categories include <b>Grades</b>, <b>Athletics</b>, and <b>Clubs</b>.</p>
                                                    <ul>
                                                        <li>Each Category has its own color and icon to help people quickly visually identify Groups and content within that Category.</li>
                                                        <li>The sort order of Categories controls what order people see the Categories when selecting their Groups the first time they use the app.</li>
                                                        <li>Each Division can have its own unique list of Categories. (e.g. <b>Lower School</b> may have a Group Category called <i>Homerooms</i>, but <b>Upper School</b> might not. </li>
                                                        <li>Customize Group Categories to fit your school's structure!</li>
                                                    </ul>
                                                </div>
                                            }
                                            type='secondary'
                                        />
                                        <Box my={3} />
                                        <FieldArray
                                            name='groupCategories'
                                            children={arrayHelpers => {
                                                /* 
                                                    To "add" a new Group Category, we push a set of fields to the array of groupCategories in this form.
                                                    Nothing is persisted until we save the whole form.
                                                */
                                                addGroupCategory = () => arrayHelpers.push({ id: v4(), isNew: true, sortOrder: values.groupCategories.length })

                                                return (
                                                    <>
                                                        <GroupCategoryFormRows
                                                            groupCategories={values.groupCategories}
                                                            addGroupCategory={addGroupCategory}
                                                            setGroupCategoryToDeleteId={setGroupCategoryToDeleteId}
                                                            remove={arrayHelpers.remove}
                                                            setValues={setValues}
                                                        />

                                                        <ConfirmDialog
                                                            isOpen={!!groupCategoryToDeleteId}
                                                            close={() => setGroupCategoryToDeleteId(undefined)}
                                                            title='Delete Group Category?'
                                                            body='Are you sure you want to delete this Group Category? This action cannot be undone.'
                                                            confirmButton={{
                                                                label: 'Delete',
                                                                type: 'error'
                                                            }}
                                                            confirm={async () => {
                                                                if (groupCategoryToDeleteId) {
                                                                    deleteGroupCategory(
                                                                        { id: groupCategoryToDeleteId },
                                                                        {
                                                                            onSettled: async () => {
                                                                                arrayHelpers.remove(values.groupCategories.findIndex(g => g.id === groupCategoryToDeleteId))
                                                                                if (refetch) await refetch()
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }}
                                        />
                                    </Form>
                                </Modal>
                            </>
                        )
                    }}
                />
            }
        </>
    )
}

interface IGroupCategoryFormRow {
    id: string
    isNew: boolean
    sortOrder: number
    iconType: IconType
    color?: string | null
    name: string
}

interface IGroupCategoryFormRowsProps {
    groupCategories: IGroupCategoryFormRow[]
    addGroupCategory: () => void
    setGroupCategoryToDeleteId: (id: string) => void
    remove: (index: number) => void
    setValues: (values: React.SetStateAction<{ groupCategories: IGroupCategoryFormRow[] }>) => void
}

/* 
    We split out the form rows so that we can set up the sortable Ref AFTER the <Forkik /> component starts. 
    We need to do this so that we can access the "setValues" render prop from the Formik Form.
*/
const GroupCategoryFormRows = ({ groupCategories, addGroupCategory, setGroupCategoryToDeleteId, remove, setValues }: IGroupCategoryFormRowsProps) => {
    const commonStyles = useCommonStyles()
    const styles = useStyles()
    const theme = useTheme()

    const sortableListRef = useCallback((element: HTMLDivElement) => {
        if (element) {
            Sortable.create(element, {
                animation: 150,
                easing: 'cubic-bezier(1, 0, 0, 1)',
                handle: '.sortableHandle',
                draggable: '.sortableDraggable',
                store: {
                    set: async (sortable) => {
                        const ids = sortable.toArray()
                        setValues(({ groupCategories: prevGroupCategories }) => ({
                            groupCategories: prevGroupCategories.map(g => (
                                {
                                    ...g,
                                    sortOrder: ids.findIndex(id => id === g.id)
                                }
                            ))
                        }))
                    },
                    get: () => {
                        return []
                    }
                },
            })
        }
    }, [])

    // Control the manual color input into color pickers throughout the form. This will preserve a user's manual input, thus keeping a consistent palette of options in each color picker.
    const [ manualColorInputValue, setManualColorInputValue ] = useState('')


    return (
        <div ref={sortableListRef} >
            {/* If we don't have any group categories yet, show an empty state and an add button. */}
            {groupCategories.length === 0 &&
                <Box my={5} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                    <Typography variant='h5'>No Group Categories created yet.</Typography>
                    <Box mt={3}>
                        <Button type='button' variant='outlined' color='primary' onClick={addGroupCategory}>Add Group Category</Button>
                    </Box>
                </Box>
            }

            {/* If we have group categories, show the form rows. */}
            {groupCategories.length > 0 &&
                <div
                    className={clsx(styles.grid)}
                    style={{
                        borderBottom: `1px solid ${theme.palette.grey[ 400 ]}`,
                        paddingBottom: theme.spacing(.5),
                        marginBottom: theme.spacing(3)
                    }}
                >
                    <b></b>
                    <b>Icon</b>
                    <b>Color</b>
                    <b>Category Name</b>
                    <b></b>
                </div>
            }
            {groupCategories.map((groupCategory, index) => (
                <div key={groupCategory.id} data-id={groupCategory.id} className={clsx(styles.grid, 'sortableDraggable')}>
                    <DragHandle className={clsx(styles.sortableHandle, styles.inputHeight, 'sortableHandle')} />
                    <FormikIconSelector
                        fieldProps={{ name: `groupCategories.${index}.iconType`, label: 'Icon' }}
                        style={!!groupCategory.color && !!groupCategory.iconType ?
                            {
                                backgroundColor: groupCategory.color,
                                borderColor: groupCategory.color,
                            }
                            :
                            {}
                        }
                    />

                    <FormikColorPicker
                        fieldProps={{ name: `groupCategories.${index}.color`, label: 'Color' }}
                        manualColorInputControls={{ manualColorInputValue, setManualColorInputValue }}
                    />

                    <FormikTextInput
                        fieldProps={{ name: `groupCategories.${index}.name`, label: 'Category Name' }}
                        style={{ margin: 0 }}
                    />
                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className={clsx(styles.inputHeight)}
                    >
                        {groupCategory.isNew ?
                            // If this category was just added to the list (i.e. not yet persisted to the database), just remove it from the list. 
                            <AnimationIconWraper
                                children={<Remove />}
                                className={clsx(commonStyles.errorColorHover)}
                                onClick={() => remove(index)}
                                tooltip={{ title: 'Remove', placement: 'top' }}
                            />
                            :
                            // If this category was a preexisting (i.e. persisted) category, pop the delete confirm modal.
                            <AnimationIconWraper
                                children={<Delete />}
                                className={clsx(commonStyles.errorColorHover)}
                                onClick={() => setGroupCategoryToDeleteId(groupCategory.id)}
                                tooltip={{ title: 'Delete', placement: 'top' }}
                            />
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}