import { Box, Button, Grid } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useHandleReactQueryMutation } from '../hooks'
import { OnKeyPressEvent } from './on-key-press-event'
import { FormikTextInput } from './forms'
import { useCreateDivisionMutation } from '../graphql/autogenerate/react-query'

interface INewDivisionFormProps {
    schoolId: string
    afterDivisionSave?: () => any
    onFormCancel?: () => any
}

export const NewDivisionForm = ({ afterDivisionSave, schoolId, onFormCancel }: INewDivisionFormProps) => {
    const { mutate: createDivision } = useHandleReactQueryMutation(useCreateDivisionMutation())

    return (
        <Formik
            initialValues={{
                divisionName: ''
            }}
            validationSchema={Yup.object({
                divisionName: Yup.string().required('Required'),
            })}
            onSubmit={(values) => {
                createDivision(
                    {
                        name: values.divisionName,
                        schoolId
                    },
                    { onSettled: () => { afterDivisionSave && afterDivisionSave() } }
                )
            }}
        >
            {formikProps => {
                const cancel = () => {
                    formikProps.resetForm()
                    onFormCancel && onFormCancel()
                }

                return (
                    <>
                        <OnKeyPressEvent keyPress='Escape' action={cancel} />
                        <Form style={{ flex: 1 }}>
                            <Grid container>
                                <Grid item xs={12} container>
                                    <FormikTextInput
                                        fieldProps={{ name: 'divisionName', label: 'New Division Name' }}
                                        style={{ flex: 1, margin: 0 }}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs container>
                                    <Button type='submit' variant='outlined' color='primary'>
                                        Save
                                    </Button>
                                    <Box ml={1}>
                                        <Button variant='text' color='inherit' onClick={cancel}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}