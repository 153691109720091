import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import { DetailedHTMLProps, HTMLAttributes, useCallback } from 'react'
import Countdown, { CountdownProps } from 'react-countdown'

const useStyles = makeStyles(theme =>
    createStyles({
        timeContainer: {
            color: 'white',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[ 600 ],
            flex: 1,
            margin: theme.spacing(.5),
            padding: `${theme.spacing(1)}px 0px`
        },
        timeValue: {
            fontWeight: 'bold',
            fontSize: '2rem',
            lineHeight: 1,
        },
        timeLabel: {
            fontStyle: 'italic'
        },
        countdownLabel: {
            fontStyle: 'italic',
            color: theme.palette.grey[ 600 ],
        }
    })
)

interface ICountdownDisplayProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
    time: Dayjs
    label?: string | null
    hideWhenCompleted?: boolean | null
}

export const CountdownDisplay = ({ time, label, hideWhenCompleted, ...rest }: ICountdownDisplayProps) => {
    const styles = useStyles()

    const renderer: CountdownProps[ 'renderer' ] = useCallback(({ days, hours, minutes, seconds, completed }) => {
        if (completed && hideWhenCompleted) return null
        return (
            <>
                <div style={{ display: 'flex', }}>
                    <div className={styles.timeContainer}>
                        <Typography align='center' className={styles.timeValue}>{days}</Typography>
                        <Typography variant='caption' align='center' className={styles.timeLabel}>days</Typography>
                    </div>
                    <div className={styles.timeContainer}>
                        <Typography align='center' className={styles.timeValue}>{hours}</Typography>
                        <Typography variant='caption' align='center' className={styles.timeLabel}>hours</Typography>
                    </div>
                    <div className={styles.timeContainer}>
                        <Typography align='center' className={styles.timeValue}>{minutes}</Typography>
                        <Typography variant='caption' align='center' className={styles.timeLabel}>minutes</Typography>
                    </div>
                    <div className={styles.timeContainer}>
                        <Typography align='center' className={styles.timeValue}>{seconds}</Typography>
                        <Typography variant='caption' align='center' className={styles.timeLabel}>seconds</Typography>
                    </div>
                </div>
                <Typography variant='caption' align='center' className={styles.countdownLabel}>{label}</Typography>
            </>
        )
    }, [ label, hideWhenCompleted ])

    return (
        <div {...rest}>
            <Countdown
                date={time.toDate()}
                renderer={renderer}
            />

        </div>
    )
}