import { FormikProps } from 'formik'
import { useEffect, useRef } from 'react'

interface IFormikEffectProps<ValuesType> {
    formikProps: FormikProps<ValuesType>
    onChange: (prev: ValuesType, next: ValuesType, formikProps: FormikProps<ValuesType>) => void
}
export const FormikEffect = <ValuesType,> ({ formikProps, onChange }: IFormikEffectProps<ValuesType>) => {
    const valuesRef = useRef(formikProps.values)

    useEffect(() => {
        onChange(valuesRef.current, formikProps.values, formikProps)
        valuesRef.current = formikProps.values
    }, [ formikProps.values ])

    return null
}