import { Form, Formik } from 'formik'
import { MutableRefObject } from 'react'
import * as yup from 'yup'
import {
	useCreateVideoPageSectionMutation,
	useUpdatePageSectionSortOrderMutation,
	useUpdateVideoPageSectionMutation,
} from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { FormikEmbedField, embedUrlValidation } from './forms'
import { usePageContext } from './page-edit'

interface IPageSectionVideoFormProps {
	submitFormRef: MutableRefObject<(() => Promise<any>) | undefined>
}

export const PageSectionVideoForm = ({
	submitFormRef,
}: IPageSectionVideoFormProps) => {
	const {
		pageId,
		state: { newPageSection, pageSectionToEdit },
		refetchPageSections,
		pageSectionFormModal,
	} = usePageContext()

	const { mutateAsync: updateSortOrder } = useHandleReactQueryMutation(
		useUpdatePageSectionSortOrderMutation()
	)
	const { mutate: createPageSection } = useHandleReactQueryMutation(
		useCreateVideoPageSectionMutation({
			onSuccess: async data => {
				if (
					newPageSection?.sortOrder !== undefined &&
					data.createPageSection?.pageSection?.id
				)
					await updateSortOrder({
						pageSectionId: data.createPageSection?.pageSection?.id,
						newSortOrder: newPageSection.sortOrder,
					})
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	const { mutate: updatePageSection } = useHandleReactQueryMutation(
		useUpdateVideoPageSectionMutation({
			onSuccess: async () => {
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	return (
		<Formik
			initialValues={{
				url: pageSectionToEdit?.url || '',
			}}
			validationSchema={yup.object({
				url: embedUrlValidation,
			})}
			onSubmit={async values => {
				if (pageSectionToEdit) {
					updatePageSection({
						id: pageSectionToEdit.id,
						...values,
					})
				} else {
					createPageSection({
						pageId,
						...values,
					})
				}
			}}
		>
			{formikProps => {
				submitFormRef.current = formikProps.submitForm
				return (
					<Form style={{ display: 'flex', flexDirection: 'column' }}>
						<FormikEmbedField field={{ name: 'url', label: 'Video or Podcast URL' }} />
					</Form>
				)
			}}
		</Formik>
	)
}
