import { Box, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import { Form, Formik, FormikProps } from 'formik'
import React, { useRef } from 'react'
import { v4 } from 'uuid'
import { CalendarActionType, ICalendarActions, ICalendarState, useCalendarContext } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { buttonsSchema, FormikButtonsField, FormikDatePicker, FormikEffect, FormikGroupSelector, FormikTextInput, FormikTimePicker } from './forms'
import { LoadingButton } from './loading-button'
import { Modal, useModal } from './modal'
import * as Yup from 'yup'
import { getNextTimeForIncrement } from '../helpers'
import { Tooltip } from './tooltip'
import { FormikRichText } from './forms/formik-rich-text'
import { useCallback } from 'react'
import { useHandleReactQueryMutation } from '../hooks'
import { SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { ISchoolState, useSchoolContext } from '../stores/school'
import { flatten } from 'lodash'
import { useButtonStyles } from '../styles'
import { ConfirmDialog } from './confirm-dialog'
import { Callout } from './callout'
import { Google } from '../images'
import { useCreateOrUpdateEventMutation, useDeleteEventMutation } from '../graphql/autogenerate/react-query'
import { useSnackbar } from 'notistack'

export const CalendarEventModal = () => {
    const { state: { eventModal }, dispatch, actions: { refetch } } = useCalendarContext()
    const { state: { school: { divisions }, groups } } = useSchoolContext()

    if (!refetch.events) return null

    return (
        <EventModal
            eventModal={eventModal}
            dispatch={dispatch}
            divisions={divisions.nodes}
            groups={groups}
            refetchEvents={refetch.events}
        />
    )
}

interface ICalendarEventModalProps extends Pick<ICalendarState, 'eventModal'>, ICalendarDispatchProp {
    divisions: SchoolDivisionsFragment[]
    groups: ISchoolState[ 'groups' ]
    refetchEvents: ICalendarActions[ 'refetch' ][ 'events' ]
}

const EventModal = React.memo(({
    eventModal: { isOpen, eventToEdit },
    dispatch,
    divisions,
    groups,
    refetchEvents,
}: ICalendarEventModalProps) => {

    const modalId = useRef(v4())

    const buttonStyles = useButtonStyles()
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()

    const { mutate: createOrUpdateEvent } = useHandleReactQueryMutation(useCreateOrUpdateEventMutation())

    // Not strictly necessary, but go ahead and use the same base time for all the default values. Ensures all milliseconds are identical (even tho we eventually ignore milliseconds 🤷‍♂️). 
    const now = useRef(dayjs())
    const startOfDay = useRef(now.current.startOf('d'))
    const startTimeDefault = useRef(getNextTimeForIncrement(30, now.current))
    const endTimeDefault = useRef(getNextTimeForIncrement(30, now.current).add(1, 'hour'))

    const wasLastFormikChangeAutomatic = useRef(false)

    const initialValues = {
        title: eventToEdit?.title || '',
        startDate: eventToEdit?.startDate || startOfDay.current,
        endDate: eventToEdit?.endDate || startOfDay.current,
        startTime: eventToEdit?.startDate || startTimeDefault.current,
        endTime: eventToEdit?.endDate || endTimeDefault.current,
        location: eventToEdit?.locationString || '',
        groupIds: eventToEdit?.groups.map(o => o.id) || [] as string[],
        information: eventToEdit?.information || '',
        buttons: eventToEdit?.buttons?.buttons || []
    }

    const handleValuesChange = useCallback((prev: typeof initialValues, next: typeof initialValues, formikProps: FormikProps<typeof initialValues>) => {
        // We need to figure out what's changing and handle all automatic changes at once, else we risk loops/overwrites
        let nextValues = { ...next }
        let updated = false

        const startTimeChanged = !prev.startTime.isSame(next.startTime)
        const endTimeChanged = !prev.endTime.isSame(next.endTime)
        const endTimeBeforeStartTime = next.endTime.isBefore(next.startTime)

        const startDateChanged = !next.startDate.isSame(prev.startDate)
        const endDateChanged = !next.endDate.isSame(prev.endDate)
        const endDateBeforeStartDate = next.endDate.isBefore(next.startDate)

        // Don't do anything automatic to the start/end time if both were being changed
        if (!(startTimeChanged && endTimeChanged)) {
            // If the start time changes, update the end time to keep the same gap.
            if (startTimeChanged) {
                nextValues.endTime = next.startTime.add(prev.endTime.diff(prev.startTime, 'minutes'), 'minutes')
                updated = true
            }

            // If the end time moves before the start time, move the start time to one hour earlier
            if (endTimeChanged && endTimeBeforeStartTime) {
                nextValues.startTime = next.endTime.subtract(1, 'hour')
                updated = true
            }
        }

        if (!(startDateChanged && endDateChanged)) {
            // If the start date changes, move the end date to preserve the same gap
            if (startDateChanged) {
                nextValues.endDate = prev.startDate.isSame(prev.endDate) ? next.startDate : next.startDate.add(prev.endDate.diff(prev.startDate, 'days'), 'days')
                updated = true
            }

            // If the end date moves before the start date, move the start date to the same day
            if (endDateChanged && endDateBeforeStartDate) {
                nextValues.startDate = next.endDate.clone()
                updated = true
            }
        }

        if (updated && !wasLastFormikChangeAutomatic.current) {
            formikProps.setValues(nextValues)
            wasLastFormikChangeAutomatic.current = true
            return
        }

        wasLastFormikChangeAutomatic.current = false
    }, [])

    const { mutate: deleteEvent, isLoading: deleteEventLoading } = useDeleteEventMutation()
    const { props, open } = useModal()
    const handleDeleteEvent = useCallback(async () => {
        if (!eventToEdit) return
        deleteEvent(
            { id: eventToEdit.id },
            {
                onSuccess: async () => {
                    enqueueSnackbar('Event deleted', { variant: 'warning' })
                    if (refetchEvents) await refetchEvents()
                    dispatch({ type: CalendarActionType.toggleCreateEventModal })
                }
            }
        )
    }, [ eventToEdit, refetchEvents ])
    const handleDeleteClick = useCallback(() => {
        if (!eventToEdit) return
        if (eventToEdit.groups.length > 1) {
            open()
        } else {
            handleDeleteEvent()
        }
    }, [ eventToEdit, handleDeleteEvent ])

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                title: Yup.string().required('Required'),
                groupIds: Yup.array().min(1, 'Please select at least one calendar.'),
                buttons: buttonsSchema,
            })}
            onSubmit={async (values, actions) => createOrUpdateEvent(
                {
                    title: values.title,
                    calendarIds: flatten(groups.filter(group => values.groupIds.includes(group.id)).map(o => o.calendarIds)),
                    locationString: values.location.length > 0 ? values.location : null,
                    information: values.information.length > 0 ? values.information : null,
                    startDate: values.startDate.hour(values.startTime.hour()).minute(values.startTime.minute()).toISOString(),
                    endDate: values.endDate.hour(values.endTime.hour()).minute(values.endTime.minute()).toISOString(),
                    eventId: eventToEdit?.id || null,
                    buttons: values.buttons,
                },
                {
                    onSettled: async () => {
                        enqueueSnackbar(`Event ${eventToEdit ? 'updated' : 'created'}.`, { variant: 'success' })
                        if (refetchEvents) await refetchEvents()
                        dispatch({ type: CalendarActionType.toggleCreateEventModal })
                        actions.resetForm()
                    }
                }
            )}
            children={formikProps => {
                const { values, submitForm, isSubmitting } = formikProps
                return (
                    <>
                        <FormikEffect formikProps={formikProps} onChange={handleValuesChange} />
                        <Modal
                            id={modalId.current}
                            size='md'
                            title={eventToEdit ? 'Edit Event' : 'Add Event'}
                            dividers
                            actions={
                                <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                                    {eventToEdit ?
                                        <LoadingButton
                                            variant='outlined'
                                            className={buttonStyles.errorOutlined}
                                            loading={deleteEventLoading}
                                            onClick={handleDeleteClick}
                                        >
                                            Delete
                                        </LoadingButton>
                                        :
                                        <div />
                                    }
                                    <LoadingButton
                                        type='submit'
                                        color='primary'
                                        variant='contained'
                                        loading={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Save
                                    </LoadingButton>
                                </div>
                            }
                            closeButton
                            isOpen={isOpen}
                            close={() => dispatch({ type: CalendarActionType.toggleCreateEventModal })}
                        >
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                {eventToEdit?.googleId &&
                                    <Callout
                                        why={{
                                            tooltip: `Synced from ${eventToEdit.googleCalendarId}`
                                        }}
                                        style={{ marginBottom: theme.spacing(3), padding: theme.spacing(.5) }}
                                    >
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Google style={{ marginRight: 5 }} /> This event was synced from Google.
                                            </div>
                                            <div style={{ marginTop: theme.spacing(1) }}>Make edits here that you want to show up in your school's app, but not in Google.</div>
                                        </div>
                                    </Callout>
                                }

                                <div style={{ display: 'flex' }}>
                                    <FormikTextInput fieldProps={{ name: 'title', label: 'Title' }} autoFocus />
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box marginX={.5}><FormikDatePicker fieldProps={{ name: 'startDate' }} /></Box>
                                    <Box marginX={.5}><FormikTimePicker startTime={startOfDay.current} increment={15} fieldProps={{ name: 'startTime' }} /></Box>
                                    <Box marginX={.5}>to</Box>
                                    <Box marginX={.5}><FormikDatePicker fieldProps={{ name: 'endDate' }} /></Box>
                                    <Box marginX={.5}><FormikTimePicker startTime={values.startTime || startOfDay.current} increment={15} fieldProps={{ name: 'endTime' }} durationFromTime={values.startTime} /></Box>
                                </div>

                                <Tooltip placement='right-start' title='The event location is a simple text field and will show up as text in the event detail.'>
                                    <Box display='flex' marginTop={4}>
                                        <FormikTextInput fieldProps={{ name: 'location', label: 'Location' }} />
                                    </Box>
                                </Tooltip>

                                <FormikGroupSelector divisions={divisions} fieldProps={{ name: 'groupIds', label: 'Calendar(s)' }} />

                                <FormikRichText fieldProps={{ name: 'information', label: 'Information', placeholder: 'Event description...' }} />

                                <FormikButtonsField field={{ name: 'buttons', label: 'Buttons' }} style={{ marginBottom: theme.spacing(2), }} />
                            </Form>
                        </Modal>

                        <ConfirmDialog
                            {...props}
                            confirm={handleDeleteEvent}
                            title='Delete from all calendars?'
                            body={<span>Are you sure you want to delete this event from <b>all calendars</b>?</span>}
                            confirmButton={{
                                label: 'Delete',
                                type: 'error'
                            }}
                        />
                    </>
                )
            }}
        />
    )
},
    (prevProps, nextProps) => {
        const isSame = prevProps.eventModal?.isOpen === nextProps.eventModal?.isOpen
        return isSame
    }
)
