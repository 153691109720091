import { animated } from 'react-spring'
import { useBoop } from '../hooks'
import { useCommonStyles } from '../styles'
import { TooltipPlacement } from './interfaces'
import clsx from 'clsx'
import { Tooltip } from './tooltip'

interface IAnimationIconWraperProps {
    children: JSX.Element
    tooltip?: {
        title: string
        placement?: TooltipPlacement
    }
    className?: string
    onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any
}

export const AnimationIconWraper = ({ children, tooltip, className, onClick }: IAnimationIconWraperProps) => {
    const hoverBoop = useBoop({ rotation: 15, timing: 100, })
    const onClickBoop = useBoop({ scale: 1.5, timing: 100, springConfig: { tension: 300, friction: 30 } })

    const commonStyles = useCommonStyles()

    return (
        <animated.span
            onMouseEnter={hoverBoop.trigger}
            onClick={(e) => {
                onClickBoop.trigger()
                onClick && onClick(e)
            }}
            className={clsx(commonStyles.primaryColorHover, commonStyles.baseIconColor, className)}
            style={{ ...hoverBoop.style, cursor: 'pointer', display: 'flex', }}
        >
            <animated.span style={{ ...onClickBoop.style, display: 'flex' }}>
                {tooltip ?
                    <Tooltip title={tooltip.title} placement={tooltip.placement} enterDelay={30} arrow>
                        {children}
                    </Tooltip>
                    :
                    children
                }
            </animated.span>
        </animated.span>
    )
}