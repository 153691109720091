import { useTheme } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { MutableRefObject } from 'react'
import * as yup from 'yup'
import {
	DeletePageSectionFileMutation,
	FileFragment,
} from '../graphql/autogenerate/operations'
import {
	useCreateAttachmentPageSectionMutation,
	useCreateTitlePageSectionMutation,
	useDeletePageSectionFileMutation,
	useDeleteTemporaryAwsS3UploadMutation,
	useUpdateAttachmentPageSectionMutation,
	useUpdatePageSectionSortOrderMutation,
	useUpdateTitlePageSectionMutation,
} from '../graphql/autogenerate/react-query'
import { FileRelationType } from '../graphql/autogenerate/schemas'
import { useButtonStyles } from '../styles'
import {
	AttachmentFieldValue,
	FormikAttachmentsField,
	FormikPatchTouched,
	attachmentsSchema,
	useAttachmentFieldValueHandler,
} from './forms'
import { usePageContext } from './page-edit'
import { useHandleReactQueryMutation } from '../hooks'

interface IPageSectionAttachmentFormProps {
	submitFormRef: MutableRefObject<(() => Promise<any>) | undefined>
	loadingRef: MutableRefObject<boolean>
}

export const PageSectionAttachmentForm = ({
	submitFormRef,
	loadingRef,
}: IPageSectionAttachmentFormProps) => {
	const {
		pageId,
		state: { newPageSection, pageSectionToEdit },
		refetchPageSections,
		pageSectionFormModal,
	} = usePageContext()

	const attachmentFieldValueHandler = useAttachmentFieldValueHandler()

	const { mutateAsync: updateSortOrder } = useHandleReactQueryMutation(
		useUpdatePageSectionSortOrderMutation()
	)
	const { mutateAsync: createPageSection } = useHandleReactQueryMutation(
		useCreateAttachmentPageSectionMutation({
			onSuccess: async data => {
				if (
					newPageSection?.sortOrder !== undefined &&
					data.createPageSection?.pageSection?.id
				)
					await updateSortOrder({
						pageSectionId: data.createPageSection?.pageSection?.id,
						newSortOrder: newPageSection.sortOrder,
					})
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	const { mutateAsync: updatePageSection } = useHandleReactQueryMutation(
		useUpdateAttachmentPageSectionMutation({
			onSuccess: async () => {
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	const { mutateAsync: deletePageSectionFile } = useHandleReactQueryMutation(
		useDeletePageSectionFileMutation()
	)

	return (
		<Formik
			initialValues={{
				attachments: (pageSectionToEdit?.pageSectionFiles?.nodes
					.filter(o => o.fileRelationType === FileRelationType.Attachment)
					.map(o => o.file) || []) as AttachmentFieldValue[],
			}}
			onSubmit={async ({ attachments }) => {
				const { addedAttachments, removedAttachments } =
					await attachmentFieldValueHandler({
						submittedAttachments: attachments,
						initialAttachmentFiles: pageSectionToEdit?.pageSectionFiles?.nodes.reduce<
							FileFragment[]
						>((fileFragments, o) => {
							if (o.file && o.fileRelationType === FileRelationType.Attachment)
								fileFragments.push(o.file)
							return fileFragments
						}, []),
					})

				if (removedAttachments.length) {
					await Promise.all(
						removedAttachments.reduce<Promise<DeletePageSectionFileMutation>[]>(
							(mutations, { oldValue }) => {
								if (oldValue) {
									const fileAttachment = pageSectionToEdit?.pageSectionFiles?.nodes.find(
										o => o.file?.id === oldValue.id
									)
									if (fileAttachment)
										mutations.push(deletePageSectionFile({ id: fileAttachment.id }))
								}
								return mutations
							},
							[]
						)
					)
				}

				if (pageSectionToEdit) {
					await updatePageSection({
						id: pageSectionToEdit.id,
						attachments: {
							create: addedAttachments.map((o, index) => ({
								file: { create: o },
								fileRelationType: FileRelationType.Attachment,
							})),
						},
					})
				} else {
					await createPageSection({
						pageId,
						attachments: {
							create: addedAttachments.map((o, index) => ({
								file: { create: o },
								fileRelationType: FileRelationType.Attachment,
							})),
						},
					})
				}
			}}
			validationSchema={yup.object({
				attachments: attachmentsSchema.min(1, 'Please attach at least one file'),
			})}
		>
			{formikProps => {
				submitFormRef.current = formikProps.submitForm
				loadingRef.current = formikProps.isSubmitting

				return (
					<Form style={{ display: 'flex', flexDirection: 'column' }}>
						<FormikPatchTouched />
						<FormikAttachmentsField field={{ name: 'attachments' }} />
					</Form>
				)
			}}
		</Formik>
	)
}
