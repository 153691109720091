import { Avatar, useTheme } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import { useFacultyStaffQuery } from '../graphql/autogenerate/react-query'
import { ButtonType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { LinkButton } from './link-button'
import { SchoolNavBar } from './school-nav-bar'

export const SchoolDirectory = () => {
    const { personId } = useParams()

    const theme = useTheme()
    const navigate = useNavigate()

    const { state: { school: { id: schoolId } } } = useSchoolContext()
    const { data } = useHandleReactQuery(useFacultyStaffQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL }))

    const alphabetizedFacultyStaff = useMemo(() => {
        return data?.schoolPeople?.nodes.filter(o => o.includeInDirectory).map(o => o.person).sort((a, b) => {
            const aLastName = a?.lastName
            const bLastName = b?.lastName
            if (!aLastName || !bLastName) return 0
            if (aLastName > bLastName) return 1
            if (aLastName < bLastName) return -1
            return 0
        }) || []
    }, [ data ])

    type Person = typeof alphabetizedFacultyStaff[ number ]

    const [ selectedPerson, setSelectedPerson ] = useState<Person>()
    useEffect(() => {
        if (selectedPerson) {
            const updatedPerson = alphabetizedFacultyStaff.find(o => o?.id === selectedPerson.id)
            if (updatedPerson) setSelectedPerson(updatedPerson)
        }

        if (personId) {
            const person = alphabetizedFacultyStaff.find(o => o?.id === personId)
            setSelectedPerson(person)
        }
    }, [ alphabetizedFacultyStaff, personId ])

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
            <SchoolNavBar style={{ padding: `0px ${theme.spacing(1)}px`, justifyContent: 'flex-start' }}>
                <div
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                        navigate(-1)
                        setSelectedPerson(undefined)
                    }}
                >
                    <ArrowBackIos />
                    <span>{selectedPerson ? 'Directory' : 'Back'}</span>
                </div>
                <span>
                    {!selectedPerson && 'Directory'}
                </span>

                <ArrowBackIos style={{ opacity: 0, flex: 1 }} />
            </SchoolNavBar>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', paddingBottom: 55 }}>
                {selectedPerson ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: theme.spacing(2) }}>
                        <Avatar
                            alt={`${selectedPerson.firstName} ${selectedPerson.lastName}`}
                            src={selectedPerson.profileImage?.temporaryDownloadUrl}
                            style={{ width: theme.spacing(15), height: theme.spacing(15), border: `5px solid ${theme.palette.primary.main}`, borderRadius: '50%' }}
                        />

                        <div style={{ margin: `${theme.spacing(2)}px 0px` }}>{selectedPerson.title} {selectedPerson.firstName} {selectedPerson.lastName}</div>
                        {selectedPerson.email &&
                            <LinkButton style={{ width: '100%' }} button={{ id: selectedPerson.email, url: selectedPerson.email, label: 'Email', type: ButtonType.Email }} />
                        }
                        {selectedPerson.phone &&
                            <LinkButton style={{ width: '100%' }} button={{ id: selectedPerson.phone, url: selectedPerson.phone, label: 'Call', type: ButtonType.Phone }} />
                        }
                        <div dangerouslySetInnerHTML={{ __html: selectedPerson.bio || '' }} />
                    </div>
                    :
                    alphabetizedFacultyStaff?.map(person =>
                        <div
                            key={person?.id}
                            style={{
                                display: 'flex',
                                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                                borderBottom: `1px solid ${theme.palette.grey[ 300 ]}`,
                            }}
                            onClick={() =>  person && navigate(person.id)}
                        >
                            <Avatar
                                alt={`${person?.firstName} ${person?.lastName}`}
                                src={person?.profileImage?.temporaryDownloadUrl}
                                style={{ width: theme.spacing(8), height: theme.spacing(8) }}
                            />

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: theme.spacing(1) }}>
                                <div><b>{person?.lastName}</b></div>
                                <div style={{ color: theme.palette.grey[ 600 ] }}>{person?.firstName}</div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ArrowForwardIos style={{ height: theme.spacing(2), color: theme.palette.grey[ 600 ] }} />
                            </div>
                        </div>
                    )
                }
            </div>
        </div >
    )
}