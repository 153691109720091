import { Button, useTheme } from '@material-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useRef } from 'react'
import { useUpdateSchoolPersonProfileAndSchoolPropertiesMutation } from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import {
	FileFieldValue,
	FormikImageField,
	FormikPhoneField,
	FormikTextInput,
	useImageFieldChangeHandler,
} from './forms'
import { Modal, useModal } from './modal'
import * as yup from 'yup'
import { useHandleReactQueryMutation } from '../hooks'
import { SchoolPersonFragment } from '../graphql/autogenerate/operations'
import { Callout } from './callout'
import { FormikRadio } from './forms/formik-radio'
import { FormikRichText } from './forms/formik-rich-text'
import { useSchoolContext } from '../stores/school'

interface ISchoolPersonProfileEditModalProps {
	modal: ReturnType<typeof useModal>
	afterClose: () => void
	schoolPersonToEdit?: SchoolPersonFragment
}

export const SchoolPersonProfileEditModal = ({
	modal,
	afterClose,
	schoolPersonToEdit,
}: ISchoolPersonProfileEditModalProps) => {
	const theme = useTheme()

	const initialValues = {
		title: schoolPersonToEdit?.person?.title || '',
		firstName: schoolPersonToEdit?.person?.firstName || '',
		lastName: schoolPersonToEdit?.person?.lastName || '',
		email: schoolPersonToEdit?.person?.email || '',
		phone: schoolPersonToEdit?.person?.phone || '',
		bio: schoolPersonToEdit?.person?.bio || '',
		userType: UserType.FacultyStaff,
		facultyStaffAlsoParent: schoolPersonToEdit?.userTypes.includes(
			UserType.Parent
		)
			? 'yes'
			: ('no' as 'yes' | 'no'),
		includeInDirectory: schoolPersonToEdit?.includeInDirectory
			? 'yes'
			: ('no' as 'yes' | 'no'),
		profileImage: schoolPersonToEdit?.person?.profileImage as FileFieldValue,
	}

	const externalUserWithoutAcceptedInvite = Boolean(
		schoolPersonToEdit?.person?.userAccountId &&
			!schoolPersonToEdit.schoolPersonInvitation?.accepted
	)

	const {
		state: {
			school: { name: schoolName },
		},
	} = useSchoolContext()

	const { enqueueSnackbar } = useSnackbar()

	const { mutate: updateExistingSchoolPerson } = useHandleReactQueryMutation(
		useUpdateSchoolPersonProfileAndSchoolPropertiesMutation({
			onSuccess: () => {
				enqueueSnackbar('Person successfully updated.', { variant: 'success' })
				modal.close()
			},
		})
	)
	const fileChangeHandler = useImageFieldChangeHandler()

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={yup.object({
				firstName: yup.string().required('Required'),
				lastName: yup.string().required('Required'),
				userType: yup.string().nullable().required('Required'),
			})}
			onSubmit={async ({ profileImage, ...values }) => {
				// Should be an unnecessary check here since the edit modal shouldn't ever be opened without these values. But it makes Typescript happy
				if (!schoolPersonToEdit || !schoolPersonToEdit.person)
					throw new Error('Missing person to edit value.')
				updateExistingSchoolPerson({
					...values,
					id: schoolPersonToEdit.person.id,
					schoolPersonId: schoolPersonToEdit.id,
					userTypes: [
						values.userType,
						...(values.facultyStaffAlsoParent === 'yes'
							? [UserType.Parent]
							: []),
					],
					includeInDirectory:
						values.includeInDirectory === 'yes' ? true : false,
					...(await fileChangeHandler({
						oldValue: schoolPersonToEdit?.person?.profileImage,
						newValue: profileImage,
						filePropertyName: 'profileImage',
					})),
					phone: values.phone.length ? values.phone : null,
					bio: values.bio.length ? values.bio : null,
				})
			}}
		>
			{({ values, submitForm, resetForm }) => (
				<Modal
					{...modal.props}
					title='Edit Profile'
					size='sm'
					dismissible
					closeButton
					afterClose={() => {
						resetForm()
						afterClose()
					}}
					actions={
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								style={{ minWidth: 100 }}
								type='button'
								variant='text'
								onClick={modal.close}
							>
								Cancel
							</Button>

							<Button
								type='button'
								style={{ minWidth: 100 }}
								variant='contained'
								color='primary'
								onClick={submitForm}
							>
								Save
							</Button>
						</div>
					}
				>
					<Form style={{ display: 'flex', flexDirection: 'column' }}>
						{externalUserWithoutAcceptedInvite && (
							<Callout
								type='warning'
								style={{
									marginLeft: theme.spacing(0.5),
									marginRight: theme.spacing(0.5),
									marginBottom: theme.spacing(2),
								}}
							>
								<div>
									Since {schoolPersonToEdit?.person?.email} has already created
									their profile, you won't be able to edit their profile until
									they've accepted an invitation to join {schoolName}.
								</div>
							</Callout>
						)}

						<div style={{ display: 'flex' }}>
							<FormikTextInput
								hideHelperTextWhenEmpty
								fieldProps={{
									name: 'title',
									label: 'Title',
									disabled: externalUserWithoutAcceptedInvite,
								}}
								style={{ marginRight: theme.spacing(2), maxWidth: 60 }}
							/>
							<FormikTextInput
								hideHelperTextWhenEmpty
								fieldProps={{
									name: 'firstName',
									label: 'First name',
									disabled: externalUserWithoutAcceptedInvite,
								}}
								style={{ marginRight: theme.spacing(2) }}
							/>
							<FormikTextInput
								hideHelperTextWhenEmpty
								fieldProps={{
									name: 'lastName',
									label: 'Last name',
									disabled: externalUserWithoutAcceptedInvite,
								}}
							/>
						</div>

						<FormikPhoneField
							style={{
								margin: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
							}}
							field={{
								name: 'phone',
								label: 'Phone',
								disabled: externalUserWithoutAcceptedInvite,
							}}
						/>

						<FormikRichText
							style={{ marginTop: theme.spacing(0.5) }}
							hideHelperTextWhenEmpty
							fieldProps={{
								name: 'bio',
								label: 'Bio',
								disabled: externalUserWithoutAcceptedInvite,
							}}
						/>

						<FormikImageField
							field={{
								name: 'profileImage',
								label: 'Profile Picture',
								disabled: externalUserWithoutAcceptedInvite,
							}}
							circleCrop
							aspectRatioHelper='Recommended dimensions: 500px by 500px'
							style={{ margin: theme.spacing(0.5) }}
						/>

						{values.userType === UserType.FacultyStaff && (
							<>
								<FormikRadio
									style={{ margin: `0px ${theme.spacing(0.5)}px` }}
									field={{
										name: 'facultyStaffAlsoParent',
										label: `Is ${
											values.firstName || 'this person'
										} also a school parent?`,
									}}
									options={[
										{ label: 'Yes', value: 'yes' },
										{ label: 'No', value: 'no' },
									]}
								/>
								<FormikRadio
									style={{
										margin: `0px ${theme.spacing(0.5)}px`,
										marginTop: theme.spacing(1),
									}}
									field={{
										name: 'includeInDirectory',
										label: `Include ${
											values.firstName || 'this person'
										} in the faculty/staff directory?`,
									}}
									options={[
										{ label: 'Yes', value: 'yes' },
										{ label: 'No', value: 'no' },
									]}
								/>
							</>
						)}
					</Form>
				</Modal>
			)}
		</Formik>
	)
}
