import { AppAction, AppActionType, IAppState } from "./definitions"

export const reducer = (state: IAppState, action: AppAction): IAppState => {
    switch (action.type) {
        case AppActionType.bootstrap:
            return { ...state, bootstrapped: true, ...action.payload }
        case AppActionType.login:
            return { ...state, authed: true }
        case AppActionType.logout:
            return { ...state, authed: false, currentUser: undefined }
        case AppActionType.setToolbarContent:
            return { ...state, ...action.payload }
        case AppActionType.setCurrentUser:
            return { ...state, ...action.payload }
    }
}