import { Typography, useTheme } from '@material-ui/core'
import { useMemo } from 'react'
import tinycolor from 'tinycolor2'
import { useSchoolContext } from '../stores/school'
import { ImageDisplay } from './image-display'


export const SchoolLaunchScreen = () => {
    const { state: { school: { schoolSetting } } } = useSchoolContext()
    const theme = useTheme()
    const backgroundImage = useMemo(() => {
        if (!schoolSetting?.schoolImage?.temporaryDownloadUrl) return
        const { r, g, b } = tinycolor(theme.palette.primary.main).toRgb()
        return `linear-gradient(to top, rgba(${r},${g},${b},.9), rgba(${r},${g},${b},1)), url(${schoolSetting?.schoolImage?.temporaryDownloadUrl})`
    }, [ schoolSetting?.schoolImage?.temporaryDownloadUrl ])

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                backgroundImage,
                backgroundPosition: 'center',
                backgroundSize: 'auto 100%',
                backgroundRepeat: 'no-repeat',
                color: theme.palette.primary.contrastText,
                zIndex: 15,
                backgroundColor: 'red',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <ImageDisplay style={{ width: '40%' }} src={schoolSetting?.logoImage?.temporaryDownloadUrl} />
            </div>
            <div style={{ margin: `${theme.spacing(4)}px 0px`, color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography align='center' gutterBottom>{schoolSetting?.launchScreenPoweredByLabel || 'Powered by:'}</Typography>
                <div style={{ display: 'flex', height: 100 }}>
                    <div style={{ flex: 2 }} />
                    <div style={{ flex: 6 }}>
                        <ImageDisplay style={{ height: '100%', objectFit: 'contain' }} src={schoolSetting?.launchScreenPoweredByImage?.temporaryDownloadUrl} />
                    </div>
                    <div style={{ flex: 2 }} />
                </div>
            </div>
        </div>
    )
}