import { Box, createStyles, FormHelperText, FormLabel, makeStyles } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import clsx from 'clsx'
import { useField } from 'formik'
import { CSSProperties } from 'react'
import { useFormStyles } from '../../styles'
import { IField } from './interfaces'

interface IFormikRichTextProps {
    fieldProps: IField
    height?: number | string
    autoFocus?: boolean
    onKeyDown?: (ev: KeyboardEvent) => void
    style?: CSSProperties
    hideHelperTextWhenEmpty?: boolean
}

const useStyles = makeStyles(theme =>
    createStyles({
        tinyMceError: {
            '& > .tox': {
                border: `1px solid ${theme.palette.error.main}`
            }
        }
    })
)


export const FormikRichText = ({ fieldProps, height = 200, autoFocus, onKeyDown, style, hideHelperTextWhenEmpty }: IFormikRichTextProps) => {
    const [ field, meta, helpers ] = useField(fieldProps)

    const formStyles = useFormStyles()
    const styles = useStyles()

    const error = (meta.touched && !!meta.error) || !!meta.initialError

    return (
        <div className={clsx(formStyles.margin, meta.error && styles.tinyMceError)} style={style} >
            <Box marginBottom={.5}>
                <FormLabel error={error}>{fieldProps.label}</FormLabel>
            </Box>
            <Editor
                apiKey='h5pwucughnfnp5q6fiuk0nif845rxka3c2x1y7e91rbsv6p8'
                {...field}
                textareaName={field.name}
                disabled={fieldProps.disabled}
                initialValue={fieldProps.placeholder}
                value={field.value}
                init={{
                    height,
                    plugins: [
                        'advlist autolink lists link image charmap print preview link',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount emoticons fullscreen'
                    ],
                    toolbar: 'undo redo | fullscreen | spellchecker | bullist numlist outdent indent link | emoticons | bold italic backcolor | \
                    formatselect | removeformat | \
                    alignleft aligncenter alignright alignjustify | help',
                    auto_focus: autoFocus ? true : undefined,
                    mode: 'textareas',
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: '',
                    body_id: 'tiny_mce_editor',
                    help_tabs: ['shortcuts', 'keyboardnav'],
                }}
                onEditorChange={(value) => helpers.setValue(value)}
                onKeyDown={onKeyDown}
            />
            {(!hideHelperTextWhenEmpty || (meta.error?.toString() || fieldProps.helperText?.toString())) && <FormHelperText error={error}>{meta.error?.toString() || fieldProps.helperText?.toString() || ' '}</FormHelperText>}
        </div>
    )
}