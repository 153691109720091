import { Link } from 'react-router-dom'
import { Grid, Typography, Paper, Box, Divider } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
	SvgIconComponent,
	People,
	Event,
	AccountBox,
	Settings,
	MonetizationOn,
	Announcement,
	ArtTrack,
	ChromeReaderMode,
	FeaturedVideo,
} from '@material-ui/icons'
import { useCommonStyles } from '../styles'
import clsx from 'clsx'
import { SchoolDetailFragment } from '../graphql/autogenerate/operations'
import React from 'react'
import { useSchoolContext } from '../stores/school'
import { useAppState } from '../hooks'

export const AdminSchoolButtonNavButtons = () => {
	const {
		state: { school },
	} = useSchoolContext()

	return <MemoizedAdminSchoolButtonNavButtons school={school} />
}

const useAdminSchoolButtonNavStyles = makeStyles(theme =>
	createStyles({
		actionButtonContainer: {
			margin: theme.spacing(2),
			width: 'unset',
		},
		spacer: {
			margin: theme.spacing(2),
		},
	})
)

const MemoizedAdminSchoolButtonNavButtons = React.memo(
	({ school }: { school: SchoolDetailFragment }) => {
		const {
			state: { currentUser },
		} = useAppState()
		const {
			state: { currentUserPermissions },
		} = useSchoolContext()

		const styles = useAdminSchoolButtonNavStyles()

		const actionButtons = [
			{ path: 'notifications', label: 'Notifications', Icon: Announcement },
			{ path: 'newsletters', label: 'Newsletters', Icon: ArtTrack },
			{ path: 'calendars', label: 'Calendars', Icon: Event },
			{ path: 'resources', label: 'Resources', Icon: ChromeReaderMode },
		]

		if (currentUserPermissions.schoolwideAdmin || currentUser?.appAdministrator)
			actionButtons.push({
				path: 'marquee',
				label: 'Marquee',
				Icon: FeaturedVideo,
			})

		const manageButtons = [
			{
				path: 'groups',
				label:
					school.divisions.totalCount > 1 || currentUser?.appAdministrator
						? 'Divisions & Groups'
						: 'Groups',
				Icon: People,
			},
			{ path: 'people', label: 'People', Icon: AccountBox },
			{ path: 'sponsors', label: 'Sponsors', Icon: MonetizationOn },
			{ path: 'settings', label: 'School Settings', Icon: Settings },
		]

		return (
			<Grid item container className={styles.actionButtonContainer}>
				<NavButtons buttons={actionButtons} header='Communicate' />

				{(currentUser?.appAdministrator ||
					currentUserPermissions.schoolwideAdmin) && (
					<>
						<Grid item className={styles.spacer} />

						<NavButtons buttons={manageButtons} header='Manage' />
					</>
				)}
			</Grid>
		)
	}
)

const navButtonStyles = makeStyles(theme =>
	createStyles({
		actionButton: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: theme.shape.borderRadius,
			color: theme.palette.primary.main,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				height: 100,
			},
			[theme.breakpoints.down('sm')]: {
				height: 75,
			},
			// backgroundColor: theme.palette.primary.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: 5, // Push the label up just a bit to feel more centered
			boxShadow: theme.shadows[3],
			'&:hover': {
				boxShadow: theme.shadows[15],
				transition: theme.transitions.create('box-shadow'),
				cursor: 'pointer',
			},
			'& > svg': {
				marginRight: theme.spacing(1),
			},
		},
	})
)

interface INavButtonsProps {
	buttons: { label: string; path: string; Icon?: SvgIconComponent }[]
	header: string
}

const NavButtons = ({ buttons, header }: INavButtonsProps) => {
	const styles = navButtonStyles()
	const commonStyles = useCommonStyles()

	return (
		<>
			<Grid item xs={12}>
				<Typography variant='h4' color='textSecondary'>
					{header}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box marginBottom={2} marginTop={1}>
					{' '}
					<Divider />
				</Box>
			</Grid>
			<Grid item container spacing={2} xs={12}>
				{buttons.map(item => (
					<Grid key={item.path} item xl={2} lg={3} sm={6} xs={12}>
						<Link to={item.path}>
							<Paper
								elevation={2}
								className={clsx(
									styles.actionButton,
									commonStyles.elevationOnHover
								)}
							>
								{item.Icon && <item.Icon />}
								<Typography variant='h5'>{item.label}</Typography>
							</Paper>
						</Link>
					</Grid>
				))}
			</Grid>
		</>
	)
}
