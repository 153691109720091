import { Routes, Route, Navigate } from 'react-router-dom'
import { useAppState, useMobileDetect } from '../hooks'
import { Login } from './login'
import { Signup } from './signup'
import { ForgotPassword } from './forgot-password'
import { ResetPassword } from './reset-password'
import { NewsletterPublic } from './newsletter-public'
import { NotFound } from './not-found'
import { Invitation } from './invitation'
import { SchoolRoutes } from './school-routes'
import { NoSchools } from './no-schools'
import { Schools } from './schools'
import { AuthGuardedRoute, GuardedRoute } from './guarded-route'
import { atomWithHash } from 'jotai/utils'
import { ReturnToApp } from './return-to-app'

export const AppRoutes = () => {
  const { isMobile } = useMobileDetect()
  const { state: { currentUser, authed } } = useAppState()

  return (
    <Routes>

      <Route path='' element={<InitialRedirect />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='login' element={<Login />} />
      <Route path='return-to-app' element={<ReturnToApp />} />

      <Route path='invitation/:invitationSlug' element={<Invitation />} />



      {/* Both mobile and desktop routes */}
      {/* <SchoolRoutes /> handles much more mobile/desktop switching  */}
      <AuthGuardedRoute path='school/:schoolSlug/*' element={<SchoolRoutes />} />
      <Route path='newsletter/:newsletterSlug' element={<NewsletterPublic />} />

      <GuardedRoute
        path='schools'
        element={<Schools />}
        guard={Boolean(currentUser?.schoolSlugs?.length || currentUser?.appAdministrator)}
        redirect='/'
      />

      {/* Desktop routes */}
      {!isMobile() &&
        <>
          {/* Only allow folks to signup on desktop */}
          <GuardedRoute guard={!authed} redirect='/' path='signup' element={<Signup />} />
        </>
      }

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

/** 
  If someone is hitting the TLD, figure out what his first stop needs to be.

  This is different on mobile vs. desktop (for now)
*/
const InitialRedirect = () => {
  const { state: { currentUser, authed } } = useAppState()
  const { isMobile } = useMobileDetect()

  if (authed) {
    if (!currentUser) return null
    if (currentUser.schoolSlugs?.length || currentUser.appAdministrator) {
      return <Navigate to='/schools' replace />
    } else {
      return <NoSchools />
    }
  } else {
    if (isMobile()) {
      return <NoSchools />
    } else {
      return <Navigate to='/login' replace />
    }
  }
}


// Modal states
export const gcsModalAtom = atomWithHash('gcsModal', false)