import { useFormikContext } from 'formik'
import { useKeyPressEvent } from 'react-use'

export const FormikSubmitOnEnter = () => {
    const { submitForm } = useFormikContext()
    useKeyPressEvent('Enter', () => {
        console.log('pressed!')
        submitForm()
    })
    return null
}