import { TextField, Typography, useTheme } from '@material-ui/core'
import { ArrowBackIos, SearchOutlined } from '@material-ui/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import { ResourceFragmentFragment } from '../graphql/autogenerate/operations'
import { useResourceQuery } from '../graphql/autogenerate/react-query'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useCommonStyles } from '../styles'
import { Header } from './header'
import { ImageDisplay } from './image-display'
import { LinkButton } from './link-button'
import { Modal, useModal } from './modal'
import { ResourceListItem } from './resource-list-item'
import { ResourceTile } from './resource-tile'
import { SchoolNavBar } from './school-nav-bar'
import Fuse from 'fuse.js'
import { AttachmentCell } from './attachment-cell'
import { EmbedDisplay } from './embed-display'

interface IResourceDetailProps {
	resource: ResourceFragmentFragment
	onClosePressed: () => void
}

export const ResourceDetail = ({
	resource: initialResource,
	onClosePressed,
}: IResourceDetailProps) => {
	const theme = useTheme()
	const commonStyles = useCommonStyles()

	const [state, setState] = useState({
		selectedResources: [initialResource],
	})
	const resourceToDisplay = [...state.selectedResources].pop()

	const notInitialRender = useRef(false)
	const location = useLocation()
	useEffect(() => {
		const homePressed = location.pathname.split('/').pop() === 'home'
		if (notInitialRender.current && homePressed) onClosePressed()
		notInitialRender.current = true
	}, [location])

	const searchModal = useModal()
	const [searchTerm, setSearchTerm] = useState('')

	return (
		<>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column',
					minHeight: 0,
					overflow: 'hidden',
				}}
			>
				<SchoolNavBar
					style={{
						padding: `0px ${theme.spacing(1)}px`,
						justifyContent: 'flex-start',
					}}
				>
					<Header
						left={
							<div
								style={{ display: 'flex', alignItems: 'center' }}
								onClick={() => {
									if (state.selectedResources.length > 1) {
										setState(_state => ({
											..._state,
											selectedResources: _state.selectedResources.splice(
												0,
												_state.selectedResources.length - 1
											),
										}))
									} else {
										onClosePressed()
									}
								}}
							>
								<ArrowBackIos />
								<span>Back</span>
							</div>
						}
						center={
							<div className={commonStyles.ellipsisText} style={{ flex: 1 }}>
								{state.selectedResources.length > 1
									? state.selectedResources[state.selectedResources.length - 1].title
									: initialResource.title}
							</div>
						}
						right={
							resourceToDisplay?.type === ResourceType.Folder && (
								<div
									style={{
										display: 'flex',
										color: 'white',
										justifyContent: 'flex-end',
									}}
									onClick={() => searchModal.open()}
								>
									<SearchOutlined />
								</div>
							)
						}
					/>
				</SchoolNavBar>

				{/* <SchoolNavBar style={{ justifyContent: 'space-between', padding: `0px ${theme.spacing(1)}px` }}>
                {initialResource.type === ResourceType.Folder &&
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between', minWidth: 0 }}>
                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            <FolderOutlined style={{ marginRight: theme.spacing(1) }} />

                            {state.selectedResources.length > 1 ?
                                <>
                                    <span style={{ whiteSpace: 'nowrap' }} onClick={() => setState(_state => ({ ..._state, selectedResources: _state.selectedResources.splice(0, _state.selectedResources.length - 1) }))}>{state.selectedResources[ state.selectedResources.length - 2 ].title}</span>
                                    <span style={{ padding: `0px ${theme.spacing(.5)}px` }}>/</span>
                                    <span className={commonStyles.ellipsisText}>{state.selectedResources[ state.selectedResources.length - 1 ].title}</span>
                                </>
                                :
                                initialResource.title
                            }
                        </div>
                        <Close onClick={onClosePressed} />
                    </div>
                }

                {initialResource.type === ResourceType.Page &&
                    <>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} onClick={onClosePressed}>
                            <ArrowBackIos />
                            <span>Back</span>
                        </div>
                        {initialResource.title}
                        <ArrowBackIos style={{ opacity: 0, flex: 1 }} />
                    </>
                }
            </SchoolNavBar> */}

				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minHeight: 0,
						overflowY: 'auto',
						paddingBottom: 55,
						wordWrap: 'break-word',
					}}
				>
					{resourceToDisplay?.type === ResourceType.Page && (
						<ResourcePage resourceId={resourceToDisplay.id} />
					)}
					{resourceToDisplay?.type === ResourceType.Folder && (
						<ResourceFolder
							folder={resourceToDisplay}
							onNonLinkResourcePressed={_resource =>
								setState(_state => ({
									..._state,
									selectedResources: [..._state.selectedResources, _resource],
								}))
							}
						/>
					)}
				</div>
			</div>

			<Modal
				{...searchModal.props}
				title='Search...'
				afterClose={() => setSearchTerm('')}
			>
				<div style={{ display: 'flex', paddingBottom: theme.spacing(1) }}>
					<TextField
						value={searchTerm}
						autoFocus
						variant='outlined'
						size='small'
						placeholder='Search resources...'
						style={{ flex: 1 }}
						onChange={ev => setSearchTerm(ev.currentTarget.value)}
					/>
				</div>

				{resourceToDisplay && (
					<ResourceFolder
						searchTerm={searchTerm}
						folder={resourceToDisplay}
						onNonLinkResourcePressed={_resource =>
							setState(_state => ({
								..._state,
								selectedResources: [..._state.selectedResources, _resource],
							}))
						}
					/>
				)}
			</Modal>
		</>
	)
}

interface IResourcePageProps {
	resourceId: string
}

const ResourcePage = ({ resourceId }: IResourcePageProps) => {
	const theme = useTheme()

	const { data } = useHandleReactQuery(
		useResourceQuery({ id: resourceId }, { refetchInterval: REFETCH_INTERVAL })
	)

	const resource = data?.resource

	if (!resource) return null

	return (
		<>
			{(resource.coverImage?.temporaryDownloadUrl ||
				resource.resourceByParentResource?.defaultCoverImage
					?.temporaryDownloadUrl) && (
				<ImageDisplay
					src={
						resource.coverImage?.temporaryDownloadUrl ||
						resource.resourceByParentResource?.defaultCoverImage?.temporaryDownloadUrl
					}
				/>
			)}

			<Typography style={{ margin: theme.spacing(1) }} variant='h5'>
				{resource.title}
			</Typography>

			{resource.body && (
				<div
					style={{
						margin: theme.spacing(1),
					}}
					dangerouslySetInnerHTML={{ __html: resource.body }}
				/>
			)}

			<div
				style={{
					margin: theme.spacing(1),
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{resource.embedUrl && <EmbedDisplay url={resource.embedUrl} />}

				{resource.buttons?.buttons?.map(o => (
					<LinkButton
						key={o.id}
						style={{ marginBottom: theme.spacing(1) }}
						button={o}
					/>
				))}

				{resource.resourceFiles?.nodes?.map(o => {
					const file = o.file
					if (file) {
						return (
							<AttachmentCell
								key={file.id}
								label={file.label || file.filename}
								temporaryDownloadUrl={file.temporaryDownloadUrl}
								type={file.type}
							/>
						)
					}
					return null
				})}
			</div>
		</>
	)
}

interface IResourceFolderProps {
	folder: ResourceFragmentFragment
	onNonLinkResourcePressed: (resource: ResourceFragmentFragment) => void
	searchTerm?: string
}

const ResourceFolder = ({
	folder,
	onNonLinkResourcePressed,
	searchTerm,
}: IResourceFolderProps) => {
	const theme = useTheme()

	const { data } = useHandleReactQuery(
		useResourceQuery({ id: folder.id }, { refetchInterval: REFETCH_INTERVAL })
	)
	const { folders, listItems } = useMemo(() => {
		const resouces = data?.resources?.nodes || []

		if (searchTerm) {
			const fuze = new Fuse(resouces, {
				threshold: 0.3,
				keys: [
					{
						name: 'title',
						weight: 3,
					},
					{
						name: 'body',
						weight: 2,
					},
					'url',
				],
			})

			const results = fuze.search(searchTerm)

			return {
				folders:
					results.map(o => o.item).filter(o => o.type === ResourceType.Folder) || [],
				listItems:
					results.map(o => o.item).filter(o => o.type !== ResourceType.Folder) || [],
			}
		} else {
			return {
				folders:
					data?.resources?.nodes.filter(o => o.type === ResourceType.Folder) || [],
				listItems:
					data?.resources?.nodes.filter(o => o.type !== ResourceType.Folder) || [],
			}
		}
	}, [data, searchTerm])

	if (!data?.resources || !data.resource) return null

	const { resource } = data

	return (
		<>
			{(resource.coverImage?.temporaryDownloadUrl ||
				resource.defaultCoverImage?.temporaryDownloadUrl) && (
				<ImageDisplay
					src={
						resource.coverImage?.temporaryDownloadUrl ||
						resource.defaultCoverImage?.temporaryDownloadUrl
					}
				/>
			)}

			{resource.body && (
				<div
					style={{
						padding: theme.spacing(1),
						backgroundColor: theme.palette.grey[300],
						margin: theme.spacing(1),
						borderRadius: theme.shape.borderRadius,
						whiteSpace: 'pre-wrap',
					}}
					dangerouslySetInnerHTML={{ __html: resource.body }}
				/>
			)}

			{folders.length > 0 && (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
						gridAutoRows: 130,
						columnGap: theme.spacing(1),
						rowGap: theme.spacing(1),
						marginBottom: theme.spacing(2),
						margin: theme.spacing(1),
					}}
				>
					{folders?.map(o => (
						<ResourceTile
							key={o.id}
							resourceItem={o}
							color={theme.palette.primary.main}
							onClick={() => onNonLinkResourcePressed(o)}
						/>
					))}
				</div>
			)}

			<div>
				{listItems.map(o => (
					<ResourceListItem
						key={o.id}
						resourceItem={o}
						onClick={
							o.type !== ResourceType.Link
								? () => onNonLinkResourcePressed(o)
								: undefined
						}
					/>
				))}
			</div>
		</>
	)
}
