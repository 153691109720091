import { makeStyles, createStyles } from "@material-ui/core"

export type ButtonStyle = 'warning' | 'error' | 'success'

export const useButtonStyles = makeStyles((theme) =>
    createStyles({
        warning: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
        },
        warningOutlined: {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                backgroundColor: `${theme.palette.error.main}80`,
            }
        },
        errorOutlined: {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
        },
        success: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
        },
        white: {
            borderColor: 'white',
            color: 'white',
        },
        iconButton: {
            width: 150,
            height: 150,
            border: `1px solid`,
            borderColor: theme.palette.grey[ 600 ],
            color: theme.palette.grey[ 600 ],
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            flexDirection: 'column',
            '&:hover': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            }
        },
    }),
)