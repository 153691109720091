import {
	Button,
	createStyles,
	FormControl,
	FormHelperText,
	FormLabel,
	makeStyles,
	useTheme,
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Add, DragHandle, RemoveCircleOutline } from '@material-ui/icons'
import clsx from 'clsx'
import { FieldArray, useField } from 'formik'
import { useCallback, useEffect, useRef } from 'react'
import Sortable from 'sortablejs'
import { v4 } from 'uuid'
import * as Yup from 'yup'
import { ButtonInput, ButtonType } from '../../graphql/autogenerate/schemas'
import { useMobileDetect } from '../../hooks'
import { FormikPhoneField } from './formik-phone-field'
import { FormikSimpleSelectField } from './formik-simple-select-field'
import { FormikTextInput } from './formik-text-input'
import { IField } from './interfaces'

const buttonSchema = Yup.object({
	type: Yup.string().required('Required'),
	url: Yup.string()
		.required('Required')
		.test({
			name: 'url',
			test: async function (value) {
				switch (this.parent.type) {
					case ButtonType.Url:
					case ButtonType.Form:
						if (await Yup.string().url().isValid(value)) return true
						return this.createError({
							message: 'Please enter a valid URL.',
							path: this.path,
						})
					case ButtonType.Email:
						if (await Yup.string().isValid(value)) return true
						return this.createError({
							message: 'Please enter a valid email address.',
							path: this.path,
						})
					case ButtonType.Phone:
					case ButtonType.Sms:
					default:
						return true
				}
			},
		}),
	label: Yup.string(),
})

export const buttonsSchema = Yup.array().of(buttonSchema)

const useStyles = makeStyles(theme =>
	createStyles({
		deleteButton: {
			color: theme.palette.grey[500],
			cursor: 'pointer',
			marginLeft: theme.spacing(1),
			marginTop: 15,
			'&:hover': {
				color: theme.palette.error.main,
			},
		},
		dragHandle: {
			color: theme.palette.grey[500],
			cursor: 'grab',
			marginRight: theme.spacing(1),
			marginTop: 15,
			'&:hover': {
				color: theme.palette.primary.main,
			},
		},
	})
)

interface IFormikButtonsFieldProps {
	field: IField
	style?: CSSProperties
	single?: boolean
	enableDescription?: boolean
}

export const FormikButtonsField = ({
	single,
	enableDescription,
	field,
	style,
}: IFormikButtonsFieldProps) => {
	const theme = useTheme()
	const styles = useStyles()
	const { isMobile } = useMobileDetect()
	const [formikField, meta, helpers] = useField<ButtonInput[] | undefined>(field)

	const sortableRef = useRef<Sortable>()
	const setValueAfterSort = useCallback(() => {
		if (!sortableRef.current)
			throw new Error('Cannot have sorted without a sortable instance...')
		if (!formikField.value) return
		const sortedIds = sortableRef.current.toArray()
		helpers.setValue(
			formikField.value.sort((a, b) => {
				if (sortedIds.indexOf(a.id) > sortedIds.indexOf(b.id)) return 1
				if (sortedIds.indexOf(a.id) < sortedIds.indexOf(b.id)) return -1
				return 0
			})
		)
	}, [helpers.setValue, formikField.value])
	const listRef = useCallback(
		(node: HTMLDivElement | null) => {
			if (node) {
				if (sortableRef.current) {
					// If we've already created the Sortable, we may be needing to update the onEnd function since it uses the latest field value in its closure.
					sortableRef.current.option('onEnd', setValueAfterSort)
				} else {
					sortableRef.current = Sortable.create(node, {
						handle: '.drag-handle',
						animation: 300,
						dataIdAttr: 'id',
						onEnd: setValueAfterSort,
					})
				}
			}
		},
		[setValueAfterSort]
	)
	useEffect(() => {
		return () => {
			if (sortableRef.current) sortableRef.current.destroy()
		}
	}, [])

	return (
		<FormControl error={meta.touched && Boolean(meta.error)} style={style}>
			<FormLabel style={{ marginBottom: theme.spacing(1) }}>
				{field.label}
			</FormLabel>
			<FieldArray
				{...formikField}
				children={arrayHelpers => {
					return (
						<>
							<div ref={listRef}>
								{formikField.value?.map((button, index) => (
									<div
										id={button.id}
										key={button.id}
										style={{
											marginBottom: theme.spacing(1),
											padding: theme.spacing(1),
											border: `1px solid ${theme.palette.grey[300]}`,
											borderRadius: theme.shape.borderRadius,
										}}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'flex-start',
												flexDirection: isMobile() ? 'column' : 'row',
											}}
										>
											{!single && (
												<DragHandle className={clsx(styles.dragHandle, 'drag-handle')} />
											)}

											<FormikSimpleSelectField
												variant='filled'
												style={{
													minWidth: 150,
													...(isMobile()
														? {
																paddingLeft: theme.spacing(0.5),
																paddingBottom: theme.spacing(0.5),
														  }
														: {}),
												}}
												field={{
													name: `${field.name}.${index}.type`,
													label: 'Button Type',
												}}
												options={[
													{ label: 'URL', value: ButtonType.Url },
													{ label: 'Form', value: ButtonType.Form },
													{ label: 'Send an Email', value: ButtonType.Email },
													{ label: 'Start a Call', value: ButtonType.Phone },
													{ label: 'Send a Text', value: ButtonType.Sms },
												]}
											/>

											<FormikTextInput
												variant='filled'
												style={{
													marginLeft: theme.spacing(0.5),
													flex: 1,
													...(isMobile() ? { paddingBottom: theme.spacing(0.5) } : {}),
												}}
												noMargin
												hideHelperTextWhenEmpty
												fieldProps={{
													name: `${field.name}.${index}.label`,
													label: 'Button Label (optional)',
												}}
											/>

											{formikField.value &&
												formikField.value[index].type === ButtonType.Email && (
													<FormikTextInput
														variant='filled'
														hideHelperTextWhenEmpty
														style={{
															marginLeft: theme.spacing(0.5),
															flex: 1,
															...(isMobile() ? { paddingBottom: theme.spacing(0.5) } : {}),
														}}
														noMargin
														fieldProps={{
															name: `${field.name}.${index}.url`,
															label: 'Email Address',
														}}
													/>
												)}
											{formikField.value &&
												formikField.value[index].type === ButtonType.Url && (
													<FormikTextInput
														variant='filled'
														hideHelperTextWhenEmpty
														style={{
															marginLeft: theme.spacing(0.5),
															flex: 1,
															...(isMobile() ? { paddingBottom: theme.spacing(0.5) } : {}),
														}}
														noMargin
														fieldProps={{ name: `${field.name}.${index}.url`, label: 'URL' }}
													/>
												)}
											{formikField.value &&
												formikField.value[index].type === ButtonType.Form && (
													<FormikTextInput
														variant='filled'
														hideHelperTextWhenEmpty
														style={{
															marginLeft: theme.spacing(0.5),
															flex: 1,
															...(isMobile() ? { paddingBottom: theme.spacing(0.5) } : {}),
														}}
														noMargin
														fieldProps={{
															name: `${field.name}.${index}.url`,
															label: 'Form URL',
														}}
													/>
												)}
											{formikField.value &&
												(formikField.value[index].type === ButtonType.Phone ||
													formikField.value[index].type === ButtonType.Sms) && (
													<FormikPhoneField
														variant='filled'
														style={{
															marginLeft: theme.spacing(0.5),
															flex: 1,
															...(isMobile() ? { paddingBottom: theme.spacing(0.5) } : {}),
														}}
														field={{
															name: `${field.name}.${index}.url`,
															label: 'Phone Number',
														}}
													/>
												)}

											{!single && (
												<RemoveCircleOutline
													className={styles.deleteButton}
													onClick={() => arrayHelpers.remove(index)}
												/>
											)}
										</div>

										{enableDescription && (
											<div style={{ display: 'flex', marginTop: theme.spacing(1) }}>
												<FormikTextInput
													noMargin
													hideHelperTextWhenEmpty
													variant='filled'
													fieldProps={{
														name: `${field.name}.${index}.description`,
														label: 'Description',
													}}
												/>
											</div>
										)}
									</div>
								))}
							</div>

							{!single && (
								<Button
									variant='text'
									startIcon={<Add />}
									onClick={() => {
										helpers.setTouched(true)
										arrayHelpers.push({ id: v4(), type: '', label: '', url: '' })
									}}
									style={{ marginBottom: theme.spacing(1) }}
								>
									Add a Button
								</Button>
							)}
						</>
					)
				}}
			/>
			<FormHelperText>
				{meta.touched && typeof meta.error === 'string' && meta.error}
			</FormHelperText>
		</FormControl>
	)
}
