import { FormControl, InputLabel, Select, MenuItem, makeStyles, createStyles, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import { ISelectOption } from '.'
import { MUIInputVariants } from '../interfaces'
import { MATERIAL_FIELD_VARIANT } from './constants'
import { IField } from './interfaces'

const useStyles = makeStyles(() =>
    createStyles({
        noLabel: {
            paddingTop: 10
        }
    })
)

interface IFormikSimpleSelectField {
    field: IField
    options: Omit<ISelectOption<string | number>, 'key'>[]
    style?: React.CSSProperties
    multiple?: Boolean
    variant?: MUIInputVariants
}

export const FormikSimpleSelectField = ({ field, options, style, multiple, variant = MATERIAL_FIELD_VARIANT }: IFormikSimpleSelectField) => {

    const [ { value, name, onBlur }, { error, touched }, { setValue, setTouched } ] = useField<string | number>(field)

    const styles = useStyles()

    return (
        <FormControl variant={variant} error={touched && Boolean(error)} style={style}>
            <InputLabel id={field.label}>{field.label}</InputLabel>
            <Select
                label={field.label}
                labelId={field.label}
                id={name}
                value={value}
                onChange={(ev) => setValue(ev.target.value as string)}
                classes={{
                    select: field.label ? '' : styles.noLabel
                }}
                onBlur={onBlur}
                onOpen={() => {
                    setTouched(true)
                }}
                multiple={Boolean(multiple)}
            >
                {options.map(({ label, value }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
            </Select>
            {touched && error && <FormHelperText style={{ marginLeft: 0 }}>{error}</FormHelperText>}
        </FormControl>
    )
}