import { useTheme } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import { memo } from 'react'
import { ButtonInput } from '../graphql/autogenerate/schemas'
import { CountdownDisplay } from './countdown-display'
import { ImageDisplay } from './image-display'
import { LinkButton } from './link-button'
import { EmbedDisplay } from './embed-display'

interface IMarqueeDisplayProps {
	enabled: boolean
	image?: string
	body?: string | null
	countdown?: {
		time: Dayjs
		label?: string | null
		hideWhenCompleted?: boolean | null
	}
	button?: ButtonInput
	embedUrl?: string | null
	mobilePreview?: boolean
}

export const MarqueeDisplay = memo(
	({
		image,
		enabled,
		body,
		countdown,
		button,
		embedUrl,
		mobilePreview,
	}: IMarqueeDisplayProps) => {
		const theme = useTheme()

		return (
			<div>
				{image && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: theme.spacing(1),
						}}
					>
						<div style={{ flex: 1 }} />
						<ImageDisplay
							src={image}
							style={{
								minWidth: 0,
								border: `4px solid ${theme.palette.primary.main}`,
								borderRadius: '50%',
								height: 100,
								width: 100,
							}}
						/>
						<div style={{ flex: 1 }} />
					</div>
				)}

				{enabled && (body || countdown || embedUrl || button) && (
					<>
						{image && (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div
									className='arrow-up'
									style={{ borderBottomColor: theme.palette.grey[300] }}
								/>
							</div>
						)}

						<div
							style={{
								backgroundColor: theme.palette.grey[300],
								borderRadius: theme.shape.borderRadius,
								padding: theme.spacing(1),
								...theme.typography.body2,
								textAlign: 'center',
							}}
						>
							<div
								style={{ whiteSpace: 'pre-wrap' }}
								dangerouslySetInnerHTML={{ __html: body || '' }}
							/>
							{embedUrl && (
								<EmbedDisplay
									url={embedUrl}
									style={{ marginTop: theme.spacing(1) }}
									mobilePreview={mobilePreview}
								/>
							)}
							{button && button.url && (
								<LinkButton
									button={button}
									style={{
										marginBottom: 0,
										marginTop: theme.spacing(1),
										width: '90%',
									}}
								/>
							)}
							{countdown && (
								<CountdownDisplay
									style={{ marginTop: theme.spacing(1) }}
									{...countdown}
								/>
							)}
						</div>
					</>
				)}
			</div>
		)
	}
)
