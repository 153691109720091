import { FormControl, FormHelperText, FormLabel, useTheme } from '@material-ui/core'
import { useField } from 'formik'
import { UserType } from '../../graphql/autogenerate/schemas'
import { IField } from './interfaces'
import { UserTypeSelector } from './user-type-selector'

interface IFormikUserTypeSelectorProps {
    field: IField
    disableAll?: boolean
    userTypes: UserType[]
}

export const FormikUserTypeSelector = ({ field, userTypes, disableAll }: IFormikUserTypeSelectorProps) => {
    const theme = useTheme()

    const [ formikField, meta, helpers ] = useField<string[] | null>(field)

    return (
        <FormControl error={meta.touched && Boolean(meta.error)} style={{ margin: `0px ${theme.spacing(.5)}px ${theme.spacing(2)}px ${theme.spacing(.5)}px` }}>
            {field.label && <FormLabel style={{ marginBottom: theme.spacing(1) }}>{field.label}</FormLabel>}
            <UserTypeSelector
                disableAll={disableAll}
                selectedUserTypes={formikField.value}
                setSelectedUserTypes={helpers.setValue}
                onTouched={() => helpers.setTouched(true)}
                error={meta.touched && Boolean(meta.error)}
                userTypes={userTypes}
            />
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    )
}