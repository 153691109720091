import { Button, Paper, Typography, useTheme } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { CSSProperties, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInvitationDetailQuery } from '../graphql/autogenerate/react-query'
import { getContrastTextColor } from '../helpers'
import { useAccessToken, useAppState, useAuth, useHandleReactQuery } from '../hooks'
import { ImageDisplay } from './image-display'
import { Login } from './login'
import * as yup from 'yup'
import { PasswordField } from './forms'
import { AppActionType } from '../stores/app-state'

interface IState {
    existingUser?: boolean
    accept?: boolean
    accepted?: boolean
}

export const Invitation = () => {
    const { invitationSlug } = useParams()
    const theme = useTheme()
    const { acceptInvitation } = useAuth()
    const { dispatch } = useAppState()
    const { setAccessToken } = useAccessToken()

    // For cleanliness's sake, go ahead and logout on mount just in case, to guarantee we don't have any conflicts.
    useEffect(() => {
        setAccessToken(null)
        dispatch({ type: AppActionType.logout })
    }, [])

    const [ state, setState ] = useState<IState>({})

    const { data, isFetched } = useHandleReactQuery(useInvitationDetailQuery(
        { slug: invitationSlug },
        {
            enabled: Boolean(invitationSlug),
            onSuccess: ({ invitationDetail }) => {
                setState(_state => ({ ..._state, existingUser: Boolean(invitationDetail?.inviteeHasAccount) }))
            }
        }
    ))

    if (!isFetched) return null

    if (data?.invitationDetail) {
        const buttonStyle: CSSProperties = data.invitationDetail.schoolPrimaryColor ? { backgroundColor: data.invitationDetail.schoolPrimaryColor, color: getContrastTextColor(data.invitationDetail.schoolPrimaryColor) } : {}
        return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: 0, overflowY: 'hidden', backgroundColor: data.invitationDetail.schoolPrimaryColor || undefined, padding: theme.spacing(1) }}>
                <Paper style={{ display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`, maxWidth: 600, width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), }}>
                        {data.invitationDetail.schoolThumbnail?.temporaryDownloadUrl && <ImageDisplay style={{ width: 100, height: 100, marginBottom: theme.spacing(2) }} src={data.invitationDetail.schoolThumbnail?.temporaryDownloadUrl} />}
                        <Typography align='center' variant='h4'>{data.invitationDetail.schoolName}</Typography>
                    </div>

                    {state.accepted ?
                        <>
                            <Typography align='center' variant='h5' gutterBottom style={{ marginBottom: theme.spacing(1) }}>Invitation accepted!</Typography>
                            <Typography align='center' style={{ marginBottom: theme.spacing(4) }}>Please return to the app to continue setting up your account.</Typography>
                        </>
                        :
                        data.invitationDetail.invitationAccepted ?
                            <>
                                <Typography align='center' style={{ marginBottom: theme.spacing(4) }}>This invitation has already been accepted!</Typography>

                                <Button href='/login' variant='contained' style={{ ...buttonStyle, minWidth: 200 }} >Login</Button>
                            </>
                            :
                            <>
                                <Typography align='center' style={{ marginBottom: theme.spacing(2) }}>{data.invitationDetail.inviterFirstName ? <span><b>{data.invitationDetail.inviterFirstName} {data.invitationDetail.inviterLastName}</b> has invited you</span> : `You've been invited`} to join <b>{data.invitationDetail.schoolName}</b>.</Typography>

                                {state.accept ?
                                    <>
                                        {/* <Callout>
                                            <div>
                                                <div>For security purposes, you will need to <b>{state.existingUser ? 'sign in' : 'create your account'}</b> using the same email address this invitation was created for.</div>
                                                <div style={{ marginTop: theme.spacing(1) }}>If you would like to use a different email address, please <a href={`mailto:${data.invitationDetail.inviterEmail}`}>contact your school administrator</a> and request a new invitation for that email address.</div>
                                            </div>
                                        </Callout> */}

                                        {state.existingUser && data.invitationDetail.inviteeAccountEmail ?
                                            <div style={{ marginTop: theme.spacing(4), width: '100%' }}>
                                                <Login
                                                    invitationToAccept={{
                                                        email: data.invitationDetail.inviteeAccountEmail,
                                                        invitationSlug,
                                                    }}
                                                />
                                            </div>
                                            :
                                            <>
                                                <Typography align='center' >Please enter a password below to create your account.</Typography>
                                                <Formik
                                                    initialValues={{
                                                        password: '',
                                                        confirmPassowrd: ''
                                                    }}
                                                    validationSchema={yup.object({
                                                        password: yup.string()
                                                            .min(12, 'Must be at least 12 characters. Chain some common words together! Your password is stronger if it is long and you can remember it, not if it contains numbers, random capitalization, or special characters. 😊')
                                                            .required('Required'),
                                                        confirmPassword: yup.string()
                                                            .test(
                                                                'confirmPassword',
                                                                'Passwords do not match',
                                                                function (value) {
                                                                    return this.parent.password === value
                                                                }
                                                            ),
                                                    })}
                                                    onSubmit={values =>
                                                        acceptInvitation(
                                                            { invitationSlug, password: values.password },
                                                            { onSuccess: results => setState(_state => ({ ..._state, accepted: Boolean(results.acceptInvitation?.jwtToken) })) }
                                                        )}
                                                >
                                                    <Form style={{ marginTop: theme.spacing(4), width: '100%' }}>
                                                        <Typography color='textSecondary' style={{ marginLeft: theme.spacing(.5), marginBottom: theme.spacing(2) }}>
                                                            {data.invitationDetail.inviteeAccountEmail}
                                                        </Typography>
                                                        <div style={{ display: 'flex' }}><PasswordField fieldProps={{ name: 'password', label: 'Password', labelWidth: 70, placeholder: '11+ characters' }} /></div>
                                                        <div style={{ display: 'flex' }}><PasswordField fieldProps={{ name: 'confirmPassword', label: 'Confirm Password', labelWidth: 140 }} /></div>

                                                        <div style={{ margin: `0px ${theme.spacing(.5)}px`, display: 'flex' }}>
                                                            <Button type='submit' style={{ ...buttonStyle, flex: 1 }} disableElevation >Create Account</Button>
                                                        </div>

                                                    </Form>
                                                </Formik>
                                            </>
                                        }
                                    </>
                                    :
                                    <Button variant='contained' style={{ ...buttonStyle, minWidth: 200 }} onClick={() => setState(_state => ({ ..._state, accept: true }))} disableElevation>Get Started</Button>
                                }
                            </>
                    }
                </Paper>
            </div>
        )
    } else {
        return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paper style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(4) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: theme.spacing(4) }}>
                        <Typography style={{ display: 'flex', }} color='textSecondary' variant='h4'><span>404</span> <div style={{ borderRight: `2px solid ${theme.palette.grey[ 500 ]}`, margin: `0px ${theme.spacing(2)}px` }} /> <span>Invitation not found</span></Typography>
                    </div>

                    <Typography style={{ marginBottom: theme.spacing(4) }}>It could be that your school administrator canceled the invitation.</Typography>
                    <Typography> Please contact them directly to ensure you have an updated invitation link.</Typography>
                </Paper>
            </div>
        )
    }
}

const SigninToAccept = () => {
    return (
        <div>

        </div>
    )
}