import { Button, Paper, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppState, useMobileDetect } from '../hooks'
import logo from '../images/logo.svg'
import { useSchoolContext } from '../stores/school'
import { AdminSchool } from './admin-school'
import { DirectoryListingView } from './directory-listing-view'
import { GuardedRoute } from './guarded-route'
import { LoadingOverlay } from './loading-overlay'
import { ResourceDetailView } from './resource-detail-view'
import { School } from './school'
import { SchoolCalendar } from './school-calendar'
import { SchoolDirectory } from './school-directory'
import { SchoolGroups } from './school-groups'
import { SchoolHome } from './school-home'
import { SchoolNotifications } from './school-notifications'
import { SchoolOnboarding } from './school-onboarding'
import { SchoolProvider } from './school-provider'
import { SchoolResourcesSearch } from './school-resources-search'
import { SchoolSponsors } from './school-sponsors'

export const SchoolRoutes = () => {
    const { state: { currentUser } } = useAppState()
    if (!currentUser) return <LoadingOverlay />

    return (
        <SchoolProvider refetchEnabled>
            <SchoolRoutesActual />
        </SchoolProvider>
    )
}

/**
    - If desktop and signed in:       redirect to `/school/{slug}/admin`
    - If desktop and not signed in:   redirect to `/login`
    - If mobile and signed in:        redirect to `/school/{slug}/home`
    - If mobile and not signed in:    redirect to `/school/{slug}/join`
*/
const SchoolRoutesActual = () => {
    const theme = useTheme()
    const { isMobile } = useMobileDetect()
    const { state: { currentUser, authed } } = useAppState()
    const { state: { currentUserPermissions, school: { appIsLatest, isLaunched, archived, name, schoolSetting } }, userHasOnboarded } = useSchoolContext()

    const phoneAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const tabletAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(768))
    const tabletAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(768))

    const userHasAdminAccessToSchool = Boolean(currentUserPermissions.school?.schoolPersonInvitation?.accepted || currentUser?.appAdministrator)

    const adminRoutes = (
        <>
            <GuardedRoute
                path='admin/*'
                guard={userHasAdminAccessToSchool}
                element={
                    (!appIsLatest && isLaunched && !currentUser?.appAdministrator) ?
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Paper style={{ maxWidth: 850, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(4), ...phoneAndDown ? { flex: 1 } : {} }}>
                                <a href='https://www.legitapps.com/schools'><img src={logo} alt='Legit Apps logo' style={{ maxWidth: 75, marginBottom: theme.spacing(4) }} /></a>

                                <Typography variant='h5' align='center'>🏗️ Migration in Progress</Typography>

                                <Typography style={{ marginTop: theme.spacing(4), width: '100%' }}>Your school's iOS and Android apps are still in the process of being updated to 4.0.</Typography>

                                <Typography style={{ marginTop: theme.spacing(2), width: '100%' }}>To expedite the migration, please ensure you have accepted the latest agreement for your Apple Developer account.</Typography>

                                <Typography style={{ marginTop: theme.spacing(2), width: '100%' }}>In the meantime, you can continue to manage your current app at <a href='https://legacy.legitapps.com/'>https://legacy.legitapps.com/</a>.</Typography>

                                <Typography style={{ marginTop: theme.spacing(2), width: '100%' }}><b>Please make as few edits as possible until you've been notified that your app is fully migrated. Any changes made while the migration is progress will not be transferred.</b></Typography>

                                <Typography style={{ marginTop: theme.spacing(2), width: '100%' }}>Please contact us on Basecamp or at <a href='support@legitapps.com'>support@legitapps.com</a> if you have any questions about your migration!</Typography>

                                <div style={{ display: 'flex', width: '100%', marginTop: theme.spacing(2) }}>
                                    <Button variant='text' href='https://legacy.legitapps.com/' style={{ minWidth: 100 }}>Manage current (unmigrated) app</Button>
                                </div>
                            </Paper>
                        </div>
                        :
                        <AdminSchool />
                }
                redirect={!currentUser ? '/' : undefined}
            />

            <Route path='*' element={<Navigate to='admin' replace />} />
        </>
    )

    const publicRoutes = (
        <>
            <GuardedRoute
                guard={authed}
                redirect='join'
                path=''
                element={<Navigate to='home' replace />}
            />

            <Route path='*' element={<School />}>
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='home' element={<SchoolHome />} title='Home' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='search' element={<SchoolResourcesSearch />} title='Search' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='sponsors/*' element={<SchoolSponsors />} title='Sponsors' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='calendar' element={<SchoolCalendar />} title='Calendar' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='groups/*' element={<SchoolGroups />} title='Groups' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='notifications' element={<SchoolNotifications />} title='Notifications' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='directory' element={<SchoolDirectory />} title='Directory' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='directory/:personId' element={<SchoolDirectory />} title='Directory' />
                <GuardedRoute guard={userHasOnboarded} redirect='../join' path='resources/:resourceSlug' element={<ResourceDetailView />} title='Resource' />
                <GuardedRoute guard={!userHasOnboarded} redirect='../home' path='join' element={<SchoolOnboarding />} title='Onboarding' />

                {tabletAndUp && adminRoutes}

                <Route path='*' element={<Navigate to='home' replace />} />
            </Route>

            <Route path='*' element={<Navigate to='home' replace />} />
        </>
    )

    if (archived && !currentUser?.appAdministrator) {
        let archivedSchoolMessage = (
            <>
                <Typography align='center' gutterBottom variant='h5' style={{ display: 'flex', alignItems: 'center' }}>Account Inactive</Typography>
                <Typography gutterBottom>We're sorry to see you go! Please contact <a href='mailto:support@legitapps.com'>customer support</a> if you'd like to renew your service or if you have a question about your account deactivation.</Typography>
            </>
        )

        if (isMobile()) {
            archivedSchoolMessage = (
                <>
                    <Typography align='center' gutterBottom variant='h5' style={{ display: 'flex', alignItems: 'center' }}>App Inactive</Typography>
                    <Typography gutterBottom>The {name} app has been discontinued. Please contact your administrator if you have any questions or would like to see the app reenabled.</Typography>
                </>
            )
        }

        return (
            <Routes>
                <Route
                    path='*'
                    element={
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ maxWidth: 700, margin: `0px ${theme.spacing(2)}px` }}>
                                <img style={{ width: 50, height: 50, borderRadius: theme.shape.borderRadius, marginBottom: theme.spacing(2) }} src={schoolSetting?.thumbnailImage?.temporaryDownloadUrl} />
                                {archivedSchoolMessage}
                            </div>

                        </div>
                    }
                />
            </Routes>
        )
    }

    if (!currentUser) return <LoadingOverlay />

    return (
        <>
            {/* Desktop */}
            {!isMobile() &&
                <Routes>
                    {/* On desktop we want to guarantee the admin UI will be available */}
                    {/* On large, show just the admin UI and do the auto redirect */}
                    {/* On tablet size and down, show the mobile app UI, which includes the admin tab on tablet size */}

                    {tabletAndUp &&
                        <>
                            <GuardedRoute
                                guard={authed}
                                redirect='/login'
                                path=''
                                element={<Navigate to='admin' replace />}
                            />

                            {adminRoutes}
                        </>
                    }

                    {tabletAndDown && publicRoutes}
                </Routes>
            }

            {/* Mobile */}
            {/* On mobile, we want to make sure we ALWAYS show the mobile UI, no matter how large the screen... */}
            {isMobile() &&
                <Routes>
                    {publicRoutes}
                </Routes>
            }
        </>
    )
}