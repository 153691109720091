import { makeStyles, createStyles } from '@material-ui/core'

// #E7F0F9

export const usePageSectionStyles = makeStyles(theme =>
    createStyles({
        container: {
            position: 'relative',
            minHeight: 40,
        },
        contentContainer: {
            // padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        },
        iconContainer: {
            position: 'absolute',
            color: 'white',
            display: 'flex',
            transition: 'opacity .2s ease-in-out',
        },
        topRight: {
            top: theme.spacing(.5),
            right: theme.spacing(.5),
        },
        topLeft: {
            top: theme.spacing(.5),
            left: theme.spacing(.5),
        },
        addIconContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 10,
        },
        addIconContainerBottom: {
            bottom: -15,
        },
        addIconContainerTop: {
            top: -15,
        },
        icon: {
            zIndex: 10,
            backgroundColor: `${theme.palette.grey[ 300 ]}`,
            transition: 'opacity .2s ease-in-out',
            opacity: .5,
            '&:hover': {
                backgroundColor: `${theme.palette.grey[ 300 ]}`,
                opacity: 1,
            },

        },
        deleteIcon: {
            marginLeft: theme.spacing(1)
        },
        coverImage: {
            height: 250,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
        title: {
            padding: theme.spacing(1),
            paddingTop: 0,
        },
        subtitle: {
            color: theme.palette.grey[ 700 ]
        },
        hidden: {
            display: 'none'
        },
        isDragging: {
            height: 100,
            border: `5px dashed ${theme.palette.grey[ 500 ]}`,
            borderRadius: theme.shape.borderRadius,
            margin: `0px ${theme.spacing(2)}px`,
            cursor: 'grabbing'
        }
    })
)