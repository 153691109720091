import React, { useEffect, useMemo, useState } from 'react'
import {
	Box,
	Divider,
	Tab,
	Tabs,
	Paper,
	Fab,
	useTheme,
} from '@material-ui/core'
import {
	useHandleReactQuery,
	useRegisterBreadcrumb,
	useSetPageTitle,
} from '../hooks'
import { AdminDivisionDetail } from './admin-division-detail'
import { EditDivisionsButtons } from './edit-divisions-button'
import { Add, People } from '@material-ui/icons'
import { GroupFormModal } from './group-form-modal'
import { useModal } from './modal'
import {
	GroupListFieldsFragment,
	SchoolDivisionsFragment,
} from '../graphql/autogenerate/operations'
import { ISchoolContext, useSchoolContext } from '../stores/school'
import { useCurrentUserQuery } from '../graphql/autogenerate/react-query'

export const AdminSchoolDivisions = () => {
	const {
		state: {
			school: { divisions, id: schoolId },
		},
		actions: {
			refetch: { schoolDetail },
		},
	} = useSchoolContext()

	return (
		<MemoizedAdminSchoolDivisions
			divisions={divisions.nodes}
			schoolId={schoolId}
			refetchSchoolDetail={schoolDetail}
		/>
	)
}

const MemoizedAdminSchoolDivisions = React.memo(
	({
		divisions,
		refetchSchoolDetail,
		schoolId,
	}: {
		schoolId: string
		divisions: SchoolDivisionsFragment[]
		refetchSchoolDetail: ISchoolContext['actions']['refetch']['schoolDetail']
	}) => {
		const theme = useTheme()

		const currentUserQuery = useHandleReactQuery(useCurrentUserQuery())

		// const [ groupSearchTerm, setGroupSearchTerm ] = useState('')

		const [selectedTabIndex, setSelectedTabIndex] = useState('0')

		const canAddGroups = useMemo(() => {
			return !!divisions.some(division => {
				return division.groupCategories.nodes.length > 0
			})
		}, [divisions])
		const addEditGroupModal = useModal()
		const [groupToEdit, setGroupToEdit] = useState<GroupListFieldsFragment>()
		useEffect(() => {
			if (!!groupToEdit) addEditGroupModal.open()
		}, [groupToEdit])
		useEffect(() => {
			if (!addEditGroupModal.props.isOpen) setGroupToEdit(undefined)
		}, [addEditGroupModal.props.isOpen])

		const label = useMemo(() => {
			if (currentUserQuery.data?.currentUser?.appAdministrator)
				return 'Divisions & Groups'
			if (divisions.length > 1) return 'Divisions & Groups'
			return 'Groups'
		}, [divisions, currentUserQuery.data])
		useRegisterBreadcrumb({
			relativePath: 'groups',
			label,
			Icon: People,
		})
		useSetPageTitle(label)

		return (
			<div
				style={{
					flex: 1,
					margin: theme.spacing(2),
					display: 'flex',
					flexDirection: 'column',
					minHeight: 0,
					overflow: 'hidden',
				}}
			>
				<div
					style={{ display: 'flex', flexDirection: 'column', minHeight: 0, flex: 1 }}
				>
					{currentUserQuery.data?.currentUser?.appAdministrator && (
						<>
							<div
								style={{
									flexBasis: 'unset',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								{/* <div item lg={1}>
                                <TextInput
                                    fieldProps={{ name: 'groupSearch', label: 'Filter Groups' }}
                                    value={groupSearchTerm}
                                    onChange={setGroupSearchTerm}
                                    hideHelperTextWhenEmpty
                                    style={{ minWidth: 200, margin: 0 }}
                                    size='small'
                                />
                            </div> */}

								<div>
									<EditDivisionsButtons
										schoolId={schoolId}
										refetchDivisions={refetchSchoolDetail}
										divisions={divisions}
									/>
								</div>
							</div>

							<Box marginY={2}>
								<Divider />
							</Box>
						</>
					)}

					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minHeight: 0,
							overflow: 'hidden',
						}}
					>
						{divisions.length === 1 && (
							<Paper
								style={{
									flex: 1,
									padding: theme.spacing(2),
									display: 'flex',
									height: '100%',
									flexDirection: 'column',
									overflow: 'hidden',
								}}
							>
								{divisions.map(
									(division, index) =>
										index.toString() === selectedTabIndex && (
											<AdminDivisionDetail
												division={divisions[0]}
												refetch={refetchSchoolDetail}
												schoolId={schoolId}
												setGroupToEdit={setGroupToEdit}
											/>
										)
								)}
							</Paper>
						)}

						{divisions.length > 1 && (
							<>
								<Paper>
									<Tabs
										value={selectedTabIndex}
										onChange={(_, newValue) => setSelectedTabIndex(newValue)}
										indicatorColor='primary'
										textColor='primary'
										variant='scrollable'
									>
										{divisions.map((division, index) => (
											<Tab
												key={division.id}
												label={division.name}
												value={index.toString()}
											/>
										))}
									</Tabs>
								</Paper>

								<Box marginY={0.5} />

								<Paper
									style={{
										flex: 1,
										padding: theme.spacing(2),
										display: 'flex',
										height: '100%',
										flexDirection: 'column',
										overflow: 'hidden',
									}}
								>
									{divisions.map(
										(division, index) =>
											index.toString() === selectedTabIndex && (
												<AdminDivisionDetail
													key={division.id}
													division={division}
													refetch={refetchSchoolDetail}
													schoolId={schoolId}
													setGroupToEdit={setGroupToEdit}
												/>
											)
									)}
								</Paper>
							</>
						)}
					</div>
				</div>

				{canAddGroups && (
					<>
						<Fab
							onClick={addEditGroupModal.open}
							variant='extended'
							color='primary'
							style={{
								position: 'absolute',
								bottom: theme.spacing(4),
								right: theme.spacing(4),
							}}
						>
							<Add style={{ marginRight: theme.spacing(1), marginBottom: 3 }} />
							Add Group
						</Fab>
						<GroupFormModal
							modalControl={addEditGroupModal}
							// Safe to ! these since the canAddGroups checks these values
							divisions={divisions}
							afterSave={() => refetchSchoolDetail()}
							groupToEdit={groupToEdit}
						/>
					</>
				)}
			</div>
		)
	}
)
