import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react"
import IMask from 'imask'
import { FormControl, InputBaseComponentProps, TextField } from '@material-ui/core'
import { CommonProps, MUIInputVariants } from '../interfaces'
import { MATERIAL_FIELD_VARIANT } from './constants'

interface IMaskedInputProps extends CommonProps {
    mask: IMask.AnyMaskedOptions
    setValue: (val: string) => void
    value: string
    label?: string
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    name?: string
    touched?: boolean
    error?: string
    helperText?: string
    variant?: MUIInputVariants
    disabled?: boolean
}
export const MaskedInput = forwardRef<HTMLInputElement, IMaskedInputProps>(({ label, mask, setValue, value, error, touched, variant = MATERIAL_FIELD_VARIANT, helperText, style, className, disabled, ...rest }, forwardedRef) => {
    return (
        <FormControl disabled={disabled} error={touched && Boolean(error)} className={className} style={style}>
            {/* <InputLabel shrink>{label}</InputLabel> */}
            <TextField
                disabled={disabled}
                error={touched && Boolean(error)}
                value={value}
                variant={variant}
                helperText={(touched && error) || helperText}
                label={label}
                InputLabelProps={{
                    shrink: Boolean(value)
                }}
                InputProps={{
                    inputComponent: Mask,
                    inputProps: { mask, setValue, value, ref: forwardedRef }
                }}
                {...rest}
            />
        </FormControl>
    )
})

interface IMaskProps extends InputBaseComponentProps {
    mask: IMask.AnyMaskedOptions
    setValue: (val: string) => void
    value: string
}
const Mask = forwardRef<HTMLInputElement | undefined, IMaskProps>(({ mask, setValue, value, inputRef: discarded, ...rest }, forwardedRef) => {
    const iMaskInstance = useRef<IMask.InputMask<typeof mask>>()
    useEffect(() => {
        return () => {
            if (iMaskInstance.current) iMaskInstance.current.destroy()
        }
    }, [])

    const inputRef = useRef<HTMLInputElement>()
    const refHandler = useCallback((node: HTMLInputElement) => {
        if (!node) return
        inputRef.current = node
        const iMask = IMask(node, mask)
        iMaskInstance.current = iMask
        iMaskInstance.current.on('accept', () => setValue(iMask.unmaskedValue))
    }, [])

    /* 
        To support forwarded refs AND use one locally we have to do some gymnastics to recombine them.
        useImperativeHandle sets and returns the value of the forwaded ref back up to the parent component.
    */
    useImperativeHandle(forwardedRef, () => {
        if (!inputRef.current) return undefined
        return {
            ...inputRef.current,
            focus: () => {
                inputRef.current && inputRef.current.focus()
            }
        }
    })

    useEffect(() => {
        if (iMaskInstance.current && iMaskInstance.current.unmaskedValue !== value && typeof value === 'string') {
            iMaskInstance.current.unmaskedValue = value
        }
    }, [ value ])

    return (
        <input
            ref={refHandler}
            {...rest}
        />
    )
})