import { Avatar, Paper, Typography, useTheme } from '@material-ui/core'
import { Edit, Email, Phone } from '@material-ui/icons'
import clsx from 'clsx'
import { CSSProperties } from 'react'
import { a } from 'react-spring'
import { UserType } from '../graphql/autogenerate/schemas'
import { getLabelForUserType } from '../helpers'
import { useBoop, useFormatPhoneNumber } from '../hooks'
import { useCommonStyles } from '../styles'

interface IContactCardProps {
    profileImageUrl?: string | null
    title?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phone?: string | null
    userType?: UserType | null
    style?: CSSProperties | null
    onClick?: () => void
    editIcon?: boolean
}

export const ContactCard = ({ title, firstName, lastName, profileImageUrl, userType, email, phone, style, onClick, editIcon }: IContactCardProps) => {
    const theme = useTheme()
    const styles = useCommonStyles()

    const hoverBoop = useBoop({ rotation: 15, timing: 100, })

    const formatPhoneNumber = useFormatPhoneNumber()

    return (
        <Paper onMouseEnter={hoverBoop.trigger} onClick={onClick} className={clsx(onClick && styles.elevationOnHover)} style={{ display: 'flex', padding: theme.spacing(1), alignItems: 'center', ...onClick ? { cursor: 'pointer' } : {}, ...style, }}>
            <Avatar alt={`${firstName} ${lastName}`} src={profileImageUrl || ''} style={{ width: 60, height: 60 }}>
                {firstName?.slice(0, 1)}{lastName?.slice(0, 1)}
            </Avatar>
            <div style={{ flex: 1, marginLeft: theme.spacing(1) }}>
                <Typography variant='h6' style={{ lineHeight: 1 }}>{title} {firstName} {lastName}</Typography>
                {userType && <Typography variant='caption' color='textSecondary'>{getLabelForUserType(userType)}</Typography>}
                <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', color: theme.palette.grey[ 800 ] }}>
                    {email &&
                        <>
                            <Email style={{ fontSize: '1rem', color: theme.palette.grey[ 500 ], marginRight: theme.spacing(.5) }} />
                            <div style={{ marginRight: theme.spacing(1) }} >{email}</div>
                        </>
                    }
                    {phone &&
                        <>
                            <Phone style={{ fontSize: '1rem', color: theme.palette.grey[ 500 ], marginRight: theme.spacing(.5) }} />
                            <div>{formatPhoneNumber(phone)}</div>
                        </>
                    }
                </Typography>
            </div>
            {editIcon &&
                <a.div style={hoverBoop.style}>
                    <Edit style={{ color: theme.palette.grey[ 700 ] }} />
                </a.div>
            }
        </Paper>
    )
}