import clsx from 'clsx'
import { CSSProperties, DetailedHTMLProps, IframeHTMLAttributes } from 'react'

interface IEmbedDisplayProps {
	url: string
	style?: CSSProperties
	mobilePreview?: boolean
}

export const EmbedDisplay = ({
	url,
	style,
	mobilePreview,
}: IEmbedDisplayProps) => {
	const iframeProps = getEmbedProps(url)

	if (!iframeProps) return null

	return (
		<iframe
			width='100%'
			frameBorder='0'
			title='Embed'
			allowFullScreen
			{...iframeProps}
			style={style}
			className={clsx(
				'embed-display',
				mobilePreview && 'mobile-preview',
				iframeProps.className
			)}
		/>
	)
}

const getEmbedProps = (
	url: string
):
	| DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>
	| undefined => {
	try {
		const parsedUrl = new URL(url)

		// YouTube
		const youtubeMatch = url.match(
			/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
		)
		if (
			youtubeMatch &&
			youtubeMatch.length === 2 &&
			youtubeMatch[1].length === 11
		)
			return {
				src: `https://www.youtube.com/embed/${youtubeMatch[1]}?rel=0&legitappsembed=true`,
				allow:
					'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
				className: 'youtube',
			}

		// Vimeo
		const vimeoId = (() => {
			if (url.includes('vimeo.com')) {
				const id = url.split('/').pop()
				return id
			}
		})()
		if (vimeoId) {
			return {
				src: `https://player.vimeo.com/video/${vimeoId}?legitappsembed=true`,
				allow: 'autoplay; fullscreen; picture-in-picture',
				className: 'vimeo',
			}
		}

		// Apple Podcast
		if (parsedUrl.host.includes('apple.com')) {
			return {
				src: `https://embed.podcasts.apple.com/${parsedUrl.pathname}?legitappsembed=true`,
				allow: 'autoplay *; encrypted-media *; fullscreen *; clipboard-write',
				sandbox:
					'allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation',
				className: 'apple-podcast',
			}
		}

		// Spotify
		if (url.includes('open.spotify.com/episode')) {
			return {
				src: `https://open.spotify.com/embed${parsedUrl.pathname}?legitappsembed=true`,
				allow:
					'autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture',
				className: 'spotify',
			}
		}

		// Soundcloud
		if (url.includes('soundcloud.com')) {
			return {
				src: `https://w.soundcloud.com/player/?url=https%3A//soundcloud.com${parsedUrl.pathname}&&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&legitappsembed=true`,
				allow: 'autoplay',
				className: 'soundcloud',
			}
		}
	} catch (e) {
		console.error('invalid url', url)
	}
}
