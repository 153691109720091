import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import React from 'react'
import { CalendarViewMode } from '../stores/calendar'

interface ICalendarHeaderSelectedDateTitleProps {
    viewMode: CalendarViewMode
    selectedDate: Dayjs
}

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            color: theme.palette.grey[ 800 ],
            flex: 1,
        }
    })
)

const CalendarHeaderSelectedDateTitle = ({ selectedDate, viewMode }: ICalendarHeaderSelectedDateTitleProps) => {
    const styles = useStyles()

    return (
        <Typography variant='h5' className={styles.title}>
            {selectedDate.format(`MMMM${viewMode === 'day' ? ' D,' : ''} YYYY`)}
        </Typography>
    )
}

export const MemoizedCalendarHeaderSelectedDateTitle = React.memo(CalendarHeaderSelectedDateTitle)