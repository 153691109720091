export const xyzTransitionClasses = {
	appearFrom: 'xyz-appear-from',
	appearActive: 'xyz-appear',
	appearTo: 'xyz-appear-to',
	inFrom: 'xyz-in-from',
	inActive: 'xyz-in',
	inTo: 'xyz-in-to',
	outFrom: 'xyz-out-from',
	outActive: 'xyz-out',
	outTo: 'xyz-out-to',
}

export const mappedXyzTransitionClasses = {
	appear: xyzTransitionClasses.appearFrom,
	appearActive: xyzTransitionClasses.appearActive,
	appearDone: xyzTransitionClasses.appearTo,
	enter: xyzTransitionClasses.inFrom,
	enterActive: xyzTransitionClasses.inActive,
	enterDone: xyzTransitionClasses.inTo,
	exit: xyzTransitionClasses.outFrom,
	exitActive: xyzTransitionClasses.outActive,
	exitDone: xyzTransitionClasses.outTo,
}