import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { createContext, FunctionComponent, useContext, useReducer } from 'react'
import { reducer, CalendarAction, ICalendarState } from '.'
import { useCalendarGroupsQuery, useCurrentUserSessionQuery, useUpdateCurrentUserSessionDataMutation } from '../../graphql/autogenerate/react-query'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../../hooks'
import { useSchoolContext } from '../school'
import { calendarStateFromDb, calendarStateToDb } from './db-transform'
import { CalendarActionType } from './definitions'
import { useCalendarQuery } from './use-calendar-query'


export interface ICalendarActions {
    refetch: {
        events: ReturnType<typeof useCalendarQuery>[ 'eventsQuery' ][ 'refetch' ]
        calendarGroups: ReturnType<typeof useCalendarGroupsQuery>[ 'refetch' ]
    }
}

interface ICalendarContext {
    state: ICalendarState
    dispatch: React.Dispatch<CalendarAction>
    actions: ICalendarActions
}


const CalendarContext = createContext<ICalendarContext | undefined>(undefined)

export const useCalendarContext = () => {
    const context = useContext(CalendarContext)
    if (!context) throw new Error('Attempted to CalendarContext before it was provided.')
    return context
}

const Provider: FunctionComponent<{ initialState: ICalendarState, }> = ({ children, initialState, }) => {
    const { state: { school: { id: schoolId } } } = useSchoolContext()

    const [ state, dispatch ] = useReducer(reducer, initialState)

    /* 
        Save the user's interaction with the calendar any time the slice of state we persist to DB changes.
        We don't want to save every time any of the state changes since there are a lot of portions of the calendar state that we don't persist to DB.
    */
    const prevState = useRef(state)
    useEffect(() => {
        const prevSessionDataPartial = calendarStateToDb(prevState.current)
        const sessionDataPartial = calendarStateToDb(state)

        if (!isEqual(prevSessionDataPartial, sessionDataPartial)) updateSesionData({ sessionDataPartial })

        prevState.current = state

    }, [ state ])

    const { mutate: updateSesionData } =useHandleReactQueryMutation( useUpdateCurrentUserSessionDataMutation())

    const calendarGroupsQuery = useHandleReactQuery(useCalendarGroupsQuery({ schoolId }))
    useEffect(() => {
        if (calendarGroupsQuery.data?.calendarGroups?.nodes) dispatch({ type: CalendarActionType.setCalendarGroups, payload: { calendarGroups: calendarGroupsQuery.data.calendarGroups.nodes } })
    }, [ calendarGroupsQuery.data ])

    const { eventsQuery: { refetch: refetchEvents } } = useCalendarQuery({ state, dispatch })

    return <CalendarContext.Provider value={{ state, dispatch, actions: { refetch: { events: refetchEvents, calendarGroups: calendarGroupsQuery.refetch } } }} children={children} />
}

const ProvideInitialCalendarContextState: FunctionComponent = ({ children }) => {
    const { state: { school: { id: schoolId } } } = useSchoolContext()

    const currentUserSessionQuery = useHandleReactQuery(useCurrentUserSessionQuery())
    const sessionData = currentUserSessionQuery.data?.currentUser?.userSession?.sessionData

    const calendarGroupsQuery = useCalendarGroupsQuery( { schoolId })
    const initialState: ICalendarState = useMemo(() => {
        return calendarStateFromDb(sessionData)
    }, [ sessionData ])

    if (!currentUserSessionQuery.data || !calendarGroupsQuery.data?.calendarGroups) return null

    return (
        <Provider
            children={children}
            initialState={{
                ...initialState,
                events: [],
                selectedDate: dayjs(),
                calendarGroups: calendarGroupsQuery.data.calendarGroups.nodes,
            }}
        />
    )
}

/* 
    Alias
*/
export const CalendarContextProvider: FunctionComponent = ({ children }) => {
    return <ProvideInitialCalendarContextState children={children} />
}



