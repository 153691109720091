import { useQuery, UseQueryOptions } from 'react-query'
import * as Types from './autogenerate/operations'
import { useFetchData } from './network'

/** 
    There is a bug in GraphQL codegen preventing it from de-duping fragments included in a query.
    https://github.com/dotansimha/graphql-code-generator/issues/6239

    This workaround is simpmle copy/pasting the contents of `client\src\graphql\autogenerate\react-query.ts#SchoolDetailDocument` and removing the duplicate fragments.
    Copy/pasting and editing the query would need to be manually updated any time any of the fragments or queries within the school detail query get updated.
*/
export const useSchoolDetailQueryOverride = <
    TData = Types.SchoolDetailQuery,
    TError = unknown
> (
    variables: Types.SchoolDetailQueryVariables,
    options?: UseQueryOptions<Types.SchoolDetailQuery, TError, TData>
) =>
    useQuery<Types.SchoolDetailQuery, TError, TData>(
        [ 'SchoolDetail', variables ],
        useFetchData<Types.SchoolDetailQuery, Types.SchoolDetailQueryVariables>(` query SchoolDetail($slug: String!) {
            schoolBySlug(slug: $slug) {
              ...SchoolDetail
            }
          }
              
              fragment SchoolDetail on School {
            theme {
              primaryColor
              secondaryColor
            }
            appleDeveloperAccountExpiry
            appIsLatest
            iosAppIsLatest
            androidAppIsLatest
            androidAppDownloadUrl
            iosAppDownloadUrl
            isLaunched
            isMigrated
            name
            schoolwideLabel
            id
            slug
            archived
            schoolSetting {
              ...SchoolSetting
            }
            divisions(orderBy: SORT_ORDER_ASC) {
              nodes {
                ...SchoolDivisions
              }
              totalCount
            }
            sponsors(orderBy: SORT_ORDER_ASC, filter: {published: {equalTo: true}}) {
              totalCount
              nodes {
                id
                logoImage {
                  ...File
                }
                type
              }
            }
          }
              
              fragment SchoolSetting on SchoolSetting {
            coverImage {
              ...File
            }
            downloadUrl
            feedbackUrl
            launchScreenEnabled
            launchScreenPoweredByImage {
              ...File
            }
            launchScreenPoweredByLabel
            logoImage {
              ...File
            }
            mascotImage {
              ...File
            }
            schoolImage {
              ...File
            }
            sponsored
            sponsorsIncludeSilversOnHome
            thumbnailImage {
              ...File
            }
            timeZone
            parentPasscode
            parentPasscodeHint
            studentPasscode
            studentPasscodeHint
            enableGuestAccess
            enableParentFamilyAccess
            enableStudentAccess
            googleAnalyticsId
            scheduledFacultyReset
            scheduledGuestReset
            scheduledParentReset
            scheduledStudentReset
            resourceTileOpacity
            goldSponsorLabel
            silverSponsorLabel
            bronzeSponsorLabel
          }
              
              fragment File on File {
            temporaryDownloadUrl
            filename
            id
            label
            type
          }
              
          
              fragment SchoolDivisions on Division {
            name
            id
            schoolwide
            group {
              ...GroupListFields
            }
            groupCategories(orderBy: SORT_ORDER_ASC) {
              nodes {
                ...GroupCategoryListFields
              }
              totalCount
            }
          }
              
              fragment GroupListFields on Group {
            groupName
            iconType
            id
            information
            groupCategoryId
            divisionId
            isDivisionGroup
            isSchoolwideGroup
            icon
            calendars {
              nodes {
                id
                title
              }
            }
            passcode
            passcodeHint
            autoEnrollUserTypes
            slug
          }
              
          
              fragment GroupCategoryListFields on GroupCategory {
            name
            id
            groups(orderBy: SORT_ORDER_ASC) {
              nodes {
                ...GroupListFields
              }
              totalCount
            }
            iconBackgroundColor
            iconType
          }`).bind(null, variables),
        options
    )