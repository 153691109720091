export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: number;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  Email: string;
  /**
   * Value type that performs a check to ensure the supplied color matches hexcode format. <br/><br/>
   *
   * Regex pulled from [here](https://stackoverflow.com/a/53330328/1594335).
   */
  HexColor: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  JwtToken: string;
  /** A small helper value type for timezones. Uses the is_timezone helper to validate the text value. */
  Timezone: string;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

/** All input for the `acceptInvitation` mutation. */
export type AcceptInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invitationSlug: Scalars['String'];
  password: Scalars['String'];
};

/** The output of our `acceptInvitation` mutation. */
export type AcceptInvitationPayload = {
  __typename?: 'AcceptInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `addJks64ToJavaKeyStore` mutation. */
export type AddJks64ToJavaKeyStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vJksId: Scalars['UUID'];
  vJks64: Scalars['String'];
};

/** The output of our `addJks64ToJavaKeyStore` mutation. */
export type AddJks64ToJavaKeyStorePayload = {
  __typename?: 'AddJks64ToJavaKeyStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum Alignment {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

/** A filter to be used against Alignment fields. All fields are combined with a logical ‘and.’ */
export type AlignmentFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Alignment>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Alignment>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Alignment>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Alignment>;
  /** Included in the specified list. */
  in?: Maybe<Array<Alignment>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Alignment>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Alignment>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Alignment>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Alignment>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Alignment>;
};

/**
 * `android_app` is connected to `app` in a 1-1 relationship where app's foreign key is on that android_app table. \n\n
 *
 * `android_app` is connected to `android_java_key_store` in a one to many (1 android app to many jks) where`android_app`'s `id` is a foreign key on the jks table. \n\n
 *
 * `android_app` is connected to builds and tasks table in a one to many relationship where`app`'s `id` is a foreign key. (Not built or fully settled on yet)
 *
 * The latest build is the most recently updated build that is live and has this android_app_id as a foreign key. The latest release is the release id associated with that build.
 */
export type AndroidApp = Node & {
  __typename?: 'AndroidApp';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  /** Reads a single `App` that is related to this `AndroidApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStores: AndroidJavaKeyStoresConnection;
  /** Reads a single `GooglePlayListing` that is related to this `AndroidApp`. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedBy: AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedBy: AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyConnection;
};


/**
 * `android_app` is connected to `app` in a 1-1 relationship where app's foreign key is on that android_app table. \n\n
 *
 * `android_app` is connected to `android_java_key_store` in a one to many (1 android app to many jks) where`android_app`'s `id` is a foreign key on the jks table. \n\n
 *
 * `android_app` is connected to builds and tasks table in a one to many relationship where`app`'s `id` is a foreign key. (Not built or fully settled on yet)
 *
 * The latest build is the most recently updated build that is live and has this android_app_id as a foreign key. The latest release is the release id associated with that build.
 */
export type AndroidAppAndroidJavaKeyStoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};


/**
 * `android_app` is connected to `app` in a 1-1 relationship where app's foreign key is on that android_app table. \n\n
 *
 * `android_app` is connected to `android_java_key_store` in a one to many (1 android app to many jks) where`android_app`'s `id` is a foreign key on the jks table. \n\n
 *
 * `android_app` is connected to builds and tasks table in a one to many relationship where`app`'s `id` is a foreign key. (Not built or fully settled on yet)
 *
 * The latest build is the most recently updated build that is live and has this android_app_id as a foreign key. The latest release is the release id associated with that build.
 */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `android_app` is connected to `app` in a 1-1 relationship where app's foreign key is on that android_app table. \n\n
 *
 * `android_app` is connected to `android_java_key_store` in a one to many (1 android app to many jks) where`android_app`'s `id` is a foreign key on the jks table. \n\n
 *
 * `android_app` is connected to builds and tasks table in a one to many relationship where`app`'s `id` is a foreign key. (Not built or fully settled on yet)
 *
 * The latest build is the most recently updated build that is live and has this android_app_id as a foreign key. The latest release is the release id associated with that build.
 */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The fields on `androidApp` to look up the row to connect. */
export type AndroidAppAndroidAppAppIdKeyConnect = {
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to delete. */
export type AndroidAppAndroidAppAppIdKeyDelete = {
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to connect. */
export type AndroidAppAndroidAppPkeyConnect = {
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to delete. */
export type AndroidAppAndroidAppPkeyDelete = {
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The `androidApp` to be created by this mutation. */
export type AndroidAppAppIdFkeyAndroidAppCreateInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** The `app` to be created by this mutation. */
export type AndroidAppAppIdFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `app` in the `AndroidAppInput` mutation. */
export type AndroidAppAppIdFkeyInput = {
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<AppAppPkeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<AppAppSchoolIdKeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppNodeIdConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<AppAppPkeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<AppAppSchoolIdKeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppNodeIdDelete>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<AppOnAndroidAppForAndroidAppAppIdFkeyUsingAppPkeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<AppOnAndroidAppForAndroidAppAppIdFkeyUsingAppSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidAppOnAndroidAppForAndroidAppAppIdFkeyNodeIdUpdate>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidAppAppIdFkeyAppCreateInput>;
};

/** Input for the nested mutation of `androidApp` in the `AppInput` mutation. */
export type AndroidAppAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `androidApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectById?: Maybe<AndroidAppAndroidAppPkeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AndroidAppNodeIdConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteById?: Maybe<AndroidAppAndroidAppPkeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AndroidAppNodeIdDelete>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateById?: Maybe<AndroidAppOnAndroidAppForAndroidAppAppIdFkeyUsingAndroidAppPkeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByAppId?: Maybe<AndroidAppOnAndroidAppForAndroidAppAppIdFkeyUsingAndroidAppAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppOnAndroidAppForAndroidAppAppIdFkeyNodeIdUpdate>;
  /** A `AndroidAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidAppAppIdFkeyAndroidAppCreateInput>>;
};

/**
 * A condition to be used against `AndroidApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AndroidAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appId` field. */
  appId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `bundleId` field. */
  bundleId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `packageName` field. */
  packageName?: Maybe<Scalars['String']>;
};

/** The `androidApp` to be created by this mutation. */
export type AndroidAppCreatedByFkeyAndroidAppCreateInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `androidApp` in the `PersonInput` mutation. */
export type AndroidAppCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidAppAndroidAppPkeyConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByAppId?: Maybe<Array<AndroidAppAndroidAppAppIdKeyConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidAppNodeIdConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidAppAndroidAppPkeyDelete>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<Array<AndroidAppAndroidAppAppIdKeyDelete>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidAppOnAndroidAppForAndroidAppCreatedByFkeyUsingAndroidAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByAppId?: Maybe<Array<AndroidAppOnAndroidAppForAndroidAppCreatedByFkeyUsingAndroidAppAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidAppForAndroidAppCreatedByFkeyNodeIdUpdate>>;
  /** A `AndroidAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidAppCreatedByFkeyAndroidAppCreateInput>>;
};

/** A filter to be used against `AndroidApp` object types. All fields are combined with a logical ‘and.’ */
export type AndroidAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `appId` field. */
  appId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `bundleId` field. */
  bundleId?: Maybe<StringFilter>;
  /** Filter by the object’s `packageName` field. */
  packageName?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AndroidAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AndroidAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<AndroidAppFilter>;
};

/** An input for mutations affecting `AndroidApp` */
export type AndroidAppInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AndroidAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AndroidAppNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `androidApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppAppIdFkeyUsingAndroidAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppAppIdFkeyPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppAppIdFkeyUsingAndroidAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppAppIdFkeyPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppCreatedByFkeyUsingAndroidAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppCreatedByFkeyPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppCreatedByFkeyUsingAndroidAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppCreatedByFkeyPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyUsingAndroidAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyUsingAndroidAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidJavaKeyStore` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: AndroidJavaKeyStorePatch;
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingAndroidAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** The fields on `androidApp` to look up the row to update. */
export type AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingAndroidAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: UpdateAndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AndroidApp`. Fields that are set will be updated. */
export type AndroidAppPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyConnection = {
  __typename?: 'AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyEdge = {
  __typename?: 'AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByCreatedBy: AndroidJavaKeyStoresConnection;
};


/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndCreatedByManyToManyEdgeAndroidJavaKeyStoresByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyConnection = {
  __typename?: 'AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyEdge = {
  __typename?: 'AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByUpdatedBy: AndroidJavaKeyStoresConnection;
};


/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type AndroidAppPeopleByAndroidJavaKeyStoreAndroidAppIdAndUpdatedByManyToManyEdgeAndroidJavaKeyStoresByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** The `androidApp` to be created by this mutation. */
export type AndroidAppUpdatedByFkeyAndroidAppCreateInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `AndroidAppInput` mutation. */
export type AndroidAppUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAndroidAppForAndroidAppUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidAppUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `androidApp` in the `PersonInput` mutation. */
export type AndroidAppUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidAppAndroidAppPkeyConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByAppId?: Maybe<Array<AndroidAppAndroidAppAppIdKeyConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidAppNodeIdConnect>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidAppAndroidAppPkeyDelete>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<Array<AndroidAppAndroidAppAppIdKeyDelete>>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyUsingAndroidAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByAppId?: Maybe<Array<AndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyUsingAndroidAppAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidAppForAndroidAppUpdatedByFkeyNodeIdUpdate>>;
  /** A `AndroidAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidAppUpdatedByFkeyAndroidAppCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AndroidAppUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AndroidApp` values. */
export type AndroidAppsConnection = {
  __typename?: 'AndroidAppsConnection';
  /** A list of `AndroidApp` objects. */
  nodes: Array<AndroidApp>;
  /** A list of edges which contains the `AndroidApp` and cursor to aid in pagination. */
  edges: Array<AndroidAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AndroidApp` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AndroidApp` edge in the connection. */
export type AndroidAppsEdge = {
  __typename?: 'AndroidAppsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AndroidApp` at the end of the edge. */
  node: AndroidApp;
};

/** Methods to use when ordering `AndroidApp`. */
export enum AndroidAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AppIdAsc = 'APP_ID_ASC',
  AppIdDesc = 'APP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  BundleIdAsc = 'BUNDLE_ID_ASC',
  BundleIdDesc = 'BUNDLE_ID_DESC',
  PackageNameAsc = 'PACKAGE_NAME_ASC',
  PackageNameDesc = 'PACKAGE_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * Storing  the jks contents and information on a row of this table. \n\n
 *
 * One `android_app` has many `android_java_key_store`s. This is to archive old jks. \n\n
 *
 * NEVER delete a row in this database, but rather use the archiving method.
 */
export type AndroidJavaKeyStore = Node & {
  __typename?: 'AndroidJavaKeyStore';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias: Scalars['String'];
  /** The key password for the keystore. */
  keyPassword: Scalars['String'];
  /** The store password for the keystore. */
  storePassword: Scalars['String'];
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The `androidApp` to be created by this mutation. */
export type AndroidJavaKeyStoreAndroidAppIdFkeyAndroidAppCreateInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** The `androidJavaKeyStore` to be created by this mutation. */
export type AndroidJavaKeyStoreAndroidAppIdFkeyAndroidJavaKeyStoreCreateInput = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias: Scalars['String'];
  /** The key password for the keystore. */
  keyPassword: Scalars['String'];
  /** The store password for the keystore. */
  storePassword: Scalars['String'];
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `androidApp` in the `AndroidJavaKeyStoreInput` mutation. */
export type AndroidJavaKeyStoreAndroidAppIdFkeyInput = {
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectById?: Maybe<AndroidAppAndroidAppPkeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AndroidAppNodeIdConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteById?: Maybe<AndroidAppAndroidAppPkeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AndroidAppNodeIdDelete>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateById?: Maybe<AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidAppPkeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByAppId?: Maybe<AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidAppAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyNodeIdUpdate>;
  /** A `AndroidAppInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyAndroidAppCreateInput>;
};

/** Input for the nested mutation of `androidJavaKeyStore` in the `AndroidAppInput` mutation. */
export type AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `androidJavaKeyStore` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyDelete>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidJavaKeyStorePkeyUpdate>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyNodeIdUpdate>>;
  /** A `AndroidJavaKeyStoreInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidJavaKeyStoreAndroidAppIdFkeyAndroidJavaKeyStoreCreateInput>>;
};

/** The fields on `androidJavaKeyStore` to look up the row to connect. */
export type AndroidJavaKeyStoreAndroidJavaKeyStorePkeyConnect = {
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** The fields on `androidJavaKeyStore` to look up the row to delete. */
export type AndroidJavaKeyStoreAndroidJavaKeyStorePkeyDelete = {
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `AndroidJavaKeyStore` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AndroidJavaKeyStoreCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `androidAppId` field. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `jks64` field. */
  jks64?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `keyAlias` field. */
  keyAlias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `keyPassword` field. */
  keyPassword?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `storePassword` field. */
  storePassword?: Maybe<Scalars['String']>;
};

/** The `androidJavaKeyStore` to be created by this mutation. */
export type AndroidJavaKeyStoreCreatedByFkeyAndroidJavaKeyStoreCreateInput = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias: Scalars['String'];
  /** The key password for the keystore. */
  keyPassword: Scalars['String'];
  /** The store password for the keystore. */
  storePassword: Scalars['String'];
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `androidJavaKeyStore` in the `PersonInput` mutation. */
export type AndroidJavaKeyStoreCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidJavaKeyStore` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyDelete>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyUsingAndroidJavaKeyStorePkeyUpdate>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyNodeIdUpdate>>;
  /** A `AndroidJavaKeyStoreInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidJavaKeyStoreCreatedByFkeyAndroidJavaKeyStoreCreateInput>>;
};

/** A filter to be used against `AndroidJavaKeyStore` object types. All fields are combined with a logical ‘and.’ */
export type AndroidJavaKeyStoreFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `androidAppId` field. */
  androidAppId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: Maybe<StringFilter>;
  /** Filter by the object’s `jks64` field. */
  jks64?: Maybe<StringFilter>;
  /** Filter by the object’s `keyAlias` field. */
  keyAlias?: Maybe<StringFilter>;
  /** Filter by the object’s `keyPassword` field. */
  keyPassword?: Maybe<StringFilter>;
  /** Filter by the object’s `storePassword` field. */
  storePassword?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AndroidJavaKeyStoreFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AndroidJavaKeyStoreFilter>>;
  /** Negates the expression. */
  not?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** An input for mutations affecting `AndroidJavaKeyStore` */
export type AndroidJavaKeyStoreInput = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias: Scalars['String'];
  /** The key password for the keystore. */
  keyPassword: Scalars['String'];
  /** The store password for the keystore. */
  storePassword: Scalars['String'];
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AndroidJavaKeyStoreNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `androidJavaKeyStore` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AndroidJavaKeyStoreNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `androidJavaKeyStore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: AndroidAppPatch;
};

/** The fields on `androidJavaKeyStore` to look up the row to update. */
export type AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyUsingAndroidJavaKeyStorePkeyUpdate = {
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyPatch;
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** The fields on `androidJavaKeyStore` to look up the row to update. */
export type AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyUsingAndroidJavaKeyStorePkeyUpdate = {
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyPatch;
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `androidJavaKeyStore` to look up the row to update. */
export type AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyUsingAndroidJavaKeyStorePkeyUpdate = {
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyPatch;
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AndroidJavaKeyStore`. Fields that are set will be updated. */
export type AndroidJavaKeyStorePatch = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias?: Maybe<Scalars['String']>;
  /** The key password for the keystore. */
  keyPassword?: Maybe<Scalars['String']>;
  /** The store password for the keystore. */
  storePassword?: Maybe<Scalars['String']>;
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** The `androidJavaKeyStore` to be created by this mutation. */
export type AndroidJavaKeyStoreUpdatedByFkeyAndroidJavaKeyStoreCreateInput = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias: Scalars['String'];
  /** The key password for the keystore. */
  keyPassword: Scalars['String'];
  /** The store password for the keystore. */
  storePassword: Scalars['String'];
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `AndroidJavaKeyStoreInput` mutation. */
export type AndroidJavaKeyStoreUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `androidJavaKeyStore` in the `PersonInput` mutation. */
export type AndroidJavaKeyStoreUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidJavaKeyStore` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdConnect>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidJavaKeyStoreAndroidJavaKeyStorePkeyDelete>>;
  /** The primary key(s) for `androidJavaKeyStore` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidJavaKeyStoreNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyUsingAndroidJavaKeyStorePkeyUpdate>>;
  /** The primary key(s) and patch data for `androidJavaKeyStore` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyNodeIdUpdate>>;
  /** A `AndroidJavaKeyStoreInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidJavaKeyStoreUpdatedByFkeyAndroidJavaKeyStoreCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AndroidJavaKeyStoreUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AndroidJavaKeyStore` values. */
export type AndroidJavaKeyStoresConnection = {
  __typename?: 'AndroidJavaKeyStoresConnection';
  /** A list of `AndroidJavaKeyStore` objects. */
  nodes: Array<AndroidJavaKeyStore>;
  /** A list of edges which contains the `AndroidJavaKeyStore` and cursor to aid in pagination. */
  edges: Array<AndroidJavaKeyStoresEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AndroidJavaKeyStore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AndroidJavaKeyStore` edge in the connection. */
export type AndroidJavaKeyStoresEdge = {
  __typename?: 'AndroidJavaKeyStoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AndroidJavaKeyStore` at the end of the edge. */
  node: AndroidJavaKeyStore;
};

/** Methods to use when ordering `AndroidJavaKeyStore`. */
export enum AndroidJavaKeyStoresOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AndroidAppIdAsc = 'ANDROID_APP_ID_ASC',
  AndroidAppIdDesc = 'ANDROID_APP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  Jks_64Asc = 'JKS_64_ASC',
  Jks_64Desc = 'JKS_64_DESC',
  KeyAliasAsc = 'KEY_ALIAS_ASC',
  KeyAliasDesc = 'KEY_ALIAS_DESC',
  KeyPasswordAsc = 'KEY_PASSWORD_ASC',
  KeyPasswordDesc = 'KEY_PASSWORD_DESC',
  StorePasswordAsc = 'STORE_PASSWORD_ASC',
  StorePasswordDesc = 'STORE_PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * Storing android screenshots on individual rows of this table. \n\n
 *
 * With this table, I'm setting the precedent for putting uncommon cases in their own table (one to none, sometimes one to many)\n\n
 *
 * One `android_store_listing` can have many `android_screenshots` (but most likely we'll opt have 0 and just generate screenshots with each shipment). \n\n
 */
export type AndroidScreenshot = Node & {
  __typename?: 'AndroidScreenshot';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size: AndroidScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder: Scalars['Int'];
  /** Reads a single `GooglePlayListing` that is related to this `AndroidScreenshot`. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The fields on `androidScreenshot` to look up the row to connect. */
export type AndroidScreenshotAndroidScreenshotPkeyConnect = {
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** The fields on `androidScreenshot` to look up the row to delete. */
export type AndroidScreenshotAndroidScreenshotPkeyDelete = {
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `AndroidScreenshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AndroidScreenshotCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googlePlayListingId` field. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `size` field. */
  size?: Maybe<AndroidScreenshotSize>;
  /** Checks for equality with the object’s `screenshotUrl` field. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `screenshotOrder` field. */
  screenshotOrder?: Maybe<Scalars['Int']>;
};

/** The `androidScreenshot` to be created by this mutation. */
export type AndroidScreenshotCreatedByFkeyAndroidScreenshotCreateInput = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size: AndroidScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `androidScreenshot` in the `PersonInput` mutation. */
export type AndroidScreenshotCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidScreenshotNodeIdConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyDelete>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyUsingAndroidScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyNodeIdUpdate>>;
  /** A `AndroidScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidScreenshotCreatedByFkeyAndroidScreenshotCreateInput>>;
};

/** A filter to be used against `AndroidScreenshot` object types. All fields are combined with a logical ‘and.’ */
export type AndroidScreenshotFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `googlePlayListingId` field. */
  googlePlayListingId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `size` field. */
  size?: Maybe<AndroidScreenshotSizeFilter>;
  /** Filter by the object’s `screenshotUrl` field. */
  screenshotUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `screenshotOrder` field. */
  screenshotOrder?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AndroidScreenshotFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AndroidScreenshotFilter>>;
  /** Negates the expression. */
  not?: Maybe<AndroidScreenshotFilter>;
};

/** The `androidScreenshot` to be created by this mutation. */
export type AndroidScreenshotGooglePlayListingIdFkeyAndroidScreenshotCreateInput = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size: AndroidScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** The `googlePlayListing` to be created by this mutation. */
export type AndroidScreenshotGooglePlayListingIdFkeyGooglePlayListingCreateInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayListing` in the `AndroidScreenshotInput` mutation. */
export type AndroidScreenshotGooglePlayListingIdFkeyInput = {
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectById?: Maybe<GooglePlayListingGooglePlayListingPkeyConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByAndroidAppId?: Maybe<GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<GooglePlayListingNodeIdConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteById?: Maybe<GooglePlayListingGooglePlayListingPkeyDelete>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByAndroidAppId?: Maybe<GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GooglePlayListingNodeIdDelete>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateById?: Maybe<GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingGooglePlayListingPkeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByAndroidAppId?: Maybe<GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyNodeIdUpdate>;
  /** A `GooglePlayListingInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyGooglePlayListingCreateInput>;
};

/** Input for the nested mutation of `androidScreenshot` in the `GooglePlayListingInput` mutation. */
export type AndroidScreenshotGooglePlayListingIdFkeyInverseInput = {
  /** Flag indicating whether all other `androidScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidScreenshotNodeIdConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyDelete>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingAndroidScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyNodeIdUpdate>>;
  /** A `AndroidScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidScreenshotGooglePlayListingIdFkeyAndroidScreenshotCreateInput>>;
};

/** An input for mutations affecting `AndroidScreenshot` */
export type AndroidScreenshotInput = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size: AndroidScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AndroidScreenshotNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `androidScreenshot` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AndroidScreenshotNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `androidScreenshot` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `androidScreenshot` to look up the row to update. */
export type AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyUsingAndroidScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyPatch;
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** The fields on `androidScreenshot` to look up the row to update. */
export type AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingAndroidScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyPatch;
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `androidScreenshot` to look up the row to update. */
export type AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyUsingAndroidScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyPatch;
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AndroidScreenshot`. Fields that are set will be updated. */
export type AndroidScreenshotPatch = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size?: Maybe<AndroidScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

export enum AndroidScreenshotSize {
  PhoneScreenshot = 'PHONE_SCREENSHOT'
}

/** A filter to be used against AndroidScreenshotSize fields. All fields are combined with a logical ‘and.’ */
export type AndroidScreenshotSizeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<AndroidScreenshotSize>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<AndroidScreenshotSize>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<AndroidScreenshotSize>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<AndroidScreenshotSize>;
  /** Included in the specified list. */
  in?: Maybe<Array<AndroidScreenshotSize>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<AndroidScreenshotSize>>;
  /** Less than the specified value. */
  lessThan?: Maybe<AndroidScreenshotSize>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<AndroidScreenshotSize>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<AndroidScreenshotSize>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<AndroidScreenshotSize>;
};

/** The `androidScreenshot` to be created by this mutation. */
export type AndroidScreenshotUpdatedByFkeyAndroidScreenshotCreateInput = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size: AndroidScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `AndroidScreenshotInput` mutation. */
export type AndroidScreenshotUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AndroidScreenshotUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `androidScreenshot` in the `PersonInput` mutation. */
export type AndroidScreenshotUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `androidScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AndroidScreenshotNodeIdConnect>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<AndroidScreenshotAndroidScreenshotPkeyDelete>>;
  /** The primary key(s) for `androidScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AndroidScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<AndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyUsingAndroidScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `androidScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyNodeIdUpdate>>;
  /** A `AndroidScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AndroidScreenshotUpdatedByFkeyAndroidScreenshotCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AndroidScreenshotUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AndroidScreenshot` values. */
export type AndroidScreenshotsConnection = {
  __typename?: 'AndroidScreenshotsConnection';
  /** A list of `AndroidScreenshot` objects. */
  nodes: Array<AndroidScreenshot>;
  /** A list of edges which contains the `AndroidScreenshot` and cursor to aid in pagination. */
  edges: Array<AndroidScreenshotsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AndroidScreenshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AndroidScreenshot` edge in the connection. */
export type AndroidScreenshotsEdge = {
  __typename?: 'AndroidScreenshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AndroidScreenshot` at the end of the edge. */
  node: AndroidScreenshot;
};

/** Methods to use when ordering `AndroidScreenshot`. */
export enum AndroidScreenshotsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GooglePlayListingIdAsc = 'GOOGLE_PLAY_LISTING_ID_ASC',
  GooglePlayListingIdDesc = 'GOOGLE_PLAY_LISTING_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  ScreenshotUrlAsc = 'SCREENSHOT_URL_ASC',
  ScreenshotUrlDesc = 'SCREENSHOT_URL_DESC',
  ScreenshotOrderAsc = 'SCREENSHOT_ORDER_ASC',
  ScreenshotOrderDesc = 'SCREENSHOT_ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * App is the entry point to connect a school to it's metadata/data for mobile app compilation and deployment. \n\n
 *
 * android_app and ios_app both have foreign keys referencing app_id for their 1-1 relationship. \n\n
 *
 * All logs reference this id to collect all relevant logs. \n\n
 */
export type App = Node & {
  __typename?: 'App';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A primary ID for the app. */
  id: Scalars['UUID'];
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived: Scalars['Boolean'];
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  /** Reads a single `School` that is related to this `App`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `App`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `App`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
  /** Reads a single `AndroidApp` that is related to this `App`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `IosApp` that is related to this `App`. */
  iosApp?: Maybe<IosApp>;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByBuildAppIdAndReleaseId: AppReleasesByBuildAppIdAndReleaseIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildAppIdAndCreatedBy: AppPeopleByBuildAppIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildAppIdAndUpdatedBy: AppPeopleByBuildAppIdAndUpdatedByManyToManyConnection;
};


/**
 * App is the entry point to connect a school to it's metadata/data for mobile app compilation and deployment. \n\n
 *
 * android_app and ios_app both have foreign keys referencing app_id for their 1-1 relationship. \n\n
 *
 * All logs reference this id to collect all relevant logs. \n\n
 */
export type AppBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * App is the entry point to connect a school to it's metadata/data for mobile app compilation and deployment. \n\n
 *
 * android_app and ios_app both have foreign keys referencing app_id for their 1-1 relationship. \n\n
 *
 * All logs reference this id to collect all relevant logs. \n\n
 */
export type AppReleasesByBuildAppIdAndReleaseIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/**
 * App is the entry point to connect a school to it's metadata/data for mobile app compilation and deployment. \n\n
 *
 * android_app and ios_app both have foreign keys referencing app_id for their 1-1 relationship. \n\n
 *
 * All logs reference this id to collect all relevant logs. \n\n
 */
export type AppPeopleByBuildAppIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * App is the entry point to connect a school to it's metadata/data for mobile app compilation and deployment. \n\n
 *
 * android_app and ios_app both have foreign keys referencing app_id for their 1-1 relationship. \n\n
 *
 * All logs reference this id to collect all relevant logs. \n\n
 */
export type AppPeopleByBuildAppIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The fields on `app` to look up the row to connect. */
export type AppAppPkeyConnect = {
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to delete. */
export type AppAppPkeyDelete = {
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to connect. */
export type AppAppSchoolIdKeyConnect = {
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The fields on `app` to look up the row to delete. */
export type AppAppSchoolIdKeyDelete = {
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** A condition to be used against `App` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `appTitleUnderIcon` field. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `onesignalAppId` field. */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `onesignalAppAuthToken` field. */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `iconUrl` field. */
  iconUrl?: Maybe<Scalars['String']>;
};

/** The `app` to be created by this mutation. */
export type AppCreatedByFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `app` in the `PersonInput` mutation. */
export type AppCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `app` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<Array<AppAppPkeyConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<AppAppSchoolIdKeyConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppNodeIdConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppAppPkeyDelete>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<AppAppSchoolIdKeyDelete>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppNodeIdDelete>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<Array<AppOnAppForAppCreatedByFkeyUsingAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<AppOnAppForAppCreatedByFkeyUsingAppSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppForAppCreatedByFkeyNodeIdUpdate>>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppCreatedByFkeyAppCreateInput>>;
};

/** A filter to be used against `App` object types. All fields are combined with a logical ‘and.’ */
export type AppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `appTitleUnderIcon` field. */
  appTitleUnderIcon?: Maybe<StringFilter>;
  /** Filter by the object’s `onesignalAppId` field. */
  onesignalAppId?: Maybe<StringFilter>;
  /** Filter by the object’s `onesignalAppAuthToken` field. */
  onesignalAppAuthToken?: Maybe<StringFilter>;
  /** Filter by the object’s `iconUrl` field. */
  iconUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppFilter>;
};

/** An input for mutations affecting `App` */
export type AppInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `app` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppOnAndroidAppForAndroidAppAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: AndroidAppPatch;
};

/** The fields on `app` to look up the row to update. */
export type AppOnAndroidAppForAndroidAppAppIdFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAndroidAppForAndroidAppAppIdFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnAndroidAppForAndroidAppAppIdFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAndroidAppForAndroidAppAppIdFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppCreatedByFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppCreatedByFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppCreatedByFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppCreatedByFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppOnAppForAppSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppSchoolIdFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppSchoolIdFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppSchoolIdFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppSchoolIdFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppOnAppForAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppUpdatedByFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppUpdatedByFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnAppForAppUpdatedByFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnAppForAppUpdatedByFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppOnBuildForBuildAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: BuildPatch;
};

/** The fields on `app` to look up the row to update. */
export type AppOnBuildForBuildAppIdFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnBuildForBuildAppIdFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnBuildForBuildAppIdFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnBuildForBuildAppIdFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppOnIosAppForIosAppAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: IosAppPatch;
};

/** The fields on `app` to look up the row to update. */
export type AppOnIosAppForIosAppAppIdFkeyUsingAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnIosAppForIosAppAppIdFkeyPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The fields on `app` to look up the row to update. */
export type AppOnIosAppForIosAppAppIdFkeyUsingAppSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: UpdateAppOnIosAppForIosAppAppIdFkeyPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** Represents an update to a `App`. Fields that are set will be updated. */
export type AppPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type AppPeopleByBuildAppIdAndCreatedByManyToManyConnection = {
  __typename?: 'AppPeopleByBuildAppIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<AppPeopleByBuildAppIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type AppPeopleByBuildAppIdAndCreatedByManyToManyEdge = {
  __typename?: 'AppPeopleByBuildAppIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByCreatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type AppPeopleByBuildAppIdAndCreatedByManyToManyEdgeBuildsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type AppPeopleByBuildAppIdAndUpdatedByManyToManyConnection = {
  __typename?: 'AppPeopleByBuildAppIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<AppPeopleByBuildAppIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type AppPeopleByBuildAppIdAndUpdatedByManyToManyEdge = {
  __typename?: 'AppPeopleByBuildAppIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByUpdatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type AppPeopleByBuildAppIdAndUpdatedByManyToManyEdgeBuildsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Release` values, with data from `Build`. */
export type AppReleasesByBuildAppIdAndReleaseIdManyToManyConnection = {
  __typename?: 'AppReleasesByBuildAppIdAndReleaseIdManyToManyConnection';
  /** A list of `Release` objects. */
  nodes: Array<Release>;
  /** A list of edges which contains the `Release`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<AppReleasesByBuildAppIdAndReleaseIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Release` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Release` edge in the connection, with data from `Build`. */
export type AppReleasesByBuildAppIdAndReleaseIdManyToManyEdge = {
  __typename?: 'AppReleasesByBuildAppIdAndReleaseIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Release` at the end of the edge. */
  node: Release;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `Release` edge in the connection, with data from `Build`. */
export type AppReleasesByBuildAppIdAndReleaseIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** The `app` to be created by this mutation. */
export type AppSchoolIdFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `school` in the `AppInput` mutation. */
export type AppSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnAppForAppSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnAppForAppSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppOnAppForAppSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<AppSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `app` in the `SchoolInput` mutation. */
export type AppSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `app` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<AppAppPkeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<AppAppSchoolIdKeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppNodeIdConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<AppAppPkeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<AppAppSchoolIdKeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppNodeIdDelete>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<AppOnAppForAppSchoolIdFkeyUsingAppPkeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<AppOnAppForAppSchoolIdFkeyUsingAppSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolOnAppForAppSchoolIdFkeyNodeIdUpdate>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppSchoolIdFkeyAppCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type AppSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/**
 * `app_store_account`.\n\n
 *
 * This table contains the info for the Apple Developer Account and App Store Connect Account.\n\n
 *
 * 1 `app_store_account` has many `ios_listing`.\n\n
 */
export type AppStoreAccount = Node & {
  __typename?: 'AppStoreAccount';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin: Scalars['Boolean'];
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf: Scalars['Boolean'];
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles: Scalars['Boolean'];
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements: Scalars['Boolean'];
  /** Reads a single `AppStoreUser` that is related to this `AppStoreAccount`. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListings: AppStoreListingsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreListingAppStoreAccountIdAndCreatedBy: AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreListingAppStoreAccountIdAndUpdatedBy: AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyConnection;
};


/**
 * `app_store_account`.\n\n
 *
 * This table contains the info for the Apple Developer Account and App Store Connect Account.\n\n
 *
 * 1 `app_store_account` has many `ios_listing`.\n\n
 */
export type AppStoreAccountAppStoreListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/**
 * `app_store_account`.\n\n
 *
 * This table contains the info for the Apple Developer Account and App Store Connect Account.\n\n
 *
 * 1 `app_store_account` has many `ios_listing`.\n\n
 */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `app_store_account`.\n\n
 *
 * This table contains the info for the Apple Developer Account and App Store Connect Account.\n\n
 *
 * 1 `app_store_account` has many `ios_listing`.\n\n
 */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The fields on `appStoreAccount` to look up the row to connect. */
export type AppStoreAccountAppStoreAccountAppStoreConnectIdKeyConnect = {
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to delete. */
export type AppStoreAccountAppStoreAccountAppStoreConnectIdKeyDelete = {
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to connect. */
export type AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyConnect = {
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to delete. */
export type AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyDelete = {
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to connect. */
export type AppStoreAccountAppStoreAccountOrganizationNameKeyConnect = {
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to delete. */
export type AppStoreAccountAppStoreAccountOrganizationNameKeyDelete = {
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to connect. */
export type AppStoreAccountAppStoreAccountPkeyConnect = {
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreAccount` to look up the row to delete. */
export type AppStoreAccountAppStoreAccountPkeyDelete = {
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The `appStoreAccount` to be created by this mutation. */
export type AppStoreAccountAppStoreUserIdFkeyAppStoreAccountCreateInput = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** The `appStoreUser` to be created by this mutation. */
export type AppStoreAccountAppStoreUserIdFkeyAppStoreUserCreateInput = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreUser` in the `AppStoreAccountInput` mutation. */
export type AppStoreAccountAppStoreUserIdFkeyInput = {
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectById?: Maybe<AppStoreUserAppStoreUserPkeyConnect>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByEmail?: Maybe<AppStoreUserAppStoreUserEmailKeyConnect>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppStoreUserNodeIdConnect>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteById?: Maybe<AppStoreUserAppStoreUserPkeyDelete>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<AppStoreUserAppStoreUserEmailKeyDelete>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppStoreUserNodeIdDelete>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateById?: Maybe<AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreUserPkeyUpdate>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByEmail?: Maybe<AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyNodeIdUpdate>;
  /** A `AppStoreUserInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreAccountAppStoreUserIdFkeyAppStoreUserCreateInput>;
};

/** Input for the nested mutation of `appStoreAccount` in the `AppStoreUserInput` mutation. */
export type AppStoreAccountAppStoreUserIdFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreAccountNodeIdConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppStoreConnectId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByOrganizationName?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountOrganizationNameKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyNodeIdUpdate>>;
  /** A `AppStoreAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreAccountAppStoreUserIdFkeyAppStoreAccountCreateInput>>;
};

/**
 * A condition to be used against `AppStoreAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AppStoreAccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appStoreUserId` field. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `appStoreConnectId` field. */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appleDeveloperAccountId` field. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationName` field. */
  organizationName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `expirationDate` field. */
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `appStoreUserIsAdmin` field. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `clientUploadedPrivacyDetailsOnOurBehalf` field. */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `neverNukeCertsAndProfiles` field. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `distributionCertExpirationDate` field. */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `needsToAcceptAgreements` field. */
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
};

/** The `appStoreAccount` to be created by this mutation. */
export type AppStoreAccountCreatedByFkeyAppStoreAccountCreateInput = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreAccount` in the `PersonInput` mutation. */
export type AppStoreAccountCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreAccountNodeIdConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppStoreConnectId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByOrganizationName?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountOrganizationNameKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreAccountForAppStoreAccountCreatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreAccountCreatedByFkeyAppStoreAccountCreateInput>>;
};

/** A filter to be used against `AppStoreAccount` object types. All fields are combined with a logical ‘and.’ */
export type AppStoreAccountFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `appStoreUserId` field. */
  appStoreUserId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `appStoreConnectId` field. */
  appStoreConnectId?: Maybe<StringFilter>;
  /** Filter by the object’s `appleDeveloperAccountId` field. */
  appleDeveloperAccountId?: Maybe<StringFilter>;
  /** Filter by the object’s `organizationName` field. */
  organizationName?: Maybe<StringFilter>;
  /** Filter by the object’s `expirationDate` field. */
  expirationDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `appStoreUserIsAdmin` field. */
  appStoreUserIsAdmin?: Maybe<BooleanFilter>;
  /** Filter by the object’s `clientUploadedPrivacyDetailsOnOurBehalf` field. */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<BooleanFilter>;
  /** Filter by the object’s `neverNukeCertsAndProfiles` field. */
  neverNukeCertsAndProfiles?: Maybe<BooleanFilter>;
  /** Filter by the object’s `distributionCertExpirationDate` field. */
  distributionCertExpirationDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `needsToAcceptAgreements` field. */
  needsToAcceptAgreements?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppStoreAccountFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppStoreAccountFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppStoreAccountFilter>;
};

/** An input for mutations affecting `AppStoreAccount` */
export type AppStoreAccountInput = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppStoreAccountNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppStoreAccountNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: AppStoreUserPatch;
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountOrganizationNameKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyPatch;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyPatch;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountOrganizationNameKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyPatch;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyUsingAppStoreAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyPatch;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountOrganizationNameKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountOrganizationNameKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** The fields on `appStoreAccount` to look up the row to update. */
export type AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: UpdateAppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AppStoreAccount`. Fields that are set will be updated. */
export type AppStoreAccountPatch = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyConnection = {
  __typename?: 'AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyEdge = {
  __typename?: 'AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByCreatedBy: AppStoreListingsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndCreatedByManyToManyEdgeAppStoreListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyConnection = {
  __typename?: 'AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyEdge = {
  __typename?: 'AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByUpdatedBy: AppStoreListingsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type AppStoreAccountPeopleByAppStoreListingAppStoreAccountIdAndUpdatedByManyToManyEdgeAppStoreListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** The `appStoreAccount` to be created by this mutation. */
export type AppStoreAccountUpdatedByFkeyAppStoreAccountCreateInput = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `AppStoreAccountInput` mutation. */
export type AppStoreAccountUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreAccountUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `appStoreAccount` in the `PersonInput` mutation. */
export type AppStoreAccountUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreAccountNodeIdConnect>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreAccountAppStoreAccountPkeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppStoreConnectId?: Maybe<Array<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByOrganizationName?: Maybe<Array<AppStoreAccountAppStoreAccountOrganizationNameKeyDelete>>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppStoreConnectId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppleDeveloperAccountId?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByOrganizationName?: Maybe<Array<AppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingAppStoreAccountOrganizationNameKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreAccountUpdatedByFkeyAppStoreAccountCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AppStoreAccountUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AppStoreAccount` values. */
export type AppStoreAccountsConnection = {
  __typename?: 'AppStoreAccountsConnection';
  /** A list of `AppStoreAccount` objects. */
  nodes: Array<AppStoreAccount>;
  /** A list of edges which contains the `AppStoreAccount` and cursor to aid in pagination. */
  edges: Array<AppStoreAccountsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreAccount` edge in the connection. */
export type AppStoreAccountsEdge = {
  __typename?: 'AppStoreAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreAccount` at the end of the edge. */
  node: AppStoreAccount;
};

/** Methods to use when ordering `AppStoreAccount`. */
export enum AppStoreAccountsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AppStoreUserIdAsc = 'APP_STORE_USER_ID_ASC',
  AppStoreUserIdDesc = 'APP_STORE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  AppStoreConnectIdAsc = 'APP_STORE_CONNECT_ID_ASC',
  AppStoreConnectIdDesc = 'APP_STORE_CONNECT_ID_DESC',
  AppleDeveloperAccountIdAsc = 'APPLE_DEVELOPER_ACCOUNT_ID_ASC',
  AppleDeveloperAccountIdDesc = 'APPLE_DEVELOPER_ACCOUNT_ID_DESC',
  OrganizationNameAsc = 'ORGANIZATION_NAME_ASC',
  OrganizationNameDesc = 'ORGANIZATION_NAME_DESC',
  ExpirationDateAsc = 'EXPIRATION_DATE_ASC',
  ExpirationDateDesc = 'EXPIRATION_DATE_DESC',
  AppStoreUserIsAdminAsc = 'APP_STORE_USER_IS_ADMIN_ASC',
  AppStoreUserIsAdminDesc = 'APP_STORE_USER_IS_ADMIN_DESC',
  ClientUploadedPrivacyDetailsOnOurBehalfAsc = 'CLIENT_UPLOADED_PRIVACY_DETAILS_ON_OUR_BEHALF_ASC',
  ClientUploadedPrivacyDetailsOnOurBehalfDesc = 'CLIENT_UPLOADED_PRIVACY_DETAILS_ON_OUR_BEHALF_DESC',
  NeverNukeCertsAndProfilesAsc = 'NEVER_NUKE_CERTS_AND_PROFILES_ASC',
  NeverNukeCertsAndProfilesDesc = 'NEVER_NUKE_CERTS_AND_PROFILES_DESC',
  DistributionCertExpirationDateAsc = 'DISTRIBUTION_CERT_EXPIRATION_DATE_ASC',
  DistributionCertExpirationDateDesc = 'DISTRIBUTION_CERT_EXPIRATION_DATE_DESC',
  NeedsToAcceptAgreementsAsc = 'NEEDS_TO_ACCEPT_AGREEMENTS_ASC',
  NeedsToAcceptAgreementsDesc = 'NEEDS_TO_ACCEPT_AGREEMENTS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * `app_store_listing`.\n\n
 *
 * This is a table that contains the metadata for the app for it's listing on the App Store.\n\n
 *
 * Currently, info about state is not implemented, but I think that'll live on an `ios_build` and not here on the listing.\n\n
 *
 * 1 `app_store_listing` has many `ios_screenshot`\n\n
 */
export type AppStoreListing = Node & {
  __typename?: 'AppStoreListing';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked: Scalars['Boolean'];
  /** Reads a single `IosApp` that is related to this `AppStoreListing`. */
  iosApp?: Maybe<IosApp>;
  /** Reads a single `AppStoreAccount` that is related to this `AppStoreListing`. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshots: IosScreenshotsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosScreenshotAppStoreListingIdAndCreatedBy: AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosScreenshotAppStoreListingIdAndUpdatedBy: AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyConnection;
};


/**
 * `app_store_listing`.\n\n
 *
 * This is a table that contains the metadata for the app for it's listing on the App Store.\n\n
 *
 * Currently, info about state is not implemented, but I think that'll live on an `ios_build` and not here on the listing.\n\n
 *
 * 1 `app_store_listing` has many `ios_screenshot`\n\n
 */
export type AppStoreListingIosScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};


/**
 * `app_store_listing`.\n\n
 *
 * This is a table that contains the metadata for the app for it's listing on the App Store.\n\n
 *
 * Currently, info about state is not implemented, but I think that'll live on an `ios_build` and not here on the listing.\n\n
 *
 * 1 `app_store_listing` has many `ios_screenshot`\n\n
 */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `app_store_listing`.\n\n
 *
 * This is a table that contains the metadata for the app for it's listing on the App Store.\n\n
 *
 * Currently, info about state is not implemented, but I think that'll live on an `ios_build` and not here on the listing.\n\n
 *
 * 1 `app_store_listing` has many `ios_screenshot`\n\n
 */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The `appStoreAccount` to be created by this mutation. */
export type AppStoreListingAppStoreAccountIdFkeyAppStoreAccountCreateInput = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** The `appStoreListing` to be created by this mutation. */
export type AppStoreListingAppStoreAccountIdFkeyAppStoreListingCreateInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreAccount` in the `AppStoreListingInput` mutation. */
export type AppStoreListingAppStoreAccountIdFkeyInput = {
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectById?: Maybe<AppStoreAccountAppStoreAccountPkeyConnect>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppStoreConnectId?: Maybe<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyConnect>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByAppleDeveloperAccountId?: Maybe<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyConnect>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByOrganizationName?: Maybe<AppStoreAccountAppStoreAccountOrganizationNameKeyConnect>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppStoreAccountNodeIdConnect>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteById?: Maybe<AppStoreAccountAppStoreAccountPkeyDelete>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppStoreConnectId?: Maybe<AppStoreAccountAppStoreAccountAppStoreConnectIdKeyDelete>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByAppleDeveloperAccountId?: Maybe<AppStoreAccountAppStoreAccountAppleDeveloperAccountIdKeyDelete>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByOrganizationName?: Maybe<AppStoreAccountAppStoreAccountOrganizationNameKeyDelete>;
  /** The primary key(s) for `appStoreAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppStoreAccountNodeIdDelete>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateById?: Maybe<AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppStoreConnectId?: Maybe<AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountAppStoreConnectIdKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByAppleDeveloperAccountId?: Maybe<AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountAppleDeveloperAccountIdKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByOrganizationName?: Maybe<AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreAccountOrganizationNameKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyNodeIdUpdate>;
  /** A `AppStoreAccountInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreListingAppStoreAccountIdFkeyAppStoreAccountCreateInput>;
};

/** Input for the nested mutation of `appStoreListing` in the `AppStoreAccountInput` mutation. */
export type AppStoreListingAppStoreAccountIdFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreListingAppStoreListingPkeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreListingNodeIdConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreListingAppStoreListingPkeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByIosAppId?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreListingIosAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyNodeIdUpdate>>;
  /** A `AppStoreListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreListingAppStoreAccountIdFkeyAppStoreListingCreateInput>>;
};

/** The fields on `appStoreListing` to look up the row to connect. */
export type AppStoreListingAppStoreListingIosAppIdKeyConnect = {
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to delete. */
export type AppStoreListingAppStoreListingIosAppIdKeyDelete = {
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to connect. */
export type AppStoreListingAppStoreListingPkeyConnect = {
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to delete. */
export type AppStoreListingAppStoreListingPkeyDelete = {
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `AppStoreListing` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AppStoreListingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `iosAppId` field. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appStoreAccountId` field. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `appName` field. */
  appName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `keywords` field. */
  keywords?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `reviewerNotes` field. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `marketingUrl` field. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `supportUrl` field. */
  supportUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appStoreProprietaryAppId` field. */
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `pushCertExpirationDate` field. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `appGroupLinked` field. */
  appGroupLinked?: Maybe<Scalars['Boolean']>;
};

/** The `appStoreListing` to be created by this mutation. */
export type AppStoreListingCreatedByFkeyAppStoreListingCreateInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreListing` in the `PersonInput` mutation. */
export type AppStoreListingCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreListingAppStoreListingPkeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreListingNodeIdConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreListingAppStoreListingPkeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyUsingAppStoreListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByIosAppId?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyUsingAppStoreListingIosAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreListingForAppStoreListingCreatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreListingCreatedByFkeyAppStoreListingCreateInput>>;
};

/** A filter to be used against `AppStoreListing` object types. All fields are combined with a logical ‘and.’ */
export type AppStoreListingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `iosAppId` field. */
  iosAppId?: Maybe<UuidFilter>;
  /** Filter by the object’s `appStoreAccountId` field. */
  appStoreAccountId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `appName` field. */
  appName?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `keywords` field. */
  keywords?: Maybe<StringFilter>;
  /** Filter by the object’s `reviewerNotes` field. */
  reviewerNotes?: Maybe<StringFilter>;
  /** Filter by the object’s `marketingUrl` field. */
  marketingUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `supportUrl` field. */
  supportUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `appStoreProprietaryAppId` field. */
  appStoreProprietaryAppId?: Maybe<StringFilter>;
  /** Filter by the object’s `pushCertExpirationDate` field. */
  pushCertExpirationDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `appGroupLinked` field. */
  appGroupLinked?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppStoreListingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppStoreListingFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppStoreListingFilter>;
};

/** An input for mutations affecting `AppStoreListing` */
export type AppStoreListingInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** The `appStoreListing` to be created by this mutation. */
export type AppStoreListingIosAppIdFkeyAppStoreListingCreateInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `iosApp` in the `AppStoreListingInput` mutation. */
export type AppStoreListingIosAppIdFkeyInput = {
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectById?: Maybe<IosAppIosAppPkeyConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByAppId?: Maybe<IosAppIosAppAppIdKeyConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<IosAppNodeIdConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteById?: Maybe<IosAppIosAppPkeyDelete>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<IosAppIosAppAppIdKeyDelete>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IosAppNodeIdDelete>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateById?: Maybe<IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingIosAppPkeyUpdate>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByAppId?: Maybe<IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingIosAppAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyNodeIdUpdate>;
  /** A `IosAppInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreListingIosAppIdFkeyIosAppCreateInput>;
};

/** Input for the nested mutation of `appStoreListing` in the `IosAppInput` mutation. */
export type AppStoreListingIosAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectById?: Maybe<AppStoreListingAppStoreListingPkeyConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByIosAppId?: Maybe<AppStoreListingAppStoreListingIosAppIdKeyConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppStoreListingNodeIdConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteById?: Maybe<AppStoreListingAppStoreListingPkeyDelete>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByIosAppId?: Maybe<AppStoreListingAppStoreListingIosAppIdKeyDelete>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppStoreListingNodeIdDelete>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateById?: Maybe<AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingAppStoreListingPkeyUpdate>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByIosAppId?: Maybe<AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingAppStoreListingIosAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyNodeIdUpdate>;
  /** A `AppStoreListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreListingIosAppIdFkeyAppStoreListingCreateInput>>;
};

/** The `iosApp` to be created by this mutation. */
export type AppStoreListingIosAppIdFkeyIosAppCreateInput = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppStoreListingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppStoreListingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreListingIosAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyUsingAppStoreListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyUsingAppStoreListingIosAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyUsingAppStoreListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: IosAppPatch;
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingAppStoreListingIosAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingAppStoreListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingAppStoreListingIosAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingAppStoreListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: IosScreenshotPatch;
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingAppStoreListingIosAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** The fields on `appStoreListing` to look up the row to update. */
export type AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingAppStoreListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: UpdateAppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AppStoreListing`. Fields that are set will be updated. */
export type AppStoreListingPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyConnection = {
  __typename?: 'AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyEdge = {
  __typename?: 'AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByCreatedBy: IosScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndCreatedByManyToManyEdgeIosScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyConnection = {
  __typename?: 'AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyEdge = {
  __typename?: 'AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByUpdatedBy: IosScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type AppStoreListingPeopleByIosScreenshotAppStoreListingIdAndUpdatedByManyToManyEdgeIosScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** The `appStoreListing` to be created by this mutation. */
export type AppStoreListingUpdatedByFkeyAppStoreListingCreateInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `AppStoreListingInput` mutation. */
export type AppStoreListingUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreListingUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `appStoreListing` in the `PersonInput` mutation. */
export type AppStoreListingUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreListingAppStoreListingPkeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreListingNodeIdConnect>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreListingAppStoreListingPkeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByIosAppId?: Maybe<Array<AppStoreListingAppStoreListingIosAppIdKeyDelete>>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingAppStoreListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByIosAppId?: Maybe<Array<AppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingAppStoreListingIosAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreListingForAppStoreListingUpdatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreListingUpdatedByFkeyAppStoreListingCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AppStoreListingUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AppStoreListing` values. */
export type AppStoreListingsConnection = {
  __typename?: 'AppStoreListingsConnection';
  /** A list of `AppStoreListing` objects. */
  nodes: Array<AppStoreListing>;
  /** A list of edges which contains the `AppStoreListing` and cursor to aid in pagination. */
  edges: Array<AppStoreListingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreListing` edge in the connection. */
export type AppStoreListingsEdge = {
  __typename?: 'AppStoreListingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreListing` at the end of the edge. */
  node: AppStoreListing;
};

/** Methods to use when ordering `AppStoreListing`. */
export enum AppStoreListingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IosAppIdAsc = 'IOS_APP_ID_ASC',
  IosAppIdDesc = 'IOS_APP_ID_DESC',
  AppStoreAccountIdAsc = 'APP_STORE_ACCOUNT_ID_ASC',
  AppStoreAccountIdDesc = 'APP_STORE_ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  AppNameAsc = 'APP_NAME_ASC',
  AppNameDesc = 'APP_NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  KeywordsAsc = 'KEYWORDS_ASC',
  KeywordsDesc = 'KEYWORDS_DESC',
  ReviewerNotesAsc = 'REVIEWER_NOTES_ASC',
  ReviewerNotesDesc = 'REVIEWER_NOTES_DESC',
  MarketingUrlAsc = 'MARKETING_URL_ASC',
  MarketingUrlDesc = 'MARKETING_URL_DESC',
  SupportUrlAsc = 'SUPPORT_URL_ASC',
  SupportUrlDesc = 'SUPPORT_URL_DESC',
  AppStoreProprietaryAppIdAsc = 'APP_STORE_PROPRIETARY_APP_ID_ASC',
  AppStoreProprietaryAppIdDesc = 'APP_STORE_PROPRIETARY_APP_ID_DESC',
  PushCertExpirationDateAsc = 'PUSH_CERT_EXPIRATION_DATE_ASC',
  PushCertExpirationDateDesc = 'PUSH_CERT_EXPIRATION_DATE_DESC',
  AppGroupLinkedAsc = 'APP_GROUP_LINKED_ASC',
  AppGroupLinkedDesc = 'APP_GROUP_LINKED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * `app_store_user`.\n\n
 *
 * Currently, this table will contain one row with our user account credentials. Eventually this poises us to allow for dev account invites to be a thing of the past if we go the api key route.\n\n
 *
 * When `fastlane_space_auth` is null, all accounts associate with this user cannot ship, and we'll need to run a fastlane action locally to restore this cookie.
 *
 * 1 `app_store_user` relates to many `app_store_accounts`.
 */
export type AppStoreUser = Node & {
  __typename?: 'AppStoreUser';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccounts: AppStoreAccountsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreAccountAppStoreUserIdAndCreatedBy: AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreAccountAppStoreUserIdAndUpdatedBy: AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyConnection;
};


/**
 * `app_store_user`.\n\n
 *
 * Currently, this table will contain one row with our user account credentials. Eventually this poises us to allow for dev account invites to be a thing of the past if we go the api key route.\n\n
 *
 * When `fastlane_space_auth` is null, all accounts associate with this user cannot ship, and we'll need to run a fastlane action locally to restore this cookie.
 *
 * 1 `app_store_user` relates to many `app_store_accounts`.
 */
export type AppStoreUserAppStoreAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/**
 * `app_store_user`.\n\n
 *
 * Currently, this table will contain one row with our user account credentials. Eventually this poises us to allow for dev account invites to be a thing of the past if we go the api key route.\n\n
 *
 * When `fastlane_space_auth` is null, all accounts associate with this user cannot ship, and we'll need to run a fastlane action locally to restore this cookie.
 *
 * 1 `app_store_user` relates to many `app_store_accounts`.
 */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `app_store_user`.\n\n
 *
 * Currently, this table will contain one row with our user account credentials. Eventually this poises us to allow for dev account invites to be a thing of the past if we go the api key route.\n\n
 *
 * When `fastlane_space_auth` is null, all accounts associate with this user cannot ship, and we'll need to run a fastlane action locally to restore this cookie.
 *
 * 1 `app_store_user` relates to many `app_store_accounts`.
 */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The fields on `appStoreUser` to look up the row to connect. */
export type AppStoreUserAppStoreUserEmailKeyConnect = {
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** The fields on `appStoreUser` to look up the row to delete. */
export type AppStoreUserAppStoreUserEmailKeyDelete = {
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** The fields on `appStoreUser` to look up the row to connect. */
export type AppStoreUserAppStoreUserPkeyConnect = {
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreUser` to look up the row to delete. */
export type AppStoreUserAppStoreUserPkeyDelete = {
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `AppStoreUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AppStoreUserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `password` field. */
  password?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appSpecificPassword` field. */
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fastlaneSpaceAuth` field. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
};

/** The `appStoreUser` to be created by this mutation. */
export type AppStoreUserCreatedByFkeyAppStoreUserCreateInput = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreUser` in the `PersonInput` mutation. */
export type AppStoreUserCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreUser` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreUserAppStoreUserPkeyConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByEmail?: Maybe<Array<AppStoreUserAppStoreUserEmailKeyConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreUserNodeIdConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreUserAppStoreUserPkeyDelete>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<Array<AppStoreUserAppStoreUserEmailKeyDelete>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreUserNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyUsingAppStoreUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByEmail?: Maybe<Array<AppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyUsingAppStoreUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreUserForAppStoreUserCreatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreUserCreatedByFkeyAppStoreUserCreateInput>>;
};

/** A filter to be used against `AppStoreUser` object types. All fields are combined with a logical ‘and.’ */
export type AppStoreUserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `password` field. */
  password?: Maybe<StringFilter>;
  /** Filter by the object’s `appSpecificPassword` field. */
  appSpecificPassword?: Maybe<StringFilter>;
  /** Filter by the object’s `fastlaneSpaceAuth` field. */
  fastlaneSpaceAuth?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppStoreUserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppStoreUserFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppStoreUserFilter>;
};

/** An input for mutations affecting `AppStoreUser` */
export type AppStoreUserInput = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppStoreUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `appStoreUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppStoreUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `appStoreUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyUsingAppStoreUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch;
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyUsingAppStoreUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyPatch;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyUsingAppStoreUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyPatch;
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingAppStoreUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyPatch;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** The fields on `appStoreUser` to look up the row to update. */
export type AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingAppStoreUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: UpdateAppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyPatch;
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `AppStoreUser`. Fields that are set will be updated. */
export type AppStoreUserPatch = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyConnection = {
  __typename?: 'AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyEdge = {
  __typename?: 'AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByCreatedBy: AppStoreAccountsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndCreatedByManyToManyEdgeAppStoreAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyConnection = {
  __typename?: 'AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyEdge = {
  __typename?: 'AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByUpdatedBy: AppStoreAccountsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type AppStoreUserPeopleByAppStoreAccountAppStoreUserIdAndUpdatedByManyToManyEdgeAppStoreAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** The `appStoreUser` to be created by this mutation. */
export type AppStoreUserUpdatedByFkeyAppStoreUserCreateInput = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `AppStoreUserInput` mutation. */
export type AppStoreUserUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AppStoreUserUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `appStoreUser` in the `PersonInput` mutation. */
export type AppStoreUserUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `appStoreUser` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectById?: Maybe<Array<AppStoreUserAppStoreUserPkeyConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByEmail?: Maybe<Array<AppStoreUserAppStoreUserEmailKeyConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppStoreUserNodeIdConnect>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppStoreUserAppStoreUserPkeyDelete>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<Array<AppStoreUserAppStoreUserEmailKeyDelete>>;
  /** The primary key(s) for `appStoreUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppStoreUserNodeIdDelete>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateById?: Maybe<Array<AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingAppStoreUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByEmail?: Maybe<Array<AppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingAppStoreUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `appStoreUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppStoreUserForAppStoreUserUpdatedByFkeyNodeIdUpdate>>;
  /** A `AppStoreUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppStoreUserUpdatedByFkeyAppStoreUserCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AppStoreUserUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `AppStoreUser` values. */
export type AppStoreUsersConnection = {
  __typename?: 'AppStoreUsersConnection';
  /** A list of `AppStoreUser` objects. */
  nodes: Array<AppStoreUser>;
  /** A list of edges which contains the `AppStoreUser` and cursor to aid in pagination. */
  edges: Array<AppStoreUsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreUser` edge in the connection. */
export type AppStoreUsersEdge = {
  __typename?: 'AppStoreUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreUser` at the end of the edge. */
  node: AppStoreUser;
};

/** Methods to use when ordering `AppStoreUser`. */
export enum AppStoreUsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  AppSpecificPasswordAsc = 'APP_SPECIFIC_PASSWORD_ASC',
  AppSpecificPasswordDesc = 'APP_SPECIFIC_PASSWORD_DESC',
  FastlaneSpaceAuthAsc = 'FASTLANE_SPACE_AUTH_ASC',
  FastlaneSpaceAuthDesc = 'FASTLANE_SPACE_AUTH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `app` to be created by this mutation. */
export type AppUpdatedByFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `AppInput` mutation. */
export type AppUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnAppForAppUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppOnAppForAppUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<AppUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `app` in the `PersonInput` mutation. */
export type AppUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `app` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<Array<AppAppPkeyConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<AppAppSchoolIdKeyConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppNodeIdConnect>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppAppPkeyDelete>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<AppAppSchoolIdKeyDelete>>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppNodeIdDelete>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<Array<AppOnAppForAppUpdatedByFkeyUsingAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<AppOnAppForAppUpdatedByFkeyUsingAppSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnAppForAppUpdatedByFkeyNodeIdUpdate>>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppUpdatedByFkeyAppCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type AppUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `App` values. */
export type AppsConnection = {
  __typename?: 'AppsConnection';
  /** A list of `App` objects. */
  nodes: Array<App>;
  /** A list of edges which contains the `App` and cursor to aid in pagination. */
  edges: Array<AppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `App` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `App` edge in the connection. */
export type AppsEdge = {
  __typename?: 'AppsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `App` at the end of the edge. */
  node: App;
};

/** Methods to use when ordering `App`. */
export enum AppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  AppTitleUnderIconAsc = 'APP_TITLE_UNDER_ICON_ASC',
  AppTitleUnderIconDesc = 'APP_TITLE_UNDER_ICON_DESC',
  OnesignalAppIdAsc = 'ONESIGNAL_APP_ID_ASC',
  OnesignalAppIdDesc = 'ONESIGNAL_APP_ID_DESC',
  OnesignalAppAuthTokenAsc = 'ONESIGNAL_APP_AUTH_TOKEN_ASC',
  OnesignalAppAuthTokenDesc = 'ONESIGNAL_APP_AUTH_TOKEN_DESC',
  IconUrlAsc = 'ICON_URL_ASC',
  IconUrlDesc = 'ICON_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `archiveAndroidJavaKeyStoreAndCreateNew` mutation. */
export type ArchiveAndroidJavaKeyStoreAndCreateNewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vOldJksId: Scalars['UUID'];
  vKeyAlias: Scalars['String'];
  vKeyPass: Scalars['String'];
  vStorePass: Scalars['String'];
};

/** The output of our `archiveAndroidJavaKeyStoreAndCreateNew` mutation. */
export type ArchiveAndroidJavaKeyStoreAndCreateNewPayload = {
  __typename?: 'ArchiveAndroidJavaKeyStoreAndCreateNewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidJavaKeyStore`. May be used by Relay 1. */
  androidJavaKeyStoreEdge?: Maybe<AndroidJavaKeyStoresEdge>;
};


/** The output of our `archiveAndroidJavaKeyStoreAndCreateNew` mutation. */
export type ArchiveAndroidJavaKeyStoreAndCreateNewPayloadAndroidJavaKeyStoreEdgeArgs = {
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
};

/** All input for the `authenticate` mutation. */
export type AuthenticateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  password: Scalars['String'];
};

/** The output of our `authenticate` mutation. */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/**
 * `build`.\n\n
 *
 * A `build` has meta data for an app store listing that is specific to the app build, and helpful info to track a builds progress.\n\n
 *
 * This will be included in on the `file` table in app_public for initial Android uploads.
 *
 * The most recent build with `build_live` is true is the current live build.\n\n
 *
 * The most recent build where `build_killed` `build_failed` `build_rejected` `build_live` are all false is the currently building build.\n\n
 */
export type Build = Node & {
  __typename?: 'Build';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `build`. */
  id: Scalars['UUID'];
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId: Scalars['UUID'];
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted: Scalars['Boolean'];
  /** This is set set to true when the build goes live. */
  buildLive: Scalars['Boolean'];
  /** This is set to true once the if we stop a build. */
  buildKilled: Scalars['Boolean'];
  /** This is set to true if the build fails. */
  buildFailed: Scalars['Boolean'];
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Reads a single `App` that is related to this `Build`. */
  app?: Maybe<App>;
  /** Reads a single `Release` that is related to this `Build`. */
  release?: Maybe<Release>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildSteps: BuildStepsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildStepBuildIdAndCreatedBy: BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildStepBuildIdAndUpdatedBy: BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyConnection;
};


/**
 * `build`.\n\n
 *
 * A `build` has meta data for an app store listing that is specific to the app build, and helpful info to track a builds progress.\n\n
 *
 * This will be included in on the `file` table in app_public for initial Android uploads.
 *
 * The most recent build with `build_live` is true is the current live build.\n\n
 *
 * The most recent build where `build_killed` `build_failed` `build_rejected` `build_live` are all false is the currently building build.\n\n
 */
export type BuildBuildStepsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};


/**
 * `build`.\n\n
 *
 * A `build` has meta data for an app store listing that is specific to the app build, and helpful info to track a builds progress.\n\n
 *
 * This will be included in on the `file` table in app_public for initial Android uploads.
 *
 * The most recent build with `build_live` is true is the current live build.\n\n
 *
 * The most recent build where `build_killed` `build_failed` `build_rejected` `build_live` are all false is the currently building build.\n\n
 */
export type BuildPeopleByBuildStepBuildIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `build`.\n\n
 *
 * A `build` has meta data for an app store listing that is specific to the app build, and helpful info to track a builds progress.\n\n
 *
 * This will be included in on the `file` table in app_public for initial Android uploads.
 *
 * The most recent build with `build_live` is true is the current live build.\n\n
 *
 * The most recent build where `build_killed` `build_failed` `build_rejected` `build_live` are all false is the currently building build.\n\n
 */
export type BuildPeopleByBuildStepBuildIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The `app` to be created by this mutation. */
export type BuildAppIdFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** The `build` to be created by this mutation. */
export type BuildAppIdFkeyBuildCreateInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** Input for the nested mutation of `app` in the `BuildInput` mutation. */
export type BuildAppIdFkeyInput = {
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<AppAppPkeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<AppAppSchoolIdKeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppNodeIdConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<AppAppPkeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<AppAppSchoolIdKeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppNodeIdDelete>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<AppOnBuildForBuildAppIdFkeyUsingAppPkeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<AppOnBuildForBuildAppIdFkeyUsingAppSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<BuildOnBuildForBuildAppIdFkeyNodeIdUpdate>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<BuildAppIdFkeyAppCreateInput>;
};

/** Input for the nested mutation of `build` in the `AppInput` mutation. */
export type BuildAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `build` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildBuildPkeyConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildNodeIdConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildBuildPkeyDelete>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildNodeIdDelete>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildOnBuildForBuildAppIdFkeyUsingBuildPkeyUpdate>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AppOnBuildForBuildAppIdFkeyNodeIdUpdate>>;
  /** A `BuildInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildAppIdFkeyBuildCreateInput>>;
};

/** The fields on `build` to look up the row to connect. */
export type BuildBuildPkeyConnect = {
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The fields on `build` to look up the row to delete. */
export type BuildBuildPkeyDelete = {
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** A condition to be used against `Build` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BuildCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appId` field. */
  appId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `releaseId` field. */
  releaseId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `platform` field. */
  platform?: Maybe<PlatformType>;
  /** Checks for equality with the object’s `appVersion` field. */
  appVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `buildNumber` field. */
  buildNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `releaseNotes` field. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `buildStarted` field. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `buildLive` field. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `buildKilled` field. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `buildFailed` field. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `timeStarted` field. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `timeLive` field. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `timeKilled` field. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `timeFailed` field. */
  timeFailed?: Maybe<Scalars['Datetime']>;
};

/** The `build` to be created by this mutation. */
export type BuildCreatedByFkeyBuildCreateInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** Input for the nested mutation of `build` in the `PersonInput` mutation. */
export type BuildCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `build` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildBuildPkeyConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildNodeIdConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildBuildPkeyDelete>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildNodeIdDelete>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildOnBuildForBuildCreatedByFkeyUsingBuildPkeyUpdate>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnBuildForBuildCreatedByFkeyNodeIdUpdate>>;
  /** A `BuildInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildCreatedByFkeyBuildCreateInput>>;
};

/** A filter to be used against `Build` object types. All fields are combined with a logical ‘and.’ */
export type BuildFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `appId` field. */
  appId?: Maybe<UuidFilter>;
  /** Filter by the object’s `releaseId` field. */
  releaseId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `platform` field. */
  platform?: Maybe<PlatformTypeFilter>;
  /** Filter by the object’s `appVersion` field. */
  appVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `buildNumber` field. */
  buildNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `releaseNotes` field. */
  releaseNotes?: Maybe<StringFilter>;
  /** Filter by the object’s `buildStarted` field. */
  buildStarted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `buildLive` field. */
  buildLive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `buildKilled` field. */
  buildKilled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `buildFailed` field. */
  buildFailed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `timeStarted` field. */
  timeStarted?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `timeLive` field. */
  timeLive?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `timeKilled` field. */
  timeKilled?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `timeFailed` field. */
  timeFailed?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BuildFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BuildFilter>>;
  /** Negates the expression. */
  not?: Maybe<BuildFilter>;
};

/** An input for mutations affecting `Build` */
export type BuildInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BuildNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BuildNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `build` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildOnBuildForBuildAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The fields on `build` to look up the row to update. */
export type BuildOnBuildForBuildAppIdFkeyUsingBuildPkeyUpdate = {
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: UpdateBuildOnBuildForBuildAppIdFkeyPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The fields on `build` to look up the row to update. */
export type BuildOnBuildForBuildCreatedByFkeyUsingBuildPkeyUpdate = {
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: UpdateBuildOnBuildForBuildCreatedByFkeyPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildOnBuildForBuildReleaseIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `release` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: ReleasePatch;
};

/** The fields on `build` to look up the row to update. */
export type BuildOnBuildForBuildReleaseIdFkeyUsingBuildPkeyUpdate = {
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: UpdateBuildOnBuildForBuildReleaseIdFkeyPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildOnBuildForBuildUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `build` to look up the row to update. */
export type BuildOnBuildForBuildUpdatedByFkeyUsingBuildPkeyUpdate = {
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: UpdateBuildOnBuildForBuildUpdatedByFkeyPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildOnBuildStepForBuildStepBuildIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `buildStep` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: BuildStepPatch;
};

/** The fields on `build` to look up the row to update. */
export type BuildOnBuildStepForBuildStepBuildIdFkeyUsingBuildPkeyUpdate = {
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: UpdateBuildOnBuildStepForBuildStepBuildIdFkeyPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `Build`. Fields that are set will be updated. */
export type BuildPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyConnection = {
  __typename?: 'BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyEdge = {
  __typename?: 'BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByCreatedBy: BuildStepsConnection;
};


/** A `Person` edge in the connection, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndCreatedByManyToManyEdgeBuildStepsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** A connection to a list of `Person` values, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyConnection = {
  __typename?: 'BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyEdge = {
  __typename?: 'BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByUpdatedBy: BuildStepsConnection;
};


/** A `Person` edge in the connection, with data from `BuildStep`. */
export type BuildPeopleByBuildStepBuildIdAndUpdatedByManyToManyEdgeBuildStepsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** The `build` to be created by this mutation. */
export type BuildReleaseIdFkeyBuildCreateInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** Input for the nested mutation of `release` in the `BuildInput` mutation. */
export type BuildReleaseIdFkeyInput = {
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectById?: Maybe<ReleaseReleasePkeyConnect>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectByNodeId?: Maybe<ReleaseNodeIdConnect>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteById?: Maybe<ReleaseReleasePkeyDelete>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ReleaseNodeIdDelete>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateById?: Maybe<ReleaseOnBuildForBuildReleaseIdFkeyUsingReleasePkeyUpdate>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateByNodeId?: Maybe<BuildOnBuildForBuildReleaseIdFkeyNodeIdUpdate>;
  /** A `ReleaseInput` object that will be created and connected to this object. */
  create?: Maybe<BuildReleaseIdFkeyReleaseCreateInput>;
};

/** Input for the nested mutation of `build` in the `ReleaseInput` mutation. */
export type BuildReleaseIdFkeyInverseInput = {
  /** Flag indicating whether all other `build` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildBuildPkeyConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildNodeIdConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildBuildPkeyDelete>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildNodeIdDelete>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildOnBuildForBuildReleaseIdFkeyUsingBuildPkeyUpdate>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ReleaseOnBuildForBuildReleaseIdFkeyNodeIdUpdate>>;
  /** A `BuildInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildReleaseIdFkeyBuildCreateInput>>;
};

/** The `release` to be created by this mutation. */
export type BuildReleaseIdFkeyReleaseCreateInput = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform: PlatformType;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType: ReleaseType;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion: Scalars['String'];
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse: Scalars['String'];
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse: Scalars['String'];
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/**
 * `build_step`.\n\n
 *
 * This is the table that holds all the info for each build step in the process.\n\n
 *
 * The plan is for these to drive the flow of builds.\n\n
 *
 * An active build step is not failed, killed, or succeeded.
 */
export type BuildStep = Node & {
  __typename?: 'BuildStep';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
  /** The foreign key for `build` where one build has many steps. */
  buildId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber: Scalars['Int'];
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded: Scalars['Boolean'];
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed: Scalars['Boolean'];
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed: Scalars['Boolean'];
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Build` that is related to this `BuildStep`. */
  build?: Maybe<Build>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The `build` to be created by this mutation. */
export type BuildStepBuildIdFkeyBuildCreateInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** The `buildStep` to be created by this mutation. */
export type BuildStepBuildIdFkeyBuildStepCreateInput = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `build` in the `BuildStepInput` mutation. */
export type BuildStepBuildIdFkeyInput = {
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectById?: Maybe<BuildBuildPkeyConnect>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectByNodeId?: Maybe<BuildNodeIdConnect>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteById?: Maybe<BuildBuildPkeyDelete>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteByNodeId?: Maybe<BuildNodeIdDelete>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateById?: Maybe<BuildOnBuildStepForBuildStepBuildIdFkeyUsingBuildPkeyUpdate>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateByNodeId?: Maybe<BuildStepOnBuildStepForBuildStepBuildIdFkeyNodeIdUpdate>;
  /** A `BuildInput` object that will be created and connected to this object. */
  create?: Maybe<BuildStepBuildIdFkeyBuildCreateInput>;
};

/** Input for the nested mutation of `buildStep` in the `BuildInput` mutation. */
export type BuildStepBuildIdFkeyInverseInput = {
  /** Flag indicating whether all other `buildStep` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildStepBuildStepPkeyConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildStepNodeIdConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildStepBuildStepPkeyDelete>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildStepNodeIdDelete>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildStepOnBuildStepForBuildStepBuildIdFkeyUsingBuildStepPkeyUpdate>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<BuildOnBuildStepForBuildStepBuildIdFkeyNodeIdUpdate>>;
  /** A `BuildStepInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildStepBuildIdFkeyBuildStepCreateInput>>;
};

/** The fields on `buildStep` to look up the row to connect. */
export type BuildStepBuildStepPkeyConnect = {
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** The fields on `buildStep` to look up the row to delete. */
export type BuildStepBuildStepPkeyDelete = {
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `BuildStep` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BuildStepCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `buildId` field. */
  buildId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `platform` field. */
  platform?: Maybe<PlatformType>;
  /** Checks for equality with the object’s `stepNumber` field. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `buildStepName` field. */
  buildStepName?: Maybe<BuildStepName>;
  /** Checks for equality with the object’s `bitriseBuildSlug` field. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `timeStarted` field. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `succeeded` field. */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `timeSucceeded` field. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `failed` field. */
  failed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `failureMessage` field. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `timeFailed` field. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `killed` field. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `timeKilled` field. */
  timeKilled?: Maybe<Scalars['Datetime']>;
};

/** The `buildStep` to be created by this mutation. */
export type BuildStepCreatedByFkeyBuildStepCreateInput = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `buildStep` in the `PersonInput` mutation. */
export type BuildStepCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `buildStep` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildStepBuildStepPkeyConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildStepNodeIdConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildStepBuildStepPkeyDelete>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildStepNodeIdDelete>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildStepOnBuildStepForBuildStepCreatedByFkeyUsingBuildStepPkeyUpdate>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnBuildStepForBuildStepCreatedByFkeyNodeIdUpdate>>;
  /** A `BuildStepInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildStepCreatedByFkeyBuildStepCreateInput>>;
};

/** A filter to be used against `BuildStep` object types. All fields are combined with a logical ‘and.’ */
export type BuildStepFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `buildId` field. */
  buildId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `platform` field. */
  platform?: Maybe<PlatformTypeFilter>;
  /** Filter by the object’s `stepNumber` field. */
  stepNumber?: Maybe<IntFilter>;
  /** Filter by the object’s `buildStepName` field. */
  buildStepName?: Maybe<BuildStepNameFilter>;
  /** Filter by the object’s `bitriseBuildSlug` field. */
  bitriseBuildSlug?: Maybe<StringFilter>;
  /** Filter by the object’s `timeStarted` field. */
  timeStarted?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `succeeded` field. */
  succeeded?: Maybe<BooleanFilter>;
  /** Filter by the object’s `timeSucceeded` field. */
  timeSucceeded?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `failed` field. */
  failed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `failureMessage` field. */
  failureMessage?: Maybe<StringFilter>;
  /** Filter by the object’s `timeFailed` field. */
  timeFailed?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `killed` field. */
  killed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `timeKilled` field. */
  timeKilled?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BuildStepFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BuildStepFilter>>;
  /** Negates the expression. */
  not?: Maybe<BuildStepFilter>;
};

/** An input for mutations affecting `BuildStep` */
export type BuildStepInput = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

export enum BuildStepName {
  IosMakeGitRepo = 'IOS_MAKE_GIT_REPO',
  IosSetupOnesignal = 'IOS_SETUP_ONESIGNAL',
  IosMakeAppInAsc = 'IOS_MAKE_APP_IN_ASC',
  IosUploadPrivacyDetails = 'IOS_UPLOAD_PRIVACY_DETAILS',
  IosMakeProfilesAndCerts = 'IOS_MAKE_PROFILES_AND_CERTS',
  IosBuildApp = 'IOS_BUILD_APP',
  IosUploadApp = 'IOS_UPLOAD_APP',
  IosUploadMetaDataAndSubmitForReview = 'IOS_UPLOAD_META_DATA_AND_SUBMIT_FOR_REVIEW',
  IosReview = 'IOS_REVIEW',
  IosPublishApprovedApp = 'IOS_PUBLISH_APPROVED_APP',
  IosFinishBuild = 'IOS_FINISH_BUILD',
  IosFailBuild = 'IOS_FAIL_BUILD',
  Empty = '_EMPTY_',
  AndroidTestGooglePlayConnection = 'ANDROID_TEST_GOOGLE_PLAY_CONNECTION',
  AndroidSetupOnesignal = 'ANDROID_SETUP_ONESIGNAL',
  AndroidMakeApp = 'ANDROID_MAKE_APP',
  AndroidUploadToPlayStore = 'ANDROID_UPLOAD_TO_PLAY_STORE',
  AndroidReview = 'ANDROID_REVIEW',
  AndroidFinishBuild = 'ANDROID_FINISH_BUILD',
  AndroidFailBuild = 'ANDROID_FAIL_BUILD'
}

/** A filter to be used against BuildStepName fields. All fields are combined with a logical ‘and.’ */
export type BuildStepNameFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<BuildStepName>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<BuildStepName>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<BuildStepName>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<BuildStepName>;
  /** Included in the specified list. */
  in?: Maybe<Array<BuildStepName>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<BuildStepName>>;
  /** Less than the specified value. */
  lessThan?: Maybe<BuildStepName>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<BuildStepName>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<BuildStepName>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<BuildStepName>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BuildStepNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `buildStep` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BuildStepNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `buildStep` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildStepOnBuildStepForBuildStepBuildIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: BuildPatch;
};

/** The fields on `buildStep` to look up the row to update. */
export type BuildStepOnBuildStepForBuildStepBuildIdFkeyUsingBuildStepPkeyUpdate = {
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: UpdateBuildStepOnBuildStepForBuildStepBuildIdFkeyPatch;
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** The fields on `buildStep` to look up the row to update. */
export type BuildStepOnBuildStepForBuildStepCreatedByFkeyUsingBuildStepPkeyUpdate = {
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: UpdateBuildStepOnBuildStepForBuildStepCreatedByFkeyPatch;
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BuildStepOnBuildStepForBuildStepUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `buildStep` to look up the row to update. */
export type BuildStepOnBuildStepForBuildStepUpdatedByFkeyUsingBuildStepPkeyUpdate = {
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: UpdateBuildStepOnBuildStepForBuildStepUpdatedByFkeyPatch;
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `BuildStep`. Fields that are set will be updated. */
export type BuildStepPatch = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** The `buildStep` to be created by this mutation. */
export type BuildStepUpdatedByFkeyBuildStepCreateInput = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `BuildStepInput` mutation. */
export type BuildStepUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnBuildStepForBuildStepUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<BuildStepOnBuildStepForBuildStepUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<BuildStepUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `buildStep` in the `PersonInput` mutation. */
export type BuildStepUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `buildStep` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildStepBuildStepPkeyConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildStepNodeIdConnect>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildStepBuildStepPkeyDelete>>;
  /** The primary key(s) for `buildStep` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildStepNodeIdDelete>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildStepOnBuildStepForBuildStepUpdatedByFkeyUsingBuildStepPkeyUpdate>>;
  /** The primary key(s) and patch data for `buildStep` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnBuildStepForBuildStepUpdatedByFkeyNodeIdUpdate>>;
  /** A `BuildStepInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildStepUpdatedByFkeyBuildStepCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type BuildStepUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `BuildStep` values. */
export type BuildStepsConnection = {
  __typename?: 'BuildStepsConnection';
  /** A list of `BuildStep` objects. */
  nodes: Array<BuildStep>;
  /** A list of edges which contains the `BuildStep` and cursor to aid in pagination. */
  edges: Array<BuildStepsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BuildStep` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BuildStep` edge in the connection. */
export type BuildStepsEdge = {
  __typename?: 'BuildStepsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BuildStep` at the end of the edge. */
  node: BuildStep;
};

/** Methods to use when ordering `BuildStep`. */
export enum BuildStepsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BuildIdAsc = 'BUILD_ID_ASC',
  BuildIdDesc = 'BUILD_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  PlatformAsc = 'PLATFORM_ASC',
  PlatformDesc = 'PLATFORM_DESC',
  StepNumberAsc = 'STEP_NUMBER_ASC',
  StepNumberDesc = 'STEP_NUMBER_DESC',
  BuildStepNameAsc = 'BUILD_STEP_NAME_ASC',
  BuildStepNameDesc = 'BUILD_STEP_NAME_DESC',
  BitriseBuildSlugAsc = 'BITRISE_BUILD_SLUG_ASC',
  BitriseBuildSlugDesc = 'BITRISE_BUILD_SLUG_DESC',
  TimeStartedAsc = 'TIME_STARTED_ASC',
  TimeStartedDesc = 'TIME_STARTED_DESC',
  SucceededAsc = 'SUCCEEDED_ASC',
  SucceededDesc = 'SUCCEEDED_DESC',
  TimeSucceededAsc = 'TIME_SUCCEEDED_ASC',
  TimeSucceededDesc = 'TIME_SUCCEEDED_DESC',
  FailedAsc = 'FAILED_ASC',
  FailedDesc = 'FAILED_DESC',
  FailureMessageAsc = 'FAILURE_MESSAGE_ASC',
  FailureMessageDesc = 'FAILURE_MESSAGE_DESC',
  TimeFailedAsc = 'TIME_FAILED_ASC',
  TimeFailedDesc = 'TIME_FAILED_DESC',
  KilledAsc = 'KILLED_ASC',
  KilledDesc = 'KILLED_DESC',
  TimeKilledAsc = 'TIME_KILLED_ASC',
  TimeKilledDesc = 'TIME_KILLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `build` to be created by this mutation. */
export type BuildUpdatedByFkeyBuildCreateInput = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `BuildInput` mutation. */
export type BuildUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnBuildForBuildUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<BuildOnBuildForBuildUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<BuildUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `build` in the `PersonInput` mutation. */
export type BuildUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `build` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectById?: Maybe<Array<BuildBuildPkeyConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<BuildNodeIdConnect>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteById?: Maybe<Array<BuildBuildPkeyDelete>>;
  /** The primary key(s) for `build` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<BuildNodeIdDelete>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateById?: Maybe<Array<BuildOnBuildForBuildUpdatedByFkeyUsingBuildPkeyUpdate>>;
  /** The primary key(s) and patch data for `build` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnBuildForBuildUpdatedByFkeyNodeIdUpdate>>;
  /** A `BuildInput` object that will be created and connected to this object. */
  create?: Maybe<Array<BuildUpdatedByFkeyBuildCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type BuildUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Build` values. */
export type BuildsConnection = {
  __typename?: 'BuildsConnection';
  /** A list of `Build` objects. */
  nodes: Array<Build>;
  /** A list of edges which contains the `Build` and cursor to aid in pagination. */
  edges: Array<BuildsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Build` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Build` edge in the connection. */
export type BuildsEdge = {
  __typename?: 'BuildsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Build` at the end of the edge. */
  node: Build;
};

/** Methods to use when ordering `Build`. */
export enum BuildsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AppIdAsc = 'APP_ID_ASC',
  AppIdDesc = 'APP_ID_DESC',
  ReleaseIdAsc = 'RELEASE_ID_ASC',
  ReleaseIdDesc = 'RELEASE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  PlatformAsc = 'PLATFORM_ASC',
  PlatformDesc = 'PLATFORM_DESC',
  AppVersionAsc = 'APP_VERSION_ASC',
  AppVersionDesc = 'APP_VERSION_DESC',
  BuildNumberAsc = 'BUILD_NUMBER_ASC',
  BuildNumberDesc = 'BUILD_NUMBER_DESC',
  ReleaseNotesAsc = 'RELEASE_NOTES_ASC',
  ReleaseNotesDesc = 'RELEASE_NOTES_DESC',
  BuildStartedAsc = 'BUILD_STARTED_ASC',
  BuildStartedDesc = 'BUILD_STARTED_DESC',
  BuildLiveAsc = 'BUILD_LIVE_ASC',
  BuildLiveDesc = 'BUILD_LIVE_DESC',
  BuildKilledAsc = 'BUILD_KILLED_ASC',
  BuildKilledDesc = 'BUILD_KILLED_DESC',
  BuildFailedAsc = 'BUILD_FAILED_ASC',
  BuildFailedDesc = 'BUILD_FAILED_DESC',
  TimeStartedAsc = 'TIME_STARTED_ASC',
  TimeStartedDesc = 'TIME_STARTED_DESC',
  TimeLiveAsc = 'TIME_LIVE_ASC',
  TimeLiveDesc = 'TIME_LIVE_DESC',
  TimeKilledAsc = 'TIME_KILLED_ASC',
  TimeKilledDesc = 'TIME_KILLED_DESC',
  TimeFailedAsc = 'TIME_FAILED_ASC',
  TimeFailedDesc = 'TIME_FAILED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Button = {
  __typename?: 'Button';
  id: Scalars['UUID'];
  type: ButtonType;
  url: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ButtonInput = {
  id: Scalars['UUID'];
  type: ButtonType;
  url: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ButtonType {
  Url = 'URL',
  Form = 'FORM',
  Phone = 'PHONE',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type Buttons = {
  __typename?: 'Buttons';
  buttons?: Maybe<Array<Button>>;
};

export type ButtonsInput = {
  buttons?: Maybe<Array<ButtonInput>>;
};

/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type Calendar = Node & {
  __typename?: 'Calendar';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Calendar`. */
  group?: Maybe<Group>;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  allEvents: EventsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCalendarEventCalendarIdAndEventId: CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventCalendarIdAndCreatedBy: CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventCalendarIdAndUpdatedBy: CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupId: CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarCalendarIdAndCreatedBy: CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarCalendarIdAndUpdatedBy: CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedBy: CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedBy: CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncId: CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyConnection;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarAllEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<EventFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarEventsByCalendarEventCalendarIdAndEventIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByCalendarEventCalendarIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByCalendarEventCalendarIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Every `Group` has at least one `Calendar`. All `Event` live on at least one `Calendar`. <br/><br/> */
export type CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `CalendarGroup` values, with data from `CalendarGroupCalendar`. */
export type CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyConnection = {
  __typename?: 'CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyConnection';
  /** A list of `CalendarGroup` objects. */
  nodes: Array<CalendarGroup>;
  /** A list of edges which contains the `CalendarGroup`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarGroup` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyEdge = {
  __typename?: 'CalendarCalendarGroupsByCalendarGroupCalendarCalendarIdAndCalendarGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarGroup` at the end of the edge. */
  node: CalendarGroup;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The fields on `calendar` to look up the row to connect. */
export type CalendarCalendarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `calendar` to look up the row to delete. */
export type CalendarCalendarPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `Calendar` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CalendarCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `information` field. */
  information?: Maybe<Scalars['String']>;
};

/** The `calendar` to be created by this mutation. */
export type CalendarCreatedByFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** Input for the nested mutation of `calendar` in the `PersonInput` mutation. */
export type CalendarCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarCalendarPkeyConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarNodeIdConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarCalendarPkeyDelete>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarOnCalendarForCalendarCreatedByFkeyUsingCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarForCalendarCreatedByFkeyNodeIdUpdate>>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarCreatedByFkeyCalendarCreateInput>>;
};

/** Connects an `Event` to a `Calendar`. */
export type CalendarEvent = Node & {
  __typename?: 'CalendarEvent';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  calendarId: Scalars['UUID'];
  eventId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Calendar` that is related to this `CalendarEvent`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `Event` that is related to this `CalendarEvent`. */
  event?: Maybe<Event>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The fields on `calendarEvent` to look up the row to connect. */
export type CalendarEventCalendarEventEventIdCalendarIdKeyConnect = {
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to delete. */
export type CalendarEventCalendarEventEventIdCalendarIdKeyDelete = {
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to connect. */
export type CalendarEventCalendarEventPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to delete. */
export type CalendarEventCalendarEventPkeyDelete = {
  id: Scalars['UUID'];
};

/** The `calendar` to be created by this mutation. */
export type CalendarEventCalendarIdFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** The `calendarEvent` to be created by this mutation. */
export type CalendarEventCalendarIdFkeyCalendarEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `calendar` in the `CalendarEventInput` mutation. */
export type CalendarEventCalendarIdFkeyInput = {
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<CalendarCalendarPkeyConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<CalendarNodeIdConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<CalendarCalendarPkeyDelete>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CalendarNodeIdDelete>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<CalendarOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarPkeyUpdate>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyNodeIdUpdate>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarEventCalendarIdFkeyCalendarCreateInput>;
};

/** Input for the nested mutation of `calendarEvent` in the `CalendarInput` mutation. */
export type CalendarEventCalendarIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendarEvent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarEventCalendarEventPkeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarEventNodeIdConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarEventCalendarEventPkeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarEventNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByEventIdAndCalendarId?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CalendarOnCalendarEventForCalendarEventCalendarIdFkeyNodeIdUpdate>>;
  /** A `CalendarEventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarEventCalendarIdFkeyCalendarEventCreateInput>>;
};

/**
 * A condition to be used against `CalendarEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CalendarEventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `calendarId` field. */
  calendarId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The `calendarEvent` to be created by this mutation. */
export type CalendarEventCreatedByFkeyCalendarEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `calendarEvent` in the `PersonInput` mutation. */
export type CalendarEventCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarEvent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarEventCalendarEventPkeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarEventNodeIdConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarEventCalendarEventPkeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarEventNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventCreatedByFkeyUsingCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByEventIdAndCalendarId?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventCreatedByFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarEventForCalendarEventCreatedByFkeyNodeIdUpdate>>;
  /** A `CalendarEventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarEventCreatedByFkeyCalendarEventCreateInput>>;
};

/** The `calendarEvent` to be created by this mutation. */
export type CalendarEventEventIdFkeyCalendarEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** The `event` to be created by this mutation. */
export type CalendarEventEventIdFkeyEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** Input for the nested mutation of `event` in the `CalendarEventInput` mutation. */
export type CalendarEventEventIdFkeyInput = {
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<EventEventPkeyConnect>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectBySlug?: Maybe<EventEventSlugKeyConnect>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByGoogleId?: Maybe<EventEventGoogleIdIdxConnect>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<EventNodeIdConnect>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<EventEventPkeyDelete>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteBySlug?: Maybe<EventEventSlugKeyDelete>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByGoogleId?: Maybe<EventEventGoogleIdIdxDelete>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<EventNodeIdDelete>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventPkeyUpdate>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateBySlug?: Maybe<EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventSlugKeyUpdate>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByGoogleId?: Maybe<EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventGoogleIdIdxUpdate>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarEventOnCalendarEventForCalendarEventEventIdFkeyNodeIdUpdate>;
  /** A `EventInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarEventEventIdFkeyEventCreateInput>;
};

/** Input for the nested mutation of `calendarEvent` in the `EventInput` mutation. */
export type CalendarEventEventIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendarEvent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarEventCalendarEventPkeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarEventNodeIdConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarEventCalendarEventPkeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarEventNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventEventIdFkeyUsingCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByEventIdAndCalendarId?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventEventIdFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<EventOnCalendarEventForCalendarEventEventIdFkeyNodeIdUpdate>>;
  /** A `CalendarEventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarEventEventIdFkeyCalendarEventCreateInput>>;
};

/** A filter to be used against `CalendarEvent` object types. All fields are combined with a logical ‘and.’ */
export type CalendarEventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `calendarId` field. */
  calendarId?: Maybe<UuidFilter>;
  /** Filter by the object’s `eventId` field. */
  eventId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CalendarEventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CalendarEventFilter>>;
  /** Negates the expression. */
  not?: Maybe<CalendarEventFilter>;
};

/** An input for mutations affecting `CalendarEvent` */
export type CalendarEventInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CalendarEventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CalendarEventNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyPatch;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventCreatedByFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventCreatedByFkeyPatch;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventCreatedByFkeyUsingCalendarEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventEventIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventEventIdFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventEventIdFkeyPatch;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventEventIdFkeyUsingCalendarEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventEventIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyPatch;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** The fields on `calendarEvent` to look up the row to update. */
export type CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyUsingCalendarEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: UpdateCalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `CalendarEvent`. Fields that are set will be updated. */
export type CalendarEventPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** The `calendarEvent` to be created by this mutation. */
export type CalendarEventUpdatedByFkeyCalendarEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `CalendarEventInput` mutation. */
export type CalendarEventUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnCalendarEventForCalendarEventUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarEventUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `calendarEvent` in the `PersonInput` mutation. */
export type CalendarEventUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarEvent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarEventCalendarEventPkeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarEventNodeIdConnect>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarEventCalendarEventPkeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByEventIdAndCalendarId?: Maybe<Array<CalendarEventCalendarEventEventIdCalendarIdKeyDelete>>;
  /** The primary key(s) for `calendarEvent` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarEventNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyUsingCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByEventIdAndCalendarId?: Maybe<Array<CalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyUsingCalendarEventEventIdCalendarIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarEvent` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarEventForCalendarEventUpdatedByFkeyNodeIdUpdate>>;
  /** A `CalendarEventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarEventUpdatedByFkeyCalendarEventCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type CalendarEventUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Event` values, with data from `CalendarEvent`. */
export type CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyConnection = {
  __typename?: 'CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyConnection';
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection, with data from `CalendarEvent`. */
export type CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyEdge = {
  __typename?: 'CalendarEventsByCalendarEventCalendarIdAndEventIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `CalendarEvent` values. */
export type CalendarEventsConnection = {
  __typename?: 'CalendarEventsConnection';
  /** A list of `CalendarEvent` objects. */
  nodes: Array<CalendarEvent>;
  /** A list of edges which contains the `CalendarEvent` and cursor to aid in pagination. */
  edges: Array<CalendarEventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarEvent` edge in the connection. */
export type CalendarEventsEdge = {
  __typename?: 'CalendarEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarEvent` at the end of the edge. */
  node: CalendarEvent;
};

/** Methods to use when ordering `CalendarEvent`. */
export enum CalendarEventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CalendarIdAsc = 'CALENDAR_ID_ASC',
  CalendarIdDesc = 'CALENDAR_ID_DESC',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `Calendar` object types. All fields are combined with a logical ‘and.’ */
export type CalendarFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `information` field. */
  information?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CalendarFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CalendarFilter>>;
  /** Negates the expression. */
  not?: Maybe<CalendarFilter>;
};

/** A connection to a list of `GoogleCalendarSync` values, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyConnection = {
  __typename?: 'CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyConnection';
  /** A list of `GoogleCalendarSync` objects. */
  nodes: Array<GoogleCalendarSync>;
  /** A list of edges which contains the `GoogleCalendarSync`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarSync` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyEdge = {
  __typename?: 'CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarSync` at the end of the edge. */
  node: GoogleCalendarSync;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarGoogleCalendarSyncsByGoogleCalendarSyncCalendarCalendarIdAndGoogleCalendarSyncIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A simple entity to gather a list of `Calendar`s together for quick access (e.g. Athletics). */
export type CalendarGroup = Node & {
  __typename?: 'CalendarGroup';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  sortOrder: Scalars['Int'];
  schoolId: Scalars['UUID'];
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `CalendarGroup`. */
  school?: Maybe<School>;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarId: CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarCalendarGroupIdAndCreatedBy: CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedBy: CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyConnection;
};


/** A simple entity to gather a list of `Calendar`s together for quick access (e.g. Athletics). */
export type CalendarGroupCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};


/** A simple entity to gather a list of `Calendar`s together for quick access (e.g. Athletics). */
export type CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/** A simple entity to gather a list of `Calendar`s together for quick access (e.g. Athletics). */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A simple entity to gather a list of `Calendar`s together for quick access (e.g. Athletics). */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type CalendarGroupCalendar = Node & {
  __typename?: 'CalendarGroupCalendar';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Calendar` that is related to this `CalendarGroupCalendar`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `CalendarGroup` that is related to this `CalendarGroupCalendar`. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The fields on `calendarGroupCalendar` to look up the row to connect. */
export type CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyConnect = {
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to delete. */
export type CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyDelete = {
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to connect. */
export type CalendarGroupCalendarCalendarGroupCalendarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to delete. */
export type CalendarGroupCalendarCalendarGroupCalendarPkeyDelete = {
  id: Scalars['UUID'];
};

/** The `calendarGroupCalendar` to be created by this mutation. */
export type CalendarGroupCalendarCalendarGroupIdFkeyCalendarGroupCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** The `calendarGroup` to be created by this mutation. */
export type CalendarGroupCalendarCalendarGroupIdFkeyCalendarGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `calendarGroup` in the `CalendarGroupCalendarInput` mutation. */
export type CalendarGroupCalendarCalendarGroupIdFkeyInput = {
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectById?: Maybe<CalendarGroupCalendarGroupPkeyConnect>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<CalendarGroupNodeIdConnect>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteById?: Maybe<CalendarGroupCalendarGroupPkeyDelete>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CalendarGroupNodeIdDelete>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateById?: Maybe<CalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyNodeIdUpdate>;
  /** A `CalendarGroupInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyCalendarGroupCreateInput>;
};

/** Input for the nested mutation of `calendarGroupCalendar` in the `CalendarGroupInput` mutation. */
export type CalendarGroupCalendarCalendarGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroupCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyNodeIdUpdate>>;
  /** A `CalendarGroupCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupCalendarCalendarGroupIdFkeyCalendarGroupCalendarCreateInput>>;
};

/** The `calendar` to be created by this mutation. */
export type CalendarGroupCalendarCalendarIdFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** The `calendarGroupCalendar` to be created by this mutation. */
export type CalendarGroupCalendarCalendarIdFkeyCalendarGroupCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `calendar` in the `CalendarGroupCalendarInput` mutation. */
export type CalendarGroupCalendarCalendarIdFkeyInput = {
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<CalendarCalendarPkeyConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<CalendarNodeIdConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<CalendarCalendarPkeyDelete>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CalendarNodeIdDelete>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<CalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarPkeyUpdate>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyNodeIdUpdate>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupCalendarCalendarIdFkeyCalendarCreateInput>;
};

/** Input for the nested mutation of `calendarGroupCalendar` in the `CalendarInput` mutation. */
export type CalendarGroupCalendarCalendarIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroupCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarGroupCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyNodeIdUpdate>>;
  /** A `CalendarGroupCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupCalendarCalendarIdFkeyCalendarGroupCalendarCreateInput>>;
};

/**
 * A condition to be used against `CalendarGroupCalendar` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CalendarGroupCalendarCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `calendarId` field. */
  calendarId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `calendarGroupId` field. */
  calendarGroupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The `calendarGroupCalendar` to be created by this mutation. */
export type CalendarGroupCalendarCreatedByFkeyCalendarGroupCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `calendarGroupCalendar` in the `PersonInput` mutation. */
export type CalendarGroupCalendarCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroupCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyUsingCalendarGroupCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyNodeIdUpdate>>;
  /** A `CalendarGroupCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupCalendarCreatedByFkeyCalendarGroupCalendarCreateInput>>;
};

/** A filter to be used against `CalendarGroupCalendar` object types. All fields are combined with a logical ‘and.’ */
export type CalendarGroupCalendarFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `calendarId` field. */
  calendarId?: Maybe<UuidFilter>;
  /** Filter by the object’s `calendarGroupId` field. */
  calendarGroupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CalendarGroupCalendarFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CalendarGroupCalendarFilter>>;
  /** Negates the expression. */
  not?: Maybe<CalendarGroupCalendarFilter>;
};

/** The fields on `calendarGroup` to look up the row to connect. */
export type CalendarGroupCalendarGroupPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `calendarGroup` to look up the row to delete. */
export type CalendarGroupCalendarGroupPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `CalendarGroupCalendar` */
export type CalendarGroupCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CalendarGroupCalendarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CalendarGroupCalendarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: CalendarGroupPatch;
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyPatch;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyPatch;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarGroupCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyPatch;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyUsingCalendarGroupCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyPatch;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** The fields on `calendarGroupCalendar` to look up the row to update. */
export type CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingCalendarGroupCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `CalendarGroupCalendar`. Fields that are set will be updated. */
export type CalendarGroupCalendarPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** The `calendarGroupCalendar` to be created by this mutation. */
export type CalendarGroupCalendarUpdatedByFkeyCalendarGroupCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `CalendarGroupCalendarInput` mutation. */
export type CalendarGroupCalendarUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupCalendarUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `calendarGroupCalendar` in the `PersonInput` mutation. */
export type CalendarGroupCalendarUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroupCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdConnect>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarPkeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarCalendarGroupCalendarCalendarIdCalendarGroupIdKeyDelete>>;
  /** The primary key(s) for `calendarGroupCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingCalendarGroupCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByCalendarIdAndCalendarGroupId?: Maybe<Array<CalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingCalendarGroupCalendarCalendarIdCalendarGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroupCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyNodeIdUpdate>>;
  /** A `CalendarGroupCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupCalendarUpdatedByFkeyCalendarGroupCalendarCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type CalendarGroupCalendarUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarGroupCalendar`. */
export type CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyConnection = {
  __typename?: 'CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyEdge = {
  __typename?: 'CalendarGroupCalendarsByCalendarGroupCalendarCalendarGroupIdAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `CalendarGroupCalendar` values. */
export type CalendarGroupCalendarsConnection = {
  __typename?: 'CalendarGroupCalendarsConnection';
  /** A list of `CalendarGroupCalendar` objects. */
  nodes: Array<CalendarGroupCalendar>;
  /** A list of edges which contains the `CalendarGroupCalendar` and cursor to aid in pagination. */
  edges: Array<CalendarGroupCalendarsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarGroupCalendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarGroupCalendar` edge in the connection. */
export type CalendarGroupCalendarsEdge = {
  __typename?: 'CalendarGroupCalendarsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarGroupCalendar` at the end of the edge. */
  node: CalendarGroupCalendar;
};

/** Methods to use when ordering `CalendarGroupCalendar`. */
export enum CalendarGroupCalendarsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CalendarIdAsc = 'CALENDAR_ID_ASC',
  CalendarIdDesc = 'CALENDAR_ID_DESC',
  CalendarGroupIdAsc = 'CALENDAR_GROUP_ID_ASC',
  CalendarGroupIdDesc = 'CALENDAR_GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `CalendarGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CalendarGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
};

/** The `calendarGroup` to be created by this mutation. */
export type CalendarGroupCreatedByFkeyCalendarGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `calendarGroup` in the `PersonInput` mutation. */
export type CalendarGroupCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarGroupPkeyConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupNodeIdConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarGroupPkeyDelete>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupOnCalendarGroupForCalendarGroupCreatedByFkeyUsingCalendarGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarGroupForCalendarGroupCreatedByFkeyNodeIdUpdate>>;
  /** A `CalendarGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupCreatedByFkeyCalendarGroupCreateInput>>;
};

/** A filter to be used against `CalendarGroup` object types. All fields are combined with a logical ‘and.’ */
export type CalendarGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CalendarGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CalendarGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<CalendarGroupFilter>;
};

/** The `calendar` to be created by this mutation. */
export type CalendarGroupIdFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** The `group` to be created by this mutation. */
export type CalendarGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `CalendarInput` mutation. */
export type CalendarGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCalendarForCalendarGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnCalendarForCalendarGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarOnCalendarForCalendarGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `calendar` in the `GroupInput` mutation. */
export type CalendarGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarCalendarPkeyConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarNodeIdConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarCalendarPkeyDelete>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarOnCalendarForCalendarGroupIdFkeyUsingCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCalendarForCalendarGroupIdFkeyNodeIdUpdate>>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupIdFkeyCalendarCreateInput>>;
};

/** An input for mutations affecting `CalendarGroup` */
export type CalendarGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CalendarGroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CalendarGroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
};

/** The fields on `calendarGroup` to look up the row to update. */
export type CalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyUsingCalendarGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: UpdateCalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `calendarGroup` to look up the row to update. */
export type CalendarGroupOnCalendarGroupForCalendarGroupCreatedByFkeyUsingCalendarGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: UpdateCalendarGroupOnCalendarGroupForCalendarGroupCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `calendarGroup` to look up the row to update. */
export type CalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingCalendarGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: UpdateCalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `calendarGroup` to look up the row to update. */
export type CalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyUsingCalendarGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: UpdateCalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `CalendarGroup`. Fields that are set will be updated. */
export type CalendarGroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByCreatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndCreatedByManyToManyEdgeCalendarGroupCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByUpdatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarGroupPeopleByCalendarGroupCalendarCalendarGroupIdAndUpdatedByManyToManyEdgeCalendarGroupCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** The `calendarGroup` to be created by this mutation. */
export type CalendarGroupSchoolIdFkeyCalendarGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `school` in the `CalendarGroupInput` mutation. */
export type CalendarGroupSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `calendarGroup` in the `SchoolInput` mutation. */
export type CalendarGroupSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarGroupPkeyConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupNodeIdConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarGroupPkeyDelete>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingCalendarGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyNodeIdUpdate>>;
  /** A `CalendarGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupSchoolIdFkeyCalendarGroupCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type CalendarGroupSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `calendarGroup` to be created by this mutation. */
export type CalendarGroupUpdatedByFkeyCalendarGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  title: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `CalendarGroupInput` mutation. */
export type CalendarGroupUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnCalendarGroupForCalendarGroupUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarGroupUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `calendarGroup` in the `PersonInput` mutation. */
export type CalendarGroupUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendarGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarGroupCalendarGroupPkeyConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarGroupNodeIdConnect>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarGroupCalendarGroupPkeyDelete>>;
  /** The primary key(s) for `calendarGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyUsingCalendarGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendarGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarGroupForCalendarGroupUpdatedByFkeyNodeIdUpdate>>;
  /** A `CalendarGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarGroupUpdatedByFkeyCalendarGroupCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type CalendarGroupUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `CalendarGroup` values. */
export type CalendarGroupsConnection = {
  __typename?: 'CalendarGroupsConnection';
  /** A list of `CalendarGroup` objects. */
  nodes: Array<CalendarGroup>;
  /** A list of edges which contains the `CalendarGroup` and cursor to aid in pagination. */
  edges: Array<CalendarGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarGroup` edge in the connection. */
export type CalendarGroupsEdge = {
  __typename?: 'CalendarGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarGroup` at the end of the edge. */
  node: CalendarGroup;
};

/** Methods to use when ordering `CalendarGroup`. */
export enum CalendarGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Calendar` */
export type CalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CalendarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CalendarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `calendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarOnCalendarEventForCalendarEventCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: CalendarEventPatch;
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnCalendarEventForCalendarEventCalendarIdFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnCalendarEventForCalendarEventCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnCalendarForCalendarCreatedByFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnCalendarForCalendarCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarOnCalendarForCalendarGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnCalendarForCalendarGroupIdFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnCalendarForCalendarGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarOnCalendarForCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnCalendarForCalendarUpdatedByFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnCalendarForCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
};

/** The fields on `calendar` to look up the row to update. */
export type CalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyUsingCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: UpdateCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Calendar`. Fields that are set will be updated. */
export type CalendarPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByCreatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndCreatedByManyToManyEdgeCalendarEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByUpdatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type CalendarPeopleByCalendarEventCalendarIdAndUpdatedByManyToManyEdgeCalendarEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByCreatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndCreatedByManyToManyEdgeCalendarGroupCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByUpdatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type CalendarPeopleByCalendarGroupCalendarCalendarIdAndUpdatedByManyToManyEdgeCalendarGroupCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByCreatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndCreatedByManyToManyEdgeGoogleCalendarSyncCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyConnection = {
  __typename?: 'CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyEdge = {
  __typename?: 'CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByUpdatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type CalendarPeopleByGoogleCalendarSyncCalendarCalendarIdAndUpdatedByManyToManyEdgeGoogleCalendarSyncCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** The `calendar` to be created by this mutation. */
export type CalendarUpdatedByFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `CalendarInput` mutation. */
export type CalendarUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnCalendarForCalendarUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<CalendarOnCalendarForCalendarUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<CalendarUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `calendar` in the `PersonInput` mutation. */
export type CalendarUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `calendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<Array<CalendarCalendarPkeyConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CalendarNodeIdConnect>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<CalendarCalendarPkeyDelete>>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<Array<CalendarOnCalendarForCalendarUpdatedByFkeyUsingCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnCalendarForCalendarUpdatedByFkeyNodeIdUpdate>>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CalendarUpdatedByFkeyCalendarCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type CalendarUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Calendar` values. */
export type CalendarsConnection = {
  __typename?: 'CalendarsConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar` and cursor to aid in pagination. */
  edges: Array<CalendarsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection. */
export type CalendarsEdge = {
  __typename?: 'CalendarsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
};

/** Methods to use when ordering `Calendar`. */
export enum CalendarsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  InformationAsc = 'INFORMATION_ASC',
  InformationDesc = 'INFORMATION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `cancelScheduleSettingsResetForSchoolUserType` mutation. */
export type CancelScheduleSettingsResetForSchoolUserTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolId: Scalars['UUID'];
  userType: UserType;
};

/** The output of our `cancelScheduleSettingsResetForSchoolUserType` mutation. */
export type CancelScheduleSettingsResetForSchoolUserTypePayload = {
  __typename?: 'CancelScheduleSettingsResetForSchoolUserTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `changeAndroidAppBundleId` mutation. */
export type ChangeAndroidAppBundleIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAndroidAppId: Scalars['UUID'];
  vBundleId: Scalars['String'];
};

/** The output of our `changeAndroidAppBundleId` mutation. */
export type ChangeAndroidAppBundleIdPayload = {
  __typename?: 'ChangeAndroidAppBundleIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `claimAnonymousAccount` mutation. */
export type ClaimAnonymousAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  claimingAccountPassword: Scalars['String'];
  anonymousAccountEmail: Scalars['Email'];
  anonymousAccountPassword: Scalars['String'];
  claimingAccountEmail?: Maybe<Scalars['Email']>;
};

/** The output of our `claimAnonymousAccount` mutation. */
export type ClaimAnonymousAccountPayload = {
  __typename?: 'ClaimAnonymousAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `AndroidApp` mutation. */
export type CreateAndroidAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidApp` to be created by this mutation. */
  androidApp: AndroidAppInput;
};

/** The output of our create `AndroidApp` mutation. */
export type CreateAndroidAppPayload = {
  __typename?: 'CreateAndroidAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidApp` that was created by this mutation. */
  androidApp?: Maybe<AndroidApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `AndroidApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidApp`. May be used by Relay 1. */
  androidAppEdge?: Maybe<AndroidAppsEdge>;
};


/** The output of our create `AndroidApp` mutation. */
export type CreateAndroidAppPayloadAndroidAppEdgeArgs = {
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
};

/** All input for the create `AndroidJavaKeyStore` mutation. */
export type CreateAndroidJavaKeyStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidJavaKeyStore` to be created by this mutation. */
  androidJavaKeyStore: AndroidJavaKeyStoreInput;
};

/** The output of our create `AndroidJavaKeyStore` mutation. */
export type CreateAndroidJavaKeyStorePayload = {
  __typename?: 'CreateAndroidJavaKeyStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidJavaKeyStore` that was created by this mutation. */
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidJavaKeyStore`. May be used by Relay 1. */
  androidJavaKeyStoreEdge?: Maybe<AndroidJavaKeyStoresEdge>;
};


/** The output of our create `AndroidJavaKeyStore` mutation. */
export type CreateAndroidJavaKeyStorePayloadAndroidJavaKeyStoreEdgeArgs = {
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
};

/** All input for the create `AndroidScreenshot` mutation. */
export type CreateAndroidScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidScreenshot` to be created by this mutation. */
  androidScreenshot: AndroidScreenshotInput;
};

/** The output of our create `AndroidScreenshot` mutation. */
export type CreateAndroidScreenshotPayload = {
  __typename?: 'CreateAndroidScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidScreenshot` that was created by this mutation. */
  androidScreenshot?: Maybe<AndroidScreenshot>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayListing` that is related to this `AndroidScreenshot`. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidScreenshot`. May be used by Relay 1. */
  androidScreenshotEdge?: Maybe<AndroidScreenshotsEdge>;
};


/** The output of our create `AndroidScreenshot` mutation. */
export type CreateAndroidScreenshotPayloadAndroidScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
};

/** All input for the create `App` mutation. */
export type CreateAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `App` to be created by this mutation. */
  app: AppInput;
};

/** The output of our create `App` mutation. */
export type CreateAppPayload = {
  __typename?: 'CreateAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `App` that was created by this mutation. */
  app?: Maybe<App>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `App`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `App`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `App`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `App`. May be used by Relay 1. */
  appEdge?: Maybe<AppsEdge>;
};


/** The output of our create `App` mutation. */
export type CreateAppPayloadAppEdgeArgs = {
  orderBy?: Maybe<Array<AppsOrderBy>>;
};

/** All input for the create `AppStoreAccount` mutation. */
export type CreateAppStoreAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreAccount` to be created by this mutation. */
  appStoreAccount: AppStoreAccountInput;
};

/** The output of our create `AppStoreAccount` mutation. */
export type CreateAppStoreAccountPayload = {
  __typename?: 'CreateAppStoreAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreAccount` that was created by this mutation. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreUser` that is related to this `AppStoreAccount`. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreAccount`. May be used by Relay 1. */
  appStoreAccountEdge?: Maybe<AppStoreAccountsEdge>;
};


/** The output of our create `AppStoreAccount` mutation. */
export type CreateAppStoreAccountPayloadAppStoreAccountEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
};

/** All input for the create `AppStoreListing` mutation. */
export type CreateAppStoreListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreListing` to be created by this mutation. */
  appStoreListing: AppStoreListingInput;
};

/** The output of our create `AppStoreListing` mutation. */
export type CreateAppStoreListingPayload = {
  __typename?: 'CreateAppStoreListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreListing` that was created by this mutation. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IosApp` that is related to this `AppStoreListing`. */
  iosApp?: Maybe<IosApp>;
  /** Reads a single `AppStoreAccount` that is related to this `AppStoreListing`. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreListing`. May be used by Relay 1. */
  appStoreListingEdge?: Maybe<AppStoreListingsEdge>;
};


/** The output of our create `AppStoreListing` mutation. */
export type CreateAppStoreListingPayloadAppStoreListingEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
};

/** All input for the create `AppStoreUser` mutation. */
export type CreateAppStoreUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreUser` to be created by this mutation. */
  appStoreUser: AppStoreUserInput;
};

/** The output of our create `AppStoreUser` mutation. */
export type CreateAppStoreUserPayload = {
  __typename?: 'CreateAppStoreUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreUser` that was created by this mutation. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreUser`. May be used by Relay 1. */
  appStoreUserEdge?: Maybe<AppStoreUsersEdge>;
};


/** The output of our create `AppStoreUser` mutation. */
export type CreateAppStoreUserPayloadAppStoreUserEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
};

/** All input for the create `Build` mutation. */
export type CreateBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Build` to be created by this mutation. */
  build: BuildInput;
};

/** The output of our create `Build` mutation. */
export type CreateBuildPayload = {
  __typename?: 'CreateBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Build` that was created by this mutation. */
  build?: Maybe<Build>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `Build`. */
  app?: Maybe<App>;
  /** Reads a single `Release` that is related to this `Build`. */
  release?: Maybe<Release>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Build`. May be used by Relay 1. */
  buildEdge?: Maybe<BuildsEdge>;
};


/** The output of our create `Build` mutation. */
export type CreateBuildPayloadBuildEdgeArgs = {
  orderBy?: Maybe<Array<BuildsOrderBy>>;
};

/** All input for the create `BuildStep` mutation. */
export type CreateBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BuildStep` to be created by this mutation. */
  buildStep: BuildStepInput;
};

/** The output of our create `BuildStep` mutation. */
export type CreateBuildStepPayload = {
  __typename?: 'CreateBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BuildStep` that was created by this mutation. */
  buildStep?: Maybe<BuildStep>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Build` that is related to this `BuildStep`. */
  build?: Maybe<Build>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `BuildStep`. May be used by Relay 1. */
  buildStepEdge?: Maybe<BuildStepsEdge>;
};


/** The output of our create `BuildStep` mutation. */
export type CreateBuildStepPayloadBuildStepEdgeArgs = {
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
};

/** All input for the create `CalendarEvent` mutation. */
export type CreateCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarEvent` to be created by this mutation. */
  calendarEvent: CalendarEventInput;
};

/** The output of our create `CalendarEvent` mutation. */
export type CreateCalendarEventPayload = {
  __typename?: 'CreateCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarEvent` that was created by this mutation. */
  calendarEvent?: Maybe<CalendarEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarEvent`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `Event` that is related to this `CalendarEvent`. */
  event?: Maybe<Event>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarEvent`. May be used by Relay 1. */
  calendarEventEdge?: Maybe<CalendarEventsEdge>;
};


/** The output of our create `CalendarEvent` mutation. */
export type CreateCalendarEventPayloadCalendarEventEdgeArgs = {
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
};

/** All input for the create `CalendarGroupCalendar` mutation. */
export type CreateCalendarGroupCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroupCalendar` to be created by this mutation. */
  calendarGroupCalendar: CalendarGroupCalendarInput;
};

/** The output of our create `CalendarGroupCalendar` mutation. */
export type CreateCalendarGroupCalendarPayload = {
  __typename?: 'CreateCalendarGroupCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroupCalendar` that was created by this mutation. */
  calendarGroupCalendar?: Maybe<CalendarGroupCalendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarGroupCalendar`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `CalendarGroup` that is related to this `CalendarGroupCalendar`. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarGroupCalendar`. May be used by Relay 1. */
  calendarGroupCalendarEdge?: Maybe<CalendarGroupCalendarsEdge>;
};


/** The output of our create `CalendarGroupCalendar` mutation. */
export type CreateCalendarGroupCalendarPayloadCalendarGroupCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
};

/** All input for the create `CalendarGroup` mutation. */
export type CreateCalendarGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroup` to be created by this mutation. */
  calendarGroup: CalendarGroupInput;
};

/** The output of our create `CalendarGroup` mutation. */
export type CreateCalendarGroupPayload = {
  __typename?: 'CreateCalendarGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroup` that was created by this mutation. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `CalendarGroup`. */
  school?: Maybe<School>;
  /** An edge for our `CalendarGroup`. May be used by Relay 1. */
  calendarGroupEdge?: Maybe<CalendarGroupsEdge>;
};


/** The output of our create `CalendarGroup` mutation. */
export type CreateCalendarGroupPayloadCalendarGroupEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
};

/** All input for the create `Calendar` mutation. */
export type CreateCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Calendar` to be created by this mutation. */
  calendar: CalendarInput;
};

/** The output of our create `Calendar` mutation. */
export type CreateCalendarPayload = {
  __typename?: 'CreateCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Calendar` that was created by this mutation. */
  calendar?: Maybe<Calendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Calendar`. */
  group?: Maybe<Group>;
  /** An edge for our `Calendar`. May be used by Relay 1. */
  calendarEdge?: Maybe<CalendarsEdge>;
};


/** The output of our create `Calendar` mutation. */
export type CreateCalendarPayloadCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
};

/** All input for the `createDeploymentRowsForSchool` mutation. */
export type CreateDeploymentRowsForSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vSchoolId: Scalars['UUID'];
};

/** The output of our `createDeploymentRowsForSchool` mutation. */
export type CreateDeploymentRowsForSchoolPayload = {
  __typename?: 'CreateDeploymentRowsForSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Division` mutation. */
export type CreateDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Division` to be created by this mutation. */
  division: DivisionInput;
};

/** The output of our create `Division` mutation. */
export type CreateDivisionPayload = {
  __typename?: 'CreateDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Division` that was created by this mutation. */
  division?: Maybe<Division>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Division`. */
  school?: Maybe<School>;
  /** Reads a single `Group` that is related to this `Division`. */
  group?: Maybe<Group>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge?: Maybe<DivisionsEdge>;
};


/** The output of our create `Division` mutation. */
export type CreateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
};

/** All input for the create `Event` mutation. */
export type CreateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` to be created by this mutation. */
  event: EventInput;
};

/** The output of our create `Event` mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was created by this mutation. */
  event?: Maybe<Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Event`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Event`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our create `Event` mutation. */
export type CreateEventPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  __typename?: 'CreateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was created by this mutation. */
  file?: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `File`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `File`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the create `GoogleAccount` mutation. */
export type CreateGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleAccount` to be created by this mutation. */
  googleAccount: GoogleAccountInput;
};

/** The output of our create `GoogleAccount` mutation. */
export type CreateGoogleAccountPayload = {
  __typename?: 'CreateGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleAccount` that was created by this mutation. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GoogleAccount`. May be used by Relay 1. */
  googleAccountEdge?: Maybe<GoogleAccountsEdge>;
};


/** The output of our create `GoogleAccount` mutation. */
export type CreateGoogleAccountPayloadGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
};

/** All input for the create `GoogleCalendarSyncCalendar` mutation. */
export type CreateGoogleCalendarSyncCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSyncCalendar` to be created by this mutation. */
  googleCalendarSyncCalendar: GoogleCalendarSyncCalendarInput;
};

/** The output of our create `GoogleCalendarSyncCalendar` mutation. */
export type CreateGoogleCalendarSyncCalendarPayload = {
  __typename?: 'CreateGoogleCalendarSyncCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSyncCalendar` that was created by this mutation. */
  googleCalendarSyncCalendar?: Maybe<GoogleCalendarSyncCalendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleCalendarSync` that is related to this `GoogleCalendarSyncCalendar`. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Reads a single `Calendar` that is related to this `GoogleCalendarSyncCalendar`. */
  calendar?: Maybe<Calendar>;
  /** An edge for our `GoogleCalendarSyncCalendar`. May be used by Relay 1. */
  googleCalendarSyncCalendarEdge?: Maybe<GoogleCalendarSyncCalendarsEdge>;
};


/** The output of our create `GoogleCalendarSyncCalendar` mutation. */
export type CreateGoogleCalendarSyncCalendarPayloadGoogleCalendarSyncCalendarEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
};

/** All input for the create `GoogleCalendarSync` mutation. */
export type CreateGoogleCalendarSyncInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSync` to be created by this mutation. */
  googleCalendarSync: GoogleCalendarSyncInput;
};

/** The output of our create `GoogleCalendarSync` mutation. */
export type CreateGoogleCalendarSyncPayload = {
  __typename?: 'CreateGoogleCalendarSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSync` that was created by this mutation. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleAccount` that is related to this `GoogleCalendarSync`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** An edge for our `GoogleCalendarSync`. May be used by Relay 1. */
  googleCalendarSyncEdge?: Maybe<GoogleCalendarSyncsEdge>;
};


/** The output of our create `GoogleCalendarSync` mutation. */
export type CreateGoogleCalendarSyncPayloadGoogleCalendarSyncEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
};

/** All input for the `createGoogleCalendarSyncWithCalendars` mutation. */
export type CreateGoogleCalendarSyncWithCalendarsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor: Scalars['String'];
  googleAccountId: Scalars['UUID'];
  calendarIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `createGoogleCalendarSyncWithCalendars` mutation. */
export type CreateGoogleCalendarSyncWithCalendarsPayload = {
  __typename?: 'CreateGoogleCalendarSyncWithCalendarsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleAccount` that is related to this `GoogleCalendarSync`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** An edge for our `GoogleCalendarSync`. May be used by Relay 1. */
  googleCalendarSyncEdge?: Maybe<GoogleCalendarSyncsEdge>;
};


/** The output of our `createGoogleCalendarSyncWithCalendars` mutation. */
export type CreateGoogleCalendarSyncWithCalendarsPayloadGoogleCalendarSyncEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
};

/** All input for the create `GooglePlayAccount` mutation. */
export type CreateGooglePlayAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayAccount` to be created by this mutation. */
  googlePlayAccount: GooglePlayAccountInput;
};

/** The output of our create `GooglePlayAccount` mutation. */
export type CreateGooglePlayAccountPayload = {
  __typename?: 'CreateGooglePlayAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayAccount` that was created by this mutation. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayUser` that is related to this `GooglePlayAccount`. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayAccount`. May be used by Relay 1. */
  googlePlayAccountEdge?: Maybe<GooglePlayAccountsEdge>;
};


/** The output of our create `GooglePlayAccount` mutation. */
export type CreateGooglePlayAccountPayloadGooglePlayAccountEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
};

/** All input for the create `GooglePlayListing` mutation. */
export type CreateGooglePlayListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayListing` to be created by this mutation. */
  googlePlayListing: GooglePlayListingInput;
};

/** The output of our create `GooglePlayListing` mutation. */
export type CreateGooglePlayListingPayload = {
  __typename?: 'CreateGooglePlayListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayListing` that was created by this mutation. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `GooglePlayListing`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `GooglePlayAccount` that is related to this `GooglePlayListing`. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayListing`. May be used by Relay 1. */
  googlePlayListingEdge?: Maybe<GooglePlayListingsEdge>;
};


/** The output of our create `GooglePlayListing` mutation. */
export type CreateGooglePlayListingPayloadGooglePlayListingEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
};

/** All input for the create `GooglePlayUser` mutation. */
export type CreateGooglePlayUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayUser` to be created by this mutation. */
  googlePlayUser: GooglePlayUserInput;
};

/** The output of our create `GooglePlayUser` mutation. */
export type CreateGooglePlayUserPayload = {
  __typename?: 'CreateGooglePlayUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayUser` that was created by this mutation. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayUser`. May be used by Relay 1. */
  googlePlayUserEdge?: Maybe<GooglePlayUsersEdge>;
};


/** The output of our create `GooglePlayUser` mutation. */
export type CreateGooglePlayUserPayloadGooglePlayUserEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
};

/** All input for the create `GroupCategory` mutation. */
export type CreateGroupCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupCategory` to be created by this mutation. */
  groupCategory: GroupCategoryInput;
};

/** The output of our create `GroupCategory` mutation. */
export type CreateGroupCategoryPayload = {
  __typename?: 'CreateGroupCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupCategory` that was created by this mutation. */
  groupCategory?: Maybe<GroupCategory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Division` that is related to this `GroupCategory`. */
  division?: Maybe<Division>;
  /** An edge for our `GroupCategory`. May be used by Relay 1. */
  groupCategoryEdge?: Maybe<GroupCategoriesEdge>;
};


/** The output of our create `GroupCategory` mutation. */
export type CreateGroupCategoryPayloadGroupCategoryEdgeArgs = {
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
};

/** All input for the create `Group` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` to be created by this mutation. */
  group: GroupInput;
};

/** All input for the create `GroupNewsletter` mutation. */
export type CreateGroupNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupNewsletter` to be created by this mutation. */
  groupNewsletter: GroupNewsletterInput;
};

/** The output of our create `GroupNewsletter` mutation. */
export type CreateGroupNewsletterPayload = {
  __typename?: 'CreateGroupNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupNewsletter` that was created by this mutation. */
  groupNewsletter?: Maybe<GroupNewsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupNewsletter`. */
  group?: Maybe<Group>;
  /** Reads a single `Newsletter` that is related to this `GroupNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupNewsletter`. May be used by Relay 1. */
  groupNewsletterEdge?: Maybe<GroupNewslettersEdge>;
};


/** The output of our create `GroupNewsletter` mutation. */
export type CreateGroupNewsletterPayloadGroupNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
};

/** The output of our create `Group` mutation. */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was created by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GroupCategory` that is related to this `Group`. */
  groupCategory?: Maybe<GroupCategory>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our create `Group` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the create `GroupPerson` mutation. */
export type CreateGroupPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupPerson` to be created by this mutation. */
  groupPerson: GroupPersonInput;
};

/** The output of our create `GroupPerson` mutation. */
export type CreateGroupPersonPayload = {
  __typename?: 'CreateGroupPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupPerson` that was created by this mutation. */
  groupPerson?: Maybe<GroupPerson>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupPerson`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupPerson`. May be used by Relay 1. */
  groupPersonEdge?: Maybe<GroupPeopleEdge>;
};


/** The output of our create `GroupPerson` mutation. */
export type CreateGroupPersonPayloadGroupPersonEdgeArgs = {
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
};

/** All input for the create `IosApp` mutation. */
export type CreateIosAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosApp` to be created by this mutation. */
  iosApp: IosAppInput;
};

/** The output of our create `IosApp` mutation. */
export type CreateIosAppPayload = {
  __typename?: 'CreateIosAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosApp` that was created by this mutation. */
  iosApp?: Maybe<IosApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `IosApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosApp`. May be used by Relay 1. */
  iosAppEdge?: Maybe<IosAppsEdge>;
};


/** The output of our create `IosApp` mutation. */
export type CreateIosAppPayloadIosAppEdgeArgs = {
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
};

/** All input for the create `IosScreenshot` mutation. */
export type CreateIosScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosScreenshot` to be created by this mutation. */
  iosScreenshot: IosScreenshotInput;
};

/** The output of our create `IosScreenshot` mutation. */
export type CreateIosScreenshotPayload = {
  __typename?: 'CreateIosScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosScreenshot` that was created by this mutation. */
  iosScreenshot?: Maybe<IosScreenshot>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreListing` that is related to this `IosScreenshot`. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosScreenshot`. May be used by Relay 1. */
  iosScreenshotEdge?: Maybe<IosScreenshotsEdge>;
};


/** The output of our create `IosScreenshot` mutation. */
export type CreateIosScreenshotPayloadIosScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
};

/** All input for the create `Marquee` mutation. */
export type CreateMarqueeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Marquee` to be created by this mutation. */
  marquee: MarqueeInput;
};

/** The output of our create `Marquee` mutation. */
export type CreateMarqueePayload = {
  __typename?: 'CreateMarqueePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Marquee` that was created by this mutation. */
  marquee?: Maybe<Marquee>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Marquee`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Marquee`. */
  marqueeImage?: Maybe<File>;
  /** An edge for our `Marquee`. May be used by Relay 1. */
  marqueeEdge?: Maybe<MarqueesEdge>;
};


/** The output of our create `Marquee` mutation. */
export type CreateMarqueePayloadMarqueeEdgeArgs = {
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
};

/** All input for the `createNewAndroidJavaKeyStore` mutation. */
export type CreateNewAndroidJavaKeyStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAndroidAppId: Scalars['UUID'];
  vKeyAlias: Scalars['String'];
  vKeyPass: Scalars['String'];
  vStorePass: Scalars['String'];
};

/** The output of our `createNewAndroidJavaKeyStore` mutation. */
export type CreateNewAndroidJavaKeyStorePayload = {
  __typename?: 'CreateNewAndroidJavaKeyStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidJavaKeyStore`. May be used by Relay 1. */
  androidJavaKeyStoreEdge?: Maybe<AndroidJavaKeyStoresEdge>;
};


/** The output of our `createNewAndroidJavaKeyStore` mutation. */
export type CreateNewAndroidJavaKeyStorePayloadAndroidJavaKeyStoreEdgeArgs = {
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
};

/** All input for the `createNewAppStoreUser` mutation. */
export type CreateNewAppStoreUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vEmail: Scalars['String'];
  vPassword: Scalars['String'];
  vAppSpecificPassword: Scalars['String'];
};

/** The output of our `createNewAppStoreUser` mutation. */
export type CreateNewAppStoreUserPayload = {
  __typename?: 'CreateNewAppStoreUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createNewBuildForAppOnPlatform` mutation. */
export type CreateNewBuildForAppOnPlatformInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAppId: Scalars['UUID'];
  vPlatform: PlatformType;
};

/** The output of our `createNewBuildForAppOnPlatform` mutation. */
export type CreateNewBuildForAppOnPlatformPayload = {
  __typename?: 'CreateNewBuildForAppOnPlatformPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createNewGooglePlayUser` mutation. */
export type CreateNewGooglePlayUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vEmail: Scalars['String'];
  vPassword: Scalars['String'];
};

/** The output of our `createNewGooglePlayUser` mutation. */
export type CreateNewGooglePlayUserPayload = {
  __typename?: 'CreateNewGooglePlayUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createNewReleaseForPlatform` mutation. */
export type CreateNewReleaseForPlatformInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vReleasePlatform: PlatformType;
  vReleaseType: ReleaseType;
  vInternalReleaseNotes: Scalars['String'];
  vExternalReleaseNotes: Scalars['String'];
};

/** The output of our `createNewReleaseForPlatform` mutation. */
export type CreateNewReleaseForPlatformPayload = {
  __typename?: 'CreateNewReleaseForPlatformPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  release?: Maybe<Release>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Release`. May be used by Relay 1. */
  releaseEdge?: Maybe<ReleasesEdge>;
};


/** The output of our `createNewReleaseForPlatform` mutation. */
export type CreateNewReleaseForPlatformPayloadReleaseEdgeArgs = {
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
};

/** All input for the create `Newsletter` mutation. */
export type CreateNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Newsletter` to be created by this mutation. */
  newsletter: NewsletterInput;
};

/** The output of our create `Newsletter` mutation. */
export type CreateNewsletterPayload = {
  __typename?: 'CreateNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Newsletter` that was created by this mutation. */
  newsletter?: Maybe<Newsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** An edge for our `Newsletter`. May be used by Relay 1. */
  newsletterEdge?: Maybe<NewslettersEdge>;
};


/** The output of our create `Newsletter` mutation. */
export type CreateNewsletterPayloadNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
};

/** All input for the create `NotificationFile` mutation. */
export type CreateNotificationFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationFile` to be created by this mutation. */
  notificationFile: NotificationFileInput;
};

/** The output of our create `NotificationFile` mutation. */
export type CreateNotificationFilePayload = {
  __typename?: 'CreateNotificationFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationFile` that was created by this mutation. */
  notificationFile?: Maybe<NotificationFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationFile`. */
  notification?: Maybe<Notification>;
  /** Reads a single `File` that is related to this `NotificationFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationFile`. May be used by Relay 1. */
  notificationFileEdge?: Maybe<NotificationFilesEdge>;
};


/** The output of our create `NotificationFile` mutation. */
export type CreateNotificationFilePayloadNotificationFileEdgeArgs = {
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
};

/** All input for the create `NotificationGroup` mutation. */
export type CreateNotificationGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationGroup` to be created by this mutation. */
  notificationGroup: NotificationGroupInput;
};

/** The output of our create `NotificationGroup` mutation. */
export type CreateNotificationGroupPayload = {
  __typename?: 'CreateNotificationGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationGroup` that was created by this mutation. */
  notificationGroup?: Maybe<NotificationGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationGroup`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Group` that is related to this `NotificationGroup`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationGroup`. May be used by Relay 1. */
  notificationGroupEdge?: Maybe<NotificationGroupsEdge>;
};


/** The output of our create `NotificationGroup` mutation. */
export type CreateNotificationGroupPayloadNotificationGroupEdgeArgs = {
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
};

/** All input for the create `Notification` mutation. */
export type CreateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Notification` to be created by this mutation. */
  notification: NotificationInput;
};

/** All input for the create `NotificationNewsletter` mutation. */
export type CreateNotificationNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationNewsletter` to be created by this mutation. */
  notificationNewsletter: NotificationNewsletterInput;
};

/** The output of our create `NotificationNewsletter` mutation. */
export type CreateNotificationNewsletterPayload = {
  __typename?: 'CreateNotificationNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationNewsletter` that was created by this mutation. */
  notificationNewsletter?: Maybe<NotificationNewsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationNewsletter`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Newsletter` that is related to this `NotificationNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationNewsletter`. May be used by Relay 1. */
  notificationNewsletterEdge?: Maybe<NotificationNewslettersEdge>;
};


/** The output of our create `NotificationNewsletter` mutation. */
export type CreateNotificationNewsletterPayloadNotificationNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
};

/** The output of our create `Notification` mutation. */
export type CreateNotificationPayload = {
  __typename?: 'CreateNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Notification` that was created by this mutation. */
  notification?: Maybe<Notification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Notification` that is related to this `Notification`. */
  notificationByRecurringParent?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
};


/** The output of our create `Notification` mutation. */
export type CreateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
};

/** All input for the `createOrUpdateCalendarGroup` mutation. */
export type CreateOrUpdateCalendarGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  calendarIds: Array<Maybe<Scalars['UUID']>>;
  calendarGroupSchoolId: Scalars['UUID'];
  calendarGroupTitle: Scalars['String'];
  calendarGroupSortOrder: Scalars['Int'];
  calendarGroupId?: Maybe<Scalars['UUID']>;
};

/** The output of our `createOrUpdateCalendarGroup` mutation. */
export type CreateOrUpdateCalendarGroupPayload = {
  __typename?: 'CreateOrUpdateCalendarGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  calendarGroup?: Maybe<CalendarGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `CalendarGroup`. */
  school?: Maybe<School>;
  /** An edge for our `CalendarGroup`. May be used by Relay 1. */
  calendarGroupEdge?: Maybe<CalendarGroupsEdge>;
};


/** The output of our `createOrUpdateCalendarGroup` mutation. */
export type CreateOrUpdateCalendarGroupPayloadCalendarGroupEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
};

/** All input for the `createOrUpdateEventForCalendars` mutation. */
export type CreateOrUpdateEventForCalendarsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  calendarIds: Array<Maybe<Scalars['UUID']>>;
  eventTitle: Scalars['String'];
  eventStartDate: Scalars['Datetime'];
  eventEndDate: Scalars['Datetime'];
  eventLocationString?: Maybe<Scalars['String']>;
  eventInformation?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['UUID']>;
  eventButtons?: Maybe<Scalars['JSON']>;
};

/** The output of our `createOrUpdateEventForCalendars` mutation. */
export type CreateOrUpdateEventForCalendarsPayload = {
  __typename?: 'CreateOrUpdateEventForCalendarsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Event`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Event`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our `createOrUpdateEventForCalendars` mutation. */
export type CreateOrUpdateEventForCalendarsPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the `createOrUpdateGoogleAccount` mutation. */
export type CreateOrUpdateGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  gaUserId: Scalars['UUID'];
  gaEmail: Scalars['String'];
  gaAccountId: Scalars['String'];
  gaRefreshToken: Scalars['String'];
  gaTokenExpiry: Scalars['BigInt'];
  gaPictureUrl?: Maybe<Scalars['String']>;
};

/** The output of our `createOrUpdateGoogleAccount` mutation. */
export type CreateOrUpdateGoogleAccountPayload = {
  __typename?: 'CreateOrUpdateGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleAccount?: Maybe<GoogleAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GoogleAccount`. May be used by Relay 1. */
  googleAccountEdge?: Maybe<GoogleAccountsEdge>;
};


/** The output of our `createOrUpdateGoogleAccount` mutation. */
export type CreateOrUpdateGoogleAccountPayloadGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
};

/** All input for the `createOrUpdateNotification` mutation. */
export type CreateOrUpdateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationGroupIds: Array<Maybe<Scalars['UUID']>>;
  notificationUserTypes: Array<Maybe<UserType>>;
  notificationMessage: Scalars['String'];
  notificationDelivery: NotificationDelivery;
  notificationBody?: Maybe<Scalars['String']>;
  notificationDraft?: Maybe<Scalars['Boolean']>;
  notificationSendDate?: Maybe<Scalars['Datetime']>;
  notificationRecurringCron?: Maybe<Scalars['String']>;
  notificationRecurringEnabled?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['UUID']>;
  notificationButtons?: Maybe<Scalars['JSON']>;
  notificationEmbedUrl?: Maybe<Scalars['String']>;
};

/** The output of our `createOrUpdateNotification` mutation. */
export type CreateOrUpdateNotificationPayload = {
  __typename?: 'CreateOrUpdateNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  notification?: Maybe<Notification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Notification` that is related to this `Notification`. */
  notificationByRecurringParent?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
};


/** The output of our `createOrUpdateNotification` mutation. */
export type CreateOrUpdateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
};

/** All input for the create `Page` mutation. */
export type CreatePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` to be created by this mutation. */
  page: PageInput;
};

/** The output of our create `Page` mutation. */
export type CreatePagePayload = {
  __typename?: 'CreatePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was created by this mutation. */
  page?: Maybe<Page>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Page`. May be used by Relay 1. */
  pageEdge?: Maybe<PagesEdge>;
};


/** The output of our create `Page` mutation. */
export type CreatePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<Array<PagesOrderBy>>;
};

/** All input for the create `PageSectionFile` mutation. */
export type CreatePageSectionFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSectionFile` to be created by this mutation. */
  pageSectionFile: PageSectionFileInput;
};

/** The output of our create `PageSectionFile` mutation. */
export type CreatePageSectionFilePayload = {
  __typename?: 'CreatePageSectionFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSectionFile` that was created by this mutation. */
  pageSectionFile?: Maybe<PageSectionFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PageSection` that is related to this `PageSectionFile`. */
  pageSection?: Maybe<PageSection>;
  /** Reads a single `File` that is related to this `PageSectionFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `PageSectionFile`. May be used by Relay 1. */
  pageSectionFileEdge?: Maybe<PageSectionFilesEdge>;
};


/** The output of our create `PageSectionFile` mutation. */
export type CreatePageSectionFilePayloadPageSectionFileEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
};

/** All input for the create `PageSection` mutation. */
export type CreatePageSectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSection` to be created by this mutation. */
  pageSection: PageSectionInput;
};

/** The output of our create `PageSection` mutation. */
export type CreatePageSectionPayload = {
  __typename?: 'CreatePageSectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSection` that was created by this mutation. */
  pageSection?: Maybe<PageSection>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `PageSection`. */
  page?: Maybe<Page>;
  /** An edge for our `PageSection`. May be used by Relay 1. */
  pageSectionEdge?: Maybe<PageSectionsEdge>;
};


/** The output of our create `PageSection` mutation. */
export type CreatePageSectionPayloadPageSectionEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
};

/** All input for the create `Person` mutation. */
export type CreatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` to be created by this mutation. */
  person: PersonInput;
};

/** The output of our create `Person` mutation. */
export type CreatePersonPayload = {
  __typename?: 'CreatePersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` that was created by this mutation. */
  person?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Person`. */
  profileImage?: Maybe<File>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
};


/** The output of our create `Person` mutation. */
export type CreatePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>;
};

/** All input for the create `Release` mutation. */
export type CreateReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Release` to be created by this mutation. */
  release: ReleaseInput;
};

/** The output of our create `Release` mutation. */
export type CreateReleasePayload = {
  __typename?: 'CreateReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Release` that was created by this mutation. */
  release?: Maybe<Release>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Release`. May be used by Relay 1. */
  releaseEdge?: Maybe<ReleasesEdge>;
};


/** The output of our create `Release` mutation. */
export type CreateReleasePayloadReleaseEdgeArgs = {
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
};

/** All input for the create `ResourceFile` mutation. */
export type CreateResourceFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ResourceFile` to be created by this mutation. */
  resourceFile: ResourceFileInput;
};

/** The output of our create `ResourceFile` mutation. */
export type CreateResourceFilePayload = {
  __typename?: 'CreateResourceFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ResourceFile` that was created by this mutation. */
  resourceFile?: Maybe<ResourceFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Resource` that is related to this `ResourceFile`. */
  resource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `ResourceFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `ResourceFile`. May be used by Relay 1. */
  resourceFileEdge?: Maybe<ResourceFilesEdge>;
};


/** The output of our create `ResourceFile` mutation. */
export type CreateResourceFilePayloadResourceFileEdgeArgs = {
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
};

/** All input for the create `Resource` mutation. */
export type CreateResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Resource` to be created by this mutation. */
  resource: ResourceInput;
};

/** The output of our create `Resource` mutation. */
export type CreateResourcePayload = {
  __typename?: 'CreateResourcePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Resource` that was created by this mutation. */
  resource?: Maybe<Resource>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Resource`. */
  group?: Maybe<Group>;
  /** Reads a single `Resource` that is related to this `Resource`. */
  resourceByParentResource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `Resource`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  tileImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultCoverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultThumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};


/** The output of our create `Resource` mutation. */
export type CreateResourcePayloadResourceEdgeArgs = {
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
};

/** All input for the create `School` mutation. */
export type CreateSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `School` to be created by this mutation. */
  school: SchoolInput;
};

/** The output of our create `School` mutation. */
export type CreateSchoolPayload = {
  __typename?: 'CreateSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `School` that was created by this mutation. */
  school?: Maybe<School>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `School`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `School`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Theme` that is related to this `School`. */
  theme?: Maybe<Theme>;
  /** An edge for our `School`. May be used by Relay 1. */
  schoolEdge?: Maybe<SchoolsEdge>;
};


/** The output of our create `School` mutation. */
export type CreateSchoolPayloadSchoolEdgeArgs = {
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
};

/** All input for the create `SchoolPerson` mutation. */
export type CreateSchoolPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPerson` to be created by this mutation. */
  schoolPerson: SchoolPersonInput;
};

/** All input for the create `SchoolPersonInstallation` mutation. */
export type CreateSchoolPersonInstallationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInstallation` to be created by this mutation. */
  schoolPersonInstallation: SchoolPersonInstallationInput;
};

/** The output of our create `SchoolPersonInstallation` mutation. */
export type CreateSchoolPersonInstallationPayload = {
  __typename?: 'CreateSchoolPersonInstallationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInstallation` that was created by this mutation. */
  schoolPersonInstallation?: Maybe<SchoolPersonInstallation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInstallation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInstallation`. May be used by Relay 1. */
  schoolPersonInstallationEdge?: Maybe<SchoolPersonInstallationsEdge>;
};


/** The output of our create `SchoolPersonInstallation` mutation. */
export type CreateSchoolPersonInstallationPayloadSchoolPersonInstallationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
};

/** All input for the create `SchoolPersonInvitation` mutation. */
export type CreateSchoolPersonInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInvitation` to be created by this mutation. */
  schoolPersonInvitation: SchoolPersonInvitationInput;
};

/** The output of our create `SchoolPersonInvitation` mutation. */
export type CreateSchoolPersonInvitationPayload = {
  __typename?: 'CreateSchoolPersonInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInvitation` that was created by this mutation. */
  schoolPersonInvitation?: Maybe<SchoolPersonInvitation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInvitation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInvitation`. May be used by Relay 1. */
  schoolPersonInvitationEdge?: Maybe<SchoolPersonInvitationsEdge>;
};


/** The output of our create `SchoolPersonInvitation` mutation. */
export type CreateSchoolPersonInvitationPayloadSchoolPersonInvitationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
};

/** The output of our create `SchoolPerson` mutation. */
export type CreateSchoolPersonPayload = {
  __typename?: 'CreateSchoolPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPerson` that was created by this mutation. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolPerson`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPerson`. May be used by Relay 1. */
  schoolPersonEdge?: Maybe<SchoolPeopleEdge>;
};


/** The output of our create `SchoolPerson` mutation. */
export type CreateSchoolPersonPayloadSchoolPersonEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
};

/** All input for the create `SchoolSetting` mutation. */
export type CreateSchoolSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolSetting` to be created by this mutation. */
  schoolSetting: SchoolSettingInput;
};

/** The output of our create `SchoolSetting` mutation. */
export type CreateSchoolSettingPayload = {
  __typename?: 'CreateSchoolSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolSetting` that was created by this mutation. */
  schoolSetting?: Maybe<SchoolSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolSetting`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  schoolImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  mascotImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  launchScreenPoweredByImage?: Maybe<File>;
  /** An edge for our `SchoolSetting`. May be used by Relay 1. */
  schoolSettingEdge?: Maybe<SchoolSettingsEdge>;
};


/** The output of our create `SchoolSetting` mutation. */
export type CreateSchoolSettingPayloadSchoolSettingEdgeArgs = {
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
};

/** All input for the create `Sponsor` mutation. */
export type CreateSponsorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Sponsor` to be created by this mutation. */
  sponsor: SponsorInput;
};

/** The output of our create `Sponsor` mutation. */
export type CreateSponsorPayload = {
  __typename?: 'CreateSponsorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Sponsor` that was created by this mutation. */
  sponsor?: Maybe<Sponsor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Sponsor`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  promoImage?: Maybe<File>;
  /** An edge for our `Sponsor`. May be used by Relay 1. */
  sponsorEdge?: Maybe<SponsorsEdge>;
};


/** The output of our create `Sponsor` mutation. */
export type CreateSponsorPayloadSponsorEdgeArgs = {
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
};

/** All input for the create `Theme` mutation. */
export type CreateThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Theme` to be created by this mutation. */
  theme: ThemeInput;
};

/** The output of our create `Theme` mutation. */
export type CreateThemePayload = {
  __typename?: 'CreateThemePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Theme` that was created by this mutation. */
  theme?: Maybe<Theme>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge?: Maybe<ThemesEdge>;
};


/** The output of our create `Theme` mutation. */
export type CreateThemePayloadThemeEdgeArgs = {
  orderBy?: Maybe<Array<ThemesOrderBy>>;
};

/** All input for the create `UserGoogleAccount` mutation. */
export type CreateUserGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserGoogleAccount` to be created by this mutation. */
  userGoogleAccount: UserGoogleAccountInput;
};

/** The output of our create `UserGoogleAccount` mutation. */
export type CreateUserGoogleAccountPayload = {
  __typename?: 'CreateUserGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserGoogleAccount` that was created by this mutation. */
  userGoogleAccount?: Maybe<UserGoogleAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GoogleAccount` that is related to this `UserGoogleAccount`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `UserGoogleAccount`. May be used by Relay 1. */
  userGoogleAccountEdge?: Maybe<UserGoogleAccountsEdge>;
};


/** The output of our create `UserGoogleAccount` mutation. */
export type CreateUserGoogleAccountPayloadUserGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
};

/** All input for the create `UserSession` mutation. */
export type CreateUserSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSession` to be created by this mutation. */
  userSession: UserSessionInput;
};

/** The output of our create `UserSession` mutation. */
export type CreateUserSessionPayload = {
  __typename?: 'CreateUserSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSession` that was created by this mutation. */
  userSession?: Maybe<UserSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserSession`. May be used by Relay 1. */
  userSessionEdge?: Maybe<UserSessionsEdge>;
};


/** The output of our create `UserSession` mutation. */
export type CreateUserSessionPayloadUserSessionEdgeArgs = {
  orderBy?: Maybe<Array<UserSessionsOrderBy>>;
};



/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteAndroidAppByAppId` mutation. */
export type DeleteAndroidAppByAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** All input for the `deleteAndroidAppByNodeId` mutation. */
export type DeleteAndroidAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAndroidApp` mutation. */
export type DeleteAndroidAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The output of our delete `AndroidApp` mutation. */
export type DeleteAndroidAppPayload = {
  __typename?: 'DeleteAndroidAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidApp` that was deleted by this mutation. */
  androidApp?: Maybe<AndroidApp>;
  deletedAndroidAppNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `AndroidApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidApp`. May be used by Relay 1. */
  androidAppEdge?: Maybe<AndroidAppsEdge>;
};


/** The output of our delete `AndroidApp` mutation. */
export type DeleteAndroidAppPayloadAndroidAppEdgeArgs = {
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
};

/** All input for the `deleteAndroidJavaKeyStoreByNodeId` mutation. */
export type DeleteAndroidJavaKeyStoreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidJavaKeyStore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAndroidJavaKeyStore` mutation. */
export type DeleteAndroidJavaKeyStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** The output of our delete `AndroidJavaKeyStore` mutation. */
export type DeleteAndroidJavaKeyStorePayload = {
  __typename?: 'DeleteAndroidJavaKeyStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidJavaKeyStore` that was deleted by this mutation. */
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  deletedAndroidJavaKeyStoreNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidJavaKeyStore`. May be used by Relay 1. */
  androidJavaKeyStoreEdge?: Maybe<AndroidJavaKeyStoresEdge>;
};


/** The output of our delete `AndroidJavaKeyStore` mutation. */
export type DeleteAndroidJavaKeyStorePayloadAndroidJavaKeyStoreEdgeArgs = {
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
};

/** All input for the `deleteAndroidScreenshotByNodeId` mutation. */
export type DeleteAndroidScreenshotByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidScreenshot` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAndroidScreenshot` mutation. */
export type DeleteAndroidScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** The output of our delete `AndroidScreenshot` mutation. */
export type DeleteAndroidScreenshotPayload = {
  __typename?: 'DeleteAndroidScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidScreenshot` that was deleted by this mutation. */
  androidScreenshot?: Maybe<AndroidScreenshot>;
  deletedAndroidScreenshotNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayListing` that is related to this `AndroidScreenshot`. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidScreenshot`. May be used by Relay 1. */
  androidScreenshotEdge?: Maybe<AndroidScreenshotsEdge>;
};


/** The output of our delete `AndroidScreenshot` mutation. */
export type DeleteAndroidScreenshotPayloadAndroidScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
};

/** All input for the `deleteAppByNodeId` mutation. */
export type DeleteAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `App` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppBySchoolId` mutation. */
export type DeleteAppBySchoolIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** All input for the `deleteApp` mutation. */
export type DeleteAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The output of our delete `App` mutation. */
export type DeleteAppPayload = {
  __typename?: 'DeleteAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `App` that was deleted by this mutation. */
  app?: Maybe<App>;
  deletedAppNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `App`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `App`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `App`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `App`. May be used by Relay 1. */
  appEdge?: Maybe<AppsEdge>;
};


/** The output of our delete `App` mutation. */
export type DeleteAppPayloadAppEdgeArgs = {
  orderBy?: Maybe<Array<AppsOrderBy>>;
};

/** All input for the `deleteAppStoreAccountByAppStoreConnectId` mutation. */
export type DeleteAppStoreAccountByAppStoreConnectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** All input for the `deleteAppStoreAccountByAppleDeveloperAccountId` mutation. */
export type DeleteAppStoreAccountByAppleDeveloperAccountIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** All input for the `deleteAppStoreAccountByNodeId` mutation. */
export type DeleteAppStoreAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppStoreAccountByOrganizationName` mutation. */
export type DeleteAppStoreAccountByOrganizationNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** All input for the `deleteAppStoreAccount` mutation. */
export type DeleteAppStoreAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The output of our delete `AppStoreAccount` mutation. */
export type DeleteAppStoreAccountPayload = {
  __typename?: 'DeleteAppStoreAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreAccount` that was deleted by this mutation. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  deletedAppStoreAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreUser` that is related to this `AppStoreAccount`. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreAccount`. May be used by Relay 1. */
  appStoreAccountEdge?: Maybe<AppStoreAccountsEdge>;
};


/** The output of our delete `AppStoreAccount` mutation. */
export type DeleteAppStoreAccountPayloadAppStoreAccountEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
};

/** All input for the `deleteAppStoreListingByIosAppId` mutation. */
export type DeleteAppStoreListingByIosAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** All input for the `deleteAppStoreListingByNodeId` mutation. */
export type DeleteAppStoreListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppStoreListing` mutation. */
export type DeleteAppStoreListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The output of our delete `AppStoreListing` mutation. */
export type DeleteAppStoreListingPayload = {
  __typename?: 'DeleteAppStoreListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreListing` that was deleted by this mutation. */
  appStoreListing?: Maybe<AppStoreListing>;
  deletedAppStoreListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IosApp` that is related to this `AppStoreListing`. */
  iosApp?: Maybe<IosApp>;
  /** Reads a single `AppStoreAccount` that is related to this `AppStoreListing`. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreListing`. May be used by Relay 1. */
  appStoreListingEdge?: Maybe<AppStoreListingsEdge>;
};


/** The output of our delete `AppStoreListing` mutation. */
export type DeleteAppStoreListingPayloadAppStoreListingEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
};

/** All input for the `deleteAppStoreUserByEmail` mutation. */
export type DeleteAppStoreUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** All input for the `deleteAppStoreUserByNodeId` mutation. */
export type DeleteAppStoreUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppStoreUser` mutation. */
export type DeleteAppStoreUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** The output of our delete `AppStoreUser` mutation. */
export type DeleteAppStoreUserPayload = {
  __typename?: 'DeleteAppStoreUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreUser` that was deleted by this mutation. */
  appStoreUser?: Maybe<AppStoreUser>;
  deletedAppStoreUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreUser`. May be used by Relay 1. */
  appStoreUserEdge?: Maybe<AppStoreUsersEdge>;
};


/** The output of our delete `AppStoreUser` mutation. */
export type DeleteAppStoreUserPayloadAppStoreUserEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
};

/** All input for the `deleteBuildByNodeId` mutation. */
export type DeleteBuildByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Build` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBuild` mutation. */
export type DeleteBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The output of our delete `Build` mutation. */
export type DeleteBuildPayload = {
  __typename?: 'DeleteBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Build` that was deleted by this mutation. */
  build?: Maybe<Build>;
  deletedBuildNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `Build`. */
  app?: Maybe<App>;
  /** Reads a single `Release` that is related to this `Build`. */
  release?: Maybe<Release>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Build`. May be used by Relay 1. */
  buildEdge?: Maybe<BuildsEdge>;
};


/** The output of our delete `Build` mutation. */
export type DeleteBuildPayloadBuildEdgeArgs = {
  orderBy?: Maybe<Array<BuildsOrderBy>>;
};

/** All input for the `deleteBuildStepByNodeId` mutation. */
export type DeleteBuildStepByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuildStep` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBuildStep` mutation. */
export type DeleteBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** The output of our delete `BuildStep` mutation. */
export type DeleteBuildStepPayload = {
  __typename?: 'DeleteBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BuildStep` that was deleted by this mutation. */
  buildStep?: Maybe<BuildStep>;
  deletedBuildStepNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Build` that is related to this `BuildStep`. */
  build?: Maybe<Build>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `BuildStep`. May be used by Relay 1. */
  buildStepEdge?: Maybe<BuildStepsEdge>;
};


/** The output of our delete `BuildStep` mutation. */
export type DeleteBuildStepPayloadBuildStepEdgeArgs = {
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
};

/** All input for the `deleteCalendarByNodeId` mutation. */
export type DeleteCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Calendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCalendarEventByEventIdAndCalendarId` mutation. */
export type DeleteCalendarEventByEventIdAndCalendarIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** All input for the `deleteCalendarEventByNodeId` mutation. */
export type DeleteCalendarEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCalendarEvent` mutation. */
export type DeleteCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `CalendarEvent` mutation. */
export type DeleteCalendarEventPayload = {
  __typename?: 'DeleteCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarEvent` that was deleted by this mutation. */
  calendarEvent?: Maybe<CalendarEvent>;
  deletedCalendarEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarEvent`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `Event` that is related to this `CalendarEvent`. */
  event?: Maybe<Event>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarEvent`. May be used by Relay 1. */
  calendarEventEdge?: Maybe<CalendarEventsEdge>;
};


/** The output of our delete `CalendarEvent` mutation. */
export type DeleteCalendarEventPayloadCalendarEventEdgeArgs = {
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
};

/** All input for the `deleteCalendarGroupByNodeId` mutation. */
export type DeleteCalendarGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCalendarGroupCalendarByCalendarIdAndCalendarGroupId` mutation. */
export type DeleteCalendarGroupCalendarByCalendarIdAndCalendarGroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** All input for the `deleteCalendarGroupCalendarByNodeId` mutation. */
export type DeleteCalendarGroupCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarGroupCalendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCalendarGroupCalendar` mutation. */
export type DeleteCalendarGroupCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `CalendarGroupCalendar` mutation. */
export type DeleteCalendarGroupCalendarPayload = {
  __typename?: 'DeleteCalendarGroupCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroupCalendar` that was deleted by this mutation. */
  calendarGroupCalendar?: Maybe<CalendarGroupCalendar>;
  deletedCalendarGroupCalendarNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarGroupCalendar`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `CalendarGroup` that is related to this `CalendarGroupCalendar`. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarGroupCalendar`. May be used by Relay 1. */
  calendarGroupCalendarEdge?: Maybe<CalendarGroupCalendarsEdge>;
};


/** The output of our delete `CalendarGroupCalendar` mutation. */
export type DeleteCalendarGroupCalendarPayloadCalendarGroupCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
};

/** All input for the `deleteCalendarGroup` mutation. */
export type DeleteCalendarGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `CalendarGroup` mutation. */
export type DeleteCalendarGroupPayload = {
  __typename?: 'DeleteCalendarGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroup` that was deleted by this mutation. */
  calendarGroup?: Maybe<CalendarGroup>;
  deletedCalendarGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `CalendarGroup`. */
  school?: Maybe<School>;
  /** An edge for our `CalendarGroup`. May be used by Relay 1. */
  calendarGroupEdge?: Maybe<CalendarGroupsEdge>;
};


/** The output of our delete `CalendarGroup` mutation. */
export type DeleteCalendarGroupPayloadCalendarGroupEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
};

/** All input for the `deleteCalendar` mutation. */
export type DeleteCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Calendar` mutation. */
export type DeleteCalendarPayload = {
  __typename?: 'DeleteCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Calendar` that was deleted by this mutation. */
  calendar?: Maybe<Calendar>;
  deletedCalendarNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Calendar`. */
  group?: Maybe<Group>;
  /** An edge for our `Calendar`. May be used by Relay 1. */
  calendarEdge?: Maybe<CalendarsEdge>;
};


/** The output of our delete `Calendar` mutation. */
export type DeleteCalendarPayloadCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
};

/** All input for the `deleteDivisionByNodeId` mutation. */
export type DeleteDivisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Division` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDivision` mutation. */
export type DeleteDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Division` mutation. */
export type DeleteDivisionPayload = {
  __typename?: 'DeleteDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Division` that was deleted by this mutation. */
  division?: Maybe<Division>;
  deletedDivisionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Division`. */
  school?: Maybe<School>;
  /** Reads a single `Group` that is related to this `Division`. */
  group?: Maybe<Group>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge?: Maybe<DivisionsEdge>;
};


/** The output of our delete `Division` mutation. */
export type DeleteDivisionPayloadDivisionEdgeArgs = {
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
};

/** All input for the `deleteEventByGoogleId` mutation. */
export type DeleteEventByGoogleIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleId: Scalars['String'];
};

/** All input for the `deleteEventByNodeId` mutation. */
export type DeleteEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEventBySlug` mutation. */
export type DeleteEventBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteEvent` mutation. */
export type DeleteEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was deleted by this mutation. */
  event?: Maybe<Event>;
  deletedEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Event`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Event`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our delete `Event` mutation. */
export type DeleteEventPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteFileByNodeId` mutation. */
export type DeleteFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFileIfPossible` mutation. */
export type DeleteFileIfPossibleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fileId: Scalars['UUID'];
};

/** The output of our `deleteFileIfPossible` mutation. */
export type DeleteFileIfPossiblePayload = {
  __typename?: 'DeleteFileIfPossiblePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was deleted by this mutation. */
  file?: Maybe<File>;
  deletedFileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `File`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `File`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteGcs` mutation. */
export type DeleteGcsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  gcsId: Scalars['UUID'];
  deleteEvents: Scalars['Boolean'];
};

/** The output of our `deleteGcs` mutation. */
export type DeleteGcsPayload = {
  __typename?: 'DeleteGcsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteGoogleAccountByNodeId` mutation. */
export type DeleteGoogleAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGoogleAccount` mutation. */
export type DeleteGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GoogleAccount` mutation. */
export type DeleteGoogleAccountPayload = {
  __typename?: 'DeleteGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleAccount` that was deleted by this mutation. */
  googleAccount?: Maybe<GoogleAccount>;
  deletedGoogleAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GoogleAccount`. May be used by Relay 1. */
  googleAccountEdge?: Maybe<GoogleAccountsEdge>;
};


/** The output of our delete `GoogleAccount` mutation. */
export type DeleteGoogleAccountPayloadGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
};

/** All input for the `deleteGoogleCalendarSyncByNodeId` mutation. */
export type DeleteGoogleCalendarSyncByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarSync` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGoogleCalendarSyncCalendarByNodeId` mutation. */
export type DeleteGoogleCalendarSyncCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarSyncCalendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGoogleCalendarSyncCalendar` mutation. */
export type DeleteGoogleCalendarSyncCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GoogleCalendarSyncCalendar` mutation. */
export type DeleteGoogleCalendarSyncCalendarPayload = {
  __typename?: 'DeleteGoogleCalendarSyncCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSyncCalendar` that was deleted by this mutation. */
  googleCalendarSyncCalendar?: Maybe<GoogleCalendarSyncCalendar>;
  deletedGoogleCalendarSyncCalendarNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleCalendarSync` that is related to this `GoogleCalendarSyncCalendar`. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Reads a single `Calendar` that is related to this `GoogleCalendarSyncCalendar`. */
  calendar?: Maybe<Calendar>;
  /** An edge for our `GoogleCalendarSyncCalendar`. May be used by Relay 1. */
  googleCalendarSyncCalendarEdge?: Maybe<GoogleCalendarSyncCalendarsEdge>;
};


/** The output of our delete `GoogleCalendarSyncCalendar` mutation. */
export type DeleteGoogleCalendarSyncCalendarPayloadGoogleCalendarSyncCalendarEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
};

/** All input for the `deleteGoogleCalendarSync` mutation. */
export type DeleteGoogleCalendarSyncInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GoogleCalendarSync` mutation. */
export type DeleteGoogleCalendarSyncPayload = {
  __typename?: 'DeleteGoogleCalendarSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSync` that was deleted by this mutation. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  deletedGoogleCalendarSyncNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleAccount` that is related to this `GoogleCalendarSync`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** An edge for our `GoogleCalendarSync`. May be used by Relay 1. */
  googleCalendarSyncEdge?: Maybe<GoogleCalendarSyncsEdge>;
};


/** The output of our delete `GoogleCalendarSync` mutation. */
export type DeleteGoogleCalendarSyncPayloadGoogleCalendarSyncEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
};

/** All input for the `deleteGooglePlayAccountByNodeId` mutation. */
export type DeleteGooglePlayAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGooglePlayAccount` mutation. */
export type DeleteGooglePlayAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GooglePlayAccount` mutation. */
export type DeleteGooglePlayAccountPayload = {
  __typename?: 'DeleteGooglePlayAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayAccount` that was deleted by this mutation. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  deletedGooglePlayAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayUser` that is related to this `GooglePlayAccount`. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayAccount`. May be used by Relay 1. */
  googlePlayAccountEdge?: Maybe<GooglePlayAccountsEdge>;
};


/** The output of our delete `GooglePlayAccount` mutation. */
export type DeleteGooglePlayAccountPayloadGooglePlayAccountEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
};

/** All input for the `deleteGooglePlayListingByAndroidAppId` mutation. */
export type DeleteGooglePlayListingByAndroidAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** All input for the `deleteGooglePlayListingByNodeId` mutation. */
export type DeleteGooglePlayListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGooglePlayListing` mutation. */
export type DeleteGooglePlayListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The output of our delete `GooglePlayListing` mutation. */
export type DeleteGooglePlayListingPayload = {
  __typename?: 'DeleteGooglePlayListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayListing` that was deleted by this mutation. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  deletedGooglePlayListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `GooglePlayListing`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `GooglePlayAccount` that is related to this `GooglePlayListing`. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayListing`. May be used by Relay 1. */
  googlePlayListingEdge?: Maybe<GooglePlayListingsEdge>;
};


/** The output of our delete `GooglePlayListing` mutation. */
export type DeleteGooglePlayListingPayloadGooglePlayListingEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
};

/** All input for the `deleteGooglePlayUserByEmail` mutation. */
export type DeleteGooglePlayUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email used to login */
  email: Scalars['String'];
};

/** All input for the `deleteGooglePlayUserByNodeId` mutation. */
export type DeleteGooglePlayUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGooglePlayUser` mutation. */
export type DeleteGooglePlayUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** The output of our delete `GooglePlayUser` mutation. */
export type DeleteGooglePlayUserPayload = {
  __typename?: 'DeleteGooglePlayUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayUser` that was deleted by this mutation. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  deletedGooglePlayUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayUser`. May be used by Relay 1. */
  googlePlayUserEdge?: Maybe<GooglePlayUsersEdge>;
};


/** The output of our delete `GooglePlayUser` mutation. */
export type DeleteGooglePlayUserPayloadGooglePlayUserEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
};

/** All input for the `deleteGroupByNodeId` mutation. */
export type DeleteGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupBySlug` mutation. */
export type DeleteGroupBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** All input for the `deleteGroupCategoryByNodeId` mutation. */
export type DeleteGroupCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupCategory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupCategory` mutation. */
export type DeleteGroupCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GroupCategory` mutation. */
export type DeleteGroupCategoryPayload = {
  __typename?: 'DeleteGroupCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupCategory` that was deleted by this mutation. */
  groupCategory?: Maybe<GroupCategory>;
  deletedGroupCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Division` that is related to this `GroupCategory`. */
  division?: Maybe<Division>;
  /** An edge for our `GroupCategory`. May be used by Relay 1. */
  groupCategoryEdge?: Maybe<GroupCategoriesEdge>;
};


/** The output of our delete `GroupCategory` mutation. */
export type DeleteGroupCategoryPayloadGroupCategoryEdgeArgs = {
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
};

/** All input for the `deleteGroup` mutation. */
export type DeleteGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteGroupNewsletterByGroupIdAndNewsletterId` mutation. */
export type DeleteGroupNewsletterByGroupIdAndNewsletterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** All input for the `deleteGroupNewsletterByNodeId` mutation. */
export type DeleteGroupNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupNewsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupNewsletter` mutation. */
export type DeleteGroupNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GroupNewsletter` mutation. */
export type DeleteGroupNewsletterPayload = {
  __typename?: 'DeleteGroupNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupNewsletter` that was deleted by this mutation. */
  groupNewsletter?: Maybe<GroupNewsletter>;
  deletedGroupNewsletterNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupNewsletter`. */
  group?: Maybe<Group>;
  /** Reads a single `Newsletter` that is related to this `GroupNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupNewsletter`. May be used by Relay 1. */
  groupNewsletterEdge?: Maybe<GroupNewslettersEdge>;
};


/** The output of our delete `GroupNewsletter` mutation. */
export type DeleteGroupNewsletterPayloadGroupNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
};

/** The output of our delete `Group` mutation. */
export type DeleteGroupPayload = {
  __typename?: 'DeleteGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was deleted by this mutation. */
  group?: Maybe<Group>;
  deletedGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GroupCategory` that is related to this `Group`. */
  groupCategory?: Maybe<GroupCategory>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our delete `Group` mutation. */
export type DeleteGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the `deleteGroupPersonByGroupIdAndPersonId` mutation. */
export type DeleteGroupPersonByGroupIdAndPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** All input for the `deleteGroupPersonByNodeId` mutation. */
export type DeleteGroupPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupPerson` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupPerson` mutation. */
export type DeleteGroupPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GroupPerson` mutation. */
export type DeleteGroupPersonPayload = {
  __typename?: 'DeleteGroupPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupPerson` that was deleted by this mutation. */
  groupPerson?: Maybe<GroupPerson>;
  deletedGroupPersonNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupPerson`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupPerson`. May be used by Relay 1. */
  groupPersonEdge?: Maybe<GroupPeopleEdge>;
};


/** The output of our delete `GroupPerson` mutation. */
export type DeleteGroupPersonPayloadGroupPersonEdgeArgs = {
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
};

/** All input for the `deleteIosAppByAppId` mutation. */
export type DeleteIosAppByAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** All input for the `deleteIosAppByNodeId` mutation. */
export type DeleteIosAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IosApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIosApp` mutation. */
export type DeleteIosAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The output of our delete `IosApp` mutation. */
export type DeleteIosAppPayload = {
  __typename?: 'DeleteIosAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosApp` that was deleted by this mutation. */
  iosApp?: Maybe<IosApp>;
  deletedIosAppNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `IosApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosApp`. May be used by Relay 1. */
  iosAppEdge?: Maybe<IosAppsEdge>;
};


/** The output of our delete `IosApp` mutation. */
export type DeleteIosAppPayloadIosAppEdgeArgs = {
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
};

/** All input for the `deleteIosScreenshotByNodeId` mutation. */
export type DeleteIosScreenshotByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IosScreenshot` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIosScreenshot` mutation. */
export type DeleteIosScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The output of our delete `IosScreenshot` mutation. */
export type DeleteIosScreenshotPayload = {
  __typename?: 'DeleteIosScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosScreenshot` that was deleted by this mutation. */
  iosScreenshot?: Maybe<IosScreenshot>;
  deletedIosScreenshotNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreListing` that is related to this `IosScreenshot`. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosScreenshot`. May be used by Relay 1. */
  iosScreenshotEdge?: Maybe<IosScreenshotsEdge>;
};


/** The output of our delete `IosScreenshot` mutation. */
export type DeleteIosScreenshotPayloadIosScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
};

/** All input for the `deleteMarqueeByNodeId` mutation. */
export type DeleteMarqueeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Marquee` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMarquee` mutation. */
export type DeleteMarqueeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Marquee` mutation. */
export type DeleteMarqueePayload = {
  __typename?: 'DeleteMarqueePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Marquee` that was deleted by this mutation. */
  marquee?: Maybe<Marquee>;
  deletedMarqueeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Marquee`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Marquee`. */
  marqueeImage?: Maybe<File>;
  /** An edge for our `Marquee`. May be used by Relay 1. */
  marqueeEdge?: Maybe<MarqueesEdge>;
};


/** The output of our delete `Marquee` mutation. */
export type DeleteMarqueePayloadMarqueeEdgeArgs = {
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
};

/** All input for the `deleteNewsletterByNodeId` mutation. */
export type DeleteNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Newsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNewsletter` mutation. */
export type DeleteNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Newsletter` mutation. */
export type DeleteNewsletterPayload = {
  __typename?: 'DeleteNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Newsletter` that was deleted by this mutation. */
  newsletter?: Maybe<Newsletter>;
  deletedNewsletterNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** An edge for our `Newsletter`. May be used by Relay 1. */
  newsletterEdge?: Maybe<NewslettersEdge>;
};


/** The output of our delete `Newsletter` mutation. */
export type DeleteNewsletterPayloadNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
};

/** All input for the `deleteNotificationByNodeId` mutation. */
export type DeleteNotificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Notification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNotificationBySlug` mutation. */
export type DeleteNotificationBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteNotificationFileByFileIdAndNotificationId` mutation. */
export type DeleteNotificationFileByFileIdAndNotificationIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** All input for the `deleteNotificationFileByNodeId` mutation. */
export type DeleteNotificationFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNotificationFile` mutation. */
export type DeleteNotificationFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `NotificationFile` mutation. */
export type DeleteNotificationFilePayload = {
  __typename?: 'DeleteNotificationFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationFile` that was deleted by this mutation. */
  notificationFile?: Maybe<NotificationFile>;
  deletedNotificationFileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationFile`. */
  notification?: Maybe<Notification>;
  /** Reads a single `File` that is related to this `NotificationFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationFile`. May be used by Relay 1. */
  notificationFileEdge?: Maybe<NotificationFilesEdge>;
};


/** The output of our delete `NotificationFile` mutation. */
export type DeleteNotificationFilePayloadNotificationFileEdgeArgs = {
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
};

/** All input for the `deleteNotificationGroupByNodeId` mutation. */
export type DeleteNotificationGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNotificationGroupByNotificationIdAndGroupId` mutation. */
export type DeleteNotificationGroupByNotificationIdAndGroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** All input for the `deleteNotificationGroup` mutation. */
export type DeleteNotificationGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `NotificationGroup` mutation. */
export type DeleteNotificationGroupPayload = {
  __typename?: 'DeleteNotificationGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationGroup` that was deleted by this mutation. */
  notificationGroup?: Maybe<NotificationGroup>;
  deletedNotificationGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationGroup`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Group` that is related to this `NotificationGroup`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationGroup`. May be used by Relay 1. */
  notificationGroupEdge?: Maybe<NotificationGroupsEdge>;
};


/** The output of our delete `NotificationGroup` mutation. */
export type DeleteNotificationGroupPayloadNotificationGroupEdgeArgs = {
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
};

/** All input for the `deleteNotification` mutation. */
export type DeleteNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteNotificationNewsletterByNodeId` mutation. */
export type DeleteNotificationNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationNewsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNotificationNewsletterByNotificationIdAndNewsletterId` mutation. */
export type DeleteNotificationNewsletterByNotificationIdAndNewsletterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** All input for the `deleteNotificationNewsletter` mutation. */
export type DeleteNotificationNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `NotificationNewsletter` mutation. */
export type DeleteNotificationNewsletterPayload = {
  __typename?: 'DeleteNotificationNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationNewsletter` that was deleted by this mutation. */
  notificationNewsletter?: Maybe<NotificationNewsletter>;
  deletedNotificationNewsletterNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationNewsletter`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Newsletter` that is related to this `NotificationNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationNewsletter`. May be used by Relay 1. */
  notificationNewsletterEdge?: Maybe<NotificationNewslettersEdge>;
};


/** The output of our delete `NotificationNewsletter` mutation. */
export type DeleteNotificationNewsletterPayloadNotificationNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
};

/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayload = {
  __typename?: 'DeleteNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Notification` that was deleted by this mutation. */
  notification?: Maybe<Notification>;
  deletedNotificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Notification` that is related to this `Notification`. */
  notificationByRecurringParent?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
};


/** The output of our delete `Notification` mutation. */
export type DeleteNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
};

/** All input for the `deletePageByNodeId` mutation. */
export type DeletePageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Page` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePage` mutation. */
export type DeletePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Page` mutation. */
export type DeletePagePayload = {
  __typename?: 'DeletePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was deleted by this mutation. */
  page?: Maybe<Page>;
  deletedPageNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Page`. May be used by Relay 1. */
  pageEdge?: Maybe<PagesEdge>;
};


/** The output of our delete `Page` mutation. */
export type DeletePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<Array<PagesOrderBy>>;
};

/** All input for the `deletePageSectionByNodeId` mutation. */
export type DeletePageSectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PageSection` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePageSectionFileByFileIdAndPageSectionId` mutation. */
export type DeletePageSectionFileByFileIdAndPageSectionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** All input for the `deletePageSectionFileByNodeId` mutation. */
export type DeletePageSectionFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PageSectionFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePageSectionFile` mutation. */
export type DeletePageSectionFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `PageSectionFile` mutation. */
export type DeletePageSectionFilePayload = {
  __typename?: 'DeletePageSectionFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSectionFile` that was deleted by this mutation. */
  pageSectionFile?: Maybe<PageSectionFile>;
  deletedPageSectionFileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PageSection` that is related to this `PageSectionFile`. */
  pageSection?: Maybe<PageSection>;
  /** Reads a single `File` that is related to this `PageSectionFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `PageSectionFile`. May be used by Relay 1. */
  pageSectionFileEdge?: Maybe<PageSectionFilesEdge>;
};


/** The output of our delete `PageSectionFile` mutation. */
export type DeletePageSectionFilePayloadPageSectionFileEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
};

/** All input for the `deletePageSection` mutation. */
export type DeletePageSectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `PageSection` mutation. */
export type DeletePageSectionPayload = {
  __typename?: 'DeletePageSectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSection` that was deleted by this mutation. */
  pageSection?: Maybe<PageSection>;
  deletedPageSectionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `PageSection`. */
  page?: Maybe<Page>;
  /** An edge for our `PageSection`. May be used by Relay 1. */
  pageSectionEdge?: Maybe<PageSectionsEdge>;
};


/** The output of our delete `PageSection` mutation. */
export type DeletePageSectionPayloadPageSectionEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
};

/** All input for the `deletePersonByNodeId` mutation. */
export type DeletePersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Person` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePerson` mutation. */
export type DeletePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Person` mutation. */
export type DeletePersonPayload = {
  __typename?: 'DeletePersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` that was deleted by this mutation. */
  person?: Maybe<Person>;
  deletedPersonNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Person`. */
  profileImage?: Maybe<File>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
};


/** The output of our delete `Person` mutation. */
export type DeletePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>;
};

/** All input for the `deleteReleaseByNodeId` mutation. */
export type DeleteReleaseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Release` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRelease` mutation. */
export type DeleteReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** The output of our delete `Release` mutation. */
export type DeleteReleasePayload = {
  __typename?: 'DeleteReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Release` that was deleted by this mutation. */
  release?: Maybe<Release>;
  deletedReleaseNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Release`. May be used by Relay 1. */
  releaseEdge?: Maybe<ReleasesEdge>;
};


/** The output of our delete `Release` mutation. */
export type DeleteReleasePayloadReleaseEdgeArgs = {
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
};

/** All input for the `deleteResourceByNodeId` mutation. */
export type DeleteResourceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Resource` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteResourceBySlug` mutation. */
export type DeleteResourceBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteResourceFileByFileIdAndResourceId` mutation. */
export type DeleteResourceFileByFileIdAndResourceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** All input for the `deleteResourceFileByNodeId` mutation. */
export type DeleteResourceFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ResourceFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteResourceFile` mutation. */
export type DeleteResourceFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ResourceFile` mutation. */
export type DeleteResourceFilePayload = {
  __typename?: 'DeleteResourceFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ResourceFile` that was deleted by this mutation. */
  resourceFile?: Maybe<ResourceFile>;
  deletedResourceFileNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Resource` that is related to this `ResourceFile`. */
  resource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `ResourceFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `ResourceFile`. May be used by Relay 1. */
  resourceFileEdge?: Maybe<ResourceFilesEdge>;
};


/** The output of our delete `ResourceFile` mutation. */
export type DeleteResourceFilePayloadResourceFileEdgeArgs = {
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
};

/** All input for the `deleteResource` mutation. */
export type DeleteResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Resource` mutation. */
export type DeleteResourcePayload = {
  __typename?: 'DeleteResourcePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Resource` that was deleted by this mutation. */
  resource?: Maybe<Resource>;
  deletedResourceNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Resource`. */
  group?: Maybe<Group>;
  /** Reads a single `Resource` that is related to this `Resource`. */
  resourceByParentResource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `Resource`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  tileImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultCoverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultThumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};


/** The output of our delete `Resource` mutation. */
export type DeleteResourcePayloadResourceEdgeArgs = {
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
};

/** All input for the `deleteSchoolByNodeId` mutation. */
export type DeleteSchoolByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `School` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSchoolBySlug` mutation. */
export type DeleteSchoolBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteSchool` mutation. */
export type DeleteSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `School` mutation. */
export type DeleteSchoolPayload = {
  __typename?: 'DeleteSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `School` that was deleted by this mutation. */
  school?: Maybe<School>;
  deletedSchoolNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `School`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `School`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Theme` that is related to this `School`. */
  theme?: Maybe<Theme>;
  /** An edge for our `School`. May be used by Relay 1. */
  schoolEdge?: Maybe<SchoolsEdge>;
};


/** The output of our delete `School` mutation. */
export type DeleteSchoolPayloadSchoolEdgeArgs = {
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
};

/** All input for the `deleteSchoolPersonByNodeId` mutation. */
export type DeleteSchoolPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPerson` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSchoolPersonByPersonIdAndSchoolId` mutation. */
export type DeleteSchoolPersonByPersonIdAndSchoolIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** All input for the `deleteSchoolPerson` mutation. */
export type DeleteSchoolPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSchoolPersonInstallationByNodeId` mutation. */
export type DeleteSchoolPersonInstallationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPersonInstallation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSchoolPersonInstallation` mutation. */
export type DeleteSchoolPersonInstallationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SchoolPersonInstallation` mutation. */
export type DeleteSchoolPersonInstallationPayload = {
  __typename?: 'DeleteSchoolPersonInstallationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInstallation` that was deleted by this mutation. */
  schoolPersonInstallation?: Maybe<SchoolPersonInstallation>;
  deletedSchoolPersonInstallationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInstallation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInstallation`. May be used by Relay 1. */
  schoolPersonInstallationEdge?: Maybe<SchoolPersonInstallationsEdge>;
};


/** The output of our delete `SchoolPersonInstallation` mutation. */
export type DeleteSchoolPersonInstallationPayloadSchoolPersonInstallationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
};

/** All input for the `deleteSchoolPersonInvitationByNodeId` mutation. */
export type DeleteSchoolPersonInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPersonInvitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSchoolPersonInvitationBySlug` mutation. */
export type DeleteSchoolPersonInvitationBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteSchoolPersonInvitation` mutation. */
export type DeleteSchoolPersonInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolPersonId: Scalars['UUID'];
};

/** The output of our delete `SchoolPersonInvitation` mutation. */
export type DeleteSchoolPersonInvitationPayload = {
  __typename?: 'DeleteSchoolPersonInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInvitation` that was deleted by this mutation. */
  schoolPersonInvitation?: Maybe<SchoolPersonInvitation>;
  deletedSchoolPersonInvitationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInvitation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInvitation`. May be used by Relay 1. */
  schoolPersonInvitationEdge?: Maybe<SchoolPersonInvitationsEdge>;
};


/** The output of our delete `SchoolPersonInvitation` mutation. */
export type DeleteSchoolPersonInvitationPayloadSchoolPersonInvitationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
};

/** The output of our delete `SchoolPerson` mutation. */
export type DeleteSchoolPersonPayload = {
  __typename?: 'DeleteSchoolPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPerson` that was deleted by this mutation. */
  schoolPerson?: Maybe<SchoolPerson>;
  deletedSchoolPersonNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolPerson`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPerson`. May be used by Relay 1. */
  schoolPersonEdge?: Maybe<SchoolPeopleEdge>;
};


/** The output of our delete `SchoolPerson` mutation. */
export type DeleteSchoolPersonPayloadSchoolPersonEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
};

/** All input for the `deleteSchoolSettingByNodeId` mutation. */
export type DeleteSchoolSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSchoolSetting` mutation. */
export type DeleteSchoolSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolId: Scalars['UUID'];
};

/** The output of our delete `SchoolSetting` mutation. */
export type DeleteSchoolSettingPayload = {
  __typename?: 'DeleteSchoolSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolSetting` that was deleted by this mutation. */
  schoolSetting?: Maybe<SchoolSetting>;
  deletedSchoolSettingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolSetting`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  schoolImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  mascotImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  launchScreenPoweredByImage?: Maybe<File>;
  /** An edge for our `SchoolSetting`. May be used by Relay 1. */
  schoolSettingEdge?: Maybe<SchoolSettingsEdge>;
};


/** The output of our delete `SchoolSetting` mutation. */
export type DeleteSchoolSettingPayloadSchoolSettingEdgeArgs = {
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
};

/** All input for the `deleteSponsorByNodeId` mutation. */
export type DeleteSponsorByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sponsor` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSponsorBySlug` mutation. */
export type DeleteSponsorBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `deleteSponsor` mutation. */
export type DeleteSponsorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Sponsor` mutation. */
export type DeleteSponsorPayload = {
  __typename?: 'DeleteSponsorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Sponsor` that was deleted by this mutation. */
  sponsor?: Maybe<Sponsor>;
  deletedSponsorNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Sponsor`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  promoImage?: Maybe<File>;
  /** An edge for our `Sponsor`. May be used by Relay 1. */
  sponsorEdge?: Maybe<SponsorsEdge>;
};


/** The output of our delete `Sponsor` mutation. */
export type DeleteSponsorPayloadSponsorEdgeArgs = {
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
};

export type DeleteTemporaryAwsS3UploadInput = {
  filename: Scalars['String'];
};

/** All input for the `deleteThemeByNodeId` mutation. */
export type DeleteThemeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Theme` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTheme` mutation. */
export type DeleteThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Theme` mutation. */
export type DeleteThemePayload = {
  __typename?: 'DeleteThemePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Theme` that was deleted by this mutation. */
  theme?: Maybe<Theme>;
  deletedThemeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge?: Maybe<ThemesEdge>;
};


/** The output of our delete `Theme` mutation. */
export type DeleteThemePayloadThemeEdgeArgs = {
  orderBy?: Maybe<Array<ThemesOrderBy>>;
};

/** All input for the `deleteUserGoogleAccountByNodeId` mutation. */
export type DeleteUserGoogleAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserGoogleAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserGoogleAccountByUserIdAndGoogleAccountId` mutation. */
export type DeleteUserGoogleAccountByUserIdAndGoogleAccountIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** All input for the `deleteUserGoogleAccount` mutation. */
export type DeleteUserGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserGoogleAccount` mutation. */
export type DeleteUserGoogleAccountPayload = {
  __typename?: 'DeleteUserGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserGoogleAccount` that was deleted by this mutation. */
  userGoogleAccount?: Maybe<UserGoogleAccount>;
  deletedUserGoogleAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GoogleAccount` that is related to this `UserGoogleAccount`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `UserGoogleAccount`. May be used by Relay 1. */
  userGoogleAccountEdge?: Maybe<UserGoogleAccountsEdge>;
};


/** The output of our delete `UserGoogleAccount` mutation. */
export type DeleteUserGoogleAccountPayloadUserGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
};

/** All input for the `deleteUserSessionByNodeId` mutation. */
export type DeleteUserSessionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserSession` mutation. */
export type DeleteUserSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A foreign key reference to the user's `user_id` in their `user_account` record. */
  userId: Scalars['UUID'];
};

/** The output of our delete `UserSession` mutation. */
export type DeleteUserSessionPayload = {
  __typename?: 'DeleteUserSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSession` that was deleted by this mutation. */
  userSession?: Maybe<UserSession>;
  deletedUserSessionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserSession`. May be used by Relay 1. */
  userSessionEdge?: Maybe<UserSessionsEdge>;
};


/** The output of our delete `UserSession` mutation. */
export type DeleteUserSessionPayloadUserSessionEdgeArgs = {
  orderBy?: Maybe<Array<UserSessionsOrderBy>>;
};

/**
 * The main unit of organization within a `School`.
 *
 *
 *
 * Every `School` has at least one `Division` as its main "nexus" of data for Schoolwide groups, etc..
 */
export type Division = Node & {
  __typename?: 'Division';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId: Scalars['UUID'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId: Scalars['UUID'];
  /** Reads a single `Person` that is related to this `Division`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Division`. */
  school?: Maybe<School>;
  /** Reads a single `Group` that is related to this `Division`. */
  group?: Maybe<Group>;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategories: GroupCategoriesConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupCategoryDivisionIdAndCreatedBy: DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupCategoryDivisionIdAndUpdatedBy: DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyConnection;
};


/**
 * The main unit of organization within a `School`.
 *
 *
 *
 * Every `School` has at least one `Division` as its main "nexus" of data for Schoolwide groups, etc..
 */
export type DivisionGroupCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/**
 * The main unit of organization within a `School`.
 *
 *
 *
 * Every `School` has at least one `Division` as its main "nexus" of data for Schoolwide groups, etc..
 */
export type DivisionPeopleByGroupCategoryDivisionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * The main unit of organization within a `School`.
 *
 *
 *
 * Every `School` has at least one `Division` as its main "nexus" of data for Schoolwide groups, etc..
 */
export type DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `Division` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DivisionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `schoolwide` field. */
  schoolwide?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
};

/** The `division` to be created by this mutation. */
export type DivisionCreatedByFkeyDivisionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** Input for the nested mutation of `division` in the `PersonInput` mutation. */
export type DivisionCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `division` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectById?: Maybe<Array<DivisionDivisionPkeyConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DivisionNodeIdConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteById?: Maybe<Array<DivisionDivisionPkeyDelete>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DivisionNodeIdDelete>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateById?: Maybe<Array<DivisionOnDivisionForDivisionCreatedByFkeyUsingDivisionPkeyUpdate>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnDivisionForDivisionCreatedByFkeyNodeIdUpdate>>;
  /** A `DivisionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DivisionCreatedByFkeyDivisionCreateInput>>;
};

/** The fields on `division` to look up the row to connect. */
export type DivisionDivisionPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `division` to look up the row to delete. */
export type DivisionDivisionPkeyDelete = {
  id: Scalars['UUID'];
};

/** A filter to be used against `Division` object types. All fields are combined with a logical ‘and.’ */
export type DivisionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `schoolwide` field. */
  schoolwide?: Maybe<BooleanFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DivisionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DivisionFilter>>;
  /** Negates the expression. */
  not?: Maybe<DivisionFilter>;
};

/** The `division` to be created by this mutation. */
export type DivisionGroupIdFkeyDivisionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** The `group` to be created by this mutation. */
export type DivisionGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `DivisionInput` mutation. */
export type DivisionGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnDivisionForDivisionGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnDivisionForDivisionGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<DivisionOnDivisionForDivisionGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<DivisionGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `division` in the `GroupInput` mutation. */
export type DivisionGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `division` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectById?: Maybe<Array<DivisionDivisionPkeyConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DivisionNodeIdConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteById?: Maybe<Array<DivisionDivisionPkeyDelete>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DivisionNodeIdDelete>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateById?: Maybe<Array<DivisionOnDivisionForDivisionGroupIdFkeyUsingDivisionPkeyUpdate>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnDivisionForDivisionGroupIdFkeyNodeIdUpdate>>;
  /** A `DivisionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DivisionGroupIdFkeyDivisionCreateInput>>;
};

/** An input for mutations affecting `Division` */
export type DivisionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DivisionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DivisionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `division` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `division` to look up the row to update. */
export type DivisionOnDivisionForDivisionCreatedByFkeyUsingDivisionPkeyUpdate = {
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: UpdateDivisionOnDivisionForDivisionCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DivisionOnDivisionForDivisionGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `division` to look up the row to update. */
export type DivisionOnDivisionForDivisionGroupIdFkeyUsingDivisionPkeyUpdate = {
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: UpdateDivisionOnDivisionForDivisionGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DivisionOnDivisionForDivisionSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `division` to look up the row to update. */
export type DivisionOnDivisionForDivisionSchoolIdFkeyUsingDivisionPkeyUpdate = {
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: UpdateDivisionOnDivisionForDivisionSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DivisionOnDivisionForDivisionUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `division` to look up the row to update. */
export type DivisionOnDivisionForDivisionUpdatedByFkeyUsingDivisionPkeyUpdate = {
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: UpdateDivisionOnDivisionForDivisionUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: GroupCategoryPatch;
};

/** The fields on `division` to look up the row to update. */
export type DivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyUsingDivisionPkeyUpdate = {
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: UpdateDivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Division`. Fields that are set will be updated. */
export type DivisionPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyConnection = {
  __typename?: 'DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyEdge = {
  __typename?: 'DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByCreatedBy: GroupCategoriesConnection;
};


/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndCreatedByManyToManyEdgeGroupCategoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyConnection = {
  __typename?: 'DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyEdge = {
  __typename?: 'DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByUpdatedBy: GroupCategoriesConnection;
};


/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type DivisionPeopleByGroupCategoryDivisionIdAndUpdatedByManyToManyEdgeGroupCategoriesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** The `division` to be created by this mutation. */
export type DivisionSchoolIdFkeyDivisionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** Input for the nested mutation of `school` in the `DivisionInput` mutation. */
export type DivisionSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnDivisionForDivisionSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnDivisionForDivisionSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<DivisionOnDivisionForDivisionSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<DivisionSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `division` in the `SchoolInput` mutation. */
export type DivisionSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `division` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectById?: Maybe<Array<DivisionDivisionPkeyConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DivisionNodeIdConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteById?: Maybe<Array<DivisionDivisionPkeyDelete>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DivisionNodeIdDelete>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateById?: Maybe<Array<DivisionOnDivisionForDivisionSchoolIdFkeyUsingDivisionPkeyUpdate>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolOnDivisionForDivisionSchoolIdFkeyNodeIdUpdate>>;
  /** A `DivisionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DivisionSchoolIdFkeyDivisionCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type DivisionSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `division` to be created by this mutation. */
export type DivisionUpdatedByFkeyDivisionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `DivisionInput` mutation. */
export type DivisionUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnDivisionForDivisionUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<DivisionOnDivisionForDivisionUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<DivisionUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `division` in the `PersonInput` mutation. */
export type DivisionUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `division` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectById?: Maybe<Array<DivisionDivisionPkeyConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DivisionNodeIdConnect>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteById?: Maybe<Array<DivisionDivisionPkeyDelete>>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DivisionNodeIdDelete>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateById?: Maybe<Array<DivisionOnDivisionForDivisionUpdatedByFkeyUsingDivisionPkeyUpdate>>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnDivisionForDivisionUpdatedByFkeyNodeIdUpdate>>;
  /** A `DivisionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DivisionUpdatedByFkeyDivisionCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type DivisionUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Division` values. */
export type DivisionsConnection = {
  __typename?: 'DivisionsConnection';
  /** A list of `Division` objects. */
  nodes: Array<Division>;
  /** A list of edges which contains the `Division` and cursor to aid in pagination. */
  edges: Array<DivisionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Division` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Division` edge in the connection. */
export type DivisionsEdge = {
  __typename?: 'DivisionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Division` at the end of the edge. */
  node: Division;
};

/** Methods to use when ordering `Division`. */
export enum DivisionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  SchoolwideAsc = 'SCHOOLWIDE_ASC',
  SchoolwideDesc = 'SCHOOLWIDE_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `duplicateNewsletter` mutation. */
export type DuplicateNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newsletterId: Scalars['UUID'];
};

/** The output of our `duplicateNewsletter` mutation. */
export type DuplicateNewsletterPayload = {
  __typename?: 'DuplicateNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** An edge for our `Newsletter`. May be used by Relay 1. */
  newsletterEdge?: Maybe<NewslettersEdge>;
};


/** The output of our `duplicateNewsletter` mutation. */
export type DuplicateNewsletterPayloadNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
};


/** A filter to be used against Email fields. All fields are combined with a logical ‘and.’ */
export type EmailFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Email']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Email']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Email']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Email']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Email']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Email']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Email']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Email']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Email']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Email']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['Email']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['Email']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['Email']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['Email']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['Email']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['Email']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['Email']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['Email']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['Email']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['Email']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['Email']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['Email']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['Email']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['Email']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['Email']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['Email']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['Email']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['Email']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['Email']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['Email']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['Email']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['Email']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['Email']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['Email']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['Email']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['Email']>;
};

/** `Event`s - what they sound like.<br/><br/> */
export type Event = Node & {
  __typename?: 'Event';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay: Scalars['Boolean'];
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<Buttons>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Event`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Event`. */
  thumbnailImage?: Maybe<File>;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
  /** Returns the correct icon for an event taking into account how many calendars, group categories, and divisions it is included in. */
  calendarDisplay?: Maybe<EventCalendarDisplayAgg>;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarEventEventIdAndCalendarId: EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventEventIdAndCreatedBy: EventPeopleByCalendarEventEventIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventEventIdAndUpdatedBy: EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyConnection;
};


/** `Event`s - what they sound like.<br/><br/> */
export type EventCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};


/** `Event`s - what they sound like.<br/><br/> */
export type EventCalendarsByCalendarEventEventIdAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/** `Event`s - what they sound like.<br/><br/> */
export type EventPeopleByCalendarEventEventIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** `Event`s - what they sound like.<br/><br/> */
export type EventPeopleByCalendarEventEventIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

export type EventCalendarDisplay = {
  __typename?: 'EventCalendarDisplay';
  icon?: Maybe<Scalars['String']>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EventCalendarDisplayAgg = {
  __typename?: 'EventCalendarDisplayAgg';
  icon?: Maybe<Scalars['String']>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  calendars?: Maybe<Array<Maybe<EventCalendarDisplay>>>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarEvent`. */
export type EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyConnection = {
  __typename?: 'EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarEvent`. */
export type EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyEdge = {
  __typename?: 'EventCalendarsByCalendarEventEventIdAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googleId` field. */
  googleId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googleCalendarId` field. */
  googleCalendarId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `information` field. */
  information?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationString` field. */
  locationString?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `allDay` field. */
  allDay?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
};

/** The `event` to be created by this mutation. */
export type EventCoverImageFileIdFkeyEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** The `file` to be created by this mutation. */
export type EventCoverImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `EventInput` mutation. */
export type EventCoverImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnEventForEventCoverImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<EventOnEventForEventCoverImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<EventCoverImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `event` in the `FileInput` mutation. */
export type EventCoverImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<EventEventSlugKeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByGoogleId?: Maybe<Array<EventEventGoogleIdIdxConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<EventEventSlugKeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByGoogleId?: Maybe<Array<EventEventGoogleIdIdxDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForEventCoverImageFileIdFkeyUsingEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<EventOnEventForEventCoverImageFileIdFkeyUsingEventSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByGoogleId?: Maybe<Array<EventOnEventForEventCoverImageFileIdFkeyUsingEventGoogleIdIdxUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnEventForEventCoverImageFileIdFkeyNodeIdUpdate>>;
  /** A `EventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EventCoverImageFileIdFkeyEventCreateInput>>;
};

/** The `event` to be created by this mutation. */
export type EventCreatedByFkeyEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** Input for the nested mutation of `event` in the `PersonInput` mutation. */
export type EventCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<EventEventSlugKeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByGoogleId?: Maybe<Array<EventEventGoogleIdIdxConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<EventEventSlugKeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByGoogleId?: Maybe<Array<EventEventGoogleIdIdxDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForEventCreatedByFkeyUsingEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<EventOnEventForEventCreatedByFkeyUsingEventSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByGoogleId?: Maybe<Array<EventOnEventForEventCreatedByFkeyUsingEventGoogleIdIdxUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnEventForEventCreatedByFkeyNodeIdUpdate>>;
  /** A `EventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EventCreatedByFkeyEventCreateInput>>;
};

/** The fields on `event` to look up the row to connect. */
export type EventEventGoogleIdIdxConnect = {
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to delete. */
export type EventEventGoogleIdIdxDelete = {
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to connect. */
export type EventEventPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to delete. */
export type EventEventPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to connect. */
export type EventEventSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `event` to look up the row to delete. */
export type EventEventSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `googleId` field. */
  googleId?: Maybe<StringFilter>;
  /** Filter by the object’s `googleCalendarId` field. */
  googleCalendarId?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `information` field. */
  information?: Maybe<StringFilter>;
  /** Filter by the object’s `locationString` field. */
  locationString?: Maybe<StringFilter>;
  /** Filter by the object’s `allDay` field. */
  allDay?: Maybe<BooleanFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EventFilter>>;
  /** Negates the expression. */
  not?: Maybe<EventFilter>;
};

/** An input for mutations affecting `Event` */
export type EventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type EventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type EventNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnCalendarEventForCalendarEventEventIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: CalendarEventPatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventGoogleIdIdxUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnCalendarEventForCalendarEventEventIdFkeyPatch;
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnCalendarEventForCalendarEventEventIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnCalendarEventForCalendarEventEventIdFkeyUsingEventSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnCalendarEventForCalendarEventEventIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnEventForEventCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCoverImageFileIdFkeyUsingEventGoogleIdIdxUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCoverImageFileIdFkeyPatch;
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCoverImageFileIdFkeyUsingEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCoverImageFileIdFkeyUsingEventSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCoverImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCreatedByFkeyUsingEventGoogleIdIdxUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCreatedByFkeyPatch;
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCreatedByFkeyUsingEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventCreatedByFkeyUsingEventSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnEventForEventThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventThumbnailImageFileIdFkeyUsingEventGoogleIdIdxUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventThumbnailImageFileIdFkeyPatch;
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventThumbnailImageFileIdFkeyUsingEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventThumbnailImageFileIdFkeyUsingEventSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventThumbnailImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnEventForEventUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventUpdatedByFkeyUsingEventGoogleIdIdxUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventUpdatedByFkeyPatch;
  googleId: Scalars['String'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventUpdatedByFkeyUsingEventPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForEventUpdatedByFkeyUsingEventSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForEventUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Represents an update to a `Event`. Fields that are set will be updated. */
export type EventPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndCreatedByManyToManyConnection = {
  __typename?: 'EventPeopleByCalendarEventEventIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<EventPeopleByCalendarEventEventIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndCreatedByManyToManyEdge = {
  __typename?: 'EventPeopleByCalendarEventEventIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByCreatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndCreatedByManyToManyEdgeCalendarEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyConnection = {
  __typename?: 'EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyEdge = {
  __typename?: 'EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByUpdatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type EventPeopleByCalendarEventEventIdAndUpdatedByManyToManyEdgeCalendarEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** The `event` to be created by this mutation. */
export type EventThumbnailImageFileIdFkeyEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** The `file` to be created by this mutation. */
export type EventThumbnailImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `EventInput` mutation. */
export type EventThumbnailImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnEventForEventThumbnailImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<EventOnEventForEventThumbnailImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<EventThumbnailImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `event` in the `FileInput` mutation. */
export type EventThumbnailImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<EventEventSlugKeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByGoogleId?: Maybe<Array<EventEventGoogleIdIdxConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<EventEventSlugKeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByGoogleId?: Maybe<Array<EventEventGoogleIdIdxDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForEventThumbnailImageFileIdFkeyUsingEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<EventOnEventForEventThumbnailImageFileIdFkeyUsingEventSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByGoogleId?: Maybe<Array<EventOnEventForEventThumbnailImageFileIdFkeyUsingEventGoogleIdIdxUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnEventForEventThumbnailImageFileIdFkeyNodeIdUpdate>>;
  /** A `EventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EventThumbnailImageFileIdFkeyEventCreateInput>>;
};

/** The `event` to be created by this mutation. */
export type EventUpdatedByFkeyEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate: Scalars['Datetime'];
  /** The end datetime for the event. Stored in UTC. */
  endDate: Scalars['Datetime'];
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `EventInput` mutation. */
export type EventUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnEventForEventUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<EventOnEventForEventUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<EventUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `event` in the `PersonInput` mutation. */
export type EventUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<EventEventSlugKeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByGoogleId?: Maybe<Array<EventEventGoogleIdIdxConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<EventEventSlugKeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByGoogleId?: Maybe<Array<EventEventGoogleIdIdxDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForEventUpdatedByFkeyUsingEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<EventOnEventForEventUpdatedByFkeyUsingEventSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByGoogleId?: Maybe<Array<EventOnEventForEventUpdatedByFkeyUsingEventGoogleIdIdxUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnEventForEventUpdatedByFkeyNodeIdUpdate>>;
  /** A `EventInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EventUpdatedByFkeyEventCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type EventUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  GoogleIdAsc = 'GOOGLE_ID_ASC',
  GoogleIdDesc = 'GOOGLE_ID_DESC',
  GoogleCalendarIdAsc = 'GOOGLE_CALENDAR_ID_ASC',
  GoogleCalendarIdDesc = 'GOOGLE_CALENDAR_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  InformationAsc = 'INFORMATION_ASC',
  InformationDesc = 'INFORMATION_DESC',
  LocationStringAsc = 'LOCATION_STRING_ASC',
  LocationStringDesc = 'LOCATION_STRING_DESC',
  AllDayAsc = 'ALL_DAY_ASC',
  AllDayDesc = 'ALL_DAY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  CoverImageFileIdAsc = 'COVER_IMAGE_FILE_ID_ASC',
  CoverImageFileIdDesc = 'COVER_IMAGE_FILE_ID_DESC',
  ThumbnailImageFileIdAsc = 'THUMBNAIL_IMAGE_FILE_ID_ASC',
  ThumbnailImageFileIdDesc = 'THUMBNAIL_IMAGE_FILE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `failBuildStep` mutation. */
export type FailBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildStepId: Scalars['UUID'];
  vFailureMessage: Scalars['String'];
};

/** The output of our `failBuildStep` mutation. */
export type FailBuildStepPayload = {
  __typename?: 'FailBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** File records are 1-to-1 with actual files stored in AWS S3. */
export type File = Node & {
  __typename?: 'File';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `File`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `File`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `Event`. */
  eventCoverImages: EventsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventThumbnailImages: EventsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  personProfileImages: PeopleConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletterCoverImages: NewslettersConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueeImages: MarqueesConnection;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventCoverImageFileIdAndCreatedBy: FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventCoverImageFileIdAndUpdatedBy: FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventCoverImageFileIdAndThumbnailImageFileId: FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventThumbnailImageFileIdAndCreatedBy: FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventThumbnailImageFileIdAndUpdatedBy: FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventThumbnailImageFileIdAndCoverImageFileId: FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonProfileImageFileIdAndCreatedBy: FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonProfileImageFileIdAndUpdatedBy: FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterCoverImageFileIdAndCreatedBy: FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterCoverImageFileIdAndUpdatedBy: FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByNewsletterCoverImageFileIdAndPageId: FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceCoverImageFileIdAndCreatedBy: FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceCoverImageFileIdAndUpdatedBy: FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceCoverImageFileIdAndGroupId: FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceCoverImageFileIdAndParentResource: FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCoverImageFileIdAndTileImageFileId: FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCoverImageFileIdAndDefaultCoverImageFileId: FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCoverImageFileIdAndDefaultThumbnailImageFileId: FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCoverImageFileIdAndThumbnailImageFileId: FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceTileImageFileIdAndCreatedBy: FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceTileImageFileIdAndUpdatedBy: FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceTileImageFileIdAndGroupId: FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceTileImageFileIdAndParentResource: FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceTileImageFileIdAndCoverImageFileId: FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceTileImageFileIdAndDefaultCoverImageFileId: FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceTileImageFileIdAndDefaultThumbnailImageFileId: FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceTileImageFileIdAndThumbnailImageFileId: FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceDefaultCoverImageFileIdAndCreatedBy: FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceDefaultCoverImageFileIdAndUpdatedBy: FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceDefaultCoverImageFileIdAndGroupId: FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceDefaultCoverImageFileIdAndParentResource: FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultCoverImageFileIdAndCoverImageFileId: FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultCoverImageFileIdAndTileImageFileId: FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileId: FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultCoverImageFileIdAndThumbnailImageFileId: FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceDefaultThumbnailImageFileIdAndCreatedBy: FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceDefaultThumbnailImageFileIdAndUpdatedBy: FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceDefaultThumbnailImageFileIdAndGroupId: FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceDefaultThumbnailImageFileIdAndParentResource: FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultThumbnailImageFileIdAndCoverImageFileId: FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultThumbnailImageFileIdAndTileImageFileId: FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileId: FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileId: FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceThumbnailImageFileIdAndCreatedBy: FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceThumbnailImageFileIdAndUpdatedBy: FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceThumbnailImageFileIdAndGroupId: FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceThumbnailImageFileIdAndParentResource: FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceThumbnailImageFileIdAndCoverImageFileId: FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceThumbnailImageFileIdAndTileImageFileId: FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceThumbnailImageFileIdAndDefaultCoverImageFileId: FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileId: FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingCoverImageFileIdAndCreatedBy: FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingCoverImageFileIdAndUpdatedBy: FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCoverImageFileIdAndThumbnailImageFileId: FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCoverImageFileIdAndLogoImageFileId: FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCoverImageFileIdAndSchoolImageFileId: FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCoverImageFileIdAndMascotImageFileId: FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageId: FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingThumbnailImageFileIdAndCreatedBy: FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingThumbnailImageFileIdAndUpdatedBy: FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingThumbnailImageFileIdAndCoverImageFileId: FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingThumbnailImageFileIdAndLogoImageFileId: FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileId: FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingThumbnailImageFileIdAndMascotImageFileId: FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageId: FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingLogoImageFileIdAndCreatedBy: FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingLogoImageFileIdAndUpdatedBy: FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLogoImageFileIdAndCoverImageFileId: FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLogoImageFileIdAndThumbnailImageFileId: FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLogoImageFileIdAndSchoolImageFileId: FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLogoImageFileIdAndMascotImageFileId: FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageId: FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingSchoolImageFileIdAndCreatedBy: FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingSchoolImageFileIdAndUpdatedBy: FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingSchoolImageFileIdAndCoverImageFileId: FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileId: FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingSchoolImageFileIdAndLogoImageFileId: FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingSchoolImageFileIdAndMascotImageFileId: FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageId: FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingMascotImageFileIdAndCreatedBy: FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingMascotImageFileIdAndUpdatedBy: FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingMascotImageFileIdAndCoverImageFileId: FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingMascotImageFileIdAndThumbnailImageFileId: FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingMascotImageFileIdAndLogoImageFileId: FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingMascotImageFileIdAndSchoolImageFileId: FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageId: FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedBy: FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedBy: FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileId: FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileId: FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileId: FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileId: FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileId: FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeMarqueeImageFileIdAndCreatedBy: FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeMarqueeImageFileIdAndUpdatedBy: FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByMarqueeMarqueeImageFileIdAndSchoolId: FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationFileFileIdAndNotificationId: FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileFileIdAndCreatedBy: FilePeopleByNotificationFileFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileFileIdAndUpdatedBy: FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByPageSectionFileFileIdAndPageSectionId: FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFileFileIdAndCreatedBy: FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFileFileIdAndUpdatedBy: FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceFileFileIdAndResourceId: FileResourcesByResourceFileFileIdAndResourceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileFileIdAndCreatedBy: FilePeopleByResourceFileFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileFileIdAndUpdatedBy: FilePeopleByResourceFileFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorCoverImageFileIdAndCreatedBy: FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorCoverImageFileIdAndUpdatedBy: FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorCoverImageFileIdAndSchoolId: FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCoverImageFileIdAndThumbnailImageFileId: FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCoverImageFileIdAndLogoImageFileId: FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCoverImageFileIdAndPromoImageFileId: FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorThumbnailImageFileIdAndCreatedBy: FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorThumbnailImageFileIdAndUpdatedBy: FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorThumbnailImageFileIdAndSchoolId: FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorThumbnailImageFileIdAndCoverImageFileId: FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorThumbnailImageFileIdAndLogoImageFileId: FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorThumbnailImageFileIdAndPromoImageFileId: FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorLogoImageFileIdAndCreatedBy: FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorLogoImageFileIdAndUpdatedBy: FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorLogoImageFileIdAndSchoolId: FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorLogoImageFileIdAndCoverImageFileId: FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorLogoImageFileIdAndThumbnailImageFileId: FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorLogoImageFileIdAndPromoImageFileId: FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorPromoImageFileIdAndCreatedBy: FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorPromoImageFileIdAndUpdatedBy: FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorPromoImageFileIdAndSchoolId: FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorPromoImageFileIdAndCoverImageFileId: FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorPromoImageFileIdAndThumbnailImageFileId: FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorPromoImageFileIdAndLogoImageFileId: FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyConnection;
  /**
   * An S3 presigned download URL that expires in 7 days.
   *
   * NOTE: if the File has a legacyImgixUrl this will be returned instead.
   * This way you can use temporaryDownloadUrl without worrying whether it's an AWS presigned URL or a legacy Imgix URL
   */
  temporaryDownloadUrl: Scalars['String'];
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileEventCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileEventThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePersonProfileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileNewsletterCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileMarqueeImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByEventCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByEventCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByEventThumbnailImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByEventThumbnailImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByPersonProfileImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByPersonProfileImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByNewsletterCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByNewsletterCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePagesByNewsletterCoverImageFileIdAndPageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileGroupsByResourceCoverImageFileIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceCoverImageFileIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceCoverImageFileIdAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceTileImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceTileImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileGroupsByResourceTileImageFileIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceTileImageFileIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceTileImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceThumbnailImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceThumbnailImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileGroupsByResourceThumbnailImageFileIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceThumbnailImageFileIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileNotificationsByNotificationFileFileIdAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByNotificationFileFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByNotificationFileFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePageSectionsByPageSectionFileFileIdAndPageSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByPageSectionFileFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByPageSectionFileFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileResourcesByResourceFileFileIdAndResourceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceFileFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleByResourceFileFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorCoverImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorCoverImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolsBySponsorCoverImageFileIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorThumbnailImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorLogoImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorLogoImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolsBySponsorLogoImageFileIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorPromoImageFileIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FilePeopleBySponsorPromoImageFileIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileSchoolsBySponsorPromoImageFileIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** File records are 1-to-1 with actual files stored in AWS S3. */
export type FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `filename` field. */
  filename?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<FileType>;
  /** Checks for equality with the object’s `legacyImgixPath` field. */
  legacyImgixPath?: Maybe<Scalars['String']>;
};

/** The `file` to be created by this mutation. */
export type FileCreatedByFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `PersonInput` mutation. */
export type FileCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<Array<FileFilePkeyConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FileNodeIdConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<Array<FileFilePkeyDelete>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FileNodeIdDelete>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<Array<FileOnFileForFileCreatedByFkeyUsingFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnFileForFileCreatedByFkeyNodeIdUpdate>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FileCreatedByFkeyFileCreateInput>>;
};

/** The fields on `file` to look up the row to connect. */
export type FileFilePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `file` to look up the row to delete. */
export type FileFilePkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `File` values, with data from `Event`. */
export type FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventThumbnailImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type FileFilesByEventCoverImageFileIdAndThumbnailImageFileIdManyToManyEdgeEventThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Event`. */
export type FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventCoverImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type FileFilesByEventThumbnailImageFileIdAndCoverImageFileIdManyToManyEdgeEventCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceCoverImageFileIdAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultCoverImageFileIdAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceDefaultThumbnailImageFileIdAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceThumbnailImageFileIdAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type FileFilesByResourceTileImageFileIdAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingCoverImageFileIdAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLaunchScreenPoweredByImageIdAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingLogoImageFileIdAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingMascotImageFileIdAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingSchoolImageFileIdAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type FileFilesBySchoolSettingThumbnailImageFileIdAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorCoverImageFileIdAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorLogoImageFileIdAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorPromoImageFileIdAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type FileFilesBySponsorThumbnailImageFileIdAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `filename` field. */
  filename?: Maybe<StringFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<FileTypeFilter>;
  /** Filter by the object’s `legacyImgixPath` field. */
  legacyImgixPath?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FileFilter>>;
  /** Negates the expression. */
  not?: Maybe<FileFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyConnection = {
  __typename?: 'FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyEdge = {
  __typename?: 'FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceCoverImageFileIdAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyConnection = {
  __typename?: 'FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyEdge = {
  __typename?: 'FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceDefaultCoverImageFileIdAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyConnection = {
  __typename?: 'FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyEdge = {
  __typename?: 'FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceDefaultThumbnailImageFileIdAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyConnection = {
  __typename?: 'FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyEdge = {
  __typename?: 'FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceThumbnailImageFileIdAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyConnection = {
  __typename?: 'FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyEdge = {
  __typename?: 'FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type FileGroupsByResourceTileImageFileIdAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FileNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `file` to be deleted. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `Notification` values, with data from `NotificationFile`. */
export type FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyConnection = {
  __typename?: 'FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationFile`. */
export type FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyEdge = {
  __typename?: 'FileNotificationsByNotificationFileFileIdAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnEventForEventCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnEventForEventCoverImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnEventForEventCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnEventForEventThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnEventForEventThumbnailImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnEventForEventThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileCreatedByFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnFileForFileCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnFileForFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileUpdatedByFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnFileForFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnMarqueeForMarqueeMarqueeImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `marquee` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: MarqueePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnMarqueeForMarqueeMarqueeImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnMarqueeForMarqueeMarqueeImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnNewsletterForNewsletterCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnNewsletterForNewsletterCoverImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnNewsletterForNewsletterCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnNotificationFileForNotificationFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: NotificationFilePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnNotificationFileForNotificationFileFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnNotificationFileForNotificationFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnPageSectionFileForPageSectionFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: PageSectionFilePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnPageSectionFileForPageSectionFileFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnPageSectionFileForPageSectionFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnPersonForProfileImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnPersonForProfileImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnPersonForProfileImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceFileForResourceFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: ResourceFilePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceFileForResourceFileFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceFileForResourceFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceForResourceCoverImageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceForResourceCoverImageIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceForResourceCoverImageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceForResourceDefaultCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceForResourceDefaultCoverImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceForResourceDefaultCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceForResourceDefaultThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceForResourceDefaultThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceForResourceThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceForResourceThumbnailImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceForResourceThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnResourceForResourceTileImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnResourceForResourceTileImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnResourceForResourceTileImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSponsorForSponsorCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSponsorForSponsorCoverImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSponsorForSponsorCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSponsorForSponsorLogoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSponsorForSponsorLogoImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSponsorForSponsorLogoImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSponsorForSponsorPromoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSponsorForSponsorPromoImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSponsorForSponsorPromoImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FileOnSponsorForSponsorThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `file` to look up the row to update. */
export type FileOnSponsorForSponsorThumbnailImageFileIdFkeyUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: UpdateFileOnSponsorForSponsorThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** A connection to a list of `PageSection` values, with data from `PageSectionFile`. */
export type FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyConnection = {
  __typename?: 'FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyConnection';
  /** A list of `PageSection` objects. */
  nodes: Array<PageSection>;
  /** A list of edges which contains the `PageSection`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PageSection` edge in the connection, with data from `PageSectionFile`. */
export type FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyEdge = {
  __typename?: 'FilePageSectionsByPageSectionFileFileIdAndPageSectionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageSection` at the end of the edge. */
  node: PageSection;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Sort orders are managed by the DB and only exposed for updating via custom functions. */
  sortOrder: Scalars['Int'];
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
};

/** A connection to a list of `Page` values, with data from `Newsletter`. */
export type FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyConnection = {
  __typename?: 'FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection, with data from `Newsletter`. */
export type FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyEdge = {
  __typename?: 'FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters: NewslettersConnection;
};


/** A `Page` edge in the connection, with data from `Newsletter`. */
export type FilePagesByNewsletterCoverImageFileIdAndPageIdManyToManyEdgeNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCreatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndCreatedByManyToManyEdgeEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByUpdatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventCoverImageFileIdAndUpdatedByManyToManyEdgeEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCreatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndCreatedByManyToManyEdgeEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByUpdatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type FilePeopleByEventThumbnailImageFileIdAndUpdatedByManyToManyEdgeEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByCreatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndCreatedByManyToManyEdgeMarqueesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByUpdatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type FilePeopleByMarqueeMarqueeImageFileIdAndUpdatedByManyToManyEdgeMarqueesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByCreatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndCreatedByManyToManyEdgeNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByUpdatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type FilePeopleByNewsletterCoverImageFileIdAndUpdatedByManyToManyEdgeNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByNotificationFileFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNotificationFileFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByNotificationFileFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByCreatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndCreatedByManyToManyEdgeNotificationFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByUpdatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type FilePeopleByNotificationFileFileIdAndUpdatedByManyToManyEdgeNotificationFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByCreatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndCreatedByManyToManyEdgePageSectionFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByUpdatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type FilePeopleByPageSectionFileFileIdAndUpdatedByManyToManyEdgePageSectionFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Person` values, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCreatedBy: PeopleConnection;
};


/** A `Person` edge in the connection, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndCreatedByManyToManyEdgePeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUpdatedBy: PeopleConnection;
};


/** A `Person` edge in the connection, with data from `Person`. */
export type FilePeopleByPersonProfileImageFileIdAndUpdatedByManyToManyEdgePeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceCoverImageFileIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultCoverImageFileIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceDefaultThumbnailImageFileIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceFileFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceFileFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceFileFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByCreatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndCreatedByManyToManyEdgeResourceFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceFileFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceFileFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceFileFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByUpdatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type FilePeopleByResourceFileFileIdAndUpdatedByManyToManyEdgeResourceFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceThumbnailImageFileIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type FilePeopleByResourceTileImageFileIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingCoverImageFileIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLaunchScreenPoweredByImageIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingLogoImageFileIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingMascotImageFileIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingSchoolImageFileIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type FilePeopleBySchoolSettingThumbnailImageFileIdAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorCoverImageFileIdAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorLogoImageFileIdAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorPromoImageFileIdAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyConnection = {
  __typename?: 'FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyEdge = {
  __typename?: 'FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type FilePeopleBySponsorThumbnailImageFileIdAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A generic set of file relationship types that can be used on entity_file join objects to define the relationship between the entity and the File. */
export enum FileRelationType {
  Cover = 'COVER',
  Gallery = 'GALLERY',
  Attachment = 'ATTACHMENT'
}

/** A filter to be used against FileRelationType fields. All fields are combined with a logical ‘and.’ */
export type FileRelationTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<FileRelationType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<FileRelationType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<FileRelationType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<FileRelationType>;
  /** Included in the specified list. */
  in?: Maybe<Array<FileRelationType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<FileRelationType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<FileRelationType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<FileRelationType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<FileRelationType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<FileRelationType>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyConnection = {
  __typename?: 'FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyEdge = {
  __typename?: 'FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceCoverImageFileIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyConnection = {
  __typename?: 'FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyEdge = {
  __typename?: 'FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceDefaultCoverImageFileIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyConnection = {
  __typename?: 'FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyEdge = {
  __typename?: 'FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceDefaultThumbnailImageFileIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Resource` values, with data from `ResourceFile`. */
export type FileResourcesByResourceFileFileIdAndResourceIdManyToManyConnection = {
  __typename?: 'FileResourcesByResourceFileFileIdAndResourceIdManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceFileFileIdAndResourceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `ResourceFile`. */
export type FileResourcesByResourceFileFileIdAndResourceIdManyToManyEdge = {
  __typename?: 'FileResourcesByResourceFileFileIdAndResourceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyConnection = {
  __typename?: 'FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyEdge = {
  __typename?: 'FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceThumbnailImageFileIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyConnection = {
  __typename?: 'FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyEdge = {
  __typename?: 'FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type FileResourcesByResourceTileImageFileIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `School` values, with data from `Marquee`. */
export type FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyConnection = {
  __typename?: 'FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Marquee`. */
export type FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyEdge = {
  __typename?: 'FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Marquee`. */
  marquees: MarqueesConnection;
};


/** A `School` edge in the connection, with data from `Marquee`. */
export type FileSchoolsByMarqueeMarqueeImageFileIdAndSchoolIdManyToManyEdgeMarqueesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyConnection = {
  __typename?: 'FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyEdge = {
  __typename?: 'FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorCoverImageFileIdAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyConnection = {
  __typename?: 'FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyEdge = {
  __typename?: 'FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorLogoImageFileIdAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyConnection = {
  __typename?: 'FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyEdge = {
  __typename?: 'FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorPromoImageFileIdAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyConnection = {
  __typename?: 'FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyEdge = {
  __typename?: 'FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type FileSchoolsBySponsorThumbnailImageFileIdAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

export enum FileType {
  Apng = 'APNG',
  Avif = 'AVIF',
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  SvgXml = 'SVG_XML',
  Webp = 'WEBP',
  Txt = 'TXT',
  Csv = 'CSV',
  Pdf = 'PDF',
  Doc = 'DOC',
  Docx = 'DOCX',
  Xls = 'XLS',
  Xlsx = 'XLSX',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Jpg = 'JPG',
  Pages = 'PAGES',
  Numbers = 'NUMBERS',
  Key = 'KEY'
}

/** A filter to be used against FileType fields. All fields are combined with a logical ‘and.’ */
export type FileTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<FileType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<FileType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<FileType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<FileType>;
  /** Included in the specified list. */
  in?: Maybe<Array<FileType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<FileType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<FileType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<FileType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<FileType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<FileType>;
};

/** The `file` to be created by this mutation. */
export type FileUpdatedByFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `FileInput` mutation. */
export type FileUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnFileForFileUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<FileOnFileForFileUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<FileUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `file` in the `PersonInput` mutation. */
export type FileUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<Array<FileFilePkeyConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FileNodeIdConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<Array<FileFilePkeyDelete>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FileNodeIdDelete>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<Array<FileOnFileForFileUpdatedByFkeyUsingFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnFileForFileUpdatedByFkeyNodeIdUpdate>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FileUpdatedByFkeyFileCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type FileUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  FilenameAsc = 'FILENAME_ASC',
  FilenameDesc = 'FILENAME_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  LegacyImgixPathAsc = 'LEGACY_IMGIX_PATH_ASC',
  LegacyImgixPathDesc = 'LEGACY_IMGIX_PATH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `getThenSetAppVersionForBuild` mutation. */
export type GetThenSetAppVersionForBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildId: Scalars['UUID'];
};

/** The output of our `getThenSetAppVersionForBuild` mutation. */
export type GetThenSetAppVersionForBuildPayload = {
  __typename?: 'GetThenSetAppVersionForBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccount = Node & {
  __typename?: 'GoogleAccount';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncs: GoogleCalendarSyncsConnection;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccounts: UserGoogleAccountsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncGoogleAccountIdAndCreatedBy: GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedBy: GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUserGoogleAccountGoogleAccountIdAndCreatedBy: GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUserGoogleAccountGoogleAccountIdAndUpdatedBy: GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyConnection;
  googleCalendars: Array<Maybe<GoogleCalendar>>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountGoogleCalendarSyncsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountUserGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Join object to connect `Calendar`s to a `CalendarGroup`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `GoogleAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GoogleAccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `pictureUrl` field. */
  pictureUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `refreshToken` field. */
  refreshToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tokenExpiry` field. */
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `errorState` field. */
  errorState?: Maybe<Scalars['String']>;
};

/** The `googleAccount` to be created by this mutation. */
export type GoogleAccountCreatedByFkeyGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googleAccount` in the `PersonInput` mutation. */
export type GoogleAccountCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleAccountGoogleAccountPkeyConnect>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleAccountNodeIdConnect>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleAccountGoogleAccountPkeyDelete>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleAccountOnGoogleAccountForGoogleAccountCreatedByFkeyUsingGoogleAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleAccountForGoogleAccountCreatedByFkeyNodeIdUpdate>>;
  /** A `GoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleAccountCreatedByFkeyGoogleAccountCreateInput>>;
};

/** A filter to be used against `GoogleAccount` object types. All fields are combined with a logical ‘and.’ */
export type GoogleAccountFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: Maybe<StringFilter>;
  /** Filter by the object’s `pictureUrl` field. */
  pictureUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `refreshToken` field. */
  refreshToken?: Maybe<StringFilter>;
  /** Filter by the object’s `tokenExpiry` field. */
  tokenExpiry?: Maybe<BigIntFilter>;
  /** Filter by the object’s `errorState` field. */
  errorState?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GoogleAccountFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GoogleAccountFilter>>;
  /** Negates the expression. */
  not?: Maybe<GoogleAccountFilter>;
};

/** The fields on `googleAccount` to look up the row to connect. */
export type GoogleAccountGoogleAccountPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `googleAccount` to look up the row to delete. */
export type GoogleAccountGoogleAccountPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GoogleAccount` */
export type GoogleAccountInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GoogleAccountNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GoogleAccountNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `googleAccount` to look up the row to update. */
export type GoogleAccountOnGoogleAccountForGoogleAccountCreatedByFkeyUsingGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: UpdateGoogleAccountOnGoogleAccountForGoogleAccountCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googleAccount` to look up the row to update. */
export type GoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyUsingGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: UpdateGoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
};

/** The fields on `googleAccount` to look up the row to update. */
export type GoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyUsingGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: UpdateGoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userGoogleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
};

/** The fields on `googleAccount` to look up the row to update. */
export type GoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: UpdateGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GoogleAccount`. Fields that are set will be updated. */
export type GoogleAccountPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyConnection = {
  __typename?: 'GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyEdge = {
  __typename?: 'GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByCreatedBy: GoogleCalendarSyncsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndCreatedByManyToManyEdgeGoogleCalendarSyncsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByUpdatedBy: GoogleCalendarSyncsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type GoogleAccountPeopleByGoogleCalendarSyncGoogleAccountIdAndUpdatedByManyToManyEdgeGoogleCalendarSyncsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `Person` values, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyConnection = {
  __typename?: 'GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyEdge = {
  __typename?: 'GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByCreatedBy: UserGoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndCreatedByManyToManyEdgeUserGoogleAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByUpdatedBy: UserGoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type GoogleAccountPeopleByUserGoogleAccountGoogleAccountIdAndUpdatedByManyToManyEdgeUserGoogleAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** The `googleAccount` to be created by this mutation. */
export type GoogleAccountUpdatedByFkeyGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GoogleAccountInput` mutation. */
export type GoogleAccountUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGoogleAccountForGoogleAccountUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleAccountUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googleAccount` in the `PersonInput` mutation. */
export type GoogleAccountUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleAccountGoogleAccountPkeyConnect>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleAccountNodeIdConnect>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleAccountGoogleAccountPkeyDelete>>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyUsingGoogleAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleAccountForGoogleAccountUpdatedByFkeyNodeIdUpdate>>;
  /** A `GoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleAccountUpdatedByFkeyGoogleAccountCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GoogleAccountUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `GoogleAccount` values. */
export type GoogleAccountsConnection = {
  __typename?: 'GoogleAccountsConnection';
  /** A list of `GoogleAccount` objects. */
  nodes: Array<GoogleAccount>;
  /** A list of edges which contains the `GoogleAccount` and cursor to aid in pagination. */
  edges: Array<GoogleAccountsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccount` edge in the connection. */
export type GoogleAccountsEdge = {
  __typename?: 'GoogleAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccount` at the end of the edge. */
  node: GoogleAccount;
};

/** Methods to use when ordering `GoogleAccount`. */
export enum GoogleAccountsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  PictureUrlAsc = 'PICTURE_URL_ASC',
  PictureUrlDesc = 'PICTURE_URL_DESC',
  RefreshTokenAsc = 'REFRESH_TOKEN_ASC',
  RefreshTokenDesc = 'REFRESH_TOKEN_DESC',
  TokenExpiryAsc = 'TOKEN_EXPIRY_ASC',
  TokenExpiryDesc = 'TOKEN_EXPIRY_DESC',
  ErrorStateAsc = 'ERROR_STATE_ASC',
  ErrorStateDesc = 'ERROR_STATE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type GoogleCalendar = {
  __typename?: 'GoogleCalendar';
  accessRole: Scalars['String'];
  backgroundColor?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  summary: Scalars['String'];
  summaryOverride?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
};

/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSync = Node & {
  __typename?: 'GoogleCalendarSync';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status: GoogleCalendarSyncStatus;
  googleAccountId: Scalars['UUID'];
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleAccount` that is related to this `GoogleCalendarSync`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
  /** All events synced from this Google Calendar Sync, across all `Calendar`s. */
  syncedEvents: EventsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedBy: GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedBy: GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarId: GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyConnection;
};


/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSyncGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};


/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSyncSyncedEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<EventFilter>;
};


/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The base for syncing a Google calendar to Calendars in Legit Apps. (Calendars are included with join objects.) */
export type GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** Join object to connect a `GoogleCalendarSync` to a `Calendar`. */
export type GoogleCalendarSyncCalendar = Node & {
  __typename?: 'GoogleCalendarSyncCalendar';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleCalendarSync` that is related to this `GoogleCalendarSyncCalendar`. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Reads a single `Calendar` that is related to this `GoogleCalendarSyncCalendar`. */
  calendar?: Maybe<Calendar>;
};

/** The `calendar` to be created by this mutation. */
export type GoogleCalendarSyncCalendarCalendarIdFkeyCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** The `googleCalendarSyncCalendar` to be created by this mutation. */
export type GoogleCalendarSyncCalendarCalendarIdFkeyGoogleCalendarSyncCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** Input for the nested mutation of `calendar` in the `GoogleCalendarSyncCalendarInput` mutation. */
export type GoogleCalendarSyncCalendarCalendarIdFkeyInput = {
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectById?: Maybe<CalendarCalendarPkeyConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<CalendarNodeIdConnect>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteById?: Maybe<CalendarCalendarPkeyDelete>;
  /** The primary key(s) for `calendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CalendarNodeIdDelete>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateById?: Maybe<CalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyUsingCalendarPkeyUpdate>;
  /** The primary key(s) and patch data for `calendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyNodeIdUpdate>;
  /** A `CalendarInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyCalendarCreateInput>;
};

/** Input for the nested mutation of `googleCalendarSyncCalendar` in the `CalendarInput` mutation. */
export type GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSyncCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncCalendarCalendarIdFkeyGoogleCalendarSyncCalendarCreateInput>>;
};

/**
 * A condition to be used against `GoogleCalendarSyncCalendar` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type GoogleCalendarSyncCalendarCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googleCalendarSyncId` field. */
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `calendarId` field. */
  calendarId?: Maybe<Scalars['UUID']>;
};

/** The `googleCalendarSyncCalendar` to be created by this mutation. */
export type GoogleCalendarSyncCalendarCreatedByFkeyGoogleCalendarSyncCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** Input for the nested mutation of `googleCalendarSyncCalendar` in the `PersonInput` mutation. */
export type GoogleCalendarSyncCalendarCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSyncCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncCalendarCreatedByFkeyGoogleCalendarSyncCalendarCreateInput>>;
};

/** A filter to be used against `GoogleCalendarSyncCalendar` object types. All fields are combined with a logical ‘and.’ */
export type GoogleCalendarSyncCalendarFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `googleCalendarSyncId` field. */
  googleCalendarSyncId?: Maybe<UuidFilter>;
  /** Filter by the object’s `calendarId` field. */
  calendarId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GoogleCalendarSyncCalendarFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GoogleCalendarSyncCalendarFilter>>;
  /** Negates the expression. */
  not?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to connect. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to delete. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyDelete = {
  id: Scalars['UUID'];
};

/** The `googleCalendarSyncCalendar` to be created by this mutation. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyGoogleCalendarSyncCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** The `googleCalendarSync` to be created by this mutation. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyGoogleCalendarSyncCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googleCalendarSync` in the `GoogleCalendarSyncCalendarInput` mutation. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput = {
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectById?: Maybe<GoogleCalendarSyncGoogleCalendarSyncPkeyConnect>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectByNodeId?: Maybe<GoogleCalendarSyncNodeIdConnect>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteById?: Maybe<GoogleCalendarSyncGoogleCalendarSyncPkeyDelete>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GoogleCalendarSyncNodeIdDelete>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateById?: Maybe<GoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyUsingGoogleCalendarSyncPkeyUpdate>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyNodeIdUpdate>;
  /** A `GoogleCalendarSyncInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyGoogleCalendarSyncCreateInput>;
};

/** Input for the nested mutation of `googleCalendarSyncCalendar` in the `GoogleCalendarSyncInput` mutation. */
export type GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSyncCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyGoogleCalendarSyncCalendarCreateInput>>;
};

/** An input for mutations affecting `GoogleCalendarSyncCalendar` */
export type GoogleCalendarSyncCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GoogleCalendarSyncCalendarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GoogleCalendarSyncCalendarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googleCalendarSyncCalendar` to look up the row to update. */
export type GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GoogleCalendarSyncCalendar`. Fields that are set will be updated. */
export type GoogleCalendarSyncCalendarPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** The `googleCalendarSyncCalendar` to be created by this mutation. */
export type GoogleCalendarSyncCalendarUpdatedByFkeyGoogleCalendarSyncCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** Input for the nested mutation of `person` in the `GoogleCalendarSyncCalendarInput` mutation. */
export type GoogleCalendarSyncCalendarUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googleCalendarSyncCalendar` in the `PersonInput` mutation. */
export type GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSyncCalendar` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncCalendarGoogleCalendarSyncCalendarPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSyncCalendar` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncCalendarNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyUsingGoogleCalendarSyncCalendarPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSyncCalendar` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncCalendarInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncCalendarUpdatedByFkeyGoogleCalendarSyncCalendarCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GoogleCalendarSyncCalendarUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Calendar` values, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyConnection = {
  __typename?: 'GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyEdge = {
  __typename?: 'GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncCalendarsByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCalendarIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `GoogleCalendarSyncCalendar` values. */
export type GoogleCalendarSyncCalendarsConnection = {
  __typename?: 'GoogleCalendarSyncCalendarsConnection';
  /** A list of `GoogleCalendarSyncCalendar` objects. */
  nodes: Array<GoogleCalendarSyncCalendar>;
  /** A list of edges which contains the `GoogleCalendarSyncCalendar` and cursor to aid in pagination. */
  edges: Array<GoogleCalendarSyncCalendarsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarSyncCalendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarSyncCalendar` edge in the connection. */
export type GoogleCalendarSyncCalendarsEdge = {
  __typename?: 'GoogleCalendarSyncCalendarsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarSyncCalendar` at the end of the edge. */
  node: GoogleCalendarSyncCalendar;
};

/** Methods to use when ordering `GoogleCalendarSyncCalendar`. */
export enum GoogleCalendarSyncCalendarsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  GoogleCalendarSyncIdAsc = 'GOOGLE_CALENDAR_SYNC_ID_ASC',
  GoogleCalendarSyncIdDesc = 'GOOGLE_CALENDAR_SYNC_ID_DESC',
  CalendarIdAsc = 'CALENDAR_ID_ASC',
  CalendarIdDesc = 'CALENDAR_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `GoogleCalendarSync` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GoogleCalendarSyncCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googleCalendarId` field. */
  googleCalendarId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googleCalendarSummary` field. */
  googleCalendarSummary?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googleCalendarColor` field. */
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  /** Checks for equality with the object’s `lastSyncedAt` field. */
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `syncedUpTo` field. */
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<GoogleCalendarSyncStatus>;
  /** Checks for equality with the object’s `googleAccountId` field. */
  googleAccountId?: Maybe<Scalars['UUID']>;
};

/** The `googleCalendarSync` to be created by this mutation. */
export type GoogleCalendarSyncCreatedByFkeyGoogleCalendarSyncCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googleCalendarSync` in the `PersonInput` mutation. */
export type GoogleCalendarSyncCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSync` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyUsingGoogleCalendarSyncPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncCreatedByFkeyGoogleCalendarSyncCreateInput>>;
};

/** A filter to be used against `GoogleCalendarSync` object types. All fields are combined with a logical ‘and.’ */
export type GoogleCalendarSyncFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `googleCalendarId` field. */
  googleCalendarId?: Maybe<StringFilter>;
  /** Filter by the object’s `googleCalendarSummary` field. */
  googleCalendarSummary?: Maybe<StringFilter>;
  /** Filter by the object’s `googleCalendarColor` field. */
  googleCalendarColor?: Maybe<HexColorFilter>;
  /** Filter by the object’s `lastSyncedAt` field. */
  lastSyncedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `syncedUpTo` field. */
  syncedUpTo?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<GoogleCalendarSyncStatusFilter>;
  /** Filter by the object’s `googleAccountId` field. */
  googleAccountId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GoogleCalendarSyncFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GoogleCalendarSyncFilter>>;
  /** Negates the expression. */
  not?: Maybe<GoogleCalendarSyncFilter>;
};

/** The `googleAccount` to be created by this mutation. */
export type GoogleCalendarSyncGoogleAccountIdFkeyGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** The `googleCalendarSync` to be created by this mutation. */
export type GoogleCalendarSyncGoogleAccountIdFkeyGoogleCalendarSyncCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googleAccount` in the `GoogleCalendarSyncInput` mutation. */
export type GoogleCalendarSyncGoogleAccountIdFkeyInput = {
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectById?: Maybe<GoogleAccountGoogleAccountPkeyConnect>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<GoogleAccountNodeIdConnect>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteById?: Maybe<GoogleAccountGoogleAccountPkeyDelete>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GoogleAccountNodeIdDelete>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateById?: Maybe<GoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyUsingGoogleAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyNodeIdUpdate>;
  /** A `GoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyGoogleAccountCreateInput>;
};

/** Input for the nested mutation of `googleCalendarSync` in the `GoogleAccountInput` mutation. */
export type GoogleCalendarSyncGoogleAccountIdFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSync` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyUsingGoogleCalendarSyncPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncGoogleAccountIdFkeyGoogleCalendarSyncCreateInput>>;
};

/** The fields on `googleCalendarSync` to look up the row to connect. */
export type GoogleCalendarSyncGoogleCalendarSyncPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `googleCalendarSync` to look up the row to delete. */
export type GoogleCalendarSyncGoogleCalendarSyncPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GoogleCalendarSync` */
export type GoogleCalendarSyncInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GoogleCalendarSyncNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GoogleCalendarSyncNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
};

/** The fields on `googleCalendarSync` to look up the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyUsingGoogleCalendarSyncPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: UpdateGoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `googleCalendarSync` to look up the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyUsingGoogleCalendarSyncPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: GoogleAccountPatch;
};

/** The fields on `googleCalendarSync` to look up the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyUsingGoogleCalendarSyncPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googleCalendarSync` to look up the row to update. */
export type GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyUsingGoogleCalendarSyncPkeyUpdate = {
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GoogleCalendarSync`. Fields that are set will be updated. */
export type GoogleCalendarSyncPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyConnection = {
  __typename?: 'GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyEdge = {
  __typename?: 'GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByCreatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndCreatedByManyToManyEdgeGoogleCalendarSyncCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByUpdatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type GoogleCalendarSyncPeopleByGoogleCalendarSyncCalendarGoogleCalendarSyncIdAndUpdatedByManyToManyEdgeGoogleCalendarSyncCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

export enum GoogleCalendarSyncStatus {
  Idle = 'IDLE',
  Syncing = 'SYNCING'
}

/** A filter to be used against GoogleCalendarSyncStatus fields. All fields are combined with a logical ‘and.’ */
export type GoogleCalendarSyncStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<GoogleCalendarSyncStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<GoogleCalendarSyncStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<GoogleCalendarSyncStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<GoogleCalendarSyncStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<GoogleCalendarSyncStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<GoogleCalendarSyncStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<GoogleCalendarSyncStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<GoogleCalendarSyncStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<GoogleCalendarSyncStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<GoogleCalendarSyncStatus>;
};

/** The `googleCalendarSync` to be created by this mutation. */
export type GoogleCalendarSyncUpdatedByFkeyGoogleCalendarSyncCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleCalendarId: Scalars['String'];
  googleCalendarSummary: Scalars['String'];
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GoogleCalendarSyncInput` mutation. */
export type GoogleCalendarSyncUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GoogleCalendarSyncUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googleCalendarSync` in the `PersonInput` mutation. */
export type GoogleCalendarSyncUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googleCalendarSync` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdConnect>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteById?: Maybe<Array<GoogleCalendarSyncGoogleCalendarSyncPkeyDelete>>;
  /** The primary key(s) for `googleCalendarSync` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GoogleCalendarSyncNodeIdDelete>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateById?: Maybe<Array<GoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyUsingGoogleCalendarSyncPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarSync` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyNodeIdUpdate>>;
  /** A `GoogleCalendarSyncInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GoogleCalendarSyncUpdatedByFkeyGoogleCalendarSyncCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GoogleCalendarSyncUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `GoogleCalendarSync` values. */
export type GoogleCalendarSyncsConnection = {
  __typename?: 'GoogleCalendarSyncsConnection';
  /** A list of `GoogleCalendarSync` objects. */
  nodes: Array<GoogleCalendarSync>;
  /** A list of edges which contains the `GoogleCalendarSync` and cursor to aid in pagination. */
  edges: Array<GoogleCalendarSyncsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarSync` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarSync` edge in the connection. */
export type GoogleCalendarSyncsEdge = {
  __typename?: 'GoogleCalendarSyncsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarSync` at the end of the edge. */
  node: GoogleCalendarSync;
};

/** Methods to use when ordering `GoogleCalendarSync`. */
export enum GoogleCalendarSyncsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  GoogleCalendarIdAsc = 'GOOGLE_CALENDAR_ID_ASC',
  GoogleCalendarIdDesc = 'GOOGLE_CALENDAR_ID_DESC',
  GoogleCalendarSummaryAsc = 'GOOGLE_CALENDAR_SUMMARY_ASC',
  GoogleCalendarSummaryDesc = 'GOOGLE_CALENDAR_SUMMARY_DESC',
  GoogleCalendarColorAsc = 'GOOGLE_CALENDAR_COLOR_ASC',
  GoogleCalendarColorDesc = 'GOOGLE_CALENDAR_COLOR_DESC',
  LastSyncedAtAsc = 'LAST_SYNCED_AT_ASC',
  LastSyncedAtDesc = 'LAST_SYNCED_AT_DESC',
  SyncedUpToAsc = 'SYNCED_UP_TO_ASC',
  SyncedUpToDesc = 'SYNCED_UP_TO_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  GoogleAccountIdAsc = 'GOOGLE_ACCOUNT_ID_ASC',
  GoogleAccountIdDesc = 'GOOGLE_ACCOUNT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * This table holds the info related to the Google Developer account. This table is essential currently, while the `google_play_user` table is not.
 *
 * This google play account has a 1-many relationship with google play listing where 1 google play account can have many listings (and listings have one android app).
 */
export type GooglePlayAccount = Node & {
  __typename?: 'GooglePlayAccount';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  /** Reads a single `GooglePlayUser` that is related to this `GooglePlayAccount`. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListings: GooglePlayListingsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayListingGooglePlayAccountIdAndCreatedBy: GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayListingGooglePlayAccountIdAndUpdatedBy: GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyConnection;
};


/**
 * This table holds the info related to the Google Developer account. This table is essential currently, while the `google_play_user` table is not.
 *
 * This google play account has a 1-many relationship with google play listing where 1 google play account can have many listings (and listings have one android app).
 */
export type GooglePlayAccountGooglePlayListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/**
 * This table holds the info related to the Google Developer account. This table is essential currently, while the `google_play_user` table is not.
 *
 * This google play account has a 1-many relationship with google play listing where 1 google play account can have many listings (and listings have one android app).
 */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * This table holds the info related to the Google Developer account. This table is essential currently, while the `google_play_user` table is not.
 *
 * This google play account has a 1-many relationship with google play listing where 1 google play account can have many listings (and listings have one android app).
 */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `GooglePlayAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GooglePlayAccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googlePlayUserId` field. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `googlePlaysProprietaryDeveloperId` field. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googlePlayDeveloperName` field. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `apiKeyJson` field. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `apiKeyUrl` field. */
  apiKeyUrl?: Maybe<Scalars['String']>;
};

/** The `googlePlayAccount` to be created by this mutation. */
export type GooglePlayAccountCreatedByFkeyGooglePlayAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayAccount` in the `PersonInput` mutation. */
export type GooglePlayAccountCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayAccountNodeIdConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyDelete>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyUsingGooglePlayAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayAccountCreatedByFkeyGooglePlayAccountCreateInput>>;
};

/** A filter to be used against `GooglePlayAccount` object types. All fields are combined with a logical ‘and.’ */
export type GooglePlayAccountFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `googlePlayUserId` field. */
  googlePlayUserId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `googlePlaysProprietaryDeveloperId` field. */
  googlePlaysProprietaryDeveloperId?: Maybe<StringFilter>;
  /** Filter by the object’s `googlePlayDeveloperName` field. */
  googlePlayDeveloperName?: Maybe<StringFilter>;
  /** Filter by the object’s `apiKeyJson` field. */
  apiKeyJson?: Maybe<JsonFilter>;
  /** Filter by the object’s `apiKeyUrl` field. */
  apiKeyUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GooglePlayAccountFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GooglePlayAccountFilter>>;
  /** Negates the expression. */
  not?: Maybe<GooglePlayAccountFilter>;
};

/** The fields on `googlePlayAccount` to look up the row to connect. */
export type GooglePlayAccountGooglePlayAccountPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `googlePlayAccount` to look up the row to delete. */
export type GooglePlayAccountGooglePlayAccountPkeyDelete = {
  id: Scalars['UUID'];
};

/** The `googlePlayAccount` to be created by this mutation. */
export type GooglePlayAccountGooglePlayUserIdFkeyGooglePlayAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** The `googlePlayUser` to be created by this mutation. */
export type GooglePlayAccountGooglePlayUserIdFkeyGooglePlayUserCreateInput = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayUser` in the `GooglePlayAccountInput` mutation. */
export type GooglePlayAccountGooglePlayUserIdFkeyInput = {
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectById?: Maybe<GooglePlayUserGooglePlayUserPkeyConnect>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByEmail?: Maybe<GooglePlayUserGooglePlayUserEmailKeyConnect>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<GooglePlayUserNodeIdConnect>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteById?: Maybe<GooglePlayUserGooglePlayUserPkeyDelete>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<GooglePlayUserGooglePlayUserEmailKeyDelete>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GooglePlayUserNodeIdDelete>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateById?: Maybe<GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayUserPkeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByEmail?: Maybe<GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyNodeIdUpdate>;
  /** A `GooglePlayUserInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyGooglePlayUserCreateInput>;
};

/** Input for the nested mutation of `googlePlayAccount` in the `GooglePlayUserInput` mutation. */
export type GooglePlayAccountGooglePlayUserIdFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayAccountNodeIdConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyDelete>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyNodeIdUpdate>>;
  /** A `GooglePlayAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayAccountGooglePlayUserIdFkeyGooglePlayAccountCreateInput>>;
};

/** An input for mutations affecting `GooglePlayAccount` */
export type GooglePlayAccountInput = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GooglePlayAccountNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GooglePlayAccountNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `googlePlayAccount` to look up the row to update. */
export type GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyUsingGooglePlayAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: GooglePlayUserPatch;
};

/** The fields on `googlePlayAccount` to look up the row to update. */
export type GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googlePlayAccount` to look up the row to update. */
export type GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyUsingGooglePlayAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** The fields on `googlePlayAccount` to look up the row to update. */
export type GooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: UpdateGooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GooglePlayAccount`. Fields that are set will be updated. */
export type GooglePlayAccountPatch = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyConnection = {
  __typename?: 'GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyEdge = {
  __typename?: 'GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByCreatedBy: GooglePlayListingsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndCreatedByManyToManyEdgeGooglePlayListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByUpdatedBy: GooglePlayListingsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type GooglePlayAccountPeopleByGooglePlayListingGooglePlayAccountIdAndUpdatedByManyToManyEdgeGooglePlayListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** The `googlePlayAccount` to be created by this mutation. */
export type GooglePlayAccountUpdatedByFkeyGooglePlayAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GooglePlayAccountInput` mutation. */
export type GooglePlayAccountUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayAccountUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googlePlayAccount` in the `PersonInput` mutation. */
export type GooglePlayAccountUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayAccountNodeIdConnect>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayAccountGooglePlayAccountPkeyDelete>>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyUsingGooglePlayAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayAccountUpdatedByFkeyGooglePlayAccountCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GooglePlayAccountUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `GooglePlayAccount` values. */
export type GooglePlayAccountsConnection = {
  __typename?: 'GooglePlayAccountsConnection';
  /** A list of `GooglePlayAccount` objects. */
  nodes: Array<GooglePlayAccount>;
  /** A list of edges which contains the `GooglePlayAccount` and cursor to aid in pagination. */
  edges: Array<GooglePlayAccountsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayAccount` edge in the connection. */
export type GooglePlayAccountsEdge = {
  __typename?: 'GooglePlayAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayAccount` at the end of the edge. */
  node: GooglePlayAccount;
};

/** Methods to use when ordering `GooglePlayAccount`. */
export enum GooglePlayAccountsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GooglePlayUserIdAsc = 'GOOGLE_PLAY_USER_ID_ASC',
  GooglePlayUserIdDesc = 'GOOGLE_PLAY_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  GooglePlaysProprietaryDeveloperIdAsc = 'GOOGLE_PLAYS_PROPRIETARY_DEVELOPER_ID_ASC',
  GooglePlaysProprietaryDeveloperIdDesc = 'GOOGLE_PLAYS_PROPRIETARY_DEVELOPER_ID_DESC',
  GooglePlayDeveloperNameAsc = 'GOOGLE_PLAY_DEVELOPER_NAME_ASC',
  GooglePlayDeveloperNameDesc = 'GOOGLE_PLAY_DEVELOPER_NAME_DESC',
  ApiKeyJsonAsc = 'API_KEY_JSON_ASC',
  ApiKeyJsonDesc = 'API_KEY_JSON_DESC',
  ApiKeyUrlAsc = 'API_KEY_URL_ASC',
  ApiKeyUrlDesc = 'API_KEY_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * Storing Google Play Listing on a row of this table. \n\n
 *
 *  Google Play Listings have a 1-1 relationship with Android Apps, and a many-1 relationship with Google Play Accounts (where many listings can have the same account). \n\n
 */
export type GooglePlayListing = Node & {
  __typename?: 'GooglePlayListing';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  /** Reads a single `AndroidApp` that is related to this `GooglePlayListing`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `GooglePlayAccount` that is related to this `GooglePlayListing`. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshots: AndroidScreenshotsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidScreenshotGooglePlayListingIdAndCreatedBy: GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidScreenshotGooglePlayListingIdAndUpdatedBy: GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyConnection;
};


/**
 * Storing Google Play Listing on a row of this table. \n\n
 *
 *  Google Play Listings have a 1-1 relationship with Android Apps, and a many-1 relationship with Google Play Accounts (where many listings can have the same account). \n\n
 */
export type GooglePlayListingAndroidScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};


/**
 * Storing Google Play Listing on a row of this table. \n\n
 *
 *  Google Play Listings have a 1-1 relationship with Android Apps, and a many-1 relationship with Google Play Accounts (where many listings can have the same account). \n\n
 */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Storing Google Play Listing on a row of this table. \n\n
 *
 *  Google Play Listings have a 1-1 relationship with Android Apps, and a many-1 relationship with Google Play Accounts (where many listings can have the same account). \n\n
 */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** The `androidApp` to be created by this mutation. */
export type GooglePlayListingAndroidAppIdFkeyAndroidAppCreateInput = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** The `googlePlayListing` to be created by this mutation. */
export type GooglePlayListingAndroidAppIdFkeyGooglePlayListingCreateInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `androidApp` in the `GooglePlayListingInput` mutation. */
export type GooglePlayListingAndroidAppIdFkeyInput = {
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectById?: Maybe<AndroidAppAndroidAppPkeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AndroidAppNodeIdConnect>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteById?: Maybe<AndroidAppAndroidAppPkeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<AndroidAppAndroidAppAppIdKeyDelete>;
  /** The primary key(s) for `androidApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AndroidAppNodeIdDelete>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateById?: Maybe<AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingAndroidAppPkeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByAppId?: Maybe<AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingAndroidAppAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `androidApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyNodeIdUpdate>;
  /** A `AndroidAppInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayListingAndroidAppIdFkeyAndroidAppCreateInput>;
};

/** Input for the nested mutation of `googlePlayListing` in the `AndroidAppInput` mutation. */
export type GooglePlayListingAndroidAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectById?: Maybe<GooglePlayListingGooglePlayListingPkeyConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByAndroidAppId?: Maybe<GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<GooglePlayListingNodeIdConnect>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteById?: Maybe<GooglePlayListingGooglePlayListingPkeyDelete>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByAndroidAppId?: Maybe<GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GooglePlayListingNodeIdDelete>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateById?: Maybe<GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingGooglePlayListingPkeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByAndroidAppId?: Maybe<GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<AndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyNodeIdUpdate>;
  /** A `GooglePlayListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayListingAndroidAppIdFkeyGooglePlayListingCreateInput>>;
};

/**
 * A condition to be used against `GooglePlayListing` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GooglePlayListingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `androidAppId` field. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googlePlayAccountId` field. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `googlePlaysProprietaryAppId` field. */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appName` field. */
  appName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortDescription` field. */
  shortDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullDescription` field. */
  fullDescription?: Maybe<Scalars['String']>;
};

/** The `googlePlayListing` to be created by this mutation. */
export type GooglePlayListingCreatedByFkeyGooglePlayListingCreateInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayListing` in the `PersonInput` mutation. */
export type GooglePlayListingCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayListingNodeIdConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyUsingGooglePlayListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByAndroidAppId?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayListingForGooglePlayListingCreatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayListingCreatedByFkeyGooglePlayListingCreateInput>>;
};

/** A filter to be used against `GooglePlayListing` object types. All fields are combined with a logical ‘and.’ */
export type GooglePlayListingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `androidAppId` field. */
  androidAppId?: Maybe<UuidFilter>;
  /** Filter by the object’s `googlePlayAccountId` field. */
  googlePlayAccountId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `googlePlaysProprietaryAppId` field. */
  googlePlaysProprietaryAppId?: Maybe<StringFilter>;
  /** Filter by the object’s `appName` field. */
  appName?: Maybe<StringFilter>;
  /** Filter by the object’s `shortDescription` field. */
  shortDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `fullDescription` field. */
  fullDescription?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GooglePlayListingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GooglePlayListingFilter>>;
  /** Negates the expression. */
  not?: Maybe<GooglePlayListingFilter>;
};

/** The `googlePlayAccount` to be created by this mutation. */
export type GooglePlayListingGooglePlayAccountIdFkeyGooglePlayAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** The `googlePlayListing` to be created by this mutation. */
export type GooglePlayListingGooglePlayAccountIdFkeyGooglePlayListingCreateInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayAccount` in the `GooglePlayListingInput` mutation. */
export type GooglePlayListingGooglePlayAccountIdFkeyInput = {
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectById?: Maybe<GooglePlayAccountGooglePlayAccountPkeyConnect>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<GooglePlayAccountNodeIdConnect>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteById?: Maybe<GooglePlayAccountGooglePlayAccountPkeyDelete>;
  /** The primary key(s) for `googlePlayAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GooglePlayAccountNodeIdDelete>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateById?: Maybe<GooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `googlePlayAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyNodeIdUpdate>;
  /** A `GooglePlayAccountInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyGooglePlayAccountCreateInput>;
};

/** Input for the nested mutation of `googlePlayListing` in the `GooglePlayAccountInput` mutation. */
export type GooglePlayListingGooglePlayAccountIdFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayListingNodeIdConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByAndroidAppId?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyNodeIdUpdate>>;
  /** A `GooglePlayListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayListingGooglePlayAccountIdFkeyGooglePlayListingCreateInput>>;
};

/** The fields on `googlePlayListing` to look up the row to connect. */
export type GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect = {
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to delete. */
export type GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete = {
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to connect. */
export type GooglePlayListingGooglePlayListingPkeyConnect = {
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to delete. */
export type GooglePlayListingGooglePlayListingPkeyDelete = {
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GooglePlayListing` */
export type GooglePlayListingInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GooglePlayListingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GooglePlayListingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: AndroidScreenshotPatch;
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyUsingGooglePlayListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: AndroidAppPatch;
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyUsingGooglePlayListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyUsingGooglePlayListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyUsingGooglePlayListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** The fields on `googlePlayListing` to look up the row to update. */
export type GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingGooglePlayListingPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** Represents an update to a `GooglePlayListing`. Fields that are set will be updated. */
export type GooglePlayListingPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyConnection = {
  __typename?: 'GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyEdge = {
  __typename?: 'GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByCreatedBy: AndroidScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndCreatedByManyToManyEdgeAndroidScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByUpdatedBy: AndroidScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type GooglePlayListingPeopleByAndroidScreenshotGooglePlayListingIdAndUpdatedByManyToManyEdgeAndroidScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** The `googlePlayListing` to be created by this mutation. */
export type GooglePlayListingUpdatedByFkeyGooglePlayListingCreateInput = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GooglePlayListingInput` mutation. */
export type GooglePlayListingUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayListingUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googlePlayListing` in the `PersonInput` mutation. */
export type GooglePlayListingUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayListing` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayListingNodeIdConnect>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayListingGooglePlayListingPkeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByAndroidAppId?: Maybe<Array<GooglePlayListingGooglePlayListingAndroidAppIdKeyDelete>>;
  /** The primary key(s) for `googlePlayListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayListingNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingGooglePlayListingPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByAndroidAppId?: Maybe<Array<GooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingGooglePlayListingAndroidAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayListingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayListingUpdatedByFkeyGooglePlayListingCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GooglePlayListingUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `GooglePlayListing` values. */
export type GooglePlayListingsConnection = {
  __typename?: 'GooglePlayListingsConnection';
  /** A list of `GooglePlayListing` objects. */
  nodes: Array<GooglePlayListing>;
  /** A list of edges which contains the `GooglePlayListing` and cursor to aid in pagination. */
  edges: Array<GooglePlayListingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayListing` edge in the connection. */
export type GooglePlayListingsEdge = {
  __typename?: 'GooglePlayListingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayListing` at the end of the edge. */
  node: GooglePlayListing;
};

/** Methods to use when ordering `GooglePlayListing`. */
export enum GooglePlayListingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AndroidAppIdAsc = 'ANDROID_APP_ID_ASC',
  AndroidAppIdDesc = 'ANDROID_APP_ID_DESC',
  GooglePlayAccountIdAsc = 'GOOGLE_PLAY_ACCOUNT_ID_ASC',
  GooglePlayAccountIdDesc = 'GOOGLE_PLAY_ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  GooglePlaysProprietaryAppIdAsc = 'GOOGLE_PLAYS_PROPRIETARY_APP_ID_ASC',
  GooglePlaysProprietaryAppIdDesc = 'GOOGLE_PLAYS_PROPRIETARY_APP_ID_DESC',
  AppNameAsc = 'APP_NAME_ASC',
  AppNameDesc = 'APP_NAME_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  FullDescriptionAsc = 'FULL_DESCRIPTION_ASC',
  FullDescriptionDesc = 'FULL_DESCRIPTION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** I'm pretty sure this table only exists so that Selenium can log in to Google Play Console, and this exists as a table only so that in the future we can transition to other accounts authing? */
export type GooglePlayUser = Node & {
  __typename?: 'GooglePlayUser';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccounts: GooglePlayAccountsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayAccountGooglePlayUserIdAndCreatedBy: GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayAccountGooglePlayUserIdAndUpdatedBy: GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyConnection;
};


/** I'm pretty sure this table only exists so that Selenium can log in to Google Play Console, and this exists as a table only so that in the future we can transition to other accounts authing? */
export type GooglePlayUserGooglePlayAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/** I'm pretty sure this table only exists so that Selenium can log in to Google Play Console, and this exists as a table only so that in the future we can transition to other accounts authing? */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** I'm pretty sure this table only exists so that Selenium can log in to Google Play Console, and this exists as a table only so that in the future we can transition to other accounts authing? */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `GooglePlayUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GooglePlayUserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `password` field. */
  password?: Maybe<Scalars['String']>;
};

/** The `googlePlayUser` to be created by this mutation. */
export type GooglePlayUserCreatedByFkeyGooglePlayUserCreateInput = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googlePlayUser` in the `PersonInput` mutation. */
export type GooglePlayUserCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayUser` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayUserGooglePlayUserPkeyConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByEmail?: Maybe<Array<GooglePlayUserGooglePlayUserEmailKeyConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayUserNodeIdConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayUserGooglePlayUserPkeyDelete>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<Array<GooglePlayUserGooglePlayUserEmailKeyDelete>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayUserNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyUsingGooglePlayUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByEmail?: Maybe<Array<GooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyUsingGooglePlayUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayUserForGooglePlayUserCreatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayUserCreatedByFkeyGooglePlayUserCreateInput>>;
};

/** A filter to be used against `GooglePlayUser` object types. All fields are combined with a logical ‘and.’ */
export type GooglePlayUserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `password` field. */
  password?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GooglePlayUserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GooglePlayUserFilter>>;
  /** Negates the expression. */
  not?: Maybe<GooglePlayUserFilter>;
};

/** The fields on `googlePlayUser` to look up the row to connect. */
export type GooglePlayUserGooglePlayUserEmailKeyConnect = {
  /** The email used to login */
  email: Scalars['String'];
};

/** The fields on `googlePlayUser` to look up the row to delete. */
export type GooglePlayUserGooglePlayUserEmailKeyDelete = {
  /** The email used to login */
  email: Scalars['String'];
};

/** The fields on `googlePlayUser` to look up the row to connect. */
export type GooglePlayUserGooglePlayUserPkeyConnect = {
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** The fields on `googlePlayUser` to look up the row to delete. */
export type GooglePlayUserGooglePlayUserPkeyDelete = {
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GooglePlayUser` */
export type GooglePlayUserInput = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GooglePlayUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googlePlayUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GooglePlayUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googlePlayUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyPatch;
  /** The email used to login */
  email: Scalars['String'];
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyUsingGooglePlayUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyPatch;
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyUsingGooglePlayUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyPatch;
  /** The email used to login */
  email: Scalars['String'];
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyUsingGooglePlayUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyPatch;
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingGooglePlayUserEmailKeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyPatch;
  /** The email used to login */
  email: Scalars['String'];
};

/** The fields on `googlePlayUser` to look up the row to update. */
export type GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingGooglePlayUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyPatch;
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** Represents an update to a `GooglePlayUser`. Fields that are set will be updated. */
export type GooglePlayUserPatch = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyConnection = {
  __typename?: 'GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyEdge = {
  __typename?: 'GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByCreatedBy: GooglePlayAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndCreatedByManyToManyEdgeGooglePlayAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByUpdatedBy: GooglePlayAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type GooglePlayUserPeopleByGooglePlayAccountGooglePlayUserIdAndUpdatedByManyToManyEdgeGooglePlayAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** The `googlePlayUser` to be created by this mutation. */
export type GooglePlayUserUpdatedByFkeyGooglePlayUserCreateInput = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GooglePlayUserInput` mutation. */
export type GooglePlayUserUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GooglePlayUserUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `googlePlayUser` in the `PersonInput` mutation. */
export type GooglePlayUserUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `googlePlayUser` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectById?: Maybe<Array<GooglePlayUserGooglePlayUserPkeyConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByEmail?: Maybe<Array<GooglePlayUserGooglePlayUserEmailKeyConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GooglePlayUserNodeIdConnect>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteById?: Maybe<Array<GooglePlayUserGooglePlayUserPkeyDelete>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByEmail?: Maybe<Array<GooglePlayUserGooglePlayUserEmailKeyDelete>>;
  /** The primary key(s) for `googlePlayUser` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GooglePlayUserNodeIdDelete>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateById?: Maybe<Array<GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingGooglePlayUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByEmail?: Maybe<Array<GooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingGooglePlayUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `googlePlayUser` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyNodeIdUpdate>>;
  /** A `GooglePlayUserInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GooglePlayUserUpdatedByFkeyGooglePlayUserCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GooglePlayUserUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `GooglePlayUser` values. */
export type GooglePlayUsersConnection = {
  __typename?: 'GooglePlayUsersConnection';
  /** A list of `GooglePlayUser` objects. */
  nodes: Array<GooglePlayUser>;
  /** A list of edges which contains the `GooglePlayUser` and cursor to aid in pagination. */
  edges: Array<GooglePlayUsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayUser` edge in the connection. */
export type GooglePlayUsersEdge = {
  __typename?: 'GooglePlayUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayUser` at the end of the edge. */
  node: GooglePlayUser;
};

/** Methods to use when ordering `GooglePlayUser`. */
export enum GooglePlayUsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type Group = Node & {
  __typename?: 'Group';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GroupCategory` that is related to this `Group`. */
  groupCategory?: Maybe<GroupCategory>;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendars: CalendarsConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  currentUserNewsletters: NewslettersConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  currentUserNotifications: NotificationsConnection;
  /** A Group's associated Division ID (either via GroupCategory or the Division itself). */
  divisionId?: Maybe<Scalars['UUID']>;
  /** Calculates a Group's "global" sort order by taking into account the sort order of its GroupCategory and the GroupCategory's Division. */
  globalSortOrder?: Maybe<Scalars['Int']>;
  /** Whether a Group has any Events, Resources, Notifications, or Newsletters. */
  hasContent?: Maybe<Scalars['Boolean']>;
  /** Whether a Group has any Resources. */
  hasResources?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether this `Group` is the `Group` for a `Division`. */
  isDivisionGroup?: Maybe<Scalars['Boolean']>;
  /** Whether this `Group` is the Schoolwide `Group` on a `School`. */
  isSchoolwideGroup?: Maybe<Scalars['Boolean']>;
  /** The "real" name of the `Group`. Returns the `group_name` if the `Group` is a standalone `Group`. Returns the `Division` name if the `Group` is for a `Division`. */
  name?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters: NewslettersConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupIdAndCreatedBy: GroupPeopleByCalendarGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupIdAndUpdatedBy: GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceGroupIdAndCreatedBy: GroupPeopleByResourceGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceGroupIdAndUpdatedBy: GroupPeopleByResourceGroupIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceGroupIdAndParentResource: GroupResourcesByResourceGroupIdAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceGroupIdAndCoverImageFileId: GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceGroupIdAndTileImageFileId: GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceGroupIdAndDefaultCoverImageFileId: GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceGroupIdAndDefaultThumbnailImageFileId: GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceGroupIdAndThumbnailImageFileId: GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonGroupIdAndPersonId: GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonGroupIdAndCreatedBy: GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonGroupIdAndUpdatedBy: GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionGroupIdAndCreatedBy: GroupPeopleByDivisionGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionGroupIdAndUpdatedBy: GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByDivisionGroupIdAndSchoolId: GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByGroupNewsletterGroupIdAndNewsletterId: GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterGroupIdAndCreatedBy: GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterGroupIdAndUpdatedBy: GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationGroupGroupIdAndNotificationId: GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupGroupIdAndCreatedBy: GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupGroupIdAndUpdatedBy: GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyConnection;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupCurrentUserNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  filter?: Maybe<NewsletterFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupCurrentUserNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  filter?: Maybe<NotificationFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  filter?: Maybe<NewsletterFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByCalendarGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByCalendarGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByResourceGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByResourceGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupResourcesByResourceGroupIdAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupFilesByResourceGroupIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupFilesByResourceGroupIdAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupFilesByResourceGroupIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByGroupPersonGroupIdAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByGroupPersonGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByGroupPersonGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByDivisionGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByDivisionGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupSchoolsByDivisionGroupIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByGroupNewsletterGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByGroupNewsletterGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupNotificationsByNotificationGroupGroupIdAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByNotificationGroupGroupIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The core "unit" of information in a School. Almost everything starts with a `Group` (even the School itself is a `Group`). <br/><br/> */
export type GroupPeopleByNotificationGroupGroupIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `GroupCategory` values. */
export type GroupCategoriesConnection = {
  __typename?: 'GroupCategoriesConnection';
  /** A list of `GroupCategory` objects. */
  nodes: Array<GroupCategory>;
  /** A list of edges which contains the `GroupCategory` and cursor to aid in pagination. */
  edges: Array<GroupCategoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GroupCategory` edge in the connection. */
export type GroupCategoriesEdge = {
  __typename?: 'GroupCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupCategory` at the end of the edge. */
  node: GroupCategory;
};

/** Methods to use when ordering `GroupCategory`. */
export enum GroupCategoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  DivisionIdAsc = 'DIVISION_ID_ASC',
  DivisionIdDesc = 'DIVISION_ID_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IconTypeAsc = 'ICON_TYPE_ASC',
  IconTypeDesc = 'ICON_TYPE_DESC',
  IconBackgroundColorAsc = 'ICON_BACKGROUND_COLOR_ASC',
  IconBackgroundColorDesc = 'ICON_BACKGROUND_COLOR_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * Customizable GroupCategories for a Division. <br/><br/>
 *
 * Most Divisions will probably use pretty standard categories, but this allows for editing names and any other default content for a cateogry without having to jump through denormalization hoops. <br /><br />
 */
export type GroupCategory = Node & {
  __typename?: 'GroupCategory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId: Scalars['UUID'];
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType: IconType;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Division` that is related to this `GroupCategory`. */
  division?: Maybe<Division>;
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupGroupCategoryIdAndCreatedBy: GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupGroupCategoryIdAndUpdatedBy: GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyConnection;
};


/**
 * Customizable GroupCategories for a Division. <br/><br/>
 *
 * Most Divisions will probably use pretty standard categories, but this allows for editing names and any other default content for a cateogry without having to jump through denormalization hoops. <br /><br />
 */
export type GroupCategoryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * Customizable GroupCategories for a Division. <br/><br/>
 *
 * Most Divisions will probably use pretty standard categories, but this allows for editing names and any other default content for a cateogry without having to jump through denormalization hoops. <br /><br />
 */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Customizable GroupCategories for a Division. <br/><br/>
 *
 * Most Divisions will probably use pretty standard categories, but this allows for editing names and any other default content for a cateogry without having to jump through denormalization hoops. <br /><br />
 */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `GroupCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GroupCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `divisionId` field. */
  divisionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `iconType` field. */
  iconType?: Maybe<IconType>;
  /** Checks for equality with the object’s `iconBackgroundColor` field. */
  iconBackgroundColor?: Maybe<Scalars['String']>;
};

/** The `groupCategory` to be created by this mutation. */
export type GroupCategoryCreatedByFkeyGroupCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `groupCategory` in the `PersonInput` mutation. */
export type GroupCategoryCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupCategory` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupCategoryGroupCategoryPkeyConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupCategoryNodeIdConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupCategoryGroupCategoryPkeyDelete>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupCategoryNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupCategoryOnGroupCategoryForGroupCategoryCreatedByFkeyUsingGroupCategoryPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupCategoryForGroupCategoryCreatedByFkeyNodeIdUpdate>>;
  /** A `GroupCategoryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupCategoryCreatedByFkeyGroupCategoryCreateInput>>;
};

/** The `division` to be created by this mutation. */
export type GroupCategoryDivisionIdFkeyDivisionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** The `groupCategory` to be created by this mutation. */
export type GroupCategoryDivisionIdFkeyGroupCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `division` in the `GroupCategoryInput` mutation. */
export type GroupCategoryDivisionIdFkeyInput = {
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectById?: Maybe<DivisionDivisionPkeyConnect>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  connectByNodeId?: Maybe<DivisionNodeIdConnect>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteById?: Maybe<DivisionDivisionPkeyDelete>;
  /** The primary key(s) for `division` for the far side of the relationship. */
  deleteByNodeId?: Maybe<DivisionNodeIdDelete>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateById?: Maybe<DivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyUsingDivisionPkeyUpdate>;
  /** The primary key(s) and patch data for `division` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyNodeIdUpdate>;
  /** A `DivisionInput` object that will be created and connected to this object. */
  create?: Maybe<GroupCategoryDivisionIdFkeyDivisionCreateInput>;
};

/** Input for the nested mutation of `groupCategory` in the `DivisionInput` mutation. */
export type GroupCategoryDivisionIdFkeyInverseInput = {
  /** Flag indicating whether all other `groupCategory` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupCategoryGroupCategoryPkeyConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupCategoryNodeIdConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupCategoryGroupCategoryPkeyDelete>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupCategoryNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyUsingGroupCategoryPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<DivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyNodeIdUpdate>>;
  /** A `GroupCategoryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupCategoryDivisionIdFkeyGroupCategoryCreateInput>>;
};

/** A filter to be used against `GroupCategory` object types. All fields are combined with a logical ‘and.’ */
export type GroupCategoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `divisionId` field. */
  divisionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `iconType` field. */
  iconType?: Maybe<IconTypeFilter>;
  /** Filter by the object’s `iconBackgroundColor` field. */
  iconBackgroundColor?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupCategoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupCategoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupCategoryFilter>;
};

/** The fields on `groupCategory` to look up the row to connect. */
export type GroupCategoryGroupCategoryPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `groupCategory` to look up the row to delete. */
export type GroupCategoryGroupCategoryPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GroupCategory` */
export type GroupCategoryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupCategoryNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GroupCategoryNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `groupCategory` to look up the row to update. */
export type GroupCategoryOnGroupCategoryForGroupCategoryCreatedByFkeyUsingGroupCategoryPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: UpdateGroupCategoryOnGroupCategoryForGroupCategoryCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: DivisionPatch;
};

/** The fields on `groupCategory` to look up the row to update. */
export type GroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyUsingGroupCategoryPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: UpdateGroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `groupCategory` to look up the row to update. */
export type GroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyUsingGroupCategoryPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: UpdateGroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupCategoryOnGroupForGroupGroupCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `groupCategory` to look up the row to update. */
export type GroupCategoryOnGroupForGroupGroupCategoryIdFkeyUsingGroupCategoryPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: UpdateGroupCategoryOnGroupForGroupGroupCategoryIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GroupCategory`. Fields that are set will be updated. */
export type GroupCategoryPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCreatedBy: GroupsConnection;
};


/** A `Person` edge in the connection, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndCreatedByManyToManyEdgeGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** A connection to a list of `Person` values, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByUpdatedBy: GroupsConnection;
};


/** A `Person` edge in the connection, with data from `Group`. */
export type GroupCategoryPeopleByGroupGroupCategoryIdAndUpdatedByManyToManyEdgeGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** The `groupCategory` to be created by this mutation. */
export type GroupCategoryUpdatedByFkeyGroupCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GroupCategoryInput` mutation. */
export type GroupCategoryUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGroupCategoryForGroupCategoryUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GroupCategoryUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `groupCategory` in the `PersonInput` mutation. */
export type GroupCategoryUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupCategory` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupCategoryGroupCategoryPkeyConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupCategoryNodeIdConnect>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupCategoryGroupCategoryPkeyDelete>>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupCategoryNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyUsingGroupCategoryPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupCategoryForGroupCategoryUpdatedByFkeyNodeIdUpdate>>;
  /** A `GroupCategoryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupCategoryUpdatedByFkeyGroupCategoryCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GroupCategoryUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `groupCategoryId` field. */
  groupCategoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupName` field. */
  groupName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `information` field. */
  information?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `iconType` field. */
  iconType?: Maybe<IconType>;
  /** Checks for equality with the object’s `passcode` field. */
  passcode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `passcodeHint` field. */
  passcodeHint?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoEnrollUserTypes` field. */
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
};

/** The `group` to be created by this mutation. */
export type GroupCreatedByFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `PersonInput` mutation. */
export type GroupCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `group` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupGroupPkeyConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<GroupGroupSlugIdxConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNodeIdConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupGroupPkeyDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<GroupGroupSlugIdxDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupOnGroupForGroupCreatedByFkeyUsingGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<GroupOnGroupForGroupCreatedByFkeyUsingGroupSlugIdxUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupForGroupCreatedByFkeyNodeIdUpdate>>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupCreatedByFkeyGroupCreateInput>>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyConnection = {
  __typename?: 'GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyEdge = {
  __typename?: 'GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type GroupFilesByResourceGroupIdAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A filter to be used against `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `groupCategoryId` field. */
  groupCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `groupName` field. */
  groupName?: Maybe<StringFilter>;
  /** Filter by the object’s `information` field. */
  information?: Maybe<StringFilter>;
  /** Filter by the object’s `iconType` field. */
  iconType?: Maybe<IconTypeFilter>;
  /** Filter by the object’s `passcode` field. */
  passcode?: Maybe<StringFilter>;
  /** Filter by the object’s `passcodeHint` field. */
  passcodeHint?: Maybe<StringFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `autoEnrollUserTypes` field. */
  autoEnrollUserTypes?: Maybe<UserTypeListFilter>;
  /** Filter by the object’s `divisionId` field. */
  divisionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `globalSortOrder` field. */
  globalSortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `hasContent` field. */
  hasContent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `hasResources` field. */
  hasResources?: Maybe<BooleanFilter>;
  /** Filter by the object’s `icon` field. */
  icon?: Maybe<StringFilter>;
  /** Filter by the object’s `isDivisionGroup` field. */
  isDivisionGroup?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isSchoolwideGroup` field. */
  isSchoolwideGroup?: Maybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupFilter>;
};

/** The `groupCategory` to be created by this mutation. */
export type GroupGroupCategoryIdFkeyGroupCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** The `group` to be created by this mutation. */
export type GroupGroupCategoryIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `groupCategory` in the `GroupInput` mutation. */
export type GroupGroupCategoryIdFkeyInput = {
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectById?: Maybe<GroupCategoryGroupCategoryPkeyConnect>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupCategoryNodeIdConnect>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteById?: Maybe<GroupCategoryGroupCategoryPkeyDelete>;
  /** The primary key(s) for `groupCategory` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupCategoryNodeIdDelete>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateById?: Maybe<GroupCategoryOnGroupForGroupGroupCategoryIdFkeyUsingGroupCategoryPkeyUpdate>;
  /** The primary key(s) and patch data for `groupCategory` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupOnGroupForGroupGroupCategoryIdFkeyNodeIdUpdate>;
  /** A `GroupCategoryInput` object that will be created and connected to this object. */
  create?: Maybe<GroupGroupCategoryIdFkeyGroupCategoryCreateInput>;
};

/** Input for the nested mutation of `group` in the `GroupCategoryInput` mutation. */
export type GroupGroupCategoryIdFkeyInverseInput = {
  /** Flag indicating whether all other `group` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupGroupPkeyConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<GroupGroupSlugIdxConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNodeIdConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupGroupPkeyDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<GroupGroupSlugIdxDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupOnGroupForGroupGroupCategoryIdFkeyUsingGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<GroupOnGroupForGroupGroupCategoryIdFkeyUsingGroupSlugIdxUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupCategoryOnGroupForGroupGroupCategoryIdFkeyNodeIdUpdate>>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupGroupCategoryIdFkeyGroupCreateInput>>;
};

/** The fields on `group` to look up the row to connect. */
export type GroupGroupPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to delete. */
export type GroupGroupPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to connect. */
export type GroupGroupSlugIdxConnect = {
  slug: Scalars['String'];
};

/** The fields on `group` to look up the row to delete. */
export type GroupGroupSlugIdxDelete = {
  slug: Scalars['String'];
};

/** An input for mutations affecting `Group` */
export type GroupInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** `A Group's Newsletters.`. */
export type GroupNewsletter = Node & {
  __typename?: 'GroupNewsletter';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Group` that is related to this `GroupNewsletter`. */
  group?: Maybe<Group>;
  /** Reads a single `Newsletter` that is related to this `GroupNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `GroupNewsletter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GroupNewsletterCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `newsletterId` field. */
  newsletterId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The `groupNewsletter` to be created by this mutation. */
export type GroupNewsletterCreatedByFkeyGroupNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `groupNewsletter` in the `PersonInput` mutation. */
export type GroupNewsletterCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNewsletterNodeIdConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyUsingGroupNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupNewsletterForGroupNewsletterCreatedByFkeyNodeIdUpdate>>;
  /** A `GroupNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupNewsletterCreatedByFkeyGroupNewsletterCreateInput>>;
};

/** A filter to be used against `GroupNewsletter` object types. All fields are combined with a logical ‘and.’ */
export type GroupNewsletterFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `newsletterId` field. */
  newsletterId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupNewsletterFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupNewsletterFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupNewsletterFilter>;
};

/** The `group` to be created by this mutation. */
export type GroupNewsletterGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** The `groupNewsletter` to be created by this mutation. */
export type GroupNewsletterGroupIdFkeyGroupNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `group` in the `GroupNewsletterInput` mutation. */
export type GroupNewsletterGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<GroupNewsletterGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `groupNewsletter` in the `GroupInput` mutation. */
export type GroupNewsletterGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `groupNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNewsletterNodeIdConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyNodeIdUpdate>>;
  /** A `GroupNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupNewsletterGroupIdFkeyGroupNewsletterCreateInput>>;
};

/** The fields on `groupNewsletter` to look up the row to connect. */
export type GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyConnect = {
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to delete. */
export type GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyDelete = {
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to connect. */
export type GroupNewsletterGroupNewsletterPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to delete. */
export type GroupNewsletterGroupNewsletterPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GroupNewsletter` */
export type GroupNewsletterInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** The `groupNewsletter` to be created by this mutation. */
export type GroupNewsletterNewsletterIdFkeyGroupNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `newsletter` in the `GroupNewsletterInput` mutation. */
export type GroupNewsletterNewsletterIdFkeyInput = {
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<NewsletterNewsletterPkeyConnect>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<NewsletterNodeIdConnect>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<NewsletterNewsletterPkeyDelete>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NewsletterNodeIdDelete>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<NewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingNewsletterPkeyUpdate>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyNodeIdUpdate>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<GroupNewsletterNewsletterIdFkeyNewsletterCreateInput>;
};

/** Input for the nested mutation of `groupNewsletter` in the `NewsletterInput` mutation. */
export type GroupNewsletterNewsletterIdFkeyInverseInput = {
  /** Flag indicating whether all other `groupNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNewsletterNodeIdConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingGroupNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyNodeIdUpdate>>;
  /** A `GroupNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupNewsletterNewsletterIdFkeyGroupNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type GroupNewsletterNewsletterIdFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupNewsletterNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GroupNewsletterNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyPatch;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyUsingGroupNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyPatch;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingGroupNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyPatch;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `groupNewsletter` to look up the row to update. */
export type GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingGroupNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GroupNewsletter`. Fields that are set will be updated. */
export type GroupNewsletterPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** The `groupNewsletter` to be created by this mutation. */
export type GroupNewsletterUpdatedByFkeyGroupNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `GroupNewsletterInput` mutation. */
export type GroupNewsletterUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GroupNewsletterUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `groupNewsletter` in the `PersonInput` mutation. */
export type GroupNewsletterUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNewsletterNodeIdConnect>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupNewsletterGroupNewsletterPkeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterGroupNewsletterGroupIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `groupNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingGroupNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByGroupIdAndNewsletterId?: Maybe<Array<GroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingGroupNewsletterGroupIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyNodeIdUpdate>>;
  /** A `GroupNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupNewsletterUpdatedByFkeyGroupNewsletterCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GroupNewsletterUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Newsletter` values, with data from `GroupNewsletter`. */
export type GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyConnection = {
  __typename?: 'GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `GroupNewsletter`. */
export type GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyEdge = {
  __typename?: 'GroupNewslettersByGroupNewsletterGroupIdAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `GroupNewsletter` values. */
export type GroupNewslettersConnection = {
  __typename?: 'GroupNewslettersConnection';
  /** A list of `GroupNewsletter` objects. */
  nodes: Array<GroupNewsletter>;
  /** A list of edges which contains the `GroupNewsletter` and cursor to aid in pagination. */
  edges: Array<GroupNewslettersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupNewsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GroupNewsletter` edge in the connection. */
export type GroupNewslettersEdge = {
  __typename?: 'GroupNewslettersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupNewsletter` at the end of the edge. */
  node: GroupNewsletter;
};

/** Methods to use when ordering `GroupNewsletter`. */
export enum GroupNewslettersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  NewsletterIdAsc = 'NEWSLETTER_ID_ASC',
  NewsletterIdDesc = 'NEWSLETTER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type GroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `group` to be deleted. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `Notification` values, with data from `NotificationGroup`. */
export type GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyConnection = {
  __typename?: 'GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationGroup`. */
export type GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyEdge = {
  __typename?: 'GroupNotificationsByNotificationGroupGroupIdAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCalendarForCalendarGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCalendarForCalendarGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCalendarForCalendarGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCalendarForCalendarGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCalendarForCalendarGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnDivisionForDivisionGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: DivisionPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDivisionForDivisionGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDivisionForDivisionGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDivisionForDivisionGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDivisionForDivisionGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupCreatedByFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupCreatedByFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupCreatedByFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnGroupForGroupGroupCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: GroupCategoryPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupGroupCategoryIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupGroupCategoryIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupGroupCategoryIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupGroupCategoryIdFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnGroupForGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupUpdatedByFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupForGroupUpdatedByFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupForGroupUpdatedByFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnGroupPersonForGroupPersonGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: GroupPersonPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupPersonForGroupPersonGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnGroupPersonForGroupPersonGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnNotificationGroupForNotificationGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: NotificationGroupPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnResourceForResourceGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnResourceForResourceGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnResourceForResourceGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnResourceForResourceGroupIdFkeyUsingGroupSlugIdxUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnResourceForResourceGroupIdFkeyPatch;
  slug: Scalars['String'];
};

/** Represents an update to a `Group`. Fields that are set will be updated. */
export type GroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByCalendarGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByCalendarGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByCalendarGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCreatedBy: CalendarsConnection;
};


/** A `Person` edge in the connection, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndCreatedByManyToManyEdgeCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByUpdatedBy: CalendarsConnection;
};


/** A `Person` edge in the connection, with data from `Calendar`. */
export type GroupPeopleByCalendarGroupIdAndUpdatedByManyToManyEdgeCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByDivisionGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByDivisionGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByDivisionGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByCreatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndCreatedByManyToManyEdgeDivisionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByUpdatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type GroupPeopleByDivisionGroupIdAndUpdatedByManyToManyEdgeDivisionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByCreatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndCreatedByManyToManyEdgeGroupNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByUpdatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type GroupPeopleByGroupNewsletterGroupIdAndUpdatedByManyToManyEdgeGroupNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByCreatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndCreatedByManyToManyEdgeGroupPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyConnection = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyEdge = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member: Scalars['Boolean'];
  admin: Scalars['Boolean'];
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByUpdatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type GroupPeopleByGroupPersonGroupIdAndUpdatedByManyToManyEdgeGroupPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByCreatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndCreatedByManyToManyEdgeNotificationGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByUpdatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type GroupPeopleByNotificationGroupGroupIdAndUpdatedByManyToManyEdgeNotificationGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndCreatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByResourceGroupIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByResourceGroupIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndCreatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByResourceGroupIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndUpdatedByManyToManyConnection = {
  __typename?: 'GroupPeopleByResourceGroupIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupPeopleByResourceGroupIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndUpdatedByManyToManyEdge = {
  __typename?: 'GroupPeopleByResourceGroupIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type GroupPeopleByResourceGroupIdAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `GroupPerson` values. */
export type GroupPeopleConnection = {
  __typename?: 'GroupPeopleConnection';
  /** A list of `GroupPerson` objects. */
  nodes: Array<GroupPerson>;
  /** A list of edges which contains the `GroupPerson` and cursor to aid in pagination. */
  edges: Array<GroupPeopleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupPerson` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GroupPerson` edge in the connection. */
export type GroupPeopleEdge = {
  __typename?: 'GroupPeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupPerson` at the end of the edge. */
  node: GroupPerson;
};

/** Methods to use when ordering `GroupPerson`. */
export enum GroupPeopleOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  MemberAsc = 'MEMBER_ASC',
  MemberDesc = 'MEMBER_DESC',
  AdminAsc = 'ADMIN_ASC',
  AdminDesc = 'ADMIN_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A Person's relationship to a Group. Could be a member (subscribed to its content) or an admin (editor of content). */
export type GroupPerson = Node & {
  __typename?: 'GroupPerson';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  /** Reads a single `Group` that is related to this `GroupPerson`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `GroupPerson` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GroupPersonCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `member` field. */
  member?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `admin` field. */
  admin?: Maybe<Scalars['Boolean']>;
};

/** The `groupPerson` to be created by this mutation. */
export type GroupPersonCreatedByFkeyGroupPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `groupPerson` in the `PersonInput` mutation. */
export type GroupPersonCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupPersonGroupPersonPkeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupPersonNodeIdConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupPersonGroupPersonPkeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonCreatedByFkeyUsingGroupPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByGroupIdAndPersonId?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonCreatedByFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupPersonForGroupPersonCreatedByFkeyNodeIdUpdate>>;
  /** A `GroupPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupPersonCreatedByFkeyGroupPersonCreateInput>>;
};

/** A filter to be used against `GroupPerson` object types. All fields are combined with a logical ‘and.’ */
export type GroupPersonFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `personId` field. */
  personId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `member` field. */
  member?: Maybe<BooleanFilter>;
  /** Filter by the object’s `admin` field. */
  admin?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupPersonFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupPersonFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupPersonFilter>;
};

/** The `group` to be created by this mutation. */
export type GroupPersonGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** The `groupPerson` to be created by this mutation. */
export type GroupPersonGroupIdFkeyGroupPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `group` in the `GroupPersonInput` mutation. */
export type GroupPersonGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<GroupPersonGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `groupPerson` in the `GroupInput` mutation. */
export type GroupPersonGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `groupPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupPersonGroupPersonPkeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupPersonNodeIdConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupPersonGroupPersonPkeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByGroupIdAndPersonId?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnGroupPersonForGroupPersonGroupIdFkeyNodeIdUpdate>>;
  /** A `GroupPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupPersonGroupIdFkeyGroupPersonCreateInput>>;
};

/** The fields on `groupPerson` to look up the row to connect. */
export type GroupPersonGroupPersonGroupIdPersonIdKeyConnect = {
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to delete. */
export type GroupPersonGroupPersonGroupIdPersonIdKeyDelete = {
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to connect. */
export type GroupPersonGroupPersonPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to delete. */
export type GroupPersonGroupPersonPkeyDelete = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GroupPerson` */
export type GroupPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupPersonNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GroupPersonNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonCreatedByFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonCreatedByFkeyPatch;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonCreatedByFkeyUsingGroupPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonGroupIdFkeyPatch;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonGroupIdFkeyUsingGroupPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonPersonIdFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonPersonIdFkeyPatch;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonPersonIdFkeyUsingGroupPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonPersonIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyPatch;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** The fields on `groupPerson` to look up the row to update. */
export type GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingGroupPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: UpdateGroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `GroupPerson`. Fields that are set will be updated. */
export type GroupPersonPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** The `groupPerson` to be created by this mutation. */
export type GroupPersonPersonIdFkeyGroupPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `groupPerson` in the `PersonInput` mutation. */
export type GroupPersonPersonIdFkeyInverseInput = {
  /** Flag indicating whether all other `groupPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupPersonGroupPersonPkeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupPersonNodeIdConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupPersonGroupPersonPkeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonPersonIdFkeyUsingGroupPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByGroupIdAndPersonId?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonPersonIdFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupPersonForGroupPersonPersonIdFkeyNodeIdUpdate>>;
  /** A `GroupPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupPersonPersonIdFkeyGroupPersonCreateInput>>;
};

/** The `groupPerson` to be created by this mutation. */
export type GroupPersonUpdatedByFkeyGroupPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `GroupPersonInput` mutation. */
export type GroupPersonUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GroupPersonUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `groupPerson` in the `PersonInput` mutation. */
export type GroupPersonUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `groupPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupPersonGroupPersonPkeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupPersonNodeIdConnect>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupPersonGroupPersonPkeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByGroupIdAndPersonId?: Maybe<Array<GroupPersonGroupPersonGroupIdPersonIdKeyDelete>>;
  /** The primary key(s) for `groupPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingGroupPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByGroupIdAndPersonId?: Maybe<Array<GroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingGroupPersonGroupIdPersonIdKeyUpdate>>;
  /** The primary key(s) and patch data for `groupPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupPersonForGroupPersonUpdatedByFkeyNodeIdUpdate>>;
  /** A `GroupPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupPersonUpdatedByFkeyGroupPersonCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GroupPersonUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type GroupResourcesByResourceGroupIdAndParentResourceManyToManyConnection = {
  __typename?: 'GroupResourcesByResourceGroupIdAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<GroupResourcesByResourceGroupIdAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type GroupResourcesByResourceGroupIdAndParentResourceManyToManyEdge = {
  __typename?: 'GroupResourcesByResourceGroupIdAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type GroupResourcesByResourceGroupIdAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `School` values, with data from `Division`. */
export type GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyConnection = {
  __typename?: 'GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Division`. */
export type GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyEdge = {
  __typename?: 'GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `School` edge in the connection, with data from `Division`. */
export type GroupSchoolsByDivisionGroupIdAndSchoolIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** The `group` to be created by this mutation. */
export type GroupUpdatedByFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `GroupInput` mutation. */
export type GroupUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnGroupForGroupUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupOnGroupForGroupUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<GroupUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `group` in the `PersonInput` mutation. */
export type GroupUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `group` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<Array<GroupGroupPkeyConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<GroupGroupSlugIdxConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<GroupNodeIdConnect>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<Array<GroupGroupPkeyDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<GroupGroupSlugIdxDelete>>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<GroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<Array<GroupOnGroupForGroupUpdatedByFkeyUsingGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<GroupOnGroupForGroupUpdatedByFkeyUsingGroupSlugIdxUpdate>>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnGroupForGroupUpdatedByFkeyNodeIdUpdate>>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<GroupUpdatedByFkeyGroupCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type GroupUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  __typename?: 'GroupsConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  __typename?: 'GroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  GroupCategoryIdAsc = 'GROUP_CATEGORY_ID_ASC',
  GroupCategoryIdDesc = 'GROUP_CATEGORY_ID_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  GroupNameAsc = 'GROUP_NAME_ASC',
  GroupNameDesc = 'GROUP_NAME_DESC',
  InformationAsc = 'INFORMATION_ASC',
  InformationDesc = 'INFORMATION_DESC',
  IconTypeAsc = 'ICON_TYPE_ASC',
  IconTypeDesc = 'ICON_TYPE_DESC',
  PasscodeAsc = 'PASSCODE_ASC',
  PasscodeDesc = 'PASSCODE_DESC',
  PasscodeHintAsc = 'PASSCODE_HINT_ASC',
  PasscodeHintDesc = 'PASSCODE_HINT_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  AutoEnrollUserTypesAsc = 'AUTO_ENROLL_USER_TYPES_ASC',
  AutoEnrollUserTypesDesc = 'AUTO_ENROLL_USER_TYPES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against HexColor fields. All fields are combined with a logical ‘and.’ */
export type HexColorFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['HexColor']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['HexColor']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['HexColor']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['HexColor']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['HexColor']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['HexColor']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['HexColor']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['HexColor']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['HexColor']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['HexColor']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['HexColor']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['HexColor']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['HexColor']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['HexColor']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['HexColor']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['HexColor']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['HexColor']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['HexColor']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['HexColor']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['HexColor']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['HexColor']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['HexColor']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['HexColor']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['HexColor']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['HexColor']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['HexColor']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['HexColor']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['HexColor']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['HexColor']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['HexColor']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['HexColor']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['HexColor']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['HexColor']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['HexColor']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['HexColor']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['HexColor']>;
};

export enum IconType {
  IosFilledOne = 'IOS_FILLED_ONE',
  IosFilledTwo = 'IOS_FILLED_TWO',
  IosFilledThree = 'IOS_FILLED_THREE',
  IosFilledFour = 'IOS_FILLED_FOUR',
  IosFilledFive = 'IOS_FILLED_FIVE',
  IosFilledSix = 'IOS_FILLED_SIX',
  IosFilledSeven = 'IOS_FILLED_SEVEN',
  IosFilledEight = 'IOS_FILLED_EIGHT',
  IosFilledNine = 'IOS_FILLED_NINE',
  IosFilledTen = 'IOS_FILLED_TEN',
  IosFilledEleven = 'IOS_FILLED_ELEVEN',
  IosFilledTwelve = 'IOS_FILLED_TWELVE',
  IosFilledBasketball = 'IOS_FILLED_BASKETBALL',
  IosFilledBaseball = 'IOS_FILLED_BASEBALL',
  IosFilledSoftball = 'IOS_FILLED_SOFTBALL',
  IosFilledSoccer = 'IOS_FILLED_SOCCER',
  IosFilledFootball = 'IOS_FILLED_FOOTBALL',
  IosFilledVolleyball = 'IOS_FILLED_VOLLEYBALL',
  IosFilledGolf = 'IOS_FILLED_GOLF',
  IosFilledArchery = 'IOS_FILLED_ARCHERY',
  IosFilledBassFishing = 'IOS_FILLED_BASS_FISHING',
  IosFilledLacrosse = 'IOS_FILLED_LACROSSE',
  IosFilledFieldHockey = 'IOS_FILLED_FIELD_HOCKEY',
  IosFilledTennis = 'IOS_FILLED_TENNIS',
  IosFilledCrossCountry = 'IOS_FILLED_CROSS_COUNTRY',
  IosFilledTrack = 'IOS_FILLED_TRACK',
  IosFilledSwimming = 'IOS_FILLED_SWIMMING',
  IosFilledBowling = 'IOS_FILLED_BOWLING',
  IosFilledCheer = 'IOS_FILLED_CHEER',
  IosFilledDance = 'IOS_FILLED_DANCE',
  IosFilledWrestling = 'IOS_FILLED_WRESTLING',
  IosFilledSportsJersey = 'IOS_FILLED_SPORTS_JERSEY',
  IosFilledExercise = 'IOS_FILLED_EXERCISE',
  IosFilledJumprope = 'IOS_FILLED_JUMPROPE',
  IosFilledWomanAthlete = 'IOS_FILLED_WOMAN_ATHLETE',
  IosFilledAcrobatics = 'IOS_FILLED_ACROBATICS',
  IosFilledGymnastics = 'IOS_FILLED_GYMNASTICS',
  IosFilledWeightlifting = 'IOS_FILLED_WEIGHTLIFTING',
  IosFilledBoxingGlove = 'IOS_FILLED_BOXING_GLOVE',
  IosFilledTrackLap = 'IOS_FILLED_TRACK_LAP',
  IosFilledCityHall = 'IOS_FILLED_CITY_HALL',
  IosFilledSchool = 'IOS_FILLED_SCHOOL',
  IosFilledPersonWithFlag = 'IOS_FILLED_PERSON_WITH_FLAG',
  IosFilledBackpack = 'IOS_FILLED_BACKPACK',
  IosFilledGroup = 'IOS_FILLED_GROUP',
  IosFilledClassroom = 'IOS_FILLED_CLASSROOM',
  IosFilledStudents = 'IOS_FILLED_STUDENTS',
  IosFilledPreschool = 'IOS_FILLED_PRESCHOOL',
  IosFilledAbcCube = 'IOS_FILLED_ABC_CUBE',
  IosFilledSoccerPlayer = 'IOS_FILLED_SOCCER_PLAYER',
  IosFilledVolleyballPlayer = 'IOS_FILLED_VOLLEYBALL_PLAYER',
  IosFilledCheerleading = 'IOS_FILLED_CHEERLEADING',
  IosFilledBasketballPlayer = 'IOS_FILLED_BASKETBALL_PLAYER',
  IosFilledAirJordan = 'IOS_FILLED_AIR_JORDAN',
  IosFilledBaseballPlayer = 'IOS_FILLED_BASEBALL_PLAYER',
  IosFilledMusic = 'IOS_FILLED_MUSIC',
  IosFilledTheatreMask = 'IOS_FILLED_THEATRE_MASK',
  IosFilledFlyingMortarboard = 'IOS_FILLED_FLYING_MORTARBOARD',
  IosFilledHeartHandshake = 'IOS_FILLED_HEART_HANDSHAKE',
  IosFilledEasel = 'IOS_FILLED_EASEL',
  IosFilledFist = 'IOS_FILLED_FIST',
  IosFilledNaruto = 'IOS_FILLED_NARUTO',
  IosFilledMoveDirtAround = 'IOS_FILLED_MOVE_DIRT_AROUND',
  IosFilledArguing = 'IOS_FILLED_ARGUING',
  IosFilledHandWithPen = 'IOS_FILLED_HAND_WITH_PEN',
  IosFilledCaduceus = 'IOS_FILLED_CADUCEUS',
  IosFilledPodcast = 'IOS_FILLED_PODCAST',
  IosFilledMicrophone = 'IOS_FILLED_MICROPHONE',
  IosFilledEngineer = 'IOS_FILLED_ENGINEER',
  IosFilledTwitter = 'IOS_FILLED_TWITTER',
  IosFilledInstagram = 'IOS_FILLED_INSTAGRAM',
  IosFilledPaella = 'IOS_FILLED_PAELLA',
  IosFilledBeach = 'IOS_FILLED_BEACH',
  IosFilledBook = 'IOS_FILLED_BOOK',
  IosFilledVeganFood = 'IOS_FILLED_VEGAN_FOOD',
  IosFilledGraduationScroll = 'IOS_FILLED_GRADUATION_SCROLL',
  IosFilledDefendFamily = 'IOS_FILLED_DEFEND_FAMILY',
  IosFilledMario = 'IOS_FILLED_MARIO'
}

/** A filter to be used against IconType fields. All fields are combined with a logical ‘and.’ */
export type IconTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<IconType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<IconType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<IconType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<IconType>;
  /** Included in the specified list. */
  in?: Maybe<Array<IconType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<IconType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<IconType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<IconType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<IconType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<IconType>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type InvitationPublic = {
  __typename?: 'InvitationPublic';
  schoolPrimaryColor?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolThumbnail?: Maybe<File>;
  inviterFirstName?: Maybe<Scalars['String']>;
  inviterLastName?: Maybe<Scalars['String']>;
  inviterEmail?: Maybe<Scalars['Email']>;
  invitationAccepted?: Maybe<Scalars['Boolean']>;
  inviteeAccountEmail?: Maybe<Scalars['Email']>;
  inviteeHasAccount?: Maybe<Scalars['Boolean']>;
};

/**
 * `ios_app` is connected to `app` in a 1-1 relationship where app's foreign key is on that android_app table. \n\n
 *
 * `ios_app` is connected to builds and tasks table in a one to many relationship where`app`'s `id` is a foreign key. \n\n
 *
 * The latest build is the most recently updated build that is live and has this android_app_id as a foreign key. The latest release is the release id associated with that build.
 */
export type IosApp = Node & {
  __typename?: 'IosApp';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** Reads a single `App` that is related to this `IosApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `AppStoreListing` that is related to this `IosApp`. */
  appStoreListing?: Maybe<AppStoreListing>;
};

/** The `app` to be created by this mutation. */
export type IosAppAppIdFkeyAppCreateInput = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `app` in the `IosAppInput` mutation. */
export type IosAppAppIdFkeyInput = {
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectById?: Maybe<AppAppPkeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectBySchoolId?: Maybe<AppAppSchoolIdKeyConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppNodeIdConnect>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteById?: Maybe<AppAppPkeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<AppAppSchoolIdKeyDelete>;
  /** The primary key(s) for `app` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppNodeIdDelete>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateById?: Maybe<AppOnIosAppForIosAppAppIdFkeyUsingAppPkeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateBySchoolId?: Maybe<AppOnIosAppForIosAppAppIdFkeyUsingAppSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `app` for the far side of the relationship. */
  updateByNodeId?: Maybe<IosAppOnIosAppForIosAppAppIdFkeyNodeIdUpdate>;
  /** A `AppInput` object that will be created and connected to this object. */
  create?: Maybe<IosAppAppIdFkeyAppCreateInput>;
};

/** Input for the nested mutation of `iosApp` in the `AppInput` mutation. */
export type IosAppAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `iosApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectById?: Maybe<IosAppIosAppPkeyConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByAppId?: Maybe<IosAppIosAppAppIdKeyConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<IosAppNodeIdConnect>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteById?: Maybe<IosAppIosAppPkeyDelete>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<IosAppIosAppAppIdKeyDelete>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IosAppNodeIdDelete>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateById?: Maybe<IosAppOnIosAppForIosAppAppIdFkeyUsingIosAppPkeyUpdate>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByAppId?: Maybe<IosAppOnIosAppForIosAppAppIdFkeyUsingIosAppAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppOnIosAppForIosAppAppIdFkeyNodeIdUpdate>;
  /** A `IosAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosAppAppIdFkeyIosAppCreateInput>>;
};

/** The `iosApp` to be created by this mutation. */
export type IosAppAppIdFkeyIosAppCreateInput = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** A condition to be used against `IosApp` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type IosAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appId` field. */
  appId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `bundleId` field. */
  bundleId?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `iosApp` in the `PersonInput` mutation. */
export type IosAppCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `iosApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectById?: Maybe<Array<IosAppIosAppPkeyConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByAppId?: Maybe<Array<IosAppIosAppAppIdKeyConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IosAppNodeIdConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<IosAppIosAppPkeyDelete>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<Array<IosAppIosAppAppIdKeyDelete>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IosAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateById?: Maybe<Array<IosAppOnIosAppForIosAppCreatedByFkeyUsingIosAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByAppId?: Maybe<Array<IosAppOnIosAppForIosAppCreatedByFkeyUsingIosAppAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnIosAppForIosAppCreatedByFkeyNodeIdUpdate>>;
  /** A `IosAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosAppCreatedByFkeyIosAppCreateInput>>;
};

/** The `iosApp` to be created by this mutation. */
export type IosAppCreatedByFkeyIosAppCreateInput = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** A filter to be used against `IosApp` object types. All fields are combined with a logical ‘and.’ */
export type IosAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `appId` field. */
  appId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `bundleId` field. */
  bundleId?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IosAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IosAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<IosAppFilter>;
};

/** An input for mutations affecting `IosApp` */
export type IosAppInput = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** The fields on `iosApp` to look up the row to connect. */
export type IosAppIosAppAppIdKeyConnect = {
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to delete. */
export type IosAppIosAppAppIdKeyDelete = {
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to connect. */
export type IosAppIosAppPkeyConnect = {
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to delete. */
export type IosAppIosAppPkeyDelete = {
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type IosAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `iosApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IosAppNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `iosApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingIosAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyUsingIosAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IosAppOnIosAppForIosAppAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppAppIdFkeyUsingIosAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppAppIdFkeyPatch;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppAppIdFkeyUsingIosAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppAppIdFkeyPatch;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppCreatedByFkeyUsingIosAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppCreatedByFkeyPatch;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppCreatedByFkeyUsingIosAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppCreatedByFkeyPatch;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IosAppOnIosAppForIosAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppUpdatedByFkeyUsingIosAppAppIdKeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppUpdatedByFkeyPatch;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** The fields on `iosApp` to look up the row to update. */
export type IosAppOnIosAppForIosAppUpdatedByFkeyUsingIosAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: UpdateIosAppOnIosAppForIosAppUpdatedByFkeyPatch;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `IosApp`. Fields that are set will be updated. */
export type IosAppPatch = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `IosAppInput` mutation. */
export type IosAppUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnIosAppForIosAppUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<IosAppOnIosAppForIosAppUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<IosAppUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `iosApp` in the `PersonInput` mutation. */
export type IosAppUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `iosApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectById?: Maybe<Array<IosAppIosAppPkeyConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByAppId?: Maybe<Array<IosAppIosAppAppIdKeyConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IosAppNodeIdConnect>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<IosAppIosAppPkeyDelete>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByAppId?: Maybe<Array<IosAppIosAppAppIdKeyDelete>>;
  /** The primary key(s) for `iosApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IosAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateById?: Maybe<Array<IosAppOnIosAppForIosAppUpdatedByFkeyUsingIosAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByAppId?: Maybe<Array<IosAppOnIosAppForIosAppUpdatedByFkeyUsingIosAppAppIdKeyUpdate>>;
  /** The primary key(s) and patch data for `iosApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnIosAppForIosAppUpdatedByFkeyNodeIdUpdate>>;
  /** A `IosAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosAppUpdatedByFkeyIosAppCreateInput>>;
};

/** The `iosApp` to be created by this mutation. */
export type IosAppUpdatedByFkeyIosAppCreateInput = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** The `person` to be created by this mutation. */
export type IosAppUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `IosApp` values. */
export type IosAppsConnection = {
  __typename?: 'IosAppsConnection';
  /** A list of `IosApp` objects. */
  nodes: Array<IosApp>;
  /** A list of edges which contains the `IosApp` and cursor to aid in pagination. */
  edges: Array<IosAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IosApp` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `IosApp` edge in the connection. */
export type IosAppsEdge = {
  __typename?: 'IosAppsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IosApp` at the end of the edge. */
  node: IosApp;
};

/** Methods to use when ordering `IosApp`. */
export enum IosAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AppIdAsc = 'APP_ID_ASC',
  AppIdDesc = 'APP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  BundleIdAsc = 'BUNDLE_ID_ASC',
  BundleIdDesc = 'BUNDLE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * Storing ios screenshots on individual rows of this table. \n\n
 *
 * With this table, I'm setting the precedent for putting uncommon cases in their own table (one to none, sometimes one to many)\n\n
 *
 * One `app_store_listing` can have many `ios_screenshot` (but most likely we'll opt have 0 and just generate screenshots with each shipment). \n\n
 */
export type IosScreenshot = Node & {
  __typename?: 'IosScreenshot';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size: IosScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder: Scalars['Int'];
  /** Reads a single `AppStoreListing` that is related to this `IosScreenshot`. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
};

/** The `appStoreListing` to be created by this mutation. */
export type IosScreenshotAppStoreListingIdFkeyAppStoreListingCreateInput = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** Input for the nested mutation of `appStoreListing` in the `IosScreenshotInput` mutation. */
export type IosScreenshotAppStoreListingIdFkeyInput = {
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectById?: Maybe<AppStoreListingAppStoreListingPkeyConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByIosAppId?: Maybe<AppStoreListingAppStoreListingIosAppIdKeyConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<AppStoreListingNodeIdConnect>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteById?: Maybe<AppStoreListingAppStoreListingPkeyDelete>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByIosAppId?: Maybe<AppStoreListingAppStoreListingIosAppIdKeyDelete>;
  /** The primary key(s) for `appStoreListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<AppStoreListingNodeIdDelete>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateById?: Maybe<AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingAppStoreListingPkeyUpdate>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByIosAppId?: Maybe<AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingAppStoreListingIosAppIdKeyUpdate>;
  /** The primary key(s) and patch data for `appStoreListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<IosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyNodeIdUpdate>;
  /** A `AppStoreListingInput` object that will be created and connected to this object. */
  create?: Maybe<IosScreenshotAppStoreListingIdFkeyAppStoreListingCreateInput>;
};

/** Input for the nested mutation of `iosScreenshot` in the `AppStoreListingInput` mutation. */
export type IosScreenshotAppStoreListingIdFkeyInverseInput = {
  /** Flag indicating whether all other `iosScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<IosScreenshotIosScreenshotPkeyConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IosScreenshotNodeIdConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<IosScreenshotIosScreenshotPkeyDelete>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IosScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<IosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingIosScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyNodeIdUpdate>>;
  /** A `IosScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosScreenshotAppStoreListingIdFkeyIosScreenshotCreateInput>>;
};

/** The `iosScreenshot` to be created by this mutation. */
export type IosScreenshotAppStoreListingIdFkeyIosScreenshotCreateInput = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size: IosScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/**
 * A condition to be used against `IosScreenshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IosScreenshotCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appStoreListingId` field. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `size` field. */
  size?: Maybe<IosScreenshotSize>;
  /** Checks for equality with the object’s `screenshotUrl` field. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `screenshotOrder` field. */
  screenshotOrder?: Maybe<Scalars['Int']>;
};

/** Input for the nested mutation of `iosScreenshot` in the `PersonInput` mutation. */
export type IosScreenshotCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `iosScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<IosScreenshotIosScreenshotPkeyConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IosScreenshotNodeIdConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<IosScreenshotIosScreenshotPkeyDelete>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IosScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<IosScreenshotOnIosScreenshotForIosScreenshotCreatedByFkeyUsingIosScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnIosScreenshotForIosScreenshotCreatedByFkeyNodeIdUpdate>>;
  /** A `IosScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosScreenshotCreatedByFkeyIosScreenshotCreateInput>>;
};

/** The `iosScreenshot` to be created by this mutation. */
export type IosScreenshotCreatedByFkeyIosScreenshotCreateInput = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size: IosScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** A filter to be used against `IosScreenshot` object types. All fields are combined with a logical ‘and.’ */
export type IosScreenshotFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `appStoreListingId` field. */
  appStoreListingId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `size` field. */
  size?: Maybe<IosScreenshotSizeFilter>;
  /** Filter by the object’s `screenshotUrl` field. */
  screenshotUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `screenshotOrder` field. */
  screenshotOrder?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IosScreenshotFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IosScreenshotFilter>>;
  /** Negates the expression. */
  not?: Maybe<IosScreenshotFilter>;
};

/** An input for mutations affecting `IosScreenshot` */
export type IosScreenshotInput = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size: IosScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** The fields on `iosScreenshot` to look up the row to connect. */
export type IosScreenshotIosScreenshotPkeyConnect = {
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The fields on `iosScreenshot` to look up the row to delete. */
export type IosScreenshotIosScreenshotPkeyDelete = {
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type IosScreenshotNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `iosScreenshot` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IosScreenshotNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `iosScreenshot` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** The fields on `iosScreenshot` to look up the row to update. */
export type IosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyUsingIosScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: UpdateIosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyPatch;
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The fields on `iosScreenshot` to look up the row to update. */
export type IosScreenshotOnIosScreenshotForIosScreenshotCreatedByFkeyUsingIosScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: UpdateIosScreenshotOnIosScreenshotForIosScreenshotCreatedByFkeyPatch;
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `iosScreenshot` to look up the row to update. */
export type IosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyUsingIosScreenshotPkeyUpdate = {
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: UpdateIosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyPatch;
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `IosScreenshot`. Fields that are set will be updated. */
export type IosScreenshotPatch = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size?: Maybe<IosScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

export enum IosScreenshotSize {
  ButtonIPhone = 'BUTTON_I_PHONE',
  ButtonIPad = 'BUTTON_I_PAD',
  NoButtonIPhone = 'NO_BUTTON_I_PHONE',
  NoButtonIPad = 'NO_BUTTON_I_PAD'
}

/** A filter to be used against IosScreenshotSize fields. All fields are combined with a logical ‘and.’ */
export type IosScreenshotSizeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<IosScreenshotSize>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<IosScreenshotSize>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<IosScreenshotSize>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<IosScreenshotSize>;
  /** Included in the specified list. */
  in?: Maybe<Array<IosScreenshotSize>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<IosScreenshotSize>>;
  /** Less than the specified value. */
  lessThan?: Maybe<IosScreenshotSize>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<IosScreenshotSize>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<IosScreenshotSize>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<IosScreenshotSize>;
};

/** Input for the nested mutation of `person` in the `IosScreenshotInput` mutation. */
export type IosScreenshotUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnIosScreenshotForIosScreenshotUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<IosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<IosScreenshotUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `iosScreenshot` in the `PersonInput` mutation. */
export type IosScreenshotUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `iosScreenshot` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectById?: Maybe<Array<IosScreenshotIosScreenshotPkeyConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<IosScreenshotNodeIdConnect>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteById?: Maybe<Array<IosScreenshotIosScreenshotPkeyDelete>>;
  /** The primary key(s) for `iosScreenshot` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<IosScreenshotNodeIdDelete>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateById?: Maybe<Array<IosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyUsingIosScreenshotPkeyUpdate>>;
  /** The primary key(s) and patch data for `iosScreenshot` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnIosScreenshotForIosScreenshotUpdatedByFkeyNodeIdUpdate>>;
  /** A `IosScreenshotInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IosScreenshotUpdatedByFkeyIosScreenshotCreateInput>>;
};

/** The `iosScreenshot` to be created by this mutation. */
export type IosScreenshotUpdatedByFkeyIosScreenshotCreateInput = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size: IosScreenshotSize;
  /** The url at which the screenshot is hosted. */
  screenshotUrl: Scalars['String'];
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type IosScreenshotUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `IosScreenshot` values. */
export type IosScreenshotsConnection = {
  __typename?: 'IosScreenshotsConnection';
  /** A list of `IosScreenshot` objects. */
  nodes: Array<IosScreenshot>;
  /** A list of edges which contains the `IosScreenshot` and cursor to aid in pagination. */
  edges: Array<IosScreenshotsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IosScreenshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `IosScreenshot` edge in the connection. */
export type IosScreenshotsEdge = {
  __typename?: 'IosScreenshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IosScreenshot` at the end of the edge. */
  node: IosScreenshot;
};

/** Methods to use when ordering `IosScreenshot`. */
export enum IosScreenshotsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AppStoreListingIdAsc = 'APP_STORE_LISTING_ID_ASC',
  AppStoreListingIdDesc = 'APP_STORE_LISTING_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  ScreenshotUrlAsc = 'SCREENSHOT_URL_ASC',
  ScreenshotUrlDesc = 'SCREENSHOT_URL_DESC',
  ScreenshotOrderAsc = 'SCREENSHOT_ORDER_ASC',
  ScreenshotOrderDesc = 'SCREENSHOT_ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};


/** All input for the `killBuildStepAndBuild` mutation. */
export type KillBuildStepAndBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildStepId: Scalars['UUID'];
};

/** The output of our `killBuildStepAndBuild` mutation. */
export type KillBuildStepAndBuildPayload = {
  __typename?: 'KillBuildStepAndBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `legacyMigrationAddLegacySubscriptionsForPerson` mutation. */
export type LegacyMigrationAddLegacySubscriptionsForPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  selectedGroupIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `legacyMigrationAddLegacySubscriptionsForPerson` mutation. */
export type LegacyMigrationAddLegacySubscriptionsForPersonPayload = {
  __typename?: 'LegacyMigrationAddLegacySubscriptionsForPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `legacyMigrationUpdatePersonGroupMembershipForSchool` mutation. */
export type LegacyMigrationUpdatePersonGroupMembershipForSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
  selectedGroupIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `legacyMigrationUpdatePersonGroupMembershipForSchool` mutation. */
export type LegacyMigrationUpdatePersonGroupMembershipForSchoolPayload = {
  __typename?: 'LegacyMigrationUpdatePersonGroupMembershipForSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `makeDivisionSchoolwide` mutation. */
export type MakeDivisionSchoolwideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  divisionId: Scalars['UUID'];
};

/** The output of our `makeDivisionSchoolwide` mutation. */
export type MakeDivisionSchoolwidePayload = {
  __typename?: 'MakeDivisionSchoolwidePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `makeStartingBuildStepForBuild` mutation. */
export type MakeStartingBuildStepForBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildId: Scalars['UUID'];
};

/** The output of our `makeStartingBuildStepForBuild` mutation. */
export type MakeStartingBuildStepForBuildPayload = {
  __typename?: 'MakeStartingBuildStepForBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  buildStep?: Maybe<BuildStep>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Build` that is related to this `BuildStep`. */
  build?: Maybe<Build>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `BuildStep`. May be used by Relay 1. */
  buildStepEdge?: Maybe<BuildStepsEdge>;
};


/** The output of our `makeStartingBuildStepForBuild` mutation. */
export type MakeStartingBuildStepForBuildPayloadBuildStepEdgeArgs = {
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
};

/** All input for the `markAsNeverNuke` mutation. */
export type MarkAsNeverNukeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAppStoreAccountId: Scalars['UUID'];
};

/** The output of our `markAsNeverNuke` mutation. */
export type MarkAsNeverNukePayload = {
  __typename?: 'MarkAsNeverNukePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `markDistCertsAsNuked` mutation. */
export type MarkDistCertsAsNukedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAppStoreAccountId: Scalars['UUID'];
};

/** The output of our `markDistCertsAsNuked` mutation. */
export type MarkDistCertsAsNukedPayload = {
  __typename?: 'MarkDistCertsAsNukedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The Marquee for the homepage of a school's app. */
export type Marquee = Node & {
  __typename?: 'Marquee';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled: Scalars['Boolean'];
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<Buttons>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Marquee`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Marquee`. */
  marqueeImage?: Maybe<File>;
};

/** A condition to be used against `Marquee` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MarqueeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `marqueeImageFileId` field. */
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `countdownLabel` field. */
  countdownLabel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `countdownDate` field. */
  countdownDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `countdownHideWhenCompleted` field. */
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `embedUrl` field. */
  embedUrl?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `marquee` in the `PersonInput` mutation. */
export type MarqueeCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `marquee` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectById?: Maybe<Array<MarqueeMarqueePkeyConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MarqueeNodeIdConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteById?: Maybe<Array<MarqueeMarqueePkeyDelete>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MarqueeNodeIdDelete>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateById?: Maybe<Array<MarqueeOnMarqueeForMarqueeCreatedByFkeyUsingMarqueePkeyUpdate>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnMarqueeForMarqueeCreatedByFkeyNodeIdUpdate>>;
  /** A `MarqueeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MarqueeCreatedByFkeyMarqueeCreateInput>>;
};

/** The `marquee` to be created by this mutation. */
export type MarqueeCreatedByFkeyMarqueeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** A filter to be used against `Marquee` object types. All fields are combined with a logical ‘and.’ */
export type MarqueeFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `marqueeImageFileId` field. */
  marqueeImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `countdownLabel` field. */
  countdownLabel?: Maybe<StringFilter>;
  /** Filter by the object’s `countdownDate` field. */
  countdownDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `countdownHideWhenCompleted` field. */
  countdownHideWhenCompleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `embedUrl` field. */
  embedUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MarqueeFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MarqueeFilter>>;
  /** Negates the expression. */
  not?: Maybe<MarqueeFilter>;
};

/** An input for mutations affecting `Marquee` */
export type MarqueeInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type MarqueeMarqueeImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `MarqueeInput` mutation. */
export type MarqueeMarqueeImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnMarqueeForMarqueeMarqueeImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<MarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<MarqueeMarqueeImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `marquee` in the `FileInput` mutation. */
export type MarqueeMarqueeImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `marquee` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectById?: Maybe<Array<MarqueeMarqueePkeyConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MarqueeNodeIdConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteById?: Maybe<Array<MarqueeMarqueePkeyDelete>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MarqueeNodeIdDelete>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateById?: Maybe<Array<MarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyUsingMarqueePkeyUpdate>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnMarqueeForMarqueeMarqueeImageFileIdFkeyNodeIdUpdate>>;
  /** A `MarqueeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MarqueeMarqueeImageFileIdFkeyMarqueeCreateInput>>;
};

/** The `marquee` to be created by this mutation. */
export type MarqueeMarqueeImageFileIdFkeyMarqueeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** The fields on `marquee` to look up the row to connect. */
export type MarqueeMarqueePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `marquee` to look up the row to delete. */
export type MarqueeMarqueePkeyDelete = {
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type MarqueeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `marquee` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type MarqueeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `marquee` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `marquee` to look up the row to update. */
export type MarqueeOnMarqueeForMarqueeCreatedByFkeyUsingMarqueePkeyUpdate = {
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: UpdateMarqueeOnMarqueeForMarqueeCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `marquee` to look up the row to update. */
export type MarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyUsingMarqueePkeyUpdate = {
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: UpdateMarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MarqueeOnMarqueeForMarqueeSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `marquee` to look up the row to update. */
export type MarqueeOnMarqueeForMarqueeSchoolIdFkeyUsingMarqueePkeyUpdate = {
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: UpdateMarqueeOnMarqueeForMarqueeSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MarqueeOnMarqueeForMarqueeUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `marquee` to look up the row to update. */
export type MarqueeOnMarqueeForMarqueeUpdatedByFkeyUsingMarqueePkeyUpdate = {
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: UpdateMarqueeOnMarqueeForMarqueeUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Marquee`. Fields that are set will be updated. */
export type MarqueePatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** Input for the nested mutation of `school` in the `MarqueeInput` mutation. */
export type MarqueeSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnMarqueeForMarqueeSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnMarqueeForMarqueeSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<MarqueeOnMarqueeForMarqueeSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<MarqueeSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `marquee` in the `SchoolInput` mutation. */
export type MarqueeSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `marquee` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectById?: Maybe<Array<MarqueeMarqueePkeyConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MarqueeNodeIdConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteById?: Maybe<Array<MarqueeMarqueePkeyDelete>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MarqueeNodeIdDelete>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateById?: Maybe<Array<MarqueeOnMarqueeForMarqueeSchoolIdFkeyUsingMarqueePkeyUpdate>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolOnMarqueeForMarqueeSchoolIdFkeyNodeIdUpdate>>;
  /** A `MarqueeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MarqueeSchoolIdFkeyMarqueeCreateInput>>;
};

/** The `marquee` to be created by this mutation. */
export type MarqueeSchoolIdFkeyMarqueeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** The `school` to be created by this mutation. */
export type MarqueeSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `MarqueeInput` mutation. */
export type MarqueeUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnMarqueeForMarqueeUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<MarqueeOnMarqueeForMarqueeUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<MarqueeUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `marquee` in the `PersonInput` mutation. */
export type MarqueeUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `marquee` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectById?: Maybe<Array<MarqueeMarqueePkeyConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MarqueeNodeIdConnect>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteById?: Maybe<Array<MarqueeMarqueePkeyDelete>>;
  /** The primary key(s) for `marquee` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MarqueeNodeIdDelete>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateById?: Maybe<Array<MarqueeOnMarqueeForMarqueeUpdatedByFkeyUsingMarqueePkeyUpdate>>;
  /** The primary key(s) and patch data for `marquee` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnMarqueeForMarqueeUpdatedByFkeyNodeIdUpdate>>;
  /** A `MarqueeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MarqueeUpdatedByFkeyMarqueeCreateInput>>;
};

/** The `marquee` to be created by this mutation. */
export type MarqueeUpdatedByFkeyMarqueeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type MarqueeUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Marquee` values. */
export type MarqueesConnection = {
  __typename?: 'MarqueesConnection';
  /** A list of `Marquee` objects. */
  nodes: Array<Marquee>;
  /** A list of edges which contains the `Marquee` and cursor to aid in pagination. */
  edges: Array<MarqueesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Marquee` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Marquee` edge in the connection. */
export type MarqueesEdge = {
  __typename?: 'MarqueesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Marquee` at the end of the edge. */
  node: Marquee;
};

/** Methods to use when ordering `Marquee`. */
export enum MarqueesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  MarqueeImageFileIdAsc = 'MARQUEE_IMAGE_FILE_ID_ASC',
  MarqueeImageFileIdDesc = 'MARQUEE_IMAGE_FILE_ID_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  CountdownLabelAsc = 'COUNTDOWN_LABEL_ASC',
  CountdownLabelDesc = 'COUNTDOWN_LABEL_DESC',
  CountdownDateAsc = 'COUNTDOWN_DATE_ASC',
  CountdownDateDesc = 'COUNTDOWN_DATE_DESC',
  CountdownHideWhenCompletedAsc = 'COUNTDOWN_HIDE_WHEN_COMPLETED_ASC',
  CountdownHideWhenCompletedDesc = 'COUNTDOWN_HIDE_WHEN_COMPLETED_DESC',
  EmbedUrlAsc = 'EMBED_URL_ASC',
  EmbedUrlDesc = 'EMBED_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `migrateLegacyInstallationsSubscriptions` mutation. */
export type MigrateLegacyInstallationsSubscriptionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  legacyInstallationSubscriptions: Array<Maybe<MigrateLegacyInstallationsSubscriptionsInputRecordInput>>;
};

/** An input for mutations affecting `MigrateLegacyInstallationsSubscriptionsInputRecord` */
export type MigrateLegacyInstallationsSubscriptionsInputRecordInput = {
  oneSignalToken?: Maybe<Scalars['String']>;
  groupIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

/** The output of our `migrateLegacyInstallationsSubscriptions` mutation. */
export type MigrateLegacyInstallationsSubscriptionsPayload = {
  __typename?: 'MigrateLegacyInstallationsSubscriptionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `migrateLegacyOneSignalDevices` mutation. */
export type MigrateLegacyOneSignalDevicesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolId: Scalars['UUID'];
  legacyDevices: Array<Maybe<MigrateLegacyOneSignalDevicesInputRecordInput>>;
};

/** An input for mutations affecting `MigrateLegacyOneSignalDevicesInputRecord` */
export type MigrateLegacyOneSignalDevicesInputRecordInput = {
  platform?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['UUID']>;
  externalUserId?: Maybe<Scalars['UUID']>;
};

/** The output of our `migrateLegacyOneSignalDevices` mutation. */
export type MigrateLegacyOneSignalDevicesPayload = {
  __typename?: 'MigrateLegacyOneSignalDevicesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `migrateLegacySubscriptionSelectionsForUpdatedUsers` mutation. */
export type MigrateLegacySubscriptionSelectionsForUpdatedUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  legacyInstallationSubscriptions: Array<Maybe<MigrateLegacyInstallationsSubscriptionsInputRecordInput>>;
};

/** The output of our `migrateLegacySubscriptionSelectionsForUpdatedUsers` mutation. */
export type MigrateLegacySubscriptionSelectionsForUpdatedUsersPayload = {
  __typename?: 'MigrateLegacySubscriptionSelectionsForUpdatedUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `App`. */
  createApp?: Maybe<CreateAppPayload>;
  /** Creates a single `Calendar`. */
  createCalendar?: Maybe<CreateCalendarPayload>;
  /** Creates a single `CalendarEvent`. */
  createCalendarEvent?: Maybe<CreateCalendarEventPayload>;
  /** Creates a single `CalendarGroup`. */
  createCalendarGroup?: Maybe<CreateCalendarGroupPayload>;
  /** Creates a single `CalendarGroupCalendar`. */
  createCalendarGroupCalendar?: Maybe<CreateCalendarGroupCalendarPayload>;
  /** Creates a single `Division`. */
  createDivision?: Maybe<CreateDivisionPayload>;
  /** Creates a single `Event`. */
  createEvent?: Maybe<CreateEventPayload>;
  /** Creates a single `File`. */
  createFile?: Maybe<CreateFilePayload>;
  /** Creates a single `GoogleAccount`. */
  createGoogleAccount?: Maybe<CreateGoogleAccountPayload>;
  /** Creates a single `GoogleCalendarSync`. */
  createGoogleCalendarSync?: Maybe<CreateGoogleCalendarSyncPayload>;
  /** Creates a single `GoogleCalendarSyncCalendar`. */
  createGoogleCalendarSyncCalendar?: Maybe<CreateGoogleCalendarSyncCalendarPayload>;
  /** Creates a single `Group`. */
  createGroup?: Maybe<CreateGroupPayload>;
  /** Creates a single `GroupPerson`. */
  createGroupPerson?: Maybe<CreateGroupPersonPayload>;
  /** Creates a single `GroupCategory`. */
  createGroupCategory?: Maybe<CreateGroupCategoryPayload>;
  /** Creates a single `GroupNewsletter`. */
  createGroupNewsletter?: Maybe<CreateGroupNewsletterPayload>;
  /** Creates a single `Marquee`. */
  createMarquee?: Maybe<CreateMarqueePayload>;
  /** Creates a single `Newsletter`. */
  createNewsletter?: Maybe<CreateNewsletterPayload>;
  /** Creates a single `Notification`. */
  createNotification?: Maybe<CreateNotificationPayload>;
  /** Creates a single `NotificationFile`. */
  createNotificationFile?: Maybe<CreateNotificationFilePayload>;
  /** Creates a single `NotificationNewsletter`. */
  createNotificationNewsletter?: Maybe<CreateNotificationNewsletterPayload>;
  /** Creates a single `NotificationGroup`. */
  createNotificationGroup?: Maybe<CreateNotificationGroupPayload>;
  /** Creates a single `Page`. */
  createPage?: Maybe<CreatePagePayload>;
  /** Creates a single `PageSection`. */
  createPageSection?: Maybe<CreatePageSectionPayload>;
  /** Creates a single `PageSectionFile`. */
  createPageSectionFile?: Maybe<CreatePageSectionFilePayload>;
  /** Creates a single `Person`. */
  createPerson?: Maybe<CreatePersonPayload>;
  /** Creates a single `Resource`. */
  createResource?: Maybe<CreateResourcePayload>;
  /** Creates a single `ResourceFile`. */
  createResourceFile?: Maybe<CreateResourceFilePayload>;
  /** Creates a single `School`. */
  createSchool?: Maybe<CreateSchoolPayload>;
  /** Creates a single `SchoolPerson`. */
  createSchoolPerson?: Maybe<CreateSchoolPersonPayload>;
  /** Creates a single `SchoolPersonInstallation`. */
  createSchoolPersonInstallation?: Maybe<CreateSchoolPersonInstallationPayload>;
  /** Creates a single `SchoolPersonInvitation`. */
  createSchoolPersonInvitation?: Maybe<CreateSchoolPersonInvitationPayload>;
  /** Creates a single `SchoolSetting`. */
  createSchoolSetting?: Maybe<CreateSchoolSettingPayload>;
  /** Creates a single `Sponsor`. */
  createSponsor?: Maybe<CreateSponsorPayload>;
  /** Creates a single `Theme`. */
  createTheme?: Maybe<CreateThemePayload>;
  /** Creates a single `UserGoogleAccount`. */
  createUserGoogleAccount?: Maybe<CreateUserGoogleAccountPayload>;
  /** Creates a single `UserSession`. */
  createUserSession?: Maybe<CreateUserSessionPayload>;
  /** Creates a single `AndroidApp`. */
  createAndroidApp?: Maybe<CreateAndroidAppPayload>;
  /** Creates a single `AndroidJavaKeyStore`. */
  createAndroidJavaKeyStore?: Maybe<CreateAndroidJavaKeyStorePayload>;
  /** Creates a single `AndroidScreenshot`. */
  createAndroidScreenshot?: Maybe<CreateAndroidScreenshotPayload>;
  /** Creates a single `AppStoreAccount`. */
  createAppStoreAccount?: Maybe<CreateAppStoreAccountPayload>;
  /** Creates a single `AppStoreListing`. */
  createAppStoreListing?: Maybe<CreateAppStoreListingPayload>;
  /** Creates a single `AppStoreUser`. */
  createAppStoreUser?: Maybe<CreateAppStoreUserPayload>;
  /** Creates a single `Build`. */
  createBuild?: Maybe<CreateBuildPayload>;
  /** Creates a single `BuildStep`. */
  createBuildStep?: Maybe<CreateBuildStepPayload>;
  /** Creates a single `GooglePlayAccount`. */
  createGooglePlayAccount?: Maybe<CreateGooglePlayAccountPayload>;
  /** Creates a single `GooglePlayListing`. */
  createGooglePlayListing?: Maybe<CreateGooglePlayListingPayload>;
  /** Creates a single `GooglePlayUser`. */
  createGooglePlayUser?: Maybe<CreateGooglePlayUserPayload>;
  /** Creates a single `IosApp`. */
  createIosApp?: Maybe<CreateIosAppPayload>;
  /** Creates a single `IosScreenshot`. */
  createIosScreenshot?: Maybe<CreateIosScreenshotPayload>;
  /** Creates a single `Release`. */
  createRelease?: Maybe<CreateReleasePayload>;
  /** Updates a single `App` using its globally unique id and a patch. */
  updateAppByNodeId?: Maybe<UpdateAppPayload>;
  /** Updates a single `App` using a unique key and a patch. */
  updateApp?: Maybe<UpdateAppPayload>;
  /** Updates a single `App` using a unique key and a patch. */
  updateAppBySchoolId?: Maybe<UpdateAppPayload>;
  /** Updates a single `Calendar` using its globally unique id and a patch. */
  updateCalendarByNodeId?: Maybe<UpdateCalendarPayload>;
  /** Updates a single `Calendar` using a unique key and a patch. */
  updateCalendar?: Maybe<UpdateCalendarPayload>;
  /** Updates a single `CalendarEvent` using its globally unique id and a patch. */
  updateCalendarEventByNodeId?: Maybe<UpdateCalendarEventPayload>;
  /** Updates a single `CalendarEvent` using a unique key and a patch. */
  updateCalendarEvent?: Maybe<UpdateCalendarEventPayload>;
  /** Updates a single `CalendarEvent` using a unique key and a patch. */
  updateCalendarEventByEventIdAndCalendarId?: Maybe<UpdateCalendarEventPayload>;
  /** Updates a single `CalendarGroup` using its globally unique id and a patch. */
  updateCalendarGroupByNodeId?: Maybe<UpdateCalendarGroupPayload>;
  /** Updates a single `CalendarGroup` using a unique key and a patch. */
  updateCalendarGroup?: Maybe<UpdateCalendarGroupPayload>;
  /** Updates a single `CalendarGroupCalendar` using its globally unique id and a patch. */
  updateCalendarGroupCalendarByNodeId?: Maybe<UpdateCalendarGroupCalendarPayload>;
  /** Updates a single `CalendarGroupCalendar` using a unique key and a patch. */
  updateCalendarGroupCalendar?: Maybe<UpdateCalendarGroupCalendarPayload>;
  /** Updates a single `CalendarGroupCalendar` using a unique key and a patch. */
  updateCalendarGroupCalendarByCalendarIdAndCalendarGroupId?: Maybe<UpdateCalendarGroupCalendarPayload>;
  /** Updates a single `Division` using its globally unique id and a patch. */
  updateDivisionByNodeId?: Maybe<UpdateDivisionPayload>;
  /** Updates a single `Division` using a unique key and a patch. */
  updateDivision?: Maybe<UpdateDivisionPayload>;
  /** Updates a single `Event` using its globally unique id and a patch. */
  updateEventByNodeId?: Maybe<UpdateEventPayload>;
  /** Updates a single `Event` using a unique key and a patch. */
  updateEvent?: Maybe<UpdateEventPayload>;
  /** Updates a single `Event` using a unique key and a patch. */
  updateEventBySlug?: Maybe<UpdateEventPayload>;
  /** Updates a single `Event` using a unique key and a patch. */
  updateEventByGoogleId?: Maybe<UpdateEventPayload>;
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFileByNodeId?: Maybe<UpdateFilePayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFile?: Maybe<UpdateFilePayload>;
  /** Updates a single `GoogleAccount` using its globally unique id and a patch. */
  updateGoogleAccountByNodeId?: Maybe<UpdateGoogleAccountPayload>;
  /** Updates a single `GoogleAccount` using a unique key and a patch. */
  updateGoogleAccount?: Maybe<UpdateGoogleAccountPayload>;
  /** Updates a single `GoogleCalendarSync` using its globally unique id and a patch. */
  updateGoogleCalendarSyncByNodeId?: Maybe<UpdateGoogleCalendarSyncPayload>;
  /** Updates a single `GoogleCalendarSync` using a unique key and a patch. */
  updateGoogleCalendarSync?: Maybe<UpdateGoogleCalendarSyncPayload>;
  /** Updates a single `GoogleCalendarSyncCalendar` using its globally unique id and a patch. */
  updateGoogleCalendarSyncCalendarByNodeId?: Maybe<UpdateGoogleCalendarSyncCalendarPayload>;
  /** Updates a single `GoogleCalendarSyncCalendar` using a unique key and a patch. */
  updateGoogleCalendarSyncCalendar?: Maybe<UpdateGoogleCalendarSyncCalendarPayload>;
  /** Updates a single `Group` using its globally unique id and a patch. */
  updateGroupByNodeId?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroupBySlug?: Maybe<UpdateGroupPayload>;
  /** Updates a single `GroupPerson` using its globally unique id and a patch. */
  updateGroupPersonByNodeId?: Maybe<UpdateGroupPersonPayload>;
  /** Updates a single `GroupPerson` using a unique key and a patch. */
  updateGroupPerson?: Maybe<UpdateGroupPersonPayload>;
  /** Updates a single `GroupPerson` using a unique key and a patch. */
  updateGroupPersonByGroupIdAndPersonId?: Maybe<UpdateGroupPersonPayload>;
  /** Updates a single `GroupCategory` using its globally unique id and a patch. */
  updateGroupCategoryByNodeId?: Maybe<UpdateGroupCategoryPayload>;
  /** Updates a single `GroupCategory` using a unique key and a patch. */
  updateGroupCategory?: Maybe<UpdateGroupCategoryPayload>;
  /** Updates a single `GroupNewsletter` using its globally unique id and a patch. */
  updateGroupNewsletterByNodeId?: Maybe<UpdateGroupNewsletterPayload>;
  /** Updates a single `GroupNewsletter` using a unique key and a patch. */
  updateGroupNewsletter?: Maybe<UpdateGroupNewsletterPayload>;
  /** Updates a single `GroupNewsletter` using a unique key and a patch. */
  updateGroupNewsletterByGroupIdAndNewsletterId?: Maybe<UpdateGroupNewsletterPayload>;
  /** Updates a single `Marquee` using its globally unique id and a patch. */
  updateMarqueeByNodeId?: Maybe<UpdateMarqueePayload>;
  /** Updates a single `Marquee` using a unique key and a patch. */
  updateMarquee?: Maybe<UpdateMarqueePayload>;
  /** Updates a single `Newsletter` using its globally unique id and a patch. */
  updateNewsletterByNodeId?: Maybe<UpdateNewsletterPayload>;
  /** Updates a single `Newsletter` using a unique key and a patch. */
  updateNewsletter?: Maybe<UpdateNewsletterPayload>;
  /** Updates a single `Notification` using its globally unique id and a patch. */
  updateNotificationByNodeId?: Maybe<UpdateNotificationPayload>;
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotification?: Maybe<UpdateNotificationPayload>;
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotificationBySlug?: Maybe<UpdateNotificationPayload>;
  /** Updates a single `NotificationFile` using its globally unique id and a patch. */
  updateNotificationFileByNodeId?: Maybe<UpdateNotificationFilePayload>;
  /** Updates a single `NotificationFile` using a unique key and a patch. */
  updateNotificationFile?: Maybe<UpdateNotificationFilePayload>;
  /** Updates a single `NotificationFile` using a unique key and a patch. */
  updateNotificationFileByFileIdAndNotificationId?: Maybe<UpdateNotificationFilePayload>;
  /** Updates a single `NotificationNewsletter` using its globally unique id and a patch. */
  updateNotificationNewsletterByNodeId?: Maybe<UpdateNotificationNewsletterPayload>;
  /** Updates a single `NotificationNewsletter` using a unique key and a patch. */
  updateNotificationNewsletter?: Maybe<UpdateNotificationNewsletterPayload>;
  /** Updates a single `NotificationNewsletter` using a unique key and a patch. */
  updateNotificationNewsletterByNotificationIdAndNewsletterId?: Maybe<UpdateNotificationNewsletterPayload>;
  /** Updates a single `NotificationGroup` using its globally unique id and a patch. */
  updateNotificationGroupByNodeId?: Maybe<UpdateNotificationGroupPayload>;
  /** Updates a single `NotificationGroup` using a unique key and a patch. */
  updateNotificationGroup?: Maybe<UpdateNotificationGroupPayload>;
  /** Updates a single `NotificationGroup` using a unique key and a patch. */
  updateNotificationGroupByNotificationIdAndGroupId?: Maybe<UpdateNotificationGroupPayload>;
  /** Updates a single `Page` using its globally unique id and a patch. */
  updatePageByNodeId?: Maybe<UpdatePagePayload>;
  /** Updates a single `Page` using a unique key and a patch. */
  updatePage?: Maybe<UpdatePagePayload>;
  /** Updates a single `PageSection` using its globally unique id and a patch. */
  updatePageSectionByNodeId?: Maybe<UpdatePageSectionPayload>;
  /** Updates a single `PageSection` using a unique key and a patch. */
  updatePageSection?: Maybe<UpdatePageSectionPayload>;
  /** Updates a single `PageSectionFile` using its globally unique id and a patch. */
  updatePageSectionFileByNodeId?: Maybe<UpdatePageSectionFilePayload>;
  /** Updates a single `PageSectionFile` using a unique key and a patch. */
  updatePageSectionFile?: Maybe<UpdatePageSectionFilePayload>;
  /** Updates a single `PageSectionFile` using a unique key and a patch. */
  updatePageSectionFileByFileIdAndPageSectionId?: Maybe<UpdatePageSectionFilePayload>;
  /** Updates a single `Person` using its globally unique id and a patch. */
  updatePersonByNodeId?: Maybe<UpdatePersonPayload>;
  /** Updates a single `Person` using a unique key and a patch. */
  updatePerson?: Maybe<UpdatePersonPayload>;
  /** Updates a single `Resource` using its globally unique id and a patch. */
  updateResourceByNodeId?: Maybe<UpdateResourcePayload>;
  /** Updates a single `Resource` using a unique key and a patch. */
  updateResource?: Maybe<UpdateResourcePayload>;
  /** Updates a single `Resource` using a unique key and a patch. */
  updateResourceBySlug?: Maybe<UpdateResourcePayload>;
  /** Updates a single `ResourceFile` using its globally unique id and a patch. */
  updateResourceFileByNodeId?: Maybe<UpdateResourceFilePayload>;
  /** Updates a single `ResourceFile` using a unique key and a patch. */
  updateResourceFile?: Maybe<UpdateResourceFilePayload>;
  /** Updates a single `ResourceFile` using a unique key and a patch. */
  updateResourceFileByFileIdAndResourceId?: Maybe<UpdateResourceFilePayload>;
  /** Updates a single `School` using its globally unique id and a patch. */
  updateSchoolByNodeId?: Maybe<UpdateSchoolPayload>;
  /** Updates a single `School` using a unique key and a patch. */
  updateSchool?: Maybe<UpdateSchoolPayload>;
  /** Updates a single `School` using a unique key and a patch. */
  updateSchoolBySlug?: Maybe<UpdateSchoolPayload>;
  /** Updates a single `SchoolPerson` using its globally unique id and a patch. */
  updateSchoolPersonByNodeId?: Maybe<UpdateSchoolPersonPayload>;
  /** Updates a single `SchoolPerson` using a unique key and a patch. */
  updateSchoolPerson?: Maybe<UpdateSchoolPersonPayload>;
  /** Updates a single `SchoolPerson` using a unique key and a patch. */
  updateSchoolPersonByPersonIdAndSchoolId?: Maybe<UpdateSchoolPersonPayload>;
  /** Updates a single `SchoolPersonInstallation` using its globally unique id and a patch. */
  updateSchoolPersonInstallationByNodeId?: Maybe<UpdateSchoolPersonInstallationPayload>;
  /** Updates a single `SchoolPersonInstallation` using a unique key and a patch. */
  updateSchoolPersonInstallation?: Maybe<UpdateSchoolPersonInstallationPayload>;
  /** Updates a single `SchoolPersonInvitation` using its globally unique id and a patch. */
  updateSchoolPersonInvitationByNodeId?: Maybe<UpdateSchoolPersonInvitationPayload>;
  /** Updates a single `SchoolPersonInvitation` using a unique key and a patch. */
  updateSchoolPersonInvitation?: Maybe<UpdateSchoolPersonInvitationPayload>;
  /** Updates a single `SchoolPersonInvitation` using a unique key and a patch. */
  updateSchoolPersonInvitationBySlug?: Maybe<UpdateSchoolPersonInvitationPayload>;
  /** Updates a single `SchoolSetting` using its globally unique id and a patch. */
  updateSchoolSettingByNodeId?: Maybe<UpdateSchoolSettingPayload>;
  /** Updates a single `SchoolSetting` using a unique key and a patch. */
  updateSchoolSetting?: Maybe<UpdateSchoolSettingPayload>;
  /** Updates a single `Sponsor` using its globally unique id and a patch. */
  updateSponsorByNodeId?: Maybe<UpdateSponsorPayload>;
  /** Updates a single `Sponsor` using a unique key and a patch. */
  updateSponsor?: Maybe<UpdateSponsorPayload>;
  /** Updates a single `Sponsor` using a unique key and a patch. */
  updateSponsorBySlug?: Maybe<UpdateSponsorPayload>;
  /** Updates a single `Theme` using its globally unique id and a patch. */
  updateThemeByNodeId?: Maybe<UpdateThemePayload>;
  /** Updates a single `Theme` using a unique key and a patch. */
  updateTheme?: Maybe<UpdateThemePayload>;
  /** Updates a single `UserGoogleAccount` using its globally unique id and a patch. */
  updateUserGoogleAccountByNodeId?: Maybe<UpdateUserGoogleAccountPayload>;
  /** Updates a single `UserGoogleAccount` using a unique key and a patch. */
  updateUserGoogleAccount?: Maybe<UpdateUserGoogleAccountPayload>;
  /** Updates a single `UserGoogleAccount` using a unique key and a patch. */
  updateUserGoogleAccountByUserIdAndGoogleAccountId?: Maybe<UpdateUserGoogleAccountPayload>;
  /** Updates a single `UserSession` using its globally unique id and a patch. */
  updateUserSessionByNodeId?: Maybe<UpdateUserSessionPayload>;
  /** Updates a single `UserSession` using a unique key and a patch. */
  updateUserSession?: Maybe<UpdateUserSessionPayload>;
  /** Updates a single `AndroidApp` using its globally unique id and a patch. */
  updateAndroidAppByNodeId?: Maybe<UpdateAndroidAppPayload>;
  /** Updates a single `AndroidApp` using a unique key and a patch. */
  updateAndroidApp?: Maybe<UpdateAndroidAppPayload>;
  /** Updates a single `AndroidApp` using a unique key and a patch. */
  updateAndroidAppByAppId?: Maybe<UpdateAndroidAppPayload>;
  /** Updates a single `AndroidJavaKeyStore` using its globally unique id and a patch. */
  updateAndroidJavaKeyStoreByNodeId?: Maybe<UpdateAndroidJavaKeyStorePayload>;
  /** Updates a single `AndroidJavaKeyStore` using a unique key and a patch. */
  updateAndroidJavaKeyStore?: Maybe<UpdateAndroidJavaKeyStorePayload>;
  /** Updates a single `AndroidScreenshot` using its globally unique id and a patch. */
  updateAndroidScreenshotByNodeId?: Maybe<UpdateAndroidScreenshotPayload>;
  /** Updates a single `AndroidScreenshot` using a unique key and a patch. */
  updateAndroidScreenshot?: Maybe<UpdateAndroidScreenshotPayload>;
  /** Updates a single `AppStoreAccount` using its globally unique id and a patch. */
  updateAppStoreAccountByNodeId?: Maybe<UpdateAppStoreAccountPayload>;
  /** Updates a single `AppStoreAccount` using a unique key and a patch. */
  updateAppStoreAccount?: Maybe<UpdateAppStoreAccountPayload>;
  /** Updates a single `AppStoreAccount` using a unique key and a patch. */
  updateAppStoreAccountByAppStoreConnectId?: Maybe<UpdateAppStoreAccountPayload>;
  /** Updates a single `AppStoreAccount` using a unique key and a patch. */
  updateAppStoreAccountByAppleDeveloperAccountId?: Maybe<UpdateAppStoreAccountPayload>;
  /** Updates a single `AppStoreAccount` using a unique key and a patch. */
  updateAppStoreAccountByOrganizationName?: Maybe<UpdateAppStoreAccountPayload>;
  /** Updates a single `AppStoreListing` using its globally unique id and a patch. */
  updateAppStoreListingByNodeId?: Maybe<UpdateAppStoreListingPayload>;
  /** Updates a single `AppStoreListing` using a unique key and a patch. */
  updateAppStoreListing?: Maybe<UpdateAppStoreListingPayload>;
  /** Updates a single `AppStoreListing` using a unique key and a patch. */
  updateAppStoreListingByIosAppId?: Maybe<UpdateAppStoreListingPayload>;
  /** Updates a single `AppStoreUser` using its globally unique id and a patch. */
  updateAppStoreUserByNodeId?: Maybe<UpdateAppStoreUserPayload>;
  /** Updates a single `AppStoreUser` using a unique key and a patch. */
  updateAppStoreUser?: Maybe<UpdateAppStoreUserPayload>;
  /** Updates a single `AppStoreUser` using a unique key and a patch. */
  updateAppStoreUserByEmail?: Maybe<UpdateAppStoreUserPayload>;
  /** Updates a single `Build` using its globally unique id and a patch. */
  updateBuildByNodeId?: Maybe<UpdateBuildPayload>;
  /** Updates a single `Build` using a unique key and a patch. */
  updateBuild?: Maybe<UpdateBuildPayload>;
  /** Updates a single `BuildStep` using its globally unique id and a patch. */
  updateBuildStepByNodeId?: Maybe<UpdateBuildStepPayload>;
  /** Updates a single `BuildStep` using a unique key and a patch. */
  updateBuildStep?: Maybe<UpdateBuildStepPayload>;
  /** Updates a single `GooglePlayAccount` using its globally unique id and a patch. */
  updateGooglePlayAccountByNodeId?: Maybe<UpdateGooglePlayAccountPayload>;
  /** Updates a single `GooglePlayAccount` using a unique key and a patch. */
  updateGooglePlayAccount?: Maybe<UpdateGooglePlayAccountPayload>;
  /** Updates a single `GooglePlayListing` using its globally unique id and a patch. */
  updateGooglePlayListingByNodeId?: Maybe<UpdateGooglePlayListingPayload>;
  /** Updates a single `GooglePlayListing` using a unique key and a patch. */
  updateGooglePlayListing?: Maybe<UpdateGooglePlayListingPayload>;
  /** Updates a single `GooglePlayListing` using a unique key and a patch. */
  updateGooglePlayListingByAndroidAppId?: Maybe<UpdateGooglePlayListingPayload>;
  /** Updates a single `GooglePlayUser` using its globally unique id and a patch. */
  updateGooglePlayUserByNodeId?: Maybe<UpdateGooglePlayUserPayload>;
  /** Updates a single `GooglePlayUser` using a unique key and a patch. */
  updateGooglePlayUser?: Maybe<UpdateGooglePlayUserPayload>;
  /** Updates a single `GooglePlayUser` using a unique key and a patch. */
  updateGooglePlayUserByEmail?: Maybe<UpdateGooglePlayUserPayload>;
  /** Updates a single `IosApp` using its globally unique id and a patch. */
  updateIosAppByNodeId?: Maybe<UpdateIosAppPayload>;
  /** Updates a single `IosApp` using a unique key and a patch. */
  updateIosApp?: Maybe<UpdateIosAppPayload>;
  /** Updates a single `IosApp` using a unique key and a patch. */
  updateIosAppByAppId?: Maybe<UpdateIosAppPayload>;
  /** Updates a single `IosScreenshot` using its globally unique id and a patch. */
  updateIosScreenshotByNodeId?: Maybe<UpdateIosScreenshotPayload>;
  /** Updates a single `IosScreenshot` using a unique key and a patch. */
  updateIosScreenshot?: Maybe<UpdateIosScreenshotPayload>;
  /** Updates a single `Release` using its globally unique id and a patch. */
  updateReleaseByNodeId?: Maybe<UpdateReleasePayload>;
  /** Updates a single `Release` using a unique key and a patch. */
  updateRelease?: Maybe<UpdateReleasePayload>;
  /** Deletes a single `App` using its globally unique id. */
  deleteAppByNodeId?: Maybe<DeleteAppPayload>;
  /** Deletes a single `App` using a unique key. */
  deleteApp?: Maybe<DeleteAppPayload>;
  /** Deletes a single `App` using a unique key. */
  deleteAppBySchoolId?: Maybe<DeleteAppPayload>;
  /** Deletes a single `Calendar` using its globally unique id. */
  deleteCalendarByNodeId?: Maybe<DeleteCalendarPayload>;
  /** Deletes a single `Calendar` using a unique key. */
  deleteCalendar?: Maybe<DeleteCalendarPayload>;
  /** Deletes a single `CalendarEvent` using its globally unique id. */
  deleteCalendarEventByNodeId?: Maybe<DeleteCalendarEventPayload>;
  /** Deletes a single `CalendarEvent` using a unique key. */
  deleteCalendarEvent?: Maybe<DeleteCalendarEventPayload>;
  /** Deletes a single `CalendarEvent` using a unique key. */
  deleteCalendarEventByEventIdAndCalendarId?: Maybe<DeleteCalendarEventPayload>;
  /** Deletes a single `CalendarGroup` using its globally unique id. */
  deleteCalendarGroupByNodeId?: Maybe<DeleteCalendarGroupPayload>;
  /** Deletes a single `CalendarGroup` using a unique key. */
  deleteCalendarGroup?: Maybe<DeleteCalendarGroupPayload>;
  /** Deletes a single `CalendarGroupCalendar` using its globally unique id. */
  deleteCalendarGroupCalendarByNodeId?: Maybe<DeleteCalendarGroupCalendarPayload>;
  /** Deletes a single `CalendarGroupCalendar` using a unique key. */
  deleteCalendarGroupCalendar?: Maybe<DeleteCalendarGroupCalendarPayload>;
  /** Deletes a single `CalendarGroupCalendar` using a unique key. */
  deleteCalendarGroupCalendarByCalendarIdAndCalendarGroupId?: Maybe<DeleteCalendarGroupCalendarPayload>;
  /** Deletes a single `Division` using its globally unique id. */
  deleteDivisionByNodeId?: Maybe<DeleteDivisionPayload>;
  /** Deletes a single `Division` using a unique key. */
  deleteDivision?: Maybe<DeleteDivisionPayload>;
  /** Deletes a single `Event` using its globally unique id. */
  deleteEventByNodeId?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEventBySlug?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEventByGoogleId?: Maybe<DeleteEventPayload>;
  /** Deletes a single `File` using its globally unique id. */
  deleteFileByNodeId?: Maybe<DeleteFilePayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Deletes a single `GoogleAccount` using its globally unique id. */
  deleteGoogleAccountByNodeId?: Maybe<DeleteGoogleAccountPayload>;
  /** Deletes a single `GoogleAccount` using a unique key. */
  deleteGoogleAccount?: Maybe<DeleteGoogleAccountPayload>;
  /** Deletes a single `GoogleCalendarSync` using its globally unique id. */
  deleteGoogleCalendarSyncByNodeId?: Maybe<DeleteGoogleCalendarSyncPayload>;
  /** Deletes a single `GoogleCalendarSync` using a unique key. */
  deleteGoogleCalendarSync?: Maybe<DeleteGoogleCalendarSyncPayload>;
  /** Deletes a single `GoogleCalendarSyncCalendar` using its globally unique id. */
  deleteGoogleCalendarSyncCalendarByNodeId?: Maybe<DeleteGoogleCalendarSyncCalendarPayload>;
  /** Deletes a single `GoogleCalendarSyncCalendar` using a unique key. */
  deleteGoogleCalendarSyncCalendar?: Maybe<DeleteGoogleCalendarSyncCalendarPayload>;
  /** Deletes a single `Group` using its globally unique id. */
  deleteGroupByNodeId?: Maybe<DeleteGroupPayload>;
  /** Deletes a single `Group` using a unique key. */
  deleteGroup?: Maybe<DeleteGroupPayload>;
  /** Deletes a single `Group` using a unique key. */
  deleteGroupBySlug?: Maybe<DeleteGroupPayload>;
  /** Deletes a single `GroupPerson` using its globally unique id. */
  deleteGroupPersonByNodeId?: Maybe<DeleteGroupPersonPayload>;
  /** Deletes a single `GroupPerson` using a unique key. */
  deleteGroupPerson?: Maybe<DeleteGroupPersonPayload>;
  /** Deletes a single `GroupPerson` using a unique key. */
  deleteGroupPersonByGroupIdAndPersonId?: Maybe<DeleteGroupPersonPayload>;
  /** Deletes a single `GroupCategory` using its globally unique id. */
  deleteGroupCategoryByNodeId?: Maybe<DeleteGroupCategoryPayload>;
  /** Deletes a single `GroupCategory` using a unique key. */
  deleteGroupCategory?: Maybe<DeleteGroupCategoryPayload>;
  /** Deletes a single `GroupNewsletter` using its globally unique id. */
  deleteGroupNewsletterByNodeId?: Maybe<DeleteGroupNewsletterPayload>;
  /** Deletes a single `GroupNewsletter` using a unique key. */
  deleteGroupNewsletter?: Maybe<DeleteGroupNewsletterPayload>;
  /** Deletes a single `GroupNewsletter` using a unique key. */
  deleteGroupNewsletterByGroupIdAndNewsletterId?: Maybe<DeleteGroupNewsletterPayload>;
  /** Deletes a single `Marquee` using its globally unique id. */
  deleteMarqueeByNodeId?: Maybe<DeleteMarqueePayload>;
  /** Deletes a single `Marquee` using a unique key. */
  deleteMarquee?: Maybe<DeleteMarqueePayload>;
  /** Deletes a single `Newsletter` using its globally unique id. */
  deleteNewsletterByNodeId?: Maybe<DeleteNewsletterPayload>;
  /** Deletes a single `Newsletter` using a unique key. */
  deleteNewsletter?: Maybe<DeleteNewsletterPayload>;
  /** Deletes a single `Notification` using its globally unique id. */
  deleteNotificationByNodeId?: Maybe<DeleteNotificationPayload>;
  /** Deletes a single `Notification` using a unique key. */
  deleteNotification?: Maybe<DeleteNotificationPayload>;
  /** Deletes a single `Notification` using a unique key. */
  deleteNotificationBySlug?: Maybe<DeleteNotificationPayload>;
  /** Deletes a single `NotificationFile` using its globally unique id. */
  deleteNotificationFileByNodeId?: Maybe<DeleteNotificationFilePayload>;
  /** Deletes a single `NotificationFile` using a unique key. */
  deleteNotificationFile?: Maybe<DeleteNotificationFilePayload>;
  /** Deletes a single `NotificationFile` using a unique key. */
  deleteNotificationFileByFileIdAndNotificationId?: Maybe<DeleteNotificationFilePayload>;
  /** Deletes a single `NotificationNewsletter` using its globally unique id. */
  deleteNotificationNewsletterByNodeId?: Maybe<DeleteNotificationNewsletterPayload>;
  /** Deletes a single `NotificationNewsletter` using a unique key. */
  deleteNotificationNewsletter?: Maybe<DeleteNotificationNewsletterPayload>;
  /** Deletes a single `NotificationNewsletter` using a unique key. */
  deleteNotificationNewsletterByNotificationIdAndNewsletterId?: Maybe<DeleteNotificationNewsletterPayload>;
  /** Deletes a single `NotificationGroup` using its globally unique id. */
  deleteNotificationGroupByNodeId?: Maybe<DeleteNotificationGroupPayload>;
  /** Deletes a single `NotificationGroup` using a unique key. */
  deleteNotificationGroup?: Maybe<DeleteNotificationGroupPayload>;
  /** Deletes a single `NotificationGroup` using a unique key. */
  deleteNotificationGroupByNotificationIdAndGroupId?: Maybe<DeleteNotificationGroupPayload>;
  /** Deletes a single `Page` using its globally unique id. */
  deletePageByNodeId?: Maybe<DeletePagePayload>;
  /** Deletes a single `Page` using a unique key. */
  deletePage?: Maybe<DeletePagePayload>;
  /** Deletes a single `PageSection` using its globally unique id. */
  deletePageSectionByNodeId?: Maybe<DeletePageSectionPayload>;
  /** Deletes a single `PageSection` using a unique key. */
  deletePageSection?: Maybe<DeletePageSectionPayload>;
  /** Deletes a single `PageSectionFile` using its globally unique id. */
  deletePageSectionFileByNodeId?: Maybe<DeletePageSectionFilePayload>;
  /** Deletes a single `PageSectionFile` using a unique key. */
  deletePageSectionFile?: Maybe<DeletePageSectionFilePayload>;
  /** Deletes a single `PageSectionFile` using a unique key. */
  deletePageSectionFileByFileIdAndPageSectionId?: Maybe<DeletePageSectionFilePayload>;
  /** Deletes a single `Person` using its globally unique id. */
  deletePersonByNodeId?: Maybe<DeletePersonPayload>;
  /** Deletes a single `Person` using a unique key. */
  deletePerson?: Maybe<DeletePersonPayload>;
  /** Deletes a single `Resource` using its globally unique id. */
  deleteResourceByNodeId?: Maybe<DeleteResourcePayload>;
  /** Deletes a single `Resource` using a unique key. */
  deleteResource?: Maybe<DeleteResourcePayload>;
  /** Deletes a single `Resource` using a unique key. */
  deleteResourceBySlug?: Maybe<DeleteResourcePayload>;
  /** Deletes a single `ResourceFile` using its globally unique id. */
  deleteResourceFileByNodeId?: Maybe<DeleteResourceFilePayload>;
  /** Deletes a single `ResourceFile` using a unique key. */
  deleteResourceFile?: Maybe<DeleteResourceFilePayload>;
  /** Deletes a single `ResourceFile` using a unique key. */
  deleteResourceFileByFileIdAndResourceId?: Maybe<DeleteResourceFilePayload>;
  /** Deletes a single `School` using its globally unique id. */
  deleteSchoolByNodeId?: Maybe<DeleteSchoolPayload>;
  /** Deletes a single `School` using a unique key. */
  deleteSchool?: Maybe<DeleteSchoolPayload>;
  /** Deletes a single `School` using a unique key. */
  deleteSchoolBySlug?: Maybe<DeleteSchoolPayload>;
  /** Deletes a single `SchoolPerson` using its globally unique id. */
  deleteSchoolPersonByNodeId?: Maybe<DeleteSchoolPersonPayload>;
  /** Deletes a single `SchoolPerson` using a unique key. */
  deleteSchoolPerson?: Maybe<DeleteSchoolPersonPayload>;
  /** Deletes a single `SchoolPerson` using a unique key. */
  deleteSchoolPersonByPersonIdAndSchoolId?: Maybe<DeleteSchoolPersonPayload>;
  /** Deletes a single `SchoolPersonInstallation` using its globally unique id. */
  deleteSchoolPersonInstallationByNodeId?: Maybe<DeleteSchoolPersonInstallationPayload>;
  /** Deletes a single `SchoolPersonInstallation` using a unique key. */
  deleteSchoolPersonInstallation?: Maybe<DeleteSchoolPersonInstallationPayload>;
  /** Deletes a single `SchoolPersonInvitation` using its globally unique id. */
  deleteSchoolPersonInvitationByNodeId?: Maybe<DeleteSchoolPersonInvitationPayload>;
  /** Deletes a single `SchoolPersonInvitation` using a unique key. */
  deleteSchoolPersonInvitation?: Maybe<DeleteSchoolPersonInvitationPayload>;
  /** Deletes a single `SchoolPersonInvitation` using a unique key. */
  deleteSchoolPersonInvitationBySlug?: Maybe<DeleteSchoolPersonInvitationPayload>;
  /** Deletes a single `SchoolSetting` using its globally unique id. */
  deleteSchoolSettingByNodeId?: Maybe<DeleteSchoolSettingPayload>;
  /** Deletes a single `SchoolSetting` using a unique key. */
  deleteSchoolSetting?: Maybe<DeleteSchoolSettingPayload>;
  /** Deletes a single `Sponsor` using its globally unique id. */
  deleteSponsorByNodeId?: Maybe<DeleteSponsorPayload>;
  /** Deletes a single `Sponsor` using a unique key. */
  deleteSponsor?: Maybe<DeleteSponsorPayload>;
  /** Deletes a single `Sponsor` using a unique key. */
  deleteSponsorBySlug?: Maybe<DeleteSponsorPayload>;
  /** Deletes a single `Theme` using its globally unique id. */
  deleteThemeByNodeId?: Maybe<DeleteThemePayload>;
  /** Deletes a single `Theme` using a unique key. */
  deleteTheme?: Maybe<DeleteThemePayload>;
  /** Deletes a single `UserGoogleAccount` using its globally unique id. */
  deleteUserGoogleAccountByNodeId?: Maybe<DeleteUserGoogleAccountPayload>;
  /** Deletes a single `UserGoogleAccount` using a unique key. */
  deleteUserGoogleAccount?: Maybe<DeleteUserGoogleAccountPayload>;
  /** Deletes a single `UserGoogleAccount` using a unique key. */
  deleteUserGoogleAccountByUserIdAndGoogleAccountId?: Maybe<DeleteUserGoogleAccountPayload>;
  /** Deletes a single `UserSession` using its globally unique id. */
  deleteUserSessionByNodeId?: Maybe<DeleteUserSessionPayload>;
  /** Deletes a single `UserSession` using a unique key. */
  deleteUserSession?: Maybe<DeleteUserSessionPayload>;
  /** Deletes a single `AndroidApp` using its globally unique id. */
  deleteAndroidAppByNodeId?: Maybe<DeleteAndroidAppPayload>;
  /** Deletes a single `AndroidApp` using a unique key. */
  deleteAndroidApp?: Maybe<DeleteAndroidAppPayload>;
  /** Deletes a single `AndroidApp` using a unique key. */
  deleteAndroidAppByAppId?: Maybe<DeleteAndroidAppPayload>;
  /** Deletes a single `AndroidJavaKeyStore` using its globally unique id. */
  deleteAndroidJavaKeyStoreByNodeId?: Maybe<DeleteAndroidJavaKeyStorePayload>;
  /** Deletes a single `AndroidJavaKeyStore` using a unique key. */
  deleteAndroidJavaKeyStore?: Maybe<DeleteAndroidJavaKeyStorePayload>;
  /** Deletes a single `AndroidScreenshot` using its globally unique id. */
  deleteAndroidScreenshotByNodeId?: Maybe<DeleteAndroidScreenshotPayload>;
  /** Deletes a single `AndroidScreenshot` using a unique key. */
  deleteAndroidScreenshot?: Maybe<DeleteAndroidScreenshotPayload>;
  /** Deletes a single `AppStoreAccount` using its globally unique id. */
  deleteAppStoreAccountByNodeId?: Maybe<DeleteAppStoreAccountPayload>;
  /** Deletes a single `AppStoreAccount` using a unique key. */
  deleteAppStoreAccount?: Maybe<DeleteAppStoreAccountPayload>;
  /** Deletes a single `AppStoreAccount` using a unique key. */
  deleteAppStoreAccountByAppStoreConnectId?: Maybe<DeleteAppStoreAccountPayload>;
  /** Deletes a single `AppStoreAccount` using a unique key. */
  deleteAppStoreAccountByAppleDeveloperAccountId?: Maybe<DeleteAppStoreAccountPayload>;
  /** Deletes a single `AppStoreAccount` using a unique key. */
  deleteAppStoreAccountByOrganizationName?: Maybe<DeleteAppStoreAccountPayload>;
  /** Deletes a single `AppStoreListing` using its globally unique id. */
  deleteAppStoreListingByNodeId?: Maybe<DeleteAppStoreListingPayload>;
  /** Deletes a single `AppStoreListing` using a unique key. */
  deleteAppStoreListing?: Maybe<DeleteAppStoreListingPayload>;
  /** Deletes a single `AppStoreListing` using a unique key. */
  deleteAppStoreListingByIosAppId?: Maybe<DeleteAppStoreListingPayload>;
  /** Deletes a single `AppStoreUser` using its globally unique id. */
  deleteAppStoreUserByNodeId?: Maybe<DeleteAppStoreUserPayload>;
  /** Deletes a single `AppStoreUser` using a unique key. */
  deleteAppStoreUser?: Maybe<DeleteAppStoreUserPayload>;
  /** Deletes a single `AppStoreUser` using a unique key. */
  deleteAppStoreUserByEmail?: Maybe<DeleteAppStoreUserPayload>;
  /** Deletes a single `Build` using its globally unique id. */
  deleteBuildByNodeId?: Maybe<DeleteBuildPayload>;
  /** Deletes a single `Build` using a unique key. */
  deleteBuild?: Maybe<DeleteBuildPayload>;
  /** Deletes a single `BuildStep` using its globally unique id. */
  deleteBuildStepByNodeId?: Maybe<DeleteBuildStepPayload>;
  /** Deletes a single `BuildStep` using a unique key. */
  deleteBuildStep?: Maybe<DeleteBuildStepPayload>;
  /** Deletes a single `GooglePlayAccount` using its globally unique id. */
  deleteGooglePlayAccountByNodeId?: Maybe<DeleteGooglePlayAccountPayload>;
  /** Deletes a single `GooglePlayAccount` using a unique key. */
  deleteGooglePlayAccount?: Maybe<DeleteGooglePlayAccountPayload>;
  /** Deletes a single `GooglePlayListing` using its globally unique id. */
  deleteGooglePlayListingByNodeId?: Maybe<DeleteGooglePlayListingPayload>;
  /** Deletes a single `GooglePlayListing` using a unique key. */
  deleteGooglePlayListing?: Maybe<DeleteGooglePlayListingPayload>;
  /** Deletes a single `GooglePlayListing` using a unique key. */
  deleteGooglePlayListingByAndroidAppId?: Maybe<DeleteGooglePlayListingPayload>;
  /** Deletes a single `GooglePlayUser` using its globally unique id. */
  deleteGooglePlayUserByNodeId?: Maybe<DeleteGooglePlayUserPayload>;
  /** Deletes a single `GooglePlayUser` using a unique key. */
  deleteGooglePlayUser?: Maybe<DeleteGooglePlayUserPayload>;
  /** Deletes a single `GooglePlayUser` using a unique key. */
  deleteGooglePlayUserByEmail?: Maybe<DeleteGooglePlayUserPayload>;
  /** Deletes a single `IosApp` using its globally unique id. */
  deleteIosAppByNodeId?: Maybe<DeleteIosAppPayload>;
  /** Deletes a single `IosApp` using a unique key. */
  deleteIosApp?: Maybe<DeleteIosAppPayload>;
  /** Deletes a single `IosApp` using a unique key. */
  deleteIosAppByAppId?: Maybe<DeleteIosAppPayload>;
  /** Deletes a single `IosScreenshot` using its globally unique id. */
  deleteIosScreenshotByNodeId?: Maybe<DeleteIosScreenshotPayload>;
  /** Deletes a single `IosScreenshot` using a unique key. */
  deleteIosScreenshot?: Maybe<DeleteIosScreenshotPayload>;
  /** Deletes a single `Release` using its globally unique id. */
  deleteReleaseByNodeId?: Maybe<DeleteReleasePayload>;
  /** Deletes a single `Release` using a unique key. */
  deleteRelease?: Maybe<DeleteReleasePayload>;
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  /** Creates a JWT token that will securely identify a user and give them certain permissions. This token expires in 5 days. */
  authenticate?: Maybe<AuthenticatePayload>;
  /** Cancels a scheduled settings reset for a user type within a school. */
  cancelScheduleSettingsResetForSchoolUserType?: Maybe<CancelScheduleSettingsResetForSchoolUserTypePayload>;
  /** Allows an anonymous account to be claimed. Could be claimed by either a) supplying an email/password for it or b) authenticating as an existing user and claiming. If claiming as an existing user, the anonymous account's data is merged into the claiming user's. Returns a JWT for the final user (either the claiming user if it exists or the anonymous user that now has an email/password). */
  claimAnonymousAccount?: Maybe<ClaimAnonymousAccountPayload>;
  /** Create a Google Calendar Sync record and GoogleCalendarSync_Calendar join objects. */
  createGoogleCalendarSyncWithCalendars?: Maybe<CreateGoogleCalendarSyncWithCalendarsPayload>;
  /**
   * Creates or updates a `CalendarGroup`. If no `calendar_group_id` is provided a new `CalendarGroup` will be created. <br /><br />
   *
   * If a `calendar_group_id` is provided then it will be updated if it exists, if it does not exist then a new `CalendarGroup` will be created with the provided ID.
   */
  createOrUpdateCalendarGroup?: Maybe<CreateOrUpdateCalendarGroupPayload>;
  /** Creates an event and join objects for the provided calendar IDs. */
  createOrUpdateEventForCalendars?: Maybe<CreateOrUpdateEventForCalendarsPayload>;
  /**
   * We do a one-to-many relationship between a GoogleAccountRecord and Users...
   *
   *
   *
   * Google only allows for so many live refresh tokens for a Google account, so if too many users sign in with a shared account (e.g. office@school.org) we may get to a limit.
   *
   *
   *
   * And even if we don't get near that limit, it's probably a good idea to enforce a unique constraint on auth credentials for a Google account - we should only ever have one GoogleAccountRecord per Google account.
   *
   *
   *
   * Then, if we want, we can allow unlimited users to "re-authenticate" that one account, but if they do successfully authenticate all the does is give them "access" to the account via a one-to-many join object record in our datastore.
   * They are able to view calendar lists, set up syncs, disable syncs, etc. for calendars from that auth (along with unlimited other users who knew the password and were able to authenticate that Google account).
   */
  createOrUpdateGoogleAccount?: Maybe<CreateOrUpdateGoogleAccountPayload>;
  createOrUpdateNotification?: Maybe<CreateOrUpdateNotificationPayload>;
  deleteFileIfPossible?: Maybe<DeleteFileIfPossiblePayload>;
  /** Removes a GCS and, optionally, all events synced from this Google calendar. */
  deleteGcs?: Maybe<DeleteGcsPayload>;
  duplicateNewsletter?: Maybe<DuplicateNewsletterPayload>;
  /** One time function to add all 3.0 subscription selections to matching 4.0 installations (already updated apps). Only ADDS 3.0 subscription selections, does not remove any newly added 4.0 selections made by the user. */
  legacyMigrationAddLegacySubscriptionsForPerson?: Maybe<LegacyMigrationAddLegacySubscriptionsForPersonPayload>;
  /** Special version of Group membership update function for handling legacy subscriptions. Gracefully handles when the Group that a user was subscribed to in 3.0 does not exist in 4.0. */
  legacyMigrationUpdatePersonGroupMembershipForSchool?: Maybe<LegacyMigrationUpdatePersonGroupMembershipForSchoolPayload>;
  /** Only one `Division` can be marked as `schoolwide` for a single `School`. This function marks all `Divisions` for a `School` as NOT `schoolwide` then marks the single one matching the provided ID as `schoolwide`. */
  makeDivisionSchoolwide?: Maybe<MakeDivisionSchoolwidePayload>;
  /** Dedicated function used during 3.0 migration to port legacy 3.0 Installations' Group subscriptions. This function is a part of supporting Jira issue K12-50. */
  migrateLegacyInstallationsSubscriptions?: Maybe<MigrateLegacyInstallationsSubscriptionsPayload>;
  /** Dedicated function used during 3.0 migration to port legacy 3.0 OneSignal devices that haven't yet upgraded their apps. This function is a part of supporting Jira issue K12-50. */
  migrateLegacyOneSignalDevices?: Maybe<MigrateLegacyOneSignalDevicesPayload>;
  /** Dedicated function used during 3.0 migration to port subscription selections made by users in 3.0 to their updated installations in 4.0. This function is a part of supporting Jira issue K12-50. */
  migrateLegacySubscriptionSelectionsForUpdatedUsers?: Maybe<MigrateLegacySubscriptionSelectionsForUpdatedUsersPayload>;
  processGoogleCalendarSync?: Maybe<ProcessGoogleCalendarSyncPayload>;
  /** Publishes a Newsletter by sending a push notification to its target Groups/UserTypes. */
  publishNewsletter?: Maybe<PublishNewsletterPayload>;
  /** Registers an anonymous user creating their profile (app_public.person) and an account (app_private.user_account). */
  registerAnonymousUser?: Maybe<RegisterAnonymousUserPayload>;
  /**
   * Registers a single user creating their profile (app_public.person) and an account (app_private.user_account).
   *
   *
   * Follows guidelines from [here](https://www.graphile.org/postgraphile/postgresql-schema-design/#storing-emails-and-passwords).
   */
  registerUser?: Maybe<RegisterUserPayload>;
  removeFacultyStaffPersonFromSchool?: Maybe<RemoveFacultyStaffPersonFromSchoolPayload>;
  /** Removes a Google Account, all associated Google Calendar Syncs, and, optionally, all events synced from those Google Calendar Syncs. */
  removeGoogleAccount?: Maybe<RemoveGoogleAccountPayload>;
  /**
   *  Resets a Google Calendar Sync.
   *
   *
   *
   * 1. Clears the next sync token.
   *
   *  2. Deletes all events from this Google Calendar.
   *
   *  3. Triggers a sync job for this record (effectively doing an initial sync).
   */
  resetGoogleCalendarSync?: Maybe<ResetGoogleCalendarSyncPayload>;
  /** Redeem a password reset token. */
  resetPassword?: Maybe<ResetPasswordPayload>;
  schoolPersonInvitationSend?: Maybe<SchoolPersonInvitationSendPayload>;
  /** Fetch all group IDs of a school, including division group IDs (which will include the schoolwide division). */
  schoolGroupIds?: Maybe<SchoolGroupIdsPayload>;
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  /** Explicitly sets the Groups within a School that a Person is an admin for. */
  setSchoolAdminGroupsForPerson?: Maybe<SetSchoolAdminGroupsForPersonPayload>;
  /**
   * We break this worked call out into a separate function since it requires SECURITY DEFINER.
   *
   *  Even though it would be better to put some explicit checks on permissions for a user to stop a watch channel, at least putting this function here will minimize the circumstances when someone could figure out how to inappropriately stop a watch channel since they'd have to be able to pass the appropriate params.
   */
  stopGoogleWatchChannel?: Maybe<StopGoogleWatchChannelPayload>;
  /** Submit the current person's onboarding for a school. */
  submitCurrentPersonSchoolOnboarding?: Maybe<SubmitCurrentPersonSchoolOnboardingPayload>;
  /** Merges the provided JSON into the user's current `session_data` overwriting any keys that already exist. */
  updateCurrentUserSessionData?: Maybe<UpdateCurrentUserSessionDataPayload>;
  /** Updates the recipients for a Newsletter (adding/removing Group__Newsletter join objects as necessary). */
  updateNewsletterRecipients?: Maybe<UpdateNewsletterRecipientsPayload>;
  /** Update a PageSection's sort order along with the sort order of any other PageSections in the same Page. */
  updatePageSectionSortOrder?: Maybe<UpdatePageSectionSortOrderPayload>;
  /** This function is ONLY concerned with Groups that a user can self join/leave. It cannot be used to update a Person's membership in auto-enroll Groups. It updates a Person's member status for Groups (within a School) to the provided Group IDs. */
  updatePersonGroupMembershipForSchool?: Maybe<UpdatePersonGroupMembershipForSchoolPayload>;
  /** Update a Resource's sort order along with the sort order of any other Resources in the same list. */
  updateResourceSortOrder?: Maybe<UpdateResourceSortOrderPayload>;
  /** Update a Sponsor's sort order along with the sort order of any other Sponsors in the same list. */
  updateSponsorSortOrder?: Maybe<UpdateSponsorSortOrderPayload>;
  /** If the value for jks_64 is null on a JKS, then update it. Otherwise safely archive, and make a new jks. */
  addJks64ToJavaKeyStore?: Maybe<AddJks64ToJavaKeyStorePayload>;
  /** Safely archives while creating a new jks with helpful note pointing back to the archived jks. */
  archiveAndroidJavaKeyStoreAndCreateNew?: Maybe<ArchiveAndroidJavaKeyStoreAndCreateNewPayload>;
  /** Updates the bundle id, and archives the old jks and creates a new one. */
  changeAndroidAppBundleId?: Maybe<ChangeAndroidAppBundleIdPayload>;
  /**
   * Creates the app, android_app, and google_play_listing for a school linking them together with the appropriate foreign keys.
   *
   * This will later include ios_app and app_store_listing.
   *
   * This does not automatically add any of the fields. It just creates new rows with the default values and foreign keys.
   *
   * This function does not create  java key stores, google play accounts or users, app store connect accounts or users, or logs.
   */
  createDeploymentRowsForSchool?: Maybe<CreateDeploymentRowsForSchoolPayload>;
  /** Safely creates new jks where if there is an active jks, it is archived and the new jks is created. */
  createNewAndroidJavaKeyStore?: Maybe<CreateNewAndroidJavaKeyStorePayload>;
  createNewAppStoreUser?: Maybe<CreateNewAppStoreUserPayload>;
  /** Creates a new build with the latest release for the app platform, a commit message informed by the last live build it's release, and smartly sets the new app_version. */
  createNewBuildForAppOnPlatform?: Maybe<CreateNewBuildForAppOnPlatformPayload>;
  createNewGooglePlayUser?: Maybe<CreateNewGooglePlayUserPayload>;
  /** Create a new release for the platform, and correctly assign the version number. */
  createNewReleaseForPlatform?: Maybe<CreateNewReleaseForPlatformPayload>;
  /** This will mark a step as failed and mark a build as failed if there aren't any steps to rescue it. */
  failBuildStep?: Maybe<FailBuildStepPayload>;
  /** Determine the appropriate `app_version` and update this build to have that app_version. */
  getThenSetAppVersionForBuild?: Maybe<GetThenSetAppVersionForBuildPayload>;
  /** This marks this step and it's build as killed. Supposed to mark when an LA user cancels a build. */
  killBuildStepAndBuild?: Maybe<KillBuildStepAndBuildPayload>;
  /** Creates the starting build step for the platform. Fails if there is not a build with that id, the build is started/failed/killed, or if there are build steps already. */
  makeStartingBuildStepForBuild?: Maybe<MakeStartingBuildStepForBuildPayload>;
  /** Updates sets never_nuke_certs_and_profiles to true on the specified app store account. */
  markAsNeverNuke?: Maybe<MarkAsNeverNukePayload>;
  /** Updates the expiration date to null on the specified app store account. */
  markDistCertsAsNuked?: Maybe<MarkDistCertsAsNukedPayload>;
  /** This will mark a step as started and mark a build as started if it's the first step */
  startBuildStep?: Maybe<StartBuildStepPayload>;
  /** This will mark a step as succeeded and mark a build as went_live if it's the last step */
  succeedBuildStep?: Maybe<SucceedBuildStepPayload>;
  /** Updates the expiration date on the dist cert to a year from now if the expiration date was null before (the sign of a nuke or new app). */
  updateDistCertExpDate?: Maybe<UpdateDistCertExpDatePayload>;
  /** Updates the expiration date of the push cert to 13 months minus a day from now. */
  updateExpirationDateOnPushCert?: Maybe<UpdateExpirationDateOnPushCertPayload>;
  /** Wrapping around add_jks_64_to_java_key_store where we go through the app_id. */
  updateJksWithJks64FromApp?: Maybe<UpdateJksWithJks64FromAppPayload>;
  /** Creates a new account or updates the existing account and adds it to the play store listing. */
  updatePlayStoreListingWithCreatedOrUpdatedAccount?: Maybe<UpdatePlayStoreListingWithCreatedOrUpdatedAccountPayload>;
  presignedUploadUrl?: Maybe<PresignedUploadUrlPayload>;
  deleteTemporaryAwsS3Upload?: Maybe<Scalars['Boolean']>;
  scheduleSettingsReset?: Maybe<Scalars['Boolean']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppArgs = {
  input: CreateAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCalendarGroupArgs = {
  input: CreateCalendarGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCalendarGroupCalendarArgs = {
  input: CreateCalendarGroupCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDivisionArgs = {
  input: CreateDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleAccountArgs = {
  input: CreateGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleCalendarSyncArgs = {
  input: CreateGoogleCalendarSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleCalendarSyncCalendarArgs = {
  input: CreateGoogleCalendarSyncCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupPersonArgs = {
  input: CreateGroupPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupCategoryArgs = {
  input: CreateGroupCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupNewsletterArgs = {
  input: CreateGroupNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMarqueeArgs = {
  input: CreateMarqueeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationFileArgs = {
  input: CreateNotificationFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationNewsletterArgs = {
  input: CreateNotificationNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationGroupArgs = {
  input: CreateNotificationGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePageSectionArgs = {
  input: CreatePageSectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePageSectionFileArgs = {
  input: CreatePageSectionFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePersonArgs = {
  input: CreatePersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateResourceFileArgs = {
  input: CreateResourceFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSchoolPersonArgs = {
  input: CreateSchoolPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSchoolPersonInstallationArgs = {
  input: CreateSchoolPersonInstallationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSchoolPersonInvitationArgs = {
  input: CreateSchoolPersonInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSchoolSettingArgs = {
  input: CreateSchoolSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSponsorArgs = {
  input: CreateSponsorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserGoogleAccountArgs = {
  input: CreateUserGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserSessionArgs = {
  input: CreateUserSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAndroidAppArgs = {
  input: CreateAndroidAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAndroidJavaKeyStoreArgs = {
  input: CreateAndroidJavaKeyStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAndroidScreenshotArgs = {
  input: CreateAndroidScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppStoreAccountArgs = {
  input: CreateAppStoreAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppStoreListingArgs = {
  input: CreateAppStoreListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppStoreUserArgs = {
  input: CreateAppStoreUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBuildArgs = {
  input: CreateBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBuildStepArgs = {
  input: CreateBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGooglePlayAccountArgs = {
  input: CreateGooglePlayAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGooglePlayListingArgs = {
  input: CreateGooglePlayListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGooglePlayUserArgs = {
  input: CreateGooglePlayUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIosAppArgs = {
  input: CreateIosAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIosScreenshotArgs = {
  input: CreateIosScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppByNodeIdArgs = {
  input: UpdateAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppArgs = {
  input: UpdateAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppBySchoolIdArgs = {
  input: UpdateAppBySchoolIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarByNodeIdArgs = {
  input: UpdateCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarArgs = {
  input: UpdateCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarEventByNodeIdArgs = {
  input: UpdateCalendarEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarEventArgs = {
  input: UpdateCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarEventByEventIdAndCalendarIdArgs = {
  input: UpdateCalendarEventByEventIdAndCalendarIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarGroupByNodeIdArgs = {
  input: UpdateCalendarGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarGroupArgs = {
  input: UpdateCalendarGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarGroupCalendarByNodeIdArgs = {
  input: UpdateCalendarGroupCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarGroupCalendarArgs = {
  input: UpdateCalendarGroupCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCalendarGroupCalendarByCalendarIdAndCalendarGroupIdArgs = {
  input: UpdateCalendarGroupCalendarByCalendarIdAndCalendarGroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDivisionByNodeIdArgs = {
  input: UpdateDivisionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDivisionArgs = {
  input: UpdateDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventByNodeIdArgs = {
  input: UpdateEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventBySlugArgs = {
  input: UpdateEventBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventByGoogleIdArgs = {
  input: UpdateEventByGoogleIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByNodeIdArgs = {
  input: UpdateFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleAccountByNodeIdArgs = {
  input: UpdateGoogleAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleAccountArgs = {
  input: UpdateGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarSyncByNodeIdArgs = {
  input: UpdateGoogleCalendarSyncByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarSyncArgs = {
  input: UpdateGoogleCalendarSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarSyncCalendarByNodeIdArgs = {
  input: UpdateGoogleCalendarSyncCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarSyncCalendarArgs = {
  input: UpdateGoogleCalendarSyncCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByNodeIdArgs = {
  input: UpdateGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupBySlugArgs = {
  input: UpdateGroupBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupPersonByNodeIdArgs = {
  input: UpdateGroupPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupPersonArgs = {
  input: UpdateGroupPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupPersonByGroupIdAndPersonIdArgs = {
  input: UpdateGroupPersonByGroupIdAndPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupCategoryByNodeIdArgs = {
  input: UpdateGroupCategoryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupCategoryArgs = {
  input: UpdateGroupCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupNewsletterByNodeIdArgs = {
  input: UpdateGroupNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupNewsletterArgs = {
  input: UpdateGroupNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupNewsletterByGroupIdAndNewsletterIdArgs = {
  input: UpdateGroupNewsletterByGroupIdAndNewsletterIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMarqueeByNodeIdArgs = {
  input: UpdateMarqueeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMarqueeArgs = {
  input: UpdateMarqueeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNewsletterByNodeIdArgs = {
  input: UpdateNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNewsletterArgs = {
  input: UpdateNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByNodeIdArgs = {
  input: UpdateNotificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationBySlugArgs = {
  input: UpdateNotificationBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationFileByNodeIdArgs = {
  input: UpdateNotificationFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationFileArgs = {
  input: UpdateNotificationFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationFileByFileIdAndNotificationIdArgs = {
  input: UpdateNotificationFileByFileIdAndNotificationIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationNewsletterByNodeIdArgs = {
  input: UpdateNotificationNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationNewsletterArgs = {
  input: UpdateNotificationNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationNewsletterByNotificationIdAndNewsletterIdArgs = {
  input: UpdateNotificationNewsletterByNotificationIdAndNewsletterIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationGroupByNodeIdArgs = {
  input: UpdateNotificationGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationGroupArgs = {
  input: UpdateNotificationGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationGroupByNotificationIdAndGroupIdArgs = {
  input: UpdateNotificationGroupByNotificationIdAndGroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageByNodeIdArgs = {
  input: UpdatePageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionByNodeIdArgs = {
  input: UpdatePageSectionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionArgs = {
  input: UpdatePageSectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionFileByNodeIdArgs = {
  input: UpdatePageSectionFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionFileArgs = {
  input: UpdatePageSectionFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionFileByFileIdAndPageSectionIdArgs = {
  input: UpdatePageSectionFileByFileIdAndPageSectionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByNodeIdArgs = {
  input: UpdatePersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceByNodeIdArgs = {
  input: UpdateResourceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceBySlugArgs = {
  input: UpdateResourceBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceFileByNodeIdArgs = {
  input: UpdateResourceFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceFileArgs = {
  input: UpdateResourceFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceFileByFileIdAndResourceIdArgs = {
  input: UpdateResourceFileByFileIdAndResourceIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolByNodeIdArgs = {
  input: UpdateSchoolByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolBySlugArgs = {
  input: UpdateSchoolBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonByNodeIdArgs = {
  input: UpdateSchoolPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonArgs = {
  input: UpdateSchoolPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonByPersonIdAndSchoolIdArgs = {
  input: UpdateSchoolPersonByPersonIdAndSchoolIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonInstallationByNodeIdArgs = {
  input: UpdateSchoolPersonInstallationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonInstallationArgs = {
  input: UpdateSchoolPersonInstallationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonInvitationByNodeIdArgs = {
  input: UpdateSchoolPersonInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonInvitationArgs = {
  input: UpdateSchoolPersonInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolPersonInvitationBySlugArgs = {
  input: UpdateSchoolPersonInvitationBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolSettingByNodeIdArgs = {
  input: UpdateSchoolSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSchoolSettingArgs = {
  input: UpdateSchoolSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsorByNodeIdArgs = {
  input: UpdateSponsorByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsorArgs = {
  input: UpdateSponsorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsorBySlugArgs = {
  input: UpdateSponsorBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateThemeByNodeIdArgs = {
  input: UpdateThemeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserGoogleAccountByNodeIdArgs = {
  input: UpdateUserGoogleAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserGoogleAccountArgs = {
  input: UpdateUserGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserGoogleAccountByUserIdAndGoogleAccountIdArgs = {
  input: UpdateUserGoogleAccountByUserIdAndGoogleAccountIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserSessionByNodeIdArgs = {
  input: UpdateUserSessionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserSessionArgs = {
  input: UpdateUserSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidAppByNodeIdArgs = {
  input: UpdateAndroidAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidAppArgs = {
  input: UpdateAndroidAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidAppByAppIdArgs = {
  input: UpdateAndroidAppByAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidJavaKeyStoreByNodeIdArgs = {
  input: UpdateAndroidJavaKeyStoreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidJavaKeyStoreArgs = {
  input: UpdateAndroidJavaKeyStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidScreenshotByNodeIdArgs = {
  input: UpdateAndroidScreenshotByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAndroidScreenshotArgs = {
  input: UpdateAndroidScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreAccountByNodeIdArgs = {
  input: UpdateAppStoreAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreAccountArgs = {
  input: UpdateAppStoreAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreAccountByAppStoreConnectIdArgs = {
  input: UpdateAppStoreAccountByAppStoreConnectIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreAccountByAppleDeveloperAccountIdArgs = {
  input: UpdateAppStoreAccountByAppleDeveloperAccountIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreAccountByOrganizationNameArgs = {
  input: UpdateAppStoreAccountByOrganizationNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreListingByNodeIdArgs = {
  input: UpdateAppStoreListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreListingArgs = {
  input: UpdateAppStoreListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreListingByIosAppIdArgs = {
  input: UpdateAppStoreListingByIosAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreUserByNodeIdArgs = {
  input: UpdateAppStoreUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreUserArgs = {
  input: UpdateAppStoreUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppStoreUserByEmailArgs = {
  input: UpdateAppStoreUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuildByNodeIdArgs = {
  input: UpdateBuildByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuildArgs = {
  input: UpdateBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuildStepByNodeIdArgs = {
  input: UpdateBuildStepByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuildStepArgs = {
  input: UpdateBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayAccountByNodeIdArgs = {
  input: UpdateGooglePlayAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayAccountArgs = {
  input: UpdateGooglePlayAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayListingByNodeIdArgs = {
  input: UpdateGooglePlayListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayListingArgs = {
  input: UpdateGooglePlayListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayListingByAndroidAppIdArgs = {
  input: UpdateGooglePlayListingByAndroidAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayUserByNodeIdArgs = {
  input: UpdateGooglePlayUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayUserArgs = {
  input: UpdateGooglePlayUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGooglePlayUserByEmailArgs = {
  input: UpdateGooglePlayUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIosAppByNodeIdArgs = {
  input: UpdateIosAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIosAppArgs = {
  input: UpdateIosAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIosAppByAppIdArgs = {
  input: UpdateIosAppByAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIosScreenshotByNodeIdArgs = {
  input: UpdateIosScreenshotByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIosScreenshotArgs = {
  input: UpdateIosScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseByNodeIdArgs = {
  input: UpdateReleaseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReleaseArgs = {
  input: UpdateReleaseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppByNodeIdArgs = {
  input: DeleteAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppArgs = {
  input: DeleteAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppBySchoolIdArgs = {
  input: DeleteAppBySchoolIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarByNodeIdArgs = {
  input: DeleteCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarArgs = {
  input: DeleteCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarEventByNodeIdArgs = {
  input: DeleteCalendarEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarEventArgs = {
  input: DeleteCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarEventByEventIdAndCalendarIdArgs = {
  input: DeleteCalendarEventByEventIdAndCalendarIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarGroupByNodeIdArgs = {
  input: DeleteCalendarGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarGroupArgs = {
  input: DeleteCalendarGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarGroupCalendarByNodeIdArgs = {
  input: DeleteCalendarGroupCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarGroupCalendarArgs = {
  input: DeleteCalendarGroupCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCalendarGroupCalendarByCalendarIdAndCalendarGroupIdArgs = {
  input: DeleteCalendarGroupCalendarByCalendarIdAndCalendarGroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDivisionByNodeIdArgs = {
  input: DeleteDivisionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDivisionArgs = {
  input: DeleteDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByNodeIdArgs = {
  input: DeleteEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventBySlugArgs = {
  input: DeleteEventBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByGoogleIdArgs = {
  input: DeleteEventByGoogleIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByNodeIdArgs = {
  input: DeleteFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleAccountByNodeIdArgs = {
  input: DeleteGoogleAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleAccountArgs = {
  input: DeleteGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarSyncByNodeIdArgs = {
  input: DeleteGoogleCalendarSyncByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarSyncArgs = {
  input: DeleteGoogleCalendarSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarSyncCalendarByNodeIdArgs = {
  input: DeleteGoogleCalendarSyncCalendarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarSyncCalendarArgs = {
  input: DeleteGoogleCalendarSyncCalendarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupByNodeIdArgs = {
  input: DeleteGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupBySlugArgs = {
  input: DeleteGroupBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupPersonByNodeIdArgs = {
  input: DeleteGroupPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupPersonArgs = {
  input: DeleteGroupPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupPersonByGroupIdAndPersonIdArgs = {
  input: DeleteGroupPersonByGroupIdAndPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupCategoryByNodeIdArgs = {
  input: DeleteGroupCategoryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupCategoryArgs = {
  input: DeleteGroupCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupNewsletterByNodeIdArgs = {
  input: DeleteGroupNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupNewsletterArgs = {
  input: DeleteGroupNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupNewsletterByGroupIdAndNewsletterIdArgs = {
  input: DeleteGroupNewsletterByGroupIdAndNewsletterIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMarqueeByNodeIdArgs = {
  input: DeleteMarqueeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMarqueeArgs = {
  input: DeleteMarqueeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNewsletterByNodeIdArgs = {
  input: DeleteNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNewsletterArgs = {
  input: DeleteNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationByNodeIdArgs = {
  input: DeleteNotificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationBySlugArgs = {
  input: DeleteNotificationBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationFileByNodeIdArgs = {
  input: DeleteNotificationFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationFileArgs = {
  input: DeleteNotificationFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationFileByFileIdAndNotificationIdArgs = {
  input: DeleteNotificationFileByFileIdAndNotificationIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationNewsletterByNodeIdArgs = {
  input: DeleteNotificationNewsletterByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationNewsletterArgs = {
  input: DeleteNotificationNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationNewsletterByNotificationIdAndNewsletterIdArgs = {
  input: DeleteNotificationNewsletterByNotificationIdAndNewsletterIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationGroupByNodeIdArgs = {
  input: DeleteNotificationGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationGroupArgs = {
  input: DeleteNotificationGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationGroupByNotificationIdAndGroupIdArgs = {
  input: DeleteNotificationGroupByNotificationIdAndGroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageByNodeIdArgs = {
  input: DeletePageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageArgs = {
  input: DeletePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageSectionByNodeIdArgs = {
  input: DeletePageSectionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageSectionArgs = {
  input: DeletePageSectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageSectionFileByNodeIdArgs = {
  input: DeletePageSectionFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageSectionFileArgs = {
  input: DeletePageSectionFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePageSectionFileByFileIdAndPageSectionIdArgs = {
  input: DeletePageSectionFileByFileIdAndPageSectionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonByNodeIdArgs = {
  input: DeletePersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePersonArgs = {
  input: DeletePersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceByNodeIdArgs = {
  input: DeleteResourceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceBySlugArgs = {
  input: DeleteResourceBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceFileByNodeIdArgs = {
  input: DeleteResourceFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceFileArgs = {
  input: DeleteResourceFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceFileByFileIdAndResourceIdArgs = {
  input: DeleteResourceFileByFileIdAndResourceIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolByNodeIdArgs = {
  input: DeleteSchoolByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolArgs = {
  input: DeleteSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolBySlugArgs = {
  input: DeleteSchoolBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonByNodeIdArgs = {
  input: DeleteSchoolPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonArgs = {
  input: DeleteSchoolPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonByPersonIdAndSchoolIdArgs = {
  input: DeleteSchoolPersonByPersonIdAndSchoolIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonInstallationByNodeIdArgs = {
  input: DeleteSchoolPersonInstallationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonInstallationArgs = {
  input: DeleteSchoolPersonInstallationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonInvitationByNodeIdArgs = {
  input: DeleteSchoolPersonInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonInvitationArgs = {
  input: DeleteSchoolPersonInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolPersonInvitationBySlugArgs = {
  input: DeleteSchoolPersonInvitationBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolSettingByNodeIdArgs = {
  input: DeleteSchoolSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSchoolSettingArgs = {
  input: DeleteSchoolSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsorByNodeIdArgs = {
  input: DeleteSponsorByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsorArgs = {
  input: DeleteSponsorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSponsorBySlugArgs = {
  input: DeleteSponsorBySlugInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteThemeByNodeIdArgs = {
  input: DeleteThemeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteThemeArgs = {
  input: DeleteThemeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserGoogleAccountByNodeIdArgs = {
  input: DeleteUserGoogleAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserGoogleAccountArgs = {
  input: DeleteUserGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserGoogleAccountByUserIdAndGoogleAccountIdArgs = {
  input: DeleteUserGoogleAccountByUserIdAndGoogleAccountIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserSessionByNodeIdArgs = {
  input: DeleteUserSessionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserSessionArgs = {
  input: DeleteUserSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidAppByNodeIdArgs = {
  input: DeleteAndroidAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidAppArgs = {
  input: DeleteAndroidAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidAppByAppIdArgs = {
  input: DeleteAndroidAppByAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidJavaKeyStoreByNodeIdArgs = {
  input: DeleteAndroidJavaKeyStoreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidJavaKeyStoreArgs = {
  input: DeleteAndroidJavaKeyStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidScreenshotByNodeIdArgs = {
  input: DeleteAndroidScreenshotByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAndroidScreenshotArgs = {
  input: DeleteAndroidScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreAccountByNodeIdArgs = {
  input: DeleteAppStoreAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreAccountArgs = {
  input: DeleteAppStoreAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreAccountByAppStoreConnectIdArgs = {
  input: DeleteAppStoreAccountByAppStoreConnectIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreAccountByAppleDeveloperAccountIdArgs = {
  input: DeleteAppStoreAccountByAppleDeveloperAccountIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreAccountByOrganizationNameArgs = {
  input: DeleteAppStoreAccountByOrganizationNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreListingByNodeIdArgs = {
  input: DeleteAppStoreListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreListingArgs = {
  input: DeleteAppStoreListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreListingByIosAppIdArgs = {
  input: DeleteAppStoreListingByIosAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreUserByNodeIdArgs = {
  input: DeleteAppStoreUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreUserArgs = {
  input: DeleteAppStoreUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppStoreUserByEmailArgs = {
  input: DeleteAppStoreUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuildByNodeIdArgs = {
  input: DeleteBuildByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuildArgs = {
  input: DeleteBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuildStepByNodeIdArgs = {
  input: DeleteBuildStepByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuildStepArgs = {
  input: DeleteBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayAccountByNodeIdArgs = {
  input: DeleteGooglePlayAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayAccountArgs = {
  input: DeleteGooglePlayAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayListingByNodeIdArgs = {
  input: DeleteGooglePlayListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayListingArgs = {
  input: DeleteGooglePlayListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayListingByAndroidAppIdArgs = {
  input: DeleteGooglePlayListingByAndroidAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayUserByNodeIdArgs = {
  input: DeleteGooglePlayUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayUserArgs = {
  input: DeleteGooglePlayUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGooglePlayUserByEmailArgs = {
  input: DeleteGooglePlayUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIosAppByNodeIdArgs = {
  input: DeleteIosAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIosAppArgs = {
  input: DeleteIosAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIosAppByAppIdArgs = {
  input: DeleteIosAppByAppIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIosScreenshotByNodeIdArgs = {
  input: DeleteIosScreenshotByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIosScreenshotArgs = {
  input: DeleteIosScreenshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReleaseByNodeIdArgs = {
  input: DeleteReleaseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReleaseArgs = {
  input: DeleteReleaseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelScheduleSettingsResetForSchoolUserTypeArgs = {
  input: CancelScheduleSettingsResetForSchoolUserTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClaimAnonymousAccountArgs = {
  input: ClaimAnonymousAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleCalendarSyncWithCalendarsArgs = {
  input: CreateGoogleCalendarSyncWithCalendarsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrUpdateCalendarGroupArgs = {
  input: CreateOrUpdateCalendarGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrUpdateEventForCalendarsArgs = {
  input: CreateOrUpdateEventForCalendarsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrUpdateGoogleAccountArgs = {
  input: CreateOrUpdateGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrUpdateNotificationArgs = {
  input: CreateOrUpdateNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileIfPossibleArgs = {
  input: DeleteFileIfPossibleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGcsArgs = {
  input: DeleteGcsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateNewsletterArgs = {
  input: DuplicateNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLegacyMigrationAddLegacySubscriptionsForPersonArgs = {
  input: LegacyMigrationAddLegacySubscriptionsForPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLegacyMigrationUpdatePersonGroupMembershipForSchoolArgs = {
  input: LegacyMigrationUpdatePersonGroupMembershipForSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMakeDivisionSchoolwideArgs = {
  input: MakeDivisionSchoolwideInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrateLegacyInstallationsSubscriptionsArgs = {
  input: MigrateLegacyInstallationsSubscriptionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrateLegacyOneSignalDevicesArgs = {
  input: MigrateLegacyOneSignalDevicesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrateLegacySubscriptionSelectionsForUpdatedUsersArgs = {
  input: MigrateLegacySubscriptionSelectionsForUpdatedUsersInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProcessGoogleCalendarSyncArgs = {
  input: ProcessGoogleCalendarSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishNewsletterArgs = {
  input: PublishNewsletterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterAnonymousUserArgs = {
  input: RegisterAnonymousUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFacultyStaffPersonFromSchoolArgs = {
  input: RemoveFacultyStaffPersonFromSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveGoogleAccountArgs = {
  input: RemoveGoogleAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetGoogleCalendarSyncArgs = {
  input: ResetGoogleCalendarSyncInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSchoolPersonInvitationSendArgs = {
  input: SchoolPersonInvitationSendInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSchoolGroupIdsArgs = {
  input: SchoolGroupIdsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetSchoolAdminGroupsForPersonArgs = {
  input: SetSchoolAdminGroupsForPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStopGoogleWatchChannelArgs = {
  input: StopGoogleWatchChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitCurrentPersonSchoolOnboardingArgs = {
  input: SubmitCurrentPersonSchoolOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCurrentUserSessionDataArgs = {
  input: UpdateCurrentUserSessionDataInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNewsletterRecipientsArgs = {
  input: UpdateNewsletterRecipientsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePageSectionSortOrderArgs = {
  input: UpdatePageSectionSortOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonGroupMembershipForSchoolArgs = {
  input: UpdatePersonGroupMembershipForSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceSortOrderArgs = {
  input: UpdateResourceSortOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSponsorSortOrderArgs = {
  input: UpdateSponsorSortOrderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddJks64ToJavaKeyStoreArgs = {
  input: AddJks64ToJavaKeyStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationArchiveAndroidJavaKeyStoreAndCreateNewArgs = {
  input: ArchiveAndroidJavaKeyStoreAndCreateNewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeAndroidAppBundleIdArgs = {
  input: ChangeAndroidAppBundleIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDeploymentRowsForSchoolArgs = {
  input: CreateDeploymentRowsForSchoolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewAndroidJavaKeyStoreArgs = {
  input: CreateNewAndroidJavaKeyStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewAppStoreUserArgs = {
  input: CreateNewAppStoreUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewBuildForAppOnPlatformArgs = {
  input: CreateNewBuildForAppOnPlatformInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewGooglePlayUserArgs = {
  input: CreateNewGooglePlayUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNewReleaseForPlatformArgs = {
  input: CreateNewReleaseForPlatformInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFailBuildStepArgs = {
  input: FailBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetThenSetAppVersionForBuildArgs = {
  input: GetThenSetAppVersionForBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationKillBuildStepAndBuildArgs = {
  input: KillBuildStepAndBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMakeStartingBuildStepForBuildArgs = {
  input: MakeStartingBuildStepForBuildInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkAsNeverNukeArgs = {
  input: MarkAsNeverNukeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkDistCertsAsNukedArgs = {
  input: MarkDistCertsAsNukedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartBuildStepArgs = {
  input: StartBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSucceedBuildStepArgs = {
  input: SucceedBuildStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDistCertExpDateArgs = {
  input: UpdateDistCertExpDateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExpirationDateOnPushCertArgs = {
  input: UpdateExpirationDateOnPushCertInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateJksWithJks64FromAppArgs = {
  input: UpdateJksWithJks64FromAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlayStoreListingWithCreatedOrUpdatedAccountArgs = {
  input: UpdatePlayStoreListingWithCreatedOrUpdatedAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPresignedUploadUrlArgs = {
  input: PresignedUploadUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTemporaryAwsS3UploadArgs = {
  input: DeleteTemporaryAwsS3UploadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationScheduleSettingsResetArgs = {
  input: ScheduleSettingsResetInput;
};

/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type Newsletter = Node & {
  __typename?: 'Newsletter';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId: Scalars['UUID'];
  status: NewsletterStatus;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes: Array<Maybe<UserType>>;
  slug: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
  /** Fetches the ID of the school this newsletter is in. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupNewsletterNewsletterIdAndGroupId: NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterNewsletterIdAndCreatedBy: NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterNewsletterIdAndUpdatedBy: NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationNewsletterNewsletterIdAndNotificationId: NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterNewsletterIdAndCreatedBy: NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterNewsletterIdAndUpdatedBy: NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyConnection;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Newsletters - just the basic meta data for a Newsletter. All content should go inside the Newsletter's Page. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `Newsletter` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type NewsletterCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pageId` field. */
  pageId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<NewsletterStatus>;
  /** Checks for equality with the object’s `publishDate` field. */
  publishDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userTypes` field. */
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>;
};

/** The `file` to be created by this mutation. */
export type NewsletterCoverImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `NewsletterInput` mutation. */
export type NewsletterCoverImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnNewsletterForNewsletterCoverImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<NewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<NewsletterCoverImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `newsletter` in the `FileInput` mutation. */
export type NewsletterCoverImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `newsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NewsletterNewsletterPkeyConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NewsletterNodeIdConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NewsletterNewsletterPkeyDelete>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyUsingNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnNewsletterForNewsletterCoverImageFileIdFkeyNodeIdUpdate>>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NewsletterCoverImageFileIdFkeyNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type NewsletterCoverImageFileIdFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** Input for the nested mutation of `newsletter` in the `PersonInput` mutation. */
export type NewsletterCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `newsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NewsletterNewsletterPkeyConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NewsletterNodeIdConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NewsletterNewsletterPkeyDelete>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NewsletterOnNewsletterForNewsletterCreatedByFkeyUsingNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNewsletterForNewsletterCreatedByFkeyNodeIdUpdate>>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NewsletterCreatedByFkeyNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type NewsletterCreatedByFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** A filter to be used against `Newsletter` object types. All fields are combined with a logical ‘and.’ */
export type NewsletterFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `pageId` field. */
  pageId?: Maybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<NewsletterStatusFilter>;
  /** Filter by the object’s `publishDate` field. */
  publishDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userTypes` field. */
  userTypes?: Maybe<UserTypeListFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: Maybe<StringFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NewsletterFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NewsletterFilter>>;
  /** Negates the expression. */
  not?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Group` values, with data from `GroupNewsletter`. */
export type NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyConnection = {
  __typename?: 'NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupNewsletter`. */
export type NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyEdge = {
  __typename?: 'NewsletterGroupsByGroupNewsletterNewsletterIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `Newsletter` */
export type NewsletterInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** The fields on `newsletter` to look up the row to connect. */
export type NewsletterNewsletterPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `newsletter` to look up the row to delete. */
export type NewsletterNewsletterPkeyDelete = {
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type NewsletterNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type NewsletterNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `newsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `Notification` values, with data from `NotificationNewsletter`. */
export type NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyConnection = {
  __typename?: 'NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationNewsletter`. */
export type NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyEdge = {
  __typename?: 'NewsletterNotificationsByNotificationNewsletterNewsletterIdAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to update. */
export type NewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnNewsletterForNewsletterCreatedByFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnNewsletterForNewsletterCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NewsletterOnNewsletterForNewsletterPageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `page` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: PagePatch;
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnNewsletterForNewsletterPageIdFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnNewsletterForNewsletterPageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NewsletterOnNewsletterForNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnNewsletterForNewsletterUpdatedByFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnNewsletterForNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
};

/** The fields on `newsletter` to look up the row to update. */
export type NewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: UpdateNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `page` in the `NewsletterInput` mutation. */
export type NewsletterPageIdFkeyInput = {
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectById?: Maybe<PagePagePkeyConnect>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectByNodeId?: Maybe<PageNodeIdConnect>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteById?: Maybe<PagePagePkeyDelete>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PageNodeIdDelete>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateById?: Maybe<PageOnNewsletterForNewsletterPageIdFkeyUsingPagePkeyUpdate>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateByNodeId?: Maybe<NewsletterOnNewsletterForNewsletterPageIdFkeyNodeIdUpdate>;
  /** A `PageInput` object that will be created and connected to this object. */
  create?: Maybe<NewsletterPageIdFkeyPageCreateInput>;
};

/** Input for the nested mutation of `newsletter` in the `PageInput` mutation. */
export type NewsletterPageIdFkeyInverseInput = {
  /** Flag indicating whether all other `newsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NewsletterNewsletterPkeyConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NewsletterNodeIdConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NewsletterNewsletterPkeyDelete>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NewsletterOnNewsletterForNewsletterPageIdFkeyUsingNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PageOnNewsletterForNewsletterPageIdFkeyNodeIdUpdate>>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NewsletterPageIdFkeyNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type NewsletterPageIdFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** The `page` to be created by this mutation. */
export type NewsletterPageIdFkeyPageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** Represents an update to a `Newsletter`. Fields that are set will be updated. */
export type NewsletterPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyConnection = {
  __typename?: 'NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyEdge = {
  __typename?: 'NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByCreatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndCreatedByManyToManyEdgeGroupNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyConnection = {
  __typename?: 'NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyEdge = {
  __typename?: 'NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByUpdatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type NewsletterPeopleByGroupNewsletterNewsletterIdAndUpdatedByManyToManyEdgeGroupNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyConnection = {
  __typename?: 'NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyEdge = {
  __typename?: 'NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByCreatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndCreatedByManyToManyEdgeNotificationNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyConnection = {
  __typename?: 'NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyEdge = {
  __typename?: 'NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByUpdatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NewsletterPeopleByNotificationNewsletterNewsletterIdAndUpdatedByManyToManyEdgeNotificationNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

export enum NewsletterStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

/** A filter to be used against NewsletterStatus fields. All fields are combined with a logical ‘and.’ */
export type NewsletterStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<NewsletterStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<NewsletterStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<NewsletterStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<NewsletterStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<NewsletterStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<NewsletterStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<NewsletterStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<NewsletterStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<NewsletterStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<NewsletterStatus>;
};

/** Input for the nested mutation of `person` in the `NewsletterInput` mutation. */
export type NewsletterUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnNewsletterForNewsletterUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<NewsletterOnNewsletterForNewsletterUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<NewsletterUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `newsletter` in the `PersonInput` mutation. */
export type NewsletterUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `newsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NewsletterNewsletterPkeyConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NewsletterNodeIdConnect>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NewsletterNewsletterPkeyDelete>>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NewsletterOnNewsletterForNewsletterUpdatedByFkeyUsingNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNewsletterForNewsletterUpdatedByFkeyNodeIdUpdate>>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NewsletterUpdatedByFkeyNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type NewsletterUpdatedByFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** The `person` to be created by this mutation. */
export type NewsletterUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Newsletter` values. */
export type NewslettersConnection = {
  __typename?: 'NewslettersConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter` and cursor to aid in pagination. */
  edges: Array<NewslettersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection. */
export type NewslettersEdge = {
  __typename?: 'NewslettersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
};

/** Methods to use when ordering `Newsletter`. */
export enum NewslettersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PageIdAsc = 'PAGE_ID_ASC',
  PageIdDesc = 'PAGE_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  PublishDateAsc = 'PUBLISH_DATE_ASC',
  PublishDateDesc = 'PUBLISH_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CoverImageFileIdAsc = 'COVER_IMAGE_FILE_ID_ASC',
  CoverImageFileIdDesc = 'COVER_IMAGE_FILE_ID_DESC',
  UserTypesAsc = 'USER_TYPES_ASC',
  UserTypesDesc = 'USER_TYPES_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type Notification = Node & {
  __typename?: 'Notification';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft: Scalars['Boolean'];
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery: NotificationDelivery;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes: Array<Maybe<UserType>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<NotificationLogs>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<Buttons>;
  embedUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Notification` that is related to this `Notification`. */
  notificationByRecurringParent?: Maybe<Notification>;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByRecurringParent: NotificationsConnection;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
  /** Finds total count of users the notification would be sent to if sent now. */
  targetRecipientCount?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationRecurringParentAndCreatedBy: NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationRecurringParentAndUpdatedBy: NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationFileNotificationIdAndFileId: NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileNotificationIdAndCreatedBy: NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileNotificationIdAndUpdatedBy: NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByNotificationNewsletterNotificationIdAndNewsletterId: NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterNotificationIdAndCreatedBy: NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterNotificationIdAndUpdatedBy: NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByNotificationGroupNotificationIdAndGroupId: NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupNotificationIdAndCreatedBy: NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupNotificationIdAndUpdatedBy: NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyConnection;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationNotificationsByRecurringParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationRecurringParentAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationRecurringParentAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationFilesByNotificationFileNotificationIdAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationFileNotificationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationFileNotificationIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationGroupsByNotificationGroupNotificationIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationGroupNotificationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** A Notification message and detail sent to any number of Group(s) and filtered by User Type(s). */
export type NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `Notification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `draft` field. */
  draft?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `message` field. */
  message?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `delivery` field. */
  delivery?: Maybe<NotificationDelivery>;
  /** Checks for equality with the object’s `sendDate` field. */
  sendDate?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `recurringCron` field. */
  recurringCron?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `recurringEnabled` field. */
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `nextOccurrence` field. */
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userTypes` field. */
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  /** Checks for equality with the object’s `recipientCount` field. */
  recipientCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `logs` field. */
  logs?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `nextOccurrenceSendJob` field. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `recurringParent` field. */
  recurringParent?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `embedUrl` field. */
  embedUrl?: Maybe<Scalars['String']>;
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['UUID']>;
  delivery?: Maybe<NotificationDelivery>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  recurringCron?: Maybe<Scalars['String']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  groupIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  isSchoolwide?: Maybe<Scalars['Boolean']>;
  schoolPersonIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  oneSignalAppId?: Maybe<Scalars['String']>;
  oneSignalAppKey?: Maybe<Scalars['String']>;
  recipientCount?: Maybe<Scalars['Int']>;
  schoolPushEnabled?: Maybe<Scalars['Boolean']>;
  buttons?: Maybe<Scalars['JSON']>;
  fileIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  embedUrl?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `notification` in the `PersonInput` mutation. */
export type NotificationCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNotificationPkeyConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<NotificationNotificationSlugKeyConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNodeIdConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNotificationPkeyDelete>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<NotificationNotificationSlugKeyDelete>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNodeIdDelete>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationOnNotificationForNotificationCreatedByFkeyUsingNotificationPkeyUpdate>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<NotificationOnNotificationForNotificationCreatedByFkeyUsingNotificationSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationForNotificationCreatedByFkeyNodeIdUpdate>>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationCreatedByFkeyNotificationCreateInput>>;
};

/** The `notification` to be created by this mutation. */
export type NotificationCreatedByFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

export enum NotificationDelivery {
  Now = 'NOW',
  Later = 'LATER',
  Recurring = 'RECURRING',
  Sent = 'SENT'
}

/** A filter to be used against NotificationDelivery fields. All fields are combined with a logical ‘and.’ */
export type NotificationDeliveryFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<NotificationDelivery>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<NotificationDelivery>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<NotificationDelivery>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<NotificationDelivery>;
  /** Included in the specified list. */
  in?: Maybe<Array<NotificationDelivery>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<NotificationDelivery>>;
  /** Less than the specified value. */
  lessThan?: Maybe<NotificationDelivery>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<NotificationDelivery>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<NotificationDelivery>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<NotificationDelivery>;
};

/** `File`s for a `Notification`. */
export type NotificationFile = Node & {
  __typename?: 'NotificationFile';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  notificationId: Scalars['UUID'];
  fileId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  /** Reads a single `Notification` that is related to this `NotificationFile`. */
  notification?: Maybe<Notification>;
  /** Reads a single `File` that is related to this `NotificationFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `NotificationFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationFileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notificationId` field. */
  notificationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input for the nested mutation of `notificationFile` in the `PersonInput` mutation. */
export type NotificationFileCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationFileNotificationFilePkeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationFileNodeIdConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationFileNotificationFilePkeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileCreatedByFkeyUsingNotificationFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByFileIdAndNotificationId?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileCreatedByFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationFileForNotificationFileCreatedByFkeyNodeIdUpdate>>;
  /** A `NotificationFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationFileCreatedByFkeyNotificationFileCreateInput>>;
};

/** The `notificationFile` to be created by this mutation. */
export type NotificationFileCreatedByFkeyNotificationFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type NotificationFileFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `NotificationFileInput` mutation. */
export type NotificationFileFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnNotificationFileForNotificationFileFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationFileOnNotificationFileForNotificationFileFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationFileFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `notificationFile` in the `FileInput` mutation. */
export type NotificationFileFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationFileNotificationFilePkeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationFileNodeIdConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationFileNotificationFilePkeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileFileIdFkeyUsingNotificationFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByFileIdAndNotificationId?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileFileIdFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnNotificationFileForNotificationFileFileIdFkeyNodeIdUpdate>>;
  /** A `NotificationFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationFileFileIdFkeyNotificationFileCreateInput>>;
};

/** The `notificationFile` to be created by this mutation. */
export type NotificationFileFileIdFkeyNotificationFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** A filter to be used against `NotificationFile` object types. All fields are combined with a logical ‘and.’ */
export type NotificationFileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `notificationId` field. */
  notificationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NotificationFileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NotificationFileFilter>>;
  /** Negates the expression. */
  not?: Maybe<NotificationFileFilter>;
};

/** An input for mutations affecting `NotificationFile` */
export type NotificationFileInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type NotificationFileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type NotificationFileNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `notificationFile` to look up the row to connect. */
export type NotificationFileNotificationFileFileIdNotificationIdKeyConnect = {
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to delete. */
export type NotificationFileNotificationFileFileIdNotificationIdKeyDelete = {
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to connect. */
export type NotificationFileNotificationFilePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to delete. */
export type NotificationFileNotificationFilePkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `notification` in the `NotificationFileInput` mutation. */
export type NotificationFileNotificationIdFkeyInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<NotificationNotificationSlugKeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<NotificationNodeIdConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<NotificationNotificationPkeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<NotificationNotificationSlugKeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NotificationNodeIdDelete>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<NotificationOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationPkeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<NotificationOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationSlugKeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyNodeIdUpdate>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationFileNotificationIdFkeyNotificationCreateInput>;
};

/** Input for the nested mutation of `notificationFile` in the `NotificationInput` mutation. */
export type NotificationFileNotificationIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationFileNotificationFilePkeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationFileNodeIdConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationFileNotificationFilePkeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByFileIdAndNotificationId?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NotificationOnNotificationFileForNotificationFileNotificationIdFkeyNodeIdUpdate>>;
  /** A `NotificationFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationFileNotificationIdFkeyNotificationFileCreateInput>>;
};

/** The `notification` to be created by this mutation. */
export type NotificationFileNotificationIdFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** The `notificationFile` to be created by this mutation. */
export type NotificationFileNotificationIdFkeyNotificationFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileCreatedByFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileCreatedByFkeyPatch;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileCreatedByFkeyUsingNotificationFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileFileIdFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileFileIdFkeyPatch;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileFileIdFkeyUsingNotificationFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyPatch;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyPatch;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** The fields on `notificationFile` to look up the row to update. */
export type NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyUsingNotificationFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: UpdateNotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `NotificationFile`. Fields that are set will be updated. */
export type NotificationFilePatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `NotificationFileInput` mutation. */
export type NotificationFileUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnNotificationFileForNotificationFileUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationFileUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `notificationFile` in the `PersonInput` mutation. */
export type NotificationFileUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationFileNotificationFilePkeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationFileNodeIdConnect>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationFileNotificationFilePkeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByFileIdAndNotificationId?: Maybe<Array<NotificationFileNotificationFileFileIdNotificationIdKeyDelete>>;
  /** The primary key(s) for `notificationFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyUsingNotificationFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByFileIdAndNotificationId?: Maybe<Array<NotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyUsingNotificationFileFileIdNotificationIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationFileForNotificationFileUpdatedByFkeyNodeIdUpdate>>;
  /** A `NotificationFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationFileUpdatedByFkeyNotificationFileCreateInput>>;
};

/** The `notificationFile` to be created by this mutation. */
export type NotificationFileUpdatedByFkeyNotificationFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  sortOrder: Scalars['Int'];
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type NotificationFileUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `File` values, with data from `NotificationFile`. */
export type NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyConnection = {
  __typename?: 'NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `NotificationFile`. */
export type NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyEdge = {
  __typename?: 'NotificationFilesByNotificationFileNotificationIdAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
};

/** A connection to a list of `NotificationFile` values. */
export type NotificationFilesConnection = {
  __typename?: 'NotificationFilesConnection';
  /** A list of `NotificationFile` objects. */
  nodes: Array<NotificationFile>;
  /** A list of edges which contains the `NotificationFile` and cursor to aid in pagination. */
  edges: Array<NotificationFilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationFile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NotificationFile` edge in the connection. */
export type NotificationFilesEdge = {
  __typename?: 'NotificationFilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NotificationFile` at the end of the edge. */
  node: NotificationFile;
};

/** Methods to use when ordering `NotificationFile`. */
export enum NotificationFilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NotificationIdAsc = 'NOTIFICATION_ID_ASC',
  NotificationIdDesc = 'NOTIFICATION_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `Notification` object types. All fields are combined with a logical ‘and.’ */
export type NotificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `draft` field. */
  draft?: Maybe<BooleanFilter>;
  /** Filter by the object’s `message` field. */
  message?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `delivery` field. */
  delivery?: Maybe<NotificationDeliveryFilter>;
  /** Filter by the object’s `sendDate` field. */
  sendDate?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `recurringCron` field. */
  recurringCron?: Maybe<StringFilter>;
  /** Filter by the object’s `recurringEnabled` field. */
  recurringEnabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `nextOccurrence` field. */
  nextOccurrence?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userTypes` field. */
  userTypes?: Maybe<UserTypeListFilter>;
  /** Filter by the object’s `recipientCount` field. */
  recipientCount?: Maybe<IntFilter>;
  /** Filter by the object’s `logs` field. */
  logs?: Maybe<JsonFilter>;
  /** Filter by the object’s `nextOccurrenceSendJob` field. */
  nextOccurrenceSendJob?: Maybe<BigIntFilter>;
  /** Filter by the object’s `recurringParent` field. */
  recurringParent?: Maybe<UuidFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `embedUrl` field. */
  embedUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `targetRecipientCount` field. */
  targetRecipientCount?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NotificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NotificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<NotificationFilter>;
};

/** Join object to connect `Notification`s to `Group`s. */
export type NotificationGroup = Node & {
  __typename?: 'NotificationGroup';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Notification` that is related to this `NotificationGroup`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Group` that is related to this `NotificationGroup`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `NotificationGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notificationId` field. */
  notificationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `notificationGroup` in the `PersonInput` mutation. */
export type NotificationGroupCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationGroupNotificationGroupPkeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationGroupNodeIdConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationGroupNotificationGroupPkeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyUsingNotificationGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationGroupForNotificationGroupCreatedByFkeyNodeIdUpdate>>;
  /** A `NotificationGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationGroupCreatedByFkeyNotificationGroupCreateInput>>;
};

/** The `notificationGroup` to be created by this mutation. */
export type NotificationGroupCreatedByFkeyNotificationGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** A filter to be used against `NotificationGroup` object types. All fields are combined with a logical ‘and.’ */
export type NotificationGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `notificationId` field. */
  notificationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NotificationGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NotificationGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<NotificationGroupFilter>;
};

/** The `group` to be created by this mutation. */
export type NotificationGroupGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `NotificationGroupInput` mutation. */
export type NotificationGroupGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationGroupGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `notificationGroup` in the `GroupInput` mutation. */
export type NotificationGroupGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationGroupNotificationGroupPkeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationGroupNodeIdConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationGroupNotificationGroupPkeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingNotificationGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnNotificationGroupForNotificationGroupGroupIdFkeyNodeIdUpdate>>;
  /** A `NotificationGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationGroupGroupIdFkeyNotificationGroupCreateInput>>;
};

/** The `notificationGroup` to be created by this mutation. */
export type NotificationGroupGroupIdFkeyNotificationGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** An input for mutations affecting `NotificationGroup` */
export type NotificationGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type NotificationGroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type NotificationGroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `notificationGroup` to look up the row to connect. */
export type NotificationGroupNotificationGroupNotificationIdGroupIdKeyConnect = {
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to delete. */
export type NotificationGroupNotificationGroupNotificationIdGroupIdKeyDelete = {
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to connect. */
export type NotificationGroupNotificationGroupPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to delete. */
export type NotificationGroupNotificationGroupPkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `notification` in the `NotificationGroupInput` mutation. */
export type NotificationGroupNotificationIdFkeyInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<NotificationNotificationSlugKeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<NotificationNodeIdConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<NotificationNotificationPkeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<NotificationNotificationSlugKeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NotificationNodeIdDelete>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationPkeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationSlugKeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyNodeIdUpdate>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationGroupNotificationIdFkeyNotificationCreateInput>;
};

/** Input for the nested mutation of `notificationGroup` in the `NotificationInput` mutation. */
export type NotificationGroupNotificationIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationGroupNotificationGroupPkeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationGroupNodeIdConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationGroupNotificationGroupPkeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyNodeIdUpdate>>;
  /** A `NotificationGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationGroupNotificationIdFkeyNotificationGroupCreateInput>>;
};

/** The `notification` to be created by this mutation. */
export type NotificationGroupNotificationIdFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** The `notificationGroup` to be created by this mutation. */
export type NotificationGroupNotificationIdFkeyNotificationGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyPatch;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyUsingNotificationGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyUsingNotificationGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyPatch;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** The fields on `notificationGroup` to look up the row to update. */
export type NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingNotificationGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: UpdateNotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `NotificationGroup`. Fields that are set will be updated. */
export type NotificationGroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `NotificationGroupInput` mutation. */
export type NotificationGroupUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationGroupUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `notificationGroup` in the `PersonInput` mutation. */
export type NotificationGroupUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationGroupNotificationGroupPkeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationGroupNodeIdConnect>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationGroupNotificationGroupPkeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupNotificationGroupNotificationIdGroupIdKeyDelete>>;
  /** The primary key(s) for `notificationGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingNotificationGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNotificationIdAndGroupId?: Maybe<Array<NotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingNotificationGroupNotificationIdGroupIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationGroupForNotificationGroupUpdatedByFkeyNodeIdUpdate>>;
  /** A `NotificationGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationGroupUpdatedByFkeyNotificationGroupCreateInput>>;
};

/** The `notificationGroup` to be created by this mutation. */
export type NotificationGroupUpdatedByFkeyNotificationGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type NotificationGroupUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Group` values, with data from `NotificationGroup`. */
export type NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyConnection = {
  __typename?: 'NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `NotificationGroup`. */
export type NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyEdge = {
  __typename?: 'NotificationGroupsByNotificationGroupNotificationIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `NotificationGroup` values. */
export type NotificationGroupsConnection = {
  __typename?: 'NotificationGroupsConnection';
  /** A list of `NotificationGroup` objects. */
  nodes: Array<NotificationGroup>;
  /** A list of edges which contains the `NotificationGroup` and cursor to aid in pagination. */
  edges: Array<NotificationGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NotificationGroup` edge in the connection. */
export type NotificationGroupsEdge = {
  __typename?: 'NotificationGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NotificationGroup` at the end of the edge. */
  node: NotificationGroup;
};

/** Methods to use when ordering `NotificationGroup`. */
export enum NotificationGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NotificationIdAsc = 'NOTIFICATION_ID_ASC',
  NotificationIdDesc = 'NOTIFICATION_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Notification` */
export type NotificationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

export type NotificationLog = {
  __typename?: 'NotificationLog';
  timestamp: Scalars['Datetime'];
  error?: Maybe<Scalars['Boolean']>;
  info?: Maybe<Scalars['String']>;
};

export type NotificationLogs = {
  __typename?: 'NotificationLogs';
  logs: Array<NotificationLog>;
};

/** `Newsletters on a Notification`. */
export type NotificationNewsletter = Node & {
  __typename?: 'NotificationNewsletter';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Notification` that is related to this `NotificationNewsletter`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Newsletter` that is related to this `NotificationNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `NotificationNewsletter` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type NotificationNewsletterCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `notificationId` field. */
  notificationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `newsletterId` field. */
  newsletterId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `notificationNewsletter` in the `PersonInput` mutation. */
export type NotificationNewsletterCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNewsletterNodeIdConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyUsingNotificationNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyNodeIdUpdate>>;
  /** A `NotificationNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationNewsletterCreatedByFkeyNotificationNewsletterCreateInput>>;
};

/** The `notificationNewsletter` to be created by this mutation. */
export type NotificationNewsletterCreatedByFkeyNotificationNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** A filter to be used against `NotificationNewsletter` object types. All fields are combined with a logical ‘and.’ */
export type NotificationNewsletterFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `notificationId` field. */
  notificationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `newsletterId` field. */
  newsletterId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<NotificationNewsletterFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<NotificationNewsletterFilter>>;
  /** Negates the expression. */
  not?: Maybe<NotificationNewsletterFilter>;
};

/** An input for mutations affecting `NotificationNewsletter` */
export type NotificationNewsletterInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `newsletter` in the `NotificationNewsletterInput` mutation. */
export type NotificationNewsletterNewsletterIdFkeyInput = {
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectById?: Maybe<NewsletterNewsletterPkeyConnect>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<NewsletterNodeIdConnect>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteById?: Maybe<NewsletterNewsletterPkeyDelete>;
  /** The primary key(s) for `newsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NewsletterNodeIdDelete>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateById?: Maybe<NewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNewsletterPkeyUpdate>;
  /** The primary key(s) and patch data for `newsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyNodeIdUpdate>;
  /** A `NewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationNewsletterNewsletterIdFkeyNewsletterCreateInput>;
};

/** Input for the nested mutation of `notificationNewsletter` in the `NewsletterInput` mutation. */
export type NotificationNewsletterNewsletterIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNewsletterNodeIdConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNotificationNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyNodeIdUpdate>>;
  /** A `NotificationNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationNewsletterNewsletterIdFkeyNotificationNewsletterCreateInput>>;
};

/** The `newsletter` to be created by this mutation. */
export type NotificationNewsletterNewsletterIdFkeyNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** The `notificationNewsletter` to be created by this mutation. */
export type NotificationNewsletterNewsletterIdFkeyNotificationNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type NotificationNewsletterNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type NotificationNewsletterNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be deleted. */
  nodeId: Scalars['ID'];
};

/** Input for the nested mutation of `notification` in the `NotificationNewsletterInput` mutation. */
export type NotificationNewsletterNotificationIdFkeyInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<NotificationNotificationSlugKeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<NotificationNodeIdConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<NotificationNotificationPkeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<NotificationNotificationSlugKeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NotificationNodeIdDelete>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationPkeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationSlugKeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyNodeIdUpdate>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationNewsletterNotificationIdFkeyNotificationCreateInput>;
};

/** Input for the nested mutation of `notificationNewsletter` in the `NotificationInput` mutation. */
export type NotificationNewsletterNotificationIdFkeyInverseInput = {
  /** Flag indicating whether all other `notificationNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNewsletterNodeIdConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyNodeIdUpdate>>;
  /** A `NotificationNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationNewsletterNotificationIdFkeyNotificationNewsletterCreateInput>>;
};

/** The `notification` to be created by this mutation. */
export type NotificationNewsletterNotificationIdFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** The `notificationNewsletter` to be created by this mutation. */
export type NotificationNewsletterNotificationIdFkeyNotificationNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** The fields on `notificationNewsletter` to look up the row to connect. */
export type NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyConnect = {
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to delete. */
export type NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyDelete = {
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to connect. */
export type NotificationNewsletterNotificationNewsletterPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to delete. */
export type NotificationNewsletterNotificationNewsletterPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyPatch;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyUsingNotificationNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyPatch;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyUsingNotificationNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyPatch;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** The fields on `notificationNewsletter` to look up the row to update. */
export type NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingNotificationNewsletterPkeyUpdate = {
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `NotificationNewsletter`. Fields that are set will be updated. */
export type NotificationNewsletterPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `NotificationNewsletterInput` mutation. */
export type NotificationNewsletterUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationNewsletterUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `notificationNewsletter` in the `PersonInput` mutation. */
export type NotificationNewsletterUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notificationNewsletter` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNewsletterNodeIdConnect>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNewsletterNotificationNewsletterPkeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterNotificationNewsletterNotificationIdNewsletterIdKeyDelete>>;
  /** The primary key(s) for `notificationNewsletter` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNewsletterNodeIdDelete>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingNotificationNewsletterPkeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNotificationIdAndNewsletterId?: Maybe<Array<NotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingNotificationNewsletterNotificationIdNewsletterIdKeyUpdate>>;
  /** The primary key(s) and patch data for `notificationNewsletter` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyNodeIdUpdate>>;
  /** A `NotificationNewsletterInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationNewsletterUpdatedByFkeyNotificationNewsletterCreateInput>>;
};

/** The `notificationNewsletter` to be created by this mutation. */
export type NotificationNewsletterUpdatedByFkeyNotificationNewsletterCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type NotificationNewsletterUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Newsletter` values, with data from `NotificationNewsletter`. */
export type NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyConnection = {
  __typename?: 'NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `NotificationNewsletter`. */
export type NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyEdge = {
  __typename?: 'NotificationNewslettersByNotificationNewsletterNotificationIdAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `NotificationNewsletter` values. */
export type NotificationNewslettersConnection = {
  __typename?: 'NotificationNewslettersConnection';
  /** A list of `NotificationNewsletter` objects. */
  nodes: Array<NotificationNewsletter>;
  /** A list of edges which contains the `NotificationNewsletter` and cursor to aid in pagination. */
  edges: Array<NotificationNewslettersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationNewsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NotificationNewsletter` edge in the connection. */
export type NotificationNewslettersEdge = {
  __typename?: 'NotificationNewslettersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NotificationNewsletter` at the end of the edge. */
  node: NotificationNewsletter;
};

/** Methods to use when ordering `NotificationNewsletter`. */
export enum NotificationNewslettersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NotificationIdAsc = 'NOTIFICATION_ID_ASC',
  NotificationIdDesc = 'NOTIFICATION_ID_DESC',
  NewsletterIdAsc = 'NEWSLETTER_ID_ASC',
  NewsletterIdDesc = 'NEWSLETTER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type NotificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type NotificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `notification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `notification` to look up the row to connect. */
export type NotificationNotificationPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to delete. */
export type NotificationNotificationPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to connect. */
export type NotificationNotificationSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `notification` to look up the row to delete. */
export type NotificationNotificationSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationOnNotificationFileForNotificationFileNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: NotificationFilePatch;
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationFileForNotificationFileNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationFileForNotificationFileNotificationIdFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationFileForNotificationFileNotificationIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationCreatedByFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationCreatedByFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationOnNotificationForNotificationRecurringParentFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationRecurringParentFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationRecurringParentFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationRecurringParentFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationRecurringParentFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationOnNotificationForNotificationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationUpdatedByFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationUpdatedByFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationForNotificationUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: NotificationGroupPatch;
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyUsingNotificationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: UpdateNotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Represents an update to a `Notification`. Fields that are set will be updated. */
export type NotificationPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByCreatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndCreatedByManyToManyEdgeNotificationFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByUpdatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type NotificationPeopleByNotificationFileNotificationIdAndUpdatedByManyToManyEdgeNotificationFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByCreatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndCreatedByManyToManyEdgeNotificationGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByUpdatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type NotificationPeopleByNotificationGroupNotificationIdAndUpdatedByManyToManyEdgeNotificationGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByCreatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndCreatedByManyToManyEdgeNotificationNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByUpdatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type NotificationPeopleByNotificationNewsletterNotificationIdAndUpdatedByManyToManyEdgeNotificationNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByCreatedBy: NotificationsConnection;
};


/** A `Person` edge in the connection, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndCreatedByManyToManyEdgeNotificationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** A connection to a list of `Person` values, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyConnection = {
  __typename?: 'NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyEdge = {
  __typename?: 'NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByUpdatedBy: NotificationsConnection;
};


/** A `Person` edge in the connection, with data from `Notification`. */
export type NotificationPeopleByNotificationRecurringParentAndUpdatedByManyToManyEdgeNotificationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** Input for the nested mutation of `notification` in the `NotificationInput` mutation. */
export type NotificationRecurringParentFkeyInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<NotificationNotificationSlugKeyConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<NotificationNodeIdConnect>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<NotificationNotificationPkeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<NotificationNotificationSlugKeyDelete>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<NotificationNodeIdDelete>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<NotificationOnNotificationForNotificationRecurringParentFkeyUsingNotificationPkeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<NotificationOnNotificationForNotificationRecurringParentFkeyUsingNotificationSlugKeyUpdate>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationOnNotificationForNotificationRecurringParentFkeyNodeIdUpdate>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationRecurringParentFkeyNotificationCreateInput>;
};

/** The `notification` to be created by this mutation. */
export type NotificationRecurringParentFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `NotificationInput` mutation. */
export type NotificationUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnNotificationForNotificationUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<NotificationOnNotificationForNotificationUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<NotificationUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `notification` in the `PersonInput` mutation. */
export type NotificationUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `notification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: Maybe<Array<NotificationNotificationPkeyConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<NotificationNotificationSlugKeyConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<NotificationNodeIdConnect>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteById?: Maybe<Array<NotificationNotificationPkeyDelete>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<NotificationNotificationSlugKeyDelete>>;
  /** The primary key(s) for `notification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<NotificationNodeIdDelete>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: Maybe<Array<NotificationOnNotificationForNotificationUpdatedByFkeyUsingNotificationPkeyUpdate>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<NotificationOnNotificationForNotificationUpdatedByFkeyUsingNotificationSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnNotificationForNotificationUpdatedByFkeyNodeIdUpdate>>;
  /** A `NotificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<NotificationUpdatedByFkeyNotificationCreateInput>>;
};

/** The `notification` to be created by this mutation. */
export type NotificationUpdatedByFkeyNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  draft?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** The `person` to be created by this mutation. */
export type NotificationUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Notification` values. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification` and cursor to aid in pagination. */
  edges: Array<NotificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
};

/** Methods to use when ordering `Notification`. */
export enum NotificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  DraftAsc = 'DRAFT_ASC',
  DraftDesc = 'DRAFT_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  DeliveryAsc = 'DELIVERY_ASC',
  DeliveryDesc = 'DELIVERY_DESC',
  SendDateAsc = 'SEND_DATE_ASC',
  SendDateDesc = 'SEND_DATE_DESC',
  RecurringCronAsc = 'RECURRING_CRON_ASC',
  RecurringCronDesc = 'RECURRING_CRON_DESC',
  RecurringEnabledAsc = 'RECURRING_ENABLED_ASC',
  RecurringEnabledDesc = 'RECURRING_ENABLED_DESC',
  NextOccurrenceAsc = 'NEXT_OCCURRENCE_ASC',
  NextOccurrenceDesc = 'NEXT_OCCURRENCE_DESC',
  UserTypesAsc = 'USER_TYPES_ASC',
  UserTypesDesc = 'USER_TYPES_DESC',
  RecipientCountAsc = 'RECIPIENT_COUNT_ASC',
  RecipientCountDesc = 'RECIPIENT_COUNT_DESC',
  LogsAsc = 'LOGS_ASC',
  LogsDesc = 'LOGS_DESC',
  NextOccurrenceSendJobAsc = 'NEXT_OCCURRENCE_SEND_JOB_ASC',
  NextOccurrenceSendJobDesc = 'NEXT_OCCURRENCE_SEND_JOB_DESC',
  RecurringParentAsc = 'RECURRING_PARENT_ASC',
  RecurringParentDesc = 'RECURRING_PARENT_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  EmbedUrlAsc = 'EMBED_URL_ASC',
  EmbedUrlDesc = 'EMBED_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum NotificationsOrderByType {
  SendDateAsc = 'SEND_DATE_ASC',
  SendDateDesc = 'SEND_DATE_DESC',
  UpdatedAt = 'UPDATED_AT',
  NextOccurrence = 'NEXT_OCCURRENCE'
}

/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type Page = Node & {
  __typename?: 'Page';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters: NewslettersConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSections: PageSectionsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterPageIdAndCreatedBy: PagePeopleByNewsletterPageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterPageIdAndUpdatedBy: PagePeopleByNewsletterPageIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNewsletterPageIdAndCoverImageFileId: PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionPageIdAndCreatedBy: PagePeopleByPageSectionPageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionPageIdAndUpdatedBy: PagePeopleByPageSectionPageIdAndUpdatedByManyToManyConnection;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PageNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PagePageSectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PagePeopleByNewsletterPageIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PagePeopleByNewsletterPageIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PageFilesByNewsletterPageIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PagePeopleByPageSectionPageIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Pages are containers for PageSections. A Page is designed to contain any number of sections to create dynamic rich content such as Newsletters. Currently a Page doesn't a purpose much more than simply a container. */
export type PagePeopleByPageSectionPageIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A condition to be used against `Page` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `page` in the `PersonInput` mutation. */
export type PageCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `page` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectById?: Maybe<Array<PagePagePkeyConnect>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageNodeIdConnect>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteById?: Maybe<Array<PagePagePkeyDelete>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageNodeIdDelete>>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateById?: Maybe<Array<PageOnPageForPageCreatedByFkeyUsingPagePkeyUpdate>>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageForPageCreatedByFkeyNodeIdUpdate>>;
  /** A `PageInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageCreatedByFkeyPageCreateInput>>;
};

/** The `page` to be created by this mutation. */
export type PageCreatedByFkeyPageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** A connection to a list of `File` values, with data from `Newsletter`. */
export type PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Newsletter`. */
export type PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletterCoverImages: NewslettersConnection;
};


/** A `File` edge in the connection, with data from `Newsletter`. */
export type PageFilesByNewsletterPageIdAndCoverImageFileIdManyToManyEdgeNewsletterCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A filter to be used against `Page` object types. All fields are combined with a logical ‘and.’ */
export type PageFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PageFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PageFilter>>;
  /** Negates the expression. */
  not?: Maybe<PageFilter>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** An input for mutations affecting `Page` */
export type PageInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PageNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `page` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type PageNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `page` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageOnNewsletterForNewsletterPageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The fields on `page` to look up the row to update. */
export type PageOnNewsletterForNewsletterPageIdFkeyUsingPagePkeyUpdate = {
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: UpdatePageOnNewsletterForNewsletterPageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `page` to look up the row to update. */
export type PageOnPageForPageCreatedByFkeyUsingPagePkeyUpdate = {
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: UpdatePageOnPageForPageCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageOnPageForPageUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `page` to look up the row to update. */
export type PageOnPageForPageUpdatedByFkeyUsingPagePkeyUpdate = {
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: UpdatePageOnPageForPageUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageOnPageSectionForPageSectionPageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: PageSectionPatch;
};

/** The fields on `page` to look up the row to update. */
export type PageOnPageSectionForPageSectionPageIdFkeyUsingPagePkeyUpdate = {
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: UpdatePageOnPageSectionForPageSectionPageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `page` to look up the row to connect. */
export type PagePagePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `page` to look up the row to delete. */
export type PagePagePkeyDelete = {
  id: Scalars['UUID'];
};

/** Represents an update to a `Page`. Fields that are set will be updated. */
export type PagePatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndCreatedByManyToManyConnection = {
  __typename?: 'PagePeopleByNewsletterPageIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PagePeopleByNewsletterPageIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndCreatedByManyToManyEdge = {
  __typename?: 'PagePeopleByNewsletterPageIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByCreatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndCreatedByManyToManyEdgeNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndUpdatedByManyToManyConnection = {
  __typename?: 'PagePeopleByNewsletterPageIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PagePeopleByNewsletterPageIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndUpdatedByManyToManyEdge = {
  __typename?: 'PagePeopleByNewsletterPageIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByUpdatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PagePeopleByNewsletterPageIdAndUpdatedByManyToManyEdgeNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndCreatedByManyToManyConnection = {
  __typename?: 'PagePeopleByPageSectionPageIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PagePeopleByPageSectionPageIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndCreatedByManyToManyEdge = {
  __typename?: 'PagePeopleByPageSectionPageIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByCreatedBy: PageSectionsConnection;
};


/** A `Person` edge in the connection, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndCreatedByManyToManyEdgePageSectionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndUpdatedByManyToManyConnection = {
  __typename?: 'PagePeopleByPageSectionPageIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PagePeopleByPageSectionPageIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndUpdatedByManyToManyEdge = {
  __typename?: 'PagePeopleByPageSectionPageIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByUpdatedBy: PageSectionsConnection;
};


/** A `Person` edge in the connection, with data from `PageSection`. */
export type PagePeopleByPageSectionPageIdAndUpdatedByManyToManyEdgePageSectionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** PageSections are the building blocks for a Page. They can contain a variety of content types that are displayed (and validated) according to the page_section.type. */
export type PageSection = Node & {
  __typename?: 'PageSection';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId: Scalars['UUID'];
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<Buttons>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `PageSection`. */
  page?: Maybe<Page>;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByPageSectionFilePageSectionIdAndFileId: PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFilePageSectionIdAndCreatedBy: PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFilePageSectionIdAndUpdatedBy: PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyConnection;
};


/** PageSections are the building blocks for a Page. They can contain a variety of content types that are displayed (and validated) according to the page_section.type. */
export type PageSectionPageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};


/** PageSections are the building blocks for a Page. They can contain a variety of content types that are displayed (and validated) according to the page_section.type. */
export type PageSectionFilesByPageSectionFilePageSectionIdAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** PageSections are the building blocks for a Page. They can contain a variety of content types that are displayed (and validated) according to the page_section.type. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** PageSections are the building blocks for a Page. They can contain a variety of content types that are displayed (and validated) according to the page_section.type. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `PageSection` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PageSectionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pageId` field. */
  pageId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<PageSectionType>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `titleAlignment` field. */
  titleAlignment?: Maybe<Alignment>;
  /** Checks for equality with the object’s `subtitle` field. */
  subtitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bodyImageAlignment` field. */
  bodyImageAlignment?: Maybe<Alignment>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `pageSection` in the `PersonInput` mutation. */
export type PageSectionCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `pageSection` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionPageSectionPkeyConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionNodeIdConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionPageSectionPkeyDelete>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionOnPageSectionForPageSectionCreatedByFkeyUsingPageSectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageSectionForPageSectionCreatedByFkeyNodeIdUpdate>>;
  /** A `PageSectionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionCreatedByFkeyPageSectionCreateInput>>;
};

/** The `pageSection` to be created by this mutation. */
export type PageSectionCreatedByFkeyPageSectionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** `File`s for a `PageSection`. */
export type PageSectionFile = Node & {
  __typename?: 'PageSectionFile';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
  fileId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Sort orders are managed by the DB and only exposed for updating via custom functions. */
  sortOrder: Scalars['Int'];
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  /** Reads a single `PageSection` that is related to this `PageSectionFile`. */
  pageSection?: Maybe<PageSection>;
  /** Reads a single `File` that is related to this `PageSectionFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `PageSectionFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PageSectionFileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pageSectionId` field. */
  pageSectionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cover` field. */
  cover?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fileRelationType` field. */
  fileRelationType?: Maybe<FileRelationType>;
};

/** Input for the nested mutation of `pageSectionFile` in the `PersonInput` mutation. */
export type PageSectionFileCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `pageSectionFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionFilePageSectionFilePkeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionFileNodeIdConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionFilePageSectionFilePkeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyUsingPageSectionFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByFileIdAndPageSectionId?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageSectionFileForPageSectionFileCreatedByFkeyNodeIdUpdate>>;
  /** A `PageSectionFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionFileCreatedByFkeyPageSectionFileCreateInput>>;
};

/** The `pageSectionFile` to be created by this mutation. */
export type PageSectionFileCreatedByFkeyPageSectionFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type PageSectionFileFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `PageSectionFileInput` mutation. */
export type PageSectionFileFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnPageSectionFileForPageSectionFileFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<PageSectionFileFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `pageSectionFile` in the `FileInput` mutation. */
export type PageSectionFileFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `pageSectionFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionFilePageSectionFilePkeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionFileNodeIdConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionFilePageSectionFilePkeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyUsingPageSectionFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByFileIdAndPageSectionId?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnPageSectionFileForPageSectionFileFileIdFkeyNodeIdUpdate>>;
  /** A `PageSectionFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionFileFileIdFkeyPageSectionFileCreateInput>>;
};

/** The `pageSectionFile` to be created by this mutation. */
export type PageSectionFileFileIdFkeyPageSectionFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** A filter to be used against `PageSectionFile` object types. All fields are combined with a logical ‘and.’ */
export type PageSectionFileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `pageSectionId` field. */
  pageSectionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `cover` field. */
  cover?: Maybe<BooleanFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `fileRelationType` field. */
  fileRelationType?: Maybe<FileRelationTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PageSectionFileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PageSectionFileFilter>>;
  /** Negates the expression. */
  not?: Maybe<PageSectionFileFilter>;
};

/** An input for mutations affecting `PageSectionFile` */
export type PageSectionFileInput = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PageSectionFileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type PageSectionFileNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyPatch;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyUsingPageSectionFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyPatch;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyUsingPageSectionFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: PageSectionPatch;
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyPatch;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyPatch;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to update. */
export type PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPageSectionFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: UpdatePageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to connect. */
export type PageSectionFilePageSectionFileFileIdPageSectionIdKeyConnect = {
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to delete. */
export type PageSectionFilePageSectionFileFileIdPageSectionIdKeyDelete = {
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to connect. */
export type PageSectionFilePageSectionFilePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `pageSectionFile` to look up the row to delete. */
export type PageSectionFilePageSectionFilePkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `pageSection` in the `PageSectionFileInput` mutation. */
export type PageSectionFilePageSectionIdFkeyInput = {
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectById?: Maybe<PageSectionPageSectionPkeyConnect>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectByNodeId?: Maybe<PageSectionNodeIdConnect>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteById?: Maybe<PageSectionPageSectionPkeyDelete>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PageSectionNodeIdDelete>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateById?: Maybe<PageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionPkeyUpdate>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyNodeIdUpdate>;
  /** A `PageSectionInput` object that will be created and connected to this object. */
  create?: Maybe<PageSectionFilePageSectionIdFkeyPageSectionCreateInput>;
};

/** Input for the nested mutation of `pageSectionFile` in the `PageSectionInput` mutation. */
export type PageSectionFilePageSectionIdFkeyInverseInput = {
  /** Flag indicating whether all other `pageSectionFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionFilePageSectionFilePkeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionFileNodeIdConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionFilePageSectionFilePkeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByFileIdAndPageSectionId?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyNodeIdUpdate>>;
  /** A `PageSectionFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionFilePageSectionIdFkeyPageSectionFileCreateInput>>;
};

/** The `pageSection` to be created by this mutation. */
export type PageSectionFilePageSectionIdFkeyPageSectionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** The `pageSectionFile` to be created by this mutation. */
export type PageSectionFilePageSectionIdFkeyPageSectionFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** Represents an update to a `PageSectionFile`. Fields that are set will be updated. */
export type PageSectionFilePatch = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `PageSectionFileInput` mutation. */
export type PageSectionFileUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<PageSectionFileUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `pageSectionFile` in the `PersonInput` mutation. */
export type PageSectionFileUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `pageSectionFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionFilePageSectionFilePkeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionFileNodeIdConnect>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionFilePageSectionFilePkeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByFileIdAndPageSectionId?: Maybe<Array<PageSectionFilePageSectionFileFileIdPageSectionIdKeyDelete>>;
  /** The primary key(s) for `pageSectionFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPageSectionFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByFileIdAndPageSectionId?: Maybe<Array<PageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPageSectionFileFileIdPageSectionIdKeyUpdate>>;
  /** The primary key(s) and patch data for `pageSectionFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageSectionFileForPageSectionFileUpdatedByFkeyNodeIdUpdate>>;
  /** A `PageSectionFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionFileUpdatedByFkeyPageSectionFileCreateInput>>;
};

/** The `pageSectionFile` to be created by this mutation. */
export type PageSectionFileUpdatedByFkeyPageSectionFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** The `person` to be created by this mutation. */
export type PageSectionFileUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `File` values, with data from `PageSectionFile`. */
export type PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyConnection = {
  __typename?: 'PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `PageSectionFile`. */
export type PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyEdge = {
  __typename?: 'PageSectionFilesByPageSectionFilePageSectionIdAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Sort orders are managed by the DB and only exposed for updating via custom functions. */
  sortOrder: Scalars['Int'];
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
};

/** A connection to a list of `PageSectionFile` values. */
export type PageSectionFilesConnection = {
  __typename?: 'PageSectionFilesConnection';
  /** A list of `PageSectionFile` objects. */
  nodes: Array<PageSectionFile>;
  /** A list of edges which contains the `PageSectionFile` and cursor to aid in pagination. */
  edges: Array<PageSectionFilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageSectionFile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PageSectionFile` edge in the connection. */
export type PageSectionFilesEdge = {
  __typename?: 'PageSectionFilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageSectionFile` at the end of the edge. */
  node: PageSectionFile;
};

/** Methods to use when ordering `PageSectionFile`. */
export enum PageSectionFilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PageSectionIdAsc = 'PAGE_SECTION_ID_ASC',
  PageSectionIdDesc = 'PAGE_SECTION_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  CoverAsc = 'COVER_ASC',
  CoverDesc = 'COVER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FileRelationTypeAsc = 'FILE_RELATION_TYPE_ASC',
  FileRelationTypeDesc = 'FILE_RELATION_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `PageSection` object types. All fields are combined with a logical ‘and.’ */
export type PageSectionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `pageId` field. */
  pageId?: Maybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<PageSectionTypeFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `titleAlignment` field. */
  titleAlignment?: Maybe<AlignmentFilter>;
  /** Filter by the object’s `subtitle` field. */
  subtitle?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `bodyImageAlignment` field. */
  bodyImageAlignment?: Maybe<AlignmentFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `url` field. */
  url?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PageSectionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PageSectionFilter>>;
  /** Negates the expression. */
  not?: Maybe<PageSectionFilter>;
};

/** An input for mutations affecting `PageSection` */
export type PageSectionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PageSectionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `pageSection` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type PageSectionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `pageSection` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: PageSectionFilePatch;
};

/** The fields on `pageSection` to look up the row to update. */
export type PageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyUsingPageSectionPkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: UpdatePageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `pageSection` to look up the row to update. */
export type PageSectionOnPageSectionForPageSectionCreatedByFkeyUsingPageSectionPkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: UpdatePageSectionOnPageSectionForPageSectionCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionOnPageSectionForPageSectionPageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `page` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: PagePatch;
};

/** The fields on `pageSection` to look up the row to update. */
export type PageSectionOnPageSectionForPageSectionPageIdFkeyUsingPageSectionPkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: UpdatePageSectionOnPageSectionForPageSectionPageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PageSectionOnPageSectionForPageSectionUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `pageSection` to look up the row to update. */
export type PageSectionOnPageSectionForPageSectionUpdatedByFkeyUsingPageSectionPkeyUpdate = {
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: UpdatePageSectionOnPageSectionForPageSectionUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `page` in the `PageSectionInput` mutation. */
export type PageSectionPageIdFkeyInput = {
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectById?: Maybe<PagePagePkeyConnect>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectByNodeId?: Maybe<PageNodeIdConnect>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteById?: Maybe<PagePagePkeyDelete>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PageNodeIdDelete>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateById?: Maybe<PageOnPageSectionForPageSectionPageIdFkeyUsingPagePkeyUpdate>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageSectionOnPageSectionForPageSectionPageIdFkeyNodeIdUpdate>;
  /** A `PageInput` object that will be created and connected to this object. */
  create?: Maybe<PageSectionPageIdFkeyPageCreateInput>;
};

/** Input for the nested mutation of `pageSection` in the `PageInput` mutation. */
export type PageSectionPageIdFkeyInverseInput = {
  /** Flag indicating whether all other `pageSection` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionPageSectionPkeyConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionNodeIdConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionPageSectionPkeyDelete>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionOnPageSectionForPageSectionPageIdFkeyUsingPageSectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PageOnPageSectionForPageSectionPageIdFkeyNodeIdUpdate>>;
  /** A `PageSectionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionPageIdFkeyPageSectionCreateInput>>;
};

/** The `page` to be created by this mutation. */
export type PageSectionPageIdFkeyPageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** The `pageSection` to be created by this mutation. */
export type PageSectionPageIdFkeyPageSectionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** The fields on `pageSection` to look up the row to connect. */
export type PageSectionPageSectionPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `pageSection` to look up the row to delete. */
export type PageSectionPageSectionPkeyDelete = {
  id: Scalars['UUID'];
};

/** Represents an update to a `PageSection`. Fields that are set will be updated. */
export type PageSectionPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type?: Maybe<PageSectionType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyConnection = {
  __typename?: 'PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyEdge = {
  __typename?: 'PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByCreatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndCreatedByManyToManyEdgePageSectionFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyConnection = {
  __typename?: 'PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyEdge = {
  __typename?: 'PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByUpdatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PageSectionPeopleByPageSectionFilePageSectionIdAndUpdatedByManyToManyEdgePageSectionFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

export enum PageSectionType {
  Title = 'TITLE',
  Text = 'TEXT',
  Picture = 'PICTURE',
  Attachment = 'ATTACHMENT',
  Button = 'BUTTON',
  Video = 'VIDEO'
}

/** A filter to be used against PageSectionType fields. All fields are combined with a logical ‘and.’ */
export type PageSectionTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PageSectionType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PageSectionType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PageSectionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PageSectionType>;
  /** Included in the specified list. */
  in?: Maybe<Array<PageSectionType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PageSectionType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PageSectionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PageSectionType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PageSectionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PageSectionType>;
};

/** Input for the nested mutation of `person` in the `PageSectionInput` mutation. */
export type PageSectionUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnPageSectionForPageSectionUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageSectionOnPageSectionForPageSectionUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<PageSectionUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `pageSection` in the `PersonInput` mutation. */
export type PageSectionUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `pageSection` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectById?: Maybe<Array<PageSectionPageSectionPkeyConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageSectionNodeIdConnect>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteById?: Maybe<Array<PageSectionPageSectionPkeyDelete>>;
  /** The primary key(s) for `pageSection` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageSectionNodeIdDelete>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateById?: Maybe<Array<PageSectionOnPageSectionForPageSectionUpdatedByFkeyUsingPageSectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `pageSection` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageSectionForPageSectionUpdatedByFkeyNodeIdUpdate>>;
  /** A `PageSectionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageSectionUpdatedByFkeyPageSectionCreateInput>>;
};

/** The `pageSection` to be created by this mutation. */
export type PageSectionUpdatedByFkeyPageSectionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pageId?: Maybe<Scalars['UUID']>;
  type: PageSectionType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** The `person` to be created by this mutation. */
export type PageSectionUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `PageSection` values. */
export type PageSectionsConnection = {
  __typename?: 'PageSectionsConnection';
  /** A list of `PageSection` objects. */
  nodes: Array<PageSection>;
  /** A list of edges which contains the `PageSection` and cursor to aid in pagination. */
  edges: Array<PageSectionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PageSection` edge in the connection. */
export type PageSectionsEdge = {
  __typename?: 'PageSectionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageSection` at the end of the edge. */
  node: PageSection;
};

/** Methods to use when ordering `PageSection`. */
export enum PageSectionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PageIdAsc = 'PAGE_ID_ASC',
  PageIdDesc = 'PAGE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TitleAlignmentAsc = 'TITLE_ALIGNMENT_ASC',
  TitleAlignmentDesc = 'TITLE_ALIGNMENT_DESC',
  SubtitleAsc = 'SUBTITLE_ASC',
  SubtitleDesc = 'SUBTITLE_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  BodyImageAlignmentAsc = 'BODY_IMAGE_ALIGNMENT_ASC',
  BodyImageAlignmentDesc = 'BODY_IMAGE_ALIGNMENT_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `person` in the `PageInput` mutation. */
export type PageUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnPageForPageUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<PageOnPageForPageUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<PageUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `page` in the `PersonInput` mutation. */
export type PageUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `page` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectById?: Maybe<Array<PagePagePkeyConnect>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PageNodeIdConnect>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteById?: Maybe<Array<PagePagePkeyDelete>>;
  /** The primary key(s) for `page` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PageNodeIdDelete>>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateById?: Maybe<Array<PageOnPageForPageUpdatedByFkeyUsingPagePkeyUpdate>>;
  /** The primary key(s) and patch data for `page` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnPageForPageUpdatedByFkeyNodeIdUpdate>>;
  /** A `PageInput` object that will be created and connected to this object. */
  create?: Maybe<Array<PageUpdatedByFkeyPageCreateInput>>;
};

/** The `page` to be created by this mutation. */
export type PageUpdatedByFkeyPageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** The `person` to be created by this mutation. */
export type PageUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Page` values. */
export type PagesConnection = {
  __typename?: 'PagesConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page` and cursor to aid in pagination. */
  edges: Array<PagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection. */
export type PagesEdge = {
  __typename?: 'PagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
};

/** Methods to use when ordering `Page`. */
export enum PagesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Person` values. */
export type PeopleConnection = {
  __typename?: 'PeopleConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person` and cursor to aid in pagination. */
  edges: Array<PeopleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection. */
export type PeopleEdge = {
  __typename?: 'PeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
};

/** Methods to use when ordering `Person`. */
export enum PeopleOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  UserAccountIdAsc = 'USER_ACCOUNT_ID_ASC',
  UserAccountIdDesc = 'USER_ACCOUNT_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  BioAsc = 'BIO_ASC',
  BioDesc = 'BIO_DESC',
  ProfileImageFileIdAsc = 'PROFILE_IMAGE_FILE_ID_ASC',
  ProfileImageFileIdDesc = 'PROFILE_IMAGE_FILE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type Person = Node & {
  __typename?: 'Person';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Person`. */
  profileImage?: Maybe<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: FilesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByUpdatedBy: FilesConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCreatedBy: CalendarsConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByUpdatedBy: CalendarsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCreatedBy: EventsConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByUpdatedBy: EventsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCreatedBy: PeopleConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUpdatedBy: PeopleConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByCreatedBy: GoogleCalendarSyncsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByUpdatedBy: GoogleCalendarSyncsConnection;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCreatedBy: CalendarGroupsConnection;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByUpdatedBy: CalendarGroupsConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByCreatedBy: GoogleAccountsConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByUpdatedBy: GoogleAccountsConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByCreatedBy: NotificationsConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByUpdatedBy: NotificationsConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByCreatedBy: NewslettersConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByUpdatedBy: NewslettersConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCreatedBy: GroupsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByUpdatedBy: GroupsConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByCreatedBy: GroupPeopleConnection;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByUpdatedBy: GroupPeopleConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByCreatedBy: SchoolPeopleConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByUpdatedBy: SchoolPeopleConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByCreatedBy: SchoolsConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByUpdatedBy: SchoolsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByCreatedBy: AndroidJavaKeyStoresConnection;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByUpdatedBy: AndroidJavaKeyStoresConnection;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByCreatedBy: ReleasesConnection;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByUpdatedBy: ReleasesConnection;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByCreatedBy: BuildsConnection;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByUpdatedBy: BuildsConnection;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByCreatedBy: BuildStepsConnection;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByUpdatedBy: BuildStepsConnection;
  /** Reads and enables pagination through a set of `App`. */
  appsByCreatedBy: AppsConnection;
  /** Reads and enables pagination through a set of `App`. */
  appsByUpdatedBy: AppsConnection;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByCreatedBy: CalendarEventsConnection;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByUpdatedBy: CalendarEventsConnection;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByCreatedBy: CalendarGroupCalendarsConnection;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByUpdatedBy: CalendarGroupCalendarsConnection;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByCreatedBy: DivisionsConnection;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByUpdatedBy: DivisionsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByCreatedBy: GoogleCalendarSyncCalendarsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByUpdatedBy: GoogleCalendarSyncCalendarsConnection;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByCreatedBy: GroupCategoriesConnection;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByUpdatedBy: GroupCategoriesConnection;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByCreatedBy: GroupNewslettersConnection;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByUpdatedBy: GroupNewslettersConnection;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByCreatedBy: MarqueesConnection;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByUpdatedBy: MarqueesConnection;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByCreatedBy: NotificationFilesConnection;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByUpdatedBy: NotificationFilesConnection;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByCreatedBy: NotificationNewslettersConnection;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByUpdatedBy: NotificationNewslettersConnection;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByCreatedBy: NotificationGroupsConnection;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByUpdatedBy: NotificationGroupsConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByCreatedBy: PagesConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByUpdatedBy: PagesConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByCreatedBy: PageSectionsConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByUpdatedBy: PageSectionsConnection;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByCreatedBy: PageSectionFilesConnection;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByUpdatedBy: PageSectionFilesConnection;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByCreatedBy: ResourceFilesConnection;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByUpdatedBy: ResourceFilesConnection;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByCreatedBy: SchoolPersonInstallationsConnection;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByUpdatedBy: SchoolPersonInstallationsConnection;
  /** Reads and enables pagination through a set of `SchoolPersonInvitation`. */
  schoolPersonInvitationsByCreatedBy: SchoolPersonInvitationsConnection;
  /** Reads and enables pagination through a set of `SchoolPersonInvitation`. */
  schoolPersonInvitationsByUpdatedBy: SchoolPersonInvitationsConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByCreatedBy: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByUpdatedBy: ThemesConnection;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByCreatedBy: UserGoogleAccountsConnection;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByUpdatedBy: UserGoogleAccountsConnection;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByCreatedBy: AndroidAppsConnection;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByUpdatedBy: AndroidAppsConnection;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByCreatedBy: AndroidScreenshotsConnection;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByUpdatedBy: AndroidScreenshotsConnection;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByCreatedBy: AppStoreAccountsConnection;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByUpdatedBy: AppStoreAccountsConnection;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByCreatedBy: AppStoreListingsConnection;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByUpdatedBy: AppStoreListingsConnection;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByCreatedBy: AppStoreUsersConnection;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByUpdatedBy: AppStoreUsersConnection;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByCreatedBy: GooglePlayAccountsConnection;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByUpdatedBy: GooglePlayAccountsConnection;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByCreatedBy: GooglePlayListingsConnection;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByUpdatedBy: GooglePlayListingsConnection;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByCreatedBy: GooglePlayUsersConnection;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByUpdatedBy: GooglePlayUsersConnection;
  /** Reads and enables pagination through a set of `IosApp`. */
  iosAppsByCreatedBy: IosAppsConnection;
  /** Reads and enables pagination through a set of `IosApp`. */
  iosAppsByUpdatedBy: IosAppsConnection;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByCreatedBy: IosScreenshotsConnection;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByUpdatedBy: IosScreenshotsConnection;
  /** Accessor for the non-GraphQL exposed setting whether this user is an App Administrator or not. */
  appAdministrator?: Maybe<Scalars['Boolean']>;
  /** Person's GroupPerson records for a School. */
  groupPeopleForSchool: GroupPeopleConnection;
  /** Person's SchoolPerson record for a given School. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Slug IDs for the schools the user has access to NOT taking into account super admins. */
  schoolSlugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Accessor for a person's user session. */
  userSession?: Maybe<UserSession>;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByFileCreatedByAndUpdatedBy: PersonPeopleByFileCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByFileUpdatedByAndCreatedBy: PersonPeopleByFileUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarCreatedByAndUpdatedBy: PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCalendarCreatedByAndGroupId: PersonGroupsByCalendarCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarUpdatedByAndCreatedBy: PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCalendarUpdatedByAndGroupId: PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventCreatedByAndUpdatedBy: PersonPeopleByEventCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventCreatedByAndCoverImageFileId: PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventCreatedByAndThumbnailImageFileId: PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByEventUpdatedByAndCreatedBy: PersonPeopleByEventUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventUpdatedByAndCoverImageFileId: PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByEventUpdatedByAndThumbnailImageFileId: PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonCreatedByAndUpdatedBy: PersonPeopleByPersonCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByPersonCreatedByAndProfileImageFileId: PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPersonUpdatedByAndCreatedBy: PersonPeopleByPersonUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByPersonUpdatedByAndProfileImageFileId: PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCreatedByAndUpdatedBy: PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountId: PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncUpdatedByAndCreatedBy: PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountId: PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCreatedByAndUpdatedBy: PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByCalendarGroupCreatedByAndSchoolId: PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupUpdatedByAndCreatedBy: PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByCalendarGroupUpdatedByAndSchoolId: PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleAccountCreatedByAndUpdatedBy: PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleAccountUpdatedByAndCreatedBy: PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationCreatedByAndUpdatedBy: PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationCreatedByAndRecurringParent: PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationUpdatedByAndCreatedBy: PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationUpdatedByAndRecurringParent: PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterCreatedByAndUpdatedBy: PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByNewsletterCreatedByAndPageId: PersonPagesByNewsletterCreatedByAndPageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNewsletterCreatedByAndCoverImageFileId: PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNewsletterUpdatedByAndCreatedBy: PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByNewsletterUpdatedByAndPageId: PersonPagesByNewsletterUpdatedByAndPageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNewsletterUpdatedByAndCoverImageFileId: PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupCreatedByAndUpdatedBy: PersonPeopleByGroupCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByGroupCreatedByAndGroupCategoryId: PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupUpdatedByAndCreatedBy: PersonPeopleByGroupUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByGroupUpdatedByAndGroupCategoryId: PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceCreatedByAndUpdatedBy: PersonPeopleByResourceCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceCreatedByAndGroupId: PersonGroupsByResourceCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceCreatedByAndParentResource: PersonResourcesByResourceCreatedByAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCreatedByAndCoverImageFileId: PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCreatedByAndTileImageFileId: PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCreatedByAndDefaultCoverImageFileId: PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCreatedByAndDefaultThumbnailImageFileId: PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceCreatedByAndThumbnailImageFileId: PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceUpdatedByAndCreatedBy: PersonPeopleByResourceUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceUpdatedByAndGroupId: PersonGroupsByResourceUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceUpdatedByAndParentResource: PersonResourcesByResourceUpdatedByAndParentResourceManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceUpdatedByAndCoverImageFileId: PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceUpdatedByAndTileImageFileId: PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceUpdatedByAndDefaultCoverImageFileId: PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceUpdatedByAndDefaultThumbnailImageFileId: PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceUpdatedByAndThumbnailImageFileId: PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupPersonPersonIdAndGroupId: PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonPersonIdAndCreatedBy: PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonPersonIdAndUpdatedBy: PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupPersonCreatedByAndGroupId: PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonCreatedByAndPersonId: PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonCreatedByAndUpdatedBy: PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupPersonUpdatedByAndGroupId: PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonUpdatedByAndPersonId: PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupPersonUpdatedByAndCreatedBy: PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySchoolPersonPersonIdAndSchoolId: PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonPersonIdAndCreatedBy: PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonPersonIdAndUpdatedBy: PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySchoolPersonCreatedByAndSchoolId: PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonCreatedByAndPersonId: PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonCreatedByAndUpdatedBy: PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySchoolPersonUpdatedByAndSchoolId: PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonUpdatedByAndPersonId: PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonUpdatedByAndCreatedBy: PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolCreatedByAndUpdatedBy: PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesBySchoolCreatedByAndThemeId: PersonThemesBySchoolCreatedByAndThemeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolUpdatedByAndCreatedBy: PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesBySchoolUpdatedByAndThemeId: PersonThemesBySchoolUpdatedByAndThemeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingCreatedByAndUpdatedBy: PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndCoverImageFileId: PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndThumbnailImageFileId: PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndLogoImageFileId: PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndSchoolImageFileId: PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndMascotImageFileId: PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageId: PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolSettingUpdatedByAndCreatedBy: PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndCoverImageFileId: PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndThumbnailImageFileId: PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndLogoImageFileId: PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndSchoolImageFileId: PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndMascotImageFileId: PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageId: PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppId: PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidJavaKeyStoreCreatedByAndUpdatedBy: PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppId: PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidJavaKeyStoreUpdatedByAndCreatedBy: PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByReleaseCreatedByAndUpdatedBy: PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByReleaseUpdatedByAndCreatedBy: PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `App`. */
  appsByBuildCreatedByAndAppId: PersonAppsByBuildCreatedByAndAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByBuildCreatedByAndReleaseId: PersonReleasesByBuildCreatedByAndReleaseIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildCreatedByAndUpdatedBy: PersonPeopleByBuildCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `App`. */
  appsByBuildUpdatedByAndAppId: PersonAppsByBuildUpdatedByAndAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByBuildUpdatedByAndReleaseId: PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildUpdatedByAndCreatedBy: PersonPeopleByBuildUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByBuildStepCreatedByAndBuildId: PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildStepCreatedByAndUpdatedBy: PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByBuildStepUpdatedByAndBuildId: PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildStepUpdatedByAndCreatedBy: PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppCreatedByAndUpdatedBy: PersonPeopleByAppCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppUpdatedByAndCreatedBy: PersonPeopleByAppUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarEventCreatedByAndCalendarId: PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCalendarEventCreatedByAndEventId: PersonEventsByCalendarEventCreatedByAndEventIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventCreatedByAndUpdatedBy: PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarEventUpdatedByAndCalendarId: PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCalendarEventUpdatedByAndEventId: PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarEventUpdatedByAndCreatedBy: PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarGroupCalendarCreatedByAndCalendarId: PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupId: PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarCreatedByAndUpdatedBy: PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCalendarGroupCalendarUpdatedByAndCalendarId: PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupId: PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupCalendarUpdatedByAndCreatedBy: PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionCreatedByAndUpdatedBy: PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByDivisionCreatedByAndSchoolId: PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByDivisionCreatedByAndGroupId: PersonGroupsByDivisionCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionUpdatedByAndCreatedBy: PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByDivisionUpdatedByAndSchoolId: PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByDivisionUpdatedByAndGroupId: PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedBy: PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncId: PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarId: PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedBy: PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncId: PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarId: PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupCategoryCreatedByAndUpdatedBy: PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByGroupCategoryCreatedByAndDivisionId: PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupCategoryUpdatedByAndCreatedBy: PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByGroupCategoryUpdatedByAndDivisionId: PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupNewsletterCreatedByAndGroupId: PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByGroupNewsletterCreatedByAndNewsletterId: PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterCreatedByAndUpdatedBy: PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByGroupNewsletterUpdatedByAndGroupId: PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByGroupNewsletterUpdatedByAndNewsletterId: PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGroupNewsletterUpdatedByAndCreatedBy: PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeCreatedByAndUpdatedBy: PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByMarqueeCreatedByAndSchoolId: PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByMarqueeCreatedByAndMarqueeImageFileId: PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeUpdatedByAndCreatedBy: PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByMarqueeUpdatedByAndSchoolId: PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByMarqueeUpdatedByAndMarqueeImageFileId: PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationFileCreatedByAndNotificationId: PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationFileCreatedByAndFileId: PersonFilesByNotificationFileCreatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileCreatedByAndUpdatedBy: PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationFileUpdatedByAndNotificationId: PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByNotificationFileUpdatedByAndFileId: PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationFileUpdatedByAndCreatedBy: PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationNewsletterCreatedByAndNotificationId: PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByNotificationNewsletterCreatedByAndNewsletterId: PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterCreatedByAndUpdatedBy: PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationNewsletterUpdatedByAndNotificationId: PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByNotificationNewsletterUpdatedByAndNewsletterId: PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationNewsletterUpdatedByAndCreatedBy: PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationGroupCreatedByAndNotificationId: PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByNotificationGroupCreatedByAndGroupId: PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupCreatedByAndUpdatedBy: PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByNotificationGroupUpdatedByAndNotificationId: PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByNotificationGroupUpdatedByAndGroupId: PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByNotificationGroupUpdatedByAndCreatedBy: PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageCreatedByAndUpdatedBy: PersonPeopleByPageCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageUpdatedByAndCreatedBy: PersonPeopleByPageUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionCreatedByAndUpdatedBy: PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByPageSectionCreatedByAndPageId: PersonPagesByPageSectionCreatedByAndPageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionUpdatedByAndCreatedBy: PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByPageSectionUpdatedByAndPageId: PersonPagesByPageSectionUpdatedByAndPageIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByPageSectionFileCreatedByAndPageSectionId: PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByPageSectionFileCreatedByAndFileId: PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFileCreatedByAndUpdatedBy: PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByPageSectionFileUpdatedByAndPageSectionId: PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByPageSectionFileUpdatedByAndFileId: PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByPageSectionFileUpdatedByAndCreatedBy: PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceFileCreatedByAndResourceId: PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceFileCreatedByAndFileId: PersonFilesByResourceFileCreatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileCreatedByAndUpdatedBy: PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByResourceFileUpdatedByAndResourceId: PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceFileUpdatedByAndFileId: PersonFilesByResourceFileUpdatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileUpdatedByAndCreatedBy: PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonId: PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInstallationCreatedByAndUpdatedBy: PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonId: PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInstallationUpdatedByAndCreatedBy: PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInvitationCreatedByAndUpdatedBy: PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInvitationUpdatedByAndCreatedBy: PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorCreatedByAndUpdatedBy: PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorCreatedByAndSchoolId: PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCreatedByAndCoverImageFileId: PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCreatedByAndThumbnailImageFileId: PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCreatedByAndLogoImageFileId: PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorCreatedByAndPromoImageFileId: PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorUpdatedByAndCreatedBy: PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `School`. */
  schoolsBySponsorUpdatedByAndSchoolId: PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorUpdatedByAndCoverImageFileId: PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorUpdatedByAndThumbnailImageFileId: PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorUpdatedByAndLogoImageFileId: PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorUpdatedByAndPromoImageFileId: PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByThemeCreatedByAndUpdatedBy: PersonPeopleByThemeCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByThemeUpdatedByAndCreatedBy: PersonPeopleByThemeUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByUserGoogleAccountCreatedByAndGoogleAccountId: PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUserGoogleAccountCreatedByAndUpdatedBy: PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountId: PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUserGoogleAccountUpdatedByAndCreatedBy: PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidAppCreatedByAndUpdatedBy: PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidAppUpdatedByAndCreatedBy: PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingId: PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidScreenshotCreatedByAndUpdatedBy: PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingId: PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAndroidScreenshotUpdatedByAndCreatedBy: PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByAppStoreAccountCreatedByAndAppStoreUserId: PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreAccountCreatedByAndUpdatedBy: PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserId: PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreAccountUpdatedByAndCreatedBy: PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountId: PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreListingCreatedByAndUpdatedBy: PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountId: PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreListingUpdatedByAndCreatedBy: PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreUserCreatedByAndUpdatedBy: PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByAppStoreUserUpdatedByAndCreatedBy: PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserId: PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayAccountCreatedByAndUpdatedBy: PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserId: PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayAccountUpdatedByAndCreatedBy: PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountId: PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayListingCreatedByAndUpdatedBy: PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountId: PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayListingUpdatedByAndCreatedBy: PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayUserCreatedByAndUpdatedBy: PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByGooglePlayUserUpdatedByAndCreatedBy: PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosAppCreatedByAndUpdatedBy: PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosAppUpdatedByAndCreatedBy: PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByIosScreenshotCreatedByAndAppStoreListingId: PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosScreenshotCreatedByAndUpdatedBy: PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByIosScreenshotUpdatedByAndAppStoreListingId: PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIosScreenshotUpdatedByAndCreatedBy: PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyConnection;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidJavaKeyStoresByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidJavaKeyStoresByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonReleasesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonReleasesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildStepsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildStepsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonDivisionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonDivisionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupCategoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupCategoriesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonMarqueesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonMarqueesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourceFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourceFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPersonInstallationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPersonInstallationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPersonInvitationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
  condition?: Maybe<SchoolPersonInvitationCondition>;
  filter?: Maybe<SchoolPersonInvitationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPersonInvitationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
  condition?: Maybe<SchoolPersonInvitationCondition>;
  filter?: Maybe<SchoolPersonInvitationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonThemesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonThemesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonUserGoogleAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonUserGoogleAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreUsersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreUsersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayUsersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayUsersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonIosAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
  condition?: Maybe<IosAppCondition>;
  filter?: Maybe<IosAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonIosAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
  condition?: Maybe<IosAppCondition>;
  filter?: Maybe<IosAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonIosScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonIosScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupPeopleForSchoolArgs = {
  schoolId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPersonArgs = {
  _schoolId: Scalars['UUID'];
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByFileCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByFileUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByCalendarCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByCalendarUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByEventCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByEventCreatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByEventCreatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByEventUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByEventUpdatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByEventUpdatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPersonCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByPersonCreatedByAndProfileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPersonUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByPersonUpdatedByAndProfileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarGroupCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByCalendarGroupCreatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarGroupUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleAccountCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleAccountUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationCreatedByAndRecurringParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationUpdatedByAndRecurringParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNewsletterCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByNewsletterCreatedByAndPageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByNewsletterCreatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNewsletterUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByNewsletterUpdatedByAndPageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByNewsletterUpdatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByResourceCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByResourceCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByResourceCreatedByAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceCreatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceCreatedByAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceCreatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByResourceUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByResourceUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByResourceUpdatedByAndParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceUpdatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceUpdatedByAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceUpdatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByGroupPersonPersonIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonPersonIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonPersonIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByGroupPersonCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonCreatedByAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByGroupPersonUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonUpdatedByAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupPersonUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsBySchoolPersonPersonIdAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonPersonIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonPersonIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsBySchoolPersonCreatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonCreatedByAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonUpdatedByAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonThemesBySchoolCreatedByAndThemeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonThemesBySchoolUpdatedByAndThemeIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolSettingCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolSettingUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByReleaseCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByReleaseUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppsByBuildCreatedByAndAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonReleasesByBuildCreatedByAndReleaseIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByBuildCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppsByBuildUpdatedByAndAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonReleasesByBuildUpdatedByAndReleaseIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByBuildUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildsByBuildStepCreatedByAndBuildIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByBuildStepCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonBuildsByBuildStepUpdatedByAndBuildIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByBuildStepUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByCalendarEventCreatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonEventsByCalendarEventCreatedByAndEventIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarEventCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByCalendarEventUpdatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonEventsByCalendarEventUpdatedByAndEventIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarEventUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByDivisionCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByDivisionCreatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByDivisionCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByDivisionUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByDivisionUpdatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByDivisionUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupCategoryCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonDivisionsByGroupCategoryCreatedByAndDivisionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupCategoryUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByGroupNewsletterCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupNewsletterCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByGroupNewsletterUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGroupNewsletterUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByMarqueeCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByMarqueeCreatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByMarqueeUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsByMarqueeUpdatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationFileCreatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByNotificationFileCreatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationFileCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationFileUpdatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByNotificationFileUpdatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationFileUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationGroupCreatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByNotificationGroupCreatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationGroupCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGroupsByNotificationGroupUpdatedByAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByNotificationGroupUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageSectionCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByPageSectionCreatedByAndPageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageSectionUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPagesByPageSectionUpdatedByAndPageIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByPageSectionFileCreatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageSectionFileCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByPageSectionFileUpdatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByPageSectionFileUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByResourceFileCreatedByAndResourceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceFileCreatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByResourceFileCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonResourcesByResourceFileUpdatedByAndResourceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesByResourceFileUpdatedByAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByResourceFileUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySponsorCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsBySponsorCreatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorCreatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorCreatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorCreatedByAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorCreatedByAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleBySponsorUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonSchoolsBySponsorUpdatedByAndSchoolIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorUpdatedByAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorUpdatedByAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonFilesBySponsorUpdatedByAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByThemeCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByThemeUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidAppCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidAppUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreAccountCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreAccountUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreListingCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreListingUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreUserCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByAppStoreUserUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayListingCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayListingUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayUserCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByGooglePlayUserUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByIosAppCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByIosAppUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByIosScreenshotCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/**
 * A Person is intended to represent a singular actual human. Ideally, every Person will have a UserAccount and there will only ever be one of a particular person.
 *
 *
 *
 * Each Person can be connected to any number of schools via a School_Person join object. (i.e. one Person can have many Schools and many Schools may all have the same Person)
 *
 *
 *
 * However, there will be times when a Person needs to be added WITHOUT an associated UserAccount (e.g. adding someone to a directory but never needing or wanting them to have an actual account).
 *
 * In those cases, ideally, if that Person ever is invited to create a UserAccount we will associate that Person record with the actual human it was originally intended to represent.
 *
 * There will be, though, unavoidable circumstances when a school admin creates a Person for their directory, and then separately invites that same person at a later time, thereby creating a duplicate record.
 *
 * When that happens, we will have to just accept the duplication and encourage schools to avoid it when possible, and/or clean up afterward by deleting the Person record not associated with the UserAccount (and actual human).
 */
export type PersonPeopleByIosScreenshotUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `AndroidApp` values, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyConnection = {
  __typename?: 'PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyConnection';
  /** A list of `AndroidApp` objects. */
  nodes: Array<AndroidApp>;
  /** A list of edges which contains the `AndroidApp`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AndroidApp` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AndroidApp` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyEdge = {
  __typename?: 'PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AndroidApp` at the end of the edge. */
  node: AndroidApp;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStores: AndroidJavaKeyStoresConnection;
};


/** A `AndroidApp` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreCreatedByAndAndroidAppIdManyToManyEdgeAndroidJavaKeyStoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** A connection to a list of `AndroidApp` values, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyConnection = {
  __typename?: 'PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyConnection';
  /** A list of `AndroidApp` objects. */
  nodes: Array<AndroidApp>;
  /** A list of edges which contains the `AndroidApp`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AndroidApp` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AndroidApp` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyEdge = {
  __typename?: 'PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AndroidApp` at the end of the edge. */
  node: AndroidApp;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStores: AndroidJavaKeyStoresConnection;
};


/** A `AndroidApp` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonAndroidAppsByAndroidJavaKeyStoreUpdatedByAndAndroidAppIdManyToManyEdgeAndroidJavaKeyStoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** A connection to a list of `AppStoreAccount` values, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyConnection = {
  __typename?: 'PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyConnection';
  /** A list of `AppStoreAccount` objects. */
  nodes: Array<AppStoreAccount>;
  /** A list of edges which contains the `AppStoreAccount`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreAccount` edge in the connection, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyEdge = {
  __typename?: 'PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreAccount` at the end of the edge. */
  node: AppStoreAccount;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListings: AppStoreListingsConnection;
};


/** A `AppStoreAccount` edge in the connection, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingCreatedByAndAppStoreAccountIdManyToManyEdgeAppStoreListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** A connection to a list of `AppStoreAccount` values, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyConnection = {
  __typename?: 'PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyConnection';
  /** A list of `AppStoreAccount` objects. */
  nodes: Array<AppStoreAccount>;
  /** A list of edges which contains the `AppStoreAccount`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreAccount` edge in the connection, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyEdge = {
  __typename?: 'PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreAccount` at the end of the edge. */
  node: AppStoreAccount;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListings: AppStoreListingsConnection;
};


/** A `AppStoreAccount` edge in the connection, with data from `AppStoreListing`. */
export type PersonAppStoreAccountsByAppStoreListingUpdatedByAndAppStoreAccountIdManyToManyEdgeAppStoreListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** A connection to a list of `AppStoreListing` values, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyConnection = {
  __typename?: 'PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyConnection';
  /** A list of `AppStoreListing` objects. */
  nodes: Array<AppStoreListing>;
  /** A list of edges which contains the `AppStoreListing`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreListing` edge in the connection, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyEdge = {
  __typename?: 'PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreListing` at the end of the edge. */
  node: AppStoreListing;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshots: IosScreenshotsConnection;
};


/** A `AppStoreListing` edge in the connection, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotCreatedByAndAppStoreListingIdManyToManyEdgeIosScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** A connection to a list of `AppStoreListing` values, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyConnection = {
  __typename?: 'PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyConnection';
  /** A list of `AppStoreListing` objects. */
  nodes: Array<AppStoreListing>;
  /** A list of edges which contains the `AppStoreListing`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreListing` edge in the connection, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyEdge = {
  __typename?: 'PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreListing` at the end of the edge. */
  node: AppStoreListing;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshots: IosScreenshotsConnection;
};


/** A `AppStoreListing` edge in the connection, with data from `IosScreenshot`. */
export type PersonAppStoreListingsByIosScreenshotUpdatedByAndAppStoreListingIdManyToManyEdgeIosScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** A connection to a list of `AppStoreUser` values, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyConnection = {
  __typename?: 'PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyConnection';
  /** A list of `AppStoreUser` objects. */
  nodes: Array<AppStoreUser>;
  /** A list of edges which contains the `AppStoreUser`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreUser` edge in the connection, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyEdge = {
  __typename?: 'PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreUser` at the end of the edge. */
  node: AppStoreUser;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccounts: AppStoreAccountsConnection;
};


/** A `AppStoreUser` edge in the connection, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountCreatedByAndAppStoreUserIdManyToManyEdgeAppStoreAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** A connection to a list of `AppStoreUser` values, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyConnection = {
  __typename?: 'PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyConnection';
  /** A list of `AppStoreUser` objects. */
  nodes: Array<AppStoreUser>;
  /** A list of edges which contains the `AppStoreUser`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppStoreUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AppStoreUser` edge in the connection, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyEdge = {
  __typename?: 'PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AppStoreUser` at the end of the edge. */
  node: AppStoreUser;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccounts: AppStoreAccountsConnection;
};


/** A `AppStoreUser` edge in the connection, with data from `AppStoreAccount`. */
export type PersonAppStoreUsersByAppStoreAccountUpdatedByAndAppStoreUserIdManyToManyEdgeAppStoreAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** A connection to a list of `App` values, with data from `Build`. */
export type PersonAppsByBuildCreatedByAndAppIdManyToManyConnection = {
  __typename?: 'PersonAppsByBuildCreatedByAndAppIdManyToManyConnection';
  /** A list of `App` objects. */
  nodes: Array<App>;
  /** A list of edges which contains the `App`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonAppsByBuildCreatedByAndAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `App` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `App` edge in the connection, with data from `Build`. */
export type PersonAppsByBuildCreatedByAndAppIdManyToManyEdge = {
  __typename?: 'PersonAppsByBuildCreatedByAndAppIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `App` at the end of the edge. */
  node: App;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `App` edge in the connection, with data from `Build`. */
export type PersonAppsByBuildCreatedByAndAppIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `App` values, with data from `Build`. */
export type PersonAppsByBuildUpdatedByAndAppIdManyToManyConnection = {
  __typename?: 'PersonAppsByBuildUpdatedByAndAppIdManyToManyConnection';
  /** A list of `App` objects. */
  nodes: Array<App>;
  /** A list of edges which contains the `App`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonAppsByBuildUpdatedByAndAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `App` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `App` edge in the connection, with data from `Build`. */
export type PersonAppsByBuildUpdatedByAndAppIdManyToManyEdge = {
  __typename?: 'PersonAppsByBuildUpdatedByAndAppIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `App` at the end of the edge. */
  node: App;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `App` edge in the connection, with data from `Build`. */
export type PersonAppsByBuildUpdatedByAndAppIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Build` values, with data from `BuildStep`. */
export type PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyConnection = {
  __typename?: 'PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyConnection';
  /** A list of `Build` objects. */
  nodes: Array<Build>;
  /** A list of edges which contains the `Build`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Build` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Build` edge in the connection, with data from `BuildStep`. */
export type PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyEdge = {
  __typename?: 'PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Build` at the end of the edge. */
  node: Build;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildSteps: BuildStepsConnection;
};


/** A `Build` edge in the connection, with data from `BuildStep`. */
export type PersonBuildsByBuildStepCreatedByAndBuildIdManyToManyEdgeBuildStepsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** A connection to a list of `Build` values, with data from `BuildStep`. */
export type PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyConnection = {
  __typename?: 'PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyConnection';
  /** A list of `Build` objects. */
  nodes: Array<Build>;
  /** A list of edges which contains the `Build`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Build` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Build` edge in the connection, with data from `BuildStep`. */
export type PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyEdge = {
  __typename?: 'PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Build` at the end of the edge. */
  node: Build;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildSteps: BuildStepsConnection;
};


/** A `Build` edge in the connection, with data from `BuildStep`. */
export type PersonBuildsByBuildStepUpdatedByAndBuildIdManyToManyEdgeBuildStepsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** A connection to a list of `CalendarGroup` values, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyConnection = {
  __typename?: 'PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyConnection';
  /** A list of `CalendarGroup` objects. */
  nodes: Array<CalendarGroup>;
  /** A list of edges which contains the `CalendarGroup`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarGroup` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyEdge = {
  __typename?: 'PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarGroup` at the end of the edge. */
  node: CalendarGroup;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
};


/** A `CalendarGroup` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarCreatedByAndCalendarGroupIdManyToManyEdgeCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `CalendarGroup` values, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyConnection = {
  __typename?: 'PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyConnection';
  /** A list of `CalendarGroup` objects. */
  nodes: Array<CalendarGroup>;
  /** A list of edges which contains the `CalendarGroup`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CalendarGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CalendarGroup` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyEdge = {
  __typename?: 'PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CalendarGroup` at the end of the edge. */
  node: CalendarGroup;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
};


/** A `CalendarGroup` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarGroupsByCalendarGroupCalendarUpdatedByAndCalendarGroupIdManyToManyEdgeCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
};


/** A `Calendar` edge in the connection, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventCreatedByAndCalendarIdManyToManyEdgeCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
};


/** A `Calendar` edge in the connection, with data from `CalendarEvent`. */
export type PersonCalendarsByCalendarEventUpdatedByAndCalendarIdManyToManyEdgeCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
};


/** A `Calendar` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarCreatedByAndCalendarIdManyToManyEdgeCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Calendar` values, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars: CalendarGroupCalendarsConnection;
};


/** A `Calendar` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonCalendarsByCalendarGroupCalendarUpdatedByAndCalendarIdManyToManyEdgeCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Calendar` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarCreatedByAndCalendarIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `Calendar` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyConnection = {
  __typename?: 'PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyConnection';
  /** A list of `Calendar` objects. */
  nodes: Array<Calendar>;
  /** A list of edges which contains the `Calendar`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Calendar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyEdge = {
  __typename?: 'PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Calendar` at the end of the edge. */
  node: Calendar;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `Calendar` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonCalendarsByGoogleCalendarSyncCalendarUpdatedByAndCalendarIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A condition to be used against `Person` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PersonCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userAccountId` field. */
  userAccountId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['Email']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bio` field. */
  bio?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `profileImageFileId` field. */
  profileImageFileId?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `person` in the `PersonInput` mutation. */
export type PersonCreatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnPersonForPersonCreatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<PersonOnPersonForPersonCreatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<PersonCreatedByFkeyPersonCreateInput>;
};

/** The `person` to be created by this mutation. */
export type PersonCreatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Division` values, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyConnection = {
  __typename?: 'PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyConnection';
  /** A list of `Division` objects. */
  nodes: Array<Division>;
  /** A list of edges which contains the `Division`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Division` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Division` edge in the connection, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyEdge = {
  __typename?: 'PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Division` at the end of the edge. */
  node: Division;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategories: GroupCategoriesConnection;
};


/** A `Division` edge in the connection, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryCreatedByAndDivisionIdManyToManyEdgeGroupCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** A connection to a list of `Division` values, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyConnection = {
  __typename?: 'PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyConnection';
  /** A list of `Division` objects. */
  nodes: Array<Division>;
  /** A list of edges which contains the `Division`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Division` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Division` edge in the connection, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyEdge = {
  __typename?: 'PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Division` at the end of the edge. */
  node: Division;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategories: GroupCategoriesConnection;
};


/** A `Division` edge in the connection, with data from `GroupCategory`. */
export type PersonDivisionsByGroupCategoryUpdatedByAndDivisionIdManyToManyEdgeGroupCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** A connection to a list of `Event` values, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventCreatedByAndEventIdManyToManyConnection = {
  __typename?: 'PersonEventsByCalendarEventCreatedByAndEventIdManyToManyConnection';
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonEventsByCalendarEventCreatedByAndEventIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventCreatedByAndEventIdManyToManyEdge = {
  __typename?: 'PersonEventsByCalendarEventCreatedByAndEventIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
};


/** A `Event` edge in the connection, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventCreatedByAndEventIdManyToManyEdgeCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Event` values, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyConnection = {
  __typename?: 'PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyConnection';
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Event` edge in the connection, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyEdge = {
  __typename?: 'PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents: CalendarEventsConnection;
};


/** A `Event` edge in the connection, with data from `CalendarEvent`. */
export type PersonEventsByCalendarEventUpdatedByAndEventIdManyToManyEdgeCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `File` values, with data from `Event`. */
export type PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventCoverImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventCreatedByAndCoverImageFileIdManyToManyEdgeEventCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Event`. */
export type PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventThumbnailImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventCreatedByAndThumbnailImageFileIdManyToManyEdgeEventThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventCoverImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndCoverImageFileIdManyToManyEdgeEventCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Event`. */
  eventThumbnailImages: EventsConnection;
};


/** A `File` edge in the connection, with data from `Event`. */
export type PersonFilesByEventUpdatedByAndThumbnailImageFileIdManyToManyEdgeEventThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `File` values, with data from `Marquee`. */
export type PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Marquee`. */
export type PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueeImages: MarqueesConnection;
};


/** A `File` edge in the connection, with data from `Marquee`. */
export type PersonFilesByMarqueeCreatedByAndMarqueeImageFileIdManyToManyEdgeMarqueeImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `File` values, with data from `Marquee`. */
export type PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Marquee`. */
export type PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueeImages: MarqueesConnection;
};


/** A `File` edge in the connection, with data from `Marquee`. */
export type PersonFilesByMarqueeUpdatedByAndMarqueeImageFileIdManyToManyEdgeMarqueeImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `File` values, with data from `Newsletter`. */
export type PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Newsletter`. */
export type PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletterCoverImages: NewslettersConnection;
};


/** A `File` edge in the connection, with data from `Newsletter`. */
export type PersonFilesByNewsletterCreatedByAndCoverImageFileIdManyToManyEdgeNewsletterCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `File` values, with data from `Newsletter`. */
export type PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Newsletter`. */
export type PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletterCoverImages: NewslettersConnection;
};


/** A `File` edge in the connection, with data from `Newsletter`. */
export type PersonFilesByNewsletterUpdatedByAndCoverImageFileIdManyToManyEdgeNewsletterCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `File` values, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileCreatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNotificationFileCreatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNotificationFileCreatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileCreatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNotificationFileCreatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
};


/** A `File` edge in the connection, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileCreatedByAndFileIdManyToManyEdgeNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `File` values, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
};


/** A `File` edge in the connection, with data from `NotificationFile`. */
export type PersonFilesByNotificationFileUpdatedByAndFileIdManyToManyEdgeNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `File` values, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
};


/** A `File` edge in the connection, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileCreatedByAndFileIdManyToManyEdgePageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `File` values, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
};


/** A `File` edge in the connection, with data from `PageSectionFile`. */
export type PersonFilesByPageSectionFileUpdatedByAndFileIdManyToManyEdgePageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `File` values, with data from `Person`. */
export type PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Person`. */
export type PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Person`. */
  personProfileImages: PeopleConnection;
};


/** A `File` edge in the connection, with data from `Person`. */
export type PersonFilesByPersonCreatedByAndProfileImageFileIdManyToManyEdgePersonProfileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `File` values, with data from `Person`. */
export type PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Person`. */
export type PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Person`. */
  personProfileImages: PeopleConnection;
};


/** A `File` edge in the connection, with data from `Person`. */
export type PersonFilesByPersonUpdatedByAndProfileImageFileIdManyToManyEdgePersonProfileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceCreatedByAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `ResourceFile`. */
export type PersonFilesByResourceFileCreatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceFileCreatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceFileCreatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `ResourceFile`. */
export type PersonFilesByResourceFileCreatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceFileCreatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
};


/** A `File` edge in the connection, with data from `ResourceFile`. */
export type PersonFilesByResourceFileCreatedByAndFileIdManyToManyEdgeResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `File` values, with data from `ResourceFile`. */
export type PersonFilesByResourceFileUpdatedByAndFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceFileUpdatedByAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceFileUpdatedByAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `ResourceFile`. */
export type PersonFilesByResourceFileUpdatedByAndFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceFileUpdatedByAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
};


/** A `File` edge in the connection, with data from `ResourceFile`. */
export type PersonFilesByResourceFileUpdatedByAndFileIdManyToManyEdgeResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type PersonFilesByResourceUpdatedByAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingCreatedByAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsCoverImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndCoverImageFileIdManyToManyEdgeSchoolSettingsCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLaunchScreenPoweredByImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLaunchScreenPoweredByImageIdManyToManyEdgeSchoolSettingsLaunchScreenPoweredByImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsLogoImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndLogoImageFileIdManyToManyEdgeSchoolSettingsLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsMascotImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndMascotImageFileIdManyToManyEdgeSchoolSettingsMascotImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsSchoolImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndSchoolImageFileIdManyToManyEdgeSchoolSettingsSchoolImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsThumbnailImages: SchoolSettingsConnection;
};


/** A `File` edge in the connection, with data from `SchoolSetting`. */
export type PersonFilesBySchoolSettingUpdatedByAndThumbnailImageFileIdManyToManyEdgeSchoolSettingsThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorCreatedByAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type PersonFilesBySponsorUpdatedByAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A filter to be used against `Person` object types. All fields are combined with a logical ‘and.’ */
export type PersonFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `userAccountId` field. */
  userAccountId?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<EmailFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: Maybe<StringFilter>;
  /** Filter by the object’s `bio` field. */
  bio?: Maybe<StringFilter>;
  /** Filter by the object’s `profileImageFileId` field. */
  profileImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `appAdministrator` field. */
  appAdministrator?: Maybe<BooleanFilter>;
  /** Filter by the object’s `schoolSlugs` field. */
  schoolSlugs?: Maybe<StringListFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PersonFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PersonFilter>>;
  /** Negates the expression. */
  not?: Maybe<PersonFilter>;
};

/** A connection to a list of `GoogleAccount` values, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyConnection = {
  __typename?: 'PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyConnection';
  /** A list of `GoogleAccount` objects. */
  nodes: Array<GoogleAccount>;
  /** A list of edges which contains the `GoogleAccount`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccount` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyEdge = {
  __typename?: 'PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccount` at the end of the edge. */
  node: GoogleAccount;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncs: GoogleCalendarSyncsConnection;
};


/** A `GoogleAccount` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncCreatedByAndGoogleAccountIdManyToManyEdgeGoogleCalendarSyncsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `GoogleAccount` values, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyConnection = {
  __typename?: 'PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyConnection';
  /** A list of `GoogleAccount` objects. */
  nodes: Array<GoogleAccount>;
  /** A list of edges which contains the `GoogleAccount`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccount` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyEdge = {
  __typename?: 'PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccount` at the end of the edge. */
  node: GoogleAccount;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncs: GoogleCalendarSyncsConnection;
};


/** A `GoogleAccount` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonGoogleAccountsByGoogleCalendarSyncUpdatedByAndGoogleAccountIdManyToManyEdgeGoogleCalendarSyncsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `GoogleAccount` values, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyConnection = {
  __typename?: 'PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyConnection';
  /** A list of `GoogleAccount` objects. */
  nodes: Array<GoogleAccount>;
  /** A list of edges which contains the `GoogleAccount`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccount` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyEdge = {
  __typename?: 'PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccount` at the end of the edge. */
  node: GoogleAccount;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccounts: UserGoogleAccountsConnection;
};


/** A `GoogleAccount` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountCreatedByAndGoogleAccountIdManyToManyEdgeUserGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** A connection to a list of `GoogleAccount` values, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyConnection = {
  __typename?: 'PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyConnection';
  /** A list of `GoogleAccount` objects. */
  nodes: Array<GoogleAccount>;
  /** A list of edges which contains the `GoogleAccount`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccount` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyEdge = {
  __typename?: 'PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccount` at the end of the edge. */
  node: GoogleAccount;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccounts: UserGoogleAccountsConnection;
};


/** A `GoogleAccount` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonGoogleAccountsByUserGoogleAccountUpdatedByAndGoogleAccountIdManyToManyEdgeUserGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** A connection to a list of `GoogleCalendarSync` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyConnection = {
  __typename?: 'PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyConnection';
  /** A list of `GoogleCalendarSync` objects. */
  nodes: Array<GoogleCalendarSync>;
  /** A list of edges which contains the `GoogleCalendarSync`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarSync` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyEdge = {
  __typename?: 'PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarSync` at the end of the edge. */
  node: GoogleCalendarSync;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarCreatedByAndGoogleCalendarSyncIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `GoogleCalendarSync` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyConnection = {
  __typename?: 'PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyConnection';
  /** A list of `GoogleCalendarSync` objects. */
  nodes: Array<GoogleCalendarSync>;
  /** A list of edges which contains the `GoogleCalendarSync`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarSync` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyEdge = {
  __typename?: 'PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarSync` at the end of the edge. */
  node: GoogleCalendarSync;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars: GoogleCalendarSyncCalendarsConnection;
};


/** A `GoogleCalendarSync` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonGoogleCalendarSyncsByGoogleCalendarSyncCalendarUpdatedByAndGoogleCalendarSyncIdManyToManyEdgeGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `GooglePlayAccount` values, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyConnection';
  /** A list of `GooglePlayAccount` objects. */
  nodes: Array<GooglePlayAccount>;
  /** A list of edges which contains the `GooglePlayAccount`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayAccount` edge in the connection, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayAccount` at the end of the edge. */
  node: GooglePlayAccount;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListings: GooglePlayListingsConnection;
};


/** A `GooglePlayAccount` edge in the connection, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingCreatedByAndGooglePlayAccountIdManyToManyEdgeGooglePlayListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** A connection to a list of `GooglePlayAccount` values, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyConnection';
  /** A list of `GooglePlayAccount` objects. */
  nodes: Array<GooglePlayAccount>;
  /** A list of edges which contains the `GooglePlayAccount`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayAccount` edge in the connection, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayAccount` at the end of the edge. */
  node: GooglePlayAccount;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListings: GooglePlayListingsConnection;
};


/** A `GooglePlayAccount` edge in the connection, with data from `GooglePlayListing`. */
export type PersonGooglePlayAccountsByGooglePlayListingUpdatedByAndGooglePlayAccountIdManyToManyEdgeGooglePlayListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** A connection to a list of `GooglePlayListing` values, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyConnection';
  /** A list of `GooglePlayListing` objects. */
  nodes: Array<GooglePlayListing>;
  /** A list of edges which contains the `GooglePlayListing`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayListing` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayListing` at the end of the edge. */
  node: GooglePlayListing;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshots: AndroidScreenshotsConnection;
};


/** A `GooglePlayListing` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotCreatedByAndGooglePlayListingIdManyToManyEdgeAndroidScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** A connection to a list of `GooglePlayListing` values, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyConnection';
  /** A list of `GooglePlayListing` objects. */
  nodes: Array<GooglePlayListing>;
  /** A list of edges which contains the `GooglePlayListing`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayListing` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayListing` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayListing` at the end of the edge. */
  node: GooglePlayListing;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshots: AndroidScreenshotsConnection;
};


/** A `GooglePlayListing` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonGooglePlayListingsByAndroidScreenshotUpdatedByAndGooglePlayListingIdManyToManyEdgeAndroidScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** A connection to a list of `GooglePlayUser` values, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyConnection';
  /** A list of `GooglePlayUser` objects. */
  nodes: Array<GooglePlayUser>;
  /** A list of edges which contains the `GooglePlayUser`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayUser` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayUser` at the end of the edge. */
  node: GooglePlayUser;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccounts: GooglePlayAccountsConnection;
};


/** A `GooglePlayUser` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountCreatedByAndGooglePlayUserIdManyToManyEdgeGooglePlayAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** A connection to a list of `GooglePlayUser` values, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyConnection = {
  __typename?: 'PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyConnection';
  /** A list of `GooglePlayUser` objects. */
  nodes: Array<GooglePlayUser>;
  /** A list of edges which contains the `GooglePlayUser`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GooglePlayUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GooglePlayUser` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyEdge = {
  __typename?: 'PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GooglePlayUser` at the end of the edge. */
  node: GooglePlayUser;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccounts: GooglePlayAccountsConnection;
};


/** A `GooglePlayUser` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonGooglePlayUsersByGooglePlayAccountUpdatedByAndGooglePlayUserIdManyToManyEdgeGooglePlayAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** A connection to a list of `GroupCategory` values, with data from `Group`. */
export type PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyConnection = {
  __typename?: 'PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyConnection';
  /** A list of `GroupCategory` objects. */
  nodes: Array<GroupCategory>;
  /** A list of edges which contains the `GroupCategory`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GroupCategory` edge in the connection, with data from `Group`. */
export type PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyEdge = {
  __typename?: 'PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupCategory` at the end of the edge. */
  node: GroupCategory;
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
};


/** A `GroupCategory` edge in the connection, with data from `Group`. */
export type PersonGroupCategoriesByGroupCreatedByAndGroupCategoryIdManyToManyEdgeGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** A connection to a list of `GroupCategory` values, with data from `Group`. */
export type PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyConnection = {
  __typename?: 'PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyConnection';
  /** A list of `GroupCategory` objects. */
  nodes: Array<GroupCategory>;
  /** A list of edges which contains the `GroupCategory`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GroupCategory` edge in the connection, with data from `Group`. */
export type PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyEdge = {
  __typename?: 'PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupCategory` at the end of the edge. */
  node: GroupCategory;
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
};


/** A `GroupCategory` edge in the connection, with data from `Group`. */
export type PersonGroupCategoriesByGroupUpdatedByAndGroupCategoryIdManyToManyEdgeGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** A connection to a list of `Group` values, with data from `Calendar`. */
export type PersonGroupsByCalendarCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByCalendarCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByCalendarCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Calendar`. */
export type PersonGroupsByCalendarCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByCalendarCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendars: CalendarsConnection;
};


/** A `Group` edge in the connection, with data from `Calendar`. */
export type PersonGroupsByCalendarCreatedByAndGroupIdManyToManyEdgeCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Group` values, with data from `Calendar`. */
export type PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Calendar`. */
export type PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendars: CalendarsConnection;
};


/** A `Group` edge in the connection, with data from `Calendar`. */
export type PersonGroupsByCalendarUpdatedByAndGroupIdManyToManyEdgeCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Group` values, with data from `Division`. */
export type PersonGroupsByDivisionCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByDivisionCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByDivisionCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Division`. */
export type PersonGroupsByDivisionCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByDivisionCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `Group` edge in the connection, with data from `Division`. */
export type PersonGroupsByDivisionCreatedByAndGroupIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Group` values, with data from `Division`. */
export type PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Division`. */
export type PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `Group` edge in the connection, with data from `Division`. */
export type PersonGroupsByDivisionUpdatedByAndGroupIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Group` values, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
};


/** A `Group` edge in the connection, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterCreatedByAndGroupIdManyToManyEdgeGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Group` values, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
};


/** A `Group` edge in the connection, with data from `GroupNewsletter`. */
export type PersonGroupsByGroupNewsletterUpdatedByAndGroupIdManyToManyEdgeGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Group` values, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
};


/** A `Group` edge in the connection, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonCreatedByAndGroupIdManyToManyEdgeGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Group` values, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByGroupPersonPersonIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member: Scalars['Boolean'];
  admin: Scalars['Boolean'];
};

/** A connection to a list of `Group` values, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
};


/** A `Group` edge in the connection, with data from `GroupPerson`. */
export type PersonGroupsByGroupPersonUpdatedByAndGroupIdManyToManyEdgeGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Group` values, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
};


/** A `Group` edge in the connection, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupCreatedByAndGroupIdManyToManyEdgeNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Group` values, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
};


/** A `Group` edge in the connection, with data from `NotificationGroup`. */
export type PersonGroupsByNotificationGroupUpdatedByAndGroupIdManyToManyEdgeNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type PersonGroupsByResourceCreatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByResourceCreatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByResourceCreatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type PersonGroupsByResourceCreatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByResourceCreatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type PersonGroupsByResourceCreatedByAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type PersonGroupsByResourceUpdatedByAndGroupIdManyToManyConnection = {
  __typename?: 'PersonGroupsByResourceUpdatedByAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonGroupsByResourceUpdatedByAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type PersonGroupsByResourceUpdatedByAndGroupIdManyToManyEdge = {
  __typename?: 'PersonGroupsByResourceUpdatedByAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type PersonGroupsByResourceUpdatedByAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** An input for mutations affecting `Person` */
export type PersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Newsletter` values, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyConnection = {
  __typename?: 'PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyEdge = {
  __typename?: 'PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
};


/** A `Newsletter` edge in the connection, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterCreatedByAndNewsletterIdManyToManyEdgeGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Newsletter` values, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyConnection = {
  __typename?: 'PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyEdge = {
  __typename?: 'PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters: GroupNewslettersConnection;
};


/** A `Newsletter` edge in the connection, with data from `GroupNewsletter`. */
export type PersonNewslettersByGroupNewsletterUpdatedByAndNewsletterIdManyToManyEdgeGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Newsletter` values, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyConnection = {
  __typename?: 'PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyEdge = {
  __typename?: 'PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
};


/** A `Newsletter` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterCreatedByAndNewsletterIdManyToManyEdgeNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Newsletter` values, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyConnection = {
  __typename?: 'PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyConnection';
  /** A list of `Newsletter` objects. */
  nodes: Array<Newsletter>;
  /** A list of edges which contains the `Newsletter`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Newsletter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Newsletter` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyEdge = {
  __typename?: 'PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Newsletter` at the end of the edge. */
  node: Newsletter;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
};


/** A `Newsletter` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNewslettersByNotificationNewsletterUpdatedByAndNewsletterIdManyToManyEdgeNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PersonNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type PersonNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `person` to be deleted. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `Notification` values, with data from `Notification`. */
export type PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `Notification`. */
export type PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByRecurringParent: NotificationsConnection;
};


/** A `Notification` edge in the connection, with data from `Notification`. */
export type PersonNotificationsByNotificationCreatedByAndRecurringParentManyToManyEdgeNotificationsByRecurringParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileCreatedByAndNotificationIdManyToManyEdgeNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles: NotificationFilesConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationFile`. */
export type PersonNotificationsByNotificationFileUpdatedByAndNotificationIdManyToManyEdgeNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupCreatedByAndNotificationIdManyToManyEdgeNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups: NotificationGroupsConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationGroup`. */
export type PersonNotificationsByNotificationGroupUpdatedByAndNotificationIdManyToManyEdgeNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterCreatedByAndNotificationIdManyToManyEdgeNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Notification` values, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters: NotificationNewslettersConnection;
};


/** A `Notification` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonNotificationsByNotificationNewsletterUpdatedByAndNotificationIdManyToManyEdgeNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Notification` values, with data from `Notification`. */
export type PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyConnection = {
  __typename?: 'PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyConnection';
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** A list of edges which contains the `Notification`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Notification` edge in the connection, with data from `Notification`. */
export type PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyEdge = {
  __typename?: 'PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByRecurringParent: NotificationsConnection;
};


/** A `Notification` edge in the connection, with data from `Notification`. */
export type PersonNotificationsByNotificationUpdatedByAndRecurringParentManyToManyEdgeNotificationsByRecurringParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidAppForAndroidAppCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: AndroidAppPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidAppForAndroidAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidApp` being updated. */
  patch: AndroidAppPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAndroidAppForAndroidAppUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAndroidAppForAndroidAppUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidJavaKeyStore` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: AndroidJavaKeyStorePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidJavaKeyStore` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
  patch: AndroidJavaKeyStorePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: AndroidScreenshotPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `androidScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `androidScreenshot` being updated. */
  patch: AndroidScreenshotPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppForAppCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppForAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAppForAppUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAppForAppUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreAccountForAppStoreAccountCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreListingForAppStoreListingCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreListingForAppStoreListingUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAppStoreListingForAppStoreListingUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAppStoreListingForAppStoreListingUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreUserForAppStoreUserCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: AppStoreUserPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnAppStoreUserForAppStoreUserUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appStoreUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appStoreUser` being updated. */
  patch: AppStoreUserPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnAppStoreUserForAppStoreUserUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnAppStoreUserForAppStoreUserUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnBuildForBuildCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: BuildPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnBuildForBuildUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: BuildPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnBuildForBuildUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnBuildForBuildUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnBuildStepForBuildStepCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `buildStep` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: BuildStepPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnBuildStepForBuildStepUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `buildStep` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `buildStep` being updated. */
  patch: BuildStepPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnBuildStepForBuildStepUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnBuildStepForBuildStepUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarEventForCalendarEventCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: CalendarEventPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarEventForCalendarEventUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarEvent` being updated. */
  patch: CalendarEventPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnCalendarEventForCalendarEventUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnCalendarEventForCalendarEventUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarForCalendarCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarForCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendar` being updated. */
  patch: CalendarPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnCalendarForCalendarUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnCalendarForCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroupCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarGroupForCalendarGroupCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: CalendarGroupPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnCalendarGroupForCalendarGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: CalendarGroupPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnCalendarGroupForCalendarGroupUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnCalendarGroupForCalendarGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnDivisionForDivisionCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: DivisionPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnDivisionForDivisionUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: DivisionPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnDivisionForDivisionUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnDivisionForDivisionUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnEventForEventCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnEventForEventUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnEventForEventUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnEventForEventUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnFileForFileCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnFileForFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnFileForFileUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnFileForFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleAccountForGoogleAccountCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: GoogleAccountPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleAccountForGoogleAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: GoogleAccountPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGoogleAccountForGoogleAccountUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGoogleAccountForGoogleAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSyncCalendar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarSync` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayListingForGooglePlayListingCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayUserForGooglePlayUserCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: GooglePlayUserPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googlePlayUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googlePlayUser` being updated. */
  patch: GooglePlayUserPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupCategoryForGroupCategoryCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: GroupCategoryPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupCategoryForGroupCategoryUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupCategory` being updated. */
  patch: GroupCategoryPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGroupCategoryForGroupCategoryUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGroupCategoryForGroupCategoryUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupForGroupCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupForGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGroupForGroupUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGroupForGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupNewsletterForGroupNewsletterCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupPersonForGroupPersonCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: GroupPersonPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupPersonForGroupPersonPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: GroupPersonPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnGroupPersonForGroupPersonUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `groupPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `groupPerson` being updated. */
  patch: GroupPersonPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnGroupPersonForGroupPersonUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnGroupPersonForGroupPersonUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnIosAppForIosAppCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: IosAppPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnIosAppForIosAppUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosApp` being updated. */
  patch: IosAppPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnIosAppForIosAppUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnIosAppForIosAppUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnIosScreenshotForIosScreenshotCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: IosScreenshotPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnIosScreenshotForIosScreenshotUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `iosScreenshot` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `iosScreenshot` being updated. */
  patch: IosScreenshotPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnIosScreenshotForIosScreenshotUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnIosScreenshotForIosScreenshotUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnMarqueeForMarqueeCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `marquee` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: MarqueePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnMarqueeForMarqueeUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `marquee` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: MarqueePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnMarqueeForMarqueeUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnMarqueeForMarqueeUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNewsletterForNewsletterCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNewsletterForNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `newsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `newsletter` being updated. */
  patch: NewsletterPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnNewsletterForNewsletterUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnNewsletterForNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationFileForNotificationFileCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: NotificationFilePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationFileForNotificationFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationFile` being updated. */
  patch: NotificationFilePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnNotificationFileForNotificationFileUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnNotificationFileForNotificationFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationForNotificationCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationForNotificationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notification` being updated. */
  patch: NotificationPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnNotificationForNotificationUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnNotificationForNotificationUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationGroupForNotificationGroupCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: NotificationGroupPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationGroupForNotificationGroupUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationGroup` being updated. */
  patch: NotificationGroupPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnNotificationGroupForNotificationGroupUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnNotificationGroupForNotificationGroupUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `notificationNewsletter` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageForPageCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `page` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: PagePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageForPageUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `page` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `page` being updated. */
  patch: PagePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPageForPageUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPageForPageUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageSectionFileForPageSectionFileCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: PageSectionFilePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageSectionFileForPageSectionFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSectionFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSectionFile` being updated. */
  patch: PageSectionFilePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPageSectionFileForPageSectionFileUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPageSectionFileForPageSectionFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageSectionForPageSectionCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: PageSectionPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPageSectionForPageSectionUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `pageSection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `pageSection` being updated. */
  patch: PageSectionPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPageSectionForPageSectionUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPageSectionForPageSectionUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPersonForPersonCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPersonForPersonCreatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPersonForPersonCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPersonForPersonUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPersonForPersonUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPersonForPersonUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnPersonForProfileImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnPersonForProfileImageFileIdFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnPersonForProfileImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnReleaseForReleaseCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `release` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: ReleasePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnReleaseForReleaseUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `release` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: ReleasePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnReleaseForReleaseUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnReleaseForReleaseUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnResourceFileForResourceFileCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: ResourceFilePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnResourceFileForResourceFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: ResourceFilePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnResourceFileForResourceFileUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnResourceFileForResourceFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnResourceForResourceCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnResourceForResourceUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnResourceForResourceUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnResourceForResourceUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolForSchoolCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolForSchoolUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSchoolForSchoolUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSchoolForSchoolUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonForSchoolPersonCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonForSchoolPersonPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonForSchoolPersonUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSchoolPersonForSchoolPersonUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInstallation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: SchoolPersonInstallationPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInstallation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: SchoolPersonInstallationPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInvitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInvitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolSettingForSchoolSettingsCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSponsorForSponsorCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnSponsorForSponsorUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnSponsorForSponsorUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnSponsorForSponsorUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnThemeForThemeCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `theme` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: ThemePatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnThemeForThemeUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `theme` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: ThemePatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnThemeForThemeUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnThemeForThemeUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userGoogleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userGoogleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
};

/** The fields on `person` to look up the row to update. */
export type PersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: UpdatePersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** A connection to a list of `PageSection` values, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyConnection = {
  __typename?: 'PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyConnection';
  /** A list of `PageSection` objects. */
  nodes: Array<PageSection>;
  /** A list of edges which contains the `PageSection`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PageSection` edge in the connection, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyEdge = {
  __typename?: 'PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageSection` at the end of the edge. */
  node: PageSection;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
};


/** A `PageSection` edge in the connection, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileCreatedByAndPageSectionIdManyToManyEdgePageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `PageSection` values, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyConnection = {
  __typename?: 'PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyConnection';
  /** A list of `PageSection` objects. */
  nodes: Array<PageSection>;
  /** A list of edges which contains the `PageSection`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageSection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PageSection` edge in the connection, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyEdge = {
  __typename?: 'PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageSection` at the end of the edge. */
  node: PageSection;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles: PageSectionFilesConnection;
};


/** A `PageSection` edge in the connection, with data from `PageSectionFile`. */
export type PersonPageSectionsByPageSectionFileUpdatedByAndPageSectionIdManyToManyEdgePageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Page` values, with data from `Newsletter`. */
export type PersonPagesByNewsletterCreatedByAndPageIdManyToManyConnection = {
  __typename?: 'PersonPagesByNewsletterCreatedByAndPageIdManyToManyConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPagesByNewsletterCreatedByAndPageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection, with data from `Newsletter`. */
export type PersonPagesByNewsletterCreatedByAndPageIdManyToManyEdge = {
  __typename?: 'PersonPagesByNewsletterCreatedByAndPageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters: NewslettersConnection;
};


/** A `Page` edge in the connection, with data from `Newsletter`. */
export type PersonPagesByNewsletterCreatedByAndPageIdManyToManyEdgeNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Page` values, with data from `Newsletter`. */
export type PersonPagesByNewsletterUpdatedByAndPageIdManyToManyConnection = {
  __typename?: 'PersonPagesByNewsletterUpdatedByAndPageIdManyToManyConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPagesByNewsletterUpdatedByAndPageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection, with data from `Newsletter`. */
export type PersonPagesByNewsletterUpdatedByAndPageIdManyToManyEdge = {
  __typename?: 'PersonPagesByNewsletterUpdatedByAndPageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters: NewslettersConnection;
};


/** A `Page` edge in the connection, with data from `Newsletter`. */
export type PersonPagesByNewsletterUpdatedByAndPageIdManyToManyEdgeNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Page` values, with data from `PageSection`. */
export type PersonPagesByPageSectionCreatedByAndPageIdManyToManyConnection = {
  __typename?: 'PersonPagesByPageSectionCreatedByAndPageIdManyToManyConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PersonPagesByPageSectionCreatedByAndPageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection, with data from `PageSection`. */
export type PersonPagesByPageSectionCreatedByAndPageIdManyToManyEdge = {
  __typename?: 'PersonPagesByPageSectionCreatedByAndPageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSections: PageSectionsConnection;
};


/** A `Page` edge in the connection, with data from `PageSection`. */
export type PersonPagesByPageSectionCreatedByAndPageIdManyToManyEdgePageSectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** A connection to a list of `Page` values, with data from `PageSection`. */
export type PersonPagesByPageSectionUpdatedByAndPageIdManyToManyConnection = {
  __typename?: 'PersonPagesByPageSectionUpdatedByAndPageIdManyToManyConnection';
  /** A list of `Page` objects. */
  nodes: Array<Page>;
  /** A list of edges which contains the `Page`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PersonPagesByPageSectionUpdatedByAndPageIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Page` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Page` edge in the connection, with data from `PageSection`. */
export type PersonPagesByPageSectionUpdatedByAndPageIdManyToManyEdge = {
  __typename?: 'PersonPagesByPageSectionUpdatedByAndPageIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Page` at the end of the edge. */
  node: Page;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSections: PageSectionsConnection;
};


/** A `Page` edge in the connection, with data from `PageSection`. */
export type PersonPagesByPageSectionUpdatedByAndPageIdManyToManyEdgePageSectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** Represents an update to a `Person`. Fields that are set will be updated. */
export type PersonPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidApp`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByUpdatedBy: AndroidAppsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppCreatedByAndUpdatedByManyToManyEdgeAndroidAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidApp`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidAppsByCreatedBy: AndroidAppsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidApp`. */
export type PersonPeopleByAndroidAppUpdatedByAndCreatedByManyToManyEdgeAndroidAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByUpdatedBy: AndroidJavaKeyStoresConnection;
};


/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreCreatedByAndUpdatedByManyToManyEdgeAndroidJavaKeyStoresByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidJavaKeyStore`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStoresByCreatedBy: AndroidJavaKeyStoresConnection;
};


/** A `Person` edge in the connection, with data from `AndroidJavaKeyStore`. */
export type PersonPeopleByAndroidJavaKeyStoreUpdatedByAndCreatedByManyToManyEdgeAndroidJavaKeyStoresByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByUpdatedBy: AndroidScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotCreatedByAndUpdatedByManyToManyEdgeAndroidScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AndroidScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshotsByCreatedBy: AndroidScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `AndroidScreenshot`. */
export type PersonPeopleByAndroidScreenshotUpdatedByAndCreatedByManyToManyEdgeAndroidScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `App`. */
export type PersonPeopleByAppCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `App`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `App`. */
export type PersonPeopleByAppCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `App`. */
  appsByUpdatedBy: AppsConnection;
};


/** A `Person` edge in the connection, with data from `App`. */
export type PersonPeopleByAppCreatedByAndUpdatedByManyToManyEdgeAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByUpdatedBy: AppStoreAccountsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountCreatedByAndUpdatedByManyToManyEdgeAppStoreAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccountsByCreatedBy: AppStoreAccountsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreAccount`. */
export type PersonPeopleByAppStoreAccountUpdatedByAndCreatedByManyToManyEdgeAppStoreAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByUpdatedBy: AppStoreListingsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingCreatedByAndUpdatedByManyToManyEdgeAppStoreListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreListing`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListingsByCreatedBy: AppStoreListingsConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreListing`. */
export type PersonPeopleByAppStoreListingUpdatedByAndCreatedByManyToManyEdgeAppStoreListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreUser`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByUpdatedBy: AppStoreUsersConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserCreatedByAndUpdatedByManyToManyEdgeAppStoreUsersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};

/** A connection to a list of `Person` values, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `AppStoreUser`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsersByCreatedBy: AppStoreUsersConnection;
};


/** A `Person` edge in the connection, with data from `AppStoreUser`. */
export type PersonPeopleByAppStoreUserUpdatedByAndCreatedByManyToManyEdgeAppStoreUsersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};

/** A connection to a list of `Person` values, with data from `App`. */
export type PersonPeopleByAppUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByAppUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `App`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByAppUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `App`. */
export type PersonPeopleByAppUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByAppUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `App`. */
  appsByCreatedBy: AppsConnection;
};


/** A `Person` edge in the connection, with data from `App`. */
export type PersonPeopleByAppUpdatedByAndCreatedByManyToManyEdgeAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type PersonPeopleByBuildCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByBuildCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByBuildCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type PersonPeopleByBuildCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByBuildCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByUpdatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type PersonPeopleByBuildCreatedByAndUpdatedByManyToManyEdgeBuildsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Person` values, with data from `BuildStep`. */
export type PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `BuildStep`. */
export type PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByUpdatedBy: BuildStepsConnection;
};


/** A `Person` edge in the connection, with data from `BuildStep`. */
export type PersonPeopleByBuildStepCreatedByAndUpdatedByManyToManyEdgeBuildStepsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** A connection to a list of `Person` values, with data from `BuildStep`. */
export type PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `BuildStep`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `BuildStep`. */
export type PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildStepsByCreatedBy: BuildStepsConnection;
};


/** A `Person` edge in the connection, with data from `BuildStep`. */
export type PersonPeopleByBuildStepUpdatedByAndCreatedByManyToManyEdgeBuildStepsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type PersonPeopleByBuildUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByBuildUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByBuildUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type PersonPeopleByBuildUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByBuildUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByCreatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type PersonPeopleByBuildUpdatedByAndCreatedByManyToManyEdgeBuildsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Person` values, with data from `Calendar`. */
export type PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Calendar`. */
export type PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByUpdatedBy: CalendarsConnection;
};


/** A `Person` edge in the connection, with data from `Calendar`. */
export type PersonPeopleByCalendarCreatedByAndUpdatedByManyToManyEdgeCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByUpdatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventCreatedByAndUpdatedByManyToManyEdgeCalendarEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarEvent`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEventsByCreatedBy: CalendarEventsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarEvent`. */
export type PersonPeopleByCalendarEventUpdatedByAndCreatedByManyToManyEdgeCalendarEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByUpdatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarCreatedByAndUpdatedByManyToManyEdgeCalendarGroupCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroupCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendarsByCreatedBy: CalendarGroupCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroupCalendar`. */
export type PersonPeopleByCalendarGroupCalendarUpdatedByAndCreatedByManyToManyEdgeCalendarGroupCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByUpdatedBy: CalendarGroupsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupCreatedByAndUpdatedByManyToManyEdgeCalendarGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCreatedBy: CalendarGroupsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type PersonPeopleByCalendarGroupUpdatedByAndCreatedByManyToManyEdgeCalendarGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `Calendar`. */
export type PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Calendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Calendar`. */
export type PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendarsByCreatedBy: CalendarsConnection;
};


/** A `Person` edge in the connection, with data from `Calendar`. */
export type PersonPeopleByCalendarUpdatedByAndCreatedByManyToManyEdgeCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByUpdatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type PersonPeopleByDivisionCreatedByAndUpdatedByManyToManyEdgeDivisionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByCreatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type PersonPeopleByDivisionUpdatedByAndCreatedByManyToManyEdgeDivisionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type PersonPeopleByEventCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByEventCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByEventCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type PersonPeopleByEventCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByEventCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByUpdatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type PersonPeopleByEventCreatedByAndUpdatedByManyToManyEdgeEventsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `Event`. */
export type PersonPeopleByEventUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByEventUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByEventUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Event`. */
export type PersonPeopleByEventUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByEventUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByCreatedBy: EventsConnection;
};


/** A `Person` edge in the connection, with data from `Event`. */
export type PersonPeopleByEventUpdatedByAndCreatedByManyToManyEdgeEventsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};

/** A connection to a list of `Person` values, with data from `File`. */
export type PersonPeopleByFileCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByFileCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByFileCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `File`. */
export type PersonPeopleByFileCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByFileCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `File`. */
  filesByUpdatedBy: FilesConnection;
};


/** A `Person` edge in the connection, with data from `File`. */
export type PersonPeopleByFileCreatedByAndUpdatedByManyToManyEdgeFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};

/** A connection to a list of `Person` values, with data from `File`. */
export type PersonPeopleByFileUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByFileUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByFileUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `File`. */
export type PersonPeopleByFileUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByFileUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: FilesConnection;
};


/** A `Person` edge in the connection, with data from `File`. */
export type PersonPeopleByFileUpdatedByAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByUpdatedBy: GoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountCreatedByAndUpdatedByManyToManyEdgeGoogleAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccountsByCreatedBy: GoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleAccount`. */
export type PersonPeopleByGoogleAccountUpdatedByAndCreatedByManyToManyEdgeGoogleAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByUpdatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarCreatedByAndUpdatedByManyToManyEdgeGoogleCalendarSyncCalendarsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSyncCalendar`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendarsByCreatedBy: GoogleCalendarSyncCalendarsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSyncCalendar`. */
export type PersonPeopleByGoogleCalendarSyncCalendarUpdatedByAndCreatedByManyToManyEdgeGoogleCalendarSyncCalendarsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByUpdatedBy: GoogleCalendarSyncsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncCreatedByAndUpdatedByManyToManyEdgeGoogleCalendarSyncsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `Person` values, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GoogleCalendarSync`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncsByCreatedBy: GoogleCalendarSyncsConnection;
};


/** A `Person` edge in the connection, with data from `GoogleCalendarSync`. */
export type PersonPeopleByGoogleCalendarSyncUpdatedByAndCreatedByManyToManyEdgeGoogleCalendarSyncsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByUpdatedBy: GooglePlayAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountCreatedByAndUpdatedByManyToManyEdgeGooglePlayAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccountsByCreatedBy: GooglePlayAccountsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayAccount`. */
export type PersonPeopleByGooglePlayAccountUpdatedByAndCreatedByManyToManyEdgeGooglePlayAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByUpdatedBy: GooglePlayListingsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingCreatedByAndUpdatedByManyToManyEdgeGooglePlayListingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayListing`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListingsByCreatedBy: GooglePlayListingsConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayListing`. */
export type PersonPeopleByGooglePlayListingUpdatedByAndCreatedByManyToManyEdgeGooglePlayListingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayUser`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByUpdatedBy: GooglePlayUsersConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserCreatedByAndUpdatedByManyToManyEdgeGooglePlayUsersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};

/** A connection to a list of `Person` values, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GooglePlayUser`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsersByCreatedBy: GooglePlayUsersConnection;
};


/** A `Person` edge in the connection, with data from `GooglePlayUser`. */
export type PersonPeopleByGooglePlayUserUpdatedByAndCreatedByManyToManyEdgeGooglePlayUsersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByUpdatedBy: GroupCategoriesConnection;
};


/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryCreatedByAndUpdatedByManyToManyEdgeGroupCategoriesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupCategory`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategoriesByCreatedBy: GroupCategoriesConnection;
};


/** A `Person` edge in the connection, with data from `GroupCategory`. */
export type PersonPeopleByGroupCategoryUpdatedByAndCreatedByManyToManyEdgeGroupCategoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};

/** A connection to a list of `Person` values, with data from `Group`. */
export type PersonPeopleByGroupCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Group`. */
export type PersonPeopleByGroupCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByUpdatedBy: GroupsConnection;
};


/** A `Person` edge in the connection, with data from `Group`. */
export type PersonPeopleByGroupCreatedByAndUpdatedByManyToManyEdgeGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByUpdatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterCreatedByAndUpdatedByManyToManyEdgeGroupNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewslettersByCreatedBy: GroupNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `GroupNewsletter`. */
export type PersonPeopleByGroupNewsletterUpdatedByAndCreatedByManyToManyEdgeGroupNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndPersonIdManyToManyEdgeGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByUpdatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonCreatedByAndUpdatedByManyToManyEdgeGroupPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByCreatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndCreatedByManyToManyEdgeGroupPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByUpdatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonPersonIdAndUpdatedByManyToManyEdgeGroupPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeopleByCreatedBy: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndCreatedByManyToManyEdgeGroupPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `GroupPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople: GroupPeopleConnection;
};


/** A `Person` edge in the connection, with data from `GroupPerson`. */
export type PersonPeopleByGroupPersonUpdatedByAndPersonIdManyToManyEdgeGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Group`. */
export type PersonPeopleByGroupUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByGroupUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Group`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByGroupUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Group`. */
export type PersonPeopleByGroupUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByGroupUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCreatedBy: GroupsConnection;
};


/** A `Person` edge in the connection, with data from `Group`. */
export type PersonPeopleByGroupUpdatedByAndCreatedByManyToManyEdgeGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};

/** A connection to a list of `Person` values, with data from `IosApp`. */
export type PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosApp`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosApp`. */
export type PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosApp`. */
  iosAppsByUpdatedBy: IosAppsConnection;
};


/** A `Person` edge in the connection, with data from `IosApp`. */
export type PersonPeopleByIosAppCreatedByAndUpdatedByManyToManyEdgeIosAppsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
  condition?: Maybe<IosAppCondition>;
  filter?: Maybe<IosAppFilter>;
};

/** A connection to a list of `Person` values, with data from `IosApp`. */
export type PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosApp`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosApp`. */
export type PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosApp`. */
  iosAppsByCreatedBy: IosAppsConnection;
};


/** A `Person` edge in the connection, with data from `IosApp`. */
export type PersonPeopleByIosAppUpdatedByAndCreatedByManyToManyEdgeIosAppsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
  condition?: Maybe<IosAppCondition>;
  filter?: Maybe<IosAppFilter>;
};

/** A connection to a list of `Person` values, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByUpdatedBy: IosScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotCreatedByAndUpdatedByManyToManyEdgeIosScreenshotsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `IosScreenshot`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshotsByCreatedBy: IosScreenshotsConnection;
};


/** A `Person` edge in the connection, with data from `IosScreenshot`. */
export type PersonPeopleByIosScreenshotUpdatedByAndCreatedByManyToManyEdgeIosScreenshotsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByUpdatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type PersonPeopleByMarqueeCreatedByAndUpdatedByManyToManyEdgeMarqueesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByCreatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type PersonPeopleByMarqueeUpdatedByAndCreatedByManyToManyEdgeMarqueesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByUpdatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PersonPeopleByNewsletterCreatedByAndUpdatedByManyToManyEdgeNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Newsletter`. */
export type PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Newsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newslettersByCreatedBy: NewslettersConnection;
};


/** A `Person` edge in the connection, with data from `Newsletter`. */
export type PersonPeopleByNewsletterUpdatedByAndCreatedByManyToManyEdgeNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Notification`. */
export type PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByUpdatedBy: NotificationsConnection;
};


/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationCreatedByAndUpdatedByManyToManyEdgeNotificationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByUpdatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileCreatedByAndUpdatedByManyToManyEdgeNotificationFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFilesByCreatedBy: NotificationFilesConnection;
};


/** A `Person` edge in the connection, with data from `NotificationFile`. */
export type PersonPeopleByNotificationFileUpdatedByAndCreatedByManyToManyEdgeNotificationFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByUpdatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupCreatedByAndUpdatedByManyToManyEdgeNotificationGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroupsByCreatedBy: NotificationGroupsConnection;
};


/** A `Person` edge in the connection, with data from `NotificationGroup`. */
export type PersonPeopleByNotificationGroupUpdatedByAndCreatedByManyToManyEdgeNotificationGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByUpdatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterCreatedByAndUpdatedByManyToManyEdgeNotificationNewslettersByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `NotificationNewsletter`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewslettersByCreatedBy: NotificationNewslettersConnection;
};


/** A `Person` edge in the connection, with data from `NotificationNewsletter`. */
export type PersonPeopleByNotificationNewsletterUpdatedByAndCreatedByManyToManyEdgeNotificationNewslettersByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};

/** A connection to a list of `Person` values, with data from `Notification`. */
export type PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Notification`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByCreatedBy: NotificationsConnection;
};


/** A `Person` edge in the connection, with data from `Notification`. */
export type PersonPeopleByNotificationUpdatedByAndCreatedByManyToManyEdgeNotificationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};

/** A connection to a list of `Person` values, with data from `Page`. */
export type PersonPeopleByPageCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Page`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Page`. */
export type PersonPeopleByPageCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByUpdatedBy: PagesConnection;
};


/** A `Person` edge in the connection, with data from `Page`. */
export type PersonPeopleByPageCreatedByAndUpdatedByManyToManyEdgePagesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSection`. */
export type PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSection`. */
export type PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByUpdatedBy: PageSectionsConnection;
};


/** A `Person` edge in the connection, with data from `PageSection`. */
export type PersonPeopleByPageSectionCreatedByAndUpdatedByManyToManyEdgePageSectionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByUpdatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileCreatedByAndUpdatedByManyToManyEdgePageSectionFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSectionFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFilesByCreatedBy: PageSectionFilesConnection;
};


/** A `Person` edge in the connection, with data from `PageSectionFile`. */
export type PersonPeopleByPageSectionFileUpdatedByAndCreatedByManyToManyEdgePageSectionFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};

/** A connection to a list of `Person` values, with data from `PageSection`. */
export type PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `PageSection`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `PageSection`. */
export type PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSectionsByCreatedBy: PageSectionsConnection;
};


/** A `Person` edge in the connection, with data from `PageSection`. */
export type PersonPeopleByPageSectionUpdatedByAndCreatedByManyToManyEdgePageSectionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};

/** A connection to a list of `Person` values, with data from `Page`. */
export type PersonPeopleByPageUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPageUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Page`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPageUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Page`. */
export type PersonPeopleByPageUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPageUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Page`. */
  pagesByCreatedBy: PagesConnection;
};


/** A `Person` edge in the connection, with data from `Page`. */
export type PersonPeopleByPageUpdatedByAndCreatedByManyToManyEdgePagesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};

/** A connection to a list of `Person` values, with data from `Person`. */
export type PersonPeopleByPersonCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPersonCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPersonCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Person`. */
export type PersonPeopleByPersonCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPersonCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByUpdatedBy: PeopleConnection;
};


/** A `Person` edge in the connection, with data from `Person`. */
export type PersonPeopleByPersonCreatedByAndUpdatedByManyToManyEdgePeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Person`. */
export type PersonPeopleByPersonUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByPersonUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Person`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByPersonUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Person`. */
export type PersonPeopleByPersonUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByPersonUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCreatedBy: PeopleConnection;
};


/** A `Person` edge in the connection, with data from `Person`. */
export type PersonPeopleByPersonUpdatedByAndCreatedByManyToManyEdgePeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Release`. */
export type PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Release`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Release`. */
export type PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByUpdatedBy: ReleasesConnection;
};


/** A `Person` edge in the connection, with data from `Release`. */
export type PersonPeopleByReleaseCreatedByAndUpdatedByManyToManyEdgeReleasesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};

/** A connection to a list of `Person` values, with data from `Release`. */
export type PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Release`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Release`. */
export type PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Release`. */
  releasesByCreatedBy: ReleasesConnection;
};


/** A `Person` edge in the connection, with data from `Release`. */
export type PersonPeopleByReleaseUpdatedByAndCreatedByManyToManyEdgeReleasesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type PersonPeopleByResourceCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByResourceCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByResourceCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type PersonPeopleByResourceCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByResourceCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type PersonPeopleByResourceCreatedByAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByUpdatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileCreatedByAndUpdatedByManyToManyEdgeResourceFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByCreatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type PersonPeopleByResourceFileUpdatedByAndCreatedByManyToManyEdgeResourceFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type PersonPeopleByResourceUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByResourceUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByResourceUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type PersonPeopleByResourceUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByResourceUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type PersonPeopleByResourceUpdatedByAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `School`. */
export type PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `School`. */
export type PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByUpdatedBy: SchoolsConnection;
};


/** A `Person` edge in the connection, with data from `School`. */
export type PersonPeopleBySchoolCreatedByAndUpdatedByManyToManyEdgeSchoolsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndPersonIdManyToManyEdgeSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByUpdatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonCreatedByAndUpdatedByManyToManyEdgeSchoolPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByUpdatedBy: SchoolPersonInstallationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationCreatedByAndUpdatedByManyToManyEdgeSchoolPersonInstallationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByCreatedBy: SchoolPersonInstallationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonPeopleBySchoolPersonInstallationUpdatedByAndCreatedByManyToManyEdgeSchoolPersonInstallationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInvitation`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInvitation`. */
  schoolPersonInvitationsByUpdatedBy: SchoolPersonInvitationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationCreatedByAndUpdatedByManyToManyEdgeSchoolPersonInvitationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
  condition?: Maybe<SchoolPersonInvitationCondition>;
  filter?: Maybe<SchoolPersonInvitationFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInvitation`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInvitation`. */
  schoolPersonInvitationsByCreatedBy: SchoolPersonInvitationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInvitation`. */
export type PersonPeopleBySchoolPersonInvitationUpdatedByAndCreatedByManyToManyEdgeSchoolPersonInvitationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
  condition?: Maybe<SchoolPersonInvitationCondition>;
  filter?: Maybe<SchoolPersonInvitationFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByCreatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndCreatedByManyToManyEdgeSchoolPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByUpdatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonPersonIdAndUpdatedByManyToManyEdgeSchoolPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByCreatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndCreatedByManyToManyEdgeSchoolPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type PersonPeopleBySchoolPersonUpdatedByAndPersonIdManyToManyEdgeSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByUpdatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingCreatedByAndUpdatedByManyToManyEdgeSchoolSettingsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolSetting`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettingsByCreatedBy: SchoolSettingsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolSetting`. */
export type PersonPeopleBySchoolSettingUpdatedByAndCreatedByManyToManyEdgeSchoolSettingsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};

/** A connection to a list of `Person` values, with data from `School`. */
export type PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `School`. */
export type PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByCreatedBy: SchoolsConnection;
};


/** A `Person` edge in the connection, with data from `School`. */
export type PersonPeopleBySchoolUpdatedByAndCreatedByManyToManyEdgeSchoolsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type PersonPeopleBySponsorCreatedByAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type PersonPeopleBySponsorUpdatedByAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Theme`. */
export type PersonPeopleByThemeCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByThemeCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Theme`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByThemeCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Theme`. */
export type PersonPeopleByThemeCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByThemeCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByUpdatedBy: ThemesConnection;
};


/** A `Person` edge in the connection, with data from `Theme`. */
export type PersonPeopleByThemeCreatedByAndUpdatedByManyToManyEdgeThemesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};

/** A connection to a list of `Person` values, with data from `Theme`. */
export type PersonPeopleByThemeUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByThemeUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Theme`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByThemeUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Theme`. */
export type PersonPeopleByThemeUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByThemeUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByCreatedBy: ThemesConnection;
};


/** A `Person` edge in the connection, with data from `Theme`. */
export type PersonPeopleByThemeUpdatedByAndCreatedByManyToManyEdgeThemesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};

/** A connection to a list of `Person` values, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByUpdatedBy: UserGoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountCreatedByAndUpdatedByManyToManyEdgeUserGoogleAccountsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** A connection to a list of `Person` values, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `UserGoogleAccount`, and the cursor to aid in pagination. */
  edges: Array<PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccountsByCreatedBy: UserGoogleAccountsConnection;
};


/** A `Person` edge in the connection, with data from `UserGoogleAccount`. */
export type PersonPeopleByUserGoogleAccountUpdatedByAndCreatedByManyToManyEdgeUserGoogleAccountsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};

/** The fields on `person` to look up the row to connect. */
export type PersonPersonPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `person` to look up the row to delete. */
export type PersonPersonPkeyDelete = {
  id: Scalars['UUID'];
};

export type PersonPublic = {
  __typename?: 'PersonPublic';
  id?: Maybe<Scalars['UUID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
};

/** A connection to a list of `Release` values, with data from `Build`. */
export type PersonReleasesByBuildCreatedByAndReleaseIdManyToManyConnection = {
  __typename?: 'PersonReleasesByBuildCreatedByAndReleaseIdManyToManyConnection';
  /** A list of `Release` objects. */
  nodes: Array<Release>;
  /** A list of edges which contains the `Release`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonReleasesByBuildCreatedByAndReleaseIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Release` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Release` edge in the connection, with data from `Build`. */
export type PersonReleasesByBuildCreatedByAndReleaseIdManyToManyEdge = {
  __typename?: 'PersonReleasesByBuildCreatedByAndReleaseIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Release` at the end of the edge. */
  node: Release;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `Release` edge in the connection, with data from `Build`. */
export type PersonReleasesByBuildCreatedByAndReleaseIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Release` values, with data from `Build`. */
export type PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyConnection = {
  __typename?: 'PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyConnection';
  /** A list of `Release` objects. */
  nodes: Array<Release>;
  /** A list of edges which contains the `Release`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Release` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Release` edge in the connection, with data from `Build`. */
export type PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyEdge = {
  __typename?: 'PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Release` at the end of the edge. */
  node: Release;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `Release` edge in the connection, with data from `Build`. */
export type PersonReleasesByBuildUpdatedByAndReleaseIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type PersonResourcesByResourceCreatedByAndParentResourceManyToManyConnection = {
  __typename?: 'PersonResourcesByResourceCreatedByAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonResourcesByResourceCreatedByAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type PersonResourcesByResourceCreatedByAndParentResourceManyToManyEdge = {
  __typename?: 'PersonResourcesByResourceCreatedByAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type PersonResourcesByResourceCreatedByAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Resource` values, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyConnection = {
  __typename?: 'PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyEdge = {
  __typename?: 'PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
};


/** A `Resource` edge in the connection, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileCreatedByAndResourceIdManyToManyEdgeResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Resource` values, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyConnection = {
  __typename?: 'PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyEdge = {
  __typename?: 'PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
};


/** A `Resource` edge in the connection, with data from `ResourceFile`. */
export type PersonResourcesByResourceFileUpdatedByAndResourceIdManyToManyEdgeResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Resource` values, with data from `Resource`. */
export type PersonResourcesByResourceUpdatedByAndParentResourceManyToManyConnection = {
  __typename?: 'PersonResourcesByResourceUpdatedByAndParentResourceManyToManyConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<PersonResourcesByResourceUpdatedByAndParentResourceManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection, with data from `Resource`. */
export type PersonResourcesByResourceUpdatedByAndParentResourceManyToManyEdge = {
  __typename?: 'PersonResourcesByResourceUpdatedByAndParentResourceManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
};


/** A `Resource` edge in the connection, with data from `Resource`. */
export type PersonResourcesByResourceUpdatedByAndParentResourceManyToManyEdgeResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `SchoolPerson` values, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyConnection = {
  __typename?: 'PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyConnection';
  /** A list of `SchoolPerson` objects. */
  nodes: Array<SchoolPerson>;
  /** A list of edges which contains the `SchoolPerson`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolPerson` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SchoolPerson` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyEdge = {
  __typename?: 'PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolPerson` at the end of the edge. */
  node: SchoolPerson;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallations: SchoolPersonInstallationsConnection;
};


/** A `SchoolPerson` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationCreatedByAndSchoolPersonIdManyToManyEdgeSchoolPersonInstallationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** A connection to a list of `SchoolPerson` values, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyConnection = {
  __typename?: 'PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyConnection';
  /** A list of `SchoolPerson` objects. */
  nodes: Array<SchoolPerson>;
  /** A list of edges which contains the `SchoolPerson`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolPerson` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SchoolPerson` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyEdge = {
  __typename?: 'PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolPerson` at the end of the edge. */
  node: SchoolPerson;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallations: SchoolPersonInstallationsConnection;
};


/** A `SchoolPerson` edge in the connection, with data from `SchoolPersonInstallation`. */
export type PersonSchoolPeopleBySchoolPersonInstallationUpdatedByAndSchoolPersonIdManyToManyEdgeSchoolPersonInstallationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** A connection to a list of `School` values, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroups: CalendarGroupsConnection;
};


/** A `School` edge in the connection, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupCreatedByAndSchoolIdManyToManyEdgeCalendarGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `School` values, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroups: CalendarGroupsConnection;
};


/** A `School` edge in the connection, with data from `CalendarGroup`. */
export type PersonSchoolsByCalendarGroupUpdatedByAndSchoolIdManyToManyEdgeCalendarGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `School` values, with data from `Division`. */
export type PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Division`. */
export type PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `School` edge in the connection, with data from `Division`. */
export type PersonSchoolsByDivisionCreatedByAndSchoolIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `School` values, with data from `Division`. */
export type PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Division`. */
export type PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `School` edge in the connection, with data from `Division`. */
export type PersonSchoolsByDivisionUpdatedByAndSchoolIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `School` values, with data from `Marquee`. */
export type PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Marquee`. */
export type PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Marquee`. */
  marquees: MarqueesConnection;
};


/** A `School` edge in the connection, with data from `Marquee`. */
export type PersonSchoolsByMarqueeCreatedByAndSchoolIdManyToManyEdgeMarqueesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `School` values, with data from `Marquee`. */
export type PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Marquee`. */
export type PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Marquee`. */
  marquees: MarqueesConnection;
};


/** A `School` edge in the connection, with data from `Marquee`. */
export type PersonSchoolsByMarqueeUpdatedByAndSchoolIdManyToManyEdgeMarqueesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `School` values, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
};


/** A `School` edge in the connection, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonCreatedByAndSchoolIdManyToManyEdgeSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `School` values, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsBySchoolPersonPersonIdAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes: Array<Maybe<UserType>>;
  includeInDirectory: Scalars['Boolean'];
  completedOnboarding: Scalars['Boolean'];
};

/** A connection to a list of `School` values, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
};


/** A `School` edge in the connection, with data from `SchoolPerson`. */
export type PersonSchoolsBySchoolPersonUpdatedByAndSchoolIdManyToManyEdgeSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type PersonSchoolsBySponsorCreatedByAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `School` values, with data from `Sponsor`. */
export type PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyConnection = {
  __typename?: 'PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection, with data from `Sponsor`. */
export type PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyEdge = {
  __typename?: 'PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
};


/** A `School` edge in the connection, with data from `Sponsor`. */
export type PersonSchoolsBySponsorUpdatedByAndSchoolIdManyToManyEdgeSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Theme` values, with data from `School`. */
export type PersonThemesBySchoolCreatedByAndThemeIdManyToManyConnection = {
  __typename?: 'PersonThemesBySchoolCreatedByAndThemeIdManyToManyConnection';
  /** A list of `Theme` objects. */
  nodes: Array<Theme>;
  /** A list of edges which contains the `Theme`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<PersonThemesBySchoolCreatedByAndThemeIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Theme` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Theme` edge in the connection, with data from `School`. */
export type PersonThemesBySchoolCreatedByAndThemeIdManyToManyEdge = {
  __typename?: 'PersonThemesBySchoolCreatedByAndThemeIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Theme` at the end of the edge. */
  node: Theme;
  /** Reads and enables pagination through a set of `School`. */
  schools: SchoolsConnection;
};


/** A `Theme` edge in the connection, with data from `School`. */
export type PersonThemesBySchoolCreatedByAndThemeIdManyToManyEdgeSchoolsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** A connection to a list of `Theme` values, with data from `School`. */
export type PersonThemesBySchoolUpdatedByAndThemeIdManyToManyConnection = {
  __typename?: 'PersonThemesBySchoolUpdatedByAndThemeIdManyToManyConnection';
  /** A list of `Theme` objects. */
  nodes: Array<Theme>;
  /** A list of edges which contains the `Theme`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<PersonThemesBySchoolUpdatedByAndThemeIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Theme` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Theme` edge in the connection, with data from `School`. */
export type PersonThemesBySchoolUpdatedByAndThemeIdManyToManyEdge = {
  __typename?: 'PersonThemesBySchoolUpdatedByAndThemeIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Theme` at the end of the edge. */
  node: Theme;
  /** Reads and enables pagination through a set of `School`. */
  schools: SchoolsConnection;
};


/** A `Theme` edge in the connection, with data from `School`. */
export type PersonThemesBySchoolUpdatedByAndThemeIdManyToManyEdgeSchoolsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** Input for the nested mutation of `person` in the `PersonInput` mutation. */
export type PersonUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnPersonForPersonUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<PersonOnPersonForPersonUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<PersonUpdatedByFkeyPersonCreateInput>;
};

/** The `person` to be created by this mutation. */
export type PersonUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

export enum PlatformType {
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB'
}

/** A filter to be used against PlatformType fields. All fields are combined with a logical ‘and.’ */
export type PlatformTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PlatformType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PlatformType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PlatformType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PlatformType>;
  /** Included in the specified list. */
  in?: Maybe<Array<PlatformType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PlatformType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PlatformType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PlatformType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PlatformType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PlatformType>;
};

export type PresignedUploadUrlInput = {
  filetype: FileType;
};

export type PresignedUploadUrlPayload = {
  __typename?: 'PresignedUploadUrlPayload';
  url: Scalars['String'];
  filename: Scalars['String'];
};

/** All input for the `processGoogleCalendarSync` mutation. */
export type ProcessGoogleCalendarSyncInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  events: Scalars['JSON'];
  vGoogleCalendarId: Scalars['String'];
  vNextSyncToken: Scalars['String'];
};

/** The output of our `processGoogleCalendarSync` mutation. */
export type ProcessGoogleCalendarSyncPayload = {
  __typename?: 'ProcessGoogleCalendarSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The `file` to be created by this mutation. */
export type ProfileImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `PersonInput` mutation. */
export type ProfileImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnPersonForProfileImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<PersonOnPersonForProfileImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ProfileImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `person` in the `FileInput` mutation. */
export type ProfileImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `person` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<Array<PersonPersonPkeyConnect>>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<PersonNodeIdConnect>>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<Array<PersonPersonPkeyDelete>>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<PersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<Array<PersonOnPersonForProfileImageFileIdFkeyUsingPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnPersonForProfileImageFileIdFkeyNodeIdUpdate>>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ProfileImageFileIdFkeyPersonCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type ProfileImageFileIdFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** All input for the `publishNewsletter` mutation. */
export type PublishNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newsletterId: Scalars['UUID'];
  publishDate?: Maybe<Scalars['Datetime']>;
  sendNotification?: Maybe<Scalars['Boolean']>;
};

/** The output of our `publishNewsletter` mutation. */
export type PublishNewsletterPayload = {
  __typename?: 'PublishNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `App`. */
  apps?: Maybe<AppsConnection>;
  /** Reads and enables pagination through a set of `Calendar`. */
  calendars?: Maybe<CalendarsConnection>;
  /** Reads and enables pagination through a set of `CalendarEvent`. */
  calendarEvents?: Maybe<CalendarEventsConnection>;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroups?: Maybe<CalendarGroupsConnection>;
  /** Reads and enables pagination through a set of `CalendarGroupCalendar`. */
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarsConnection>;
  /** Reads and enables pagination through a set of `Division`. */
  divisions?: Maybe<DivisionsConnection>;
  /** Reads and enables pagination through a set of `Event`. */
  events?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `GoogleAccount`. */
  googleAccounts?: Maybe<GoogleAccountsConnection>;
  /** Reads and enables pagination through a set of `GoogleCalendarSync`. */
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncsConnection>;
  /** Reads and enables pagination through a set of `GoogleCalendarSyncCalendar`. */
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarsConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  groups?: Maybe<GroupsConnection>;
  /** Reads and enables pagination through a set of `GroupPerson`. */
  groupPeople?: Maybe<GroupPeopleConnection>;
  /** Reads and enables pagination through a set of `GroupCategory`. */
  groupCategories?: Maybe<GroupCategoriesConnection>;
  /** Reads and enables pagination through a set of `GroupNewsletter`. */
  groupNewsletters?: Maybe<GroupNewslettersConnection>;
  /** Reads and enables pagination through a set of `Marquee`. */
  marquees?: Maybe<MarqueesConnection>;
  /** Reads and enables pagination through a set of `Newsletter`. */
  newsletters?: Maybe<NewslettersConnection>;
  /** Reads and enables pagination through a set of `Notification`. */
  notifications?: Maybe<NotificationsConnection>;
  /** Reads and enables pagination through a set of `NotificationFile`. */
  notificationFiles?: Maybe<NotificationFilesConnection>;
  /** Reads and enables pagination through a set of `NotificationNewsletter`. */
  notificationNewsletters?: Maybe<NotificationNewslettersConnection>;
  /** Reads and enables pagination through a set of `NotificationGroup`. */
  notificationGroups?: Maybe<NotificationGroupsConnection>;
  /** Reads and enables pagination through a set of `Page`. */
  pages?: Maybe<PagesConnection>;
  /** Reads and enables pagination through a set of `PageSection`. */
  pageSections?: Maybe<PageSectionsConnection>;
  /** Reads and enables pagination through a set of `PageSectionFile`. */
  pageSectionFiles?: Maybe<PageSectionFilesConnection>;
  /** Reads and enables pagination through a set of `Person`. */
  people?: Maybe<PeopleConnection>;
  /** Reads and enables pagination through a set of `Resource`. */
  resources?: Maybe<ResourcesConnection>;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles?: Maybe<ResourceFilesConnection>;
  /** Reads and enables pagination through a set of `School`. */
  schools?: Maybe<SchoolsConnection>;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople?: Maybe<SchoolPeopleConnection>;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationsConnection>;
  /** Reads and enables pagination through a set of `SchoolPersonInvitation`. */
  schoolPersonInvitations?: Maybe<SchoolPersonInvitationsConnection>;
  /** Reads and enables pagination through a set of `SchoolSetting`. */
  schoolSettings?: Maybe<SchoolSettingsConnection>;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors?: Maybe<SponsorsConnection>;
  /** Reads and enables pagination through a set of `Theme`. */
  themes?: Maybe<ThemesConnection>;
  /** Reads and enables pagination through a set of `UserGoogleAccount`. */
  userGoogleAccounts?: Maybe<UserGoogleAccountsConnection>;
  /** Reads and enables pagination through a set of `UserSession`. */
  userSessions?: Maybe<UserSessionsConnection>;
  /** Reads and enables pagination through a set of `AndroidApp`. */
  androidApps?: Maybe<AndroidAppsConnection>;
  /** Reads and enables pagination through a set of `AndroidJavaKeyStore`. */
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoresConnection>;
  /** Reads and enables pagination through a set of `AndroidScreenshot`. */
  androidScreenshots?: Maybe<AndroidScreenshotsConnection>;
  /** Reads and enables pagination through a set of `AppStoreAccount`. */
  appStoreAccounts?: Maybe<AppStoreAccountsConnection>;
  /** Reads and enables pagination through a set of `AppStoreListing`. */
  appStoreListings?: Maybe<AppStoreListingsConnection>;
  /** Reads and enables pagination through a set of `AppStoreUser`. */
  appStoreUsers?: Maybe<AppStoreUsersConnection>;
  /** Reads and enables pagination through a set of `Build`. */
  builds?: Maybe<BuildsConnection>;
  /** Reads and enables pagination through a set of `BuildStep`. */
  buildSteps?: Maybe<BuildStepsConnection>;
  /** Reads and enables pagination through a set of `GooglePlayAccount`. */
  googlePlayAccounts?: Maybe<GooglePlayAccountsConnection>;
  /** Reads and enables pagination through a set of `GooglePlayListing`. */
  googlePlayListings?: Maybe<GooglePlayListingsConnection>;
  /** Reads and enables pagination through a set of `GooglePlayUser`. */
  googlePlayUsers?: Maybe<GooglePlayUsersConnection>;
  /** Reads and enables pagination through a set of `IosApp`. */
  iosApps?: Maybe<IosAppsConnection>;
  /** Reads and enables pagination through a set of `IosScreenshot`. */
  iosScreenshots?: Maybe<IosScreenshotsConnection>;
  /** Reads and enables pagination through a set of `Release`. */
  releases?: Maybe<ReleasesConnection>;
  app?: Maybe<App>;
  appBySchoolId?: Maybe<App>;
  calendar?: Maybe<Calendar>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventByEventIdAndCalendarId?: Maybe<CalendarEvent>;
  calendarGroup?: Maybe<CalendarGroup>;
  calendarGroupCalendar?: Maybe<CalendarGroupCalendar>;
  calendarGroupCalendarByCalendarIdAndCalendarGroupId?: Maybe<CalendarGroupCalendar>;
  division?: Maybe<Division>;
  event?: Maybe<Event>;
  eventBySlug?: Maybe<Event>;
  eventByGoogleId?: Maybe<Event>;
  file?: Maybe<File>;
  googleAccount?: Maybe<GoogleAccount>;
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  googleCalendarSyncCalendar?: Maybe<GoogleCalendarSyncCalendar>;
  group?: Maybe<Group>;
  groupBySlug?: Maybe<Group>;
  groupPerson?: Maybe<GroupPerson>;
  groupPersonByGroupIdAndPersonId?: Maybe<GroupPerson>;
  groupCategory?: Maybe<GroupCategory>;
  groupNewsletter?: Maybe<GroupNewsletter>;
  groupNewsletterByGroupIdAndNewsletterId?: Maybe<GroupNewsletter>;
  marquee?: Maybe<Marquee>;
  newsletter?: Maybe<Newsletter>;
  notification?: Maybe<Notification>;
  notificationBySlug?: Maybe<Notification>;
  notificationFile?: Maybe<NotificationFile>;
  notificationFileByFileIdAndNotificationId?: Maybe<NotificationFile>;
  notificationNewsletter?: Maybe<NotificationNewsletter>;
  notificationNewsletterByNotificationIdAndNewsletterId?: Maybe<NotificationNewsletter>;
  notificationGroup?: Maybe<NotificationGroup>;
  notificationGroupByNotificationIdAndGroupId?: Maybe<NotificationGroup>;
  page?: Maybe<Page>;
  pageSection?: Maybe<PageSection>;
  pageSectionFile?: Maybe<PageSectionFile>;
  pageSectionFileByFileIdAndPageSectionId?: Maybe<PageSectionFile>;
  person?: Maybe<Person>;
  resource?: Maybe<Resource>;
  resourceBySlug?: Maybe<Resource>;
  resourceFile?: Maybe<ResourceFile>;
  resourceFileByFileIdAndResourceId?: Maybe<ResourceFile>;
  school?: Maybe<School>;
  schoolBySlug?: Maybe<School>;
  schoolPerson?: Maybe<SchoolPerson>;
  schoolPersonByPersonIdAndSchoolId?: Maybe<SchoolPerson>;
  schoolPersonInstallation?: Maybe<SchoolPersonInstallation>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitation>;
  schoolPersonInvitationBySlug?: Maybe<SchoolPersonInvitation>;
  schoolSetting?: Maybe<SchoolSetting>;
  sponsor?: Maybe<Sponsor>;
  sponsorBySlug?: Maybe<Sponsor>;
  theme?: Maybe<Theme>;
  userGoogleAccount?: Maybe<UserGoogleAccount>;
  userGoogleAccountByUserIdAndGoogleAccountId?: Maybe<UserGoogleAccount>;
  userSession?: Maybe<UserSession>;
  androidApp?: Maybe<AndroidApp>;
  androidAppByAppId?: Maybe<AndroidApp>;
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  androidScreenshot?: Maybe<AndroidScreenshot>;
  appStoreAccount?: Maybe<AppStoreAccount>;
  appStoreAccountByAppStoreConnectId?: Maybe<AppStoreAccount>;
  appStoreAccountByAppleDeveloperAccountId?: Maybe<AppStoreAccount>;
  appStoreAccountByOrganizationName?: Maybe<AppStoreAccount>;
  appStoreListing?: Maybe<AppStoreListing>;
  appStoreListingByIosAppId?: Maybe<AppStoreListing>;
  appStoreUser?: Maybe<AppStoreUser>;
  appStoreUserByEmail?: Maybe<AppStoreUser>;
  build?: Maybe<Build>;
  buildStep?: Maybe<BuildStep>;
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  googlePlayListing?: Maybe<GooglePlayListing>;
  googlePlayListingByAndroidAppId?: Maybe<GooglePlayListing>;
  googlePlayUser?: Maybe<GooglePlayUser>;
  googlePlayUserByEmail?: Maybe<GooglePlayUser>;
  iosApp?: Maybe<IosApp>;
  iosAppByAppId?: Maybe<IosApp>;
  iosScreenshot?: Maybe<IosScreenshot>;
  release?: Maybe<Release>;
  /**
   * Does a broad, system-wide search for any Person WITH a UserAccount attached (they can log in) with this email address.
   *
   *  We return a custom type from this function because it runs as security definer. We want to severely limit the amount of info someone can find out about any given user if they try some email addresses.
   */
  checkAccountEmail?: Maybe<PersonPublic>;
  /** Runs a quick check to see if a Person has already been created for this School with this email address. For now we just return the first person with a matching email address since we don't allow duplicate Person records. */
  checkPersonEmailForSchool?: Maybe<Person>;
  /** Validation check to make sure a text field is not set to an empty string. */
  checkTextNotEmpty?: Maybe<Scalars['Boolean']>;
  /** Generic function for providing a custom error message with Postgres `check` statements. */
  checkWithErrorMessage?: Maybe<Scalars['Boolean']>;
  /** Google accounts for the current logged in user. */
  currentPersonGoogleAccounts?: Maybe<GoogleAccountsConnection>;
  /** Get all events within a given week for a Person's Group membership within a school. */
  currentPersonSchoolCalendarEventsInWeek?: Maybe<EventsConnection>;
  /** If a user is logged in, returns the user's Person as identified by their JWT. */
  currentUser?: Maybe<Person>;
  /** Gets the current user ID identified by the current query's JWT. */
  currentUserId?: Maybe<Scalars['UUID']>;
  /** Gets the current user's Person ID identified by the current query's JWT. */
  currentUserPersonId?: Maybe<Scalars['UUID']>;
  /**
   * Fetches all `Group` IDs for a `Division`.
   *
   * 1) Gets the Division's Group
   * 2) Gets all GroupCategories for the Division (sorted by GroupCategory sort order)
   * 3) For each GroupCategory, gets all Groups (sorted by Group sort order)
   */
  divisionGroupIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /**
   * Uses the provided `calendar_ids` and `date` to return all `Event`s the current user has access to that fall within the week that the provided `date` falls within. <br/><br/>
   *
   * Uses Postgres's `date_trunc` function to get the beginning of the week for the submitted `date` and Postgres's `overlap` operator to compare .
   */
  getCalendarEventsForWeek?: Maybe<EventsConnection>;
  /**
   * Fetches all `Group`s for a `Division`.
   *
   * 1) Gets the Division's Group
   * 2) Gets all GroupCategories for the Division (sorted by GroupCategory sort order)
   * 3) For each GroupCategory, gets all Groups (sorted by Group sort order)
   */
  getGroupsForDivision?: Maybe<GroupsConnection>;
  /**
   * Fetches all `Group`s for a `School`.
   *
   * 1) Loops through all Divisions for the School (sorted by Division sort order) and retrieves its Groups.
   */
  getGroupsForSchool?: Maybe<GroupsConnection>;
  /** Fetch the public data to display a Newsletter. */
  getNewsletterBySlug?: Maybe<Newsletter>;
  /** Fetches all `Notification`s for a `School` that the currently logged in Person has access to based on User_Group membership/role (admin, member). */
  getNotificationsForSchoolForCurrentPerson?: Maybe<NotificationsConnection>;
  /** Gets a Group's School ID. */
  getSchoolIdForGroupId?: Maybe<Scalars['UUID']>;
  /** All Google Calendar Syncs for a School. */
  googleCalendarSyncForSchool?: Maybe<GoogleCalendarSyncsConnection>;
  /** Whether this `Group` ID is the Schoolwide `Group` on a `School`. */
  groupIdIsSchoolwideGroup?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Resource`. */
  groupResourcesForCurrentPerson?: Maybe<ResourcesConnection>;
  /** Reads and enables pagination through a set of `Resource`. */
  groupResourcesForPerson?: Maybe<ResourcesConnection>;
  invitationDetail?: Maybe<InvitationPublic>;
  /** Fetches all `Newsletters`s for a `School` that the currently logged in Person has access to based on User_Group membership/role (admin, member). */
  newslettersForSchoolForCurrentPerson?: Maybe<NewslettersConnection>;
  /** Checks to see if the provided email has an account without a password. If one is required, automatically send a password reset email to the provided email address. */
  passwordResetRequired?: Maybe<Scalars['Boolean']>;
  /** Returns all the Groups that a person has access to within a school filtering by admin and/or member role. For admins, it honors admin access to a Division as cascading down to admin access for all Groups within the Division. For member roles, access to a Division does not cascade down to child Groups. The Person must explicitly be a member of each Group. */
  personGroupIdsForSchool?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Get all the Group IDs within a given school for a Person that is a member of those Groups. */
  personMemberGroupIdsForSchool?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Gather the info necessary to send a notification. */
  prepareToSendNotification?: Maybe<NotificationConfig>;
  /** Finds total count of users subscribed to a list of Groups. Optionally filters based on the user type. */
  recipientCount?: Maybe<Scalars['Int']>;
  /** Allows a successfully authenticated user to refresh their access token for another 180 days. */
  refreshToken?: Maybe<Scalars['JwtToken']>;
  schoolCalendarIdsForPerson?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Returns all notifications for groups in a school that the current person is subscribed to. */
  schoolNotificationsForCurrentPerson?: Maybe<NotificationsConnection>;
  /** Returns all notifications for groups in a school that the given person is subscribed to. */
  schoolNotificationsForPerson?: Maybe<NotificationsConnection>;
  /** Return all Resources within a School for Groups that a Person is a member of. */
  schoolResourcesForPerson?: Maybe<ResourcesConnection>;
  /** Ensures the current user's JWT is valid and the user exists. */
  verifyAuth?: Maybe<Scalars['Boolean']>;
  /** String of epoch timestamp for right now rounded to the nearest second. \n\ntrunc(extract(epoch from now()))::text; */
  generateBuildNumber?: Maybe<Scalars['String']>;
  /** Returns the bitrise slug for the app platform. */
  getBitriseAppSlugForPlatform?: Maybe<Scalars['String']>;
  /** Updates the appropriate build number from the latest release for the platform specified. */
  getEventcastVersionNumberForRelease?: Maybe<Scalars['String']>;
  /** Loops through and concatenates the public release notes (with a trailing newline) following the specified release. */
  getExternalReleaseNotesAfterThisRelease?: Maybe<Scalars['String']>;
  /** Returns the most recently created build for an app/platform that is not marked as failed, rejected, or live. */
  getLatestActiveBuildForAppForPlatform?: Maybe<Build>;
  /** Fails if no build for id, or no active build_step for id. returns the not killed/failed/succeeded build_step with the highest step_number. */
  getLatestActiveBuildStepForBuild?: Maybe<BuildStep>;
  /** Returns the most recently created build for this app/platform. This function does not take any build states into account. */
  getLatestBuildForAppForPlatform?: Maybe<Build>;
  /** Returns the most recently created build for an app/platform that is marked as live. */
  getLatestLiveBuildForAppForPlatform?: Maybe<Build>;
  /** Returns the most recently created release for the platform. */
  getLatestReleaseForPlatform?: Maybe<Release>;
  /** Right now this just always returns IOS_FAIL_BUILD which is more of a director than a build_step_name that'll be used on a build_step. */
  getNextBuildStepOnFailure?: Maybe<BuildStepName>;
  /** Returns the step that follows a successful completion of the step passed in. After IOS_PUBLISH APPROVED_APP it returns IOS_FINISH_BUILD, which is more of a director in what to do next, not necessarily a step name to be used on a build step. */
  getNextBuildStepOnSuccess?: Maybe<BuildStepName>;
  /** Returns the curl call to start a bitrise build for this build id on this branch of the mobile wrapper app code. */
  getNextStartCurl?: Maybe<Scalars['String']>;
  /** Increments the correct number of a ##.##.## type version string. */
  incrementVersionStringForReleaseType?: Maybe<Scalars['String']>;
  /** Takes a `deployment.build_step_name` and returns the name of the lane that step starts. If that step isn't at the start of the lane, a exception is raised. */
  laneForBuildStepName?: Maybe<Scalars['String']>;
  /** Suggests a review note that has all passcodes for a school's groups, and explains the relationship between the Developer account org and the school if needed. */
  suggestAppReviewerNotes?: Maybe<Scalars['String']>;
  /** Reads a single `App` using its globally unique `ID`. */
  appByNodeId?: Maybe<App>;
  /** Reads a single `Calendar` using its globally unique `ID`. */
  calendarByNodeId?: Maybe<Calendar>;
  /** Reads a single `CalendarEvent` using its globally unique `ID`. */
  calendarEventByNodeId?: Maybe<CalendarEvent>;
  /** Reads a single `CalendarGroup` using its globally unique `ID`. */
  calendarGroupByNodeId?: Maybe<CalendarGroup>;
  /** Reads a single `CalendarGroupCalendar` using its globally unique `ID`. */
  calendarGroupCalendarByNodeId?: Maybe<CalendarGroupCalendar>;
  /** Reads a single `Division` using its globally unique `ID`. */
  divisionByNodeId?: Maybe<Division>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>;
  /** Reads a single `GoogleAccount` using its globally unique `ID`. */
  googleAccountByNodeId?: Maybe<GoogleAccount>;
  /** Reads a single `GoogleCalendarSync` using its globally unique `ID`. */
  googleCalendarSyncByNodeId?: Maybe<GoogleCalendarSync>;
  /** Reads a single `GoogleCalendarSyncCalendar` using its globally unique `ID`. */
  googleCalendarSyncCalendarByNodeId?: Maybe<GoogleCalendarSyncCalendar>;
  /** Reads a single `Group` using its globally unique `ID`. */
  groupByNodeId?: Maybe<Group>;
  /** Reads a single `GroupPerson` using its globally unique `ID`. */
  groupPersonByNodeId?: Maybe<GroupPerson>;
  /** Reads a single `GroupCategory` using its globally unique `ID`. */
  groupCategoryByNodeId?: Maybe<GroupCategory>;
  /** Reads a single `GroupNewsletter` using its globally unique `ID`. */
  groupNewsletterByNodeId?: Maybe<GroupNewsletter>;
  /** Reads a single `Marquee` using its globally unique `ID`. */
  marqueeByNodeId?: Maybe<Marquee>;
  /** Reads a single `Newsletter` using its globally unique `ID`. */
  newsletterByNodeId?: Maybe<Newsletter>;
  /** Reads a single `Notification` using its globally unique `ID`. */
  notificationByNodeId?: Maybe<Notification>;
  /** Reads a single `NotificationFile` using its globally unique `ID`. */
  notificationFileByNodeId?: Maybe<NotificationFile>;
  /** Reads a single `NotificationNewsletter` using its globally unique `ID`. */
  notificationNewsletterByNodeId?: Maybe<NotificationNewsletter>;
  /** Reads a single `NotificationGroup` using its globally unique `ID`. */
  notificationGroupByNodeId?: Maybe<NotificationGroup>;
  /** Reads a single `Page` using its globally unique `ID`. */
  pageByNodeId?: Maybe<Page>;
  /** Reads a single `PageSection` using its globally unique `ID`. */
  pageSectionByNodeId?: Maybe<PageSection>;
  /** Reads a single `PageSectionFile` using its globally unique `ID`. */
  pageSectionFileByNodeId?: Maybe<PageSectionFile>;
  /** Reads a single `Person` using its globally unique `ID`. */
  personByNodeId?: Maybe<Person>;
  /** Reads a single `Resource` using its globally unique `ID`. */
  resourceByNodeId?: Maybe<Resource>;
  /** Reads a single `ResourceFile` using its globally unique `ID`. */
  resourceFileByNodeId?: Maybe<ResourceFile>;
  /** Reads a single `School` using its globally unique `ID`. */
  schoolByNodeId?: Maybe<School>;
  /** Reads a single `SchoolPerson` using its globally unique `ID`. */
  schoolPersonByNodeId?: Maybe<SchoolPerson>;
  /** Reads a single `SchoolPersonInstallation` using its globally unique `ID`. */
  schoolPersonInstallationByNodeId?: Maybe<SchoolPersonInstallation>;
  /** Reads a single `SchoolPersonInvitation` using its globally unique `ID`. */
  schoolPersonInvitationByNodeId?: Maybe<SchoolPersonInvitation>;
  /** Reads a single `SchoolSetting` using its globally unique `ID`. */
  schoolSettingByNodeId?: Maybe<SchoolSetting>;
  /** Reads a single `Sponsor` using its globally unique `ID`. */
  sponsorByNodeId?: Maybe<Sponsor>;
  /** Reads a single `Theme` using its globally unique `ID`. */
  themeByNodeId?: Maybe<Theme>;
  /** Reads a single `UserGoogleAccount` using its globally unique `ID`. */
  userGoogleAccountByNodeId?: Maybe<UserGoogleAccount>;
  /** Reads a single `UserSession` using its globally unique `ID`. */
  userSessionByNodeId?: Maybe<UserSession>;
  /** Reads a single `AndroidApp` using its globally unique `ID`. */
  androidAppByNodeId?: Maybe<AndroidApp>;
  /** Reads a single `AndroidJavaKeyStore` using its globally unique `ID`. */
  androidJavaKeyStoreByNodeId?: Maybe<AndroidJavaKeyStore>;
  /** Reads a single `AndroidScreenshot` using its globally unique `ID`. */
  androidScreenshotByNodeId?: Maybe<AndroidScreenshot>;
  /** Reads a single `AppStoreAccount` using its globally unique `ID`. */
  appStoreAccountByNodeId?: Maybe<AppStoreAccount>;
  /** Reads a single `AppStoreListing` using its globally unique `ID`. */
  appStoreListingByNodeId?: Maybe<AppStoreListing>;
  /** Reads a single `AppStoreUser` using its globally unique `ID`. */
  appStoreUserByNodeId?: Maybe<AppStoreUser>;
  /** Reads a single `Build` using its globally unique `ID`. */
  buildByNodeId?: Maybe<Build>;
  /** Reads a single `BuildStep` using its globally unique `ID`. */
  buildStepByNodeId?: Maybe<BuildStep>;
  /** Reads a single `GooglePlayAccount` using its globally unique `ID`. */
  googlePlayAccountByNodeId?: Maybe<GooglePlayAccount>;
  /** Reads a single `GooglePlayListing` using its globally unique `ID`. */
  googlePlayListingByNodeId?: Maybe<GooglePlayListing>;
  /** Reads a single `GooglePlayUser` using its globally unique `ID`. */
  googlePlayUserByNodeId?: Maybe<GooglePlayUser>;
  /** Reads a single `IosApp` using its globally unique `ID`. */
  iosAppByNodeId?: Maybe<IosApp>;
  /** Reads a single `IosScreenshot` using its globally unique `ID`. */
  iosScreenshotByNodeId?: Maybe<IosScreenshot>;
  /** Reads a single `Release` using its globally unique `ID`. */
  releaseByNodeId?: Maybe<Release>;
  googleOauthUrl: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
  condition?: Maybe<CalendarCondition>;
  filter?: Maybe<CalendarFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
  condition?: Maybe<CalendarEventCondition>;
  filter?: Maybe<CalendarEventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
  condition?: Maybe<CalendarGroupCalendarCondition>;
  filter?: Maybe<CalendarGroupCalendarFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
  condition?: Maybe<GoogleAccountCondition>;
  filter?: Maybe<GoogleAccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCondition>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
  condition?: Maybe<GoogleCalendarSyncCalendarCondition>;
  filter?: Maybe<GoogleCalendarSyncCalendarFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
  condition?: Maybe<GroupPersonCondition>;
  filter?: Maybe<GroupPersonFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
  condition?: Maybe<GroupCategoryCondition>;
  filter?: Maybe<GroupCategoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
  condition?: Maybe<GroupNewsletterCondition>;
  filter?: Maybe<GroupNewsletterFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMarqueesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
  condition?: Maybe<NewsletterCondition>;
  filter?: Maybe<NewsletterFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
  condition?: Maybe<NotificationCondition>;
  filter?: Maybe<NotificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
  condition?: Maybe<NotificationFileCondition>;
  filter?: Maybe<NotificationFileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationNewslettersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
  condition?: Maybe<NotificationNewsletterCondition>;
  filter?: Maybe<NotificationNewsletterFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
  condition?: Maybe<NotificationGroupCondition>;
  filter?: Maybe<NotificationGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PagesOrderBy>>;
  condition?: Maybe<PageCondition>;
  filter?: Maybe<PageFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
  condition?: Maybe<PageSectionCondition>;
  filter?: Maybe<PageSectionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
  condition?: Maybe<PageSectionFileCondition>;
  filter?: Maybe<PageSectionFileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInstallationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInvitationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
  condition?: Maybe<SchoolPersonInvitationCondition>;
  filter?: Maybe<SchoolPersonInvitationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
  condition?: Maybe<SchoolSettingCondition>;
  filter?: Maybe<SchoolSettingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryThemesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ThemesOrderBy>>;
  condition?: Maybe<ThemeCondition>;
  filter?: Maybe<ThemeFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
  condition?: Maybe<UserGoogleAccountCondition>;
  filter?: Maybe<UserGoogleAccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserSessionsOrderBy>>;
  condition?: Maybe<UserSessionCondition>;
  filter?: Maybe<UserSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
  condition?: Maybe<AndroidAppCondition>;
  filter?: Maybe<AndroidAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidJavaKeyStoresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
  condition?: Maybe<AndroidJavaKeyStoreCondition>;
  filter?: Maybe<AndroidJavaKeyStoreFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
  condition?: Maybe<AndroidScreenshotCondition>;
  filter?: Maybe<AndroidScreenshotFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
  condition?: Maybe<AppStoreAccountCondition>;
  filter?: Maybe<AppStoreAccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
  condition?: Maybe<AppStoreListingCondition>;
  filter?: Maybe<AppStoreListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
  condition?: Maybe<AppStoreUserCondition>;
  filter?: Maybe<AppStoreUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildStepsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
  condition?: Maybe<BuildStepCondition>;
  filter?: Maybe<BuildStepFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
  condition?: Maybe<GooglePlayAccountCondition>;
  filter?: Maybe<GooglePlayAccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
  condition?: Maybe<GooglePlayListingCondition>;
  filter?: Maybe<GooglePlayListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
  condition?: Maybe<GooglePlayUserCondition>;
  filter?: Maybe<GooglePlayUserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIosAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
  condition?: Maybe<IosAppCondition>;
  filter?: Maybe<IosAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIosScreenshotsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
  condition?: Maybe<IosScreenshotCondition>;
  filter?: Maybe<IosScreenshotFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReleasesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
  condition?: Maybe<ReleaseCondition>;
  filter?: Maybe<ReleaseFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppBySchoolIdArgs = {
  schoolId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarEventArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarEventByEventIdAndCalendarIdArgs = {
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupCalendarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupCalendarByCalendarIdAndCalendarGroupIdArgs = {
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByGoogleIdArgs = {
  googleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncCalendarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupPersonArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupPersonByGroupIdAndPersonIdArgs = {
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupCategoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupNewsletterArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupNewsletterByGroupIdAndNewsletterIdArgs = {
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMarqueeArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNewsletterArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationFileArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationFileByFileIdAndNotificationIdArgs = {
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationNewsletterArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationNewsletterByNotificationIdAndNewsletterIdArgs = {
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationGroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationGroupByNotificationIdAndGroupIdArgs = {
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionFileArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionFileByFileIdAndPageSectionIdArgs = {
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceFileArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceFileByFileIdAndResourceIdArgs = {
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonByPersonIdAndSchoolIdArgs = {
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInstallationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInvitationArgs = {
  schoolPersonId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInvitationBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolSettingArgs = {
  schoolId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsorArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsorBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryThemeArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGoogleAccountArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGoogleAccountByUserIdAndGoogleAccountIdArgs = {
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSessionArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidAppArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidAppByAppIdArgs = {
  appId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidJavaKeyStoreArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidScreenshotArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountByAppStoreConnectIdArgs = {
  appStoreConnectId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountByAppleDeveloperAccountIdArgs = {
  appleDeveloperAccountId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountByOrganizationNameArgs = {
  organizationName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreListingArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreListingByIosAppIdArgs = {
  iosAppId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreUserByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildStepArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayAccountArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayListingArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayListingByAndroidAppIdArgs = {
  androidAppId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayUserByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIosAppArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIosAppByAppIdArgs = {
  appId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIosScreenshotArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReleaseArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckAccountEmailArgs = {
  email: Scalars['Email'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckPersonEmailForSchoolArgs = {
  email: Scalars['Email'];
  schoolId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckTextNotEmptyArgs = {
  textValue: Scalars['String'];
  fieldName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckWithErrorMessageArgs = {
  validationCheck: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentPersonGoogleAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GoogleAccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentPersonSchoolCalendarEventsInWeekArgs = {
  schoolId: Scalars['UUID'];
  date: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionGroupIdsArgs = {
  divisionId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCalendarEventsForWeekArgs = {
  calendarIds: Array<Maybe<Scalars['UUID']>>;
  date: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGroupsForDivisionArgs = {
  divisionId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGroupsForSchoolArgs = {
  schoolId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetNewsletterBySlugArgs = {
  newsletterSlug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetNotificationsForSchoolForCurrentPersonArgs = {
  schoolId: Scalars['UUID'];
  asAdmin?: Maybe<Scalars['Boolean']>;
  asMember?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<NotificationsOrderByType>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<NotificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSchoolIdForGroupIdArgs = {
  vGroupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncForSchoolArgs = {
  schoolId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GoogleCalendarSyncFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupIdIsSchoolwideGroupArgs = {
  vGroupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupResourcesForCurrentPersonArgs = {
  groupId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ResourceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupResourcesForPersonArgs = {
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ResourceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvitationDetailArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNewslettersForSchoolForCurrentPersonArgs = {
  schoolId: Scalars['UUID'];
  publishedAfter: Scalars['Datetime'];
  publishedBefore: Scalars['Datetime'];
  asAdmin?: Maybe<Scalars['Boolean']>;
  asMember?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<NewsletterFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPasswordResetRequiredArgs = {
  email: Scalars['Email'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonGroupIdsForSchoolArgs = {
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
  asAdmin?: Maybe<Scalars['Boolean']>;
  asMember?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonMemberGroupIdsForSchoolArgs = {
  schoolId: Scalars['UUID'];
  personId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrepareToSendNotificationArgs = {
  notificationId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecipientCountArgs = {
  groupIds: Array<Maybe<Scalars['UUID']>>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolCalendarIdsForPersonArgs = {
  schoolId: Scalars['UUID'];
  personId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolNotificationsForCurrentPersonArgs = {
  schoolId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<NotificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolNotificationsForPersonArgs = {
  schoolId: Scalars['UUID'];
  personId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<NotificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolResourcesForPersonArgs = {
  schoolId: Scalars['UUID'];
  personId: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ResourceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetBitriseAppSlugForPlatformArgs = {
  vPlatformType: PlatformType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetEventcastVersionNumberForReleaseArgs = {
  vPlatform: PlatformType;
  vReleaseType: ReleaseType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetExternalReleaseNotesAfterThisReleaseArgs = {
  vReleaseId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLatestActiveBuildForAppForPlatformArgs = {
  vAppId: Scalars['UUID'];
  vPlatform: PlatformType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLatestActiveBuildStepForBuildArgs = {
  vBuildId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLatestBuildForAppForPlatformArgs = {
  vAppId: Scalars['UUID'];
  vPlatform: PlatformType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLatestLiveBuildForAppForPlatformArgs = {
  vAppId: Scalars['UUID'];
  vPlatform: PlatformType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLatestReleaseForPlatformArgs = {
  vPlatform: PlatformType;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetNextBuildStepOnFailureArgs = {
  vFailedBuildStepId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetNextBuildStepOnSuccessArgs = {
  vSuccessfulBuildStepId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetNextStartCurlArgs = {
  vBuildId: Scalars['UUID'];
  vGitBranch: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncrementVersionStringForReleaseTypeArgs = {
  vVersionNumber: Scalars['String'];
  vReleaseType: ReleaseType;
};


/** The root query type which gives access points into the data universe. */
export type QueryLaneForBuildStepNameArgs = {
  vBuildStepName: BuildStepName;
};


/** The root query type which gives access points into the data universe. */
export type QuerySuggestAppReviewerNotesArgs = {
  vSchoolId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCalendarGroupCalendarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarSyncCalendarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupPersonByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupCategoryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupNewsletterByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMarqueeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNewsletterByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationNewsletterByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageSectionFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryResourceFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInstallationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolPersonInvitationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySchoolSettingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySponsorByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryThemeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGoogleAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSessionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidJavaKeyStoreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAndroidScreenshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreListingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppStoreUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuildStepByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayListingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGooglePlayUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIosAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIosScreenshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReleaseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** All input for the `registerAnonymousUser` mutation. */
export type RegisterAnonymousUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  _email: Scalars['Email'];
  password: Scalars['String'];
};

/** The output of our `registerAnonymousUser` mutation. */
export type RegisterAnonymousUserPayload = {
  __typename?: 'RegisterAnonymousUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerUser` mutation. */
export type RegisterUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  _email: Scalars['Email'];
  password: Scalars['String'];
};

/** The output of our `registerUser` mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/**
 * `release`.\n\n
 *
 * This contains info that helps inform an individual app's `build` information.\n\n
 *
 * This table has no foreign keys, but is a foreign key on the `build` table.
 */
export type Release = Node & {
  __typename?: 'Release';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The primary key for `release`. */
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform: PlatformType;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType: ReleaseType;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion: Scalars['String'];
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse: Scalars['String'];
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse: Scalars['String'];
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
  /** Reads and enables pagination through a set of `App`. */
  appsByBuildReleaseIdAndAppId: ReleaseAppsByBuildReleaseIdAndAppIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildReleaseIdAndCreatedBy: ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByBuildReleaseIdAndUpdatedBy: ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyConnection;
};


/**
 * `release`.\n\n
 *
 * This contains info that helps inform an individual app's `build` information.\n\n
 *
 * This table has no foreign keys, but is a foreign key on the `build` table.
 */
export type ReleaseBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};


/**
 * `release`.\n\n
 *
 * This contains info that helps inform an individual app's `build` information.\n\n
 *
 * This table has no foreign keys, but is a foreign key on the `build` table.
 */
export type ReleaseAppsByBuildReleaseIdAndAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppsOrderBy>>;
  condition?: Maybe<AppCondition>;
  filter?: Maybe<AppFilter>;
};


/**
 * `release`.\n\n
 *
 * This contains info that helps inform an individual app's `build` information.\n\n
 *
 * This table has no foreign keys, but is a foreign key on the `build` table.
 */
export type ReleasePeopleByBuildReleaseIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * `release`.\n\n
 *
 * This contains info that helps inform an individual app's `build` information.\n\n
 *
 * This table has no foreign keys, but is a foreign key on the `build` table.
 */
export type ReleasePeopleByBuildReleaseIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A connection to a list of `App` values, with data from `Build`. */
export type ReleaseAppsByBuildReleaseIdAndAppIdManyToManyConnection = {
  __typename?: 'ReleaseAppsByBuildReleaseIdAndAppIdManyToManyConnection';
  /** A list of `App` objects. */
  nodes: Array<App>;
  /** A list of edges which contains the `App`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<ReleaseAppsByBuildReleaseIdAndAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `App` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `App` edge in the connection, with data from `Build`. */
export type ReleaseAppsByBuildReleaseIdAndAppIdManyToManyEdge = {
  __typename?: 'ReleaseAppsByBuildReleaseIdAndAppIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `App` at the end of the edge. */
  node: App;
  /** Reads and enables pagination through a set of `Build`. */
  builds: BuildsConnection;
};


/** A `App` edge in the connection, with data from `Build`. */
export type ReleaseAppsByBuildReleaseIdAndAppIdManyToManyEdgeBuildsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A condition to be used against `Release` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ReleaseCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `platform` field. */
  platform?: Maybe<PlatformType>;
  /** Checks for equality with the object’s `releaseType` field. */
  releaseType?: Maybe<ReleaseType>;
  /** Checks for equality with the object’s `eventcastVersion` field. */
  eventcastVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `releaseNotesForInternalUse` field. */
  releaseNotesForInternalUse?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `releaseNotesForExternalUse` field. */
  releaseNotesForExternalUse?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `githubReleaseUrl` field. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `release` in the `PersonInput` mutation. */
export type ReleaseCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `release` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectById?: Maybe<Array<ReleaseReleasePkeyConnect>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ReleaseNodeIdConnect>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteById?: Maybe<Array<ReleaseReleasePkeyDelete>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ReleaseNodeIdDelete>>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateById?: Maybe<Array<ReleaseOnReleaseForReleaseCreatedByFkeyUsingReleasePkeyUpdate>>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnReleaseForReleaseCreatedByFkeyNodeIdUpdate>>;
  /** A `ReleaseInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ReleaseCreatedByFkeyReleaseCreateInput>>;
};

/** The `release` to be created by this mutation. */
export type ReleaseCreatedByFkeyReleaseCreateInput = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform: PlatformType;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType: ReleaseType;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion: Scalars['String'];
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse: Scalars['String'];
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse: Scalars['String'];
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** A filter to be used against `Release` object types. All fields are combined with a logical ‘and.’ */
export type ReleaseFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `platform` field. */
  platform?: Maybe<PlatformTypeFilter>;
  /** Filter by the object’s `releaseType` field. */
  releaseType?: Maybe<ReleaseTypeFilter>;
  /** Filter by the object’s `eventcastVersion` field. */
  eventcastVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `releaseNotesForInternalUse` field. */
  releaseNotesForInternalUse?: Maybe<StringFilter>;
  /** Filter by the object’s `releaseNotesForExternalUse` field. */
  releaseNotesForExternalUse?: Maybe<StringFilter>;
  /** Filter by the object’s `githubReleaseUrl` field. */
  githubReleaseUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReleaseFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReleaseFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReleaseFilter>;
};

/** An input for mutations affecting `Release` */
export type ReleaseInput = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform: PlatformType;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType: ReleaseType;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion: Scalars['String'];
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse: Scalars['String'];
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse: Scalars['String'];
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReleaseNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `release` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReleaseNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `release` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReleaseOnBuildForBuildReleaseIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `build` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `build` being updated. */
  patch: BuildPatch;
};

/** The fields on `release` to look up the row to update. */
export type ReleaseOnBuildForBuildReleaseIdFkeyUsingReleasePkeyUpdate = {
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: UpdateReleaseOnBuildForBuildReleaseIdFkeyPatch;
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** The fields on `release` to look up the row to update. */
export type ReleaseOnReleaseForReleaseCreatedByFkeyUsingReleasePkeyUpdate = {
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: UpdateReleaseOnReleaseForReleaseCreatedByFkeyPatch;
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReleaseOnReleaseForReleaseUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `release` to look up the row to update. */
export type ReleaseOnReleaseForReleaseUpdatedByFkeyUsingReleasePkeyUpdate = {
  /** An object where the defined keys will be set on the `release` being updated. */
  patch: UpdateReleaseOnReleaseForReleaseUpdatedByFkeyPatch;
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** Represents an update to a `Release`. Fields that are set will be updated. */
export type ReleasePatch = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform?: Maybe<PlatformType>;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType?: Maybe<ReleaseType>;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion?: Maybe<Scalars['String']>;
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse?: Maybe<Scalars['String']>;
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse?: Maybe<Scalars['String']>;
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyConnection = {
  __typename?: 'ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyEdge = {
  __typename?: 'ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByCreatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndCreatedByManyToManyEdgeBuildsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** A connection to a list of `Person` values, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Build`, and the cursor to aid in pagination. */
  edges: Array<ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Build`. */
  buildsByUpdatedBy: BuildsConnection;
};


/** A `Person` edge in the connection, with data from `Build`. */
export type ReleasePeopleByBuildReleaseIdAndUpdatedByManyToManyEdgeBuildsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BuildsOrderBy>>;
  condition?: Maybe<BuildCondition>;
  filter?: Maybe<BuildFilter>;
};

/** The fields on `release` to look up the row to connect. */
export type ReleaseReleasePkeyConnect = {
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** The fields on `release` to look up the row to delete. */
export type ReleaseReleasePkeyDelete = {
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

export enum ReleaseType {
  Major = 'MAJOR',
  Minor = 'MINOR',
  BugFix = 'BUG_FIX'
}

/** A filter to be used against ReleaseType fields. All fields are combined with a logical ‘and.’ */
export type ReleaseTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ReleaseType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ReleaseType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ReleaseType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ReleaseType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ReleaseType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ReleaseType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ReleaseType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ReleaseType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ReleaseType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ReleaseType>;
};

/** Input for the nested mutation of `person` in the `ReleaseInput` mutation. */
export type ReleaseUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnReleaseForReleaseUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<ReleaseOnReleaseForReleaseUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<ReleaseUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `release` in the `PersonInput` mutation. */
export type ReleaseUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `release` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectById?: Maybe<Array<ReleaseReleasePkeyConnect>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ReleaseNodeIdConnect>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteById?: Maybe<Array<ReleaseReleasePkeyDelete>>;
  /** The primary key(s) for `release` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ReleaseNodeIdDelete>>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateById?: Maybe<Array<ReleaseOnReleaseForReleaseUpdatedByFkeyUsingReleasePkeyUpdate>>;
  /** The primary key(s) and patch data for `release` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnReleaseForReleaseUpdatedByFkeyNodeIdUpdate>>;
  /** A `ReleaseInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ReleaseUpdatedByFkeyReleaseCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type ReleaseUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `release` to be created by this mutation. */
export type ReleaseUpdatedByFkeyReleaseCreateInput = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform: PlatformType;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType: ReleaseType;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion: Scalars['String'];
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse: Scalars['String'];
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse: Scalars['String'];
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** A connection to a list of `Release` values. */
export type ReleasesConnection = {
  __typename?: 'ReleasesConnection';
  /** A list of `Release` objects. */
  nodes: Array<Release>;
  /** A list of edges which contains the `Release` and cursor to aid in pagination. */
  edges: Array<ReleasesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Release` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Release` edge in the connection. */
export type ReleasesEdge = {
  __typename?: 'ReleasesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Release` at the end of the edge. */
  node: Release;
};

/** Methods to use when ordering `Release`. */
export enum ReleasesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  PlatformAsc = 'PLATFORM_ASC',
  PlatformDesc = 'PLATFORM_DESC',
  ReleaseTypeAsc = 'RELEASE_TYPE_ASC',
  ReleaseTypeDesc = 'RELEASE_TYPE_DESC',
  EventcastVersionAsc = 'EVENTCAST_VERSION_ASC',
  EventcastVersionDesc = 'EVENTCAST_VERSION_DESC',
  ReleaseNotesForInternalUseAsc = 'RELEASE_NOTES_FOR_INTERNAL_USE_ASC',
  ReleaseNotesForInternalUseDesc = 'RELEASE_NOTES_FOR_INTERNAL_USE_DESC',
  ReleaseNotesForExternalUseAsc = 'RELEASE_NOTES_FOR_EXTERNAL_USE_ASC',
  ReleaseNotesForExternalUseDesc = 'RELEASE_NOTES_FOR_EXTERNAL_USE_DESC',
  GithubReleaseUrlAsc = 'GITHUB_RELEASE_URL_ASC',
  GithubReleaseUrlDesc = 'GITHUB_RELEASE_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `removeFacultyStaffPersonFromSchool` mutation. */
export type RemoveFacultyStaffPersonFromSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The output of our `removeFacultyStaffPersonFromSchool` mutation. */
export type RemoveFacultyStaffPersonFromSchoolPayload = {
  __typename?: 'RemoveFacultyStaffPersonFromSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `removeGoogleAccount` mutation. */
export type RemoveGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleAccountId: Scalars['UUID'];
  deleteEvents: Scalars['Boolean'];
};

/** The output of our `removeGoogleAccount` mutation. */
export type RemoveGoogleAccountPayload = {
  __typename?: 'RemoveGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetGoogleCalendarSync` mutation. */
export type ResetGoogleCalendarSyncInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  googleCalendarId: Scalars['String'];
};

/** The output of our `resetGoogleCalendarSync` mutation. */
export type ResetGoogleCalendarSyncPayload = {
  __typename?: 'ResetGoogleCalendarSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetPassword` mutation. */
export type ResetPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
};

/** The output of our `resetPassword` mutation. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type Resource = Node & {
  __typename?: 'Resource';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published: Scalars['Boolean'];
  type: ResourceType;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder: Scalars['Int'];
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<Buttons>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes: Array<Maybe<UserType>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Resource`. */
  group?: Maybe<Group>;
  /** Reads a single `Resource` that is related to this `Resource`. */
  resourceByParentResource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `Resource`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  tileImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultCoverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultThumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  thumbnailImage?: Maybe<File>;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByParentResource: ResourcesConnection;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFiles: ResourceFilesConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceParentResourceAndCreatedBy: ResourcePeopleByResourceParentResourceAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceParentResourceAndUpdatedBy: ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByResourceParentResourceAndGroupId: ResourceGroupsByResourceParentResourceAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceParentResourceAndCoverImageFileId: ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceParentResourceAndTileImageFileId: ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceParentResourceAndDefaultCoverImageFileId: ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceParentResourceAndDefaultThumbnailImageFileId: ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceParentResourceAndThumbnailImageFileId: ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByResourceFileResourceIdAndFileId: ResourceFilesByResourceFileResourceIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileResourceIdAndCreatedBy: ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByResourceFileResourceIdAndUpdatedBy: ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyConnection;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceResourcesByParentResourceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceResourceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourcePeopleByResourceParentResourceAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourcePeopleByResourceParentResourceAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceGroupsByResourceParentResourceAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceParentResourceAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceParentResourceAndTileImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceParentResourceAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourceFilesByResourceFileResourceIdAndFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourcePeopleByResourceFileResourceIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Resources are basic information that can be organized similar to a website. Resources can be a link, a page, or a folder of other resources. Resource folders can be nested indefinitely to allow flexible organization. */
export type ResourcePeopleByResourceFileResourceIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `Resource` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ResourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `published` field. */
  published?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<ResourceType>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `header` field. */
  header?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentResource` field. */
  parentResource?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tileImageFileId` field. */
  tileImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `defaultCoverImageFileId` field. */
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `defaultThumbnailImageFileId` field. */
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userTypes` field. */
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  /** Checks for equality with the object’s `disableTileOverlay` field. */
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `embedUrl` field. */
  embedUrl?: Maybe<Scalars['String']>;
};

/** The `file` to be created by this mutation. */
export type ResourceCoverImageIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceInput` mutation. */
export type ResourceCoverImageIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceForResourceCoverImageIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceCoverImageIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceCoverImageIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resource` in the `FileInput` mutation. */
export type ResourceCoverImageIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceCoverImageIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceCoverImageIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceForResourceCoverImageIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceCoverImageIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceCoverImageIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** Input for the nested mutation of `resource` in the `PersonInput` mutation. */
export type ResourceCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceCreatedByFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceCreatedByFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnResourceForResourceCreatedByFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceCreatedByFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceCreatedByFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** The `file` to be created by this mutation. */
export type ResourceDefaultCoverImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceInput` mutation. */
export type ResourceDefaultCoverImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceForResourceDefaultCoverImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceDefaultCoverImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resource` in the `FileInput` mutation. */
export type ResourceDefaultCoverImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceForResourceDefaultCoverImageFileIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceDefaultCoverImageFileIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceDefaultCoverImageFileIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** The `file` to be created by this mutation. */
export type ResourceDefaultThumbnailImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceInput` mutation. */
export type ResourceDefaultThumbnailImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resource` in the `FileInput` mutation. */
export type ResourceDefaultThumbnailImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceForResourceDefaultThumbnailImageFileIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceDefaultThumbnailImageFileIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceDefaultThumbnailImageFileIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** `File`s for a `Resource`. */
export type ResourceFile = Node & {
  __typename?: 'ResourceFile';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  resourceId: Scalars['UUID'];
  fileId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  /** Reads a single `Resource` that is related to this `ResourceFile`. */
  resource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `ResourceFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `ResourceFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ResourceFileCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `resourceId` field. */
  resourceId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input for the nested mutation of `resourceFile` in the `PersonInput` mutation. */
export type ResourceFileCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `resourceFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceFileResourceFilePkeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceFileNodeIdConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceFileResourceFilePkeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceFileOnResourceFileForResourceFileCreatedByFkeyUsingResourceFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByFileIdAndResourceId?: Maybe<Array<ResourceFileOnResourceFileForResourceFileCreatedByFkeyUsingResourceFileFileIdResourceIdKeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnResourceFileForResourceFileCreatedByFkeyNodeIdUpdate>>;
  /** A `ResourceFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceFileCreatedByFkeyResourceFileCreateInput>>;
};

/** The `resourceFile` to be created by this mutation. */
export type ResourceFileCreatedByFkeyResourceFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type ResourceFileFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceFileInput` mutation. */
export type ResourceFileFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceFileForResourceFileFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceFileOnResourceFileForResourceFileFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceFileFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resourceFile` in the `FileInput` mutation. */
export type ResourceFileFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `resourceFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceFileResourceFilePkeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceFileNodeIdConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceFileResourceFilePkeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceFileOnResourceFileForResourceFileFileIdFkeyUsingResourceFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByFileIdAndResourceId?: Maybe<Array<ResourceFileOnResourceFileForResourceFileFileIdFkeyUsingResourceFileFileIdResourceIdKeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceFileForResourceFileFileIdFkeyNodeIdUpdate>>;
  /** A `ResourceFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceFileFileIdFkeyResourceFileCreateInput>>;
};

/** The `resourceFile` to be created by this mutation. */
export type ResourceFileFileIdFkeyResourceFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** A filter to be used against `ResourceFile` object types. All fields are combined with a logical ‘and.’ */
export type ResourceFileFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `resourceId` field. */
  resourceId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ResourceFileFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ResourceFileFilter>>;
  /** Negates the expression. */
  not?: Maybe<ResourceFileFilter>;
};

/** An input for mutations affecting `ResourceFile` */
export type ResourceFileInput = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ResourceFileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ResourceFileNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileCreatedByFkeyUsingResourceFileFileIdResourceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileCreatedByFkeyPatch;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileCreatedByFkeyUsingResourceFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceFileOnResourceFileForResourceFileFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileFileIdFkeyUsingResourceFileFileIdResourceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileFileIdFkeyPatch;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileFileIdFkeyUsingResourceFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceFileOnResourceFileForResourceFileResourceIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileResourceIdFkeyUsingResourceFileFileIdResourceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileResourceIdFkeyPatch;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileResourceIdFkeyUsingResourceFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileResourceIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceFileOnResourceFileForResourceFileUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileUpdatedByFkeyUsingResourceFileFileIdResourceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileUpdatedByFkeyPatch;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to update. */
export type ResourceFileOnResourceFileForResourceFileUpdatedByFkeyUsingResourceFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: UpdateResourceFileOnResourceFileForResourceFileUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `ResourceFile`. Fields that are set will be updated. */
export type ResourceFilePatch = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** The fields on `resourceFile` to look up the row to connect. */
export type ResourceFileResourceFileFileIdResourceIdKeyConnect = {
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to delete. */
export type ResourceFileResourceFileFileIdResourceIdKeyDelete = {
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to connect. */
export type ResourceFileResourceFilePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `resourceFile` to look up the row to delete. */
export type ResourceFileResourceFilePkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `resource` in the `ResourceFileInput` mutation. */
export type ResourceFileResourceIdFkeyInput = {
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<ResourceResourcePkeyConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<ResourceResourceSlugKeyConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<ResourceNodeIdConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<ResourceResourcePkeyDelete>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<ResourceResourceSlugKeyDelete>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ResourceNodeIdDelete>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<ResourceOnResourceFileForResourceFileResourceIdFkeyUsingResourcePkeyUpdate>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<ResourceOnResourceFileForResourceFileResourceIdFkeyUsingResourceSlugKeyUpdate>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceFileOnResourceFileForResourceFileResourceIdFkeyNodeIdUpdate>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceFileResourceIdFkeyResourceCreateInput>;
};

/** Input for the nested mutation of `resourceFile` in the `ResourceInput` mutation. */
export type ResourceFileResourceIdFkeyInverseInput = {
  /** Flag indicating whether all other `resourceFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceFileResourceFilePkeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceFileNodeIdConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceFileResourceFilePkeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceFileOnResourceFileForResourceFileResourceIdFkeyUsingResourceFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByFileIdAndResourceId?: Maybe<Array<ResourceFileOnResourceFileForResourceFileResourceIdFkeyUsingResourceFileFileIdResourceIdKeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ResourceOnResourceFileForResourceFileResourceIdFkeyNodeIdUpdate>>;
  /** A `ResourceFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceFileResourceIdFkeyResourceFileCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceFileResourceIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** The `resourceFile` to be created by this mutation. */
export type ResourceFileResourceIdFkeyResourceFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `ResourceFileInput` mutation. */
export type ResourceFileUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnResourceFileForResourceFileUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceFileOnResourceFileForResourceFileUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceFileUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `resourceFile` in the `PersonInput` mutation. */
export type ResourceFileUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `resourceFile` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceFileResourceFilePkeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceFileNodeIdConnect>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceFileResourceFilePkeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByFileIdAndResourceId?: Maybe<Array<ResourceFileResourceFileFileIdResourceIdKeyDelete>>;
  /** The primary key(s) for `resourceFile` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceFileNodeIdDelete>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceFileOnResourceFileForResourceFileUpdatedByFkeyUsingResourceFilePkeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByFileIdAndResourceId?: Maybe<Array<ResourceFileOnResourceFileForResourceFileUpdatedByFkeyUsingResourceFileFileIdResourceIdKeyUpdate>>;
  /** The primary key(s) and patch data for `resourceFile` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnResourceFileForResourceFileUpdatedByFkeyNodeIdUpdate>>;
  /** A `ResourceFileInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceFileUpdatedByFkeyResourceFileCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type ResourceFileUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `resourceFile` to be created by this mutation. */
export type ResourceFileUpdatedByFkeyResourceFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  sortOrder: Scalars['Int'];
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** A connection to a list of `File` values, with data from `ResourceFile`. */
export type ResourceFilesByResourceFileResourceIdAndFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceFileResourceIdAndFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceFileResourceIdAndFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `ResourceFile`. */
export type ResourceFilesByResourceFileResourceIdAndFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceFileResourceIdAndFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder: Scalars['Int'];
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndCoverImageFileIdManyToManyEdgeResourceCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultCoverImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultCoverImageFileIdManyToManyEdgeResourceDefaultCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceDefaultThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndDefaultThumbnailImageFileIdManyToManyEdgeResourceDefaultThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceThumbnailImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndThumbnailImageFileIdManyToManyEdgeResourceThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `File` values, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyConnection = {
  __typename?: 'ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyEdge = {
  __typename?: 'ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Resource`. */
  resourceTileImages: ResourcesConnection;
};


/** A `File` edge in the connection, with data from `Resource`. */
export type ResourceFilesByResourceParentResourceAndTileImageFileIdManyToManyEdgeResourceTileImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `ResourceFile` values. */
export type ResourceFilesConnection = {
  __typename?: 'ResourceFilesConnection';
  /** A list of `ResourceFile` objects. */
  nodes: Array<ResourceFile>;
  /** A list of edges which contains the `ResourceFile` and cursor to aid in pagination. */
  edges: Array<ResourceFilesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ResourceFile` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ResourceFile` edge in the connection. */
export type ResourceFilesEdge = {
  __typename?: 'ResourceFilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ResourceFile` at the end of the edge. */
  node: ResourceFile;
};

/** Methods to use when ordering `ResourceFile`. */
export enum ResourceFilesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ResourceIdAsc = 'RESOURCE_ID_ASC',
  ResourceIdDesc = 'RESOURCE_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `Resource` object types. All fields are combined with a logical ‘and.’ */
export type ResourceFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `published` field. */
  published?: Maybe<BooleanFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<ResourceTypeFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `header` field. */
  header?: Maybe<BooleanFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: Maybe<StringFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentResource` field. */
  parentResource?: Maybe<UuidFilter>;
  /** Filter by the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `tileImageFileId` field. */
  tileImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `defaultCoverImageFileId` field. */
  defaultCoverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `defaultThumbnailImageFileId` field. */
  defaultThumbnailImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userTypes` field. */
  userTypes?: Maybe<UserTypeListFilter>;
  /** Filter by the object’s `disableTileOverlay` field. */
  disableTileOverlay?: Maybe<BooleanFilter>;
  /** Filter by the object’s `embedUrl` field. */
  embedUrl?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ResourceFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ResourceFilter>>;
  /** Negates the expression. */
  not?: Maybe<ResourceFilter>;
};

/** The `group` to be created by this mutation. */
export type ResourceGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName: Scalars['String'];
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `group` in the `ResourceInput` mutation. */
export type ResourceGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectBySlug?: Maybe<GroupGroupSlugIdxConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteById?: Maybe<GroupGroupPkeyDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteBySlug?: Maybe<GroupGroupSlugIdxDelete>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GroupNodeIdDelete>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnResourceForResourceGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateBySlug?: Maybe<GroupOnResourceForResourceGroupIdFkeyUsingGroupSlugIdxUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `resource` in the `GroupInput` mutation. */
export type ResourceGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceGroupIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceGroupIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnResourceForResourceGroupIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceGroupIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceGroupIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** A connection to a list of `Group` values, with data from `Resource`. */
export type ResourceGroupsByResourceParentResourceAndGroupIdManyToManyConnection = {
  __typename?: 'ResourceGroupsByResourceParentResourceAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourceGroupsByResourceParentResourceAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Resource`. */
export type ResourceGroupsByResourceParentResourceAndGroupIdManyToManyEdge = {
  __typename?: 'ResourceGroupsByResourceParentResourceAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Resource`. */
  resources: ResourcesConnection;
};


/** A `Group` edge in the connection, with data from `Resource`. */
export type ResourceGroupsByResourceParentResourceAndGroupIdManyToManyEdgeResourcesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** An input for mutations affecting `Resource` */
export type ResourceInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ResourceNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ResourceNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `resource` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceFileForResourceFileResourceIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resourceFile` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resourceFile` being updated. */
  patch: ResourceFilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceFileForResourceFileResourceIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceFileForResourceFileResourceIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceFileForResourceFileResourceIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceFileForResourceFileResourceIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceCoverImageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceCoverImageIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceCoverImageIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceCoverImageIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceCoverImageIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceCreatedByFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceCreatedByFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceDefaultCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceDefaultCoverImageFileIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceDefaultCoverImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceGroupIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceGroupIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceGroupIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceGroupIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceParentResourceFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `resource` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: ResourcePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceParentResourceFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceParentResourceFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceParentResourceFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceParentResourceFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceThumbnailImageFileIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceThumbnailImageFileIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceThumbnailImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceTileImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceTileImageFileIdFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceTileImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceTileImageFileIdFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceTileImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type ResourceOnResourceForResourceUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceUpdatedByFkeyUsingResourcePkeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to update. */
export type ResourceOnResourceForResourceUpdatedByFkeyUsingResourceSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `resource` being updated. */
  patch: UpdateResourceOnResourceForResourceUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Input for the nested mutation of `resource` in the `ResourceInput` mutation. */
export type ResourceParentResourceFkeyInput = {
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<ResourceResourcePkeyConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<ResourceResourceSlugKeyConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<ResourceNodeIdConnect>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<ResourceResourcePkeyDelete>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<ResourceResourceSlugKeyDelete>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ResourceNodeIdDelete>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<ResourceOnResourceForResourceParentResourceFkeyUsingResourcePkeyUpdate>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<ResourceOnResourceForResourceParentResourceFkeyUsingResourceSlugKeyUpdate>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceParentResourceFkeyNodeIdUpdate>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceParentResourceFkeyResourceCreateInput>;
};

/** The `resource` to be created by this mutation. */
export type ResourceParentResourceFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** Represents an update to a `Resource`. Fields that are set will be updated. */
export type ResourcePatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyConnection = {
  __typename?: 'ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyEdge = {
  __typename?: 'ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByCreatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndCreatedByManyToManyEdgeResourceFilesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `ResourceFile`, and the cursor to aid in pagination. */
  edges: Array<ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `ResourceFile`. */
  resourceFilesByUpdatedBy: ResourceFilesConnection;
};


/** A `Person` edge in the connection, with data from `ResourceFile`. */
export type ResourcePeopleByResourceFileResourceIdAndUpdatedByManyToManyEdgeResourceFilesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
  condition?: Maybe<ResourceFileCondition>;
  filter?: Maybe<ResourceFileFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndCreatedByManyToManyConnection = {
  __typename?: 'ResourcePeopleByResourceParentResourceAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourcePeopleByResourceParentResourceAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndCreatedByManyToManyEdge = {
  __typename?: 'ResourcePeopleByResourceParentResourceAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByCreatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndCreatedByManyToManyEdgeResourcesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** A connection to a list of `Person` values, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyConnection = {
  __typename?: 'ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Resource`, and the cursor to aid in pagination. */
  edges: Array<ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyEdge = {
  __typename?: 'ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Resource`. */
  resourcesByUpdatedBy: ResourcesConnection;
};


/** A `Person` edge in the connection, with data from `Resource`. */
export type ResourcePeopleByResourceParentResourceAndUpdatedByManyToManyEdgeResourcesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
  condition?: Maybe<ResourceCondition>;
  filter?: Maybe<ResourceFilter>;
};

/** The fields on `resource` to look up the row to connect. */
export type ResourceResourcePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to delete. */
export type ResourceResourcePkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `resource` to look up the row to connect. */
export type ResourceResourceSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `resource` to look up the row to delete. */
export type ResourceResourceSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The `file` to be created by this mutation. */
export type ResourceThumbnailImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceInput` mutation. */
export type ResourceThumbnailImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceForResourceThumbnailImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceThumbnailImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceThumbnailImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resource` in the `FileInput` mutation. */
export type ResourceThumbnailImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceThumbnailImageFileIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceThumbnailImageFileIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceForResourceThumbnailImageFileIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceThumbnailImageFileIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceThumbnailImageFileIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** The `file` to be created by this mutation. */
export type ResourceTileImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `ResourceInput` mutation. */
export type ResourceTileImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnResourceForResourceTileImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceTileImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceTileImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `resource` in the `FileInput` mutation. */
export type ResourceTileImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceTileImageFileIdFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceTileImageFileIdFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnResourceForResourceTileImageFileIdFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceTileImageFileIdFkeyResourceCreateInput>>;
};

/** The `resource` to be created by this mutation. */
export type ResourceTileImageFileIdFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

export enum ResourceType {
  Folder = 'FOLDER',
  Page = 'PAGE',
  Link = 'LINK',
  InternalLink = 'INTERNAL_LINK'
}

/** A filter to be used against ResourceType fields. All fields are combined with a logical ‘and.’ */
export type ResourceTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ResourceType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ResourceType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ResourceType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ResourceType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ResourceType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ResourceType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ResourceType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ResourceType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ResourceType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ResourceType>;
};

/** Input for the nested mutation of `person` in the `ResourceInput` mutation. */
export type ResourceUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnResourceForResourceUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<ResourceOnResourceForResourceUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<ResourceUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `resource` in the `PersonInput` mutation. */
export type ResourceUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `resource` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectById?: Maybe<Array<ResourceResourcePkeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<ResourceResourceSlugKeyConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ResourceNodeIdConnect>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteById?: Maybe<Array<ResourceResourcePkeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<ResourceResourceSlugKeyDelete>>;
  /** The primary key(s) for `resource` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ResourceNodeIdDelete>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateById?: Maybe<Array<ResourceOnResourceForResourceUpdatedByFkeyUsingResourcePkeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<ResourceOnResourceForResourceUpdatedByFkeyUsingResourceSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `resource` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnResourceForResourceUpdatedByFkeyNodeIdUpdate>>;
  /** A `ResourceInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ResourceUpdatedByFkeyResourceCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type ResourceUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `resource` to be created by this mutation. */
export type ResourceUpdatedByFkeyResourceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** A connection to a list of `Resource` values. */
export type ResourcesConnection = {
  __typename?: 'ResourcesConnection';
  /** A list of `Resource` objects. */
  nodes: Array<Resource>;
  /** A list of edges which contains the `Resource` and cursor to aid in pagination. */
  edges: Array<ResourcesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Resource` edge in the connection. */
export type ResourcesEdge = {
  __typename?: 'ResourcesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Resource` at the end of the edge. */
  node: Resource;
};

/** Methods to use when ordering `Resource`. */
export enum ResourcesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  HeaderAsc = 'HEADER_ASC',
  HeaderDesc = 'HEADER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  ParentResourceAsc = 'PARENT_RESOURCE_ASC',
  ParentResourceDesc = 'PARENT_RESOURCE_DESC',
  CoverImageFileIdAsc = 'COVER_IMAGE_FILE_ID_ASC',
  CoverImageFileIdDesc = 'COVER_IMAGE_FILE_ID_DESC',
  TileImageFileIdAsc = 'TILE_IMAGE_FILE_ID_ASC',
  TileImageFileIdDesc = 'TILE_IMAGE_FILE_ID_DESC',
  DefaultCoverImageFileIdAsc = 'DEFAULT_COVER_IMAGE_FILE_ID_ASC',
  DefaultCoverImageFileIdDesc = 'DEFAULT_COVER_IMAGE_FILE_ID_DESC',
  DefaultThumbnailImageFileIdAsc = 'DEFAULT_THUMBNAIL_IMAGE_FILE_ID_ASC',
  DefaultThumbnailImageFileIdDesc = 'DEFAULT_THUMBNAIL_IMAGE_FILE_ID_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  ThumbnailImageFileIdAsc = 'THUMBNAIL_IMAGE_FILE_ID_ASC',
  ThumbnailImageFileIdDesc = 'THUMBNAIL_IMAGE_FILE_ID_DESC',
  UserTypesAsc = 'USER_TYPES_ASC',
  UserTypesDesc = 'USER_TYPES_DESC',
  DisableTileOverlayAsc = 'DISABLE_TILE_OVERLAY_ASC',
  DisableTileOverlayDesc = 'DISABLE_TILE_OVERLAY_DESC',
  EmbedUrlAsc = 'EMBED_URL_ASC',
  EmbedUrlDesc = 'EMBED_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ScheduleSettingsResetInput = {
  schoolId: Scalars['UUID'];
  userType: UserType;
  scheduleDate: Scalars['Datetime'];
};

/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type School = Node & {
  __typename?: 'School';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived: Scalars['Boolean'];
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy: Scalars['Boolean'];
  pushEnabled: Scalars['Boolean'];
  /** Reads a single `Person` that is related to this `School`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `School`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Theme` that is related to this `School`. */
  theme?: Maybe<Theme>;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroups: CalendarGroupsConnection;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeople: SchoolPeopleConnection;
  /** Reads a single `SchoolSetting` that is related to this `School`. */
  schoolSetting?: Maybe<SchoolSetting>;
  /** Reads a single `App` that is related to this `School`. */
  app?: Maybe<App>;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
  /** Reads and enables pagination through a set of `Marquee`. */
  marquees: MarqueesConnection;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsors: SponsorsConnection;
  androidAppDownloadUrl?: Maybe<Scalars['String']>;
  androidAppIsLatest?: Maybe<Scalars['Boolean']>;
  /** Check if a school's app has a live build for the latest release. */
  appIsLatest?: Maybe<Scalars['Boolean']>;
  appleDeveloperAccountExpiry?: Maybe<Scalars['Datetime']>;
  /** The Group from the schoolwide Division. */
  group?: Maybe<Group>;
  iosAppDownloadUrl?: Maybe<Scalars['String']>;
  iosAppIsLatest?: Maybe<Scalars['Boolean']>;
  iosPushCertExpiry?: Maybe<Scalars['Datetime']>;
  /** Checks to see if a school's app has been launched on iOS yet. */
  isLaunched?: Maybe<Scalars['Boolean']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  /** The `schoolwideLabel` for this `School` (the `name` of the `schoolwide` `Division` serves as the `schoolwideLabel`). */
  schoolwideLabel?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupSchoolIdAndCreatedBy: SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByCalendarGroupSchoolIdAndUpdatedBy: SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonSchoolIdAndPersonId: SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonSchoolIdAndCreatedBy: SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonSchoolIdAndUpdatedBy: SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionSchoolIdAndCreatedBy: SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByDivisionSchoolIdAndUpdatedBy: SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByDivisionSchoolIdAndGroupId: SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeSchoolIdAndCreatedBy: SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByMarqueeSchoolIdAndUpdatedBy: SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByMarqueeSchoolIdAndMarqueeImageFileId: SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorSchoolIdAndCreatedBy: SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySponsorSchoolIdAndUpdatedBy: SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorSchoolIdAndCoverImageFileId: SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorSchoolIdAndThumbnailImageFileId: SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorSchoolIdAndLogoImageFileId: SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesBySponsorSchoolIdAndPromoImageFileId: SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyConnection;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolCalendarGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolSchoolPeopleArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolMarqueesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolSponsorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByCalendarGroupSchoolIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleBySchoolPersonSchoolIdAndPersonIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleBySchoolPersonSchoolIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByDivisionSchoolIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByDivisionSchoolIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolGroupsByDivisionSchoolIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByMarqueeSchoolIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleByMarqueeSchoolIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleBySponsorSchoolIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolPeopleBySponsorSchoolIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolFilesBySponsorSchoolIdAndCoverImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolFilesBySponsorSchoolIdAndLogoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};


/**
 * Stores basic information about a `School`. <br/><br/>
 *
 * Try to keep it from getting too overloaded. When possible break things out into their own tables so that `School` doesn't become a giant hodgepodge of random information. <br/><br/>
 *
 * We specifically have a `SchoolSetting` table to contain general settings for the school that can be edited and controlled by the admins on the `School` itself. <br/><br/>
 *
 * **NOTE**: we do not yet support `DELETE` at all, by anyone, no matter how high your permissions.
 */
export type SchoolFilesBySponsorSchoolIdAndPromoImageFileIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FilesOrderBy>>;
  condition?: Maybe<FileCondition>;
  filter?: Maybe<FileFilter>;
};

/** A condition to be used against `School` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SchoolCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address1` field. */
  address1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address2` field. */
  address2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `information` field. */
  information?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `themeId` field. */
  themeId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deploy` field. */
  deploy?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `pushEnabled` field. */
  pushEnabled?: Maybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `school` in the `PersonInput` mutation. */
export type SchoolCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `school` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolSchoolPkeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SchoolSchoolSlugKeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolNodeIdConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolSchoolPkeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SchoolSchoolSlugKeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolNodeIdDelete>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolOnSchoolForSchoolCreatedByFkeyUsingSchoolPkeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SchoolOnSchoolForSchoolCreatedByFkeyUsingSchoolSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolForSchoolCreatedByFkeyNodeIdUpdate>>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolCreatedByFkeySchoolCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type SchoolCreatedByFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** A connection to a list of `File` values, with data from `Marquee`. */
export type SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyConnection = {
  __typename?: 'SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Marquee`. */
export type SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyEdge = {
  __typename?: 'SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueeImages: MarqueesConnection;
};


/** A `File` edge in the connection, with data from `Marquee`. */
export type SchoolFilesByMarqueeSchoolIdAndMarqueeImageFileIdManyToManyEdgeMarqueeImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyConnection = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyEdge = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorCoverImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndCoverImageFileIdManyToManyEdgeSponsorCoverImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyConnection = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyEdge = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorLogoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndLogoImageFileIdManyToManyEdgeSponsorLogoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyConnection = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyEdge = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorPromoImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndPromoImageFileIdManyToManyEdgeSponsorPromoImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `File` values, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyConnection = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyConnection';
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** A list of edges which contains the `File`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyEdge = {
  __typename?: 'SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorThumbnailImages: SponsorsConnection;
};


/** A `File` edge in the connection, with data from `Sponsor`. */
export type SchoolFilesBySponsorSchoolIdAndThumbnailImageFileIdManyToManyEdgeSponsorThumbnailImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A filter to be used against `School` object types. All fields are combined with a logical ‘and.’ */
export type SchoolFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `address1` field. */
  address1?: Maybe<StringFilter>;
  /** Filter by the object’s `address2` field. */
  address2?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<StringFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: Maybe<StringFilter>;
  /** Filter by the object’s `information` field. */
  information?: Maybe<StringFilter>;
  /** Filter by the object’s `themeId` field. */
  themeId?: Maybe<UuidFilter>;
  /** Filter by the object’s `deploy` field. */
  deploy?: Maybe<BooleanFilter>;
  /** Filter by the object’s `pushEnabled` field. */
  pushEnabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `androidAppDownloadUrl` field. */
  androidAppDownloadUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `androidAppIsLatest` field. */
  androidAppIsLatest?: Maybe<BooleanFilter>;
  /** Filter by the object’s `appIsLatest` field. */
  appIsLatest?: Maybe<BooleanFilter>;
  /** Filter by the object’s `appleDeveloperAccountExpiry` field. */
  appleDeveloperAccountExpiry?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `iosAppDownloadUrl` field. */
  iosAppDownloadUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `iosAppIsLatest` field. */
  iosAppIsLatest?: Maybe<BooleanFilter>;
  /** Filter by the object’s `iosPushCertExpiry` field. */
  iosPushCertExpiry?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isLaunched` field. */
  isLaunched?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isMigrated` field. */
  isMigrated?: Maybe<BooleanFilter>;
  /** Filter by the object’s `schoolwideLabel` field. */
  schoolwideLabel?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SchoolFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SchoolFilter>>;
  /** Negates the expression. */
  not?: Maybe<SchoolFilter>;
};

/** All input for the `schoolGroupIds` mutation. */
export type SchoolGroupIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolId: Scalars['UUID'];
};

/** The output of our `schoolGroupIds` mutation. */
export type SchoolGroupIdsPayload = {
  __typename?: 'SchoolGroupIdsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `Group` values, with data from `Division`. */
export type SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyConnection = {
  __typename?: 'SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Division`. */
export type SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyEdge = {
  __typename?: 'SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: DivisionsConnection;
};


/** A `Group` edge in the connection, with data from `Division`. */
export type SchoolGroupsByDivisionSchoolIdAndGroupIdManyToManyEdgeDivisionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** An input for mutations affecting `School` */
export type SchoolInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SchoolNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SchoolNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `school` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnAppForAppSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `app` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `app` being updated. */
  patch: AppPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnAppForAppSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnAppForAppSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnAppForAppSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnAppForAppSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `calendarGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `calendarGroup` being updated. */
  patch: CalendarGroupPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnDivisionForDivisionSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `division` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `division` being updated. */
  patch: DivisionPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnDivisionForDivisionSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnDivisionForDivisionSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnDivisionForDivisionSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnDivisionForDivisionSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnMarqueeForMarqueeSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `marquee` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `marquee` being updated. */
  patch: MarqueePatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnMarqueeForMarqueeSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnMarqueeForMarqueeSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnMarqueeForMarqueeSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnMarqueeForMarqueeSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolCreatedByFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolCreatedByFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnSchoolForSchoolThemeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `theme` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: ThemePatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolThemeIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolThemeIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolThemeIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolThemeIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnSchoolForSchoolUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolUpdatedByFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolForSchoolUpdatedByFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolForSchoolUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolOnSponsorForSponsorSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: SponsorPatch;
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSponsorForSponsorSchoolIdFkeyUsingSchoolPkeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSponsorForSponsorSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to update. */
export type SchoolOnSponsorForSponsorSchoolIdFkeyUsingSchoolSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: UpdateSchoolOnSponsorForSponsorSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Represents an update to a `School`. Fields that are set will be updated. */
export type SchoolPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByCreatedBy: CalendarGroupsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndCreatedByManyToManyEdgeCalendarGroupsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `CalendarGroup`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `CalendarGroup`. */
  calendarGroupsByUpdatedBy: CalendarGroupsConnection;
};


/** A `Person` edge in the connection, with data from `CalendarGroup`. */
export type SchoolPeopleByCalendarGroupSchoolIdAndUpdatedByManyToManyEdgeCalendarGroupsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
  condition?: Maybe<CalendarGroupCondition>;
  filter?: Maybe<CalendarGroupFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByCreatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndCreatedByManyToManyEdgeDivisionsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Division`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Division`. */
  divisionsByUpdatedBy: DivisionsConnection;
};


/** A `Person` edge in the connection, with data from `Division`. */
export type SchoolPeopleByDivisionSchoolIdAndUpdatedByManyToManyEdgeDivisionsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
  condition?: Maybe<DivisionCondition>;
  filter?: Maybe<DivisionFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByCreatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndCreatedByManyToManyEdgeMarqueesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Marquee`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Marquee`. */
  marqueesByUpdatedBy: MarqueesConnection;
};


/** A `Person` edge in the connection, with data from `Marquee`. */
export type SchoolPeopleByMarqueeSchoolIdAndUpdatedByManyToManyEdgeMarqueesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
  condition?: Maybe<MarqueeCondition>;
  filter?: Maybe<MarqueeFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByCreatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndCreatedByManyToManyEdgeSchoolPeopleByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyConnection = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyEdge = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndPersonIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes: Array<Maybe<UserType>>;
  includeInDirectory: Scalars['Boolean'];
  completedOnboarding: Scalars['Boolean'];
};

/** A connection to a list of `Person` values, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPerson`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPerson`. */
  schoolPeopleByUpdatedBy: SchoolPeopleConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPerson`. */
export type SchoolPeopleBySchoolPersonSchoolIdAndUpdatedByManyToManyEdgeSchoolPeopleByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
  condition?: Maybe<SchoolPersonCondition>;
  filter?: Maybe<SchoolPersonFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByCreatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndCreatedByManyToManyEdgeSponsorsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `Person` values, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `Sponsor`, and the cursor to aid in pagination. */
  edges: Array<SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `Sponsor`. */
  sponsorsByUpdatedBy: SponsorsConnection;
};


/** A `Person` edge in the connection, with data from `Sponsor`. */
export type SchoolPeopleBySponsorSchoolIdAndUpdatedByManyToManyEdgeSponsorsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
  condition?: Maybe<SponsorCondition>;
  filter?: Maybe<SponsorFilter>;
};

/** A connection to a list of `SchoolPerson` values. */
export type SchoolPeopleConnection = {
  __typename?: 'SchoolPeopleConnection';
  /** A list of `SchoolPerson` objects. */
  nodes: Array<SchoolPerson>;
  /** A list of edges which contains the `SchoolPerson` and cursor to aid in pagination. */
  edges: Array<SchoolPeopleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolPerson` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SchoolPerson` edge in the connection. */
export type SchoolPeopleEdge = {
  __typename?: 'SchoolPeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolPerson` at the end of the edge. */
  node: SchoolPerson;
};

/** Methods to use when ordering `SchoolPerson`. */
export enum SchoolPeopleOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  UserTypesAsc = 'USER_TYPES_ASC',
  UserTypesDesc = 'USER_TYPES_DESC',
  IncludeInDirectoryAsc = 'INCLUDE_IN_DIRECTORY_ASC',
  IncludeInDirectoryDesc = 'INCLUDE_IN_DIRECTORY_DESC',
  CompletedOnboardingAsc = 'COMPLETED_ONBOARDING_ASC',
  CompletedOnboardingDesc = 'COMPLETED_ONBOARDING_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** How we link a Person to a School. This is a CORE connection. It links faculty/staff to a school so they can have admin permissions and it links anonymous users to a school so they can read its public data. */
export type SchoolPerson = Node & {
  __typename?: 'SchoolPerson';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  schoolId: Scalars['UUID'];
  personId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes: Array<Maybe<UserType>>;
  includeInDirectory: Scalars['Boolean'];
  completedOnboarding: Scalars['Boolean'];
  /** Reads a single `School` that is related to this `SchoolPerson`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallations: SchoolPersonInstallationsConnection;
  /** Reads a single `SchoolPersonInvitation` that is related to this `SchoolPerson`. */
  schoolPersonInvitation?: Maybe<SchoolPersonInvitation>;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInstallationSchoolPersonIdAndCreatedBy: SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedBy: SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyConnection;
};


/** How we link a Person to a School. This is a CORE connection. It links faculty/staff to a school so they can have admin permissions and it links anonymous users to a school so they can read its public data. */
export type SchoolPersonSchoolPersonInstallationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};


/** How we link a Person to a School. This is a CORE connection. It links faculty/staff to a school so they can have admin permissions and it links anonymous users to a school so they can read its public data. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** How we link a Person to a School. This is a CORE connection. It links faculty/staff to a school so they can have admin permissions and it links anonymous users to a school so they can read its public data. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/**
 * A condition to be used against `SchoolPerson` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SchoolPersonCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userTypes` field. */
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  /** Checks for equality with the object’s `includeInDirectory` field. */
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `completedOnboarding` field. */
  completedOnboarding?: Maybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `schoolPerson` in the `PersonInput` mutation. */
export type SchoolPersonCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonSchoolPersonPkeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonNodeIdConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonSchoolPersonPkeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyUsingSchoolPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonForSchoolPersonCreatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonCreatedByFkeySchoolPersonCreateInput>>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonCreatedByFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** A filter to be used against `SchoolPerson` object types. All fields are combined with a logical ‘and.’ */
export type SchoolPersonFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `personId` field. */
  personId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `userTypes` field. */
  userTypes?: Maybe<UserTypeListFilter>;
  /** Filter by the object’s `includeInDirectory` field. */
  includeInDirectory?: Maybe<BooleanFilter>;
  /** Filter by the object’s `completedOnboarding` field. */
  completedOnboarding?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SchoolPersonFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SchoolPersonFilter>>;
  /** Negates the expression. */
  not?: Maybe<SchoolPersonFilter>;
};

/** An input for mutations affecting `SchoolPerson` */
export type SchoolPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** Represents a single school__person_installation, or "instance", of a School's app for a Person. One Person might have two or three School_PersonInstallations for one School. */
export type SchoolPersonInstallation = Node & {
  __typename?: 'SchoolPersonInstallation';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  schoolPersonId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion: Scalars['String'];
  platform: Scalars['String'];
  osVersion: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  bundleId: Scalars['String'];
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInstallation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `SchoolPersonInstallation` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SchoolPersonInstallationCondition = {
  /** Checks for equality with the object’s `schoolPersonId` field. */
  schoolPersonId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `appVersion` field. */
  appVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `platform` field. */
  platform?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `osVersion` field. */
  osVersion?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deviceName` field. */
  deviceName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bundleId` field. */
  bundleId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `pushEnabled` field. */
  pushEnabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `oneSignalToken` field. */
  oneSignalToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `oneSignalPlayerId` field. */
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `schoolPersonInstallation` in the `PersonInput` mutation. */
export type SchoolPersonInstallationCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInstallation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyDelete>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyUsingSchoolPersonInstallationPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInstallationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInstallationCreatedByFkeySchoolPersonInstallationCreateInput>>;
};

/** The `schoolPersonInstallation` to be created by this mutation. */
export type SchoolPersonInstallationCreatedByFkeySchoolPersonInstallationCreateInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion: Scalars['String'];
  platform: Scalars['String'];
  osVersion: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  bundleId: Scalars['String'];
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** A filter to be used against `SchoolPersonInstallation` object types. All fields are combined with a logical ‘and.’ */
export type SchoolPersonInstallationFilter = {
  /** Filter by the object’s `schoolPersonId` field. */
  schoolPersonId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `appVersion` field. */
  appVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `platform` field. */
  platform?: Maybe<StringFilter>;
  /** Filter by the object’s `osVersion` field. */
  osVersion?: Maybe<StringFilter>;
  /** Filter by the object’s `deviceName` field. */
  deviceName?: Maybe<StringFilter>;
  /** Filter by the object’s `bundleId` field. */
  bundleId?: Maybe<StringFilter>;
  /** Filter by the object’s `pushEnabled` field. */
  pushEnabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `oneSignalToken` field. */
  oneSignalToken?: Maybe<StringFilter>;
  /** Filter by the object’s `oneSignalPlayerId` field. */
  oneSignalPlayerId?: Maybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SchoolPersonInstallationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SchoolPersonInstallationFilter>>;
  /** Negates the expression. */
  not?: Maybe<SchoolPersonInstallationFilter>;
};

/** An input for mutations affecting `SchoolPersonInstallation` */
export type SchoolPersonInstallationInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion: Scalars['String'];
  platform: Scalars['String'];
  osVersion: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  bundleId: Scalars['String'];
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SchoolPersonInstallationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `schoolPersonInstallation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SchoolPersonInstallationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `schoolPersonInstallation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `schoolPersonInstallation` to look up the row to update. */
export type SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyUsingSchoolPersonInstallationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The fields on `schoolPersonInstallation` to look up the row to update. */
export type SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonInstallationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `schoolPersonInstallation` to look up the row to update. */
export type SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyUsingSchoolPersonInstallationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `SchoolPersonInstallation`. Fields that are set will be updated. */
export type SchoolPersonInstallationPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `schoolPerson` in the `SchoolPersonInstallationInput` mutation. */
export type SchoolPersonInstallationSchoolPersonIdFkeyInput = {
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<SchoolPersonSchoolPersonPkeyConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolPersonNodeIdConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<SchoolPersonSchoolPersonPkeyDelete>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolPersonNodeIdDelete>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyNodeIdUpdate>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeySchoolPersonCreateInput>;
};

/** Input for the nested mutation of `schoolPersonInstallation` in the `SchoolPersonInput` mutation. */
export type SchoolPersonInstallationSchoolPersonIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInstallation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyDelete>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonInstallationPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInstallationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInstallationSchoolPersonIdFkeySchoolPersonInstallationCreateInput>>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonInstallationSchoolPersonIdFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** The `schoolPersonInstallation` to be created by this mutation. */
export type SchoolPersonInstallationSchoolPersonIdFkeySchoolPersonInstallationCreateInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion: Scalars['String'];
  platform: Scalars['String'];
  osVersion: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  bundleId: Scalars['String'];
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** The fields on `schoolPersonInstallation` to look up the row to connect. */
export type SchoolPersonInstallationSchoolPersonInstallationPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `schoolPersonInstallation` to look up the row to delete. */
export type SchoolPersonInstallationSchoolPersonInstallationPkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `person` in the `SchoolPersonInstallationInput` mutation. */
export type SchoolPersonInstallationUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonInstallationUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `schoolPersonInstallation` in the `PersonInput` mutation. */
export type SchoolPersonInstallationUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInstallation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdConnect>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonInstallationSchoolPersonInstallationPkeyDelete>>;
  /** The primary key(s) for `schoolPersonInstallation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonInstallationNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyUsingSchoolPersonInstallationPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInstallation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInstallationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInstallationUpdatedByFkeySchoolPersonInstallationCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SchoolPersonInstallationUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `schoolPersonInstallation` to be created by this mutation. */
export type SchoolPersonInstallationUpdatedByFkeySchoolPersonInstallationCreateInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  appVersion: Scalars['String'];
  platform: Scalars['String'];
  osVersion: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  bundleId: Scalars['String'];
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** A connection to a list of `SchoolPersonInstallation` values. */
export type SchoolPersonInstallationsConnection = {
  __typename?: 'SchoolPersonInstallationsConnection';
  /** A list of `SchoolPersonInstallation` objects. */
  nodes: Array<SchoolPersonInstallation>;
  /** A list of edges which contains the `SchoolPersonInstallation` and cursor to aid in pagination. */
  edges: Array<SchoolPersonInstallationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolPersonInstallation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SchoolPersonInstallation` edge in the connection. */
export type SchoolPersonInstallationsEdge = {
  __typename?: 'SchoolPersonInstallationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolPersonInstallation` at the end of the edge. */
  node: SchoolPersonInstallation;
};

/** Methods to use when ordering `SchoolPersonInstallation`. */
export enum SchoolPersonInstallationsOrderBy {
  Natural = 'NATURAL',
  SchoolPersonIdAsc = 'SCHOOL_PERSON_ID_ASC',
  SchoolPersonIdDesc = 'SCHOOL_PERSON_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  AppVersionAsc = 'APP_VERSION_ASC',
  AppVersionDesc = 'APP_VERSION_DESC',
  PlatformAsc = 'PLATFORM_ASC',
  PlatformDesc = 'PLATFORM_DESC',
  OsVersionAsc = 'OS_VERSION_ASC',
  OsVersionDesc = 'OS_VERSION_DESC',
  DeviceNameAsc = 'DEVICE_NAME_ASC',
  DeviceNameDesc = 'DEVICE_NAME_DESC',
  BundleIdAsc = 'BUNDLE_ID_ASC',
  BundleIdDesc = 'BUNDLE_ID_DESC',
  PushEnabledAsc = 'PUSH_ENABLED_ASC',
  PushEnabledDesc = 'PUSH_ENABLED_DESC',
  OneSignalTokenAsc = 'ONE_SIGNAL_TOKEN_ASC',
  OneSignalTokenDesc = 'ONE_SIGNAL_TOKEN_DESC',
  OneSignalPlayerIdAsc = 'ONE_SIGNAL_PLAYER_ID_ASC',
  OneSignalPlayerIdDesc = 'ONE_SIGNAL_PLAYER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * To invite someone to join a school we create a School_PersonInvitation record.
 *
 *  The flow for accepting an invitation can be fairly complex, but the record indicating an open invitation can be fairly simple.
 *
 *  This record is intended to only keep track of information about the invitation itself (e.g. accepted/not accepted, expiration, emails sent, etc.).
 */
export type SchoolPersonInvitation = Node & {
  __typename?: 'SchoolPersonInvitation';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  schoolPersonId: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted: Scalars['Boolean'];
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInvitation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `SchoolPersonInvitation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SchoolPersonInvitationCondition = {
  /** Checks for equality with the object’s `schoolPersonId` field. */
  schoolPersonId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `accepted` field. */
  accepted?: Maybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `schoolPersonInvitation` in the `PersonInput` mutation. */
export type SchoolPersonInvitationCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInvitation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationPkeyConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationSlugKeyConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonInvitationNodeIdConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationPkeyDelete>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationSlugKeyDelete>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonInvitationNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyUsingSchoolPersonInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyUsingSchoolPersonInvitationSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInvitationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInvitationCreatedByFkeySchoolPersonInvitationCreateInput>>;
};

/** The `schoolPersonInvitation` to be created by this mutation. */
export type SchoolPersonInvitationCreatedByFkeySchoolPersonInvitationCreateInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** A filter to be used against `SchoolPersonInvitation` object types. All fields are combined with a logical ‘and.’ */
export type SchoolPersonInvitationFilter = {
  /** Filter by the object’s `schoolPersonId` field. */
  schoolPersonId?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `accepted` field. */
  accepted?: Maybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SchoolPersonInvitationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SchoolPersonInvitationFilter>>;
  /** Negates the expression. */
  not?: Maybe<SchoolPersonInvitationFilter>;
};

/** An input for mutations affecting `SchoolPersonInvitation` */
export type SchoolPersonInvitationInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SchoolPersonInvitationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `schoolPersonInvitation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SchoolPersonInvitationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `schoolPersonInvitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyUsingSchoolPersonInvitationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyPatch;
  schoolPersonId: Scalars['UUID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyUsingSchoolPersonInvitationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonInvitationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch;
  schoolPersonId: Scalars['UUID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonInvitationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingSchoolPersonInvitationPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyPatch;
  schoolPersonId: Scalars['UUID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to update. */
export type SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingSchoolPersonInvitationSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Represents an update to a `SchoolPersonInvitation`. Fields that are set will be updated. */
export type SchoolPersonInvitationPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `schoolPerson` in the `SchoolPersonInvitationInput` mutation. */
export type SchoolPersonInvitationSchoolPersonIdFkeyInput = {
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<SchoolPersonSchoolPersonPkeyConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolPersonNodeIdConnect>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<SchoolPersonSchoolPersonPkeyDelete>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolPersonNodeIdDelete>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyNodeIdUpdate>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeySchoolPersonCreateInput>;
};

/** Input for the nested mutation of `schoolPersonInvitation` in the `SchoolPersonInput` mutation. */
export type SchoolPersonInvitationSchoolPersonIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInvitation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySchoolPersonId?: Maybe<SchoolPersonInvitationSchoolPersonInvitationPkeyConnect>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolPersonInvitationSchoolPersonInvitationSlugKeyConnect>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolPersonInvitationNodeIdConnect>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySchoolPersonId?: Maybe<SchoolPersonInvitationSchoolPersonInvitationPkeyDelete>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolPersonInvitationSchoolPersonInvitationSlugKeyDelete>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolPersonInvitationNodeIdDelete>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySchoolPersonId?: Maybe<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonInvitationPkeyUpdate>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonInvitationSlugKeyUpdate>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyNodeIdUpdate>;
  /** A `SchoolPersonInvitationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInvitationSchoolPersonIdFkeySchoolPersonInvitationCreateInput>>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonInvitationSchoolPersonIdFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** The `schoolPersonInvitation` to be created by this mutation. */
export type SchoolPersonInvitationSchoolPersonIdFkeySchoolPersonInvitationCreateInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** The fields on `schoolPersonInvitation` to look up the row to connect. */
export type SchoolPersonInvitationSchoolPersonInvitationPkeyConnect = {
  schoolPersonId: Scalars['UUID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to delete. */
export type SchoolPersonInvitationSchoolPersonInvitationPkeyDelete = {
  schoolPersonId: Scalars['UUID'];
};

/** The fields on `schoolPersonInvitation` to look up the row to connect. */
export type SchoolPersonInvitationSchoolPersonInvitationSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `schoolPersonInvitation` to look up the row to delete. */
export type SchoolPersonInvitationSchoolPersonInvitationSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `schoolPersonInvitationSend` mutation. */
export type SchoolPersonInvitationSendInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolPersonInvitationId: Scalars['UUID'];
};

/** The output of our `schoolPersonInvitationSend` mutation. */
export type SchoolPersonInvitationSendPayload = {
  __typename?: 'SchoolPersonInvitationSendPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Input for the nested mutation of `person` in the `SchoolPersonInvitationInput` mutation. */
export type SchoolPersonInvitationUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonInvitationUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `schoolPersonInvitation` in the `PersonInput` mutation. */
export type SchoolPersonInvitationUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPersonInvitation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationPkeyConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationSlugKeyConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonInvitationNodeIdConnect>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationPkeyDelete>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SchoolPersonInvitationSchoolPersonInvitationSlugKeyDelete>>;
  /** The primary key(s) for `schoolPersonInvitation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonInvitationNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySchoolPersonId?: Maybe<Array<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingSchoolPersonInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyUsingSchoolPersonInvitationSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPersonInvitation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInvitationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonInvitationUpdatedByFkeySchoolPersonInvitationCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SchoolPersonInvitationUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `schoolPersonInvitation` to be created by this mutation. */
export type SchoolPersonInvitationUpdatedByFkeySchoolPersonInvitationCreateInput = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** A connection to a list of `SchoolPersonInvitation` values. */
export type SchoolPersonInvitationsConnection = {
  __typename?: 'SchoolPersonInvitationsConnection';
  /** A list of `SchoolPersonInvitation` objects. */
  nodes: Array<SchoolPersonInvitation>;
  /** A list of edges which contains the `SchoolPersonInvitation` and cursor to aid in pagination. */
  edges: Array<SchoolPersonInvitationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolPersonInvitation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SchoolPersonInvitation` edge in the connection. */
export type SchoolPersonInvitationsEdge = {
  __typename?: 'SchoolPersonInvitationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolPersonInvitation` at the end of the edge. */
  node: SchoolPersonInvitation;
};

/** Methods to use when ordering `SchoolPersonInvitation`. */
export enum SchoolPersonInvitationsOrderBy {
  Natural = 'NATURAL',
  SchoolPersonIdAsc = 'SCHOOL_PERSON_ID_ASC',
  SchoolPersonIdDesc = 'SCHOOL_PERSON_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  AcceptedAsc = 'ACCEPTED_ASC',
  AcceptedDesc = 'ACCEPTED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type SchoolPersonNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SchoolPersonNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `schoolPerson` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInstallation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
  patch: SchoolPersonInstallationPatch;
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `schoolPersonInvitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to update. */
export type SchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyUsingSchoolPersonPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolPerson` being updated. */
  patch: UpdateSchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `SchoolPerson`. Fields that are set will be updated. */
export type SchoolPersonPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyConnection = {
  __typename?: 'SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyEdge = {
  __typename?: 'SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByCreatedBy: SchoolPersonInstallationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndCreatedByManyToManyEdgeSchoolPersonInstallationsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** A connection to a list of `Person` values, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyConnection = {
  __typename?: 'SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `SchoolPersonInstallation`, and the cursor to aid in pagination. */
  edges: Array<SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyEdge = {
  __typename?: 'SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `SchoolPersonInstallation`. */
  schoolPersonInstallationsByUpdatedBy: SchoolPersonInstallationsConnection;
};


/** A `Person` edge in the connection, with data from `SchoolPersonInstallation`. */
export type SchoolPersonPeopleBySchoolPersonInstallationSchoolPersonIdAndUpdatedByManyToManyEdgeSchoolPersonInstallationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
  condition?: Maybe<SchoolPersonInstallationCondition>;
  filter?: Maybe<SchoolPersonInstallationFilter>;
};

/** Input for the nested mutation of `schoolPerson` in the `PersonInput` mutation. */
export type SchoolPersonPersonIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonSchoolPersonPkeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonNodeIdConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonSchoolPersonPkeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyUsingSchoolPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonForSchoolPersonPersonIdFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonPersonIdFkeySchoolPersonCreateInput>>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonPersonIdFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** Input for the nested mutation of `school` in the `SchoolPersonInput` mutation. */
export type SchoolPersonSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `schoolPerson` in the `SchoolInput` mutation. */
export type SchoolPersonSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonSchoolPersonPkeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonNodeIdConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonSchoolPersonPkeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonSchoolIdFkeySchoolPersonCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type SchoolPersonSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonSchoolIdFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** The fields on `schoolPerson` to look up the row to connect. */
export type SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect = {
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to delete. */
export type SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete = {
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to connect. */
export type SchoolPersonSchoolPersonPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `schoolPerson` to look up the row to delete. */
export type SchoolPersonSchoolPersonPkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `person` in the `SchoolPersonInput` mutation. */
export type SchoolPersonUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolPersonUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `schoolPerson` in the `PersonInput` mutation. */
export type SchoolPersonUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolPerson` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolPersonSchoolPersonPkeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolPersonNodeIdConnect>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolPersonSchoolPersonPkeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonSchoolPersonPersonIdSchoolIdKeyDelete>>;
  /** The primary key(s) for `schoolPerson` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolPersonNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingSchoolPersonPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByPersonIdAndSchoolId?: Maybe<Array<SchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyUsingSchoolPersonPersonIdSchoolIdKeyUpdate>>;
  /** The primary key(s) and patch data for `schoolPerson` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolPersonForSchoolPersonUpdatedByFkeyNodeIdUpdate>>;
  /** A `SchoolPersonInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolPersonUpdatedByFkeySchoolPersonCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SchoolPersonUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `schoolPerson` to be created by this mutation. */
export type SchoolPersonUpdatedByFkeySchoolPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** The fields on `school` to look up the row to connect. */
export type SchoolSchoolPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to delete. */
export type SchoolSchoolPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `school` to look up the row to connect. */
export type SchoolSchoolSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `school` to look up the row to delete. */
export type SchoolSchoolSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/**
 * Dedicated table to store settings for a `School`. <br/><br/>
 *
 * Allows us to separate settings into a specific domain which, among other things, lets us set specific permissions on `SchoolSetting`s.
 */
export type SchoolSetting = Node & {
  __typename?: 'SchoolSetting';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  schoolId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone: Scalars['Timezone'];
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess: Scalars['Boolean'];
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess: Scalars['Boolean'];
  enableStudentAccess: Scalars['Boolean'];
  resourceTileOpacity: Scalars['Int'];
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  /** Reads a single `School` that is related to this `SchoolSetting`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  schoolImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  mascotImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  launchScreenPoweredByImage?: Maybe<File>;
  /** Returns scheduled date for faculty settings reset for this school. */
  scheduledFacultyReset?: Maybe<Scalars['Datetime']>;
  /** Returns scheduled date for guest settings reset for this school. */
  scheduledGuestReset?: Maybe<Scalars['Datetime']>;
  /** Returns scheduled date for parent settings reset for this school. */
  scheduledParentReset?: Maybe<Scalars['Datetime']>;
  /** Returns scheduled date for student settings reset for this school. */
  scheduledStudentReset?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `SchoolSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SchoolSettingCondition = {
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `timeZone` field. */
  timeZone?: Maybe<Scalars['Timezone']>;
  /** Checks for equality with the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logoImageFileId` field. */
  logoImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolImageFileId` field. */
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `mascotImageFileId` field. */
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `downloadUrl` field. */
  downloadUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `feedbackUrl` field. */
  feedbackUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sponsored` field. */
  sponsored?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `sponsorsIncludeSilversOnHome` field. */
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `launchScreenEnabled` field. */
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `launchScreenPoweredByLabel` field. */
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `launchScreenPoweredByImageId` field. */
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentPasscode` field. */
  parentPasscode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentPasscodeHint` field. */
  parentPasscodeHint?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `studentPasscode` field. */
  studentPasscode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `studentPasscodeHint` field. */
  studentPasscodeHint?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enableGuestAccess` field. */
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `googleAnalyticsId` field. */
  googleAnalyticsId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enableParentFamilyAccess` field. */
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `enableStudentAccess` field. */
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `resourceTileOpacity` field. */
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `goldSponsorLabel` field. */
  goldSponsorLabel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `silverSponsorLabel` field. */
  silverSponsorLabel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bronzeSponsorLabel` field. */
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SchoolSetting` object types. All fields are combined with a logical ‘and.’ */
export type SchoolSettingFilter = {
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `timeZone` field. */
  timeZone?: Maybe<TimezoneFilter>;
  /** Filter by the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `logoImageFileId` field. */
  logoImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolImageFileId` field. */
  schoolImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `mascotImageFileId` field. */
  mascotImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `downloadUrl` field. */
  downloadUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `feedbackUrl` field. */
  feedbackUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `sponsored` field. */
  sponsored?: Maybe<BooleanFilter>;
  /** Filter by the object’s `sponsorsIncludeSilversOnHome` field. */
  sponsorsIncludeSilversOnHome?: Maybe<BooleanFilter>;
  /** Filter by the object’s `launchScreenEnabled` field. */
  launchScreenEnabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `launchScreenPoweredByLabel` field. */
  launchScreenPoweredByLabel?: Maybe<StringFilter>;
  /** Filter by the object’s `launchScreenPoweredByImageId` field. */
  launchScreenPoweredByImageId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentPasscode` field. */
  parentPasscode?: Maybe<StringFilter>;
  /** Filter by the object’s `parentPasscodeHint` field. */
  parentPasscodeHint?: Maybe<StringFilter>;
  /** Filter by the object’s `studentPasscode` field. */
  studentPasscode?: Maybe<StringFilter>;
  /** Filter by the object’s `studentPasscodeHint` field. */
  studentPasscodeHint?: Maybe<StringFilter>;
  /** Filter by the object’s `enableGuestAccess` field. */
  enableGuestAccess?: Maybe<BooleanFilter>;
  /** Filter by the object’s `googleAnalyticsId` field. */
  googleAnalyticsId?: Maybe<StringFilter>;
  /** Filter by the object’s `enableParentFamilyAccess` field. */
  enableParentFamilyAccess?: Maybe<BooleanFilter>;
  /** Filter by the object’s `enableStudentAccess` field. */
  enableStudentAccess?: Maybe<BooleanFilter>;
  /** Filter by the object’s `resourceTileOpacity` field. */
  resourceTileOpacity?: Maybe<IntFilter>;
  /** Filter by the object’s `goldSponsorLabel` field. */
  goldSponsorLabel?: Maybe<StringFilter>;
  /** Filter by the object’s `silverSponsorLabel` field. */
  silverSponsorLabel?: Maybe<StringFilter>;
  /** Filter by the object’s `bronzeSponsorLabel` field. */
  bronzeSponsorLabel?: Maybe<StringFilter>;
  /** Filter by the object’s `scheduledFacultyReset` field. */
  scheduledFacultyReset?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `scheduledGuestReset` field. */
  scheduledGuestReset?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `scheduledParentReset` field. */
  scheduledParentReset?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `scheduledStudentReset` field. */
  scheduledStudentReset?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SchoolSettingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SchoolSettingFilter>>;
  /** Negates the expression. */
  not?: Maybe<SchoolSettingFilter>;
};

/** An input for mutations affecting `SchoolSetting` */
export type SchoolSettingInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SchoolSettingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SchoolSettingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `schoolSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsCreatedByFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsCreatedByFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `schoolSetting` to look up the row to update. */
export type SchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyUsingSchoolSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `schoolSetting` being updated. */
  patch: UpdateSchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyPatch;
  schoolId: Scalars['UUID'];
};

/** Represents an update to a `SchoolSetting`. Fields that are set will be updated. */
export type SchoolSettingPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The fields on `schoolSetting` to look up the row to connect. */
export type SchoolSettingSchoolSettingsPkeyConnect = {
  schoolId: Scalars['UUID'];
};

/** The fields on `schoolSetting` to look up the row to delete. */
export type SchoolSettingSchoolSettingsPkeyDelete = {
  schoolId: Scalars['UUID'];
};

/** A connection to a list of `SchoolSetting` values. */
export type SchoolSettingsConnection = {
  __typename?: 'SchoolSettingsConnection';
  /** A list of `SchoolSetting` objects. */
  nodes: Array<SchoolSetting>;
  /** A list of edges which contains the `SchoolSetting` and cursor to aid in pagination. */
  edges: Array<SchoolSettingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchoolSetting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsCoverImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsCoverImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsCoverImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsCoverImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsCoverImageFileIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsCoverImageFileIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `PersonInput` mutation. */
export type SchoolSettingsCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsCreatedByFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolSettingForSchoolSettingsCreatedByFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsCreatedByFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsCreatedByFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** A `SchoolSetting` edge in the connection. */
export type SchoolSettingsEdge = {
  __typename?: 'SchoolSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchoolSetting` at the end of the edge. */
  node: SchoolSetting;
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsLaunchScreenPoweredByImageIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsLaunchScreenPoweredByImageIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsLaunchScreenPoweredByImageIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsLogoImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsLogoImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsLogoImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsLogoImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsLogoImageFileIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsLogoImageFileIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsMascotImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsMascotImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsMascotImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsMascotImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsMascotImageFileIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsMascotImageFileIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** Methods to use when ordering `SchoolSetting`. */
export enum SchoolSettingsOrderBy {
  Natural = 'NATURAL',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  TimeZoneAsc = 'TIME_ZONE_ASC',
  TimeZoneDesc = 'TIME_ZONE_DESC',
  CoverImageFileIdAsc = 'COVER_IMAGE_FILE_ID_ASC',
  CoverImageFileIdDesc = 'COVER_IMAGE_FILE_ID_DESC',
  ThumbnailImageFileIdAsc = 'THUMBNAIL_IMAGE_FILE_ID_ASC',
  ThumbnailImageFileIdDesc = 'THUMBNAIL_IMAGE_FILE_ID_DESC',
  LogoImageFileIdAsc = 'LOGO_IMAGE_FILE_ID_ASC',
  LogoImageFileIdDesc = 'LOGO_IMAGE_FILE_ID_DESC',
  SchoolImageFileIdAsc = 'SCHOOL_IMAGE_FILE_ID_ASC',
  SchoolImageFileIdDesc = 'SCHOOL_IMAGE_FILE_ID_DESC',
  MascotImageFileIdAsc = 'MASCOT_IMAGE_FILE_ID_ASC',
  MascotImageFileIdDesc = 'MASCOT_IMAGE_FILE_ID_DESC',
  DownloadUrlAsc = 'DOWNLOAD_URL_ASC',
  DownloadUrlDesc = 'DOWNLOAD_URL_DESC',
  FeedbackUrlAsc = 'FEEDBACK_URL_ASC',
  FeedbackUrlDesc = 'FEEDBACK_URL_DESC',
  SponsoredAsc = 'SPONSORED_ASC',
  SponsoredDesc = 'SPONSORED_DESC',
  SponsorsIncludeSilversOnHomeAsc = 'SPONSORS_INCLUDE_SILVERS_ON_HOME_ASC',
  SponsorsIncludeSilversOnHomeDesc = 'SPONSORS_INCLUDE_SILVERS_ON_HOME_DESC',
  LaunchScreenEnabledAsc = 'LAUNCH_SCREEN_ENABLED_ASC',
  LaunchScreenEnabledDesc = 'LAUNCH_SCREEN_ENABLED_DESC',
  LaunchScreenPoweredByLabelAsc = 'LAUNCH_SCREEN_POWERED_BY_LABEL_ASC',
  LaunchScreenPoweredByLabelDesc = 'LAUNCH_SCREEN_POWERED_BY_LABEL_DESC',
  LaunchScreenPoweredByImageIdAsc = 'LAUNCH_SCREEN_POWERED_BY_IMAGE_ID_ASC',
  LaunchScreenPoweredByImageIdDesc = 'LAUNCH_SCREEN_POWERED_BY_IMAGE_ID_DESC',
  ParentPasscodeAsc = 'PARENT_PASSCODE_ASC',
  ParentPasscodeDesc = 'PARENT_PASSCODE_DESC',
  ParentPasscodeHintAsc = 'PARENT_PASSCODE_HINT_ASC',
  ParentPasscodeHintDesc = 'PARENT_PASSCODE_HINT_DESC',
  StudentPasscodeAsc = 'STUDENT_PASSCODE_ASC',
  StudentPasscodeDesc = 'STUDENT_PASSCODE_DESC',
  StudentPasscodeHintAsc = 'STUDENT_PASSCODE_HINT_ASC',
  StudentPasscodeHintDesc = 'STUDENT_PASSCODE_HINT_DESC',
  EnableGuestAccessAsc = 'ENABLE_GUEST_ACCESS_ASC',
  EnableGuestAccessDesc = 'ENABLE_GUEST_ACCESS_DESC',
  GoogleAnalyticsIdAsc = 'GOOGLE_ANALYTICS_ID_ASC',
  GoogleAnalyticsIdDesc = 'GOOGLE_ANALYTICS_ID_DESC',
  EnableParentFamilyAccessAsc = 'ENABLE_PARENT_FAMILY_ACCESS_ASC',
  EnableParentFamilyAccessDesc = 'ENABLE_PARENT_FAMILY_ACCESS_DESC',
  EnableStudentAccessAsc = 'ENABLE_STUDENT_ACCESS_ASC',
  EnableStudentAccessDesc = 'ENABLE_STUDENT_ACCESS_DESC',
  ResourceTileOpacityAsc = 'RESOURCE_TILE_OPACITY_ASC',
  ResourceTileOpacityDesc = 'RESOURCE_TILE_OPACITY_DESC',
  GoldSponsorLabelAsc = 'GOLD_SPONSOR_LABEL_ASC',
  GoldSponsorLabelDesc = 'GOLD_SPONSOR_LABEL_DESC',
  SilverSponsorLabelAsc = 'SILVER_SPONSOR_LABEL_ASC',
  SilverSponsorLabelDesc = 'SILVER_SPONSOR_LABEL_DESC',
  BronzeSponsorLabelAsc = 'BRONZE_SPONSOR_LABEL_ASC',
  BronzeSponsorLabelDesc = 'BRONZE_SPONSOR_LABEL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `school` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `SchoolInput` mutation. */
export type SchoolSettingsSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<SchoolSettingSchoolSettingsPkeyConnect>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolSettingNodeIdConnect>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<SchoolSettingSchoolSettingsPkeyDelete>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolSettingNodeIdDelete>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyUsingSchoolSettingsPkeyUpdate>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsSchoolIdFkeySchoolSettingsCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type SchoolSettingsSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsSchoolIdFkeySchoolSettingsCreateInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsSchoolImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsSchoolImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsSchoolImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsSchoolImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsSchoolImageFileIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsSchoolImageFileIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SchoolSettingsThumbnailImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsThumbnailImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `FileInput` mutation. */
export type SchoolSettingsThumbnailImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsThumbnailImageFileIdFkeySchoolSettingsCreateInput>>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsThumbnailImageFileIdFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** Input for the nested mutation of `person` in the `SchoolSettingInput` mutation. */
export type SchoolSettingsUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolSettingsUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `schoolSetting` in the `PersonInput` mutation. */
export type SchoolSettingsUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `schoolSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolSettingNodeIdConnect>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteBySchoolId?: Maybe<Array<SchoolSettingSchoolSettingsPkeyDelete>>;
  /** The primary key(s) for `schoolSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateBySchoolId?: Maybe<Array<SchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyUsingSchoolSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `schoolSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyNodeIdUpdate>>;
  /** A `SchoolSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolSettingsUpdatedByFkeySchoolSettingsCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SchoolSettingsUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `schoolSetting` to be created by this mutation. */
export type SchoolSettingsUpdatedByFkeySchoolSettingsCreateInput = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** Input for the nested mutation of `theme` in the `SchoolInput` mutation. */
export type SchoolThemeIdFkeyInput = {
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectById?: Maybe<ThemeThemePkeyConnect>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectByNodeId?: Maybe<ThemeNodeIdConnect>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteById?: Maybe<ThemeThemePkeyDelete>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ThemeNodeIdDelete>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateById?: Maybe<ThemeOnSchoolForSchoolThemeIdFkeyUsingThemePkeyUpdate>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolOnSchoolForSchoolThemeIdFkeyNodeIdUpdate>;
  /** A `ThemeInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolThemeIdFkeyThemeCreateInput>;
};

/** Input for the nested mutation of `school` in the `ThemeInput` mutation. */
export type SchoolThemeIdFkeyInverseInput = {
  /** Flag indicating whether all other `school` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolSchoolPkeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SchoolSchoolSlugKeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolNodeIdConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolSchoolPkeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SchoolSchoolSlugKeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolNodeIdDelete>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolOnSchoolForSchoolThemeIdFkeyUsingSchoolPkeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SchoolOnSchoolForSchoolThemeIdFkeyUsingSchoolSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ThemeOnSchoolForSchoolThemeIdFkeyNodeIdUpdate>>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolThemeIdFkeySchoolCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type SchoolThemeIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `theme` to be created by this mutation. */
export type SchoolThemeIdFkeyThemeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** Input for the nested mutation of `person` in the `SchoolInput` mutation. */
export type SchoolUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSchoolForSchoolUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SchoolOnSchoolForSchoolUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SchoolUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `school` in the `PersonInput` mutation. */
export type SchoolUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `school` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<Array<SchoolSchoolPkeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SchoolSchoolSlugKeyConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SchoolNodeIdConnect>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<Array<SchoolSchoolPkeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SchoolSchoolSlugKeyDelete>>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SchoolNodeIdDelete>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<Array<SchoolOnSchoolForSchoolUpdatedByFkeyUsingSchoolPkeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SchoolOnSchoolForSchoolUpdatedByFkeyUsingSchoolSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSchoolForSchoolUpdatedByFkeyNodeIdUpdate>>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SchoolUpdatedByFkeySchoolCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SchoolUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `school` to be created by this mutation. */
export type SchoolUpdatedByFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** A connection to a list of `School` values. */
export type SchoolsConnection = {
  __typename?: 'SchoolsConnection';
  /** A list of `School` objects. */
  nodes: Array<School>;
  /** A list of edges which contains the `School` and cursor to aid in pagination. */
  edges: Array<SchoolsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `School` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `School` edge in the connection. */
export type SchoolsEdge = {
  __typename?: 'SchoolsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `School` at the end of the edge. */
  node: School;
};

/** Methods to use when ordering `School`. */
export enum SchoolsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Address1Asc = 'ADDRESS1_ASC',
  Address1Desc = 'ADDRESS1_DESC',
  Address2Asc = 'ADDRESS2_ASC',
  Address2Desc = 'ADDRESS2_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipAsc = 'ZIP_ASC',
  ZipDesc = 'ZIP_DESC',
  InformationAsc = 'INFORMATION_ASC',
  InformationDesc = 'INFORMATION_DESC',
  ThemeIdAsc = 'THEME_ID_ASC',
  ThemeIdDesc = 'THEME_ID_DESC',
  DeployAsc = 'DEPLOY_ASC',
  DeployDesc = 'DEPLOY_DESC',
  PushEnabledAsc = 'PUSH_ENABLED_ASC',
  PushEnabledDesc = 'PUSH_ENABLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `sendPasswordResetEmail` mutation. */
export type SendPasswordResetEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  _email: Scalars['Email'];
  redirect?: Maybe<Scalars['String']>;
};

/** The output of our `sendPasswordResetEmail` mutation. */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setSchoolAdminGroupsForPerson` mutation. */
export type SetSchoolAdminGroupsForPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
  groupIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `setSchoolAdminGroupsForPerson` mutation. */
export type SetSchoolAdminGroupsForPersonPayload = {
  __typename?: 'SetSchoolAdminGroupsForPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Sponsor listing for within a school's app. */
export type Sponsor = Node & {
  __typename?: 'Sponsor';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId: Scalars['UUID'];
  published: Scalars['Boolean'];
  type: SponsorType;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder: Scalars['Int'];
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Buttons>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Sponsor`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  promoImage?: Maybe<File>;
};

/** A condition to be used against `Sponsor` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SponsorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `schoolId` field. */
  schoolId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `published` field. */
  published?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<SponsorType>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `primaryColor` field. */
  primaryColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `buttons` field. */
  buttons?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `logoImageFileId` field. */
  logoImageFileId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `promoImageFileId` field. */
  promoImageFileId?: Maybe<Scalars['UUID']>;
};

/** The `file` to be created by this mutation. */
export type SponsorCoverImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SponsorInput` mutation. */
export type SponsorCoverImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSponsorForSponsorCoverImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorCoverImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorCoverImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `FileInput` mutation. */
export type SponsorCoverImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorCoverImageFileIdFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorCoverImageFileIdFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSponsorForSponsorCoverImageFileIdFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorCoverImageFileIdFkeySponsorCreateInput>>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorCoverImageFileIdFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** Input for the nested mutation of `sponsor` in the `PersonInput` mutation. */
export type SponsorCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorCreatedByFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorCreatedByFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSponsorForSponsorCreatedByFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorCreatedByFkeySponsorCreateInput>>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorCreatedByFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** A filter to be used against `Sponsor` object types. All fields are combined with a logical ‘and.’ */
export type SponsorFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `schoolId` field. */
  schoolId?: Maybe<UuidFilter>;
  /** Filter by the object’s `published` field. */
  published?: Maybe<BooleanFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<SponsorTypeFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: Maybe<IntFilter>;
  /** Filter by the object’s `primaryColor` field. */
  primaryColor?: Maybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `buttons` field. */
  buttons?: Maybe<JsonFilter>;
  /** Filter by the object’s `coverImageFileId` field. */
  coverImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `thumbnailImageFileId` field. */
  thumbnailImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `logoImageFileId` field. */
  logoImageFileId?: Maybe<UuidFilter>;
  /** Filter by the object’s `promoImageFileId` field. */
  promoImageFileId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SponsorFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SponsorFilter>>;
  /** Negates the expression. */
  not?: Maybe<SponsorFilter>;
};

/** An input for mutations affecting `Sponsor` */
export type SponsorInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SponsorLogoImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SponsorInput` mutation. */
export type SponsorLogoImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSponsorForSponsorLogoImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorLogoImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorLogoImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `FileInput` mutation. */
export type SponsorLogoImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorLogoImageFileIdFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorLogoImageFileIdFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSponsorForSponsorLogoImageFileIdFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorLogoImageFileIdFkeySponsorCreateInput>>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorLogoImageFileIdFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SponsorNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `sponsor` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SponsorNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `sponsor` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorCoverImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorCoverImageFileIdFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorCoverImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorCoverImageFileIdFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorCoverImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorCreatedByFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorCreatedByFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorCreatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorLogoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorLogoImageFileIdFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorLogoImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorLogoImageFileIdFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorLogoImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorPromoImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorPromoImageFileIdFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorPromoImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorPromoImageFileIdFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorPromoImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorSchoolIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorSchoolIdFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorSchoolIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorSchoolIdFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorSchoolIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `file` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `file` being updated. */
  patch: FilePatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorThumbnailImageFileIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorThumbnailImageFileIdFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type SponsorOnSponsorForSponsorUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorUpdatedByFkeyUsingSponsorPkeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to update. */
export type SponsorOnSponsorForSponsorUpdatedByFkeyUsingSponsorSlugKeyUpdate = {
  /** An object where the defined keys will be set on the `sponsor` being updated. */
  patch: UpdateSponsorOnSponsorForSponsorUpdatedByFkeyPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** Represents an update to a `Sponsor`. Fields that are set will be updated. */
export type SponsorPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** The `file` to be created by this mutation. */
export type SponsorPromoImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SponsorInput` mutation. */
export type SponsorPromoImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSponsorForSponsorPromoImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorPromoImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorPromoImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `FileInput` mutation. */
export type SponsorPromoImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorPromoImageFileIdFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorPromoImageFileIdFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSponsorForSponsorPromoImageFileIdFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorPromoImageFileIdFkeySponsorCreateInput>>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorPromoImageFileIdFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** Input for the nested mutation of `school` in the `SponsorInput` mutation. */
export type SponsorSchoolIdFkeyInput = {
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectById?: Maybe<SchoolSchoolPkeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectBySlug?: Maybe<SchoolSchoolSlugKeyConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  connectByNodeId?: Maybe<SchoolNodeIdConnect>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteById?: Maybe<SchoolSchoolPkeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteBySlug?: Maybe<SchoolSchoolSlugKeyDelete>;
  /** The primary key(s) for `school` for the far side of the relationship. */
  deleteByNodeId?: Maybe<SchoolNodeIdDelete>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateById?: Maybe<SchoolOnSponsorForSponsorSchoolIdFkeyUsingSchoolPkeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateBySlug?: Maybe<SchoolOnSponsorForSponsorSchoolIdFkeyUsingSchoolSlugKeyUpdate>;
  /** The primary key(s) and patch data for `school` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorSchoolIdFkeyNodeIdUpdate>;
  /** A `SchoolInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorSchoolIdFkeySchoolCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `SchoolInput` mutation. */
export type SponsorSchoolIdFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorSchoolIdFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorSchoolIdFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<SchoolOnSponsorForSponsorSchoolIdFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorSchoolIdFkeySponsorCreateInput>>;
};

/** The `school` to be created by this mutation. */
export type SponsorSchoolIdFkeySchoolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorSchoolIdFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** The fields on `sponsor` to look up the row to connect. */
export type SponsorSponsorPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to delete. */
export type SponsorSponsorPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `sponsor` to look up the row to connect. */
export type SponsorSponsorSlugKeyConnect = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The fields on `sponsor` to look up the row to delete. */
export type SponsorSponsorSlugKeyDelete = {
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** The `file` to be created by this mutation. */
export type SponsorThumbnailImageFileIdFkeyFileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  type: FileType;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** Input for the nested mutation of `file` in the `SponsorInput` mutation. */
export type SponsorThumbnailImageFileIdFkeyInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: Maybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectByNodeId?: Maybe<FileNodeIdConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: Maybe<FileFilePkeyDelete>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FileNodeIdDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: Maybe<FileOnSponsorForSponsorThumbnailImageFileIdFkeyUsingFilePkeyUpdate>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyNodeIdUpdate>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorThumbnailImageFileIdFkeyFileCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `FileInput` mutation. */
export type SponsorThumbnailImageFileIdFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorThumbnailImageFileIdFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FileOnSponsorForSponsorThumbnailImageFileIdFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorThumbnailImageFileIdFkeySponsorCreateInput>>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorThumbnailImageFileIdFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

export enum SponsorType {
  Gold = 'GOLD',
  Silver = 'SILVER',
  Bronze = 'BRONZE'
}

/** A filter to be used against SponsorType fields. All fields are combined with a logical ‘and.’ */
export type SponsorTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<SponsorType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<SponsorType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<SponsorType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<SponsorType>;
  /** Included in the specified list. */
  in?: Maybe<Array<SponsorType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<SponsorType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<SponsorType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<SponsorType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<SponsorType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<SponsorType>;
};

/** Input for the nested mutation of `person` in the `SponsorInput` mutation. */
export type SponsorUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnSponsorForSponsorUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<SponsorOnSponsorForSponsorUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<SponsorUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `sponsor` in the `PersonInput` mutation. */
export type SponsorUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `sponsor` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectById?: Maybe<Array<SponsorSponsorPkeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectBySlug?: Maybe<Array<SponsorSponsorSlugKeyConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SponsorNodeIdConnect>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteById?: Maybe<Array<SponsorSponsorPkeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteBySlug?: Maybe<Array<SponsorSponsorSlugKeyDelete>>;
  /** The primary key(s) for `sponsor` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<SponsorNodeIdDelete>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateById?: Maybe<Array<SponsorOnSponsorForSponsorUpdatedByFkeyUsingSponsorPkeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateBySlug?: Maybe<Array<SponsorOnSponsorForSponsorUpdatedByFkeyUsingSponsorSlugKeyUpdate>>;
  /** The primary key(s) and patch data for `sponsor` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnSponsorForSponsorUpdatedByFkeyNodeIdUpdate>>;
  /** A `SponsorInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SponsorUpdatedByFkeySponsorCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type SponsorUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `sponsor` to be created by this mutation. */
export type SponsorUpdatedByFkeySponsorCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  primaryColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** A connection to a list of `Sponsor` values. */
export type SponsorsConnection = {
  __typename?: 'SponsorsConnection';
  /** A list of `Sponsor` objects. */
  nodes: Array<Sponsor>;
  /** A list of edges which contains the `Sponsor` and cursor to aid in pagination. */
  edges: Array<SponsorsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Sponsor` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Sponsor` edge in the connection. */
export type SponsorsEdge = {
  __typename?: 'SponsorsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Sponsor` at the end of the edge. */
  node: Sponsor;
};

/** Methods to use when ordering `Sponsor`. */
export enum SponsorsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  SchoolIdAsc = 'SCHOOL_ID_ASC',
  SchoolIdDesc = 'SCHOOL_ID_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  PrimaryColorAsc = 'PRIMARY_COLOR_ASC',
  PrimaryColorDesc = 'PRIMARY_COLOR_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  ButtonsAsc = 'BUTTONS_ASC',
  ButtonsDesc = 'BUTTONS_DESC',
  CoverImageFileIdAsc = 'COVER_IMAGE_FILE_ID_ASC',
  CoverImageFileIdDesc = 'COVER_IMAGE_FILE_ID_DESC',
  ThumbnailImageFileIdAsc = 'THUMBNAIL_IMAGE_FILE_ID_ASC',
  ThumbnailImageFileIdDesc = 'THUMBNAIL_IMAGE_FILE_ID_DESC',
  LogoImageFileIdAsc = 'LOGO_IMAGE_FILE_ID_ASC',
  LogoImageFileIdDesc = 'LOGO_IMAGE_FILE_ID_DESC',
  PromoImageFileIdAsc = 'PROMO_IMAGE_FILE_ID_ASC',
  PromoImageFileIdDesc = 'PROMO_IMAGE_FILE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `startBuildStep` mutation. */
export type StartBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildStepId: Scalars['UUID'];
  vBitriseBuildSlug: Scalars['String'];
};

/** The output of our `startBuildStep` mutation. */
export type StartBuildStepPayload = {
  __typename?: 'StartBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `stopGoogleWatchChannel` mutation. */
export type StopGoogleWatchChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  watchChannelId: Scalars['String'];
  googleAccountId: Scalars['UUID'];
  authRefreshToken: Scalars['String'];
  watchChannelResourceId: Scalars['String'];
};

/** The output of our `stopGoogleWatchChannel` mutation. */
export type StopGoogleWatchChannelPayload = {
  __typename?: 'StopGoogleWatchChannelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

/** All input for the `submitCurrentPersonSchoolOnboarding` mutation. */
export type SubmitCurrentPersonSchoolOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolId: Scalars['UUID'];
  userTypes: Array<Maybe<UserType>>;
  selectedGroupIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `submitCurrentPersonSchoolOnboarding` mutation. */
export type SubmitCurrentPersonSchoolOnboardingPayload = {
  __typename?: 'SubmitCurrentPersonSchoolOnboardingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolPerson?: Maybe<SchoolPerson>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolPerson`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPerson`. May be used by Relay 1. */
  schoolPersonEdge?: Maybe<SchoolPeopleEdge>;
};


/** The output of our `submitCurrentPersonSchoolOnboarding` mutation. */
export type SubmitCurrentPersonSchoolOnboardingPayloadSchoolPersonEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
};

/** All input for the `succeedBuildStep` mutation. */
export type SucceedBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildStepId: Scalars['UUID'];
};

/** The output of our `succeedBuildStep` mutation. */
export type SucceedBuildStepPayload = {
  __typename?: 'SucceedBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Theme is for building out branding. Primarily for `School`s, but could also end up being used by Sponsors or `Group`s. */
export type Theme = Node & {
  __typename?: 'Theme';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads and enables pagination through a set of `School`. */
  schools: SchoolsConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolThemeIdAndCreatedBy: ThemePeopleBySchoolThemeIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleBySchoolThemeIdAndUpdatedBy: ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyConnection;
};


/** Theme is for building out branding. Primarily for `School`s, but could also end up being used by Sponsors or `Group`s. */
export type ThemeSchoolsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};


/** Theme is for building out branding. Primarily for `School`s, but could also end up being used by Sponsors or `Group`s. */
export type ThemePeopleBySchoolThemeIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};


/** Theme is for building out branding. Primarily for `School`s, but could also end up being used by Sponsors or `Group`s. */
export type ThemePeopleBySchoolThemeIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PeopleOrderBy>>;
  condition?: Maybe<PersonCondition>;
  filter?: Maybe<PersonFilter>;
};

/** A condition to be used against `Theme` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ThemeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `primaryColor` field. */
  primaryColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `secondaryColor` field. */
  secondaryColor?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `theme` in the `PersonInput` mutation. */
export type ThemeCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `theme` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectById?: Maybe<Array<ThemeThemePkeyConnect>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ThemeNodeIdConnect>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteById?: Maybe<Array<ThemeThemePkeyDelete>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ThemeNodeIdDelete>>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateById?: Maybe<Array<ThemeOnThemeForThemeCreatedByFkeyUsingThemePkeyUpdate>>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnThemeForThemeCreatedByFkeyNodeIdUpdate>>;
  /** A `ThemeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ThemeCreatedByFkeyThemeCreateInput>>;
};

/** The `theme` to be created by this mutation. */
export type ThemeCreatedByFkeyThemeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** A filter to be used against `Theme` object types. All fields are combined with a logical ‘and.’ */
export type ThemeFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `primaryColor` field. */
  primaryColor?: Maybe<StringFilter>;
  /** Filter by the object’s `secondaryColor` field. */
  secondaryColor?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ThemeFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ThemeFilter>>;
  /** Negates the expression. */
  not?: Maybe<ThemeFilter>;
};

/** An input for mutations affecting `Theme` */
export type ThemeInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ThemeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `theme` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ThemeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `theme` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ThemeOnSchoolForSchoolThemeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `school` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `school` being updated. */
  patch: SchoolPatch;
};

/** The fields on `theme` to look up the row to update. */
export type ThemeOnSchoolForSchoolThemeIdFkeyUsingThemePkeyUpdate = {
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: UpdateThemeOnSchoolForSchoolThemeIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `theme` to look up the row to update. */
export type ThemeOnThemeForThemeCreatedByFkeyUsingThemePkeyUpdate = {
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: UpdateThemeOnThemeForThemeCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ThemeOnThemeForThemeUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `theme` to look up the row to update. */
export type ThemeOnThemeForThemeUpdatedByFkeyUsingThemePkeyUpdate = {
  /** An object where the defined keys will be set on the `theme` being updated. */
  patch: UpdateThemeOnThemeForThemeUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Theme`. Fields that are set will be updated. */
export type ThemePatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** A connection to a list of `Person` values, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndCreatedByManyToManyConnection = {
  __typename?: 'ThemePeopleBySchoolThemeIdAndCreatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<ThemePeopleBySchoolThemeIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndCreatedByManyToManyEdge = {
  __typename?: 'ThemePeopleBySchoolThemeIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByCreatedBy: SchoolsConnection;
};


/** A `Person` edge in the connection, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndCreatedByManyToManyEdgeSchoolsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** A connection to a list of `Person` values, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyConnection';
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** A list of edges which contains the `Person`, info from the `School`, and the cursor to aid in pagination. */
  edges: Array<ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
  /** Reads and enables pagination through a set of `School`. */
  schoolsByUpdatedBy: SchoolsConnection;
};


/** A `Person` edge in the connection, with data from `School`. */
export type ThemePeopleBySchoolThemeIdAndUpdatedByManyToManyEdgeSchoolsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
  condition?: Maybe<SchoolCondition>;
  filter?: Maybe<SchoolFilter>;
};

/** The fields on `theme` to look up the row to connect. */
export type ThemeThemePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `theme` to look up the row to delete. */
export type ThemeThemePkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `person` in the `ThemeInput` mutation. */
export type ThemeUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnThemeForThemeUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<ThemeOnThemeForThemeUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<ThemeUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `theme` in the `PersonInput` mutation. */
export type ThemeUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `theme` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectById?: Maybe<Array<ThemeThemePkeyConnect>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ThemeNodeIdConnect>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteById?: Maybe<Array<ThemeThemePkeyDelete>>;
  /** The primary key(s) for `theme` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ThemeNodeIdDelete>>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateById?: Maybe<Array<ThemeOnThemeForThemeUpdatedByFkeyUsingThemePkeyUpdate>>;
  /** The primary key(s) and patch data for `theme` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnThemeForThemeUpdatedByFkeyNodeIdUpdate>>;
  /** A `ThemeInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ThemeUpdatedByFkeyThemeCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type ThemeUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `theme` to be created by this mutation. */
export type ThemeUpdatedByFkeyThemeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** A connection to a list of `Theme` values. */
export type ThemesConnection = {
  __typename?: 'ThemesConnection';
  /** A list of `Theme` objects. */
  nodes: Array<Theme>;
  /** A list of edges which contains the `Theme` and cursor to aid in pagination. */
  edges: Array<ThemesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Theme` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Theme` edge in the connection. */
export type ThemesEdge = {
  __typename?: 'ThemesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Theme` at the end of the edge. */
  node: Theme;
};

/** Methods to use when ordering `Theme`. */
export enum ThemesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryColorAsc = 'PRIMARY_COLOR_ASC',
  PrimaryColorDesc = 'PRIMARY_COLOR_DESC',
  SecondaryColorAsc = 'SECONDARY_COLOR_ASC',
  SecondaryColorDesc = 'SECONDARY_COLOR_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against Timezone fields. All fields are combined with a logical ‘and.’ */
export type TimezoneFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Timezone']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Timezone']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Timezone']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Timezone']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Timezone']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Timezone']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Timezone']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Timezone']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Timezone']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Timezone']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['Timezone']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['Timezone']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['Timezone']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['Timezone']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['Timezone']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['Timezone']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['Timezone']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['Timezone']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['Timezone']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['Timezone']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['Timezone']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['Timezone']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['Timezone']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['Timezone']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['Timezone']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['Timezone']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['Timezone']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['Timezone']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['Timezone']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['Timezone']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['Timezone']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['Timezone']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['Timezone']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['Timezone']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['Timezone']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['Timezone']>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** All input for the `updateAndroidAppByAppId` mutation. */
export type UpdateAndroidAppByAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AndroidApp` being updated. */
  patch: AndroidAppPatch;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId: Scalars['UUID'];
};

/** All input for the `updateAndroidAppByNodeId` mutation. */
export type UpdateAndroidAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AndroidApp` being updated. */
  patch: AndroidAppPatch;
};

/** All input for the `updateAndroidApp` mutation. */
export type UpdateAndroidAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AndroidApp` being updated. */
  patch: AndroidAppPatch;
  /** A primary ID for the android_app. */
  id: Scalars['UUID'];
};

/** The output of our update `AndroidApp` mutation. */
export type UpdateAndroidAppPayload = {
  __typename?: 'UpdateAndroidAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidApp` that was updated by this mutation. */
  androidApp?: Maybe<AndroidApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `AndroidApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidApp`. May be used by Relay 1. */
  androidAppEdge?: Maybe<AndroidAppsEdge>;
};


/** The output of our update `AndroidApp` mutation. */
export type UpdateAndroidAppPayloadAndroidAppEdgeArgs = {
  orderBy?: Maybe<Array<AndroidAppsOrderBy>>;
};

/** All input for the `updateAndroidJavaKeyStoreByNodeId` mutation. */
export type UpdateAndroidJavaKeyStoreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidJavaKeyStore` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AndroidJavaKeyStore` being updated. */
  patch: AndroidJavaKeyStorePatch;
};

/** All input for the `updateAndroidJavaKeyStore` mutation. */
export type UpdateAndroidJavaKeyStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AndroidJavaKeyStore` being updated. */
  patch: AndroidJavaKeyStorePatch;
  /** A primary ID for the jks. */
  id: Scalars['UUID'];
};

/** The output of our update `AndroidJavaKeyStore` mutation. */
export type UpdateAndroidJavaKeyStorePayload = {
  __typename?: 'UpdateAndroidJavaKeyStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidJavaKeyStore` that was updated by this mutation. */
  androidJavaKeyStore?: Maybe<AndroidJavaKeyStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `AndroidJavaKeyStore`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidJavaKeyStore`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidJavaKeyStore`. May be used by Relay 1. */
  androidJavaKeyStoreEdge?: Maybe<AndroidJavaKeyStoresEdge>;
};


/** The output of our update `AndroidJavaKeyStore` mutation. */
export type UpdateAndroidJavaKeyStorePayloadAndroidJavaKeyStoreEdgeArgs = {
  orderBy?: Maybe<Array<AndroidJavaKeyStoresOrderBy>>;
};

/** All input for the `updateAndroidScreenshotByNodeId` mutation. */
export type UpdateAndroidScreenshotByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AndroidScreenshot` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AndroidScreenshot` being updated. */
  patch: AndroidScreenshotPatch;
};

/** All input for the `updateAndroidScreenshot` mutation. */
export type UpdateAndroidScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AndroidScreenshot` being updated. */
  patch: AndroidScreenshotPatch;
  /** A primary key for the apple_screenshots. */
  id: Scalars['UUID'];
};

/** The output of our update `AndroidScreenshot` mutation. */
export type UpdateAndroidScreenshotPayload = {
  __typename?: 'UpdateAndroidScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AndroidScreenshot` that was updated by this mutation. */
  androidScreenshot?: Maybe<AndroidScreenshot>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayListing` that is related to this `AndroidScreenshot`. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AndroidScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AndroidScreenshot`. May be used by Relay 1. */
  androidScreenshotEdge?: Maybe<AndroidScreenshotsEdge>;
};


/** The output of our update `AndroidScreenshot` mutation. */
export type UpdateAndroidScreenshotPayloadAndroidScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<AndroidScreenshotsOrderBy>>;
};

/** All input for the `updateAppByNodeId` mutation. */
export type UpdateAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `App` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `App` being updated. */
  patch: AppPatch;
};

/** All input for the `updateAppBySchoolId` mutation. */
export type UpdateAppBySchoolIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `App` being updated. */
  patch: AppPatch;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId: Scalars['UUID'];
};

/** All input for the `updateApp` mutation. */
export type UpdateAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `App` being updated. */
  patch: AppPatch;
  /** A primary ID for the app. */
  id: Scalars['UUID'];
};

/** The output of our update `App` mutation. */
export type UpdateAppPayload = {
  __typename?: 'UpdateAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `App` that was updated by this mutation. */
  app?: Maybe<App>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `App`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `App`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `App`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `App`. May be used by Relay 1. */
  appEdge?: Maybe<AppsEdge>;
};


/** The output of our update `App` mutation. */
export type UpdateAppPayloadAppEdgeArgs = {
  orderBy?: Maybe<Array<AppsOrderBy>>;
};

/** All input for the `updateAppStoreAccountByAppStoreConnectId` mutation. */
export type UpdateAppStoreAccountByAppStoreConnectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId: Scalars['String'];
};

/** All input for the `updateAppStoreAccountByAppleDeveloperAccountId` mutation. */
export type UpdateAppStoreAccountByAppleDeveloperAccountIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId: Scalars['String'];
};

/** All input for the `updateAppStoreAccountByNodeId` mutation. */
export type UpdateAppStoreAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreAccount` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AppStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
};

/** All input for the `updateAppStoreAccountByOrganizationName` mutation. */
export type UpdateAppStoreAccountByOrganizationNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName: Scalars['String'];
};

/** All input for the `updateAppStoreAccount` mutation. */
export type UpdateAppStoreAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreAccount` being updated. */
  patch: AppStoreAccountPatch;
  /** The primary key for `app_store_account`. */
  id: Scalars['UUID'];
};

/** The output of our update `AppStoreAccount` mutation. */
export type UpdateAppStoreAccountPayload = {
  __typename?: 'UpdateAppStoreAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreAccount` that was updated by this mutation. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreUser` that is related to this `AppStoreAccount`. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreAccount`. May be used by Relay 1. */
  appStoreAccountEdge?: Maybe<AppStoreAccountsEdge>;
};


/** The output of our update `AppStoreAccount` mutation. */
export type UpdateAppStoreAccountPayloadAppStoreAccountEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreAccountsOrderBy>>;
};

/** All input for the `updateAppStoreListingByIosAppId` mutation. */
export type UpdateAppStoreListingByIosAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreListing` being updated. */
  patch: AppStoreListingPatch;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId: Scalars['UUID'];
};

/** All input for the `updateAppStoreListingByNodeId` mutation. */
export type UpdateAppStoreListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreListing` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AppStoreListing` being updated. */
  patch: AppStoreListingPatch;
};

/** All input for the `updateAppStoreListing` mutation. */
export type UpdateAppStoreListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreListing` being updated. */
  patch: AppStoreListingPatch;
  /** The primary key for `app_store_listing`. */
  id: Scalars['UUID'];
};

/** The output of our update `AppStoreListing` mutation. */
export type UpdateAppStoreListingPayload = {
  __typename?: 'UpdateAppStoreListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreListing` that was updated by this mutation. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `IosApp` that is related to this `AppStoreListing`. */
  iosApp?: Maybe<IosApp>;
  /** Reads a single `AppStoreAccount` that is related to this `AppStoreListing`. */
  appStoreAccount?: Maybe<AppStoreAccount>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreListing`. May be used by Relay 1. */
  appStoreListingEdge?: Maybe<AppStoreListingsEdge>;
};


/** The output of our update `AppStoreListing` mutation. */
export type UpdateAppStoreListingPayloadAppStoreListingEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreListingsOrderBy>>;
};

/** All input for the `updateAppStoreUserByEmail` mutation. */
export type UpdateAppStoreUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreUser` being updated. */
  patch: AppStoreUserPatch;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email: Scalars['String'];
};

/** All input for the `updateAppStoreUserByNodeId` mutation. */
export type UpdateAppStoreUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AppStoreUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AppStoreUser` being updated. */
  patch: AppStoreUserPatch;
};

/** All input for the `updateAppStoreUser` mutation. */
export type UpdateAppStoreUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AppStoreUser` being updated. */
  patch: AppStoreUserPatch;
  /** The primary key for `app_store_user`. */
  id: Scalars['UUID'];
};

/** The output of our update `AppStoreUser` mutation. */
export type UpdateAppStoreUserPayload = {
  __typename?: 'UpdateAppStoreUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AppStoreUser` that was updated by this mutation. */
  appStoreUser?: Maybe<AppStoreUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `AppStoreUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `AppStoreUser`. May be used by Relay 1. */
  appStoreUserEdge?: Maybe<AppStoreUsersEdge>;
};


/** The output of our update `AppStoreUser` mutation. */
export type UpdateAppStoreUserPayloadAppStoreUserEdgeArgs = {
  orderBy?: Maybe<Array<AppStoreUsersOrderBy>>;
};

/** All input for the `updateBuildByNodeId` mutation. */
export type UpdateBuildByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Build` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Build` being updated. */
  patch: BuildPatch;
};

/** All input for the `updateBuild` mutation. */
export type UpdateBuildInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Build` being updated. */
  patch: BuildPatch;
  /** The primary key for `build`. */
  id: Scalars['UUID'];
};

/** The output of our update `Build` mutation. */
export type UpdateBuildPayload = {
  __typename?: 'UpdateBuildPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Build` that was updated by this mutation. */
  build?: Maybe<Build>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `Build`. */
  app?: Maybe<App>;
  /** Reads a single `Release` that is related to this `Build`. */
  release?: Maybe<Release>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Build`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Build`. May be used by Relay 1. */
  buildEdge?: Maybe<BuildsEdge>;
};


/** The output of our update `Build` mutation. */
export type UpdateBuildPayloadBuildEdgeArgs = {
  orderBy?: Maybe<Array<BuildsOrderBy>>;
};

/** All input for the `updateBuildStepByNodeId` mutation. */
export type UpdateBuildStepByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuildStep` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BuildStep` being updated. */
  patch: BuildStepPatch;
};

/** All input for the `updateBuildStep` mutation. */
export type UpdateBuildStepInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `BuildStep` being updated. */
  patch: BuildStepPatch;
  /** The primary key for `build_step`. */
  id: Scalars['UUID'];
};

/** The output of our update `BuildStep` mutation. */
export type UpdateBuildStepPayload = {
  __typename?: 'UpdateBuildStepPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BuildStep` that was updated by this mutation. */
  buildStep?: Maybe<BuildStep>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Build` that is related to this `BuildStep`. */
  build?: Maybe<Build>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `BuildStep`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `BuildStep`. May be used by Relay 1. */
  buildStepEdge?: Maybe<BuildStepsEdge>;
};


/** The output of our update `BuildStep` mutation. */
export type UpdateBuildStepPayloadBuildStepEdgeArgs = {
  orderBy?: Maybe<Array<BuildStepsOrderBy>>;
};

/** All input for the `updateCalendarByNodeId` mutation. */
export type UpdateCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Calendar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Calendar` being updated. */
  patch: CalendarPatch;
};

/** All input for the `updateCalendarEventByEventIdAndCalendarId` mutation. */
export type UpdateCalendarEventByEventIdAndCalendarIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CalendarEvent` being updated. */
  patch: CalendarEventPatch;
  eventId: Scalars['UUID'];
  calendarId: Scalars['UUID'];
};

/** All input for the `updateCalendarEventByNodeId` mutation. */
export type UpdateCalendarEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CalendarEvent` being updated. */
  patch: CalendarEventPatch;
};

/** All input for the `updateCalendarEvent` mutation. */
export type UpdateCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CalendarEvent` being updated. */
  patch: CalendarEventPatch;
  id: Scalars['UUID'];
};

/** The output of our update `CalendarEvent` mutation. */
export type UpdateCalendarEventPayload = {
  __typename?: 'UpdateCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarEvent` that was updated by this mutation. */
  calendarEvent?: Maybe<CalendarEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarEvent`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `Event` that is related to this `CalendarEvent`. */
  event?: Maybe<Event>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarEvent`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarEvent`. May be used by Relay 1. */
  calendarEventEdge?: Maybe<CalendarEventsEdge>;
};


/** The output of our update `CalendarEvent` mutation. */
export type UpdateCalendarEventPayloadCalendarEventEdgeArgs = {
  orderBy?: Maybe<Array<CalendarEventsOrderBy>>;
};

/** All input for the `updateCalendarGroupByNodeId` mutation. */
export type UpdateCalendarGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CalendarGroup` being updated. */
  patch: CalendarGroupPatch;
};

/** All input for the `updateCalendarGroupCalendarByCalendarIdAndCalendarGroupId` mutation. */
export type UpdateCalendarGroupCalendarByCalendarIdAndCalendarGroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CalendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
  calendarId: Scalars['UUID'];
  calendarGroupId: Scalars['UUID'];
};

/** All input for the `updateCalendarGroupCalendarByNodeId` mutation. */
export type UpdateCalendarGroupCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CalendarGroupCalendar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CalendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
};

/** All input for the `updateCalendarGroupCalendar` mutation. */
export type UpdateCalendarGroupCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CalendarGroupCalendar` being updated. */
  patch: CalendarGroupCalendarPatch;
  id: Scalars['UUID'];
};

/** The output of our update `CalendarGroupCalendar` mutation. */
export type UpdateCalendarGroupCalendarPayload = {
  __typename?: 'UpdateCalendarGroupCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroupCalendar` that was updated by this mutation. */
  calendarGroupCalendar?: Maybe<CalendarGroupCalendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Calendar` that is related to this `CalendarGroupCalendar`. */
  calendar?: Maybe<Calendar>;
  /** Reads a single `CalendarGroup` that is related to this `CalendarGroupCalendar`. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroupCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `CalendarGroupCalendar`. May be used by Relay 1. */
  calendarGroupCalendarEdge?: Maybe<CalendarGroupCalendarsEdge>;
};


/** The output of our update `CalendarGroupCalendar` mutation. */
export type UpdateCalendarGroupCalendarPayloadCalendarGroupCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupCalendarsOrderBy>>;
};

/** All input for the `updateCalendarGroup` mutation. */
export type UpdateCalendarGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CalendarGroup` being updated. */
  patch: CalendarGroupPatch;
  id: Scalars['UUID'];
};

/** The output of our update `CalendarGroup` mutation. */
export type UpdateCalendarGroupPayload = {
  __typename?: 'UpdateCalendarGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CalendarGroup` that was updated by this mutation. */
  calendarGroup?: Maybe<CalendarGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `CalendarGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `CalendarGroup`. */
  school?: Maybe<School>;
  /** An edge for our `CalendarGroup`. May be used by Relay 1. */
  calendarGroupEdge?: Maybe<CalendarGroupsEdge>;
};


/** The output of our update `CalendarGroup` mutation. */
export type UpdateCalendarGroupPayloadCalendarGroupEdgeArgs = {
  orderBy?: Maybe<Array<CalendarGroupsOrderBy>>;
};

/** All input for the `updateCalendar` mutation. */
export type UpdateCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Calendar` being updated. */
  patch: CalendarPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Calendar` mutation. */
export type UpdateCalendarPayload = {
  __typename?: 'UpdateCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Calendar` that was updated by this mutation. */
  calendar?: Maybe<Calendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Calendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Calendar`. */
  group?: Maybe<Group>;
  /** An edge for our `Calendar`. May be used by Relay 1. */
  calendarEdge?: Maybe<CalendarsEdge>;
};


/** The output of our update `Calendar` mutation. */
export type UpdateCalendarPayloadCalendarEdgeArgs = {
  orderBy?: Maybe<Array<CalendarsOrderBy>>;
};

/** All input for the `updateCurrentUserSessionData` mutation. */
export type UpdateCurrentUserSessionDataInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionDataPartial: Scalars['JSON'];
};

/** The output of our `updateCurrentUserSessionData` mutation. */
export type UpdateCurrentUserSessionDataPayload = {
  __typename?: 'UpdateCurrentUserSessionDataPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDistCertExpDate` mutation. */
export type UpdateDistCertExpDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAppStoreAccountId: Scalars['UUID'];
};

/** The output of our `updateDistCertExpDate` mutation. */
export type UpdateDistCertExpDatePayload = {
  __typename?: 'UpdateDistCertExpDatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDivisionByNodeId` mutation. */
export type UpdateDivisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Division` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Division` being updated. */
  patch: DivisionPatch;
};

/** All input for the `updateDivision` mutation. */
export type UpdateDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Division` being updated. */
  patch: DivisionPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Division` mutation. */
export type UpdateDivisionPayload = {
  __typename?: 'UpdateDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Division` that was updated by this mutation. */
  division?: Maybe<Division>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Division`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Division`. */
  school?: Maybe<School>;
  /** Reads a single `Group` that is related to this `Division`. */
  group?: Maybe<Group>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge?: Maybe<DivisionsEdge>;
};


/** The output of our update `Division` mutation. */
export type UpdateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: Maybe<Array<DivisionsOrderBy>>;
};

/** All input for the `updateEventByGoogleId` mutation. */
export type UpdateEventByGoogleIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
  googleId: Scalars['String'];
};

/** All input for the `updateEventByNodeId` mutation. */
export type UpdateEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
};

/** All input for the `updateEventBySlug` mutation. */
export type UpdateEventBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateEvent` mutation. */
export type UpdateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Event` being updated. */
  patch: EventPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Event` mutation. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was updated by this mutation. */
  event?: Maybe<Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Event`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Event`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Event`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our update `Event` mutation. */
export type UpdateEventPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the `updateExpirationDateOnPushCert` mutation. */
export type UpdateExpirationDateOnPushCertInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vBuildId: Scalars['UUID'];
};

/** The output of our `updateExpirationDateOnPushCert` mutation. */
export type UpdateExpirationDateOnPushCertPayload = {
  __typename?: 'UpdateExpirationDateOnPushCertPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateFileByNodeId` mutation. */
export type UpdateFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
};

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
  id: Scalars['UUID'];
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was updated by this mutation. */
  file?: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `File`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `File`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
};


/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: Maybe<Array<FilesOrderBy>>;
};

/** All input for the `updateGoogleAccountByNodeId` mutation. */
export type UpdateGoogleAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleAccount` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GoogleAccount` being updated. */
  patch: GoogleAccountPatch;
};

/** All input for the `updateGoogleAccount` mutation. */
export type UpdateGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GoogleAccount` being updated. */
  patch: GoogleAccountPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GoogleAccount` mutation. */
export type UpdateGoogleAccountPayload = {
  __typename?: 'UpdateGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleAccount` that was updated by this mutation. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GoogleAccount`. May be used by Relay 1. */
  googleAccountEdge?: Maybe<GoogleAccountsEdge>;
};


/** The output of our update `GoogleAccount` mutation. */
export type UpdateGoogleAccountPayloadGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<GoogleAccountsOrderBy>>;
};

/** All input for the `updateGoogleCalendarSyncByNodeId` mutation. */
export type UpdateGoogleCalendarSyncByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarSync` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GoogleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
};

/** All input for the `updateGoogleCalendarSyncCalendarByNodeId` mutation. */
export type UpdateGoogleCalendarSyncCalendarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarSyncCalendar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GoogleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
};

/** All input for the `updateGoogleCalendarSyncCalendar` mutation. */
export type UpdateGoogleCalendarSyncCalendarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GoogleCalendarSyncCalendar` being updated. */
  patch: GoogleCalendarSyncCalendarPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GoogleCalendarSyncCalendar` mutation. */
export type UpdateGoogleCalendarSyncCalendarPayload = {
  __typename?: 'UpdateGoogleCalendarSyncCalendarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSyncCalendar` that was updated by this mutation. */
  googleCalendarSyncCalendar?: Maybe<GoogleCalendarSyncCalendar>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSyncCalendar`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleCalendarSync` that is related to this `GoogleCalendarSyncCalendar`. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Reads a single `Calendar` that is related to this `GoogleCalendarSyncCalendar`. */
  calendar?: Maybe<Calendar>;
  /** An edge for our `GoogleCalendarSyncCalendar`. May be used by Relay 1. */
  googleCalendarSyncCalendarEdge?: Maybe<GoogleCalendarSyncCalendarsEdge>;
};


/** The output of our update `GoogleCalendarSyncCalendar` mutation. */
export type UpdateGoogleCalendarSyncCalendarPayloadGoogleCalendarSyncCalendarEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncCalendarsOrderBy>>;
};

/** All input for the `updateGoogleCalendarSync` mutation. */
export type UpdateGoogleCalendarSyncInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GoogleCalendarSync` being updated. */
  patch: GoogleCalendarSyncPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GoogleCalendarSync` mutation. */
export type UpdateGoogleCalendarSyncPayload = {
  __typename?: 'UpdateGoogleCalendarSyncPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GoogleCalendarSync` that was updated by this mutation. */
  googleCalendarSync?: Maybe<GoogleCalendarSync>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GoogleCalendarSync`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GoogleAccount` that is related to this `GoogleCalendarSync`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** An edge for our `GoogleCalendarSync`. May be used by Relay 1. */
  googleCalendarSyncEdge?: Maybe<GoogleCalendarSyncsEdge>;
};


/** The output of our update `GoogleCalendarSync` mutation. */
export type UpdateGoogleCalendarSyncPayloadGoogleCalendarSyncEdgeArgs = {
  orderBy?: Maybe<Array<GoogleCalendarSyncsOrderBy>>;
};

/** All input for the `updateGooglePlayAccountByNodeId` mutation. */
export type UpdateGooglePlayAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayAccount` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GooglePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
};

/** All input for the `updateGooglePlayAccount` mutation. */
export type UpdateGooglePlayAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GooglePlayAccount` being updated. */
  patch: GooglePlayAccountPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GooglePlayAccount` mutation. */
export type UpdateGooglePlayAccountPayload = {
  __typename?: 'UpdateGooglePlayAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayAccount` that was updated by this mutation. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GooglePlayUser` that is related to this `GooglePlayAccount`. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayAccount`. May be used by Relay 1. */
  googlePlayAccountEdge?: Maybe<GooglePlayAccountsEdge>;
};


/** The output of our update `GooglePlayAccount` mutation. */
export type UpdateGooglePlayAccountPayloadGooglePlayAccountEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayAccountsOrderBy>>;
};

/** All input for the `updateGooglePlayListingByAndroidAppId` mutation. */
export type UpdateGooglePlayListingByAndroidAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GooglePlayListing` being updated. */
  patch: GooglePlayListingPatch;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId: Scalars['UUID'];
};

/** All input for the `updateGooglePlayListingByNodeId` mutation. */
export type UpdateGooglePlayListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayListing` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GooglePlayListing` being updated. */
  patch: GooglePlayListingPatch;
};

/** All input for the `updateGooglePlayListing` mutation. */
export type UpdateGooglePlayListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GooglePlayListing` being updated. */
  patch: GooglePlayListingPatch;
  /** A primary ID for the android_listing. */
  id: Scalars['UUID'];
};

/** The output of our update `GooglePlayListing` mutation. */
export type UpdateGooglePlayListingPayload = {
  __typename?: 'UpdateGooglePlayListingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayListing` that was updated by this mutation. */
  googlePlayListing?: Maybe<GooglePlayListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AndroidApp` that is related to this `GooglePlayListing`. */
  androidApp?: Maybe<AndroidApp>;
  /** Reads a single `GooglePlayAccount` that is related to this `GooglePlayListing`. */
  googlePlayAccount?: Maybe<GooglePlayAccount>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayListing`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayListing`. May be used by Relay 1. */
  googlePlayListingEdge?: Maybe<GooglePlayListingsEdge>;
};


/** The output of our update `GooglePlayListing` mutation. */
export type UpdateGooglePlayListingPayloadGooglePlayListingEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayListingsOrderBy>>;
};

/** All input for the `updateGooglePlayUserByEmail` mutation. */
export type UpdateGooglePlayUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GooglePlayUser` being updated. */
  patch: GooglePlayUserPatch;
  /** The email used to login */
  email: Scalars['String'];
};

/** All input for the `updateGooglePlayUserByNodeId` mutation. */
export type UpdateGooglePlayUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GooglePlayUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GooglePlayUser` being updated. */
  patch: GooglePlayUserPatch;
};

/** All input for the `updateGooglePlayUser` mutation. */
export type UpdateGooglePlayUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GooglePlayUser` being updated. */
  patch: GooglePlayUserPatch;
  /** The primary key for the google play user. */
  id: Scalars['UUID'];
};

/** The output of our update `GooglePlayUser` mutation. */
export type UpdateGooglePlayUserPayload = {
  __typename?: 'UpdateGooglePlayUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GooglePlayUser` that was updated by this mutation. */
  googlePlayUser?: Maybe<GooglePlayUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GooglePlayUser`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GooglePlayUser`. May be used by Relay 1. */
  googlePlayUserEdge?: Maybe<GooglePlayUsersEdge>;
};


/** The output of our update `GooglePlayUser` mutation. */
export type UpdateGooglePlayUserPayloadGooglePlayUserEdgeArgs = {
  orderBy?: Maybe<Array<GooglePlayUsersOrderBy>>;
};

/** All input for the `updateGroupByNodeId` mutation. */
export type UpdateGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** All input for the `updateGroupBySlug` mutation. */
export type UpdateGroupBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  slug: Scalars['String'];
};

/** All input for the `updateGroupCategoryByNodeId` mutation. */
export type UpdateGroupCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupCategory` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GroupCategory` being updated. */
  patch: GroupCategoryPatch;
};

/** All input for the `updateGroupCategory` mutation. */
export type UpdateGroupCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GroupCategory` being updated. */
  patch: GroupCategoryPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GroupCategory` mutation. */
export type UpdateGroupCategoryPayload = {
  __typename?: 'UpdateGroupCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupCategory` that was updated by this mutation. */
  groupCategory?: Maybe<GroupCategory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupCategory`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Division` that is related to this `GroupCategory`. */
  division?: Maybe<Division>;
  /** An edge for our `GroupCategory`. May be used by Relay 1. */
  groupCategoryEdge?: Maybe<GroupCategoriesEdge>;
};


/** The output of our update `GroupCategory` mutation. */
export type UpdateGroupCategoryPayloadGroupCategoryEdgeArgs = {
  orderBy?: Maybe<Array<GroupCategoriesOrderBy>>;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateGroupNewsletterByGroupIdAndNewsletterId` mutation. */
export type UpdateGroupNewsletterByGroupIdAndNewsletterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GroupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
  groupId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** All input for the `updateGroupNewsletterByNodeId` mutation. */
export type UpdateGroupNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupNewsletter` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GroupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
};

/** All input for the `updateGroupNewsletter` mutation. */
export type UpdateGroupNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GroupNewsletter` being updated. */
  patch: GroupNewsletterPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GroupNewsletter` mutation. */
export type UpdateGroupNewsletterPayload = {
  __typename?: 'UpdateGroupNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupNewsletter` that was updated by this mutation. */
  groupNewsletter?: Maybe<GroupNewsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupNewsletter`. */
  group?: Maybe<Group>;
  /** Reads a single `Newsletter` that is related to this `GroupNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupNewsletter`. May be used by Relay 1. */
  groupNewsletterEdge?: Maybe<GroupNewslettersEdge>;
};


/** The output of our update `GroupNewsletter` mutation. */
export type UpdateGroupNewsletterPayloadGroupNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<GroupNewslettersOrderBy>>;
};

/** The output of our update `Group` mutation. */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was updated by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Group`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `GroupCategory` that is related to this `Group`. */
  groupCategory?: Maybe<GroupCategory>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our update `Group` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the `updateGroupPersonByGroupIdAndPersonId` mutation. */
export type UpdateGroupPersonByGroupIdAndPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GroupPerson` being updated. */
  patch: GroupPersonPatch;
  groupId: Scalars['UUID'];
  personId: Scalars['UUID'];
};

/** All input for the `updateGroupPersonByNodeId` mutation. */
export type UpdateGroupPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupPerson` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GroupPerson` being updated. */
  patch: GroupPersonPatch;
};

/** All input for the `updateGroupPerson` mutation. */
export type UpdateGroupPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `GroupPerson` being updated. */
  patch: GroupPersonPatch;
  id: Scalars['UUID'];
};

/** The output of our update `GroupPerson` mutation. */
export type UpdateGroupPersonPayload = {
  __typename?: 'UpdateGroupPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupPerson` that was updated by this mutation. */
  groupPerson?: Maybe<GroupPerson>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `GroupPerson`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `GroupPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `GroupPerson`. May be used by Relay 1. */
  groupPersonEdge?: Maybe<GroupPeopleEdge>;
};


/** The output of our update `GroupPerson` mutation. */
export type UpdateGroupPersonPayloadGroupPersonEdgeArgs = {
  orderBy?: Maybe<Array<GroupPeopleOrderBy>>;
};

/** All input for the `updateIosAppByAppId` mutation. */
export type UpdateIosAppByAppIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IosApp` being updated. */
  patch: IosAppPatch;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId: Scalars['UUID'];
};

/** All input for the `updateIosAppByNodeId` mutation. */
export type UpdateIosAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IosApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IosApp` being updated. */
  patch: IosAppPatch;
};

/** All input for the `updateIosApp` mutation. */
export type UpdateIosAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IosApp` being updated. */
  patch: IosAppPatch;
  /** The primary key for `ios_app`. */
  id: Scalars['UUID'];
};

/** The output of our update `IosApp` mutation. */
export type UpdateIosAppPayload = {
  __typename?: 'UpdateIosAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosApp` that was updated by this mutation. */
  iosApp?: Maybe<IosApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `App` that is related to this `IosApp`. */
  app?: Maybe<App>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosApp`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosApp`. May be used by Relay 1. */
  iosAppEdge?: Maybe<IosAppsEdge>;
};


/** The output of our update `IosApp` mutation. */
export type UpdateIosAppPayloadIosAppEdgeArgs = {
  orderBy?: Maybe<Array<IosAppsOrderBy>>;
};

/** All input for the `updateIosScreenshotByNodeId` mutation. */
export type UpdateIosScreenshotByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IosScreenshot` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IosScreenshot` being updated. */
  patch: IosScreenshotPatch;
};

/** All input for the `updateIosScreenshot` mutation. */
export type UpdateIosScreenshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IosScreenshot` being updated. */
  patch: IosScreenshotPatch;
  /** The primary key for `ios_screenshot`. */
  id: Scalars['UUID'];
};

/** The output of our update `IosScreenshot` mutation. */
export type UpdateIosScreenshotPayload = {
  __typename?: 'UpdateIosScreenshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IosScreenshot` that was updated by this mutation. */
  iosScreenshot?: Maybe<IosScreenshot>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AppStoreListing` that is related to this `IosScreenshot`. */
  appStoreListing?: Maybe<AppStoreListing>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `IosScreenshot`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `IosScreenshot`. May be used by Relay 1. */
  iosScreenshotEdge?: Maybe<IosScreenshotsEdge>;
};


/** The output of our update `IosScreenshot` mutation. */
export type UpdateIosScreenshotPayloadIosScreenshotEdgeArgs = {
  orderBy?: Maybe<Array<IosScreenshotsOrderBy>>;
};

/** All input for the `updateJksWithJks64FromApp` mutation. */
export type UpdateJksWithJks64FromAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vAppId: Scalars['UUID'];
  vJks64: Scalars['String'];
};

/** The output of our `updateJksWithJks64FromApp` mutation. */
export type UpdateJksWithJks64FromAppPayload = {
  __typename?: 'UpdateJksWithJks64FromAppPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateMarqueeByNodeId` mutation. */
export type UpdateMarqueeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Marquee` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Marquee` being updated. */
  patch: MarqueePatch;
};

/** All input for the `updateMarquee` mutation. */
export type UpdateMarqueeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Marquee` being updated. */
  patch: MarqueePatch;
  id: Scalars['UUID'];
};

/** The output of our update `Marquee` mutation. */
export type UpdateMarqueePayload = {
  __typename?: 'UpdateMarqueePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Marquee` that was updated by this mutation. */
  marquee?: Maybe<Marquee>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Marquee`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Marquee`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Marquee`. */
  marqueeImage?: Maybe<File>;
  /** An edge for our `Marquee`. May be used by Relay 1. */
  marqueeEdge?: Maybe<MarqueesEdge>;
};


/** The output of our update `Marquee` mutation. */
export type UpdateMarqueePayloadMarqueeEdgeArgs = {
  orderBy?: Maybe<Array<MarqueesOrderBy>>;
};

/** All input for the `updateNewsletterByNodeId` mutation. */
export type UpdateNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Newsletter` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Newsletter` being updated. */
  patch: NewsletterPatch;
};

/** All input for the `updateNewsletter` mutation. */
export type UpdateNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Newsletter` being updated. */
  patch: NewsletterPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Newsletter` mutation. */
export type UpdateNewsletterPayload = {
  __typename?: 'UpdateNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Newsletter` that was updated by this mutation. */
  newsletter?: Maybe<Newsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** An edge for our `Newsletter`. May be used by Relay 1. */
  newsletterEdge?: Maybe<NewslettersEdge>;
};


/** The output of our update `Newsletter` mutation. */
export type UpdateNewsletterPayloadNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
};

/** All input for the `updateNewsletterRecipients` mutation. */
export type UpdateNewsletterRecipientsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newsletterId: Scalars['UUID'];
  newsletterGroupIds: Array<Maybe<Scalars['UUID']>>;
  newsletterUserTypes: Array<Maybe<UserType>>;
};

/** The output of our `updateNewsletterRecipients` mutation. */
export type UpdateNewsletterRecipientsPayload = {
  __typename?: 'UpdateNewsletterRecipientsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Newsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `Newsletter`. */
  page?: Maybe<Page>;
  /** Reads a single `File` that is related to this `Newsletter`. */
  coverImage?: Maybe<File>;
  /** An edge for our `Newsletter`. May be used by Relay 1. */
  newsletterEdge?: Maybe<NewslettersEdge>;
};


/** The output of our `updateNewsletterRecipients` mutation. */
export type UpdateNewsletterRecipientsPayloadNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NewslettersOrderBy>>;
};

/** All input for the `updateNotificationByNodeId` mutation. */
export type UpdateNotificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Notification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch;
};

/** All input for the `updateNotificationBySlug` mutation. */
export type UpdateNotificationBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateNotificationFileByFileIdAndNotificationId` mutation. */
export type UpdateNotificationFileByFileIdAndNotificationIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationFile` being updated. */
  patch: NotificationFilePatch;
  fileId: Scalars['UUID'];
  notificationId: Scalars['UUID'];
};

/** All input for the `updateNotificationFileByNodeId` mutation. */
export type UpdateNotificationFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationFile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NotificationFile` being updated. */
  patch: NotificationFilePatch;
};

/** All input for the `updateNotificationFile` mutation. */
export type UpdateNotificationFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationFile` being updated. */
  patch: NotificationFilePatch;
  id: Scalars['UUID'];
};

/** The output of our update `NotificationFile` mutation. */
export type UpdateNotificationFilePayload = {
  __typename?: 'UpdateNotificationFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationFile` that was updated by this mutation. */
  notificationFile?: Maybe<NotificationFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationFile`. */
  notification?: Maybe<Notification>;
  /** Reads a single `File` that is related to this `NotificationFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationFile`. May be used by Relay 1. */
  notificationFileEdge?: Maybe<NotificationFilesEdge>;
};


/** The output of our update `NotificationFile` mutation. */
export type UpdateNotificationFilePayloadNotificationFileEdgeArgs = {
  orderBy?: Maybe<Array<NotificationFilesOrderBy>>;
};

/** All input for the `updateNotificationGroupByNodeId` mutation. */
export type UpdateNotificationGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NotificationGroup` being updated. */
  patch: NotificationGroupPatch;
};

/** All input for the `updateNotificationGroupByNotificationIdAndGroupId` mutation. */
export type UpdateNotificationGroupByNotificationIdAndGroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationGroup` being updated. */
  patch: NotificationGroupPatch;
  notificationId: Scalars['UUID'];
  groupId: Scalars['UUID'];
};

/** All input for the `updateNotificationGroup` mutation. */
export type UpdateNotificationGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationGroup` being updated. */
  patch: NotificationGroupPatch;
  id: Scalars['UUID'];
};

/** The output of our update `NotificationGroup` mutation. */
export type UpdateNotificationGroupPayload = {
  __typename?: 'UpdateNotificationGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationGroup` that was updated by this mutation. */
  notificationGroup?: Maybe<NotificationGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationGroup`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Group` that is related to this `NotificationGroup`. */
  group?: Maybe<Group>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationGroup`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationGroup`. May be used by Relay 1. */
  notificationGroupEdge?: Maybe<NotificationGroupsEdge>;
};


/** The output of our update `NotificationGroup` mutation. */
export type UpdateNotificationGroupPayloadNotificationGroupEdgeArgs = {
  orderBy?: Maybe<Array<NotificationGroupsOrderBy>>;
};

/** All input for the `updateNotification` mutation. */
export type UpdateNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Notification` being updated. */
  patch: NotificationPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateNotificationNewsletterByNodeId` mutation. */
export type UpdateNotificationNewsletterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationNewsletter` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NotificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
};

/** All input for the `updateNotificationNewsletterByNotificationIdAndNewsletterId` mutation. */
export type UpdateNotificationNewsletterByNotificationIdAndNewsletterIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
  notificationId: Scalars['UUID'];
  newsletterId: Scalars['UUID'];
};

/** All input for the `updateNotificationNewsletter` mutation. */
export type UpdateNotificationNewsletterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `NotificationNewsletter` being updated. */
  patch: NotificationNewsletterPatch;
  id: Scalars['UUID'];
};

/** The output of our update `NotificationNewsletter` mutation. */
export type UpdateNotificationNewsletterPayload = {
  __typename?: 'UpdateNotificationNewsletterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationNewsletter` that was updated by this mutation. */
  notificationNewsletter?: Maybe<NotificationNewsletter>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Notification` that is related to this `NotificationNewsletter`. */
  notification?: Maybe<Notification>;
  /** Reads a single `Newsletter` that is related to this `NotificationNewsletter`. */
  newsletter?: Maybe<Newsletter>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `NotificationNewsletter`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `NotificationNewsletter`. May be used by Relay 1. */
  notificationNewsletterEdge?: Maybe<NotificationNewslettersEdge>;
};


/** The output of our update `NotificationNewsletter` mutation. */
export type UpdateNotificationNewsletterPayloadNotificationNewsletterEdgeArgs = {
  orderBy?: Maybe<Array<NotificationNewslettersOrderBy>>;
};

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayload = {
  __typename?: 'UpdateNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Notification` that was updated by this mutation. */
  notification?: Maybe<Notification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Notification`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Notification` that is related to this `Notification`. */
  notificationByRecurringParent?: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge?: Maybe<NotificationsEdge>;
};


/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: Maybe<Array<NotificationsOrderBy>>;
};

/** All input for the `updatePageByNodeId` mutation. */
export type UpdatePageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Page` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Page` being updated. */
  patch: PagePatch;
};

/** All input for the `updatePage` mutation. */
export type UpdatePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Page` being updated. */
  patch: PagePatch;
  id: Scalars['UUID'];
};

/** The output of our update `Page` mutation. */
export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Page` that was updated by this mutation. */
  page?: Maybe<Page>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Page`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Page`. May be used by Relay 1. */
  pageEdge?: Maybe<PagesEdge>;
};


/** The output of our update `Page` mutation. */
export type UpdatePagePayloadPageEdgeArgs = {
  orderBy?: Maybe<Array<PagesOrderBy>>;
};

/** All input for the `updatePageSectionByNodeId` mutation. */
export type UpdatePageSectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PageSection` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PageSection` being updated. */
  patch: PageSectionPatch;
};

/** All input for the `updatePageSectionFileByFileIdAndPageSectionId` mutation. */
export type UpdatePageSectionFileByFileIdAndPageSectionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PageSectionFile` being updated. */
  patch: PageSectionFilePatch;
  fileId: Scalars['UUID'];
  pageSectionId: Scalars['UUID'];
};

/** All input for the `updatePageSectionFileByNodeId` mutation. */
export type UpdatePageSectionFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PageSectionFile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PageSectionFile` being updated. */
  patch: PageSectionFilePatch;
};

/** All input for the `updatePageSectionFile` mutation. */
export type UpdatePageSectionFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PageSectionFile` being updated. */
  patch: PageSectionFilePatch;
  id: Scalars['UUID'];
};

/** The output of our update `PageSectionFile` mutation. */
export type UpdatePageSectionFilePayload = {
  __typename?: 'UpdatePageSectionFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSectionFile` that was updated by this mutation. */
  pageSectionFile?: Maybe<PageSectionFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PageSection` that is related to this `PageSectionFile`. */
  pageSection?: Maybe<PageSection>;
  /** Reads a single `File` that is related to this `PageSectionFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSectionFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `PageSectionFile`. May be used by Relay 1. */
  pageSectionFileEdge?: Maybe<PageSectionFilesEdge>;
};


/** The output of our update `PageSectionFile` mutation. */
export type UpdatePageSectionFilePayloadPageSectionFileEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionFilesOrderBy>>;
};

/** All input for the `updatePageSection` mutation. */
export type UpdatePageSectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PageSection` being updated. */
  patch: PageSectionPatch;
  id: Scalars['UUID'];
};

/** The output of our update `PageSection` mutation. */
export type UpdatePageSectionPayload = {
  __typename?: 'UpdatePageSectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PageSection` that was updated by this mutation. */
  pageSection?: Maybe<PageSection>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `PageSection`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Page` that is related to this `PageSection`. */
  page?: Maybe<Page>;
  /** An edge for our `PageSection`. May be used by Relay 1. */
  pageSectionEdge?: Maybe<PageSectionsEdge>;
};


/** The output of our update `PageSection` mutation. */
export type UpdatePageSectionPayloadPageSectionEdgeArgs = {
  orderBy?: Maybe<Array<PageSectionsOrderBy>>;
};

/** All input for the `updatePageSectionSortOrder` mutation. */
export type UpdatePageSectionSortOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pageSectionId: Scalars['UUID'];
  newSortOrder: Scalars['Int'];
};

/** The output of our `updatePageSectionSortOrder` mutation. */
export type UpdatePageSectionSortOrderPayload = {
  __typename?: 'UpdatePageSectionSortOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePersonByNodeId` mutation. */
export type UpdatePersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Person` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch;
};

/** All input for the `updatePersonGroupMembershipForSchool` mutation. */
export type UpdatePersonGroupMembershipForSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
  selectedGroupIds: Array<Maybe<Scalars['UUID']>>;
};

/** The output of our `updatePersonGroupMembershipForSchool` mutation. */
export type UpdatePersonGroupMembershipForSchoolPayload = {
  __typename?: 'UpdatePersonGroupMembershipForSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePerson` mutation. */
export type UpdatePersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Person` being updated. */
  patch: PersonPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Person` mutation. */
export type UpdatePersonPayload = {
  __typename?: 'UpdatePersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Person` that was updated by this mutation. */
  person?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Person`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `Person`. */
  profileImage?: Maybe<File>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
};


/** The output of our update `Person` mutation. */
export type UpdatePersonPayloadPersonEdgeArgs = {
  orderBy?: Maybe<Array<PeopleOrderBy>>;
};

/** All input for the `updatePlayStoreListingWithCreatedOrUpdatedAccount` mutation. */
export type UpdatePlayStoreListingWithCreatedOrUpdatedAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  vGooglePlaysProprietaryDeveloperId: Scalars['String'];
  vAppId: Scalars['UUID'];
  vApiKeyUrl?: Maybe<Scalars['String']>;
};

/** The output of our `updatePlayStoreListingWithCreatedOrUpdatedAccount` mutation. */
export type UpdatePlayStoreListingWithCreatedOrUpdatedAccountPayload = {
  __typename?: 'UpdatePlayStoreListingWithCreatedOrUpdatedAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateReleaseByNodeId` mutation. */
export type UpdateReleaseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Release` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Release` being updated. */
  patch: ReleasePatch;
};

/** All input for the `updateRelease` mutation. */
export type UpdateReleaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Release` being updated. */
  patch: ReleasePatch;
  /** The primary key for `release`. */
  id: Scalars['UUID'];
};

/** The output of our update `Release` mutation. */
export type UpdateReleasePayload = {
  __typename?: 'UpdateReleasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Release` that was updated by this mutation. */
  release?: Maybe<Release>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Release`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Release`. May be used by Relay 1. */
  releaseEdge?: Maybe<ReleasesEdge>;
};


/** The output of our update `Release` mutation. */
export type UpdateReleasePayloadReleaseEdgeArgs = {
  orderBy?: Maybe<Array<ReleasesOrderBy>>;
};

/** All input for the `updateResourceByNodeId` mutation. */
export type UpdateResourceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Resource` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
};

/** All input for the `updateResourceBySlug` mutation. */
export type UpdateResourceBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateResourceFileByFileIdAndResourceId` mutation. */
export type UpdateResourceFileByFileIdAndResourceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ResourceFile` being updated. */
  patch: ResourceFilePatch;
  fileId: Scalars['UUID'];
  resourceId: Scalars['UUID'];
};

/** All input for the `updateResourceFileByNodeId` mutation. */
export type UpdateResourceFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ResourceFile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ResourceFile` being updated. */
  patch: ResourceFilePatch;
};

/** All input for the `updateResourceFile` mutation. */
export type UpdateResourceFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ResourceFile` being updated. */
  patch: ResourceFilePatch;
  id: Scalars['UUID'];
};

/** The output of our update `ResourceFile` mutation. */
export type UpdateResourceFilePayload = {
  __typename?: 'UpdateResourceFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ResourceFile` that was updated by this mutation. */
  resourceFile?: Maybe<ResourceFile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Resource` that is related to this `ResourceFile`. */
  resource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `ResourceFile`. */
  file?: Maybe<File>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `ResourceFile`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `ResourceFile`. May be used by Relay 1. */
  resourceFileEdge?: Maybe<ResourceFilesEdge>;
};


/** The output of our update `ResourceFile` mutation. */
export type UpdateResourceFilePayloadResourceFileEdgeArgs = {
  orderBy?: Maybe<Array<ResourceFilesOrderBy>>;
};

/** All input for the `updateResource` mutation. */
export type UpdateResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
  id: Scalars['UUID'];
};

/** The output of our update `Resource` mutation. */
export type UpdateResourcePayload = {
  __typename?: 'UpdateResourcePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Resource` that was updated by this mutation. */
  resource?: Maybe<Resource>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Resource`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Group` that is related to this `Resource`. */
  group?: Maybe<Group>;
  /** Reads a single `Resource` that is related to this `Resource`. */
  resourceByParentResource?: Maybe<Resource>;
  /** Reads a single `File` that is related to this `Resource`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  tileImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultCoverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  defaultThumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Resource`. */
  thumbnailImage?: Maybe<File>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};


/** The output of our update `Resource` mutation. */
export type UpdateResourcePayloadResourceEdgeArgs = {
  orderBy?: Maybe<Array<ResourcesOrderBy>>;
};

/** All input for the `updateResourceSortOrder` mutation. */
export type UpdateResourceSortOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  resourceId: Scalars['UUID'];
  newSortOrder: Scalars['Int'];
};

/** The output of our `updateResourceSortOrder` mutation. */
export type UpdateResourceSortOrderPayload = {
  __typename?: 'UpdateResourceSortOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateSchoolByNodeId` mutation. */
export type UpdateSchoolByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `School` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `School` being updated. */
  patch: SchoolPatch;
};

/** All input for the `updateSchoolBySlug` mutation. */
export type UpdateSchoolBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `School` being updated. */
  patch: SchoolPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateSchool` mutation. */
export type UpdateSchoolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `School` being updated. */
  patch: SchoolPatch;
  id: Scalars['UUID'];
};

/** The output of our update `School` mutation. */
export type UpdateSchoolPayload = {
  __typename?: 'UpdateSchoolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `School` that was updated by this mutation. */
  school?: Maybe<School>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `School`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `School`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `Theme` that is related to this `School`. */
  theme?: Maybe<Theme>;
  /** An edge for our `School`. May be used by Relay 1. */
  schoolEdge?: Maybe<SchoolsEdge>;
};


/** The output of our update `School` mutation. */
export type UpdateSchoolPayloadSchoolEdgeArgs = {
  orderBy?: Maybe<Array<SchoolsOrderBy>>;
};

/** All input for the `updateSchoolPersonByNodeId` mutation. */
export type UpdateSchoolPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPerson` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SchoolPerson` being updated. */
  patch: SchoolPersonPatch;
};

/** All input for the `updateSchoolPersonByPersonIdAndSchoolId` mutation. */
export type UpdateSchoolPersonByPersonIdAndSchoolIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolPerson` being updated. */
  patch: SchoolPersonPatch;
  personId: Scalars['UUID'];
  schoolId: Scalars['UUID'];
};

/** All input for the `updateSchoolPerson` mutation. */
export type UpdateSchoolPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolPerson` being updated. */
  patch: SchoolPersonPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateSchoolPersonInstallationByNodeId` mutation. */
export type UpdateSchoolPersonInstallationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPersonInstallation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SchoolPersonInstallation` being updated. */
  patch: SchoolPersonInstallationPatch;
};

/** All input for the `updateSchoolPersonInstallation` mutation. */
export type UpdateSchoolPersonInstallationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolPersonInstallation` being updated. */
  patch: SchoolPersonInstallationPatch;
  id: Scalars['UUID'];
};

/** The output of our update `SchoolPersonInstallation` mutation. */
export type UpdateSchoolPersonInstallationPayload = {
  __typename?: 'UpdateSchoolPersonInstallationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInstallation` that was updated by this mutation. */
  schoolPersonInstallation?: Maybe<SchoolPersonInstallation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInstallation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInstallation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInstallation`. May be used by Relay 1. */
  schoolPersonInstallationEdge?: Maybe<SchoolPersonInstallationsEdge>;
};


/** The output of our update `SchoolPersonInstallation` mutation. */
export type UpdateSchoolPersonInstallationPayloadSchoolPersonInstallationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInstallationsOrderBy>>;
};

/** All input for the `updateSchoolPersonInvitationByNodeId` mutation. */
export type UpdateSchoolPersonInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolPersonInvitation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SchoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
};

/** All input for the `updateSchoolPersonInvitationBySlug` mutation. */
export type UpdateSchoolPersonInvitationBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateSchoolPersonInvitation` mutation. */
export type UpdateSchoolPersonInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolPersonInvitation` being updated. */
  patch: SchoolPersonInvitationPatch;
  schoolPersonId: Scalars['UUID'];
};

/** The output of our update `SchoolPersonInvitation` mutation. */
export type UpdateSchoolPersonInvitationPayload = {
  __typename?: 'UpdateSchoolPersonInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPersonInvitation` that was updated by this mutation. */
  schoolPersonInvitation?: Maybe<SchoolPersonInvitation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `SchoolPerson` that is related to this `SchoolPersonInvitation`. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPersonInvitation`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPersonInvitation`. May be used by Relay 1. */
  schoolPersonInvitationEdge?: Maybe<SchoolPersonInvitationsEdge>;
};


/** The output of our update `SchoolPersonInvitation` mutation. */
export type UpdateSchoolPersonInvitationPayloadSchoolPersonInvitationEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPersonInvitationsOrderBy>>;
};

/** The output of our update `SchoolPerson` mutation. */
export type UpdateSchoolPersonPayload = {
  __typename?: 'UpdateSchoolPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolPerson` that was updated by this mutation. */
  schoolPerson?: Maybe<SchoolPerson>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolPerson`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  person?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolPerson`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `SchoolPerson`. May be used by Relay 1. */
  schoolPersonEdge?: Maybe<SchoolPeopleEdge>;
};


/** The output of our update `SchoolPerson` mutation. */
export type UpdateSchoolPersonPayloadSchoolPersonEdgeArgs = {
  orderBy?: Maybe<Array<SchoolPeopleOrderBy>>;
};

/** All input for the `updateSchoolSettingByNodeId` mutation. */
export type UpdateSchoolSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SchoolSetting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SchoolSetting` being updated. */
  patch: SchoolSettingPatch;
};

/** All input for the `updateSchoolSetting` mutation. */
export type UpdateSchoolSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SchoolSetting` being updated. */
  patch: SchoolSettingPatch;
  schoolId: Scalars['UUID'];
};

/** The output of our update `SchoolSetting` mutation. */
export type UpdateSchoolSettingPayload = {
  __typename?: 'UpdateSchoolSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SchoolSetting` that was updated by this mutation. */
  schoolSetting?: Maybe<SchoolSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `School` that is related to this `SchoolSetting`. */
  school?: Maybe<School>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `SchoolSetting`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  schoolImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  mascotImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `SchoolSetting`. */
  launchScreenPoweredByImage?: Maybe<File>;
  /** An edge for our `SchoolSetting`. May be used by Relay 1. */
  schoolSettingEdge?: Maybe<SchoolSettingsEdge>;
};


/** The output of our update `SchoolSetting` mutation. */
export type UpdateSchoolSettingPayloadSchoolSettingEdgeArgs = {
  orderBy?: Maybe<Array<SchoolSettingsOrderBy>>;
};

/** All input for the `updateSponsorByNodeId` mutation. */
export type UpdateSponsorByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sponsor` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Sponsor` being updated. */
  patch: SponsorPatch;
};

/** All input for the `updateSponsorBySlug` mutation. */
export type UpdateSponsorBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Sponsor` being updated. */
  patch: SponsorPatch;
  /**  Unique short ID that can be used in public URLs. */
  slug: Scalars['String'];
};

/** All input for the `updateSponsor` mutation. */
export type UpdateSponsorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Sponsor` being updated. */
  patch: SponsorPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Sponsor` mutation. */
export type UpdateSponsorPayload = {
  __typename?: 'UpdateSponsorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Sponsor` that was updated by this mutation. */
  sponsor?: Maybe<Sponsor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Sponsor`. */
  personByUpdatedBy?: Maybe<Person>;
  /** Reads a single `School` that is related to this `Sponsor`. */
  school?: Maybe<School>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  coverImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  thumbnailImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  logoImage?: Maybe<File>;
  /** Reads a single `File` that is related to this `Sponsor`. */
  promoImage?: Maybe<File>;
  /** An edge for our `Sponsor`. May be used by Relay 1. */
  sponsorEdge?: Maybe<SponsorsEdge>;
};


/** The output of our update `Sponsor` mutation. */
export type UpdateSponsorPayloadSponsorEdgeArgs = {
  orderBy?: Maybe<Array<SponsorsOrderBy>>;
};

/** All input for the `updateSponsorSortOrder` mutation. */
export type UpdateSponsorSortOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sponsorId: Scalars['UUID'];
  newSortOrder: Scalars['Int'];
};

/** The output of our `updateSponsorSortOrder` mutation. */
export type UpdateSponsorSortOrderPayload = {
  __typename?: 'UpdateSponsorSortOrderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateThemeByNodeId` mutation. */
export type UpdateThemeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Theme` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Theme` being updated. */
  patch: ThemePatch;
};

/** All input for the `updateTheme` mutation. */
export type UpdateThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Theme` being updated. */
  patch: ThemePatch;
  id: Scalars['UUID'];
};

/** The output of our update `Theme` mutation. */
export type UpdateThemePayload = {
  __typename?: 'UpdateThemePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Theme` that was updated by this mutation. */
  theme?: Maybe<Theme>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `Theme`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge?: Maybe<ThemesEdge>;
};


/** The output of our update `Theme` mutation. */
export type UpdateThemePayloadThemeEdgeArgs = {
  orderBy?: Maybe<Array<ThemesOrderBy>>;
};

/** All input for the `updateUserGoogleAccountByNodeId` mutation. */
export type UpdateUserGoogleAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserGoogleAccount` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
};

/** All input for the `updateUserGoogleAccountByUserIdAndGoogleAccountId` mutation. */
export type UpdateUserGoogleAccountByUserIdAndGoogleAccountIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** All input for the `updateUserGoogleAccount` mutation. */
export type UpdateUserGoogleAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserGoogleAccount` being updated. */
  patch: UserGoogleAccountPatch;
  id: Scalars['UUID'];
};

/** The output of our update `UserGoogleAccount` mutation. */
export type UpdateUserGoogleAccountPayload = {
  __typename?: 'UpdateUserGoogleAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserGoogleAccount` that was updated by this mutation. */
  userGoogleAccount?: Maybe<UserGoogleAccount>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `GoogleAccount` that is related to this `UserGoogleAccount`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
  /** An edge for our `UserGoogleAccount`. May be used by Relay 1. */
  userGoogleAccountEdge?: Maybe<UserGoogleAccountsEdge>;
};


/** The output of our update `UserGoogleAccount` mutation. */
export type UpdateUserGoogleAccountPayloadUserGoogleAccountEdgeArgs = {
  orderBy?: Maybe<Array<UserGoogleAccountsOrderBy>>;
};

/** All input for the `updateUserSessionByNodeId` mutation. */
export type UpdateUserSessionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserSession` being updated. */
  patch: UserSessionPatch;
};

/** All input for the `updateUserSession` mutation. */
export type UpdateUserSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserSession` being updated. */
  patch: UserSessionPatch;
  /** A foreign key reference to the user's `user_id` in their `user_account` record. */
  userId: Scalars['UUID'];
};

/** The output of our update `UserSession` mutation. */
export type UpdateUserSessionPayload = {
  __typename?: 'UpdateUserSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSession` that was updated by this mutation. */
  userSession?: Maybe<UserSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserSession`. May be used by Relay 1. */
  userSessionEdge?: Maybe<UserSessionsEdge>;
};


/** The output of our update `UserSession` mutation. */
export type UpdateUserSessionPayloadUserSessionEdgeArgs = {
  orderBy?: Maybe<Array<UserSessionsOrderBy>>;
};

/** Join object to connect a `User` to a `GoogleAccount`. */
export type UserGoogleAccount = Node & {
  __typename?: 'UserGoogleAccount';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** Reads a single `GoogleAccount` that is related to this `UserGoogleAccount`. */
  googleAccount?: Maybe<GoogleAccount>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByCreatedBy?: Maybe<Person>;
  /** Reads a single `Person` that is related to this `UserGoogleAccount`. */
  personByUpdatedBy?: Maybe<Person>;
};

/**
 * A condition to be used against `UserGoogleAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserGoogleAccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `googleAccountId` field. */
  googleAccountId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `userGoogleAccount` in the `PersonInput` mutation. */
export type UserGoogleAccountCreatedByFkeyInverseInput = {
  /** Flag indicating whether all other `userGoogleAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<UserGoogleAccountNodeIdConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<UserGoogleAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyUsingUserGoogleAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyNodeIdUpdate>>;
  /** A `UserGoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<UserGoogleAccountCreatedByFkeyUserGoogleAccountCreateInput>>;
};

/** The `userGoogleAccount` to be created by this mutation. */
export type UserGoogleAccountCreatedByFkeyUserGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** A filter to be used against `UserGoogleAccount` object types. All fields are combined with a logical ‘and.’ */
export type UserGoogleAccountFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `googleAccountId` field. */
  googleAccountId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserGoogleAccountFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserGoogleAccountFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserGoogleAccountFilter>;
};

/** The `googleAccount` to be created by this mutation. */
export type UserGoogleAccountGoogleAccountIdFkeyGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  accountId: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  tokenExpiry: Scalars['BigInt'];
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** Input for the nested mutation of `googleAccount` in the `UserGoogleAccountInput` mutation. */
export type UserGoogleAccountGoogleAccountIdFkeyInput = {
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectById?: Maybe<GoogleAccountGoogleAccountPkeyConnect>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<GoogleAccountNodeIdConnect>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteById?: Maybe<GoogleAccountGoogleAccountPkeyDelete>;
  /** The primary key(s) for `googleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<GoogleAccountNodeIdDelete>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateById?: Maybe<GoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingGoogleAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `googleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyNodeIdUpdate>;
  /** A `GoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<UserGoogleAccountGoogleAccountIdFkeyGoogleAccountCreateInput>;
};

/** Input for the nested mutation of `userGoogleAccount` in the `GoogleAccountInput` mutation. */
export type UserGoogleAccountGoogleAccountIdFkeyInverseInput = {
  /** Flag indicating whether all other `userGoogleAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<UserGoogleAccountNodeIdConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<UserGoogleAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingUserGoogleAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyNodeIdUpdate>>;
  /** A `UserGoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<UserGoogleAccountGoogleAccountIdFkeyUserGoogleAccountCreateInput>>;
};

/** The `userGoogleAccount` to be created by this mutation. */
export type UserGoogleAccountGoogleAccountIdFkeyUserGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** An input for mutations affecting `UserGoogleAccount` */
export type UserGoogleAccountInput = {
  id?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserGoogleAccountNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userGoogleAccount` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserGoogleAccountNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userGoogleAccount` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyUsingUserGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyPatch;
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleAccount` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleAccount` being updated. */
  patch: GoogleAccountPatch;
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingUserGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyPatch;
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `person` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `person` being updated. */
  patch: PersonPatch;
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingUserGoogleAccountPkeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to update. */
export type UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate = {
  /** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
  patch: UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyPatch;
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** Represents an update to a `UserGoogleAccount`. Fields that are set will be updated. */
export type UserGoogleAccountPatch = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** Input for the nested mutation of `person` in the `UserGoogleAccountInput` mutation. */
export type UserGoogleAccountUpdatedByFkeyInput = {
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectById?: Maybe<PersonPersonPkeyConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  connectByNodeId?: Maybe<PersonNodeIdConnect>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteById?: Maybe<PersonPersonPkeyDelete>;
  /** The primary key(s) for `person` for the far side of the relationship. */
  deleteByNodeId?: Maybe<PersonNodeIdDelete>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateById?: Maybe<PersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingPersonPkeyUpdate>;
  /** The primary key(s) and patch data for `person` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyNodeIdUpdate>;
  /** A `PersonInput` object that will be created and connected to this object. */
  create?: Maybe<UserGoogleAccountUpdatedByFkeyPersonCreateInput>;
};

/** Input for the nested mutation of `userGoogleAccount` in the `PersonInput` mutation. */
export type UserGoogleAccountUpdatedByFkeyInverseInput = {
  /** Flag indicating whether all other `userGoogleAccount` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<UserGoogleAccountNodeIdConnect>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteById?: Maybe<Array<UserGoogleAccountUserGoogleAccountPkeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyDelete>>;
  /** The primary key(s) for `userGoogleAccount` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<UserGoogleAccountNodeIdDelete>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateById?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingUserGoogleAccountPkeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByUserIdAndGoogleAccountId?: Maybe<Array<UserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyUsingUserGoogleAccountUserIdGoogleAccountIdKeyUpdate>>;
  /** The primary key(s) and patch data for `userGoogleAccount` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<PersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyNodeIdUpdate>>;
  /** A `UserGoogleAccountInput` object that will be created and connected to this object. */
  create?: Maybe<Array<UserGoogleAccountUpdatedByFkeyUserGoogleAccountCreateInput>>;
};

/** The `person` to be created by this mutation. */
export type UserGoogleAccountUpdatedByFkeyPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** The `userGoogleAccount` to be created by this mutation. */
export type UserGoogleAccountUpdatedByFkeyUserGoogleAccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** The fields on `userGoogleAccount` to look up the row to connect. */
export type UserGoogleAccountUserGoogleAccountPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to delete. */
export type UserGoogleAccountUserGoogleAccountPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to connect. */
export type UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyConnect = {
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** The fields on `userGoogleAccount` to look up the row to delete. */
export type UserGoogleAccountUserGoogleAccountUserIdGoogleAccountIdKeyDelete = {
  userId: Scalars['UUID'];
  googleAccountId: Scalars['UUID'];
};

/** A connection to a list of `UserGoogleAccount` values. */
export type UserGoogleAccountsConnection = {
  __typename?: 'UserGoogleAccountsConnection';
  /** A list of `UserGoogleAccount` objects. */
  nodes: Array<UserGoogleAccount>;
  /** A list of edges which contains the `UserGoogleAccount` and cursor to aid in pagination. */
  edges: Array<UserGoogleAccountsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserGoogleAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserGoogleAccount` edge in the connection. */
export type UserGoogleAccountsEdge = {
  __typename?: 'UserGoogleAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserGoogleAccount` at the end of the edge. */
  node: UserGoogleAccount;
};

/** Methods to use when ordering `UserGoogleAccount`. */
export enum UserGoogleAccountsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  GoogleAccountIdAsc = 'GOOGLE_ACCOUNT_ID_ASC',
  GoogleAccountIdDesc = 'GOOGLE_ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** `user_session` is a user's session data, including raw JSON data used purely for customizations to view presentation. <br><br> */
export type UserSession = Node & {
  __typename?: 'UserSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A foreign key reference to the user's `user_id` in their `user_account` record. */
  userId: Scalars['UUID'];
  /** When the user's profile was created. */
  createdAt: Scalars['Datetime'];
  /** The last time the user's profile was updated. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** A document store of view related data for this `user`. */
  sessionData: Scalars['JSON'];
  lastOnline?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `UserSession` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserSessionCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sessionData` field. */
  sessionData?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `lastOnline` field. */
  lastOnline?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `UserSession` object types. All fields are combined with a logical ‘and.’ */
export type UserSessionFilter = {
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `sessionData` field. */
  sessionData?: Maybe<JsonFilter>;
  /** Filter by the object’s `lastOnline` field. */
  lastOnline?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserSessionFilter>;
};

/** An input for mutations affecting `UserSession` */
export type UserSessionInput = {
  /** A foreign key reference to the user's `user_id` in their `user_account` record. */
  userId: Scalars['UUID'];
  /** When the user's profile was created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The last time the user's profile was updated. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** A document store of view related data for this `user`. */
  sessionData?: Maybe<Scalars['JSON']>;
  lastOnline?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `UserSession`. Fields that are set will be updated. */
export type UserSessionPatch = {
  /** A foreign key reference to the user's `user_id` in their `user_account` record. */
  userId?: Maybe<Scalars['UUID']>;
  /** When the user's profile was created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The last time the user's profile was updated. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** A document store of view related data for this `user`. */
  sessionData?: Maybe<Scalars['JSON']>;
  lastOnline?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `UserSession` values. */
export type UserSessionsConnection = {
  __typename?: 'UserSessionsConnection';
  /** A list of `UserSession` objects. */
  nodes: Array<UserSession>;
  /** A list of edges which contains the `UserSession` and cursor to aid in pagination. */
  edges: Array<UserSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserSession` edge in the connection. */
export type UserSessionsEdge = {
  __typename?: 'UserSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserSession` at the end of the edge. */
  node: UserSession;
};

/** Methods to use when ordering `UserSession`. */
export enum UserSessionsOrderBy {
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SessionDataAsc = 'SESSION_DATA_ASC',
  SessionDataDesc = 'SESSION_DATA_DESC',
  LastOnlineAsc = 'LAST_ONLINE_ASC',
  LastOnlineDesc = 'LAST_ONLINE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum UserType {
  Parent = 'PARENT',
  Student = 'STUDENT',
  FacultyStaff = 'FACULTY_STAFF',
  Guest = 'GUEST'
}

/** A filter to be used against UserType List fields. All fields are combined with a logical ‘and.’ */
export type UserTypeListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<UserType>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<UserType>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<UserType>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<UserType>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<UserType>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<UserType>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<UserType>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<UserType>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<UserType>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<UserType>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<UserType>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<UserType>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<UserType>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<UserType>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<UserType>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<UserType>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<UserType>;
};

/** An object where the defined keys will be set on the `androidApp` being updated. */
export type UpdateAndroidAppOnAndroidAppForAndroidAppAppIdFkeyPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `androidApp` being updated. */
export type UpdateAndroidAppOnAndroidAppForAndroidAppCreatedByFkeyPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `androidApp` being updated. */
export type UpdateAndroidAppOnAndroidAppForAndroidAppUpdatedByFkeyPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `androidApp` being updated. */
export type UpdateAndroidAppOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `androidApp` being updated. */
export type UpdateAndroidAppOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch = {
  /** A primary ID for the android_app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for relating android app to app in a 1-1 fashion. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the Android App. Typically this is the same as iOS, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  /** This is typically the bundle suffix  */
  packageName?: Maybe<Scalars['String']>;
  app?: Maybe<AndroidAppAppIdFkeyInput>;
  person?: Maybe<AndroidAppUpdatedByFkeyInput>;
  androidJavaKeyStores?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInverseInput>;
  googlePlayListing?: Maybe<GooglePlayListingAndroidAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
export type UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreAndroidAppIdFkeyPatch = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias?: Maybe<Scalars['String']>;
  /** The key password for the keystore. */
  keyPassword?: Maybe<Scalars['String']>;
  /** The store password for the keystore. */
  storePassword?: Maybe<Scalars['String']>;
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
export type UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreCreatedByFkeyPatch = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias?: Maybe<Scalars['String']>;
  /** The key password for the keystore. */
  keyPassword?: Maybe<Scalars['String']>;
  /** The store password for the keystore. */
  storePassword?: Maybe<Scalars['String']>;
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `androidJavaKeyStore` being updated. */
export type UpdateAndroidJavaKeyStoreOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyPatch = {
  /** A primary ID for the jks. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key responsible for connecting the 1-Many relationship of Android Apps to JKSs. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Notes! */
  notes?: Maybe<Scalars['String']>;
  /** The contents of a java keystore written out to base64. */
  jks64?: Maybe<Scalars['String']>;
  /** The alias used for the keystore. */
  keyAlias?: Maybe<Scalars['String']>;
  /** The key password for the keystore. */
  keyPassword?: Maybe<Scalars['String']>;
  /** The store password for the keystore. */
  storePassword?: Maybe<Scalars['String']>;
  androidApp?: Maybe<AndroidJavaKeyStoreAndroidAppIdFkeyInput>;
  person?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `androidScreenshot` being updated. */
export type UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotCreatedByFkeyPatch = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size?: Maybe<AndroidScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `androidScreenshot` being updated. */
export type UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyPatch = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size?: Maybe<AndroidScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `androidScreenshot` being updated. */
export type UpdateAndroidScreenshotOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyPatch = {
  /** A primary key for the apple_screenshots. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for connecting android_screenshot to an google_play_listing in a one to many relationship. Most google_play_listings won't have screenshots but rather opt to generate the screenshots with each build. */
  googlePlayListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 1 android screenshot size this screenshot is. */
  size?: Maybe<AndroidScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  googlePlayListing?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInput>;
  person?: Maybe<AndroidScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnAndroidAppForAndroidAppAppIdFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnAppForAppCreatedByFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnAppForAppSchoolIdFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnAppForAppUpdatedByFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnBuildForBuildAppIdFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `app` being updated. */
export type UpdateAppOnIosAppForIosAppAppIdFkeyPatch = {
  /** A primary ID for the app. */
  id?: Maybe<Scalars['UUID']>;
  /** A foreign key for a 1-1 relationship on the `school` table. */
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** A tracker of the state of whether an app is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The name displayed under the icon on the mobile device. This value is always the same for both mobile platforms. */
  appTitleUnderIcon?: Maybe<Scalars['String']>;
  /** The app id for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppId?: Maybe<Scalars['String']>;
  /** The auth token for onesignal. This value is always the same for both mobile platforms (this may move to another table outside of this schema). */
  onesignalAppAuthToken?: Maybe<Scalars['String']>;
  /** The icon for the mobile app. This value is always the same for both mobile platforms. */
  iconUrl?: Maybe<Scalars['String']>;
  school?: Maybe<AppSchoolIdFkeyInput>;
  person?: Maybe<AppUpdatedByFkeyInput>;
  builds?: Maybe<BuildAppIdFkeyInverseInput>;
  androidApp?: Maybe<AndroidAppAppIdFkeyInverseInput>;
  iosApp?: Maybe<IosAppAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreAccount` being updated. */
export type UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreAccount` being updated. */
export type UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountCreatedByFkeyPatch = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreAccount` being updated. */
export type UpdateAppStoreAccountOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreAccount` being updated. */
export type UpdateAppStoreAccountOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch = {
  /** The primary key for `app_store_account`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_user` relating 1 user to many accounts. */
  appStoreUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The content_provider_id for the asc team from this endpoint https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail */
  appStoreConnectId?: Maybe<Scalars['String']>;
  /** The developer team id (found on the member page of the dev portal). Also the git repo name for the certificates for all apps on that team. */
  appleDeveloperAccountId?: Maybe<Scalars['String']>;
  /** The name of the developer team (found on the member page of the dev portal). */
  organizationName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['Datetime']>;
  /** If true, the user is an admin on this team. */
  appStoreUserIsAdmin?: Maybe<Scalars['Boolean']>;
  /** This is set to true when the client has successfully uploaded privacy details for us (because they do not want to give us admin access). */
  clientUploadedPrivacyDetailsOnOurBehalf?: Maybe<Scalars['Boolean']>;
  /** If true, there other apps and developers using this account other than us, so we should never nuke certs but rather manually revoke them. */
  neverNukeCertsAndProfiles?: Maybe<Scalars['Boolean']>;
  /** The date that the distribution certificate for this account will expire (along with all the profiles created using this certificate). */
  distributionCertExpirationDate?: Maybe<Scalars['Datetime']>;
  needsToAcceptAgreements?: Maybe<Scalars['Boolean']>;
  appStoreUser?: Maybe<AppStoreAccountAppStoreUserIdFkeyInput>;
  person?: Maybe<AppStoreAccountUpdatedByFkeyInput>;
  appStoreListings?: Maybe<AppStoreListingAppStoreAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreListing` being updated. */
export type UpdateAppStoreListingOnAppStoreListingForAppStoreListingAppStoreAccountIdFkeyPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreListing` being updated. */
export type UpdateAppStoreListingOnAppStoreListingForAppStoreListingCreatedByFkeyPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreListing` being updated. */
export type UpdateAppStoreListingOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreListing` being updated. */
export type UpdateAppStoreListingOnAppStoreListingForAppStoreListingUpdatedByFkeyPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreListing` being updated. */
export type UpdateAppStoreListingOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyPatch = {
  /** The primary key for `app_store_listing`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `ios_app`. 1-1 relation. */
  iosAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_account`. 1-1 relation. */
  appStoreAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The name of the app on the app store. Cannot be longer than 30 characters. */
  appName?: Maybe<Scalars['String']>;
  /** The description of the app in the app store. */
  description?: Maybe<Scalars['String']>;
  /** The keywords to search for this app with (100 character string where each keyword is separated by a comma without spaces). */
  keywords?: Maybe<Scalars['String']>;
  /** The notes to the reviewer to reduce the likelihood we'll get rejected. Should contain an acknowledgement of the app name and organization name differing, and the passcodes for groups, and any new notes that reduce the amount of rejections. */
  reviewerNotes?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  marketingUrl?: Maybe<Scalars['String']>;
  /** Typically the school's url. */
  supportUrl?: Maybe<Scalars['String']>;
  appStoreProprietaryAppId?: Maybe<Scalars['String']>;
  /** The date that the most recently created push cert expires. */
  pushCertExpirationDate?: Maybe<Scalars['Datetime']>;
  appGroupLinked?: Maybe<Scalars['Boolean']>;
  iosApp?: Maybe<AppStoreListingIosAppIdFkeyInput>;
  appStoreAccount?: Maybe<AppStoreListingAppStoreAccountIdFkeyInput>;
  person?: Maybe<AppStoreListingUpdatedByFkeyInput>;
  iosScreenshots?: Maybe<IosScreenshotAppStoreListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreUser` being updated. */
export type UpdateAppStoreUserOnAppStoreAccountForAppStoreAccountAppStoreUserIdFkeyPatch = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreUser` being updated. */
export type UpdateAppStoreUserOnAppStoreUserForAppStoreUserCreatedByFkeyPatch = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `appStoreUser` being updated. */
export type UpdateAppStoreUserOnAppStoreUserForAppStoreUserUpdatedByFkeyPatch = {
  /** The primary key for `app_store_user`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** Email  for Apple ID linked with the Apple Developer/App Store Connect account. */
  email?: Maybe<Scalars['String']>;
  /** The password for Apple ID linked with the Apple Developer/App Store Connect account (NOT APP SPECIFIC PASSWORD SINCE THIS ISN'T SUPPORTED FOR ALL THINGS. */
  password?: Maybe<Scalars['String']>;
  appSpecificPassword?: Maybe<Scalars['String']>;
  /** Fastlane cookie to store 2fa session. This is emptied when a cookie expires. This will be replaced with API keys once those are required. */
  fastlaneSpaceAuth?: Maybe<Scalars['String']>;
  person?: Maybe<AppStoreUserUpdatedByFkeyInput>;
  appStoreAccounts?: Maybe<AppStoreAccountAppStoreUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `build` being updated. */
export type UpdateBuildOnBuildForBuildAppIdFkeyPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `build` being updated. */
export type UpdateBuildOnBuildForBuildCreatedByFkeyPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `build` being updated. */
export type UpdateBuildOnBuildForBuildReleaseIdFkeyPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `build` being updated. */
export type UpdateBuildOnBuildForBuildUpdatedByFkeyPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `build` being updated. */
export type UpdateBuildOnBuildStepForBuildStepBuildIdFkeyPatch = {
  /** The primary key for `build`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_public.app` where 1 `app` has many `build`. The app and release combo are NOT unique on the table build. */
  appId?: Maybe<Scalars['UUID']>;
  /** The foreign key for `deployment.release` where 1 release has many `build`. The app and release combo are NOT unique on the table build. */
  releaseId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID' The platform for the build. */
  platform?: Maybe<PlatformType>;
  /** A version number in ##.##.## format. Multiple builds can have the same app_version, but once a build has an app_version where build_live is true, you'll need to increment the app_version. */
  appVersion?: Maybe<Scalars['String']>;
  /** The build number used to identify which build to submit. */
  buildNumber?: Maybe<Scalars['String']>;
  /** The notes publicly displayed to those downloading the app. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** This is set to true once the bitrise lane is started. */
  buildStarted?: Maybe<Scalars['Boolean']>;
  /** This is set set to true when the build goes live. */
  buildLive?: Maybe<Scalars['Boolean']>;
  /** This is set to true once the if we stop a build. */
  buildKilled?: Maybe<Scalars['Boolean']>;
  /** This is set to true if the build fails. */
  buildFailed?: Maybe<Scalars['Boolean']>;
  /** The time that this was set to true. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeLive?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  /** The time that this was set to true. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  app?: Maybe<BuildAppIdFkeyInput>;
  release?: Maybe<BuildReleaseIdFkeyInput>;
  person?: Maybe<BuildUpdatedByFkeyInput>;
  buildSteps?: Maybe<BuildStepBuildIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `buildStep` being updated. */
export type UpdateBuildStepOnBuildStepForBuildStepBuildIdFkeyPatch = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `buildStep` being updated. */
export type UpdateBuildStepOnBuildStepForBuildStepCreatedByFkeyPatch = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `buildStep` being updated. */
export type UpdateBuildStepOnBuildStepForBuildStepUpdatedByFkeyPatch = {
  /** The primary key for `build_step`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `build` where one build has many steps. */
  buildId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The platform for this build step. Technically this is denormalized data, but that's the case for release->build too. */
  platform?: Maybe<PlatformType>;
  /** This is incremented at each new step and is for ordering the display of the steps that were taken. */
  stepNumber?: Maybe<Scalars['Int']>;
  /** This is an enum of each step that is tried in a fastlane script. */
  buildStepName?: Maybe<BuildStepName>;
  /** The bitrise slug of the bitrise build this step is being executed on. Multiple build steps can take place on the bitrise build, so this is not unique. */
  bitriseBuildSlug?: Maybe<Scalars['String']>;
  /** Timestamp when this step started. */
  timeStarted?: Maybe<Scalars['Datetime']>;
  /** Whether or not this succeeded */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step finished successfully. */
  timeSucceeded?: Maybe<Scalars['Datetime']>;
  /** Whether or not this failed. */
  failed?: Maybe<Scalars['Boolean']>;
  /** This is the message raised in fastlane on failure. */
  failureMessage?: Maybe<Scalars['String']>;
  /** Timestamp when this step failed. */
  timeFailed?: Maybe<Scalars['Datetime']>;
  /** Whether or not was killed by an LA team member. */
  killed?: Maybe<Scalars['Boolean']>;
  /** Timestamp when this step killed. */
  timeKilled?: Maybe<Scalars['Datetime']>;
  build?: Maybe<BuildStepBuildIdFkeyInput>;
  person?: Maybe<BuildStepUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarEvent` being updated. */
export type UpdateCalendarEventOnCalendarEventForCalendarEventCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarEvent` being updated. */
export type UpdateCalendarEventOnCalendarEventForCalendarEventCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarEvent` being updated. */
export type UpdateCalendarEventOnCalendarEventForCalendarEventEventIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarEvent` being updated. */
export type UpdateCalendarEventOnCalendarEventForCalendarEventUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  calendar?: Maybe<CalendarEventCalendarIdFkeyInput>;
  event?: Maybe<CalendarEventEventIdFkeyInput>;
  person?: Maybe<CalendarEventUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
export type UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
export type UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
export type UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarGroupCalendar` being updated. */
export type UpdateCalendarGroupCalendarOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  calendarGroupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  calendar?: Maybe<CalendarGroupCalendarCalendarIdFkeyInput>;
  calendarGroup?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInput>;
  person?: Maybe<CalendarGroupCalendarUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `calendarGroup` being updated. */
export type UpdateCalendarGroupOnCalendarGroupCalendarForCalendarGroupCalendarCalendarGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendarGroup` being updated. */
export type UpdateCalendarGroupOnCalendarGroupForCalendarGroupCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendarGroup` being updated. */
export type UpdateCalendarGroupOnCalendarGroupForCalendarGroupSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendarGroup` being updated. */
export type UpdateCalendarGroupOnCalendarGroupForCalendarGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['UUID']>;
  person?: Maybe<CalendarGroupUpdatedByFkeyInput>;
  school?: Maybe<CalendarGroupSchoolIdFkeyInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnCalendarEventForCalendarEventCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnCalendarForCalendarCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnCalendarForCalendarGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnCalendarForCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnCalendarGroupCalendarForCalendarGroupCalendarCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `calendar` being updated. */
export type UpdateCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  person?: Maybe<CalendarUpdatedByFkeyInput>;
  group?: Maybe<CalendarGroupIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventCalendarIdFkeyInverseInput>;
  calendarGroupCalendars?: Maybe<CalendarGroupCalendarCalendarIdFkeyInverseInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `division` being updated. */
export type UpdateDivisionOnDivisionForDivisionCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `division` being updated. */
export type UpdateDivisionOnDivisionForDivisionGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `division` being updated. */
export type UpdateDivisionOnDivisionForDivisionSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `division` being updated. */
export type UpdateDivisionOnDivisionForDivisionUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `division` being updated. */
export type UpdateDivisionOnGroupCategoryForGroupCategoryDivisionIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  schoolwide?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['UUID']>;
  person?: Maybe<DivisionUpdatedByFkeyInput>;
  school?: Maybe<DivisionSchoolIdFkeyInput>;
  group?: Maybe<DivisionGroupIdFkeyInput>;
  groupCategories?: Maybe<GroupCategoryDivisionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnCalendarEventForCalendarEventEventIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForEventCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForEventCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForEventThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForEventUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  locationString?: Maybe<Scalars['String']>;
  allDay?: Maybe<Scalars['Boolean']>;
  /** The start datetime for the event. Stored in UTC. */
  startDate?: Maybe<Scalars['Datetime']>;
  /** The end datetime for the event. Stored in UTC. */
  endDate?: Maybe<Scalars['Datetime']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<EventUpdatedByFkeyInput>;
  coverImage?: Maybe<EventCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<EventThumbnailImageFileIdFkeyInput>;
  calendarEvents?: Maybe<CalendarEventEventIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnEventForEventCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnEventForEventThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnMarqueeForMarqueeMarqueeImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnNewsletterForNewsletterCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnNotificationFileForNotificationFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnPageSectionFileForPageSectionFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnPersonForProfileImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceFileForResourceFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceForResourceCoverImageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceForResourceDefaultCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceForResourceDefaultThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceForResourceThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnResourceForResourceTileImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSponsorForSponsorCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSponsorForSponsorLogoImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSponsorForSponsorPromoImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnSponsorForSponsorThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  /** NOTE: the filename is NOT the full file path, rather only the terminal portion (the actual filename of [UUID].[EXTENSION]). */
  filename?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  legacyImgixPath?: Maybe<Scalars['String']>;
  person?: Maybe<FileUpdatedByFkeyInput>;
  eventCoverImages?: Maybe<EventCoverImageFileIdFkeyInverseInput>;
  eventThumbnailImages?: Maybe<EventThumbnailImageFileIdFkeyInverseInput>;
  personProfileImages?: Maybe<ProfileImageFileIdFkeyInverseInput>;
  newsletterCoverImages?: Maybe<NewsletterCoverImageFileIdFkeyInverseInput>;
  resourceCoverImages?: Maybe<ResourceCoverImageIdFkeyInverseInput>;
  resourceTileImages?: Maybe<ResourceTileImageFileIdFkeyInverseInput>;
  resourceDefaultCoverImages?: Maybe<ResourceDefaultCoverImageFileIdFkeyInverseInput>;
  resourceDefaultThumbnailImages?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInverseInput>;
  resourceThumbnailImages?: Maybe<ResourceThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsCoverImages?: Maybe<SchoolSettingsCoverImageFileIdFkeyInverseInput>;
  schoolSettingsThumbnailImages?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInverseInput>;
  schoolSettingsLogoImages?: Maybe<SchoolSettingsLogoImageFileIdFkeyInverseInput>;
  schoolSettingsSchoolImages?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInverseInput>;
  schoolSettingsMascotImages?: Maybe<SchoolSettingsMascotImageFileIdFkeyInverseInput>;
  schoolSettingsLaunchScreenPoweredByImages?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInverseInput>;
  marqueeImages?: Maybe<MarqueeMarqueeImageFileIdFkeyInverseInput>;
  notificationFiles?: Maybe<NotificationFileFileIdFkeyInverseInput>;
  pageSectionFiles?: Maybe<PageSectionFileFileIdFkeyInverseInput>;
  resourceFiles?: Maybe<ResourceFileFileIdFkeyInverseInput>;
  sponsorCoverImages?: Maybe<SponsorCoverImageFileIdFkeyInverseInput>;
  sponsorThumbnailImages?: Maybe<SponsorThumbnailImageFileIdFkeyInverseInput>;
  sponsorLogoImages?: Maybe<SponsorLogoImageFileIdFkeyInverseInput>;
  sponsorPromoImages?: Maybe<SponsorPromoImageFileIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleAccount` being updated. */
export type UpdateGoogleAccountOnGoogleAccountForGoogleAccountCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleAccount` being updated. */
export type UpdateGoogleAccountOnGoogleAccountForGoogleAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleAccount` being updated. */
export type UpdateGoogleAccountOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleAccount` being updated. */
export type UpdateGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  tokenExpiry?: Maybe<Scalars['BigInt']>;
  errorState?: Maybe<Scalars['String']>;
  person?: Maybe<GoogleAccountUpdatedByFkeyInput>;
  googleCalendarSyncs?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInverseInput>;
  userGoogleAccounts?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
export type UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCalendarIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
export type UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
export type UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSyncCalendar` being updated. */
export type UpdateGoogleCalendarSyncCalendarOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleCalendarSyncId?: Maybe<Scalars['UUID']>;
  calendarId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInput>;
  googleCalendarSync?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInput>;
  calendar?: Maybe<GoogleCalendarSyncCalendarCalendarIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
export type UpdateGoogleCalendarSyncOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
export type UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
export type UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncGoogleAccountIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googleCalendarSync` being updated. */
export type UpdateGoogleCalendarSyncOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  googleCalendarColor?: Maybe<Scalars['HexColor']>;
  lastSyncedAt?: Maybe<Scalars['Datetime']>;
  syncedUpTo?: Maybe<Scalars['Datetime']>;
  status?: Maybe<GoogleCalendarSyncStatus>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  person?: Maybe<GoogleCalendarSyncUpdatedByFkeyInput>;
  googleAccount?: Maybe<GoogleCalendarSyncGoogleAccountIdFkeyInput>;
  googleCalendarSyncCalendars?: Maybe<GoogleCalendarSyncCalendarGoogleCalendarSyncIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
export type UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
export type UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
export type UpdateGooglePlayAccountOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayAccount` being updated. */
export type UpdateGooglePlayAccountOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key relating a google play account to the google play user for logging in. This is a premature development of functionality since we only use one account to login currently. */
  googlePlayUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** This is the id that google uses to refer to the account. It will be used for building urls to this accounts google play console page. */
  googlePlaysProprietaryDeveloperId?: Maybe<Scalars['String']>;
  /** This is the name the client chose for their google play account. */
  googlePlayDeveloperName?: Maybe<Scalars['String']>;
  /** TEMPORARILY UNUSED. This is the raw json that is the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyJson?: Maybe<Scalars['JSON']>;
  /** TEMPORARILY USED. This url to the api key that gives fastlane access to uploading compiled apps to the google play store. */
  apiKeyUrl?: Maybe<Scalars['String']>;
  googlePlayUser?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInput>;
  person?: Maybe<GooglePlayAccountUpdatedByFkeyInput>;
  googlePlayListings?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayListing` being updated. */
export type UpdateGooglePlayListingOnAndroidScreenshotForAndroidScreenshotGooglePlayListingIdFkeyPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayListing` being updated. */
export type UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingAndroidAppIdFkeyPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayListing` being updated. */
export type UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingCreatedByFkeyPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayListing` being updated. */
export type UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingGooglePlayAccountIdFkeyPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayListing` being updated. */
export type UpdateGooglePlayListingOnGooglePlayListingForGooglePlayListingUpdatedByFkeyPatch = {
  /** A primary ID for the android_listing. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-1 relationship between listings and apps. */
  androidAppId?: Maybe<Scalars['UUID']>;
  /** The foreign key establishing the 1-many relationship between accounts and listings. */
  googlePlayAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The id that google uses to refer to the app in google play console */
  googlePlaysProprietaryAppId?: Maybe<Scalars['String']>;
  /** The name to be displayed on the google play listing. Can be up to 50 characters, but instead we mirror Apple's 30 character limit. */
  appName?: Maybe<Scalars['String']>;
  /** The short description for a google play listing. It has the max length of 80 characters. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The full description for a google play listing. This description typically mirrors Apple's. */
  fullDescription?: Maybe<Scalars['String']>;
  androidApp?: Maybe<GooglePlayListingAndroidAppIdFkeyInput>;
  googlePlayAccount?: Maybe<GooglePlayListingGooglePlayAccountIdFkeyInput>;
  person?: Maybe<GooglePlayListingUpdatedByFkeyInput>;
  androidScreenshots?: Maybe<AndroidScreenshotGooglePlayListingIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayUser` being updated. */
export type UpdateGooglePlayUserOnGooglePlayAccountForGooglePlayAccountGooglePlayUserIdFkeyPatch = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayUser` being updated. */
export type UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserCreatedByFkeyPatch = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `googlePlayUser` being updated. */
export type UpdateGooglePlayUserOnGooglePlayUserForGooglePlayUserUpdatedByFkeyPatch = {
  /** The primary key for the google play user. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The email used to login */
  email?: Maybe<Scalars['String']>;
  /** Raw text of a users' password. :o */
  password?: Maybe<Scalars['String']>;
  person?: Maybe<GooglePlayUserUpdatedByFkeyInput>;
  googlePlayAccounts?: Maybe<GooglePlayAccountGooglePlayUserIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupCategory` being updated. */
export type UpdateGroupCategoryOnGroupCategoryForGroupCategoryCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupCategory` being updated. */
export type UpdateGroupCategoryOnGroupCategoryForGroupCategoryDivisionIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupCategory` being updated. */
export type UpdateGroupCategoryOnGroupCategoryForGroupCategoryUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupCategory` being updated. */
export type UpdateGroupCategoryOnGroupForGroupGroupCategoryIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  divisionId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  person?: Maybe<GroupCategoryUpdatedByFkeyInput>;
  division?: Maybe<GroupCategoryDivisionIdFkeyInput>;
  groups?: Maybe<GroupGroupCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupNewsletter` being updated. */
export type UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupNewsletter` being updated. */
export type UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupNewsletter` being updated. */
export type UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupNewsletter` being updated. */
export type UpdateGroupNewsletterOnGroupNewsletterForGroupNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  group?: Maybe<GroupNewsletterGroupIdFkeyInput>;
  newsletter?: Maybe<GroupNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<GroupNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCalendarForCalendarGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnDivisionForDivisionGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnGroupForGroupCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnGroupForGroupGroupCategoryIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnGroupForGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnGroupNewsletterForGroupNewsletterGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnGroupPersonForGroupPersonGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnResourceForResourceGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  groupCategoryId?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  passcode?: Maybe<Scalars['String']>;
  passcodeHint?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  autoEnrollUserTypes?: Maybe<Array<Maybe<UserType>>>;
  person?: Maybe<GroupUpdatedByFkeyInput>;
  groupCategory?: Maybe<GroupGroupCategoryIdFkeyInput>;
  calendars?: Maybe<CalendarGroupIdFkeyInverseInput>;
  resources?: Maybe<ResourceGroupIdFkeyInverseInput>;
  groupPeople?: Maybe<GroupPersonGroupIdFkeyInverseInput>;
  divisions?: Maybe<DivisionGroupIdFkeyInverseInput>;
  groupNewsletters?: Maybe<GroupNewsletterGroupIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupGroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `groupPerson` being updated. */
export type UpdateGroupPersonOnGroupPersonForGroupPersonCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupPerson` being updated. */
export type UpdateGroupPersonOnGroupPersonForGroupPersonGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupPerson` being updated. */
export type UpdateGroupPersonOnGroupPersonForGroupPersonPersonIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `groupPerson` being updated. */
export type UpdateGroupPersonOnGroupPersonForGroupPersonUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  member?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  group?: Maybe<GroupPersonGroupIdFkeyInput>;
  person?: Maybe<GroupPersonUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `iosApp` being updated. */
export type UpdateIosAppOnAppStoreListingForAppStoreListingIosAppIdFkeyPatch = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `iosApp` being updated. */
export type UpdateIosAppOnIosAppForIosAppAppIdFkeyPatch = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `iosApp` being updated. */
export type UpdateIosAppOnIosAppForIosAppCreatedByFkeyPatch = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `iosApp` being updated. */
export type UpdateIosAppOnIosAppForIosAppUpdatedByFkeyPatch = {
  /** The primary key for `ios_app`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app` relating ios_app to app in a 1-1 relationship. */
  appId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** The bundle id for the iOS App. Typically this is the same as Android, but we have to permit it to differ. */
  bundleId?: Maybe<Scalars['String']>;
  app?: Maybe<IosAppAppIdFkeyInput>;
  person?: Maybe<IosAppUpdatedByFkeyInput>;
  appStoreListing?: Maybe<AppStoreListingIosAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `iosScreenshot` being updated. */
export type UpdateIosScreenshotOnIosScreenshotForIosScreenshotAppStoreListingIdFkeyPatch = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size?: Maybe<IosScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `iosScreenshot` being updated. */
export type UpdateIosScreenshotOnIosScreenshotForIosScreenshotCreatedByFkeyPatch = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size?: Maybe<IosScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `iosScreenshot` being updated. */
export type UpdateIosScreenshotOnIosScreenshotForIosScreenshotUpdatedByFkeyPatch = {
  /** The primary key for `ios_screenshot`. */
  id?: Maybe<Scalars['UUID']>;
  /** The foreign key for `app_store_listing`. 1 listing may have many screenshots. */
  appStoreListingId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** An enum specifying which of the 4 ios screenshot sizes this screenshot is. */
  size?: Maybe<IosScreenshotSize>;
  /** The url at which the screenshot is hosted. */
  screenshotUrl?: Maybe<Scalars['String']>;
  /** The integer specifying in what order the screenshot should appear. */
  screenshotOrder?: Maybe<Scalars['Int']>;
  appStoreListing?: Maybe<IosScreenshotAppStoreListingIdFkeyInput>;
  person?: Maybe<IosScreenshotUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `marquee` being updated. */
export type UpdateMarqueeOnMarqueeForMarqueeCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `marquee` being updated. */
export type UpdateMarqueeOnMarqueeForMarqueeMarqueeImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `marquee` being updated. */
export type UpdateMarqueeOnMarqueeForMarqueeSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `marquee` being updated. */
export type UpdateMarqueeOnMarqueeForMarqueeUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  marqueeImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  countdownLabel?: Maybe<Scalars['String']>;
  countdownDate?: Maybe<Scalars['Datetime']>;
  countdownHideWhenCompleted?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<MarqueeUpdatedByFkeyInput>;
  school?: Maybe<MarqueeSchoolIdFkeyInput>;
  marqueeImage?: Maybe<MarqueeMarqueeImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnGroupNewsletterForGroupNewsletterNewsletterIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnNewsletterForNewsletterCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnNewsletterForNewsletterCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnNewsletterForNewsletterPageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnNewsletterForNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `newsletter` being updated. */
export type UpdateNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  status?: Maybe<NewsletterStatus>;
  publishDate?: Maybe<Scalars['Datetime']>;
  title?: Maybe<Scalars['String']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  person?: Maybe<NewsletterUpdatedByFkeyInput>;
  page?: Maybe<NewsletterPageIdFkeyInput>;
  coverImage?: Maybe<NewsletterCoverImageFileIdFkeyInput>;
  groupNewsletters?: Maybe<GroupNewsletterNewsletterIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNewsletterIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notificationFile` being updated. */
export type UpdateNotificationFileOnNotificationFileForNotificationFileCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationFile` being updated. */
export type UpdateNotificationFileOnNotificationFileForNotificationFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationFile` being updated. */
export type UpdateNotificationFileOnNotificationFileForNotificationFileNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationFile` being updated. */
export type UpdateNotificationFileOnNotificationFileForNotificationFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  notification?: Maybe<NotificationFileNotificationIdFkeyInput>;
  file?: Maybe<NotificationFileFileIdFkeyInput>;
  person?: Maybe<NotificationFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationGroup` being updated. */
export type UpdateNotificationGroupOnNotificationGroupForNotificationGroupCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationGroup` being updated. */
export type UpdateNotificationGroupOnNotificationGroupForNotificationGroupGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationGroup` being updated. */
export type UpdateNotificationGroupOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationGroup` being updated. */
export type UpdateNotificationGroupOnNotificationGroupForNotificationGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  notification?: Maybe<NotificationGroupNotificationIdFkeyInput>;
  group?: Maybe<NotificationGroupGroupIdFkeyInput>;
  person?: Maybe<NotificationGroupUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
export type UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
export type UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNewsletterIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
export type UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notificationNewsletter` being updated. */
export type UpdateNotificationNewsletterOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  newsletterId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  notification?: Maybe<NotificationNewsletterNotificationIdFkeyInput>;
  newsletter?: Maybe<NotificationNewsletterNewsletterIdFkeyInput>;
  person?: Maybe<NotificationNewsletterUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationFileForNotificationFileNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationForNotificationCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationForNotificationRecurringParentFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationForNotificationUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationGroupForNotificationGroupNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationNewsletterForNotificationNewsletterNotificationIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  draft?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  delivery?: Maybe<NotificationDelivery>;
  /** Use in two ways: if `delivery` is `later` then `send_date` is the datetime the message will be sent. If `delivery` is `recurring` then `send_date` marks the "start date" for the occurrences (e.g. a recurring message set for every Monday will only start sending every Monday *after* the `send_date` has passed). */
  sendDate?: Maybe<Scalars['Datetime']>;
  recurringCron?: Maybe<Scalars['String']>;
  recurringEnabled?: Maybe<Scalars['Boolean']>;
  nextOccurrence?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  recipientCount?: Maybe<Scalars['Int']>;
  logs?: Maybe<Scalars['JSON']>;
  /** This is a critical column. By storing the next_occurrence_send_job on the Notification, we can ensure that we do not ever accidentally schedule duplicate send jobs for the next occurrence of a Notification (either Later or Recurring). A Notification can only ever have one 'next' occurrence, thus only ever one next_occurrence_send_job. */
  nextOccurrenceSendJob?: Maybe<Scalars['BigInt']>;
  recurringParent?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<NotificationUpdatedByFkeyInput>;
  notification?: Maybe<NotificationRecurringParentFkeyInput>;
  notificationFiles?: Maybe<NotificationFileNotificationIdFkeyInverseInput>;
  notificationNewsletters?: Maybe<NotificationNewsletterNotificationIdFkeyInverseInput>;
  notificationGroups?: Maybe<NotificationGroupNotificationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `page` being updated. */
export type UpdatePageOnNewsletterForNewsletterPageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `page` being updated. */
export type UpdatePageOnPageForPageCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `page` being updated. */
export type UpdatePageOnPageForPageUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `page` being updated. */
export type UpdatePageOnPageSectionForPageSectionPageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  person?: Maybe<PageUpdatedByFkeyInput>;
  newsletters?: Maybe<NewsletterPageIdFkeyInverseInput>;
  pageSections?: Maybe<PageSectionPageIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `pageSectionFile` being updated. */
export type UpdatePageSectionFileOnPageSectionFileForPageSectionFileCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `pageSectionFile` being updated. */
export type UpdatePageSectionFileOnPageSectionFileForPageSectionFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `pageSectionFile` being updated. */
export type UpdatePageSectionFileOnPageSectionFileForPageSectionFilePageSectionIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `pageSectionFile` being updated. */
export type UpdatePageSectionFileOnPageSectionFileForPageSectionFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  pageSectionId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  cover?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileRelationType?: Maybe<FileRelationType>;
  pageSection?: Maybe<PageSectionFilePageSectionIdFkeyInput>;
  file?: Maybe<PageSectionFileFileIdFkeyInput>;
  person?: Maybe<PageSectionFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `pageSection` being updated. */
export type UpdatePageSectionOnPageSectionFileForPageSectionFilePageSectionIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type?: Maybe<PageSectionType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `pageSection` being updated. */
export type UpdatePageSectionOnPageSectionForPageSectionCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  pageId?: Maybe<Scalars['UUID']>;
  type?: Maybe<PageSectionType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `pageSection` being updated. */
export type UpdatePageSectionOnPageSectionForPageSectionPageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  type?: Maybe<PageSectionType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `pageSection` being updated. */
export type UpdatePageSectionOnPageSectionForPageSectionUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  pageId?: Maybe<Scalars['UUID']>;
  type?: Maybe<PageSectionType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.page_section_before_save trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleAlignment?: Maybe<Alignment>;
  subtitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** Controls whether the image shows to the left of the body text or to the right. */
  bodyImageAlignment?: Maybe<Alignment>;
  buttons?: Maybe<ButtonsInput>;
  /** URL is currently only used for the 'video' PageSectionType. */
  url?: Maybe<Scalars['String']>;
  person?: Maybe<PageSectionUpdatedByFkeyInput>;
  page?: Maybe<PageSectionPageIdFkeyInput>;
  pageSectionFiles?: Maybe<PageSectionFilePageSectionIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAndroidAppForAndroidAppUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAndroidJavaKeyStoreForAndroidJavaKeyStoreUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAndroidScreenshotForAndroidScreenshotUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAppForAppUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAppStoreAccountForAppStoreAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAppStoreListingForAppStoreListingUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnAppStoreUserForAppStoreUserUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnBuildForBuildUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnBuildStepForBuildStepUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnCalendarEventForCalendarEventUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnCalendarForCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnCalendarGroupCalendarForCalendarGroupCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnCalendarGroupForCalendarGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnDivisionForDivisionUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnEventForEventUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnFileForFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGoogleAccountForGoogleAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGoogleCalendarSyncCalendarForGoogleCalendarSyncCalendarUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGoogleCalendarSyncForGoogleCalendarSyncUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGooglePlayAccountForGooglePlayAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGooglePlayListingForGooglePlayListingUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGooglePlayUserForGooglePlayUserUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGroupCategoryForGroupCategoryUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGroupForGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGroupNewsletterForGroupNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnGroupPersonForGroupPersonUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnIosAppForIosAppUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnIosScreenshotForIosScreenshotUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnMarqueeForMarqueeUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnNewsletterForNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnNotificationFileForNotificationFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnNotificationForNotificationUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnNotificationGroupForNotificationGroupUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnNotificationNewsletterForNotificationNewsletterUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPageForPageUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPageSectionFileForPageSectionFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPageSectionForPageSectionUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPersonForPersonCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPersonForPersonUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnPersonForProfileImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnReleaseForReleaseUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnResourceFileForResourceFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnResourceForResourceUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSchoolForSchoolUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSchoolPersonForSchoolPersonUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSchoolSettingForSchoolSettingsUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnSponsorForSponsorUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnThemeForThemeUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `person` being updated. */
export type UpdatePersonOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['Email']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  profileImageFileId?: Maybe<Scalars['UUID']>;
  personToCreatedBy?: Maybe<PersonCreatedByFkeyInput>;
  personToUpdatedBy?: Maybe<PersonUpdatedByFkeyInput>;
  profileImage?: Maybe<ProfileImageFileIdFkeyInput>;
  filesToCreatedByUsingId?: Maybe<FileCreatedByFkeyInverseInput>;
  filesToUpdatedByUsingId?: Maybe<FileUpdatedByFkeyInverseInput>;
  calendarsToCreatedByUsingId?: Maybe<CalendarCreatedByFkeyInverseInput>;
  calendarsToUpdatedByUsingId?: Maybe<CalendarUpdatedByFkeyInverseInput>;
  eventsToCreatedByUsingId?: Maybe<EventCreatedByFkeyInverseInput>;
  eventsToUpdatedByUsingId?: Maybe<EventUpdatedByFkeyInverseInput>;
  googleCalendarSyncsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCreatedByFkeyInverseInput>;
  googleCalendarSyncsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncUpdatedByFkeyInverseInput>;
  calendarGroupsToCreatedByUsingId?: Maybe<CalendarGroupCreatedByFkeyInverseInput>;
  calendarGroupsToUpdatedByUsingId?: Maybe<CalendarGroupUpdatedByFkeyInverseInput>;
  googleAccountsToCreatedByUsingId?: Maybe<GoogleAccountCreatedByFkeyInverseInput>;
  googleAccountsToUpdatedByUsingId?: Maybe<GoogleAccountUpdatedByFkeyInverseInput>;
  notificationsToCreatedByUsingId?: Maybe<NotificationCreatedByFkeyInverseInput>;
  notificationsToUpdatedByUsingId?: Maybe<NotificationUpdatedByFkeyInverseInput>;
  newslettersToCreatedByUsingId?: Maybe<NewsletterCreatedByFkeyInverseInput>;
  newslettersToUpdatedByUsingId?: Maybe<NewsletterUpdatedByFkeyInverseInput>;
  groupsToCreatedByUsingId?: Maybe<GroupCreatedByFkeyInverseInput>;
  groupsToUpdatedByUsingId?: Maybe<GroupUpdatedByFkeyInverseInput>;
  resourcesToCreatedByUsingId?: Maybe<ResourceCreatedByFkeyInverseInput>;
  resourcesToUpdatedByUsingId?: Maybe<ResourceUpdatedByFkeyInverseInput>;
  groupPeopleToPersonIdUsingId?: Maybe<GroupPersonPersonIdFkeyInverseInput>;
  groupPeopleToCreatedByUsingId?: Maybe<GroupPersonCreatedByFkeyInverseInput>;
  groupPeopleToUpdatedByUsingId?: Maybe<GroupPersonUpdatedByFkeyInverseInput>;
  schoolPeopleToPersonIdUsingId?: Maybe<SchoolPersonPersonIdFkeyInverseInput>;
  schoolPeopleToCreatedByUsingId?: Maybe<SchoolPersonCreatedByFkeyInverseInput>;
  schoolPeopleToUpdatedByUsingId?: Maybe<SchoolPersonUpdatedByFkeyInverseInput>;
  schoolsToCreatedByUsingId?: Maybe<SchoolCreatedByFkeyInverseInput>;
  schoolsToUpdatedByUsingId?: Maybe<SchoolUpdatedByFkeyInverseInput>;
  schoolSettingsToCreatedByUsingId?: Maybe<SchoolSettingsCreatedByFkeyInverseInput>;
  schoolSettingsToUpdatedByUsingId?: Maybe<SchoolSettingsUpdatedByFkeyInverseInput>;
  androidJavaKeyStoresToCreatedByUsingId?: Maybe<AndroidJavaKeyStoreCreatedByFkeyInverseInput>;
  androidJavaKeyStoresToUpdatedByUsingId?: Maybe<AndroidJavaKeyStoreUpdatedByFkeyInverseInput>;
  releasesToCreatedByUsingId?: Maybe<ReleaseCreatedByFkeyInverseInput>;
  releasesToUpdatedByUsingId?: Maybe<ReleaseUpdatedByFkeyInverseInput>;
  buildsToCreatedByUsingId?: Maybe<BuildCreatedByFkeyInverseInput>;
  buildsToUpdatedByUsingId?: Maybe<BuildUpdatedByFkeyInverseInput>;
  buildStepsToCreatedByUsingId?: Maybe<BuildStepCreatedByFkeyInverseInput>;
  buildStepsToUpdatedByUsingId?: Maybe<BuildStepUpdatedByFkeyInverseInput>;
  appsToCreatedByUsingId?: Maybe<AppCreatedByFkeyInverseInput>;
  appsToUpdatedByUsingId?: Maybe<AppUpdatedByFkeyInverseInput>;
  calendarEventsToCreatedByUsingId?: Maybe<CalendarEventCreatedByFkeyInverseInput>;
  calendarEventsToUpdatedByUsingId?: Maybe<CalendarEventUpdatedByFkeyInverseInput>;
  calendarGroupCalendarsToCreatedByUsingId?: Maybe<CalendarGroupCalendarCreatedByFkeyInverseInput>;
  calendarGroupCalendarsToUpdatedByUsingId?: Maybe<CalendarGroupCalendarUpdatedByFkeyInverseInput>;
  divisionsToCreatedByUsingId?: Maybe<DivisionCreatedByFkeyInverseInput>;
  divisionsToUpdatedByUsingId?: Maybe<DivisionUpdatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToCreatedByUsingId?: Maybe<GoogleCalendarSyncCalendarCreatedByFkeyInverseInput>;
  googleCalendarSyncCalendarsToUpdatedByUsingId?: Maybe<GoogleCalendarSyncCalendarUpdatedByFkeyInverseInput>;
  groupCategoriesToCreatedByUsingId?: Maybe<GroupCategoryCreatedByFkeyInverseInput>;
  groupCategoriesToUpdatedByUsingId?: Maybe<GroupCategoryUpdatedByFkeyInverseInput>;
  groupNewslettersToCreatedByUsingId?: Maybe<GroupNewsletterCreatedByFkeyInverseInput>;
  groupNewslettersToUpdatedByUsingId?: Maybe<GroupNewsletterUpdatedByFkeyInverseInput>;
  marqueesToCreatedByUsingId?: Maybe<MarqueeCreatedByFkeyInverseInput>;
  marqueesToUpdatedByUsingId?: Maybe<MarqueeUpdatedByFkeyInverseInput>;
  notificationFilesToCreatedByUsingId?: Maybe<NotificationFileCreatedByFkeyInverseInput>;
  notificationFilesToUpdatedByUsingId?: Maybe<NotificationFileUpdatedByFkeyInverseInput>;
  notificationNewslettersToCreatedByUsingId?: Maybe<NotificationNewsletterCreatedByFkeyInverseInput>;
  notificationNewslettersToUpdatedByUsingId?: Maybe<NotificationNewsletterUpdatedByFkeyInverseInput>;
  notificationGroupsToCreatedByUsingId?: Maybe<NotificationGroupCreatedByFkeyInverseInput>;
  notificationGroupsToUpdatedByUsingId?: Maybe<NotificationGroupUpdatedByFkeyInverseInput>;
  pagesToCreatedByUsingId?: Maybe<PageCreatedByFkeyInverseInput>;
  pagesToUpdatedByUsingId?: Maybe<PageUpdatedByFkeyInverseInput>;
  pageSectionsToCreatedByUsingId?: Maybe<PageSectionCreatedByFkeyInverseInput>;
  pageSectionsToUpdatedByUsingId?: Maybe<PageSectionUpdatedByFkeyInverseInput>;
  pageSectionFilesToCreatedByUsingId?: Maybe<PageSectionFileCreatedByFkeyInverseInput>;
  pageSectionFilesToUpdatedByUsingId?: Maybe<PageSectionFileUpdatedByFkeyInverseInput>;
  resourceFilesToCreatedByUsingId?: Maybe<ResourceFileCreatedByFkeyInverseInput>;
  resourceFilesToUpdatedByUsingId?: Maybe<ResourceFileUpdatedByFkeyInverseInput>;
  schoolPersonInstallationsToCreatedByUsingId?: Maybe<SchoolPersonInstallationCreatedByFkeyInverseInput>;
  schoolPersonInstallationsToUpdatedByUsingId?: Maybe<SchoolPersonInstallationUpdatedByFkeyInverseInput>;
  schoolPersonInvitationsToCreatedByUsingId?: Maybe<SchoolPersonInvitationCreatedByFkeyInverseInput>;
  schoolPersonInvitationsToUpdatedByUsingId?: Maybe<SchoolPersonInvitationUpdatedByFkeyInverseInput>;
  sponsorsToCreatedByUsingId?: Maybe<SponsorCreatedByFkeyInverseInput>;
  sponsorsToUpdatedByUsingId?: Maybe<SponsorUpdatedByFkeyInverseInput>;
  themesToCreatedByUsingId?: Maybe<ThemeCreatedByFkeyInverseInput>;
  themesToUpdatedByUsingId?: Maybe<ThemeUpdatedByFkeyInverseInput>;
  userGoogleAccountsToCreatedByUsingId?: Maybe<UserGoogleAccountCreatedByFkeyInverseInput>;
  userGoogleAccountsToUpdatedByUsingId?: Maybe<UserGoogleAccountUpdatedByFkeyInverseInput>;
  androidAppsToCreatedByUsingId?: Maybe<AndroidAppCreatedByFkeyInverseInput>;
  androidAppsToUpdatedByUsingId?: Maybe<AndroidAppUpdatedByFkeyInverseInput>;
  androidScreenshotsToCreatedByUsingId?: Maybe<AndroidScreenshotCreatedByFkeyInverseInput>;
  androidScreenshotsToUpdatedByUsingId?: Maybe<AndroidScreenshotUpdatedByFkeyInverseInput>;
  appStoreAccountsToCreatedByUsingId?: Maybe<AppStoreAccountCreatedByFkeyInverseInput>;
  appStoreAccountsToUpdatedByUsingId?: Maybe<AppStoreAccountUpdatedByFkeyInverseInput>;
  appStoreListingsToCreatedByUsingId?: Maybe<AppStoreListingCreatedByFkeyInverseInput>;
  appStoreListingsToUpdatedByUsingId?: Maybe<AppStoreListingUpdatedByFkeyInverseInput>;
  appStoreUsersToCreatedByUsingId?: Maybe<AppStoreUserCreatedByFkeyInverseInput>;
  appStoreUsersToUpdatedByUsingId?: Maybe<AppStoreUserUpdatedByFkeyInverseInput>;
  googlePlayAccountsToCreatedByUsingId?: Maybe<GooglePlayAccountCreatedByFkeyInverseInput>;
  googlePlayAccountsToUpdatedByUsingId?: Maybe<GooglePlayAccountUpdatedByFkeyInverseInput>;
  googlePlayListingsToCreatedByUsingId?: Maybe<GooglePlayListingCreatedByFkeyInverseInput>;
  googlePlayListingsToUpdatedByUsingId?: Maybe<GooglePlayListingUpdatedByFkeyInverseInput>;
  googlePlayUsersToCreatedByUsingId?: Maybe<GooglePlayUserCreatedByFkeyInverseInput>;
  googlePlayUsersToUpdatedByUsingId?: Maybe<GooglePlayUserUpdatedByFkeyInverseInput>;
  iosAppsToCreatedByUsingId?: Maybe<IosAppCreatedByFkeyInverseInput>;
  iosAppsToUpdatedByUsingId?: Maybe<IosAppUpdatedByFkeyInverseInput>;
  iosScreenshotsToCreatedByUsingId?: Maybe<IosScreenshotCreatedByFkeyInverseInput>;
  iosScreenshotsToUpdatedByUsingId?: Maybe<IosScreenshotUpdatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `release` being updated. */
export type UpdateReleaseOnBuildForBuildReleaseIdFkeyPatch = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform?: Maybe<PlatformType>;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType?: Maybe<ReleaseType>;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion?: Maybe<Scalars['String']>;
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse?: Maybe<Scalars['String']>;
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse?: Maybe<Scalars['String']>;
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `release` being updated. */
export type UpdateReleaseOnReleaseForReleaseCreatedByFkeyPatch = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform?: Maybe<PlatformType>;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType?: Maybe<ReleaseType>;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion?: Maybe<Scalars['String']>;
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse?: Maybe<Scalars['String']>;
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse?: Maybe<Scalars['String']>;
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `release` being updated. */
export type UpdateReleaseOnReleaseForReleaseUpdatedByFkeyPatch = {
  /** The primary key for `release`. */
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  /** 'IOS', 'ANDROID', 'WEB' The platform this release is for. */
  platform?: Maybe<PlatformType>;
  /** 'MAJOR', 'MINOR', 'BUG_FIX' This helps inform what to update an individual build's number. */
  releaseType?: Maybe<ReleaseType>;
  /** The version string for the release in ##.##.## format. */
  eventcastVersion?: Maybe<Scalars['String']>;
  /** Release notes in language that Legit Apps employees understand. */
  releaseNotesForInternalUse?: Maybe<Scalars['String']>;
  /** Release notes intended for our apps' users to understand */
  releaseNotesForExternalUse?: Maybe<Scalars['String']>;
  /** The url to the github release. */
  githubReleaseUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ReleaseUpdatedByFkeyInput>;
  builds?: Maybe<BuildReleaseIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resourceFile` being updated. */
export type UpdateResourceFileOnResourceFileForResourceFileCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `resourceFile` being updated. */
export type UpdateResourceFileOnResourceFileForResourceFileFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `resourceFile` being updated. */
export type UpdateResourceFileOnResourceFileForResourceFileResourceIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  sortOrder?: Maybe<Scalars['Int']>;
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `resourceFile` being updated. */
export type UpdateResourceFileOnResourceFileForResourceFileUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  resourceId?: Maybe<Scalars['UUID']>;
  fileId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  resource?: Maybe<ResourceFileResourceIdFkeyInput>;
  file?: Maybe<ResourceFileFileIdFkeyInput>;
  person?: Maybe<ResourceFileUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceFileForResourceFileResourceIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceCoverImageIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceDefaultCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceDefaultThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceGroupIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceParentResourceFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceTileImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `resource` being updated. */
export type UpdateResourceOnResourceForResourceUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ResourceType>;
  /** This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.resource_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
  parentResource?: Maybe<Scalars['UUID']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  tileImageFileId?: Maybe<Scalars['UUID']>;
  defaultCoverImageFileId?: Maybe<Scalars['UUID']>;
  defaultThumbnailImageFileId?: Maybe<Scalars['UUID']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  disableTileOverlay?: Maybe<Scalars['Boolean']>;
  embedUrl?: Maybe<Scalars['String']>;
  person?: Maybe<ResourceUpdatedByFkeyInput>;
  group?: Maybe<ResourceGroupIdFkeyInput>;
  resource?: Maybe<ResourceParentResourceFkeyInput>;
  coverImage?: Maybe<ResourceCoverImageIdFkeyInput>;
  tileImage?: Maybe<ResourceTileImageFileIdFkeyInput>;
  defaultCoverImage?: Maybe<ResourceDefaultCoverImageFileIdFkeyInput>;
  defaultThumbnailImage?: Maybe<ResourceDefaultThumbnailImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<ResourceThumbnailImageFileIdFkeyInput>;
  resourceFiles?: Maybe<ResourceFileResourceIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnAppForAppSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnCalendarGroupForCalendarGroupSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnDivisionForDivisionSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnMarqueeForMarqueeSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSchoolForSchoolCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSchoolForSchoolThemeIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSchoolForSchoolUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSchoolSettingForSchoolSettingsSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `school` being updated. */
export type UpdateSchoolOnSponsorForSponsorSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  archived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['UUID']>;
  deploy?: Maybe<Scalars['Boolean']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  person?: Maybe<SchoolUpdatedByFkeyInput>;
  theme?: Maybe<SchoolThemeIdFkeyInput>;
  calendarGroups?: Maybe<CalendarGroupSchoolIdFkeyInverseInput>;
  schoolPeople?: Maybe<SchoolPersonSchoolIdFkeyInverseInput>;
  schoolSetting?: Maybe<SchoolSettingsSchoolIdFkeyInverseInput>;
  app?: Maybe<AppSchoolIdFkeyInverseInput>;
  divisions?: Maybe<DivisionSchoolIdFkeyInverseInput>;
  marquees?: Maybe<MarqueeSchoolIdFkeyInverseInput>;
  sponsors?: Maybe<SponsorSchoolIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
export type UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationCreatedByFkeyPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
export type UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  appVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInstallation` being updated. */
export type UpdateSchoolPersonInstallationOnSchoolPersonInstallationForSchoolPersonInstallationUpdatedByFkeyPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  appVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  pushEnabled?: Maybe<Scalars['Boolean']>;
  oneSignalToken?: Maybe<Scalars['String']>;
  oneSignalPlayerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  schoolPerson?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInstallationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
export type UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationCreatedByFkeyPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
export type UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch = {
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPersonInvitation` being updated. */
export type UpdateSchoolPersonInvitationOnSchoolPersonInvitationForSchoolPersonInvitationUpdatedByFkeyPatch = {
  schoolPersonId?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  schoolPerson?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInput>;
  person?: Maybe<SchoolPersonInvitationUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonForSchoolPersonCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonForSchoolPersonPersonIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonForSchoolPersonSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonForSchoolPersonUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonInstallationForSchoolPersonInstallationSchoolPersonIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolPerson` being updated. */
export type UpdateSchoolPersonOnSchoolPersonInvitationForSchoolPersonInvitationSchoolPersonIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  personId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  userTypes?: Maybe<Array<Maybe<UserType>>>;
  includeInDirectory?: Maybe<Scalars['Boolean']>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  school?: Maybe<SchoolPersonSchoolIdFkeyInput>;
  person?: Maybe<SchoolPersonUpdatedByFkeyInput>;
  schoolPersonInstallations?: Maybe<SchoolPersonInstallationSchoolPersonIdFkeyInverseInput>;
  schoolPersonInvitation?: Maybe<SchoolPersonInvitationSchoolPersonIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsCoverImageFileIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsCreatedByFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsLaunchScreenPoweredByImageIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsLogoImageFileIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsMascotImageFileIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsSchoolIdFkeyPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsSchoolImageFileIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsThumbnailImageFileIdFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `schoolSetting` being updated. */
export type UpdateSchoolSettingOnSchoolSettingForSchoolSettingsUpdatedByFkeyPatch = {
  schoolId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  timeZone?: Maybe<Scalars['Timezone']>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  schoolImageFileId?: Maybe<Scalars['UUID']>;
  mascotImageFileId?: Maybe<Scalars['UUID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  feedbackUrl?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  sponsorsIncludeSilversOnHome?: Maybe<Scalars['Boolean']>;
  launchScreenEnabled?: Maybe<Scalars['Boolean']>;
  launchScreenPoweredByLabel?: Maybe<Scalars['String']>;
  launchScreenPoweredByImageId?: Maybe<Scalars['UUID']>;
  parentPasscode?: Maybe<Scalars['String']>;
  parentPasscodeHint?: Maybe<Scalars['String']>;
  studentPasscode?: Maybe<Scalars['String']>;
  studentPasscodeHint?: Maybe<Scalars['String']>;
  enableGuestAccess?: Maybe<Scalars['Boolean']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  enableParentFamilyAccess?: Maybe<Scalars['Boolean']>;
  enableStudentAccess?: Maybe<Scalars['Boolean']>;
  resourceTileOpacity?: Maybe<Scalars['Int']>;
  goldSponsorLabel?: Maybe<Scalars['String']>;
  silverSponsorLabel?: Maybe<Scalars['String']>;
  bronzeSponsorLabel?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolSettingsSchoolIdFkeyInput>;
  person?: Maybe<SchoolSettingsUpdatedByFkeyInput>;
  coverImage?: Maybe<SchoolSettingsCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SchoolSettingsThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SchoolSettingsLogoImageFileIdFkeyInput>;
  schoolImage?: Maybe<SchoolSettingsSchoolImageFileIdFkeyInput>;
  mascotImage?: Maybe<SchoolSettingsMascotImageFileIdFkeyInput>;
  launchScreenPoweredByImage?: Maybe<SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorCoverImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorLogoImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorPromoImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorSchoolIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorThumbnailImageFileIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `sponsor` being updated. */
export type UpdateSponsorOnSponsorForSponsorUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  /**  Unique short ID that can be used in public URLs. */
  slug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  schoolId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SponsorType>;
  /**  This column is omitted from the GraphQL schema via a custom Postgraphile plugin. It is intended to be managed completely by Postgres (see the app_private.sponsor_sort_order trigger). */
  sortOrder?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<ButtonsInput>;
  coverImageFileId?: Maybe<Scalars['UUID']>;
  thumbnailImageFileId?: Maybe<Scalars['UUID']>;
  logoImageFileId?: Maybe<Scalars['UUID']>;
  promoImageFileId?: Maybe<Scalars['UUID']>;
  person?: Maybe<SponsorUpdatedByFkeyInput>;
  school?: Maybe<SponsorSchoolIdFkeyInput>;
  coverImage?: Maybe<SponsorCoverImageFileIdFkeyInput>;
  thumbnailImage?: Maybe<SponsorThumbnailImageFileIdFkeyInput>;
  logoImage?: Maybe<SponsorLogoImageFileIdFkeyInput>;
  promoImage?: Maybe<SponsorPromoImageFileIdFkeyInput>;
};

/** An object where the defined keys will be set on the `theme` being updated. */
export type UpdateThemeOnSchoolForSchoolThemeIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `theme` being updated. */
export type UpdateThemeOnThemeForThemeCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `theme` being updated. */
export type UpdateThemeOnThemeForThemeUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  person?: Maybe<ThemeUpdatedByFkeyInput>;
  schools?: Maybe<SchoolThemeIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
export type UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountCreatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
export type UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountGoogleAccountIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `userGoogleAccount` being updated. */
export type UpdateUserGoogleAccountOnUserGoogleAccountForUserGoogleAccountUpdatedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
  googleAccountId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  googleAccount?: Maybe<UserGoogleAccountGoogleAccountIdFkeyInput>;
  person?: Maybe<UserGoogleAccountUpdatedByFkeyInput>;
};
