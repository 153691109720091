import { useField } from 'formik'
import { IField } from './interfaces'
import { CommonProps } from '../interfaces'
import { HexColorInput } from './hex-color-input'


interface IFormikHexColorInputProps extends CommonProps {
    fieldProps: IField
    hideSwatch?: boolean
}
export const FormikHexColorInput = (props: IFormikHexColorInputProps) => {
    const [ field, meta, helpers ] = useField(props.fieldProps)

    return (
        <HexColorInput
            {...props}
            {...meta}
            {...field}
            onChange={helpers.setValue}
        />
    )
}