import { Typography, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { useSchoolSponsorsQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { ImageDisplay } from './image-display'

export const SchoolHomeSponsorsPanel = () => {
    const theme = useTheme()
    const { state: { school: { id: schoolId, schoolSetting } } } = useSchoolContext()
    const navigate = useNavigate()
    const { data } = useHandleReactQuery(useSchoolSponsorsQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL }))
    const gold = data?.gold?.nodes
    const silver = data?.silver?.nodes

    const atLeastOneGoldPublished = gold?.some(o => o.published)
    const atLeastOneSilverPublished = silver?.some(o => o.published)

    if (!atLeastOneGoldPublished && (schoolSetting?.sponsorsIncludeSilversOnHome && !atLeastOneSilverPublished)) return null

    return (
        <>
            {(atLeastOneGoldPublished || (atLeastOneSilverPublished && schoolSetting?.sponsorsIncludeSilversOnHome)) &&
                <div
                    style={{
                        backgroundColor: theme.palette.grey[ 300 ],
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(1.5),
                        display: 'flex',
                        marginTop: theme.spacing(1),
                        maxHeight: 75,
                    }}
                >
                    {atLeastOneGoldPublished && gold && <SponsorsPanel sponsors={gold} />}

                    {schoolSetting?.sponsorsIncludeSilversOnHome && atLeastOneSilverPublished && silver &&
                        <>
                            <div style={{ width: theme.spacing(2) }} />
                            <SponsorsPanel sponsors={silver} />
                        </>
                    }
                </div>
            }

            <Typography onClick={() => navigate('../sponsors')} style={{ width: '100%', paddingTop: theme.spacing(.5) }} variant='caption' align='center' component='div'>View All Sponsors &gt;</Typography>
        </>
    )
}

interface ISponsorsPanel {
    sponsors: SponsorFragment[]
}
const SponsorsPanel = ({ sponsors }: ISponsorsPanel) => {
    const navigate = useNavigate()

    const [ currentSponsor, setCurrentSponsor ] = useState<SponsorFragment>(sponsors[ 0 ])

    const rotateSponsor = () => {
        const currentSponsorIdx = sponsors.findIndex(o => o.id === currentSponsor.id)
        const nextSponsor = sponsors[ currentSponsorIdx + 1 ]
        if (nextSponsor) {
            setCurrentSponsor(nextSponsor)
        } else {
            setCurrentSponsor(sponsors[ 0 ])
        }
    }

    useEffect(() => {
        // If more than one sponsor, rotate the current sponsor
        if (sponsors.length > 1) {
            setTimeout(() => {
                rotateSponsor()
            }, 5000)
        }
    }, [ currentSponsor ])

    return (
        <div onClick={() => navigate(`../sponsors/${currentSponsor.slug}`)} style={{ flex: 1 }}>
            <ImageDisplay style={{ height: '100%', objectFit: 'contain' }} src={currentSponsor.logoImage?.temporaryDownloadUrl} />
        </div>
    )
}