import { FormControl, TextField } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import { IField } from '.'

interface IFormikNumberFieldProps {
    field: IField
    style?: React.CSSProperties
}
export const FormikNumberField = ({ field, style }: IFormikNumberFieldProps) => {
    const [ { value, onBlur, onChange }, { touched, initialError, error } ] = useField(field)

    return (
        <TextField
            style={style}
            type='number'
            variant='outlined'
            label={field.label}
            name={field.name}
            value={value}
            helperText={(touched && error) || field.helperText}
            error={(touched && !!error) || !!initialError}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}