import { Form, Formik } from 'formik'
import { MutableRefObject } from 'react'
import { usePageContext } from './page-edit'
import * as yup from 'yup'
import { FormikTextInput } from './forms'
import { useCreateTextPageSectionMutation, useUpdatePageSectionSortOrderMutation, useUpdateTextPageSectionMutation } from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { FormikRichText } from './forms/formik-rich-text'

interface IPageSectionTextFormProps {
    submitFormRef: MutableRefObject<(() => Promise<any>) | undefined>
}

export const PageSectionTextForm = ({ submitFormRef }: IPageSectionTextFormProps) => {
    const { pageId, state: { newPageSection, pageSectionToEdit }, refetchPageSections, pageSectionFormModal } = usePageContext()

    const { mutateAsync: updateSortOrder } = useHandleReactQueryMutation(useUpdatePageSectionSortOrderMutation())
    const { mutate: createPageSection } = useHandleReactQueryMutation(useCreateTextPageSectionMutation({
        onSuccess: async data => {
            if (newPageSection?.sortOrder !== undefined && data.createPageSection?.pageSection?.id) await updateSortOrder({ pageSectionId: data.createPageSection?.pageSection?.id, newSortOrder: newPageSection.sortOrder })
            await refetchPageSections()
            pageSectionFormModal.close()
        }
    }))

    const { mutate: updatePageSection } = useHandleReactQueryMutation(useUpdateTextPageSectionMutation({
        onSuccess: async () => {
            await refetchPageSections()
            pageSectionFormModal.close()
        },
    }))

    return (
        <Formik
            initialValues={{
                title: pageSectionToEdit?.title || '',
                body: pageSectionToEdit?.body || '',
            }}
            validationSchema={yup.object({
                title: yup.string(),
                body: yup.string().required('Required'),
            })}
            onSubmit={async (values) => {
                if (pageSectionToEdit) {
                    updatePageSection({
                        id: pageSectionToEdit.id,
                        ...values,
                        title: values.title.length ? values.title : null,
                    })
                } else {
                    createPageSection({
                        pageId,
                        ...values,
                        title: values.title.length ? values.title : null,
                    })
                }
            }}
        >
            {formikProps => {
                submitFormRef.current = formikProps.submitForm
                return (
                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormikTextInput fieldProps={{ name: 'title', label: 'Title (optional)' }} />
                        <FormikRichText fieldProps={{ name: 'body' }} height={300} />
                    </Form>
                )
            }}
        </Formik>
    )
}