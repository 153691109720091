import { Typography, useTheme } from '@material-ui/core'
import { ArrowBackIos, CalendarToday, ChatBubble, GroupWork, Home, Send } from '@material-ui/icons'
import clsx from 'clsx'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppState } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useMobileStyles } from '../styles/use-mobile-styles'
import { Modal, useModal } from './modal'
import { NotificationForm } from './notification-form'
import { SchoolLaunchScreen } from './school-launch-screen'
import { SchoolNavBar } from './school-nav-bar'

interface IMobileState {
    tabBarIndex: number
    navbar: {
        title: ReactNode
        backUrl?: string
    }
}

interface IMobileContext {
    state: IMobileState
    setState: Dispatch<SetStateAction<IMobileState>>
}

const MobileContext = createContext<IMobileContext | undefined>(undefined)
export const useMobileContext = () => {
    const context = useContext(MobileContext)
    if (!context) throw new Error('Cannot use MobileContext before it is provided.')
    return context
}

const getTitleForTabIndex = (tabIndex: number) => {
    switch (tabIndex) {
        case 0: return 'Home'
        case 1: return 'Calendar'
        case 2: return 'Groups'
        case 3: return 'Notifications'
        default: return 'Home'
    }
}

const getTabIndexFromPathname = (pathname: string) => {
    let tabBarIndex = 0

    const urlParts = pathname.split('/')
    switch (urlParts[ 3 ]) {
        case 'home':
            tabBarIndex = 0
            break
        case 'calendar':
            tabBarIndex = 1
            break
        case 'groups':
            tabBarIndex = 2
            break
        case 'notifications':
            tabBarIndex = 3
            break
    }

    return tabBarIndex
}


export const School = () => {
    const theme = useTheme()
    const mobileStyles = useMobileStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const { state: { currentUser } } = useAppState()
    const { state: { school: { schoolSetting }, currentUserPermissions } } = useSchoolContext()
    const [ state, setState ] = useState<IMobileState>((): IMobileState => {
        let tabBarIndex = getTabIndexFromPathname(location.pathname)

        return {
            tabBarIndex,
            navbar: { title: getTitleForTabIndex(tabBarIndex) }
        }
    })
    const { tabBarIndex, navbar } = state

    const [ showLaunchScreen, setShowLaunchScreen ] = useState(Boolean(schoolSetting?.launchScreenEnabled))
    useEffect(() => {
        if (showLaunchScreen) {
            setTimeout(() => {
                setShowLaunchScreen(false)
            }, 3000)
        }
    }, [])

    useEffect(() => {
        const index = getTabIndexFromPathname(location.pathname)

        // If a new index then we're switching tabs
        setState(_state => {
            if (_state.tabBarIndex !== index) {
                return { ..._state, tabBarIndex: index, navbar: { ..._state.navbar, title: getTitleForTabIndex(index) } }
            }

            return _state
        })
    }, [ location.pathname ])

    const showSendNotificationAction = tabBarIndex === 3 && (currentUser?.appAdministrator || currentUserPermissions.schoolwideAdmin || currentUserPermissions.groups.nodes.some(o => o.admin))
    const sendNotificationModal = useModal()

    return (
        <MobileContext.Provider value={{ state, setState }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', minHeight: 0, overflow: 'hidden' }}>
                <SchoolNavBar>
                    {navbar.backUrl &&
                        <div
                            style={{ minWidth: 100, display: 'flex', alignItems: 'center', flex: 1, paddingLeft: theme.spacing(1) }}
                            onClick={() => navbar.backUrl && navigate(navbar.backUrl)}
                        >
                            <ArrowBackIos />
                            <span>Back</span>
                        </div>
                    }
                    {!navbar.backUrl && showSendNotificationAction && <div style={{ flex: 1 }} />}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        {navbar.title}
                    </div>
                    {(navbar.backUrl && !showSendNotificationAction) && <div style={{ flex: 1, minWidth: 100, }} />}
                    {showSendNotificationAction &&
                        <div onClick={sendNotificationModal.open} style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', }}>
                            <Send style={{ marginRight: theme.spacing(1) }} />
                        </div>
                    }
                </SchoolNavBar>

                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', backgroundColor: 'white' }}>
                    <Outlet />
                </div>

                <div
                    style={{
                        zIndex: 1,
                        height: 55,
                        display: 'flex',
                        backgroundColor: 'white',
                        // background: 'rgba( 255, 255, 255, 0.90)', // Glassmorphism
                        backdropFilter: 'blur( 4px )',
                        WebkitBackdropFilter: 'blur( 4px )',
                        borderTop: `1px solid ${theme.palette.grey[ 300 ]}`,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <div onClick={() => navigate('home')} className={clsx(mobileStyles.tabBar, tabBarIndex === 0 && mobileStyles.tabBarSelected)}>
                        <Home />
                        <Typography style={{ fontSize: 12 }}>Home</Typography>
                    </div>
                    <div onClick={() => navigate('calendar')} className={clsx(mobileStyles.tabBar, tabBarIndex === 1 && mobileStyles.tabBarSelected)}>
                        <CalendarToday />
                        <Typography style={{ fontSize: 12 }}>Calendar</Typography>
                    </div>
                    <div onClick={() => navigate('groups')} className={clsx(mobileStyles.tabBar, tabBarIndex === 2 && mobileStyles.tabBarSelected)}>
                        <GroupWork />
                        <Typography style={{ fontSize: 12 }}>Groups</Typography>
                    </div>
                    <div onClick={() => navigate('notifications')} className={clsx(mobileStyles.tabBar, tabBarIndex === 3 && mobileStyles.tabBarSelected)}>
                        <ChatBubble />
                        <Typography style={{ fontSize: 12 }}>Notifications</Typography>
                    </div>
                </div>

                {showLaunchScreen &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 15,
                        }}
                    >
                        <SchoolLaunchScreen />
                    </div>
                }
            </div>

            <Modal
                {...sendNotificationModal.props}
                fullscreen
                dismissible
                closeButton
                title='Send Notification'
                noPadding
            >
                <div style={{ padding: `${theme.spacing(1)}px`, overflow: 'hidden', display: 'flex', flexDirection: 'column', minHeight: 0, flex: 1 }}>
                    <NotificationForm showPreview={false} afterSave={sendNotificationModal.close} />
                </div>
            </Modal>
        </MobileContext.Provider>
    )
}