import { Link, useTheme } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { IconType } from '../graphql/autogenerate/schemas'
import { joinStrings } from '../helpers'
import { useAppState } from '../stores/app-state/app-state-provider'
import { useSchoolContext } from '../stores/school'

export interface IGroupOption {
	id: string
	title: string
	divisionName?: string
	iconType: IconType
	color: string
	isDivision?: boolean
	isSchoolwide?: boolean
	divisionId?: string
	divisionGroupId?: string
}

export interface IInfoOption {
	status:
		| 'no-input'
		| 'no-matches'
		| 'matches'
		| 'schoolwide-selected'
		| 'divisions-selected'
	selectedDivisionNames?: string[]
	openSidebarWhenSelected?: boolean
}

export type GroupOptionType = IGroupOption | IInfoOption

export const isInfoOption = (
	option?: IGroupOption | IInfoOption
): option is IInfoOption => (option as IInfoOption)?.status !== undefined

interface IGroupSelectorState {
	selectedGroupIds: string[]
	selectionsAutomaticallyRemoved?: {
		schoolwide?: boolean
		divisions?: {
			id: string
			name: string
		}[]
	}
}

interface IUseGroupSelectorOptions {
	divisions?: SchoolDivisionsFragment[]
	initialSelectedGroupIds?: string[]
}

/** 
    Logic for powering a Group Selector. 
    
    Includes a little display logic in the form of InfoOptions added to the list of GroupOptions.

    Tries to focus on just the implementation and not the display.
*/
export const useGroupSelector = ({
	divisions,
	initialSelectedGroupIds,
}: IUseGroupSelectorOptions) => {
	const theme = useTheme()
	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: { currentUserPermissions },
	} = useSchoolContext()

	const [groupSelectorState, setGroupSelectorState] =
		useState<IGroupSelectorState>({
			selectedGroupIds: initialSelectedGroupIds || [],
		})

	const { selectedGroupIds } = groupSelectorState

	// Calculate the raw options list, this should only need to be done once since the divisions query is unlikely to be run again (but it's OK if it happens multiple times, hence including Divisions in the deps list).
	const groupOptionsRaw = useMemo<IGroupOption[]>(() => {
		if (!divisions) return []

		const multiDivisionSchool = divisions.length > 1

		// We check the group names to see if there are any duplicates. If there are, we add the group's division and category to help distinguish it.
		const groupNames: string[] = []
		divisions.forEach(division => {
			division.groupCategories.nodes.forEach(category => {
				groupNames.push(
					...category.groups.nodes.map(group => group.groupName.toLowerCase())
				)
			})
		})
		const nameCounts = groupNames.reduce<{ [key: string]: number }>(
			(nameCounts, name) => ({
				...nameCounts,
				[name]: (nameCounts[name] || 0) + 1,
			}),
			{}
		)
		const duplicateNames = Object.keys(nameCounts).filter(
			name => nameCounts[name] > 1
		)

		const _options: IGroupOption[] = []
		divisions.forEach(division => {
			if (division.group) {
				_options.push({
					id: division.group.id,
					title: division.name,
					iconType: division.schoolwide
						? IconType.IosFilledCityHall
						: IconType.IosFilledSchool,
					color: theme.palette.primary.main,
					isSchoolwide: Boolean(division.schoolwide),
					isDivision: true,
					divisionName: division.name,
					divisionId: division.id,
					divisionGroupId: division.group.id,
				})
			}

			division.groupCategories.nodes.forEach(category => {
				_options.push(
					...category.groups.nodes.map(group => ({
						id: group.id,
						title: group.groupName,
						divisionName:
							!multiDivisionSchool &&
							duplicateNames.includes(group.groupName.toLowerCase())
								? division.name
								: undefined,
						iconType: group.iconType || category.iconType,
						color: category.iconBackgroundColor || theme.palette.grey[500],
						divisionId: division.id,
						divisionGroupId: division.group?.id,
					}))
				)
			})
		})

		if (
			currentUser?.appAdministrator ||
			currentUserPermissions.schoolwideAdmin
		) {
			return _options
		} else {
			return _options.filter(option =>
                // Allow access if the user is an admin on the group OR an admin on this group's division's group
				currentUserPermissions.groups.nodes.some(
					group =>
						(group.groupId === option.id ||
							option.divisionGroupId === group.groupId) &&
						group.admin
				)
			)
		}
	}, [divisions])

	/** 
        Performs validations on the group selections to assemble the final list of group options to present the user. 
        Adds InfoOptions to the list for use in displaying information to the user about available options.

        - If Schoolwide is selected then show no options.
        - If any Divisions are selected then filter out 

    */
	const availableOptions = useMemo<GroupOptionType[]>(() => {
		let options: GroupOptionType[] = groupOptionsRaw

		/* 
            First and foremost, we need to check validation states...

            - Is schoolwide selected?
            - Which divisions are selected?
        */
		const schoolwideOptionSelected = options.find(
			o => !isInfoOption(o) && o.isSchoolwide && selectedGroupIds.includes(o.id)
		)
		if (schoolwideOptionSelected) {
			// If schoolwide is selected we don't want to offer any more selections.
			const schoolwideSelected: IInfoOption = { status: 'schoolwide-selected' }
			return [schoolwideSelected]
		}

		const divisionOptionsSelected = options.filter(
			o => !isInfoOption(o) && o.isDivision && selectedGroupIds.includes(o.id)
		)
		if (divisionOptionsSelected.length > 0) {
			const selectedDivisionIds = (
				divisionOptionsSelected as IGroupOption[]
			).map(o => o.divisionId)

			// For any selected divisions, remove groups for those divisions from the option set.
			options = options.filter(
				o =>
					isInfoOption(o) ||
					!o.divisionId ||
					!selectedDivisionIds.includes(o.divisionId)
			)

			// Add a "final option" that lists which divisions groups have been removed for.
			const selectedDivisions: IInfoOption = {
				status: 'divisions-selected',
				selectedDivisionNames: (divisionOptionsSelected as IGroupOption[]).map(
					o => {
						if (o.divisionName) return o.divisionName
						throw new Error(
							'Encountered a group option marked as "isDivision" but without a value for the divisionName.'
						)
					}
				),
			}
			options.push(selectedDivisions)
		}

		return options
	}, [groupOptionsRaw, selectedGroupIds])

	const availableGroupOptions = useMemo<IGroupOption[]>(() => {
		return availableOptions.filter(o => !isInfoOption(o)) as IGroupOption[]
	}, [availableOptions])

	const selectedGroupOptions = useMemo(() => {
		return groupOptionsRaw
			.filter(o => selectedGroupIds.includes(o.id))
			.sort(
				(a, b) =>
					selectedGroupIds.indexOf(a.id) - selectedGroupIds.indexOf(b.id)
			)
	}, [groupOptionsRaw, selectedGroupIds])

	const handleSelectedGroupsChange = useCallback(
		(selectedOptions: IGroupOption[]) => {
			/* 
            Validation checks:

            - Is Schoolwide selected?
            If so, check if there were any other selections.
            If there were, don't include them, just set Schoolwide, and show the Schoolwide callout to notify the user what just happened.

            - Are any Divisions selected?
            If so, check if there were any other selections.
            If there were, gather the selections for any selected Divisions.
            If there were any group selections from the selected Divisions, don't inlcude them and show the Divisions callout to notify the user.
        */

			const schoolwideSelected = selectedOptions.find(o => o.isSchoolwide)
			if (schoolwideSelected) {
				setGroupSelectorState(_state => ({
					..._state,
					selectedGroupIds: [schoolwideSelected.id],
					selectionsAutomaticallyRemoved: {
						schoolwide: selectedOptions.length > 1,
					},
				}))
				return
			}

			const divisionsSelected = selectedOptions.filter(o => o.isDivision)
			if (divisionsSelected.length > 0) {
				// Check to see if any of the other selections were from these divisions
				const selectedDivisionIds = divisionsSelected.map(o => o.divisionId)
				const groupSelectionIdsFromSelectedDivisions = selectedOptions
					.filter(
						o =>
							!o.isDivision &&
							!o.isSchoolwide &&
							o.divisionId &&
							selectedDivisionIds.includes(o.divisionId)
					)
					.map(o => o.id)

				// Set the selection to only schoolwide, divisions, and groups not included in the selected divisions
				setGroupSelectorState(_state => ({
					..._state,
					selectedGroupIds: selectedOptions
						.filter(o => !groupSelectionIdsFromSelectedDivisions.includes(o.id))
						.map(o => o.id),
					selectionsAutomaticallyRemoved: {
						divisions:
							groupSelectionIdsFromSelectedDivisions.length > 0
								? divisionsSelected.map(o => {
										if (o.divisionName)
											return { id: o.id, name: o.divisionName }
										throw new Error(
											'Encountered a group option marked as "isDivision" but without a value for the divisionName.'
										)
								  })
								: undefined,
					},
				}))

				return
			}

			setGroupSelectorState(_state => ({
				..._state,
				selectedGroupIds: selectedOptions.map(o => o.id),
				schoolwideSelected: undefined,
				divisionsSelected: undefined,
				selectionsAutomaticallyRemoved: undefined,
			}))
		},
		[]
	)

	const removeGroupFromSelection = useCallback(
		(groupId: string) => {
			const _ids = selectedGroupIds.filter(id => id !== groupId)

			handleSelectedGroupsChange(
				groupOptionsRaw.filter(o => _ids.includes(o.id))
			)
		},
		[selectedGroupIds]
	)

	/** 
        Adds a single group to the selection if it is not already there.

        *Thin wrapper for `handleSelectedGroupsChange`.
    */
	const addGroupToSelection = useCallback(
		(groupOption: IGroupOption | string) => {
			if (typeof groupOption === 'string') {
				const addOption = groupOptionsRaw.find(o => o.id === groupOption)
				if (addOption)
					handleSelectedGroupsChange([...selectedGroupOptions, addOption])
			} else {
				handleSelectedGroupsChange([...selectedGroupOptions, groupOption])
			}
		},
		[selectedGroupOptions]
	)

	/** 
        Toggles the selected state of a group.

        *Thin wrapper for `handleSelectedGroupsChange`.
    */
	const toggleGroupSelected = useCallback(
		(groupId: string) => {
			if (selectedGroupIds.includes(groupId)) {
				removeGroupFromSelection(groupId)
			} else {
				addGroupToSelection(groupId)
			}
		},
		[selectedGroupIds]
	)

	/** 
        Clear all selected groups.
    */
	const clearSelectedGroups = useCallback(
		() =>
			setGroupSelectorState(_state => ({ ..._state, selectedGroupIds: [] })),
		[]
	)

	const setSelectedGroupIds = useCallback((_selectedGroupIds: string[]) => {
		setGroupSelectorState(_state => ({
			..._state,
			selectedGroupIds: _selectedGroupIds,
		}))
	}, [])

	return {
		availableOptions,
		availableGroupOptions,
		selectedGroupOptions,
		handleSelectedGroupsChange,
		removeGroupFromSelection,
		addGroupToSelection,
		toggleGroupSelected,
		groupSelectorState,
		divisions,
		clearSelectedGroups,
		setSelectedGroupIds,
	}
}

export const useGetContentForInfoOption = () =>
	useCallback((option: IInfoOption) => {
		switch (option.status) {
			case 'matches':
				return (
					<span>
						Select an option above or <Link>view list</Link>.
					</span>
				)
			case 'no-input':
				return (
					<span>
						Start typing to search for a group or <Link>pick from list</Link>.
					</span>
				)
			case 'no-matches':
				return (
					<span>
						No groups match. Try a different search or{' '}
						<Link>pick from a list</Link> instead.
					</span>
				)
			case 'schoolwide-selected':
				return (
					<span>
						You have selected the <b>Schoolwide</b> group. There is no need to
						include any more groups since content available to the{' '}
						<b>Schoolwide</b> group will be visible to all users from all
						groups.
					</span>
				)
			case 'divisions-selected':
				if (!option.selectedDivisionNames)
					throw new Error(
						'Unable to display divisions-selected IFinalOption. Missing selectedDivisionNames.'
					)
				const divisionNames = joinStrings(option.selectedDivisionNames)
				const genericLabel =
					option.selectedDivisionNames &&
					option.selectedDivisionNames.length > 1
						? 'these divisions'
						: 'this division'
				return (
					<span>
						You have selected <b>{divisionNames}</b>. Groups from {genericLabel}{' '}
						are omitted from this list of group options. There is no need to
						include any more groups from <b>{divisionNames}</b> since content
						available to {genericLabel} will be visible to users in all groups
						within <b>{divisionNames}</b>.
					</span>
				)
		}
	}, [])
