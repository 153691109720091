import { Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { ImageDisplay } from './image-display'

interface IPageSectionPictureProps {
	pageSection: PageSectionFragment
}

export const PageSectionPicture = ({
	pageSection,
}: IPageSectionPictureProps) => {
	const theme = useTheme()

	const cover = pageSection.pageSectionFiles.nodes.find(o => o.cover)
	const phoneAndDown = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	)

	if (cover) {
		return (
			<>
				<ImageDisplay
					src={cover.file?.temporaryDownloadUrl}
					alt='cover image'
					style={{ borderRadius: theme.shape.borderRadius }}
				/>
				{cover.description && (
					<Typography variant='caption'>{cover.description}</Typography>
				)}
			</>
		)
	} else {
		return (
			<>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						// margin: `${theme.spacing(phoneAndDown ? 0.5 : 1)}px 0px`,
					}}
				>
					{pageSection.pageSectionFiles.nodes.map(image => (
						<div
							key={image.id}
							style={{
								flex: 1,
								maxWidth: 200,
								margin: `0px ${theme.spacing(phoneAndDown ? 0.5 : 1.5)}px`,
							}}
						>
							<ImageDisplay
								style={{ borderRadius: theme.shape.borderRadius, maxHeight: 200 }}
								src={image.file?.temporaryDownloadUrl}
								alt={image.title || ''}
							/>
						</div>
					))}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						margin: `${theme.spacing(phoneAndDown ? 0.5 : 1)}px 0px`,
					}}
				>
					{pageSection.pageSectionFiles.nodes
						.filter(o => o.title || o.description)
						.map(image => (
							<div
								key={image.id}
								style={{
									flex: 1,
									maxWidth: 200,
									margin: `0px ${theme.spacing(phoneAndDown ? 0.5 : 1.5)}px`,
								}}
							>
								{image.title && <Typography variant='h6'>{image.title}</Typography>}
								{image.description && (
									<Typography color='textSecondary'>
										<div dangerouslySetInnerHTML={{ __html: image.description || '' }} />
									</Typography>
								)}
							</div>
						))}
				</div>
			</>
		)
	}
}
