import { Box, IconButton } from '@material-ui/core'
import React from 'react'
import { Back, Forward } from '../images'
import { CalendarActionType, CalendarViewMode } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { Tooltip } from './tooltip'

interface ICalendarHeaderForwardBackArrowsProps extends ICalendarDispatchProp {
    viewMode: CalendarViewMode
}


const CalendarHeaderForwardBackArrows = ({ dispatch, viewMode }: ICalendarHeaderForwardBackArrowsProps) => {
    const previousTitle = `Previous ${viewMode}`
    const nextTitle = `Next ${viewMode}`

    return (
        <Box display='flex' mx={2} >
            <Tooltip title={previousTitle}>
                <IconButton aria-label={previousTitle} onClick={() => dispatch({ type: CalendarActionType.goToPrevious })}>
                    <Back style={{ height: 20 }} />
                </IconButton>
            </Tooltip>

            <Tooltip title={nextTitle}>
                <IconButton aria-label={nextTitle} onClick={() => dispatch({ type: CalendarActionType.goToNext })}>
                    <Forward style={{ height: 20 }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export const MemoizedCalendarHeaderForwardBackArrows = React.memo(CalendarHeaderForwardBackArrows)