import React from 'react'
import { CalendarAction, CalendarContextProvider } from '../stores/calendar'
import { MemoizedCalendarViews } from './calendar-views'
import { MemoizedCalendarHeader } from './calendar-header'
import { MemoizedCalendarSidebar } from './calendar-sidebar'
import { CalendarEventModal } from './calendar-event-modal'

export const Calendar = () => {
    return (
        <CalendarContextProvider>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: 'calc(100% - 50px)' }}>
                <MemoizedCalendarHeader />
                <div style={{ flex: 1, display: 'flex', minHeight: 0, }}>
                    <MemoizedCalendarSidebar />
                    <MemoizedCalendarViews />
                </div>
            </div>
            <CalendarEventModal />
        </CalendarContextProvider>
    )
}

export interface ICalendarDispatchProp {
    dispatch: React.Dispatch<CalendarAction>
}