import dayjs from 'dayjs'
import { ICalendarState } from '.'
import { Scalars } from '../../graphql/autogenerate/schemas'
import { SessionDataKey } from '../../hooks/use-session-data'

const initialCalendarState: ICalendarState = {
    sidebarIsOpen: true,
    viewMode: 'week',
    selectedDate: dayjs().startOf('d'),
    searchActive: false,
    disableShortcuts: false,
    eventModal: {
        isOpen: false
    },
    visibleCalendars: {},
    favoritedCalendars: {},
    disableDateScrolling: false,
    events: [],
    calendarGroups: [],
}

export const calendarStateFromDb = (dbState?: Scalars[ 'JSON' ]): ICalendarState => {
    const slice = dbState && dbState[ SessionDataKey.calendar ]
    if (slice) {
        return {
            ...initialCalendarState,
            ...slice,
            selectedDate: slice.selectedDate ? dayjs(slice.selectedDate) : initialCalendarState.selectedDate,
            eventModal: {
                ...initialCalendarState.eventModal,
                ...slice.eventModal,
            },
            visibleCalendars: slice.visibleCalendars ? slice.visibleCalendars : {},
            favoritedCalendars: slice.favoritedCalendars ? slice.favoritedCalendars : {},
        }
    } else {
        return initialCalendarState
    }
}

export const calendarStateToDb = (state: ICalendarState): Scalars[ 'JSON' ] => {

    // Remove the properties we don't want to persist...
    const {
        disableDateScrolling,
        disableShortcuts,
        events,
        searchActive,
        eventModal,
        calendarGroups,
        ...rest
    } = state

    return {
        [ SessionDataKey.calendar ]: {
            ...rest,
            selectedDate: state.selectedDate.toISOString(),
        }
    }
}