import { makeStyles, createStyles } from "@material-ui/core"

export const useFormStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flex: 1,
        },
        margin: {
            marginLeft: `${theme.spacing(.5)}px !important`,
            marginRight: `${theme.spacing(.5)}px !important`,
            marginBottom: `${theme.spacing(1)}px !important`,
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        errorBorder: {
            border: `1px solid ${theme.palette.error.main}`,
            borderRadius: theme.shape.borderRadius,
        }
    }),
)