import { useTheme, Paper, Typography, Button } from '@material-ui/core'
import { useSchoolContext } from '../stores/school'
import { ImageDisplay } from './image-display'
import { useSchoolOnboardingContext } from './school-onboarding'


export const SchoolOnboardingWelcome = () => {
    const theme = useTheme()
    const { state: { school } } = useSchoolContext()
    const { setSchoolOnboardingState } = useSchoolOnboardingContext()

    return (
        <Paper style={{ padding: theme.spacing(2), margin: theme.spacing(2) }}>
            {school.schoolSetting?.thumbnailImage?.temporaryDownloadUrl &&
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: theme.spacing(2) }}>
                    <ImageDisplay style={{ height: 75, width: 75, borderRadius: theme.shape.borderRadius }} src={school.schoolSetting.thumbnailImage.temporaryDownloadUrl} />
                </div>
            }

            <Typography variant='h5' style={{ marginBottom: theme.spacing(2) }} align='center'>Welcome to the <b>{school.name}</b> app!</Typography>

            <Typography style={{ marginBottom: theme.spacing(2) }} align='center'>Customize your app experience and receive the most up to date calendars, resources, notifications, and newsletters tailored for you.</Typography>

            <Button onClick={() => setSchoolOnboardingState(_state => ({ ..._state, step: 'user-type' }))} size='large' variant='contained' color='secondary' style={{ width: '100%' }} >Get Started</Button>
        </Paper>
    )
}