import { ReactNode, useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'
import { useAuthenticateMutation, useCurrentUserQuery } from '../graphql/autogenerate/react-query'
import { useAccessToken, useAppState, useLocalStorage, useMobileDetect, useRegisterAnonymousUser } from "../hooks"
import { AppActionType } from "../stores/app-state"
import { LoadingOverlay } from "./loading-overlay"

/* 
    // TODO: make sure the anonymous user ID is not set if there is a JWT
    TODO: if there was a JWT for a NON anonymous user, show the auth screen
*/

/**
    __Bootstrap__

    @description
    What happens during Bootstrap?
    - Attempt to fetch the current user, if the request goes through, bootstrap as authed, otherwise bootstrap as un-authed.
    - If someone is trying to access the app ON MOBILE but doesn't have a user account yet, go ahead and create an anonymous one for them.
**/
export const Bootstrap = ({ children }: { children: ReactNode }) => {
    const { state: { bootstrapped }, dispatch } = useAppState()
    const { isMobile } = useMobileDetect()
    const location = useLocation()
    const navigate = useNavigate()
    const [ start, setStart ] = useState(false)
    const { getItem } = useLocalStorage()
    const { setAccessToken, tokenIsExpired, getAccessToken } = useAccessToken()
    const registerAnonymousUser = useRegisterAnonymousUser()

    const { mutateAsync: anonymousAuthenticate } = useAuthenticateMutation({
        onSuccess: async ({ authenticate }) => {
            await setAccessToken(authenticate?.jwtToken)
        }
    })

    const bootstrap = useCallback(async () => {
        // Do we have a stored anonymous user account ID?
        const anonymousAccountId = await getItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })

        /* 
            If we have an expired JWT for a NON-anonymous user, redirect to auth (regardless whether we're on mobile or desktop).
            Preserve the starting URL the user was trying to visit.
        */
        const jwt = await getAccessToken()
        const isExpired = await tokenIsExpired()
        if (jwt && isExpired && !anonymousAccountId) {
            navigate(`/login?redirectUrl=${location.pathname}`)
            return
        }

        // Mobile requires some specific startup logic to handle anonymous accounts (a hack to avoid having to redo database schemas requiring an authenticated user for all queries).
        if (isMobile()) {
            // If we have an expired JWT for an anonymous user, automatically authenticate.
            if (jwt && isExpired && anonymousAccountId) await anonymousAuthenticate({ email: `${anonymousAccountId}@legitapps.com`, password: anonymousAccountId })

            // No stored JWT (expired or alive)...this is (unless something went wrong) a fresh installation on mobile. Register an anonymous user.
            if (!jwt) await registerAnonymousUser()
        }

        // Setup global analytics
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    }, [])

    useEffect(() => {
        bootstrap().then(() => setStart(true))
    }, [])

    // The user query essentially serves as bootstrap
    useCurrentUserQuery(undefined, {
        onSuccess: async results => {
            let currentUser = results.currentUser

            dispatch({
                type: AppActionType.bootstrap,
                payload: {
                    authed: Boolean(currentUser),
                    currentUser: currentUser,
                }
            })
        },
        enabled: start,

        // Only run this once (disable all refetches)
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })


    /* 
        We don't want to begin rendering the app until it has been bootstrapped.

        This helps make sure all opening configuration has been finished before anything tries to render.

        E.g. checking for a valid or stale auth, setting up theme data, fetching remote config, etc.
    */
    if (!bootstrapped) return <LoadingOverlay />

    return (
        <>
            {children}
        </>
    )
}