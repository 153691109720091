import {
	createStyles,
	IconButton,
	makeStyles,
	Tooltip,
} from '@material-ui/core'
import {
	Title,
	Subject,
	Photo,
	Mouse,
	YouTube,
	AttachFile,
	PlayArrow
} from '@material-ui/icons'
import clsx from 'clsx'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { PageSectionType } from '../graphql/autogenerate/schemas'

const useStyles = makeStyles(theme =>
	createStyles({
		toolbarContainer: {
			backgroundColor: theme.palette.grey[100],
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
		},
		toolbar: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main}`,
			},
			marginRight: theme.spacing(2),
		},
	})
)

interface IPageSectionAddToolbarProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	onAddPageSectionTypePressed: (type: PageSectionType) => void
}

export const PageSectionAddToolbar: FC<IPageSectionAddToolbarProps> = ({
	children,
	onAddPageSectionTypePressed,
	className,
	...rest
}) => {
	const styles = useStyles()

	return (
		<div className={clsx(styles.toolbarContainer, className)} {...rest}>
			{children}
			<div className={clsx(styles.toolbar)}>
				<Tooltip title='Title'>
					<IconButton
						className={styles.button}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Title)}
					>
						<Title />
					</IconButton>
				</Tooltip>

				<Tooltip title='Rich Text'>
					<IconButton
						className={styles.button}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Text)}
					>
						<Subject />
					</IconButton>
				</Tooltip>

				<Tooltip title='Picture'>
					<IconButton
						className={styles.button}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Picture)}
					>
						<Photo />
					</IconButton>
				</Tooltip>

				<Tooltip title='Button'>
					<IconButton
						className={styles.button}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Button)}
					>
						<Mouse />
					</IconButton>
				</Tooltip>

				<Tooltip title='Embed Video or Podcast'>
					<IconButton
						className={styles.button}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Video)}
					>
						<PlayArrow />
					</IconButton>
				</Tooltip>

				<Tooltip title='Attachments'>
					<IconButton
						className={styles.button}
						style={{ marginRight: 0 }}
						onClick={() => onAddPageSectionTypePressed(PageSectionType.Attachment)}
					>
						<AttachFile />
					</IconButton>
				</Tooltip>
			</div>
		</div>
	)
}
