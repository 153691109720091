import { Button, Typography, useTheme } from '@material-ui/core'
import { Link, ListAlt, Mail, Phone, Textsms } from '@material-ui/icons'
import { CSSProperties } from 'react'
import { ButtonFragment } from '../graphql/autogenerate/operations'
import { ButtonType } from '../graphql/autogenerate/schemas'

interface ILinkButtonProps {
	button: ButtonFragment
	style?: CSSProperties
}
export const LinkButton = ({ button, style }: ILinkButtonProps) => {
	const theme = useTheme()

	const startIcon = (() => {
		switch (button.type) {
			case ButtonType.Email:
				return <Mail style={{ fontSize: style?.fontSize }} />
			case ButtonType.Form:
				return <ListAlt style={{ fontSize: style?.fontSize }} />
			case ButtonType.Phone:
				return <Phone style={{ fontSize: style?.fontSize }} />
			case ButtonType.Sms:
				return <Textsms style={{ fontSize: style?.fontSize }} />
			case ButtonType.Url:
				return <Link style={{ fontSize: style?.fontSize }} />
		}
	})()

	const url = (() => {
		switch (button.type) {
			case ButtonType.Email:
				return `mailto:${button.url}`
			case ButtonType.Phone:
				return `tel:${button.url}`
			case ButtonType.Sms:
				return `sms:${button.url}`
			default:
				return button.url
		}
	})()

	return (
		<>
			<Button
				target='_blank'
				style={{ marginBottom: theme.spacing(1), ...style }}
				variant='contained'
				color='primary'
				href={url}
				startIcon={startIcon}
			>
				<span style={{ textAlign: 'center' }}>{button.label || button.url}</span>
			</Button>
			{button.description && (
				<Typography align='center' variant='subtitle2'>
					{button.description}
				</Typography>
			)}
		</>
	)
}
