import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useInterval } from 'react-use'

const getTimeBarPositionFromTime = () => {
    const currentTime = dayjs()
    const dayOfWeek = currentTime.day()
    const hour = currentTime.hour()
    return {
        gridArea: `${(hour * 4) + 1} / ${dayOfWeek + 3} / ${((hour + 1) * 4) + 1} / ${dayOfWeek + 4} `,
        marginTop: currentTime.minute(),
    }
}


const CurrentTimeBar = () => {
    const [ state, setState ] = useState(getTimeBarPositionFromTime())

    useInterval(() => {
        setState(getTimeBarPositionFromTime())
    }, 1000)

    const ref = useCallback((ref: HTMLDivElement) => {
        if (ref) ref.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
    }, [])

    return (
        <div ref={ref} style={{ gridArea: state.gridArea }}>
            <div className='currentTimeBar' style={{ marginTop: state.marginTop }} />
        </div>
    )
}

export const MemoizedCurrentTimeBar = React.memo(CurrentTimeBar)