import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { FileRelationType } from '../graphql/autogenerate/schemas'
import { AttachmentCell } from './attachment-cell'

interface IPageSectionAttachmentsProps {
	pageSection: PageSectionFragment
}

export const PageSectionAttachments = ({
	pageSection,
}: IPageSectionAttachmentsProps) => {
	return (
		<div>
			{pageSection.pageSectionFiles.nodes
				.filter(o => o.fileRelationType === FileRelationType.Attachment)
				.map(o => {
					const file = o.file
					if (file) {
						return (
							<AttachmentCell
								key={file.id}
								label={file.label || file.filename}
								temporaryDownloadUrl={file.temporaryDownloadUrl}
								type={file.type}
							/>
						)
					}
					return null
				})}
		</div>
	)
}
