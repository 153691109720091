import { CircularProgress } from '@material-ui/core'
import React from 'react'

export interface ILoadingProps {
    size?: 'xs' | 's' | 'm' | 'l' | 'xl'
}
export const Loading = ({ size}: ILoadingProps) => {
    const _size = (() => {
        switch (size) {
            case 'xs':
                return 10
            case 's':
                return 25
            case 'm':
                return 50
            case 'l':
                return 100
            case 'xl':
                return 200
            default:
                return 100
        }
    })()

    return <CircularProgress color='inherit' size={_size} />
}