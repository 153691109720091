import { Box, Typography, useTheme } from '@material-ui/core'
import { School } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { withContextSelector } from '../helpers'
import { CityHall } from '../images'
import { useCalendarContext } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { IDivisionCalendars } from './calendar-sidebar-calendars-list'
import { ICalendarSidebarCalendarsListRowProps, MemoizedCalendarSidebarCalendarsListRow } from './calendar-sidebar-calendars-list-row'
import { MemoizedExpandableDropdown } from './expandable-dropdown'
import { IconTypeDisplay } from './icon-type-display'

interface ICalendarSidebarCalendarsListFavoritesProps extends ICalendarDispatchProp {
    divisions: IDivisionCalendars[]
    favoritedCalendars?: { [ key: string ]: boolean }
}

const CalendarSidebarCalendarsListFavorites = ({ favoritedCalendars, divisions, dispatch }: ICalendarSidebarCalendarsListFavoritesProps) => {
    const theme = useTheme()

    const calendars: Omit<ICalendarSidebarCalendarsListRowProps, 'dispatch'>[] = useMemo(() => {

        if (!favoritedCalendars) return []

        const _calendars: Omit<ICalendarSidebarCalendarsListRowProps, 'dispatch'>[] = []

        divisions.forEach(division => {

            if (favoritedCalendars[ division.group.calendar.id ] && !division.disabled)
                _calendars.push(({
                    id: division.group.calendar.id,
                    title:
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {division.schoolwide ?
                                <CityHall height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} /> :
                                <School height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} />
                            }
                            {division.name}
                        </span>,
                    calendarIds: [ division.group.calendar.id ],
                    color: theme.palette.primary.main,
                }))

            division.groupCategories.filter(o => o.groups.length).forEach(({ groups, iconBackgroundColor, iconType }) => {
                groups.forEach(group => {
                    if (favoritedCalendars[ group.calendar.id ])
                        _calendars.push({
                            id: group.calendar.id,
                            title:
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {group.iconType ?
                                        <IconTypeDisplay type={group.iconType} size={20} style={{ marginRight: 5, color: iconBackgroundColor || theme.palette.grey[ 500 ] }} /> :
                                        <IconTypeDisplay type={iconType} size={20} style={{ marginRight: 5, color: iconBackgroundColor || theme.palette.grey[ 500 ] }} />
                                    }
                                    <div>
                                        <div>{group.groupName}</div>
                                        <div style={{ ...theme.typography.caption, lineHeight: 1, color: theme.palette.grey[ 400 ], paddingLeft: 2 }}>{division.name}</div>
                                    </div>
                                </div>,
                            calendarIds: [ group.calendar.id ],
                            color: iconBackgroundColor || theme.palette.grey[ 500 ],
                        })
                })
            })
        })

        return _calendars

    }, [ favoritedCalendars, divisions ])

    const [ isOpen, setIsOpen ] = useState(true)

    return (
        <MemoizedExpandableDropdown sectionTitle='Favorites' controls={{ isOpen, setIsOpen }}>
            {calendars.map(calendar => (
                <MemoizedCalendarSidebarCalendarsListRow
                    key={calendar.id}
                    {...calendar}
                    dispatch={dispatch}
                />
            ))}
            {calendars.length === 0 &&
                <Box px={4}>
                    <Typography variant='caption'>No favorites added yet. Hover a calendar and press the "+" icon to add it to your favorites.</Typography>
                </Box>
            }
        </MemoizedExpandableDropdown>
    )
}

const Memoized = React.memo(CalendarSidebarCalendarsListFavorites)
export const MemoizedCalendarSidebarCalendarsListFavorites = withContextSelector({
    MemoizedComponent: Memoized,
    getContext: useCalendarContext,
    selector: ({ state: { favoritedCalendars } }) => ({ favoritedCalendars })
})