import { Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { PageSectionFragment } from '../graphql/autogenerate/operations'

interface IPageSectionTitleProps {
    pageSection: PageSectionFragment
}

export const PageSectionTitle = ({ pageSection }: IPageSectionTitleProps) => {
    const theme = useTheme()
    const phoneAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    return (
        <>
            <Typography variant={phoneAndDown ? 'h5' : 'h4'}>{pageSection.title}</Typography>
            <Typography style={theme.typography.subtitle1}>
                {pageSection.subtitle}
            </Typography>

            {/* <div className='fading-divider' /> */}
        </>
    )
}