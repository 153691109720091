import { IconType } from '../graphql/autogenerate/schemas'
import Fuse from 'fuse.js'

const iconTypeFuse = new Fuse(Object.values(IconType))

const replaceNumbersWithText = (searchTerm: string) => {
    searchTerm = searchTerm.toLowerCase()
    searchTerm = searchTerm.replaceAll('grade', '')
    searchTerm = searchTerm.replaceAll('1st', 'one')
    searchTerm = searchTerm.replaceAll('2nd', 'two')
    searchTerm = searchTerm.replaceAll('3rd', 'three')
    searchTerm = searchTerm.replaceAll('4th', 'four')
    searchTerm = searchTerm.replaceAll('5th', 'five')
    searchTerm = searchTerm.replaceAll('6th', 'six')
    searchTerm = searchTerm.replaceAll('7th', 'seven')
    searchTerm = searchTerm.replaceAll('8th', 'eight')
    searchTerm = searchTerm.replaceAll('9th', 'nine')
    searchTerm = searchTerm.replaceAll('10th', 'ten')
    searchTerm = searchTerm.replaceAll('11th', 'eleven')
    searchTerm = searchTerm.replaceAll('12th', 'twelve')
    searchTerm = searchTerm.replaceAll('1', 'one')
    searchTerm = searchTerm.replaceAll('2', 'two')
    searchTerm = searchTerm.replaceAll('3', 'three')
    searchTerm = searchTerm.replaceAll('4', 'four')
    searchTerm = searchTerm.replaceAll('5', 'five')
    searchTerm = searchTerm.replaceAll('6', 'six')
    searchTerm = searchTerm.replaceAll('7', 'seven')
    searchTerm = searchTerm.replaceAll('8', 'eight')
    searchTerm = searchTerm.replaceAll('9', 'nine')
    searchTerm = searchTerm.replaceAll('10', 'ten')
    searchTerm = searchTerm.replaceAll('11', 'eleven')
    searchTerm = searchTerm.replaceAll('12', 'twelve')
    searchTerm = searchTerm.replaceAll('first', 'one')
    searchTerm = searchTerm.replaceAll('second', 'two')
    searchTerm = searchTerm.replaceAll('third', 'three')
    searchTerm = searchTerm.replaceAll('fourth', 'four')
    searchTerm = searchTerm.replaceAll('fifth', 'five')
    searchTerm = searchTerm.replaceAll('sixth', 'six')
    searchTerm = searchTerm.replaceAll('seventh', 'seven')
    searchTerm = searchTerm.replaceAll('eighth', 'eight')
    searchTerm = searchTerm.replaceAll('ninth', 'nine')
    searchTerm = searchTerm.replaceAll('tenth', 'ten')
    searchTerm = searchTerm.replaceAll('eleventh', 'eleven')
    searchTerm = searchTerm.replaceAll('twelfth', 'twelve')
    return searchTerm
}

export const searchIconTypes = (searchTerm: string): IconType[] => {
    return iconTypeFuse.search(replaceNumbersWithText(searchTerm)).map(o => o.item)
}

