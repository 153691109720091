import { useFormikContext } from "formik"
import { RefObject, useEffect } from "react"

interface IUseFormikScrollToErrorArgs {
    field: RefObject<HTMLElement>
    name: string
}

export const useFormikScrollToError = ({ field, name }: IUseFormikScrollToErrorArgs) => {
    const formik = useFormikContext()

    useEffect(() => {
        /* 
            Scroll to this field if it's the first error
            (otherwise we'd get conflicting scroll attemps if more than one element on the page is using this hook)
        */
        if (field.current && Object.keys(formik.errors)[ 0 ] === name) {
            if (field.current instanceof HTMLInputElement) {
                field.current.focus()
            } else {
                field.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    }, [ formik.submitCount ])
}