import { Stepper, Step, StepLabel, StepContent, Typography, Button, Box, useTheme, Link, makeStyles, createStyles } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { flatten } from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { GoogleAccountFragment, GoogleCalendarFragment, GoogleCalendarSyncFragment, SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { useCreateGoogleCalendarSyncMutation, useGoogleAccountsQuery } from '../graphql/autogenerate/react-query'
import { joinStrings } from '../helpers'
import { useGroupSelector, useHandleReactQueryMutation } from '../hooks'
import { ISchoolState } from '../stores/school'
import { Callout } from './callout'
import { ISelectOption } from './forms'
import { SelectField } from './forms/select-field'
import { GcsStatus } from './gcs'
import { GroupSelector } from './group-selector'
import { LoadingButton } from './loading-button'
import { IModalControlProps, Modal } from './modal'

const useStyles = makeStyles(theme =>
    createStyles({
        syncedIndicator: {
            backgroundColor: `#00000090`,
            color: 'white',
            borderRadius: theme.shape.borderRadius,
            ...theme.typography.caption,
            padding: `0px ${theme.spacing(1)}px`
        }
    })
)

interface IGcsAddModalProps {
    modalControls: IModalControlProps
    googleAccounts: GoogleAccountFragment[]
    googleCalendarSyncs: GoogleCalendarSyncFragment[]
    divisions: SchoolDivisionsFragment[]
    groups: ISchoolState[ 'groups' ]
    refetchGoogleAccounts?: ReturnType<typeof useGoogleAccountsQuery>[ 'refetch' ]
    gcsStatus?: { status: GcsStatus, message?: string }
    setStatus: Dispatch<SetStateAction<{ status: GcsStatus, message?: string } | undefined>>
    statusMessage?: ReactNode
}

interface IGoogleCalendarOption extends GoogleCalendarFragment {
    googleAccountId: string
    synced: boolean
}

/** 
    Component for creating a GCS.
*/
export const GcsAddModal = ({ modalControls, googleAccounts, googleCalendarSyncs, divisions, groups, refetchGoogleAccounts, gcsStatus, setStatus, statusMessage }: IGcsAddModalProps) => {
    const theme = useTheme()
    const styles = useStyles()

    /* Purposefully use manual form state so that we can handle behavior moving through the <Stepper /> */
    const initialState = {
        activeStep: 0,
        googleCalendar: undefined as IGoogleCalendarOption | undefined,
        googleCalendarSelectorTouched: false,
        groupSelectorTouched: false,
    }
    const [ state, setState ] = useState(initialState)

    const googleCalendarOptions = useMemo(() => {
        const calendarOptions = googleAccounts.reduce<ISelectOption<IGoogleCalendarOption>[]>((options, account) => {
            options.push(...account.googleCalendars.map<ISelectOption<IGoogleCalendarOption>>(o => {
                if (!o || !o.id || (!o?.summary && !o?.summaryOverride)) throw new Error('Encountered a Google calendar missing ID or title.')
                return {
                    key: o.id,
                    label: `${o.summary || o.summaryOverride}`,
                    value: {
                        ...o,
                        googleAccountId: account.id,
                        synced: Boolean(googleCalendarSyncs.find(gcs => gcs.googleCalendarId === o.id))
                    },
                    group: account.email,
                }
            }))
            return options
        }, [])

        // Sort this list so the synced ones are at the bottom.
        calendarOptions.sort((a, b) => {
            if (a.value.synced && !b.value.synced) return 1
            if (!a.value.synced && b.value.synced) return -1
            return 0
        })

        return calendarOptions
    }, [ googleAccounts ])

    const groupSelector = useGroupSelector({ divisions })

    const { activeStep } = state
    const handleBack = () => setState((_state) => ({ ..._state, activeStep: _state.activeStep - 1 }))
    const handleNext = () => setState((_state) => ({ ..._state, activeStep: _state.activeStep + 1 }))

    const { mutate: createGoogleCalendarSync, isLoading } = useHandleReactQueryMutation(useCreateGoogleCalendarSyncMutation())
    const handleSubmit = () => {
        if (!state.googleCalendar) return
        createGoogleCalendarSync(
            {
                googleAccountId: state.googleCalendar.googleAccountId,
                calendarIds: flatten(groups.filter(group => groupSelector.groupSelectorState.selectedGroupIds.includes(group.id)).map(o => o.calendarIds)),
                googleCalendarId: state.googleCalendar.id,
                googleCalendarColor: state.googleCalendar.backgroundColor || '',
                googleCalendarSummary: state.googleCalendar.summaryOverride || state.googleCalendar.summary,
            },
            {
                onSettled: handleNext
            }
        )
    }

    const handleReset = () => {
        setState(initialState)
        groupSelector.clearSelectedGroups()
        if (refetchGoogleAccounts) refetchGoogleAccounts()
        if (gcsStatus?.status === 'auth_created' && googleAccounts.length === 1) setStatus(undefined)
    }

    return (
        <Modal
            {...modalControls.props}
            title='Sync Google Calendar'
            afterClose={handleReset}
            size='sm'
            noPadding
            closeButton
            dismissible
        >
            {Boolean(gcsStatus?.status && (googleAccounts.length === 1 && gcsStatus.status === 'auth_created')) &&
                <Callout
                    children={statusMessage}
                    type='success'
                    style={{ padding: `0px ${theme.spacing(3)}px` }}
                />
            }

            <Stepper activeStep={activeStep} orientation='vertical'>
                <Step>
                    <StepLabel>Select Google calendar</StepLabel>
                    <StepContent>
                        <Typography style={{ marginLeft: theme.spacing(1) }}>Select the Google calendar to sync <b>from</b>:</Typography>

                        <SelectField
                            value={googleCalendarOptions.find(o => state.googleCalendar?.id === o.value.id)?.value}
                            options={googleCalendarOptions}
                            onChange={(option) => setState(_state => ({ ..._state, googleCalendar: option?.value }))}
                            getKeyFromOptionValue={(value) => value?.id || undefined}
                            style={{ width: '100%', marginBottom: 0 }}
                            onBlur={() => setState(_state => ({ ..._state, googleCalendarSelectorTouched: true }))}
                            error={state.googleCalendarSelectorTouched && !state.googleCalendar ? 'Required' : undefined}
                            touched={state.googleCalendarSelectorTouched}
                            renderOption={(option) => (
                                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                    <div style={{ height: 10, width: 10, borderRadius: '50%', backgroundColor: option.value?.backgroundColor || 'unset', marginRight: theme.spacing(1) }} />
                                    <div style={{ flex: 1 }}>{option.value?.summaryOverride || option.value?.summary}</div>
                                    {option.value?.synced && <div className={styles.syncedIndicator}>Synced</div>}
                                </div>
                            )}
                            getOptionDisabled={(option) => Boolean(option.value?.synced)}
                        />

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                style={{ width: 100 }}
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    if (state.googleCalendar) {
                                        handleNext()
                                    } else {
                                        setState(_state => ({ ..._state, googleCalendarSelectorTouched: true }))
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Select School calendar(s)</StepLabel>
                    <StepContent>
                        <Typography style={{ marginLeft: theme.spacing(1) }}>Select the school calendar(s) to sync <b>to</b>:</Typography>
                        <GroupSelector
                            groupSelector={groupSelector}
                            searchPlaceholder='Search calendars...'
                            error={state.groupSelectorTouched && groupSelector.groupSelectorState.selectedGroupIds.length === 0 ? 'Select at least one calendar.' : undefined}
                            touched={state.groupSelectorTouched}
                            onBlur={() => setState(_state => ({ ..._state, groupSelectorTouched: true }))}
                        />
                        <div style={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ width: 100, marginRight: theme.spacing(1) }} onClick={handleBack}>Back</Button>
                            <Button
                                style={{ width: 100 }}
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    if (groupSelector.selectedGroupOptions.length > 0) {
                                        handleNext()
                                    } else {
                                        setState(_state => ({ ..._state, groupSelectorTouched: true }))
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Confirm sync</StepLabel>
                    <StepContent>
                        <Typography>Sync <b>{state.googleCalendar?.summaryOverride || state.googleCalendar?.summary}</b> to the <b>{joinStrings(groupSelector.selectedGroupOptions.map(o => o.title))}</b> calendar{groupSelector.selectedGroupOptions.length === 1 ? '' : 's'}?</Typography>

                        <ul style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }}>
                            <li><Typography>Existing events from Google calendar you selected (<b>{state.googleCalendar?.summaryOverride || state.googleCalendar?.summary}</b>) will be added to the selected school calendar{groupSelector.selectedGroupOptions.length === 1 ? '' : 's'}.</Typography></li>
                            <li><Typography>When you edit, add, or delete events on <b>{state.googleCalendar?.summaryOverride || state.googleCalendar?.summary}</b> those changes will be synced to the school calendar{groupSelector.selectedGroupOptions.length === 1 ? '' : 's'}.</Typography></li>
                        </ul>


                        <div style={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ width: 100, marginRight: theme.spacing(1) }} onClick={handleBack}>Back</Button>
                            <LoadingButton style={{ width: 100 }} variant='contained' color='primary' onClick={handleSubmit} loading={isLoading}>Sync</LoadingButton>
                        </div>
                    </StepContent>
                </Step>
            </Stepper>
            {activeStep === 3 &&
                <Box mx={3} mb={3} display='flex'>
                    <Check style={{ color: theme.palette.success.main, marginRight: theme.spacing(1) }} />
                    <Typography>Sync started! <Link style={{ cursor: 'pointer' }} onClick={modalControls.close}>View sync status.</Link></Typography>
                </Box>
            }
        </Modal>
    )
}