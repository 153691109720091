import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { Dayjs } from 'dayjs'
import { useField } from 'formik'
import React from 'react'
import { CommonProps } from '../interfaces'
import { IField } from './'
import clsx from 'clsx'

export interface IFormikDatePickerProps extends CommonProps {
    fieldProps: IField
    onOpened?: () => void
    color?: string | null
    disabledKeyboard?: boolean
    style?: React.CSSProperties
    disablePast?: boolean
}
export const FormikDatePicker = ({ fieldProps, disabledKeyboard, style, disablePast }: IFormikDatePickerProps) => {
    const [ field, meta, helpers ] = useField<Dayjs | null | undefined>(fieldProps)
    const { touched, error, initialError } = meta


    if (disabledKeyboard) {
        return (
            <DatePicker
                {...field}
                style={{ width: 115, ...style }}
                onChange={(date) => {
                    if (date?.isValid()) helpers.setValue(date.startOf('d'))
                }}
                className={clsx(!fieldProps.label && 'compactInput')}
                error={(touched && !!error) || !!initialError}
                helperText={(touched && error) || fieldProps.helperText}
                autoOk
                variant='inline'
                inputVariant='filled'
                label={fieldProps.label}
                hiddenLabel={!fieldProps.label}
                placeholder={fieldProps.placeholder}
                disabled={fieldProps.disabled}
                format='MM/DD/YYYY'
                animateYearScrolling
                disablePast={disablePast}
            />
        )
    } else {
        return (
            <KeyboardDatePicker
                {...field}
                style={style}
                onChange={(date) => {
                    if (date?.isValid()) helpers.setValue(date.startOf('d'))
                }}
                className={clsx(!fieldProps.label && 'compactInput')}
                error={(touched && !!error) || !!initialError}
                helperText={(touched && error) || fieldProps.helperText}
                autoOk
                variant='inline'
                inputVariant='filled'
                label={fieldProps.label}
                hiddenLabel={!fieldProps.label}
                placeholder={fieldProps.placeholder}
                format='MM/DD/YYYY'
                InputAdornmentProps={{ position: "start" }}
                disablePast={disablePast}
                disabled={fieldProps.disabled}
            />
        )
    }

}