import { createStyles, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import React from 'react'
import { CalendarActionType, ICalendarState } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

const useStyles = makeStyles(() =>
    createStyles({
        datepickerOverride: {
            '&> div': {
                backgroundColor: 'transparent'
            }
        }
    })
)

interface ICalendarSidebarDatePickerProps extends ICalendarDispatchProp, Pick<ICalendarState, 'selectedDate'> { }

const CalendarSidebarDatePicker = ({ dispatch, selectedDate }: ICalendarSidebarDatePickerProps) => {
    const styles = useStyles()

    return (
        <div
            className={styles.datepickerOverride}
        >
            <DatePicker
                autoOk
                orientation='landscape'
                variant='static'
                openTo='date'
                value={selectedDate}
                onChange={(ev) => ev && dispatch({ type: CalendarActionType.goToDate, payload: ev })}
                disableToolbar
                allowKeyboardControl={false}
            />
        </div>
    )
}

export const MemoizedCalendarSidebarDatePicker = React.memo(CalendarSidebarDatePicker)