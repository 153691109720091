import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Typography,
	useTheme,
} from '@material-ui/core'
import { ExpandMore, LockOutlined } from '@material-ui/icons'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import * as yup from 'yup'
import {
	useCancelScheduleSettingsResetMutation,
	useScheduleSettingsResetMutation,
	useSchoolAppQuery,
	useToggleSchoolArchivedMutation,
	useUpdateCreativeSchoolSettingsMutation,
	useUpdateGeneralSchoolSettingsMutation,
	useUpdateLaunchScreenSchoolSettingsMutation,
	useUpdateOneSignalSchoolSettingsMutation,
	useUpdateSchoolSettingUserAccessMutation,
	useUpdateSponsorSchoolSettingsMutation,
} from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import {
	useAppState,
	useHandleReactQuery,
	useHandleReactQueryMutation,
} from '../hooks'
import { useSchoolContext } from '../stores/school'
import { Callout } from './callout'
import {
	FileFieldValue,
	FormikDatePicker,
	FormikImageField,
	FormikSwitchField,
	FormikTextInput,
	useImageFieldChangeHandler,
} from './forms'
import { FormikNumberField } from './forms/formik-number-field'
import { Tooltip } from './tooltip'

export const AdminSchoolSettings = () => {
	const theme = useTheme()
	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: {
			school: {
				schoolSetting,
				id: schoolId,
				archived,
				androidAppDownloadUrl,
				iosAppDownloadUrl,
				appleDeveloperAccountExpiry,
			},
		},
		actions: {
			refetch: { schoolDetail: refetchSchoolDetails },
		},
	} = useSchoolContext()
	const { data } = useHandleReactQuery(useSchoolAppQuery({ schoolId }))
	const app = data?.apps?.nodes[0]

	const [state, setState] = useState({
		pushNotificationsOpen: false,
		generalOpen: false,
		sponsorSettingsOpen: false,
		launchScreenSettingsOpen: false,
		creativeOpen: false,
		passcodesOpen: false,
	})

	const { enqueueSnackbar } = useSnackbar()

	const { mutate: updateOneSignalSettings } = useHandleReactQueryMutation(
		useUpdateOneSignalSchoolSettingsMutation({
			onSuccess: () => {
				enqueueSnackbar('Push notification settings updated', {
					variant: 'success',
				})
				refetchSchoolDetails()
			},
		})
	)
	const { mutate: updateGeneralSettings } = useHandleReactQueryMutation(
		useUpdateGeneralSchoolSettingsMutation({
			onSuccess: () => {
				enqueueSnackbar('General settings updated', { variant: 'success' })
				refetchSchoolDetails()
			},
		})
	)
	const { mutate: toggleSchoolArchived } = useHandleReactQueryMutation(
		useToggleSchoolArchivedMutation()
	)
	const { mutate: updateSponsorSettings } = useHandleReactQueryMutation(
		useUpdateSponsorSchoolSettingsMutation({
			onSuccess: () => {
				enqueueSnackbar('Sponsor settings updated', { variant: 'success' })
				refetchSchoolDetails()
			},
		})
	)
	const { mutate: updateLaunchScreenSettings } = useHandleReactQueryMutation(
		useUpdateLaunchScreenSchoolSettingsMutation({
			onSuccess: () => {
				enqueueSnackbar('Launch screen settings updated', {
					variant: 'success',
				})
				refetchSchoolDetails()
			},
		})
	)
	const { mutate: updateCreativeSettings } = useHandleReactQueryMutation(
		useUpdateCreativeSchoolSettingsMutation({
			onSuccess: () => {
				enqueueSnackbar('Creative settings updated', { variant: 'success' })
				refetchSchoolDetails()
			},
		})
	)
	const { mutateAsync: updateUserAccessSettings } = useHandleReactQueryMutation(
		useUpdateSchoolSettingUserAccessMutation({
			onSuccess: () => {
				enqueueSnackbar('User access settings updated', { variant: 'success' })
				refetchSchoolDetails()
			},
		})
	)

	const { mutateAsync: scheduleSettingsReset } = useHandleReactQueryMutation(
		useScheduleSettingsResetMutation()
	)
	const { mutateAsync: cancelScheduleSettingsReset } =
		useHandleReactQueryMutation(useCancelScheduleSettingsResetMutation())

	const handleSettingsResetValues = useCallback(
		async ({
			userType,
			previousValue,
			newValue,
		}: {
			userType: UserType
			previousValue?: string | null
			newValue: Dayjs | null
		}) => {
			if (!previousValue && newValue)
				await scheduleSettingsReset({
					scheduleDate: newValue.toISOString(),
					schoolId,
					userType,
				})
			if (previousValue && newValue && !dayjs(previousValue).isSame(newValue)) {
				await cancelScheduleSettingsReset({ schoolId, userType })
				await scheduleSettingsReset({
					scheduleDate: newValue.toISOString(),
					schoolId,
					userType,
				})
			}
		},
		[]
	)

	const fileChangeHandler = useImageFieldChangeHandler()

	if (!schoolSetting) return null

	const appleDeveloperAccountIsExpired = Boolean(
		appleDeveloperAccountExpiry &&
			dayjs(appleDeveloperAccountExpiry).isBefore(dayjs(), 'd')
	)
	const appStoreButton = (
		<Button
			href={iosAppDownloadUrl || ''}
			target='_blank'
			style={{ marginRight: theme.spacing(1) }}
			variant='contained'
		>
			View App Store Listing {appleDeveloperAccountIsExpired ? '(expired)' : ''}
		</Button>
	)

	return (
		<div
			style={{
				margin: theme.spacing(2),
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
				overflowY: 'auto',
			}}
		>
			<div style={{ marginBottom: theme.spacing(2) }}>
				{iosAppDownloadUrl &&
					(appleDeveloperAccountIsExpired ? (
						<Tooltip title='Apple developer account is expired'>
							{appStoreButton}
						</Tooltip>
					) : (
						appStoreButton
					))}
				{androidAppDownloadUrl && (
					<Button
						href={androidAppDownloadUrl}
						target='_blank'
						variant='contained'
					>
						View Google Play Listing
					</Button>
				)}
			</div>
			{app && currentUser?.appAdministrator && (
				<Formik
					initialValues={{
						onesignalAppId: app.onesignalAppId || '',
						onesignalAppAuthToken: app.onesignalAppAuthToken || '',
					}}
					onSubmit={values =>
						updateOneSignalSettings({ ...values, appId: app.id })
					}
				>
					{formikProps => (
						<Accordion
							expanded={state.pushNotificationsOpen}
							onChange={(_, expanded) =>
								setState(_state => ({
									..._state,
									pushNotificationsOpen: expanded,
								}))
							}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant='h5'>📲 Push Notifications</Typography>
							</AccordionSummary>
							<AccordionDetails
								style={{
									padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
								}}
							>
								<Form style={{ flex: 1 }}>
									<div style={{ display: 'flex' }}>
										<FormikTextInput
											hideHelperTextWhenEmpty
											fieldProps={{ name: 'onesignalAppId', label: 'App ID' }}
										/>
										<FormikTextInput
											hideHelperTextWhenEmpty
											fieldProps={{
												name: 'onesignalAppAuthToken',
												label: 'Key',
											}}
										/>
									</div>
								</Form>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button
									size='small'
									onClick={() =>
										setState(_state => ({
											..._state,
											pushNotificationsOpen: false,
										}))
									}
								>
									Cancel
								</Button>
								<Button
									onClick={formikProps.submitForm}
									type='submit'
									size='small'
									color='primary'
								>
									Save
								</Button>
							</AccordionActions>
						</Accordion>
					)}
				</Formik>
			)}

			{currentUser?.appAdministrator && (
				<Formik
					initialValues={{
						downloadUrl: schoolSetting.downloadUrl || '',
						feedbackUrl: schoolSetting.feedbackUrl || '',
						googleAnalyticsId: schoolSetting.googleAnalyticsId || '',
						archived,
					}}
					validationSchema={yup.object({
						downloadUrl: yup.string().url('Must be a valid URL'),
						feedbackUrl: yup.string().url('Must be a valid URL'),
					})}
					onSubmit={values => {
						updateGeneralSettings({ ...values, schoolId })
						toggleSchoolArchived({ ...values, schoolId })
					}}
				>
					{formikProps => (
						<Accordion
							expanded={state.generalOpen}
							onChange={(_, expanded) =>
								setState(_state => ({ ..._state, generalOpen: expanded }))
							}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant='h5'>⚙ General</Typography>
							</AccordionSummary>
							<AccordionDetails
								style={{
									padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
								}}
							>
								<Form style={{ flex: 1 }}>
									<div style={{ display: 'flex' }}>
										<FormikTextInput
											fieldProps={{
												name: 'downloadUrl',
												label: 'Download URL',
											}}
										/>
										<FormikTextInput
											fieldProps={{
												name: 'feedbackUrl',
												label: 'Feedback URL',
											}}
										/>
									</div>
									<FormikTextInput
										fieldProps={{
											name: 'googleAnalyticsId',
											label: 'Google Analytics ID',
										}}
									/>
									<FormikSwitchField
										field={{ name: 'archived', label: 'Archived' }}
									/>
								</Form>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button
									size='small'
									onClick={() =>
										setState(_state => ({ ..._state, generalOpen: false }))
									}
								>
									Cancel
								</Button>
								<Button
									onClick={formikProps.submitForm}
									type='submit'
									size='small'
									color='primary'
								>
									Save
								</Button>
							</AccordionActions>
						</Accordion>
					)}
				</Formik>
			)}

			<Formik
				initialValues={{
					sponsored: Boolean(schoolSetting.sponsored),
					sponsorsIncludeSilversOnHome: Boolean(
						schoolSetting.sponsorsIncludeSilversOnHome
					),
					goldSponsorLabel: schoolSetting.goldSponsorLabel,
					silverSponsorLabel: schoolSetting.silverSponsorLabel,
					bronzeSponsorLabel: schoolSetting.bronzeSponsorLabel,
				}}
				onSubmit={values => updateSponsorSettings({ ...values, schoolId })}
			>
				{formikProps => (
					<Accordion
						expanded={state.sponsorSettingsOpen}
						onChange={(_, expanded) =>
							setState(_state => ({ ..._state, sponsorSettingsOpen: expanded }))
						}
					>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant='h5'>💸 Sponsor Settings</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
						>
							<Form style={{ flex: 1 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: theme.spacing(1),
									}}
								>
									<FormikSwitchField
										style={{ marginRight: theme.spacing(2) }}
										field={{ name: 'sponsored', label: 'Sponsored' }}
									/>
									{formikProps.values.sponsored && (
										<FormikSwitchField
											field={{
												name: 'sponsorsIncludeSilversOnHome',
												label: 'Include silver sponsors on the home screen',
											}}
										/>
									)}
								</div>

								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: theme.spacing(1),
									}}
								>
									<FormikTextInput
										fieldProps={{
											name: 'goldSponsorLabel',
											label: 'Gold Label',
										}}
									/>
									<FormikTextInput
										fieldProps={{
											name: 'silverSponsorLabel',
											label: 'Silver Label',
										}}
									/>
									<FormikTextInput
										fieldProps={{
											name: 'bronzeSponsorLabel',
											label: 'Bronze Label',
										}}
									/>
								</div>
							</Form>
						</AccordionDetails>
						<Divider />
						<AccordionActions>
							<Button
								size='small'
								onClick={() =>
									setState(_state => ({
										..._state,
										sponsorSettingsOpen: false,
									}))
								}
							>
								Cancel
							</Button>
							<Button
								onClick={formikProps.submitForm}
								type='submit'
								size='small'
								color='primary'
							>
								Save
							</Button>
						</AccordionActions>
					</Accordion>
				)}
			</Formik>

			<Formik
				initialValues={{
					launchScreenEnabled: Boolean(schoolSetting.launchScreenEnabled),
					launchScreenPoweredByLabel:
						schoolSetting.launchScreenPoweredByLabel || '',
					launchScreenPoweredByImage:
						schoolSetting.launchScreenPoweredByImage as FileFieldValue,
					logoImage: schoolSetting.logoImage as FileFieldValue,
					schoolImage: schoolSetting.schoolImage as FileFieldValue,
				}}
				onSubmit={async ({
					launchScreenPoweredByImage,
					logoImage,
					schoolImage,
					...values
				}) => {
					updateLaunchScreenSettings({
						...values,
						schoolId,
						...(await fileChangeHandler({
							oldValue: schoolSetting.launchScreenPoweredByImage,
							newValue: launchScreenPoweredByImage,
							filePropertyName: 'launchScreenPoweredByImage',
						})),
						...(await fileChangeHandler({
							oldValue: schoolSetting.logoImage,
							newValue: logoImage,
							filePropertyName: 'logoImage',
						})),
						...(await fileChangeHandler({
							oldValue: schoolSetting.schoolImage,
							newValue: schoolImage,
							filePropertyName: 'schoolImage',
						})),
					})
				}}
			>
				{formikProps => (
					<Accordion
						expanded={state.launchScreenSettingsOpen}
						onChange={(_, expanded) =>
							setState(_state => ({
								..._state,
								launchScreenSettingsOpen: expanded,
							}))
						}
					>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant='h5'>🚀 Launch Screen</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
						>
							<Form style={{ flex: 1, minWidth: 0 }}>
								<FormikSwitchField
									style={{ marginBottom: theme.spacing(2) }}
									field={{ name: 'launchScreenEnabled', label: 'Enabled' }}
								/>
								{formikProps.values.launchScreenEnabled && (
									<div>
										<FormikTextInput
											fieldProps={{
												name: 'launchScreenPoweredByLabel',
												label: '"Powered by" label',
											}}
										/>
										<FormikImageField
											style={{ margin: theme.spacing(0.5), flex: 1 }}
											aspectRatioHelper='Recommended dimensions: 500px by 500px or 900px by 300px'
											field={{
												name: 'launchScreenPoweredByImage',
												label: '"Powered by" Image',
											}}
										/>
									</div>
								)}

								<div style={{ display: 'flex' }}>
									<FormikImageField
										style={{
											flex: 1,
											marginRight: theme.spacing(1),
											marginLeft: theme.spacing(1),
										}}
										field={{ name: 'logoImage', label: 'Logo' }}
										aspectRatio={1}
										aspectRatioHelper='1:1 aspect ratio (e.g. 500px by 500px)'
									/>
									<FormikImageField
										style={{ flex: 1, marginLeft: theme.spacing(1) }}
										field={{ name: 'schoolImage', label: 'School' }}
										imageCropLimitToImage={false}
										aspectRatioHelper='Recommended dimensions: 3000px by 1000px'
									/>
								</div>
							</Form>
						</AccordionDetails>
						<Divider />
						<AccordionActions>
							<Button
								size='small'
								onClick={() =>
									setState(_state => ({
										..._state,
										launchScreenSettingsOpen: false,
									}))
								}
							>
								Cancel
							</Button>
							<Button
								onClick={formikProps.submitForm}
								type='submit'
								size='small'
								color='primary'
							>
								Save
							</Button>
						</AccordionActions>
					</Accordion>
				)}
			</Formik>

			<Formik
				initialValues={{
					thumbnailImage: schoolSetting.thumbnailImage as FileFieldValue,
					resourceTileOpacity: schoolSetting.resourceTileOpacity,
				}}
				validationSchema={yup.object({
					resourceTileOpacity: yup
						.number()
						.required('Required')
						.min(0, 'Must be greater than or equal to 0.')
						.max(99, 'Must be less than 100.'),
				})}
				onSubmit={async values => {
					updateCreativeSettings({
						schoolId,
						...(await fileChangeHandler({
							oldValue: schoolSetting.thumbnailImage,
							newValue: values.thumbnailImage,
							filePropertyName: 'thumbnailImage',
						})),
						resourceTileOpacity: values.resourceTileOpacity,
					})
				}}
			>
				{formikProps => (
					<Accordion
						expanded={state.creativeOpen}
						onChange={(_, expanded) =>
							setState(_state => ({ ..._state, creativeOpen: expanded }))
						}
					>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant='h5'>📷 Creative</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
						>
							<Form style={{ flex: 1, minWidth: 0 }}>
								<div style={{ display: 'flex' }}>
									<FormikImageField
										style={{ flex: 1, marginRight: theme.spacing(1) }}
										field={{ name: 'thumbnailImage', label: 'Icon' }}
										aspectRatio={1}
										aspectRatioHelper='1:1 aspect ratio (e.g. 500px by 500px)'
									/>
								</div>

								<div style={{ marginTop: theme.spacing(1) }}>
									<FormikNumberField
										field={{
											name: 'resourceTileOpacity',
											label: 'Resource Tile Opacity',
										}}
									/>
								</div>
							</Form>
						</AccordionDetails>
						<Divider />
						<AccordionActions>
							<Button
								size='small'
								onClick={() =>
									setState(_state => ({ ..._state, creativeOpen: false }))
								}
							>
								Cancel
							</Button>
							<Button
								onClick={formikProps.submitForm}
								type='submit'
								size='small'
								color='primary'
							>
								Save
							</Button>
						</AccordionActions>
					</Accordion>
				)}
			</Formik>

			<Formik
				initialValues={{
					parentPasscodeEnabled: Boolean(schoolSetting.parentPasscode),
					parentPasscode: schoolSetting.parentPasscode,
					parentPasscodeHint: schoolSetting.parentPasscodeHint,
					studentPasscodeEnabled: Boolean(schoolSetting.studentPasscode),
					studentPasscode: schoolSetting.studentPasscode,
					studentPasscodeHint: schoolSetting.studentPasscodeHint,
					enableGuestAccess: schoolSetting.enableGuestAccess,
					enableParentFamilyAccess: schoolSetting.enableParentFamilyAccess,
					enableStudentAccess: schoolSetting.enableStudentAccess,
					scheduledFacultyReset: schoolSetting.scheduledFacultyReset
						? dayjs(schoolSetting.scheduledFacultyReset)
						: null,
					scheduledGuestReset: schoolSetting.scheduledGuestReset
						? dayjs(schoolSetting.scheduledGuestReset)
						: null,
					scheduledParentReset: schoolSetting.scheduledParentReset
						? dayjs(schoolSetting.scheduledParentReset)
						: null,
					scheduledStudentReset: schoolSetting.scheduledStudentReset
						? dayjs(schoolSetting.scheduledStudentReset)
						: null,
				}}
				validationSchema={yup.object({
					parentPasscode: yup
						.string()
						.nullable()
						.test('parentPasscode', 'Required', function (value) {
							if (this.parent.parentPasscodeEnabled) return Boolean(value)
							return true
						}),
					studentPasscode: yup
						.string()
						.nullable()
						.test('studentPasscode', 'Required', function (value) {
							if (this.parent.studentPasscodeEnabled) return Boolean(value)
							return true
						}),
				})}
				onSubmit={async ({
					scheduledFacultyReset,
					scheduledGuestReset,
					scheduledParentReset,
					scheduledStudentReset,
					...values
				}) => {
					await updateUserAccessSettings({
						schoolId,
						...values,
						parentPasscode: values.parentPasscodeEnabled
							? values.parentPasscode
							: null,
						parentPasscodeHint: values.parentPasscodeEnabled
							? values.parentPasscodeHint
							: null,
						studentPasscode: values.studentPasscodeEnabled
							? values.studentPasscode
							: null,
						studentPasscodeHint: values.studentPasscodeEnabled
							? values.studentPasscodeHint
							: null,
					})

					await handleSettingsResetValues({
						userType: UserType.Parent,
						previousValue: schoolSetting.scheduledParentReset,
						newValue: scheduledParentReset,
					})
					await handleSettingsResetValues({
						userType: UserType.FacultyStaff,
						previousValue: schoolSetting.scheduledFacultyReset,
						newValue: scheduledFacultyReset,
					})
					await handleSettingsResetValues({
						userType: UserType.Student,
						previousValue: schoolSetting.scheduledStudentReset,
						newValue: scheduledStudentReset,
					})
					await handleSettingsResetValues({
						userType: UserType.Guest,
						previousValue: schoolSetting.scheduledGuestReset,
						newValue: scheduledGuestReset,
					})

					await refetchSchoolDetails()
				}}
			>
				{formikProps => (
					<Accordion
						expanded={state.passcodesOpen}
						onChange={(_, expanded) =>
							setState(_state => ({ ..._state, passcodesOpen: expanded }))
						}
					>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant='h5'>🧑‍🤝‍🧑 User Type Settings</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{
								padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							{/* Show a warning if the only enabled user types require a passcode. DON'T show this warning if all user types are disabled. */}
							{Boolean(
								!formikProps.values.enableParentFamilyAccess &&
									!formikProps.values.enableStudentAccess &&
									!formikProps.values.enableGuestAccess
							) ? (
								<Callout
									type='error'
									style={{ display: 'flex', marginBottom: theme.spacing(1) }}
								>
									WARNING: you have disabled all user types. Only Faculty/Staff
									users will be able to access your app.
								</Callout>
							) : (
								!formikProps.values.enableGuestAccess &&
								(!formikProps.values.enableParentFamilyAccess ||
									formikProps.values.parentPasscodeEnabled) &&
								(!formikProps.values.enableStudentAccess ||
									formikProps.values.studentPasscodeEnabled) &&
								!Boolean(
									!formikProps.values.enableParentFamilyAccess &&
										!formikProps.values.enableStudentAccess &&
										!formikProps.values.enableGuestAccess
								) && (
									<Callout
										type='warning'
										style={{ marginBottom: theme.spacing(2), display: 'flex' }}
									>
										<LockOutlined style={{ marginRight: theme.spacing(1) }} />{' '}
										With your current settings no user will be able to join the
										app without a passcode.
									</Callout>
								)
							)}
							<Form style={{ flex: 1 }}>
								<h3>Enabled User Types</h3>
								<div
									style={{ display: 'flex', marginBottom: theme.spacing(2) }}
								>
									<FormikSwitchField
										style={{ marginRight: theme.spacing(3) }}
										field={{
											name: 'enableParentFamilyAccess',
											label: 'Parent/Family',
										}}
										tooltip='Allow parent/family users to join the app.'
									/>
									<FormikSwitchField
										style={{ marginRight: theme.spacing(3) }}
										field={{ name: 'enableStudentAccess', label: 'Student' }}
										tooltip='Allow student users to join the app.'
									/>
									<FormikSwitchField
										style={{ marginRight: theme.spacing(3) }}
										field={{ name: 'enableGuestAccess', label: 'Guest' }}
										tooltip='Allow guest users to join the app.'
									/>
								</div>

								<h3>User Type Passcodes</h3>
								<FormikSwitchField
									style={{ marginBottom: theme.spacing(2) }}
									field={{
										name: 'parentPasscodeEnabled',
										label: 'Require passcode for Parents',
										disabled: !formikProps.values.enableParentFamilyAccess,
									}}
									tooltip='Enable to require a passcode when someone tries to join the app as a parent. Note: the passcode is case sensitive.'
								/>
								{formikProps.values.parentPasscodeEnabled && (
									<div style={{ display: 'flex', maxWidth: 500 }}>
										<FormikTextInput
											fieldProps={{
												name: 'parentPasscode',
												label: 'Passcode',
												disabled: !formikProps.values.enableParentFamilyAccess,
											}}
										/>
										<FormikTextInput
											fieldProps={{
												name: 'parentPasscodeHint',
												label: 'Passcode Hint (optional)',
												disabled: !formikProps.values.enableParentFamilyAccess,
											}}
										/>
									</div>
								)}

								<FormikSwitchField
									style={{ marginBottom: theme.spacing(2) }}
									field={{
										name: 'studentPasscodeEnabled',
										label: 'Require passcode for Students',
										disabled: !formikProps.values.enableStudentAccess,
									}}
									tooltip='Enable to require a passcode when someone tries to join the app as a student. Note: the passcode is case sensitive.'
								/>
								{formikProps.values.studentPasscodeEnabled && (
									<div style={{ display: 'flex', maxWidth: 500 }}>
										<FormikTextInput
											fieldProps={{
												name: 'studentPasscode',
												label: 'Passcode',
												disabled: !formikProps.values.enableStudentAccess,
											}}
										/>
										<FormikTextInput
											fieldProps={{
												name: 'studentPasscodeHint',
												label: 'Passcode Hint (optional)',
												disabled: !formikProps.values.enableStudentAccess,
											}}
										/>
									</div>
								)}

								<h3>Require Onboading</h3>
								<Callout
									type='info'
									style={{ display: 'flex', marginBottom: theme.spacing(1) }}
								>
									Choose dates to (optionally) require users of each type to
									re-do their app onboarding the next time they open the app.
								</Callout>

								<div
									style={{ display: 'inline-flex', flexDirection: 'column' }}
								>
									<FormikDatePicker
										fieldProps={{
											name: 'scheduledParentReset',
											label: 'Parent/Family',
											disabled: !formikProps.values.enableParentFamilyAccess,
										}}
									/>
									{schoolSetting.scheduledParentReset && (
										<Button
											onClick={() => {
												cancelScheduleSettingsReset({
													schoolId,
													userType: UserType.Parent,
												})
												formikProps.setFieldValue('scheduledParentReset', null)
											}}
											variant='text'
										>
											Cancel
										</Button>
									)}
								</div>

								<div
									style={{
										display: 'inline-flex',
										flexDirection: 'column',
										marginLeft: theme.spacing(2),
									}}
								>
									<FormikDatePicker
										fieldProps={{
											name: 'scheduledStudentReset',
											label: 'Student',
											disabled: !formikProps.values.enableStudentAccess,
										}}
									/>
									{schoolSetting.scheduledStudentReset && (
										<Button
											onClick={() => {
												cancelScheduleSettingsReset({
													schoolId,
													userType: UserType.Student,
												})
												formikProps.setFieldValue('scheduledStudentReset', null)
											}}
											variant='text'
										>
											Cancel
										</Button>
									)}
								</div>

								<div
									style={{
										display: 'inline-flex',
										flexDirection: 'column',
										marginLeft: theme.spacing(2),
									}}
								>
									<FormikDatePicker
										fieldProps={{
											name: 'scheduledGuestReset',
											label: 'Guest',
											disabled: !formikProps.values.enableGuestAccess,
										}}
									/>
									{schoolSetting.scheduledGuestReset && (
										<Button
											onClick={() => {
												cancelScheduleSettingsReset({
													schoolId,
													userType: UserType.Guest,
												})
												formikProps.setFieldValue('scheduledGuestReset', null)
											}}
											variant='text'
										>
											Cancel
										</Button>
									)}
								</div>

								<div
									style={{
										display: 'inline-flex',
										flexDirection: 'column',
										marginLeft: theme.spacing(2),
									}}
								>
									<FormikDatePicker
										fieldProps={{
											name: 'scheduledFacultyReset',
											label: 'Faculty/Staff',
										}}
									/>
									{schoolSetting.scheduledFacultyReset && (
										<Button
											onClick={() => {
												cancelScheduleSettingsReset({
													schoolId,
													userType: UserType.FacultyStaff,
												})
												formikProps.setFieldValue('scheduledFacultyReset', null)
											}}
											variant='text'
										>
											Cancel
										</Button>
									)}
								</div>
							</Form>
						</AccordionDetails>
						<Divider />
						<AccordionActions>
							<Button
								size='small'
								onClick={() =>
									setState(_state => ({
										..._state,
										launchScreenSettingsOpen: false,
									}))
								}
							>
								Cancel
							</Button>
							<Button
								onClick={formikProps.submitForm}
								type='submit'
								size='small'
								color='primary'
							>
								Save
							</Button>
						</AccordionActions>
					</Accordion>
				)}
			</Formik>
		</div>
	)
}
