import { useBackdropStyles } from "../styles"
import { Fade, Backdrop } from '@material-ui/core'
import clsx from 'clsx'
import { ILoadingProps, Loading } from './loading'

interface ILoadingOverlayProps extends ILoadingProps {
    delayMs?: number
}
export const LoadingOverlay = ({ size, delayMs }: ILoadingOverlayProps) => {
    const classes = useBackdropStyles()

    return (
        <Fade in style={{ transitionDelay: `${delayMs !== undefined ? delayMs : '800'}ms` }} unmountOnExit>
            <Backdrop className={clsx(classes.backdrop, classes.transparentBackground)} open>
                <Loading size={size} />
            </Backdrop>
        </Fade>
    )
}