import { IconButton } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import React from 'react'
import { CalendarActionType, SIDEBAR_WIDTH } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

const CalendarHeaderSidenavToggle = ({ dispatch }: ICalendarDispatchProp) => {
    return (
        <div style={{ width: SIDEBAR_WIDTH }}>
            <IconButton aria-label='Main drawer' onClick={() => dispatch({ type: CalendarActionType.toggleSidebar })}>
                <Menu />
            </IconButton>
        </div>
    )
}

export const MemoizedCalendarHeaderSidenavToggle = React.memo(CalendarHeaderSidenavToggle)