import { ThemeProvider as ThemeProviderOC } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { FunctionComponent } from 'react'

const theme = createMuiTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            tablet: 768,
            md: 960,
            lg: 1270,
            xl: 1920,
        }
    },
    props: {
        MuiTypography: {
            variantMapping: {
                h1: 'h2',
                h2: 'h2',
                h3: 'h2',
                h4: 'h2',
                h5: 'h2',
                h6: 'h2',
                subtitle1: 'h2',
                subtitle2: 'h2',
                body1: 'div',
                body2: 'span',
            }
        },
    },
    overrides: {
        MuiTooltip: {
            tooltipPlacementTop: {
                marginBottom: 5,
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: 'transparent',
                padding: 5,
            },
            dayLabel: {
                margin: 0
            },
            daysHeader: {
                height: 36,
                maxHeight: 'unset',
            },
            switchHeader: {
                marginBottom: 0
            }
        },
        MuiPickersDay: {
            day: {
                margin: 0
            }
        },
        // @ts-expect-error
        MuiPickersBasePicker: {
            pickerView: {
                minWidth: 'unset',
                minHeight: 290,
                overflowX: 'unset',
            },
            pickerViewLandscape: {
                padding: 0,
            },
            container: {
                justifyContent: 'center'
            }
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                minWidth: 'unset',
                overflow: 'unset',
            }
        },
        MuiPickersCalendar: {
            transitionContainer: {
                marginTop: 0,
                minHeight: 'unset',
                flex: 1,
            }
        },
    }
})



export const ThemeProvider: FunctionComponent = ({ children }) => {
    return (
        <ThemeProviderOC theme={theme}>
            {children}
        </ThemeProviderOC>
    )
}