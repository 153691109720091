import {
	Button,
	Checkbox,
	createStyles,
	FormControlLabel,
	makeStyles,
	Typography,
	useTheme,
} from '@material-ui/core'
import { GoogleCalendarSyncFragment } from '../graphql/autogenerate/operations'
import { joinStrings } from '../helpers'
import clsx from 'clsx'
import { GoogleCalendarSyncStatus } from '../graphql/autogenerate/schemas'
import React, { SetStateAction, useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { Delete } from '@material-ui/icons'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { useButtonStyles, useCommonStyles } from '../styles'
import { MemoizedSpringHeight } from './spring-height'
import { Dispatch } from 'react'
import { useHandleReactQueryMutation } from '../hooks'
import { LoadingButton } from './loading-button'
import {
	useDeleteGcsMutation,
	useGoogleAccountsQuery,
} from '../graphql/autogenerate/react-query'
import { Tooltip } from './tooltip'

const useStyles = makeStyles(theme =>
	createStyles({
		container: {
			display: 'grid',
			gridTemplateColumns: 'auto 1fr auto auto auto',
			gridTemplateRows: 'auto',
			padding: theme.spacing(2),
		},
		rowContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		emptyLastSynced: {
			color: theme.palette.grey[300],
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		spacing: {
			marginRight: theme.spacing(2),
		},
		calendarDot: {
			marginRight: theme.spacing(1),
			backgroundColor: theme.palette.grey[300],
		},
	})
)

interface IGcsToDelete {
	id: string
	deleteEvents: boolean
}

interface IGcsListProps {
	googleCalendarSyncs: GoogleCalendarSyncFragment[]
	refetchGoogleAccounts: ReturnType<typeof useGoogleAccountsQuery>['refetch']
}
export const GcsList = ({
	googleCalendarSyncs,
	refetchGoogleAccounts,
}: IGcsListProps) => {
	const styles = useStyles()

	const [gcsToDelete, setGcsToDelete] = useState<IGcsToDelete>()

	return (
		<div className={clsx(styles.container, 'gcs-list')}>
			<Typography className={styles.spacing}>
				<b>Google Calendar</b>
			</Typography>
			<Typography className={styles.spacing}>
				<b>Synced To</b>
			</Typography>
			<Typography className={styles.spacing}>
				<b>Status</b>
			</Typography>
			<Typography className={styles.spacing}>
				<b>Last Synced</b>
			</Typography>
			<div />
			{googleCalendarSyncs.map(gcs => (
				<GcsRow
					key={gcs.id}
					googleCalendarSync={gcs}
					gcsToDelete={gcsToDelete}
					setGcsToDelete={setGcsToDelete}
					refetchGoogleAccounts={refetchGoogleAccounts}
				/>
			))}
		</div>
	)
}

interface IGcsRowProps {
	googleCalendarSync: GoogleCalendarSyncFragment
	gcsToDelete?: IGcsToDelete
	setGcsToDelete: Dispatch<SetStateAction<IGcsToDelete | undefined>>
	refetchGoogleAccounts: ReturnType<typeof useGoogleAccountsQuery>['refetch']
}
const GcsRow = React.memo(
	({
		googleCalendarSync,
		gcsToDelete,
		setGcsToDelete,
		refetchGoogleAccounts,
	}: IGcsRowProps) => {
		const theme = useTheme()
		const styles = useStyles()
		const commonStyles = useCommonStyles()
		const buttonStyles = useButtonStyles()

		const { mutate: deleteGcs, isLoading: deleteGcsLoading } =
			useHandleReactQueryMutation(useDeleteGcsMutation())
		const handleDelete = useCallback(() => {
			if (gcsToDelete) {
				deleteGcs(
					{ gcsId: gcsToDelete.id, deleteEvents: gcsToDelete.deleteEvents },
					{
						onSettled: () => {
							refetchGoogleAccounts()
						},
					}
				)
			}
		}, [gcsToDelete, refetchGoogleAccounts])

		const status = (() => {
			switch (googleCalendarSync.status) {
				case GoogleCalendarSyncStatus.Idle:
					return (
						<Typography>
							{googleCalendarSync.syncedEvents.totalCount.toLocaleString()} event
							{googleCalendarSync.syncedEvents.totalCount === 1 ? '' : 's'} synced
						</Typography>
					)
				case GoogleCalendarSyncStatus.Syncing:
					return <Typography>Syncing...</Typography>
			}
		})()

		const calendarNames = joinStrings(
			googleCalendarSync.googleCalendarSyncCalendars.nodes.map(
				o => o.calendar?.group?.groupName || ''
			)
		)

		return (
			<>
				<div className={clsx(styles.spacing, 'row-title')}>
					<div
						className={clsx(styles.calendarDot, 'calendar-dot')}
						style={
							googleCalendarSync.googleCalendarColor
								? {
										backgroundColor: googleCalendarSync.googleCalendarColor,
								  }
								: undefined
						}
					/>
					<Tooltip title={googleCalendarSync.googleCalendarSummary}>
						<Typography className='ellipsis'>
							{googleCalendarSync.googleCalendarSummary}
						</Typography>
					</Tooltip>
				</div>
				<div className={clsx(styles.spacing)}>
					<Typography>{calendarNames}</Typography>
				</div>
				<div className={clsx(styles.spacing)}>{status}</div>
				<div
					className={clsx(
						!googleCalendarSync.lastSyncedAt && styles.emptyLastSynced,
						styles.spacing
					)}
				>
					<Typography>
						{googleCalendarSync.lastSyncedAt ? (
							dayjs(googleCalendarSync.lastSyncedAt).format('M/D/YYYY h:mma')
						) : (
							<span>&mdash;</span>
						)}
					</Typography>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<AnimationIconWraper
						children={<Delete style={{ height: 20, width: 20 }} />}
						className={commonStyles.errorColorHover}
						onClick={() =>
							setGcsToDelete({ id: googleCalendarSync.id, deleteEvents: true })
						}
						tooltip={{ title: 'Stop syncing', placement: 'right' }}
					/>
				</div>
				<div style={{ gridColumn: '1 / 6' }}>
					<MemoizedSpringHeight isOpen={gcsToDelete?.id === googleCalendarSync.id}>
						<div style={{ padding: `${theme.spacing(2)}px 0` }}>
							<div
								style={{
									border: `1px solid ${theme.palette.warning.main}`,
									borderRadius: theme.shape.borderRadius,
									padding: theme.spacing(2),
								}}
							>
								<div>
									<Typography>
										Are you sure you want to remove the sync for{' '}
										<b>{googleCalendarSync.googleCalendarSummary}</b>?
									</Typography>{' '}
								</div>
								{googleCalendarSync.syncedEvents.totalCount > 0 && (
									<FormControlLabel
										control={
											<Checkbox
												checked={Boolean(gcsToDelete?.deleteEvents)}
												onChange={ev =>
													gcsToDelete &&
													setGcsToDelete({
														...gcsToDelete,
														deleteEvents: ev.currentTarget.checked,
													})
												}
											/>
										}
										label={
											<span>
												Also delete the{' '}
												<b>
													{googleCalendarSync.syncedEvents.totalCount.toLocaleString()}{' '}
													synced event
													{googleCalendarSync.syncedEvents.totalCount === 1 ? '' : 's'}
												</b>{' '}
												from <b>{calendarNames}</b>?
											</span>
										}
									/>
								)}
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginTop: theme.spacing(2),
									}}
								>
									<Button variant='text' onClick={() => setGcsToDelete(undefined)}>
										Cancel
									</Button>
									<LoadingButton
										variant='outlined'
										className={buttonStyles.errorOutlined}
										onClick={handleDelete}
										loading={deleteGcsLoading}
									>
										DELETE SYNC
									</LoadingButton>
								</div>
							</div>
						</div>
					</MemoizedSpringHeight>
				</div>
			</>
		)
	}
)
