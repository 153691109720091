import {
	ClickAwayListener,
	createStyles,
	IconButton,
	makeStyles,
	Typography,
	useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import {
	createContext,
	DetailedHTMLProps,
	Dispatch,
	Fragment,
	HTMLAttributes,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { a, config, useSpring } from 'react-spring'
import {
	usePageQuery,
	useUpdatePageSectionSortOrderMutation,
} from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useModal } from './modal'
import { PageSectionEdit } from './page-section-edit'
import { PageSectionAddToolbar } from './page-section-add-toolbar'
import { IPageSectionFormProps, PageSectionForm } from './page-section-form'
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragOverlay,
} from '@dnd-kit/core'
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableOverlay } from './sortable-item'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { PageSectionDisplay } from './page-section-display'
import { snapCenterToCursor } from '../helpers/dnd-kit/snap-to-y-axis-center-modifier'

const useStyles = makeStyles(theme =>
	createStyles({
		container: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		persistentToolbar: {
			marginTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			paddingTop: theme.spacing(1),
			borderBottomLeftRadius: theme.shape.borderRadius,
			borderBottomRightRadius: theme.shape.borderRadius,
		},
		addSectionToobar: {
			borderTop: '1px dashed',
			borderBottom: '1px dashed',
			borderTopColor: theme.palette.grey[300],
			borderBottomColor: theme.palette.grey[300],
			'&:hover': {
				borderColor: theme.palette.primary.main,
			},
			overflow: 'hidden',
			position: 'relative',
			display: 'flex',
		},
	})
)

interface IPageProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	pageId: string
}

interface IState extends Omit<IPageSectionFormProps, 'modal'> {
	addPageSectionToolbarOpen: boolean
	hideAddSectionButtons: boolean
	addSectionToolbarIndex?: number
}

interface IPageContext extends Pick<IPageProps, 'pageId'> {
	state: IState
	setState: Dispatch<SetStateAction<IState>>
	refetchPageSections: () => Promise<any>
	pageSectionFormModal: ReturnType<typeof useModal>
	openAddSectionToolbarAtIndex: (index: number) => void
	pageSectionCount?: number
}
const PageContext = createContext<IPageContext | undefined>(undefined)
export const usePageContext = () => {
	const context = useContext(PageContext)
	if (!context) throw new Error('Cannot use PageContext before it is provided.')
	return context
}

export const PageEdit = ({ pageId, className, ...rest }: IPageProps) => {
	const theme = useTheme()
	const styles = useStyles()

	const [state, setState] = useState<IState>({
		addPageSectionToolbarOpen: false,
		hideAddSectionButtons: false,
	})

	const pageSectionFormModal = useModal()

	const { data, refetch } = useHandleReactQuery(
		usePageQuery({ id: pageId }, { refetchInterval: 3000 })
	)
	const [pageSections, setPageSections] = useState<PageSectionFragment[]>([])
	useEffect(() => {
		setPageSections(data?.page?.pageSections.nodes || [])
	}, [data?.page?.pageSections.nodes])

	const [springStyles, api] = useSpring(() => ({
		opacity: 0,
		height: 0,
		config: config.stiff,
	}))
	const { addPageSectionToolbarOpen, addSectionToolbarIndex } = state
	const openAddSectionToolbarAtIndex = useCallback(
		(index: number) => {
			if (!addPageSectionToolbarOpen) {
				setState(_state => ({
					..._state,
					hideAddSectionButtons: true,
					addSectionToolbarIndex: index,
				}))
				api.start({
					opacity: 1,
					height: 75,
					onRest: () =>
						setState(_state => ({
							..._state,
							addPageSectionToolbarOpen: true,
						})),
				})
			}
		},
		[addPageSectionToolbarOpen]
	)
	const onAddSectionClosed = useCallback(() => {
		if (addPageSectionToolbarOpen) {
			api.start({
				opacity: 0,
				height: 0,
				onRest: () =>
					setState(_state => ({
						..._state,
						addPageSectionToolbarOpen: false,
						hideAddSectionButtons: false,
						addSectionToolbarIndex: undefined,
					})),
			})
		}
	}, [addPageSectionToolbarOpen])

	const { mutate: updateSortOrder } = useUpdatePageSectionSortOrderMutation()
	const sensors = useSensors(
		useSensor(PointerSensor, { activationConstraint: { distance: { y: 15 } } }),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const [activeDragPageSection, setActiveDragPageSection] =
		useState<PageSectionFragment>()

	return (
		<PageContext.Provider
			value={{
				pageId,
				state,
				setState,
				refetchPageSections: refetch,
				pageSectionFormModal,
				openAddSectionToolbarAtIndex,
				pageSectionCount: data?.page?.pageSections.totalCount,
			}}
		>
			<div className={clsx(styles.container, className)} {...rest}>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={evt => {
						const { active, over } = evt

						if (active.id !== over?.id) {
							setPageSections(items => {
								const oldIndex = items.findIndex(o => o.id === active.id)
								const newIndex = items.findIndex(o => o.id === over?.id)

								updateSortOrder({
									newSortOrder: newIndex,
									pageSectionId: evt.active.id,
								})

								return arrayMove(items, oldIndex, newIndex)
							})
						}
					}}
					onDragStart={evt => {
						setActiveDragPageSection(
							pageSections.find(o => o.id === evt.active.id)
						)
						api.set({ opacity: 0, height: 0 })
						setState(_state => ({
							..._state,
							addPageSectionToolbarOpen: false,
							hideAddSectionButtons: false,
							addSectionToolbarIndex: undefined,
						}))
					}}
					modifiers={[restrictToVerticalAxis, snapCenterToCursor]}
				>
					<SortableContext
						items={pageSections}
						strategy={verticalListSortingStrategy}
					>
						{pageSections.map((pageSection, index) => (
							<Fragment key={pageSection.id}>
								{index === addSectionToolbarIndex && (
									<ClickAwayListener onClickAway={onAddSectionClosed}>
										<a.div
											className={clsx(
												styles.addSectionToobar,
												'disable-sortable'
											)}
											style={{ ...springStyles }}
										>
											<PageSectionAddToolbar
												onAddPageSectionTypePressed={type =>
													setState(_state => ({
														..._state,
														newPageSection: {
															type,
															sortOrder: addSectionToolbarIndex,
														},
													}))
												}
												children={
													<IconButton
														size='small'
														style={{ position: 'absolute', top: 2, right: 2 }}
														onClick={onAddSectionClosed}
													>
														<Close style={{ fontSize: '1rem' }} />
													</IconButton>
												}
											/>
										</a.div>
									</ClickAwayListener>
								)}
								<PageSectionEdit pageSection={pageSection} />
							</Fragment>
						))}
					</SortableContext>
					<DragOverlay>
						{activeDragPageSection && (
							<SortableOverlay
								id={activeDragPageSection.id}
								style={{
									maxHeight: 150,
									overflow: 'hidden',
									backgroundColor: theme.palette.action.active,
									opacity: 0.7,
								}}
							>
								<PageSectionDisplay pageSection={activeDragPageSection} />
							</SortableOverlay>
						)}
					</DragOverlay>
				</DndContext>

				{data?.page?.pageSections.totalCount === addSectionToolbarIndex && (
					<ClickAwayListener onClickAway={onAddSectionClosed}>
						<a.div
							className={clsx(styles.addSectionToobar, 'disable-sortable')}
							style={{ ...springStyles }}
						>
							<PageSectionAddToolbar
								onAddPageSectionTypePressed={type =>
									setState(_state => ({
										..._state,
										newPageSection: { type, sortOrder: addSectionToolbarIndex },
									}))
								}
								children={
									<IconButton
										size='small'
										style={{ position: 'absolute', top: 2, right: 2 }}
										onClick={onAddSectionClosed}
									>
										<Close style={{ fontSize: '1rem' }} />
									</IconButton>
								}
							/>
						</a.div>
					</ClickAwayListener>
				)}

				<div style={{ flex: 1 }} />

				<Typography
					variant='h6'
					color='textSecondary'
					style={{
						paddingLeft: theme.spacing(2),
						backgroundColor: theme.palette.grey[100],
					}}
				>
					Add more sections:
				</Typography>
				<PageSectionAddToolbar
					className={styles.persistentToolbar}
					onAddPageSectionTypePressed={type =>
						setState(_state => ({
							..._state,
							newPageSection: {
								type,
								sortOrder: data?.page?.pageSections.totalCount,
							},
						}))
					}
					style={{ marginTop: 0, flex: 'unset' }}
				/>
			</div>

			<PageSectionForm
				modal={pageSectionFormModal}
				newPageSection={state.newPageSection}
				pageSectionToEdit={state.pageSectionToEdit}
			/>
		</PageContext.Provider>
	)
}
