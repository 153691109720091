import { Box, Chip, TextField, TextFieldProps, useTheme } from '@material-ui/core'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useFormStyles } from '../styles'
import { GroupSelectorSidebar } from './group-selector-sidebar'
import clsx from 'clsx'
import { GroupOptionType, IGroupOption, IInfoOption, isInfoOption, useGetContentForInfoOption, useGroupSelector } from '../hooks'
import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, AutocompleteRenderOptionState, FilterOptionsState } from '@material-ui/lab'
import { matchSorter } from 'match-sorter'
import { IconTypeDisplay } from './icon-type-display'
import { useMuiClassOverrides } from '../styles/use-mui-class-overrides'
import { GroupSelectorRecents } from './group-selector-recents'
import { Callout, ICalloutClosable, ICalloutProps, ICalloutWhy } from './callout'
import { joinStrings } from '../helpers'
import { xor } from 'lodash'
import { animated, useSpring } from 'react-spring'

type IGroupSelectorProps = {
    label?: string
    searchPlaceholder?: string
    error?: string
    initialError?: string
    helperText?: string
    touched?: boolean
    groupSelector: ReturnType<typeof useGroupSelector>
} & Pick<TextFieldProps, 'onBlur'>

export const GroupSelector = React.memo(({
    groupSelector: {
        groupSelectorState,
        handleSelectedGroupsChange,
        removeGroupFromSelection,
        addGroupToSelection,
        toggleGroupSelected,
        availableOptions,
        availableGroupOptions,
        selectedGroupOptions,
        divisions,
    },
    label,
    searchPlaceholder,
    initialError,
    error,
    helperText,
    touched,
    onBlur,
}: IGroupSelectorProps) => {

    const theme = useTheme()

    const [ sidebarOpen, setSidebarOpen ] = useState(false)

    const formStyles = useFormStyles()
    const muiOverrides = useMuiClassOverrides()

    const { selectedGroupIds, selectionsAutomaticallyRemoved } = groupSelectorState

    const handleFilterOptions = useCallback((options: GroupOptionType[], state: FilterOptionsState<GroupOptionType>) => {
        // Extract the info options real quick before performing the search (we don't want to filter out the info options)
        const infoOptions = options.filter(o => isInfoOption(o))
        const groupOptions = options.filter(o => !isInfoOption(o))

        // If there are only info options and no group options, just return the info options
        if (groupOptions.length === 0) return infoOptions

        const filteredOptions = matchSorter(groupOptions, state.inputValue, { keys: [ 'title' ] })

        // Add the info options back in
        filteredOptions.push(...infoOptions)

        // If there are 10 or fewer options, show the filter results even if there's no input to filter them by (i.e., just normal dropdown list options with no filter)
        if (options.length <= 10) return filteredOptions

        if (state.inputValue) {
            // If the user's search has results add a "matches" info option to tell them to select a math or open the sidebar to view more.
            if (filteredOptions.length > 0) {
                const matches: IInfoOption = { status: 'matches', openSidebarWhenSelected: true }
                filteredOptions.push(matches)
            } else {
                // If there are no matches, tell the user, but only allow sidebar opening if the are more than 10 options
                const noMatches: IInfoOption = { status: 'no-matches', openSidebarWhenSelected: options.length > 10 }
                filteredOptions.push(noMatches)
            }

            return filteredOptions
        }
        // With more than 10 options "require" input before showing the list
        else {
            const noInput: IInfoOption = { status: 'no-input', openSidebarWhenSelected: true }
            return [ noInput ]
        }
    }, [ selectedGroupIds ])

    const handleAutocompleteOnChange = useCallback(async (_: ChangeEvent<{}>, selectedOptions: GroupOptionType[], reason: AutocompleteChangeReason) => {
        if (reason === 'clear') {
            closeSchoolwideCallout()
            closeDivisionCallout()
            handleSelectedGroupsChange([])
        } else if (selectedOptions.find(o => isInfoOption(o))) {
            const infoOption = selectedOptions.find(o => isInfoOption(o)) as IInfoOption
            if (infoOption.openSidebarWhenSelected) setSidebarOpen(true)
            return
        } else {
            // Safe to type cast at this point since we filter out any info options in the check above this one. Otherwise, totally NOT safe to force handleSelectedGroupsChange to take the list of selectedOptions.
            handleSelectedGroupsChange(selectedOptions as IGroupOption[])
        }
    }, [])

    const handleRenderInput = useCallback((params: AutocompleteRenderInputParams) =>
        <TextField
            {...params}
            label={label}
            error={(touched && !!error) || !!initialError}
            placeholder={searchPlaceholder || 'Search...'}
            helperText={(touched && error) || helperText}
            variant='outlined'
            onBlur={onBlur}
        />, [label, touched, error, initialError, searchPlaceholder, helperText, onBlur])
    const getContentForInfoOption = useGetContentForInfoOption()
    const handleRenderOption = useCallback((option: GroupOptionType, state: AutocompleteRenderOptionState) => {
        if (isInfoOption(option)) {
            return getContentForInfoOption(option)
        } else {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconTypeDisplay style={{ color: option.color || theme.palette.grey[ 500 ], marginRight: theme.spacing(1) }} size={15} type={option.iconType} />
                    {option.title}
                    {option.divisionName && !option.isDivision && ` (${option.divisionName})`}
                </div>
            )
        }
    }, [])
    const handleRenderTags = useCallback((values: GroupOptionType[]) => {
        return (
            <>
                {(values as IGroupOption[]).map(o =>
                    <Chip
                        style={{ marginRight: theme.spacing(.5), marginTop: theme.spacing(.5), backgroundColor: o.color || theme.palette.grey[ 500 ] }}
                        classes={{ label: muiOverrides.label }}
                        key={o.id}
                        variant='default'
                        color='primary'
                        onDelete={() => removeGroupFromSelection(o.id)}
                        avatar={<IconTypeDisplay style={{ color: 'white', marginLeft: theme.spacing(1) }} size={15} type={o.iconType} />}
                        label={`${o.title}${o.divisionName && !o.isDivision ? ` (${o.divisionName})` : ''}`}
                    />
                )}
            </>
        )
        /* 
            We need to include a dependency on selectedGroupIds or removeGroupFromSelection since we have a hidden dependency in here.
            The removeGroupFromSelection function depends on selectedGroupIds.
            But the current version of the removeGroupFromSelection callback is "captured" inside this useCallback for handleRenderTags. 
            Thus it wouldn't get updated when selectedGroupIds changes unelss we make the handleRenderTags useCallback also depend on removeGroupFromSelection.

            Tricky
        */
    }, [ removeGroupFromSelection ])

    const [ calloutsState, setCalloutsState ] = useState<{
        schoolwideCallout: Pick<ICalloutClosable, 'isOpen'>
        divisionCallout: Pick<ICalloutProps, 'children'> & Pick<ICalloutClosable, 'isOpen'> & Pick<ICalloutWhy, 'tooltip'>
    }>({
        schoolwideCallout: {
            isOpen: false,
        },
        divisionCallout: {
            isOpen: false,
            tooltip: '',
            children: '',
        }
    })
    const closeSchoolwideCallout = useCallback(() => {
        setCalloutsState(_state => ({
            ..._state,
            schoolwideCallout: {
                isOpen: false,
            }
        }))
    }, [])
    const closeDivisionCallout = useCallback(() => {
        setCalloutsState(_state => ({
            ..._state,
            divisionCallout: {
                ..._state.divisionCallout,
                isOpen: false,
            }
        }))
    }, [])
    const { schoolwideCallout, divisionCallout } = calloutsState

    const prevSelectionsAutomaticallyRemoved = useRef(selectionsAutomaticallyRemoved)
    useEffect(() => {
        // We only need to show the alerts if previous selections were automatically removed for the first time (i.e. we don't reshow the callout if we aren't moving from )

        // Toggle on the Schoolwide callout if it was just added for the first time (we don't want to reopen the callout on every subsequent change if the user has dismissed it)
        if (!prevSelectionsAutomaticallyRemoved.current?.schoolwide && selectionsAutomaticallyRemoved?.schoolwide) {
            setCalloutsState(_state => ({
                schoolwideCallout: {
                    isOpen: true,
                },
                divisionCallout: {
                    ..._state.divisionCallout,
                    isOpen: false,
                },
            }))
        }
        /* 
            Toggling the Divisions callout takes a bit more checking:
            - Toggle on if a Division was selected for the first time
            - Toggle on if a different mixture of Divisions were selected
        */
        else if (selectionsAutomaticallyRemoved?.divisions && xor(prevSelectionsAutomaticallyRemoved.current?.divisions?.map(o => o.id) || [], selectionsAutomaticallyRemoved.divisions.map(o => o.id)).length > 0) {
            const divisionNames = joinStrings(selectionsAutomaticallyRemoved.divisions.map(o => o.name))
            setCalloutsState(_state => ({
                schoolwideCallout: {
                    isOpen: false,
                },
                divisionCallout: {
                    ..._state.divisionCallout,
                    isOpen: true,
                    children: `${divisionNames} selected. All group selections from ${divisionNames} cleared.`,
                    tooltip: `There is no need to include any more groups from ${divisionNames} since content available to ${divisionNames} will be visible to users in all groups within ${divisionNames}.`
                }
            }))
        }
        // If Schoolwide isn't selected and no Divisions were selected, hide all callouts
        // ! Not sure if this is good...we're automatically hiding these callouts but maybe they should just persist until the user hides them...disabling for now.
        // else if (!selectionsAutomaticallyRemoved || (!selectionsAutomaticallyRemoved.schoolwide && !selectionsAutomaticallyRemoved.divisions)) {
        //     setCalloutsState(_state => ({ ..._state, schoolwideCalloutOpen: false, divisionCallout: { ..._state.divisionCallout, isOpen: false } }))
        // }

        prevSelectionsAutomaticallyRemoved.current = selectionsAutomaticallyRemoved
    }, [ selectionsAutomaticallyRemoved ])

    const marginSpring = useSpring({
        marginTop: schoolwideCallout.isOpen || divisionCallout.isOpen ? theme.spacing(2) : 0,
    })

    return (
        <Box mb={2}>
            <Box mx={.5}>
                <Callout
                    closable={{ isOpen: schoolwideCallout.isOpen, close: closeSchoolwideCallout }}
                    why={{ tooltip: 'Content added to the Schoolwide group is visible to all users. There is no need to select additional groups.' }}
                    children={'Schoolwide group selected. All other selections cleared.'}
                />
            </Box>

            <Box mx={.5}>
                <Callout
                    closable={{ isOpen: divisionCallout.isOpen, close: closeDivisionCallout }}
                    why={{ tooltip: divisionCallout.tooltip }}
                    children={divisionCallout.children}
                />
            </Box>

            <animated.div style={marginSpring} />

            <Box display='flex'>
                <Autocomplete
                    // Data
                    filterOptions={handleFilterOptions}
                    options={availableOptions}
                    onChange={handleAutocompleteOnChange}
                    value={selectedGroupOptions}

                    // Config
                    className={clsx(formStyles.margin)}
                    multiple
                    fullWidth

                    // Display
                    renderInput={handleRenderInput}
                    renderOption={handleRenderOption}
                    renderTags={handleRenderTags}
                />
            </Box>

            <Box mx={.5}>
                <GroupSelectorRecents
                    groupOptions={availableGroupOptions}
                    onGroupOptionSelected={addGroupToSelection}
                    selectedGroupIds={selectedGroupIds}
                />
            </Box>

            {divisions &&
                <GroupSelectorSidebar
                    open={sidebarOpen}
                    close={() => setSidebarOpen(false)}
                    availableOptions={availableOptions}
                    divisions={divisions}
                    selectedGroupIds={selectedGroupIds}
                    onGroupSelected={toggleGroupSelected}
                />
            }
        </Box>
    )
})