import { Box, createStyles, makeStyles, useTheme } from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import clsx from 'clsx'
import { memo, useMemo } from 'react'
import {
	GroupCategoryListFieldsFragment,
	GroupListFieldsFragment,
} from '../graphql/autogenerate/operations'
import { IconType } from '../graphql/autogenerate/schemas'
import { useAppState } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { MemoizedExpandableDropdown } from './expandable-dropdown'
import { IconTypeDisplay } from './icon-type-display'

interface IGroupOption extends GroupListFieldsFragment {
	divisionGroupId?: string
}

interface IGroupsTreeProps {
	onGroupSelected: (group: GroupListFieldsFragment) => void
	selectedGroupIds?: string[]
}

export const GroupsTree = ({
	onGroupSelected,
	selectedGroupIds,
}: IGroupsTreeProps) => {
	const theme = useTheme()
	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: {
			school: { divisions },
			currentUserPermissions,
		},
	} = useSchoolContext()

	// What groups is this user allowed to manage?
	const displayGroups = useMemo(() => {
		const groups: IGroupOption[] = []

		divisions.nodes.forEach(division => {
			if (division.group) groups.push(division.group)
			division.groupCategories.nodes.forEach(category =>
				groups.push(
					...category.groups.nodes.map(o => ({
						...o,
						divisionGroupId: division.group?.id,
					}))
				)
			)
		})

		if (
			currentUserPermissions.schoolwideAdmin ||
			currentUser?.appAdministrator
		) {
			return groups
		} else {
			return groups.filter(group =>
				currentUserPermissions.groups.nodes.some(
					o =>
						(o.groupId === group.id || o.groupId === group.divisionGroupId) &&
						o.admin
				)
			)
		}
	}, [divisions, currentUser, divisions, currentUserPermissions])

	/* 
        If there are 10 or fewer groups to show, just render a pure list.

        If a Group's division is selected, disable it.
    */
	if (displayGroups.length <= 10) {
		return (
			<>
				{displayGroups.map(group => (
					<GroupRow
						key={group.id}
						title={group.groupName}
						iconType={group.icon as IconType}
						color={theme.palette.primary.main}
						onClick={() => onGroupSelected(group)}
						disabled={Boolean(
							!group.isDivisionGroup &&
								group.divisionId &&
								selectedGroupIds?.includes(group.divisionId)
						)}
					/>
				))}
			</>
		)
	}

	return (
		<>
			{divisions.totalCount === 1 ? (
				<>
					<Box my={0.5}>
						<GroupRow
							key={divisions.nodes[0].id}
							title={divisions.nodes[0].name}
							iconType={
								divisions.nodes[0].schoolwide
									? IconType.IosFilledCityHall
									: IconType.IosFilledSchool
							}
							color={theme.palette.primary.main}
							onClick={() =>
								divisions.nodes[0].group &&
								onGroupSelected(divisions.nodes[0].group)
							}
							disabled={
								!displayGroups.some(o => o.id === divisions.nodes[0].group?.id)
							}
						/>
					</Box>

					<Box>
						{divisions.nodes[0].groupCategories.nodes
							.filter(o => o.groups.totalCount)
							.map(groupCategory => (
								<GroupCategory
									key={groupCategory.id}
									category={groupCategory}
									groupSelected={group => onGroupSelected(group)}
									displayGroups={displayGroups}
									selectedGroupIds={selectedGroupIds}
									disabled={Boolean(
										divisions.nodes[0].group &&
											selectedGroupIds?.includes(divisions.nodes[0].group?.id)
									)}
								/>
							))}
					</Box>
				</>
			) : (
				divisions.nodes.map(division => (
					<MemoizedExpandableDropdown
						key={division.id}
						sectionTitle={division.name}
						startExpanded
					>
						<Box ml={3} my={0.5}>
							<GroupRow
								key={division.id}
								title={division.name}
								iconType={
									division.schoolwide
										? IconType.IosFilledCityHall
										: IconType.IosFilledSchool
								}
								color={theme.palette.primary.main}
								onClick={() =>
									division.group && onGroupSelected(division.group)
								}
								disabled={!displayGroups.some(o => o.id === division.group?.id)}
							/>
						</Box>

						<Box ml={3}>
							{division.groupCategories.nodes
								.filter(o => o.groups.totalCount)
								.map(groupCategory => (
									<GroupCategory
										key={groupCategory.id}
										category={groupCategory}
										groupSelected={group => onGroupSelected(group)}
										displayGroups={displayGroups}
										selectedGroupIds={selectedGroupIds}
										disabled={Boolean(
											division.group &&
												selectedGroupIds?.includes(division.group?.id)
										)}
									/>
								))}
						</Box>
					</MemoizedExpandableDropdown>
				))
			)}
		</>
	)
}

export const GroupCategory = memo(
	({
		category,
		groupSelected,
		displayGroups,
		disabled,
	}: {
		category: GroupCategoryListFieldsFragment
		displayGroups: GroupListFieldsFragment[]
		disabled?: boolean
		selectedGroupIds?: string[]
		groupSelected: (group: GroupListFieldsFragment) => void
	}) => {
		const theme = useTheme()
		return (
			<MemoizedExpandableDropdown
				sectionTitle={
					<>
						<IconTypeDisplay
							type={category.iconType}
							size={20}
							style={{
								marginRight: 5,
								color: category.iconBackgroundColor || theme.palette.grey[500],
							}}
						/>
						{category.name}
					</>
				}
			>
				<div style={{ paddingLeft: theme.spacing(3.5) }}>
					{category.groups.nodes
						.filter(o => displayGroups.some(g => g.id === o.id))
						.map(group => (
							<GroupRow
								key={group.id}
								title={group.groupName}
								iconType={group.iconType || category.iconType}
								color={category.iconBackgroundColor || theme.palette.grey[500]}
								onClick={() => groupSelected(group)}
								disabled={disabled}
							/>
						))}
				</div>
			</MemoizedExpandableDropdown>
		)
	}
)

const useGroupRowStyles = makeStyles(theme =>
	createStyles({
		row: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			marginBottom: theme.spacing(0.5),
			padding: `0px ${theme.spacing(1)}px`,
			cursor: 'pointer',
		},
		hover: {
			'&:hover': {
				backgroundColor: theme.palette.grey[200],
				'& span:nth-child(3)': {
					display: 'flex!important',
				},
			},
		},
		disabled: {
			color: theme.palette.grey[500],
			cursor: 'default',
		},
		rowTitle: {
			paddingLeft: theme.spacing(0.5),
			flex: 1,
		},
		icon: {
			color: theme.palette.grey[500],
		},
		hideAddRemoveFavoritesIcon: {
			display: 'none!important',
		},
	})
)

interface IGroupRow {
	title: string
	subTitle?: string
	iconType: IconType
	color: string
	onClick: () => void
	selected?: boolean
	disabled?: boolean
}
export const GroupRow = memo(
	({ onClick, title, selected, iconType, color, disabled }: IGroupRow) => {
		const styles = useGroupRowStyles()
		const theme = useTheme()
		return (
			<div
				className={clsx(
					styles.row,
					disabled && styles.disabled,
					!disabled && styles.hover
				)}
				onClick={() => !disabled && onClick()}
			>
				<IconTypeDisplay
					type={iconType}
					size={20}
					style={{
						marginRight: 5,
						color: disabled ? theme.palette.grey[500] : color,
					}}
				/>
				<div className={styles.rowTitle}>{title}</div>
				{selected !== undefined && (
					<AnimationIconWraper
						className={clsx(styles.icon)}
						children={selected ? <Remove /> : <Add />}
						tooltip={{
							title: selected ? 'Remove from selection' : 'Add to selection',
							placement: 'right',
						}}
					/>
				)}
			</div>
		)
	}
)
