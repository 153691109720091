import { useTheme, Typography } from '@material-ui/core'
import { joinStrings } from '../helpers'
import { ISchoolState } from '../stores/school'
import { Callout } from './callout'

interface IUserPermissionsDescriptionProps {
    groupIds: string[]
    divisions: ISchoolState[ 'school' ][ 'divisions' ][ 'nodes' ]
    groups: ISchoolState[ 'groups' ]
    firstName?: string | null
    persistentCallouts?: boolean
}
export const UserPermissionsDescription = ({ divisions, groups, groupIds, firstName, persistentCallouts }: IUserPermissionsDescriptionProps) => {
    const theme = useTheme()

    const schoolwideGroupId = divisions?.find(o => o.schoolwide)?.group?.id

    const hasSchoolwideAccess = groupIds.some(groupId => schoolwideGroupId === groupId)
    const hasDivisionAccess = groupIds.some(groupId => divisions?.some(division => division.group?.id === groupId))

    const name = <b>{firstName || 'this user'}</b>

    return (
        <>
            {groupIds.length === 0 &&
                <Callout type='info' closable={!persistentCallouts}>
                    <Typography>You have not selected any groups. {name} will not be able to manage any content within the school.</Typography>
                </Callout>
            }

            {hasSchoolwideAccess &&
                <Callout type='warning' closable={!persistentCallouts}>
                    <Typography>
                        <div style={{ marginBottom: theme.spacing(1) }}>You've selected <b>schoolwide</b> access for {name}.</div>

                        <div>{name} will be able to:</div>
                        <ul>
                            <li>Add/Remove other admins</li>
                            <li>Manage all users' permissions</li>
                            <li>Send schoolwide notifications</li>
                            <li>Publish changes to the schoolwide marquee</li>
                            <li>Create/edit/delete divisions and groups</li>
                            <li>Create/edit/delete all content for every group within the school</li>
                        </ul>
                    </Typography>
                </Callout>
            }

            {!hasSchoolwideAccess && hasDivisionAccess &&
                (() => {
                    const divisionNames = divisions.filter(division => division.group?.id && groupIds.includes(division.group?.id)).map(division => division.name)
                    const joinedDivisionNames = joinStrings(divisionNames, true)
                    return (
                        <Callout type='warning' closable={!persistentCallouts}>
                            <Typography>
                                <div style={{ marginBottom: theme.spacing(1) }}>
                                    You've selected {joinedDivisionNames} access for {name}.
                                </div>

                                <div><b>{firstName || 'This user'}</b> will be able to:</div>
                                <ul>
                                    <li>Send notification blasts to everyone subscribed to {joinStrings(divisionNames, true, 'or')}</li>
                                    <li>Create/edit/delete all content for every group within {joinedDivisionNames}</li>
                                    <li>Send notifications to any individual group within {joinedDivisionNames}</li>
                                </ul>
                            </Typography>
                        </Callout>
                    )
                })()
            }

            {!hasSchoolwideAccess && !hasDivisionAccess && groupIds.length > 0 &&
                (() => {

                    return (
                        <Callout type='warning' closable={!persistentCallouts}>
                            <Typography>
                                <div style={{ marginBottom: theme.spacing(1) }}>
                                    You've selected <b>{groupIds.length} group{groupIds.length === 1 ? '' : 's'}</b> to give {name} access to.
                                </div>

                                <div><b>{firstName || 'This user'}</b> will be able to:</div>
                                <ul>
                                    <li>Send notifications to anyone subscribed to {groupIds.length === 1 ? 'this group' : 'these groups'}</li>
                                    <li>Create/edit/delete content within these {groupIds.length === 1 ? 'this group' : 'these groups'}</li>
                                </ul>
                            </Typography>
                        </Callout>
                    )
                })()
            }

        </>
    )
}