import { Button, Typography, useTheme } from '@material-ui/core'
import {
	SendOutlined,
	ScheduleOutlined,
	Check,
	HelpOutline,
} from '@material-ui/icons'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useRef } from 'react'
import {
	usePublishNewsletterMutation,
	useUpdateNewsletterPublishDateMutation,
} from '../graphql/autogenerate/react-query'
import { NewsletterStatus } from '../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../hooks'
import { useButtonStyles } from '../styles'
import { FormikDatePicker, FormikSwitchField, FormikTimePicker } from './forms'
import { useNewsletterEditContext } from './newsletter-edit'
import * as yup from 'yup'
import { Modal } from './modal'
import { useCopyToClipboard } from 'react-use'
import { Tooltip } from './tooltip'

export const NewsletterPublish = () => {
	const theme = useTheme()
	const buttonStyles = useButtonStyles()

	const {
		refetchNewsletter,
		newsletter,
		publishModal,
		editModalControls,
		publishNow,
		setPublishNow,
	} = useNewsletterEditContext()

	const initialValues = useMemo(() => {
		return {
			publishType: ((): 'now' | 'later' | null => {
				if (newsletter.status === NewsletterStatus.Scheduled) return 'later'
				return null
			})(),
			publishDate: dayjs(
				(newsletter.status === NewsletterStatus.Scheduled ||
					newsletter.status === NewsletterStatus.Published) &&
					newsletter.publishDate
					? newsletter.publishDate
					: undefined
			) as Dayjs,
			publishTime:
				(newsletter.status === NewsletterStatus.Scheduled ||
					newsletter.status === NewsletterStatus.Published) &&
				newsletter.publishDate
					? dayjs(newsletter.publishDate)
					: (dayjs().startOf('h').add(2, 'hours') as Dayjs),
			sendNotification: true,
		}
	}, [newsletter])

	const { enqueueSnackbar } = useSnackbar()

	const justPublished = useRef(false)
	const { mutateAsync: publishNewsletter } = useHandleReactQueryMutation(
		usePublishNewsletterMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				justPublished.current = true
			},
		})
	)
	const { mutateAsync: updatePublishDate } = useHandleReactQueryMutation(
		useUpdateNewsletterPublishDateMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				enqueueSnackbar('Publish date updated.', { variant: 'success' })
				publishModal.close()
			},
		})
	)

	const [_, copyToClipoard] = useCopyToClipboard()

	useEffect(() => {
		return () => publishModal.close()
	}, [])

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={yup.object({
				publishDate: yup.mixed().test('publishDate', 'Required', function (value) {
					if (this.parent.publishType === 'later') return Boolean(value)
					return true
				}),
				publishTime: yup
					.mixed()
					.test('publishTime', 'Required', function (value) {
						if (this.parent.publishType === 'later') return Boolean(value)
						return true
					})
					.test(
						'sendLaterTimeFuture',
						'Cannot schedule a newsletter to be published in the past.',
						function (value) {
							if (
								this.parent.publishType === 'later' &&
								this.parent.publishDate &&
								dayjs(this.parent.publishDate).startOf('d').isSameOrBefore(dayjs())
							) {
								return dayjs(value).isAfter(dayjs())
							}
							return true
						}
					),
			})}
			onSubmit={async values => {
				if (newsletter.status === NewsletterStatus.Scheduled) {
					if (publishNow) {
						await publishNewsletter({
							newsletterId: newsletter.id,
							publishDate: null,
							sendNotification: values.sendNotification,
						})
					} else {
						await updatePublishDate({
							id: newsletter.id,
							publishDate: values.publishDate
								.hour(values.publishTime.hour())
								.minute(values.publishTime.minute())
								.startOf('minute')
								.toISOString(),
						})
					}
				}
				if (newsletter.status === NewsletterStatus.Published) {
					await updatePublishDate({
						id: newsletter.id,
						publishDate: values.publishDate
							.hour(values.publishTime.hour())
							.minute(values.publishTime.minute())
							.startOf('minute')
							.toISOString(),
					})
				} else {
					await publishNewsletter({
						newsletterId: newsletter.id,
						publishDate:
							values.publishType === 'later'
								? values.publishDate
										.hour(values.publishTime.hour())
										.minute(values.publishTime.minute())
										.startOf('minute')
										.toISOString()
								: null,
						sendNotification: values.sendNotification,
					})
				}

				publishModal.close()
			}}
		>
			{formikProps => {
				const sendNotificationToggle = (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'flex-end',
						}}
					>
						<FormikSwitchField
							field={{
								name: 'sendNotification',
								label: `Send Notification`,
							}}
						/>
						<Tooltip
							children={
								<HelpOutline
									style={{
										color: theme.palette.info.main,
										marginLeft: theme.spacing(1),
									}}
								/>
							}
							title='Toggle whether or not to send a notification when this Newsletter is published. This is required to schedule a newsletter/notification to be published later.'
						/>
					</div>
				)

				return (
					<Modal
						{...publishModal.props}
						title={(() => {
							if (newsletter.status === NewsletterStatus.Scheduled)
								return 'Edit Schedule'
							if (newsletter.status !== NewsletterStatus.Published) return 'Success'
							if (
								newsletter.status === NewsletterStatus.Published &&
								newsletter.publishDate
							)
								return 'Edit Published Date'
							return 'Publish Newsletter'
						})()}
						dismissible
						closeButton
						size='xs'
						afterClose={() => {
							justPublished.current = false
							formikProps.resetForm()
							setPublishNow(false)
						}}
						actions={(() => {
							if (newsletter.status === NewsletterStatus.Published)
								return (
									<Button
										onClick={formikProps.submitForm}
										style={{ minWidth: 100, marginRight: theme.spacing(1) }}
										variant='contained'
										color='primary'
										type='submit'
									>
										Save
									</Button>
								)

							if (justPublished.current)
								return (
									<div
										style={{
											display: 'flex',
											flex: 1,
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Button
											style={{ minWidth: 100, marginRight: theme.spacing(1) }}
											variant='outlined'
											color='primary'
											type='button'
											onClick={() => {
												copyToClipoard(
													`${process.env.REACT_APP_ROOT_URL}/newsletter/${newsletter.slug}`
												)
												enqueueSnackbar('Copied!')
											}}
										>
											Copy Share Link
										</Button>
										<Button
											style={{ minWidth: 100 }}
											variant='outlined'
											color='primary'
											onClick={editModalControls.close}
										>
											Done
										</Button>
									</div>
								)

							if (formikProps.values.publishType)
								return (
									<Button
										onClick={formikProps.submitForm}
										style={{ minWidth: 100, marginRight: theme.spacing(1) }}
										variant='contained'
										color='primary'
										type='submit'
									>
										{(() => {
											if (newsletter.status === NewsletterStatus.Scheduled) {
												if (publishNow) return 'Publish Now'
												return 'Update'
											}
											return 'Schedule'
										})()}
									</Button>
								)
						})()}
						children={(() => {
							if (justPublished.current)
								return (
									<div
										style={{
											color: theme.palette.success.main,
											display: 'flex',
											alignItems: 'center',
											marginBottom: theme.spacing(1),
										}}
									>
										<Check style={{ marginRight: theme.spacing(1) }} />
										<Typography>
											{formikProps.values.publishType === 'later'
												? 'Newsletter scheduled to be published.'
												: 'Newsletter successfully published.'}
										</Typography>
									</div>
								)

							if (newsletter.status === NewsletterStatus.Published)
								return (
									<Form>
										<div
											style={{
												flex: 1,
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<div style={{ paddingBottom: theme.spacing(2) }}>
												<div
													style={{
														display: 'flex',
														marginTop: theme.spacing(1),
													}}
												>
													<FormikDatePicker
														disabledKeyboard
														fieldProps={{ name: 'publishDate' }}
														style={{ marginRight: theme.spacing(1) }}
													/>
													<FormikTimePicker
														fieldProps={{ name: 'publishTime' }}
														startTime={dayjs().startOf('d')}
													/>
												</div>
											</div>
										</div>
									</Form>
								)

							return (
								<Form>
									<div
										style={{
											flex: 1,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										{!formikProps.values.publishType && (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-around',
												}}
											>
												<div style={{ margin: theme.spacing(2) }}>
													<div
														className={buttonStyles.iconButton}
														onClick={formikProps.submitForm}
													>
														<SendOutlined style={{ fontSize: '3rem' }} />
														<Typography>Publish now</Typography>
													</div>
												</div>

												{formikProps.values.sendNotification && (
													<div style={{ margin: theme.spacing(2) }}>
														<div
															className={buttonStyles.iconButton}
															onClick={() => formikProps.setFieldValue('publishType', 'later')}
														>
															<ScheduleOutlined style={{ fontSize: '3rem' }} />
															<Typography>Publish later</Typography>
														</div>
													</div>
												)}
											</div>
										)}

										{newsletter.status === NewsletterStatus.Draft &&
											sendNotificationToggle}

										{formikProps.values.publishType === 'later' && (
											<div style={{ paddingBottom: theme.spacing(2) }}>
												<div
													style={{
														display: 'flex',
														marginTop: theme.spacing(1),
													}}
												>
													{publishNow ? (
														<div>
															<div style={{ marginBottom: theme.spacing(1) }}>
																Are you sure you want to publish this newsletter now?
															</div>
															{sendNotificationToggle}
														</div>
													) : (
														<>
															<FormikDatePicker
																disabledKeyboard
																fieldProps={{ name: 'publishDate' }}
																style={{ marginRight: theme.spacing(1) }}
															/>
															<FormikTimePicker
																fieldProps={{ name: 'publishTime' }}
																startTime={dayjs().startOf('d')}
															/>
														</>
													)}
												</div>
											</div>
										)}
									</div>
								</Form>
							)
						})()}
					/>
				)
			}}
		</Formik>
	)
}
