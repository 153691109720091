import { createStyles, makeStyles, TextField, List, ListItem, FormHelperText, FormControl } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { v4 } from 'uuid'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/shift-away.css'
import { IField } from '.'
import { useField } from 'formik'
import clsx from 'clsx'
import { extractHourMinuteInput, getLabelForMinutes, getNextTimeForIncrement } from '../../helpers'
import { useKeyPressEvent } from 'react-use'

const useStyles = makeStyles(theme =>
    createStyles({
        timeList: {
            backgroundColor: 'white',
            overflow: 'auto',
            maxHeight: 200,
            boxShadow: theme.shadows[ 10 ],
        },
        timeListItem: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.grey[ 300 ]
            }
        },
        timeListItemSelected: {
            backgroundColor: theme.palette.grey[ 300 ]
        }
    })
)

export type TimePickerIncrement = 15 | 30 | 60

interface IFormikTimePickerProps {
    fieldProps: IField
    startTime?: Dayjs
    increment?: TimePickerIncrement
    durationFromTime?: Dayjs
    style?: React.CSSProperties
}
export const FormikTimePicker = ({
    fieldProps,
    ...rest
}: IFormikTimePickerProps) => {

    const [ { value, onBlur, }, { error, touched, initialError }, { setValue } ] = useField<Dayjs | undefined>(fieldProps)

    return (
        <TimePicker
            {...rest}
            value={value}
            onBlur={onBlur}
            setValue={setValue}
            error={error}
            touched={touched}
            initialError={initialError}
        />
    )
}

/* 
    TODO: add internal error handling/styling, set error on invalid date and clear on valid date
    TODO: convert to completely internal except for onblur
*/
interface ITimePickerProps extends Omit<IFormikTimePickerProps, 'fieldProps'> {
    error?: string
    initialError?: string
    value?: Dayjs
    setValue: (value?: Dayjs) => void
    touched?: boolean
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}
export const TimePicker = React.memo(
    ({
        startTime = dayjs(),
        increment = 30,
        durationFromTime,
        error,
        initialError,
        value,
        setValue,
        touched,
        onBlur,
        style,
    }: ITimePickerProps) => {
        const styles = useStyles()
        const pickerId = useRef(v4())

        const inputRef = useRef<HTMLInputElement>()

        /* 
            Set the start time to the next whole increment. 
            (e.g. if it's 6:01 and the increment is 30 set the start time to 6:30)
        */
        startTime = getNextTimeForIncrement(increment, startTime)
        const times = useMemo(() => {
            let timeOption = startTime.clone()
            const _times: Dayjs[] = []
            for (let minutes = 1; minutes <= 24 * 60 / increment; minutes++) {
                _times.push(timeOption)
                timeOption = timeOption.add(increment, 'minutes')
            }
            return _times
        }, [ startTime, increment ])

        useEffect(() => {
            if (!value) setValue(startTime)
        }, [ startTime, value ])

        const [ ref, setRef ] = useState<HTMLLIElement | null>()

        useKeyPressEvent(
            (ev) => ev.code === 'ArrowUp',
            null,
            (ev) => {
                if (document.activeElement === inputRef.current) {
                    setValue((value || startTime).subtract(increment, 'minutes'))
                }
            }
        )

        useKeyPressEvent(
            (ev) => ev.code === 'ArrowDown',
            null,
            (ev) => {
                if (document.activeElement === inputRef.current) {
                    setValue((value || startTime).add(increment, 'minutes'))
                }
            }
        )

        const [ fieldValue, setFieldValue ] = useState<string>((value || startTime).format('h:mma'))

        useEffect(() => {
            setFieldValue((value || startTime).format('h:mma'))
        }, [ value ])

        const [ isOpen, setIsOpen ] = useState(false)
        const [ localValid, setLocalValid ] = useState(true)

        useEffect(() => {
            setLocalValid(!!extractHourMinuteInput(fieldValue))
        }, [ fieldValue ])

        useKeyPressEvent(
            (ev) => ev.code === 'Escape',
            null,
            () => setIsOpen(false)
        )

        return (
            <>
                <Tippy
                    delay={[ null, 300 ]}
                    visible={isOpen}
                    trigger='manual'
                    placement='bottom-start'
                    interactive
                    allowHTML
                    animation='shift-away'
                    inertia
                    appendTo={document.getElementById('root') || undefined}
                    sticky='reference'
                    onShown={() => { if (ref) ref.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' }) }}
                    content={
                        <List className={styles.timeList} >
                            {times.map(time =>
                                <ListItem
                                    key={`${pickerId.current}-${time.format('h:mma')}`}
                                    id={`${pickerId.current}-${time.format('h:mma')}`}
                                    dataset-value={time}
                                    className={clsx(styles.timeListItem, value && time.isSame(value, 'minutes') && styles.timeListItemSelected)}
                                    onClick={() => {
                                        setValue(time)
                                        setIsOpen(false)
                                    }}
                                    ref={(_ref) => {
                                        if (value && time.isSame(value, 'minutes')) setRef(_ref)
                                    }}
                                >
                                    {time.format('h:mma')} {durationFromTime && (time.minute() === 0 || time.minute() === 30 || time.isSameOrBefore(startTime.add(1, 'hour'))) ? `(${getLabelForMinutes(time.diff(durationFromTime, 'minutes'))})` : ''}
                                </ListItem>
                            )}
                        </List>
                    }

                >
                    <FormControl variant='filled' error={touched && Boolean(error)} style={style}>
                        <TextField
                            inputRef={inputRef}
                            className='compactInput'
                            style={{ width: 90 }}
                            variant='filled'
                            error={!localValid || (touched && !!error) || !!initialError}
                            value={fieldValue}
                            onChange={(ev) => {
                                setFieldValue(ev.currentTarget.value)
                            }}
                            onBlur={(ev) => {
                                onBlur(ev)
                                const testedValue = extractHourMinuteInput(ev.currentTarget.value)
                                if (testedValue) {
                                    setValue(dayjs(testedValue, 'h:mma'))
                                } else {
                                    setFieldValue((value || startTime).format('h:mma'))
                                }

                                // Give the popup a second to register the click
                                // setTimeout(() => {
                                //     setIsOpen(false)
                                // }, 500)
                            }}
                            onFocus={() => setIsOpen(true)}
                            onClick={() => setIsOpen(true)}
                        />
                        {touched && error && <FormHelperText style={{ marginLeft: 0 }}>{error}</FormHelperText>}

                    </FormControl>
                </Tippy>
            </>
        )
    }
)