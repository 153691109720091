import { createStyles, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import dayjs from 'dayjs'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { NewsletterFragment } from '../graphql/autogenerate/operations'
import { ImageDisplay } from './image-display'

const useStyles = (hasCoverImage?: boolean) => makeStyles(theme =>
    createStyles({
        headerContainer: {
            position: 'relative',
            marginBottom: theme.spacing(1)
        },
        coverImage: {
            height: 250,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
        title: {
            padding: theme.spacing(1),
            paddingTop: 0,
            ...hasCoverImage ? {} : {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                paddingTop: theme.spacing(1)
            }
        },
        subtitle: {
            marginLeft: theme.spacing(2),
            color: theme.palette.grey[ 700 ],
            marginBottom: theme.spacing(1),
            ...hasCoverImage ? {} : {
                color: theme.palette.primary.contrastText,
                marginBottom: theme.spacing(8)
            }
        },
    })
)



interface INewsletterHeaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    newsletter: NewsletterFragment
}

export const NewsletterHeader = ({ newsletter, ...rest }: INewsletterHeaderProps) => {
    const styles = useStyles(Boolean(newsletter.coverImage))()
    const phoneAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <div className={styles.headerContainer} {...rest}>
            {newsletter.coverImage &&
                <ImageDisplay
                    style={{ ...phoneAndDown ? { objectFit: 'contain', height: 'unset' } : { height: 250 } }}
                    className={styles.coverImage}
                    src={newsletter.coverImage?.temporaryDownloadUrl}
                    alt='cover image'
                />
            }

            <div className={styles.title}>
                <Typography className={styles.subtitle}>
                    {dayjs(newsletter.publishDate || dayjs()).format('dddd, MMMM D, YYYY')}
                </Typography>
                <Typography align='center' variant={phoneAndDown ? 'h5' : 'h2'}>{newsletter.title}</Typography>
            </div>

            <div className='fading-divider' />
        </div>
    )
}