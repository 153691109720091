import { Navigate, Route, useLocation } from 'react-router-dom'
import { useAppState, useSetPageTitle } from '../hooks'
import { NoAccess } from './no-access'

type RouteProps = Parameters<typeof Route>[ 0 ]

export interface IGuardedRouteProps extends RouteProps {
    title?: string
    guard?: boolean
    redirect?: string
}

export const GuardedRoute = ({ element, guard, redirect, title, ...rest }: IGuardedRouteProps) => {
    useSetPageTitle(title, [ title ])

    if (!guard) {
        if (redirect) {
            return <Navigate to={redirect} replace />
        } else {
            element = <NoAccess />
        }
    }

    return (
        <Route {...rest} element={element} />
    )
}

export const AuthGuardedRoute = (props: RouteProps) => {
    const location = useLocation()
    const { state: { authed } } = useAppState()
    return <GuardedRoute {...props} redirect={`/login?redirectUrl=${location.pathname}`} guard={Boolean(authed)} />
}