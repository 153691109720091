import { makeStyles, createStyles } from '@material-ui/core'
import React from 'react'
import { CalendarEventCell } from './calendar-event-cell'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { withContextSelector } from '../helpers'
import { ICalendarEventCell, useCalendarContext } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

interface ICalendarWeekAllDayEventsProps extends ICalendarDispatchProp {
    events: ICalendarEventCell[]
    selectedDate?: Dayjs
}

export interface ICalendarEventCellAllDay {
    startsBeforeWeek: boolean
    endsAfterWeek: boolean
}

interface IAllDayEvent extends ICalendarEventCell, ICalendarEventCellAllDay {

}

const AllDayEvents = React.memo(({ events, selectedDate, dispatch }: ICalendarWeekAllDayEventsProps) => {
    if (!selectedDate) throw new Error('Error: cannot use all day events for week without a selectedDate.')

    const firstDayOfWeek = selectedDate.startOf('week').utc()
    const lastDayOfWeek = selectedDate.endOf('week').utc()

    const allDayEvents: IAllDayEvent[] = events.filter(o => o.allDay).map(event => ({
        ...event,
        // Detect if the event's start and/or end date is outside this week.
        startsBeforeWeek: event.startDate.isBefore(firstDayOfWeek),
        endsAfterWeek: event.endDate.isAfter(lastDayOfWeek),
    })) || []

    /* 
        A little explanation for this bit:
        - We need to know the max number of all day events spanning a day
        - Create an array of 7 numbers, one for each day of the week
        - Then iterate over all the events and, for each day of the week that event spans, increment it by 1
            - Note: we check if the event starts before this week, in which case we start with the first day of the week, 0
                    and if the event ends after the last day of the week, in which case we go through the last day of the week, 6.
        - Then, take that array of 7 numbers and get the max number
    */
    const dayEventCounts = [
        firstDayOfWeek.dayOfYear(),
        firstDayOfWeek.add(1, 'day').dayOfYear(),
        firstDayOfWeek.add(2, 'day').dayOfYear(),
        firstDayOfWeek.add(3, 'day').dayOfYear(),
        firstDayOfWeek.add(4, 'day').dayOfYear(),
        firstDayOfWeek.add(5, 'day').dayOfYear(),
        firstDayOfWeek.add(6, 'day').dayOfYear()
    ]
    dayEventCounts.forEach((dayOfYear, index) => {

        // Get all of the events that "pass over" this day of the year
        const eventsOnThisDay = allDayEvents.filter(e => {
            const eUtcStartDayOfYear = e.startDate.utc().dayOfYear()
            const eUtcEndDayOfYear = e.endDate.utc().dayOfYear()

            if (eUtcStartDayOfYear <= dayOfYear && eUtcEndDayOfYear >= dayOfYear) return true
        })
        dayEventCounts[ index ] = eventsOnThisDay.length
    })

    const maxStackedAllDayEventsCurrentlyDisplayed = Math.max(...dayEventCounts)

    const styles = makeStyles(theme =>
        createStyles({
            container: {
                display: 'grid',
                gridTemplateRows: `repeat(${maxStackedAllDayEventsCurrentlyDisplayed}, 25px) ${theme.spacing(.5)}px`,
                gridTemplateColumns: '50px 10px repeat(7, 1fr)',
            },
            borderRight: {
                borderRight: `1px solid ${theme.palette.grey[ 300 ]}`,
            },
            borderBottom: {
                borderBottom: `1px solid ${theme.palette.grey[ 300 ]}`,
            },
        })
    )()

    return (
        <div className={styles.container}>
            {allDayEvents.map((o, index, array) => (
                <div
                    key={o.id}
                    style={{
                        gridArea: getEventGridPlacement(o, array),
                        overflow: 'hidden',
                        zIndex: 10
                    }}
                >
                    <CalendarEventCell event={o} dispatch={dispatch} />
                </div>
            ))}

            {/* Column Grid Lines */}
            <div style={{ gridArea: `${1} / 2 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 3` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 3 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 4` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 4 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 5` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 5 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 6` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 6 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 7` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 7 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 8` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 8 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 9` }} className={clsx(styles.borderRight, styles.borderBottom)} />
            <div style={{ gridArea: `${1} / 9 / ${2 + maxStackedAllDayEventsCurrentlyDisplayed} / 10` }} className={clsx(styles.borderBottom)} />
        </div>
    )
})

export const CalendarWeekAllDayEvents = withContextSelector({
    MemoizedComponent: AllDayEvents,
    getContext: useCalendarContext,
    selector: ({ state: { selectedDate } }) => ({ selectedDate })
})

const getEventGridPlacement = (event: IAllDayEvent, events: IAllDayEvent[]) => {
    /* 
        The event's row is determined by how many other all day events there are on the same day
        - Events starting/ending before/after the day come before events that start/end the same day

        Also, see comments on the mobile app's logic for all day event start/end date calculation (school-calendar.tsx)
    */

    const utcStart = event.startDate.utc()
    const utcStartDayOfYear = utcStart.dayOfYear()
    const utcEnd = event.endDate.utc()
    const utcEndDayOfYear = utcEnd.dayOfYear()


    let multipleEventsSameDay = events.filter(e => {
        const eUtcStartDayOfYear = e.startDate.utc().dayOfYear()
        const eUtcEndDayOfYear = e.endDate.utc().dayOfYear()

        // We have to compare both ways: if the event being placed "contains" the event we're comparing to and visa versa

        // The event we're comparing starts/ends on the same day the event being placed on the grid (most common)
        if (eUtcStartDayOfYear <= utcStartDayOfYear && eUtcEndDayOfYear >= utcEndDayOfYear) return true

        // The event we're comparing starts same or before and ends same or after the event being placed on the grid
        if (utcStartDayOfYear <= eUtcStartDayOfYear && utcEndDayOfYear >= eUtcEndDayOfYear) return true

        return false
    })

    let row = 1
    if (multipleEventsSameDay.filter(e => e.id !== event.id).length) {
        row = multipleEventsSameDay.findIndex(e => e.id === event.id) + 1
    }

    return `${row} / ${(event.startsBeforeWeek ? 0 : utcStart.add(1, 'minute').day()) + 3} / ${row + 1} / ${(event.endsAfterWeek ? 10 : (utcEnd.subtract(1, 'day').day())) + 4}`
}

