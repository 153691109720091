import { createStyles, makeStyles, Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        tooltipTop: {
            marginBottom: 5,
        },
        tooltipRight: {
            marginLeft: 5,
        },
        tooltipBottom: {
            marginTop: 5,
        },
        tooltipLeft: {
            marginRight: 5,
        },
    })
)

export const Tooltip = (props: TooltipProps) => {
    const styles = useStyles()

    return (
        <MuiTooltip
            {...props}

            // Have to apply these manually here since global theme override in the <ThemeProvider /> doesn't seem to work...
            classes={{
                tooltipPlacementTop: styles.tooltipTop,
                tooltipPlacementRight: 'tooltipRightPlacementFix',
                tooltipPlacementBottom: styles.tooltipBottom,
                tooltipPlacementLeft: 'tooltipLeftPlacementFix',
            }}
        >
            {props.children}
        </MuiTooltip>
    )
}