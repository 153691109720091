import { useTheme } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { SchoolNavBar } from './school-nav-bar'

interface IMobileViewContainer {
    children: ReactNode
    onBackPressed?: () => void
    backLabel?: string
    title?: string
}

export const MobileViewContainer = ({ children, title, backLabel, onBackPressed }: IMobileViewContainer) => {
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
            <SchoolNavBar style={{ padding: `0px ${theme.spacing(1)}px`, justifyContent: 'flex-start' }}>
                <div
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                        if (onBackPressed) {
                            onBackPressed()
                        } else {
                            navigate(-1)
                        }
                    }}
                >
                    <ArrowBackIos />
                    <span>{backLabel || 'Back'}</span>
                </div>
                <span>
                    {title}
                </span>

                <ArrowBackIos style={{ opacity: 0, flex: 1 }} />
            </SchoolNavBar>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', paddingBottom: 55 }}>
                {children}
            </div>
        </div >
    )
}