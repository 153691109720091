import { Button } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { PageSectionType } from '../graphql/autogenerate/schemas'
import { PageSectionTitleForm } from './page-section-title-form'
import { Modal, useModal } from './modal'
import { PageSectionTextForm } from './page-section-text-form'
import { PageSectionButtonForm } from './page-section-button-form'
import { PageSectionPictureForm } from './page-section-picture-form'
import { PageSectionVideoForm } from './page-section-video-form'
import { usePageContext } from './page-edit'
import { PageSectionAttachmentForm } from './page-section-attachment-form'

export interface IPageSectionFormProps {
	modal: ReturnType<typeof useModal>
	pageSectionToEdit?: PageSectionFragment
	newPageSection?: {
		sortOrder?: number
		type: PageSectionType
	}
}

const getPageSectionTypeName = (type: PageSectionType) => {
	switch (type) {
		case PageSectionType.Button:
			return 'Button'
		case PageSectionType.Picture:
			return 'Image'
		case PageSectionType.Text:
			return 'Rich Text'
		case PageSectionType.Title:
			return 'Title'
		case PageSectionType.Video:
			return 'Video/Podcast'
		case PageSectionType.Attachment:
			return 'Attachments'
	}
}

export const PageSectionForm = ({
	modal,
	pageSectionToEdit,
	newPageSection,
}: IPageSectionFormProps) => {
	const { setState } = usePageContext()

	useEffect(() => {
		if (pageSectionToEdit || (newPageSection && !modal.props.isOpen)) {
			modal.open()
		} else if (modal.props.isOpen) {
			modal.close()
		}
	}, [pageSectionToEdit, newPageSection])

	const submitFormRef = useRef<() => Promise<any>>()
	const loadingRef = useRef<boolean>(false)

	const type = pageSectionToEdit?.type || newPageSection?.type
	if (!type) return null

	return (
		<Modal
			{...modal.props}
			title={`${pageSectionToEdit ? 'Edit' : 'Add'} ${getPageSectionTypeName(
				type
			)} Section`}
			size={type === PageSectionType.Picture ? 'md' : 'sm'}
			closeButton
			dividers={Boolean(submitFormRef.current)}
			actions={
				<>
					{submitFormRef.current && (
						<Button
							style={{ minWidth: 100 }}
							variant='contained'
							color='primary'
							onClick={submitFormRef.current}
							disabled={loadingRef.current}
						>
							Save
						</Button>
					)}
				</>
			}
			afterClose={() =>
				setState(_state => ({
					..._state,
					newPageSection: undefined,
					pageSectionToEdit: undefined,
				}))
			}
		>
			{type === PageSectionType.Title && (
				<PageSectionTitleForm submitFormRef={submitFormRef} />
			)}
			{type === PageSectionType.Text && (
				<PageSectionTextForm submitFormRef={submitFormRef} />
			)}
			{type === PageSectionType.Picture && (
				<PageSectionPictureForm submitFormRef={submitFormRef} />
			)}
			{type === PageSectionType.Button && (
				<PageSectionButtonForm submitFormRef={submitFormRef} />
			)}
			{type === PageSectionType.Video && (
				<PageSectionVideoForm submitFormRef={submitFormRef} />
			)}
			{type === PageSectionType.Attachment && (
				<PageSectionAttachmentForm
					submitFormRef={submitFormRef}
					loadingRef={loadingRef}
				/>
			)}
		</Modal>
	)
}
