import { Button, Typography, useTheme } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useRef, useState } from 'react'
import { useKeyPressEvent } from 'react-use'
import * as yup from 'yup'
import { DoesPersonExistQuery } from '../graphql/autogenerate/operations'
import {
	useAddExternalPersonToSchoolMutation,
	useCreatePersonForSchoolMutation,
	useDoesPersonExistQuery,
	useInvitePersonToSchoolMutation,
	useSetSchoolAdminGroupsForPersonMutation,
	useUpdateSchoolPersonProfileAndSchoolPropertiesMutation,
} from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import { getLabelForUserType } from '../helpers'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { Callout } from './callout'
import { ConfirmDialog } from './confirm-dialog'
import { ContactCard } from './contact-card'
import {
	FileFieldValue,
	FormikEffect,
	FormikGroupSelector,
	FormikImageField,
	FormikPhoneField,
	FormikTextInput,
	useImageFieldChangeHandler,
} from './forms'
import { FormikRadio } from './forms/formik-radio'
import { FormikRichText } from './forms/formik-rich-text'
import { LoadingButton } from './loading-button'
import { Modal, useModal } from './modal'
import { UserPermissionsDescription } from './user-permissions-descriptor'

export enum InvitationStatus {
	not_invited,
	invited,
	accepted,
}

interface IState {
	step: number
	newImage?: string
	personUpdated?: boolean
	status: 'adding' | 'editing'

	// State to track person conflicts
	personEmailToCheck?: string
	existingPersonQueryResults?: DoesPersonExistQuery
	existingPersonId?: string
	existingPersonHasAccount?: boolean
	existingPersonSchoolPersonId?: string
	existingPersonInvitationStatus?: InvitationStatus
}

export interface IAddPersonModalProps {
	userType: UserType // Which user type this form is for
	modal: ReturnType<typeof useModal>
	afterClose: (personUpdated?: boolean) => void
}

const EMAIL_STEP = 1
const DETAILS_STEP = 2
const PERMISSIONS_STEP = 3
const CONFIRM_STEP = 4
const INVITE_STEP = 5
const CONFIRM_INVITE_STEP = 6

const FIRST_STEP = EMAIL_STEP
const LAST_STEP = CONFIRM_INVITE_STEP

export const AddPersonModal = ({
	modal,
	afterClose,
	userType,
}: IAddPersonModalProps) => {
	const theme = useTheme()

	const { enqueueSnackbar } = useSnackbar()

	const {
		state: {
			school: {
				divisions: { nodes: divisions },
				id: schoolId,
				name: schoolName,
			},
			groups,
		},
	} = useSchoolContext()

	const [state, setState] = useState<IState>({ step: 1, status: 'adding' })
	const {
		step,
		newImage,
		existingPersonQueryResults,
		existingPersonId,
		existingPersonHasAccount,
		existingPersonSchoolPersonId,
		existingPersonInvitationStatus,
	} = state

	const initialValues = {
		title: '',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		bio: '',
		userType,
		facultyStaffAlsoParent: 'no' as 'yes' | 'no',
		includeInDirectory: 'yes' as 'yes' | 'no',
		profileImage: undefined as FileFieldValue,
		permissionGroupIds: [] as string[],
	}

	const formikRef = useRef<FormikProps<typeof initialValues>>(null)

	const confirmCancel = useModal()

	const doesPersonExistQuery = useHandleReactQuery(
		useDoesPersonExistQuery(
			{ email: state.personEmailToCheck!, schoolId },
			{
				enabled: Boolean(state.personEmailToCheck),
				onSuccess: data => {
					let invitationStatus: InvitationStatus = InvitationStatus.not_invited
					const personSchoolInfo = data.schoolPerson?.schoolInfo.nodes.find(
						o => o.schoolId === schoolId
					)
					if (personSchoolInfo) {
						if (personSchoolInfo.schoolPersonInvitation)
							invitationStatus = InvitationStatus.invited
						if (personSchoolInfo.schoolPersonInvitation?.accepted)
							invitationStatus = InvitationStatus.accepted
					}

					setState(_state => ({
						..._state,
						existingPersonId:
							data.personWithAccount?.id || data.schoolPerson?.id,
						existingPersonHasAccount: Boolean(data?.personWithAccount),
						existingPersonSchoolPersonId: personSchoolInfo?.id,
						existingPersonInvitationStatus: invitationStatus,
						existingPersonQueryResults: data,

						// If we got a person we need to stay on the current step so the user can decide what to do, otherwise, we can proceed to the next step.
						step:
							data?.personWithAccount || data?.schoolPerson
								? _state.step
								: _state.step + 1,
						// We immediately set the personEmailToCheck to undefined so this query doesn't run again and trigger the onSuccess callback, possibly jacking up our steps progression.
						// Also, FYI, setting this to undefined apparently immediately clears the "data" results property on this query.
						personEmailToCheck: undefined,
					}))
				},
			}
		)
	)

	const { mutateAsync: createPerson, isLoading } = useHandleReactQueryMutation(
		useCreatePersonForSchoolMutation({
			onSuccess: () => {
				enqueueSnackbar('Person successfully added.', { variant: 'success' })
			},
		})
	)
	const fileChangeHandler = useImageFieldChangeHandler()

	const { mutateAsync: addExternalPersonToSchool } =
		useHandleReactQueryMutation(
			useAddExternalPersonToSchoolMutation({
				onSuccess: () => {
					enqueueSnackbar('Person successfully added.', { variant: 'success' })
				},
			})
		)

	const { mutateAsync: updateExistingSchoolPerson } =
		useHandleReactQueryMutation(
			useUpdateSchoolPersonProfileAndSchoolPropertiesMutation({
				onSuccess: () => {
					enqueueSnackbar('Person successfully updated.', {
						variant: 'success',
					})
				},
			})
		)

	const { mutate: invitePersonToSchool } = useHandleReactQueryMutation(
		useInvitePersonToSchoolMutation({
			onSuccess: () => {
				enqueueSnackbar('Person successfully invited.', { variant: 'success' })
			},
		})
	)

	const { mutateAsync: setAdminGroups } = useHandleReactQueryMutation(
		useSetSchoolAdminGroupsForPersonMutation()
	)

	const onNextPressed = useCallback(async () => {
		if (!formikRef.current) return
		const { setTouched, validateForm, values, setValues, submitForm } =
			formikRef.current

		const errors = await validateForm()

		switch (step) {
			case EMAIL_STEP:
				if (!Boolean(existingPersonId)) {
					setTouched({ email: true }, false)

					if (!Object.keys(errors).includes('email')) {
						// By setting the personEmailToCheck we fire off the doesPersonExistQuery query. It's onSuccess callback handles the next steps.
						setState(_state => ({
							..._state,
							personEmailToCheck: values.email,
						}))
					}
				}
				// External person not from this school
				else if (existingPersonHasAccount && !existingPersonSchoolPersonId) {
					// Set the first/last name values in prep for the confirm step
					setValues({
						...values,
						firstName:
							existingPersonQueryResults?.personWithAccount?.firstName || '',
						lastName:
							existingPersonQueryResults?.personWithAccount?.lastName || '',
					})
					setState(_state => ({ ..._state, step: DETAILS_STEP }))
				}
				break
			case DETAILS_STEP:
				setTouched(
					{
						title: true,
						firstName: true,
						lastName: true,
						bio: true,
						userType: true,
						profileImage: true,
						facultyStaffAlsoParent: true,
					},
					false
				)
				if (Object.keys(errors).length === 0)
					setState(_state => ({ ..._state, step: _state.step + 1 }))
				break
			case PERMISSIONS_STEP:
				setTouched({ permissionGroupIds: true }, false)
				if (Object.keys(errors).length === 0)
					setState(_state => ({ ..._state, step: _state.step + 1 }))
				break
			case CONFIRM_STEP:
				submitForm()
				break
			case INVITE_STEP:
				modal.close()
				break
			default:
				break
		}
	}, [
		step,
		existingPersonHasAccount,
		existingPersonSchoolPersonId,
		existingPersonId,
		existingPersonQueryResults,
	])
	useKeyPressEvent('Enter', onNextPressed)

	return (
		<Formik
			innerRef={formikRef}
			initialValues={initialValues}
			validationSchema={yup.object({
				firstName: yup.string().required('Required'), // first/last only required once we have email and not if we have an existing user
				lastName: yup.string().required('Required'),
				email: yup
					.string()
					.required('Required')
					.email('Please enter a valid email address.'),
				userType: yup.string().nullable().required('Required'),
			})}
			onSubmit={async ({ profileImage, ...values }) => {
				if (existingPersonId) {
					if (existingPersonHasAccount && !existingPersonSchoolPersonId) {
						const results = await addExternalPersonToSchool({
							schoolId,
							personId: existingPersonId,
							userTypes: [
								values.userType,
								...(values.facultyStaffAlsoParent === 'yes'
									? [UserType.Parent]
									: []),
							],
							includeInDirectory:
								values.includeInDirectory === 'yes' ? true : false,
						})

						await setAdminGroups({
							personId: existingPersonId,
							schoolId,
							groupIds: values.permissionGroupIds,
						})

						setState(_state => ({
							..._state,
							step: INVITE_STEP,
							personUpdated: Boolean(results.createSchoolPerson?.schoolPerson),
							existingPersonSchoolPersonId:
								results.createSchoolPerson?.schoolPerson?.id,
						}))
					} else if (existingPersonSchoolPersonId) {
						// If there is an existing person ID and existingPersonHasAccount is FALSE then it's definitely a Person that's already been added to this school
						await updateExistingSchoolPerson({
							...values,
							id: existingPersonId,
							schoolPersonId: existingPersonSchoolPersonId,
							userTypes: [
								values.userType,
								...(values.facultyStaffAlsoParent === 'yes'
									? [UserType.Parent]
									: []),
							],
							includeInDirectory:
								values.includeInDirectory === 'yes' ? true : false,
							...(await fileChangeHandler({
								oldValue: undefined,
								newValue: profileImage,
								filePropertyName: 'profileImage',
							})),
							phone: values.phone.length ? values.phone : null,
							bio: values.bio.length ? values.bio : null,
						})

						await setAdminGroups({
							personId: existingPersonId,
							schoolId,
							groupIds: values.permissionGroupIds,
						})

						if (
							existingPersonInvitationStatus === InvitationStatus.not_invited
						) {
							setState(_state => ({
								..._state,
								step: INVITE_STEP,
								personUpdated: true,
							}))
						} else {
							modal.close()
						}
					}
				} else {
					const results = await createPerson({
						...values,
						schoolId,
						userTypes: [
							values.userType,
							...(values.facultyStaffAlsoParent === 'yes'
								? [UserType.Parent]
								: []),
						],
						includeInDirectory:
							values.includeInDirectory === 'yes' ? true : false,
						...(await fileChangeHandler({
							oldValue: undefined,
							newValue: profileImage,
							filePropertyName: 'profileImage',
						})),
						phone: values.phone.length ? values.phone : null,
						bio: values.bio.length ? values.bio : null,
					})

					if (results.createPerson?.person)
						await setAdminGroups({
							personId: results.createPerson.person.id,
							schoolId,
							groupIds: values.permissionGroupIds,
						})

					setState(_state => ({
						..._state,
						step: INVITE_STEP,
						personUpdated: Boolean(results.createPerson?.person),
						existingPersonId: results.createPerson?.person?.id,
						existingPersonSchoolPersonId:
							results.createPerson?.person?.schoolPeople.nodes.find(
								o => o.schoolId === schoolId
							)?.id,
					}))
				}
			}}
		>
			{formikProps => {
				const { values, resetForm, setValues } = formikProps
				return (
					<>
						<Modal
							{...modal.props}
							title={(() => {
								let title = `${state.status === 'editing' ? 'Edit' : 'Add'} ${
									(values.userType && getLabelForUserType(values.userType)) ||
									'Person'
								}`
								switch (step) {
									case EMAIL_STEP:
									case DETAILS_STEP:
										return `${title}: Details`
									case PERMISSIONS_STEP:
										return `${title}: Permissions`
									case CONFIRM_STEP:
										return `${title}: Confirm`
									case INVITE_STEP:
										return `${title}: Invite`
								}
								return title
							})()}
							dividers
							size='sm'
							afterClose={() => {
								afterClose(state.personUpdated)
								resetForm()
								setState({ step: FIRST_STEP, status: 'adding' })
							}}
							actions={
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flex: 1,
									}}
								>
									{step < INVITE_STEP && (
										<Button
											style={{ minWidth: 100 }}
											type='button'
											variant='text'
											onClick={confirmCancel.open}
										>
											Cancel
										</Button>
									)}
									{step === INVITE_STEP && (
										<Button
											style={{ minWidth: 100 }}
											type='button'
											variant='text'
											onClick={modal.close}
										>
											Invite Later
										</Button>
									)}

									<div>
										{step !== FIRST_STEP && step < INVITE_STEP && (
											<Button
												type='button'
												style={{ minWidth: 100, marginRight: theme.spacing(1) }}
												variant='text'
												onClick={() =>
													setState(_state => ({
														..._state,
														step: _state.step - 1,
														// If we're going back to the email step, clear all existing person query state
														...(_state.step === EMAIL_STEP + 1
															? {
																	existingPersonQueryResults: undefined,
																	existingPersonId: undefined,
																	existingPersonHasAccount: undefined,
																	existingPersonSchoolPersonId: undefined,
																	existingPersonInvitationStatus: undefined,
																	status: 'adding',
															  }
															: {}),
													}))
												}
											>
												Back
											</Button>
										)}

										{step === EMAIL_STEP && (
											<>
												{!Boolean(existingPersonId) ? (
													<LoadingButton
														type='button'
														variant='outlined'
														color='primary'
														style={{ minWidth: 100 }}
														loading={doesPersonExistQuery.isLoading}
														onClick={onNextPressed}
													>
														Next
													</LoadingButton>
												) : (
													// OK...we have an existing person.
													<>
														{/* External person not from this school */}
														{existingPersonHasAccount &&
															!existingPersonSchoolPersonId && (
																<Button
																	type='button'
																	style={{ minWidth: 100 }}
																	variant='outlined'
																	onClick={onNextPressed}
																>
																	Configure & Invite
																</Button>
															)}
													</>
												)}
											</>
										)}

										{step === DETAILS_STEP && (
											<Button
												type='button'
												style={{ minWidth: 100 }}
												variant='outlined'
												color='primary'
												onClick={onNextPressed}
											>
												Next
											</Button>
										)}

										{step === PERMISSIONS_STEP && (
											<Button
												type='button'
												style={{ minWidth: 100 }}
												variant='outlined'
												color='primary'
												onClick={onNextPressed}
											>
												Next
											</Button>
										)}

										{step === CONFIRM_STEP && (
											<LoadingButton
												loading={isLoading}
												type='button'
												style={{ minWidth: 100 }}
												variant='contained'
												color='primary'
												onClick={onNextPressed}
											>
												Save
											</LoadingButton>
										)}

										{step === INVITE_STEP && (
											<Button
												type='button'
												style={{ minWidth: 100 }}
												variant='contained'
												color='primary'
												onClick={() => {
													if (existingPersonSchoolPersonId)
														invitePersonToSchool(
															{ schoolPersonId: existingPersonSchoolPersonId },
															{
																onSuccess: () =>
																	setState(_state => ({
																		..._state,
																		step: CONFIRM_INVITE_STEP,
																	})),
															}
														)
												}}
											>
												Send Invite
											</Button>
										)}

										{step === CONFIRM_INVITE_STEP && (
											<Button
												type='button'
												style={{ minWidth: 100 }}
												variant='text'
												color='primary'
												onClick={modal.close}
											>
												Done
											</Button>
										)}
									</div>
								</div>
							}
						>
							<Form style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										display: step === EMAIL_STEP ? 'flex' : 'none',
										flexDirection: 'column',
									}}
								>
									<Typography gutterBottom color='textSecondary'>
										Please enter the email address for the person you would like
										to add:
									</Typography>
									<div style={{ display: 'flex' }}>
										<FormikTextInput
											autoFocus
											hideHelperTextWhenEmpty
											fieldProps={{ name: 'email', label: 'Email' }}
										/>
									</div>

									<FormikEffect
										formikProps={formikProps}
										onChange={(prev, next) => {
											/* 
                                                As always...be INCREDIBLY careful setting state inside a formik effect. Form typing could trigger rapid, unnecessary, and possibly infinite rerenders depending on the logic.

                                                If...
                                                - There is an existing previous email value
                                                - The existing previous email value !== the new email value
                                                - We're on the EMAIL_STEP
                                                - There's an active "existing person search" in progress (existingPerson is set)
                                                
                                                Then...reset the existing person check so the user can trigger another search.
                                            */
											if (
												prev.email &&
												prev.email !== next.email &&
												step === EMAIL_STEP &&
												existingPersonId
											)
												setState(_state => ({
													..._state,
													existingPersonQueryResults: undefined,
													existingPersonId: undefined,
													existingPersonHasAccount: undefined,
													existingPersonSchoolPersonId: undefined,
													existingPersonInvitationStatus: undefined,
													status: 'adding',
												}))
										}}
									/>

									{existingPersonId && (
										<>
											{existingPersonSchoolPersonId &&
												existingPersonQueryResults?.schoolPerson && (
													<div style={{ margin: theme.spacing(0.5) }}>
														<Callout style={{ marginBottom: theme.spacing(1) }}>
															{existingPersonInvitationStatus ===
																InvitationStatus.accepted && (
																<div>
																	It looks like{' '}
																	{
																		existingPersonQueryResults.schoolPerson
																			.email
																	}{' '}
																	is already a member of {schoolName}.
																</div>
															)}

															{existingPersonInvitationStatus ===
																InvitationStatus.invited && (
																<div>
																	It looks like{' '}
																	{
																		existingPersonQueryResults.schoolPerson
																			.email
																	}{' '}
																	has already been invited to {schoolName}.
																</div>
															)}

															{existingPersonInvitationStatus ===
																InvitationStatus.not_invited && (
																<div>
																	It looks like{' '}
																	{
																		existingPersonQueryResults.schoolPerson
																			.email
																	}{' '}
																	has already been added to {schoolName}, but
																	not yet invited to create their account.
																</div>
															)}
														</Callout>
														<ContactCard
															{...existingPersonQueryResults.schoolPerson}
															profileImageUrl={
																existingPersonQueryResults.schoolPerson
																	.profileImage?.temporaryDownloadUrl
															}
															editIcon
															style={{ marginBottom: theme.spacing(1) }}
															onClick={() => {
																// Edit an existing person from this school.

																/* 
                                                                Grab their school info - we need to NOT assume that they'll have just one person__school join object.
                                                                They could be members of multiple schools. 
                                                                We need to find their settings for THIS school.
                                                            */
																const person =
																	existingPersonQueryResults?.schoolPerson
																const schoolInfo =
																	person?.schoolInfo.nodes.find(
																		o => o.schoolId === schoolId
																	)

																setValues({
																	...values,
																	title: person?.title || '',
																	firstName: person?.firstName || '',
																	lastName: person?.lastName || '',
																	phone: person?.phone || '',
																	bio: person?.bio || '',
																	facultyStaffAlsoParent: Boolean(
																		schoolInfo?.userTypes.includes(
																			UserType.Parent
																		)
																	)
																		? 'yes'
																		: 'no',
																	profileImage:
																		person?.profileImage || undefined,
																	permissionGroupIds:
																		person?.adminGroups.nodes.map(
																			o => o.groupId
																		) || [],
																})

																setState(_state => ({
																	..._state,
																	step: DETAILS_STEP,
																	status: 'editing',
																}))
															}}
														/>
													</div>
												)}

											{existingPersonHasAccount &&
												!existingPersonSchoolPersonId && (
													<div style={{ margin: theme.spacing(0.5) }}>
														<Callout
															style={{ marginBottom: theme.spacing(1) }}
															type='warning'
														>
															<div>
																<div
																	style={{ marginBottom: theme.spacing(0.5) }}
																>
																	It looks like{' '}
																	<b>
																		{
																			existingPersonQueryResults
																				?.personWithAccount?.email
																		}
																	</b>{' '}
																	is already associated with a user account.
																</div>
																<div
																	style={{ marginBottom: theme.spacing(0.5) }}
																>
																	Select <b>Configure & Invite</b> below to add
																	them to {schoolName}.
																</div>
																<div>
																	(Tip: if you just want someone to show up in
																	the directory, you can invite them without
																	giving any admin permissions.)
																</div>
															</div>
														</Callout>
														{existingPersonQueryResults?.personWithAccount
															?.firstName &&
															existingPersonQueryResults?.personWithAccount
																?.lastName && (
																<ContactCard
																	firstName={
																		existingPersonQueryResults.personWithAccount
																			.firstName
																	}
																	lastName={
																		existingPersonQueryResults.personWithAccount
																			.lastName
																	}
																	email={
																		existingPersonQueryResults.personWithAccount
																			.email
																	}
																/>
															)}
													</div>
												)}
										</>
									)}
								</div>

								<div
									style={{
										display: step === DETAILS_STEP ? 'flex' : 'none',
										flexDirection: 'column',
									}}
								>
									{existingPersonHasAccount &&
										existingPersonInvitationStatus !==
											InvitationStatus.accepted && (
											<Callout
												type='warning'
												style={{
													marginLeft: theme.spacing(0.5),
													marginRight: theme.spacing(0.5),
													marginBottom: theme.spacing(2),
												}}
											>
												<div>
													Since{' '}
													{existingPersonQueryResults?.personWithAccount?.email}{' '}
													has already created their profile, you won't be able
													to edit their profile until they've accepted an
													invitation to join {schoolName}.
												</div>
											</Callout>
										)}

									<div style={{ display: 'flex' }}>
										<FormikTextInput
											hideHelperTextWhenEmpty
											fieldProps={{
												name: 'title',
												label: 'Title',
												disabled: Boolean(
													existingPersonHasAccount &&
														existingPersonInvitationStatus !==
															InvitationStatus.accepted
												),
											}}
											style={{ marginRight: theme.spacing(2), maxWidth: 60 }}
										/>
										{/* Conditionally display this field so that the autoFocus can fire when we enter the DETAILS_STEP */}
										{step === DETAILS_STEP && (
											<FormikTextInput
												autoFocus
												hideHelperTextWhenEmpty
												fieldProps={{
													name: 'firstName',
													label: 'First name',
													disabled: Boolean(
														existingPersonHasAccount &&
															existingPersonInvitationStatus !==
																InvitationStatus.accepted
													),
												}}
												style={{ marginRight: theme.spacing(2) }}
											/>
										)}
										<FormikTextInput
											hideHelperTextWhenEmpty
											fieldProps={{
												name: 'lastName',
												label: 'Last name',
												disabled: Boolean(
													existingPersonHasAccount &&
														existingPersonInvitationStatus !==
															InvitationStatus.accepted
												),
											}}
										/>
									</div>

									<FormikPhoneField
										style={{
											margin: `0px ${theme.spacing(0.5)}px ${theme.spacing(
												0.5
											)}px`,
										}}
										field={{
											name: 'phone',
											label: 'Phone',
											disabled: Boolean(
												existingPersonHasAccount &&
													existingPersonInvitationStatus !==
														InvitationStatus.accepted
											),
										}}
									/>

									<FormikImageField
										field={{
											name: 'profileImage',
											label: 'Profile Picture',
											disabled: Boolean(
												existingPersonHasAccount &&
													existingPersonInvitationStatus !==
														InvitationStatus.accepted
											),
										}}
                                        aspectRatioHelper='Recommended dimensions: 500px by 500px'
										circleCrop
										style={{ margin: theme.spacing(0.5), marginBottom: 0 }}
										onImageProcessed={image =>
											setState(_state => ({
												..._state,
												newImage: URL.createObjectURL(image as unknown as Blob),
											}))
										}
									/>

									{values.userType === UserType.FacultyStaff && (
										<>
											<FormikRadio
												style={{ margin: `0px ${theme.spacing(0.5)}px` }}
												field={{
													name: 'facultyStaffAlsoParent',
													label: `Is ${
														values.firstName || 'this person'
													} also a school parent?`,
												}}
												options={[
													{ label: 'Yes', value: 'yes' },
													{ label: 'No', value: 'no' },
												]}
											/>
											<FormikRadio
												style={{
													margin: `0px ${theme.spacing(0.5)}px`,
													marginTop: theme.spacing(1),
												}}
												field={{
													name: 'includeInDirectory',
													label: `Include ${
														values.firstName || 'this person'
													} in the faculty/staff directory?`,
												}}
												options={[
													{ label: 'Yes', value: 'yes' },
													{ label: 'No', value: 'no' },
												]}
											/>
										</>
									)}
								</div>

								<div
									style={{
										display: step === PERMISSIONS_STEP ? 'flex' : 'none',
										flexDirection: 'column',
									}}
								>
									{/* We add this back in once we support authentication for all user types. */}
									{/* <FormikUserTypeRadioButton field={{ name: 'userType', label: 'User type' }} /> */}

									{values.userType === UserType.FacultyStaff && (
										<>
											<Typography
												color='textSecondary'
												style={{
													marginTop: theme.spacing(2),
													marginBottom: theme.spacing(1),
													marginLeft: theme.spacing(0.5),
													marginRight: theme.spacing(0.5),
												}}
											>
												Should {values.firstName || 'this user'} be able to
												manage any groups?
											</Typography>
											<FormikGroupSelector
												divisions={divisions}
												fieldProps={{
													name: 'permissionGroupIds',
													label: 'Select group(s)',
												}}
											/>
											<UserPermissionsDescription
												groupIds={values.permissionGroupIds}
												divisions={divisions}
												groups={groups}
												firstName={values.firstName}
											/>
										</>
									)}
								</div>

								{step === CONFIRM_STEP && (
									<>
										<ContactCard
											style={{ marginBottom: theme.spacing(2) }}
											{...values}
											profileImageUrl={newImage}
										/>

										<Typography
											style={{ display: 'flex', alignItems: 'center' }}
										>
											{values.facultyStaffAlsoParent == 'yes' ? (
												<Check />
											) : (
												<Close />
											)}
											<div>Also a parent</div>
										</Typography>
										<Typography
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: theme.spacing(2),
											}}
										>
											{values.includeInDirectory === 'yes' ? (
												<Check />
											) : (
												<Close />
											)}
											<div>Include in faculty/staff directory</div>
										</Typography>

										<UserPermissionsDescription
											persistentCallouts
											groupIds={values.permissionGroupIds}
											divisions={divisions}
											groups={groups}
											firstName={values.firstName}
										/>
									</>
								)}

								{step === INVITE_STEP && (
									<>
										<Typography gutterBottom variant='h6'>
											Person successfully{' '}
											{state.status === 'adding' ? 'added' : 'updated'}!
										</Typography>

										<Typography>
											Would you like to send <b>{values.firstName}</b> an
											invitation to set up their account with {schoolName}?
										</Typography>
									</>
								)}

								{step === CONFIRM_INVITE_STEP && (
									<>
										<Typography gutterBottom variant='h6'>
											Invitation sent to {values.firstName}.
										</Typography>

										<Typography>
											Tip: it may help to let {values.firstName} know to expect
											this invitation. Consider{' '}
											<a href={`mailto:${values.email}`}>
												sending {values.firstName} an email
											</a>{' '}
											to give them a heads up in case the invitation goes to
											their junk/spam folder.
										</Typography>
									</>
								)}
							</Form>
						</Modal>

						<ConfirmDialog
							{...confirmCancel.props}
							confirmButton={{
								label: 'Close the Form',
								type: 'warning',
							}}
							goBackButtonLabel='Go Back'
							body={
								<>
									<Typography gutterBottom>
										Are you sure you want to close the form?
									</Typography>
									<Typography>Your unsaved progress will be lost.</Typography>
								</>
							}
							confirm={modal.close}
						/>
					</>
				)
			}}
		</Formik>
	)
}
