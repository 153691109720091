import { HighlightOffOutlined } from '@material-ui/icons'
import axios from 'axios'
import { useField } from 'formik'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import {
	useDeleteTemporaryAwsS3UploadMutation,
	usePresignedUploadUrlMutation,
} from '../../graphql/autogenerate/react-query'
import { FileType } from '../../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../../hooks'
import { IField } from './interfaces'

// pintura
import {
	PinturaDefaultImageWriterOptions,
	PinturaEditorOptions,
	createDefaultImageOrienter,
	createDefaultImageReader,
	createDefaultImageWriter,
	legacyDataToImageState,
	locale_en_gb,
	markup_editor_defaults,
	markup_editor_locale_en_gb,
	openEditor,
	plugin_annotate,
	plugin_annotate_locale_en_gb,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_filter,
	plugin_filter_defaults,
	plugin_filter_locale_en_gb,
	plugin_finetune,
	plugin_finetune_defaults,
	plugin_finetune_locale_en_gb,
	processImage,

	// plugins
	setPlugins,
} from '@pqina/pintura'

// filepond
import {
	FormControl,
	FormHelperText,
	IconButton,
	useTheme,
} from '@material-ui/core'
import { AddPhotoAlternateOutlined } from '@material-ui/icons'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor'
import { FilePond, registerPlugin } from 'react-filepond'
import {
	FileFieldValue,
	getFileTypeForFile,
	isFileFragment,
} from './formik-file-helpers'

registerPlugin(
	FilePondPluginFileValidateType,
	FilePondPluginImageEditor,
	FilePondPluginFilePoster
)

interface IFormikImageFieldProps {
	field: IField
	immediatelyOpenEditor?: boolean
	enableAnotate?: boolean
	aspectRatio?: number
	aspectRatioHelper?: string
	style?: CSSProperties
	circleCrop?: boolean
	onImageProcessed?: (dest: string) => void
	imageCropLimitToImage?: boolean
}

export const FormikImageField = ({
	field,
	immediatelyOpenEditor,
	enableAnotate,
	aspectRatio,
	aspectRatioHelper,
	style,
	circleCrop,
	onImageProcessed,
	imageCropLimitToImage = true,
}: IFormikImageFieldProps) => {
	const theme = useTheme()

	useEffect(() => {
		const plugins = [
			plugin_crop,
			plugin_finetune,
			plugin_filter,
			...(enableAnotate ? [plugin_annotate] : []),
		]
		setPlugins(...plugins)
	}, [enableAnotate])

	const [formikField, meta, helpers] = useField<FileFieldValue>(field)

	const { mutateAsync: getPresignedUploadUrl } = useHandleReactQueryMutation(
		usePresignedUploadUrlMutation()
	)
	const { mutateAsync: deleteTemporaryUpload } =
		useDeleteTemporaryAwsS3UploadMutation()

	// We don't want to allow the form to submit until the server.process method finishes
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		helpers.setError(loading ? 'Uploading...' : undefined)
	}, [loading])

	const pondRef = useRef<FilePond | null>(null)

	/* 
        Set up a timer to revert all files if the uploader has been open for more than 20 days.
        Crazy, I know...but we delete temporary uploads after a month, so if a user just has had a random tab open for a month then saves something with what looks like a file attached, the file won't be there.
    */
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (pondRef.current) {
				pondRef.current.removeFiles(pondRef.current.getFiles(), {
					revert: true,
				})
			}
		}, 1000 * 60 * 60 * 24 * 20) // Every 20 days

		return () => {
			clearTimeout(timeout)
		}
	}, [])

	return (
		<FormControl
			disabled={field.disabled}
			error={meta.touched && Boolean(meta.error) && !loading}
			style={{ marginBottom: theme.spacing(1), ...style }}
		>
			{/* <FormLabel style={{ marginBottom: theme.spacing(1) }}>{field.label}</FormLabel> */}
			{isFileFragment(formikField.value) ? (
				<div
					style={{
						minHeight: 100,
						maxHeight: 256,
						backgroundColor: theme.palette.grey[200],
						borderRadius: theme.shape.borderRadius,
						marginBottom: theme.spacing(2),
						padding: theme.spacing(2),
						// Very strange but it's necessary to set paddingBottom again because of marginBottom above.
						paddingBottom: theme.spacing(2),
						display: 'flex',
					}}
				>
					<div
						style={{
							borderRadius: theme.shape.borderRadius,
							display: 'flex',
							justifyContent: 'center',
							flex: 1,
							backgroundColor: theme.palette.grey[900],
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								background:
									'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)',
								padding: theme.spacing(1),
								color: 'white',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<IconButton
								onClick={() => helpers.setValue(undefined)}
								style={{ color: 'white', padding: theme.spacing(1) }}
							>
								<HighlightOffOutlined />
							</IconButton>
							<div>{field.label}</div>
						</div>
						<img
							style={{ objectFit: 'contain', height: '100%' }}
							src={formikField.value.temporaryDownloadUrl}
						/>
					</div>
				</div>
			) : (
				<FilePond
					ref={pondRef}
					disabled={field.disabled}
					name={formikField.name}
					onupdatefiles={() => helpers.setTouched(true)}
					labelIdle={renderToString(
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '1.5rem',
							}}
						>
							{field.label}{' '}
							<AddPhotoAlternateOutlined
								style={{ marginLeft: theme.spacing(1), marginTop: 4 }}
							/>
						</div>
					)}
					// Immediatly open the editor if we're enforcing an aspect ratio. This way a user is forced to do at least an initial crop.
					imageEditorInstantEdit={Boolean(
						immediatelyOpenEditor || aspectRatio || circleCrop
					)}
					acceptedFileTypes={[
						'image/apng',
						'image/avif',
						'image/gif',
						'image/jpeg',
						'image/png',
						'image/svg+xml',
						'image/webp',
					]}
					/* @ts-ignore on roadmap to be fixed */
					filePosterMaxHeight={256}
					/* @ts-ignore on roadmap to be fixed */
					imageEditor={
						!formikField.value && {
							// map legacy data objects to new imageState objects
							legacyDataToImageState: legacyDataToImageState,

							// used to create the editor, receives editor configuration, should return an editor instance
							createEditor: (config: PinturaEditorOptions) => {
								if (circleCrop) config.willRenderCanvas = circularCropWillRenderCanvas
								const editor = openEditor({
									...config,
									imageCropAspectRatio: (circleCrop ? 1 : undefined) || aspectRatio,
									imageCropLimitToImage,
									enableDropImage: true,
									// We don't allow manually close the editor when we're requiring a specific aspect ratio. This helps ensure the user crops the image.
									enableButtonClose:
										!Boolean(aspectRatio || circleCrop) ||
										isFileFragment(formikField.value),
								})

								editor.on('hide', () => {
									// Go ahead and consider the loading process to start the minute
									setLoading(true)
								})

								if (onImageProcessed)
									editor.on('process', ({ dest }) => onImageProcessed(dest))

								return editor
							},

							// Required, used for reading the image data
							imageReader: [
								createDefaultImageReader,
								{
									/* optional image reader options here */
								},
							],

							// optionally. can leave out when not generating a preview thumbnail and/or output image
							imageWriter: [
								createDefaultImageWriter,
								{
									/* optional image writer options here */
									...(circleCrop ? circularCropPostProcessOptions : {}),
								},
							],

							// used to generate poster images, runs an editor in the background
							imageProcessor: processImage,

							// editor options
							editorOptions: {
								imageOrienter: createDefaultImageOrienter(),
								...plugin_finetune_defaults,
								...plugin_filter_defaults,
								...markup_editor_defaults,
								locale: {
									...locale_en_gb,
									...plugin_crop_locale_en_gb,
									...plugin_finetune_locale_en_gb,
									...plugin_filter_locale_en_gb,
									...plugin_annotate_locale_en_gb,
									...markup_editor_locale_en_gb,
								},
							},
						}
					}
					server={{
						process: (fieldName, file, metadata, load, error, progress, abort) => {
							const type = getFileTypeForFile(file)
							if (!type) {
								error('Unable to determine file type.')
								setLoading(false)
								return
							}

							const source = axios.CancelToken.source()
							getPresignedUploadUrl(
								{ filetype: FileType.Png },
								{
									onSuccess: async ({ presignedUploadUrl }) => {
										if (!presignedUploadUrl) {
											error('Error fetching an upload URL.')
											return
										}

										try {
											const response = await axios.put(presignedUploadUrl.url, file, {
												onUploadProgress: ev =>
													progress(ev.lengthComputable, ev.loaded, ev.total),
												cancelToken: source.token,
											})

											if (response.status >= 200 && response.status < 300) {
												helpers.setValue({
													filename: presignedUploadUrl.filename,
													type,
												})

												// the load method accepts either a string (id) or an object
												load(presignedUploadUrl.filename)
											} else {
												// Can call the error method if something is wrong, should exit after
												error(`Error uploading file.`)
											}
										} catch (e) {
											error(
												`Error uploading file: ${
													e instanceof Error ? e.message : 'unknown error'
												}`
											)
										}

										setLoading(false)
									},
									onError: () => {
										error(`Error fetching upload URL.`)
										setLoading(false)
									},
								}
							)

							return {
								abort: () => {
									// This function is entered if the user has tapped the cancel button
									source.cancel('Upload was canceled by FilePond.')

									setLoading(false)

									// Let FilePond know the request has been cancelled
									abort()
								},
							}
						},
						revert: (filename, load, error) => {
							deleteTemporaryUpload(
								{ filename },
								{
									onSuccess: () => {
										helpers.setValue(undefined)
										load()
									},
									onError: err => {
										error('Error deleting file.')
									},
								}
							)
						},

						/* 
                            For the FormikImageField we don't want to actually delete the file when a user removes it. 
                            We'll leave that to the parent form to decide/do.
                        */
						remove: (source, load, error) => {
							helpers.setValue(undefined)
							load()
						},
						load: (source, load, error) => {
							const request = new Request(source)
							fetch(request).then(async response => {
								const blob = await response.blob()
								load(blob)
							})
						},
					}}
				/>
			)}

			<FormHelperText
				error={Boolean(!loading && meta.touched && meta.error)}
				style={{ marginTop: '-1em' }}
			>
				{aspectRatioHelper}
				{!loading && meta.touched && meta.error}
			</FormHelperText>
		</FormControl>
	)
}

const circularCropWillRenderCanvas: PinturaEditorOptions['willRenderCanvas'] = (
	shapes,
	state
) => {
	const { utilVisibility, selectionRect, lineColor, backgroundColor } = state

	// Exit if crop utils is not visible
	if (utilVisibility.crop <= 0) return shapes

	// Get variable shortcuts to the crop selection rect
	const { x, y, width, height } = selectionRect

	return {
		// Copy all props from current shapes
		...shapes,

		// Now we add an inverted ellipse shape to the interface shapes array
		interfaceShapes: [
			{
				x: x + width * 0.5,
				y: y + height * 0.5,
				rx: width * 0.5,
				ry: height * 0.5,
				opacity: utilVisibility.crop,
				inverted: true,
				backgroundColor: [...backgroundColor, 0.5],
				strokeWidth: 1,
				strokeColor: [...lineColor],
			},
			// Spread all existing interface shapes onto the array
			...shapes.interfaceShapes,
		],
	}
}

const circularCropPostProcessOptions: PinturaDefaultImageWriterOptions = {
	// Scale down the output image
	targetSize: {
		width: 256,
		height: 256,
	},
	// Convert to PNG so masked area is transparent
	mimeType: 'image/png',

	// Run custom processing on the image data
	postprocessImageData: imageData =>
		new Promise(resolve => {
			// Create a canvas element to handle the imageData
			const canvas = document.createElement('canvas')
			canvas.width = imageData.width
			canvas.height = imageData.height
			const ctx = canvas.getContext('2d')
			if (!ctx) return
			ctx.putImageData(imageData, 0, 0)

			// Only draw image where we render our circular mask
			ctx.globalCompositeOperation = 'destination-in'

			// Draw our circular mask
			ctx.fillStyle = 'black'
			ctx.beginPath()
			ctx.arc(
				imageData.width * 0.5,
				imageData.height * 0.5,
				imageData.width * 0.5,
				0,
				2 * Math.PI
			)
			ctx.fill()

			// Returns the modified imageData
			resolve(ctx.getImageData(0, 0, canvas.width, canvas.height))
		}),
}
