interface IContextSelector<PropTypes, ContextType, InjectedStateSelection extends Partial<PropTypes>> {
    MemoizedComponent: React.FunctionComponent<PropTypes>,
    getContext: () => ContextType,
    selector: (context: ContextType, props: PropTypes) => InjectedStateSelection,
}

/** 
    For the `MemoizedComponent` argument, __ALWAYS__ pass in a component that has gone through `React.memo()`.

    @example
    const MemoizedComponent = React.memo(MY_COMPONENT_FROM_SOMEWHERE)
    export const MemoizedComponentWithContextValues = withContextSelector({
        MemoizedComponent: MemoizedComponent,
        getContext: useSomeContext,
        selector: (context, memoizedComponentProps) => {
            const some_context_value = context.value
            const some_prop_value = memoizedComponentProps.value
            if (some_prop_value === something_you_care_about) {
                return {
                    some_key_from_props_in_MY_COMPONENT_FROM_SOMEWHERE: some_context_value
                }
            } else {
                return {
                    some_key_from_props_in_MY_COMPONENT_FROM_SOMEWHERE: something_else
                }
            }
        }
    })
    
    @description
    HOC wrapper to assist with memoizing values from a context.

    Whenever you call `useContext()` in a component, it opens that component up to all sorts of rerender opportunities.

    Any time the value of the `Context` passed into `useContext()` mutates, the component has to rerender.

    Which makes sense. Otherwise how could the component update based on changes to values in the `Context`?

    Essentially, when it comes to triggering renders, it's good to think of calling `useContext()` as equivalent to passing in props.

    A workaround is a sort of "pitstop" at a **Container** component that is the one to call `useContext()`, then pass the desired values from the `Context` into a memoized **Child** component.

    This way the **Container** will render every time the `Context` mutates, but the **Child** will only rerender when the props passed into it from the **Container** are different than before.

    This is particularly useful any time you have a `Context` which might mutate a lot (i.e. lots of components dispatching to it).
*/
export const withContextSelector = <PropTypes, ContextType, InjectedStateSelection extends Partial<PropTypes>> ({
    MemoizedComponent,
    getContext,
    selector
}: IContextSelector<PropTypes, ContextType, InjectedStateSelection>) => (props: PropTypes) => {
    const selectedValue = selector(getContext(), props)

    return (
        <MemoizedComponent
            {...props}
            {...selectedValue}
        />
    )
}
