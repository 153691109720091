import { Box, Typography } from '@material-ui/core'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { Create, Check, Close } from '@material-ui/icons'
import { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormikTextInput } from './forms'
import { OnKeyPressEvent } from './on-key-press-event'
import { useBoop } from '../hooks'
import { useCommonStyles } from '../styles'
import { animated } from 'react-spring'
import { Variant } from '@material-ui/core/styles/createTypography'

interface IDivisionEditableTitleProps {
    title: string
    variant?: Variant
    onSave: ({ title }: { title: string }) => any | Promise<any>
}

export const EditableTitle = ({ title, variant, onSave, }: IDivisionEditableTitleProps) => {

    const [ editing, setEditing ] = useState(false)

    const hoverBoop = useBoop({ rotation: 15, timing: 100, })
    const onClickBoop = useBoop({ scale: 1.5, timing: 100, springConfig: { tension: 300, friction: 30 } })

    const commonStyles = useCommonStyles()

    return (
        <Formik
            initialValues={{ title }}
            validationSchema={Yup.object({
                title: Yup.string().required('Required')
            })}
            onSubmit={async (values) => {
                await onSave(values)
                setEditing(false)
            }}
        >
            {formikProps => (
                <>
                    {editing && <>
                        <OnKeyPressEvent keyPress='Enter' action={formikProps.submitForm} />
                        <OnKeyPressEvent
                            keyPress='Escape'
                            action={() => {
                                formikProps.resetForm()
                                setEditing(!editing)
                            }}
                        />
                    </>}
                    <Box display='flex' flex={1} alignItems='center' justifyContent='space-between' onMouseEnter={hoverBoop.trigger} onClick={() => setEditing(!editing)}>
                        <Typography variant={variant}>
                            {editing ?
                                <FormikTextInput
                                    variant='standard'
                                    textFieldOnly
                                    fieldProps={{ name: 'title', label: '' }}
                                    style={{ fontSize: 'inherit' }}
                                />
                                :
                                title
                            }
                        </Typography>
                        {editing ?
                            <Box display='flex'>
                                <Box ml={1}>
                                    <AnimationIconWraper className={commonStyles.successColorHover}>
                                        <Check onClick={formikProps.submitForm} />
                                    </AnimationIconWraper>
                                </Box>
                                <Box ml={1}>
                                    <AnimationIconWraper className={commonStyles.errorColorHover}>
                                        <Close
                                            onClick={() => {
                                                formikProps.resetForm()
                                                setEditing(!editing)
                                            }}
                                        />
                                    </AnimationIconWraper>
                                </Box>
                            </Box>
                            :
                            <Box ml={1}>
                                <animated.span
                                    className={commonStyles.primaryColorHover}
                                    style={{ ...hoverBoop.style, cursor: 'pointer', display: 'flex' }}
                                >
                                    <animated.span onClick={onClickBoop.trigger} style={{ ...onClickBoop.style, display: 'flex' }}>
                                        <Create />
                                    </animated.span>
                                </animated.span>
                            </Box>
                        }
                    </Box>
                </>
            )}
        </Formik>
    )
}