import { Button, ButtonProps, createStyles, makeStyles } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { Loading } from '../images'

interface ILoadingButtonProps extends ButtonProps {
    loading: boolean
}

const useStyles = makeStyles(theme =>
    createStyles({
        loadingOverlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'white',
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    })
)

export const LoadingButton: FunctionComponent<ILoadingButtonProps> = ({ loading, children, ...rest }: ILoadingButtonProps) => {
    const styles = useStyles()
    
    return (
        <Button {...rest}>
            <div style={{ position: 'relative', opacity: loading ? 0 : 1 }}>
                {children}
            </div>
            {loading &&
                <div className={styles.loadingOverlay}>
                    <Loading style={{ height: 20 }} />
                </div>
            }
        </Button>
    )
}