import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { useLoadingOverlay } from '.'


export const useHandleReactQuery = <TData, TError, TVariables, TContext> (query: UseQueryResult<TData, TError>, initiator?: string) => {
    const [ startLoading, stopLoading, disableLoading ] = useLoadingOverlay(undefined, initiator)
    const { enqueueSnackbar } = useSnackbar()
    const { isLoading, isError, error } = query

    useEffect(() => {
        if (isLoading) {
            startLoading()
        } else {
            stopLoading()
        }

        return () => {
            // if (initiator) console.log('umounting', initiator)
            disableLoading()
        }
    }, [ isLoading ])

    useEffect(() => {
        if (isError) enqueueSnackbar(`${error}`, { variant: 'error' })
    }, [ error ])

    return query
}

// Duplicating this because my type-foo is apparently not good enough to establish type safety allowing for either a mutation or query to be passed into this function and preserve the type of the passed query when returned from the hook.
export const useHandleReactQueryMutation = <TData, TError, TVariables, TContext> (mutation: UseMutationResult<TData, TError, TVariables, TContext>, initiator?: string) => {
    const [ startLoading, stopLoading, disableLoading ] = useLoadingOverlay(undefined, initiator)
    const { enqueueSnackbar } = useSnackbar()
    const { isLoading, isError, error } = mutation

    useEffect(() => {
        if (isLoading) {
            startLoading()
        } else {
            stopLoading()
        }

        return () => {
            disableLoading()
        }
    }, [ isLoading ])

    useEffect(() => {
        if (isError) enqueueSnackbar(`${error}`, { variant: 'error' })
    }, [ error ])

    return mutation
}