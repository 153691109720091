import { Dayjs } from 'dayjs'
import { CalendarGroupFragment, EventFieldsFragment } from '../../graphql/autogenerate/operations'
import { IGroup } from '../school'

/*
    State
*/
export interface ICalendarState {
    sidebarIsOpen: boolean
    viewMode: CalendarViewMode
    selectedDate: Dayjs
    searchActive: boolean

    disableShortcuts: boolean
    disableDateScrolling: boolean

    eventModal: {
        isOpen: boolean
        eventToEdit?: ICalendarEventCell
        recentGroupSelections?: string[]
    }

    // Calendar lists
    visibleCalendars: { [ key: string ]: boolean }
    favoritedCalendars: { [ key: string ]: boolean }
    calendarGroups: CalendarGroupFragment[]

    // Events
    events: ICalendarEventCell[]
}

export type CalendarViewMode = 'day' | 'week' | 'month'

export interface ICalendarEventCell {
    // A special identifier for local use (necessary for when an event needs to be rendered as multiple cells)
    cellId: string

    // Provides the event's actual start/end date for use in the label
    spansDays?: {
        labelStartDate: Dayjs
        labelEndDate: Dayjs
    }

    id: string
    title: string
    startDate: Dayjs
    endDate: Dayjs

    locationString?: string | null
    allDay?: boolean
    information?: string | null
    buttons?: EventFieldsFragment[ 'buttons' ]
    coverImage?: EventFieldsFragment[ 'coverImage' ]

    groups: IGroup[]

    googleId?: string | null
    googleCalendarId?: string | null
}


/* 
    Actions
*/
export enum CalendarActionType {
    toggleSidebar,
    setViewMode,
    goToToday,
    goToPrevious,
    goToNext,
    goToDate,
    toggleSearchActive,
    toggleCreateEventModal,
    disableShortcuts,
    enableShortcuts,
    showCalendars,
    hideCalendars,
    toggleShowHideCalendar,
    toggleCalendarsFavorited,
    disableDateScrolling,
    enableDateScrolling,
    setEvents,
    editEvent,
    setCalendarGroups,
}

interface IPayloadLessCalendarAction {
    type:
    CalendarActionType.toggleSidebar |
    CalendarActionType.goToToday |
    CalendarActionType.goToPrevious |
    CalendarActionType.goToNext |
    CalendarActionType.toggleSearchActive |
    CalendarActionType.toggleCreateEventModal |
    CalendarActionType.disableShortcuts |
    CalendarActionType.enableShortcuts |
    CalendarActionType.disableDateScrolling |
    CalendarActionType.enableDateScrolling
}

interface ISetViewModeCalendarAction {
    type: CalendarActionType.setViewMode
    payload: CalendarViewMode
}

interface IGoToDateCalendarAction {
    type: CalendarActionType.goToDate
    payload: Dayjs
}

interface IUpdateCalendarAction {
    type:
    CalendarActionType.showCalendars |
    CalendarActionType.hideCalendars |
    CalendarActionType.toggleCalendarsFavorited
    payload: string[]
}

interface ISetEventsCalendarAction {
    type: CalendarActionType.setEvents
    payload: ICalendarEventCell[]
}

interface IEditEventAction {
    type: CalendarActionType.editEvent
    payload: ICalendarEventCell
}

interface ISetCalendarGroups {
    type: CalendarActionType.setCalendarGroups
    payload: {
        calendarGroups: CalendarGroupFragment[]
    }
}


export type CalendarAction =
    IPayloadLessCalendarAction |
    ISetViewModeCalendarAction |
    IGoToDateCalendarAction |
    IUpdateCalendarAction |
    ISetEventsCalendarAction |
    IEditEventAction |
    ISetCalendarGroups