import { Button, Grid, Box, Typography, useTheme, } from '@material-ui/core'
import { useAppState, useAuth, useHandleReactQuery, useUrlQuery } from '../hooks'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { PasswordField, FormikTextInput, FormikEffect } from './forms'
import clsx from 'clsx'
import logo from '../images/logo.svg'
import { usePaddedBorderStyles } from '../styles'
import { Link, useNavigate } from 'react-router-dom'
import { usePasswordResetRequiredQuery } from '../graphql/autogenerate/react-query'
import { useEffect, useState } from 'react'
import { LoadingButton } from './loading-button'

interface IState {
    email?: string
    passwordResetRequired?: boolean
    step: number
}

const EMAIL_STEP = 1
const RESET_REQUIRED_STEP = 2
const EMAIL_PASSWORD_STEP = 3

interface ILoginProps {
    invitationToAccept?: {
        invitationSlug: string
        email: string
    }
}

export const Login = ({ invitationToAccept }: ILoginProps) => {
    const redirectUrl = useUrlQuery().get('redirectUrl')
    const navigate = useNavigate()
    const theme = useTheme()
    const classes = usePaddedBorderStyles()
    const { login, acceptInvitation } = useAuth()
    const { state: { authed } } = useAppState()
    const [ state, setState ] = useState<IState>({ step: EMAIL_STEP })

    const { isLoading } = useHandleReactQuery(usePasswordResetRequiredQuery(
        { email: state.email! },
        {
            enabled: Boolean(state.email),
            onSuccess: ({ passwordResetRequired }) => setState(_state => ({ ..._state, step: passwordResetRequired ? RESET_REQUIRED_STEP : EMAIL_PASSWORD_STEP })),
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    ))


    useEffect(() => {
        if (authed) navigate(redirectUrl ? decodeURIComponent(redirectUrl) : '/')
    }, [ authed ])

    return (
        <Formik
            initialValues={{
                email: invitationToAccept?.email || '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().required('Required').email('Please enter a valid email address.'),
                password: Yup.string().required('Required')
            })}
            onSubmit={(values) => {
                if (invitationToAccept) {
                    acceptInvitation({ invitationSlug: invitationToAccept.invitationSlug, password: values.password })
                } else {
                    login(values)
                }
            }}
        >
            {formikProps => (
                < >
                    <FormikEffect
                        formikProps={formikProps}
                        onChange={(prev, next) => {
                            /* 
                                As always...be INCREDIBLY careful setting state inside a formik effect. Form typing could trigger rapid, unnecessary, and possibly infinite rerenders depending on the logic.

                                If...
                                - There is an existing previous email value
                                - The existing previous email value !== the new email value
                                - We're NOT on the EMAIL_STEP
                                
                                Then...clear the email state value and return to step EMAIL_STEP
                            */
                            if (prev.email && prev.email !== next.email && state.step !== EMAIL_STEP) setState({ step: EMAIL_STEP })
                        }}
                    />

                    <Grid justify='center' alignItems='center' container direction='column' style={{ height: '100%' }}>
                        <Grid className={clsx(!invitationToAccept && classes.borders)} container item xs={12} sm={invitationToAccept ? 12 : 10} md={invitationToAccept ? 12 : 8} lg={invitationToAccept ? 12 : 5} justify='center' direction='column' component={Form} style={{ padding: theme.spacing(1) }}>

                            <Grid item container justify='center' alignContent='center' direction='column'>
                                {!invitationToAccept &&
                                    <Box marginBottom={2} justifyContent='center' display='flex'>
                                        <Link to='/signup'><img src={logo} alt='Legit Apps logo' style={{ maxWidth: 75 }} /></Link>
                                    </Box>
                                }

                                <Box marginBottom={invitationToAccept ? 1 : 4}>
                                    <Typography variant='h5' align='center'>{state.step === RESET_REQUIRED_STEP ? 'Password Reset Required' : 'Sign in'}</Typography>
                                </Box>
                            </Grid>

                            {(state.step === EMAIL_STEP || state.step === EMAIL_PASSWORD_STEP) &&
                                <Grid item container>
                                    <FormikTextInput autoFocus fieldProps={{ name: 'email', label: 'Email', disabled: Boolean(invitationToAccept?.email) }} />
                                </Grid>
                            }

                            {/* {invitationToAccept &&
                                <Typography color='textSecondary' style={{ marginLeft: theme.spacing(.5), marginBottom: theme.spacing(2) }}>
                                    {invitationToAccept.email}
                                </Typography>
                            } */}

                            {state.step === EMAIL_PASSWORD_STEP &&
                                <Grid item container>
                                    <PasswordField fieldProps={{ name: 'password', label: 'Password', labelWidth: 70 }} />
                                </Grid>
                            }

                            {state.step === RESET_REQUIRED_STEP &&
                                <Grid item container>
                                    <Typography align='center'>To help ensure the security of your account, we occasionally require you to verify your email address and create a new password.</Typography>
                                    <Typography align='center' style={{ flex: 1, marginTop: theme.spacing(2) }}>Please check your email and follow the link there to finish signing in.</Typography>
                                </Grid>
                            }

                            <Grid item container>
                                {(state.step === EMAIL_STEP || state.step === EMAIL_PASSWORD_STEP) &&
                                    <Grid item xs={12} lg={3} >
                                        <Button
                                            href={`/forgot-password?email=${formikProps.values.email}${invitationToAccept ? `&redirect=${encodeURIComponent(`/invitation/${invitationToAccept.invitationSlug}`)}` : ''}`}
                                            type='button'
                                            variant='text'
                                            color='primary'
                                            disableElevation
                                        >
                                            Forgot password
                                        </Button>
                                    </Grid>

                                }

                                <Grid item container xs={12} lg={9} justify='flex-end' style={{ marginTop: theme.spacing(1) }} >
                                    {state.step === EMAIL_PASSWORD_STEP &&
                                        <Button
                                            type='button'
                                            variant='text'
                                            onClick={() => {
                                                setState({ step: EMAIL_STEP })
                                                formikProps.resetForm()
                                            }}
                                            style={{ marginRight: theme.spacing(1), minWidth: 100 }}
                                        >
                                            Cancel
                                        </Button>
                                    }

                                    {state.step === EMAIL_STEP &&
                                        <LoadingButton
                                            loading={isLoading}
                                            type='button'
                                            style={{ minWidth: 100 }}
                                            variant='contained'
                                            color='primary'
                                            disableElevation
                                            onClick={async () => {
                                                formikProps.setTouched({ email: true }, false)
                                                const errors = await formikProps.validateForm()
                                                if (!Object.keys(errors).includes('email'))
                                                    setState(_state => ({ ..._state, email: formikProps.values.email }))
                                            }}
                                        >
                                            Next
                                        </LoadingButton>
                                    }

                                    {state.step === EMAIL_PASSWORD_STEP &&
                                        <Button type='submit' style={{ minWidth: 100 }} variant='contained' color='primary' disableElevation>
                                            Login
                                        </Button>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Formik>
    )
}