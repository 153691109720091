import { createStyles, Drawer, makeStyles, Tabs, useTheme, Tab, Typography, Box } from '@material-ui/core'
import { ImageSearch } from '@material-ui/icons'
import { IconType } from '../../graphql/autogenerate/schemas'
import clsx from 'clsx'
import { useCommonStyles } from '../../styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ICON_CATEGORIES, IconTypeDisplay } from '../icon-type-display'
import { AnimationIconWraper } from '../animation-icon-wrapper'
import { useSpring, animated } from 'react-spring'
import { Tooltip } from '../tooltip'

const useStyles = makeStyles((theme) => (
    createStyles({
        container: {
            border: `1px solid white`,
            borderRadius: theme.shape.borderRadius,
            width: 56,
            height: 56,
            cursor: 'pointer',
            '&:hover': {
                borderColor: 'black'
            }
        },
        withoutIcon: {
            color: theme.palette.grey[ 400 ],
            borderColor: theme.palette.grey[ 400 ],
            padding: theme.spacing(1),
        },
        withIcon: {
            color: 'white',
            backgroundColor: theme.palette.grey[ 500 ],
            borderColor: theme.palette.grey[ 500 ],
            padding: theme.spacing(1),
            '&:hover': {
                borderColor: 'black'
            }
        },
        error: {
            borderColor: theme.palette.error.main,
            '&:hover': {
                borderColor: theme.palette.error.main,
            }
        },
        noIconButton: {
            height: 56,
            width: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            borderRadius: theme.shape.borderRadius,
            '&:hover': {
                backgroundColor: theme.palette.grey[ 300 ]
            }
        }
    })
))

interface IIconSelectorProps {
    style?: React.CSSProperties
    className?: string
    iconType?: IconType | null
    label?: string
    iconTypeSelected: (iconType?: IconType) => void
    touched?: boolean
    error?: string
    initialError?: string
    helperText?: string
    setTouched?: (value: boolean) => void
    onOpened?: () => void
    color?: string | null
}

export const IconSelector = ({ style, color, className, iconType, label, iconTypeSelected, touched, error, initialError, helperText, setTouched, onOpened }: IIconSelectorProps) => {
    const styles = useStyles()
    const theme = useTheme()

    const [ open, setOpen ] = useState(false)
    const [ selectedTabIndex, setSelectedTabIndex ] = useState(0)

    const [ currentIcon, setCurrentIcon ] = useState(iconType)
    const nextIconRef = useRef(iconType)
    const showNextIcon = useCallback((iconType?: IconType | null) => {
        nextIconRef.current = iconType
        setResetIconSquare(true)
        setOpen(false)
    }, [])

    const [ resetIconSquare, setResetIconSquare ] = useState(false)
    const springGrow = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)',
        },
        reverse: resetIconSquare && (currentIcon !== nextIconRef.current),
        reset: resetIconSquare,
        onRest: () => {
            if (resetIconSquare) setResetIconSquare(false)
            setCurrentIcon(nextIconRef.current)
            if (iconType !== nextIconRef.current && nextIconRef.current) {
                iconTypeSelected(nextIconRef.current)
            } else if (nextIconRef.current === undefined || nextIconRef.current === null) {
                iconTypeSelected()
            }
        },
        config: {
            duration: 200
        },
    })

    useEffect(() => {
        if (iconType !== nextIconRef.current) {
            showNextIcon(iconType)
        }
    }, [ iconType ])

    const selectorButton = (
        <div>
            <div
                className={clsx(className, styles.container, (touched || initialError) && error && styles.error, !!currentIcon ? styles.withIcon : styles.withoutIcon)}
                style={{
                    ...style,
                    ...color && !!currentIcon ? { backgroundColor: color, borderColor: color } : {}
                }}
                onClick={() => setOpen(true)}
            >
                {currentIcon ?
                    <animated.div style={{ ...springGrow, height: '100%', }}>
                        <IconTypeDisplay type={currentIcon} size='100%' />
                    </animated.div>
                    :
                    <AnimationIconWraper >
                        <ImageSearch style={{ height: '100%', width: '100%' }} />
                    </AnimationIconWraper>
                }
            </div>
            <Box>
                <Typography variant='caption' color='error'>{initialError || (touched && error) || helperText || ' '}</Typography>
            </Box>
        </div>
    )

    return (
        <>
            {label ?
                <Tooltip title={`Select ${label}`} placement='top' arrow >
                    {selectorButton}
                </Tooltip>
                :
                selectorButton
            }

            <Drawer
                anchor='bottom'
                open={open}
                onClose={() => {
                    setTouched && setTouched(true)
                    setOpen(false)
                }}
                onEnter={onOpened}
            >
                <Tabs
                    value={selectedTabIndex}
                    onChange={(_, newValue) => setSelectedTabIndex(newValue)}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    <Tab label='Sports' value={0} />
                    <Tab label='Numbers' value={1} />
                    <Tab label='General' value={2} />
                </Tabs>
                <div style={{ maxHeight: 200, overflow: 'hidden' }}>
                    {open &&
                        <div
                            style={{
                                color: theme.palette.grey[ 500 ],
                                padding: theme.spacing(2),
                                overflowY: 'auto',
                                display: 'grid',
                                maxHeight: 200,
                                gridTemplateRows: '56px 56px',
                                gridTemplateColumns: 'repeat(auto-fill, 56px)',
                                columnGap: theme.spacing(2),
                                rowGap: theme.spacing(2),
                                justifyContent: 'stretch'
                            }}
                        >
                            {
                                (() => {
                                    switch (selectedTabIndex) {
                                        case 0:
                                            return ICON_CATEGORIES.sports
                                        case 1:
                                            return ICON_CATEGORIES.numbers
                                        case 2:
                                            return ICON_CATEGORIES.general
                                        default:
                                            return []
                                    }
                                })().map(ic =>
                                    <AnimationIconWraper
                                        key={ic}
                                        onClick={() => showNextIcon(ic)}
                                    >
                                        <IconTypeDisplay type={ic} size={56} style={{ cursor: 'pointer' }} />
                                    </AnimationIconWraper>
                                )
                            }
                            <div onClick={() => showNextIcon()} className={styles.noIconButton}>
                                No Icon
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
        </>
    )
}