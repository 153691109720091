import clsx from "clsx"
import { CSSProperties, ReactNode } from "react"
import { useFormStyles } from "../../styles/use-form-styles"
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'

interface IFieldWrapperProps {
    name: string
    label?: string
    helperText?: string | null
    error?: string
    children: ReactNode
    noMargin?: boolean
    style?: CSSProperties
}

export const FieldWrapper = ({ name, label, helperText, error, children, noMargin, style }: IFieldWrapperProps) => {
    const classes = useFormStyles()

    return (
        <FormControl error={!!error} className={clsx(!noMargin && classes.margin, classes.root)} style={style} variant='outlined'>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            {children}
            <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
        </FormControl>
    )
}