// import { useMemo } from 'react'
// import { useHandleApolloQuery } from '.'
// import { Scalars } from '../graphql/autogenerate/schemas'
// import { ICalendarState } from '../stores/calendar'
// import { calendarStateFromDb, calendarStateToDb } from '../stores/calendar/db-transform'

export enum SessionDataKey {
    calendar = 'calendar',
}

// interface ICalendarSessionDataSlice {
//     key: SessionDataKey.calendar
//     data: ICalendarState
// }

// interface IResourcesSessionDataSlice {
//     key: SessionDataKey.resources
//     data: {
//         title: string
//         hello: boolean
//     }
// }

// type SessionDataSlice =
//     ICalendarSessionDataSlice |
//     IResourcesSessionDataSlice

// const toDb = ({ key, data }: SessionDataSlice): Scalars[ 'JSON' ] => {
//     switch (key) {
//         case SessionDataKey.calendar:
//             return calendarStateToDb(data)
//         case SessionDataKey.calendar:
//             return { test: '' }
//     }
// }

// export const useUpdateSessionData = ({ key, data }: ICalendarSessionDataSlice) => {
//     const [ updateSessionData, updateSessionDataStatus ] = useUpdateCurrentUserSessionDataMutation()
//     useHandleApolloQuery({ query: updateSessionDataStatus })
//     updateSessionData({
//         variables: {
//             sessionDataPartial: {
//                 [ key ]: toDb({ key, data })
//             }
//         }
//     })
//     return updateSessionDataStatus
// }



// const fromDb = ({ key, sessionData }: { key: SessionDataKey, sessionData?: Scalars[ 'JSON' ] }): SessionDataSlice => {
//     const slice = sessionData && sessionData[ key ]

//     switch (key) {
//         case SessionDataKey.calendar:
//             return {
//                 key,
//                 data: calendarStateFromDb(slice)
//             }
//         case SessionDataKey.resources:
//             return {
//                 key,
//                 data: {
//                     title: '',
//                     hello: false
//                 }
//             }
//     }
// }

// export const useGetSessionData = (key: SessionDataKey) => {
//     const currentUserSessionQuery = useCurrentUserSessionQuery()
//     useHandleApolloQuery({ query: currentUserSessionQuery, loadingUi: 'overlay' })

//     const initialState = useMemo(() => {
//         return fromDb({ key, sessionData: currentUserSessionQuery.data?.currentUser?.userSessionByUserId?.sessionData })
//     }, [ currentUserSessionQuery.data?.currentUser?.userSessionByUserId?.sessionData ])

//     return initialState.data
// }

