import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { ReactNode, useState } from 'react'
import { v4 } from 'uuid'
import { ButtonStyle, useButtonStyles } from '../styles'
import clsx from 'clsx'

interface IConfirmDialogProps {
    isOpen: boolean
    close: () => any
    confirm: () => any
    title?: string
    body?: ReactNode
    goBackButtonLabel?: string
    confirmButton?: {
        label?: string
        type?: ButtonStyle
    }
}
export const ConfirmDialog = ({ isOpen, close, title, body, confirm, confirmButton, goBackButtonLabel }: IConfirmDialogProps) => {
    const [ id ] = useState(v4())

    const buttonStyles = useButtonStyles()

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
        >
            <DialogTitle id={`${id}-title`}>{title || 'Confirm'}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${id}-description`} component='div'>
                    {body || 'Are you sure?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color='inherit'>
                    {goBackButtonLabel || 'Cancel'}
                </Button>
                <Button
                    onClick={() => {
                        close()
                        confirm()
                    }}
                    autoFocus
                    variant='contained'
                    className={clsx(confirmButton?.type && buttonStyles[ confirmButton.type ])}
                >
                    {confirmButton?.label || 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}