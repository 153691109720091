import { useTheme } from '@material-ui/core'
import { CSSProperties, FC } from 'react'

interface ISchoolNavBarProps {
    style?: CSSProperties
}

export const SchoolNavBar: FC<ISchoolNavBarProps> = ({ children, style }) => {
    const theme = useTheme()

    return (
        <div
            style={{
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 700,
                ...style,
            }}
        >
            {children}
        </div>
    )
}