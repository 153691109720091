export const LOCAL_STORAGE_JWT_KEY = 'qtxoqwllok'
export const LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY = 'anonymous_account_id'

export const ERRCODE_PERMISSION_DENIED = '42501'

const REFETCH_INTERVAL_OVERRIDE =
	process.env.REACT_APP_REFETCH_INTERVAL &&
	process.env.REACT_APP_REFETCH_INTERVAL.length &&
	parseInt(process.env.REACT_APP_REFETCH_INTERVAL)

export const REFETCH_INTERVAL = REFETCH_INTERVAL_OVERRIDE
	? REFETCH_INTERVAL_OVERRIDE
	: 3000

export const APPLE_REVIEWER_EMAIL = 'awesome@tester.com'
export const APPLE_REVIEWER_PASSWORD = 'thatsyou'
