import React from 'react'
import { IconType } from '../graphql/autogenerate/schemas'
import {
    AbcCube,
    Acrobatics,
    Archery,
    Backpack,
    Baseball,
    Basketball,
    Bowling,
    BoxingGlove,
    Cheer,
    CityHall,
    Classroom,
    Eight,
    Eleven,
    Exercise,
    FieldHockey,
    Fishing,
    Five,
    Football,
    Four,
    Golf,
    Group,
    Gymnastics,
    Jump,
    Jumprope,
    Lacrosse,
    Nine,
    One,
    PersonWithFlag,
    Preschool,
    Running,
    School,
    Seven,
    Six,
    Soccer,
    Softball,
    SportsJersey,
    Students,
    Swimming,
    Ten,
    Tennis,
    Three,
    TrackAndField,
    TrackLap,
    Twelve,
    Two,
    Volleyball,
    Weightlifting,
    WomanAthlete,
    Wrestling,
    SoccerPlayer,
    VolleyballPlayer,
    Cheerleading,
    BasketballPlayer,
    AirJordan,
    BaseballPlayer,
    Music,
    TheatreMask,
    FlyingMortarboard,
    HeartHandshake,
    Easel,
    Fist,
    Naruto,
    MoveDirtAround,
    Arguing,
    HandWithPen,
    Caduceus,
    Podcast,
    Microphone,
    Engineer,
    Twitter,
    Instagram,
    Paella,
    Beach,
    Book,
    VeganFood,
    GraduationScroll,
    DefendFamily,
    Mario,
} from '../images/index'

export const ICON_CATEGORIES = {
    sports: [
        IconType.IosFilledBasketball,
        IconType.IosFilledBaseball,
        IconType.IosFilledSoftball,
        IconType.IosFilledSoccer,
        IconType.IosFilledFootball,
        IconType.IosFilledVolleyball,
        IconType.IosFilledGolf,
        IconType.IosFilledArchery,
        IconType.IosFilledBassFishing,
        IconType.IosFilledLacrosse,
        IconType.IosFilledFieldHockey,
        IconType.IosFilledTennis,
        IconType.IosFilledCrossCountry,
        IconType.IosFilledTrack,
        IconType.IosFilledSwimming,
        IconType.IosFilledBowling,
        IconType.IosFilledCheer,
        IconType.IosFilledDance,
        IconType.IosFilledWrestling,
        IconType.IosFilledSportsJersey,
        IconType.IosFilledExercise,
        IconType.IosFilledJumprope,
        IconType.IosFilledWomanAthlete,
        IconType.IosFilledAcrobatics,
        IconType.IosFilledGymnastics,
        IconType.IosFilledWeightlifting,
        IconType.IosFilledBoxingGlove,
        IconType.IosFilledTrackLap,
        IconType.IosFilledSoccerPlayer,
        IconType.IosFilledVolleyballPlayer,
        IconType.IosFilledCheerleading,
        IconType.IosFilledBasketballPlayer,
        IconType.IosFilledAirJordan,
        IconType.IosFilledBaseballPlayer
    ],
    numbers: [
        IconType.IosFilledOne,
        IconType.IosFilledTwo,
        IconType.IosFilledThree,
        IconType.IosFilledFour,
        IconType.IosFilledFive,
        IconType.IosFilledSix,
        IconType.IosFilledSeven,
        IconType.IosFilledEight,
        IconType.IosFilledNine,
        IconType.IosFilledTen,
        IconType.IosFilledEleven,
        IconType.IosFilledTwelve,
    ],
    general: [
        IconType.IosFilledCityHall,
        IconType.IosFilledSchool,
        IconType.IosFilledPersonWithFlag,
        IconType.IosFilledBackpack,
        IconType.IosFilledGroup,
        IconType.IosFilledClassroom,
        IconType.IosFilledStudents,
        IconType.IosFilledPreschool,
        IconType.IosFilledAbcCube,
        IconType.IosFilledMusic,
        IconType.IosFilledTheatreMask,
        IconType.IosFilledFlyingMortarboard,
        IconType.IosFilledHeartHandshake,
        IconType.IosFilledEasel,
        IconType.IosFilledFist,
        IconType.IosFilledNaruto,
        IconType.IosFilledMoveDirtAround,
        IconType.IosFilledArguing,
        IconType.IosFilledHandWithPen,
        IconType.IosFilledCaduceus,
        IconType.IosFilledPodcast,
        IconType.IosFilledMicrophone,
        IconType.IosFilledEngineer,
        IconType.IosFilledTwitter,
        IconType.IosFilledInstagram,
        IconType.IosFilledPaella,
        IconType.IosFilledBeach,
        IconType.IosFilledBook,
        IconType.IosFilledVeganFood,
        IconType.IosFilledGraduationScroll,
        IconType.IosFilledDefendFamily,
        IconType.IosFilledMario,
    ]
}

interface IIconTypeDisplayProps {
    type: IconType
    size: number | string
    style?: React.CSSProperties
}

export const IconTypeDisplay = ({ type, size, style }: IIconTypeDisplayProps) => {
    const props = {
        height: size,
        width: size,
        style: {
            ...style ? style : {},
            minWidth: size,
            minHeight: size,
        },
    }

    switch (type) {
        case IconType.IosFilledBasketball:
            return <Basketball {...props} />
        case IconType.IosFilledBaseball:
            return <Baseball {...props} />
        case IconType.IosFilledSoftball:
            return <Softball {...props} />
        case IconType.IosFilledSoccer:
            return <Soccer {...props} />
        case IconType.IosFilledFootball:
            return <Football {...props} />
        case IconType.IosFilledVolleyball:
            return <Volleyball {...props} />
        case IconType.IosFilledGolf:
            return <Golf {...props} />
        case IconType.IosFilledArchery:
            return <Archery {...props} />
        case IconType.IosFilledBassFishing:
            return <Fishing {...props} />
        case IconType.IosFilledLacrosse:
            return <Lacrosse {...props} />
        case IconType.IosFilledFieldHockey:
            return <FieldHockey {...props} />
        case IconType.IosFilledTennis:
            return <Tennis {...props} />
        case IconType.IosFilledCrossCountry:
            return <Running {...props} />
        case IconType.IosFilledTrack:
            return <TrackAndField {...props} />
        case IconType.IosFilledSwimming:
            return <Swimming {...props} />
        case IconType.IosFilledBowling:
            return <Bowling {...props} />
        case IconType.IosFilledCheer:
            return <Cheer {...props} />
        case IconType.IosFilledDance:
            return <Jump {...props} />
        case IconType.IosFilledWrestling:
            return <Wrestling {...props} />
        case IconType.IosFilledOne:
            return <One {...props} />
        case IconType.IosFilledTwo:
            return <Two {...props} />
        case IconType.IosFilledThree:
            return <Three {...props} />
        case IconType.IosFilledFour:
            return <Four {...props} />
        case IconType.IosFilledFive:
            return <Five {...props} />
        case IconType.IosFilledSix:
            return <Six {...props} />
        case IconType.IosFilledSeven:
            return <Seven {...props} />
        case IconType.IosFilledEight:
            return <Eight {...props} />
        case IconType.IosFilledNine:
            return <Nine {...props} />
        case IconType.IosFilledTen:
            return <Ten {...props} />
        case IconType.IosFilledEleven:
            return <Eleven {...props} />
        case IconType.IosFilledTwelve:
            return <Twelve {...props} />
        case IconType.IosFilledCityHall:
            return <CityHall {...props} />
        case IconType.IosFilledSchool:
            return <School {...props} />
        case IconType.IosFilledPersonWithFlag:
            return <PersonWithFlag {...props} />
        case IconType.IosFilledBackpack:
            return <Backpack {...props} />
        case IconType.IosFilledGroup:
            return <Group {...props} />
        case IconType.IosFilledClassroom:
            return <Classroom {...props} />
        case IconType.IosFilledStudents:
            return <Students {...props} />
        case IconType.IosFilledPreschool:
            return <Preschool {...props} />
        case IconType.IosFilledAbcCube:
            return <AbcCube {...props} />
        case IconType.IosFilledSportsJersey:
            return <SportsJersey {...props} />
        case IconType.IosFilledExercise:
            return <Exercise {...props} />
        case IconType.IosFilledJumprope:
            return <Jumprope {...props} />
        case IconType.IosFilledWomanAthlete:
            return <WomanAthlete {...props} />
        case IconType.IosFilledAcrobatics:
            return <Acrobatics {...props} />
        case IconType.IosFilledGymnastics:
            return <Gymnastics {...props} />
        case IconType.IosFilledWeightlifting:
            return <Weightlifting {...props} />
        case IconType.IosFilledBoxingGlove:
            return <BoxingGlove {...props} />
        case IconType.IosFilledTrackLap:
            return <TrackLap {...props} />
        case IconType.IosFilledSoccerPlayer:
            return <SoccerPlayer {...props} />
        case IconType.IosFilledVolleyballPlayer:
            return <VolleyballPlayer {...props} />
        case IconType.IosFilledCheerleading:
            return <Cheerleading {...props} />
        case IconType.IosFilledBasketballPlayer:
            return <BasketballPlayer {...props} />
        case IconType.IosFilledAirJordan:
            return <AirJordan {...props} />
        case IconType.IosFilledBaseballPlayer:
            return <BaseballPlayer {...props} />
        case IconType.IosFilledMusic:
            return <Music {...props} />
        case IconType.IosFilledTheatreMask:
            return <TheatreMask {...props} />
        case IconType.IosFilledFlyingMortarboard:
            return <FlyingMortarboard {...props} />
        case IconType.IosFilledHeartHandshake:
            return <HeartHandshake {...props} />
        case IconType.IosFilledEasel:
            return <Easel {...props} />
        case IconType.IosFilledFist:
            return <Fist {...props} />
        case IconType.IosFilledNaruto:
            return <Naruto {...props} />
        case IconType.IosFilledMoveDirtAround:
            return <MoveDirtAround {...props} />
        case IconType.IosFilledArguing:
            return <Arguing {...props} />
        case IconType.IosFilledHandWithPen:
            return <HandWithPen {...props} />
        case IconType.IosFilledCaduceus:
            return <Caduceus {...props} />
        case IconType.IosFilledPodcast:
            return <Podcast {...props} />
        case IconType.IosFilledMicrophone:
            return <Microphone {...props} />
        case IconType.IosFilledEngineer:
            return <Engineer {...props} />
        case IconType.IosFilledTwitter:
            return <Twitter {...props} />
        case IconType.IosFilledInstagram:
            return <Instagram {...props} />
        case IconType.IosFilledPaella:
            return <Paella {...props} />
        case IconType.IosFilledBeach:
            return <Beach {...props} />
        case IconType.IosFilledBook:
            return <Book {...props} />
        case IconType.IosFilledVeganFood:
            return <VeganFood {...props} />
        case IconType.IosFilledGraduationScroll:
            return <GraduationScroll {...props} />
        case IconType.IosFilledDefendFamily:
            return <DefendFamily {...props} />
        case IconType.IosFilledMario:
            return <Mario {...props} />
        default:
            throw new Error(`Unsupported icon type: ${type}`)
    }
}