import { IField } from "./interfaces"
import { InputAdornment, OutlinedInput, IconButton, } from '@material-ui/core'
import { useField } from "formik"
import { CSSProperties, useState } from "react"
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FieldWrapper } from "./field-wrapper"

interface IPasswordField extends IField {
    labelWidth?: number
}

interface IPasswordFieldProps {
    fieldProps: IPasswordField
    noMargin?: boolean
    disableAutocomplete?: boolean
    style?: CSSProperties
}

export const PasswordField = ({ fieldProps, noMargin, disableAutocomplete, style }: IPasswordFieldProps) => {
    const [ field, meta ] = useField(fieldProps)
    const [ showPassword, setShowPassword ] = useState(false)

    return (
        <FieldWrapper {...fieldProps} error={meta.error} noMargin={noMargin} style={style}>
            <OutlinedInput
                {...field}
                autoComplete={disableAutocomplete ? 'new-password' : undefined}
                error={(meta.touched && !!meta.error) || !!meta.initialError}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={fieldProps.labelWidth}
            />
        </FieldWrapper>
    )
}