import { Button } from '@material-ui/core'
import dayjs from 'dayjs'
import React from 'react'
import { CalendarActionType } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { Tooltip } from './tooltip'

const CalendarHeaderTodayButton = ({ dispatch }: ICalendarDispatchProp) => {
    return (
        <Tooltip title={dayjs().format('dddd, MMMM Do')}>
            <Button
                variant='outlined'
                color='primary'
                onClick={() => dispatch({ type: CalendarActionType.goToToday })}
            >
                Today
            </Button>
        </Tooltip>
    )
}

export const MemoizedCalendarHeaderTodayButton = React.memo(CalendarHeaderTodayButton)