import { Box, Typography, Button } from '@material-ui/core'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

export interface IEmptyStateProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title: string
    action?: {
        buttonTitle: string
        onClick: () => void | Promise<void>
    }
}
export const EmptyState = ({ title, action, ...rest }: IEmptyStateProps) => {
    return (
        <Box my={5} display='flex' flexDirection='column' alignItems='center' justifyContent='center' flex={1} {...rest}>
            <Typography variant='h5' color='textSecondary'>{title}</Typography>
            {action &&
                <Box mt={3}>
                    <Button type='button' variant='outlined' color='primary' onClick={action.onClick}>{action.buttonTitle}</Button>
                </Box>
            }
        </Box>
    )
}