import { Button, Divider, useTheme, Chip } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useEffect } from 'react'
import { useState } from 'react'
import { NotificationFragmentFragment } from '../graphql/autogenerate/operations'
import { useSchoolAdminNotificationsCountQuery, useSchoolAdminNotificationsQuery } from '../graphql/autogenerate/react-query'
import { NotificationsOrderByType, NotificationDelivery } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { EmptyState } from './empty-state'
import { Modal, useModal } from './modal'
import { NotificationForm } from './notification-form'
import { NotificationList } from './notification-list'
import { REFETCH_INTERVAL } from '../constants'

interface IState {
    unsentNotificationView: 'drafts' | 'scheduled' | 'recurring'

    draftNotificationPage: number
    scheduledNotificationPage: number
    recurringNotificationPage: number
    sentNotificationPage: number

    notificationToEdit?: NotificationFragmentFragment
}

let NOTIFICATION_LIST_LIMIT = 20

export const AdminNotifications = () => {
    const { state: { school: { id: schoolId } } } = useSchoolContext()

    const theme = useTheme()

    const [ state, setState ] = useState<IState>({
        unsentNotificationView: 'drafts',
        draftNotificationPage: 1,
        scheduledNotificationPage: 1,
        recurringNotificationPage: 1,
        sentNotificationPage: 1,
    })

    const modal = useModal()

    const totalNotificationCount = useHandleReactQuery(useSchoolAdminNotificationsCountQuery({ schoolId }))

    const draftNotificationsQuery = useHandleReactQuery(useSchoolAdminNotificationsQuery(
        {
            draft: true,
            delivery: [ NotificationDelivery.Later, NotificationDelivery.Now, NotificationDelivery.Recurring ],
            limit: NOTIFICATION_LIST_LIMIT,
            schoolId,
            includeTargetRecipientCount: true,
            offset: (state.draftNotificationPage - 1) * NOTIFICATION_LIST_LIMIT,
            orderBy: NotificationsOrderByType.UpdatedAt,
        },
        {
            keepPreviousData: true,
            refetchInterval: REFETCH_INTERVAL,
            refetchIntervalInBackground: true,
        }
    ))

    const scheduledNotificationsQuery = useHandleReactQuery(useSchoolAdminNotificationsQuery(
        {
            draft: false,
            delivery: [ NotificationDelivery.Later ],
            limit: NOTIFICATION_LIST_LIMIT,
            schoolId,
            includeTargetRecipientCount: true,
            orderBy: NotificationsOrderByType.SendDateAsc,
            offset: (state.scheduledNotificationPage - 1) * NOTIFICATION_LIST_LIMIT,
        },
        {
            keepPreviousData: true,
            refetchInterval: REFETCH_INTERVAL,
            refetchIntervalInBackground: true,
        }
    ))

    const recurringNotificationsQuery = useHandleReactQuery(useSchoolAdminNotificationsQuery(
        {
            draft: false,
            delivery: [ NotificationDelivery.Recurring ],
            limit: NOTIFICATION_LIST_LIMIT,
            schoolId,
            includeTargetRecipientCount: true,
            orderBy: NotificationsOrderByType.NextOccurrence,
            offset: (state.recurringNotificationPage - 1) * NOTIFICATION_LIST_LIMIT,
        },
        {
            keepPreviousData: true,
            refetchInterval: REFETCH_INTERVAL,
            refetchIntervalInBackground: true,
        }
    ))

    const sentNotificationsQuery = useHandleReactQuery(useSchoolAdminNotificationsQuery(
        {
            draft: false,
            delivery: [ NotificationDelivery.Sent ],
            limit: NOTIFICATION_LIST_LIMIT,
            schoolId,
            includeTargetRecipientCount: false,
            orderBy: NotificationsOrderByType.SendDateDesc,
            offset: (state.sentNotificationPage - 1) * NOTIFICATION_LIST_LIMIT,
        },
        {
            keepPreviousData: true,
            refetchInterval: REFETCH_INTERVAL,
            refetchIntervalInBackground: true,
        }
    ))

    useEffect(() => {
        if (state.notificationToEdit) modal.open()
    }, [ state.notificationToEdit ])

    if (!totalNotificationCount.isFetched) return null

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: theme.spacing(1) }}>
                    <Button style={{ minWidth: 100 }} onClick={modal.open} variant='contained' color='primary'>
                        Create Notification
                    </Button>
                </div>
                <Divider />

                {totalNotificationCount.data?.getNotificationsForSchoolForCurrentPerson?.totalCount ?
                    <div style={{ flex: 1, display: 'flex', margin: theme.spacing(1), overflow: 'hidden' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden', }}>
                            <div>
                                <Chip clickable label='Drafts' color='secondary' variant={state.unsentNotificationView === 'drafts' ? 'default' : 'outlined'} style={{ marginRight: theme.spacing(1), minWidth: 90 }} onClick={() => setState(_state => ({ ..._state, unsentNotificationView: 'drafts' }))} />
                                <Chip clickable label='Scheduled' color='secondary' variant={state.unsentNotificationView === 'scheduled' ? 'default' : 'outlined'} style={{ marginRight: theme.spacing(1), minWidth: 90 }} onClick={() => setState(_state => ({ ..._state, unsentNotificationView: 'scheduled' }))} />
                                <Chip clickable label='Recurring' color='secondary' variant={state.unsentNotificationView === 'recurring' ? 'default' : 'outlined'} style={{ marginRight: theme.spacing(1), minWidth: 90 }} onClick={() => setState(_state => ({ ..._state, unsentNotificationView: 'recurring' }))} />
                            </div>

                            {state.unsentNotificationView === 'drafts' && draftNotificationsQuery.isFetched &&
                                <>
                                    <NotificationList
                                        emptyState={{ title: 'No drafts.', action: { buttonTitle: 'Create Notification', onClick: modal.open } }}
                                        notifications={draftNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson}
                                        notificationSelected={notificationToEdit => setState(_state => ({ ..._state, notificationToEdit }))}
                                        style={{ flex: 1, padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(4)}px 0px`, marginRight: theme.spacing(2), overflowY: 'auto' }}
                                    />
                                    {draftNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount !== 0 && draftNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount && draftNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount > NOTIFICATION_LIST_LIMIT &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Pagination
                                                count={Math.ceil(draftNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount / NOTIFICATION_LIST_LIMIT)}
                                                shape='rounded'
                                                page={state.draftNotificationPage}
                                                onChange={(_, page) => setState(_state => ({ ..._state, draftNotificationPage: page }))}
                                            />
                                        </div>
                                    }
                                </>
                            }

                            {state.unsentNotificationView === 'scheduled' && scheduledNotificationsQuery.isFetched &&
                                <>
                                    <NotificationList
                                        emptyState={{ title: 'No scheduled notifications.', action: { buttonTitle: 'Create Notification', onClick: modal.open } }}
                                        notifications={scheduledNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson}
                                        notificationSelected={notificationToEdit => setState(_state => ({ ..._state, notificationToEdit }))}
                                        style={{ flex: 1, margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`, overflowY: 'auto' }}
                                    />
                                    {scheduledNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount !== 0 && scheduledNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount && scheduledNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount > NOTIFICATION_LIST_LIMIT &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Pagination
                                                count={Math.ceil(scheduledNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount / NOTIFICATION_LIST_LIMIT)}
                                                shape='rounded'
                                                page={state.scheduledNotificationPage}
                                                onChange={(_, page) => setState(_state => ({ ..._state, scheduledNotificationPage: page }))}
                                            />
                                        </div>
                                    }
                                </>
                            }

                            {state.unsentNotificationView === 'recurring' && recurringNotificationsQuery.isFetched &&
                                <>
                                    <NotificationList
                                        emptyState={{ title: 'No recurring notifications.', action: { buttonTitle: 'Create Notification', onClick: modal.open } }}
                                        notifications={recurringNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson}
                                        notificationSelected={notificationToEdit => setState(_state => ({ ..._state, notificationToEdit }))}
                                        style={{ flex: 1, margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`, overflowY: 'auto' }}
                                    />
                                    {recurringNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount !== 0 && recurringNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount && recurringNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount > NOTIFICATION_LIST_LIMIT &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Pagination
                                                count={Math.ceil(recurringNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount / NOTIFICATION_LIST_LIMIT)}
                                                shape='rounded'
                                                page={state.recurringNotificationPage}
                                                onChange={(_, page) => setState(_state => ({ ..._state, recurringNotificationPage: page }))}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
                            <div><Chip label='Sent' color='secondary' style={{ minWidth: 90 }} /></div>
                            {sentNotificationsQuery.isFetched &&
                                <>
                                    <NotificationList
                                        emptyState={{ title: 'No Notifications have sent yet.' }}
                                        notifications={sentNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson}
                                        notificationSelected={notificationToEdit => setState(_state => ({ ..._state, notificationToEdit }))}
                                        style={{ flex: 1, margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`, overflowY: 'auto' }}
                                    />
                                    {sentNotificationsQuery.data?.getNotificationsForSchoolForCurrentPerson?.totalCount !== undefined && sentNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount > NOTIFICATION_LIST_LIMIT &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Pagination
                                                count={Math.ceil(sentNotificationsQuery.data.getNotificationsForSchoolForCurrentPerson.totalCount / NOTIFICATION_LIST_LIMIT)}
                                                shape='rounded'
                                                page={state.sentNotificationPage}
                                                onChange={(_, page) => setState(_state => ({ ..._state, sentNotificationPage: page }))}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    :
                    <EmptyState title='No Notifications have been created yet.' action={{ buttonTitle: 'Create Notification', onClick: modal.open }} />
                }
            </div>

            <Modal
                {...modal.props}
                title={(() => {
                    if (state.notificationToEdit?.delivery === NotificationDelivery.Later) return 'Edit Scheduled Notification'
                    if (state.notificationToEdit?.delivery === NotificationDelivery.Recurring) return 'Edit Recurring Notification'
                    if (state.notificationToEdit?.delivery === NotificationDelivery.Sent) return 'Edit Sent Notification'
                    if (state.notificationToEdit?.draft) return 'Edit Draft Notification'
                    if (state.notificationToEdit) return 'Edit Notification'
                    return 'New Notification'
                })()}
                size='xl'
                dividers
                dismissible
                closeButton
                disableEscapeKeyDown
                style={{ minHeight: '90%' }}
                children={
                    <NotificationForm
                        afterSave={query => {
                            // Need to refetch the total count if this is the first notification being saved.
                            if (totalNotificationCount.data?.getNotificationsForSchoolForCurrentPerson?.totalCount === 0) totalNotificationCount.refetch()

                            // Refresh the drafts query if the saved notification was a draft or if the notification being edited was a draft, but now isn't
                            if (query?.notification?.draft || (state.notificationToEdit?.draft && !query?.notification?.draft)) {
                                draftNotificationsQuery.refetch()
                            }

                            (() => {
                                if (query?.notification?.draft) {
                                    setState(_state => ({ ..._state, unsentNotificationView: 'drafts' }))
                                    return
                                }
                                if (query?.notification?.delivery === NotificationDelivery.Later) {
                                    scheduledNotificationsQuery.refetch()
                                    setState(_state => ({ ..._state, unsentNotificationView: 'scheduled' }))
                                    return
                                }
                                if (query?.notification?.delivery === NotificationDelivery.Recurring) {
                                    recurringNotificationsQuery.refetch()
                                    setState(_state => ({ ..._state, unsentNotificationView: 'recurring' }))
                                    return
                                }
                            })()

                            modal.close()
                        }}
                        afterDelete={() => {
                            if (state.notificationToEdit?.draft) draftNotificationsQuery.refetch()
                            if (state.notificationToEdit?.delivery === NotificationDelivery.Later) scheduledNotificationsQuery.refetch()
                            if (state.notificationToEdit?.delivery === NotificationDelivery.Recurring) recurringNotificationsQuery.refetch()
                            if (state.notificationToEdit?.delivery === NotificationDelivery.Sent) sentNotificationsQuery.refetch()
                            modal.close()
                        }}
                        notificationToEdit={state.notificationToEdit}
                    />}
                afterClose={() => {
                    setState(_state => ({ ..._state, notificationToEdit: undefined }))
                }}
            />
        </>
    )
}
