import IMask from 'imask'
import { FormikMaskedInput, IFormikMaskedInputProps } from './formik-masked-input'

const phoneMask: IMask.AnyMaskedOptions = {
    mask: [
        { mask: '' },
        {
            mask: '(000) 000-0000',
            lazy: false,
            placeholderChar: '#',
        }
    ]
}

interface IFormikPhoneFieldProps extends Omit<IFormikMaskedInputProps, 'mask'> {
}

export const FormikPhoneField = (props: IFormikPhoneFieldProps) => <FormikMaskedInput {...props} mask={phoneMask} />