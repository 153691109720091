import { createStyles, makeStyles, Typography, useTheme } from '@material-ui/core'
import React, { } from 'react'
import clsx from 'clsx'
import { useScrollbarWidth } from 'react-use'
import { calendarHeadHeight, MemoizedCalendarEventsHeaderWeek } from './calendar-events-header-week'
import { MemoizedCurrentTimeBar } from './calendar-current-time-bar'
import { CalendarWeekEvents } from './calendar-week-events'
import { CalendarWeekAllDayEvents } from './calendar-week-all-day-events'
import { ICalendarState } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            flex: 1,
            display: 'grid',
            gridTemplateColumns: '60px 1fr',
            gridTemplateRows: `${calendarHeadHeight}px minmax(30px, auto) 1fr`,
            maxHeight: '100%',
        },
        allDayEventsContainer: {
            gridArea: '2 / 1 / 3 / 3',
            overflow: 'overlay',
            position: 'relative',
        },
        gridContainer: {
            overflow: 'overlay',
            position: 'relative',
            gridArea: '3 / 1 / 4 / 3',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '50px 10px repeat(7, 1fr)',
            gridTemplateRows: 'repeat(96, 15px)',
        },
        horizontalGridLine: {
            borderTop: `1px solid ${theme.palette.grey[ 300 ]}`,
        },
        borderRight: {
            borderRight: `1px solid ${theme.palette.grey[ 300 ]}`,
        },
        borderBottom: {
            borderBottom: `1px solid ${theme.palette.grey[ 300 ]}`,
        },
        borderTop: {
            borderTop: `1px solid ${theme.palette.grey[ 300 ]}`,
        },
        currentTimeBar: {
            borderTop: `2px solid #ea4335`,
            '& :before': {
                content: '',
                height: 5,
                width: 5,
                backgroundColor: 'red'
            }
        }
    })
)

const hourLabels = [
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
]

interface ICalendarWeekViewProps extends ICalendarDispatchProp {
    events: ICalendarState[ 'events' ]
}

const CalendarWeekView = ({ events, dispatch }: ICalendarWeekViewProps) => {
    const styles = useStyles()

    const scrollBarWidth = useScrollbarWidth()
    const theme = useTheme()

    return (
        <div className={clsx(styles.container)}>
            {/* Header */}
            <div style={{ gridArea: '1 / 2 / 2 / 3', paddingRight: `${theme.spacing(1) + (scrollBarWidth || 0)}px` }}>
                <MemoizedCalendarEventsHeaderWeek />
            </div>

            {/* All Day Events */}
            <div className={styles.allDayEventsContainer} style={{ paddingRight: `${theme.spacing(1) + (scrollBarWidth || 0)}px` }}>
                <CalendarWeekAllDayEvents events={events} dispatch={dispatch} />
            </div>

            {/* Events Grid */}
            <div className={clsx(styles.gridContainer)} style={{ paddingRight: `${theme.spacing(1) + (scrollBarWidth || 0)}px` }}>
                <div className={clsx(styles.grid)}>
                    <MemoizedCurrentTimeBar />

                    {/* Rows Grid Lines */}
                    {/* <div style={{ gridArea: '3 / 2 / 7 / 10' }} className={clsx(styles.horizontalGridLine)} /> */}
                    {hourLabels.map((label, index) => (
                        <React.Fragment key={label}>
                            <div style={{ gridRowStart: (index + 1) * 4 + 1, gridRowEnd: (index + 2) * 4 + 1, display: 'flex', justifyContent: 'flex-end', }}>
                                <Typography variant='caption' style={{ transform: 'translate(0, -8px)', paddingRight: 5, color: theme.palette.grey[ 600 ] }}>{label}</Typography>
                            </div>
                            <div className={clsx(styles.horizontalGridLine)} style={{ gridRowStart: (index + 1) * 4 + 1, gridRowEnd: (index + 2) * 4 + 1, gridColumnStart: 2, gridColumnEnd: 10, }} />
                        </React.Fragment>
                    ))}

                    {/* Columns Grid Lines */}
                    <div style={{ gridArea: '1 / 2 / 97 / 3' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 3 / 97 / 4' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 4 / 97 / 5' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 5 / 97 / 6' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 6 / 97 / 7' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 7 / 97 / 8' }} className={styles.borderRight} />
                    <div style={{ gridArea: '1 / 8 / 97 / 9' }} className={styles.borderRight} />

                    {/* Events */}
                    <CalendarWeekEvents events={events} dispatch={dispatch} />
                </div>
            </div>
        </div>
    )
}

export const MemoizedCalendarWeekView = React.memo(CalendarWeekView)


