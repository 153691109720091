import { Button, Typography, useTheme } from '@material-ui/core'
import { Edit, LinkOutlined } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useStartNewsletterMutation } from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import {
	FileFieldValue,
	FormikGroupSelector,
	FormikImageField,
	FormikTextInput,
	FormikUserTypeSelector,
} from './forms'

interface INewsletterStartProps {
	onSave: (newsletterId?: string) => void
}
export const NewsletterStart = ({ onSave }: INewsletterStartProps) => {
	const theme = useTheme()
	const buttonStyles = useButtonStyles()
	const {
		state: {
			school: { divisions },
			enabledUserTypes,
		},
	} = useSchoolContext()
	const { mutate: startNewsletter } = useHandleReactQueryMutation(
		useStartNewsletterMutation({
			onSuccess: data => onSave(data.createNewsletter?.newsletter?.id),
		})
	)

	const [mode, setMode] = useState<'link' | 'create'>()

	if (!mode) {
		return (
			<div style={{}}>
				<div style={{ margin: theme.spacing(2) }}>
					<div
						className={buttonStyles.iconButton}
						style={{ width: '100%' }}
						onClick={() => setMode('create')}
					>
						<Edit style={{ fontSize: '3rem' }} />
						<Typography>Create Newsletter</Typography>
					</div>
				</div>

				<div style={{ margin: theme.spacing(2) }}>
					<div
						className={buttonStyles.iconButton}
						style={{ width: '100%' }}
						onClick={() => setMode('link')}
					>
						<LinkOutlined style={{ fontSize: '3rem' }} />
						<Typography>Link to Existing Newsletter</Typography>
					</div>
				</div>
			</div>
		)
	}

	return (
		<Formik
			initialValues={{
				title: '',
				url: '',
				coverImage: undefined as FileFieldValue,
				groupIds: [] as string[],
				userTypes: [] as UserType[],
			}}
			validationSchema={Yup.object({
				title: Yup.string().required('Required'),
				groupIds: Yup.array()
					.of(Yup.string())
					.min(
						1,
						'Please select at least one Group to receive this Newsletter.'
					),
				url: Yup.mixed().test('url', 'Required', function (value) {
					if (mode === 'link') return Boolean(value)
					return true
				}),
			})}
			onSubmit={async values => {
				startNewsletter({
					...values,
					coverImage: values.coverImage && {
						create: {
							filename: values.coverImage.filename,
							type: values.coverImage.type,
						},
					},
					groups: { create: values.groupIds.map(groupId => ({ groupId })) },
				})
			}}
			children={formikProps => (
				<Form style={{ display: 'flex', flexDirection: 'column' }}>
					<FormikImageField
						field={{ name: 'coverImage', label: 'Cover Image' }}
						aspectRatio={3 / 1}
						aspectRatioHelper='3:1 aspect ratio (e.g. 1500px by 500px)'
						style={{ marginBottom: theme.spacing(2) }}
					/>
					<FormikTextInput
						noMargin
						fieldProps={{ name: 'title', label: 'Title' }}
						style={{
							marginLeft: theme.spacing(0.5),
							marginRight: theme.spacing(0.5),
						}}
					/>

					{mode === 'link' && (
						<FormikTextInput fieldProps={{ name: 'url', label: 'URL' }} />
					)}

					<FormikGroupSelector
						divisions={divisions.nodes}
						fieldProps={{
							name: 'groupIds',
							label: 'Select group(s) to receive this newsletter',
						}}
					/>
					<FormikUserTypeSelector
						field={{ name: 'userTypes' }}
						userTypes={enabledUserTypes}
					/>
					<Button type='submit' color='primary' variant='contained'>
						Start newsletter
					</Button>
				</Form>
			)}
		/>
	)
}
