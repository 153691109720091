import { FormControlLabel, Checkbox as MuiCheckbox, makeStyles, createStyles } from '@material-ui/core'
import { CommonProps } from '../interfaces'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: `0px ${theme.spacing(.5)}px`
        },
        error: {
            color: theme.palette.error.main,
        }
    })
)

interface ICheckboxProps extends CommonProps {
    name: string
    label?: string
    checked?: boolean
    onChange: (checked: boolean) => void
    disabled?: boolean
    error?: boolean
}
export const Checkbox = ({ checked, onChange, name, label, className, style, disabled, error }: ICheckboxProps) => {
    const styles = useStyles()

    const checkbox =
        <MuiCheckbox
            className={clsx(className, styles.padding, error && styles.error)}
            style={style}
            checked={checked}
            onChange={(_, checked) => onChange(checked)} name={name}
            disabled={disabled}
            color='primary'
        />

    if (label) return <FormControlLabel className={clsx(error && styles.error)} control={checkbox} label={label} />

    return checkbox
}