import { Button, withStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React, { ReactNode, useCallback, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { useBoop } from '../hooks'
import { CommonProps } from './interfaces'
import { MemoizedSpringHeight } from './spring-height'

interface IExpandableDropdownProps extends CommonProps {
    sectionTitle: ReactNode
    children: ReactNode
    controls?: {
        isOpen: boolean
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    }
    disabled?: boolean
    startExpanded?: boolean
}

const ExpandableDropdown = ({ sectionTitle, children, className, style, controls, disabled, startExpanded }: IExpandableDropdownProps) => {
    const [ isOpen, setIsOpen ] = useState(Boolean(startExpanded))

    const handleHeaderPress = useCallback(() => {
        if (controls) {
            controls.setIsOpen(_open => !_open)
        } else {
            setIsOpen(_open => !_open)
        }
    }, [ controls ])

    const getIsOpen = !!controls ? controls.isOpen : isOpen

    const expandIconSpring = useSpring({ transform: `rotate(${getIsOpen ? 180 : 0}deg)`, config: { tension: 300, friction: 18 } })
    const expandHoverBoop = useBoop({ rotation: 15, timing: 100, })

    return (
        <div className={className} style={style}>
            <HeaderButton
                onClick={handleHeaderPress}
                onMouseOver={expandHoverBoop.trigger}
                startIcon={
                    <animated.span
                        style={{
                            ...expandIconSpring,
                            display: 'flex'
                        }}
                    >
                        <animated.span style={{ ...expandHoverBoop.style, display: 'flex' }}>
                            <ExpandMore />
                        </animated.span>
                    </animated.span>
                }
                disabled={disabled}
            >
                {sectionTitle}
            </HeaderButton>
            <MemoizedSpringHeight isOpen={getIsOpen}>
                {children}
            </MemoizedSpringHeight>
        </div>
    )
}

const HeaderButton = withStyles(theme => ({
    root: {
        width: '100%',
        padding: `0px ${theme.spacing(1)}px`,
        height: 32,
        borderRadius: 0,
        color: 'inherit'
    },
    label: {
        justifyContent: 'flex-start'
    }
}))(Button)


export const MemoizedExpandableDropdown = React.memo(ExpandableDropdown)