import { useTheme } from '@material-ui/core'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { LinkButton } from './link-button'

interface IPageSectionButtonProps {
	pageSection: PageSectionFragment
}

export const PageSectionButton = ({ pageSection }: IPageSectionButtonProps) => {
	const theme = useTheme()

	return (
		<div style={{ margin: theme.spacing(2) }}>
			{pageSection.buttons?.buttons?.map(o => (
				<LinkButton key={o.id} style={{ width: '100%' }} button={o} />
			))}
		</div>
	)
}
