import { createStyles, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Add, NavigateBefore, NavigateNext } from '@material-ui/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { animated } from 'react-spring'
import { useAdminNewslettersQuery } from '../graphql/autogenerate/react-query'
import { locationHashToObject } from '../helpers'
import { useBoop, useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { EmptyState } from './empty-state'
import { Modal, useModal } from './modal'
import { NewsletterEdit } from './newsletter-edit'
import { NewsletterStart } from './newsletter-start'
import { NewsletterTile } from './newsletter-tile'
import { NewsletterTileEditable } from './newsletter-tile-editable'
import { REFETCH_INTERVAL } from '../constants'

interface IState {
    newsletterToEditId?: string
    month: Dayjs
}

const useStyles = makeStyles(theme =>
    createStyles({
        addButton: {
            border: `1px dashed`,
            borderColor: theme.palette.grey[ 300 ],
            color: theme.palette.grey[ 300 ],
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            }
        }
    })
)

export const AdminNewsletters = () => {
    const theme = useTheme()
    const styles = useStyles()

    const startNewsletterModal = useModal()
    const editNewsletterModal = useModal()

    const [ state, setState ] = useState<IState>({ month: dayjs() })

    const { state: { school: { id: schoolId } } } = useSchoolContext()

    const newslettersQuery = useHandleReactQuery(useAdminNewslettersQuery(
        { schoolId, publishDateAfter: state.month.startOf('month').toISOString(), publishDateBefore: state.month.endOf('month').toISOString() },
        {
            keepPreviousData: true,
            refetchInterval: REFETCH_INTERVAL,
        }
    ), 'newslettersQuery')
    const hoverBoop = useBoop({ rotation: 15, timing: 100, })

    const editNewsletter = useCallback((newsletterId: string) => {
        setState(_state => ({ ..._state, newsletterToEditId: newsletterId }))
        editNewsletterModal.open()
        navigate(`#edit=${newsletterId}`)
    }, [])

    // If the user pressed the back button, close the modal.
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        const parsedHash = locationHashToObject(location.hash)
        if (parsedHash.edit) {
            if (!editNewsletterModal.props.isOpen) {
                editNewsletter(parsedHash.edit)
            }
        } else {
            editNewsletterModal.close()
        }
    }, [ location.hash ])


    if (!newslettersQuery.isFetched) return null

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                <IconButton onClick={() => setState(_state => ({ ..._state, month: _state.month.subtract(1, 'month') }))} children={<NavigateBefore />} />
                <Typography variant='h4' color='textSecondary' style={{ margin: `0px ${theme.spacing(2)}px`, minWidth: 250 }} align='center'>{state.month.format('MMMM YYYY')}</Typography>
                <IconButton onClick={() => setState(_state => ({ ..._state, month: _state.month.add(1, 'month') }))} children={<NavigateNext />} />
            </div>
            {newslettersQuery.data?.newslettersForSchoolForCurrentPerson?.totalCount ?
                <div style={{ margin: theme.spacing(2), display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 450px)', gridAutoRows: 225, columnGap: theme.spacing(1), rowGap: theme.spacing(1), overflow: 'auto', flex: 1 }}>
                    {newslettersQuery.data.newslettersForSchoolForCurrentPerson.nodes.map(newsletter =>
                        <NewsletterTileEditable
                            key={newsletter.id}
                            newsletter={newsletter}
                            onClick={() => editNewsletter(newsletter.id)}
                            afterDelete={newslettersQuery.refetch}
                        />
                    )}
                    <div
                        onMouseEnter={hoverBoop.trigger}
                        onClick={startNewsletterModal.open}
                        className={styles.addButton}
                    >
                        <animated.div style={{ ...hoverBoop.style, display: 'flex' }}>
                            <Add />
                        </animated.div>
                        <div style={{ marginLeft: theme.spacing(.5), marginTop: 2 }}>Start a newsletter</div>
                    </div>
                </div>
                :
                <EmptyState title='No newsletters have been started for this month.' action={{ buttonTitle: 'Start a Newsletter', onClick: startNewsletterModal.open }} />
            }

            <Modal
                {...startNewsletterModal.props}
                title='Start a Newsletter'
                size='sm'
                dividers
                dismissible
                closeButton
                children={
                    <NewsletterStart
                        onSave={newsletterId => {
                            startNewsletterModal.close()
                            newslettersQuery.refetch()
                            setState(_state => ({ ..._state, newsletterToEditId: newsletterId }))
                            editNewsletterModal.open()
                        }}
                    />
                }
            />

            <NewsletterEdit
                newsletterId={state.newsletterToEditId}
                editModalControls={editNewsletterModal}
                refetchNewsletters={newslettersQuery.refetch}
                clearNewsletterToEdit={() => setState(_state => ({ ..._state, newsletterToEditId: undefined }))}
                setNewslettersMonth={month => setState(_state => ({ ..._state, month }))}
                newslettersMonth={state.month}
            />
        </>
    )
}
