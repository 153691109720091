import { SnackbarProvider as SnackbarProviderOC } from 'notistack'
import { createRef, FunctionComponent } from 'react'
import { Button } from '@material-ui/core'

export const SnackbarProvider: FunctionComponent = ({ children }) => {
    // const notistackRef = createRef<SnackbarProviderOC>()
    // const onClickDismiss = (key: string) => {
    //     console.log('dismissing', notistackRef.current)
    //     notistackRef.current?.closeSnackbar(key)
    // }

    return (
        <SnackbarProviderOC
            // ref={notistackRef}
            maxSnack={4}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        // preventDuplicate
        // action={(key) => (
        //     <Button variant='text' style={{ color: 'white' }} onClick={() => onClickDismiss(key.toString())}>
        //         Dismiss
        //     </Button >
        // )}
        >
            {children}
        </SnackbarProviderOC>
    )
}