import { createStyles, IconButton, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { useCommonStyles } from '../styles'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, useMemo, useRef } from 'react'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { getContrastTextColor } from '../helpers'
import tinycolor from 'tinycolor2'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useHoverDirty } from 'react-use'
import { DragHandle } from '@material-ui/icons'

export const useSponsorTileStyles = makeStyles(theme =>
    createStyles({
        tile: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            cursor: 'pointer',
            position: 'relative',
        },
        tileIcon: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            opacity: .8,
            color: theme.palette.grey[ 300 ],
        },
        disabledIcon: {
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2),
            opacity: .8,
            color: theme.palette.grey[ 300 ],
        },
        iconContainer: {
            position: 'absolute',
            color: 'white',
            display: 'flex',
        },
        topLeft: {
            top: theme.spacing(.5),
            left: theme.spacing(.5),
        },
        icon: {
            zIndex: 10,
            backgroundColor: `${theme.palette.grey[ 300 ]}`,
            opacity: .5,
            '&:hover': {
                backgroundColor: `${theme.palette.grey[ 300 ]}`,
                opacity: 1,
            },
        },
        isDragging: {
            border: `2px dashed ${theme.palette.grey[ 500 ]}`,
            borderRadius: theme.shape.borderRadius,
            cursor: 'grabbing',
            opacity: .6,
        },
    })
)

interface ISponsorTileProps {
    sponsor: SponsorFragment
    onClick?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>[ 'onClick' ]
}

export const SponsorTile = forwardRef<HTMLDivElement, ISponsorTileProps>(({ sponsor, onClick }, ref) => {
    const theme = useTheme()
    const styles = useSponsorTileStyles()
    const commonStyles = useCommonStyles()

    const backgroundImage = useMemo(() => {
        if (!sponsor.coverImage?.temporaryDownloadUrl) return
        const { r, g, b } = tinycolor(sponsor.primaryColor || theme.palette.primary.main).toRgb()
        return `linear-gradient(to bottom, rgba(${r},${g},${b},.5), rgba(${r},${g},${b},.9)), url(${sponsor.coverImage.temporaryDownloadUrl})`
    }, [ sponsor.coverImage, sponsor.primaryColor ])

    return (
        <div
            ref={ref}
            id={sponsor.id}
            className={clsx(styles.tile, commonStyles.elevationOnHover)}
            onClick={onClick}
            style={{
                backgroundColor: sponsor.primaryColor || theme.palette.primary.main,
                color: getContrastTextColor(sponsor.primaryColor || theme.palette.primary.main),
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                cursor: 'pointer',
            }}

        >
            <Typography variant='h6'>{sponsor.title}</Typography>
        </div>
    )
}
)

interface ISortableSponsorTileProps extends ISponsorTileProps {
    sortingDisabled?: boolean
}

export const SortableSponsorTile = (props: ISortableSponsorTileProps) => {
    const styles = useSponsorTileStyles()

    const ref = useRef<HTMLDivElement | null>(null)
    const isHovering = useHoverDirty(ref)

    const { attributes, listeners, setNodeRef, transform, transition, isDragging, } = useSortable({ id: props.sponsor.id, disabled: props.sortingDisabled })

    return (
        <div
            id={props.sponsor.id}
            ref={node => {
                ref.current = node
                setNodeRef(node)
            }}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: transition || undefined,
                position: 'relative',
            }}
            className={clsx(isDragging && styles.isDragging)}
        >
            {!isDragging &&
                <>
                    {!props.sortingDisabled &&
                        <div className={clsx(styles.iconContainer, styles.topLeft)} style={{ opacity: isHovering ? 1 : 0 }}>
                            <Tooltip title='Reorder'>
                                <IconButton
                                    size='small'
                                    className={styles.icon}
                                    {...listeners}
                                    {...attributes}
                                    style={{ cursor: 'grab' }}
                                >
                                    <DragHandle />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }

                    <SponsorTile
                        {...props}
                    />
                </>
            }
        </div>
    )
}