import { Fade, TableContainer, Table as ReactTable, TableHead, TableRow, TableCell, TableBody, Paper, makeStyles, createStyles, Size, TableSortLabel } from '@material-ui/core'
import { Column, Row, TableState, useSortBy, useTable } from 'react-table'

interface ITableProps<D extends object> {
    data: D[]
    columns: Column<D>[]
    onRowPressed?: (row: Row<D>) => void
    tableSize?: Size
    hover?: boolean
    initialTableState?: Partial<TableState<D>>
}
export const Table = <D extends object,> ({ data, columns, onRowPressed, tableSize, hover, initialTableState }: ITableProps<D>) => {
    const tableInstance = useTable({ columns, data, disableMultiSort: true, initialState: initialTableState }, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    if (data.length === 0) {
        return (
            <Fade in={true} style={{ transitionDelay: '300ms' }} unmountOnExit>
                <div>
                    No results.
                </div>
            </Fade>
        )
    }

    return (
        <TableContainer component={Paper} style={{ maxHeight: '100%' }}>
            <ReactTable aria-label='table' size={tableSize} {...getTableProps()} stickyHeader>
                <TableHead>
                    {
                        // Loop over the header rows
                        headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Loop over the headers in each row
                                    headerGroup.headers.map(column => {
                                        const props = column.getHeaderProps(column.getSortByToggleProps())
                                        return (
                                            // Apply the header cell props
                                            <TableCell {...props} sortDirection={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : false} align='left' style={{ ...props.style, width: column.width, }}>
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                    style={{ width: '100%' }}
                                                >
                                                    {
                                                        // Render the header
                                                        column.render('Header')
                                                    }
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        ))
                    }
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()} hover={hover || Boolean(onRowPressed)} onClick={() => onRowPressed && onRowPressed(row)}>
                                {
                                    // Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                {
                                                    // Render the cell contents
                                                    cell.render('Cell')
                                                }
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        )
                    })}
                </TableBody>
            </ReactTable>
        </TableContainer>
    )
}