import { ReactNode } from 'react'

/**
    Swaps two elements in an array using destructuring assignment based on the elements' indexes.
    https://stackoverflow.com/questions/872310/javascript-swap-array-elements

    Does __NOT__ check to make sure those elements exist. If you attempt to swap to/from nonexistant indexes you may end up with `undefined` values in your array.
*/
export const swapArrayMembers = <T,> (arr: T[], index1: number, index2: number) => {
    return [ arr[ index1 ], arr[ index2 ] ] = [ arr[ index2 ], arr[ index1 ] ]
}

/** 
    Gathers strings into a human formatted comma separated list.

    @example
    const joined = joinStrings(['hi', 'hello', 'goodbye'])

    console.log(joined) // 'hi, hello, and goodbye'
*/
export const joinStrings = (strings: string[], bold?: boolean, conjunction?: string) => {
    return strings.reduce((names, name, index, array) => {
        // First item
        if (index === 0) return bold ? <b>{name}</b> : name
        // Only two items
        if (array.length === 2) return bold ? <>{names} {conjunction || 'and'} <b>{name}</b></> : `${names} ${conjunction || 'and'} ${name}`
        // More than two items: last item
        if (index === array.length - 1) return bold ? <>{names}, {conjunction || 'and'} <b>{name}</b></> : `${names}, ${conjunction || 'and'} ${name}`
        // More than two items: any item but the first or the last
        return bold ? <>{names}, <b>{name}</b></> : `${names}, ${name}`
    }, <></> as ReactNode)
}