import { useTheme, makeStyles, createStyles, IconButton, Tooltip } from '@material-ui/core'
import { Delete, DragHandle, Edit, FileCopy } from '@material-ui/icons'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { DetailedHTMLProps, HTMLAttributes, useRef } from 'react'
import { useCopyToClipboard, useHoverDirty } from 'react-use'
import { NewsletterFragment } from '../graphql/autogenerate/operations'
import { useDeleteNewsletterMutation } from '../graphql/autogenerate/react-query'
import { NewsletterStatus } from '../graphql/autogenerate/schemas'
import { Callout } from './callout'
import { ConfirmDialog } from './confirm-dialog'
import { useModal } from './modal'
import { INewsletterTileProps, NewsletterTile } from './newsletter-tile'

const useStyles = makeStyles(theme =>
    createStyles({
        iconContainer: {
            position: 'absolute',
            top: theme.spacing(1),
        },
        topLeftIconContainer: {
            left: theme.spacing(1),
        },
        topRightIconContainer: {
            right: theme.spacing(1),
        },
        icon: {
            zIndex: 10,
            color: `${theme.palette.grey[ 300 ]}`,
            transition: 'opacity .2s ease-in-out',
            opacity: .5,
            '&:hover': {
                opacity: 1,
            },

        },
    })
)

interface INewsletterTileEditableProps extends Omit<INewsletterTileProps, 'children'> {
    afterDelete: () => void
}

export const NewsletterTileEditable = ({ newsletter, afterDelete, ...rest }: INewsletterTileEditableProps) => {
    const theme = useTheme()
    const styles = useStyles()

    const ref = useRef<HTMLDivElement>(null)
    const isHovering = useHoverDirty(ref)

    const { enqueueSnackbar } = useSnackbar()

    const confirmDelete = useModal()
    const { mutate: deleteNewsletter } = useDeleteNewsletterMutation({
        onSuccess: async () => {
            enqueueSnackbar('Newsletter deleted.', { variant: 'warning' })
            afterDelete()
            confirmDelete.close()
        }
    })

    const [ _, copyToClipoard ] = useCopyToClipboard()

    return (
        <>
            <NewsletterTile ref={ref} newsletter={newsletter} {...rest}>
                {newsletter.status !== NewsletterStatus.Draft &&
                    <div className={clsx(styles.iconContainer, styles.topLeftIconContainer)} style={{ opacity: isHovering ? 1 : 0 }}>
                        <Tooltip title='Copy share link'>
                            <IconButton
                                size='small'
                                className={styles.icon}
                                onClick={e => {
                                    e.stopPropagation()
                                    copyToClipoard(`${process.env.REACT_APP_ROOT_URL}/newsletter/${newsletter.slug}`)
                                    enqueueSnackbar('Copied!')
                                }}
                            >
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                    </div>
                }

                <div className={clsx(styles.iconContainer, styles.topRightIconContainer)} style={{ opacity: isHovering ? 1 : 0 }}>
                    <Tooltip title='Edit newsletter'>
                        <IconButton
                            size='small'
                            className={styles.icon}
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete newsletter'>
                        <IconButton
                            size='small'
                            className={styles.icon}
                            onClick={e => {
                                e.stopPropagation()
                                confirmDelete.open()
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </div>
            </NewsletterTile>

            <ConfirmDialog
                {...confirmDelete.props}
                confirm={() => deleteNewsletter({ id: newsletter.id })}
                body={
                    <>
                        Are you sure you want to delete this newsletter?

                        <Callout type='error' style={{ marginTop: theme.spacing(2) }}>
                            All its content (text, buttons, images, etc.) will be deleted and cannot be recovered.
                        </Callout>
                    </>
                }
                confirmButton={{
                    type: 'error',
                    label: 'Delete'
                }}
            />
        </>
    )
}

