import { createStyles, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React from 'react'
import { Modal, useModal } from './modal'
import { Tooltip } from './tooltip'


export const CalendarHeaderInfo = () => {
    const infoModal = useModal()

    return (
        <>
            <Tooltip title='Info'>
                <IconButton aria-label='Info' onClick={infoModal.open}>
                    <Info />
                </IconButton>
            </Tooltip>

            <Modal
                {...infoModal.props}
                title='Keyboard Shortcuts'
                size='md'
                dismissible
                dividers
                closeButton
            >
                <Grid container>
                    <Grid item xs={6}>
                        <KeyboardShortcutPanel
                            title='Navitation'
                            shortcuts={[
                                { label: 'Today', primaryKey: 't' },
                                { label: 'Next week', primaryKey: 'Arrow Right', secondaryKey: 'n' },
                                { label: 'Previous week', primaryKey: 'Arrow Left', secondaryKey: 'p' },
                                // { label: 'Next period (day/week view)', primaryKey: 'Shift + Scroll Down' },
                                // { label: 'Previous period (day/week view)', primaryKey: 'Shift + Scroll Up' },
                                // { label: 'Next period (month view)', primaryKey: 'Scroll Down' },
                                // { label: 'Previous period (month view)', primaryKey: 'Scroll Up' },
                                { label: 'Next week', primaryKey: 'Shift + Scroll Down' },
                                { label: 'Previous week', primaryKey: 'Shift + Scroll Up' },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <KeyboardShortcutPanel
                            title='Views'
                            shortcuts={[
                                // { label: 'Day view', primaryKey: '1', secondaryKey: 'd' },
                                // { label: 'Week view', primaryKey: '2', secondaryKey: 'w' },
                                // { label: 'Month view', primaryKey: '3', secondaryKey: 'm' },
                                { label: 'Show/Hide side panel', primaryKey: '[' },
                            ]}
                        />
                    </Grid>

                </Grid>
            </Modal>
        </>
    )
}

export const MemoizedCalendarHeaderInfo = React.memo(CalendarHeaderInfo)

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            fontWeight: theme.typography.fontWeightBold
        },
        row: {
            height: 40,
        },
        titleCell: {
            paddingLeft: theme.spacing(2)
        },
        keyBox: {
            backgroundColor: theme.palette.grey[ 200 ],
            padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        }
    })
)

interface IKeyboardShortcutPanelProps {
    title: string
    shortcuts: {
        primaryKey: string
        secondaryKey?: string
        label: string
    }[]
}
const KeyboardShortcutPanel = ({ title, shortcuts }: IKeyboardShortcutPanelProps) => {
    const styles = useStyles()

    return (
        <Grid container >
            <Grid item xs={12}>
                <Typography color='textSecondary' className={styles.title}>{title}</Typography>
                <table width='100%'>
                    <tbody>
                        {shortcuts.map(({ label, primaryKey, secondaryKey }) => (
                            <tr className={styles.row}>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div className={styles.keyBox}>
                                            {primaryKey}
                                        </div>
                                        {secondaryKey &&
                                            <>
                                                <Typography color='textSecondary'>or</Typography>
                                                <div className={styles.keyBox}>
                                                    {secondaryKey}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </td>
                                <td className={styles.titleCell}>
                                    {label}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    )
}