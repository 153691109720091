import { Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { ArtTrack } from '@material-ui/icons'
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import { GroupDetailFragment } from '../graphql/autogenerate/operations'
import { useGroupDetailBySlugQuery } from '../graphql/autogenerate/react-query'
import { IconType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { IconTypeDisplay } from './icon-type-display'
import { useMobileContext } from './school'

interface ISchoolGroupState {
}

interface ISchoolGroupContextState extends ISchoolGroupState {
    group: GroupDetailFragment
}

interface ISchoolGroupContext {
    state: ISchoolGroupContextState
    setState: Dispatch<SetStateAction<ISchoolGroupState>>
}

const SchoolGroupContext = createContext<ISchoolGroupContext | undefined>(undefined)
export const useSchoolGroupContext = () => {
    const context = useContext(SchoolGroupContext)
    if (!context) throw new Error('Cannot use SchoolGroupContext before it is provided.')
    return context
}

export const SchoolGroupHome = () => {
    const { groupId } = useParams()
    const theme = useTheme()
    const { setState: setMobileState } = useMobileContext()
    const { data } = useHandleReactQuery(useGroupDetailBySlugQuery({ slug: groupId }, { refetchInterval: REFETCH_INTERVAL }))
    const group = data?.groupBySlug
    const [ state, setState ] = useState<ISchoolGroupState>({})

    useEffect(() => {
        setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, backUrl: 'groups' } }))

        return () => {
            setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, backUrl: undefined } }))
        }
    }, [])

    const [ tabIdx, setTabIdx ] = useState(0)
    const navigate = useNavigate()

    if (!group) return null

    return (
        <SchoolGroupContext.Provider value={{ state: { ...state, group }, setState }}>
            {group.hasContent ?
                <div style={{ padding: theme.spacing(1), paddingBottom: 63, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden', flex: 1 }}>
                    <div>
                        <div style={{ margin: theme.spacing(1), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ height: 100, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: group.groupCategory?.iconBackgroundColor || theme.palette.primary.main, color: 'white' }}>
                                <IconTypeDisplay type={group.icon as IconType} size='50%' />
                            </div>
                            <Typography style={{ maxWidth: 100, marginTop: theme.spacing(.5), }} align='center'>{group.name}</Typography>
                        </div>

                        {group.information && <div style={{ marginTop: theme.spacing(1) }} dangerouslySetInnerHTML={{ __html: group.information }} />}

                        <Tabs
                            value={tabIdx}
                            onChange={(_, value) => setTabIdx(value)}
                        >
                            <Tab
                                component='a'
                                onClick={(event) => {
                                    event.preventDefault()
                                    navigate('resources')
                                }}
                                href='resources'
                                label='Resources'
                            />
                            <Tab
                                component='a'
                                onClick={(event) => {
                                    event.preventDefault()
                                    navigate('calendar')
                                }}
                                href='calendar'
                                label='Calendar'
                            />
                            <Tab
                                component='a'
                                onClick={(event) => {
                                    event.preventDefault()
                                    navigate('newsletters')
                                }}
                                href='newsletters'
                                label='Newsletters'
                            />
                            <Tab
                                component='a'
                                onClick={(event) => {
                                    event.preventDefault()
                                    navigate('notifications')
                                }}
                                href='notifications'
                                label='Notifications'
                            />
                        </Tabs>
                    </div>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'auto' }}>
                        <Outlet />
                    </div>
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', flex: 1, paddingBottom: theme.spacing(10) }}>
                    <div style={{ marginBottom: theme.spacing(4), }}>
                        <div style={{ height: 100, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: group.groupCategory?.iconBackgroundColor || theme.palette.primary.main, color: 'white' }}>
                            <IconTypeDisplay type={group.icon as IconType} size='50%' />
                        </div>
                    </div>
                    <Typography gutterBottom align='center'>
                        This is the place for all things <b>{group.name}</b>.
                        <br />
                        Unfortunately, nothing has been added yet...
                    </Typography>
                    <Typography gutterBottom align='center'>
                        Ask the main office about getting <b>{group.name}</b>
                        <br />
                        information into the app!
                    </Typography>
                </div>
            }

        </SchoolGroupContext.Provider>
    )
}