import { Typography, useTheme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { ArrowBackIos, Link } from '@material-ui/icons'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState, HTMLAttributes } from 'react'
import { useLocation } from 'react-router'
import tinycolor from 'tinycolor2'
import { REFETCH_INTERVAL } from '../constants'
import { useGetNewsletterByIdQuery, useGroupNewslettersQuery } from '../graphql/autogenerate/react-query'
import { getContrastTextColor } from '../helpers'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { NewsletterDisplay } from './newsletter-display'
import { useSchoolGroupContext } from './school-group-home'
import { SchoolNavBar } from './school-nav-bar'

export const SchoolGroupNewsletters = () => {
    const theme = useTheme()
    const { state: { school: { slug } } } = useSchoolContext()
    const { state: { group } } = useSchoolGroupContext()

    const { data } = useHandleReactQuery(useGroupNewslettersQuery({ groupId: group.id }, { refetchInterval: REFETCH_INTERVAL }))
    const newsletters = data?.group?.newsletters.nodes

    const [ selectedNewsletterId, setSelectedNewsletterId ] = useState<string>()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'auto', padding: `${theme.spacing(1)}px 0px`, flex: 1 }}>
            {newsletters?.map(newsletter =>
                <NewsletterCell
                    {...newsletter}
                    key={newsletter.id}
                    coverImageUrl={newsletter.coverImage?.temporaryDownloadUrl}
                    color={group.groupCategory?.iconBackgroundColor || theme.palette.primary.main}
                    onClick={() => {
                        setSelectedNewsletterId(newsletter.id)
                        gtag('event', 'newsletter_viewed', {
                            event_category: slug,
                            event_label: newsletter.title,
                        })
                    }}
                />
            )}

            {newsletters?.length === 0 &&
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: theme.spacing(4) }}>
                    <Typography gutterBottom color='textSecondary' align='center'>No newsletters have been published yet.</Typography>
                    <Typography color='textSecondary' align='center'>Check back later!</Typography>
                </div>
            }

            {selectedNewsletterId && <NewsletterDetail newsletterId={selectedNewsletterId} onClosePressed={() => setSelectedNewsletterId(undefined)} />}
        </div>
    )
}

interface INewsletterCellProps {
    coverImageUrl?: string | null
    publishDate?: string | null
    title?: string | null
    color: string
    url?: string | null
    onClick: () => void
    style?: CSSProperties
}

const NewsletterCell = (
    {
        coverImageUrl,
        publishDate,
        title,
        color,
        style,
        url,
        onClick
    }: INewsletterCellProps
) => {
    const theme = useTheme()

    const backgroundImage = useMemo(() => {
        if (!coverImageUrl) return
        const { r, g, b } = tinycolor(color).toRgb()
        return `linear-gradient(to bottom, rgba(${r},${g},${b},.3), rgba(${r},${g},${b},.6)), url(${coverImageUrl})`
    }, [ coverImageUrl, color ])

    const textColor = useMemo(() => {
        return getContrastTextColor(color)
    }, [ color ])

    const cellStyle: CSSProperties = {
        ...style,
        backgroundColor: backgroundImage ? undefined : color,
        backgroundImage,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        color: textColor,
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        position: 'relative'
    }

    const cell = (
        <>
            {url && <Link style={{ position: 'absolute', top: 5, right: 5, color: 'white' }} />}
            <div>{dayjs(publishDate || undefined).format('MM/DD/YYYY')}</div>
            <Typography variant='h5'>{title}</Typography>
        </>
    )

    if (url) return <a target='_blank' href={url} style={cellStyle}>{cell}</a>

    return <div onClick={onClick} style={cellStyle}>{cell}</div>
}


interface INewsletterDetailProps {
    newsletterId: string
    onClosePressed: () => void
}
const NewsletterDetail = ({ newsletterId, onClosePressed }: INewsletterDetailProps) => {
    const theme = useTheme()

    const { data } = useHandleReactQuery(useGetNewsletterByIdQuery({ id: newsletterId }, { refetchInterval: REFETCH_INTERVAL }))
    const newsletter = data?.newsletter

    const notInitialRender = useRef(false)
    const location = useLocation()
    useEffect(() => {
        const homePressed = location.pathname.split('/').pop() === 'notifications'
        if (notInitialRender.current && homePressed) onClosePressed()
        notInitialRender.current = true
    }, [ location ])

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, paddingBottom: 55, backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
            <SchoolNavBar style={{ padding: `0px ${theme.spacing(1)}px`, justifyContent: 'flex-start' }}>
                <div style={{ minWidth: 100, display: 'flex', alignItems: 'center' }} onClick={onClosePressed}>
                    <ArrowBackIos />
                    <span>Back</span>
                </div>
            </SchoolNavBar>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', padding: theme.spacing(1) }}>
                {newsletter && <NewsletterDisplay newsletter={newsletter} />}
            </div>
        </div >
    )
}