
import { Modal, IModalControlProps } from './modal'
import { useHandleReactQueryMutation } from '../hooks'
import { Button, useTheme, makeStyles, createStyles, Box } from '@material-ui/core'
import { CalendarGroupFragment, SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { Delete, DragHandle, Remove } from '@material-ui/icons'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { useCallback } from 'react'
import { useCommonStyles } from '../styles'
import Sortable from 'sortablejs'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { FormikTextInput, FormikDynamicFieldsTouched, FormikGroupSelector } from './forms'
import { v4 } from 'uuid'
import { Callout } from './callout'
import { ICalendarActions } from '../stores/calendar'
import { flatten } from 'lodash'
import { ISchoolState, useSchoolContext } from '../stores/school'
import React from 'react'
import { LoadingButton } from './loading-button'
import { EmptyState } from './empty-state'
import { useCreateOrUpdateCalendarGroupMutation, useDeleteCalendarGroupMutation } from '../graphql/autogenerate/react-query'

const useStyles = makeStyles((theme) =>
    createStyles({
        grid: {
            display: 'grid',
            gridTemplateColumns: '30px 200px auto 25px',
            columnGap: theme.spacing(1),
            rowGap: theme.spacing(1),
            justifyItems: 'stretch',
            alignItems: 'start',
            flex: 1,
            marginBottom: theme.spacing(1),
        },
        inputHeight: {
            height: 56
        },
        sortableHandle: {
            cursor: 'pointer',
            justifySelf: 'center'
        }
    })
)

interface ICalendarGroupsFormProps {
    modalControls: IModalControlProps
    calendarGroups: CalendarGroupFragment[]
    groups: ISchoolState[ 'groups' ]
    refetch: ICalendarActions[ 'refetch' ][ 'calendarGroups' ]
    divisions: SchoolDivisionsFragment[]
}
export const CalendarGroupsForm = ({ calendarGroups, refetch, groups, modalControls, divisions }: ICalendarGroupsFormProps) => {

    const { state: { school: { id: schoolId } } } = useSchoolContext()

    const { mutateAsync: createOrUpdateCalendarGroup } = useHandleReactQueryMutation(useCreateOrUpdateCalendarGroupMutation())

    const calendarGroupSchema = Yup.object({
        id: Yup.string().required('Required'),
        isNew: Yup.boolean().required('Required').default(true),
        sortOrder: Yup.number().required('Required'),
        title: Yup.string().required('Required'),
        groupIds: Yup.array().of(Yup.string()).min(1, 'Please select at least one calendar.').required('Required')
    })

    const validationSchema = Yup.object({
        calendarGroups: Yup.array().of(calendarGroupSchema).required('Required')
    })

    const initialValues: {
        calendarGroups: ICalendarGroupFormRow[]
    } = {
        calendarGroups: calendarGroups.map<ICalendarGroupFormRow>((g, index) => ({
            id: g.id,
            isNew: false,
            sortOrder: g.sortOrder,
            title: g.title,
            groupIds: g.calendarGroupCalendars.nodes.map(o => {
                if (!o.calendar?.group) throw new Error('CalendarGroupsForm: Encountered a Calendar without a Group.')
                return o.calendar.group.id
            })
        }))
    }

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                const promises: Promise<any>[] = values.calendarGroups.map(calendarGroup =>
                    createOrUpdateCalendarGroup({
                        ...calendarGroup,
                        schoolId,
                        calendarIds: flatten(groups.filter(group => calendarGroup.groupIds.includes(group.id)).map(o => o.calendarIds)),
                    })
                )

                await Promise.all(promises)
                await refetch()
                actions.resetForm()
                modalControls.close()
            }}

            children={({ values, submitForm, setValues, isSubmitting, isValid }) => {
                // Store the addCalendarGroup function out here so we can set it inside the <FieldArray /> component but use it in the <Modal actions={...} /> actions footer.
                let addCalendarGroup: () => void

                return (
                    <>
                        <Modal
                            {...modalControls.props}
                            title='Manage Calendar Groups'
                            size='md'
                            dividers

                            dismissible
                            closeButton

                            actions={
                                <Box flex={1} display='flex' justifyContent='space-between'>
                                    {values.calendarGroups.length > 0 &&
                                        <Button type='button' variant='outlined' color='primary' onClick={() => addCalendarGroup && addCalendarGroup()}>Add Calendar Group</Button>
                                    }
                                    <Box display='flex' justifyContent='flex-end' flex={1}>
                                        <LoadingButton
                                            type='submit'
                                            color='primary'
                                            variant='contained'
                                            loading={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            }
                        >
                            <FormikDynamicFieldsTouched />
                            <Form>
                                <Callout
                                    children={
                                        <div>
                                            <p>With <b>Calendar Groups</b> you can combine calendars into a single "toggle".</p>
                                            <p>The most common use case for a Calendar Group is <b>Athletics</b>. You can gather all Athletics calendars into a single view so everyone can easily see all Athletics events at a glance without having to manually enable/disable each individual athletics calendar.</p>
                                            <p>Calendar Groups will appear as dropdown options at the top of the <b>Calendar</b> tab of your school's app.</p>
                                        </div>
                                    }
                                    type='secondary'
                                />
                                <Box my={3} />
                                <FieldArray
                                    name='calendarGroups'
                                    children={arrayHelpers => {
                                        /* 
                                            To "add" a new Calendar Group, we push a set of fields to the array of calendarGroups in this form.
                                            Nothing is persisted until we save the whole form.
                                        */
                                        addCalendarGroup = () => arrayHelpers.push({ id: v4(), isNew: true, sortOrder: values.calendarGroups.length })

                                        return (
                                            <>
                                                <GroupCategoryFormRows
                                                    calendarGroups={values.calendarGroups}
                                                    addCalendarGroup={addCalendarGroup}
                                                    remove={arrayHelpers.remove}
                                                    setValues={setValues}
                                                    refetch={refetch}
                                                    divisions={divisions}
                                                />
                                            </>
                                        )
                                    }}
                                />
                            </Form>
                        </Modal>
                    </>
                )
            }}
        />
    )
}

interface ICalendarGroupFormRow {
    id: string
    isNew: boolean
    sortOrder: number
    title: string
    groupIds: string[]
}

interface IGroupCategoryFormRowsProps {
    calendarGroups: ICalendarGroupFormRow[]
    addCalendarGroup: () => void
    remove: (index: number) => void
    setValues: (values: React.SetStateAction<{ calendarGroups: ICalendarGroupFormRow[] }>) => void
    refetch: ICalendarGroupsFormProps[ 'refetch' ]
    divisions: SchoolDivisionsFragment[]
}

/* 
    We split out the form rows so that we can set up the sortable Ref AFTER the <Forkik /> component starts. 
    We need to do this so that we can access the "setValues" render prop from the Formik Form.
*/
const GroupCategoryFormRows = ({ calendarGroups, addCalendarGroup, remove, setValues, refetch, divisions }: IGroupCategoryFormRowsProps) => {
    const commonStyles = useCommonStyles()
    const styles = useStyles()
    const theme = useTheme()

    const sortableListRef = useCallback((element: HTMLDivElement) => {
        if (element) {
            Sortable.create(element, {
                animation: 150,
                easing: 'cubic-bezier(1, 0, 0, 1)',
                handle: '.sortableHandle',
                draggable: '.sortableDraggable',
                store: {
                    set: async (sortable) => {
                        const ids = sortable.toArray()
                        setValues(({ calendarGroups: prevCalendarGroups }) => ({
                            calendarGroups: prevCalendarGroups.map(g => (
                                {
                                    ...g,
                                    sortOrder: ids.findIndex(id => id === g.id)
                                }
                            ))
                        }))
                    },
                    get: () => {
                        return []
                    }
                },
            })
        }
    }, [])

    const { mutate: deleteCalendarGroup } = useHandleReactQueryMutation(useDeleteCalendarGroupMutation())
    const handleDeletePress = useCallback(async (calendarGroupId: string) => deleteCalendarGroup({ id: calendarGroupId }, { onSettled: () => refetch() }), [])

    return (
        <div ref={sortableListRef} >
            {/* If we don't have any group categories yet, show an empty state and an add button. */}
            {calendarGroups.length === 0 && <EmptyState title='No Calendar Groups created yet.' action={{ buttonTitle: 'Add Calendar Group', onClick: addCalendarGroup }} />}

            {/* If we have group categories, show the form rows. */}
            {calendarGroups.length > 0 &&
                <div
                    className={clsx(styles.grid)}
                    style={{
                        borderBottom: `1px solid ${theme.palette.grey[ 400 ]}`,
                        paddingBottom: theme.spacing(.5),
                        marginBottom: theme.spacing(3)
                    }}
                >
                    <b></b>
                    <b>Title</b>
                    <b>Calendars</b>
                    <b></b>
                </div>
            }
            {calendarGroups.map((calendarGroup, index) => (
                <div key={calendarGroup.id} data-id={calendarGroup.id} className={clsx(styles.grid, 'sortableDraggable')}>
                    <DragHandle className={clsx(styles.sortableHandle, styles.inputHeight, 'sortableHandle')} />

                    <FormikTextInput
                        fieldProps={{ name: `calendarGroups.${index}.title`, label: 'Calendar Group Title' }}
                        style={{ margin: 0 }}
                    />

                    <FormikGroupSelector divisions={divisions} fieldProps={{ name: `calendarGroups.${index}.groupIds`, label: 'Calendar(s)' }} />

                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className={clsx(styles.inputHeight)}
                    >
                        {calendarGroup.isNew ?
                            // If this Calendar Group was just added to the list (i.e. not yet persisted to the database), just remove it from the list. 
                            <AnimationIconWraper
                                children={<Remove />}
                                className={clsx(commonStyles.errorColorHover)}
                                onClick={() => remove(index)}
                                tooltip={{ title: 'Remove', placement: 'top' }}
                            />
                            :
                            // If this Calendar Group was a preexisting (i.e. persisted) Calendar Group, delete it entirely and refetch.
                            <AnimationIconWraper
                                children={<Delete />}
                                className={clsx(commonStyles.errorColorHover)}
                                onClick={() => handleDeletePress(calendarGroup.id)}
                                tooltip={{ title: 'Delete', placement: 'top' }}
                            />
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}
