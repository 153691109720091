import { useTheme, Typography, Button } from '@material-ui/core'
import { FormikProps, Formik, Form } from 'formik'
import { useSnackbar } from 'notistack'
import { useRef } from 'react'
import { SchoolPersonFragment } from '../graphql/autogenerate/operations'
import { useSetSchoolAdminGroupsForPersonMutation } from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { FormikGroupSelector } from './forms'
import { Modal, useModal } from './modal'
import { UserPermissionsDescription } from './user-permissions-descriptor'

interface ISchoolPermissionsModalProps {
    modal: ReturnType<typeof useModal>
    afterClose: () => void
    schoolPersonToEdit?: SchoolPersonFragment
}

export const SchoolPersonPermissionsEditModal = ({ modal, afterClose, schoolPersonToEdit }: ISchoolPermissionsModalProps) => {
    const theme = useTheme()

    const { state: { school: { divisions: { nodes: divisions }, id: schoolId }, groups } } = useSchoolContext()

    const initialValues = {
        groupIds: schoolPersonToEdit?.person?.adminGroups.nodes.map(o => o.groupId) || []
    }

    const { enqueueSnackbar } = useSnackbar()
    const { mutateAsync: setAdminGroups } = useHandleReactQueryMutation(useSetSchoolAdminGroupsForPersonMutation({
        onSuccess: () => {
            enqueueSnackbar('Permissions updated.', { variant: 'success' })
            modal.close()
        }
    }))

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values) => {
                if (!schoolPersonToEdit?.person) throw new Error('Missing school person to edit.')
                setAdminGroups({ personId: schoolPersonToEdit.person.id, schoolId, groupIds: values.groupIds })
            }}
        >
            {formikProps =>
                <Modal
                    {...modal.props}
                    title={<div>Permissions for {schoolPersonToEdit?.person?.firstName} {schoolPersonToEdit?.person?.lastName} <Typography color='textSecondary'>{schoolPersonToEdit?.person?.email}</Typography></div>}
                    size='md'
                    dividers
                    dismissible
                    afterClose={() => {
                        formikProps.resetForm()
                        afterClose()
                    }}
                    actions={
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Button style={{ minWidth: 100 }} type='button' variant='text' onClick={modal.close}>Cancel</Button>

                            <Button type='submit' style={{ minWidth: 100 }} variant='contained' color='primary' onClick={formikProps.submitForm}>Save</Button>
                        </div>
                    }
                >
                    <Form>
                        <div>
                            <Typography style={{ marginBottom: theme.spacing(2) }}>What would you like to give {schoolPersonToEdit?.person?.firstName} admin access to?</Typography>
                            <FormikGroupSelector divisions={divisions} fieldProps={{ name: 'groupIds', label: 'Select group(s)' }} />
                            <UserPermissionsDescription groupIds={formikProps.values.groupIds} divisions={divisions} groups={groups} firstName={schoolPersonToEdit?.person?.firstName} />
                        </div>
                    </Form>
                </Modal>
            }
        </Formik>
    )
}

