
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import logo from '../images/logo.svg'
import { usePaddedBorderStyles } from '../styles'

export const ReturnToApp = () => {
    const classes = usePaddedBorderStyles()
    const theme = useTheme()

    return (
        <Grid justify='center' alignItems='center' container direction='column' style={{ height: '100%' }}>
            <Grid className={clsx(classes.borders)} container item xs={12} sm={10} md={8} lg={5} direction='column' justify='center' style={{ padding: theme.spacing(2) }}>
                <Grid item container justify='center' alignContent='center' direction='column'>
                    <Box marginBottom={2} display='flex' justifyContent='center' >
                        <Link to='/'><img src={logo} alt='Legit Apps logo' style={{ maxWidth: 75 }} /></Link>
                    </Box>
                    <Box marginBottom={4}>
                        <Typography variant='h5' align='center'>Success!</Typography>
                    </Box>
                </Grid>
                <Grid item container justify='center'>
                    <Box textAlign='center'>
                        Operation successful! Please return to your school app to log in and continue.
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}