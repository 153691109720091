import { CSSProperties } from 'react'
import { Callout } from '../callout'
import { FormikTextInput } from './formik-text-input'
import { IField } from './interfaces'
import * as yup from 'yup'

const errorMessage =
	"Must be a valid YouTube, Vimeo, Apple Podcast, Spotify, or Soundcloud link. Be sure you're using the link for a single video/episode not a channel or podcast landing page."

export const embedUrlValidation = yup
	.string()
	.nullable()
	.matches(
		new RegExp(
			/^.*((youtu.be\/|vimeo.com\/|podcasts.apple.com\/|spotify.com\/|soundcloud.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
		),
		errorMessage
	)
	.test(
		'embedUrl',
		'Make sure you are posting just the URL for a video/podcast, not a full embed code.',
		value => {
			if (value && value.includes('iframe')) return false
			return true
		}
	)
	.url('Invalid URL')

interface IFormikEmbedFieldProps {
	field: IField
	style?: CSSProperties
}

export const FormikEmbedField = ({ field, style }: IFormikEmbedFieldProps) => {
	return (
		<div style={style}>
			<div style={{ display: 'flex' }}>
				<FormikTextInput fieldProps={field} />
			</div>
			<Callout>
				Paste a video or podcast URL to embed it. YouTube, Vimeo, Soundcloud,
				Spotify, and Apple Podcast embeds are supported.
			</Callout>
		</div>
	)
}
