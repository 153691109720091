import { Button, Typography, useTheme } from '@material-ui/core'
import {
	FolderOutlined,
	LibraryBooksOutlined,
	LinkOutlined,
} from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { withFormikDevtools } from 'formik-devtools-extension'
import { useEffect, useMemo } from 'react'
import * as yup from 'yup'
import {
	FileFragment,
	ResourceFragmentFragment,
} from '../graphql/autogenerate/operations'
import {
	useCreateResourceMutation,
	useDeleteResourceMutation,
	useUpdateResourceMutation,
} from '../graphql/autogenerate/react-query'
import { ResourceType, UserType } from '../graphql/autogenerate/schemas'
import { useAppState, useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { Callout } from './callout'
import {
	AttachmentFieldValue,
	FileFieldValue,
	FormikAttachmentsField,
	FormikButtonsField,
	FormikEmbedField,
	FormikImageField,
	FormikPatchTouched,
	FormikSwitchField,
	FormikTextInput,
	FormikUserTypeSelector,
	attachmentsSchema,
	buttonsSchema,
	embedUrlValidation,
	useAttachmentFieldValueHandler,
	useImageFieldChangeHandler,
} from './forms'
import { FormikRichText } from './forms/formik-rich-text'
import { Modal, useModal } from './modal'
import { TooltipWrapper } from './tooltip-wrapper'

const getResourceTypeTitle = (type: ResourceType) => {
	switch (type) {
		case ResourceType.Folder:
			return 'Resource Folder'
		case ResourceType.Link:
			return 'Link'
		case ResourceType.Page:
			return 'Resource Page'
		case ResourceType.InternalLink:
			return 'Directory'
	}
}

interface IResourceFormProps {
	groupId: string
	parentResourceId?: string
	modalControls: ReturnType<typeof useModal>
	onSave?: () => void
	onDelete?: () => void
	resourceToEdit?: {
		resource?: ResourceFragmentFragment
		setResourceToEdit: (resource?: ResourceFragmentFragment) => void
	}

	/** 
        Allow parent component to pass a boolean or a function to conditionally enable the option to make a Resource the header.
    */
	enableHeaderResourceToggle?: boolean | ((type: ResourceType) => boolean)
	enableTileImageForResourceTypes?: ResourceType[]
}

export const ResourceForm = ({
	modalControls,
	groupId,
	parentResourceId,
	onSave,
	onDelete,
	resourceToEdit,
	enableHeaderResourceToggle,
	enableTileImageForResourceTypes = [],
}: IResourceFormProps) => {
	const theme = useTheme()
	const buttonStyles = useButtonStyles()

	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: { currentUserPermissions, enabledUserTypes },
	} = useSchoolContext()

	const initialValues = useMemo(() => {
		return {
			type: resourceToEdit?.resource?.type || ('' as ResourceType),
			published: resourceToEdit?.resource
				? resourceToEdit.resource.published
				: true,
			title: resourceToEdit?.resource?.title || '',
			body: resourceToEdit?.resource?.body || '',
			url: resourceToEdit?.resource?.url || '',
			header: resourceToEdit?.resource
				? Boolean(resourceToEdit.resource.header)
				: false,
			thumbnailImage: resourceToEdit?.resource
				?.thumbnailImage as FileFieldValue,
			coverImage: resourceToEdit?.resource?.coverImage as FileFieldValue,
			tileImage: resourceToEdit?.resource?.tileImage as FileFieldValue,
			buttons: resourceToEdit?.resource?.buttons?.buttons || [],
			userTypes:
				(resourceToEdit?.resource?.userTypes as UserType[]) ||
				([] as UserType[]),
			disableTileOverlay: resourceToEdit?.resource?.disableTileOverlay,
			attachments: (resourceToEdit?.resource?.resourceFiles.nodes.map(
				o => o.file
			) || []) as AttachmentFieldValue[],
			includeEmbed: Boolean(resourceToEdit?.resource?.embedUrl),
			embedUrl: resourceToEdit?.resource?.embedUrl,
		}
	}, [resourceToEdit?.resource])

	const { mutateAsync: createResource } = useHandleReactQueryMutation(
		useCreateResourceMutation({
			onSuccess: () => {
				if (onSave) onSave()
				modalControls.close()
			},
		})
	)
	const { mutateAsync: updateResource } = useHandleReactQueryMutation(
		useUpdateResourceMutation({
			onSuccess: () => {
				if (onSave) onSave()
				modalControls.close()
			},
		})
	)
	const { mutate: deleteResource } = useHandleReactQueryMutation(
		useDeleteResourceMutation({
			onSuccess: () => {
				if (onDelete) onDelete()
				modalControls.close()
			},
		})
	)

	const imageFieldChangeHandler = useImageFieldChangeHandler()
	const attachmentFieldValueHandler = useAttachmentFieldValueHandler()

	const modal = useModal()

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={yup.object({
				userTypes: yup.array().nullable().required('Required'),
				type: yup.string().required('Required'),
				title: yup.string().test('title', 'Required', function (value) {
					if (this.parent.tileImage) return true
					return Boolean(value)
				}),
				url: yup
					.string()
					.test('url', 'Required', function (value) {
						if (
							this.parent.type === ResourceType.Link ||
							this.parent.type === ResourceType.InternalLink
						)
							return Boolean(value?.length)
						return true
					})
					.test('validUrl', 'Must be a valid URL.', async function (value) {
						if (this.parent.type === ResourceType.Link) {
							try {
								await yup.string().url().validate(value)
								return true
							} catch (e) {
								return false
							}
						}
						return true
					}),
				buttons: buttonsSchema,
				attachments: attachmentsSchema,
				embedUrl: embedUrlValidation,
			})}
			onSubmit={async ({
				thumbnailImage,
				coverImage,
				tileImage,
				attachments,
				...values
			}) => {
				const { addedAttachments } = await attachmentFieldValueHandler({
					submittedAttachments: attachments,
					initialAttachmentFiles:
						resourceToEdit?.resource?.resourceFiles.nodes.reduce<
							FileFragment[]
						>((fileFragments, o) => {
							if (o.file) fileFragments.push(o.file)
							return fileFragments
						}, []),
				})

				if (resourceToEdit?.resource) {
					await updateResource({
						...values,
						id: resourceToEdit.resource.id,
						...(await imageFieldChangeHandler({
							oldValue: resourceToEdit.resource.thumbnailImage,
							newValue: thumbnailImage,
							filePropertyName: 'thumbnailImage',
						})),
						...(await imageFieldChangeHandler({
							oldValue: resourceToEdit.resource.coverImage,
							newValue: coverImage,
							filePropertyName: 'coverImage',
						})),
						...(await imageFieldChangeHandler({
							oldValue: resourceToEdit.resource.tileImage,
							newValue: tileImage,
							filePropertyName: 'tileImage',
						})),
						body: values.body.length ? values.body : null,
						url: values.url.length ? values.url : null,
						userTypes: values.userTypes,
						disableTileOverlay: values.disableTileOverlay,
						// Only create new attachment join records if there are any.
						...(addedAttachments.length
							? {
									attachments: {
										create: addedAttachments.map((o, index) => ({
											sortOrder: o.sortOrder || index + 1,
											file: { create: o },
										})),
									},
							  }
							: {}),
						embedUrl: values.includeEmbed ? values.embedUrl : null,
					})
				} else {
					await createResource({
						...values,
						groupId,
						parentResource: parentResourceId,
						// If we have a /temp uploaded cover image we use the GraphQL inline create to create a corresponding File record.
						thumbnailImage: thumbnailImage && {
							create: {
								filename: thumbnailImage.filename,
								type: thumbnailImage.type,
							},
						},
						coverImage: coverImage && {
							create: { filename: coverImage.filename, type: coverImage.type },
						},
						tileImage: tileImage && {
							create: { filename: tileImage.filename, type: tileImage.type },
						},
						body: values.body.length ? values.body : null,
						url: values.url.length ? values.url : null,
						userTypes: values.userTypes,
						disableTileOverlay: values.disableTileOverlay,
						// Only create new attachment join records if there are any.
						...(addedAttachments.length
							? {
									attachments: {
										create: addedAttachments.map((o, index) => ({
											sortOrder: o.sortOrder || index + 1,
											file: { create: o },
										})),
									},
							  }
							: {}),
					})
				}
			}}
		>
			{formikProps => {
				withFormikDevtools(formikProps)
				return (
					<Form>
						<FormikPatchTouched />
						<Modal
							{...modalControls.props}
							title={`${resourceToEdit?.resource ? 'Edit' : 'Add'} ${
								formikProps.values.type
									? getResourceTypeTitle(formikProps.values.type)
									: ''
							}`}
							dividers
							closeButton
							size={formikProps.values.type === ResourceType.Page ? 'md' : 'sm'}
							afterClose={() => {
								formikProps.resetForm()
								resourceToEdit && resourceToEdit.setResourceToEdit()
							}}
							actions={
								<div
									style={{
										display: 'flex',
										flex: 1,
										justifyContent: 'space-between',
										margin: `0px ${theme.spacing(2)}px`,
									}}
								>
									<div style={{ flex: 1, display: 'flex' }}>
										{resourceToEdit?.resource && (
											<>
												<Button
													onClick={modal.open}
													className={buttonStyles.warningOutlined}
													style={{
														minWidth: 100,
														marginRight: theme.spacing(3),
													}}
													variant='outlined'
												>
													Delete
												</Button>
												<FormikSwitchField
													style={{ marginRight: theme.spacing(1) }}
													field={{ name: 'published', label: 'Published' }}
												/>
											</>
										)}
									</div>
									<div>
										<Button
											style={{ minWidth: 100, marginRight: theme.spacing(1) }}
											variant='text'
											color='primary'
											type='button'
											onClick={modalControls.close}
										>
											Cancel
										</Button>
										{/* 
                                            IMPORTANT - for some reason, having an explicitly set "Uploading..." error on a file field doesn't prevent the Formik submitForm from being called like an error on another field does.
                                            So, any time there is a File field on a form, we need to explicitly disable submitting if the form isn't valid, since the File field adds an "Uploading..." error on itself when 
                                            an upload is in progress in order to prevent a form from being submited in the middle of an upload (thereby losing the upload).
                                        */}
										{formikProps.values.type && (
											<Button
												style={{ minWidth: 100 }}
												disabled={Boolean(
													formikProps.errors.coverImage ||
														formikProps.errors.tileImage ||
														formikProps.isSubmitting
												)}
												variant='contained'
												color='primary'
												type='submit'
												onClick={formikProps.submitForm}
											>
												Save
											</Button>
										)}
									</div>
								</div>
							}
							style={
								formikProps.values.type === ResourceType.Page
									? { minHeight: '80%' }
									: {}
							}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									flex: 1,
									minHeight: 0,
								}}
							>
								{!formikProps.values.type && (
									<div
										style={{
											flex: 1,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Typography
											variant='h6'
											style={{ marginBottom: theme.spacing(2) }}
										>
											What type of resource would you like to add?
										</Typography>

										<div
											style={{
												display: 'flex',
												justifyContent: 'space-around',
											}}
										>
											<div style={{ margin: theme.spacing(2) }}>
												<div
													className={buttonStyles.iconButton}
													onClick={() =>
														formikProps.setFieldValue(
															'type',
															ResourceType.Folder
														)
													}
												>
													<FolderOutlined style={{ fontSize: '3rem' }} />
													<Typography>Folder</Typography>
												</div>
											</div>
											<div style={{ margin: theme.spacing(2) }}>
												<div
													className={buttonStyles.iconButton}
													onClick={() =>
														formikProps.setFieldValue('type', ResourceType.Link)
													}
												>
													<LinkOutlined style={{ fontSize: '3rem' }} />
													<Typography>Link</Typography>
												</div>
											</div>
											<div
												style={{ margin: theme.spacing(2) }}
												onClick={() =>
													formikProps.setFieldValue('type', ResourceType.Page)
												}
											>
												<div className={buttonStyles.iconButton}>
													<LibraryBooksOutlined style={{ fontSize: '3rem' }} />
													<Typography>Page</Typography>
												</div>
											</div>
										</div>
									</div>
								)}

								{formikProps.values.type && (
									<>
										{(!parentResourceId ||
											parentResourceId === resourceToEdit?.resource?.id) && (
											<TooltipWrapper tooltip='Specify whether this resource should be visible to all users or only specific types.'>
												<FormikUserTypeSelector
													field={{ name: 'userTypes', label: 'User Type(s)' }}
													userTypes={enabledUserTypes.filter(
														o =>
															o !== UserType.FacultyStaff ||
															Boolean(
																currentUserPermissions.schoolwideAdmin ||
																	currentUser?.appAdministrator
															)
													)}
												/>
											</TooltipWrapper>
										)}

										{enableTileImageForResourceTypes.includes(
											formikProps.values.type
										) && (
											<>
												<FormikImageField
													field={{ name: 'tileImage', label: 'Tile Image' }}
													aspectRatio={(formikProps.values.header ? 6 : 3) / 2}
													aspectRatioHelper={
														formikProps.values.header
															? '3:1 aspect ratio (e.g. 1500px by 500px)'
															: '3:2 aspect ratio (e.g. 900px by 600px)'
													}
												/>
												{formikProps.values.tileImage && (
													<FormikSwitchField
														style={{ marginBottom: theme.spacing(3) }}
														field={{
															name: 'disableTileOverlay',
															label: 'Disable tile image overlay color',
														}}
														tooltip='This will disable the fading color overlay. Useful when the tile image already has a title.'
													/>
												)}
											</>
										)}
										{(formikProps.values.type === ResourceType.Page ||
											formikProps.values.type === ResourceType.Folder) && (
											<FormikImageField
												field={{ name: 'coverImage', label: 'Cover Image' }}
												aspectRatio={16 / 9}
												aspectRatioHelper='16:9 aspect ratio (e.g. 1600px by 900px)'
												style={{ marginBottom: theme.spacing(2) }}
											/>
										)}
										{formikProps.values.type !== ResourceType.Folder &&
											parentResourceId && (
												<FormikImageField
													field={{
														name: 'thumbnailImage',
														label: 'Thumbnail Image',
													}}
													aspectRatio={1}
													aspectRatioHelper='1:1 aspect ratio (e.g. 500px by 500px)'
													style={{ marginBottom: theme.spacing(2) }}
												/>
											)}

										<FormikTextInput
											autoFocus
											style={{ flex: 0 }}
											fieldProps={{
												name: 'title',
												label: `${getResourceTypeTitle(
													formikProps.values.type
												)} Title`,
											}}
										/>

										{formikProps.values.type !== ResourceType.InternalLink && (
											<FormikRichText
												style={
													formikProps.values.type === ResourceType.Page
														? {
																flex: 1,
																display: 'flex',
																flexDirection: 'column',
																minHeight: 300,
														  }
														: {}
												}
												fieldProps={{
													name: 'body',
													label:
														formikProps.values.type === ResourceType.Page
															? 'Resource Page Content'
															: `${getResourceTypeTitle(
																	formikProps.values.type
															  )} Description`,
												}}
											/>
										)}

										{formikProps.values.type === ResourceType.Link && (
											<FormikTextInput
												fieldProps={{ name: 'url', label: `Link URL` }}
											/>
										)}

										{formikProps.values.type === ResourceType.Page && (
											<FormikButtonsField
												field={{ name: 'buttons', label: 'Buttons' }}
												style={{ marginBottom: theme.spacing(2) }}
											/>
										)}

										{formikProps.values.type === ResourceType.Page && (
											<FormikAttachmentsField
												field={{ name: 'attachments', label: 'Attachments' }}
											/>
										)}

										{formikProps.values.type === ResourceType.Page && (
											<>
												<FormikSwitchField
													field={{
														name: 'includeEmbed',
														label: 'Embed a video/podcast',
													}}
												/>
												{formikProps.values.includeEmbed && (
													<FormikEmbedField
														field={{ label: 'Embed URL', name: 'embedUrl' }}
													/>
												)}
												<div style={{ paddingBottom: theme.spacing(2) }} />
											</>
										)}

										{(enableHeaderResourceToggle === true ||
											(typeof enableHeaderResourceToggle === 'function' &&
												enableHeaderResourceToggle(
													formikProps.values.type
												))) && (
											<>
												<FormikSwitchField
													field={{
														name: 'header',
														label: 'Make header resource',
													}}
												/>
												{formikProps.values.header && (
													<>
														<Callout type='info'>
															<div>
																Making this resource the header will:
																<ul>
																	<li>
																		Replace the current header resource (if
																		there is one)
																	</li>
																	<li>
																		Move this resource to the beginning of the
																		list
																	</li>
																	<li>
																		Make this resource twice the usual width (it
																		will display across two tiles)
																	</li>
																</ul>
															</div>
														</Callout>
													</>
												)}
												{/* This guy is here to serve as a spacer since adding margin to the callout won't create actual spacing. */}
												<div style={{ paddingBottom: theme.spacing(2) }} />
											</>
										)}
									</>
								)}
							</div>
						</Modal>

						<Modal
							{...modal.props}
							title={
								formikProps.values.type === ResourceType.InternalLink
									? ''
									: 'Confirm Delete'
							}
							actions={
								formikProps.values.type === ResourceType.InternalLink ? (
									<Button variant='text' onClick={modal.close}>
										OK
									</Button>
								) : (
									<>
										<Button variant='text' onClick={modal.close}>
											Cancel
										</Button>
										<Button
											variant='contained'
											className={buttonStyles.error}
											onClick={() => {
												if (resourceToEdit?.resource)
													deleteResource({ id: resourceToEdit.resource.id })
												modal.close()
											}}
										>
											Delete
										</Button>
									</>
								)
							}
							size='xs'
						>
							{formikProps.values.type === ResourceType.InternalLink ? (
								<div>
									<h3>The Faculty/Staff Directory cannot be deleted.</h3>
									Unpublish the Faculty/Staff Directory to hide it instead.
								</div>
							) : (
								<>
									<div>
										Are you sure you want to delete{' '}
										<b>{resourceToEdit?.resource?.title}</b>?
									</div>

									{resourceToEdit?.resource?.type === ResourceType.Folder &&
										resourceToEdit.resource.resourcesByParentResource
											.totalCount > 0 && (
											<>
												<p>
													Deleting this folder will also delete the{' '}
													<b>
														{
															resourceToEdit.resource.resourcesByParentResource
																.totalCount
														}{' '}
														resource
														{resourceToEdit.resource.resourcesByParentResource
															.totalCount !== 1 && 's'}
													</b>{' '}
													inside.{' '}
													{resourceToEdit.resource.resourcesByParentResource
														.totalCount === 1
														? 'If that resource is a folder, its'
														: 'If any of those resources are folders, their'}{' '}
													contents will be deleted as well.
												</p>
												<b>This action cannot be undone.</b>
											</>
										)}
								</>
							)}
						</Modal>
					</Form>
				)
			}}
		</Formik>
	)
}
