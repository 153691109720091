import { useTheme } from '@material-ui/core'
import { AttachmentOutlined } from '@material-ui/icons'
import { FileType } from '../graphql/autogenerate/schemas'

interface IAttachmentCellProps {
	temporaryDownloadUrl?: string
	label: string
	type?: FileType
}

export const AttachmentCell = ({
	temporaryDownloadUrl,
	label,
	type,
}: IAttachmentCellProps) => {
	const theme = useTheme()

	const cell = (
		<div
			style={{
				flex: 1,
				display: 'flex',
				alignItems: 'center',
				marginBottom: theme.spacing(1),
				padding: theme.spacing(1),
				border: `1px solid ${theme.palette.grey[300]}`,
				borderRadius: theme.shape.borderRadius,
				color: theme.palette.grey[600],
			}}
		>
			<AttachmentOutlined
				style={{
					marginRight: theme.spacing(1),
				}}
			/>
			{label} {type && `(${type})`}
		</div>
	)

	if (temporaryDownloadUrl) {
		return (
			<a href={temporaryDownloadUrl} target='_blank' rel='noreferrer'>
				{cell}
			</a>
		)
	}

	return cell
}
