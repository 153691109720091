import { IconButton, Typography, useTheme } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useCalendarEventsForWeekQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useDrag } from '@use-gesture/react'
import { REFETCH_INTERVAL } from '../constants'
import { EventsList } from './events-list'
import { useSchoolGroupContext } from './school-group-home'
import { useSchoolContext } from '../stores/school'

export const SchoolGroupCalendar = () => {
    const theme = useTheme()
    const { state: { school: { slug } } } = useSchoolContext()
    const { state: { group } } = useSchoolGroupContext()
    const [ day, setDay ] = useState(dayjs().startOf('isoWeek'))
    const { data } = useHandleReactQuery(useCalendarEventsForWeekQuery({ calendarIds: group.calendars.nodes.map(o => o.id), date: day.toISOString() }, { refetchInterval: REFETCH_INTERVAL }))
    const bind = useDrag(({ active, movement: [ mx ], direction: [ xDir ] }) => {
        const trigger = Math.abs(mx) > 60 // Make sure the swipe was a full swipe, not some half hearted flick or brush of the screen
        if (!active && trigger) {
            if (xDir === -1) {
                setDay(day.add(1, 'week'))
            } else {
                setDay(day.subtract(1, 'week'))
            }
        }
    })

    return (
        <>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden', }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <IconButton onClick={() => setDay(day.subtract(1, 'week'))} children={<NavigateBefore />} />
                    <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ flex: 1 }}
                        align='center'
                    >
                        Week {day.subtract(2, 'day').week()}, {day.format('MMMM D')} &ndash; {day.endOf('isoWeek').format(`${day.startOf('isoWeek').month() === day.endOf('isoWeek').month() ? '' : 'MMMM'} D`)}
                    </Typography>
                    <IconButton onClick={() => setDay(day.add(1, 'week'))} children={<NavigateNext />} />
                </div>

                <div {...bind()} style={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: 0, overflowY: 'auto', padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1) + 55}px ${theme.spacing(1)}px` }}>
                    <EventsList
                        events={data?.getCalendarEventsForWeek?.nodes || []}
                        day={day}
                        onEventPressed={({ title }) => {
                            gtag('event', 'event_viewed', {
                                event_category: slug,
                                event_label: title,
                            })
                        }}
                    />
                </div>
            </div>
        </>
    )
}