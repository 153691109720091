import { useField } from 'formik'
import { CommonProps } from '../interfaces'
import { ColorPicker, IField } from './'

interface IFormikColorPickerProps extends CommonProps {
    fieldProps: IField
    // Optionally provide the controls for the manual color selection. This allows for preserving a consistent set of palette options across multipe color pickers.
    manualColorInputControls?: {
        manualColorInputValue: string
        setManualColorInputValue: (color: string) => void
    }
}
export const FormikColorPicker = ({ style, className, fieldProps, manualColorInputControls }: IFormikColorPickerProps) => {
    const [ field, meta, helpers ] = useField<string>(fieldProps)

    return (
        <ColorPicker
            style={style}
            className={className}
            color={field.value}
            label={fieldProps.label}
            name={fieldProps.name}
            colorSelected={(color) => helpers.setValue(color)}
            touched={meta.touched}
            error={meta.error}
            initialError={meta.initialError}
            helperText={fieldProps.helperText}
            setTouched={helpers.setTouched}
            manualColorInputControls={manualColorInputControls}
        />
    )
}