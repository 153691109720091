
import jwtDecode from 'jwt-decode'
import { useLocalStorage } from '.'
import { LOCAL_STORAGE_JWT_KEY } from "../constants"


export interface IJwt {
    role: string
    user_id: string
    exp: number
}

export const useAccessToken = () => {
    const { setItem, getItem, removeItem } = useLocalStorage()

    const setAccessToken = async (token?: string | null) => {
        if (token) {
            await setItem({ key: LOCAL_STORAGE_JWT_KEY, value: token })
        } else {
            removeItem({ key: LOCAL_STORAGE_JWT_KEY })
            gtag('set', { 'user_id': null })
        }
    }
    const getAccessToken = () => getItem({ key: LOCAL_STORAGE_JWT_KEY })
    const tokenIsExpired = async () => {
        const token = await getAccessToken()
        if (!token) return null
        return Boolean(Date.now() >= jwtDecode<IJwt>(token).exp * 1000)
    }

    return {
        setAccessToken,
        getAccessToken,
        tokenIsExpired,
    }
}