import { Link as MaterialUiLink } from '@material-ui/core'
import { ReactNode } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { CommonProps } from './interfaces'

interface ITextLinkProps extends CommonProps {
    to: string
    children: ReactNode
}

export const TextLink = ({ children, to, style }: ITextLinkProps) => {
    return (
        <MaterialUiLink to={to} component={ReactRouterLink} color='inherit' style={style}>
            {children}
        </MaterialUiLink>
    )
}