import { createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { IconType } from '../graphql/autogenerate/schemas'
import tinycolor from 'tinycolor2'
import { IconTypeDisplay } from './icon-type-display'
import dayjs, { Dayjs } from 'dayjs'
import { CommonProps } from './interfaces'
import clsx from 'clsx'
import { Tooltip } from './tooltip'
import { ICalendarEventCellAllDay } from './calendar-week-all-day-events'
import { CalendarActionType, ICalendarEventCell } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { joinStrings } from '../helpers'
import { Google } from '../images'

const useStyles = makeStyles(theme =>
    createStyles({
        cell: {
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            border: '1px solid white',
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(.5),
            fontSize: theme.typography.caption.fontSize,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column'
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        tiny: {
            padding: 0,
            fontSize: 10,
        },
        noWrap: {
            whiteSpace: 'nowrap'
        },
        startsBefore: {
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
        },
        endsAfter: {
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
        },
    })
)

interface ICalendarEventCellProps extends CommonProps, ICalendarDispatchProp {
    event: ICalendarEventCell
    allDay?: ICalendarEventCellAllDay
    thin?: boolean
}
/* 
    TODO: this thing is a mess...really should refactor to use "states" (e.g. sm, md, lg) and grid to move things around. Then, every item (title, location, time, etc.) is rendered separately and placed separately according to the state.
*/
export const CalendarEventCell = ({ event, className, style, thin, allDay, dispatch }: ICalendarEventCellProps) => {
    const { title, startDate: startTime, endDate: endTime, locationString: location, spansDays, groups } = event
    const styles = useStyles()

    const start = spansDays?.labelStartDate || startTime
    const end = spansDays?.labelEndDate || endTime

    const textColor = groups.length > 1 ? '#212121' : tinycolor.mostReadable(groups[ 0 ].color, [ '#fff' ], { includeFallbackColors: true }).toHexString()
    const past = end.isBefore(dayjs())
    const slim = end.diff(start, 'minutes') < 45
    const tiny = end.diff(start, 'minutes') < 30
    const sameAmPm = start.format('a') !== end.format('a')
    const sameMonth = start.month() === end.month()
    const sameDay = start.date() === end.date()

    const startTimeLabel = `${start.minute() === 0 ? start.format('h') : start.format('h:mm')}${sameAmPm || slim || thin ? start.format('a') : ''}`
    const endTimeLabel = end.minute() === 0 ? end.format(spansDays ? 'M/D ha' : 'ha') : end.format(spansDays ? 'M/D h:mma' : 'h:mma')

    const startEndLabel = allDay ?
        <>{start.format('MMM D')} {!sameDay && <>&ndash; {sameMonth ? end.format('D') : end.format('MMM D')}</>}</>
        :
        <>{`${start.minute() === 0 ? start.format(spansDays ? 'M/D h' : 'h') : start.format(spansDays ? 'M/D h:mma' : 'h:mm')}${sameAmPm ? start.format('a') : ''}`} &ndash; {endTimeLabel}</>

    return (
        <Tooltip interactive title={
            <>
                <div>
                    <b>{title}</b>
                </div>
                <div style={{ marginBottom: 4 }}>
                    {startEndLabel}
                    {location &&
                        <>
                            <br />
                            <em>{location}</em>
                        </>
                    }
                </div>
                {groups.map(o =>
                    <div key={o.id} style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                        <IconTypeDisplay type={o.iconType} size='15px' style={{ marginRight: 4 }} />
                        {o.groupName}
                    </div>
                )}

                {event.googleId &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 2 }}>
                        <Google style={{ height: 10, width: 10, marginRight: -5 }} />
                    </div>
                }
            </>
        }>
            <div
                className={clsx(
                    styles.cell,
                    className,
                    tiny && styles.tiny,
                    allDay?.startsBeforeWeek && styles.startsBefore,
                    allDay?.endsAfterWeek && styles.endsAfter,
                )}
                style={{
                    backgroundColor: groups.length > 1 ? '#e0e0e0' : groups[ 0 ].color,
                    color: textColor,
                    ...style,
                }}
                onClick={() => dispatch({ type: CalendarActionType.editEvent, payload: event })}
            >
                <div className={clsx((slim || tiny) && styles.noWrap, styles.title)}>
                    {groups.length === 1 && <IconTypeDisplay type={groups[ 0 ].iconType} size={tiny ? '10px' : '15px'} style={{ marginRight: 4, ...tiny ? { marginTop: 2, width: 10, height: 10 } : { width: 15, height: 15 }, }} />}
                    <b>{title}</b>{slim && !allDay && `, ${startTimeLabel}`}
                </div>
                {!slim && !allDay &&
                    <div>
                        {thin ?
                            startTimeLabel
                            :
                            startEndLabel
                        }
                        {!slim && !thin && !allDay && location &&
                            <span>
                                , <em>{location}</em>
                            </span>
                        }
                    </div>
                }

                {!slim && groups.length > 1 &&
                    groups.map(o => <IconTypeDisplay key={o.id} type={o.iconType} size={tiny ? '10px' : '15px'} style={{ marginRight: 4, ...tiny ? { marginTop: 2 } : {}, ...groups.length > 1 ? { color: o.color } : {} }} />)
                }

                {!slim && !tiny && event.googleId &&
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Google style={{ height: 10, width: 10, marginRight: 5, marginBottom: 5 }} />
                    </div>
                }
            </div>
        </Tooltip>
    )
}

