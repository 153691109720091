import {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { useHandleReactQueryMutation, useMobileDetect } from '.'
import { useUpdateSchoolPersonInstallationMutation } from '../graphql/autogenerate/react-query'
import { SchoolPersonInstallationPatch } from '../graphql/autogenerate/schemas'
import {
	INativeMessage,
	initialize,
	off,
	on,
	send,
} from '../helpers/native-bridge'
import { SchoolContext } from '../stores/school'

enum MessageName {
	'app.notificationsPrompt' = 'app.notificationsPrompt',
	'app.setOneSignalUserId' = 'app.setOneSignalUserId',
	'app.goToAppSettings' = 'app.goToAppSettings',
	'app.bootstrapped' = 'app.bootstrapped',
	'app.pollForPushNotificationSubscriptionStatus' = 'app.pollForPushNotificationSubscriptionStatus',
	'app.pollIOSForNotDeterminedNotificationPermissionStatus' = 'app.pollIOSForNotDeterminedNotificationPermissionStatus',
}

enum NativeMessagName {
	'native.notificationSubscriptionStatusChange' = 'native.notificationSubscriptionStatusChange',
	'native.navigate' = 'native.navigate',
}

interface INotificationStatusPayload {
	pushToken?: string | null
	playerId?: string | null
	pushEnabled: boolean
}

// Please use nativelyChangePushNotificationSubscriptionStatus instead
export const registerForPushNotifications = async () => {
	console.log('registerForPushNotifications START')
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.notificationsPrompt'],
		payload: {},
		rsvp: true,
		noTimeout: true,
	}
	const results = await send(message)
	console.log('registerForPushNotifications RESULTS ' + results)
	const payload = results?.payload as INotificationStatusPayload | undefined
	return payload
}

// returns an INotificationStatusPayload with
// pushEnabled set to true if device is subscribed
// and false if not subscribed
export const pollForNotificationIsSubscribed = async () => {
	console.log('pollForPushNotificationSubscriptionStatus START')
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.pollForPushNotificationSubscriptionStatus'],
		payload: {},
		rsvp: true,
		noTimeout: true,
	}
	const results = await send(message)
	const payload = results?.payload as INotificationStatusPayload | undefined
	return payload
}

// returns object {ispushStatusIsUndefined: true} if status is not determined notificationPermissionStatus [0]
// returns object {ispushStatusIsUndefined: false} if determined notificationPermissionStatus [1-4]
// https://documentation.onesignal.com/docs/sdk-reference#osdevicestate
export const pollIOSForNotDeterminedNotificationPermissionStatus = async () => {
	console.log('pollIOSForNotDeterminedNotificationPermissionStatus START')
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.pollIOSForNotDeterminedNotificationPermissionStatus'],
		payload: {},
		rsvp: true,
		noTimeout: true,
	}
	const results = await send(message)
	const payload = results?.payload as
		| { ispushStatusIsUndefined: boolean }
		| undefined
	return payload
}

export const setOneSignalUserId = ({
	schoolPersonId,
}: {
	schoolPersonId: string
}) => {
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.setOneSignalUserId'],
		payload: {
			userId: schoolPersonId,
		},
	}
	send(message)
}

// Please use nativelyChangePushNotificationSubscriptionStatus instead if you are dealing with push notification subscription stuff.
export const openAppSettings = () => {
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.goToAppSettings'],
		payload: {},
	}
	send(message)
}

export const bootstrapped = () => {
	const message: INativeMessage = {
		id: v4(),
		name: MessageName['app.bootstrapped'],
		payload: {},
	}
	send(message)
}

interface INativeBridgeState {
	device: IInitializePayload
}

export interface INativeBridgeContext extends INativeBridgeState {}

const NativeBridgeContext = createContext<INativeBridgeContext | undefined>(
	undefined
)

export const useNativeBridge = () => {
	const context = useContext(NativeBridgeContext)

	return context
}

interface IInitializePayload {
	platform: string
	appVersion: string
	sourceVersion?: string
	osVersion: string
	deviceName: string
	bundleId: string
}

export enum SourceVersionTest {
	'GreaterThan' = 2,
	'GreaterThanOrEqualTo' = 1,
	'EqualTo' = 0,
	'LessThanOrEqualTo' = -1,
	'LessThan' = -2,
}

/*
 * iOS 10.0.2
 * Sends up sourceVersion on init
 * Can use pollForNotificationIsSubscribed()
 * Can use pollIOSForNotDeterminedNotificationPermissionStatus()
 * Android 10.0.2
 * Sends up sourceVersion on init
 * Can use pollForNotificationIsSubscribed()
 */
export enum SourceVersionNumber {
	'10.0.2' = '10.0.2',
}

// returns false if deviceSourceVersion is undefined
// OR if version is not in x.y.z format or returns the result of the test.
// CURRENTLY DOES NOT TAKE PLATFORM INTO ACCOUNT
export const TestDeviceSourceVersion = (
	sourceVersionToCheck: SourceVersionNumber,
	deviceSourceVersion: string,
	test: SourceVersionTest
) => {
	if (deviceSourceVersion === undefined) {
		return false
	}

	const splitCheckVersion: string[] = sourceVersionToCheck.split('.')
	const splitDeviceVersion: string[] = deviceSourceVersion.split('.')
	let equalTo,
		greaterThan,
		lessThan = false
	if (splitCheckVersion.length !== 3 && splitDeviceVersion.length !== 3) {
		return false
	} else {
		equalTo =
			splitCheckVersion[0] === splitDeviceVersion[0] &&
			splitCheckVersion[1] === splitDeviceVersion[1] &&
			splitCheckVersion[2] === splitDeviceVersion[2]

		greaterThan =
			splitCheckVersion[0] < splitDeviceVersion[0] ||
			(splitCheckVersion[0] <= splitDeviceVersion[0] &&
				splitCheckVersion[1] < splitDeviceVersion[1]) ||
			(splitCheckVersion[0] <= splitDeviceVersion[0] &&
				splitCheckVersion[1] <= splitDeviceVersion[1] &&
				splitCheckVersion[2] < splitDeviceVersion[2])

		lessThan =
			splitCheckVersion[0] > splitDeviceVersion[0] ||
			(splitCheckVersion[0] >= splitDeviceVersion[0] &&
				splitCheckVersion[1] > splitDeviceVersion[1]) ||
			(splitCheckVersion[0] >= splitDeviceVersion[0] &&
				splitCheckVersion[1] >= splitDeviceVersion[1] &&
				splitCheckVersion[2] > splitDeviceVersion[2])
	}

	switch (test) {
		case SourceVersionTest.GreaterThan:
			return greaterThan

		case SourceVersionTest.GreaterThanOrEqualTo:
			return greaterThan || equalTo

		case SourceVersionTest.EqualTo:
			return equalTo

		case SourceVersionTest.LessThanOrEqualTo:
			return lessThan || equalTo

		case SourceVersionTest.LessThan:
			return lessThan

		default:
			return false
	}
}

export const NativeBridgeProvider: FC = ({ children }) => {
	const { isMobile } = useMobileDetect()
	const schoolContext = useContext(SchoolContext)
	const existingSchoolPersonId =
		schoolContext?.state?.currentUserPermissions?.school?.id
	const existingSchoolPersonInstallationId =
		schoolContext?.state.currentUserPermissions.school?.schoolPersonInstallations
			.nodes[0]?.id
	const { mutateAsync: updateInstallation } = useHandleReactQueryMutation(
		useUpdateSchoolPersonInstallationMutation()
	)

	const [loaded, setLoaded] = useState(false)
	const [state, setState] = useState<INativeBridgeState>()

	const navigate = useNavigate()

	const handleNotificationSubscriptionStatusChange = useCallback(
		(message: INativeMessage) => {
			// If we know who this user is, listen for updates to their OneSignal registration
			if (existingSchoolPersonInstallationId) {
				const payload = message.payload as INotificationStatusPayload
				updateInstallation({
					id: existingSchoolPersonInstallationId,
					patch: {
						pushEnabled: payload.pushEnabled,
						oneSignalPlayerId: payload.playerId,
						oneSignalToken: payload.pushToken,
					},
				})
			}
		},
		[existingSchoolPersonInstallationId]
	)

	const handleNavigate = useCallback((message: INativeMessage) => {
		console.log('received navigate message', message.payload)
		const path = message.payload.path
		if (typeof path === 'string') {
			navigate(path)
		}
	}, [])

	const bridgeSetup = useCallback(async () => {
		if (isMobile()) {
			try {
				// Set up listeners before initiating bridge
				off(NativeMessagName['native.notificationSubscriptionStatusChange'])
				on(
					NativeMessagName['native.notificationSubscriptionStatusChange'],
					handleNotificationSubscriptionStatusChange
				)

				off(NativeMessagName['native.navigate'])
				on(NativeMessagName['native.navigate'], handleNavigate)

				const initializeResults = await initialize({})

				// Something very strange happened if these results are null....
				if (initializeResults) {
					const device = initializeResults.payload as IInitializePayload
					console.log('bridge started!', device)

					bootstrapped()

					setState({ device })

					console.log('updating the person', existingSchoolPersonId)
					if (existingSchoolPersonId) {
						let updateSchoolPersonInstallationPayload: SchoolPersonInstallationPatch =
							{
								platform: device.platform,
								appVersion: device.appVersion,
								osVersion: device.osVersion,
								deviceName: device.deviceName,
								bundleId: device.bundleId,
							}

						if (
							device.sourceVersion !== undefined &&
							TestDeviceSourceVersion(
								SourceVersionNumber['10.0.2'],
								device.sourceVersion,
								SourceVersionTest.GreaterThanOrEqualTo
							)
						) {
							let pushStatus = await pollForNotificationIsSubscribed().catch(e => {
								console.log('Error in polling Installation for push status.', e)
							})
							if (pushStatus) {
								updateSchoolPersonInstallationPayload = {
									...updateSchoolPersonInstallationPayload,
									pushEnabled: pushStatus.pushEnabled,
									oneSignalPlayerId: pushStatus.playerId,
									oneSignalToken: pushStatus.pushToken,
								}
							}
						}

						if (existingSchoolPersonInstallationId) {
							await updateInstallation({
								id: existingSchoolPersonInstallationId,
								patch: updateSchoolPersonInstallationPayload,
							})
						}

						setOneSignalUserId({ schoolPersonId: existingSchoolPersonId })
					}
				}
			} catch (e) {
				console.log('error starting bridge...', e)
			}
		}

		setLoaded(true)
	}, [existingSchoolPersonId])

	useEffect(() => {
		console.log('calling the bridge startup')
		bridgeSetup()

		return () => {
			console.log('unmounting')
		}
	}, [])

	// Make sure the bridge has finished if we're on native
	if (!loaded) return null

	return (
		<NativeBridgeContext.Provider value={state}>
			{children}
		</NativeBridgeContext.Provider>
	)
}
