import axios from 'axios'
import { mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'
import { ErrorCode } from '../errors'
import { useLocalStorage, useAccessToken } from '../hooks'

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_GRAPH_URL || 'http://localhost:5000/graphql',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    validateStatus: () => true // We want all status codes to flow through to our handler
})

const useGetBearerToken = () => {
    const location = useLocation()
    const { getItem } = useLocalStorage()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { getAccessToken, setAccessToken, tokenIsExpired } = useAccessToken()

    // Not all requests will need a bearer token (e.g. anonymous requests for public resources)
    const getBearerToken = useCallback(async (): Promise<string | null | undefined> => {
        let token = await getAccessToken()

        if (!token) return null

        // Token expired.
        if (await tokenIsExpired()) {
            // If an anonymous account, log them in and return that new token
            // We can't use a React Query mutation here since this function is the fetch function for all React Queries
            const id = await getItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })
            if (id) {
                const anonymousAuthQuery = await fetch(process.env.REACT_APP_GRAPH_URL || 'http://localhost:5000/graphql', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        query: `
                            mutation Authenticate($email: Email!, $password: String!) {
                                authenticate(input: {email: $email, password: $password}) {
                                    clientMutationId
                                    jwtToken
                                }
                            }
                        `,
                        variables: {
                            email: `${id}@legitapps.com`,
                            password: id
                        }
                    }),
                })
                const results = await anonymousAuthQuery.json()

                if (results?.data?.authenticate?.jwtToken) {
                    token = results.data.authenticate.jwtToken
                    await setAccessToken(token)
                }
                // If we didn't get a token for this anonymous auth something went very wrong...we should only ever have had an ID in local storage if this device had previously signed up as an anonymous user.
                else {
                    enqueueSnackbar('Encountered a problem authenticating your device. Please completely close then reopen the app. If this problem persists, please delete and reinstall the app.', { variant: 'info' })
                }
            }
            // Else, redirect to login. Since there was a token, this user has logged in at some point, we'll go ahead and assume they can log in again.
            // Preserve the current URL that the user is trying to access
            else {
                await setAccessToken(null)
                enqueueSnackbar('Your session has expired. Please login to continue.', { variant: 'warning' })
                navigate(`/login?redirectUrl=${location.pathname}`)
                throw new Error(ErrorCode.auth_token_expired)
            }
        }

        // There is a non-expired token, go ahead and refresh it.
        // const { data } = await apiClient.post<{ data: { access_token: string | null } }>(
        //     '',
        //     JSON.stringify({
        //         query: `
        //                 query {
        //                     refreshToken {
        //                         jwtToken
        //                     }
        //                 }
        //             `,
        //     }),
        //     { headers: { Authorization: `Bearer ${token}` } }
        // )

        // console.log('this be the refresh token data', data)

        // // For some reason the server decided not to honor the refresh, redirect to login
        // if (!data.data.access_token) {
        //     setAccessToken(null)
        //     enqueueSnackbar('Please login to continue.', { variant: 'warning' })
        //     navigate('/login')
        //     throw new Error(ErrorCode.auth_token_invalid)
        // }

        // setAccessToken(data.data.access_token)

        // return data.data.access_token

        return token
    }, [ navigate, enqueueSnackbar ])

    return getBearerToken
}

export const useFetchData = <TData, TVariables> (query: string): (() => Promise<TData>) => {
    const getBearerToken = useGetBearerToken()

    return async (variables?: TVariables) => {
        const token = await getBearerToken()

        // We don't do empty strings as a valid value for anything. Strip them all.
        if (variables) {
            // @ts-ignore
            variables = mapValues(variables, v => v === '' ? null : v)
        }

        const response = await apiClient.post<{ data: TData, errors?: IPostgraphileError[] }>(
            '',
            JSON.stringify({
                query,
                variables,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    ...token ? { Authorization: `Bearer ${token}` } : {},
                },
            }
        )


        if (response.data.errors?.length) {
            throw new Error(`${response.data.errors[ 0 ].message}`)
        } else if (response.status >= 400) {
            throw new Error(response.statusText)
        }

        return response.data.data
    }
}

interface IPostgraphileError {
    code: string
    message: string
    where: string
    hint: string
}