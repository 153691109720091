import { CssBaseline } from '@material-ui/core'
import { BrowserRouter } from 'react-router-dom'
import { useCommonStyles } from '../styles'
import { AppRoutes } from './app-routes'
import { Bootstrap } from './bootstrap'
import { AlertsProvider, AppStateProvider, AuthProvider, BreadcrumbProvider, ComposeProviders, HelmetProvider, LoadingProvider, MuiDatePickerUtilsProvider, ReactQueryProvider, SnackbarProvider, ThemeProvider } from './providers'

export const App = () => {
  const commonStyles = useCommonStyles()

  const isDevWithRemoteServer = process.env.NODE_ENV === 'development' && !process.env.REACT_APP_GRAPH_URL?.includes('localhost')

  return (
    <>
      <CssBaseline />

      {/* Warning if running on localhost connected to remote server */}
      {isDevWithRemoteServer &&
        <div className={commonStyles.backgroundInvalid}>
          CONNECTED TO REMOTE SERVER: {process.env.REACT_APP_GRAPH_URL}
        </div>
      }

      <div className={commonStyles.rootTypography} style={{ height: isDevWithRemoteServer ? 'calc(100% - 58px)' : '100%' }}>
        <ComposeProviders
          providers={[
            BrowserRouter,
            AppStateProvider,
            BreadcrumbProvider,
            ThemeProvider,
            LoadingProvider,
            SnackbarProvider,
            AlertsProvider,
            ReactQueryProvider,
            HelmetProvider,
            // NativeBridgeProvider,
            /* 
              Bootstrap comes before AuthProvider because AuthProvider immediately tries to query for the authed user.
              Why does this matter, you ask?
              The authed user is a proxy for whether the current session is authenticated.
              Bootstrap handles the earliest authentication and fallbacks if not, so we don't want to load 
              the auth provider until those first checks are complete.
              Yes...checking the initial auth state does sound like something that should be in the auth provider,
              but I'm trying to keep ALL "startup" actions in Bootstrap, hency why it checks initial auth state instead of Auth provider.
            */
            Bootstrap,
            AuthProvider,
            MuiDatePickerUtilsProvider,
          ]}
        >
          <AppRoutes />
        </ComposeProviders>
      </div>
    </>
  )
}

export default App
