import { Typography, useTheme } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import React, { useMemo } from 'react'
import { ButtonFragment } from '../graphql/autogenerate/operations'
import { NotificationDelivery, UserType } from '../graphql/autogenerate/schemas'
import { getLabelForUserType, joinStrings } from '../helpers'
import { useSchoolContext } from '../stores/school'
import { LinkButton } from './link-button'
import { NotificationMessage } from './notification-message'
import { PhoneFrame } from './phone-frame'
import { AttachmentFieldValue, isFileFragment } from './forms'
import { AttachmentCell } from './attachment-cell'
import { EmbedDisplay } from './embed-display'

interface INotificationPreviewProps {
	groupIds: string[]

	userTypes: UserType[] | null
	recipients?: number | null
	message?: string
	body?: string
	buttons?: ButtonFragment[]
	displayTime?: Dayjs
	delivery?: NotificationDelivery
	attachments?: AttachmentFieldValue[]
	embedUrl?: string | null

	view: 'notification' | 'detail'
	notificationPressed: () => void
	backPressed: () => void
}

export const NotificationPreview = React.memo(
	({
		groupIds,
		userTypes,
		recipients,
		message,
		body,
		buttons,
		view,
		notificationPressed,
		backPressed,
		displayTime,
		delivery,
		attachments,
		embedUrl,
	}: INotificationPreviewProps) => {
		const theme = useTheme()
		const {
			state: {
				school: { name },
				groups,
			},
		} = useSchoolContext()

		const groupNames = useMemo(
			() =>
				joinStrings(
					groups.filter(o => groupIds.includes(o.id)).map(o => o.groupName),
					true,
					'or'
				),
			[groupIds, groups]
		)
		const userTypeNames = useMemo(() => {
			if (userTypes === null || userTypes.length === 0) return 'users'
			return joinStrings(
				userTypes.map(o => getLabelForUserType(o)),
				true
			)
		}, [userTypes])

		return (
			<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
				{groupIds.length > 0 && (
					<div style={{ padding: theme.spacing(2), textAlign: 'center' }}>
						<Typography style={{ padding: `0 ${theme.spacing(2)}px` }}>
							{delivery === NotificationDelivery.Sent ? 'Sent' : 'Sending'} to{' '}
							<b>{recipients}</b> {userTypeNames} in {groupNames}.
						</Typography>
					</div>
				)}

				<PhoneFrame
					view={view === 'notification' ? 'lock-screen' : 'detail'}
					lockScreenNotification={{
						message:
							message && message.length ? message : <i>Enter a message...</i>,
						schoolName: name,
						displayTime,
						notificationPressed,
					}}
					detailView={{
						title: 'Notification',
						onBackPressed: backPressed,
						body: (
							<>
								<NotificationMessage
									message={message}
									displayTime={displayTime}
									groupIds={groupIds}
									userTypes={userTypes}
								/>

								<div
									style={{
										padding: theme.spacing(1),
										marginBottom: theme.spacing(2),
									}}
									dangerouslySetInnerHTML={{ __html: body || '' }}
								/>

								{buttons?.map(o => (
									<LinkButton button={o} />
								))}

								{attachments?.map(o => {
									if (isFileFragment(o)) {
										return (
											<AttachmentCell
												key={o.id}
												label={o.label || o.filename}
												temporaryDownloadUrl={o.temporaryDownloadUrl}
												type={o.type}
											/>
										)
									} else {
										return <AttachmentCell key={o.id} label={o.label} />
									}
								})}

								{embedUrl && <EmbedDisplay url={embedUrl} mobilePreview />}
							</>
						),
					}}
				/>
			</div>
		)
	}
)
