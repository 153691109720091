import { ReactNode } from 'react'
import { CurrentUserFragment } from '../../graphql/autogenerate/operations'

/* 
    State Object 
*/
export interface IAppState {
    bootstrapped?: boolean // Has the app been started up?
    authed?: boolean
    toolbarContent?: ReactNode
    currentUser?: CurrentUserFragment | null
}


/* 
    Actions 
*/
export enum AppActionType {
    bootstrap,
    login,
    logout,
    setToolbarContent,
    setCurrentUser,
}

interface IPayloadLessAction {
    type:
    AppActionType.login |
    AppActionType.logout
}

interface IBootstrapAction {
    type: AppActionType.bootstrap
    payload: {
        authed: boolean
        currentUser?: CurrentUserFragment | null
    }
}

interface ISetToolbarContentAction {
    type: AppActionType.setToolbarContent
    payload: {
        toolbarContent?: ReactNode
    }
}

interface ISetCurrentUser {
    type: AppActionType.setCurrentUser
    payload: {
        currentUser?: CurrentUserFragment | null
    }
}


export type AppAction =
    IPayloadLessAction |
    IBootstrapAction |
    ISetToolbarContentAction |
    ISetCurrentUser 
