import { Button, IconButton, Typography, useTheme } from '@material-ui/core'
import { ArrowBack, ArrowForward, Check } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { useSchoolOnboardingStyles, useSchoolOnboardingContext } from './school-onboarding'
import * as yup from 'yup'
import { useCallback, useState } from 'react'
import { FormikTextInput, PasswordField } from './forms'
import { useCheckEmailForFacultyStaffJoinSchoolQuery, useSendSchoolPersonInvitationEmailMutation, useClaimAnonymousAccountMutation, useAuthenticateMutation, useInvitePersonToSchoolMutation } from '../graphql/autogenerate/react-query'
import { useAccessToken, useAppState, useHandleReactQuery, useHandleReactQueryMutation, useLocalStorage, } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { UserType } from '../graphql/autogenerate/schemas'
import { useSnackbar } from 'notistack'
import { AppActionType } from '../stores/app-state'
import { APPLE_REVIEWER_EMAIL, APPLE_REVIEWER_PASSWORD, LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'

export const SchoolOnboardingAuth = () => {
    const theme = useTheme()
    const onboardingStyles = useSchoolOnboardingStyles()
    const { state: { school: { id: schoolId, name: schoolName } } } = useSchoolContext()
    const { setSchoolOnboardingState } = useSchoolOnboardingContext()
    const { enqueueSnackbar } = useSnackbar()
    const { dispatch } = useAppState()
    const { getItem, removeItem } = useLocalStorage()
    const { setAccessToken } = useAccessToken()

    const [ state, setState ] = useState({
        step: 'check-account' as 'check-account' | 'password-reset-required' | 'sign-in' | 'invitation-not-accepted' | 'not-added',
        email: null as string | null,
        schoolPersonId: null as string | null,
    })
    const { step } = state

    const initialValues = {
        email: '',
        password: '',
    }

    const { mutate: invitePersonToSchool } = useHandleReactQueryMutation(useInvitePersonToSchoolMutation({
        onSuccess: () => {
            setState(_state => ({ ..._state, step: 'invitation-not-accepted', }))
        }
    }))


    const { refetch: reCheckEmailForFacultyStaffJoinSchoolQuery } = useHandleReactQuery(useCheckEmailForFacultyStaffJoinSchoolQuery(
        { schoolId, email: state.email! },
        {
            enabled: Boolean(state.email),
            onSuccess: ({ passwordResetRequired, checkPersonEmailForSchool }) => {
                // We allow Apple reviewers to bypass the auth
                if (state.email === APPLE_REVIEWER_EMAIL) {
                    setState(_state => ({ ..._state, step: 'sign-in' }))
                } else {
                    const schoolPerson = checkPersonEmailForSchool?.schoolPeople.nodes[ 0 ]

                    // This person is in the Faculty/Staff list for this school
                    if (schoolPerson) {
                        // They have an accepted invitation, let them do password auth
                        if (schoolPerson?.schoolPersonInvitation?.accepted) {
                            if (passwordResetRequired) {
                                setState(_state => ({ ..._state, step: 'password-reset-required' }))
                            } else {
                                setState(_state => ({ ..._state, step: 'sign-in' }))
                            }
                        } else if (schoolPerson.schoolPersonInvitation) {
                            // They have an invitation they just haven't accepted it
                            setState(_state => ({ ..._state, step: 'invitation-not-accepted', schoolPersonId: schoolPerson.id }))
                        } else {
                            // They don't have an invitation, but they are in the Faculty/Staff list. Let them just send their own invite
                            setState(_state => ({ ..._state, schoolPersonId: schoolPerson.id }))
                            invitePersonToSchool({ schoolPersonId: schoolPerson.id })
                        }
                    } else {
                        // This Person is not yet listed in the Faculty/Staff list for the school. Show a message suggesting they request to be added.
                        setState(_state => ({ ..._state, step: 'not-added' }))
                    }
                }
            },
            refetchOnMount: false,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
        }
    ))

    const onAuthSuccess = useCallback(async (jwtToken?: string | null) => {
        /* 
            A null jwtToken means the authentication failed for any reason (wrong password, account doesn't exist)
        */
        if (jwtToken === null) {
            enqueueSnackbar(
                <div>
                    Login failed. <span>Email and password do not match, or an account does not exist with the provided email address.</span>
                </div>,
                {
                    variant: 'error',
                    preventDuplicate: false,
                }
            )
        }

        if (jwtToken) {
            await setAccessToken(jwtToken)
            dispatch({ type: AppActionType.login })
            setSchoolOnboardingState(_state => ({ ..._state, step: 'divisions' }))
        }
    }, [])

    const { mutate: resendInvitation } = useHandleReactQueryMutation(useSendSchoolPersonInvitationEmailMutation({ onSuccess: () => { enqueueSnackbar('Invitation email sent.', { variant: 'success' }) } }))
    const { mutate: claimAnonymousAccount } = useHandleReactQueryMutation(useClaimAnonymousAccountMutation({
        onSuccess: async ({ claimAnonymousAccount }) => {
            await removeItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })
            await onAuthSuccess(claimAnonymousAccount?.jwtToken)
        }
    }))
    const { mutate: login } = useHandleReactQueryMutation(useAuthenticateMutation({ onSuccess: ({ authenticate }) => onAuthSuccess(authenticate?.jwtToken) }))

    return (
        <div className={onboardingStyles.stepContainer}>
            <div style={{ position: 'absolute', top: theme.spacing(1), left: 0 }}>
                <IconButton
                    onClick={() => setSchoolOnboardingState(_state => ({
                        ..._state,
                        step: 'user-type',
                        userTypes: step === 'not-added' ? _state.userTypes?.filter(o => o !== UserType.FacultyStaff) : _state.userTypes,
                    }))}
                >
                    <ArrowBack />
                </IconButton>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={yup.object({
                    email: yup.string().required('Required').email('Please enter a valid email address.'),
                    password: yup.string()
                        .test(
                            'password',
                            'Required',
                            function (value) {
                                if (step === 'sign-in') return Boolean(value)
                                return true
                            }
                        )
                })}
                onSubmit={async (values) => {
                    switch (step) {
                        case 'check-account':
                            setState(_state => ({ ..._state, email: values.email }))
                            break
                        case 'password-reset-required':
                        case 'sign-in':
                            if (values.password === APPLE_REVIEWER_PASSWORD) {
                                setSchoolOnboardingState(_state => ({ ..._state, step: 'divisions' }))
                            } else {
                                const anonymousAccountId = await getItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })
                                if (anonymousAccountId) {
                                    claimAnonymousAccount({
                                        anonymousAccountEmail: `${anonymousAccountId}@legitapps.com`,
                                        anonymousAccountPassword: anonymousAccountId,
                                        claimingAccountEmail: values.email,
                                        claimingAccountPassword: values.password,
                                    })
                                } else {
                                    login({
                                        email: values.email,
                                        password: values.password,
                                    })
                                }
                            }
                            break
                    }
                }}
            >
                {formikProps =>
                    <Form style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflowY: 'auto', }}>
                            <Typography gutterBottom variant='h4'>
                                {step === 'check-account' && 'Sign In'}
                                {step === 'not-added' && 'Request Access'}
                                {step === 'password-reset-required' && 'Password Reset Required'}
                                {step === 'sign-in' && 'Sign In'}
                            </Typography>

                            {(step === 'sign-in' || step === 'check-account') &&
                                <Typography style={{ marginBottom: theme.spacing(4) }}>Please sign in to join as a Faculty/Staff member.</Typography>
                            }

                            {step === 'password-reset-required' &&
                                <>
                                    <Typography gutterBottom color='textSecondary'>To help ensure the security of your account, we occasionally require you to verify your email address and create a new password.</Typography>
                                    <Typography style={{ marginBottom: theme.spacing(2) }} color='textSecondary' >Please check your email and follow the link to reset your password, then return here to continue signing in.</Typography>
                                </>
                            }

                            {step === 'invitation-not-accepted' &&
                                <>
                                    <Typography gutterBottom color='textSecondary' variant='h5' style={{ display: 'flex', alignItems: 'center' }}><Check style={{ color: theme.palette.success.main, marginRight: theme.spacing(.5) }} /> Almost there!</Typography>
                                    <Typography gutterBottom color='textSecondary'>Please check your email for an invitation to setup your account.</Typography>
                                    <Typography style={{ marginBottom: theme.spacing(2) }} color='textSecondary' >Once you've setup your account, you can return here to continue signing in.</Typography>

                                    <div style={{ marginTop: theme.spacing(2) }}>
                                        <Button onClick={() => state.schoolPersonId && resendInvitation({ schoolPersonInvitationId: state.schoolPersonId })} type='button' variant='text' color='primary' disableElevation>
                                            Resend Account Invitation
                                        </Button>
                                    </div>
                                </>
                            }

                            {step === 'not-added' &&
                                <>
                                    <Typography color='textSecondary' gutterBottom>This email address ({formikProps.values.email}) is not listed in the {schoolName} Faculty/Staff list.</Typography>
                                    <Typography color='textSecondary'>Please contact your administrator to request to be added, or to confirm which email address you should use to join the app.</Typography>
                                </>
                            }

                            {(step === 'check-account' || step === 'password-reset-required' || step === 'sign-in') &&
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <FormikTextInput type='email' noMargin fieldProps={{ name: 'email', label: 'Email', disabled: Boolean(step !== 'check-account') }} />
                                </div>
                            }

                            {(step === 'sign-in' || step === 'password-reset-required') &&
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <PasswordField style={{ marginBottom: theme.spacing(2) }} fieldProps={{ name: 'password', label: 'Password', labelWidth: 70 }} noMargin />
                                </div>
                            }

                            {step === 'sign-in' &&
                                <div style={{ marginTop: theme.spacing(2) }}>
                                    <Button href={`/forgot-password?email=${formikProps.values.email}&redirect=/return-to-app`} type='button' variant='text' color='primary' disableElevation>
                                        Forgot password
                                    </Button>
                                </div>
                            }
                        </div>

                        <div>
                            {(step === 'not-added') &&
                                <Button
                                    style={{ width: '100%' }}
                                    size='large'
                                    type='button'
                                    color='primary'
                                    variant='outlined'
                                    startIcon={<ArrowBack />}
                                    onClick={() => setSchoolOnboardingState(_state => ({ ..._state, step: 'user-type', userTypes: _state.userTypes?.filter(o => o !== UserType.FacultyStaff) }))}
                                >
                                    Back
                                </Button>
                            }

                            {(step === 'check-account' || step === 'password-reset-required' || step === 'sign-in') &&
                                <Button
                                    disabled={!formikProps.isValid}
                                    style={{ width: '100%' }}
                                    size='large'
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    endIcon={<ArrowForward />}
                                >
                                    Continue
                                </Button>
                            }

                            {step === 'invitation-not-accepted' &&
                                <Button
                                    style={{ width: '100%' }}
                                    size='large'
                                    type='button'
                                    color='primary'
                                    variant='contained'
                                    endIcon={<ArrowForward />}
                                    onClick={async () => {
                                        const results = await reCheckEmailForFacultyStaffJoinSchoolQuery()
                                        if (!Boolean(results.data?.checkPersonEmailForSchool?.schoolPeople.nodes[ 0 ]?.schoolPersonInvitation?.accepted))
                                            enqueueSnackbar(`Invitation not yet accepted - please accept the invitation sent to ${state.email}.`, { variant: 'error' })
                                    }}
                                >
                                    Sign In
                                </Button>
                            }
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    )
}

