import { makeStyles, createStyles, Typography } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import { flatten, indexOf } from 'lodash'
import React from 'react'
import { ICalendarEventCell } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'
import { CalendarEventCell } from './calendar-event-cell'
import { CalendarEventTableRow } from './calendar-event-list-item'
import { Tooltip } from './tooltip'

interface ICalendarWeekEvents extends ICalendarDispatchProp {
    events: ICalendarEventCell[]
}

export const CalendarWeekEvents = React.memo(({ events, dispatch }: ICalendarWeekEvents) => {
    /* 
        We determine event layout using an algorithm courtesy of this fine gentleman: https://github.com/legitapps/calendar-puzzle (forked to make sure we don't lose it)

        His solution description is worth a read.
    */

    events = events.filter(o => !o.allDay)

    if (events.length === 0) return null

    const collisionGroups: string[][] = [
        [ events[ 0 ].cellId ] // Start off with one collision group including the first event in the list
    ]

    /* 
        Loop through the events to check each previous event until we find a collision or get to the beginning of the list.
        
        Starting with the second event (since we already put the first in a collision group above). 
    */

    for (let i = 1, l = events.length; i < l; ++i) {
        const event = events[ i ]

        /* 
            Track whether we've found a collision or not.
            That way we can bail once we've got one.
        */
        let foundCollision = false

        // Keep comparing each previous event until you're at the beginning (i is the index of the current event, so we start with the one right before it, i - 1).
        var eventIndexToCompare = i - 1
        do {
            const previousEvent = events[ eventIndexToCompare ]

            if (checkEventCellCollision(event, previousEvent)) {
                /* 
                    Alright, this event collides with another previous event.
                    We need to find that previous event's collision group and add this one to it.
                */

                // Again, tracking this so we can bail out of the collision group search once we find the right one.
                let foundCollisionGroup = false

                /*
                    As recommended by the calendar puzzle guy, loop backward through the list of collision groups
                    since you're more likely to find the first collider later in the list of collision groups.
                */
                let collisionGroupIndex = collisionGroups.length
                while (!foundCollisionGroup && collisionGroupIndex--) {
                    if (indexOf(collisionGroups[ collisionGroupIndex ], previousEvent.cellId) !== -1) {
                        // Found the previous event's collision group! Add the current event to it.
                        collisionGroups[ collisionGroupIndex ].push(event.cellId)

                        // Bail out of the while loop
                        foundCollisionGroup = true
                    }
                }

                // Bail out of the previous event checking do while loop since we found a collision and added the current event to that collision's collision group.
                foundCollision = true
            }

            // Bail if we find a colliding event or if we get to the beginning of the list of events (which means this event doesn't collide with any others)
        } while (!foundCollision && eventIndexToCompare--)

        // No collisions! Start a new collision group.
        if (foundCollision === false) {
            collisionGroups.push([ event.cellId ])
        }
    }

    const collisionGroupLayouts: ICalendarEventCell[][][] = []

    collisionGroups.forEach(collisionGroup => {
        const layout: ICalendarEventCell[][] = []

        /* 
            A Collision Group layout is an array of columns. 

            We need to place each event in a "row" in a "column".

            A "column" is what it sounds like...columns of events within a single day view.

            A "row" is a bit tricker concept. 
            In a normal column/row situation it's like a grid. If you have 3 columns and 4 rows, the rows line up in each column.
            E.g. row 2 in column 1 lines up with row 2 in column 3. Rows are all straight across.

            In this layout it's better to think of each column as being completely independent with its own rows.

            A row is a "slot" for an event, but event "rows" cannot collide.

            When placing an event in a column we need to check if the "row" for that event is available.

            An event needs to go into a row that has no other event in its range at all (i.e. it doesn't collide). 

            If there are no open "rows" in a column for an event, we create a new column to put that event in.
        */
        collisionGroup.forEach(eventId => {
            const event = events.find(o => o.cellId === eventId)

            if (!event) return

            let colIndex = 0
            let foundARow = false
            while (!foundARow) {
                // Make sure we have a row at this index
                if (layout[ colIndex ] === undefined) layout.push([])

                if (layout[ colIndex ].length === 0) {
                    // This column is empty and can take the event
                    layout[ colIndex ].push(event)

                    // We can bail out of the search since we found a spot for this event
                    foundARow = true
                } else {
                    // This column has an event in it, need to make sure the event we're trying to place doesn't collide with the last event in the column.
                    const [ lastEventInColumn ] = layout[ colIndex ].slice(-1)

                    if (!checkEventCellCollision(event, lastEventInColumn)) {
                        layout[ colIndex ].push(event)

                        // We can bail out of the search since we found a spot for this event
                        foundARow = true
                    }
                }

                colIndex++
            }
        })

        collisionGroupLayouts.push(layout)
    })

    return (
        <>
            {collisionGroupLayouts.map((layout, index) => <RenderCollisionGroupLayout key={index} layout={layout} dispatch={dispatch} />)}
        </>
    )
})

const isSameOrBefore = (timeA: Dayjs, timeB: Dayjs) => timeA.isSame(timeB, 'minutes') || timeA.isBefore(timeB, 'minutes')

const checkEventCellCollision = (nextEvent: ICalendarEventCell, previousEvent: ICalendarEventCell) => {
    if (nextEvent.startDate.isSame(previousEvent.endDate, 'minutes') || nextEvent.startDate.isAfter(previousEvent.endDate, 'minutes')) return false
    if (isSameOrBefore(nextEvent.startDate, previousEvent.startDate) && isSameOrBefore(previousEvent.startDate, nextEvent.endDate)) return true
    if (isSameOrBefore(nextEvent.startDate, previousEvent.endDate) && isSameOrBefore(previousEvent.endDate, nextEvent.endDate)) return true
    if (isSameOrBefore(previousEvent.startDate, nextEvent.startDate) && isSameOrBefore(nextEvent.startDate, previousEvent.endDate)) return true
    if (isSameOrBefore(previousEvent.startDate, nextEvent.endDate) && isSameOrBefore(nextEvent.endDate, previousEvent.endDate)) return true
    return false
}

interface IRenderCollisionGroupLayout extends ICalendarDispatchProp { layout: ICalendarEventCell[][] }
const RenderCollisionGroupLayout = React.memo(({ layout, dispatch }: IRenderCollisionGroupLayout) => {

    /* 
        When rendering a collision group layout, we do a few things:
        - Display the longest columns first (e.g. the ones with the earliest and latest events)
        - Display a max of 3 columns. If there is a fourth, display an "expander" column as the third column with something like 8 more events from 1pm to 9pm
        - Ensure the event with the earliest start time and the event with the latest end time is/are displayed within the two visible columns.
    */
    layout.sort((colA, colB) => {
        if (getColumnDurationMins(colA) > getColumnDurationMins(colB)) return 1
        if (getColumnDurationMins(colA) < getColumnDurationMins(colB)) return -1
        return 0
    })


    if (layout.length > 3) {
        const placeholderStartTime =
            layout.slice(2).reduce<Dayjs | undefined>((startTime, column) => {
                if (!startTime) return column[ 0 ]?.startDate
                if (column[ 0 ]?.startDate.isBefore(startTime)) return column[ 0 ].startDate
                return startTime
            }, undefined)

        const placeholderEndTime =
            layout.slice(2).reduce<Dayjs | undefined>((endTime, column) => {
                if (!endTime) return column.slice(-1)[ 0 ]?.endDate
                if (column.slice(-1)[ 0 ]?.endDate.isAfter(endTime)) return column.slice(-1)[ 0 ].endDate
                return endTime
            }, undefined)

        const hiddenEvents = flatten(layout.slice(2))

        if (placeholderStartTime && placeholderEndTime) {
            return (
                <>
                    {layout.slice(0, 2).map((column, index) => <EventColumn key={index} eventColumn={column} columnPosition={index} columnCount={3} dispatch={dispatch} />)}
                    <PlaceholderColumn startTime={placeholderStartTime} endTime={placeholderEndTime} hiddenEvents={hiddenEvents} dispatch={dispatch} />
                </>
            )
        }
    }

    return (
        <>
            {layout.map((column, index) => <EventColumn key={index} eventColumn={column} columnPosition={index} columnCount={layout.length} dispatch={dispatch} />)}
        </>
    )
})

const getColumnDurationMins = (column: ICalendarEventCell[]) => column[ 0 ].startDate.diff(column.slice(-1)[ 0 ].endDate, 'minutes')

const usePlaceholderColumnStyles = makeStyles(theme =>
    createStyles({
        placeholder: {
            overflow: 'hidden',
            border: `solid 1px white`,
            backgroundColor: theme.palette.grey[ 300 ],
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                boxShadow: theme.shadows[ 2 ]
            }
        }
    })
)

interface IPlaceholderColumnProps extends ICalendarDispatchProp {
    startTime: Dayjs
    endTime: Dayjs
    hiddenEvents: ICalendarEventCell[]
}
const PlaceholderColumn = React.memo(({ startTime, endTime, hiddenEvents, dispatch }: IPlaceholderColumnProps) => {

    const { placeholder } = usePlaceholderColumnStyles()

    hiddenEvents.sort((eventA, eventB) => {
        if (eventA.startDate.isBefore(eventB.startDate)) return -1
        if (eventA.startDate.isAfter(eventB.startDate)) return 1
        if (eventA.endDate.isBefore(eventB.endDate)) return -1
        if (eventA.endDate.isAfter(eventB.endDate)) return 1
        return 0
    })

    return (
        <Tooltip
            title={
                <table>
                    <tbody>
                        {hiddenEvents.map(o => <CalendarEventTableRow key={o.cellId} event={o} dispatch={dispatch} />)}
                    </tbody>
                </table>
            }
            interactive
        >
            <div
                className={placeholder}
                style={{
                    gridArea: getGridPositionForTime(startTime, endTime),
                    marginLeft: `${(100 / 3) * 2}%`,
                }}
            >
                <Typography align='center' variant='caption'>
                    + {hiddenEvents.length}
                    <br />
                events
            </Typography>
            </div >
        </Tooltip>
    )
})


interface IEventColumnProps extends ICalendarDispatchProp { eventColumn: ICalendarEventCell[], columnPosition: number, columnCount: number }
const EventColumn = React.memo(({ eventColumn, columnPosition, columnCount, dispatch }: IEventColumnProps) => {
    return (
        <>
            {eventColumn.map(event => (
                <Event key={event.cellId} event={event} columnCount={columnCount} columnPosition={columnPosition} dispatch={dispatch} />
            ))}
        </>
    )
})

interface IEventProps extends ICalendarDispatchProp {
    event: ICalendarEventCell
    columnPosition: number
    columnCount: number
}

const Event = React.memo(({ event, columnPosition, columnCount, dispatch }: IEventProps) => {
    return (
        <div
            key={event.cellId}
            style={{
                gridArea: getGridPositionForTime(event.startDate, event.endDate),
                marginLeft: `${(100 / columnCount) * columnPosition}%`,
                marginRight: `${(100 / columnCount) * (columnCount - columnPosition - 1)}%`,
                overflow: 'hidden',
            }}
        >
            <CalendarEventCell
                event={event}
                thin={columnCount > 1}
                dispatch={dispatch}
            />
        </div >
    )
})

/** 
    This one is a bit tricky, but super important.

    This calculates the `gridArea` value for the event cell. 
    - The grid is broken up into 15 min increments across 7 days
    - For the start of the cell we need to know how many 15 min increments until the start time (start time hours * 4), then add how many 15 min increments are contained in the minutes
*/
const getGridPositionForTime = (startTime: Dayjs, endTime: Dayjs): string => {
    const dayOfWeek = startTime.day()

    return `${(startTime.hour() * 4) + Math.round(startTime.minute() / 15) + 1} / ${dayOfWeek + 3} / ${(endTime.hour() * 4) + Math.round(endTime.minute() / 15) + 1} / ${dayOfWeek + 4}`
}