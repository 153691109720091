import {
	GroupListFieldsFragment,
	SchoolDetailFragment,
} from '../graphql/autogenerate/operations'

export const gatherAllGroupsForSchoolFragment = (
	school: SchoolDetailFragment
) => {
	return school.divisions.nodes.reduce<GroupListFieldsFragment[]>(
		(allGroups, division) => {
			return [
				...allGroups,
				...division.groupCategories.nodes.reduce<GroupListFieldsFragment[]>(
					(groupCategoryGroups, groupCategory) => {
						return [...groupCategoryGroups, ...groupCategory.groups.nodes]
					},
					[]
				),
			]
		},
		[]
	)
}
