import { Typography, useTheme } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import { CSSProperties, DetailedHTMLProps, HTMLAttributes, useMemo } from 'react'
import { NotificationDelivery, UserType } from '../graphql/autogenerate/schemas'
import { joinStrings, getLabelForUserType } from '../helpers'
import { useSchoolContext } from '../stores/school'

export interface INotificationMessageProps {
    id?: string
    message?: string
    displayTime?: Dayjs
    groupIds: string[]
    userTypes?: UserType[] | null
    draft?: boolean
    sent?: boolean
    recurringEnabled?: boolean | null
    error?: boolean
    delivery?: NotificationDelivery
    newsletterUrl?: string | null
    style?: CSSProperties
    onClick?: () => void
    className?: string
}

const dayjsCalendarFormats = {
    sameDay: '[Today at] h:mma', // The same day ( Today at 2:30am )
    nextDay: '[Tomorrow at] h:mma', // The next day ( Tomorrow at 2:30am )
    nextWeek: 'dddd [at] h:mma', // The next week ( Sunday at 2:30am )
    lastDay: '[Yesterday at] h:mma', // The day before ( Yesterday at 2:30am )
    lastWeek: '[Last] dddd [at] h:mma', // Last week ( Last Monday at 2:30am )
    sameElse: 'dddd, MMMM D @ h:mma' // Everything else ( Friday, August 13 @ 2:30am )
}

const lowerCaseDayjsCalendarFormats = {
    sameDay: '[today at] h:mma', // The same day ( Today at 2:30am )
    nextDay: '[tomorrow at] h:mma', // The next day ( Tomorrow at 2:30am )
    nextWeek: 'dddd [at] h:mma', // The next week ( Sunday at 2:30am )
    lastDay: '[yesterday at] h:mma', // The day before ( Yesterday at 2:30am )
    lastWeek: '[last] dddd [at] h:mma', // Last week ( Last Monday at 2:30am )
    sameElse: 'dddd, MMMM D @ h:mma' // Everything else ( Friday, August 13 @ 2:30am )
}


export const NotificationMessage = ({ message, displayTime, groupIds, userTypes, draft, sent, recurringEnabled, error, delivery, newsletterUrl, style, onClick, className }: INotificationMessageProps) => {
    const theme = useTheme()

    const { state: { groups } } = useSchoolContext()

    const userTypeNames = useMemo(() => {
        if (userTypes === null || userTypes === undefined || userTypes.length === 0) return sent ? 'everyone' : 'Everyone'
        return joinStrings(userTypes.map(o => getLabelForUserType(o)), true)
    }, [ userTypes ])

    const groupNames = useMemo(() => joinStrings(groups.filter(o => groupIds.includes(o.id)).map(o => o.groupName), true, 'or'), [ groupIds, groups ])

    const styles: CSSProperties = { padding: theme.spacing(1), border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[ draft ? 300 : 700 ]}`, borderRadius: theme.shape.borderRadius, color: 'black', ...style }

    const cell = (
        <>
            {message && message.length ? message : <i>Enter a message...</i>}

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(1) }}>
                {typeof recurringEnabled === 'boolean' && <Typography variant='caption' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', color: recurringEnabled ? theme.palette.success.light : theme.palette.warning.light }}>{recurringEnabled ? 'Enabled' : 'Disabled'}</Typography>}
                {delivery === NotificationDelivery.Sent && error && <Typography variant='caption' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', color: theme.palette.error.main }}>Error sending.</Typography>}

                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {!draft && <i><small style={{ color: theme.palette.grey[ 500 ], textAlign: 'right' }}>
                        {typeof recurringEnabled === 'boolean' ?
                            recurringEnabled ? `Next occurrence will send ${(displayTime || dayjs()).calendar(undefined, lowerCaseDayjsCalendarFormats)}` : ''
                            :
                            (displayTime || dayjs()).calendar(undefined, dayjsCalendarFormats)
                        }

                    </small></i>}
                    {groupIds.length > 0 && <small style={{ textAlign: 'right' }}>{sent ? 'Sent to ' : ''}{userTypeNames} subscribed to {groupNames}.</small>}
                </div>
            </div>
        </>
    )

    if (newsletterUrl) return <a target='_blank' href={newsletterUrl}><div style={styles} className={className}>{cell}</div></a>

    return <div style={styles} onClick={onClick} className={className}>{cell}</div>
}