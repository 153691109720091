import type { Modifier } from '@dnd-kit/core'


export const snapCenterToCursor: Modifier = ({ activeNodeRect, draggingNodeRect, transform }) => {
    if (draggingNodeRect && activeNodeRect) {

        const offsetY = draggingNodeRect.top - activeNodeRect.top

        return {
            ...transform,
            y: transform.y - 50,
        }
    }

    return transform
}