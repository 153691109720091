import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { usePageQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { PageSectionDisplay } from './page-section-display'
import { REFETCH_INTERVAL } from '../constants'

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(1),
            paddingTop: 0
        },
    })
)

interface IPageProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    pageId: string
}

export const PageDisplay = ({ pageId, className, style, ...rest }: IPageProps) => {
    const styles = useStyles()

    const { data } = useHandleReactQuery(usePageQuery(
        { id: pageId },
        { refetchInterval: REFETCH_INTERVAL }
    ))

    return (
        <div className={clsx(styles.container, className)} style={{ position: 'relative', ...style }} {...rest}>
            {data?.page?.pageSections.nodes?.map((pageSection) =>
                <PageSectionDisplay key={pageSection.id} pageSection={pageSection} />
            )}
        </div>
    )
}