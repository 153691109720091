import { makeStyles, createStyles, withStyles, CheckboxProps, Checkbox } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import React, { ReactNode, useCallback } from 'react'
import { withContextSelector } from '../helpers'
import { CalendarActionType, useCalendarContext } from '../stores/calendar'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { ICalendarDispatchProp } from './calendar'
import clsx from 'clsx'

const useCalendarSidebarCalendarsListRowStyles = makeStyles(theme =>
    createStyles({
        calendarRow: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: theme.spacing(.5),
            padding: `0px ${theme.spacing(1)}px`,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.grey[ 200 ],
                '& span:nth-child(3)': {
                    display: 'flex!important'
                }
            },

        },
        calendarRowLabel: {
            paddingLeft: theme.spacing(1),
            flex: 1,
        },
        addRemoveFavoritesIcon: {
            color: theme.palette.grey[ 500 ],
        },
        hideAddRemoveFavoritesIcon: {
            display: 'none!important',
        }
    })
)

export interface ICalendarSidebarCalendarsListRowProps extends ICalendarDispatchProp {
    id: string
    title: ReactNode
    color?: string | null
    calendarIds: string[]
    enabled?: boolean
    favorited?: boolean
    persistAddRemoveFavoriteIcons?: boolean
    disableFavoriting?: boolean
}

const Row = ({ title, color, enabled, dispatch, calendarIds, favorited, persistAddRemoveFavoriteIcons, disableFavoriting }: ICalendarSidebarCalendarsListRowProps) => {
    const styles = useCalendarSidebarCalendarsListRowStyles()

    const ColoredCheckbox = withStyles({
        root: {
            color: color || 'default',
            '&$checked': {
                color: color || 'default',
            },
        },
        checked: {},
    })((props: CheckboxProps) => <Checkbox color='default' {...props} />)

    const handleCheckboxChange = useCallback((_, checked: boolean) => {
        if (checked === true) {
            dispatch({ type: CalendarActionType.showCalendars, payload: calendarIds })
        } else if (checked === false) {
            dispatch({ type: CalendarActionType.hideCalendars, payload: calendarIds })
        }
    }, [])

    return (
        <div
            className={styles.calendarRow}
            onClick={() => handleCheckboxChange(false, !enabled)}
        >
            <ColoredCheckbox
                checked={enabled}
                onChange={handleCheckboxChange}
                name={`${calendarIds}-enable-disable`}
                style={{ padding: 0 }}
            />

            <div className={styles.calendarRowLabel}>
                {title}
            </div>

            {!disableFavoriting &&
                <AnimationIconWraper
                    className={clsx(styles.addRemoveFavoritesIcon, !persistAddRemoveFavoriteIcons && styles.hideAddRemoveFavoritesIcon)}
                    children={favorited ? <Remove /> : <Add />}
                    tooltip={{
                        title: favorited ? 'Remove from favorites' : 'Add to favorites',
                        placement: 'right'
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        dispatch({ type: CalendarActionType.toggleCalendarsFavorited, payload: calendarIds })
                    }}
                />
            }
        </div>
    )
}

const MemoizedRow = React.memo(Row)
export const MemoizedCalendarSidebarCalendarsListRow = withContextSelector({
    MemoizedComponent: MemoizedRow,
    getContext: useCalendarContext,
    selector: ({ state: { visibleCalendars, favoritedCalendars } }, { calendarIds }) => ({
        enabled: calendarIds.reduce<boolean>((enabled, calendarId) => enabled && visibleCalendars[ calendarId ], true),
        favorited: calendarIds.reduce<boolean>((enabled, calendarId) => enabled && favoritedCalendars[ calendarId ], true),
    })
})
