import { Typography, useTheme } from '@material-ui/core'
import { PageSectionFragment } from '../graphql/autogenerate/operations'

interface IPageSectionTextProps {
    pageSection: PageSectionFragment
}

export const PageSectionText = ({ pageSection }: IPageSectionTextProps) => {
    const theme = useTheme()
    return (
        <>
            {pageSection.title && <Typography variant='h5' style={{ fontStyle: 'italic', }}>{pageSection.title}</Typography>}
            <Typography color='textPrimary' style={{ padding: `${theme.spacing(1)}px 0px` }}>
                <div dangerouslySetInnerHTML={{ __html: pageSection.body || '' }} />
            </Typography>
        </>
    )
}