const getMobileDetect = (userAgent: string) => {

    function isIos () {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true
        } else {
            return isIpadOS()
        }
    }

    function isIpadOS () {
        return navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2 &&
            /MacIntel/.test(navigator.platform)
    }

    const isAndroid = () => Boolean(userAgent.match(/Android/i))
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
    const isSSR = () => Boolean(userAgent.match(/SSR/i))

    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
    const isDesktop = () => Boolean(!isMobile() && !isSSR())
    return {
        isMobile,
        isDesktop,
        isAndroid,
        isIos,
        isSSR
    }
}

export const useMobileDetect = () => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
    return getMobileDetect(userAgent)
}