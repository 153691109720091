import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
	createStyles,
	IconButton,
	makeStyles,
	Tooltip,
} from '@material-ui/core'
import { Add, Delete, DragHandle, Edit } from '@material-ui/icons'
import clsx from 'clsx'
import { useRef } from 'react'
import { useHoverDirty } from 'react-use'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { useDeletePageSectionMutation } from '../graphql/autogenerate/react-query'
import { usePageSectionStyles } from '../styles/use-page-section-styles'
import { ConfirmDialog } from './confirm-dialog'
import { useModal } from './modal'
import { usePageContext } from './page-edit'
import { PageSectionDisplay } from './page-section-display'

const useStyles = makeStyles(() =>
	createStyles({
		hoverBackground: {
			'&:hover': {
				backgroundColor: '#E7F0F960',
			},
		},
	})
)

interface IPageSectionProps {
	pageSection: PageSectionFragment
}
/** 
    ### WARNING
    `<PageSectionEdit />` implements `useDraggable` (via `useSortable`) and should **not** be used 
    as the "display" component within a `<DragOverlay />`. 

    `<PageSectionDisplay />` exists for a readonly purely presentational view for a PageSection.
*/
export const PageSectionEdit = ({ pageSection }: IPageSectionProps) => {
	const pageSectionStyles = usePageSectionStyles()
	const styles = useStyles()

	const {
		state: { hideAddSectionButtons },
		setState,
		refetchPageSections,
		openAddSectionToolbarAtIndex,
		pageSectionCount,
	} = usePageContext()

	const ref = useRef<HTMLDivElement | null>(null)
	const isHovering = useHoverDirty(ref)

	const confirmDeleteModal = useModal()
	const { mutate: deletePageSection } = useDeletePageSectionMutation({
		onSuccess: async () => {
			await refetchPageSections()
			confirmDeleteModal.close()
		},
	})

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: pageSection.id })

	return (
		<>
			<div
				id={pageSection.id}
				ref={node => {
					ref.current = node
					setNodeRef(node)
				}}
				style={{
					transform: CSS.Transform.toString(transform),
					transition: transition || undefined,
				}}
				className={clsx(
					pageSectionStyles.container,
					styles.hoverBackground,
					isDragging && pageSectionStyles.isDragging
				)}
			>
				{!isDragging && (
					<>
						<div
							className={clsx(
								pageSectionStyles.iconContainer,
								pageSectionStyles.topLeft
							)}
							style={{ opacity: isHovering ? 1 : 0 }}
						>
							<Tooltip title='Reorder section'>
								<IconButton
									size='small'
									className={pageSectionStyles.icon}
									{...listeners}
									{...attributes}
									style={{ cursor: 'grab' }}
								>
									<DragHandle />
								</IconButton>
							</Tooltip>
						</div>

						<div
							className={clsx(
								pageSectionStyles.iconContainer,
								pageSectionStyles.addIconContainer,
								pageSectionStyles.addIconContainerTop,
								(hideAddSectionButtons || isDragging) &&
									pageSectionStyles.hidden
							)}
							style={{ opacity: isHovering ? 1 : 0 }}
						>
							<Tooltip title='Add a section'>
								<IconButton
									size='small'
									className={pageSectionStyles.icon}
									onClick={() =>
										openAddSectionToolbarAtIndex(pageSection.sortOrder)
									}
								>
									<Add />
								</IconButton>
							</Tooltip>
						</div>

						<div
							className={clsx(
								pageSectionStyles.iconContainer,
								pageSectionStyles.topRight,
								isDragging && pageSectionStyles.hidden
							)}
							style={{ opacity: isHovering ? 1 : 0 }}
						>
							<Tooltip title='Edit section'>
								<IconButton
									size='small'
									className={pageSectionStyles.icon}
									onClick={() =>
										setState(_state => ({
											..._state,
											pageSectionToEdit: pageSection,
										}))
									}
								>
									<Edit />
								</IconButton>
							</Tooltip>
							<Tooltip title='Delete section'>
								<IconButton
									size='small'
									className={clsx(
										pageSectionStyles.icon,
										pageSectionStyles.deleteIcon
									)}
									onClick={confirmDeleteModal.open}
								>
									<Delete />
								</IconButton>
							</Tooltip>
						</div>

						<PageSectionDisplay pageSection={pageSection} />

						<div
							className={clsx(
								pageSectionStyles.iconContainer,
								pageSectionStyles.addIconContainer,
								pageSectionStyles.addIconContainerBottom,
								(hideAddSectionButtons || isDragging) &&
									pageSectionStyles.hidden
							)}
							style={{ opacity: isHovering ? 1 : 0 }}
						>
							<Tooltip title='Add a section'>
								<IconButton
									size='small'
									className={pageSectionStyles.icon}
									onClick={() =>
										openAddSectionToolbarAtIndex(pageSection.sortOrder + 1)
									}
								>
									<Add />
								</IconButton>
							</Tooltip>
						</div>
					</>
				)}
			</div>

			<ConfirmDialog
				{...confirmDeleteModal.props}
				body={
					<>
						<div>Are you sure you want to delete this section?</div>
						<p>This action cannot be undone.</p>
					</>
				}
				confirm={() => deletePageSection({ id: pageSection.id })}
				confirmButton={{
					label: 'Delete',
					type: 'error',
				}}
			/>
		</>
	)
}
