import { Paper, Typography, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { CSSProperties, useEffect, useState } from 'react'
import { animated } from 'react-spring'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { useBoop } from '../hooks'
import { useButtonStyles } from '../styles'
import { EmptyState } from './empty-state'
import { SortableSponsorTile, SponsorTile } from './sponsor-tile'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { useUpdateSponsorSortOrderMutation } from '../graphql/autogenerate/react-query'


interface ISponsorListProps {
    sponsors?: SponsorFragment[]
    onSponsorPressed: (sponsor: SponsorFragment) => void
    title: string
    emptyStateTitle: string
    style?: CSSProperties
    addSponsorPressed?: () => void
}

export const SponsorsList = ({ title, emptyStateTitle, sponsors, onSponsorPressed, style, addSponsorPressed }: ISponsorListProps) => {
    const theme = useTheme()
    const buttonStyles = useButtonStyles()
    const hoverBoop = useBoop({ rotation: 15, timing: 100, })

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )
    const [ activeDragSponsor, setActiveDragSponsor ] = useState<SponsorFragment>()

    const [ sortedSponsors, setSortedSponsors ] = useState(sponsors || [])
    useEffect(() => {
        setSortedSponsors(sponsors || [])
    }, [ sponsors ])

    const { mutate: updateSortOrder } = useUpdateSponsorSortOrderMutation()

    return (
        <Paper style={{ flex: 1, padding: theme.spacing(2), ...style }}>
            <Typography variant='h4'>{title}</Typography>
            {sponsors && sponsors.length > 0 ?
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 300px)', gridAutoRows: 150, columnGap: theme.spacing(1), rowGap: theme.spacing(1), marginTop: theme.spacing(2) }}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={(evt) => {
                            const { active, over } = evt

                            if (active.id !== over?.id) {
                                setSortedSponsors((items) => {
                                    const oldIndex = items.findIndex(o => o.id === active.id)
                                    const newIndex = items.findIndex(o => o.id === over?.id)

                                    updateSortOrder({ newSortOrder: newIndex, sponsorId: evt.active.id })

                                    return arrayMove(items, oldIndex, newIndex)
                                })
                            }
                        }}
                        onDragStart={evt => setActiveDragSponsor(sponsors.find(o => o.id === evt.active.id))}
                        modifiers={[ restrictToParentElement ]}
                    >
                        <SortableContext items={sortedSponsors}>
                            {sortedSponsors.map(o => <SortableSponsorTile sortingDisabled={sortedSponsors.length < 2} sponsor={o} key={o.id} onClick={() => onSponsorPressed(o)} />)}
                        </SortableContext>
                        <DragOverlay>
                            {activeDragSponsor && <SponsorTile sponsor={activeDragSponsor} />}
                        </DragOverlay>
                    </DndContext>


                    {addSponsorPressed &&
                        <div
                            onMouseEnter={hoverBoop.trigger}
                            onClick={addSponsorPressed}
                            className={buttonStyles.iconButton}
                            style={{ width: 300 }}
                        >
                            <animated.div style={hoverBoop.style}>
                                <Add />
                            </animated.div>
                        </div>
                    }
                </div>
                :
                <EmptyState
                    title={emptyStateTitle}
                    action={addSponsorPressed && {
                        buttonTitle: 'Add Sponsor',
                        onClick: addSponsorPressed,
                    }}
                />
            }
        </Paper>
    )
}