import { FormControl, InputLabel, Select, TextField, FormHelperText } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import clsx from 'clsx'
import { rest } from 'lodash'
import React, { FocusEventHandler } from 'react'
import { ISelectOption } from '.'
import { useMobileDetect } from '../../hooks'
import { useFormStyles } from '../../styles'
import { MATERIAL_FIELD_VARIANT } from './constants'
import { ReactNode } from 'react'
import { CommonProps } from '../interfaces'

interface ISelectFieldProps<OptionValueType> extends CommonProps {
    options: ISelectOption<OptionValueType>[]
    getKeyFromOptionValue: (value?: OptionValueType) => string | undefined
    onChange: (value?: ISelectOption<OptionValueType>) => void
    value: OptionValueType
    label?: string
    searchPlaceholder?: string
    error?: string
    initialError?: string
    helperText?: string
    touched?: boolean
    onBlur?: FocusEventHandler<HTMLDivElement>
    disabled?: boolean
    renderOption?: (option: ISelectOption<OptionValueType>) => ReactNode
    renderTags?: (options:  ISelectOption<OptionValueType>[]) => ReactNode
    getOptionDisabled?: (option: ISelectOption<OptionValueType>) => boolean
}

export const SelectField = <OptionValueType,> ({
    className,
    style,
    options,
    getKeyFromOptionValue,
    onChange,
    value,
    label,
    searchPlaceholder,
    error,
    initialError,
    helperText,
    touched,
    onBlur,
    disabled,
    renderOption,
    renderTags,
    getOptionDisabled,
}: ISelectFieldProps<OptionValueType>) => {
    const classes = useFormStyles()

    const { isMobile } = useMobileDetect()

    return (
        <FormControl
            className={clsx(classes.margin, classes.root, className)}
            variant={MATERIAL_FIELD_VARIANT}
            error={(touched && !!error) || !!initialError}
            disabled={disabled}
            style={style}
        >
            {isMobile() ?
                <>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        variant={MATERIAL_FIELD_VARIANT}
                        onChange={(ev) => {
                            const option = options.find(o => o.key === ev.currentTarget.value)
                            onChange(option)
                        }}
                        label={label}
                        native
                    >
                        <option></option>
                        {options.map(option => <option key={option.key} value={option.key}>{option.label}{option.group ? ` (${option.group})` : ''}</option>)}
                    </Select>
                </>
                :
                <Autocomplete
                    options={options}
                    onBlur={onBlur}
                    getOptionLabel={(option) => option.label || ''}
                    value={options.find(o => o.key == getKeyFromOptionValue(value)) || null}
                    getOptionSelected={(option) => option.key == getKeyFromOptionValue(value)}
                    groupBy={(option) => option.group || ''}
                    onChange={(_, option) => onChange(option || undefined)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant={MATERIAL_FIELD_VARIANT}
                            error={(touched && !!error) || !!initialError}
                            disabled={disabled}
                        />
                    )}
                    renderOption={(option, { inputValue }) => {
                        if (renderOption) return renderOption(option)
                        
                        const matches = match(option.label, inputValue)
                        const parts = parse(option.label, matches)

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        )
                    }}
                    renderTags={renderTags}
                    getOptionDisabled={getOptionDisabled}
                />
            }

            <FormHelperText>{error || helperText || ' '}</FormHelperText>
        </FormControl>
    )
}