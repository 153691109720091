import { Box, Typography, useTheme } from '@material-ui/core'
import { HorizontalSplit, School } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { CityHall } from '../images'
import { ICalendarDispatchProp } from './calendar'
import { ICalendarSidebarCalendarsListRowProps, MemoizedCalendarSidebarCalendarsListRow } from './calendar-sidebar-calendars-list-row'
import { IconTypeDisplay } from './icon-type-display'
import Fuse from 'fuse.js'
import { IDivisionCalendars } from './calendar-sidebar-calendars-list'
import { ICalendarState } from '../stores/calendar'
import { useAppState } from '../hooks'
import { useSchoolContext } from '../stores/school'

// Need to include calendarGroups so users can search for calendar groups just like they search for individual calendars
interface ICalendarSidebarCalendarsListFilterResultsProps extends ICalendarDispatchProp, Pick<ICalendarState, 'calendarGroups'> {
    divisions: IDivisionCalendars[]
    searchTerm: string
}

interface ICalendarsListItem extends Omit<ICalendarSidebarCalendarsListRowProps, 'dispatch'> {
    searchTerm: string
    disableFavoriting?: boolean
}

const CalendarSidebarCalendarsListFilterResults = ({ searchTerm, divisions, dispatch, calendarGroups }: ICalendarSidebarCalendarsListFilterResultsProps) => {
    const theme = useTheme()

    const { state: { currentUser } } = useAppState()
    const { state: { currentUserPermissions } } = useSchoolContext()

    const calendarsFuse = useMemo(() => {
        const _calendars: ICalendarsListItem[] = []

        divisions.filter(o => !o.disabled).forEach(division => {
            _calendars.push(({
                id: division.group.calendar.id,
                title:
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {division.schoolwide ?
                            <CityHall height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} /> :
                            <School height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} />
                        }
                        {division.name}
                    </span>,
                calendarIds: [ division.group.calendar.id ],
                color: theme.palette.primary.main,
                searchTerm: `${division.name} ${division.schoolwide ? 'schoolwide school' : ''}`,
                disableFavoriting: true,
            }))

            division.groupCategories.forEach(({ groups, iconBackgroundColor, iconType, name: categoryName }) => {
                groups.forEach(group => {
                    _calendars.push({
                        id: group.calendar.id,
                        title:
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {group.iconType ?
                                    <IconTypeDisplay type={group.iconType} size={20} style={{ marginRight: 5, color: iconBackgroundColor || theme.palette.grey[ 500 ] }} /> :
                                    <IconTypeDisplay type={iconType} size={20} style={{ marginRight: 5, color: iconBackgroundColor || theme.palette.grey[ 500 ] }} />
                                }
                                <div>
                                    <div>{group.groupName}</div>
                                    <div style={{ ...theme.typography.caption, lineHeight: 1, color: theme.palette.grey[ 400 ], paddingLeft: 2 }}>{division.name}</div>
                                </div>
                            </div>,
                        calendarIds: [ group.calendar.id ],
                        color: iconBackgroundColor || theme.palette.grey[ 500 ],
                        searchTerm: `${group.groupName} ${categoryName} ${division.name}`,
                    })
                })
            })
        })

        if (currentUser?.appAdministrator || currentUserPermissions.schoolwideAdmin) {
            _calendars.push(...calendarGroups.map<ICalendarsListItem>(calendarGroup => ({
                id: calendarGroup.id,
                title:
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <HorizontalSplit height={20} width={20} style={{ marginBottom: 4, marginRight: 1, marginLeft: -2, color: theme.palette.primary.main }} />
                        {calendarGroup.title}
                    </span>,
                calendarIds: calendarGroup.calendarGroupCalendars.nodes.map(o => {
                    if (!o.calendar?.id) throw new Error('CalendarSidebarCalendarsListSchoolCalendars: encountered a CalendarGroup_Calendar without a calendar id.')
                    return o.calendar.id
                }),
                color: theme.palette.primary.main,
                searchTerm: calendarGroup.title,
                disableFavoriting: true,
            })))
        }

        return new Fuse(_calendars, { keys: [ 'searchTerm' ], threshold: .4 })
    }, [ divisions, calendarGroups ])

    const searchResults = calendarsFuse.search(searchTerm).map(o => o.item)

    return (
        <>
            {searchResults.map(calendar => (
                <MemoizedCalendarSidebarCalendarsListRow
                    key={calendar.id}
                    {...calendar}
                    dispatch={dispatch}
                    persistAddRemoveFavoriteIcons
                    disableFavoriting={calendar.disableFavoriting}
                />
            ))}
            {searchResults.length === 0 &&
                <Box px={4}>
                    <Typography variant='caption'>No results.</Typography>
                </Box>
            }
        </>
    )
}

export const MemoizedCalendarSidebarCalendarsListFilterResults = React.memo(CalendarSidebarCalendarsListFilterResults)
