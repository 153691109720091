import { Box } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { ICalendarDispatchProp } from './calendar'
import { MemoizedCalendarSidebarCalendarsListFavorites } from './calendar-sidebar-calendars-list-favorites'
import { MemoizedCalendarSidebarCalendarsListAllCalendars } from './calendar-sidebar-calendars-list-all-calendars'
import { MemoizedCalendarSidebarCalendarsListSchoolCalendars } from './calendar-sidebar-calendars-list-school-calendars'
import { MemoizedCalendarSidebarCalendarsListFilter } from './calendar-sidebar-calendars-list-filter'
import { MemoizedCalendarSidebarCalendarsListFilterResults } from './calendar-sidebar-calendars-list-filter-results'
import { IconType } from '../graphql/autogenerate/schemas'
import { SchoolDetailFragment } from '../graphql/autogenerate/operations'
import { ICalendarActions, ICalendarState } from '../stores/calendar'
import { ISchoolState, useSchoolContext } from '../stores/school'
import { useAppState } from '../hooks'

interface ICalendarSidebarCalendarsListProps
	extends ICalendarDispatchProp,
		Pick<ICalendarState, 'calendarGroups'> {
	divisions: SchoolDetailFragment['divisions']['nodes']
	refetchCalendarGroups: ICalendarActions['refetch']['calendarGroups']
	groups: ISchoolState['groups']
}

const CalendarSidebarCalendarsList = ({
	dispatch,
	divisions,
	calendarGroups,
	refetchCalendarGroups,
	groups,
}: ICalendarSidebarCalendarsListProps) => {
	const [calendarSearchTerm, setCalendarSearchTerm] = useState('')
	const handleCalendarSearchTermChange = useCallback(setCalendarSearchTerm, [])

	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: { currentUserPermissions },
	} = useSchoolContext()

	/* 
        Map the query data to the view we currently support.
        The main difference between what the data model supports and the view currently supports is multiple calendars per group.
        The data model allows for multiple calendars per group, but the UI currently only supports a one-to-one between Calendars and Groups.
    */
	const divisionCalendars: IDivisionCalendars[] = React.useMemo(() => {
		if (!divisions) return []

		return divisions.map<IDivisionCalendars>(division => {
			// Protect against loss of data integrity (e.g. a division's group gets deleted or its group has the wrong number of calendars)
			if (!division.group)
				throw new Error(
					`Division (${division.name}) missing its group or the group data is invalid.`
				)
			// For now we only support one calendar per group.
			if (division.group.calendars.nodes.length !== 1)
				throw new Error(
					`Division's (${division.name}) group should have exactly 1 calendar but it has ${division.group.calendars.nodes.length}.`
				)

			const groupCategories = division.groupCategories.nodes.map(
				groupCategory => {
					const groups = groupCategory.groups.nodes
						.filter(
							g =>
								currentUserPermissions.schoolwideAdmin ||
								currentUser?.appAdministrator ||
								currentUserPermissions.groups.nodes.some(
									o =>
										(o.groupId === g.id || o.groupId === division.group?.id) &&
										o.admin
								)
						)
						.map(group => {
							// For now we only support one calendar per group.
							if (group.calendars.nodes.length !== 1)
								throw new Error(
									`Group (${group.groupName}) should have exactly 1 calendar but it has ${group.calendars.nodes.length}.`
								)

							return {
								groupName: group.groupName,
								calendar: {
									id: group.calendars.nodes[0].id,
								},
								iconType: group.iconType,
							}
						})

					return {
						id: groupCategory.id,
						name: groupCategory.name,
						iconType: groupCategory.iconType,
						iconBackgroundColor: groupCategory.iconBackgroundColor,
						groups,
					}
				}
			)

			return {
				id: division.id,
				name: division.name,
				schoolwide: division.schoolwide,
				group: {
					groupName: division.group.groupName,
					iconType: division.group.iconType,
					calendar: {
						id: division.group.calendars.nodes[0].id,
					},
				},
				groupCategories,
				disabled: !Boolean(
					currentUserPermissions.schoolwideAdmin ||
						currentUser?.appAdministrator ||
						currentUserPermissions.groups.nodes.some(
							o => o.groupId === division.group?.id && o.admin
						)
				),
			}
		})
	}, [divisions])

	return (
		<>
			<MemoizedCalendarSidebarCalendarsListFilter
				dispatch={dispatch}
				calendarSearchTerm={calendarSearchTerm}
				handleSearchTermChange={handleCalendarSearchTermChange}
			/>

			{calendarSearchTerm && calendarSearchTerm.length > 0 ? (
				<MemoizedCalendarSidebarCalendarsListFilterResults
					divisions={divisionCalendars}
					dispatch={dispatch}
					searchTerm={calendarSearchTerm}
					calendarGroups={calendarGroups}
				/>
			) : (
				<>
					{(currentUser?.appAdministrator ||
						currentUserPermissions.schoolwideAdmin ||
						divisionCalendars.filter(o => !o.disabled).length > 0) && (
						<MemoizedCalendarSidebarCalendarsListSchoolCalendars
							divisions={divisions}
							divisionCalendars={divisionCalendars}
							dispatch={dispatch}
							calendarGroups={calendarGroups}
							refetchCalendarGroups={refetchCalendarGroups}
							groups={groups}
						/>
					)}

					<Box aria-hidden m={2} />

					<MemoizedCalendarSidebarCalendarsListFavorites
						divisions={divisionCalendars}
						dispatch={dispatch}
					/>

					<Box aria-hidden m={2} />

					<MemoizedCalendarSidebarCalendarsListAllCalendars
						divisions={divisionCalendars}
						dispatch={dispatch}
					/>

					<Box aria-hidden m={2} />
				</>
			)}
		</>
	)
}
export const MemoizedCalendarSidebarCalendarsList = React.memo(
	CalendarSidebarCalendarsList
)

export interface IDivisionCalendars {
	id: string
	name: string
	schoolwide?: boolean | null
	group: IGroupCalendar
	groupCategories: IGroupCategoryCalendars[]
	disabled?: boolean
}

export interface IGroupCategoryCalendars {
	id: string
	name: string
	iconType: IconType
	iconBackgroundColor?: string | null
	groups: IGroupCalendar[]
}

export interface IGroupCalendar {
	groupName: string
	calendar: {
		id: string
	}
	iconType?: IconType | null
}
