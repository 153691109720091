import tinycolor from 'tinycolor2'
import { UserType } from '../graphql/autogenerate/schemas'

export const getLabelForUserType = (userType: UserType, singular?: boolean): string => {
    switch (userType) {
        case UserType.Parent:
            return singular ? 'Parent' : 'Parents'
        case UserType.Student:
            return singular ? 'Student' : 'Students'
        case UserType.FacultyStaff:
            return 'Faculty/Staff'
        case UserType.Guest:
            return singular ? 'Guest' : 'Guests'
    }
}

export const isDescendant = (parent: Element, child: Element) => {
    var node = child.parentNode
    while (node != null) {
        if (node === parent) {
            return true
        }
        node = node.parentNode
    }
    return false
}

export const getContrastTextColor = (color: string) => tinycolor.mostReadable(color, [ '#fff', '#000' ], { includeFallbackColors: true }).toHexString()

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const locationHashToObject = (hash: string): { [ key: string ]: string } => {
    if (!hash || !hash.length) return {}
    return hash.substr(1)
        .split('&')
        .map(v => v.split('='))
        .reduce((pre, [ key, value ]) => ({ ...pre, [ key ]: value }), {})
}

interface ISortable {
    sortOrder: number
}

export const sortBySortOrder = <T extends ISortable,> (list: T[]): T[] => {
    list.sort((a, b) => {
        if (a.sortOrder > b.sortOrder) return 1
        if (a.sortOrder < b.sortOrder) return -1
        return 0
    })
    return list
}

export const convertRtfToPlainText = (html: string) => {

    // Create a new div element
    var tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || ""
}