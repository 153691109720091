import { useTheme } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import dayjs from 'dayjs'
import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import { useNotificationDetailQuery } from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { LinkButton } from './link-button'
import { NewsletterDisplay } from './newsletter-display'
import { NotificationMessage } from './notification-message'
import { SchoolNavBar } from './school-nav-bar'
import { AttachmentCell } from './attachment-cell'
import { EmbedDisplay } from './embed-display'

interface INotificationDetailProps {
	selectedNotificationId: string
	onClosePressed: () => void
}

export const NotificationDetail = ({
	selectedNotificationId,
	onClosePressed,
}: INotificationDetailProps) => {
	const theme = useTheme()

	const { data } = useHandleReactQuery(
		useNotificationDetailQuery(
			{ id: selectedNotificationId },
			{ refetchInterval: REFETCH_INTERVAL }
		)
	)
	const notification = data?.notification

	const notInitialRender = useRef(false)
	const location = useLocation()
	useEffect(() => {
		const homePressed = location.pathname.split('/').pop() === 'notifications'
		if (notInitialRender.current && homePressed) onClosePressed()
		notInitialRender.current = true
	}, [location])

	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				paddingBottom: 55,
				backgroundColor: 'white',
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
				overflow: 'hidden',
			}}
		>
			<SchoolNavBar
				style={{
					padding: `0px ${theme.spacing(1)}px`,
					justifyContent: 'flex-start',
				}}
			>
				<div
					style={{ minWidth: 100, display: 'flex', alignItems: 'center' }}
					onClick={onClosePressed}
				>
					<ArrowBackIos />
					<span>Back</span>
				</div>
			</SchoolNavBar>

			<div
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					minHeight: 0,
					overflowY: 'auto',
					padding: theme.spacing(1),
				}}
			>
				{notification && (
					<>
						<NotificationMessage
							key={notification.id}
							style={{ marginBottom: theme.spacing(1) }}
							message={notification.message}
							displayTime={dayjs(notification.sendDate || undefined)}
							groupIds={notification.notificationGroups.nodes.map(o => o.group!.id)}
							userTypes={(notification.userTypes as UserType[]) || []}
							sent
						/>

						{notification.body && (
							<div
								style={{ margin: `${theme.spacing(2)}px 0px` }}
								dangerouslySetInnerHTML={{ __html: notification.body }}
							/>
						)}

						{notification.buttons?.buttons?.map(o => (
							<LinkButton button={o} />
						))}

						{notification.notificationFiles?.nodes?.map(o => {
							const file = o.file
							if (file) {
								return (
									<AttachmentCell
										key={file.id}
										label={file.label || file.filename}
										temporaryDownloadUrl={file.temporaryDownloadUrl}
										type={file.type}
									/>
								)
							}
							return null
						})}

						{notification.embedUrl && <EmbedDisplay url={notification.embedUrl} mobilePreview />}
					</>
				)}

				{notification?.newslettersByNotificationNewsletterNotificationIdAndNewsletterId.nodes.map(
					newsletter => (
						<NewsletterDisplay key={newsletter.id} newsletter={newsletter} />
					)
				)}
			</div>
		</div>
	)
}
