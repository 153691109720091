import { Button, Paper, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useAppState, useAuth } from '../hooks'
import logo from '../images/logo.svg'

export const SchoolNotFound = () => {
    const theme = useTheme()
    const { logout } = useAuth()
    const { state: { authed } } = useAppState()

    const phoneAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Paper style={{ maxWidth: 750, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(4), ...phoneAndDown ? { flex: 1 } : {} }}>
                <a href='https://www.legitapps.com/schools'><img src={logo} alt='Legit Apps logo' style={{ maxWidth: 75, marginBottom: theme.spacing(4) }} /></a>

                <Typography variant='h5' align='center'>🕵️‍♂️ School Not Found</Typography>

                <Typography style={{ marginTop: theme.spacing(4), width: '100%' }}>We couldn't locate a school with this ID.</Typography>

                <Typography style={{ marginTop: theme.spacing(2) }}>If you're a parent, faculty/staff member, or community member looking for your school, please download your school's app from the App Store or Google Play, or contact your school administrator to get your school's join link.</Typography>

                <Typography style={{ marginTop: theme.spacing(2) }}>If you're a school administrator, contact us at <a href='mailto:info@legitapps.com'>info@legitapps.com</a> to get access to your school or <a href='https://www.legitapps.com/schools'>learn about how Legit Apps can help</a> streamline communication at your school!</Typography>
            </Paper>
        </div>
    )
}