import { FormControl, FormHelperText, FormControlLabel, Switch, useTheme, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { useField } from 'formik'
import { CSSProperties } from 'react'
import { IField } from '.'

interface IFormikSwitchField {
    field: IField
    style?: CSSProperties
    tooltip?: string
}

export const FormikSwitchField = ({ field, style, tooltip }: IFormikSwitchField) => {

    const [ formikField, meta, helpers ] = useField<boolean>(field)

    const theme = useTheme()

    return (
        <div style={style}>
            <FormControl error={meta.touched && Boolean(meta.error)} disabled={field.disabled} >
                <FormControlLabel
                    style={{ marginRight: 0 }}
                    control={
                        <Switch
                            checked={formikField.value}
                            onChange={(_, value) => helpers.setValue(value)}
                            name={field.name}
                            color='primary'
                        />
                    }
                    label={field.label}
                />
                <FormHelperText>{meta.touched && meta.error}</FormHelperText>
            </FormControl>
            {tooltip &&
                <Tooltip title={tooltip}>
                    <InfoOutlined style={{ color: theme.palette.grey[ 500 ], cursor: 'pointer', width: 15, height: 15 }} />
                </Tooltip>
            }
        </div>
    )
}