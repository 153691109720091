import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core'
import { useCalendarContext, CalendarActionType } from '../stores/calendar'
import { MemoizedCalendarWeekView } from './calendar-week-view'


const useStyles = makeStyles(() =>
    createStyles({
        container: {
            flex: 1,
        },
    })
)

const CalendarViews = () => {
    const { state: { events, viewMode }, dispatch } = useCalendarContext()

    const styles = useStyles()

    return (
        <div
            className={clsx(styles.container)}
            onMouseEnter={() => dispatch({ type: CalendarActionType.enableDateScrolling })}
        >
            {viewMode === 'week' && <MemoizedCalendarWeekView events={events} dispatch={dispatch} />}
        </div>
    )
}

export const MemoizedCalendarViews = React.memo(CalendarViews)