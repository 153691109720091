
import { GroupCategoryListFieldsFragment, GroupListFieldsFragment } from '../graphql/autogenerate/operations'
import { Box, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core'
import { IconTypeDisplay } from './icon-type-display'
import Sortable from 'sortablejs'
import { useCallback, useState } from 'react'
import { useHandleReactQueryMutation } from '../hooks'
import { GroupCell } from './group-cell'
import { useUpdateGroupSortOrderMutation } from '../graphql/autogenerate/react-query'

const useStyles = makeStyles(() =>
    createStyles({
        dropTarget: {
            borderWidth: '4px !important',
        }
    })
)

interface IAdminGroupCategoryDetailProps {
    category: GroupCategoryListFieldsFragment
    setGroupToEdit: (group: GroupListFieldsFragment) => void
}
export const AdminGroupCategoryDetail = ({ category, setGroupToEdit }: IAdminGroupCategoryDetailProps) => {
    const theme = useTheme()
    const styles = useStyles()

    const { mutate: updateGroupSortOrder } = useHandleReactQueryMutation(useUpdateGroupSortOrderMutation())

    const [ sortingActive, setSortingActive ] = useState(false)

    const sortableListRef = useCallback((element: HTMLDivElement) => {
        let idsOrder = category.groups.nodes.map(o => o.id)
        if (element) {
            Sortable.create(element, {
                onStart: () => setSortingActive(true),
                animation: 150,
                easing: 'cubic-bezier(1, 0, 0, 1)',
                ghostClass: styles.dropTarget,
                store: {
                    set: async (sortable) => {
                        setSortingActive(false)
                        const ids = sortable.toArray()
                        // Check to see if any items were moved around
                        const shouldUpdateSortOrders = ids.some((id, index) => idsOrder[ index ] !== id)
                        if (shouldUpdateSortOrders) {
                            ids.forEach((groupId, sortOrder) => updateGroupSortOrder({ groupId, sortOrder }))
                            idsOrder = ids
                        }
                    },
                    get: () => {
                        return []
                    }
                },
            })
        }
    }, [])

    return (
        <div key={category.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: theme.spacing(3) }}>
            <Box
                py={.5}
                px={2}
                display='flex'
                alignItems='center'
                style={{
                    backgroundColor: category.iconBackgroundColor || theme.palette.grey[ 500 ],
                    color: 'white',
                    border: `1px solid ${category.iconBackgroundColor || theme.palette.grey[ 500 ]}`,
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <IconTypeDisplay size={24} type={category.iconType} style={{ marginRight: theme.spacing(1) }} />

                <Typography variant='h6' style={{ display: 'flex' }}>
                    {category.name}
                </Typography>
            </Box>

            <Box pb={2} />

            <div ref={sortableListRef} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {category.groups.nodes.map(group => (
                    <GroupCell
                        key={group.id}
                        group={group}
                        category={category}
                        sortingActive={sortingActive}
                        onClick={setGroupToEdit}
                    />
                ))}
            </div>
        </div>
    )
}