import {
	makeStyles,
	createStyles,
	useTheme,
	Button,
	Box,
	Typography,
	Card,
	CardActionArea,
	CardContent,
	Grid,
	AppBar,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	InputBase,
	FormControlLabel,
	Radio,
	RadioGroup,
	Switch,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import { matchSorter } from 'match-sorter'
import { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { SchoolListFieldsFragment } from '../graphql/autogenerate/operations'
import {
	useCreateSchoolMutation,
	useSchoolListQuery,
} from '../graphql/autogenerate/react-query'
import {
	useAppState,
	useAuth,
	useHandleReactQuery,
	useHandleReactQueryMutation,
} from '../hooks'
import { SchoolList } from './admin-school-list'
import { FormikTextInput, StateSelectField, FormikHexColorInput } from './forms'
import { VALIDATION_REGEX } from './forms/constants'
import { Modal, useModal } from './modal'
import * as Yup from 'yup'
import { School, AccountCircle, Search } from '@material-ui/icons'
import tinycolor from 'tinycolor2'
import { useButtonStyles } from '../styles'
import { ToggleButton } from '@material-ui/lab'

const useStyles = makeStyles(theme =>
	createStyles({
		padding: {
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(15),
			},
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(2),
			},
		},
		viewTitle: {
			[theme.breakpoints.up('xs')]: {
				...theme.typography.h3,
			},
			[theme.breakpoints.down('xs')]: {
				...theme.typography.h5,
			},
		},
		cardTitle: {
			[theme.breakpoints.up('xs')]: {
				...theme.typography.h5,
			},
			[theme.breakpoints.down('xs')]: {
				...theme.typography.h6,
			},
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: tinycolor(theme.palette.common.white)
				.setAlpha(0.15)
				.toRgbString(),
			'&:hover': {
				backgroundColor: tinycolor(theme.palette.common.white)
					.setAlpha(0.25)
					.toRgbString(),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
	})
)

export const Schools = () => {
	const {
		state: { currentUser, authed },
	} = useAppState()
	const { logout } = useAuth()

	const theme = useTheme()
	const styles = useStyles()
	const buttonStyles = useButtonStyles()

	const navigate = useNavigate()

	const { props, open, close } = useModal()
	const { mutate: createSchool } = useHandleReactQueryMutation(
		useCreateSchoolMutation()
	)

	const [schools, setSchools] = useState<SchoolListFieldsFragment[]>()

	const [query, setQuery] = useState({ archived: false, expiringSoon: false })
	const schoolListResult = useHandleReactQuery(
		useSchoolListQuery(
			{
				archived: query.archived,
				...(query.expiringSoon
					? {
							filter: {
								iosPushCertExpiry: {
									lessThan: dayjs().add(1, 'M').toString(),
								},
							},
					  }
					: {}),
			},
			{
				onSuccess: data => {
					if (schoolSearchTerm && schoolSearchTerm.length && data.schools?.nodes) {
						setSchools(
							matchSorter(data.schools.nodes, schoolSearchTerm, {
								keys: ['name', 'city', 'state', 'id'],
							})
						)
					} else {
						setSchools(data.schools?.nodes)
					}
				},
			}
		)
	)

	const [schoolSearchTerm, setSchoolSearchTerm] = useState('')
	useDebounce(
		() => {
			if (!schoolSearchTerm || schoolSearchTerm.length === 0 || !schools) {
				setSchools(schoolListResult.data?.schools?.nodes)
				return
			}
			setSchools(
				matchSorter(schools, schoolSearchTerm, {
					keys: ['name', 'city', 'state', 'id'],
				})
			)
		},
		300,
		[schoolSearchTerm]
	)

	useEffect(() => {
		// Reset timezone to user's local timezone
		dayjs.tz.guess()
	}, [])

	// New School form
	const initialValues = {
		schoolName: '',
		schoolCity: '',
		schoolState: '',
		primaryColor: '',
		secondaryColor: '',
		timeZone: 'America/New_York',
	}

	// User profile
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	if (!currentUser)
		throw new Error(
			'Cannot view a list of schools without being logged in and having a value for currentUser.'
		)

	// If this user isn't a super admin and they only have access to one school (the vast majority of cases), take them to the detail view for the one school they have access to.
	if (currentUser.schoolSlugs?.length === 1 && !currentUser.appAdministrator) {
		return <Navigate to={`/school/${currentUser.schoolSlugs[0]}`} replace />
	}

	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
			}}
		>
			<AppBar position='static'>
				<Toolbar>
					<School color='inherit' style={{ marginRight: theme.spacing(2) }} />
					<Typography variant='h6'>Schools</Typography>
					{currentUser.appAdministrator && (
						<>
							<div className={styles.search}>
								<div className={styles.searchIcon}>
									<Search />
								</div>
								<InputBase
									autoFocus
									placeholder='Search schools…'
									classes={{
										root: styles.inputRoot,
										input: styles.inputInput,
									}}
									value={schoolSearchTerm}
									onChange={evt => setSchoolSearchTerm(evt.currentTarget.value)}
								/>
							</div>
							<Button
								variant='outlined'
								className={buttonStyles.white}
								style={{ marginRight: theme.spacing(2) }}
								onClick={open}
							>
								Add School
							</Button>
						</>
					)}

					<div style={{ flex: 1 }} />
					<div>
						<IconButton
							color='inherit'
							onClick={evt => setAnchorEl(evt.currentTarget)}
						>
							<AccountCircle />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
						>
							<MenuItem onClick={logout}>Logout</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>

			{currentUser.appAdministrator ? (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: theme.spacing(1),
						}}
					>
						<RadioGroup
							name='active'
							value={query.archived ? 'archived' : 'active'}
							onChange={e => {
								setQuery(q => ({
									...q,
									archived: e.currentTarget?.value === 'archived',
								}))
							}}
							style={{ flexDirection: 'row' }}
						>
							<FormControlLabel
								value='active'
								control={<Radio />}
								label='Active'
								labelPlacement='end'
							/>
							<FormControlLabel
								value='archived'
								control={<Radio />}
								label='Archived'
								labelPlacement='end'
							/>
						</RadioGroup>
						<FormControlLabel
							style={{ marginRight: 0 }}
							control={
								<Switch
									checked={query.expiringSoon}
									onChange={(_, value) => setQuery(q => ({ ...q, expiringSoon: value }))}
									name='expiringSoon'
									color='primary'
								/>
							}
							label='Push certs expiring within 1 month'
						/>
						<div style={{ margin: `0px ${theme.spacing(2)}px` }}>
							Total: {schoolListResult.data?.schools?.totalCount}
						</div>
					</div>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							margin: theme.spacing(2),
						}}
					>
						{schoolListResult.isLoading ? (
							'Loading...'
						) : (
							<SchoolList
								schools={schools || []}
								onSchoolPressed={school => navigate(`/school/${school.slug}`)}
							/>
						)}
					</div>

					<Formik
						// prefixing input names with 'school' so people's automcomplete don't fill in personal 'name' or 'city'
						initialValues={initialValues}
						validationSchema={Yup.object({
							schoolName: Yup.string().required('Required'),
							schoolCity: Yup.string().required('Required'),
							schoolState: Yup.string().required('Required'),
							primaryColor: Yup.string()
								.nullable()
								.matches(
									VALIDATION_REGEX.hexCode,
									'Must be a valid hex color (e.g. #F5B517).'
								),
							secondaryColor: Yup.string()
								.nullable()
								.matches(
									VALIDATION_REGEX.hexCode,
									'Must be a valid hex color (e.g. #F5B517).'
								),
							timeZone: Yup.string().required('Required'),
						})}
						onSubmit={(values, actions) => {
							createSchool(
								{
									name: values.schoolName,
									city: values.schoolCity,
									state: values.schoolState,
									primaryColor: values.primaryColor,
									secondaryColor: values.secondaryColor,
									timeZone: values.timeZone,
								},
								{
									onSuccess: results => {
										if (results?.createSchool?.school) {
											navigate(`/school/${results.createSchool.school.slug}`)
										}
									},
								}
							)
						}}
					>
						{formikProps => (
							<Form>
								<Modal
									{...props}
									title='New School'
									size='sm'
									dividers
									dismissible
									actions={
										<>
											<Button type='button' onClick={close} color='primary'>
												Cancel
											</Button>
											<Button
												type='submit'
												onClick={formikProps.submitForm}
												color='primary'
											>
												Create
											</Button>
										</>
									}
									afterClose={formikProps.resetForm}
								>
									<Grid container>
										<Grid item container xs={12}>
											<FormikTextInput
												autoFocus
												fieldProps={{
													name: 'schoolName',
													label: 'School Name',
												}}
											/>
										</Grid>
										<Grid item container xs={12} sm={6}>
											<FormikTextInput
												fieldProps={{ name: 'schoolCity', label: 'City' }}
											/>
										</Grid>
										<Grid item container xs={12} sm={6}>
											<StateSelectField
												fieldProps={{
													name: 'schoolState',
													label: 'State',
													placeholder: 'Select a state',
												}}
											/>
										</Grid>
										<Grid item container xs={12} sm={6}>
											<FormikHexColorInput
												fieldProps={{
													name: 'primaryColor',
													label: 'Primary Color',
												}}
											/>
										</Grid>
										<Grid item container xs={12} sm={6}>
											<FormikHexColorInput
												fieldProps={{
													name: 'secondaryColor',
													label: 'Secondary Color',
												}}
											/>
										</Grid>
										<Grid item container xs={12} sm={6}>
											<FormikTextInput
												fieldProps={{ name: 'timeZone', label: 'Timezone' }}
											/>
										</Grid>
									</Grid>
								</Modal>
							</Form>
						)}
					</Formik>
				</>
			) : (
				<Box
					className={styles.padding}
					height='100%'
					display='flex'
					flexDirection='column'
				>
					<Box marginBottom={2}>
						<Typography className={styles.viewTitle}>Select your school</Typography>
					</Box>
					<Grid container spacing={2}>
						{schoolListResult.data?.schools?.nodes
							.filter(o => currentUser.schoolSlugs?.includes(o.slug))
							.map(school => (
								<Grid
									item
									xs={12}
									lg={4}
									key={school.id}
									component={Link}
									to={`/school/${school.slug}`}
									style={{ width: '100%' }}
								>
									<Card style={{ width: '100%' }}>
										<CardActionArea>
											{/* <CardMedia
                                        style={{ height: 140 }}
                                        image={school.coverImgixPath || school.logoImgixPath || ' '}
                                        title='Contemplative Reptile'
                                    /> */}
											<CardContent>
												<Typography gutterBottom className={styles.cardTitle}>
													{school.name}
												</Typography>
												<Typography variant='body2' color='textSecondary' component='p'>
													{school.city}, {school.state}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							))}
					</Grid>
				</Box>
			)}
		</div>
	)
}
