import { useState } from 'react'
import { HexColorInput } from './hex-color-input'

interface IStandaloneHexColorInputProps {
    value: string
    onChange: (value: string) => void
    label?: string
    hideSwatch?: boolean
    hideHelperTextWhenEmpty?: boolean
}
export const StandaloneHexColorInput = (props: IStandaloneHexColorInputProps) => {
    const [ touched, setTouched ] = useState(false)

    return (
        <HexColorInput
            {...props}
            fieldProps={{ name: `${props.label}-colorPicker`, label: `${props.label}` }}
            onBlur={() => setTouched(true)}
            touched={touched}
            error={(!props.value) ? 'Must be a valid hex color (e.g. #F5B517).' : undefined}
        />
    )
}