import { createStyles, makeStyles } from '@material-ui/core'

export const useMobileStyles = makeStyles(theme =>
    createStyles({
        navBar: {

        },
        tabBar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: theme.palette.grey[ 500 ],
            textAlign: 'center',
            alignItems: 'center',
        },
        tabBarSelected: {
            color: theme.palette.primary.main,
        },
    })
)