import { IconButton, Tooltip, TooltipProps } from '@material-ui/core'
import { CSSProperties } from 'react'
import { animated } from 'react-spring'
import { useBoop } from '../hooks'

interface IAnimatedIconButtonProps {
    tooltipProps?: Omit<TooltipProps, 'children'>
    icon: JSX.Element
    onClick: () => void
    className?: string
    style?: CSSProperties
}

export const AnimatedIconButton = ({ tooltipProps, icon, onClick, ...rest }: IAnimatedIconButtonProps) => {

    const hoverBoop = useBoop({ rotation: 15, timing: 75, })
    const onClickBoop = useBoop({ scale: 1.3, timing: 100, springConfig: { tension: 300, friction: 30 } })

    const button =
        <IconButton
            {...rest}
            onPointerEnter={hoverBoop.trigger}
            onClick={() => {
                onClickBoop.trigger()
                onClick()
            }}
        >
            <animated.span style={{ ...hoverBoop.style, display: 'flex' }}>
                <animated.span style={{ ...onClickBoop.style, display: 'flex' }}>
                    {icon}
                </animated.span>
            </animated.span>
        </IconButton>

    if (tooltipProps) {
        return (
            <Tooltip {...tooltipProps}>
                {button}
            </Tooltip>
        )
    } else {
        return button
    }
}