import { createStyles, makeStyles } from '@material-ui/core'

export const useMuiClassOverrides = makeStyles(theme =>
    createStyles({
        label: {
            // padding: `0px ${theme.spacing(1)}px`,
            paddingLeft: theme.spacing(.5),
            paddingRight: theme.spacing(1)
        }
    })
)