import { Tooltip, useTheme, Button } from '@material-ui/core'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppState } from '../hooks'
import { GoogleCalendar } from '../images'
import { CalendarActionType, useCalendarContext } from '../stores/calendar'
import { useSchoolContext } from '../stores/school'
import { gcsModalAtom } from './app-routes'
import { Gcs, GcsStatus } from './gcs'
import {  useModalWithAtom } from './modal'

export const CalendarHeaderGcs = () => {
    const theme = useTheme()
    const gcsModal = useModalWithAtom(gcsModalAtom)

    const location = useLocation()
    const navigate = useNavigate()

    const { dispatch } = useCalendarContext()
    useEffect(() => {
        if (gcsModal.props.isOpen) {
            dispatch({ type: CalendarActionType.disableShortcuts })
        } else {
            dispatch({ type: CalendarActionType.enableShortcuts })
        }
    }, [ gcsModal.props.isOpen ])

    const [ gcsStatus, setGcsStatus ] = useState<{ status: GcsStatus, message?: string }>()
    useEffect(() => {
        const oauthCallbackStatus = (new URLSearchParams(location.search)).get('gcs')
        if (oauthCallbackStatus) {
            try {
                const parsedStatus = JSON.parse(oauthCallbackStatus) as { status: GcsStatus, message?: string }
                gcsModal.open()
                setGcsStatus(parsedStatus)
            } catch (e) { console.log(e) }

            // Clear the gcs oauth results params from the URL (else if the user refreshed the page they'd see the message again)
            navigate('', { replace: true })
        }
    }, [])

    const { state: { currentUser } } = useAppState()
    const { state: { school: { divisions }, groups } } = useSchoolContext()

    return (
        <>
            <Tooltip title='Manage Google Calendar Sync'>
                <Button
                    variant='outlined'
                    // color='primary'
                    startIcon={<GoogleCalendar style={{ height: 24, width: 24 }} />}
                    onClick={gcsModal.open}
                    style={{ color: '#4285F4', borderColor: '#4285F4' }}
                >
                    Sync
                </Button>
            </Tooltip>

            {currentUser &&
                <Gcs
                    modalControls={gcsModal}
                    gcsStatus={gcsStatus}
                    setStatus={setGcsStatus}
                    currentUser={currentUser}
                    divisions={divisions.nodes}
                    groups={groups}
                />
            }
        </>
    )
}