import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import {
	DragHandle,
	FolderOutlined,
	LibraryBooksOutlined,
	LinkOutlined,
	PeopleAlt,
	VisibilityOffOutlined,
} from '@material-ui/icons'
import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, memo, useMemo } from 'react'
import tinycolor from 'tinycolor2'
import { ResourceFragmentFragment } from '../graphql/autogenerate/operations'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { getContrastTextColor } from '../helpers'
import { useSchoolContext } from '../stores/school'
import { useCommonStyles } from '../styles'

export const useResourceItemStyles = makeStyles(theme =>
	createStyles({
		tile: {
			padding: theme.spacing(2),
			borderRadius: theme.shape.borderRadius,
		},
		tileIconContainer: {
			display: 'flex',
			position: 'absolute',
			top: theme.spacing(2),
			right: theme.spacing(2),
			opacity: 0.8,
			color: theme.palette.grey[300],
		},
		disabledIcon: {
			position: 'absolute',
			top: theme.spacing(2),
			left: theme.spacing(2),
			opacity: 0.8,
			color: theme.palette.grey[300],
		},
	})
)

interface IResourceItemProps {
	resourceItem: ResourceFragmentFragment
	color: string
	onClick?: DetailedHTMLProps<
		HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>['onClick']
}

export const ResourceTile = memo(
	({ resourceItem, color, onClick }: IResourceItemProps) => {
		const styles = useResourceItemStyles()
		const commonStyles = useCommonStyles()
		const theme = useTheme()
		const {
			state: {
				school: { slug, schoolSetting },
			},
		} = useSchoolContext()

		const backgroundImage = useMemo(() => {
			if (!resourceItem.tileImage?.temporaryDownloadUrl) return
			const { r, g, b } = tinycolor(color).toRgb()
			const opacity = resourceItem.disableTileOverlay
				? 0
				: schoolSetting?.resourceTileOpacity || 0
			return `linear-gradient(to bottom, rgba(${r},${g},${b},.${Math.max(
				0,
				opacity - 40
			)}), rgba(${r},${g},${b},.${opacity})), url(${
				resourceItem.tileImage.temporaryDownloadUrl
			})`
		}, [
			resourceItem.tileImage,
			schoolSetting?.resourceTileOpacity,
			resourceItem.disableTileOverlay,
		])

		return (
			<div
				id={resourceItem.id}
				className={clsx(
					styles.tile,
					commonStyles.elevationOnHover,
					'resource-tile'
				)}
				onClick={ev => {
					gtag('event', 'resource_viewed', {
						event_category: slug,
						event_label: resourceItem.title,
					})
					onClick && onClick(ev)
				}}
				style={{
					backgroundColor: color,
					color: getContrastTextColor(color),
					gridColumnEnd: resourceItem.header ? 'span 2' : undefined,
					backgroundImage: backgroundImage,
					backgroundSize: 'cover',
				}}
			>
				{!resourceItem.published && (
					<VisibilityOffOutlined className={styles.disabledIcon} />
				)}
				<div className={styles.tileIconContainer}>
					{resourceItem.type === ResourceType.Link && (
						<LinkOutlined className='tile-icon' />
					)}
					{resourceItem.type === ResourceType.Folder && (
						<FolderOutlined className='tile-icon' />
					)}
					{resourceItem.type === ResourceType.Page && (
						<LibraryBooksOutlined className='tile-icon' />
					)}
					{resourceItem.type === ResourceType.InternalLink && (
						<PeopleAlt className='tile-icon' />
					)}
					<DragHandle
						className='drag-icon'
						style={{ marginLeft: theme.spacing(1) }}
					/>
				</div>

				<div className={clsx('resource-tile-label')} style={{ maxHeight: '75%' }}>
					{resourceItem.title}
				</div>
			</div>
		)
	}
)
