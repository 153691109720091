import { Color } from '@material-ui/core'
import tinycolor from 'tinycolor2'

/* 
    Inspiration from https://github.com/mbitson/mcg/blob/master/scripts/controllers/ColorGeneratorCtrl.js
*/

export const useMaterialPaletteGenerator = (): (hexColor: string) => Color => {
    return (hexColor: string): Color => {
        const base = tinycolor(getBaseWithAcceptableLightRange(hexColor)).toHexString()

        return {
            50: tinycolor(base).lighten(52).toHexString(),
            100: tinycolor(base).lighten(37).toHexString(),
            200: tinycolor(base).lighten(26).toHexString(),
            300: tinycolor(base).lighten(12).toHexString(),
            400: tinycolor(base).lighten(6).toHexString(),
            500: tinycolor(hexColor).toHexString(),
            600: tinycolor(base).darken(6).toHexString(),
            700: tinycolor(base).darken(12).toHexString(),
            800: tinycolor(base).darken(18).toHexString(),
            900: tinycolor(base).darken(24).toHexString(),
            A100: tinycolor(base).lighten(50).saturate(30).toHexString(),
            A200: tinycolor(base).lighten(30).saturate(30).toHexString(),
            A400: tinycolor(base).lighten(10).saturate(15).toHexString(),
            A700: tinycolor(base).lighten(5).saturate(5).toHexString(),
        }
    }
}

/* 
    Function to prevent lightest colors from turning into white.
    Done by darkening base until the brightest color is no longer #fff. 
    Helps us make sure a school with a crazy light primary color doesn't end up with an unusable UI.
*/
const getBaseWithAcceptableLightRange = (baseColor: string): string => {
    // If this base's lightest color returns white
    if (tinycolor(baseColor).lighten(52).toHexString().toLowerCase() == "#ffffff") {
        // Darken it and try again
        return getBaseWithAcceptableLightRange(tinycolor(baseColor).darken(5).toHexString())
    }

    // Otherwise,
    else {
        //...base is ready to rock!
        return baseColor
    }
}