import { Box, useTheme } from '@material-ui/core'
import React, { } from 'react'
import { CityHall, School } from '../images'
import { ICalendarDispatchProp } from './calendar'
import { IDivisionCalendars, IGroupCategoryCalendars } from './calendar-sidebar-calendars-list'
import { MemoizedCalendarSidebarCalendarsListRow } from './calendar-sidebar-calendars-list-row'
import { MemoizedExpandableDropdown } from './expandable-dropdown'
import { IconTypeDisplay } from './icon-type-display'

interface ICalendarSidebarCalendarsListAllCalendarsProps extends ICalendarDispatchProp {
    divisions: IDivisionCalendars[]
}

const CalendarSidebarCalendarsListAllCalendars = ({ dispatch, divisions }: ICalendarSidebarCalendarsListAllCalendarsProps) => {
    return (
        <MemoizedExpandableDropdown sectionTitle='All calendars'>
            {divisions.map(division => (
                <MemoizedDivisionCalendars key={division.id} division={division} dispatch={dispatch} />
            ))}
        </MemoizedExpandableDropdown>
    )
}

export const MemoizedCalendarSidebarCalendarsListAllCalendars = React.memo(CalendarSidebarCalendarsListAllCalendars)

interface IDivisionCalendarsProps extends ICalendarDispatchProp {
    division: IDivisionCalendars
}
const DivisionCalendars = ({ dispatch, division }: IDivisionCalendarsProps) => {
    const theme = useTheme()

    return (
        <MemoizedExpandableDropdown key={division.id} sectionTitle={division.name} style={{ marginLeft: theme.spacing(2) }}>
            {!division.disabled &&
                <Box marginLeft={3}>
                    <MemoizedCalendarSidebarCalendarsListRow
                        key={division.group!.calendar.id}
                        id={division.group!.calendar.id}
                        title={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {division.schoolwide ?
                                    <CityHall height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} /> :
                                    <School height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} />
                                }
                                {division.name}
                            </span>
                        }
                        calendarIds={[ division.group!.calendar.id ]}
                        dispatch={dispatch}
                        color={theme.palette.primary.main}
                    />
                </Box>
            }

            {division.groupCategories.filter(o => o.groups.length > 0).map(groupCategory =>
                <MemoizedGroupCategoryCalendars key={groupCategory.id} groupCategory={groupCategory} dispatch={dispatch} />
            )}
        </MemoizedExpandableDropdown>
    )
}
const MemoizedDivisionCalendars = React.memo(DivisionCalendars)

interface IGroupCategoryCalendarsProps extends ICalendarDispatchProp {
    groupCategory: IGroupCategoryCalendars
}

const GroupCategoryCalendars = ({ groupCategory, dispatch }: IGroupCategoryCalendarsProps) => {

    const theme = useTheme()

    return (
        <MemoizedExpandableDropdown
            key={groupCategory.id}
            sectionTitle={groupCategory.name}
            style={{ marginLeft: theme.spacing(3), }}
        >
            {groupCategory.groups.map(group =>
                <div
                    key={group.calendar.id}
                    style={{ marginLeft: theme.spacing(3) }}
                >
                    <MemoizedCalendarSidebarCalendarsListRow
                        id={group.calendar.id}
                        title={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {group.iconType ?
                                    <IconTypeDisplay type={group.iconType} size={20} style={{ marginRight: 5, color: groupCategory.iconBackgroundColor || theme.palette.grey[ 500 ] }} /> :
                                    <IconTypeDisplay type={groupCategory.iconType} size={20} style={{ marginRight: 5, color: groupCategory.iconBackgroundColor || theme.palette.grey[ 500 ] }} />
                                }
                                {group.groupName}
                            </span>
                        }
                        calendarIds={[ group.calendar.id ]}
                        dispatch={dispatch}
                        color={groupCategory.iconBackgroundColor || theme.palette.grey[ 500 ]}
                    />
                </div>
            )}
        </MemoizedExpandableDropdown>
    )
}

const MemoizedGroupCategoryCalendars = React.memo(GroupCategoryCalendars)