import { TextField, FormControl, useTheme } from '@material-ui/core'
import { MATERIAL_FIELD_VARIANT, VALIDATION_REGEX } from './constants'
import { useFormStyles } from '../../styles/use-form-styles'
import clsx from "clsx"
import { ITextInputProps } from '.'
import { useEffect, useRef, useState } from 'react'

interface IHexColorInputProps extends ITextInputProps<string> {
    hideSwatch?: boolean
}

const colorRegex = new RegExp(VALIDATION_REGEX.hexCode)

export const HexColorInput = ({
    style,
    fieldProps,
    value,
    onChange,
    onBlur,
    error,
    touched,
    initialError,
    hideHelperTextWhenEmpty,
    size,
    hideSwatch
}: IHexColorInputProps) => {
    const classes = useFormStyles()

    const theme = useTheme()

    const [ rawValue, setRawValue ] = useState(value)

    useEffect(() => {
        if (colorRegex.test(rawValue)) {
            onChange(rawValue)
        } else {
            onChange('')
        }
    }, [ rawValue ])

    const inputRef = useRef<HTMLDivElement>(null)

    const field = (
        <TextField
            variant={MATERIAL_FIELD_VARIANT}
            label={fieldProps.label}
            name={fieldProps.name}
            value={rawValue}
            helperText={(touched && error) || fieldProps.helperText || (!hideHelperTextWhenEmpty && ' ')}
            error={(touched && !!error) || !!initialError}
            onChange={(e) => setRawValue(e.currentTarget.value)}
            onBlur={onBlur}
            size={size}
            inputRef={inputRef}
        />
    )

    return (
        <FormControl className={clsx(classes.margin, classes.root)} variant={MATERIAL_FIELD_VARIANT} style={style}>
            {hideSwatch ?
                field
                :
                <div style={{ display: 'flex' }}>
                    {field}

                    <div style={{ marginLeft: theme.spacing(1) }}>
                        <div
                            style={{
                                backgroundColor: (value && value.length && value) || 'white',
                                height: '56px',
                                width: '56px',
                                border: `1px solid ${theme.palette.grey[ 400 ]}`,
                                color: theme.palette.grey[ 500 ],
                                borderRadius: theme.shape.borderRadius,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => inputRef.current?.focus()}
                        >
                            {(!!value && value.length) ? null : 'color'}
                        </div>
                    </div>
                </div>
            }
        </FormControl>
    )
}