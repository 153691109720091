import { useField } from 'formik'
import { IconType } from '../../graphql/autogenerate/schemas'
import { CommonProps } from '../interfaces'
import { IconSelector, IField } from './'

interface IFormikIconSelectorProps extends CommonProps {
    fieldProps: IField
    onOpened?: () => void
    color?: string | null
}
export const FormikIconSelector = (props: IFormikIconSelectorProps) => {
    const [ field, meta, helpers ] = useField<IconType | null | undefined>(props.fieldProps)

    return (
        <IconSelector
            {...props}
            {...meta}
            {...field}
            {...helpers}
            label={props.fieldProps.label}
            iconTypeSelected={(iconType) => helpers.setValue(iconType)}
            iconType={field.value}
        />
    )
}