import { Typography, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import { useState } from 'react'
import { REFETCH_INTERVAL } from '../constants'
import { useCurrentUserGroupNotificationsQuery } from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { NotificationDetail } from './notification-detail'
import { NotificationMessage } from './notification-message'
import { useSchoolGroupContext } from './school-group-home'

export const SchoolGroupNotifications = () => {
    const theme = useTheme()
    const { state: { school: { slug } } } = useSchoolContext()
    const { state: { group } } = useSchoolGroupContext()
    const { data } = useHandleReactQuery(useCurrentUserGroupNotificationsQuery({ groupId: group.id }, { refetchInterval: REFETCH_INTERVAL }))
    const notifications = data?.group?.notifications.nodes

    const [ selectedNotificationId, setSelectedNotificationId ] = useState<string>()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'auto', flex: 1 }}>
            {notifications?.length ?
                <div style={{ marginTop: theme.spacing(2) }}>
                    {notifications.map(notification =>
                        <NotificationMessage
                            key={notification.id}
                            style={{ marginBottom: theme.spacing(1) }}
                            message={notification.message}
                            displayTime={dayjs(notification.sendDate || undefined)}
                            groupIds={notification.notificationGroups.nodes.map(o => o.group!.id)}
                            userTypes={notification.userTypes as UserType[] || []}
                            sent
                            onClick={() => {
                                setSelectedNotificationId(notification.id)
                                gtag('event', 'notification_viewed', {
                                    event_category: slug,
                                    event_label: notification.message,
                                })
                            }}
                        />
                    )}
                </div>
                :
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: theme.spacing(6) }}>
                    <Typography gutterBottom color='textSecondary' align='center'>No notifications have been sent yet.</Typography>
                    <Typography color='textSecondary' align='center'>Check back later!</Typography>
                </div>
            }

            {selectedNotificationId && <NotificationDetail selectedNotificationId={selectedNotificationId} onClosePressed={() => setSelectedNotificationId(undefined)} />}
        </div>
    )
}
