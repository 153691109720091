import {
	Button,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	useTheme,
} from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import {
	Form,
	Formik,
	FormikErrors,
	FormikTouched,
	setNestedObjectValues,
} from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useKeyPressEvent } from 'react-use'
import * as Yup from 'yup'
import {
	CreateOrUpdateNotificationMutation,
	CreateOrUpdateNotificationMutationVariables,
	FileFragment,
	NotificationFragmentFragment,
} from '../graphql/autogenerate/operations'
import {
	useCreateNotificationFileMutation,
	useCreateOrUpdateNotificationMutation,
	useDeleteNotificationMutation,
	useRecipientCountQuery,
} from '../graphql/autogenerate/react-query'
import { NotificationDelivery, UserType } from '../graphql/autogenerate/schemas'
import { getLabelForUserType, joinStrings } from '../helpers'
import {
	useAppState,
	useHandleReactQuery,
	useHandleReactQueryMutation,
	useMobileDetect,
} from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { Callout } from './callout'
import { ConfirmDialog } from './confirm-dialog'
import {
	AttachmentFieldValue,
	attachmentsSchema,
	buttonsSchema,
	embedUrlValidation,
	FormikAttachmentsField,
	FormikButtonsField,
	FormikDatePicker,
	FormikEmbedField,
	FormikGroupSelector,
	FormikSwitchField,
	FormikTextInput,
	FormikTimePicker,
	FormikUserTypeSelector,
	useAttachmentFieldValueHandler,
} from './forms'
import { FormikRadio } from './forms/formik-radio'
import { FormikRichText } from './forms/formik-rich-text'
import { FormikSimpleSelectField } from './forms/formik-simple-select-field'
import { useModal } from './modal'
import { NotificationPreview } from './notification-preview'
import { Tooltip } from './tooltip'
import { TooltipWrapper } from './tooltip-wrapper'

interface INotificationFormProps {
	afterSave?: (
		notification: CreateOrUpdateNotificationMutation['createOrUpdateNotification']
	) => void
	afterDelete?: () => void
	notificationToEdit?: NotificationFragmentFragment
	showPreview?: boolean
}

const FINAL_STEP = 3

const decodeCronString = (
	cron: string
): {
	recurringTimeframe: 'day' | 'week' | 'month' | 'year'
	recurringDays: string[]
} => {
	const cronElements = cron.split(' ')
	if (cronElements.length !== 5) throw new Error('Invalid cron string.')

	const [
		cronMinute,
		cronHour,
		cronDayOfMonth,
		cronMonthOfYear,
		cronDaysOfWeek,
	] = cronElements

	let recurringTimeframe: 'day' | 'week' | 'month' | 'year' = 'day'
	let recurringDays: string[] = []

	if (cronDaysOfWeek !== '*') {
		recurringTimeframe = 'week'
		recurringDays = cronDaysOfWeek.split(',')
	}
	if (cronDayOfMonth !== '*' && cronMonthOfYear === '*')
		recurringTimeframe = 'month'
	if (cronMonthOfYear !== '*') recurringTimeframe = 'year'

	return {
		recurringTimeframe,
		recurringDays,
	}
}

// Extracting these here since we use the .calendar() plugin twice below
const dayjsCalendarFormats = {
	sameDay: '[today at] h:mma', // The same day ( today at 2:30am )
	nextDay: '[tomorrow at] h:mma', // The next day ( tomorrow at 2:30am )
	nextWeek: 'dddd [at] h:mma', // The next week ( Sunday at 2:30am )
	lastDay: '[yesterday at] h:mma', // The day before ( yesterday at 2:30am )
	lastWeek: '[last] dddd [at] h:mma', // Last week ( last Monday at 2:30am )
	sameElse: 'dddd, MMMM D @ h:mma', // Everything else (  Friday, August 13 @ 2:30am)
}

export const NotificationForm = ({
	afterSave,
	notificationToEdit,
	afterDelete,
	showPreview = true,
}: INotificationFormProps) => {
	const buttonStyles = useButtonStyles()
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const {
		state: { currentUser },
	} = useAppState()
	const {
		state: {
			school: { divisions },
			currentUserPermissions,
			enabledUserTypes,
		},
	} = useSchoolContext()
	const { isMobile } = useMobileDetect()
	const initialValues = useMemo(() => {
		return {
			groupIds:
				notificationToEdit?.groupsByNotificationGroupNotificationIdAndGroupId.nodes.map(
					o => o.id
				) || ([] as string[]),
			userTypes:
				(notificationToEdit?.userTypes as UserType[]) ||
				([] as UserType[] | null),
			message: notificationToEdit?.message || '',
			body: notificationToEdit?.body || '',
			delivery: notificationToEdit?.delivery || NotificationDelivery.Now,
			sendLaterDate: (notificationToEdit?.delivery ===
				NotificationDelivery.Later && notificationToEdit.sendDate
				? dayjs(notificationToEdit.sendDate)
				: dayjs()) as Dayjs | null,
			sendLaterTime: (notificationToEdit?.delivery ===
				NotificationDelivery.Later && notificationToEdit.sendDate
				? dayjs(notificationToEdit.sendDate)
				: dayjs().startOf('h').add(2, 'hours')) as Dayjs | null,
			recurringStartDate: (notificationToEdit?.delivery ===
				NotificationDelivery.Recurring && notificationToEdit.sendDate
				? dayjs(notificationToEdit.sendDate)
				: dayjs()) as Dayjs | null,
			recurringStartTime: (notificationToEdit?.delivery ===
				NotificationDelivery.Recurring && notificationToEdit.sendDate
				? dayjs(notificationToEdit.sendDate)
				: dayjs().startOf('h').add(2, 'hours')) as Dayjs | null,
			// recurringFrequency: 1,
			...(notificationToEdit?.recurringCron
				? decodeCronString(notificationToEdit.recurringCron)
				: {
						recurringTimeframe: 'week' as 'day' | 'week' | 'month' | 'year',
						recurringDays: [] as string[],
				  }),
			recurringEnabled: notificationToEdit
				? Boolean(notificationToEdit?.recurringEnabled)
				: true,
			buttons: notificationToEdit?.buttons?.buttons || [],
			attachments: (notificationToEdit?.notificationFiles?.nodes.map(
				o => o.file
			) || []) as AttachmentFieldValue[],
			includeEmbed: Boolean(notificationToEdit?.embedUrl),
			embedUrl: notificationToEdit?.embedUrl,
		}
	}, [notificationToEdit])

	const [state, setState] = useState<{
		activeStep: number
		recipients?: number | null
		previewView: 'notification' | 'detail'
	}>({
		activeStep: 0,
		previewView: 'notification',
		recipients: notificationToEdit?.recipientCount,
	})
	const { activeStep, previewView } = state

	const handleNextPressed = useCallback(
		(submitForm?: () => Promise<any>) => {
			if (activeStep === FINAL_STEP) {
				submitForm && submitForm()
			} else {
				setState(_state => ({
					..._state,
					activeStep: _state.activeStep + 1,
					// If we're moving from the "Message" step, go ahead and advance to the "detail" preview window.
					previewView: ((): 'notification' | 'detail' => {
						if (_state.activeStep === 1) return 'detail'
						return _state.previewView
					})(),
				}))
			}
		},
		[activeStep]
	)

	// Advance to the next step when someone presses tab
	const handleTabEvent = useCallback(
		(ev: KeyboardEvent) => {
			if (!ev) return
			ev.preventDefault()
			if (ev.shiftKey) {
				if (activeStep > 0)
					setState(_state => ({ ..._state, activeStep: _state.activeStep-- }))
			} else {
				handleNextPressed()
			}
		},
		[activeStep]
	)

	useKeyPressEvent(ev => ev.code === 'Tab', null, handleTabEvent)

	const reset = useCallback((resetForm: () => void) => {
		resetForm()
		setState({ activeStep: 0, previewView: 'notification' })
	}, [])

	// Track whether the user pressed the "Save Draft" button
	const isDraft = useRef(false)

	const { mutateAsync: createNotification } = useHandleReactQueryMutation(
		useCreateOrUpdateNotificationMutation()
	)

	const { mutateAsync: createNotificationFile } = useHandleReactQueryMutation(
		useCreateNotificationFileMutation()
	)

	const attachmentFieldValueHandler = useAttachmentFieldValueHandler()

	const handleSubmit = useCallback(
		async ({ attachments, ...values }: typeof initialValues) => {
			const variables: CreateOrUpdateNotificationMutationVariables = {
				id: notificationToEdit?.id,
				groupIds: values.groupIds,
				userTypes: values.userTypes,
				message: values.message,
				body: values.body.length ? values.body : null,
				delivery: values.delivery,
				draft: isDraft.current,
				buttons: values.buttons,
				sendDate: notificationToEdit?.sendDate,
				embedUrl: values.includeEmbed ? values?.embedUrl : null,
			}

			switch (values.delivery) {
				case NotificationDelivery.Now:
					variables.sendDate = dayjs().startOf('minute').toISOString()
					break
				case NotificationDelivery.Later:
					if (values.sendLaterTime)
						variables.sendDate = values.sendLaterDate
							?.hour(values.sendLaterTime.hour())
							?.minute(values.sendLaterTime.minute())
							?.startOf('minute')
							?.toISOString()
					break
				case NotificationDelivery.Recurring:
					if (values.recurringStartTime)
						variables.sendDate = values.recurringStartDate
							?.hour(values.recurringStartTime.hour())
							?.minute(values.recurringStartTime.minute())
							?.startOf('minute')
							?.toISOString()

					variables.recurringEnabled = values.recurringEnabled

					const cronMinute = values.recurringStartTime?.minute()
					const cronHour = values.recurringStartTime?.hour()
					const cronDaysOfWeek =
						values.recurringTimeframe === 'week'
							? values.recurringDays.join(',')
							: '*'
					const cronDayOfMonth =
						values.recurringTimeframe === 'month' ||
						values.recurringTimeframe === 'year'
							? values.recurringStartDate?.date()
							: '*'
					const cronMonthOfYear =
						values.recurringTimeframe === 'year'
							? values.recurringStartDate?.month()
							: '*'

					variables.recurringCron = `${cronMinute} ${cronHour} ${cronDayOfMonth} ${cronMonthOfYear} ${cronDaysOfWeek}`

					break
			}

			const { createOrUpdateNotification } = await createNotification(variables)

			const notification = createOrUpdateNotification?.notification
			if (!notification)
				throw new Error('Encountered an error saving notification.')

			/* 
                We do a bit of a two-step process to add/remove attachments to notifications
                1. save the notification
                2. add/remove any attachments

                TODO: Eventually this should be combined so the main createOrUpdateNotification resolver can handle attachment changes for notifications...
            */
			const { addedAttachments } = await attachmentFieldValueHandler({
				submittedAttachments: attachments,
				initialAttachmentFiles:
					notificationToEdit?.notificationFiles?.nodes.reduce<FileFragment[]>(
						(fileFragments, o) => {
							if (o.file) fileFragments.push(o.file)
							return fileFragments
						},
						[]
					),
			})

			if (addedAttachments.length) {
				await Promise.all(
					addedAttachments.map((o, index) =>
						createNotificationFile({
							notificationId: notification.id,
							...o,
							sortOrder: o.sortOrder || index + 1,
						})
					)
				)
			}

			afterSave && afterSave(createOrUpdateNotification)

			if (notification.delivery === NotificationDelivery.Now) {
				enqueueSnackbar(
					`${
						notificationToEdit ? 'Updated' : 'Created'
					} notification. Notification is sending.`,
					{ variant: 'success' }
				)
			} else {
				enqueueSnackbar(
					`${notificationToEdit ? 'Updated' : 'Created'} notification.`,
					{ variant: 'success' }
				)
			}
		},
		[]
	)

	/**
        Takes the values from the form, uses the passed in Formik validator to validate them, and, if all is valid, calls the endpoint to send a test notification to the current user.

        See the backend for more documentation for what happens from there.
    */
	const sendTestMessage = useCallback(
		async (
			values: typeof initialValues,
			validateForm: () => Promise<FormikErrors<typeof initialValues>>,
			setTouched: (
				touched: FormikTouched<typeof initialValues>,
				shouldValidate?: boolean
			) => void
		) => {
			const errors = await validateForm()
			const isValid = Object.keys(errors).length === 0
			setTouched(
				setNestedObjectValues<FormikTouched<typeof initialValues>>(errors, true)
			)

			if (isValid) {
				// TODO: Send test push notification
				alert('sending test push notification not yet implemented')
			}
		},
		[]
	)

	const { mutate: deleteNotification } = useHandleReactQueryMutation(
		useDeleteNotificationMutation({
			onSuccess: () => {
				afterDelete && afterDelete()
				enqueueSnackbar(
					`Deleted ${notificationToEdit?.draft ? 'draft' : ''}${
						notificationToEdit?.delivery === NotificationDelivery.Later
							? 'scheduled'
							: ''
					}${
						notificationToEdit?.delivery === NotificationDelivery.Recurring
							? 'recurring'
							: ''
					}${
						notificationToEdit?.delivery === NotificationDelivery.Sent
							? 'sent'
							: ''
					} notification.`,
					{ variant: 'warning' }
				)
			},
		})
	)

	const deleteConfirmationModal = useModal()

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={Yup.object({
				groupIds: Yup.array().required('Required').min(1, 'Required'),
				// userTypes: Yup.array().nullable().required('Required'),
				message: Yup.string()
					.required('Required')
					.max(
						160,
						'Push notification messages cannot be more than 160 characters.'
					),
				sendLaterDate: Yup.mixed().test(
					'sendLaterDate',
					'Required',
					function (value) {
						if (this.parent.delivery === NotificationDelivery.Later)
							return Boolean(value)
						return true
					}
				),
				sendLaterTime: Yup.mixed()
					.test('sendLaterTime', 'Required', function (value) {
						if (this.parent.delivery === NotificationDelivery.Later)
							return Boolean(value)
						return true
					})
					.test(
						'sendLaterTimeFuture',
						'When scheduling a message for the same day the send time must be after the current time.',
						function (value) {
							if (
								this.parent.delivery === NotificationDelivery.Later &&
								this.parent.sendLaterDate &&
								dayjs(this.parent.sendLaterDate)
									.startOf('d')
									.isSame(dayjs().startOf('d'))
							) {
								return dayjs(value).isAfter(dayjs())
							}
							return true
						}
					),
				recurringStartDate: Yup.mixed().test(
					'recurringStartDate',
					'Required',
					function (value) {
						if (this.parent.delivery === NotificationDelivery.Recurring)
							return Boolean(value)
						return true
					}
				),
				recurringStartTime: Yup.mixed().test(
					'recurringStartTime',
					'Required',
					function (value) {
						if (this.parent.delivery === NotificationDelivery.Recurring)
							return Boolean(value)
						return true
					}
				),
				recurringDays: Yup.mixed().test(
					'recurringDays',
					'Required',
					function (value) {
						if (
							this.parent.delivery === NotificationDelivery.Recurring &&
							this.parent.recurringTimeframe === 'week'
						)
							return value && value.length > 0
						return true
					}
				),
				buttons: buttonsSchema,
				attachments: attachmentsSchema,
				embedUrl: embedUrlValidation,
				// recurringFrequency: Yup.number()
				//     .test(
				//         'recurringFrequency',
				//         'Required',
				//         function (value) {
				//             if (this.parent.delivery === NotificationDelivery.Recurring) return typeof value === 'number'
				//             return true
				//         }
				//     )
				//     .test(
				//         'recurringFrequencyMin',
				//         'Must be higher than 0.',
				//         function (value) {
				//             if (this.parent.delivery === NotificationDelivery.Recurring) return typeof value === 'number' && value > 0
				//             return true
				//         }
				//     )
			})}
		>
			{({
				submitForm,
				resetForm,
				values,
				errors,
				touched,
				validateForm,
				setTouched,
				isSubmitting,
			}) => {
				return (
					<>
						{notificationToEdit?.delivery !== NotificationDelivery.Sent && (
							<GetRecipientsCount
								setRecipientCount={recipients =>
									setState(_state =>
										_state.recipients === recipients
											? _state
											: { ..._state, recipients }
									)
								}
								groupIds={values.groupIds}
								userTypes={values.userTypes}
							/>
						)}
						<Form
							style={{
								...(showPreview
									? {
											display: 'grid',
											gridTemplateRows: '100%',
											gridTemplateColumns: 'minmax(0, 1fr) auto minmax(0, 1fr)',
									  }
									: { display: 'flex', flexDirection: 'column', minHeight: 0 }),
								flex: 1,
								minHeight: 0,
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									overflowY: 'auto',
									flex: 1,
								}}
							>
								{Boolean(
									notificationToEdit
										?.newslettersByNotificationNewsletterNotificationIdAndNewsletterId
										.totalCount
								) && (
									<Callout
										style={{ marginBottom: theme.spacing(1) }}
										children={
											<div>
												<div style={{ marginBottom: theme.spacing(0.5) }}>
													This notification is attached to a newsletter and
													cannot be directly edited.
												</div>
												<div>
													Changes made on the newsletter (title, groups, user
													types, send time) will be reflected on the
													notification.
												</div>
											</div>
										}
									/>
								)}

								{notificationToEdit?.delivery === NotificationDelivery.Later &&
									notificationToEdit.sendDate &&
									!notificationToEdit?.draft && (
										<Callout
											style={{ marginBottom: theme.spacing(1) }}
											children={`Notification scheduled to send ${dayjs(
												notificationToEdit.sendDate
											).calendar(undefined, dayjsCalendarFormats)}.`}
										/>
									)}

								{!notificationToEdit
									?.newslettersByNotificationNewsletterNotificationIdAndNewsletterId
									.totalCount && (
									<>
										{notificationToEdit?.delivery ===
											NotificationDelivery.Recurring &&
											notificationToEdit.nextOccurrence &&
											!notificationToEdit?.draft && (
												<Callout
													style={{ marginBottom: theme.spacing(1) }}
													children={`Next occurrence of this notification scheduled to send ${dayjs(
														notificationToEdit.nextOccurrence
													).calendar(undefined, dayjsCalendarFormats)}.`}
												/>
											)}

										{notificationToEdit?.delivery ===
											NotificationDelivery.Sent && (
											<Callout
												style={{ marginBottom: theme.spacing(1) }}
												children={`You are editing an already sent notification. You can make changes, but these changes will only be reflected in users' notification feed inside the app, not on their home screens.`}
											/>
										)}

										<div
											style={{
												flex: 1,
												backgroundColor: 'white',
												marginBottom: theme.spacing(2),
												overflowY: 'auto',
											}}
										>
											<Stepper
												activeStep={activeStep}
												orientation='vertical'
												style={{ padding: 0 }}
											>
												<Step>
													<StepLabel
														error={
															(touched.groupIds && Boolean(errors.groupIds)) ||
															(touched.userTypes && Boolean(errors.userTypes))
														}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															setState({ ...state, activeStep: 0 })
														}
													>
														Recipients
													</StepLabel>
													<StepContent
														style={{ paddingLeft: theme.spacing(1) }}
													>
														{notificationToEdit?.delivery !==
															NotificationDelivery.Sent && (
															<Callout
																type='secondary'
																style={{
																	padding: theme.spacing(0.5),
																	paddingBottom: theme.spacing(2),
																}}
															>
																Who would you like to receive this notification?
															</Callout>
														)}

														<TooltipWrapper tooltip='Only users who are members of the groups you select will receive or be able to view this notification.'>
															<FormikGroupSelector
																divisions={divisions.nodes}
																fieldProps={{
																	name: 'groupIds',
																	label: 'Select group(s)',
																}}
															/>
														</TooltipWrapper>

														<TooltipWrapper tooltip='Specify whether this notification should be visible to all users or only specific types. This filter is in addition to which group(s) you have selected.'>
															<FormikUserTypeSelector
																field={{ name: 'userTypes' }}
																userTypes={enabledUserTypes.filter(
																	o =>
																		o !== UserType.FacultyStaff ||
																		Boolean(
																			currentUserPermissions.schoolwideAdmin ||
																				currentUser?.appAdministrator
																		)
																)}
															/>
														</TooltipWrapper>
													</StepContent>
												</Step>

												<Step>
													<StepLabel
														error={touched.message && Boolean(errors.message)}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															setState({
																...state,
																activeStep: 1,
																previewView: 'notification',
															})
														}
													>
														Message
													</StepLabel>
													<StepContent
														style={{ paddingLeft: theme.spacing(1) }}
													>
														{notificationToEdit?.delivery !==
															NotificationDelivery.Sent && (
															<Callout
																type='secondary'
																style={{
																	padding: theme.spacing(0.5),
																	paddingBottom: theme.spacing(2),
																}}
															>
																This message is the notification that will show
																up on users' devices and is limited to 160
																characters.
															</Callout>
														)}

														<div style={{ display: 'flex' }}>
															<FormikTextInput
																autoFocus
																fieldProps={{ name: 'message' }}
																rows={2}
																multiline
															/>
														</div>
													</StepContent>
												</Step>

												<Step>
													<StepLabel
														error={touched.body && Boolean(errors.body)}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															setState({
																...state,
																activeStep: 2,
																previewView: 'detail',
															})
														}
													>
														Body (optional)
													</StepLabel>
													<StepContent
														style={{ paddingLeft: theme.spacing(1) }}
													>
														<FormikRichText
															onKeyDown={ev => {
																if (ev.code === 'Tab') {
																	handleTabEvent(ev)
																}
															}}
															autoFocus
															fieldProps={{ name: 'body' }}
															height={400}
														/>

														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
															}}
														>
															<FormikButtonsField
																field={{ name: 'buttons', label: 'Buttons' }}
																style={{ marginBottom: theme.spacing(2) }}
															/>

															<FormikAttachmentsField
																field={{
																	name: 'attachments',
																	label: 'Attachments',
																}}
																style={{ marginBottom: theme.spacing(2) }}
															/>

															<FormikSwitchField
																style={{ marginLeft: theme.spacing(2) }}
																field={{
																	name: 'includeEmbed',
																	label: 'Embed a video/podcast',
																}}
															/>
															{values.includeEmbed && (
																<FormikEmbedField
																	field={{
																		label: 'Embed URL',
																		name: 'embedUrl',
																	}}
																/>
															)}
														</div>
													</StepContent>
												</Step>

												<Step>
													<StepLabel
														error={
															(touched.sendLaterDate &&
																Boolean(errors.sendLaterDate)) ||
															(touched.sendLaterTime &&
																Boolean(errors.sendLaterTime)) ||
															(touched.recurringDays &&
																Boolean(errors.recurringDays))
														}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															setState({ ...state, activeStep: 3 })
														}
													>
														Delivery
													</StepLabel>
													<StepContent
														style={{ paddingLeft: theme.spacing(1) }}
													>
														{!notificationToEdit && (
															<Callout
																type='secondary'
																style={{
																	padding: theme.spacing(0.5),
																	paddingBottom: theme.spacing(2),
																}}
															>
																When would you like the notification to be sent?
															</Callout>
														)}

														<div
															style={{ display: 'flex', alignItems: 'center' }}
														>
															{notificationToEdit?.delivery !==
																NotificationDelivery.Sent && (
																<Tooltip
																	title={
																		Boolean(
																			notificationToEdit &&
																				!notificationToEdit?.draft
																		)
																			? 'Delivery type cannot be edited for non-draft notifications.'
																			: ''
																	}
																>
																	<div>
																		<FormikRadio
																			field={{
																				name: 'delivery',
																				disabled: Boolean(
																					notificationToEdit &&
																						!notificationToEdit?.draft
																				),
																			}}
																			options={[
																				{
																					value: NotificationDelivery.Now,
																					label: 'Now',
																				},
																				{
																					value: NotificationDelivery.Later,
																					label: 'Later',
																				},
																				...(isMobile()
																					? []
																					: [
																							{
																								value:
																									NotificationDelivery.Recurring,
																								label: 'Recurring',
																							},
																					  ]),
																			]}
																		/>
																	</div>
																</Tooltip>
															)}

															{values.delivery ===
																NotificationDelivery.Recurring &&
																notificationToEdit && (
																	<TooltipWrapper
																		style={{ alignItems: 'center' }}
																		iconStyle={{ marginBottom: 5 }}
																		tooltip={
																			values.recurringEnabled
																				? 'This recurring notification is currently enabled and future notification occurences will be sent. Disabled it to pause notifications.'
																				: 'This recurring notification is currently disabled and future notification occurrences will not be sent. Enable it to resume sending notifications.'
																		}
																	>
																		<FormikSwitchField
																			style={{ marginRight: theme.spacing(1) }}
																			field={{
																				name: 'recurringEnabled',
																				label: 'Enable',
																			}}
																		/>
																	</TooltipWrapper>
																)}

															{notificationToEdit &&
																notificationToEdit.delivery ===
																	NotificationDelivery.Sent && (
																	<SentDelivery
																		notificationToEdit={notificationToEdit}
																	/>
																)}
														</div>

														{values.delivery === NotificationDelivery.Later && (
															<div
																style={{
																	display: 'flex',
																	marginTop: theme.spacing(1),
																}}
															>
																<FormikDatePicker
																	disabledKeyboard
																	fieldProps={{ name: 'sendLaterDate' }}
																	style={{ marginRight: theme.spacing(1) }}
																/>
																<FormikTimePicker
																	fieldProps={{ name: 'sendLaterTime' }}
																	startTime={dayjs().startOf('d')}
																/>
															</div>
														)}

														{values.delivery ===
															NotificationDelivery.Recurring && (
															<div style={{ marginTop: theme.spacing(1) }}>
																<div
																	style={{
																		display: 'flex',
																		...(values.recurringTimeframe === 'week'
																			? {
																					justifyContent: 'center',
																					flexDirection: 'column',
																			  }
																			: { alignItems: 'center' }),
																	}}
																>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			marginTop: theme.spacing(1),
																		}}
																	>
																		<div
																			style={{ marginRight: theme.spacing(1) }}
																		>
																			Repeat every
																		</div>
																		{/* <FormikNumberField field={{ name: 'recurringFrequency' }} style={{ width: 60, marginRight: theme.spacing(1) }} /> */}
																		<FormikSimpleSelectField
																			field={{ name: 'recurringTimeframe' }}
																			options={[
																				{ label: 'day', value: 'day' },
																				{ label: 'week', value: 'week' },
																				{ label: 'month', value: 'month' },
																				{ label: 'year', value: 'year' },
																			]}
																			style={{
																				minWidth: 95,
																				marginRight: theme.spacing(1),
																			}}
																		/>
																	</div>
																	{values.recurringTimeframe === 'week' && (
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				marginTop: theme.spacing(1),
																			}}
																		>
																			<div
																				style={{
																					marginRight: theme.spacing(1),
																				}}
																			>
																				on
																			</div>
																			<FormikSimpleSelectField
																				field={{ name: 'recurringDays' }}
																				options={[
																					{ label: 'Mon', value: '1' },
																					{ label: 'Tue', value: '2' },
																					{ label: 'Wed', value: '3' },
																					{ label: 'Thu', value: '4' },
																					{ label: 'Fri', value: '5' },
																					{ label: 'Sat', value: '6' },
																					{ label: 'Sun', value: '0' },
																				]}
																				style={{
																					minWidth: 95,
																					marginRight: theme.spacing(1),
																				}}
																				multiple
																			/>
																		</div>
																	)}
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			marginTop: theme.spacing(1),
																		}}
																	>
																		<div
																			style={{ marginRight: theme.spacing(1) }}
																		>
																			at
																		</div>
																		<FormikTimePicker
																			style={{ marginRight: theme.spacing(1) }}
																			fieldProps={{
																				name: 'recurringStartTime',
																			}}
																			startTime={dayjs().startOf('d')}
																		/>
																		<div
																			style={{ marginRight: theme.spacing(1) }}
																		>
																			starting
																		</div>
																		<FormikDatePicker
																			disabledKeyboard
																			fieldProps={{
																				name: 'recurringStartDate',
																			}}
																			style={{ marginRight: theme.spacing(1) }}
																			disablePast
																		/>
																	</div>
																</div>
															</div>
														)}
													</StepContent>
												</Step>
											</Stepper>
										</div>

										<div style={{ display: 'flex', flexWrap: 'wrap' }}>
											<Button
												disabled={state.activeStep === 0}
												style={{ minWidth: 100, marginRight: theme.spacing(1) }}
												type='button'
												color='secondary'
												variant='outlined'
												onClick={() =>
													setState(_state => ({
														..._state,
														activeStep: _state.activeStep - 1,
													}))
												}
											>
												Back
											</Button>

											<Button
												style={{ minWidth: 100, marginRight: theme.spacing(1) }}
												type='button'
												color='primary'
												variant={
													activeStep === FINAL_STEP ? 'contained' : 'outlined'
												}
												onClick={() => handleNextPressed(submitForm)}
												disabled={isSubmitting}
											>
												{(() => {
													if (activeStep === FINAL_STEP) {
														if (notificationToEdit && !notificationToEdit.draft)
															return 'Save'
														if (values.delivery === NotificationDelivery.Now)
															return 'Send Now'
														if (values.delivery === NotificationDelivery.Later)
															return 'Send Later'
														if (
															values.delivery === NotificationDelivery.Recurring
														)
															return 'Schedule Recurring'
														return 'Save'
													}
													return 'Next'
												})()}
											</Button>

											{(!notificationToEdit || notificationToEdit.draft) &&
												!isMobile() && (
													<Button
														style={{
															minWidth: 100,
															marginRight: theme.spacing(2),
														}}
														color='primary'
														variant='outlined'
														onClick={() => {
															isDraft.current = true
															submitForm()
														}}
													>
														Save Draft
													</Button>
												)}

											{notificationToEdit && (
												<Button
													style={{
														minWidth: 100,
														marginRight: theme.spacing(1),
													}}
													className={buttonStyles.error}
													variant='contained'
													onClick={deleteConfirmationModal.open}
												>
													Delete
												</Button>
											)}

											<div style={{ flex: 1 }} />

											{!notificationToEdit && (
												<Button
													style={{ minWidth: 100 }}
													color='primary'
													variant='text'
													onClick={() => reset(resetForm)}
												>
													Reset Form
												</Button>
											)}

											{/* <TooltipWrapper
                                                style={{ alignItems: 'center' }}
                                                iconStyle={{ marginBottom: 5 }}
                                                tooltip={`It looks like you do not have push notifications set up on any devices connected to this account (${currentUser?.email}). To send a test notification to yourself, log into the ${name} app with ${currentUser?.email} and enable push notifications.`}
                                            >
                                                <Button disabled color='primary' variant='text' onClick={() => sendTestMessage(values, validateForm, setTouched)}>Send test to myself</Button>
                                            </TooltipWrapper> */}
										</div>
									</>
								)}
							</div>

							{showPreview && (
								<>
									<div
										style={{
											borderRight: `1px solid ${theme.palette.grey[300]}`,
											margin: `0 ${theme.spacing(2)}px`,
										}}
									/>

									<div
										style={{
											display: 'flex',
											minHeight: 0,
											overflowY: 'hidden',
										}}
									>
										<NotificationPreview
											groupIds={values.groupIds}
											userTypes={values.userTypes}
											recipients={state.recipients}
											message={values.message}
											body={values.body}
											buttons={values.buttons}
											attachments={values.attachments}
											embedUrl={values.embedUrl}
											displayTime={(() => {
												switch (values.delivery) {
													case NotificationDelivery.Sent:
														return dayjs(
															notificationToEdit?.sendDate || undefined
														)
													case NotificationDelivery.Later:
														return dayjs(values.sendLaterDate || undefined)
															.hour((values.sendLaterTime || dayjs()).hour())
															.minute(
																(values.sendLaterTime || dayjs()).minute()
															)
													case NotificationDelivery.Recurring:
														return dayjs(values.recurringStartDate || undefined)
															.hour(
																(values.recurringStartTime || dayjs()).hour()
															)
															.minute(
																(values.recurringStartTime || dayjs()).minute()
															)
													default:
														return undefined
												}
											})()}
											view={previewView}
											notificationPressed={() =>
												setState(_state => ({
													..._state,
													activeStep:
														_state.activeStep === 1 ? 2 : _state.activeStep,
													previewView: 'detail',
												}))
											}
											backPressed={() =>
												setState(_state => ({
													..._state,
													activeStep:
														_state.activeStep === 2 ? 1 : _state.activeStep,
													previewView: 'notification',
												}))
											}
											delivery={values.delivery}
										/>
									</div>
								</>
							)}
						</Form>

						{notificationToEdit && (
							<ConfirmDialog
								{...deleteConfirmationModal.props}
								confirm={() =>
									deleteNotification({ id: notificationToEdit.id })
								}
								confirmButton={{ label: 'Delete', type: 'error' }}
								body={
									<div style={{ minWidth: 400 }}>
										{(() => {
											if (notificationToEdit.draft)
												return ' Are you sure you want to delete this draft?'
											if (
												notificationToEdit.delivery ===
												NotificationDelivery.Later
											)
												return (
													<>
														<div>
															Are you sure you want to <b>delete</b> this
															scheduled notification?
														</div>
														<p>
															The notification will be canceled and will not be
															sent.
														</p>
													</>
												)
											if (
												notificationToEdit.delivery ===
												NotificationDelivery.Recurring
											)
												return (
													<>
														<div>
															Are you sure you want to <b>delete</b> this
															recurring notification?
														</div>

														<ul>
															<li>
																All future occurrences of this notification will
																be canceled and will not be sent.
															</li>
															<li>
																Past occurrences of this notification that have
																already sent will not be deleted and will be
																visible in the notification feed.
															</li>
														</ul>

														<Callout
															type='info'
															style={{ marginTop: theme.spacing(2) }}
														>
															<div>
																If you only need to pause this notification, you
																can disable it in the{' '}
																<b
																	style={{ cursor: 'pointer' }}
																	onClick={() => {
																		setState(_state => ({
																			..._state,
																			activeStep: 3,
																		}))
																		deleteConfirmationModal.close()
																	}}
																>
																	Delivery step
																</b>{' '}
																instead of deleting.
															</div>
														</Callout>
													</>
												)
											if (
												notificationToEdit.delivery ===
												NotificationDelivery.Sent
											)
												return (
													<>
														<div>
															Are you sure you want to <b>delete</b> this sent
															notification?
														</div>

														<ul style={{ marginTop: theme.spacing(1) }}>
															<li>
																Deleting sent notifications will not prevent or
																remove alerts from appearing on users' home
																screens.
															</li>
															<li>
																Deleting a sent notification will, however,
																remove the notification from users' notification
																feed <i>inside</i> the app.
															</li>
														</ul>
													</>
												)
										})()}
									</div>
								}
							/>
						)}
					</>
				)
			}}
		</Formik>
	)
}

interface IGetRecipientsCountProps {
	groupIds: string[]
	userTypes?: UserType[] | null
	setRecipientCount: (count?: number | null) => void
}
const GetRecipientsCount = ({
	groupIds,
	userTypes,
	setRecipientCount,
}: IGetRecipientsCountProps) => {
	useHandleReactQuery(
		useRecipientCountQuery(
			{ groupIds, userTypes },
			{ onSuccess: ({ recipientCount }) => setRecipientCount(recipientCount) }
		)
	)
	return null
}

const SentDelivery = ({
	notificationToEdit,
}: {
	notificationToEdit: NotificationFragmentFragment
}) => {
	const {
		state: { groups },
	} = useSchoolContext()

	const groupNames = useMemo(
		() =>
			joinStrings(
				groups
					.filter(o =>
						notificationToEdit.groupsByNotificationGroupNotificationIdAndGroupId.nodes
							.map(o => o.id)
							.includes(o.id)
					)
					.map(o => o.groupName),
				true,
				'or'
			),
		[notificationToEdit, groups]
	)
	const userTypeNames = useMemo(() => {
		if (
			notificationToEdit.userTypes === null ||
			notificationToEdit.userTypes.length === 0
		)
			return 'users'
		return joinStrings(
			(notificationToEdit.userTypes as UserType[]).map(o =>
				getLabelForUserType(o)
			),
			true
		)
	}, [notificationToEdit.userTypes])

	return (
		<div>
			Sent{' '}
			<b>
				{dayjs(notificationToEdit.sendDate || undefined).calendar(
					undefined,
					dayjsCalendarFormats
				)}
			</b>{' '}
			to <b>{notificationToEdit.recipientCount}</b> {userTypeNames} subscribed
			to {groupNames}.
		</div>
	)
}
