import { Fab, Box } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
import { useSpring, animated } from 'react-spring'
import { CalendarActionType, ICalendarState } from '../stores/calendar'
import { ICalendarDispatchProp } from './calendar'

interface ICalendarSidebarCreateEventProps extends ICalendarDispatchProp, Pick<ICalendarState, 'sidebarIsOpen'> { }
const CalendarSidebarCreateEvent = ({ dispatch, sidebarIsOpen }: ICalendarSidebarCreateEventProps) => {

    const titleSpring = useSpring({
        width: sidebarIsOpen ? 'unset' : 0,
        opacity: sidebarIsOpen ? 1 : 0,
        marginRight: sidebarIsOpen ? 10 : 0,
    })

    return (
        <Box ml={1} my={2} position='absolute'>
            <Fab onClick={() => dispatch({ type: CalendarActionType.toggleCreateEventModal })} variant={sidebarIsOpen ? 'extended' : 'round'} color='primary' aria-label='add' >
                <Add style={{ marginBottom: sidebarIsOpen ? 3 : 0, fontSize: 30, marginRight: sidebarIsOpen ? 3 : 0 }} />
                <animated.span style={{ ...titleSpring, whiteSpace: 'nowrap' }} >Create Event</animated.span>
            </Fab>
        </Box>
    )
}

export const MemoizedCalendarSidebarCreateEvent = React.memo(CalendarSidebarCreateEvent)