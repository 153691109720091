import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import { PageSectionFragment } from '../graphql/autogenerate/operations'
import { PageSectionType } from '../graphql/autogenerate/schemas'
import { usePageSectionStyles } from '../styles/use-page-section-styles'
import { PageSectionButton } from './page-section-button'
import { PageSectionPicture } from './page-section-picture'
import { PageSectionText } from './page-section-text'
import { PageSectionTitle } from './page-section-title'
import { PageSectionVideo } from './page-section-video'
import { PageSectionAttachments } from './page-section-attachments'

interface IPageSectionDisplayProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	pageSection: PageSectionFragment
}

export const PageSectionDisplay = forwardRef<
	HTMLDivElement,
	IPageSectionDisplayProps
>(({ pageSection, className, ...rest }, ref) => {
	const styles = usePageSectionStyles()

	return (
		<div
			id={pageSection.id}
			ref={ref}
			className={clsx(styles.container, className, 'print-prevent-break')}
			{...rest}
		>
			<div className={styles.contentContainer}>
				{pageSection.type === PageSectionType.Title && (
					<PageSectionTitle pageSection={pageSection} />
				)}
				{pageSection.type === PageSectionType.Text && (
					<PageSectionText pageSection={pageSection} />
				)}
				{pageSection.type === PageSectionType.Picture && (
					<PageSectionPicture pageSection={pageSection} />
				)}
				{pageSection.type === PageSectionType.Button && (
					<PageSectionButton pageSection={pageSection} />
				)}
				{pageSection.type === PageSectionType.Video && (
					<PageSectionVideo pageSection={pageSection} />
				)}
				{pageSection.type === PageSectionType.Attachment && (
					<PageSectionAttachments pageSection={pageSection} />
				)}
			</div>
		</div>
	)
})
