import { useNavigate, useParams } from 'react-router-dom'
import { useResourceBySlugQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { ResourceDetail } from './resource-detail'

export const ResourceDetailView = () => {
    const { resourceSlug } = useParams()
    const navigate = useNavigate()

    const { data: resource } = useHandleReactQuery(useResourceBySlugQuery(
        { slug: resourceSlug },
        { enabled: Boolean(resourceSlug) })
    )

    if (!resource?.resourceBySlug) return null

    return (
        <ResourceDetail
            resource={resource.resourceBySlug}
            onClosePressed={() => navigate(-1)}
        />
    )
}