import { useTheme } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import { DetailedHTMLProps, HTMLAttributes, useMemo } from 'react'
import { NotificationFragmentFragment, SchoolAdminNotificationsQuery } from '../graphql/autogenerate/operations'
import { NotificationDelivery, UserType } from '../graphql/autogenerate/schemas'
import { useCommonStyles } from '../styles'
import { EmptyState, IEmptyStateProps } from './empty-state'
import { INotificationMessageProps, NotificationMessage } from './notification-message'
import clsx from 'clsx'

interface INotificationListProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    notifications?: SchoolAdminNotificationsQuery[ 'getNotificationsForSchoolForCurrentPerson' ]
    notificationSelected?: (notification: NotificationFragmentFragment) => void
    emptyState: IEmptyStateProps
}

interface INotificationMessages extends INotificationMessageProps {
    original: NotificationFragmentFragment
}

export const NotificationList = ({ notifications, emptyState, notificationSelected, ...rest }: INotificationListProps) => {
    const theme = useTheme()
    const styles = useCommonStyles()

    const notificationMessages = useMemo(() => {
        return notifications?.nodes.map<INotificationMessages>(o => {
            const displayTime: Dayjs | undefined = (() => {
                if (o.nextOccurrence) return dayjs(o.nextOccurrence)
                if (o.sendDate) return dayjs(o.sendDate)
                return undefined
            })()

            return {
                id: o.id,
                message: o.message,
                groupIds: o.groupsByNotificationGroupNotificationIdAndGroupId?.nodes.map(o => o.id),
                userTypes: o.userTypes as UserType[],
                draft: o.draft,
                sent: o.delivery === NotificationDelivery.Sent,
                displayTime,
                original: o,
                recurringEnabled: o.recurringEnabled,
                error: o.logs?.logs.some(o => o.error),
                delivery: o.delivery,
            }
        })
    }, [ notifications ])

    return (
        <div {...rest}>
            {notifications?.totalCount ?
                notificationMessages?.map(notification =>
                    <NotificationMessage
                        key={notification.id}
                        {...notification}
                        style={{ marginTop: theme.spacing(1), cursor: 'pointer', }}
                        className={clsx(notificationSelected && styles.elevationOnHover)}
                        onClick={() => notificationSelected && notificationSelected(notification.original)}
                    />
                )
                :
                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}><EmptyState style={{ paddingBottom: theme.spacing(8) }} {...emptyState} /></div>
            }
        </div>
    )
}