import { createStyles, Drawer, makeStyles, useTheme, Divider, Typography, Box, Link, Button } from '@material-ui/core'
import { FormatColorFill } from '@material-ui/icons'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { AnimationIconWraper } from '../animation-icon-wrapper'
import { useSpring, animated } from 'react-spring'
import { CirclePicker } from 'react-color'
import tinycolor from 'tinycolor2'
import { uniq } from 'lodash'
import { StandaloneHexColorInput } from './'
import React from 'react'
import { isEqual } from 'lodash'
import { Tooltip } from '../tooltip'

const useStyles = makeStyles((theme) => (
    createStyles({
        container: {
            border: `1px solid white`,
            borderRadius: theme.shape.borderRadius,
            width: 56,
            height: 56,
            cursor: 'pointer',
            color: theme.palette.grey[ 400 ],
            borderColor: theme.palette.grey[ 400 ],
            '&:hover': {
                borderColor: 'black'
            }
        },
        iconPadding: {
            padding: theme.spacing(1),
        },
        withIcon: {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            '&:hover': {
                borderColor: 'black'
            }
        },
        error: {
            borderColor: theme.palette.error.main,
            '&:hover': {
                borderColor: theme.palette.error.main,
            }
        }
    })
))

interface IColorPickerProps {
    style?: React.CSSProperties
    className?: string
    color?: string
    label?: string
    name?: string
    colorSelected: (color: string) => void
    touched?: boolean
    error?: string
    initialError?: string
    helperText?: string | null
    setTouched?: (value: boolean) => void

    // Optionally provide the controls for the manual color selection. This allows for preserving a consistent set of palette options across multipe color pickers.
    manualColorInputControls?: {
        manualColorInputValue: string
        setManualColorInputValue: (color: string) => void
    }
}

export const ColorPicker = React.memo(({ style, className, color, label, colorSelected, touched, error, initialError, helperText, setTouched, manualColorInputControls, }: IColorPickerProps) => {
    const styles = useStyles()
    const theme = useTheme()

    const [ open, setOpen ] = useState(false)

    const [ resetColorSquare, setResetColorSquare ] = useState(false)
    const [ nextColor, setNextColor ] = useState(color)
    const springGrow = useSpring({
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)',
        },
        reverse: resetColorSquare,
        reset: resetColorSquare,
        onRest: () => {
            if (resetColorSquare) setResetColorSquare(false)
            if (color !== nextColor && nextColor) colorSelected(nextColor)
        },
        config: {
            duration: 200
        },
    })

    // We need to provide local controls if manualColorInputControls weren't passed in
    const [ localManualColorInputValue, setLocalManualColorInputValue ] = useState('')
    const manualColorInputValue = manualColorInputControls?.manualColorInputValue || localManualColorInputValue
    const setManualColorInputValue = manualColorInputControls?.setManualColorInputValue || setLocalManualColorInputValue

    const [ initialShowManualColorInput, setInitialShowManualColorInput ] = useState(false)
    const showManualColorInput = initialShowManualColorInput || (!!manualColorInputValue && manualColorInputValue.length > 0)
    const springHeight = useSpring({
        height: showManualColorInput ? '78px' : '0px',
        overflow: showManualColorInput ? 'visible' : 'hidden',
        paddingTop: showManualColorInput ? theme.spacing(2) : 0,
    })

    const colorOptions = useMemo(() => {
        let primaryColor = theme.palette.primary.main
        let secondaryColor = theme.palette.secondary.main

        if (manualColorInputValue && manualColorInputValue.length) {
            primaryColor = manualColorInputValue
            secondaryColor = tinycolor(manualColorInputValue).complement().toHexString()
        }

        return uniq(
            [
                // Include the base value of a manually input color.
                ...manualColorInputValue && manualColorInputValue.length ? [ manualColorInputValue ] : [],
                ...tinycolor(primaryColor).analogous(4).map(o => o.toHexString()),
                ...tinycolor(secondaryColor).analogous(4).map(o => o.toHexString()),
                ...tinycolor(primaryColor).tetrad().map(o => o.toHexString()),
                ...tinycolor(secondaryColor).tetrad().map(o => o.toHexString()),
                ...tinycolor(primaryColor).complement().splitcomplement().map(o => o.toHexString()),
                ...tinycolor(secondaryColor).complement().splitcomplement().map(o => o.toHexString()),
            ]
        )
    }, [ theme, manualColorInputValue ])

    const selectorButton = (
        <div>
            <div
                className={clsx(className, styles.container, (touched || initialError) && error && styles.error)}
                style={{ ...style, ...color && { borderColor: color } }}
                onClick={() => setOpen(true)}
            >
                {color ?
                    <animated.div style={{ ...springGrow, height: '100%', backgroundColor: color }}>

                    </animated.div>
                    :
                    <AnimationIconWraper className={styles.iconPadding}>
                        <FormatColorFill style={{ height: '100%', width: '100%' }} />
                    </AnimationIconWraper>
                }

            </div>
            <Box>
                <Typography variant='caption' color='error'>{initialError || (touched && error) || helperText || ' '}</Typography>
            </Box>
        </div>
    )

    return (
        <>
            {label ?
                <Tooltip title={`Select ${label}`} placement='top' arrow >
                    {selectorButton}
                </Tooltip>
                :
                selectorButton
            }

            <Drawer
                anchor='bottom'
                open={open}
                onClose={() => {
                    setTouched && setTouched(true)
                    setOpen(false)
                }}
            >
                <Box m={2} style={{ maxHeight: 225 }}>
                    <Typography variant='h5'>Select Color </Typography>
                    <Typography variant='body2'>Click <Link onClick={() => setInitialShowManualColorInput(!showManualColorInput)} style={{ cursor: 'pointer' }}>here</Link> to enter a hexcode and generate more options.</Typography>
                    <Divider />

                    <animated.div
                        style={{
                            ...springHeight,
                            opacity: showManualColorInput ? 1 : 0,
                            transition: 'opacity 1s',
                        }}
                    >
                        {showManualColorInput &&
                            <>
                                <StandaloneHexColorInput
                                    label='Hexcode'
                                    value={manualColorInputValue || ''}
                                    onChange={setManualColorInputValue}
                                    hideSwatch
                                />
                                <Button
                                    variant='text'
                                    style={{ height: '100%' }}
                                    onClick={() => {
                                        setManualColorInputValue('')
                                        setInitialShowManualColorInput(false)
                                    }}
                                >
                                    Reset
                                </Button>
                            </>
                        }
                    </animated.div>
                    <Box pt={2}>
                        <CirclePicker
                            circleSpacing={theme.spacing(2)}
                            circleSize={56}
                            width='100%'
                            colors={colorOptions}
                            onChangeComplete={({ hex }) => {
                                setOpen(false)
                                setResetColorSquare(true)
                                setNextColor(hex)
                            }}
                        />
                    </Box>
                </Box>
            </Drawer>
        </>
    )
},
    (prevProps, nextProps) => {
        const { colorSelected: prevcolorSelected, setTouched: prevsetTouched, ...prevRest } = prevProps
        const { colorSelected: nextcolorSelected, setTouched: nextsetTouched, ...prevNext } = nextProps
        return isEqual(prevRest, prevNext)
    }
)

