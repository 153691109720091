import { TextField, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import { useSchoolMarqueeQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { GroupResourcesDisplay } from './group-resources-display'
import { MarqueeDisplay } from './marquee-display'
import { SchoolHomeSponsorsPanel } from './school-home-sponsors-panel'

export const SchoolHome = () => {
	const theme = useTheme()
	const navigate = useNavigate()

	const {
		state: {
			school: { id: schoolId, divisions, sponsors },
		},
	} = useSchoolContext()

	const { data: marqueeQuery } = useHandleReactQuery(
		useSchoolMarqueeQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL })
	)
	const marquee = marqueeQuery?.marquees?.nodes[0]

	const schoolwideDivision = divisions.nodes.find(o => o.schoolwide)

	return (
		<div
			style={{
				padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${
					theme.spacing(1) + 55
				}px ${theme.spacing(1)}px`,
			}}
		>
			{marquee && (
				<MarqueeDisplay
					enabled={marquee.enabled}
					image={marquee.marqueeImage?.temporaryDownloadUrl}
					body={marquee.body}
					countdown={
						marquee.countdownDate
							? {
									time: dayjs(marquee.countdownDate),
									label: marquee.countdownLabel,
									hideWhenCompleted: marquee.countdownHideWhenCompleted,
							  }
							: undefined
					}
					button={
						marquee.buttons?.buttons?.length === 1
							? marquee.buttons.buttons[0]
							: undefined
					}
					embedUrl={marquee.embedUrl}
				/>
			)}

			{sponsors.totalCount > 0 && <SchoolHomeSponsorsPanel />}

			<div style={{ display: 'flex', marginTop: theme.spacing(2) }}>
				<TextField
					variant='outlined'
					size='small'
					placeholder='Search resources...'
					style={{ flex: 1 }}
					onClick={() => navigate('../search')}
					// onChange={(ev) => {
					//     open()
					//     setSearchTerm(ev.currentTarget.value)
					// }}
				/>
			</div>

			{schoolwideDivision?.group && (
				<GroupResourcesDisplay groupId={schoolwideDivision.group.id} />
			)}
		</div>
	)
}
