import { v4 } from 'uuid'
import { useAccessToken, useAppState, useLocalStorage } from '.'
import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'
import { useRegisterAnonymousUserMutation } from '../graphql/autogenerate/react-query'
import { AppActionType } from '../stores/app-state'

/** 
    Generates an anonymous user account and saves it's credentials to local storage.
*/
export const useRegisterAnonymousUser = () => {
    const { dispatch } = useAppState()
    const { setItem } = useLocalStorage()
    const { setAccessToken } = useAccessToken()

    const { mutateAsync: registerAnonymousUserMutation } = useRegisterAnonymousUserMutation({
        onSuccess: async ({ registerAnonymousUser }) => {
            if (registerAnonymousUser?.jwtToken) {
                await setAccessToken(registerAnonymousUser.jwtToken)
                dispatch({ type: AppActionType.login })
            }
        }
    })

    const registerAnonymousUser = async () => {
        const id = v4()
        await registerAnonymousUserMutation({ _email: `${id}@legitapps.com`, password: id })
        await setItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY, value: id })
    }

    return registerAnonymousUser
}