import {
	Button,
	Divider,
	Link,
	Paper,
	Typography,
	useTheme,
} from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { Dispatch, createContext, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import * as yup from 'yup'
import { NewsletterFragment } from '../graphql/autogenerate/operations'
import {
	useCancelScheduledNewsletterMutation,
	useDeleteNewsletterMutation,
	useDuplicateNewsletterMutation,
	useGetNewsletterByIdQuery,
	useRecipientCountQuery,
	useUnpublishNewsletterMutation,
	useUpdateNewsletterRecipientsMutation,
} from '../graphql/autogenerate/react-query'
import {
	NewsletterStatus,
	NotificationDelivery,
	UserType,
} from '../graphql/autogenerate/schemas'
import { getLabelForUserType, joinStrings } from '../helpers'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { useNewsletterStyles } from '../styles/use-newsletter-styles'
import { Callout } from './callout'
import { ConfirmDialog } from './confirm-dialog'
import { FormikGroupSelector, FormikUserTypeSelector } from './forms'
import { Modal, useModal } from './modal'
import { NewsletterDisplay } from './newsletter-display'
import { NewsletterHeaderEdit } from './newsletter-header-edit'
import { NewsletterPublish } from './newsletter-publish'
import { PageEdit } from './page-edit'
import { SetStateAction } from 'jotai'

interface INewsletterEditProps {
	editModalControls: ReturnType<typeof useModal>
	newsletterId?: string
	refetchNewsletters: () => Promise<any>
	clearNewsletterToEdit: () => void

	newslettersMonth: Dayjs
	setNewslettersMonth: (month: Dayjs) => void
}

interface INewsletterEditContext
	extends Pick<
		INewsletterEditProps,
		'setNewslettersMonth' | 'editModalControls'
	> {
	publishModal: ReturnType<typeof useModal>
	newsletter: NewsletterFragment
	refetchNewsletter: () => Promise<any>
	publishNow: boolean
	setPublishNow: Dispatch<SetStateAction<boolean>>
}

const NewsletterEditContext = createContext<INewsletterEditContext | undefined>(
	undefined
)
export const useNewsletterEditContext = () => {
	const context = useContext(NewsletterEditContext)
	if (!context)
		throw new Error(
			`Attempted to use NewsletterEditContext before it's provided.`
		)
	return context
}

export const NewsletterEdit = (props: INewsletterEditProps) => {
	const styles = useNewsletterStyles(true)()
	const buttonStyles = useButtonStyles()
	const theme = useTheme()

	const { enqueueSnackbar } = useSnackbar()

	const { data, refetch: refetchNewsletter } = useHandleReactQuery(
		useGetNewsletterByIdQuery(
			{ id: props.newsletterId! },
			{ enabled: Boolean(props.newsletterId) }
		)
	)
	const newsletter = data?.newsletter

	const { mutate: deleteNewsletter } = useDeleteNewsletterMutation({
		onSuccess: async () => {
			enqueueSnackbar('Newsletter deleted.', { variant: 'warning' })
			props.editModalControls.close()
		},
	})
	const confirmDelete = useModal()

	const { mutate: cancelScheduledNewsletter } = useHandleReactQueryMutation(
		useCancelScheduledNewsletterMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				enqueueSnackbar('Scheduled newsletter canceled.', {
					variant: 'warning',
				})
			},
		})
	)

	const { mutate: unpublishNewsletter } = useHandleReactQueryMutation(
		useUnpublishNewsletterMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				enqueueSnackbar('Newsletter unpublished.', { variant: 'warning' })
			},
		})
	)
	const confirmUnpublish = useModal()

	const publishModal = useModal()
	const [publishNow, setPublishNow] = useState(false)

	const {
		state: {
			groups,
			school: { divisions },
			enabledUserTypes,
		},
	} = useSchoolContext()
	const newsletterGroupIds =
		newsletter?.groupsByGroupNewsletterNewsletterIdAndGroupId.nodes.map(
			o => o.id
		) || []
	const sentNotification =
		newsletter?.notificationsByNotificationNewsletterNewsletterIdAndNotificationId.nodes.find(
			o => o.delivery === NotificationDelivery.Sent
		)
	const groupNames = joinStrings(
		groups.filter(o => newsletterGroupIds?.includes(o.id)).map(o => o.groupName),
		true,
		'or'
	)
	const userTypeNames = (() => {
		if (newsletter?.userTypes.length === 0) return 'users'
		return joinStrings(
			((newsletter?.userTypes || []) as UserType[]).map(o =>
				getLabelForUserType(o)
			),
			true
		)
	})()
	const { data: estimatedRecipientCount } = useRecipientCountQuery(
		{ groupIds: newsletterGroupIds, userTypes: newsletter?.userTypes },
		{ enabled: !Boolean(sentNotification) }
	)
	const editAudienceModal = useModal()
	const { mutate: updateNewsletterRecipients } = useHandleReactQueryMutation(
		useUpdateNewsletterRecipientsMutation({
			onSuccess: async () => {
				await refetchNewsletter()
				enqueueSnackbar('Newsletter recipients updated.', { variant: 'info' })
				editAudienceModal.close()
			},
		})
	)

	const [_, copyToClipoard] = useCopyToClipboard()

	const [preview, setPreview] = useState(false)

	const navigate = useNavigate()

	const handlePrintRef = useRef<() => void>()

	const { mutate: duplicateNewsletter } = useHandleReactQueryMutation(
		useDuplicateNewsletterMutation({
			onSuccess: ({ duplicateNewsletter }) => {
				if (duplicateNewsletter?.newsletter) {
					enqueueSnackbar('Newsletter successfully duplicated.', {
						variant: 'info',
					})
					props.editModalControls.close()
				}
			},
		})
	)

	if (!newsletter) return null

	return (
		<NewsletterEditContext.Provider
			value={{
				...props,
				publishModal,
				newsletter,
				refetchNewsletter,
				publishNow,
				setPublishNow,
			}}
		>
			<>
				<Modal
					{...props.editModalControls.props}
					title='Edit Newsletter'
					fullscreen
					closeButton
					noPadding
					noHeader={preview}
					afterClose={async () => {
						if (
							newsletter.publishDate &&
							dayjs(newsletter.publishDate).month() !== props.newslettersMonth.month()
						) {
							props.setNewslettersMonth(dayjs(newsletter.publishDate))
						} else {
							await props.refetchNewsletters()
						}

						props.clearNewsletterToEdit()
						navigate('', { replace: true })
					}}
					children={
						preview ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									overflow: 'hidden',
								}}
							>
								<NewsletterDisplay newsletter={newsletter} />

								<div
									style={{
										marginLeft: theme.spacing(8),
										width: 250,
										padding: theme.spacing(2),
									}}
								>
									<Paper style={{ padding: theme.spacing(1) }}>
										<Button
											onClick={() => setPreview(false)}
											style={{ width: '100%' }}
											variant='outlined'
											color='secondary'
										>
											Close Preview
										</Button>
									</Paper>
								</div>
							</div>
						) : (
							<div className={styles.container} style={{ flex: 1 }}>
								<Paper
									style={{
										maxWidth: 750,
										flex: 1,
										display: 'flex',
										flexDirection: 'column',
										overflowY: 'auto',
									}}
								>
									<NewsletterHeaderEdit />

									{newsletter.url ? (
										<div
											style={{
												margin: '10%',
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
											}}
										>
											<Callout style={{ marginBottom: theme.spacing(2) }}>
												This newsletter was created as a link to an existing external
												newsletter.
											</Callout>

											<Button
												variant='contained'
												target='_blank'
												color='primary'
												href={newsletter.url}
											>
												View External Newsletter
											</Button>
										</div>
									) : (
										<PageEdit pageId={newsletter.pageId} />
									)}
								</Paper>

								<div
									style={{
										marginLeft: theme.spacing(8),
										width: 250,
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Paper
										style={{
											display: 'flex',
											flexDirection: 'column',
											padding: theme.spacing(1),
										}}
									>
										<Typography variant='h5' color='textSecondary'>
											Options
										</Typography>
										<Divider style={{ marginBottom: theme.spacing(2) }} />

										{newsletter.status === NewsletterStatus.Draft && (
											<Button
												variant='contained'
												color='primary'
												style={{ marginBottom: theme.spacing(1) }}
												onClick={publishModal.open}
											>
												Publish
											</Button>
										)}

										{newsletter.status === NewsletterStatus.Scheduled &&
											newsletter.publishDate && (
												<Typography
													variant='subtitle2'
													style={{ marginBottom: theme.spacing(2) }}
												>
													<div>
														Scheduled to be published{' '}
														{dayjs(newsletter.publishDate).format('dddd, MMMM D [at] h:mma')}.
													</div>
													<Link style={{ cursor: 'pointer' }} onClick={publishModal.open}>
														Edit
													</Link>{' '}
													|{' '}
													<Link
														style={{ cursor: 'pointer' }}
														onClick={() => {
															setPublishNow(true)
															publishModal.open()
														}}
													>
														Publish Now
													</Link>
												</Typography>
											)}

										{newsletter.status === NewsletterStatus.Published &&
											newsletter.publishDate && (
												<>
													<Typography
														variant='subtitle2'
														style={{ marginBottom: theme.spacing(2) }}
													>
														Published on{' '}
														{dayjs(newsletter.publishDate).format('dddd, MMMM D [at] h:mma')}{' '}
														(
														<Link style={{ cursor: 'pointer' }} onClick={publishModal.open}>
															edit
														</Link>
														).
													</Typography>
												</>
											)}

										{newsletter.status === NewsletterStatus.Draft && (
											<Button
												variant='outlined'
												color='primary'
												style={{ marginBottom: theme.spacing(1) }}
												onClick={() => setPreview(true)}
											>
												Preview
											</Button>
										)}

										{newsletter.status !== NewsletterStatus.Draft && (
											<>
												<Button
													variant='outlined'
													color='primary'
													style={{ marginBottom: theme.spacing(1) }}
													onClick={() => {
														copyToClipoard(
															`${process.env.REACT_APP_ROOT_URL}/newsletter/${newsletter.slug}`
														)
														enqueueSnackbar('Copied!')
													}}
												>
													Copy Public Link
												</Button>
												<Button
													variant='outlined'
													color='primary'
													style={{ marginBottom: theme.spacing(1) }}
													onClick={() => {
														handlePrintRef.current && handlePrintRef.current()
													}}
												>
													Print
													<div style={{ display: 'none' }}>
														<NewsletterDisplay
															newsletter={newsletter}
															handlePrintRef={handlePrintRef}
														/>
													</div>
												</Button>
											</>
										)}

										<Button
											variant='outlined'
											color='primary'
											style={{ marginBottom: theme.spacing(1) }}
											onClick={() => duplicateNewsletter({ id: newsletter.id })}
										>
											Duplicate
										</Button>

										{newsletter.status === NewsletterStatus.Scheduled && (
											<Button
												onClick={() => cancelScheduledNewsletter({ id: newsletter.id })}
												className={buttonStyles.warningOutlined}
												style={{
													marginBottom: theme.spacing(1),
													width: '100%',
												}}
												variant='outlined'
												type='button'
											>
												Cancel
											</Button>
										)}

										{newsletter.status === NewsletterStatus.Published && (
											<Button
												variant='outlined'
												className={buttonStyles.warningOutlined}
												style={{ marginBottom: theme.spacing(1) }}
												onClick={confirmUnpublish.open}
											>
												Unpublish
											</Button>
										)}

										<Button
											variant='outlined'
											className={buttonStyles.warningOutlined}
											onClick={confirmDelete.open}
										>
											Delete
										</Button>
									</Paper>

									<Paper
										style={{
											display: 'flex',
											flexDirection: 'column',
											padding: theme.spacing(1),
											marginTop: theme.spacing(2),
										}}
									>
										<Typography variant='h5' color='textSecondary'>
											Recipients
										</Typography>
										<Divider style={{ marginBottom: theme.spacing(2) }} />

										<Typography
											variant='subtitle2'
											style={{ marginBottom: theme.spacing(2) }}
										>
											{newsletter.status === NewsletterStatus.Published
												? 'Visible'
												: 'Will be sent'}{' '}
											to{' '}
											<b>
												{Boolean(sentNotification)
													? sentNotification?.recipientCount
													: estimatedRecipientCount?.recipientCount}
											</b>{' '}
											{userTypeNames} in {groupNames}.
										</Typography>

										{newsletter.status === NewsletterStatus.Draft && (
											<Button
												variant='contained'
												color='primary'
												onClick={editAudienceModal.open}
											>
												Edit
											</Button>
										)}
									</Paper>
								</div>
							</div>
						)
					}
				/>

				<ConfirmDialog
					{...confirmDelete.props}
					confirm={() => deleteNewsletter({ id: newsletter.id })}
					body={
						<>
							Are you sure you want to delete this newsletter?
							<Callout type='error' style={{ marginTop: theme.spacing(2) }}>
								All its content (text, buttons, images, etc.) will be deleted and cannot
								be recovered.
							</Callout>
						</>
					}
					confirmButton={{
						type: 'error',
						label: 'Delete',
					}}
				/>

				<ConfirmDialog
					{...confirmUnpublish.props}
					confirm={() => unpublishNewsletter({ id: newsletter.id })}
					body={
						<>
							Are you sure you want to unpublish this newsletter?
							<Callout type='warning' style={{ marginTop: theme.spacing(2) }}>
								<div>
									<div style={{ marginBottom: theme.spacing(0.5) }}>
										Unpublishing a newsletter will move it back to <b>draft</b> status and
										remove it from users' notification feed.
									</div>
									<div>
										If you re-publish you will send a new notification with the
										re-published newsletter attached.
									</div>
								</div>
							</Callout>
						</>
					}
					confirmButton={{
						type: 'warning',
						label: 'Unpublish',
					}}
				/>

				<NewsletterPublish />

				<Formik
					initialValues={{
						groupIds: newsletterGroupIds as string[],
						userTypes: newsletter.userTypes as UserType[],
					}}
					validationSchema={yup.object({
						groupIds: yup
							.array()
							.of(yup.string())
							.min(1, 'Please select at least one Group to receive this Newsletter.'),
					})}
					onSubmit={async values => {
						updateNewsletterRecipients({
							newsletterId: newsletter.id,
							newsletterGroupIds: values.groupIds,
							newsletterUserTypes: values.userTypes,
						})
					}}
				>
					{formikProps => (
						<Modal
							{...editAudienceModal.props}
							title='Edit Recipients'
							dismissible
							closeButton
							size='xs'
							actions={
								<>
									<Button
										type='button'
										color='secondary'
										variant='outlined'
										onClick={editAudienceModal.close}
									>
										Cancel
									</Button>
									<Button
										type='submit'
										color='primary'
										variant='contained'
										onClick={formikProps.submitForm}
									>
										Update Recipients
									</Button>
								</>
							}
							children={
								<Form style={{ display: 'flex', flexDirection: 'column' }}>
									<FormikGroupSelector
										divisions={divisions.nodes}
										fieldProps={{
											name: 'groupIds',
											label: 'Select group(s) to receive this newsletter',
										}}
									/>
									<FormikUserTypeSelector
										field={{ name: 'userTypes' }}
										// Don't allow Faculty/Staff as a choice since we don't yet employee filtering in the notifications list
										userTypes={enabledUserTypes.filter(o => o !== UserType.FacultyStaff)}
									/>
								</Form>
							}
						/>
					)}
				</Formik>
			</>
		</NewsletterEditContext.Provider>
	)
}
