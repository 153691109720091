import { makeStyles, createStyles } from '@material-ui/core'

export const useNewsletterStyles = (allowEdit?: boolean) => makeStyles(theme =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            padding: `${theme.spacing(2)}px 0px`,
            minHeight: 0,
        },
    })
)