import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { HorizontalSplit, Settings } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { SchoolDetailFragment } from '../graphql/autogenerate/operations'
import { useAppState } from '../hooks'
import { CityHall, School } from '../images'
import { ICalendarActions, ICalendarState } from '../stores/calendar'
import { ISchoolState, useSchoolContext } from '../stores/school'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { ICalendarDispatchProp } from './calendar'
import { CalendarGroupsForm } from './calendar-groups-form-modal'
import { IDivisionCalendars } from './calendar-sidebar-calendars-list'
import { MemoizedCalendarSidebarCalendarsListRow } from './calendar-sidebar-calendars-list-row'
import { MemoizedExpandableDropdown } from './expandable-dropdown'
import { useModal } from './modal'

interface ICalendarSidebarCalendarsListSchoolCalendarsProps extends ICalendarDispatchProp, Pick<ICalendarState, 'calendarGroups'> {
    divisionCalendars: IDivisionCalendars[]
    divisions: SchoolDetailFragment[ 'divisions' ][ 'nodes' ]
    refetchCalendarGroups: ICalendarActions[ 'refetch' ][ 'calendarGroups' ]
    groups: ISchoolState[ 'groups' ]
}

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            // '&:hover': {
            //     '& > span': {
            //         display: 'flex!important'
            //     }

            // }
        },
        manageCalendarGroupsIcon: {
            color: theme.palette.grey[ 500 ],
            // display: 'none!important',
        },

    })
)

const CalendarSidebarCalendarsListSchoolCalendars = ({ divisionCalendars, divisions, dispatch, calendarGroups, refetchCalendarGroups, groups }: ICalendarSidebarCalendarsListSchoolCalendarsProps) => {
    const theme = useTheme()
    const styles = useStyles()

    const { state: { currentUser } } = useAppState()
    const { state: { currentUserPermissions } } = useSchoolContext()

    const [ isOpen, setIsOpen ] = useState(true)

    // Manage Calendar Groups modal
    const calendarGroupsFormModal = useModal()

    return (
        <>
            <MemoizedExpandableDropdown
                sectionTitle={
                    <div className={styles.title}>
                        School Calendars
                        {(currentUser?.appAdministrator || currentUserPermissions.schoolwideAdmin) &&
                            <AnimationIconWraper
                                className={styles.manageCalendarGroupsIcon}
                                children={<Settings />}
                                tooltip={{
                                    title: 'Manage Calendar Groups',
                                    placement: 'right'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    calendarGroupsFormModal.open()
                                }}
                            />
                        }
                    </div>
                }
                controls={{ isOpen, setIsOpen }}
            >
                {divisionCalendars.filter(o => !o.disabled).map(division => {
                    return (
                        <MemoizedCalendarSidebarCalendarsListRow
                            id={division.group.calendar.id}
                            key={division.group.calendar.id}
                            title={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {division.schoolwide ?
                                        <CityHall height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} /> :
                                        <School height={20} width={20} style={{ marginBottom: 4, marginRight: 3, color: theme.palette.primary.main }} />
                                    }
                                    {division.name}
                                </span>
                            }
                            calendarIds={[ division.group.calendar.id ]}
                            dispatch={dispatch}
                            color={theme.palette.primary.main}
                            disableFavoriting
                        />
                    )
                })}

                {(currentUser?.appAdministrator || currentUserPermissions.schoolwideAdmin) &&
                    calendarGroups.map(calendarGroup => (
                        <MemoizedCalendarSidebarCalendarsListRow
                            key={calendarGroup.id}
                            id={calendarGroup.id}
                            title={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <HorizontalSplit height={20} width={20} style={{ marginBottom: 4, marginRight: 1, marginLeft: -2, color: theme.palette.primary.main }} />
                                    {calendarGroup.title}
                                </span>
                            }
                            calendarIds={calendarGroup.calendarGroupCalendars.nodes.map(o => {
                                if (!o.calendar?.id) throw new Error('CalendarSidebarCalendarsListSchoolCalendars: encountered a CalendarGroup_Calendar without a calendar id.')
                                return o.calendar.id
                            })}
                            dispatch={dispatch}
                            color={theme.palette.primary.main}
                            disableFavoriting
                        />
                    ))
                }
            </MemoizedExpandableDropdown>

            <CalendarGroupsForm calendarGroups={calendarGroups} refetch={refetchCalendarGroups} modalControls={calendarGroupsFormModal} groups={groups} divisions={divisions} />
        </>
    )
}

export const MemoizedCalendarSidebarCalendarsListSchoolCalendars = React.memo(CalendarSidebarCalendarsListSchoolCalendars)