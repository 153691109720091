import dayjs from 'dayjs'
import { ICalendarState } from './'
import { CalendarAction, CalendarActionType } from './definitions'

export const reducer = (state: ICalendarState, action: CalendarAction): ICalendarState => {
    switch (action.type) {
        case CalendarActionType.toggleSidebar:
            return { ...state, sidebarIsOpen: !state.sidebarIsOpen }
        case CalendarActionType.setViewMode:
            return { ...state, viewMode: action.payload }
        case CalendarActionType.goToToday:
            return { ...state, selectedDate: dayjs().startOf('d') }
        case CalendarActionType.goToPrevious:
            return { ...state, selectedDate: state.selectedDate.subtract(1, state.viewMode) }
        case CalendarActionType.goToNext:
            return { ...state, selectedDate: state.selectedDate.add(1, state.viewMode) }
        case CalendarActionType.toggleSearchActive:
            return { ...state, searchActive: !state.searchActive, disableShortcuts: !state.searchActive }
        case CalendarActionType.toggleCreateEventModal:
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    isOpen: !state.eventModal.isOpen,
                    eventToEdit: state.eventModal.isOpen ? state.eventModal.eventToEdit : undefined
                },
                disableDateScrolling: !state.eventModal.isOpen,
                disableShortcuts: !state.eventModal.isOpen,
            }
        case CalendarActionType.goToDate:
            return { ...state, selectedDate: action.payload }
        case CalendarActionType.disableShortcuts:
            return { ...state, disableShortcuts: true }
        case CalendarActionType.enableShortcuts:
            return { ...state, disableShortcuts: false }
        case CalendarActionType.showCalendars:
            return { ...state, visibleCalendars: { ...state.visibleCalendars, ...action.payload.reduce((calendars, calendarId) => ({ ...calendars, [ calendarId ]: true }), {}) } }
        case CalendarActionType.hideCalendars:
            return { ...state, visibleCalendars: { ...state.visibleCalendars, ...action.payload.reduce((calendars, calendarId) => ({ ...calendars, [ calendarId ]: false }), {}) } }
        case CalendarActionType.toggleCalendarsFavorited:
            return { ...state, favoritedCalendars: { ...state.favoritedCalendars, ...action.payload.reduce((calendars, calendarId) => ({ ...calendars, [ calendarId ]: !state.favoritedCalendars[ calendarId ] }), {}) } }
        case CalendarActionType.enableDateScrolling:
            return { ...state, disableDateScrolling: false }
        case CalendarActionType.disableDateScrolling:
            return { ...state, disableDateScrolling: true }
        case CalendarActionType.setEvents:
            return { ...state, events: action.payload }
        case CalendarActionType.editEvent:
            return {
                ...state,
                eventModal: { isOpen: true, eventToEdit: action.payload },
                disableDateScrolling: true,
                disableShortcuts: true,
            }
        case CalendarActionType.setCalendarGroups:
            return {
                ...state,
                ...action.payload
            }
    }
}

