import { TextField, useTheme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { AccountCircle, ArrowForwardIos, FolderOutlined, LibraryBooksOutlined, LinkOutlined } from '@material-ui/icons'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFacultyStaffQuery, useSchoolPersonResourcesQuery } from '../graphql/autogenerate/react-query'
import { IconType, ResourceType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { IconTypeDisplay } from './icon-type-display'
import { MobileViewContainer } from './mobile-view-container'

interface ISchoolResourcesSearchProps {

}

export const SchoolResourcesSearch = ({ }: ISchoolResourcesSearchProps) => {
    const theme = useTheme()

    let [ searchParams, setSearchParams ] = useSearchParams()
    const searchTerm = searchParams.get('term')
    const setSearchTerm = (term: string) => setSearchParams({ term }, { replace: true })

    const { state: { school: { id: schoolId, divisions, slug }, currentUserPermissions } } = useSchoolContext()
    const schoolwideDivision = divisions.nodes.find(o => o.schoolwide)

    const { data: currentUserSchoolGroupResources } = useHandleReactQuery(useSchoolPersonResourcesQuery({
        schoolId,
        personId: currentUserPermissions.id
    }))
    const { data: schoolDirectory } = useHandleReactQuery(useFacultyStaffQuery({ schoolId }))

    const searchSet = useMemo<ISearchItem[]>(() => {
        const _searchSet: ISearchItem[] = []

        _searchSet.push(
            ...currentUserSchoolGroupResources?.schoolResourcesForPerson?.nodes
                .filter(o => o.type !== ResourceType.InternalLink)
                .map<ISearchItem>(
                    resource => ({
                        id: resource.slug,
                        type: 'resource',
                        title: resource.title,
                        group: {
                            globalSortOrder: resource.group?.globalSortOrder,
                            slug: resource.group?.slug,
                            name: resource.group?.name,
                            iconType: (resource.group?.iconType || resource.group?.icon) as IconType,
                            backgroundColor: resource.group?.groupCategory?.iconBackgroundColor || theme.palette.primary.main
                        },
                        body: resource.body,
                        resourceType: resource.type,
                        url: resource.url
                    }))
            || []
        )

        _searchSet.push(
            ...schoolDirectory?.schoolPeople?.nodes
                .filter(o => o.includeInDirectory)
                .map(o => o.person)
                .filter(o => Boolean(o))
                .map<ISearchItem>(person => ({
                    id: person!.id,
                    type: 'person',
                    title: `${person?.title ? `${person.title} ` : ''}${person?.firstName} ${person?.lastName}`,
                    group: {
                        globalSortOrder: 0,
                        slug: schoolwideDivision?.group?.slug,
                        name: schoolwideDivision?.name,
                        iconType: schoolwideDivision?.group?.iconType,
                        backgroundColor: theme.palette.primary.main,
                    },
                    body: person!.bio,
                }))
            || []
        )

        return _searchSet
    }, [ currentUserSchoolGroupResources, schoolDirectory ])

    const fuzeInstance = useMemo(() => {
        return new Fuse(searchSet, {
            threshold: .3,
            keys: [
                {
                    name: 'title',
                    weight: 3
                },
                {
                    name: 'group.name',
                    weight: 2
                },
                'body',
                'url'
            ]
        })
    }, [ searchSet ])

    const [ results, setResults ] = useState<ISearchResults[]>()
    useEffect(() => {
        if (!searchTerm) {
            setResults([])
            return
        }

        setResults(
            fuzeInstance.search(searchTerm)
                .reduce<ISearchResults[]>(
                    (_results, nextResult) => {
                        const _groupResults = _results.find(o => o.group.slug === nextResult.item.group.slug)
                        if (_groupResults) {
                            _groupResults.results.push(nextResult)
                        } else {
                            _results.push({
                                group: nextResult.item.group,
                                results: [ nextResult ]
                            })
                        }
                        return _results
                    },
                    []
                )
                .sort((a, b) => (a.group.globalSortOrder || 0) - (b.group.globalSortOrder || 0))
        )
    }, [ searchTerm, fuzeInstance ])

    return (
        <MobileViewContainer>
            <div style={{ display: 'flex', padding: theme.spacing(1) }}>
                <TextField
                    value={searchTerm}
                    autoFocus
                    variant='outlined'
                    size='small'
                    placeholder='Search resources...'
                    style={{ flex: 1 }}
                    onChange={(ev) => setSearchTerm(ev.currentTarget.value)}
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', padding: theme.spacing(2) }}>
                {results?.map(o =>
                    <div key={o.group.slug} style={{ marginBottom: theme.spacing(2) }}>
                        <div style={{ display: 'flex', color: o.group.backgroundColor || undefined, alignItems: 'center', marginBottom: theme.spacing(1) }}>
                            {o.group.iconType && <IconTypeDisplay size={theme.spacing(4)} type={o.group.iconType} style={{ marginRight: theme.spacing(1) }} />}
                            <h3 style={{ marginBlock: 0 }}>{o.group.name}</h3>
                        </div>
                        <div style={{ paddingLeft: theme.spacing(1) }}>
                            {o.results.map(r => <SearchItem key={r.item.id} {...r.item} schoolSlug={slug} color={o.group.backgroundColor || undefined} />)}
                        </div>
                    </div>
                )}
            </div>
        </MobileViewContainer>
    )
}

interface IISearchItemProps extends ISearchItem {
    color?: string
    schoolSlug: string
}

const SearchItem = ({ title, type, resourceType, color, schoolSlug, id, url }: IISearchItemProps) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const iconStyles: CSSProperties = {
        // color: theme.palette.grey[ 800 ]
        color
    }

    const icon = (() => {
        if (type === 'person') return <AccountCircle style={iconStyles} />
        switch (resourceType) {
            case ResourceType.Folder:
                return <FolderOutlined style={iconStyles} />
            case ResourceType.Link:
                return <LinkOutlined style={iconStyles} />
            case ResourceType.Page:
                return <LibraryBooksOutlined style={iconStyles} />
        }
    })()

    const view = (
        <div
            style={{ display: 'flex', alignItems: 'center', borderTop: `1px solid ${theme.palette.grey[ 400 ]}`, minHeight: 40 }}
            onClick={() => {
                if (type === 'person') {
                    navigate(`/school/${schoolSlug}/directory/${id}`)
                } else if (resourceType !== ResourceType.Link) {
                    navigate(`/school/${schoolSlug}/resources/${id}`)
                }
            }}
        >
            {icon}
            <div style={{ marginLeft: theme.spacing(.5), flex: 1, color }}>{title}</div>
            <ArrowForwardIos style={{ height: theme.spacing(2), color: theme.palette.grey[ 600 ] }} />
        </div>
    )

    if (resourceType === ResourceType.Link) {
        return (
            <a
                target='_blank'
                href={url || ''}
            >
                {view}
            </a>
        )
    }

    return view
}

interface ISearchResults {
    group: ISearchItem[ 'group' ]
    results: Fuse.FuseResult<ISearchItem>[]
}

interface ISearchItem {
    id: string
    title?: string | null
    type: 'resource' | 'person'
    group: {
        globalSortOrder?: number | null
        slug?: string | null
        name?: string | null
        iconType?: IconType | null
        backgroundColor?: string | null
    }
    body?: string | null
    resourceType?: ResourceType
    url?: string | null
}