import { useField } from 'formik'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { SchoolDivisionsFragment } from '../../graphql/autogenerate/operations'
import { useGroupSelector } from '../../hooks'
import { GroupSelector } from '../group-selector'
import { IField } from './interfaces'

interface IFormikGroupSelector {
    fieldProps: IField
    searchPlaceholder?: string
    helperText?: string
    divisions?: SchoolDivisionsFragment[]
}

export const FormikGroupSelector = ({ fieldProps, divisions, searchPlaceholder, helperText }: IFormikGroupSelector) => {
    const [ field, meta, helpers ] = useField<string[] | undefined>(fieldProps)

    const groupSelector = useGroupSelector({ divisions, initialSelectedGroupIds: field.value })

    const handleOnBlur = useCallback((ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        field.onBlur(ev)
        helpers.setTouched(true)
    }, [])

    useEffect(() => {
        helpers.setValue(groupSelector.groupSelectorState.selectedGroupIds)
    }, [ groupSelector.groupSelectorState.selectedGroupIds ])


    useEffect(() => {
        if (!isEqual(field.value, groupSelector.groupSelectorState.selectedGroupIds)) groupSelector.setSelectedGroupIds(field.value || [])
    }, [ field.value ])

    return (
        <GroupSelector
            groupSelector={groupSelector}
            label={fieldProps.label}
            helperText={helperText}
            searchPlaceholder={searchPlaceholder}
            error={meta.error}
            initialError={meta.initialError}
            touched={meta.touched}
            onBlur={handleOnBlur}
        />
    )
}