import { Typography, useTheme } from '@material-ui/core'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import { usePublishedGroupResourcesQuery } from '../graphql/autogenerate/react-query'
import { ResourceType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { ResourceDetail } from './resource-detail'
import { ResourceTile } from './resource-tile'

interface IState {
    selectedResourceId?: string
}

interface IGroupResourcesDisplayProps {
    groupId: string
}

export const GroupResourcesDisplay = ({ groupId }: IGroupResourcesDisplayProps) => {
    const { state: { school: { slug } } } = useSchoolContext()
    const theme = useTheme()
    const { data: resourcesQuery } = useHandleReactQuery(usePublishedGroupResourcesQuery({ groupId }, { refetchInterval: REFETCH_INTERVAL }))

    const [ state, setState ] = useState<IState>({})
    const selectedResource = resourcesQuery?.resources?.nodes.find(o => o.id === state.selectedResourceId)

    const navigate = useNavigate()

    return (
        <>
            {resourcesQuery?.resources?.totalCount === 0 ?
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: theme.spacing(4) }}>
                    <Typography gutterBottom color='textSecondary' align='center'>No resources have been added yet.</Typography>
                    <Typography color='textSecondary' align='center'>Check back later!</Typography>
                </div>
                :
                <div style={{ display: 'grid', gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)', gridAutoRows: 130, columnGap: theme.spacing(1), rowGap: theme.spacing(1), flex: 1, marginTop: theme.spacing(1), maxWidth: '100%' }}>
                    {resourcesQuery?.resources?.nodes.map(o => {
                        const tile =
                            <ResourceTile
                                key={o.id}
                                resourceItem={o}
                                onClick={() => {
                                    if (o.type === ResourceType.Link && o.url) {
                                        // window.open(o.url, '_blank')
                                    } else if (o.type === ResourceType.InternalLink && o.url) {
                                        navigate(`/school/${slug}${o.url}`)
                                    } else {
                                        setState(_state => ({ ..._state, selectedResourceId: o.id }))
                                    }
                                }}
                                color={resourcesQuery.group?.groupCategory?.iconBackgroundColor || theme.palette.primary.main}
                            />

                        if (o.type === ResourceType.Link && o.url) {
                            return (
                                <a
                                    key={o.id}
                                    href={o.url}
                                    target='_blank'
                                    style={{ gridColumnEnd: o.header ? 'span 2' : undefined, }}
                                >
                                    {tile}
                                </a>
                            )
                        } else {
                            return tile
                        }
                    })
                    }
                </div>
            }

            {selectedResource &&
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
                    <ResourceDetail
                        resource={selectedResource}
                        onClosePressed={() => setState(_state => ({ ..._state, selectedResourceId: undefined }))}
                    />
                </div>
            }
        </>
    )
}