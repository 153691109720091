import { Box, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useCurrentUserSessionQuery, useUpdateCurrentUserSessionDataMutation } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { IGroupOption } from '../hooks/use-group-selector'
import { IUserSessionState } from '../types'
import { IconTypeDisplay } from './icon-type-display'
import { MemoizedSpringHeight } from './spring-height'

interface IGroupSelectorRecentsProps {
    groupOptions: IGroupOption[]
    selectedGroupIds?: string[]
    onGroupOptionSelected: (groupOption: IGroupOption) => void
}

/* 
    - Start with the most recent group selection loaded from user session state
    - As users selects options, filter out selected options from the list of recents
    - If the list of unselected recents is zero, collapse (use react spring to animate height to 0)
    - As groups are selected, persist the first three to the user's recent groups list
*/
export const GroupSelectorRecents = ({ groupOptions, selectedGroupIds, onGroupOptionSelected }: IGroupSelectorRecentsProps) => {
    const currentUserSessionQuery = useHandleReactQuery(useCurrentUserSessionQuery())
    const sessionData = currentUserSessionQuery.data?.currentUser?.userSession?.sessionData as IUserSessionState | undefined

    useEffect(() => {
        currentUserSessionQuery.refetch()
    }, [])

    const { mutate: updateSesionData } = useUpdateCurrentUserSessionDataMutation()

    useEffect(() => {
        if (selectedGroupIds && selectedGroupIds.length > 0) updateSesionData({ sessionDataPartial: { recentGroups: selectedGroupIds } })
    }, [ selectedGroupIds ])

    const recentGroups = groupOptions.filter(o => sessionData?.recentGroups?.includes(o.id) && !selectedGroupIds?.includes(o.id)).slice(0, 3)

    return (
        <MemoizedSpringHeight isOpen={recentGroups.length > 0}>
            <Box display='flex'>
                <Typography variant='caption' component='div' style={{ display: 'flex', alignItems: 'center' }}>Recents: </Typography>
                <Box display='flex' flexWrap='wrap' flex={1}>
                    {recentGroups.map(group =>
                        <Box key={group.id} ml={.5} mr={.5} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} whiteSpace='nowrap' onClick={() => onGroupOptionSelected(group)} >
                            <Box mr={.5} display='flex' alignItems='center'>
                                <IconTypeDisplay
                                    style={{ color: group.color }}
                                    size={15}
                                    type={group.iconType}
                                />
                            </Box>
                            {group.title}
                            {group.divisionName && !group.isDivision && ` (${group.divisionName})`}
                        </Box>
                    )}
                </Box>
            </Box>
        </MemoizedSpringHeight>
    )
}