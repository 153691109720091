import { useTheme } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { useMobileDetect } from '../hooks'
import { Tooltip } from './tooltip'

export const TooltipWrapper: React.FunctionComponent<{ tooltip: NonNullable<React.ReactNode>, style?: React.CSSProperties, iconStyle?: React.CSSProperties }> = ({ children, tooltip, style, iconStyle }) => {
    const theme = useTheme()
    const { isMobile } = useMobileDetect()

    if (isMobile()) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            <div style={{ display: 'flex', ...style }}>
                <div style={{ flex: 1 }}>{children}</div>
                <Tooltip title={tooltip}>
                    <HelpOutline style={{ color: theme.palette.grey[ 500 ], ...iconStyle }} />
                </Tooltip>
            </div>
        )
    }


}