import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import { CSSProperties } from 'react'
import { IField } from './interfaces'

interface IRadioOption {
    label: string
    value: string
    disabled?: boolean
}

interface IFormikRadioProps {
    field: IField
    options: IRadioOption[]
    column?: boolean
    style?: CSSProperties
}
export const FormikRadio = ({ field, options, column, style }: IFormikRadioProps) => {

    const [ formikField, meta ] = useField<string>(field)

    return (
        <FormControl disabled={field.disabled} error={meta.touched && Boolean(meta.error)} component='fieldset' style={style} >
            <FormLabel >{field.label}</FormLabel>
            <RadioGroup {...formikField} aria-label={field.name} name={field.name} row={!column}>
                {options.map(({ value, label, disabled }, index) => <FormControlLabel key={value} value={value} disabled={disabled} control={<Radio tabIndex={index} color='primary' />} label={label} />)}
            </RadioGroup>
            <FormHelperText style={{ marginTop: 0 }}>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    )
}