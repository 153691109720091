import { useTheme } from '@material-ui/core'
import { Apple, ArrowBackIos } from '@material-ui/icons'
import { Dayjs } from 'dayjs'
import { ReactNode } from 'react'
import { IPhone } from '../images'

interface ILockScreenNotificaton {
    schoolName: string
    displayTime?: Dayjs
    message: ReactNode
    notificationPressed: () => void
}

interface IDetailView {
    title: string
    body?: ReactNode
    onBackPressed?: () => void
}

interface IPhoneFrameProps {
    view: 'lock-screen' | 'detail'

    lockScreenNotification?: ILockScreenNotificaton

    detailView?: IDetailView
}

export const PhoneFrame = ({ view, lockScreenNotification, detailView }: IPhoneFrameProps) => {
    const theme = useTheme()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, minHeight: 0, }}>
            <div
                style={{
                    flex: 1,
                    position: 'relative',
                    width: 422,
                    maxHeight: 850,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <IPhone style={{ position: 'absolute', top: 0, left: 0 }} />

                {view === 'lock-screen' && lockScreenNotification &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 250,
                            left: 32,
                            width: '100%',
                            borderRadius: 10,
                            backgroundColor: 'white',
                            maxWidth: 360,
                            padding: 10,
                            cursor: 'pointer',
                        }}
                        onClick={lockScreenNotification.notificationPressed}
                    >
                        <div style={{ display: 'flex' }}>
                            <Apple style={{ marginRight: theme.spacing(.5) }} />
                            <div style={{ flex: 1, paddingTop: 2, marginRight: theme.spacing(.5), color: theme.palette.grey[ 700 ], whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{lockScreenNotification.schoolName.toUpperCase()}</div>
                            <div style={{ color: theme.palette.grey[ 700 ] }}>{lockScreenNotification.displayTime ? lockScreenNotification.displayTime.format('M/D/YYYY @ h:mma') : 'now'}</div>
                        </div>
                        <div style={{ marginTop: theme.spacing(1) }}>
                            {lockScreenNotification.message}
                        </div>
                    </div>
                }

                {view === 'detail' &&
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 0,
                            maxHeight: 740,
                            zIndex: 1,
                            margin: '53px 25px 0 25px',
                            backgroundColor: 'white',
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                color: theme.palette.primary.contrastText,
                                backgroundColor: theme.palette.primary.main,
                                height: 40,
                                minHeight: 40,
                                padding: `0 ${theme.spacing(1)}px`
                            }}
                        >
                            {detailView?.onBackPressed &&
                                <div
                                    onClick={detailView?.onBackPressed}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        left: theme.spacing(1),
                                        top: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <ArrowBackIos />
                                    <div>Back</div>
                                </div>
                            }
                            <div
                                style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <b>{detailView?.title}</b>
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                padding: theme.spacing(1),
                                zIndex: 1,
                                backgroundColor: 'white',
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: 0,
                                overflowY: 'auto',
                            }}
                        >
                            {detailView?.body}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}