import dayjs, { Dayjs } from 'dayjs'
import { TimePickerIncrement } from '../components/forms'

/** 
    Returns a Dayjs instance with minutes set to the next (future) increment of the passed increment size.

    @example
    const now = dayjs()

    // now.format('h:mm') -> 5:05

    const nextTime = getNextTimeFromNowForIncrement(15, now)

    // nextTime.format('h:mm') -> 5:15
*/
export const getNextTimeForIncrement = (increment: TimePickerIncrement, time: Dayjs = dayjs()) => {
    return time
        .startOf('hour')
        .add(increment * (Math.floor((time.minute() / increment)) + (time.minute() % increment === 0 ? 0 : 1)), 'minutes')
}

/**
    __NOTE: only supports 15 min increments for the first hour and 30 min increments thereafter__
*/
export const getLabelForMinutes = (minutes: number) => {
    if (minutes < 60) {
        return `${minutes} min${minutes === 1 ? '' : 's'}`
    } else if (minutes === 60) {
        return '1 hr'
    } else {
        return `${Math.floor(minutes / 60)}${minutes % 60 >= 30 ? '.5' : ''} hrs`
    }
}

/** 
    Extracts (and returns) a valid hour/minute string (in `h:mma` format) from an input string.

    As a rule of thumb, we're permissive when it's unlikely a user was actually trying to do something (e.g. multiple `:`) and restrictive when it's likely they were (e.g. both an `a` and a `p` for potential `AM` or `PM`).

    Validation
    - Ensures hour input is `24` or less
    - Ensures minute input is `60` or less
    - Supports `24-hour` time input format. Converts to `12-hour` with `am/pm`
    - Limits to a max of 4 digits
    - Appends `0`s to bring total digit count to 3
    - Requires at least one number to be valid
    - Returns `false` if a valid hour/minute can't be extracted or would be too wild to guess (e.g. `43:53`)

    AM/PM
    - Accepts one `a`, `p`, and/or `m` anywhere in the string. 
    - Rejects if there is more than one `a` and/or `p` (no user would intend to have both `a` and `p`)
    - When returning, places `am`/`pm` at the end of the string as full `am` or `pm`.
    - Strips `m` input if there is no `a` or `p`.

    Colon:
    - Accepts optional `:`
    - Includes `:` in the final output after the 1st digit if 3 or fewer digits provided, after the 4th if 4
*/
export const extractHourMinuteInput = (input?: string) => {
    // If no input, invalid
    if (!input || input.match(/[0-9]/g) === null) return false

    // If no numbers or more than 4, invalid
    const numbers = input.match(/[0-9]/g)
    if (numbers === null || numbers.length > 4) return false

    // Get all 'a's and 'p's, use the first of either and ignore the rest
    // If there user provided more than one 'a' and/or 'p', invalid
    const extractAmPm = input.toLowerCase().match(/[ap]/g)
    if (extractAmPm && extractAmPm.length > 1) return false

    // Extract numbers and colons from the string (we need to know the position of the colon)
    // From here on it's safe to '!' these results since we already checked above for at least one number present in the string
    const extractNumbersAndColon = input.match(/[:0-9]/g)

    // See if we have a colon (use the first one and ignore any others)
    const colonIndex = extractNumbersAndColon!.indexOf(':')

    // If the colon is more then 2 characters in, invalid
    if (colonIndex > 2) return false

    if (numbers.length < 3) numbers.push(...[ '0', '0' ])

    numbers.splice(colonIndex !== -1 ? colonIndex : numbers.length === 3 ? 1 : 2, 0, ':')

    // Create the numbers portion of the final string
    const hoursAndMinutes = numbers.join('')

    // Final test
    if (!new RegExp(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/).test(hoursAndMinutes)) return false

    return `${hoursAndMinutes}${extractAmPm ? `${extractAmPm[ 0 ]}m` : dayjs().format('a')}`
}