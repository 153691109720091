/* eslint-disable react-hooks/exhaustive-deps */
import { SvgIconComponent } from '@material-ui/icons'
import { isEqual } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import { createContext, useContext, useState } from 'react'

interface IBreadcrumb {
    id: string
    /**
        The _relative_ path, __NOT__ the absolute path.

        The breadcrumbs are intended to "stack" one another.

        Any component that displays them should compose a link for any given breadcrumb concatenating the path for all preceding breadcrumbs.

        @example
        const crumb = {
            path: 'events',
            label: 'Events',
            Icon: Event
        }
     */
    relativePath?: string
    label?: string
    Icon?: SvgIconComponent
}

interface IBreadcrumbState {
    crumbs: IBreadcrumb[]
}

interface IBreadcrumbActions {
    removeBreadcrumb: (crumb: IBreadcrumb) => void
    updateBreadcrumb: (crumb: IBreadcrumb) => void
}

const BreadcrumbContext = createContext<{ actions: IBreadcrumbActions, state: IBreadcrumbState } | undefined>(undefined)
const useBreadcrumbContext = () => {
    const context = useContext(BreadcrumbContext)
    if (!context) throw new Error('Attempted to use BreadcrumbContext before it has been provided.')
    return context
}

const breadcrumbMap = new Map<string, IBreadcrumb>()

/**
    Quick and easy way to register a breadcrumb for the life of a component.
    - Intended to be used by any components that handle a route that you want to be added to the breadcrumb trail.
    - Breadcrumbs registered this way will automatically be removed when the component is unounted.
    - Automatically handles the breadcrumb order - breadcrumbs added sooner will be put at the front of the list.

    @example
    // admin-events.tsx

    import { useRegisterBreadcrumb } from './hooks'
    import { Event } from 'material-ui/icons'

    export const AdminEvents = () => {
        useRegisterBreadcrumb({
            path: 'events'
            label: 'Events'
            Icon: Event
        })
    
        return ...
    }
**/
export const useRegisterBreadcrumb = (crumb: Omit<IBreadcrumb, 'id'>) => {
    const context = useBreadcrumbContext()

    const [ _crumb, setCrumb ] = useState({ ...crumb, id: `${crumb.relativePath}-${crumb.label}` })

    breadcrumbMap.set(_crumb.id, _crumb)

    useEffect(() => {
        return () => context.actions.removeBreadcrumb(_crumb)
    }, [])

    useEffect(() => {
        if (!isEqual(_crumb, { ...crumb, id: _crumb.id })) setCrumb({ ...crumb, id: _crumb.id })
    }, [ crumb ])

    useEffect(() => {
        context.actions.updateBreadcrumb(_crumb)
    }, [ _crumb ])

    return _crumb
}

export const useBreadcrumbs = () => {
    const breadcrumbs = Array.from(breadcrumbMap.values())

    /**
        Use this function to "stack" up the links for crumbs in a list.
        - The final crumb can just use its relative link
        - Each crumb other than the final crumb needs to link "up" the path however many steps it is away from the last crumb

        E.g.
        - If a crumb is the last crumb its link should be nothing other than its relativePath
        - If a crumb is the 3rd in a list of 5 crumbs, its link should have '../../' prepended to its relativePath
    **/
    const composeCrumbLink = (crumb: IBreadcrumb) => {
        const totalCrumbCount = breadcrumbs.length
        const crumbIdx = breadcrumbs.findIndex(c => c.relativePath === crumb.relativePath)
        let link = ''
        for (let linkLevels = 0; linkLevels < totalCrumbCount - 1 - crumbIdx; linkLevels++) {
            link += '../'
        }
        return `${link}${crumb.relativePath}`
    }

    return {
        breadcrumbs,
        composeCrumbLink
    }
}


export const BreadcrumbProvider: FunctionComponent = ({ children }) => {
    const [ state, setState ] = useState<IBreadcrumbState>({ crumbs: [] })

    const removeBreadcrumb = (crumb: IBreadcrumb) => {
        breadcrumbMap.delete(crumb.id)
        setState(({ crumbs }) => {
            const _crumbs = [ ...crumbs ]
            const crumbIdx = _crumbs.findIndex(c => c.id === crumb.id)
            if (crumbIdx !== -1) _crumbs.splice(crumbIdx, 1)
            return { crumbs: _crumbs }
        })
    }

    const updateBreadcrumb = (crumb: IBreadcrumb) => {
        if (breadcrumbMap.has(crumb.id)) breadcrumbMap.set(crumb.id, crumb)
        setState(({ crumbs }) => {
            const _crumbs = [ ...crumbs ]
            const crumbIdx = _crumbs.findIndex(c => c.id === crumb.id)
            if (crumbIdx !== -1) _crumbs.splice(crumbIdx, 1, crumb)
            return { crumbs: _crumbs }
        })
    }

    const actions: IBreadcrumbActions = {
        removeBreadcrumb,
        updateBreadcrumb
    }

    return (
        <BreadcrumbContext.Provider value={{ state, actions }}>
            {children}
        </BreadcrumbContext.Provider>
    )
}