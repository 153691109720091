import { useParams } from 'react-router-dom'
import { useGetNewsletterBySlugQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { NewsletterDisplay } from './newsletter-display'

interface INewsletterPublicProps {

}

export const NewsletterPublic = ({ }: INewsletterPublicProps) => {
    const { newsletterSlug } = useParams()

    const { data } = useHandleReactQuery(useGetNewsletterBySlugQuery(
        { slug: newsletterSlug },
        { enabled: Boolean(newsletterSlug), refetchInterval: 3000 }
    ))
    const newsletter = data?.getNewsletterBySlug

    if (!newsletter) return null

    return <NewsletterDisplay newsletter={newsletter} />
}
