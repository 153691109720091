import { SchoolListFieldsFragment } from '../graphql/autogenerate/operations'
import {
	Card,
	CardActionArea,
	CardMedia,
	Fade,
	Typography,
	useTheme,
} from '@material-ui/core'
import { memo, useCallback } from 'react'
import { List, AutoSizer, ListRowProps } from 'react-virtualized'
import {
	Check,
	Close,
	Remove,
	School,
	PhoneAndroid,
	PhoneIphone,
} from '@material-ui/icons'
import { Tooltip } from './tooltip'
import dayjs from 'dayjs'

interface ISchoolListProps {
	schools: SchoolListFieldsFragment[]
	onSchoolPressed: (school: SchoolListFieldsFragment) => void
}

const CARD_WIDTH = 200

interface ISchoolRowRendererProps extends ListRowProps {
	itemsPerRow: number
}

/** 
    Uses ReactVirtualized to render quickly since we list all schools at once and do local search.
*/
export const SchoolList = memo(
	({ schools, onSchoolPressed }: ISchoolListProps) => {
		const theme = useTheme()

		const rowRenderer = useCallback(
			({ index, key, style, itemsPerRow }: ISchoolRowRendererProps) => {
				const items = []
				const fromIndex = index * itemsPerRow
				const toIndex = Math.min(fromIndex + itemsPerRow, schools.length)

				for (let i = fromIndex; i < toIndex; i++) {
					const school = schools[i]

					const title = (
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-end',
								height: '100%',
								width: '100%',
							}}
						>
							<div
								style={{
									backgroundColor: `${theme.palette.grey[800]}95`,
									flex: 1,
									padding: theme.spacing(1),
								}}
							>
								<Typography style={{ color: 'white' }}>
									{school.name}
								</Typography>
								<Typography
									variant='body2'
									color='textSecondary'
									style={{ color: 'white' }}
								>
									{school.city}
									{school.city && school.state && ', '}
									{school.state}
								</Typography>
							</div>
						</div>
					)

					const status = (() => {
						if (school.isLaunched && school.isMigrated) {
							const appleDeveloperAccountIsExpired = Boolean(
								school.appleDeveloperAccountExpiry &&
									dayjs(school.appleDeveloperAccountExpiry).isBefore(
										dayjs(),
										'd'
									)
							)

							return (
								<>
									<Tooltip
										title={(() => {
											if (!school.iosAppIsLatest)
												return 'iOS app needs to be updated'
											if (appleDeveloperAccountIsExpired)
												return 'Apple developer account is expired'
											return 'iOS app is up to date'
										})()}
									>
										<PhoneIphone
											style={{
												position: 'absolute',
												top: theme.spacing(0.5),
												right: theme.spacing(0.5),
												zIndex: 1,
												color:
													!school.iosAppIsLatest ||
													appleDeveloperAccountIsExpired
														? theme.palette.error.main
														: theme.palette.success.main,
											}}
										/>
									</Tooltip>

									<Tooltip
										title={
											school.androidAppIsLatest
												? 'Android app is up to date'
												: 'Android app needs to be updated'
										}
									>
										<PhoneAndroid
											style={{
												position: 'absolute',
												top: theme.spacing(0.5),
												right: theme.spacing(0.5) + 24,
												zIndex: 1,
												color: school.androidAppIsLatest
													? theme.palette.success.main
													: theme.palette.error.main,
											}}
										/>
									</Tooltip>
								</>
							)
						}

						if (school.isLaunched && !school.isMigrated)
							return (
								<Tooltip title='Launched but not migrated from Mastodon'>
									<Close
										style={{
											position: 'absolute',
											top: theme.spacing(0.5),
											right: theme.spacing(0.5),
											zIndex: 1,
											color: theme.palette.error.main,
										}}
									/>
								</Tooltip>
							)
						if (!school.isLaunched) {
							return (
								<Tooltip title='Not yet launched'>
									<Remove
										style={{
											position: 'absolute',
											top: theme.spacing(0.5),
											right: theme.spacing(0.5),
											zIndex: 1,
											color: theme.palette.info.main,
										}}
									/>
								</Tooltip>
							)
						}
					})()

					items.push(
						<Card
							style={{
								width: CARD_WIDTH,
								height: CARD_WIDTH,
								marginBottom: theme.spacing(2),
								marginRight: theme.spacing(2),
								position: 'relative',
							}}
							key={school.id}
							onClick={() => onSchoolPressed(school)}
						>
							{status}

							<CardActionArea>
								{school.schoolSetting?.thumbnailImage?.temporaryDownloadUrl ? (
									<CardMedia
										style={{ height: CARD_WIDTH }}
										image={
											school.schoolSetting?.thumbnailImage?.temporaryDownloadUrl
										}
										title={
											school.schoolSetting?.thumbnailImage?.label || undefined
										}
										children={title}
									/>
								) : (
									<div
										style={{
											height: CARD_WIDTH,
											width: CARD_WIDTH,
											position: 'relative',
										}}
									>
										<School
											style={{
												height: CARD_WIDTH,
												width: CARD_WIDTH,
												padding: theme.spacing(1),
												position: 'absolute',
												color: theme.palette.grey[400],
												zIndex: 1,
											}}
										/>
										<div
											style={{
												zIndex: 2,
												position: 'absolute',
												bottom: 0,
												left: 0,
												right: 0,
											}}
										>
											{title}
										</div>
									</div>
								)}
							</CardActionArea>
						</Card>
					)
				}

				return (
					<div
						style={{ ...style, width: '100%', height: '100%', display: 'flex' }}
						key={key}
					>
						{items}
					</div>
				)
			},
			[schools]
		)

		if (schools.length === 0) {
			return (
				<Fade in={true} style={{ transitionDelay: '300ms' }} unmountOnExit>
					<div>No results.</div>
				</Fade>
			)
		}

		return (
			<div style={{ flex: 1 }}>
				<AutoSizer>
					{({ height, width }) => {
						const itemsPerRow = Math.floor(
							width / (CARD_WIDTH + theme.spacing(2))
						)
						const rowCount = Math.ceil(schools.length / itemsPerRow)

						return (
							<List
								className='List'
								width={width}
								height={height}
								rowCount={rowCount}
								rowHeight={CARD_WIDTH + theme.spacing(2)}
								rowRenderer={props => rowRenderer({ ...props, itemsPerRow })}
							/>
						)
					}}
				</AutoSizer>
			</div>
		)
	}
)
