import { Box, Typography, useTheme } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import clsx from 'clsx'
import React, { ReactNode, useCallback } from 'react'
import { GroupCategoryListFieldsFragment, GroupListFieldsFragment } from '../graphql/autogenerate/operations'
import { useCommonStyles } from '../styles'
import { IconTypeDisplay } from './icon-type-display'

interface IGroupCellProps {
    group: GroupListFieldsFragment
    category: GroupCategoryListFieldsFragment
    divisionName?: string
    onClick?: (group: GroupListFieldsFragment) => void
    sortingActive?: boolean // Used when this cell is part of a sortable list of Groups
    selected?: boolean
    showPasscodeLock?: boolean
}

export const GroupCell = React.memo(({ group, category, onClick, sortingActive, divisionName, selected, showPasscodeLock }: IGroupCellProps) => {
    const theme = useTheme()
    const commonStyles = useCommonStyles()

    const handleClick = useCallback(() => {
        if (onClick) onClick(group)
    }, [ onClick, group ])

    const color = selected ? 'white' : category.iconBackgroundColor || theme.palette.grey[ 500 ]
    const backgroundColor = selected ? category.iconBackgroundColor || theme.palette.grey[ 500 ] : 'white'

    return (
        <Box
            data-id={group.id}
            py={.5}
            px={2}
            mr={1}
            mb={1}
            display='flex'
            alignItems='center'
            style={{
                color,
                backgroundColor,
                border: `1px solid ${category.iconBackgroundColor || theme.palette.grey[ 500 ]}`,
                borderRadius: theme.shape.borderRadius,
                cursor: 'pointer',
                position: 'relative',
            }}
            className={clsx(!sortingActive && commonStyles.elevationOnHover)}
            onClick={handleClick}
        >
            <IconTypeDisplay size={24} type={group.iconType || category.iconType} style={{ marginRight: theme.spacing(1) }} />

            <div style={{marginRight: showPasscodeLock ? theme.spacing(1) : 0}}>
                <Typography style={{ display: 'flex' }}>
                    {group.groupName}
                </Typography>
                {divisionName && <Typography variant='caption' component='div' style={{ lineHeight: 1, paddingBottom: theme.spacing(.5) }}>{divisionName} {category.name}</Typography>}
            </div>

            {showPasscodeLock &&
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        color,
                        padding: 1,
                        top: 1,
                        right: 0,
                        zIndex: 10,
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    <Lock style={{ width: 15, height: 15 }} />
                </div>
            }
        </Box>
    )
})