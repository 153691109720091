import { useTheme } from '@material-ui/core'
import { DetailedHTMLProps, ImgHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { wait } from '../helpers'
import { Loading } from './loading'

interface IImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {

}

/* 
    May be stupid simple for now, but at least this way we have guaranteed universal control over all through this component.
*/
export const ImageDisplay = ({ style, src, ...rest }: IImageProps) => {
    const theme = useTheme()

    /* 
        In some cases, we try to display a freshly uploaded image almost instantly.
        When we do, it's a race between the server and the client to see whether the server
        can move the file from the /temp folder to the /perm folder before the client accesses
        the temporaryDownloadUrl, which will point to the /perm folder.

        If the client wins the race, the image won't be in the /perm folder in time
        and will fail to load.

        To handle that case, we go ahead and retry download the image up to 10 times with a 1 
        second delay to give the server up to 10 seconds to get the image moved over.
    */
    const [ loaded, setLoaded ] = useState(true)
    const retryCount = useRef(1)
    const retryDelay = useRef(10)
    const onImageLoadError = async () => {
        if (src && retryCount.current <= 10) {
            setLoaded(false)
            await wait(retryDelay.current)
            const img = new Image()
            img.onerror = onImageLoadError
            img.onload = () => setLoaded(true)
            img.src = src

            retryCount.current += 1
            retryDelay.current = retryDelay.current * 2
        } else {
            setLoaded(true)
        }
    }

    if (!loaded) return (
        <div style={{ width: '100%', ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: theme.shape.borderRadius, border: `1px solid ${theme.palette.grey[ 300 ]}` }} {...rest}>
            <Loading size='xs' />
        </div>
    )

    return (
        <img
            src={src}
            onError={onImageLoadError}
            style={{ objectFit: 'cover', width: '100%', ...style }}
            {...rest}
        />
    )
}