import { createStyles, makeStyles, Typography, useTheme } from '@material-ui/core'
import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import { SponsorFragment } from '../graphql/autogenerate/operations'
import { useSchoolSponsorsQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { ImageDisplay } from './image-display'
import { useMobileContext } from './school'

const useStyles = makeStyles(theme =>
    createStyles({
        body: {
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            flex: 1,
            color: theme.palette.grey[ 700 ],
        }
    })
)


export const SchoolSponsorsList = () => {
    const theme = useTheme()
    const { state: { school: { id: schoolId, schoolSetting } } } = useSchoolContext()
    const { data } = useHandleReactQuery(useSchoolSponsorsQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL }))
    const gold = data?.gold?.nodes
    const silver = data?.silver?.nodes
    const bronze = data?.bronze?.nodes

    const { setState: setMobileState, state } = useMobileContext()

    const previousTitle = useRef(state.navbar.title)
    useEffect(() => {
        setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, title: 'Sponsors', backUrl: 'home' } }))

        return () => {
            setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, title: previousTitle.current, backUrl: undefined } }))
        }
    }, [])

    return (
        <div style={{ padding: theme.spacing(1), paddingBottom: 63 }}>
            {Boolean(gold?.length) && gold && <SponsorsListSection title={schoolSetting?.goldSponsorLabel || 'Gold'} sponsors={gold} />}
            {Boolean(silver?.length) && silver && <SponsorsListSection title={schoolSetting?.silverSponsorLabel || 'Silver'} sponsors={silver} />}
            {Boolean(bronze?.length) && bronze && <SponsorsListSection title={schoolSetting?.bronzeSponsorLabel || 'Bronze'} sponsors={bronze} />}
        </div>
    )
}

interface ISponsorsListSectionProps {
    title: string
    sponsors: SponsorFragment[]
}
const SponsorsListSection = ({ title, sponsors }: ISponsorsListSectionProps) => {
    const styles = useStyles()
    const theme = useTheme()
    const navigate = useNavigate()
    const { setState: setMobileState } = useMobileContext()
    return (
        <>
            <Typography style={{ fontWeight: 700 }} gutterBottom variant='h5'>{title}</Typography>
            {sponsors.filter(o => o.published).map(sponsor =>
                <div
                    onClick={() => {
                        setMobileState(_state => ({ ..._state, navbar: { ..._state.navbar, backUrl: 'sponsors' } }))
                        navigate(sponsor.slug)
                    }}
                    key={sponsor.id}
                    style={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1), minHeight: 100 }}
                >
                    <ImageDisplay style={{ width: 100, minWidth: 100, objectFit: 'contain', padding: theme.spacing(1) }} src={sponsor.thumbnailImage?.temporaryDownloadUrl} />
                    <div>
                        <b>{sponsor.title}</b>
                        {sponsor.body && <div className={styles.body} dangerouslySetInnerHTML={{ __html: sponsor.body || '' }} />}
                    </div>
                </div>
            )}
        </>
    )
}