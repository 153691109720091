import {
	Button,
	FormHelperText,
	IconButton,
	Tooltip,
	Typography,
	createStyles,
	makeStyles,
	useTheme,
} from '@material-ui/core'
import {
	Add,
	BurstModeOutlined,
	Close,
	ImageOutlined,
	PanoramaOutlined,
} from '@material-ui/icons'
import clsx from 'clsx'
import { FieldArray, Form, Formik } from 'formik'
import { MutableRefObject, useMemo } from 'react'
import { v4 } from 'uuid'
import * as yup from 'yup'
import {
	useCreatePicturePageSectionMutation,
	useDeleteTemporaryAwsS3UploadMutation,
	useUpdatePageSectionSortOrderMutation,
	useUpdatePicturePageSectionMutation,
} from '../graphql/autogenerate/react-query'
import {
	PageSectionFilePageSectionIdFkeyInverseInput,
	PageSectionFilePageSectionIdFkeyPageSectionFileCreateInput,
} from '../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../hooks'
import { useButtonStyles } from '../styles'
import {
	FileFieldValue,
	FormikImageField,
	FormikPatchTouched,
	FormikTextInput,
	isFileFragment,
	isTempUploadedFile,
} from './forms'
import { FormikRichText } from './forms/formik-rich-text'
import { usePageContext } from './page-edit'

const useStyles = makeStyles(theme =>
	createStyles({
		deleteIcon: {
			position: 'absolute',
			top: 0,
			right: 0,
			backgroundColor: `${theme.palette.grey[300]}`,
			transition: 'opacity .2s ease-in-out',
			opacity: 0.5,
			'&:hover': {
				backgroundColor: `${theme.palette.grey[300]}`,
				opacity: 1,
			},
		},
	})
)

interface IPageSectionPictureFormProps {
	submitFormRef: MutableRefObject<(() => Promise<any>) | undefined>
}

interface IFormValues {
	type: 'cover' | 'set' | 'full' | null
	images: {
		id: string
		file: FileFieldValue
		title?: string | null
		description?: string | null
	}[]
	coverImage: { file: FileFieldValue; caption?: string | null } | null
}

export const PageSectionPictureForm = ({
	submitFormRef,
}: IPageSectionPictureFormProps) => {
	const theme = useTheme()
	const styles = useStyles()
	const buttonStyles = useButtonStyles()

	const {
		pageId,
		state: { newPageSection, pageSectionToEdit },
		refetchPageSections,
		pageSectionFormModal,
	} = usePageContext()

	const { mutateAsync: updateSortOrder } = useHandleReactQueryMutation(
		useUpdatePageSectionSortOrderMutation()
	)
	const { mutate: createPageSection } = useHandleReactQueryMutation(
		useCreatePicturePageSectionMutation({
			onSuccess: async data => {
				if (
					newPageSection?.sortOrder !== undefined &&
					data.createPageSection?.pageSection?.id
				)
					await updateSortOrder({
						pageSectionId: data.createPageSection?.pageSection?.id,
						newSortOrder: newPageSection.sortOrder,
					})
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	const { mutate: updatePageSection } = useHandleReactQueryMutation(
		useUpdatePicturePageSectionMutation({
			onSuccess: async () => {
				await refetchPageSections()
				pageSectionFormModal.close()
			},
		})
	)

	const { mutateAsync: deleteTemporaryUpload } =
		useDeleteTemporaryAwsS3UploadMutation()

	const initialValues: IFormValues = useMemo<IFormValues>(() => {
		if (!pageSectionToEdit)
			return {
				type: null,
				images: [],
				coverImage: { file: undefined, caption: '' },
			} as IFormValues

		const cover = pageSectionToEdit.pageSectionFiles.nodes.find(o => o.cover)
		if (cover) {
			return {
				type: 'cover',
				images: [],
				coverImage: {
					file: cover.file || undefined,
					caption: cover.description,
				},
			} as IFormValues
		} else {
			return {
				type: 'set',
				images: pageSectionToEdit.pageSectionFiles.nodes.map(image => ({
					file: image.file || undefined,
					title: image.title,
					description: image.description,
					id: image.id,
				})),
				coverImage: null,
			} as IFormValues
		}
	}, [pageSectionToEdit])

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={yup.object({
				type: yup.string().required('Required'),
				coverImage: yup
					.mixed()
					.test('coverImage.file', 'Please upload an image.', function (value) {
						if (this.parent.type === 'cover' && !value.file)
							return this.createError({
								message: 'Please upload an image.',
								path: 'coverImage.file',
							})
						return true
					}),
				images: yup
					.array()
					.of(
						yup.object({
							file: yup.mixed().test({
								name: 'file',
								test: function (value) {
									if (!value)
										return this.createError({
											message: 'Please upload an image.',
											path: this.path,
										})
									return true
								},
							}),
						})
					)
					.test(
						'images',
						'An image section must have at least one image. If you want to remove all images, you can delete the section.',
						function (value) {
							if (this.parent.type === 'set' && value?.length === 0) return false
							return true
						}
					),
			})}
			onSubmit={async values => {
				if (pageSectionToEdit) {
					/* 
                        The existing page section to edit will have existing 'pageSectionFiles' on it.

                        We need to compare the existing ones with the final form values - 
                        if any were removed they need to go into the 'deleteById' part of the payload.

                        If any were added they need to go into the 'create' section.

                        Otherwise we need to update them.
                    */
					const pageSectionFiles: PageSectionFilePageSectionIdFkeyInverseInput = {
						deleteById: [],
						create: [],
						updateById: [],
					}

					if (values.type === 'cover' || values.type === 'full') {
						// Was there an existing cover image? It shouldn't be possible for there not to be one if values.type was set to 'cover'...
						const cover = pageSectionToEdit.pageSectionFiles.nodes.find(o => o.cover)
						if (cover) {
							// There is a cover. Was the image changed? If so, delete it and create a new one.
							if (
								isTempUploadedFile(values.coverImage?.file) ||
								!Boolean(values.coverImage?.file)
							) {
								pageSectionFiles.deleteById?.push({ id: cover.id })
								if (values.coverImage?.file) {
									pageSectionFiles.create?.push({
										file: {
											create: {
												filename: values.coverImage.file.filename,
												type: values.coverImage.file.type,
											},
										},
										description: values.coverImage.caption?.length
											? values.coverImage.caption
											: null,
										cover: true,
									})
								}
							} else if (values.coverImage) {
								// The image didn't change...just update the description.
								pageSectionFiles.updateById?.push({
									id: cover.id,
									patch: {
										description: values.coverImage.caption?.length
											? values.coverImage.caption
											: null,
									},
								})
							}
						} else {
							/* 
                                There wasn't an existing cover image. But that should have been impossible when editing a 'cover' Picture Page Section...
                                We don't support this...
                            */
						}
					}

					if (values.type === 'set') {
						/* 
                            Delete any existing images that:
                            - Aren't in the list of images on the form
                            - Had their file changed
                        */
						pageSectionToEdit.pageSectionFiles.nodes.forEach(image => {
							if (
								!values.images.some(
									o => isFileFragment(o.file) && o.file.id === image.file?.id
								)
							) {
								pageSectionFiles.deleteById?.push({ id: image.id })
							}
						})

						/* 
                            For each image in the form:
                            - If a new upload, add it to the creates
                            - If an existing file, add it to the updates
                        */
						values.images.forEach(image => {
							const file = image.file
							if (isTempUploadedFile(file)) {
								pageSectionFiles.create?.push({
									file: {
										create: { filename: file.filename, type: file.type },
									},
									description: image.description?.length ? image.description : null,
									title: image.title?.length ? image.title : null,
								})
							}

							if (isFileFragment(file)) {
								const existingFile = pageSectionToEdit.pageSectionFiles.nodes.find(
									o => o.file?.id === file.id
								)
								if (existingFile) {
									pageSectionFiles.updateById?.push({
										id: existingFile.id,
										patch: {
											description: image.description?.length ? image.description : null,
											title: image.title?.length ? image.title : null,
										},
									})
								}
							}
						})
					}

					updatePageSection({
						id: pageSectionToEdit.id,
						pageSectionFiles,
					})
				} else {
					// Whew...the easy one. We just need to create everything.
					const pageSectionFiles: PageSectionFilePageSectionIdFkeyInverseInput = {}
					if (
						(values.type === 'cover' || values.type === 'full') &&
						values.coverImage &&
						isTempUploadedFile(values.coverImage?.file)
					) {
						pageSectionFiles.create = [
							{
								file: {
									create: {
										filename: values.coverImage.file.filename,
										type: values.coverImage.file.type,
									},
								},
								description: values.coverImage.caption?.length
									? values.coverImage.caption
									: null,
								cover: true,
							},
						]
					}
					if (values.type === 'set') {
						pageSectionFiles.create = values.images.reduce((create, image) => {
							if (image.file && isTempUploadedFile(image.file)) {
								create.push({
									file: {
										create: {
											filename: image.file.filename,
											type: image.file.type,
										},
									},
									description: image.description?.length ? image.description : null,
									title: image.title?.length ? image.title : null,
								})
							}
							return create
						}, [] as PageSectionFilePageSectionIdFkeyPageSectionFileCreateInput[])
					}

					createPageSection({
						pageSectionFiles,
						pageId,
					})
				}
			}}
		>
			{formikProps => {
				submitFormRef.current = formikProps.values.type
					? formikProps.submitForm
					: undefined
				return (
					<Form style={{ display: 'flex', flexDirection: 'column' }}>
						<FormikPatchTouched />
						<FieldArray
							name='images'
							children={arrayHelpers => (
								<>
									{!formikProps.values.type && (
										<div
											style={{
												flex: 1,
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography variant='h6' style={{ marginBottom: theme.spacing(2) }}>
												What type of image section would you like to add?
											</Typography>

											<div
												style={{
													display: 'flex',
													justifyContent: 'space-around',
												}}
											>
												<div style={{ margin: theme.spacing(2) }}>
													<div
														className={buttonStyles.iconButton}
														onClick={() => formikProps.setFieldValue('type', 'full')}
													>
														<ImageOutlined style={{ fontSize: '3rem' }} />
														<Typography>Image</Typography>
													</div>
												</div>
												<div style={{ margin: theme.spacing(2) }}>
													<div
														className={buttonStyles.iconButton}
														onClick={() => formikProps.setFieldValue('type', 'cover')}
													>
														<PanoramaOutlined style={{ fontSize: '3rem' }} />
														<Typography>Cover Image</Typography>
													</div>
												</div>
												<div style={{ margin: theme.spacing(2) }}>
													<div
														className={buttonStyles.iconButton}
														onClick={() => {
															formikProps.setFieldValue('type', 'set')
															arrayHelpers.push({
																file: undefined,
																title: '',
																description: '',
																id: v4(),
															})
														}}
													>
														<BurstModeOutlined style={{ fontSize: '3rem' }} />
														<Typography>Image Set</Typography>
													</div>
												</div>
											</div>
										</div>
									)}

									{formikProps.values.type === 'set' && (
										<>
											{formikProps.values.images.map((image, index) => (
												<div
													key={image.id}
													style={{ display: 'flex', position: 'relative' }}
												>
													<Tooltip title='Remove image'>
														<IconButton
															size='small'
															className={clsx(styles.deleteIcon)}
															onClick={() => {
																// If this is a temp file, delete it. If it's a permanent we'll remove it on the form save (that way if a user accidentally deletes an already saved file it will still be there if they don't save the form)
																if (isTempUploadedFile(image?.file))
																	deleteTemporaryUpload({
																		filename: image.file.filename,
																	})
																arrayHelpers.remove(index)
															}}
														>
															<Close />
														</IconButton>
													</Tooltip>

													<div
														style={{
															minWidth: 250,
															maxWidth: 250,
															height: 250,
															display: 'flex',
														}}
													>
														<FormikImageField
															style={{ flex: 1 }}
															field={{ name: `images.${index}.file` }}
															aspectRatio={1}
															aspectRatioHelper='1:1 aspect ratio (e.g. 500px by 500px)'
														/>
													</div>
													<div
														style={{
															marginLeft: theme.spacing(2),
															marginTop: theme.spacing(1),
														}}
													>
														<FormikTextInput
															fieldProps={{
																name: `images.${index}.title`,
																label: 'Title (optional)',
															}}
														/>
														<FormikRichText
															fieldProps={{
																name: `images.${index}.description`,
																label: 'Description (optional)',
															}}
														/>
													</div>
												</div>
											))}

											<Button
												variant='text'
												startIcon={<Add />}
												onClick={() =>
													arrayHelpers.push({
														file: undefined,
														title: '',
														description: '',
														id: v4(),
													})
												}
												style={{ marginBottom: theme.spacing(1) }}
												disabled={formikProps.values.images.length >= 3}
											>
												Add an Image (up to 3)
											</Button>

											{typeof formikProps.errors.images === 'string' && (
												<FormHelperText error={true}>
													{formikProps.errors.images}
												</FormHelperText>
											)}
										</>
									)}
								</>
							)}
						/>
						{(formikProps.values.type === 'cover' ||
							formikProps.values.type === 'full') && (
							<>
								<FormikImageField
									field={{ name: 'coverImage.file' }}
									aspectRatio={formikProps.values.type === 'cover' ? 3 / 1 : undefined}
									aspectRatioHelper={
										formikProps.values.type === 'cover'
											? '3:1 aspect ratio (e.g. 1500px by 500px)'
											: 'Unrestricted aspect ratio.'
									}
									immediatelyOpenEditor
									style={{ marginBottom: theme.spacing(2) }}
								/>
								<FormikTextInput
									noMargin
									fieldProps={{
										name: 'coverImage.caption',
										label: 'Caption (optional)',
									}}
									style={{
										marginLeft: theme.spacing(0.5),
										marginRight: theme.spacing(0.5),
									}}
								/>
							</>
						)}
					</Form>
				)
			}}
		</Formik>
	)
}
