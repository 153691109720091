import { useHandleReactQuery, useMaterialPaletteGenerator } from '../hooks'
import { createMuiTheme, useTheme } from '@material-ui/core/styles'
import { FC, useMemo } from 'react'
import { ThemeProvider } from '@material-ui/core'
import tinycolor from 'tinycolor2'
import { LoadingOverlay } from './loading-overlay'
import { useGetSchoolThemeQuery } from '../graphql/autogenerate/react-query'

interface ISchoolThemeProviderProps {
    schoolSlug?: string | null
    schoolTheme?: {
        primaryColor?: string | null
        secondaryColor?: string | null
    } | null
}

/** 
    Modifies the global MaterialTheme to match a school's Theme.

    @param schoolTheme Optionally the parent component can pass in the School's Theme data. If ommitted, the `<SchoolThemeProvider />` will fetch the School's Theme.
*/
export const SchoolThemeProvider: FC<ISchoolThemeProviderProps> = ({ schoolSlug, schoolTheme, children }) => {

    const { data } = useHandleReactQuery(useGetSchoolThemeQuery(
        { slug: schoolSlug! }, // Using '!' - normally dangerous, but our 'enabled' filter below ensures schoolId will not be null/undefined if the query starts
        { enabled: !Boolean(schoolTheme) && Boolean(schoolSlug) }) // Only run this query if we don't have a school theme provided AND we DO have a schoolId
    )
    const schoolThemeData = data?.schoolBySlug?.theme || schoolTheme

    const theme = useTheme()
    const materialPaletteGenerator = useMaterialPaletteGenerator()
    const materialSchoolTheme = useMemo(() => {
        const primary = schoolThemeData?.primaryColor ? materialPaletteGenerator(schoolThemeData.primaryColor) : undefined
        const secondary = (() => {
            if (schoolThemeData?.secondaryColor) return materialPaletteGenerator(schoolThemeData.secondaryColor)
            if (schoolThemeData?.primaryColor) return materialPaletteGenerator(tinycolor(schoolThemeData.primaryColor).complement().toHexString())
            return undefined
        })()

        /* 
            Apparently you always need to be careful about creating themes. 
            If you just "createMuiTheme" it will create a fresh theme (understandably) with ONLY the overrides you specify when calling it (not so understandably ). 
            To include overrides you've already made from a previous "createMuiTheme", make sure to spread the current theme into the createMuiTheme call.
        */
        return createMuiTheme({ ...theme, palette: { primary, secondary }, overrides: { ...theme.overrides } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ schoolThemeData ])


    if (!schoolThemeData) return <LoadingOverlay />

    return (
        <ThemeProvider theme={materialSchoolTheme}>
            {children}
        </ThemeProvider>
    )
}