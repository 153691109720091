import { Typography, useTheme, makeStyles, createStyles } from '@material-ui/core'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import { NewsletterFragment } from '../graphql/autogenerate/operations'
import { NewsletterStatus } from '../graphql/autogenerate/schemas'
import { useCommonStyles } from '../styles'
import { ImageDisplay } from './image-display'

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[ 3 ],
            '&:hover': {
                boxShadow: theme.shadows[ 15 ],
            },
            position: 'relative'
        },
        draft: {
            color: theme.palette.grey[ 700 ]
        },
        imageContainer: {
            height: 150,
            backgroundColor: theme.palette.primary.main,
        },
        image: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            height: '100%',
        },
    })
)

export interface INewsletterTileProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
    newsletter: NewsletterFragment
}

export const NewsletterTile = forwardRef<HTMLDivElement, INewsletterTileProps>(({ newsletter, className, children, ...rest }, ref) => {
    const theme = useTheme()
    const styles = useStyles()
    const commonStyles = useCommonStyles()

    return (
        <div ref={ref} className={clsx(styles.container, newsletter.status === NewsletterStatus.Draft && styles.draft, className)} {...rest}>
            {children}
            <div className={styles.imageContainer}>
                {newsletter.coverImage && <ImageDisplay className={styles.image} src={newsletter.coverImage?.temporaryDownloadUrl} alt='cover image' />}
            </div>
            <div style={{ margin: theme.spacing(1), flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant='h6' className={commonStyles.ellipsisText} >{newsletter.title}</Typography>
                <Typography variant='caption'>
                    {newsletter.status === NewsletterStatus.Draft && 'Draft'}
                    {newsletter.status === NewsletterStatus.Scheduled && `Scheduled for ${dayjs(newsletter.publishDate || dayjs()).format('dddd, MMMM D [at] h:mma')}`}
                    {newsletter.status === NewsletterStatus.Published && `Published on ${dayjs(newsletter.publishDate || dayjs()).format('dddd, MMMM D')}`}
                </Typography>
            </div>
        </div>
    )
})

