import { Button, Popover, Typography, useTheme } from '@material-ui/core'
import { CSSProperties, FC, useState } from 'react'
import { useCopyToClipboard } from 'react-use'


interface ICopyTextButtonProps {
    textToCopy: string
    style?: CSSProperties
    variant?: 'text' | 'outlined' | 'contained'
}

export const CopyTextButton: FC<ICopyTextButtonProps> = ({ children, textToCopy, style, variant }) => {
    const theme = useTheme()
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null)

    const [ _, copyToClipboard ] = useCopyToClipboard()

    return (
        <div>
            <Button
                variant={variant}
                color='primary'
                style={style}
                onClick={evt => {
                    copyToClipboard(textToCopy)
                    setAnchorEl(evt.currentTarget)
                    setTimeout(() => {
                        setAnchorEl(null)
                    }, 1000)
                }}
            >
                {children}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            >
                <Typography style={{ padding: theme.spacing(2) }}>Copied!</Typography>
            </Popover>
        </div>
    )
}